import { Table } from "components/materials";
import { PanelTableIcon } from "evergreen-ui";
import { first, isNull, range } from "lodash";
import t from "helpers/translate";
import { minorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import { add } from "helpers/math";
import { DrawSummarySelect } from "./DrawSummarySelect";

export function DrawSummaryTableHeader({
  fileType,
  form,
  lockdownDraw,
  onAmountColumnChange,
  onLineItemColumnChange,
  sheetData,
  theme,
}) {
  const {
    adjustmentsColumn,
    adjustmentsMethod,
    lineItemColumn,
    requestedColumn,
    requestedMethod,
    retainageColumn,
    retainageMethod,
    lineItems,
  } = form.values;

  const formHasOverrides = lineItems.some(({ overrides }) =>
    Object.values(overrides).some((value) => !isBlank(value))
  );

  const adjustmentsHasOverrides = lineItems.some(
    ({ overrides }) => !isBlank(overrides.adjustmentsAmount)
  );

  const requestedHasOverrides = lineItems.some(
    ({ overrides }) => !isBlank(overrides.requestedAmount)
  );

  const retainageHasOverrides = lineItems.some(
    ({ overrides }) => !isBlank(overrides.retainageAmount)
  );

  const columnOptions = getColumnOptions(sheetData, fileType);

  const selectorProps = {
    options: columnOptions,
    disabled: lockdownDraw,
    theme,
  };

  const headerTextProps = {
    width: "15%",
    border: "none",
    textAlign: "right",
    textProps: { fontWeight: theme.fontWeights.MEDIUM },
  };

  return (
    <Table.Head>
      <Table.Row>
        <Table.Cell border="none" width="25%" />
        <Table.TextCell
          border="none"
          textProps={{ color: theme.colors.green600 }}
          width="30%"
        >
          <PanelTableIcon
            color={theme.colors.green600}
            marginRight={minorScale(3)}
            minWidth={16}
          />
          {t("drawSummaryReview.rowPrompt")}
        </Table.TextCell>
        <Table.TextCell testId="adjustmentsMethodColumn" {...headerTextProps}>
          {t(`drawSummaryReview.optionHeader.adjustments.${adjustmentsMethod}`)}
        </Table.TextCell>
        <Table.TextCell testId="requestedMethodColumn" {...headerTextProps}>
          {t(`drawSummaryReview.optionHeader.requested.${requestedMethod}`)}
        </Table.TextCell>
        {retainageMethod !== "noRetainage" && (
          <Table.TextCell testId="retainageMethodColumn" {...headerTextProps}>
            {t(`drawSummaryReview.optionHeader.retainage.${retainageMethod}`)}
          </Table.TextCell>
        )}
      </Table.Row>
      <Table.Row>
        <Table.TextCell
          border="none"
          textProps={{ color: theme.colors.green600 }}
          width="25%"
        >
          <PanelTableIcon
            color={theme.colors.green600}
            marginRight={minorScale(3)}
            minWidth={16}
          />
          {t("drawSummaryReview.columnPrompt")}
        </Table.TextCell>
        <Table.Cell border="none" padding={0} width="30%" textAlign="left">
          <DrawSummarySelect
            value={form.values.lineItemColumn}
            onChange={(columnSelection) =>
              onLineItemColumnChange(columnSelection, formHasOverrides)
            }
            isWarned={isNull(lineItemColumn)}
            confirmSelect={formHasOverrides}
            comboboxProps={{
              maxWidth: 175,
            }}
            testId="lineItemColumn"
            {...selectorProps}
          />
        </Table.Cell>
        <Table.Cell border="none" padding={0} textAlign="right">
          <DrawSummarySelect
            value={form.values.adjustmentsColumn}
            onChange={(columnSelection) => {
              onAmountColumnChange(
                "adjustmentsColumn",
                "adjustmentsAmount",
                columnSelection,
                adjustmentsHasOverrides
              );
            }}
            isWarned={!isNull(lineItemColumn) && isNull(adjustmentsColumn)}
            confirmSelect={adjustmentsHasOverrides}
            comboboxProps={{
              maxWidth: 80,
            }}
            testId="adjustmentsColumn"
            {...selectorProps}
          />
        </Table.Cell>
        <Table.Cell border="none" padding={0} textAlign="right">
          <DrawSummarySelect
            value={form.values.requestedColumn}
            onChange={(columnSelection) => {
              onAmountColumnChange(
                "requestedColumn",
                "requestedAmount",
                columnSelection,
                requestedHasOverrides
              );
            }}
            isWarned={!isNull(lineItemColumn) && isNull(requestedColumn)}
            confirmSelect={requestedHasOverrides}
            comboboxProps={{
              maxWidth: 80,
            }}
            testId="requestedColumn"
            {...selectorProps}
          />
        </Table.Cell>
        {retainageMethod !== "noRetainage" && (
          <Table.Cell border="none" padding={0} textAlign="right">
            <DrawSummarySelect
              value={form.values.retainageColumn}
              onChange={(columnSelection) => {
                onAmountColumnChange(
                  "retainageColumn",
                  "retainageAmount",
                  columnSelection,
                  retainageHasOverrides
                );
              }}
              isWarned={!isNull(lineItemColumn) && isNull(retainageColumn)}
              confirmSelect={retainageHasOverrides}
              comboboxProps={{
                maxWidth: 80,
              }}
              testId="retainageColumn"
              {...selectorProps}
            />
          </Table.Cell>
        )}
      </Table.Row>
    </Table.Head>
  );
}

function getAlphabeticalColumnLabel(index) {
  if (index <= 25) return String.fromCharCode(index + 65);
  const quotient = Math.floor(index / 26);
  return getAlphabeticalColumnLabel(quotient - 1).concat(
    getAlphabeticalColumnLabel(index % 26)
  );
}

function getColumnOptions(sheetData, fileType) {
  const columnCount = (first(sheetData) || []).length;
  return range(columnCount).map((columnIndex) => ({
    key: `column-${columnIndex}`,
    value: columnIndex,
    text:
      fileType === "application/pdf"
        ? add(columnIndex, 1).toString()
        : getAlphabeticalColumnLabel(columnIndex),
  }));
}
