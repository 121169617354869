import { WarningSignIcon } from "evergreen-ui";
import { Divider, Heading, Link, RedesignLayout } from "components/materials";
import { majorScale } from "helpers/utilities";
import analytics from "helpers/analytics";
import { useHistory } from "react-router-dom";

function ErrorFallback() {
  return <Error />;
}

function Error() {
  // Track when a user actually hits the Error page
  analytics.track("Error Page");
  const history = useHistory();

  const goBack = (event) => {
    event.preventDefault();
    history.goBack();
  };

  // This specifically uses an anchor tag instead of Link because
  // Link requires us to be in the router context. This error fallback
  // happens outside of the router context, so we can't use the Link
  // component.
  return (
    <RedesignLayout>
      <RedesignLayout.Nav isFallback />
      <Divider />
      <Heading
        is="h1"
        textAlign="center"
        size={700}
        width={600}
        marginLeft="auto"
        marginRight="auto"
      >
        <WarningSignIcon size={majorScale(5)} />
        <Divider />
        <div>
          Unfortunately, it looks like an error has occurred. Please go back and
          retry or <a href="/">click here</a> to go to the home page. If you
          continue to experience issues please contact us at{" "}
          <a href="mailto:help@rabbet.com">help@rabbet.com</a> and we would be
          happy to help.
        </div>
        <Divider />
        <Link to="/" onClick={goBack} size={majorScale(2)}>
          Go Back
        </Link>
        <Divider />
      </Heading>
    </RedesignLayout>
  );
}

export default ErrorFallback;
