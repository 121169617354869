import { useState, useEffect } from "react";
import { useMemoDeep } from "./useMemoCompare";

/**
 * Debounce how often a value changes for use with hooks.
 *
 * Stolen from https://usehooks.com/useDebounce/.
 * @param value The frequently changing value to debounce.
 * @param delay How long to wait before updating the value, in milliseconds.
 * @returns A value that only updates after the delay has passed.
 */
export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

/**
 * Debounce how often a value changes(compared with deep equality) for use with
 * hooks.
 * @param value
 * @param delay
 * @returns
 */
export function useDebounceDeep<T>(value: T, delay: number) {
  const valueByRef = useMemoDeep(value);
  return useDebounce(valueByRef, delay);
}
