import gql from "graphql-tag";

export const BASE_SETTINGS_FRAGMENT = gql`
  fragment BaseSettingsFragment on Project {
    id
    amount
    hasHardCostRequested
    hasPayApplication
    divisions {
      id
      scopeId
      name
      position
      lineItems {
        id
        scopeId
        name
        position
        retainagePercentage
        types
      }
    }
    draws {
      id
      fundingConfirmed
      name
      state
      fundingSources {
        id
        scopeId
        disbursements {
          id
          disbursedAmount
          lineItemId
        }
      }
    }
    recentDraw {
      id
      name
      state
    }
    projection {
      id
    }
    organization {
      id
      projectRegions {
        id
        region
      }
      productTypes {
        id
        type
      }
      projectTemplates {
        id
        name
      }
    }
    typeId
  }
`;

export const DETAILS_SETTINGS_FRAGMENT = gql`
  fragment DetailsSettingsFragment on Project {
    customId
    name
    startDate
    expectedProjectLength
    status
    streetAddress
    city
    state
    squareFeet
    acres
    zip
    loanMaturityDate
    drawSummaryTemplate
    team {
      id
    }
    projectRegionId
    productTypeId
    customFields {
      id
      scopeId
      label
      options
      type
      value
    }
    drawUpdateSource
  }
`;

export const FUNDING_SOURCES_SETTINGS_FRAGMENT = gql`
  fragment FundingSourcesSettingsFragment on Project {
    automaticAllocationEnabled
    usesOfFundsEnabled
    fundingSourceGroups {
      id
      amount
      disbursedAmount
      label
      type
      closeDate
      maturityDate
      organization {
        id
        name
      }
      lineItemIds
    }
  }
`;

export const LINE_ITEM_TYPES_FRAGMENT = gql`
  fragment LineItemTypesFragment on Project {
    divisions {
      id
      scopeId
      lineItems {
        id
        scopeId
        types
      }
    }
  }
`;

export const RULES_SETTINGS_FRAGMENT = gql`
  fragment RulesSettingsFragment on Project {
    rules {
      id
      scopeId
      name
      type
      enabled
      config
    }
  }
`;

export const REVIEWERS_SETTINGS_FRAGMENT = gql`
  fragment ReviewersSettingsFragment on Project {
    suggestedDocumentAssignees {
      id
      userId
    }
    documentReviewers {
      id
      userId
    }
    drawReviewers {
      id
      isPreparer
      isSignatory
    }
    viewAllProjectsUserIds: actorIds(action: VIEW_ALL_PROJECTS)
    viewCurrentProjectUserIds: actorIds(action: USER_PROJECT_ACCESS)
  }
`;

export const STAKEHOLDERS_SETTINGS_FRAGMENT = gql`
  fragment StakeholdersSettingsFragment on Project {
    stakeholders {
      id
      role
      updatedAt
      member {
        id
        name
        email
      }
      organization {
        id
        name
      }
    }
    borrowers {
      id
      registrationComplete
      members {
        id
      }
    }
  }
`;

export const INSPECTION_QUESTIONS_FRAGMENT = gql`
  fragment InspectionQuestionsFragment on Project {
    inspectionReportQuestionIds
  }
`;

export const DRAW_ASSESSMENT_QUESTIONS_FRAGMENT = gql`
  fragment DrawAssessmentQuestionsFragment on Project {
    drawAssessmentQuestionIds
  }
`;

export const SHARING_SETTINGS_FRAGMENT = gql`
  fragment SharingSettingsFragment on Project {
    accessTokens
    emailToAddress
    emailNotificationsEnabledUsers {
      id
    }
  }
`;

export const NEXUS_CONFIGURATION_FRAGMENT = gql`
  fragment NexusConfigurationFragment on Project {
    glCode
    jobCode
    jobPhaseCode
  }
`;

export const YARDI_CONFIGURATION_FRAGMENT = gql`
  fragment YardiConfigurationFragment on Project {
    glCode
    accountsPayableLastSyncedAt
    accountsPayableSyncFailingSince
    accountsPayableSyncErrorMessage
  }
`;

export const QUICK_BOOKS_ONLINE_CONFIGURATION_FRAGMENT = gql`
  fragment QuickBooksOnlineConfigurationFragment on Project {
    hasQboAuthTokens
    qboAuthorizationUrl
  }
`;

// Nexus fragment already has jobCode that would otherwise go in here
export const MRI_CONFIGURATION_FRAGMENT = gql`
  fragment MriConfigurationFragment on Project {
    mriCostList
  }
`;
