import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Checkbox, Form } from "components/materials";
import { Field, connect, getIn } from "formik";

export function FastCheckbox({ label, name, value }) {
  return (
    <Field name={name} type="checkbox" value={value}>
      {({ field }) => <Checkbox {...field} label={label} />}
    </Field>
  );
}

class FormCheckboxInner extends PureComponent {
  handleChange = (event) => {
    const { formik, name, onChange } = this.props;
    formik.setFieldValue(name, event.target.checked);
    onChange && onChange(event.target.checked);
  };

  render() {
    const {
      formik,
      name,
      hint,
      label,
      fieldProps,
      testId,
      ...props
    } = this.props;
    const value = getIn(formik.values, name);
    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);
    const hasError = !!error && !!touch;
    const disabled = this.props.disabled || formik.values.__disabled;

    return (
      <Form.Field
        label=""
        hint={hint}
        validationMessage={hasError && error}
        data-testid="field"
        className="rbt-form-checkbox"
        {...fieldProps}
      >
        <Checkbox
          {...props}
          disabled={disabled}
          className="rbt-checkbox"
          data-testid={testId}
          name={name}
          label={label}
          checked={value}
          onChange={this.handleChange}
        />
      </Form.Field>
    );
  }
}

export const FormCheckbox = connect(FormCheckboxInner);

FormCheckbox.propTypes = {
  fieldProps: PropTypes.node,
  hint: PropTypes.node,
  name: PropTypes.string.isRequired,
  testId: PropTypes.string,
};
