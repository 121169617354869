import PropTypes from "prop-types";
import { minorScale } from "helpers/utilities";
import { Tooltip } from "components/materials";
import { HelpIcon, Text, withTheme } from "evergreen-ui";
import TableHeaderCell, {
  propTypes as headerCellPropTypes,
} from "./HeaderCell";
import { getProps, compose, withMemo } from "./utils";

const TableTextHeaderCell = compose(
  ({ children, content, textProps = {}, tooltip, ...props }) => {
    textProps = {
      fontWeight: props.theme.fontWeights.REGULAR,
      size: 300,
      wordBreak: "break-word",
      ...textProps,
    };

    return (
      <TableHeaderCell {...props}>
        <Text {...textProps}>{content || children}</Text>
        {tooltip && (
          <Text {...textProps}>
            <Tooltip content={tooltip}>
              <HelpIcon
                size={minorScale(3)}
                marginLeft={minorScale(2)}
                marginBottom={-2}
              />
            </Tooltip>
          </Text>
        )}
      </TableHeaderCell>
    );
  },
  [withMemo, withTheme]
);

export const propTypes = {
  ...headerCellPropTypes,
  children: PropTypes.node,
  content: PropTypes.node,
  textProps: PropTypes.object,
  tooltip: PropTypes.string,
};

TableTextHeaderCell.propTypes = propTypes;

// TODO: remove dot notation
TableTextHeaderCell.getProps = (props) => getProps(propTypes, props);

export default TableTextHeaderCell;
