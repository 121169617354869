import { useState, useEffect, useContext, Fragment } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { UserContext } from "helpers/behaviors";
import PropTypes from "prop-types";
import { get, isEqual, trim } from "lodash";
import {
  EditProjectSettingsButtons,
  ProjectDetailsForm,
} from "components/templates";
import {
  Accordion,
  Button,
  Confirm,
  InterpolatedTranslation,
  Link,
  Pane,
  Paragraph,
  SuperConfirm,
} from "components/materials";
import { CUSTOM_FIELD_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { isValidZipcode } from "helpers/addressValidation";
import { formatDate, isInvalidDate } from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import { majorScale } from "helpers/utilities";
import { getHasProjection } from "helpers/projectionHelpers";
import { setRecentProjectIds } from "helpers/localStorage";
import isEqualWithout from "helpers/isEqualWithout";
import t from "helpers/translate";

const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($projectId: String!) {
    deleteProject(projectId: $projectId) {
      id
    }
  }
`;

const COPY_PROJECT_MUTATION = gql`
  mutation CopyProject($projectId: String!) {
    copyProject(projectId: $projectId) {
      id
    }
  }
`;

// the input masks used for integers set the value as an empty string if a non-number key is pressed
// the field will still render as blank, but the value will technically be dirty
// this check ensures that the Undo / Save buttons will not render unless the form is actually dirty
const checkDetailsDirty = ({ initialValues, values }) => {
  const customFieldsChanged = initialValues.customFields.some(
    ({ value }, index) => {
      return !isEqual(trim(value), trim(values.customFields[index].value));
    }
  );

  return (
    !isEqualWithout(
      ["expectedProjectLength", "stateValue", "zip", "customFields"],
      values,
      initialValues
    ) ||
    !isEqual(
      trim(values.expectedProjectLength),
      trim(initialValues.expectedProjectLength)
    ) ||
    !isEqual(trim(values.stateValue), trim(initialValues.stateValue)) ||
    !isEqual(trim(values.zip), trim(initialValues.zip)) ||
    customFieldsChanged
  );
};

function hasChangeAffectingProjection(
  { initialValues, values },
  hasProjection
) {
  const startDateChanged = initialValues.startDate !== values.startDate;
  const projectLengthDecreased =
    initialValues.expectedProjectLength > values.expectedProjectLength;
  return hasProjection && (startDateChanged || projectLengthDecreased);
}

function hasChangeAffectingAccess({ initialValues, values }) {
  return (
    initialValues.teamId !== values.teamId && initialValues.teamId !== undefined
  );
}

function CopyProjectButton({ dirty, copyProject, project }) {
  const [showConfirmCopy, setShowConfirmCopy] = useState(false);

  return (
    <Fragment>
      <Button
        marginTop={majorScale(3)}
        marginRight={majorScale(2)}
        disabled={dirty}
        onClick={() => setShowConfirmCopy(true)}
      >
        Copy Project
      </Button>
      <Confirm
        content={t("projectSettings.confirmCopyProject")}
        header="Copy Details to New Project"
        onCloseComplete={() => setShowConfirmCopy(false)}
        onConfirm={(close) => {
          copyProject({ variables: { projectId: project.id } });
          close();
        }}
        open={showConfirmCopy}
      />
    </Fragment>
  );
}

function DeleteProjectButton({ deleteProject, project }) {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  return (
    <Fragment>
      <Button
        marginTop={majorScale(3)}
        intent="danger"
        onClick={() => setShowConfirmDelete(true)}
      >
        Delete Project
      </Button>
      <SuperConfirm
        isShown={showConfirmDelete}
        title="Delete Project"
        onConfirm={() =>
          deleteProject({ variables: { projectId: project.id } })
        }
        onCloseComplete={() => setShowConfirmDelete(false)}
        warningMessage={t("projectSettings.confirmDeleteProject")}
        confirmMatchText="DELETE"
      />
    </Fragment>
  );
}

const EditProjectDetailsPanel = ({
  dirty,
  form,
  isDeveloper,
  loading,
  onToggle,
  panelKey,
  project,
  setPanelDirty,
  ...props
}) => {
  useEffect(() => {
    const isDirty = checkDetailsDirty(form);
    if (isDirty !== dirty) {
      setPanelDirty(panelKey, isDirty);
    }
  }, [dirty, form, panelKey, setPanelDirty]);

  const { hasPermission } = useContext(UserContext);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [copyProject] = useMutation(COPY_PROJECT_MUTATION, {
    onCompleted: (result) => {
      const projectId = get(result, "copyProject.id");
      setRecentProjectIds(projectId);

      // Use window.location rather than history.push to ensure a page refresh
      // so that AppLayout's projects can refresh
      window.location.assign(`/projects/${projectId}/configure`);
    },
  });

  const [deleteProject] = useMutation(DELETE_PROJECT_MUTATION, {
    onCompleted: () => {
      window.location.assign(`/`);
    },
  });

  const hasProjection = getHasProjection(project);
  const showProjectionWarning = hasChangeAffectingProjection(
    form,
    hasProjection
  );
  const showUserAccessWarning = hasChangeAffectingAccess(form);

  const renderConfirmContent = () => {
    const oldTeamName = project.organization.teams.find(
      ({ id }) => id === form.initialValues.teamId
    )?.name;

    return (
      <Pane>
        {showUserAccessWarning && (
          <Paragraph marginBottom={majorScale(2)}>
            <InterpolatedTranslation
              string={t("projectSettings.teamChangeWarning1", {
                oldTeamName,
              })}
              values={[
                <Link href="/admin" target="_blank">
                  {t("projectSettings.teamChangeWarning2")}
                </Link>,
              ]}
            />
          </Paragraph>
        )}
        {showProjectionWarning && (
          <Paragraph marginBottom={majorScale(2)}>
            {t("projection.projectDateChangeWarning")}
          </Paragraph>
        )}
        <Paragraph>{t("projectSettings.confirmUpdateProjectText")}</Paragraph>
      </Pane>
    );
  };

  return (
    <Fragment>
      <Accordion.Panel
        panelKey={panelKey}
        title="Project Details"
        onClick={() => onToggle(panelKey)}
        actionContent={
          <EditProjectSettingsButtons
            dirty={dirty}
            form={form}
            hasSubmissionPrompt={(_form) =>
              showProjectionWarning || showUserAccessWarning
            }
            renderSubmissionPrompt={() => setConfirmOpen(true)}
            loading={loading}
          />
        }
        {...props}
      >
        <ProjectDetailsForm
          customFields={get(form, "values.customFields", [])}
          project={project}
          showAllFields
        />
        <Pane>
          {hasPermission(PERMISSION_ACTION.CREATE_PROJECT) && (
            <CopyProjectButton
              dirty={dirty}
              copyProject={copyProject}
              project={project}
            />
          )}
          {hasPermission(PERMISSION_ACTION.DELETE_PROJECT) && (
            <DeleteProjectButton
              deleteProject={deleteProject}
              project={project}
            />
          )}
        </Pane>
      </Accordion.Panel>
      <Confirm
        content={renderConfirmContent()}
        header="Warning"
        onCloseComplete={() => setConfirmOpen(false)}
        onConfirm={(close) => {
          form.handleSubmit();
          close();
        }}
        open={confirmOpen}
      />
    </Fragment>
  );
};

// TODO: remove dot notation
EditProjectDetailsPanel.validate = (isDeveloper) => (values) => {
  const errors = {};
  if (isBlank(values.name)) errors.name = "Please enter a name";

  // Start date is required if the org cannot submit draws
  if (!isDeveloper && isBlank(values.startDate)) {
    errors.startDate = "Please enter a date";
  }
  if (!isBlank(values.startDate) && isInvalidDate(values.startDate))
    errors.startDate = "Date is invalid";
  if (
    !isBlank(values.loanMaturityDate) &&
    isInvalidDate(values.loanMaturityDate)
  ) {
    errors.startDate = "Date is invalid";
  }

  if (!isValidZipcode(values.zip)) {
    errors.zip = "Postal Code is invalid";
  }

  return errors;
};

// TODO: remove dot notation
EditProjectDetailsPanel.initialValues = (project) => {
  const {
    acres,
    city,
    customFields,
    customId,
    drawSummaryTemplate,
    drawUpdateSource,
    expectedProjectLength,
    loanMaturityDate,
    name,
    productTypeId,
    projectRegionId,
    squareFeet,
    startDate,
    state,
    status,
    streetAddress,
    team,
    typeId: projectTypeId,
    zip,
  } = project;

  return {
    customId,
    name,
    streetAddress: streetAddress || undefined,
    city: city || undefined,
    stateValue: state || undefined,
    zip: zip || undefined,
    startDate: formatDate(startDate),
    loanMaturityDate: formatDate(loanMaturityDate),
    expectedProjectLength,
    squareFeet,
    acres,
    status,
    teamId: get(team, "id"),
    projectRegionId,
    projectTypeId,
    productTypeId,
    drawUpdateSource: drawUpdateSource || undefined,
    drawSummaryTemplate: drawSummaryTemplate || undefined,
    customFields: customFields.map(({ id, label, options, type, value }) => {
      if (type === CUSTOM_FIELD_TYPE.BOOLEAN) {
        return { id, label, type, value: value === "true" };
      }
      return { id, label, options, type, value };
    }),
  };
};

EditProjectDetailsPanel.propTypes = {
  isDeveloper: PropTypes.bool,
  contentStyles: PropTypes.object,
  dirty: PropTypes.bool,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  panelKey: PropTypes.string.isRequired,
  panelStyles: PropTypes.object,
  setPanelDirty: PropTypes.func,
  titleStyles: PropTypes.object,
};

export default EditProjectDetailsPanel;
