import { Fragment, useMemo } from "react";
import {
  AddIcon,
  AnnotationIcon,
  DoughnutChartIcon,
  EnvelopeIcon,
} from "evergreen-ui";
import { Link, Pane, Shortener } from "components/materials";
import {
  currencyColumnDefaults,
  dateColumnDefaults,
  enumColumnDefaults,
  fractionColumnDefaults,
  listColumnDefaults,
  numberColumnDefaults,
  percentColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import {
  addMonths,
  differenceInDays,
  differenceInMonths,
  formatDate,
  formatDateTime,
  isAtOrBefore,
  getDateFromDateTime,
} from "helpers/dateHelpers";
import { formatList } from "helpers/formatList";
import { DRAW_STATE_SORT_ORDER, secondaryStatus } from "helpers/drawHelpers";
import {
  DRAW_STATE,
  FUNDING_SOURCE_TYPE,
  ORGANIZATION_TYPE,
  PERMISSION_ACTION,
  PROJECT_STATUS_TYPE,
  TASK_STATUS,
} from "helpers/enums";
import {
  fundingSourcesByType,
  getCommitmentAggregatesForGroupedProjects,
  getFundingSourceAggregatesForGroupedProjects,
} from "helpers/fundingSourceHelpers";
import { add, divide, subtract, sumBy } from "helpers/math";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import {
  getDateRangeAggregate,
  getDefaultAggregate,
  getNumberRangeAggregate,
  getPercentAggregate,
  getPercentAggregateFromPercents,
} from "helpers/tableAggregateHelpers";
import { getEnumValuesForOrganization } from "helpers/reportHelpers";
import {
  getReviewersForProject,
  renderReviewsForProject,
} from "helpers/drawReviewHelpers";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { camelCase, compact, get, reject } from "lodash";
import { formatNumber } from "helpers/formatNumber";
import { getProjectIcon } from "helpers/projectIconHelpers";
import isBlank from "helpers/isBlank";
import {
  getApprovalsCount,
  getApprovalStatus,
  RelativeDrawReviewState,
} from "helpers/approvalHelpers";
import { orderedLineItemCategories } from "../LineItemSettings";
import { customFieldFastColumns } from "../CustomFieldColumns";
import { getFundingSourcesByLabel } from "../PortfolioInsights/helpers";

// Organization types: [BORROWER, LENDER, EQUITY_PARTNER, INSPECTOR]
// are not included in this list, as they have distinct column definitions
const STAKEHOLDER_COLUMN_DATA = {
  [ORGANIZATION_TYPE.ACCOUNTANT]: { id: "accountants", header: "Accountants" },
  [ORGANIZATION_TYPE.ARCHITECT]: { id: "architects", header: "Architects" },
  [ORGANIZATION_TYPE.BROKER]: { id: "brokers", header: "Brokers" },
  [ORGANIZATION_TYPE.CIVIL_ENGINEER]: {
    id: "civilEngineers",
    header: "Civil Engineers",
  },
  [ORGANIZATION_TYPE.CLIENT]: { id: "clients", header: "Clients" },
  [ORGANIZATION_TYPE.CONSTRUCTION_SERVICES]: {
    id: "constructionServices",
    header: "Construction Services",
  },
  [ORGANIZATION_TYPE.CONSULTANT]: { id: "consultants", header: "Consultants" },
  [ORGANIZATION_TYPE.CONTRACTOR]: {
    id: "generalContractors",
    header: "General Contractors",
  },
  [ORGANIZATION_TYPE.DEVELOPER]: { id: "developers", header: "Developers" },
  [ORGANIZATION_TYPE.DEVELOPMENT_MANAGER]: {
    id: "developmentManagers",
    header: "Development Managers",
  },
  [ORGANIZATION_TYPE.ENGINEER]: { id: "engineers", header: "Engineers" },
  [ORGANIZATION_TYPE.LAWYER]: { id: "lawyers", header: "Lawyers" },
  [ORGANIZATION_TYPE.MUNICIPALITY]: {
    id: "municipalities",
    header: "Municipalities",
  },
  [ORGANIZATION_TYPE.OTHER]: {
    id: "otherStakeholders",
    header: "Other Stakeholders",
  },
  [ORGANIZATION_TYPE.SITE_CONTACT]: {
    id: "siteContacts",
    header: "Site Contacts",
  },
  [ORGANIZATION_TYPE.SUBCONTRACTOR]: {
    id: "subcontractors",
    header: "Subcontractors",
  },
  [ORGANIZATION_TYPE.TITLE_COMPANY]: {
    id: "titleCompanies",
    header: "Title Companies",
  },
  [ORGANIZATION_TYPE.VENDOR]: { id: "vendors", header: "Vendors" },
};

export function useColumns({
  hasPermission,
  hasPermissionAcrossProfiles,
  isDeveloper,
  isReport,
  orgData,
  selectedOrganization,
  userId,
}) {
  const customFieldColumns = useMemo(() => {
    return customFieldFastColumns(orgData.projectTemplates, "customFields");
  }, [orgData.projectTemplates]);

  const taskColumns = useMemo(() => {
    return getTaskColumns(orgData.projectTemplates, isReport);
  }, [orgData.projectTemplates, isReport]);

  return useMemo(() => {
    return getColumns({
      hasPermission,
      hasPermissionAcrossProfiles,
      isDeveloper,
      orgData,
      selectedOrganization,
      userId,
    })
      .concat(customFieldColumns)
      .concat(taskColumns);
  }, [
    customFieldColumns,
    hasPermission,
    hasPermissionAcrossProfiles,
    isDeveloper,
    orgData,
    selectedOrganization,
    taskColumns,
    userId,
  ]);
}

function getStakeholderOrgsByType(project, type, formatBeforeReturn) {
  const orgs = get(project, "stakeholders", []).reduce(
    (filtered, stakeholder) =>
      stakeholder.role === type
        ? [...filtered, get(stakeholder, "organization.name")]
        : filtered,
    []
  );
  return formatBeforeReturn ? formatList(orgs) : orgs;
}

function getCostByCategoryAndUnit(project, category, unit) {
  const lineItems = project.lineItems.filter(({ types }) =>
    types.includes(category)
  );

  if (!project[unit] || lineItems.length === 0) return null;

  const totalCost = sumBy(lineItems, "budgetAmount");

  return divide(totalCost, project[unit]);
}

function getLenders(project) {
  const lenderSourceOrgs = fundingSourcesByType(
    FUNDING_SOURCE_TYPE.LOAN,
    project.fundingSourceGroups
  ).map((source) => get(source, "organization.name"));
  const lenderOrgs = getStakeholderOrgsByType(
    project,
    ORGANIZATION_TYPE.LENDER
  );
  return formatList(lenderSourceOrgs.concat(lenderOrgs));
}

function getEquityPartners(project) {
  const equitySourceOrgs = fundingSourcesByType(
    FUNDING_SOURCE_TYPE.EQUITY,
    project.fundingSourceGroups
  ).map((source) => get(source, "organization.name"));
  const equityOrgs = getStakeholderOrgsByType(
    project,
    ORGANIZATION_TYPE.EQUITY_PARTNER
  );
  return formatList(equitySourceOrgs.concat(equityOrgs));
}

function getInspectionCompanies(project) {
  const recentInspection = get(project, "documentInspectionReport.vendor.name");
  const inspectionOrgs = getStakeholderOrgsByType(
    project,
    ORGANIZATION_TYPE.INSPECTOR
  );
  return formatList([recentInspection].concat(inspectionOrgs));
}

function renderOrganizationList(orgList, _item, { isGroupedValue }) {
  if (isGroupedValue) {
    return orgList;
  }
  return <Shortener limit={20} size={300} text={orgList} />;
}

function getDocumentInspectionReportHeader(
  header,
  hasInspectionReportWorkflow
) {
  return hasInspectionReportWorkflow ? `${header} (Document)` : header;
}

function renderCurrentDraw(project, hasPermission, isExport) {
  const props = {};
  if (!project.setupComplete) {
    props.color = "selected";
    props.icon = "doughnutChart";
    props.text = "Setup in Progress";
    props.to = `/projects/${project.id}`;
  } else if (
    !project.recentUnfundedDraw &&
    !hasPermission(PERMISSION_ACTION.CREATE_DRAW, project.organization)
  ) {
    props.color = "muted";
    props.text = "No Active Draws";
  } else if (!project.recentUnfundedDraw) {
    props.color = "info";
    props.icon = "add";
    props.text = "New Draw";
    props.to = `/projects/${project.id}/draws/new`;
  } else if (project.recentUnfundedDraw.state === DRAW_STATE.SUBMITTED) {
    props.color = "success";
    props.icon = "envelope";
    props.text = `${project.recentUnfundedDraw.name} - Sent`;
    props.to = `/projects/${project.id}/draws/${project.recentUnfundedDraw.id}`;
  } else {
    props.color = "warning";
    props.icon = "annotation";
    props.text = `${project.recentUnfundedDraw.name} - ${t(
      `drawStates.${project.recentUnfundedDraw.state}`
    )} ${
      project.recentUnfundedDraw.reviews.length > 0
        ? `(${secondaryStatus(project.recentUnfundedDraw)})`
        : ""
    }`;
    props.to = `/projects/${project.id}/draws/${project.recentUnfundedDraw.id}`;
  }

  if (isExport) return props.text;

  const iconProps = {
    color: props.color,
    marginRight: majorScale(1),
  };

  const DrawIcons = {
    doughnutChart: <DoughnutChartIcon {...iconProps} />,
    add: <AddIcon {...iconProps} />,
    envelope: <EnvelopeIcon {...iconProps} />,
    annotation: <AnnotationIcon {...iconProps} />,
  };

  return (
    <Pane onClick={preventEventBubbling}>
      <Link noUnderlineOnHover to={props.to}>
        <Pane display="flex" alignItems="center">
          {props.icon && DrawIcons[props.icon]}
          <Shortener
            color={props.color}
            limit={40}
            size={300}
            text={props.text}
          />
        </Pane>
      </Link>
    </Pane>
  );
}

function getMonthsRemaining(project) {
  const { expectedProjectLength, startDate } = project;
  if (!expectedProjectLength || !startDate) return null;
  const estimatedCompletionDate = addMonths(startDate, expectedProjectLength);
  const monthsRemaining = differenceInMonths(
    estimatedCompletionDate,
    Date.now()
  );
  return monthsRemaining >= 0 ? monthsRemaining : null;
}

function getDaysSinceDrawCreation(project) {
  const recentUnfundedDraw = get(project, "recentUnfundedDraw");
  const daysSinceDrawCreation = differenceInDays(
    Date.now(),
    get(recentUnfundedDraw, "createdAt")
  );
  return !recentUnfundedDraw ||
    recentUnfundedDraw.state === DRAW_STATE.SUBMITTED
    ? null
    : daysSinceDrawCreation;
}

function getTaskColumns(projectTemplates, isReport) {
  return projectTemplates.flatMap(({ name: projectTypeName, tasks }) =>
    tasks.flatMap(({ id: projectTypeTaskId, name: taskName }) => {
      return [
        {
          ...enumColumnDefaults,
          enumValues: Object.values(TASK_STATUS).map((status) =>
            t(`taskStatus.${status}`)
          ),
          id: `${projectTypeTaskId}:status`,
          category: `${projectTypeName} Tasks`,
          header: `${taskName} - Status`,
          value: (project) => {
            const status = project.tasks.find(
              (task) => task.projectTypeTaskId === projectTypeTaskId
            )?.status;
            return status ? t(`taskStatus.${status}`) : null;
          },
          hidden: !isReport,
          width: 175,
        },
        {
          ...dateColumnDefaults,
          // keep legacy id to maintain legacy saved views
          id: `${projectTypeTaskId}:expectedCompletion`,
          category: `${projectTypeName} Tasks`,
          header: `${taskName} - Original Completion Date`,
          value: (project) =>
            project.tasks.find(
              (task) => task.projectTypeTaskId === projectTypeTaskId
            )?.originalCompletionDate,
          hidden: !isReport,
          width: 175,
        },
        {
          ...dateColumnDefaults,
          id: `${projectTypeTaskId}:projectedOrActualCompletionDate`,
          tooltip:
            "The Actual Completion Date if the task has been completed, or the Projected Completion Date if not complete",
          category: `${projectTypeName} Tasks`,
          header: `${taskName} - Projected / Actual Completion Date`,
          value: (project) => {
            const task = project.tasks.find(
              (task) => task.projectTypeTaskId === projectTypeTaskId
            );
            return (
              (task?.actualCompletionDate || task?.projectedCompletionDate) ??
              null
            );
          },
          width: 175,
        },
        {
          ...numberColumnDefaults,
          id: `${projectTypeTaskId}:completionDateVariance`,
          category: `${projectTypeName} Tasks`,
          header: `${taskName} - Completion Date Variance`,
          value: (project) =>
            project.tasks.find(
              (task) => task.projectTypeTaskId === projectTypeTaskId
            )?.completionDateVariance,
          valueFormatter: (completionDateVariance) => {
            if (
              completionDateVariance === null ||
              completionDateVariance === undefined
            )
              return null;
            return `${formatNumber(completionDateVariance)} days`;
          },
          hidden: !isReport,
          width: 140,
        },
      ];
    })
  );
}

function getColumns({
  hasPermission,
  hasPermissionAcrossProfiles,
  isDeveloper,
  orgData,
  selectedOrganization,
  userId,
}) {
  const hasInspectionReportWorkflow = hasPermission(
    PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW
  );

  const canAccessFundingSources = selectedOrganization
    ? hasPermission(
        PERMISSION_ACTION.ACCESS_FUNDING_SOURCES,
        selectedOrganization
      )
    : hasPermissionAcrossProfiles(PERMISSION_ACTION.ACCESS_FUNDING_SOURCES);

  const canAccessStakeholders = selectedOrganization
    ? hasPermission(PERMISSION_ACTION.ACCESS_STAKEHOLDERS, selectedOrganization)
    : hasPermissionAcrossProfiles(PERMISSION_ACTION.ACCESS_STAKEHOLDERS);

  const hasTeamManagement = selectedOrganization
    ? hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT, selectedOrganization)
    : hasPermissionAcrossProfiles(PERMISSION_ACTION.TEAM_MANAGEMENT);

  const hasPullDataFromYardi = selectedOrganization
    ? hasPermission(
        PERMISSION_ACTION.PULL_DATA_FROM_YARDI,
        selectedOrganization
      )
    : hasPermissionAcrossProfiles(PERMISSION_ACTION.PULL_DATA_FROM_YARDI);

  const orgEnumValues = getEnumValuesForOrganization(orgData);

  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Project",
      id: "projectName",
      // category not needed - primary column
      value: (project) => project.name,
      valueFormatter: (value, project) => (
        <Fragment>
          {getProjectIcon(project.template.icon, {})}
          <Shortener limit={40} size={300} text={value} />
        </Fragment>
      ),
      width: 300,
    },
    {
      ...stringColumnDefaults,
      header: "Organization",
      id: "organizationName",
      category: "Project Information",
      groupable: true,
      value: (project) => project.organization.name,
      width: 175,
    },
    {
      ...stringColumnDefaults,
      header: "Yardi Status",
      id: "yardiStatus",
      hidden: !hasPullDataFromYardi,
      category: "Project Information",
      value: (project) =>
        isAtOrBefore(
          project.accountsPayableSyncFailingSince,
          project.accountsPayableLastSyncedAt
        )
          ? `Last Synced: ${
              project.accountsPayableLastSyncedAt
                ? formatDateTime(project.accountsPayableLastSyncedAt)
                : "Never"
            }`
          : `Erroring Since: ${formatDateTime(
              project.accountsPayableSyncFailingSince
            )}, ${project.accountsPayableSyncErrorMessage}`,
      width: 250,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) => sumBy(groupProjects, "amount"),
      header: "Project Total",
      id: "projectTotal",
      category: "Budget Progress",
      value: (project) => project.amount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(groupProjects, (project) =>
          subtract(project.amount, project.requestedAmount)
        ),
      header: "Amount Remaining",
      id: "amountRemaining",
      category: "Budget Progress",
      value: (project) => subtract(project.amount, project.requestedAmount),
    },
    {
      ...percentColumnDefaults,
      aggregate: (groupProjects) =>
        subtract(
          1,
          getPercentAggregate(groupProjects, "percentComplete", "amount")
        ),
      header: "% Remaining (Net)",
      id: "percentRemaining",
      category: "Budget Progress",
      value: (project) => subtract(1, project.percentComplete),
    },
    {
      ...percentColumnDefaults,
      aggregate: (groupProjects) =>
        subtract(
          1,
          getPercentAggregate(groupProjects, "grossPercentComplete", "amount")
        ),
      header: "% Remaining (Gross)",
      id: "grossPercentRemaining",
      category: "Budget Progress",
      value: (project) => subtract(1, project.grossPercentComplete),
    },
    {
      ...percentColumnDefaults,
      aggregate: (groupProjects) =>
        getPercentAggregate(groupProjects, "percentComplete", "amount"),
      header: "% Complete (Net)",
      id: "percentComplete",
      category: "Budget Progress",
      value: (project) => project.percentComplete,
    },
    {
      ...percentColumnDefaults,
      aggregate: (groupProjects) =>
        getPercentAggregate(groupProjects, "grossPercentComplete", "amount"),
      header: "% Complete (Gross)",
      id: "grossPercentComplete",
      category: "Budget Progress",
      value: (project) => project.grossPercentComplete,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(groupProjects, (project) =>
          subtract(
            project.contingencyOriginalAmount,
            project.contingencySpentAmount
          )
        ),
      header: "Contingency Remaining",
      id: "contingencyRemaining",
      category: "Budget Progress",
      value: (project) =>
        subtract(
          project.contingencyOriginalAmount,
          project.contingencySpentAmount
        ),
    },
    {
      ...percentColumnDefaults,
      aggregate: (groupProjects) =>
        getPercentAggregateFromPercents(
          groupProjects,
          "contingencyPercentRemaining"
        ),
      header: "% Contingency Remaining",
      id: "contingencyRemainingPercent",
      category: "Budget Progress",
      value: (project) => project.contingencyPercentRemaining,
    },
    {
      ...percentColumnDefaults,
      aggregate: (groupProjects) =>
        getPercentAggregate(
          groupProjects,
          "hardCostsGrossPercentComplete",
          "hardCostsAmount"
        ),
      header: "Hard Costs % Complete (Gross)",
      id: "hardCostsGrossPercentComplete",
      category: "Budget Progress",
      value: (project) => project.hardCostsGrossPercentComplete,
      width: 130,
    },
    {
      ...enumColumnDefaults,
      header: "Current Draw",
      id: "currentDraw",
      category: "Draw Information",
      sortOrder: isDeveloper
        ? DRAW_STATE_SORT_ORDER
        : DRAW_STATE_SORT_ORDER.slice().reverse(),
      value: (project) => {
        const state = get(project, "recentUnfundedDraw.state");
        if (state) {
          return t(`drawStates.${state}`);
        }
        return hasPermission(
          PERMISSION_ACTION.CREATE_DRAW,
          project.organization
        )
          ? "New Draw"
          : "No Active Draws";
      },
      valueExported: (_value, project) =>
        renderCurrentDraw(project, hasPermission, true),
      valueFormatter: (_value, project) =>
        renderCurrentDraw(project, hasPermission, false),
      width: 250,
    },
    {
      ...dateColumnDefaults,
      header: "Draw Status Last Changed",
      id: "drawStateLastChanged",
      category: "Draw Information",
      value: ({ recentStateUpdate }) => recentStateUpdate?.date,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(groupProjects, (project) =>
          get(project, "recentUnfundedDraw.requestedAmount")
        ),
      header: "Current Draw Amount (Net)",
      id: "currentDrawAmount",
      category: "Draw Information",
      value: (project) => get(project, "recentUnfundedDraw.requestedAmount"),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(
          groupProjects,
          ({ recentUnfundedDraw }) =>
            recentUnfundedDraw &&
            add(
              recentUnfundedDraw.requestedAmount,
              recentUnfundedDraw.retainageAmount
            )
        ),
      header: "Current Draw Amount (Gross)",
      id: "currentDrawAmountGross",
      category: "Draw Information",
      value: ({ recentUnfundedDraw }) =>
        recentUnfundedDraw &&
        add(
          recentUnfundedDraw.requestedAmount,
          recentUnfundedDraw.retainageAmount
        ),
    },
    {
      ...stringColumnDefaults,
      header: "Last Draw Funded",
      id: "lastDrawFunded",
      category: "Draw Information",
      value: (project) =>
        project.lastDrawFunded
          ? `${project.lastDrawFunded.name} - ${formatDate(
              project.lastDrawFunded.fundedDate
            )}`
          : "",
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectTemplates,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, "template.name"),
      groupable: true,
      header: "Project Type",
      id: "type",
      category: "Project Information",
      value: (project) => project.template.name,
    },
    {
      ...stringColumnDefaults,
      header: "Custom ID",
      id: "customId",
      category: "Project Information",
      value: (project) => project.customId,
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.teams,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) =>
          project.shouldRedactTeam ? null : project?.team?.name
        ),
      groupable: true,
      header: "Team",
      hidden: !hasTeamManagement,
      id: "team",
      category: "Project Information",
      value: (project) =>
        project.shouldRedactTeam ? null : project?.team?.name,
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectRegions,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) =>
          get(project, "projectRegion.region")
        ),
      groupable: true,
      header: "Project Region",
      id: "region",
      category: "Project Information",
      value: (project) => get(project, "projectRegion.region"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.productTypes,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) =>
          get(project, "productType.type")
        ),
      groupable: true,
      header: "Product Type",
      id: "productType",
      category: "Project Information",
      value: (project) => get(project, "productType.type"),
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupProjects) =>
        getDateRangeAggregate(groupProjects, "loanMaturityDate"),
      header: "Maturity Date",
      id: "maturityDate",
      category: "Project Information",
      value: (project) => project.loanMaturityDate,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) => {
          const location = compact([project.city, project.state]);
          return location.length > 0 ? location.join(", ") : "None";
        }),
      groupable: true,
      header: "Location",
      id: "projectLocation",
      category: "Project Information",
      value: (project) => {
        const location = compact([project.city, project.state]);
        return location.length > 0 ? location.join(", ") : "None";
      },
      valueFormatter: (value, item, { isGroupedValue }) => {
        return isGroupedValue ? (
          value
        ) : (
          <Shortener limit={40} size={300} text={value} />
        );
      },
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, "streetAddress"),
      header: "Street Address",
      id: "streetAddress",
      category: "Project Information",
      value: (project) => project.streetAddress,
      width: 100,
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupProjects) => getDefaultAggregate(groupProjects, "city"),
      groupable: true,
      header: "City",
      id: "city",
      category: "Project Information",
      value: (project) => project.city,
      width: 100,
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupProjects) => getDefaultAggregate(groupProjects, "state"),
      groupable: true,
      header: "State/Province",
      id: "state",
      category: "Project Information",
      value: (project) => project.state,
      width: 100,
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupProjects) => getDefaultAggregate(groupProjects, "zip"),
      groupable: true,
      header: "Postal Code",
      id: "zip",
      category: "Project Information",
      value: (project) => project.zip,
      width: 100,
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupProjects) =>
        getDateRangeAggregate(groupProjects, "startDate"),
      header: isDeveloper
        ? "Expected Construction Start Date"
        : "Expected Project Start Date",
      id: "expectedStartDate",
      category: "Project Information",
      value: (project) => project.startDate,
      width: 145,
    },
    {
      ...numberColumnDefaults,
      aggregate: (groupProjects) =>
        getNumberRangeAggregate(groupProjects, "expectedProjectLength"),
      header: "Expected Project Length (mo)",
      id: "expectedProjectLength",
      category: "Project Information",
      value: (project) => project.expectedProjectLength,
      width: 110,
    },
    {
      ...numberColumnDefaults,
      aggregate: (groupProjects) =>
        getNumberRangeAggregate(groupProjects, getMonthsRemaining),
      header: "Months Remaining",
      id: "monthsRemaining",
      category: "Project Information",
      value: getMonthsRemaining,
      width: 100,
    },
    {
      ...numberColumnDefaults,
      header: "Time Outstanding",
      id: "timeOutstanding",
      category: "Draw Information",
      value: getDaysSinceDrawCreation,
      valueFormatter: (days) => (isBlank(days) ? null : `${days} Days`),
      width: 125,
    },
    {
      ...stringColumnDefaults,
      header: "Project Created By",
      id: "createdBy",
      category: "Project Information",
      value: (project) => project.createdByUser?.fullName,
      aggregate: (groupProjects) =>
        getDefaultAggregate(
          groupProjects,
          (project) => project.createdByUser?.fullName
        ),
    },
    {
      ...dateColumnDefaults,
      header: "Project Created Date",
      id: "createdDate",
      category: "Project Information",
      value: (project) => getDateFromDateTime(project.insertedAt),
      aggregate: (groupProjects) =>
        getDateRangeAggregate(groupProjects, (project) =>
          getDateFromDateTime(project.insertedAt)
        ),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(groupProjects, (project) =>
          subtract(
            project.interestReservesAmount,
            project.interestReservesUsedAmount
          )
        ),
      header: "Interest Reserves Remaining",
      id: "interestReservesRemaining",
      category: "Budget Progress",
      value: (project) =>
        subtract(
          project.interestReservesAmount,
          project.interestReservesUsedAmount
        ),
    },
    {
      ...numberColumnDefaults,
      header: "Project Area (sqft)",
      id: "projectArea",
      category: "Project Information",
      value: ({ squareFeet }) => squareFeet,
      aggregate: (groupProjects) => sumBy(groupProjects, "squareFeet"),
      width: 120,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) => {
        const projectsWithSquareFeet = groupProjects.filter(
          ({ squareFeet }) => !!squareFeet
        );

        return divide(
          sumBy(projectsWithSquareFeet, "amount"),
          sumBy(projectsWithSquareFeet, "squareFeet")
        );
      },
      header: "Project Total / Area (sqft)",
      id: "projectTotalPerSqFeet",
      category: "Project Information",
      value: ({ amount, squareFeet }) => divide(amount, squareFeet),
      width: 120,
    },
    {
      ...numberColumnDefaults,
      header: "Parcel Size (Acres)",
      id: "parcelSize",
      category: "Project Information",
      value: ({ acres }) => acres,
      aggregate: (groupProjects) => sumBy(groupProjects, "acres"),
      width: 120,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) => {
        const projectsWithAcres = groupProjects.filter(({ acres }) => !!acres);

        return divide(
          sumBy(projectsWithAcres, "amount"),
          sumBy(projectsWithAcres, "acres")
        );
      },
      header: "Project Total / Parcel Size (Acres)",
      id: "projectTotalPerAcre",
      category: "Project Information",
      value: ({ acres, amount }) => divide(amount, acres),
      width: 120,
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(PROJECT_STATUS_TYPE).map((status) =>
        t(`projectStatus.${status}`)
      ),
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) =>
          t(`projectStatus.${project.status}`)
        ),
      groupable: true,
      header: "Project Status",
      id: "projectStatus",
      category: "Project Information",
      value: (project) => t(`projectStatus.${project.status}`),
      width: 120,
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupProjects) => {
        const approvalStatus = getDefaultAggregate(groupProjects, (project) =>
          getApprovalStatus(project.recentDraw, userId)
        );
        return approvalStatus === "-"
          ? approvalStatus
          : t(`reviewState.${approvalStatus}`);
      },
      enumValues: Object.values(RelativeDrawReviewState).map((status) =>
        t(`reviewState.${status}`)
      ),
      groupable: true,
      header: "Approval Status",
      id: "approvalStatus",
      category: "Approvals",
      sortOrder: [
        RelativeDrawReviewState.PENDING_YOUR_APPROVAL,
        RelativeDrawReviewState.PENDING_OTHER_APPROVAL,
        RelativeDrawReviewState.APPROVED,
        RelativeDrawReviewState.NO_REVIEWERS,
        undefined,
      ],
      value: (project) =>
        t(`reviewState.${getApprovalStatus(project.recentDraw, userId)}`),
    },
    {
      ...listColumnDefaults,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) =>
          getReviewersForProject(project)
        ),
      header: "Reviewers",
      id: "approvers",
      category: "Approvals",
      value: (project) => getReviewersForProject(project),
      valueFormatter: (_value, project) => renderReviewsForProject(project),
      width: 300,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) =>
          getReviewersForProject(project, "isPreparer")
        ),
      header: "Preparer",
      id: "preparer",
      category: "Approvals",
      value: (project) => getReviewersForProject(project, "isPreparer"),
      valueFormatter: (_value, project) =>
        renderReviewsForProject(project, "isPreparer"),
      width: 300,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) =>
          getReviewersForProject(project, "isFinal")
        ),
      header: "Signatory",
      id: "signatory",
      category: "Approvals",
      value: (project) => getReviewersForProject(project, "isFinal"),
      valueFormatter: (_value, project) =>
        renderReviewsForProject(project, "isFinal"),
      width: 300,
    },
    {
      ...fractionColumnDefaults,
      aggregate: (groupProjects) =>
        groupProjects.reduce(
          (acc, project) => {
            const { numerator, denominator } = getApprovalsCount(
              project.recentDraw
            );
            return {
              numerator: add(acc.numerator, numerator),
              denominator: add(acc.denominator, denominator),
            };
          },
          { numerator: 0, denominator: 0 }
        ),
      disableAggregateExport: true,
      header: "Approvals",
      id: "approvals",
      category: "Approvals",
      value: (project) => getApprovalsCount(project.recentDraw),
      valueExporter: (value) => {
        const { numerator, denominator } = value;
        return `${numerator} / ${denominator}`;
      },
    },
    {
      ...listColumnDefaults,
      aggregate: (groupProjects) =>
        getDefaultAggregate(groupProjects, (project) =>
          project.shouldRedactFundingSources
            ? []
            : getFundingSourcesByLabel(project)
        ),
      header: "Funding Sources",
      hidden: !canAccessFundingSources,
      id: "fundingSources",
      category: "Funding Sources",
      value: (project) =>
        project.shouldRedactFundingSources
          ? ""
          : getFundingSourcesByLabel(project),
    },
    {
      ...listColumnDefaults,
      aggregate: (groupProjects) => {
        const withoutRedactedGroupProjects = reject(
          groupProjects,
          ({ shouldRedactFundingSources, shouldRedactStakeholders }) =>
            shouldRedactFundingSources || shouldRedactStakeholders
        );
        return getDefaultAggregate(withoutRedactedGroupProjects, getLenders);
      },
      groupable: true,
      header: "Lenders",
      hidden: !canAccessFundingSources || !canAccessStakeholders,
      id: "lendersList",
      category: "Funding Sources",
      value: (project) =>
        project.shouldRedactFundingSources || project.shouldRedactStakeholders
          ? ""
          : getLenders(project),
      valueFormatter: (orgList, project, options) =>
        project.shouldRedactFundingSources || project.shouldRedactStakeholders
          ? ""
          : renderOrganizationList(orgList, project, options),
    },
    {
      ...listColumnDefaults,
      aggregate: (groupProjects) => {
        const withoutRedactedGroupProjects = reject(
          groupProjects,
          ({ shouldRedactFundingSources, shouldRedactStakeholders }) =>
            shouldRedactFundingSources || shouldRedactStakeholders
        );
        return getDefaultAggregate(
          withoutRedactedGroupProjects,
          getEquityPartners
        );
      },
      groupable: true,
      header: "Equity Partners",
      hidden: !canAccessFundingSources || !canAccessStakeholders,
      id: "equityPartners",
      category: "Funding Sources",
      value: (project) =>
        project.shouldRedactFundingSources || project.shouldRedactStakeholders
          ? ""
          : getEquityPartners(project),
      valueFormatter: (orgList, project, options) =>
        project.shouldRedactFundingSources || project.shouldRedactStakeholders
          ? ""
          : renderOrganizationList(orgList, project, options),
    },
    {
      ...listColumnDefaults,
      aggregate: (groupProjects) => {
        const withoutRedactedGroupProjects = reject(
          groupProjects,
          ({ shouldRedactFundingSources, shouldRedactStakeholders }) =>
            shouldRedactStakeholders || shouldRedactFundingSources
        );
        return getDefaultAggregate(
          withoutRedactedGroupProjects,
          getStakeholderOrgsByType,
          ORGANIZATION_TYPE.BORROWER,
          true
        );
      },
      groupable: true,
      header: "Borrowers",
      hidden: !canAccessFundingSources || !canAccessStakeholders,
      id: "borrowers",
      category: "Funding Sources",
      value: (project) =>
        project.shouldRedactFundingSources || project.shouldRedactStakeholders
          ? ""
          : getStakeholderOrgsByType(project, ORGANIZATION_TYPE.BORROWER, true),
      valueFormatter: (orgList, project, options) =>
        project.shouldRedactFundingSources || project.shouldRedactStakeholders
          ? ""
          : renderOrganizationList(orgList, project, options),
    },
    {
      ...listColumnDefaults,
      aggregate: (groupProjects) => {
        const withoutRedactedGroupProjects = reject(
          groupProjects,
          ({ shouldRedactStakeholders }) => shouldRedactStakeholders
        );
        return getDefaultAggregate(
          withoutRedactedGroupProjects,
          getInspectionCompanies
        );
      },
      groupable: true,
      header: "Inspection Companies",
      hidden: !canAccessStakeholders,
      id: "inspectionCompany",
      category: "Project Stakeholders",
      value: (project) =>
        project.shouldRedactStakeholders ? "" : getInspectionCompanies(project),
      valueFormatter: (orgList, project, options) =>
        project.shouldRedactStakeholders
          ? ""
          : renderOrganizationList(orgList, project, options),
    },
    {
      ...dateColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspection Date",
        hasInspectionReportWorkflow
      ),
      id: "inspectionDate",
      category: "Recent Inspection",
      value: (project) => project?.documentInspectionReport?.inspectionDate,
      width: 110,
    },
    {
      ...dateColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspection Expected Completion Date",
        hasInspectionReportWorkflow
      ),
      id: "inspectionExpectedCompletionDate",
      category: "Recent Inspection",
      value: (project) =>
        project?.documentInspectionReport?.expectedCompletionDate,
      width: 130,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupProjects) =>
        getDefaultAggregate(
          groupProjects,
          "documentInspectionReport.inspectorName"
        ),
      header: getDocumentInspectionReportHeader(
        "Inspector Name",
        hasInspectionReportWorkflow
      ),
      id: "inspectorName",
      category: "Recent Inspection",
      value: (project) => project?.documentInspectionReport?.inspectorName,
    },
    {
      ...stringColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspector Notes",
        hasInspectionReportWorkflow
      ),
      id: "inspectorNotes",
      category: "Recent Inspection",
      value: (project) => project?.documentInspectionReport?.inspectorNotes,
      valueFormatter: (value) => (
        <Shortener text={value} limit={80} size={300} />
      ),
      width: 200,
    },
    {
      ...percentColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspection Percent Complete",
        hasInspectionReportWorkflow
      ),
      id: "inspectionPercentComplete",
      category: "Recent Inspection",
      value: (project) => project?.documentInspectionReport?.percentComplete,
      width: 147,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupProjects) =>
        getDefaultAggregate(
          groupProjects,
          "latestDrawInspectdocumentIonReport.inspectorName"
        ),
      header: "Inspector Name (Report)",
      hidden: !hasInspectionReportWorkflow,
      id: "inspectorNameReport",
      category: "Recent Inspection",
      value: (project) => project?.latestDrawInspectionReport?.inspectorName,
    },
    {
      ...dateColumnDefaults,
      header: "Inspection Requested Date (Report)",
      hidden: !hasInspectionReportWorkflow,
      id: "inspectionRequestedDateReport",
      category: "Recent Inspection",
      value: (project) => {
        const requested = project?.latestDrawInspectionReport?.insertedAt;
        return requested ? `${requested}Z` : null;
      },
      width: 140,
    },
    {
      ...dateColumnDefaults,
      header: "Inspection Date (Report)",
      hidden: !hasInspectionReportWorkflow,
      id: "inspectionDateReport",
      category: "Recent Inspection",
      value: (project) => project?.latestDrawInspectionReport?.date,
    },
    {
      ...dateColumnDefaults,
      header: "Inspection Submitted Date (Report)",
      hidden: !hasInspectionReportWorkflow,
      id: "inspectionSubmittedDateReport",
      category: "Recent Inspection",
      value: (project) => {
        const submitted = project?.latestDrawInspectionReport?.submittedAt;
        return submitted ? `${submitted}Z` : null;
      },
      width: 135,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(
          groupProjects,
          (project) => project?.fundingSourceAggregates?.totalFundedAmount
        ),
      header: "Total Funding",
      hidden: !canAccessFundingSources,
      id: "totalFunding",
      category: "Funding Sources",
      tooltip: "Sum of all funding sources",
      value: (project) => project?.fundingSourceAggregates?.totalFundedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(
          groupProjects,
          (project) => project?.fundingSourceAggregates?.totalDisbursedAmount
        ),
      header: "Total Funded To Date",
      hidden: !canAccessFundingSources,
      id: "totalFundedToDate",
      category: "Funding Sources",
      tooltip: "Total amount funded from all funding sources",
      value: (project) =>
        project?.fundingSourceAggregates?.totalDisbursedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(
          groupProjects,
          (project) => project?.commitmentAggregates?.totalFundedAmount
        ),
      header: "Commitments",
      hidden: !canAccessFundingSources,
      id: "commitments",
      category: "Funding Sources",
      tooltip: "Sum of your organization's funding commitments",
      value: (project) => project?.commitmentAggregates?.totalFundedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupProjects) =>
        sumBy(
          groupProjects,
          (project) => project?.commitmentAggregates?.totalDisbursedAmount
        ),
      header: "Commitments Funded To Date",
      hidden: !canAccessFundingSources,
      id: "commitmentsFunded",
      category: "Funding Sources",
      tooltip:
        "Total amount funded from your organization's funding commitments",
      value: (project) => project?.commitmentAggregates?.totalDisbursedAmount,
    },
  ]
    .concat(
      orderedLineItemCategories
        .map((category) => {
          const titleCaseCategory = t(`lineItemTypes.${category}`);
          const camelCaseCategory = camelCase(category);

          return [
            {
              ...currencyColumnDefaults,
              aggregate: (groupProjects) => {
                const calculableProjects = groupProjects.filter(
                  ({ acres, lineItems }) =>
                    acres &&
                    lineItems.some(({ types }) => types.includes(category))
                );

                const lineItems = calculableProjects
                  .flatMap((project) => project.lineItems)
                  .filter(({ types }) => types.includes(category));

                return divide(
                  sumBy(lineItems, "budgetAmount"),
                  sumBy(calculableProjects, "acres")
                );
              },
              category: "Costs",
              id: `${camelCaseCategory}CostPerAcre`,
              groupable: false,
              header: `${titleCaseCategory} Cost Per Acre`,
              value: (project) =>
                getCostByCategoryAndUnit(project, category, "acres"),
            },
            {
              ...currencyColumnDefaults,
              aggregate: (groupProjects) => {
                const calculableProjects = groupProjects.filter(
                  ({ lineItems, squareFeet }) =>
                    squareFeet &&
                    lineItems.some(({ types }) => types.includes(category))
                );

                const lineItems = calculableProjects
                  .flatMap((project) => project.lineItems)
                  .filter(({ types }) => types.includes(category));

                return divide(
                  sumBy(lineItems, "budgetAmount"),
                  sumBy(calculableProjects, "squareFeet")
                );
              },
              category: "Costs",
              id: `${camelCaseCategory}CostPerSqFt`,
              groupable: false,
              header: `${titleCaseCategory} Cost Per Sq Ft`,
              value: (project) =>
                getCostByCategoryAndUnit(project, category, "squareFeet"),
            },
          ];
        })
        .flat()
    )
    .concat(
      Object.values(ORGANIZATION_TYPE)
        .filter(
          (organizationType) => organizationType in STAKEHOLDER_COLUMN_DATA
        )
        .map((organizationType) => ({
          ...listColumnDefaults,
          aggregate: (groupProjects) => {
            const withoutRedactedGroupProjects = reject(
              groupProjects,
              ({ shouldRedactStakeholders }) => shouldRedactStakeholders
            );
            return getDefaultAggregate(
              withoutRedactedGroupProjects,
              getStakeholderOrgsByType,
              organizationType,
              true
            );
          },
          groupable: true,
          header: STAKEHOLDER_COLUMN_DATA[organizationType].header,
          hidden: !canAccessStakeholders,
          id: STAKEHOLDER_COLUMN_DATA[organizationType].id,
          category: "Project Stakeholders",
          value: (project) =>
            project.shouldRedactStakeholders
              ? ""
              : getStakeholderOrgsByType(project, organizationType, true),
          valueFormatter: (orgList, project, options) =>
            project.shouldRedactStakeholders
              ? ""
              : renderOrganizationList(orgList, project, options),
        }))
    )
    .concat(
      Object.values(FUNDING_SOURCE_TYPE)
        .map((fundingSourceType) => {
          const titleCaseType = t(`fundingSourceType.${fundingSourceType}`);
          const lowerCaseType = titleCaseType.toLowerCase();
          const camelCaseType = camelCase(titleCaseType);
          const startCaseType = camelCaseType[0]
            .toUpperCase()
            .concat(camelCaseType.slice(1));

          return [
            {
              ...currencyColumnDefaults,
              aggregate: (groupProjects) =>
                sumBy(groupProjects, (project) => {
                  get(
                    project,
                    `fundingSourceAggregates.${fundingSourceType}.total`
                  );
                }),
              header: `Total ${titleCaseType}`,
              hidden: !canAccessFundingSources,
              // although having nothing to do with Commitments, the legacy id for this column contains "committed"
              id: `${camelCaseType}Committed`,
              category: "Funding Sources",
              tooltip: `Sum of all ${lowerCaseType} funding sources`,
              value: (project) =>
                get(
                  project,
                  `fundingSourceAggregates.${fundingSourceType}.total`
                ),
            },
            {
              ...percentColumnDefaults,
              aggregate: (groupProjects) => {
                const withoutRedactedGroupProjects = reject(
                  groupProjects,
                  (project) => project.shouldRedactFundingSources
                );
                const {
                  [fundingSourceType]: { total: fundingForType },
                  totalFundedAmount,
                } = getFundingSourceAggregatesForGroupedProjects(
                  withoutRedactedGroupProjects
                );
                return totalFundedAmount === 0
                  ? 0
                  : divide(fundingForType, totalFundedAmount);
              },
              header: `Total ${titleCaseType} %`,
              hidden: !canAccessFundingSources,
              // although having nothing to do with Commitments, the legacy id for this column contains "committed"
              id: `percent${startCaseType}Committed`,
              category: "Funding Sources",
              tooltip: `Percent of funding that is ${lowerCaseType}`,
              value: (project) => {
                if (project.shouldRedactFundingSources) return null;
                const {
                  [fundingSourceType]: { total: fundingForType },
                  totalFundedAmount,
                } = project.fundingSourceAggregates;

                return totalFundedAmount === 0
                  ? 0
                  : divide(fundingForType, totalFundedAmount);
              },
              width: 120,
            },
            {
              ...currencyColumnDefaults,
              aggregate: (groupProjects) =>
                sumBy(groupProjects, (project) =>
                  get(
                    project,
                    `fundingSourceAggregates.${fundingSourceType}.disbursed`
                  )
                ),
              header: `Total ${titleCaseType} Funded To Date`,
              hidden: !canAccessFundingSources,
              id: `${camelCaseType}Funded`,
              category: "Funding Sources",
              tooltip: `Total amount of ${lowerCaseType} that has been funded`,
              value: (project) =>
                get(
                  project,
                  `fundingSourceAggregates.${fundingSourceType}.disbursed`
                ),
              width: 130,
            },
            {
              ...currencyColumnDefaults,
              aggregate: (groupProjects) => {
                const withoutRedactedGroupProjects = reject(
                  groupProjects,
                  (project) => project.shouldRedactFundingSources
                );
                const {
                  [fundingSourceType]: {
                    total: fundingForType,
                    disbursed: disbursedForType,
                  },
                } = getFundingSourceAggregatesForGroupedProjects(
                  withoutRedactedGroupProjects
                );

                return subtract(fundingForType, disbursedForType);
              },
              header: `Total ${titleCaseType} Remaining`,
              hidden: !canAccessFundingSources,
              id: `${camelCaseType}Remaining`,
              category: "Funding Sources",
              tooltip: `Total amount of ${lowerCaseType} that remains unfunded`,
              value: (project) => {
                if (project.shouldRedactFundingSources) return null;
                const {
                  [fundingSourceType]: {
                    total: fundingForType,
                    disbursed: disbursedForType,
                  },
                } = project.fundingSourceAggregates;
                return subtract(fundingForType, disbursedForType);
              },
              width: 135,
            },
            {
              ...percentColumnDefaults,
              aggregate: (groupProjects) => {
                const withoutRedactedGroupProjects = reject(
                  groupProjects,
                  (project) => project.shouldRedactFundingSources
                );
                const {
                  [fundingSourceType]: {
                    total: fundingForType,
                    disbursed: disbursedForType,
                  },
                } = getFundingSourceAggregatesForGroupedProjects(
                  withoutRedactedGroupProjects
                );

                return fundingForType === 0
                  ? 0
                  : divide(disbursedForType, fundingForType);
              },
              header: `Total ${titleCaseType} % Funded`,
              hidden: !canAccessFundingSources,
              id: `${camelCaseType}PercentFunded`,
              category: "Funding Sources",
              tooltip: `Percent of total ${lowerCaseType} that has been funded`,
              value: (project) => {
                if (project.shouldRedactFundingSources) return null;
                const {
                  [fundingSourceType]: {
                    total: fundingForType,
                    disbursed: disbursedForType,
                  },
                } = project.fundingSourceAggregates;

                return fundingForType === 0
                  ? 0
                  : divide(disbursedForType, fundingForType);
              },
              width: 130,
            },
            {
              ...currencyColumnDefaults,
              aggregate: (groupProjects) =>
                sumBy(groupProjects, (project) =>
                  get(
                    project,
                    `commitmentAggregates.${fundingSourceType}.total`
                  )
                ),
              header: `${titleCaseType} Commitment`,
              hidden: !canAccessFundingSources,
              id: `${camelCaseType}Commitment`,
              category: "Funding Sources",
              tooltip: `Sum of your organization's ${lowerCaseType} commitments`,
              value: (project) =>
                get(project, `commitmentAggregates.${fundingSourceType}.total`),
              width: 115,
            },
            {
              ...currencyColumnDefaults,
              aggregate: (groupProjects) =>
                sumBy(groupProjects, (project) =>
                  get(
                    project,
                    `commitmentAggregates.${fundingSourceType}.disbursed`
                  )
                ),
              header: `${titleCaseType} Commitment Funded To Date`,
              hidden: !canAccessFundingSources,
              id: `${camelCaseType}CommitmentFunded`,
              category: "Funding Sources",
              tooltip: `Amount of ${lowerCaseType} that has already been funded by your organization`,
              value: (project) =>
                get(
                  project,
                  `commitmentAggregates.${fundingSourceType}.disbursed`
                ),
              width: 150,
            },
            {
              ...currencyColumnDefaults,
              aggregate: (groupProjects) =>
                sumBy(groupProjects, (project) => {
                  if (project.shouldRedactFundingSources) return null;
                  const {
                    [fundingSourceType]: {
                      total: commitmentForType,
                      disbursed: disbursedCommitmentForType,
                    },
                  } = project.commitmentAggregates;
                  return subtract(
                    commitmentForType,
                    disbursedCommitmentForType
                  );
                }),
              header: `${titleCaseType} Commitment Remaining`,
              hidden: !canAccessFundingSources,
              id: `${camelCaseType}CommitmentRemaining`,
              category: "Funding Sources",
              tooltip: `Amount of ${lowerCaseType} your organization has committed to that remains unfunded`,
              value: (project) => {
                if (project.shouldRedactFundingSources) return null;
                const {
                  [fundingSourceType]: {
                    total: commitmentForType,
                    disbursed: disbursedCommitmentForType,
                  },
                } = project.commitmentAggregates;
                return subtract(commitmentForType, disbursedCommitmentForType);
              },
              width: 150,
            },
            {
              ...percentColumnDefaults,
              aggregate: (groupProjects) => {
                const withoutRedactedGroupProjects = reject(
                  groupProjects,
                  (project) => project.shouldRedactFundingSources
                );
                const {
                  [fundingSourceType]: {
                    total: commitmentForType,
                    disbursed: disbursedCommitmentForType,
                  },
                } = getCommitmentAggregatesForGroupedProjects(
                  withoutRedactedGroupProjects
                );

                return commitmentForType === 0
                  ? 0
                  : divide(disbursedCommitmentForType, commitmentForType);
              },
              header: `${titleCaseType} Commitment % Funded`,
              hidden: !canAccessFundingSources,
              id: `${camelCaseType}CommitmentPercentFunded`,
              category: "Funding Sources",
              tooltip: `Percent of your organization's ${lowerCaseType} commitments that have been funded`,
              value: (project) => {
                if (project.shouldRedactFundingSources) return null;
                const {
                  [fundingSourceType]: {
                    total: commitmentForType,
                    disbursed: disbursedCommitmentForType,
                  },
                } = project.commitmentAggregates;

                return commitmentForType === 0
                  ? 0
                  : divide(disbursedCommitmentForType, commitmentForType);
              },
              width: 130,
            },
          ];
        })
        .flat()
    );
}
