import { useRef, useEffect, useContext } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import WebViewer from "@pdftron/pdfjs-express";
import ExpressUtils from "@pdftron/pdfjs-express-utils";
import isBlank from "helpers/isBlank";
import { toaster } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";

const clientKey = process.env.REACT_APP_PDF_EXPRESS_CLIENT_KEY;
const licenseKey = process.env.REACT_APP_PDF_EXPRESS_LICENSE_KEY;

const SAVE_BUTTON_IMG =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>';

const SAVE_ANNOTATIONS = gql`
  mutation SaveAnnotations(
    $documentId: String!
    $userId: String!
    $annotations: String
    $annotatedFile: Upload
    $signatures: String
  ) {
    saveAnnotations(
      documentId: $documentId
      userId: $userId
      annotations: $annotations
      annotatedFile: $annotatedFile
      signatures: $signatures
    ) {
      id
      file {
        url
        name
        type
        annotations
      }
    }
  }
`;

const DOCUMENT_FILE_QUERY = gql`
  query AddDocumentsStep($documentId: String!) {
    document(id: $documentId) {
      id
      file {
        url
        name
        type
        annotations
      }
    }
  }
`;

export function PDFEditor({ file, height, minHeight, annotationProps }) {
  const { documentId, fetchFile } = annotationProps;

  // 'fetchFile' was added for the Draw Package context, to address a weird bug:
  // previously, if an edit was made to the pdf, the viewer was closed, and then the same document was opened again
  // any edits made in that subsequent view would overwrite the previous edits
  // the new file was being saved correctly, and was being returned by the mutation, but was not being pulled in the next time the file was opened
  // attempted solutions included keeping mutation results in state, refetching the full SendDrawContext query, and reading directly from the cache
  // the solution that worked -> we fetch the file from the database fresh whenever a file is opened
  const { data, loading } = useQuery(DOCUMENT_FILE_QUERY, {
    skip: !fetchFile,
    variables: { documentId },
  });

  if (!fetchFile) {
    return (
      <PDFEditorInner
        file={file}
        height={height}
        minHeight={minHeight}
        annotationProps={annotationProps}
      />
    );
  }

  if (loading || !data) return null;

  return (
    <PDFEditorInner
      file={data.document.file}
      height={height}
      minHeight={minHeight}
      annotationProps={annotationProps}
    />
  );
}

function PDFEditorInner({ file, height, minHeight, annotationProps }) {
  const { user, refetchUserContextQuery } = useContext(UserContext);
  const { id: userId } = user;
  const { documentId, dispatch, onSaveComplete } = annotationProps;

  const [saveAnnotations] = useMutation(SAVE_ANNOTATIONS, {
    onCompleted: () => {
      if (onSaveComplete) {
        onSaveComplete();
      }
      toaster.success("PDF edits have been saved");
    },
    onError: () => {
      toaster.danger("Error saving PDF edits");
    },
  });

  function handleSaveAnnotations({ annotations, annotatedFile, signatures }) {
    saveAnnotations({
      variables: { documentId, annotations, annotatedFile, userId, signatures },
    });
  }

  const utils = new ExpressUtils({ clientKey });

  const style = {
    display: "block",
    height: height || "100%",
    minHeight: minHeight || 500,
    width: "100%",
  };

  const viewer = useRef(null);

  useEffect(() => {
    WebViewer(
      {
        licenseKey,
        path: "/WebViewer/lib",
        initialDoc: file.url,
        filename: file.name,
        extension: "pdf",
        disabledElements: [
          "toolbarGroup-Insert",
          "toolbarGroup-Shapes",
          "toolbarGroup-FillAndSign",
          "highlightToolGroupButton",
          "underlineToolGroupButton",
          "strikeoutToolGroupButton",
          "squigglyToolGroupButton",
          "stickyToolGroupButton",
          "shapeToolGroupButton",
          "freeHandToolGroupButton",
          "freeHandHighlightToolGroupButton",
          "eraserToolButton",
        ],
        annotationUser: user.fullName,
        disableFlattenedAnnotations: true,
      },
      viewer.current
    ).then((instance) => {
      const { documentViewer, annotationManager } = instance.Core;

      instance.UI.setToolbarGroup("toolbarGroup-View");

      instance.UI.setHeaderItems((header) => {
        const saveButton = {
          type: "actionButton",
          img: SAVE_BUTTON_IMG,
          onClick: async () => {
            try {
              toaster.notify("Saving PDF edits. This could take a moment.");
              const signaturesData = await documentViewer
                .getTool("AnnotationCreateSignature")
                .exportSignatures();
              const signatures = JSON.stringify(signaturesData);
              const annotations = await annotationManager.exportAnnotations();

              const fileData = await documentViewer
                .getDocument()
                .getFileData({});
              const fileBlob = new Blob([fileData], {
                type: "application/pdf",
              });

              // Note: there is a 5.5MB limit for in-memory file size to pass to setFile https://pdfjs.express/api/utils/ExpressUtils.html#setFile
              utils.setFile(fileBlob).setXFDF(annotations);
              const response = await utils.set();
              const annotatedBlob = await response.getBlob();
              // delete the file from the pdfjsexpress server https://pdfjs.express/api/utils/Response.html#deleteFile
              await response.deleteFile();

              const annotatedFile = new File(
                [annotatedBlob],
                `${file.name} - Updated`,
                { type: "application/pdf" }
              );

              handleSaveAnnotations({ annotations, annotatedFile, signatures });
            } catch (error) {
              toaster.danger(error?.message || "Something went wrong");
            }
          },
        };

        header.push(saveButton);
      });

      instance.UI.setHeaderItems((header) => {
        header.getHeader("toolbarGroup-Annotate").push({
          dataElement: "signatureToolGroupButton",
          img: "icon-tool-signature",
          showColor: "never",
          title: "annotation.signature",
          toolGroup: "signatureTools",
          type: "toolGroupButton",
        });
      });

      instance.UI.setHeaderItems((header) => {
        const annotateItems = header
          .getHeader("toolbarGroup-Annotate")
          .getItems();
        const withoutSpacer = annotateItems.filter(
          (item) => item.type !== "spacer"
        );
        header.update(withoutSpacer);
      });

      documentViewer.addEventListener("documentLoaded", async () => {
        if (!isBlank(file.annotations)) {
          await annotationManager.importAnnotations(file.annotations);
        }

        const { data } = await refetchUserContextQuery();
        const { signatures: userSignatures } = data.userProfiles.find(
          (profile) => profile.id === userId
        );
        if (!isBlank(userSignatures)) {
          const signatureTool = documentViewer.getTool(
            "AnnotationCreateSignature"
          );
          const parsedSignatures = JSON.parse(userSignatures);
          await signatureTool.importSignatures(parsedSignatures);
        }
      });

      annotationManager.addEventListener("annotationChanged", async () => {
        if (dispatch) {
          const xfdfString = await annotationManager.exportAnnotations();
          dispatch({
            action: "ANNOTATIONS_UPDATED",
            annotations: xfdfString,
          });
        }
      });

      documentViewer.addEventListener("annotationsLoaded", async () => {
        if (dispatch) {
          const xfdfString = await annotationManager.exportAnnotations();
          dispatch({
            action: "ANNOTATIONS_LOADED",
            annotations: xfdfString,
          });
        }
      });

      documentViewer.addEventListener("documentUnloaded", async () => {
        if (dispatch) {
          dispatch({
            action: "ANNOTATIONS_RESET",
          });
        }
      });
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App" style={style}>
      <div className="webviewer" ref={viewer} style={style} />
    </div>
  );
}
