import { createContext, useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { get, isEmpty, reject } from "lodash";
import { isApplied } from "helpers/documentHelpers";
import { PERMISSION_ACTION, VENDOR_PAYMENT_TYPE } from "helpers/enums";
import { add, subtract } from "helpers/math";
import { UserContext } from "helpers/behaviors";
import {
  QUERY,
  SAVE_VENDOR_PAYMENT_TYPES,
  RESET_POSTED_DOCUMENTS_MUTATION,
} from "./graphql";

function handleSaveVendorPaymentTypes(
  payments,
  documents,
  projectId,
  savePaymentTypes
) {
  const paymentsArray = Object.entries(payments);
  const paymentTypes = reject(
    paymentsArray,
    ([id]) => documents.find((doc) => doc.id === id).postedAt
  ).map(([documentId, vendorPaymentType]) => {
    return {
      documentId,
      vendorPaymentType,
    };
  });
  savePaymentTypes({ variables: { projectId, paymentTypes } });
}

function getToBePosted(paymentDueAmount, postedAmount, isPosted) {
  return isPosted ? 0 : subtract(paymentDueAmount, postedAmount);
}

function getVendorPaymentType({
  hasBeenSubcontractorDirectPay,
  hasSoftCosts,
  isBackup,
  isGeneralContractor,
  previousVendorPaymentType,
  vendorPaymentType,
}) {
  if (vendorPaymentType) return vendorPaymentType;
  if (previousVendorPaymentType) return previousVendorPaymentType;
  if (isGeneralContractor) return VENDOR_PAYMENT_TYPE.GENERAL_CONTRACTOR;
  if (isBackup) return VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_REIMBURSABLE;
  if (hasSoftCosts) return VENDOR_PAYMENT_TYPE.SOFT_COSTS_DIRECT_PAY;
  if (hasBeenSubcontractorDirectPay)
    return VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_DIRECT_PAY;
  return VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_REIMBURSABLE;
}

function getPaymentDueAmount(
  documentAmount,
  documentVendorPaymentType,
  documents,
  payments
) {
  if (
    documentVendorPaymentType === VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_REIMBURSABLE
  ) {
    return 0;
  }

  if (documentVendorPaymentType === VENDOR_PAYMENT_TYPE.GENERAL_CONTRACTOR) {
    const directPayAmount = Object.entries(payments).reduce(
      (totalAmount, [id, paymentType]) => {
        if (getIsGcDirectPay(paymentType)) {
          return add(
            totalAmount,
            documents.find((document) => document.id === id).amount
          );
        }
        return totalAmount;
      },
      0
    );

    return subtract(documentAmount, directPayAmount);
  }
  return documentAmount;
}

function handleReset(resetPostedDocuments, documentsToReset) {
  const documentIds = documentsToReset.reduce(
    (documentIdsToReset, { id, postedAt }) => {
      if (postedAt) return [...documentIdsToReset, id];
      return documentIdsToReset;
    },
    []
  );

  resetPostedDocuments({ variables: { documentIds } });
}

function calculatePaymentAmounts(documents, document, payments) {
  const vendorPaymentType = payments[document.id];
  const paymentDueAmount = getPaymentDueAmount(
    document.amount,
    vendorPaymentType,
    documents,
    payments
  );
  const toBePosted = getToBePosted(
    paymentDueAmount,
    document.postedAmount,
    !!document.postedAt
  );

  const paymentAmount = document.postedAt ? document.postedAmount : toBePosted;

  return {
    ...document,
    vendorPaymentType,
    paymentDueAmount,
    toBePosted,
    paymentAmount,
  };
}
function getPayApplications(queryData) {
  return get(queryData, "project.draw.payApplications", []).map(
    (payApplication) => {
      return { ...payApplication, number: payApplication.applicationNumber };
    }
  );
}

function filterValidPaymentDocuments(documents) {
  return documents.filter(
    (document) =>
      isApplied(document) && document.vendor.id && !document.upload.toBeSplit
  );
}

const gcDirectPayTypes = [
  VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_DIRECT_PAY,
  VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_DIRECT_PAY_SEND_TO_GC,
];

function getCanDirectPayVendor(document) {
  return (
    document.vendor.isPlaceholderVendor ||
    (document.vendor.isPayable && document.vendor.vendorCostCode)
  );
}

function getDocumentHasVendorId(document) {
  return !!document.vendor.vendorCostCode;
}

function getIsGcDirectPay(vendorPaymentType) {
  return gcDirectPayTypes.indexOf(vendorPaymentType) > -1;
}

function getIsPayableType(vendorPaymentType) {
  return (
    [
      ...gcDirectPayTypes,
      VENDOR_PAYMENT_TYPE.SOFT_COSTS_DIRECT_PAY,
      VENDOR_PAYMENT_TYPE.GENERAL_CONTRACTOR,
    ].indexOf(vendorPaymentType) > -1
  );
}

function getProjectHasCustomId(project) {
  return !!project.customId;
}

function getProjectHasGlCode(project) {
  return !!project.glCode;
}

function getDocumentHasLineItemWithJCC(document) {
  return (
    document.lineItems.length > 0 &&
    document.lineItems.some(({ jobCostCodes }) => !isEmpty(jobCostCodes))
  );
}

function getHasLineItemMissingJobCode(project, document) {
  if (!document.lineItems) return false;
  const hasJobCode = !!project.jobCode || !!project.jobPhaseCode;
  return getDocumentHasLineItemWithJCC(document) && !hasJobCode;
}

function getDocumentHasExternalVendorId(project, document) {
  const { externalVendorMappings } = document.vendor;
  const matchingExternalVendorMapping = externalVendorMappings.filter(
    (mapping) => mapping.externalProjectId === project.customId
  );
  if (matchingExternalVendorMapping.length === 0) return false;
  const externalMapping = matchingExternalVendorMapping[0];
  if (
    externalMapping.externalVendorId === null ||
    externalMapping.externalVendorId === ""
  )
    return false;
  return true;
}

function getAllLineItemJobCostCodesHaveCodes(jobCostCodes) {
  return (
    jobCostCodes.length > 0 &&
    jobCostCodes.every((jobCostCode) => jobCostCode.code)
  );
}

function getAllLineItemsHaveJobCostCodesAndLineItemNumbers(document) {
  if (!document.lineItems) return false;
  return document.lineItems.every(
    (lineItem) =>
      lineItem.budgetLineItem.number &&
      getAllLineItemJobCostCodesHaveCodes(lineItem.jobCostCodes)
  );
}

function getLineItemsMissingJobCostCode(document) {
  if (!document.lineItems) return false;
  return !document.lineItems.every(
    ({ jobCostCodes }) => !isEmpty(jobCostCodes)
  );
}

function getJobCostCodesAllHaveGlAccount(document) {
  if (!document.jobCostCodes) return false;
  return document.jobCostCodes.every(({ glCode }) => glCode);
}

function getDocuments(data) {
  const {
    payments,
    queryData,
    getWarningTooltip,
    getRowState,
    getCanBePosted,
    hasYardiJobCostModule,
  } = data;

  const documents = filterValidPaymentDocuments(
    getPayApplications(queryData).concat(
      get(queryData, "project.draw.invoices", [])
    )
  );

  const { project } = queryData;

  const initialDocuments = documents.map((document) => ({
    ...document,
    vendorPaymentType: getVendorPaymentType(document),
  }));

  if (Object.keys(data.payments).length === 0) return initialDocuments;

  return initialDocuments.map((document) => {
    getAllLineItemsHaveJobCostCodesAndLineItemNumbers(document);
    const formVendorPaymentType = payments[document.id];
    const cannotDirectPayVendor =
      getIsPayableType(formVendorPaymentType) &&
      !getCanDirectPayVendor(document) &&
      !document.postedAt;

    const isGCWithEditedDirectPays =
      document.postedAt &&
      document.vendorPaymentType === VENDOR_PAYMENT_TYPE.GENERAL_CONTRACTOR &&
      documents.some(
        (document) =>
          document.updatedSincePosted &&
          getIsGcDirectPay(document.vendorPaymentType)
      );

    const warning = getWarningTooltip({
      isPayableType: getIsPayableType(document.vendorPaymentType),
      updatedSincePosted: document.updatedSincePosted,
      isGCWithEditedDirectPays,
      cannotDirectPayVendor,
      documentHasVendorId: getDocumentHasVendorId(document),
      projectHasCustomId: getProjectHasCustomId(project),
      projectHasGlCode: getProjectHasGlCode(project),
      hasLineItemMissingJobCode: getHasLineItemMissingJobCode(
        project,
        document
      ),
      hasLineItemMissingJobCostCode: getLineItemsMissingJobCostCode(document),
      allJobCostCodesHaveGlAccounts: getJobCostCodesAllHaveGlAccount(document),
      showMissingLineItemNumberOrJobCostCodeWarning:
        hasYardiJobCostModule &&
        !getAllLineItemsHaveJobCostCodesAndLineItemNumbers(document),
      documentHasExternalVendorId: getDocumentHasExternalVendorId(
        project,
        document
      ),
    });

    const rowState = getRowState({
      isPayableType: getIsPayableType(document.vendorPaymentType),
      updatedSincePosted: document.updatedSincePosted,
      isGCWithEditedDirectPays,
      cannotDirectPayVendor,
      documentHasVendorId: getDocumentHasVendorId(document),
      projectHasCustomId: getProjectHasCustomId(project),
      projectHasGlCode: getProjectHasGlCode(project),
      hasLineItemMissingJobCode: getHasLineItemMissingJobCode(
        project,
        document
      ),
      hasLineItemMissingJobCostCode: getLineItemsMissingJobCostCode(document),
      allJobCostCodesHaveGlAccounts: getJobCostCodesAllHaveGlAccount(document),
      showMissingLineItemNumberOrJobCostCodeWarning:
        hasYardiJobCostModule &&
        !getAllLineItemsHaveJobCostCodesAndLineItemNumbers(document),
      documentHasExternalVendorId: getDocumentHasExternalVendorId(
        project,
        document
      ),
    });

    const canBePosted = getCanBePosted({
      isPayableType: getIsPayableType(document.vendorPaymentType),
      isPlaceholderVendor: document.vendor.isPlaceholderVendor,
      documentHasVendorId: getDocumentHasVendorId(document),
      documentHasBeenPosted: document.postedAt,
      canDirectPayVendor: getCanDirectPayVendor(document),
      projectHasCustomId: getProjectHasCustomId(project),
      projectHasGlCode: getProjectHasGlCode(project),
      hasLineItemMissingJobCode: getHasLineItemMissingJobCode(
        project,
        document
      ),
      hasLineItemMissingJobCostCode: getLineItemsMissingJobCostCode(document),
      allJobCostCodesHaveGlAccounts: getJobCostCodesAllHaveGlAccount(document),
      showMissingLineItemNumberOrJobCostCodeWarning:
        hasYardiJobCostModule &&
        !getAllLineItemsHaveJobCostCodesAndLineItemNumbers(document),
      documentHasExternalVendorId: getDocumentHasExternalVendorId(
        project,
        document
      ),
    });

    return {
      ...document,
      ...calculatePaymentAmounts(documents, document, payments),
      warning,
      canBePosted,
      rowState,
    };
  });
}

export const PaymentsContext = createContext({});

export function PaymentsContextProvider({
  drawId,
  projectId,
  includeLineItems,
  includeJobCostCodes,
  getWarningTooltip,
  getRowState,
  getCanBePosted,
  children,
}) {
  const { data: queryData, loading: queryLoading, refetch } = useQuery(QUERY, {
    variables: { drawId, projectId, includeLineItems, includeJobCostCodes },
    skip: !drawId || !projectId,
  });

  const [resetPostedDocuments, resetPostedDocumentsResult] = useMutation(
    RESET_POSTED_DOCUMENTS_MUTATION
  );
  const [savePaymentTypes, savePaymentTypesResult] = useMutation(
    SAVE_VENDOR_PAYMENT_TYPES
  );
  const [documentsToReset, setDocumentsToReset] = useState([]);
  const [documentsToPost, setDocumentsToPost] = useState([]);
  const { hasPermission } = useContext(UserContext);
  const hasYardiJobCostModule = hasPermission(
    PERMISSION_ACTION.YARDI_JOB_COST_MODULE
  );

  function resetPayments() {
    handleReset(resetPostedDocuments, documentsToReset);
    setDocumentsToReset([]);
  }

  function saveVendorPaymentTypes(payments) {
    handleSaveVendorPaymentTypes(
      payments,
      getDocuments({
        queryData,
        payments,
        getWarningTooltip,
        getRowState,
        getCanBePosted,
        hasYardiJobCostModule,
      }),
      projectId,
      savePaymentTypes
    );
  }

  function onPaymentPosted() {
    refetch();
  }

  function onPaymentFailed() {
    refetch();
  }

  const contextBehavior = {
    getDocuments: (payments) =>
      getDocuments({
        queryData,
        payments,
        getWarningTooltip,
        getRowState,
        getCanBePosted,
        hasYardiJobCostModule,
      }),
    glCode: queryData?.project?.glCode,
    jobCode: queryData?.project?.jobCode,
    jobPhaseCode: queryData?.project?.jobPhaseCode,
    onPaymentFailed,
    onPaymentPosted,
    setDocumentsToPost,
    setDocumentsToReset,
  };

  const contextData = {
    documentsToPost,
    documentsToReset,
    recentPaymentNote: get(queryData, "project.recentPaymentNote"),
    documentReviewers: queryData?.project?.documentReviewers,
  };

  const contextMutations = {
    mutationLoading:
      savePaymentTypesResult.loading || resetPostedDocumentsResult.loading,
    resetPayments,
    saveVendorPaymentTypes,
  };

  const contextQueries = { queryLoading };

  return (
    <PaymentsContext.Provider
      value={{
        ...contextBehavior,
        ...contextData,
        ...contextMutations,
        ...contextQueries,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
}
