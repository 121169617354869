import { useContext } from "react";
import PropTypes from "prop-types";
import { uniq, map, values, keys, reduce } from "lodash";
import { WarningSignIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { Pane, Text } from "components/materials";
import t from "helpers/translate";

function warningsByType(warnings) {
  return reduce(
    values(warnings),
    (result, { name, type }) => {
      if (!result[type]) result[type] = [];
      result[type].push(name);
      return result;
    },
    {}
  );
}

function join(names) {
  if (names.length <= 1) return names[0];
  if (names.length === 2) return `${names[0]} and ${names[1]}`;
  return `${names.slice(0, -1).join(", ")}, and ${names[names.length - 1]}`;
}

function DocumentWarnings({ warnings }) {
  const theme = useContext(ThemeContext);

  if (!warnings || keys(warnings).length === 0) {
    return null;
  }

  return (
    <Pane position="sticky" top={96} zIndex={3}>
      {map(warningsByType(warnings), (names, type) => (
        <Pane
          paddingLeft={majorScale(2)}
          paddingBottom={minorScale(1)}
          paddingTop={minorScale(1)}
          backgroundColor={theme.colors.lightYellow}
          borderBottom={`1px solid ${theme.colors.gray200}`}
          borderTop={`1px solid ${theme.colors.gray200}`}
        >
          <WarningSignIcon
            color="warning"
            marginRight={majorScale(1)}
            marginBottom={-2}
          />
          <Text
            marginRight={minorScale(2)}
            fontWeight={theme.fontWeights.MEDIUM}
            size={400}
          >
            {t(`documentWarnings.title.${type}`)}
          </Text>
          <Text size={400}>
            {t(`documentWarnings.body.${type}`, {
              values: join(uniq(names)),
              count: uniq(names).length,
            })}
          </Text>
        </Pane>
      ))}
    </Pane>
  );
}

DocumentWarnings.propTypes = {
  warnings: PropTypes.object,
};

DocumentWarnings.defaultProps = {
  warnings: {},
};

// TODO: remove dot notation
DocumentWarnings.warningsByType = warningsByType;
DocumentWarnings.join = join;

export default DocumentWarnings;
