import { useContext } from "react";
import { css } from "glamor";
import { Link, Pane, Paragraph, Text } from "components/materials";
import { add, divide, subtract } from "helpers/math";
import { flatMap, max } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";

function formatLineItem(lineItem) {
  const adjustmentsToDate = add(
    lineItem.adjustmentsAmount,
    lineItem.previousAdjustmentsAmount
  );
  const fullName = `${lineItem.division.name} - ${lineItem.name}`;
  return {
    id: lineItem.id,
    adjustmentsToDate,
    absAdjustment: Math.abs(adjustmentsToDate),
    fullName,
  };
}

function prepareLineItems(lineItems) {
  const groupedAdjustments = lineItems.reduce(
    (acc, lineItem) => {
      const [positiveAdjustments, negativeAdjustments] = acc;
      const formattedLineItem = formatLineItem(lineItem);
      if (formattedLineItem.adjustmentsToDate === 0) return acc;
      if (formattedLineItem.adjustmentsToDate > 0)
        return [
          positiveAdjustments.concat(formattedLineItem),
          negativeAdjustments,
        ];
      return [
        positiveAdjustments,
        negativeAdjustments.concat(formattedLineItem),
      ];
    },
    [[], []]
  );

  const maxAdjustment = max(
    flatMap(groupedAdjustments, (adjustments) =>
      adjustments.map(({ absAdjustment }) => absAdjustment)
    )
  );

  return groupedAdjustments.map((adjustments) => {
    const adjustmentsWithProportions = adjustments.map((adjustment) => ({
      ...adjustment,
      progressBarProportion: divide(adjustment.absAdjustment, maxAdjustment),
    }));
    return adjustmentsWithProportions.sort((a, b) =>
      subtract(b.absAdjustment, a.absAdjustment)
    );
  });
}

export function BudgetAdjustments({ project }) {
  const [positiveAdjustments, negativeAdjustments] = prepareLineItems(
    project.lineItems
  );

  const theme = useContext(ThemeContext);

  const negativeCurrencyClassName = css({
    width: "120px",
    textAlign: "end",
    borderLeft: `1px solid ${theme.colors.gray500}`,
  });

  const positiveCurrencyClassName = css({
    width: "120px",
    textAlign: "start",
    borderRight: `1px solid ${theme.colors.gray500}`,
  });

  const negativeNameClassName = css({
    width: "240px",
    textAlign: "start",
  });

  const positiveNameClassName = css({
    width: "240px",
    textAlign: "end",
  });

  const nameOverflowProps = {
    size: 300,
    width: "240px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  const tableClassName = css({
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    "& tr": {
      borderBottom: `1px solid ${theme.colors.gray500}`,
    },
  });

  const hasNoAdjustments =
    positiveAdjustments.concat(negativeAdjustments).length === 0;

  if (hasNoAdjustments)
    return (
      <Pane paddingX={majorScale(4)}>
        <Text>
          There are no budget adjustments on this project. Adjustments to the
          budget can be made on a draw.
        </Text>
        <Link
          href="https://help.rabbet.com/en/articles/3351415-reallocate-funds-and-add-line-items-to-a-budget"
          purpose="adjustments help"
          marginLeft={minorScale(1)}
        >
          Learn More.
        </Link>
      </Pane>
    );

  return (
    <Pane
      display="flex"
      height="100%"
      overflowY="auto"
      paddingX={majorScale(4)}
      justifyContent="space-between"
    >
      <Pane flex={1} marginRight={majorScale(1)}>
        <table className={tableClassName}>
          <tbody>
            {negativeAdjustments.map(
              ({ id, adjustmentsToDate, fullName, progressBarProportion }) => (
                <tr key={id}>
                  <td className={negativeNameClassName}>
                    <Paragraph {...nameOverflowProps}>{fullName}</Paragraph>
                  </td>

                  <td aria-label="progress">
                    <Pane display="flex" width="100%" justifyContent="flex-end">
                      <Pane
                        width={`${100 * progressBarProportion}%`}
                        background="#A73636"
                        height={10}
                      />
                    </Pane>
                  </td>
                  <td className={negativeCurrencyClassName}>
                    <Text fontWeight={theme.fontWeights.DEMI}>
                      {formatCurrency(adjustmentsToDate)}
                    </Text>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </Pane>
      <Pane flex={1} marginLeft={majorScale(1)}>
        <table className={tableClassName}>
          <tbody>
            {positiveAdjustments.map(
              ({ id, adjustmentsToDate, fullName, progressBarProportion }) => (
                <tr key={id}>
                  <td className={positiveCurrencyClassName}>
                    <Text fontWeight={theme.fontWeights.DEMI}>
                      {formatCurrency(adjustmentsToDate)}
                    </Text>
                  </td>

                  <td aria-label="progress">
                    <Pane
                      display="flex"
                      width="100%"
                      justifyContent="flex-start"
                    >
                      <Pane
                        width={`${100 * progressBarProportion}%`}
                        background="#429777"
                        height={10}
                      />
                    </Pane>
                  </td>
                  <td className={positiveNameClassName}>
                    <Paragraph {...nameOverflowProps}>{fullName}</Paragraph>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </Pane>
    </Pane>
  );
}
