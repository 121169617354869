import { useContext } from "react";
import { InfoSignIcon } from "evergreen-ui";
import { Link, Pane, Text } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";

export default function GeneralContractorStakeholderPrompt({
  addGeneralContractor,
  form,
  projectId,
  projectName,
}) {
  const theme = useContext(ThemeContext);
  const { id: vendorId } = form.values.vendor;
  return vendorId ? (
    <Pane
      backgroundColor={theme.colors.lightBlue}
      marginTop={majorScale(2)}
      padding={minorScale(1)}
    >
      <InfoSignIcon
        color="#526070"
        marginLeft={minorScale(1)}
        paddingTop={minorScale(1)}
      />
      <Text fontWeight={theme.fontWeights.MEDIUM} marginLeft={majorScale(1)}>
        {t("generalContractorStakeholderPrompt.noGeneralContractor", {
          projectName,
        })}{" "}
      </Text>
      <Link
        onClick={() => {
          addGeneralContractor({
            variables: { projectId, vendorId },
          });
          form.handleSubmit();
        }}
        purpose="document-review gc-prompt add-gc"
        fontWeight={theme.fontWeights.MEDIUM}
        underline
      >
        {t("generalContractorStakeholderPrompt.setup")}
      </Link>
    </Pane>
  ) : null;
}
