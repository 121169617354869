import { Fragment, useContext, useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import {
  CaretDownIcon,
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon,
} from "evergreen-ui";
import {
  Button,
  Form,
  IconButton,
  Menu,
  Pane,
  Popover,
  Text,
} from "components/materials";
import {
  majorScale,
  minorScale,
  Position,
  ThemeContext,
  toaster,
} from "helpers/utilities";
import t from "helpers/translate";
import { NavigationWarnings, UserContext } from "helpers/behaviors";
import { AGREEMENT_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { dateFormToServer } from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import unformatNumber from "helpers/unformatNumber";
import {
  AgreementForm,
  getInitialValues,
  formatAgreementLineItems,
  formatTransactions,
  formatNewLineItems,
  validateAgreementForm,
} from "../AgreementForm";
import { determineAssociableAgreements } from "../AgreementsTable";
import { AddAgreementModal } from "../AddAgreementModal";
import {
  ADD_PROJECT_AGREEMENT,
  UPDATE_PROJECT_AGREEMENT,
} from "../graphql-queries";
import { CHANGE_ORDER_TYPES } from "../enums";
import { AssociateAgreementsModal } from "../AssociateAgreementsModal";
import { AssignAdjustmentToDrawModal } from "../AssignAdjustmentToDrawModal";
import { CannotDeleteWithAdjustmentModal } from "../CannotDeleteWIthAdjustmentModal";
import { DeleteAgreementModal } from "../DeleteAgreementModal";

export function AgreementsViewerLayout({
  agreement,
  cardsOpen,
  navigateOnAssociate,
  navigateOnCreate,
  navigateOnDelete,
  otherProjectAgreements,
  project,
  agreementDetailRefetchQueries,
  agreementsTableRefetchQueries,
  setCardsOpen,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);
  const canCreateAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [associateWithAgreementType, setAssociateWithAgreementType] = useState(
    null
  );

  const [upgradeAgreementModalProps, setUpgradeAgreementModalProps] = useState(
    null
  );
  const [
    assignAdjustmentToDrawModalOpen,
    setAssignAdjustmentToDrawModalOpen,
  ] = useState(false);
  const [
    cannotDeleteWithAdjustmentOpen,
    setCannotDeleteWithAdjustmentOpen,
  ] = useState(false);

  const [addProjectAgreement] = useMutation(ADD_PROJECT_AGREEMENT, {
    onCompleted: ({ addProjectAgreement: { id: newAgreementId } }) => {
      setUpgradeAgreementModalProps(null);
      navigateOnCreate(newAgreementId);
    },
    refetchQueries: agreementsTableRefetchQueries,
  });

  const [updateProjectAgreement, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_AGREEMENT,
    {
      onCompleted: () => {
        toaster.success("Your agreement has been updated.", { duration: 2.5 });
      },
      refetchQueries: [
        ...agreementsTableRefetchQueries,
        ...agreementDetailRefetchQueries,
      ],
    }
  );

  function handleSubmit(
    {
      agreementDescription,
      agreementLineItems,
      agreementType,
      budgetAdjustmentTransactions: transactions,
      agreementNumber,
      changeOrderReason,
      daysImpacted,
      name,
      date,
      drawId,
      moveDocumentToDraw,
      userTouchedName,
      vendor,
    },
    { setErrors }
  ) {
    if (transactions.length > 0 && !drawId) {
      return setAssignAdjustmentToDrawModalOpen(true);
    }

    return updateProjectAgreement({
      variables: {
        agreementId: agreement.id,
        vendorId: vendor?.id,
        agreementLineItems: formatAgreementLineItems(agreementLineItems),
        budgetAdjustmentTransactions: formatTransactions(transactions),
        newLineItems: formatNewLineItems(transactions),
        agreementNumber,
        name,
        userTouchedName,
        changeOrderReason: CHANGE_ORDER_TYPES.includes(agreementType)
          ? changeOrderReason
          : null,
        daysImpacted: isBlank(daysImpacted)
          ? null
          : unformatNumber(daysImpacted),
        agreementDescription,
        budgetAdjustmentDrawId: drawId,
        moveDocumentToDraw,
        startDate: dateFormToServer(date),
      },
    }).catch((error) => {
      if (error.message.includes(t("agreementsForm.agreementNameError"))) {
        setErrors({
          name: t("agreementsForm.agreementNameTaken"),
        });
      } else {
        toaster.warning("Something went wrong.", { duration: 2.5 });
      }
    });
  }

  const projectHasAgreementType = (agreementType) =>
    otherProjectAgreements.some(({ type }) => type === agreementType);

  const isChangeOrder = CHANGE_ORDER_TYPES.includes(agreement.type);
  const isExposure = agreement.type === AGREEMENT_TYPE.EXPOSURE;
  const hasBudgetAdjustment = agreement.budgetAdjustment !== null;

  const { correlatedAgreementTypes, name: agreementName } = agreement;

  // These booleans cover both cases, if current agreement IS <type> or if agreement is related to <type>
  const agreementHasExposure = correlatedAgreementTypes.includes(
    AGREEMENT_TYPE.EXPOSURE
  );
  const agreementHasPCO = correlatedAgreementTypes.includes(
    AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER
  );
  const agreementHasECO = correlatedAgreementTypes.includes(
    AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
  );

  const hasECODocument = agreement.correlatedDocuments.some(
    ({ type }) => type === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
  );

  const projectHasExposures = projectHasAgreementType(AGREEMENT_TYPE.EXPOSURE);
  const projectHasPCOs = projectHasAgreementType(
    AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER
  );
  const projectHasECOs = projectHasAgreementType(
    AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
  );

  const {
    id: projectId,
    lineItems,
    agreementVendorLineItems,
    draws,
    vendors,
  } = project;

  const upgradeProps = {
    isUpgradeFlow: true,
    knownExistingAgreement: agreement,
    onClose: () => setUpgradeAgreementModalProps(null),
    project,
    projectAgreementMutation: addProjectAgreement,
  };

  return (
    <Pane position="relative">
      <Form>
        <Pane
          alignItems="center"
          backgroundColor="#ffffff"
          borderBottom="1px solid #d7e0ea"
          display="flex"
          height={48}
          justifyContent="space-between"
          paddingY={minorScale(1)}
          position="sticky"
          top={0}
          width="100%"
          zIndex={3}
        >
          <Pane display="flex">
            <IconButton
              appearance="minimal"
              icon={cardsOpen ? DoubleChevronLeftIcon : DoubleChevronRightIcon}
              onClick={() => setCardsOpen(!cardsOpen)}
              type="button"
            />
            <Text
              fontWeight={theme.fontWeights.MEDIUM}
              overflow="hidden"
              paddingTop={minorScale(2)}
              size={500}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {agreementName}
            </Text>
          </Pane>
          <Pane paddingRight={majorScale(2)}>
            <Popover
              content={({ close }) => (
                <Menu>
                  <Menu.Item
                    is={Text}
                    onClick={() => {
                      if (hasBudgetAdjustment && !canCreateAdjustments) {
                        setCannotDeleteWithAdjustmentOpen(true);
                      } else {
                        setConfirmDeleteOpen(true);
                      }
                      close();
                    }}
                  >
                    <Text>Delete</Text>
                  </Menu.Item>
                  {isChangeOrder && (
                    <Fragment>
                      {!agreementHasPCO && isExposure && (
                        <Menu.Item
                          is={Text}
                          onClick={() =>
                            setUpgradeAgreementModalProps({
                              ...upgradeProps,
                              knownNewAgreementType:
                                AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER,
                            })
                          }
                        >
                          <Text>Update to Potential Change Order</Text>
                        </Menu.Item>
                      )}
                      {!agreementHasECO && (
                        <Menu.Item
                          is={Text}
                          onClick={() =>
                            setUpgradeAgreementModalProps({
                              ...upgradeProps,
                              knownNewAgreementType:
                                AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
                            })
                          }
                        >
                          <Text>Execute Change Order</Text>
                        </Menu.Item>
                      )}
                      {!agreementHasExposure && projectHasExposures && (
                        <Menu.Item
                          is={Text}
                          onClick={() =>
                            setAssociateWithAgreementType(
                              AGREEMENT_TYPE.EXPOSURE
                            )
                          }
                        >
                          <Text>Associate with an existing Exposure</Text>
                        </Menu.Item>
                      )}
                      {!agreementHasPCO && projectHasPCOs && (
                        <Menu.Item
                          is={Text}
                          onClick={() =>
                            setAssociateWithAgreementType(
                              AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER
                            )
                          }
                        >
                          <Text>
                            Associate with an existing Potential Change Order
                          </Text>
                        </Menu.Item>
                      )}
                      {!agreementHasECO && projectHasECOs && (
                        <Menu.Item
                          is={Text}
                          onClick={() =>
                            setAssociateWithAgreementType(
                              AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
                            )
                          }
                        >
                          <Text>
                            Associate with an existing Executed Change Order
                          </Text>
                        </Menu.Item>
                      )}
                    </Fragment>
                  )}
                </Menu>
              )}
              shouldCloseOnExternalClick
              position={Position.BOTTOM_RIGHT}
            >
              <Button purpose="agreement actions open" width={100}>
                Actions <CaretDownIcon />
              </Button>
            </Popover>
          </Pane>
        </Pane>
      </Form>
      <Formik
        enableReinitialize
        initialValues={getInitialValues({
          existingAgreement: agreement,
          lineItems,
          projectId,
        })}
        onSubmit={handleSubmit}
        validate={(values) => validateAgreementForm(values, hasPermission)}
      >
        {(formikProps) => (
          <Pane padding={majorScale(1)}>
            <NavigationWarnings dirty={formikProps.dirty} />
            <AgreementForm
              agreement={agreement}
              agreementVendorLineItems={agreementVendorLineItems}
              formikProps={formikProps}
              lineItems={lineItems}
              projectId={projectId}
              showAgreementFormActions
              saveLoading={updateLoading}
              vendors={vendors}
              agreementLineItems={formikProps.values.agreementLineItems}
            />
            {assignAdjustmentToDrawModalOpen && (
              <AssignAdjustmentToDrawModal
                availableDraws={draws.filter(
                  ({ isLockedDown }) => !isLockedDown
                )}
                hasECODocument={hasECODocument}
                onConfirmDrawSelect={(drawId) => {
                  setAssignAdjustmentToDrawModalOpen(false);
                  formikProps.setFieldValue("drawId", drawId);
                  formikProps.handleSubmit();
                }}
                onClose={() => setAssignAdjustmentToDrawModalOpen(false)}
                projectId={projectId}
              />
            )}
          </Pane>
        )}
      </Formik>
      {confirmDeleteOpen && (
        <DeleteAgreementModal
          agreement={agreement}
          navigateOnDelete={navigateOnDelete}
          onClose={() => setConfirmDeleteOpen(false)}
          refetchQueries={agreementsTableRefetchQueries}
        />
      )}
      {cannotDeleteWithAdjustmentOpen && (
        <CannotDeleteWithAdjustmentModal
          onClose={() => setCannotDeleteWithAdjustmentOpen(false)}
        />
      )}
      {associateWithAgreementType !== null && (
        <AssociateAgreementsModal
          agreement={agreement}
          associableAgreements={determineAssociableAgreements(
            agreement,
            otherProjectAgreements,
            associateWithAgreementType
          )}
          associateWithType={associateWithAgreementType}
          navigateOnAssociate={navigateOnAssociate}
          onClose={() => setAssociateWithAgreementType(null)}
          refetchQueries={[
            ...agreementsTableRefetchQueries,
            ...agreementDetailRefetchQueries,
          ]}
        />
      )}
      {upgradeAgreementModalProps !== null && (
        <AddAgreementModal {...upgradeAgreementModalProps} />
      )}
    </Pane>
  );
}
