import { useState, useContext, Fragment } from "react";
import { Alert, Button, Form, Modal, Pane } from "components/materials";
import { Formik } from "formik";
import { groupBy } from "lodash";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import isBlank from "helpers/isBlank";
import { areWordsEqual } from "helpers/stringHelpers";
import getOptions from "helpers/getOptions";
import { v4 as uuid } from "uuid";
import { getMasterFormatDivisions } from "./MasterFormatDivisionForm";

export function AddLineItem({
  onAddLineItem,
  divisions,
  lineItems,
  unsavedNewLineItems = [],
  renderTrigger,
  initialValues = {},
  showOriginalBudgetAlert,
}) {
  const [showAddLineItemModal, setShowAddLineItemModal] = useState(false);
  const openModal = () => setShowAddLineItemModal(true);

  const { hasPermission } = useContext(UserContext);

  if (!hasPermission(PERMISSION_ACTION.EDIT_BUDGET)) return null;

  const hasSummaryLineItem = hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS);
  const hasMasterFormatDivision = hasPermission(
    PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING
  );

  return (
    <Fragment>
      {renderTrigger ? (
        renderTrigger(openModal)
      ) : (
        <Button marginLeft={majorScale(1)} onClick={openModal}>
          Create a New Line Item
        </Button>
      )}
      {showAddLineItemModal && (
        <Formik
          initialValues={{
            lineItemId: uuid(),
            divisionId: null,
            lineItemName: "",
            lineItemNumber: "",
            originalBudgetAmount: "0.00",
            summaryLineItem: "",
            masterFormatDivision: null,
            ...initialValues,
          }}
          validate={(values) =>
            validateNewLineItem(values, lineItems, unsavedNewLineItems)
          }
          onSubmit={onAddLineItem}
        >
          {(formikProps) => {
            return (
              <Modal
                open
                hasFooter
                title="New Line Item"
                confirmLabel="Save"
                isConfirmDisabled={shouldDisableConfirm(formikProps.values)}
                onConfirm={(close) => {
                  if (formikProps.isValid) {
                    formikProps.handleSubmit();
                    close();
                  }
                }}
                onClose={() => setShowAddLineItemModal(false)}
                width={630}
              >
                <Pane>
                  <Pane display="flex">
                    <Form.Select
                      name="divisionId"
                      label="Division"
                      options={getOptions(divisions)}
                      placeholder="Select Division..."
                      isRequired
                      popoverMinWidth={400}
                      width={275}
                      marginRight={majorScale(2)}
                    />
                    <Form.Input
                      name="originalBudgetAmount"
                      label="Original Budget Amount"
                      type="currency"
                      disabled
                    />
                  </Pane>
                  <Pane marginTop={majorScale(2)} display="flex">
                    <Form.Input
                      name="lineItemName"
                      label="Line Item Name"
                      isRequired
                      width={275}
                      marginRight={majorScale(2)}
                    />
                    <Form.Input
                      name="lineItemNumber"
                      label="Line Item Number"
                      width={275}
                    />
                  </Pane>
                  <Pane marginTop={majorScale(2)} display="flex">
                    {hasSummaryLineItem && (
                      <Form.Input
                        name="summaryLineItem"
                        label="Summary Line Item"
                        width={275}
                        marginRight={majorScale(2)}
                      />
                    )}
                    {hasMasterFormatDivision && (
                      <Form.Select
                        name="masterFormatDivision"
                        label="Master Format Division"
                        options={getMasterFormatDivisions()}
                        placeholder="Select Master Format Division..."
                        popoverMinWidth={400}
                        width={275}
                      />
                    )}
                  </Pane>
                </Pane>
                {showOriginalBudgetAlert && (
                  <Alert marginTop={majorScale(3)}>
                    This line item will be added to the original budget with an
                    original budget amount of $0.00.
                  </Alert>
                )}
              </Modal>
            );
          }}
        </Formik>
      )}
    </Fragment>
  );
}

function shouldDisableConfirm(values) {
  const { divisionId, lineItemName } = values;
  if (isBlank(divisionId) || isBlank(lineItemName)) {
    return true;
  }

  return false;
}

function validateNewLineItem(values, lineItems, unsavedNewLineItems) {
  const errors = {};
  const { divisionId, lineItemName } = values;
  const lineItemsByDivision = groupBy(lineItems, "division.id");
  const existingLineItemsInDivision = lineItemsByDivision[divisionId];
  const unsavedLineItemsInDivision = unsavedNewLineItems.filter(
    (lineItem) => lineItem.divisionId === divisionId
  );

  const hasDuplicateNameWithinDivision =
    existingLineItemsInDivision.some(({ name: existingName }) =>
      areWordsEqual(existingName, lineItemName)
    ) ||
    unsavedLineItemsInDivision.some(({ lineItemName: unsavedName }) =>
      areWordsEqual(unsavedName, lineItemName)
    );

  if (!isBlank(lineItemName) && hasDuplicateNameWithinDivision) {
    errors.lineItemName = "Line item names within a division must be unique";
  }

  return errors;
}
