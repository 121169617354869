import { useContext, Fragment } from "react";
import { DashboardTodos } from "components/templates";
import { Button, Divider, Heading, Pane } from "components/materials";
import { ThemeContext } from "helpers/utilities";

const getDocumentURL = (document) =>
  `/portfolio_assigned_documents/${document.project.id}/${document.id}`;

function PortfolioTodosHeader({ dispatchViewConfig }) {
  const theme = useContext(ThemeContext);
  return (
    <Fragment>
      <Pane alignItems="center" display="flex" justifyContent="space-between">
        <Heading size={400}>TO-DOS</Heading>
        <Button
          purpose="dashboard todos hide"
          appearance="minimal"
          onClick={() => dispatchViewConfig({ type: "HIDE_TODOS" })}
        >
          Hide
        </Button>
      </Pane>
      <Divider color={theme.colors.gray500} height={1} />
    </Fragment>
  );
}

export function PortfolioTodos({ history, todos, dispatchViewConfig }) {
  return (
    <Fragment>
      <PortfolioTodosHeader dispatchViewConfig={dispatchViewConfig} />
      <DashboardTodos
        getDocumentURL={getDocumentURL}
        history={history}
        isPortfolioPage
        todos={todos}
      />
    </Fragment>
  );
}
