import { Accordion, Button, Pane } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { qboConnectButton } from "images";

export function EditQuickBooksOnlineConfigurationPanel({
  loading,
  handleDisconnectFromQuickBooksOnline,
  onToggle,
  open,
  panelKey,
  project,
  ...props
}) {
  return (
    <Accordion.Panel
      panelKey={panelKey}
      title="QuickBooks Online Configuration"
      onClick={() => onToggle(panelKey)}
      open={open}
      {...props}
    >
      <Pane marginBottom={majorScale(1)} display="flex">
        {!project.hasQboAuthTokens && (
          <Pane>
            <Pane
              is="img"
              src={qboConnectButton}
              onClick={() => window.open(project.qboAuthorizationUrl, "_blank")}
              alt=""
              cursor="pointer"
              height={30}
              marginLeft="auto"
              marginRight={majorScale(1)}
              marginTop={minorScale(1)}
            />
            {/* <Button
              purpose="connect to QuickBooks online"
              onClick={() => window.open(project.qboAuthorizationUrl, "_blank")}
            >
              Connect to QuickBooks Online
            </Button> */}
          </Pane>
        )}
        {project.hasQboAuthTokens && (
          <Pane>
            <Button
              purpose="disconnect from QuickBooks Online"
              onClick={() => handleDisconnectFromQuickBooksOnline()}
            >
              Disconnect from QuickBooks Online
            </Button>
          </Pane>
        )}
      </Pane>
    </Accordion.Panel>
  );
}
