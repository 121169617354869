import { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Divider, Heading, Pane } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { getFilteredProjects } from "../PortfolioDashboard";
import { BorrowerPortfolioProjectCard } from "./BorrowerPortfolioProjectCard";

function Header() {
  const theme = useContext(ThemeContext);
  return (
    <Pane display="flex">
      <Pane flex="1 1 auto">
        <Pane alignItems="center" display="flex" height={majorScale(4)}>
          <Heading size={400} data-testid="gridView">
            PROJECTS
          </Heading>
        </Pane>
        <Divider color={theme.colors.gray500} height={1} />
      </Pane>
    </Pane>
  );
}

function Cards({ projects }) {
  return (
    <Pane display="flex" flexWrap="wrap" justifyContent="center">
      {projects.map((project) => (
        <Pane
          flex="1 1 auto"
          height={200}
          key={project.id}
          margin={majorScale(2)}
          maxWidth={525}
          minWidth={325}
          position="relative"
        >
          <BorrowerPortfolioProjectCard project={project} />
        </Pane>
      ))}
    </Pane>
  );
}

export function BorrowerPortfolioDashboard({ projects }) {
  const filteredProjects = useMemo(() => getFilteredProjects(projects), [
    projects,
  ]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      marginTop={majorScale(2)}
      width="100vw"
    >
      <Pane
        flex="1 1 auto"
        marginX={majorScale(2)}
        maxWidth={projects.length > 1 ? 1050 : 575}
        position="relative"
        width="100%"
      >
        <Header />
        <Cards projects={filteredProjects} />
      </Pane>
    </Pane>
  );
}

BorrowerPortfolioDashboard.propTypes = {
  projects: PropTypes.array.isRequired,
};
