import { useEffect, useReducer } from "react";
import { useDropzone as useReactDropzone } from "react-dropzone";
import pdfjs from "pdfjs-dist";
import { set } from "lodash";
import { DropzoneFile } from "features/uploads/types";

type UseDropzoneProps = {
  disabled: boolean;
  allowMultiple: boolean;
  acceptableTypes?: string[];
  drawId?: string;
  projectId?: string;
};

export enum ActionType {
  FILE_ADDED,
  FILE_REMOVED,
  FILE_LOADING,
  FILE_EDITED,
  FILE_LOADED,
}

export type Action =
  | {
      type: ActionType.FILE_ADDED;
      payload: File[];
    }
  | {
      type: ActionType.FILE_REMOVED;
      payload: {
        index: number;
      };
    }
  | {
      type: ActionType.FILE_LOADING;
      payload: {
        index: number;
      };
    }
  | {
      type: ActionType.FILE_EDITED;
      payload: {
        index: number;
        fieldName: string;
        value: any;
      };
    }
  | {
      type: ActionType.FILE_LOADED;
      payload: {
        index: number;
        numberOfPages: number | null;
      };
    };

interface State {
  files: DropzoneFile[];
  config: UseDropzoneProps;
}

const SPLIT_FILE_PAGE_LIMIT = 250;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.FILE_ADDED: {
      const newFiles = action.payload.map(
        (file: File): DropzoneFile => {
          return {
            file,
            name: file.name,
            loading: null,
            split: false,
            numberOfPages: null,
            disableSplit: true,
          };
        }
      );

      return {
        ...state,
        files: [...state.files, ...newFiles],
      };
    }
    case ActionType.FILE_LOADING:
      return {
        ...state,
        files: state.files.map((file, index) => {
          if (index === action.payload.index) {
            return {
              ...file,
              loading: true,
            };
          }

          return file;
        }),
      };
    case ActionType.FILE_LOADED:
      return {
        ...state,
        files: state.files.map((file, index) => {
          if (index === action.payload.index) {
            return {
              ...file,
              loading: false,
              numberOfPages: action.payload.numberOfPages,
              disableSplit:
                !action.payload.numberOfPages ||
                action.payload.numberOfPages === 1 ||
                action.payload.numberOfPages > SPLIT_FILE_PAGE_LIMIT,
            };
          }

          return file;
        }),
      };
    case ActionType.FILE_EDITED:
      return {
        ...state,
        files: state.files.map((file, index) => {
          if (index === action.payload.index) {
            return set(file, action.payload.fieldName, action.payload.value);
          }

          return file;
        }),
      };
    case ActionType.FILE_REMOVED:
      return {
        ...state,
        files: state.files.filter(
          (_file, index) => index !== action.payload.index
        ),
      };
    default:
      return state;
  }
}

async function loadFile(
  dispatch: Function,
  droppedFile: DropzoneFile,
  index: number
) {
  const { file } = droppedFile;

  if (file.type !== "application/pdf") {
    dispatch({
      type: ActionType.FILE_LOADED,
      payload: {
        index,
        numberOfPages: null,
      },
    });

    return;
  }

  dispatch({
    type: ActionType.FILE_LOADING,
    payload: { index },
  });

  const data = new Uint8Array(await file.arrayBuffer());
  const pdf = await pdfjs.getDocument({ data }).promise;

  dispatch({
    type: ActionType.FILE_LOADED,
    payload: {
      index,
      numberOfPages: pdf.numPages,
    },
  });
}

export default function useDropzone(options: UseDropzoneProps) {
  const [state, dispatch] = useReducer(reducer, { files: [], config: options });
  useEffect(() => {
    state.files.forEach((file, index) => {
      if (file.loading !== null) return;
      loadFile(dispatch, file, index);
    });
  }, [state.files]);

  const onAdd = (addedFiles: File[]) =>
    dispatch({ type: ActionType.FILE_ADDED, payload: addedFiles });

  const onRemove = (index: number) =>
    dispatch({ type: ActionType.FILE_REMOVED, payload: { index } });

  const onEdit = (index: number, fieldName: string, value: any) =>
    dispatch({
      type: ActionType.FILE_EDITED,
      payload: { index, fieldName, value },
    });

  const { getRootProps, getInputProps } = useReactDropzone({
    disabled: options.disabled,
    onDrop: onAdd,
    multiple: options.allowMultiple,
    accept: options.acceptableTypes,
  });

  return {
    files: state.files,
    getRootProps,
    getInputProps,
    onRemove,
    onEdit,
  };
}
