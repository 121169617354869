import { useContext } from "react";
import {
  Card,
  GoogleMap,
  Pane,
  Paragraph,
  Progress,
  Shortener,
} from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { compact, get } from "lodash";
import { addMonths, format } from "date-fns";
import { formatCurrency } from "helpers/formatCurrency";
import { DRAW_STATE } from "helpers/enums";
import { DrawInformation } from "./BorrowerPortfolioProjectCard";
import { ProjectBudgetOverview } from "../../containers/ProjectDashboard/LegacyProjectDashboard/ProjectBudgetOverview";
import { ProjectFundingSourcesOverview } from "../../containers/ProjectDashboard/LegacyProjectDashboard/ProjectSourcesOverview";
import { ProjectDraws } from "../ProjectDraws";

function ProjectProgressCircle({ header, value, total }) {
  const percent = value && total ? (value / total) * 100 : 0;
  const label = `${formatCurrency(value, {
    shortenedFormat: true,
  })} / ${formatCurrency(total, {
    shortenedFormat: true,
  })}`;

  return (
    <Pane
      textAlign="center"
      // needed to keep these components within their container in IE11
      flex="1 1 0%"
    >
      <Progress.Circular height={majorScale(8)} percent={percent} />
      <Paragraph>{header}</Paragraph>
      <Paragraph color="muted" size={300} data-testid="circleProgress">
        {label}
      </Paragraph>
    </Pane>
  );
}

const ProjectProgress = ({ project }) => {
  const startDate = get(project, "startDate");
  const expectedProjectLength = get(project, "expectedProjectLength", 0);
  const endDate =
    startDate && expectedProjectLength
      ? format(
          addMonths(format(startDate), expectedProjectLength),
          "MM/DD/YYYY"
        )
      : null;

  const projectTotal = get(project, "amount", 0);

  return (
    <Pane
      // Firefox and IE11 need this width set explicitly
      width="calc(68% - 215px)"
      display="flex"
      justifyContent="center"
    >
      <Pane
        maxWidth={340}
        width="100%"
        minWidth={250}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginLeft={-majorScale(2)}
      >
        <Pane
          display="flex"
          justifyContent="space-evenly"
          marginBottom={majorScale(1)}
        >
          {endDate && (
            <Pane textAlign="center">
              <Paragraph
                fontSize={16}
                fontWeight={500}
                data-testid="projectEndDate"
              >
                {endDate}
              </Paragraph>
              <Paragraph color="muted">Completion Date</Paragraph>
            </Pane>
          )}
          <Pane textAlign="center">
            <Paragraph
              fontSize={16}
              fontWeight={500}
              data-testid="projectTotal"
            >
              {formatCurrency(projectTotal)}
            </Paragraph>
            <Paragraph color="muted">Project Total</Paragraph>
          </Pane>
        </Pane>
        <Pane display="flex" justifyContent="space-around">
          <ProjectProgressCircle
            header="Completed"
            value={get(project, "disbursedAmount", 0)}
            total={projectTotal}
          />
          <ProjectProgressCircle
            header="Requested"
            value={get(project, "inProgressAmount", 0)}
            total={projectTotal}
          />
          <ProjectProgressCircle
            header="Contingency"
            value={get(project, "contingencySpentAmount", 0)}
            total={get(project, "contingencyOriginalAmount", 0)}
          />
        </Pane>
      </Pane>
    </Pane>
  );
};

function BorrowerProjectCard({ project, theme }) {
  return (
    <Card display="flex" width="100%" height={215}>
      <Pane minWidth={215} display="flex" justifyContent="center">
        {project.picture ? (
          <div
            style={{
              backgroundImage: `url("${project.picture.url}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              borderRadius: "4px 0 0 4px",
            }}
          />
        ) : (
          <GoogleMap height="100%" projects={[project]} />
        )}
      </Pane>
      <Pane
        width="32%"
        paddingLeft={minorScale(3)}
        paddingTop={majorScale(2)}
        position="relative"
      >
        <Shortener
          fontSize={24}
          limit={30}
          minimumFontSize={16}
          text={project.name}
          useDynamicFontSize
          wordBreak="break-word"
        />
        {(project.city || project.state) && (
          <Paragraph
            color="muted"
            fontWeight={theme.fontWeights.DEMI}
            marginTop={minorScale(1)}
            overflow="hidden"
            size={300}
            textOverflow="ellipsis"
            textTransform="uppercase"
            whiteSpace="nowrap"
          >
            {compact([project.city, project.state]).join(", ")}
          </Paragraph>
        )}
        <DrawInformation draw={project.recentUnfundedDraw} />
      </Pane>
      <ProjectProgress project={project} />
    </Card>
  );
}

export function BorrowerProjectOverview({
  hasAllowMultipleUnfundedBorrowerDraws,
  project,
}) {
  const theme = useContext(ThemeContext);
  const hasDrawInProgress = project.draws.some(
    (draw) => draw.state === DRAW_STATE.STARTED
  );

  const hasAllDrawsFundedOrDeleted = project.draws.every(
    (draw) =>
      draw.state === DRAW_STATE.FUNDED || draw.isDeletedByLender === true
  );

  return (
    <Pane width="100%">
      <Paragraph
        height={40}
        display="flex"
        alignItems="center"
        color="muted"
        fontWeight={theme.fontWeights.MEDIUM}
      >
        PROJECT
      </Paragraph>
      <BorrowerProjectCard project={project} theme={theme} />
      <ProjectDraws
        getDrawPath={(drawId) =>
          `/borrower/projects/${project.id}/draws/${drawId}`
        }
        newDrawPath={`/borrower/projects/${project.id}/draws/new`}
        canCreateDraws={
          (hasAllDrawsFundedOrDeleted ||
            hasAllowMultipleUnfundedBorrowerDraws) &&
          !hasDrawInProgress
        }
        hasAllowMultipleUnfundedBorrowerDraws={
          hasAllowMultipleUnfundedBorrowerDraws
        }
        project={project}
      />
      <Pane display="flex">
        <Pane paddingRight={majorScale(1)} width="50%">
          <ProjectBudgetOverview disableFullBudget project={project} />
        </Pane>
        <Pane width="50%" paddingLeft={majorScale(1)}>
          <Pane>
            <ProjectFundingSourcesOverview project={project} theme={theme} />
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}
