import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const FILE_UPLOADED = gql`
  mutation FileUploaded($id: String!) {
    fileUploaded(uploadId: $id) {
      id
    }
  }
`;

export default function useFileUploadedMutation(variables = {}) {
  return useMutation(FILE_UPLOADED, { variables });
}
