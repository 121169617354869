import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import GoogleMaps from "helpers/getGoogleMaps";
import analytics from "helpers/analytics";
import { mapMarker, tempMap } from "images";
import "./GoogleMap.css";

function GoogleMap({ projects, history, height, width }) {
  const ref = useRef(null);
  // Setting the map in state was recommended by the official GoogleMaps API documentation
  // https://developers.google.com/maps/documentation/javascript/react-map
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (ref.current && !map) {
      GoogleMaps.then(() => {
        const { LatLngBounds, Map, Marker } = window.google.maps;
        const mapParams = {
          backgroundColor: "transparent",
          clickableIcons: false,
          disableDefaultUI: true,
          draggableCursor: "default",
          gestureHandling: "none",
          keyboardShortcuts: false,
          zoom: 12,
        };
        const map = new Map(ref.current, mapParams);
        const bounds = new LatLngBounds();
        projects.forEach((project) => {
          if (!project.coordinates) return;
          const markerParams = {
            clickable: !!history,
            icon: mapMarker,
            map,
            position: project.coordinates,
          };
          const marker = new Marker(markerParams);
          if (history) {
            marker.addListener("click", () => {
              history.push(`/projects/${project.id}`);
            });
            bounds.extend(marker.position);
            map.fitBounds(bounds, 20);
          } else {
            map.setCenter(project.coordinates);
          }
        });

        setMap(map);
      }).catch(analytics.error);
    }
    // eslint-disable-next-line
  }, [ref, map]);

  const style = {
    backgroundImage: `url(${tempMap})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height,
    width,
  };

  return <div className="google-map-component" ref={ref} style={style} />;
}

GoogleMap.propTypes = {
  history: PropTypes.object,
  projects: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.number,
};

export default GoogleMap;
