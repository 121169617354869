import { isNull } from "lodash";
import { WarningSignIcon } from "evergreen-ui";
import { Pane, Paragraph } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";

export function UnmappedLineItemsWarning({ form, theme }) {
  const { lineItemColumn, lineItems } = form.values;
  const showWarning =
    !isNull(lineItemColumn) &&
    lineItems.some((lineItem) => isNull(lineItem.rowIndex));
  if (!showWarning) return null;
  return (
    <Pane
      borderTop
      borderLeft={`3px solid ${theme.colors.warning}`}
      paddingY={minorScale(3)}
      paddingLeft={majorScale(2)}
      display="flex"
      alignItems="center"
    >
      <WarningSignIcon
        color="warning"
        marginRight={minorScale(3)}
        minWidth={16}
      />
      <Pane>
        <Paragraph fontWeight={theme.fontWeights.MEDIUM}>
          Some line items are missing information.
        </Paragraph>
        <Paragraph>
          Please review the draw summary information associated with each line
          item below.
        </Paragraph>
      </Pane>
    </Pane>
  );
}
