import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dimmer, Pane, Paragraph } from "components/materials";
import { majorScale } from "evergreen-ui";
import { loader } from "images";

function Branded({ spinnerText }) {
  return (
    <Pane>
      <Pane
        backgroundColor="#ffffff"
        borderRadius="50%"
        marginBottom={majorScale(2)}
        marginLeft="auto"
        marginRight="auto"
        padding={majorScale(3)}
        height={100}
        width={100}
      >
        <img src={loader} alt="Loading..." width={52} />
      </Pane>
      {spinnerText && (
        <Paragraph clear="both" display="block" size={500}>
          {spinnerText}
        </Paragraph>
      )}
    </Pane>
  );
}

export function Loadable({ loading, children, spinnerText, textDelay }) {
  const [delayedSpinnerText, setDelayedSpinnerText] = useState(null);

  useEffect(() => {
    if (spinnerText) {
      setTimeout(() => setDelayedSpinnerText(spinnerText), textDelay);
    }
  }, [spinnerText, textDelay]);

  if (loading) {
    return (
      <Dimmer active>
        <Branded spinnerText={delayedSpinnerText} />
      </Dimmer>
    );
  }

  return children || null;
}

Loadable.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  textDelay: PropTypes.number,
  timeout: PropTypes.number,
  spinnerText: PropTypes.string,
};

Loadable.defaultProps = {
  loading: true,
  textDelay: 0,
  timeout: 5000,
};
