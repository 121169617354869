import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
  createContext,
} from "react";
import type { FC, PropsWithChildren } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import type { User } from "@auth0/auth0-react";
import { captureException } from "@sentry/react";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { STORAGE_KEYS } from "./helpers/localStorageKeys";
import * as LocalStorage from "./LocalStorage";
import { Loadable } from "./components/materials";

const domain = process.env.REACT_APP_AUTH_DOMAIN!;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID!;
const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI!;
const audience = process.env.REACT_APP_AUTH_AUDIENCE!;
const afterLogoutUrl = process.env.REACT_APP_AUTH_AFTER_LOGOUT_URL;

export const TRACK_LOGIN = gql`
  mutation TrackLogin {
    trackLogin {
      status
    }
  }
`;

/**
 * A React Context Provider setting up Authentication with Auth0 and providing
 * auth-related values and functionality throughout the app via the `useAuth`
 * hook.
 *
 * Additionally, this Provider will automatically handle authentication
 * callbacks.
 */
export const Provider: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    // When another tab logs out, the Auth0 keys are cleared from localstorage.
    // If that is detected, we refresh this tab so that the user cannot continue
    // using the session.
    window.addEventListener("storage", () => {
      const keys = Object.keys(localStorage);
      if (!keys.some((key) => key.match(/^@@auth0spajs@@/))) {
        window.location.reload();
      }
    });
  }, []);

  const onRedirectCallback = useMemo(
    () => (_appState: any, _user: any) => {
      if (window.location.pathname.includes("/auth/callback")) {
        history.replace("/");
        return;
      }
      history.push(window.location.pathname);
    },
    [history]
  );

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
        scope: "openid profile email",
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <InnerAuthProvider>{children}</InnerAuthProvider>
    </Auth0Provider>
  );
};

export interface UseAuthReturn {
  isLoading: boolean;
  isAuthenticated: () => boolean;
  error: Error | undefined;
  auth0User: User | undefined;
  getAccessToken: () => string | null;
  getAuthenticatedUrl: (url: string) => string;
  login: (options?: { connection?: string }) => Promise<void>;
  passwordLogin: (
    email: string,
    password: string,
    callback: () => void
  ) => Promise<void>;
  logout: () => void;
  resetReturnTo: () => void;
  setReturnTo: (returnTo: string) => void;
  getReturnTo: () => string;
  setSession: (authResult: unknown) => void;
  reset: () => void;
  updateLastActivity: () => number;
}

/**
 * React hook for accessing auth-related values and functionality.
 */
export const useAuth = (): UseAuthReturn => {
  const {
    isLoading,
    error,
    isAuthenticated: isAuthedBool,
    user: auth0User,
    loginWithRedirect,
    logout: auth0Logout,
  } = useAuth0();

  if (error) {
    captureException(error);
  }

  /**
   *  This is just to maintain backwards compatability. Once the
   *  singleton is gone, we can return it to being a boolean.
   */
  const isAuthenticated = useCallback(() => isAuthedBool, [isAuthedBool]);

  const { accessToken } = useContext(InnerAuthContext);
  const getAccessToken = useCallback(() => accessToken, [accessToken]);
  const getAuthenticatedUrl = useCallback(
    (urlString: string) => {
      // The URL base (the second arg) is ignored if a full URL is provided.
      const url = new URL(urlString, process.env.REACT_APP_HOST);
      if (accessToken) {
        url.searchParams.set("access_token", accessToken!);
      }
      return url.toString();
    },
    [accessToken]
  );

  const login: UseAuthReturn["login"] = useCallback(
    (options) =>
      loginWithRedirect({
        authorizationParams: { connection: options?.connection },
      }),
    [loginWithRedirect]
  );
  /**
   * We can't log them in for them anymore, so this just attempts to log them in.
   */
  const passwordLogin: (
    _email: string,
    _password: string,
    _callback: Function
  ) => Promise<void> = useCallback(() => login(), [login]);

  const logout = useCallback(() => {
    auth0Logout({
      clientId,
      logoutParams: {
        returnTo: afterLogoutUrl,
      },
    });
  }, [auth0Logout]);

  return {
    isLoading,
    isAuthenticated,
    error,
    auth0User,
    getAccessToken,
    getAuthenticatedUrl,
    login,
    logout,
    passwordLogin,
    resetReturnTo,
    setReturnTo,
    getReturnTo,
    setSession,
    reset,
    updateLastActivity,
  };
};

/**
 * A React Hook that returns the current access token.
 *
 * This is only to be used while we try to maintain the singleton ApolloClient
 * from `src/client.js.
 *
 * Currently commented out to maintain backwards compatability with the old auth lib.
 */
// export const useAccessToken = (): string | undefined => {
//   const { isAuthenticated, getAccessToken } = useAuth();
//   const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

//   useEffect(() => {
//     if (isAuthenticated()) {
//       getAccessToken().then((token) => setAccessToken(token));
//     }
//   }, [isAuthenticated, getAccessToken]);

//   return accessToken;
// };

/**
 * Everything past this point is just to maintain the old auth singleton.
 */
const resetReturnTo = () => {
  // oldAuth.resetReturnTo();
};
const setReturnTo = (uri: string) => {
  // oldAuth.setReturnTo(uri);
};
const getReturnTo = () => "/"; // oldAuth.getReturnTo();
const setSession = (authResult: unknown) => {
  // oldAuth.setSession(authResult);
};
const reset = () => {};
const updateLastActivity = () => {
  const now = Date.now();
  LocalStorage.setItem(STORAGE_KEYS.LAST_ACTIVITY_AT, now);
  return now;
};

const InnerAuthContext = createContext<{ accessToken: string | null }>({
  accessToken: null,
});
/**
 * This is an additional provider for our own Auth state.
 *
 * At the moment, it provides an Access Token that is refreshed every 5 minutes.
 */
const InnerAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    const grabAndSetAccessToken = () => {
      if (!isAuthenticated) return;
      getAccessTokenSilently().then((token) => {
        if (!mounted) return;
        setAccessToken(token);
      });
    };

    grabAndSetAccessToken();
    const timer = setInterval(grabAndSetAccessToken, 1000 * 60 * 5);

    return () => {
      mounted = false;
      clearInterval(timer);
    };
  }, [isAuthenticated, getAccessTokenSilently]);

  // If we don't have an access token, but we're authenticated, we might be
  // logging in/out.
  if (!accessToken && isAuthenticated)
    return <Loadable loading spinnerText="Retrieving access token..." />;

  return (
    <InnerAuthContext.Provider value={{ accessToken }}>
      {children}
    </InnerAuthContext.Provider>
  );
};
