import { forwardRef, useContext } from "react";
import { EvergreenSelect } from "components/materials";
import { ThemeContext } from "helpers/utilities";
import { majorScale } from "evergreen-ui";
import { getButtonBackgroundStylingProps } from "helpers/themeHelpers";

export default forwardRef(function Select(
  {
    appearance,
    children,
    content,
    style,
    purpose,
    themeOverrides = {},
    ...rest
  },
  ref
) {
  const theme = useContext(ThemeContext);
  const overrideSelectProps = () => {
    return {
      ...getButtonBackgroundStylingProps(theme.colors.gray100),
      appearance: "minimal",
      color: theme.colors.gray900,
      border: `1px solid ${theme.colors.gray300}`,
    };
  };

  return (
    <EvergreenSelect
      ref={ref}
      appearance={appearance}
      className="select"
      data-purpose={purpose}
      justifyContent="center"
      style={{ WebkitAppearance: "none", ...style }}
      type="button"
      whiteSpace="nowrap"
      height={majorScale(4)}
      borderRadius="5px"
      {...overrideSelectProps()}
      {...rest}
    >
      {content || children}
    </EvergreenSelect>
  );
});
