import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Loadable, Text } from "components/materials";

const Frame = ({ src, title, failureMessage, ...props }) => {
  const [url, setUrl] = useState(null);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (/Trident/.test(window.navigator.userAgent)) {
      setUrl(src);
    } else {
      fetch(src)
        .then((response) => {
          if (!response.ok) setFailed(true);
          return response.blob();
        })
        .then((blob) => {
          setUrl(window.URL.createObjectURL(blob));
        })
        .catch(() => {
          setFailed(true);
        });
    }
  }, [src]);

  if (failed) return <Alert title={<Text>{failureMessage}</Text>} />;

  if (url) return <iframe src={url} title={title} {...props} />;

  return <Loadable loading />;
};

Frame.propTypes = {
  src: PropTypes.string.isRequired,
  failureMessage: PropTypes.string.isRequired,
};

export default Frame;
