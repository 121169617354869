import { Fragment } from "react";
import { BRAND_COLORS, COLORS, PIE_CHART_COLORS } from "helpers/colors";
import { map } from "lodash";

const font = {
  fontFamily: "AvenirNext, arial, sans-serif",
  fontSize: 16,
  fill: COLORS.BLACK,
  stroke: "none",
};

const fontLarge = { ...font, fontSize: 30 };

const chart = {
  width: 500,
  height: 350,
  padding: 50,
  labelWidth: 175,
  labelHeight: 325,
};

const pieChart = {
  width: 700,
  height: 375,
};

const gradient = (id, color) => (
  <linearGradient key={id} id={id} x2={0} y2={1}>
    <stop stopColor={color} stopOpacity={0.6} offset="0%" />
    <stop stopColor={color} stopOpacity={1.0} offset="100%" />
  </linearGradient>
);

const barChartGradient = (id, color1, color2) => (
  <linearGradient key={id} id={id} x2={0} y2={1}>
    <stop stopColor={color1} stopOpacity={1.0} offset="0%" />
    <stop stopColor={color2} stopOpacity={1.0} offset="100%" />
  </linearGradient>
);
const barGradientId = "barGradientId";
export const barGradient = (
  <Fragment>
    <defs>
      {barChartGradient(
        barGradientId,
        BRAND_COLORS.PRIMARY,
        BRAND_COLORS.LIGHT_PRIMARY
      )}
    </defs>
  </Fragment>
);

const colorScale = map(PIE_CHART_COLORS, (color, id) => `url(#${id})`);
export const pieGradient = (
  <defs>{map(PIE_CHART_COLORS, (color, id) => gradient(id, color))}</defs>
);

export const theme = {
  axis: {
    style: {
      axis: {
        fill: "none",
        stroke: COLORS.BLACK,
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      axisLabel: font,
      grid: {
        fill: "none",
        stroke: "none",
      },
      ticks: {
        fill: "none",
        stroke: "none",
        size: 2,
      },
      tickLabels: font,
    },
  },
  bar: {
    ...chart,
    style: {
      data: {
        fill: ({ y }) => (y ? `url(#${barGradientId})` : "none"),
        stroke: "none",
      },
      labels: {
        ...font,
        fill: BRAND_COLORS.WHITE,
        padding: 5,
      },
    },
  },
  labels: {
    ...fontLarge,
    x: 150,
    y: 182,
  },
  legend: {
    colorScale,
    rowGutter: 10,
    symbolSpacer: 8,
    style: {
      labels: { ...fontLarge },
    },
    x: 400,
    y: 15,
  },
  tooltip: {
    cornerRadius: 5,
    pointerLength: 5,
    flyoutStyle: {
      fill: COLORS.BLACK,
      stroke: "none",
    },
  },
  pie: {
    ...pieChart,
    colorScale,
    innerRadius: 90,
    padding: {
      right: 400,
    },
    style: {
      labels: {
        ...font,
        fill: BRAND_COLORS.WHITE,
        padding: 5,
      },
    },
  },
  chart,
};
