import {
  CrossIcon,
  DuplicateIcon,
  FilterIcon,
  majorScale,
  minorScale,
  withTheme,
  Position,
} from "evergreen-ui";
import { Pane, Text, Tooltip } from "components/materials";
import { boxProps } from "../utils";

const SelectionPill = withTheme(
  ({ color, icon, text, onClick, textProps, theme, tooltipText, ...props }) => {
    const pillProps = boxProps({
      alignItems: "center",
      backgroundColor: theme.colors[color],
      borderRadius: majorScale(2),
      cursor: onClick ? "pointer" : undefined,
      display: "inline-flex",
      paddingX: minorScale(3),
      paddingY: minorScale(1),
      ...props,
    });

    const leftIconProps = {
      color: theme.colors.icon.default,
      size: minorScale(3),
      marginBottom: -2,
      marginRight: majorScale(1),
    };

    const rightIconProps = {
      color: "#4f5f70",
      size: minorScale(3),
      marginBottom: -2,
      marginLeft: majorScale(1),
      cursor: "pointer",
    };

    const renderIcon = () => {
      if (icon === "filter") return <FilterIcon {...leftIconProps} />;
      if (icon === "duplicate") return <DuplicateIcon {...leftIconProps} />;
      return false;
    };

    return (
      <Pane {...pillProps} onClick={onClick}>
        {icon && renderIcon()}
        {tooltipText ? (
          <Tooltip content={tooltipText} position={Position.TOP}>
            <Text size={300} {...textProps}>
              {text}
            </Text>
          </Tooltip>
        ) : (
          <Text size={300} {...textProps}>
            {text}
          </Text>
        )}
        {onClick && <CrossIcon {...rightIconProps} />}
      </Pane>
    );
  }
);

export default SelectionPill;
