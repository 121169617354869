import { CaretDownIcon } from "evergreen-ui";
import {
  Checkbox,
  IconButton,
  Pane,
  SelectMenu,
  TextInput,
} from "components/materials";
import { difference, get, xor } from "lodash";
import { majorScale } from "helpers/utilities";
import { enumColumnDefaults } from "./FastDataTableEnumColumn";

function parseBoolean(str) {
  return str === "true";
}

export const booleanColumnDefaults = {
  ...enumColumnDefaults,
  groupable: true,
  sortOrder: [true, false],
  filterControl: (index, form, column, items) => {
    // This is very similar to the `filterControl` for EnumColumn. The differences are twofold:
    // Use "stringified" booleans ("true" instead of true) to keep the SelectMenu happy
    // Always permit filtering on "true" or "false", rather than provide options based on the current items
    const values = [true, false];
    const options = values.map((value) => ({
      key: value.toString(),
      label: value.toString(),
      value: value.toString(),
    }));
    const selected = get(form.values, `filters.${index}.enum`, []);
    const checked = difference(values, selected).length === 0;
    const indeterminate = !checked && selected.length > 0;
    return (
      <Pane marginTop={majorScale(1)}>
        <SelectMenu
          hasFilter={false}
          isMultiSelect
          onDeselect={({ value }) =>
            form.setFieldValue(
              `filters.${index}.enum`,
              xor(selected, [parseBoolean(value)])
            )
          }
          onSelect={({ value }) =>
            form.setFieldValue(
              `filters.${index}.enum`,
              xor(selected, [parseBoolean(value)])
            )
          }
          options={options}
          selected={selected.map((val) => val.toString())}
          titleView={() => (
            <Pane marginLeft={majorScale(1)}>
              <Checkbox
                checked={checked}
                indeterminate={indeterminate}
                label="SELECT ALL"
                onChange={() =>
                  form.setFieldValue(
                    `filters.${index}.enum`,
                    checked ? [] : values
                  )
                }
              />
            </Pane>
          )}
        >
          <Pane display="flex">
            <TextInput
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
              onChange={() => {}}
              value={`${selected.length} Selected`}
            />
            <IconButton
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
              icon={CaretDownIcon}
              type="button"
            />
          </Pane>
        </SelectMenu>
      </Pane>
    );
  },
  filterFormatter: (filterConfig, column) => {
    // filterConfig for an enum column that didn't used to be enum column will not have an enum key
    if (!filterConfig.enum) return null;
    const parsedFilterConfig = {
      ...filterConfig,
      enum: filterConfig.enum.map((value) =>
        typeof value === "boolean" ? value.toString() : value
      ),
    };
    return enumColumnDefaults.filterFormatter(parsedFilterConfig, column);
  },
  filterStrategy: (value, filterConfig) => filterConfig.enum.includes(value),
  width: 50,
  disableSearch: true,
};
