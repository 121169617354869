import { Fragment, useContext, useState } from "react";
import { CaretDownIcon, ChevronDownIcon, ChevronRightIcon } from "evergreen-ui";
import {
  Checkbox,
  DescriptionWithHelpLink,
  Divider,
  Form,
  IconButton,
  MaskedInput,
  Pane,
  Popover,
  SelectMenu,
  Table,
  Text,
  TextInput,
} from "components/materials";
import { Controller, useWatch } from "react-hook-form";
import { LINE_ITEM_TYPE } from "helpers/enums";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import {
  every,
  find,
  get,
  includes,
  keys,
  pick,
  pickBy,
  values,
  xor,
} from "lodash";
import t from "helpers/translate";
import { Position } from "evergreen-ui/commonjs/constants";
import { preventEventBubbling } from "helpers/preventEventBubbling";

export const orderedLineItemTypes = [
  LINE_ITEM_TYPE.HARD_COSTS,
  LINE_ITEM_TYPE.SOFT_COSTS,
];

export const orderedLineItemCategories = [
  LINE_ITEM_TYPE.ALLOWANCES,
  LINE_ITEM_TYPE.CONTINGENCY,
  LINE_ITEM_TYPE.DEVELOPER_FEES,
  LINE_ITEM_TYPE.FEES,
  LINE_ITEM_TYPE.FINANCING,
  LINE_ITEM_TYPE.INTEREST_RESERVES,
  LINE_ITEM_TYPE.LAND_ACQUISITION,
  LINE_ITEM_TYPE.TENANT_IMPROVEMENTS,
];

const BULK_ASSIGN = {
  TYPE: "TYPE",
  CATEGORIES: "CATEGORIES",
  RETAINAGE: "RETAINAGE",
};

const blankTypeOption = {
  key: "blank",
  value: null,
  text: "(neither)",
  label: "(neither)",
};

const getTypeOptions = (types) =>
  types.map((type) => ({
    key: type,
    value: type,
    label: t(`lineItemTypes.${type}`),
    text: t(`lineItemTypes.${type}`),
  }));

function formatSelectedCategories({ lineItemId, form: { getValues } }) {
  const categories = pick(
    getValues(),
    orderedLineItemCategories.map((cat) => `${lineItemId}-${cat}`)
  );
  return keys(pickBy(categories, (value) => value === true))
    .map((idAndCategory) => t(`lineItemTypes.${idAndCategory.substring(37)}`))
    .join(", ");
}

function bulkAssign({
  value,
  lineItemIds,
  field,
  form: { getValues, reset, setValue },
}) {
  const values = getValues();
  // rhf reset() keepDirty flag doesn't SET form state dirty, only preserves current state
  setValue("formDirty", true, { shouldDirty: true });
  switch (field) {
    case BULK_ASSIGN.TYPE:
      reset(
        {
          ...values,
          ...lineItemIds.reduce(
            (acc, lineItemId) => ({ ...acc, [`${lineItemId}-type`]: value }),
            {}
          ),
        },
        { keepDirty: true }
      );

      break;
    case BULK_ASSIGN.CATEGORIES:
      reset(
        {
          ...values,
          ...lineItemIds.reduce(
            (acc, lineItemId) => ({
              ...acc,
              [`${lineItemId}-${value.category}`]: value.nextValue,
            }),

            {}
          ),
        },
        { keepDirty: true }
      );
      break;
    case BULK_ASSIGN.RETAINAGE:
      if (isFinite(parseFloat(value))) {
        reset(
          {
            ...values,
            ...lineItemIds.reduce(
              (acc, lineItemId) => ({
                ...acc,
                [`${lineItemId}-retainage`]: value,
              }),
              {}
            ),
          },
          { keepDirty: true }
        );
      }
    // no default
  }
}

function getBulkCategoryText(categories) {
  const activeCategories = keys(
    pickBy({ ...categories }, (value) => value === true)
  );
  const hasPartiallySelected = values(categories).some(
    (value) => value === "indeterminate"
  );
  return activeCategories
    .map((cat) => t(`lineItemTypes.${cat}`))
    .concat(hasPartiallySelected ? ["(mixed)"] : [])
    .join(", ");
}

function LineItemRow({ lineItemId, lockRetainageEditing, form, name }) {
  const { control, setValue } = form;
  const theme = useContext(ThemeContext);
  const {
    defaultColors: { dividerColor },
  } = theme;

  return (
    <Table.Row>
      <Table.TextCell paddingLeft={majorScale(2)}>{name}</Table.TextCell>
      <Table.TextCell>
        <Controller
          control={control}
          name={`${lineItemId}-type`}
          render={({ field }) => (
            <Form.NewSelect
              {...field}
              marginTop={-10}
              options={[blankTypeOption].concat(
                getTypeOptions(orderedLineItemTypes)
              )}
              popoverMinWidth={280}
            />
          )}
        />
      </Table.TextCell>
      <Table.TextCell>
        <Popover
          content={
            <Pane height={orderedLineItemCategories.length * 35} width={266}>
              {getTypeOptions(orderedLineItemCategories).map((cat, index) => {
                const useDivider = index < orderedLineItemCategories.length - 1;
                return (
                  <Controller
                    control={control}
                    name={`${lineItemId}-${cat.value}`}
                    render={({ field }) => (
                      <Fragment>
                        <Pane
                          alignItems="center"
                          display="flex"
                          marginLeft={majorScale(2)}
                          marginY={
                            index === 0 ? -minorScale(1) : -majorScale(1)
                          }
                        >
                          <Checkbox {...field} checked={field.value} />
                          <Text
                            onClick={() =>
                              setValue(field.name, !field.value, {
                                shouldDirty: true,
                              })
                            }
                            marginLeft={majorScale(1)}
                          >
                            {cat.label}
                          </Text>
                        </Pane>
                        {useDivider && (
                          <Divider
                            color={dividerColor}
                            height={1}
                            marginLeft={majorScale(2)}
                          />
                        )}
                      </Fragment>
                    )}
                  />
                );
              })}
            </Pane>
          }
          position={Position.BOTTOM_LEFT}
        >
          <Pane display="flex" width="100%">
            <CategoryInput lineItemId={lineItemId} form={form} />
            <IconButton
              borderBottomLeftRadius={0}
              borderTopLeftRadius={0}
              borderBottomRightRadius={5}
              borderTopRightRadius={5}
              icon={CaretDownIcon}
              type="button"
            />
          </Pane>
        </Popover>
      </Table.TextCell>
      <Table.TextCell>
        <Controller
          control={control}
          name={`${lineItemId}-retainage`}
          render={({ field }) => (
            <MaskedInput
              {...field}
              disabled={lockRetainageEditing}
              textAlign="center"
              type="percentage"
              width={90}
            />
          )}
        />
      </Table.TextCell>
    </Table.Row>
  );
}

function CategoryInput({ lineItemId, form }) {
  const { control } = form;
  const fieldsToWatch = orderedLineItemCategories.map(
    (cat) => `${lineItemId}-${cat}`
  );
  useWatch({ control, name: fieldsToWatch });

  return (
    <TextInput
      borderBottomLeftRadius={5}
      borderTopLeftRadius={5}
      borderBottomRightRadius={0}
      borderTopRightRadius={0}
      onChange={() => {}}
      value={formatSelectedCategories({
        lineItemId,
        form,
      })}
      width="100%"
    />
  );
}

function BulkTypeSelect({ form, lineItemIds }) {
  const { control } = form;
  const values = useWatch({ control });
  const lineItemValues = pickBy(
    values,
    (v, k) =>
      includes(lineItemIds, k.substring(0, 36)) && k.substring(37) === "type"
  );

  const bulkType = find(orderedLineItemTypes.concat(null), (lineItemType) =>
    every(lineItemValues, (type, _key) => type === lineItemType)
  );

  return (
    <Pane onClick={preventEventBubbling}>
      <SelectMenu
        closeOnSelect
        hasFilter={false}
        hasTitle={false}
        height={100}
        onSelect={({ value }) => {
          bulkAssign({
            value,
            lineItemIds,
            field: BULK_ASSIGN.TYPE,
            form,
          });
        }}
        options={[blankTypeOption].concat(getTypeOptions(orderedLineItemTypes))}
      >
        <Pane display="flex" width="100%">
          <TextInput
            borderBottomLeftRadius={5}
            borderTopLeftRadius={5}
            borderBottomRightRadius={0}
            borderTopRightRadius={0}
            onChange={() => {}}
            value={t(`lineItemTypes.${bulkType}`)}
            width="100%"
          />
          <IconButton
            borderBottomLeftRadius={0}
            borderTopLeftRadius={0}
            borderBottomRightRadius={5}
            borderTopRightRadius={5}
            icon={CaretDownIcon}
            type="button"
          />
        </Pane>
      </SelectMenu>
    </Pane>
  );
}

function BulkRetainageInput({ disabled, form, lineItemIds }) {
  const [editing, setEditing] = useState(false);
  const [userText, setUserText] = useState("");

  const { control, getValues } = form;
  const fieldsToWatch = lineItemIds.map((id) => `${id}-retainage`);
  useWatch({ control, name: fieldsToWatch });
  const values = getValues();
  const lineItemRetainages = fieldsToWatch.map((field) =>
    parseFloat(values[field])
  );

  const commonRetainage =
    lineItemRetainages.length === 0
      ? null
      : lineItemRetainages.reduce((acc, retainage) =>
          acc === retainage ? retainage : null
        );
  let fieldValue;
  if (editing) {
    fieldValue = userText;
  } else if (commonRetainage === null) {
    fieldValue = "(mixed)";
  } else {
    fieldValue = `${commonRetainage}%`;
  }

  return (
    <Pane onClick={preventEventBubbling}>
      <TextInput
        disabled={disabled}
        onBlur={({ target: { value } }) => {
          if (editing) {
            setEditing(false);
            bulkAssign({
              value,
              lineItemIds,
              field: BULK_ASSIGN.RETAINAGE,
              form,
            });
          }
        }}
        onChange={({ target: { value } }) => {
          if (!editing) setEditing(true);
          setUserText(value);
        }}
        textAlign="center"
        width={90}
        value={fieldValue}
      />
    </Pane>
  );
}

function BulkCategorySelect({ form, lineItemIds }) {
  const theme = useContext(ThemeContext);
  const {
    defaultColors: { dividerColor },
  } = theme;
  const { control, getValues } = form;
  const values = getValues();

  const fieldsToWatch = lineItemIds.reduce(
    (acc, id) => [
      ...acc,
      ...orderedLineItemCategories.reduce(
        (acc, cat) => [...acc, `${id}-${cat}`],
        []
      ),
    ],
    []
  );

  useWatch({ control, name: fieldsToWatch });

  const lineItemCategories = lineItemIds.map((lineItemId) =>
    orderedLineItemCategories.reduce(
      (acc, cat) => ({
        ...acc,
        [cat]: values[`${lineItemId}-${cat}`],
      }),
      {}
    )
  );

  // this reduce needs to start by comparing element 1 and 2, the strange ternary is
  // for a rare case we've had where a budget has no line items and this reduce bombs
  // shouldn't be possible in theory but we've seen it twice
  const bulkShape =
    lineItemCategories.length > 0
      ? lineItemCategories.reduce((acc, lineItem) => {
          orderedLineItemCategories.forEach((cat) => {
            if (acc[cat] !== lineItem[cat]) {
              acc[cat] = "indeterminate";
            }
          });
          return acc;
        })
      : {};

  const displayText = getBulkCategoryText(bulkShape);

  return (
    <Pane onClick={preventEventBubbling}>
      <Popover
        content={
          <Pane height={orderedLineItemCategories.length * 35} width={266}>
            {getTypeOptions(orderedLineItemCategories).map((cat, index) => {
              const checked = !!bulkShape[cat.value];
              const indeterminate = bulkShape[cat.value] === "indeterminate";
              const nextValue = indeterminate ? true : !checked;
              const useDivider = index < orderedLineItemCategories.length - 1;
              const onClick = () =>
                bulkAssign({
                  value: {
                    category: cat.value,
                    nextValue,
                  },
                  lineItemIds,
                  field: BULK_ASSIGN.CATEGORIES,
                  form,
                });
              return (
                <Fragment>
                  <Pane
                    alignItems="center"
                    display="flex"
                    marginLeft={majorScale(2)}
                    marginY={index === 0 ? -minorScale(1) : -majorScale(1)}
                  >
                    <Checkbox
                      checked={checked}
                      indeterminate={indeterminate}
                      onClick={onClick}
                    />
                    <Text marginLeft={majorScale(1)} onClick={onClick}>
                      {cat.label}
                    </Text>
                  </Pane>
                  {useDivider && (
                    <Divider
                      color={dividerColor}
                      height={1}
                      marginLeft={majorScale(2)}
                    />
                  )}
                </Fragment>
              );
            })}
          </Pane>
        }
        position={Position.BOTTOM_LEFT}
      >
        <Pane display="flex" width="100%">
          <TextInput
            borderBottomLeftRadius={5}
            borderTopLeftRadius={5}
            borderBottomRightRadius={0}
            borderTopRightRadius={0}
            onChange={() => {}}
            value={displayText}
            width="100%"
          />
          <IconButton
            borderBottomLeftRadius={0}
            borderTopLeftRadius={0}
            borderBottomRightRadius={5}
            borderTopRightRadius={5}
            icon={CaretDownIcon}
            type="button"
          />
        </Pane>
      </Popover>
    </Pane>
  );
}

export function LineItemSettings({ divisions, form, lineItemIds, recentDraw }) {
  const [expandedDivisions, setExpandedDivisions] = useState([]);
  const hasNoDraws = recentDraw === null;
  const lockRetainageEditing =
    !hasNoDraws && includes(["FUNDED", "SUBMITTED"], recentDraw.state);
  const drawName = get(recentDraw, "name");
  const retainageText = `Expected Retainage${
    !hasNoDraws ? ` on ${drawName}` : ""
  }`;
  let retainageTooltip;
  if (lockRetainageEditing) {
    const drawState = recentDraw.state === "FUNDED" ? "funded" : "submitted";
    retainageTooltip = t(
      "lineItemTypes.editRetainageTooltip.recentDrawNotEditable",
      { drawName, drawState }
    );
  } else if (hasNoDraws) {
    retainageTooltip = t("lineItemTypes.editRetainageTooltip.hasNoDraws");
  } else {
    retainageTooltip = t(
      "lineItemTypes.editRetainageTooltip.recentDrawEditable",
      { drawName }
    );
  }
  return (
    <Fragment>
      <DescriptionWithHelpLink
        purpose="line-item-types help"
        helpLink="https://help.rabbet.com/en/articles/2554529-edit-project-settings"
        text={t("projectSettings.lineItemTypes")}
      />
      <Divider />
      <Pane borderLeft="muted" borderRight="muted" maxWidth={1000}>
        <Table paddingBottom={0}>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell width={260}>Line Item</Table.TextHeaderCell>
              <Table.TextHeaderCell width={180}>Type</Table.TextHeaderCell>
              <Table.TextHeaderCell width={260}>Category</Table.TextHeaderCell>
              <Table.TextHeaderCell tooltip={retainageTooltip} width={110}>
                {retainageText}
              </Table.TextHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {divisions.map(({ id: divisionId, name, lineItems }) => {
              const divisionExpanded = includes(expandedDivisions, divisionId);

              return (
                <Fragment key={divisionId}>
                  <Table.SectionHeader
                    height={majorScale(5)}
                    onClick={() =>
                      setExpandedDivisions(xor(expandedDivisions, [divisionId]))
                    }
                  >
                    <Table.TextSectionHeaderCell>
                      <Pane display="flex" alignItems="center">
                        {divisionExpanded ? (
                          <ChevronDownIcon marginRight={minorScale(1)} />
                        ) : (
                          <ChevronRightIcon marginRight={minorScale(1)} />
                        )}
                        {name}
                      </Pane>
                    </Table.TextSectionHeaderCell>
                    <Table.TextSectionHeaderCell>
                      <BulkTypeSelect
                        form={form}
                        lineItemIds={lineItemIds[divisionId]}
                      />
                    </Table.TextSectionHeaderCell>
                    <Table.TextSectionHeaderCell>
                      <BulkCategorySelect
                        form={form}
                        lineItemIds={lineItemIds[divisionId]}
                      />
                    </Table.TextSectionHeaderCell>
                    <Table.TextSectionHeaderCell>
                      <BulkRetainageInput
                        form={form}
                        disabled={lockRetainageEditing}
                        lineItemIds={lineItemIds[divisionId]}
                      />
                    </Table.TextSectionHeaderCell>
                  </Table.SectionHeader>
                  {divisionExpanded &&
                    lineItems.map(({ id: lineItemId, name }) => (
                      <LineItemRow
                        lockRetainageEditing={lockRetainageEditing}
                        key={lineItemId}
                        lineItemId={lineItemId}
                        name={name}
                        form={form}
                      />
                    ))}
                </Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </Pane>
    </Fragment>
  );
}
