import { Pane, Button } from "components/materials";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { STEPS } from "./utils";
import { Header } from "./Header";

export function DeveloperHasFundingSourcesStep({
  goBack,
  updateOnboardingData,
  navigateToStep,
}) {
  function handleNext(hasFundingSources) {
    if (hasFundingSources) navigateToStep(STEPS.FUNDING_SOURCES);
    else
      updateOnboardingData({
        automaticAllocationEnabled: false,
        fundingSourceGroups: [],
        usesOfFundsEnabled: false,
      }).then(() => navigateToStep(STEPS.CONFIRM_PROJECT_SETUP));
  }

  return (
    <Pane>
      <Header header={t("onboardingWizard.developerHasFundingSources")} />
      <Pane display="flex" marginBottom={majorScale(3)}>
        <Button
          appearance="primary"
          marginRight={majorScale(2)}
          onClick={() => handleNext(true)}
          width="20%"
        >
          Yes
        </Button>
        <Button
          appearance="primary"
          onClick={() => handleNext(false)}
          width="20%"
        >
          No
        </Button>
      </Pane>

      <Button
        onClick={() => {
          goBack();
        }}
      >
        Back
      </Button>
    </Pane>
  );
}
