import {
  asyncWithLDProvider,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { ReactNode, useMemo } from "react";
import * as Sentry from "@sentry/react";

const clientSideID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID!;

export const useFeatureFlags = () => {
  const ldClient = useLDClient();
  const ret = useMemo(
    () => ({
      isEnabled: (flag: string, defaultValue: boolean = false): boolean =>
        ldClient?.variation(flag, defaultValue),
      variation: <T>(flag: string, defaultValue: T): T =>
        ldClient?.variation(flag, defaultValue),
      identifyUser: (
        attrs: Record<string, unknown>
      ): Promise<Record<string, unknown>> => {
        return ldClient!.identify(attrs).catch((error) => {
          Sentry.captureException(error, { extra: { attrs, clientSideID } });
          return attrs;
        });
      },
    }),
    [ldClient]
  );
  return ret;
};

export const createProvider = (): Promise<
  ({ children }: { children: ReactNode }) => JSX.Element
> =>
  asyncWithLDProvider({
    clientSideID,
    options: { disableSyncEventPost: true },
  });
