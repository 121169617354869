import { useState, useEffect, useMemo, Fragment } from "react";
import { Formik } from "formik";
import { InfoSignIcon } from "evergreen-ui";
import {
  Button,
  Confirm,
  DownloadLink,
  Form,
  Heading,
  Link,
  Pane,
  Paragraph,
  TabNavigation,
  Tab,
  Table,
  Text,
} from "components/materials";
import { UploadPictureModal } from "components/templates";
import {
  add,
  flatten,
  get,
  includes,
  isNull,
  map,
  sortBy,
  values,
} from "lodash";
import { NavigationWarnings } from "helpers/behaviors";
import {
  dateFormToServer,
  formatIsoToTime,
  formatTimeToISO,
  formatDateTime,
} from "helpers/dateHelpers";
import validImageTypes from "helpers/validInspectionImageTypes";
import formatAddress from "helpers/formatAddress";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import { divide, multiply, subtract } from "helpers/math";
import { majorScale } from "helpers/utilities";
import { parseCurrencyToFloat } from "helpers/parseCurrencyToFloat";
import { parsePercentageToFloat } from "helpers/parsePercentageToFloat";
import t from "helpers/translate";
import isBlank from "helpers/isBlank";
import { v4 as uuid } from "uuid";
import { useAuth } from "../../Auth";

const TABS = {
  details: "Details",
  questionnaire: "Questionnaire",
  scheduleOfValues: "Schedule of Values",
  changeOrders: "Change Orders",
  images: "Images",
};

function DetailsTab({ inspectionReport, project, isInspector }) {
  const address = formatAddress(
    project.streetAddress,
    project.city,
    project.state,
    project.zip
  );

  return (
    <Pane display="flex" width={600}>
      <Pane marginRight={majorScale(5)} width="50%">
        <Form.Input
          label="Inspector Name"
          marginBottom={majorScale(1)}
          name="details.inspectorName"
        />
        <Form.Input
          label="Site Representative"
          marginBottom={majorScale(1)}
          name="details.siteRepresentative"
        />
        <Form.DateInput
          label="Date"
          marginBottom={majorScale(1)}
          name="details.date"
        />
        <Form.TimeInput
          label="Time"
          marginBottom={majorScale(1)}
          name="details.time"
        />
        <Form.Input
          label="Weather"
          marginBottom={majorScale(1)}
          name="details.weather"
        />
      </Pane>
      <Pane width="50%">
        <Paragraph>Project:</Paragraph>
        <Paragraph size={600}>{project.name}</Paragraph>
        <Paragraph marginTop={majorScale(2)}>Address:</Paragraph>
        <Paragraph size={600}>{address || "N/A"}</Paragraph>
        {inspectionReport.inspectorEmail && !isBlank(inspectionReport.notes) && (
          <Fragment>
            <Paragraph marginTop={majorScale(2)}>
              {isInspector ? "Notes from Requester:" : "Notes to Inspector:"}
            </Paragraph>
            <Paragraph size={600}>{inspectionReport.notes}</Paragraph>
          </Fragment>
        )}
      </Pane>
    </Pane>
  );
}

function QuestionnaireTab({
  previousDrawName,
  previousInspectionReport,
  questions,
}) {
  const topBorderRadiusProps = {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  };
  const bottomBorderRadiusProps = {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  };

  const previousResponses = get(
    previousInspectionReport,
    "responses",
    []
  ).reduce(
    (previous, { inspectionReportQuestionId, response }) => ({
      ...previous,
      [inspectionReportQuestionId]: response,
    }),
    {}
  );

  const showPreviousResponses =
    previousInspectionReport && values(previousResponses).length > 0;

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width={showPreviousResponses ? "80%" : "70%"}
      maxWidth={1200}
      minWidth={600}
    >
      {showPreviousResponses && (
        <Pane display="flex" justifyContent="flex-end" width="100%">
          <Pane
            width="32%"
            background="#E4E7EB"
            padding={majorScale(2)}
            {...topBorderRadiusProps}
          >
            <Paragraph fontWeight={600}>{previousDrawName} Responses</Paragraph>
            <Pane marginTop={majorScale(1)} height={1} background="#425A70" />
          </Pane>
        </Pane>
      )}
      {questions.map(({ id, label, description, fieldType }, index) => {
        const isLastQuestion = index === questions.length - 1;
        return (
          <Pane
            key={id}
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Pane
              width={showPreviousResponses ? "32%" : "48%"}
              paddingBottom={majorScale(2)}
              paddingRight={majorScale(1)}
            >
              {fieldType === "textarea" ? (
                <Form.TextArea
                  name={`responses.${id}`}
                  label={label}
                  labelProps={{ fontWeight: 500 }}
                />
              ) : (
                <Form.Input
                  name={`responses.${id}`}
                  label={label}
                  labelProps={{ fontWeight: 500 }}
                />
              )}
            </Pane>
            <Pane
              width={showPreviousResponses ? "32%" : "48%"}
              paddingTop={20}
              paddingBottom={majorScale(2)}
              paddingX={majorScale(2)}
            >
              <Paragraph color="muted">{description}</Paragraph>
            </Pane>
            {showPreviousResponses && (
              <Pane
                width="32%"
                paddingTop={20}
                background="#E4E7EB"
                paddingBottom={majorScale(2)}
                paddingX={majorScale(2)}
                {...(isLastQuestion ? bottomBorderRadiusProps : {})}
              >
                <Paragraph>{previousResponses[id]}</Paragraph>
              </Pane>
            )}
          </Pane>
        );
      })}
    </Pane>
  );
}

function handleBlurPercentComplete(formikProps, lineItem) {
  const { lineItems } = formikProps.values;

  const lineItemValues = get(lineItems, lineItem.id, {});

  const formattedPercentComplete = get(
    lineItemValues,
    "formattedPercentComplete",
    null
  );

  const percentCompleteAsFloat = parsePercentageToFloat(
    formattedPercentComplete
  );

  const amountComplete = multiply(
    percentCompleteAsFloat,
    lineItem.budgetAmount
  );

  const updatedLineItemValues = isBlank(formattedPercentComplete)
    ? {
        ...lineItems[lineItem.id],
        percentComplete: null,
        formattedPercentComplete: null,
        amountComplete: null,
        inspectionLineItemPercentCompleteRulePassing: isInspectionLineItemPercentCompleteRulePassing(
          null,
          lineItem
        ),
      }
    : {
        ...lineItems[lineItem.id],
        percentComplete: percentCompleteAsFloat,
        formattedPercentComplete,
        amountComplete,
        inspectionLineItemPercentCompleteRulePassing: isInspectionLineItemPercentCompleteRulePassing(
          percentCompleteAsFloat,
          lineItem
        ),
      };
  return formikProps.setValues({
    ...formikProps.values,
    lineItems: {
      ...lineItems,
      [lineItem.id]: updatedLineItemValues,
    },
  });
}

function handleBlurAmountComplete(formikProps, lineItem) {
  const { lineItems } = formikProps.values;

  const lineItemValues = get(lineItems, lineItem.id, {});
  const amountComplete = get(lineItemValues, "amountComplete", null);

  const percentCompleteAsFloat =
    lineItem.budgetAmount !== 0
      ? divide(amountComplete, lineItem.budgetAmount)
      : null;
  const formattedPercentComplete = formatPercent(
    percentCompleteAsFloat,
    null,
    1
  );

  const updatedLineItemValues = isBlank(amountComplete)
    ? {
        percentComplete: null,
        formattedPercentComplete: null,
        inspectionLineItemPercentCompleteRulePassing: isInspectionLineItemPercentCompleteRulePassing(
          null,
          lineItem
        ),
      }
    : {
        percentComplete: percentCompleteAsFloat,
        formattedPercentComplete,
        inspectionLineItemPercentCompleteRulePassing: isInspectionLineItemPercentCompleteRulePassing(
          percentCompleteAsFloat,
          lineItem
        ),
      };

  return formikProps.setValues({
    ...formikProps.values,
    lineItems: {
      ...lineItems,
      [lineItem.id]: {
        ...lineItemValues,
        ...updatedLineItemValues,
      },
    },
  });
}

function isInspectionLineItemPercentCompleteRulePassing(
  currentPercentComplete,
  lineItem
) {
  // Current Percent Complete covers the case when the previous greatest inspection % complete did not suffice
  // for this draw, and a new satisfactory value was added for this draw, whether or not it has been saved yet
  const currentPercentCompleteWithGrace = (
    currentPercentComplete + 0.01
  ).toFixed(3);
  // Max Percent Complete will encompass inspection reports on draws to date, excluding the current
  // draw's inspection report's saved values
  const maxPercentCompleteToDateWithGrace = (
    lineItem.maxInspectionPercentCompletePrior + 0.01
  ).toFixed(3);
  const grossPercentCompleteRounded = lineItem.grossPercentComplete?.toFixed(3);
  return (
    currentPercentCompleteWithGrace >= grossPercentCompleteRounded ||
    maxPercentCompleteToDateWithGrace >= grossPercentCompleteRounded
  );
}

function getTotalInspectedAmountComplete(lineItems) {
  return Object.entries(lineItems).reduce((total, [_key, lineItem]) => {
    return add(total, parseCurrencyToFloat(lineItem.amountComplete));
  }, 0);
}

function ScheduleOfValuesTab({ draw, formikProps, inspectionRule }) {
  const [
    inspectionReportAmountComplete,
    setInspectionReportAmountComplete,
  ] = useState(() =>
    getTotalInspectedAmountComplete(formikProps.values.lineItems)
  );

  useEffect(() => {
    setInspectionReportAmountComplete(
      getTotalInspectedAmountComplete(formikProps.values.lineItems)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formikProps.values.lineItems)]);

  const { divisions } = draw;

  const hardCostsOnlyDivisions = divisions.reduce((acc, division) => {
    const prunedLineItems = division.lineItems.filter(
      (lineItem) => lineItem.hardCosts === true
    );
    const prunedDivision = {
      ...division,
      lineItems: prunedLineItems,
    };
    if (prunedLineItems.length > 0) {
      return acc.concat([prunedDivision]);
    }
    return acc;
  }, []);

  const isInspectionRuleEnabled = inspectionRule?.enabled;

  const lineItems = useMemo(
    () => hardCostsOnlyDivisions.flatMap((division) => division.lineItems),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(hardCostsOnlyDivisions)]
  );

  const totals = useMemo(
    () => {
      const currencyTotals = lineItems.reduce(
        (
          totals,
          {
            balanceToFundAmount,
            budgetAmount,
            grossRequestedAmount,
            grossRequestedToDateAmount,
            retainageToDateAmount,
          }
        ) => {
          return {
            currentBudget: add(totals.currentBudget, budgetAmount),
            amountRequested: add(totals.amountRequested, grossRequestedAmount),
            amountRequestedToDate: add(
              totals.amountRequestedToDate,
              grossRequestedToDateAmount
            ),
            balanceToFund: add(
              totals.balanceToFund,
              subtract(balanceToFundAmount, retainageToDateAmount)
            ),
            retainageToDateAmount: add(
              totals.retainageToDateAmount,
              retainageToDateAmount
            ),
          };
        },
        {
          currentBudget: 0,
          amountRequested: 0,
          amountRequestedToDate: 0,
          balanceToFund: 0,
          retainageToDateAmount: 0,
        }
      );

      const previousInspectedAmountComplete = lineItems.reduce(
        (total, lineItem) =>
          add(
            total,
            multiply(
              lineItem.maxInspectionPercentCompletePrior ?? 0,
              lineItem.budgetAmount
            )
          ),
        0
      );
      return {
        ...currencyTotals,
        percentRequestedToDate: divide(
          add(
            currencyTotals.amountRequestedToDate,
            currencyTotals.retainageToDateAmount
          ),
          currencyTotals.currentBudget
        ),
        greatestInspectedCompleteToDate: divide(
          previousInspectedAmountComplete,
          currencyTotals.currentBudget
        ),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(lineItems)]
  );

  return (
    <Pane width="80%" maxWidth={1300} minWidth={850}>
      <Table>
        <Table.Head>
          <Table.Row height={40}>
            <Table.TextHeaderCell>Line Item</Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="right">
              Current Budget
            </Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="right">
              Amount Requested (Gross)
            </Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="right">
              Amount Requested to Date (Gross)
            </Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="center">
              % Requested to Date
            </Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="right">
              Balance to Fund (Gross)
            </Table.TextHeaderCell>
            <Table.TextHeaderCell
              textAlign="center"
              tooltip="Excludes the current draw's inspection percentage"
            >
              Greatest Inspection % Complete Prior to Current Draw
            </Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="center">
              To Date Percent Complete
            </Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="center">
              To Date Amount Complete
            </Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="center">
              Notes
            </Table.TextHeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {hardCostsOnlyDivisions.map((division) => {
            return (
              <Fragment>
                <Table.Row key={division.id}>
                  <Table.TextSectionHeaderCell
                    colSpan={10}
                    textProps={{ size: 300 }}
                  >
                    {division.name}
                  </Table.TextSectionHeaderCell>
                </Table.Row>
                {division.lineItems.map((lineItem) => {
                  const inspectionLineItemPercentCompleteRulePassing = get(
                    formikProps.values.lineItems[lineItem.id],
                    "inspectionLineItemPercentCompleteRulePassing",
                    true
                  );

                  return (
                    <Table.Row key={lineItem.id}>
                      <Table.TextCell paddingLeft={majorScale(2)}>
                        {lineItem.name}
                      </Table.TextCell>
                      <Table.TextCell textAlign="right">
                        {formatCurrency(lineItem.budgetAmount)}
                      </Table.TextCell>
                      <Table.TextCell textAlign="right">
                        {formatCurrency(lineItem.grossRequestedAmount)}
                      </Table.TextCell>
                      <Table.TextCell textAlign="right">
                        {formatCurrency(lineItem.grossRequestedToDateAmount)}
                      </Table.TextCell>
                      <Table.TextCell textAlign="center">
                        {formatPercent(lineItem.grossPercentComplete)}
                      </Table.TextCell>
                      <Table.TextCell textAlign="right">
                        {formatCurrency(
                          subtract(
                            lineItem.balanceToFundAmount,
                            lineItem.retainageToDateAmount
                          )
                        )}
                      </Table.TextCell>
                      <Table.TextCell textAlign="center">
                        {formatPercent(
                          lineItem.maxInspectionPercentCompletePrior
                        )}
                      </Table.TextCell>
                      <Table.TextCell padding={5}>
                        <Form.Input
                          textAlign="center"
                          name={`lineItems.${lineItem.id}.formattedPercentComplete`}
                          type="percentage"
                          backgroundColor={
                            isInspectionRuleEnabled &&
                            !inspectionLineItemPercentCompleteRulePassing
                              ? "#FFE4E3"
                              : null
                          }
                          onBlur={() =>
                            handleBlurPercentComplete(formikProps, lineItem)
                          }
                        />
                      </Table.TextCell>
                      <Table.TextCell padding={5}>
                        <Form.Input
                          textAlign="center"
                          name={`lineItems.${lineItem.id}.amountComplete`}
                          type="currency"
                          backgroundColor={
                            isInspectionRuleEnabled &&
                            !inspectionLineItemPercentCompleteRulePassing
                              ? "#FFE4E3"
                              : null
                          }
                          onBlur={() =>
                            handleBlurAmountComplete(formikProps, lineItem)
                          }
                        />
                      </Table.TextCell>
                      <Table.TextCell padding={5} width={150}>
                        <Form.Input
                          textAlign="center"
                          name={`lineItems.${lineItem.id}.notes`}
                        />
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Fragment>
            );
          })}
          <Table.Row>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="right">
              Total
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="right">
              {formatCurrency(totals.currentBudget)}
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="right">
              {formatCurrency(totals.amountRequested)}
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="right">
              {formatCurrency(totals.amountRequestedToDate)}
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="center">
              {formatPercent(totals.percentRequestedToDate)}
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="right">
              {formatCurrency(totals.balanceToFund)}
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="center">
              {formatPercent(totals.greatestInspectedCompleteToDate)}
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="center">
              {formatPercent(
                divide(inspectionReportAmountComplete, totals.currentBudget)
              )}
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="right">
              {formatCurrency(inspectionReportAmountComplete)}
            </Table.TextCell>
            <Table.TextCell textProps={{ fontWeight: 700 }} textAlign="center">
              -
            </Table.TextCell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Pane>
  );
}

function ChangeOrdersTab({ formikProps }) {
  const { changeOrders } = formikProps.values;
  return (
    <Pane display="flex" flexDirection="column" alignItems="center" width="40%">
      <Heading marginBottom={majorScale(2)} size={500}>
        Review the change orders and leave any comments here.
      </Heading>
      {changeOrders.map((changeOrder, index) => {
        return (
          <Pane
            key={changeOrder.id}
            orderBottom
            paddingY={majorScale(2)}
            width="100%"
          >
            <Pane marginBottom={majorScale(1)}>
              <Link
                purpose="inspection change order download"
                href={changeOrder.file.url}
                fontSize={16}
                fontWeight={500}
              >
                {changeOrder.file.name}
              </Link>
            </Pane>
            <Form.TextArea
              label="Inspector Comments"
              name={`changeOrders.${index}.inspectionNote`}
            />
          </Pane>
        );
      })}
    </Pane>
  );
}

function ImagesTab({ formikProps }) {
  const [open, setOpen] = useState(false);

  const handleUpload = (uploads) => {
    formikProps.setFieldValue(
      "images",
      uploads
        .map((upload) => ({
          upload,
          file: {
            name: upload.name,
            url: URL.createObjectURL(upload),
          },
          inspectionNote: "",
          id: uuid(),
        }))
        .concat(formikProps.values.images)
    );
    setOpen(false);
  };

  return (
    <Pane display="flex" flexDirection="column" alignItems="center" width="50%">
      <Heading marginBottom={majorScale(2)} size={500}>
        First upload all your images. Then you can go through and add your
        notes.
      </Heading>
      <Button
        content="Upload Images"
        marginBottom={majorScale(4)}
        onClick={() => setOpen(true)}
        width="50%"
      />
      {formikProps.values.images.map(({ file: { name, url } }, index) => (
        <Pane
          key={url}
          display="flex"
          flexDirection="column"
          paddingBottom={majorScale(3)}
          borderBottom
          marginBottom={majorScale(3)}
          paddingX={majorScale(8)}
          width="100%"
        >
          <Pane alignSelf="center" width="100%" marginBottom={majorScale(3)}>
            <Paragraph fontWeight={500} size={500}>
              {name}
            </Paragraph>
            <Pane
              height={500}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={url}
                alt={name}
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  width: "auto",
                }}
              />
            </Pane>
          </Pane>
          <Form.TextArea
            name={`images.${index}.inspectionNote`}
            label="Notes"
          />
        </Pane>
      ))}
      {open && (
        <UploadPictureModal
          allowMultiple
          restrictTypes={{
            acceptableTypes: validImageTypes,
            restrictionMessage: "(JPG, JPEG, PNG)",
          }}
          title="Upload Site Images"
          onCancel={() => setOpen(false)}
          onSubmit={handleUpload}
        />
      )}
    </Pane>
  );
}

const getInspectionChangeOrders = ({ documentIds, inspectorEmail, draw }) => {
  const { changeOrders } = draw;
  const sorted = sortBy(changeOrders, "insertedAt").map(
    ({ id, agreement, file }) => ({
      documentId: id,
      agreementId: agreement.id,
      inspectionNote: agreement.inspectionNote,
      file,
    })
  );
  if (isNull(inspectorEmail)) return sorted;
  return sorted.filter(({ documentId }) => includes(documentIds, documentId));
};

const getInspectionImages = ({ documentIds, inspectorEmail, draw }) => {
  const { inspectionImages } = draw;
  const sorted = sortBy(inspectionImages, "insertedAt");
  if (isNull(inspectorEmail)) return sorted;
  return sorted.filter(
    ({ id, isGuestUpload }) => includes(documentIds, id) || isGuestUpload
  );
};

const getInitialValues = ({
  date,
  documentIds,
  draw,
  inspectorEmail,
  inspectorName,
  lineItems,
  questions,
  responses,
  siteRepresentative,
  time,
  weather,
}) => {
  const currentResponses = responses.reduce(
    (current, { inspectionReportQuestionId, response }) => ({
      ...current,
      [inspectionReportQuestionId]: response,
    }),
    {}
  );

  const initialResponses = questions.reduce((initialResponses, { id }) => {
    return id in currentResponses
      ? { ...initialResponses, [id]: currentResponses[id] || "" }
      : initialResponses;
  }, {});

  const drawLineItems = flatten(
    draw.divisions.map((division) => division.lineItems)
  );

  // This is how the line items will be loaded from a saved form
  const formattedLineItems = lineItems.reduce(
    (
      formattedLineItems,
      { drawLineItem, lineItemId, notes, percentComplete }
    ) => ({
      ...formattedLineItems,
      [lineItemId]: {
        notes,
        percentComplete,
        formattedPercentComplete: formatPercent(percentComplete, null, 1),
        amountComplete:
          percentComplete !== null
            ? multiply(drawLineItem.budgetAmount, percentComplete)
            : null,
      },
    }),
    {}
  );

  const initialLineItems = drawLineItems.reduce(
    (initialLineItems, lineItem) => {
      if (lineItem.id in formattedLineItems) {
        // Check the inspection rule here where we actually have the data to do so
        const finalLineItem = {
          ...formattedLineItems[lineItem.id],
          inspectionLineItemPercentCompleteRulePassing: isInspectionLineItemPercentCompleteRulePassing(
            formattedLineItems[lineItem.id].percentComplete,
            lineItem
          ),
        };
        return {
          ...initialLineItems,
          [lineItem.id]: finalLineItem,
        };
      }

      // This is how the line items will initialize for a brand-new form (i.e. Start/Fill Out Inspection Report)
      return {
        ...initialLineItems,
        [lineItem.id]: {
          notes: null,
          percentComplete: null,
          formattedPercentComplete: null,
          amountComplete: null,
          inspectionLineItemPercentCompleteRulePassing: isInspectionLineItemPercentCompleteRulePassing(
            null,
            lineItem
          ),
        },
      };
    },
    {}
  );

  const changeOrders = getInspectionChangeOrders({
    documentIds,
    inspectorEmail,
    draw,
  });

  const images = getInspectionImages({
    documentIds,
    inspectorEmail,
    draw,
  });

  const initialValues = {
    details: {
      date,
      inspectorName,
      siteRepresentative,
      time: formatIsoToTime(time),
      weather,
    },
    responses: initialResponses,
    lineItems: {
      ...initialLineItems,
    },
    changeOrders,
    images,
  };

  return initialValues;
};

const prepareInspectionFormVariables = ({
  details,
  responses,
  lineItems,
  changeOrders,
  images,
  inspectionReport,
}) => {
  const formattedResponses = map(responses, (response, questionId) => ({
    questionId,
    response,
  }));

  const formattedLineItems = Object.entries(lineItems).map(
    ([lineItemId, { notes, percentComplete }]) => ({
      lineItemId,
      percentComplete,
      notes,
    })
  );

  return {
    reportId: inspectionReport.id,
    details: {
      ...details,
      date: dateFormToServer(details.date),
      time: details.time ? formatTimeToISO(details.time) : null,
    },
    responses: formattedResponses,
    lineItems: formattedLineItems,
    changeOrders: changeOrders.map(({ agreementId, inspectionNote }) => ({
      agreementId,
      note: inspectionNote,
    })),
    images: images.map(({ id, inspectionNote, upload }) => ({
      documentId: id,
      note: inspectionNote,
      upload,
    })),
  };
};

const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;
function getDownloadDrawPackageUrl(documentIds, drawId, accessToken) {
  const documentIdString = documentIds.join(",");
  const baseDownloadDrawPackageUrl = `${baseUrl}/download_draw_package/submission/${drawId}/${documentIdString}`;
  // Most inspectors are "guests" and do not have an access token
  const downloadDrawPackageUrl = accessToken
    ? baseDownloadDrawPackageUrl.concat(`?access_token=${accessToken}`)
    : baseDownloadDrawPackageUrl;

  return downloadDrawPackageUrl;
}

export function InspectionReportForm({
  additionalHeading,
  deleteInspectionReport,
  inspectionReport,
  isInspector,
  previousInspectionReport,
  project,
  saveAndSubmitInspectionReport,
  saveInspectionReport,
  saveLoading,
  submitInspectionReport,
  submitLoading,
}) {
  const [selectedTab, setSelectedTab] = useState(TABS.details);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const auth = useAuth();
  const downloadDrawPackageUrl = getDownloadDrawPackageUrl(
    inspectionReport.documentIds,
    project.draw.id,
    auth.getAccessToken()
  );

  // the shorthand rule name to the "All line items' Inspected Completion to Date are greater than their Gross Amount Requested to Date" rule
  const inspectionRule = project.rules.find(
    (rule) => rule.name === "inspection_line_item_percent_complete"
  );

  function handleSave(values, { resetForm }) {
    const variables = prepareInspectionFormVariables({
      ...values,
      inspectionReport,
    });

    saveInspectionReport({ variables }).then(() => resetForm({ values }));
  }

  const handleSaveAndSubmit = ({ values, resetForm }) => {
    const variables = prepareInspectionFormVariables({
      ...values,
      inspectionReport,
    });
    saveAndSubmitInspectionReport({ variables }).then(() =>
      resetForm({ values })
    );
  };

  const headingText = additionalHeading
    ? `Inspection Report - ${additionalHeading}`
    : "Inspection Report";

  const previousDrawName = get(
    previousInspectionReport,
    "draw.name",
    "Previous Inspection Report"
  );

  return (
    <Formik
      initialValues={getInitialValues({
        ...inspectionReport,
        draw: project.draw,
      })}
      onSubmit={handleSave}
    >
      {(formikProps) => {
        return (
          <Fragment>
            <NavigationWarnings dirty={formikProps.dirty} />
            {showConfirmDelete && (
              <Confirm
                isShown
                title="Restart Inspection Report?"
                content={t("inspectionReport.confirmRestart")}
                onCloseComplete={() => setShowConfirmDelete(false)}
                onConfirm={(close) => {
                  deleteInspectionReport({
                    variables: { reportId: inspectionReport.id },
                  });
                  close();
                }}
              />
            )}
            <Pane
              display="flex"
              elevation={1}
              justifyContent="space-between"
              alignItems="center"
              paddingLeft={majorScale(5)}
              paddingRight={majorScale(3)}
            >
              <Pane display="flex" paddingY={majorScale(2)} alignItems="center">
                <Heading size={600}>{headingText}</Heading>
                {!isInspector && (
                  <Button
                    marginLeft={majorScale(2)}
                    onClick={() => setShowConfirmDelete(true)}
                  >
                    Restart Inspection Report
                  </Button>
                )}
              </Pane>
              <Pane>
                {isInspector && inspectionReport.submittedAt && (
                  <Text
                    size={300}
                    fontStyle="italic"
                    marginRight={majorScale(2)}
                  >{`Last submitted at ${formatDateTime(
                    inspectionReport.submittedAt
                  )}`}</Text>
                )}
                {formikProps.dirty && (
                  <Button
                    appearance={isInspector ? "default" : "primary"}
                    isLoading={saveLoading}
                    onClick={formikProps.handleSubmit}
                  >
                    Save
                  </Button>
                )}
                {isInspector && (
                  <Button
                    appearance="primary"
                    marginLeft={majorScale(2)}
                    onClick={() =>
                      formikProps.dirty
                        ? handleSaveAndSubmit(formikProps)
                        : submitInspectionReport()
                    }
                    isLoading={submitLoading}
                  >
                    Submit Inspection
                  </Button>
                )}
              </Pane>
            </Pane>
            <Pane margin={majorScale(3)}>
              <Pane display="flex" justifyContent="space-between">
                <TabNavigation>
                  {Object.keys(TABS).reduce((tabs, tab) => {
                    const hasNoQuestions =
                      TABS[tab] === TABS.questionnaire &&
                      inspectionReport.questions.length === 0;
                    const hasNoChangeOrders =
                      TABS[tab] === TABS.changeOrders &&
                      formikProps.initialValues.changeOrders.length === 0;

                    return hasNoQuestions || hasNoChangeOrders
                      ? tabs
                      : [
                          ...tabs,
                          <Tab
                            key={TABS[tab]}
                            isSelected={selectedTab === TABS[tab]}
                            onSelect={() => setSelectedTab(TABS[tab])}
                          >
                            {TABS[tab]}
                          </Tab>,
                        ];
                  }, [])}
                </TabNavigation>
                <Pane display="flex" alignItems="center">
                  <Text marginRight={majorScale(2)} fontStyle="italic">
                    {t("inspectionReport.sharedFormGuidance")}
                  </Text>
                  {isInspector && (
                    <DownloadLink
                      fontSize={12}
                      url={downloadDrawPackageUrl}
                      label={
                        <Button
                          appearance="default"
                          purpose="inspection-report draw-package download"
                          marginRight={majorScale(2)}
                        >
                          Download Draw Package
                        </Button>
                      }
                    />
                  )}
                  <Link
                    purpose="inspection-report-help"
                    href="https://help.rabbet.com/en/articles/4843431-submitting-inspection-reports"
                    marginTop={4}
                  >
                    <InfoSignIcon color="info" />
                  </Link>
                </Pane>
              </Pane>
              <Form>
                <Pane
                  marginTop={majorScale(4)}
                  display="flex"
                  justifyContent="center"
                  width="100%"
                >
                  {selectedTab === TABS.details && (
                    <DetailsTab
                      inspectionReport={inspectionReport}
                      project={project}
                      isInspector={isInspector}
                    />
                  )}
                  {selectedTab === TABS.questionnaire && (
                    <QuestionnaireTab
                      previousDrawName={previousDrawName}
                      previousInspectionReport={previousInspectionReport}
                      questions={inspectionReport.questions}
                    />
                  )}
                  {selectedTab === TABS.scheduleOfValues && (
                    <ScheduleOfValuesTab
                      draw={project.draw}
                      formikProps={formikProps}
                      inspectionRule={inspectionRule}
                      previousInspectionReport={previousInspectionReport}
                    />
                  )}
                  {selectedTab === TABS.changeOrders && (
                    <ChangeOrdersTab formikProps={formikProps} />
                  )}
                  {selectedTab === TABS.images && (
                    <ImagesTab
                      formikProps={formikProps}
                      images={inspectionReport.images}
                    />
                  )}
                </Pane>
              </Form>
            </Pane>
          </Fragment>
        );
      }}
    </Formik>
  );
}
