import { Table } from "components/materials";

export function TableHeaders() {
  return (
    <Table.Head>
      <Table.Row>
        <Table.TextHeaderCell width="20%">Trade Item</Table.TextHeaderCell>
        <Table.TextHeaderCell width="15%">
          Cost as Per Cost Breakdown (A)
        </Table.TextHeaderCell>
        <Table.TextHeaderCell width="15%">
          Amount Complete (B)
        </Table.TextHeaderCell>
        <Table.TextHeaderCell width="15%">
          For HUD-FHA Use (C)
        </Table.TextHeaderCell>
        <Table.TextHeaderCell width="15%">
          Previous Advance
        </Table.TextHeaderCell>
        <Table.TextHeaderCell width="1%">%</Table.TextHeaderCell>
        <Table.TextHeaderCell width="15%">Net Amount</Table.TextHeaderCell>
      </Table.Row>
    </Table.Head>
  );
}
