import { useQuery } from "@apollo/react-hooks";
import { Loadable, Pane } from "components/materials";
import { BorrowerProjectOverview } from "components/templates";
import { majorScale } from "helpers/utilities";
import { get } from "lodash";
import { BORROWER_PROJECT_OVERVIEW_QUERY } from "./graphql";

export function BorrowerProjectOverviewPage({ match }) {
  const { projectId } = match.params;

  const { data, loading } = useQuery(BORROWER_PROJECT_OVERVIEW_QUERY, {
    variables: { projectId },
  });

  const hasAllowMultipleUnfundedBorrowerDraws = get(
    data,
    "borrower.lenderHasAllowMultipleUnfundedBorrowerDraws",
    false
  );

  if (loading) return <Loadable loading />;

  return (
    <Pane
      display="flex"
      paddingY={majorScale(5)}
      marginX={majorScale(10)}
      width="100vw"
      maxWidth={1450}
      minWidth={1100}
    >
      <Pane width="80%" marginRight={majorScale(2)}>
        <BorrowerProjectOverview
          hasAllowMultipleUnfundedBorrowerDraws={
            hasAllowMultipleUnfundedBorrowerDraws
          }
          project={data.borrower.project}
        />
      </Pane>
    </Pane>
  );
}
