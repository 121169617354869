import { useMemo } from "react";
import { FieldArray } from "formik";
import { CrossIcon } from "evergreen-ui";
import {
  Button,
  FastMaskInput,
  Form,
  Heading,
  IconButton,
  Pane,
  Pill,
  Table,
  Text,
} from "components/materials";
import { AddLineItem } from "components/templates";
import { get, uniqBy } from "lodash";
import { formatCurrency as f } from "helpers/formatCurrency";
import { majorScale, minorScale } from "helpers/utilities";
import { add } from "helpers/math";

function getLineItemName({ division, name, number }) {
  const prefix = number ? `(${number}) ` : "";
  return `${prefix}${name} (${division.name})`;
}

function GrossBudgetChange({ adjustmentsTotal }) {
  const adjustmentsTotalText = `Gross Budget Change: ${f(adjustmentsTotal)}`;

  return adjustmentsTotal === 0 ? (
    <Text fontWeight={600} color="muted" size={300}>
      {adjustmentsTotalText}
    </Text>
  ) : (
    <Pill color="yellow" textTransform="none">
      {adjustmentsTotalText}
    </Pill>
  );
}

function AdjustmentTransactionRow({
  disabled,
  index,
  lineItem,
  options,
  remove,
  transaction,
}) {
  const lineItemSelected = lineItem !== undefined;
  const budgetBefore = lineItemSelected ? lineItem.budgetAmount : "";
  const adjustmentAmount = lineItemSelected ? transaction.amount : "";

  const budgetAfter = lineItemSelected
    ? add(budgetBefore, adjustmentAmount)
    : "";

  return (
    <Table.Row>
      <Table.Cell minWidth={150}>
        <Pane display="flex">
          <IconButton
            appearance="minimal"
            disabled={disabled}
            icon={CrossIcon}
            marginRight={majorScale(1)}
            onClick={() => remove(index)}
            type="button"
          />
          <Form.Select
            disabled={disabled}
            name={`budgetAdjustmentTransactions.${index}.lineItem.id`}
            options={options}
          />
        </Pane>
      </Table.Cell>
      <Table.TextCell width="15%">{f(budgetBefore)}</Table.TextCell>
      <Table.TextCell width="15%">
        <FastMaskInput
          disabled={disabled}
          mask="currency"
          name={`budgetAdjustmentTransactions.${index}.amount`}
        />
      </Table.TextCell>
      <Table.TextCell width="15%">{f(budgetAfter)}</Table.TextCell>
    </Table.Row>
  );
}

export function ECOAdjustmentsFormSection({
  disableForm,
  formikProps,
  lineItems: projectLineItems,
}) {
  const {
    budgetAdjustmentTransactions,
    preparedLineItems,
  } = formikProps.values;
  const { setFieldValue } = formikProps;

  const divisions = uniqBy(
    projectLineItems.map(({ division }) => division),
    ({ id: divisionId }) => divisionId
  );

  const options = useMemo(
    () =>
      preparedLineItems.map((lineItem) => ({
        key: lineItem.id,
        text: getLineItemName(lineItem),
        value: lineItem.id,
      })),
    [preparedLineItems]
  );
  const adjustmentsTotal = budgetAdjustmentTransactions.reduce(
    (total, { amount }) => add(total, amount),
    0
  );

  return (
    <FieldArray name="budgetAdjustmentTransactions">
      {({ push, remove }) => (
        <Pane marginTop={majorScale(2)}>
          <Heading>Budget Adjustments</Heading>
          <Pane marginTop={majorScale(1)}>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.TextHeaderCell>Line Item(s)</Table.TextHeaderCell>
                  <Table.TextHeaderCell>
                    Current Amount Remaining
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell>Change(s)</Table.TextHeaderCell>
                  <Table.TextHeaderCell>
                    Amount Remaining After Adjustment(s)
                  </Table.TextHeaderCell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {budgetAdjustmentTransactions.map((transaction, index) => {
                  const lineItem = preparedLineItems.find(
                    ({ id }) => id === get(transaction, "lineItem.id")
                  );
                  return (
                    <AdjustmentTransactionRow
                      disabled={disableForm}
                      index={index}
                      lineItem={lineItem}
                      options={options.filter(
                        (option) =>
                          option.value === get(lineItem, "id") ||
                          !budgetAdjustmentTransactions.some(
                            (transaction) =>
                              get(transaction, "lineItem.id") === option.value
                          )
                      )}
                      remove={remove}
                      transaction={transaction}
                    />
                  );
                })}
              </Table.Body>
            </Table>
            <Pane
              alignItems="center"
              display="flex"
              justifyContent={disableForm ? "flex-end" : "space-between"}
              marginBottom={majorScale(1)}
            >
              {!disableForm && (
                <Pane>
                  <Button
                    marginRight={minorScale(1)}
                    onClick={() => push({ amount: 0, lineItem: null })}
                  >
                    Add Line Item Adjustment
                  </Button>
                  <AddLineItem
                    divisions={divisions}
                    lineItems={preparedLineItems}
                    onAddLineItem={(values) => {
                      const newLineItemDivision = divisions.find(
                        ({ id: divisionId }) => values.divisionId === divisionId
                      );
                      const newLineItem = {
                        id: values.lineItemId,
                        budgetAmount: 0,
                        division: newLineItemDivision,
                        name: values.lineItemName.trim(),
                        number: values.lineItemNumber,
                        summaryLineItem: values.summaryLineItem,
                        masterFormatDivision: values.masterFormatDivision,
                      };
                      setFieldValue("preparedLineItems", [
                        ...preparedLineItems,
                        { ...newLineItem },
                      ]);
                      push({
                        amount: 0,
                        isNewLineItem: true,
                        lineItem: { ...newLineItem },
                      });
                    }}
                  />
                </Pane>
              )}
              <Pane>
                {budgetAdjustmentTransactions.length > 0 && (
                  <GrossBudgetChange adjustmentsTotal={adjustmentsTotal} />
                )}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      )}
    </FieldArray>
  );
}
