import { sumBy } from "./math";
import { formatCurrency } from "./formatCurrency";

export const getNetAdjustment = (adjustments) =>
  sumBy(adjustments, ({ transactions }) => sumBy(transactions, "amount"));

export const formatAdjustment = (adjustment) => {
  const currency = formatCurrency(adjustment);
  return adjustment > 0 ? `+${currency}` : currency;
};
