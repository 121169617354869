import gql from "graphql-tag";

export default gql`
  fragment lineItemDocumentation on LineItemDocumentation {
    id
    amount
    retainageAmount
    retainageToDateAmount
    document {
      id
      file {
        url
        name
      }
      type

      ... on Invoice {
        number
      }
    }
  }
`;
