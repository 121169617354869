import gql from "graphql-tag";
import { DRAW_DIVISIONS_FRAGMENT } from "helpers/fragments";

export const PROJECT_FRAGMENT = gql`
  fragment DrawBudgetPageProjectFragment on Project {
    id
    acres
    amount
    squareFeet
    usesOfFundsEnabled
  }
`;

export const DRAW_FRAGMENT = gql`
  fragment DrawBudgetPageDrawFragment on Draw {
    id
    budgetAdjustments {
      id
      transactions {
        id
        amount
      }
    }
    requestedAmount
    requestedToDateAmount
    ...DrawDivisionsFragment
    fundingSources {
      id
      scopeId
      label
      type
      amount
      disbursedAmount
      disbursedToDateAmount
      organization {
        id
        name
      }
    }
  }
  ${DRAW_DIVISIONS_FRAGMENT}
`;

export const DRAW_BUDGET_PAGE_QUERY = gql`
  query DrawBudgetPageQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      ...DrawBudgetPageProjectFragment
      draw(id: $drawId) {
        ...DrawBudgetPageDrawFragment
      }
    }
  }
  ${PROJECT_FRAGMENT}
  ${DRAW_FRAGMENT}
`;

export const DOCUMENT_SUBSCRIPTION = gql`
  subscription onDocumentUpdate($projectId: String!) {
    projectDocumentUpdated(projectId: $projectId) {
      id
      draw {
        id
      }
    }
  }
`;

export const ISSUES_SUBSCRIPTION = gql`
  subscription drawIssuesUpdated($drawId: String!) {
    drawIssuesUpdated(drawId: $drawId) {
      id
      lineItems {
        id
        scopeId
        issues {
          id
          name
          severity
        }
      }
    }
  }
`;

export const SUBMISSION = gql`
  query DrawBudgetPageSubmission($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      sourceDraw {
        ...DrawBudgetPageDrawFragment
        project {
          ...DrawBudgetPageProjectFragment
        }
      }
    }
  }
  ${PROJECT_FRAGMENT}
  ${DRAW_FRAGMENT}
`;
