import gql from "graphql-tag";
import { Query } from "@apollo/react-components";
import { DocumentsViewer } from "components/templates";
import { Loadable } from "components/materials";
import {
  INVOICE_FRAGMENT,
  LIEN_RELEASE_FRAGMENT,
  PAY_APPLICATION_FRAGMENT,
  REVIEW_DOCUMENT_FRAGMENT,
} from "helpers/fragments";
import { get } from "lodash";

const QUERY = gql`
  query SubmissionDocumentQuery(
    $submissionId: String!
    $documentId: String!
    $drawDocumentIdsToExclude: [String]
  ) {
    submission(submissionId: $submissionId) {
      id
      sourceDraw {
        id
        name
        project {
          id
          document(id: $documentId) {
            id
            type
            vendor {
              id
              name
            }
            file {
              name
              type
              url
            }
            ...InvoiceFragment
            ...LienReleaseFragment
            ...PayApplicationFragment
            ...ReviewDocumentFragment
          }
        }
      }
    }
  }
  ${INVOICE_FRAGMENT}
  ${LIEN_RELEASE_FRAGMENT}
  ${PAY_APPLICATION_FRAGMENT}
  ${REVIEW_DOCUMENT_FRAGMENT}
`;

export function SubmissionDocument(props) {
  const { documentId, submissionId } = props.match.params;

  const handleClose = () => {
    const url = `/submissions/${submissionId}/${props.match.params[0]}`;
    props.history.replace(url);
  };

  const renderContent = (query) => {
    if (query.loading) return <Loadable loading />;

    const sourceDraw = get(query.data, "submission.sourceDraw");
    const project = get(sourceDraw, "project");

    return (
      <DocumentsViewer
        projectName={project.name}
        document={project.document}
        draws={[sourceDraw]}
        vendors={[document.vendor]}
        onClose={handleClose}
      />
    );
  };

  return (
    <Query
      query={QUERY}
      variables={{ documentId, drawDocumentIdsToExclude: [], submissionId }}
    >
      {renderContent}
    </Query>
  );
}
