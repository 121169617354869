import PropTypes from "prop-types";
import { Position, Text, Tooltip, withTheme } from "evergreen-ui";
import TableSectionHeaderCell, {
  propTypes as sectionHeaderCellPropTypes,
} from "./SectionHeaderCell";
import { getProps, compose, withMemo } from "./utils";

const TableTextSectionHeaderCell = compose(
  ({ cellProps, children, content, textProps = {}, ...props }) => {
    textProps = {
      fontWeight: props.theme.fontWeights.MEDIUM,
      size: 300,
      ...textProps,
    };

    const getOverflowProps = () => {
      if (!props.maxWidth) return {};

      return {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
    };

    const tableCellProps = {
      ...props,
      ...cellProps,
      ...getOverflowProps(),
    };

    const cellContent = <Text {...textProps}>{content || children}</Text>;

    return (
      <TableSectionHeaderCell {...tableCellProps}>
        {props.maxWidth ? (
          <Tooltip
            content={content || children}
            position={Position.BOTTOM_LEFT}
          >
            {cellContent}
          </Tooltip>
        ) : (
          cellContent
        )}
      </TableSectionHeaderCell>
    );
  },
  [withMemo, withTheme]
);

export const propTypes = {
  ...sectionHeaderCellPropTypes,
  children: PropTypes.node,
  content: PropTypes.node,
  textProps: PropTypes.object,
};

TableTextSectionHeaderCell.propTypes = propTypes;

// TODO: remove dot notation
TableTextSectionHeaderCell.getProps = (props) => getProps(propTypes, props);

export default TableTextSectionHeaderCell;
