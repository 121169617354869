import gql from "graphql-tag";

export const ADJUSTMENTS_OVERLAY_QUERY = gql`
  query AdjustmentsOverlayQuery($drawId: String!, $projectId: String!) {
    project(id: $projectId) {
      id
      name
      draws {
        id
        isLockedDown
        name
      }
      lineItems {
        id
        scopeId
        name
        number
        budgetAmount
        division {
          id
          scopeId
          name
        }
      }
      vendors {
        id
        name
      }
      agreements(onlyChangeOrders: true) {
        id
        correlatedAgreementTypes
        correlatedDocuments {
          id
        }
        correlationId
        agreementDescription
        type
        startDate
        agreementNumber
        changeOrderReason
        daysImpacted
        name
        userTouchedName
        lineItemNames
        amount
        adjustmentAmount
        lineItems {
          id
          amount
          lineItemId
          retainagePercentage
        }
        vendor {
          id
          name
        }
        document {
          id
        }
      }
      agreementVendorLineItems {
        id
        scopeId
        agreementsAmount
      }
    }
    draw(id: $drawId) {
      id
      name
      isLockedDown
      budgetAdjustments {
        id
        comment
        transactions {
          id
          amount
          lineItem {
            id
            scopeId
          }
        }
        agreement {
          id
          agreementNumber
          daysImpacted
          agreementDescription
          name
          userTouchedName
          startDate
          vendor {
            id
            name
          }
          document {
            id
            drawId
            file {
              url
              name
            }
          }
        }
      }
      divisions {
        id
        scopeId
        name
      }
      lineItems {
        id
        scopeId
        name
        balanceToFundAmount
        budgetAmount
        originalBudgetAmount
        number
        division {
          id
          scopeId
          name
        }
        previousLineItem {
          id
          scopeId
          budgetAmount
        }
        projection {
          id
        }
        rules {
          id
          scopeId
          enabled
          name
          state
          type
        }
      }
    }
  }
`;

export const CREATE_ADJUSTMENT = gql`
  mutation CreateBudgetAdjustment(
    $drawId: String!
    $projectId: String!
    $comment: String
    $transactions: [BudgetAdjustmentTransactionInput]
    $newLineItems: [NewLineItemInput]
  ) {
    createBudgetAdjustment(
      drawId: $drawId
      projectId: $projectId
      comment: $comment
      transactions: $transactions
      newLineItems: $newLineItems
    ) {
      id
    }
  }
`;

export const UPDATE_ADJUSTMENT = gql`
  mutation UpdateBudgetAdjustment(
    $adjustmentId: String!
    $projectId: String!
    $comment: String
    $transactions: [BudgetAdjustmentTransactionInput]
    $newLineItems: [NewLineItemInput]
  ) {
    updateBudgetAdjustment(
      adjustmentId: $adjustmentId
      projectId: $projectId
      comment: $comment
      transactions: $transactions
      newLineItems: $newLineItems
    ) {
      id
    }
  }
`;

export const DELETE_ADJUSTMENT = gql`
  mutation DeleteBudgetAdjustment($adjustmentId: String!) {
    deleteBudgetAdjustment(adjustmentId: $adjustmentId) {
      status
    }
  }
`;
