import { Form, Pane } from "components/materials";
import { formatNumber } from "helpers/formatNumber";
import unformatNumber from "helpers/unformatNumber";
import { majorScale } from "helpers/utilities";
import { numberComparator } from "helpers/comparators";
import { COMPARATORS } from "../FastDataTableUtils";

const options = [
  COMPARATORS.GREATER_THAN,
  COMPARATORS.LESS_THAN,
  COMPARATORS.EQUAL,
  COMPARATORS.NOT_EQUAL,
];

export const numberColumnDefaults = {
  aggregate: () => null,
  aggregateFormatter: (value, _items) => formatNumber(value),
  filterControl: (index) => (
    <Pane display="flex">
      <Form.Select name={`filters.${index}.operator`} options={options} />
      <Form.Input
        name={`filters.${index}.input`}
        type="number"
        marginLeft={majorScale(1)}
      />
    </Pane>
  ),
  filterFormatter: (fc, column) =>
    `${column.header}: ${COMPARATORS[fc.operator].text} ${fc.input}`,
  filterStrategy: (value, fc) => {
    const lhs = value;
    const rhs = unformatNumber(fc.input);
    switch (fc.operator) {
      case COMPARATORS.GREATER_THAN.value:
        return lhs > rhs;
      case COMPARATORS.LESS_THAN.value:
        return lhs < rhs;
      case COMPARATORS.EQUAL.value:
        return lhs === rhs;
      case COMPARATORS.NOT_EQUAL.value:
        return lhs !== rhs;
      default:
        return true;
    }
  },
  sortStrategy: numberComparator,
  textAlign: "right",
  type: "number",
  valueFormatter: (value, _item) => formatNumber(value),
  width: 150,
};
