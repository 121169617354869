import { useEffect, useState, Fragment } from "react";
import { Formik, FieldArray } from "formik";
import {
  Accordion,
  Button,
  Confirm,
  Form,
  Pane,
  Paragraph,
  Table,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { set } from "lodash";
import isBlank from "helpers/isBlank";
import { configurationPanelStyles } from "./helpers";
import { EditConfigurationButtons } from "./EditConfigurationButtons";

export const PANEL_KEY = "projectRegions";

export function ProjectRegions({
  dirtyPanels,
  setPanelDirty,
  onSubmit,
  loading,
  organization,
  toggle,
  expandedPanelKeys,
}) {
  return (
    <Formik
      initialValues={{ projectRegions: organization.projectRegions }}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {(form) => (
        <ProjectRegionsPanel
          dirtyPanels={dirtyPanels}
          setPanelDirty={setPanelDirty}
          form={form}
          loading={loading}
          toggle={toggle}
          expandedPanelKeys={expandedPanelKeys}
        />
      )}
    </Formik>
  );
}

function ProjectRegionsPanel({
  form,
  loading,
  expandedPanelKeys,
  toggle,
  dirtyPanels,
  setPanelDirty,
}) {
  const dirty = dirtyPanels[PANEL_KEY];
  const open = !!expandedPanelKeys[PANEL_KEY];

  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(PANEL_KEY, isDirty);
    }
    return undefined;
  }, [dirty, form, setPanelDirty]);

  return (
    <Accordion.Panel
      panelKey={PANEL_KEY}
      title="Project Regions"
      onClick={() => toggle(PANEL_KEY)}
      open={open}
      actionContent={
        <EditConfigurationButtons dirty={dirty} form={form} loading={loading} />
      }
      {...configurationPanelStyles}
    >
      <FieldArray name="projectRegions">
        {({ push, remove }) => (
          <ProjectRegionsForm form={form} push={push} remove={remove} />
        )}
      </FieldArray>
    </Accordion.Panel>
  );
}

function ProjectRegionsForm({ form, push, remove }) {
  const [deleteConfirmRegion, setDeleteConfirmRegion] = useState(null);

  if (form.values.projectRegions.length === 0) {
    return (
      <Pane>
        <Paragraph size={400} marginBottom={majorScale(2)}>
          Set the Project Regions you would like to make available for use
        </Paragraph>
        <Button
          appearance="primary"
          content="Add Project Region"
          onClick={() => push(getBlankRegion())}
          purpose="region blank add"
        />
      </Pane>
    );
  }

  return (
    <Fragment>
      <Form>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell>Region</Table.TextHeaderCell>
              <Table.TextHeaderCell width={150} />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Fragment>
              {form.values.projectRegions.map((region, index) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <Form.Input name={`projectRegions[${index}].region`} />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => {
                          if (region.hasBeenUsed)
                            setDeleteConfirmRegion({ ...region, index });
                          else remove(index);
                        }}
                      >
                        Delete Region
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              <Pane marginTop={majorScale(2)}>
                <Button
                  appearance="primary"
                  onClick={() => push(getBlankRegion())}
                >
                  Add Project Region
                </Button>
              </Pane>
            </Fragment>
          </Table.Body>
        </Table>
      </Form>
      {deleteConfirmRegion && (
        <Confirm
          open
          content={`"${deleteConfirmRegion.region}" has been assigned to at least one project in the organization. Do you wish to delete this region?`}
          header="Warning"
          onCloseComplete={() => setDeleteConfirmRegion(null)}
          onConfirm={(close) => {
            remove(deleteConfirmRegion.index);
            close();
          }}
        />
      )}
    </Fragment>
  );
}

function getBlankRegion() {
  return {
    id: null,
    region: "",
    hasBeenUsed: false,
  };
}

function validate(values) {
  const errors = {};

  values.projectRegions.forEach(({ region }, index) => {
    if (isBlank(region)) {
      set(errors, `projectRegions[${index}].region`, "Region cannot be blank.");
    }

    const isDuplicate =
      values.projectRegions.filter(
        (projectRegion) => projectRegion.region.trim() === region.trim()
      ).length > 1;

    if (isDuplicate) {
      set(
        errors,
        `projectRegions[${index}].region`,
        "Project Region cannot be used more than once"
      );
    }
  });

  return errors;
}
