import PropTypes from "prop-types";
import { get } from "lodash";
import { connect, getIn } from "formik";
import { InnerSelect } from "./InnerSelect";

function Select({
  name,
  options,
  value,
  label,
  labelProps,
  disabled: isDisabled,
  isHighlighted,
  isWarned,
  isLoading,
  hasSuggestions,
  noNull,
  onAddItem,
  addName,
  formik = {},
  onChange,
  placeholder,
  onFilter,
  popoverMinWidth,
  fullBorder,
  testId,
  backgroundColor,
  outerProps,
  textProps,
  height,
  isRequired,
  // all other props are spread onto the box wrapping the combobox input + button
  ...comboboxProps
}) {
  const disabled =
    isDisabled ||
    getIn(formik.values, "__disabled") ||
    get(formik, "status.disabled");
  const formikValue = getIn(formik.values, name);
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const hasError = !!error && !!touch;

  return (
    <InnerSelect
      name={name}
      options={options}
      value={value}
      form={formik}
      formValue={formikValue}
      label={label}
      labelProps={labelProps}
      disabled={disabled}
      isHighlighted={isHighlighted}
      hasError={hasError}
      error={error}
      isWarned={isWarned}
      isLoading={isLoading}
      hasSuggestions={hasSuggestions}
      noNull={noNull}
      onAddItem={onAddItem}
      addName={addName}
      onChange={onChange}
      placeholder={placeholder}
      onFilter={onFilter}
      popoverMinWidth={popoverMinWidth}
      fullBorder={fullBorder}
      testId={testId}
      outerProps={{ ...outerProps, backgroundColor }}
      textProps={textProps}
      height={height}
      isRequired={isRequired}
      comboboxProps={comboboxProps}
    />
  );
}

Select.propTypes = {
  addName: PropTypes.string,
  labelProps: PropTypes.object,
  onFilter: PropTypes.func,
  popoverMinWidth: PropTypes.number,
};

Select.defaultProps = {
  addName: "Add new...",
  labelProps: {},
  onFilter: () => {},
  popoverMinWidth: 360,
};

export default connect(Select);
