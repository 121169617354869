import { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Button, Form, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import isBlank from "helpers/isBlank";
import analytics from "helpers/analytics";

function validate(values) {
  const errors = {};
  if (isBlank(values.body)) errors.body = "Please enter a comment";
  return errors;
}

function onSubmit(mutation, handleDirtyOrFocus, setShowCommentForm) {
  return (values, formikBag) => {
    mutation({ variables: values });
    formikBag.resetForm();
    handleDirtyOrFocus(false);
    if (setShowCommentForm) setShowCommentForm(false);
    analytics.track("Comment Created", {
      hasMentions: !!values.body.match(
        /@\[[^\]]*?\]\([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\)/
      ),
    });
  };
}

export function CommentForm(props) {
  const {
    mutation,
    initialValues,
    onDirtyOrFocus,
    loading,
    showCommentForm,
    setShowCommentForm,
    users,
  } = props;
  // DocumentActivity uses setShowCommentForm to conditionally render this component, but without DocumentActivity this component
  // is always visible. With DocumentActivity the add/cancel buttons should be visible immediately - without it, they should only be visible on focus
  const [dirtyOrFocus, setDirtyOrFocus] = useState(false);
  const { hasOrgLevelPermission } = useContext(UserContext);

  const handleDirtyOrFocus = (buttonsVisible) => {
    onDirtyOrFocus(buttonsVisible);
    setShowCommentForm
      ? setShowCommentForm(buttonsVisible)
      : setDirtyOrFocus(buttonsVisible);
  };

  const handleCancel = () => {
    handleDirtyOrFocus(false);
    if (setShowCommentForm) setShowCommentForm(false);
  };

  if (
    hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS) &&
    !showCommentForm
  )
    return null;

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onReset={handleCancel}
      onSubmit={onSubmit(mutation, handleDirtyOrFocus, setShowCommentForm)}
    >
      {(form) => {
        return (
          <Fragment>
            <Form.MentionsTextArea
              allowSuggestionsAboveCursor
              name="body"
              className="rbt-comment-form"
              data-testid="comment-box"
              onFocus={() => handleDirtyOrFocus(true)}
              onBlur={() => handleDirtyOrFocus(form.dirty)}
              users={users}
            />
            {(dirtyOrFocus || showCommentForm) && (
              <Pane marginY={majorScale(2)}>
                <Button
                  content="Cancel"
                  marginRight={majorScale(1)}
                  onClick={form.handleReset}
                  purpose="comment form cancel"
                />
                <Button
                  appearance="primary"
                  content="Add Comment"
                  error={form.error}
                  isLoading={loading}
                  onClick={form.handleSubmit}
                  type="submit"
                  purpose="comment form submit"
                />
              </Pane>
            )}
          </Fragment>
        );
      }}
    </Formik>
  );
}

CommentForm.propTypes = {
  mutation: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onDirtyOrFocus: PropTypes.func,
  loading: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
};

CommentForm.defaultProps = {
  initialValues: {
    body: "",
  },
  onDirtyOrFocus: () => {},
};
