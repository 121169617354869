import { useCallback, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { isEmpty, get, flatMap } from "lodash";
import {
  Button,
  Card,
  Pane,
  Paragraph,
  Progress,
  Shortener,
} from "components/materials";
import { BRAND_COLORS } from "helpers/colors";
import { add } from "helpers/math";
import { trackPortfolioAnalysis } from "./helpers";
import { PORTFOLIO_VENDORS_QUERY } from "./graphql-queries";
import { HeaderTextCell, PortfolioInsightBlankSlateCard, TextCell } from ".";

export function VendorDiversity({
  history,
  organizationId,
  selectedOrganization,
  stylingProps,
  projects,
}) {
  const { data, loading } = useQuery(PORTFOLIO_VENDORS_QUERY, {
    variables: { organizationId },
  });

  const navigateToVendor = useCallback(
    (vendorId) => {
      trackPortfolioAnalysis(organizationId, "Go to Vendor");
      return history.push(
        `/organizations/${vendorId}?referrerSelectedOrgId=${selectedOrganization.id}`
      );
    },
    [organizationId, selectedOrganization.id, history]
  );

  const vendors = useMemo(() => {
    const filteredProjectIds = projects.map((project) => project.id);
    const filteredProjects = get(
      data,
      "organization.projects",
      []
    ).filter(({ id }) => filteredProjectIds.includes(id));
    const filteredVendorsById = flatMap(filteredProjects, "vendors").reduce(
      (acc, vendor) => ({
        ...acc,
        [vendor.id]: {
          ...vendor,
          projectCount: add(get(acc, `${vendor.id}.projectCount`, 0), 1),
        },
      }),
      {}
    );

    return Object.values(filteredVendorsById).sort(
      (a, b) => b.projectCount - a.projectCount
    );
  }, [data, projects]);

  if (isEmpty(vendors)) {
    return (
      <PortfolioInsightBlankSlateCard
        cardName="vendorDiversity"
        loading={loading}
      />
    );
  }

  const maxNumProjects = vendors[0].projectCount;

  return (
    <Pane>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        Vendor Diversity
      </Paragraph>
      <Card {...stylingProps.card}>
        <Pane maxHeight={200} overflowY="scroll">
          <table {...stylingProps.table}>
            <thead>
              <tr>
                <HeaderTextCell>Vendor</HeaderTextCell>
                <HeaderTextCell colSpan={3}>Active Projects</HeaderTextCell>
              </tr>
            </thead>
            <tbody>
              {vendors.map(({ id, name, projectCount }) => (
                <VendorDiversityRow
                  key={id}
                  name={name}
                  activeProjectCount={projectCount}
                  maxNumProjects={maxNumProjects}
                  navigateToVendor={() => navigateToVendor(id)}
                />
              ))}
            </tbody>
          </table>
        </Pane>
      </Card>
    </Pane>
  );
}

function VendorDiversityRow({
  activeProjectCount,
  maxNumProjects,
  navigateToVendor,
  name: vendorName,
}) {
  return (
    <tr>
      <TextCell>
        <Shortener limit={17} size={300} text={vendorName} />
      </TextCell>
      <TextCell>{activeProjectCount}</TextCell>
      <td width="100%" aria-label="progress">
        <Progress
          value={activeProjectCount}
          total={maxNumProjects}
          color={BRAND_COLORS.LIGHT_SECONDARY}
          background="transparent"
        />
      </td>
      <td>
        <Button onClick={navigateToVendor}>Go to Vendor</Button>
      </td>
    </tr>
  );
}
