import { Button, Shortener } from "components/materials";
import {
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { get } from "lodash";
import formatAddress from "helpers/formatAddress";

export const getColumns = (onSelect) => {
  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Organization Name",
      id: "name",
      value: ({ name }) => name,
      valueFormatter: (name) => <Shortener text={name} limit={35} size={300} />,
      sortStrategy: null,
      width: "25%",
      textOverflow: "ellipsis",
    },
    {
      ...stringColumnDefaults,
      header: "Email",
      id: "email",
      sortStrategy: null,
      value: (vendor) => get(vendor, "emailAddresses[0]", "-"),
      width: "20%",
    },
    {
      ...stringColumnDefaults,
      header: "Location",
      id: "location",
      sortStrategy: null,
      value: ({ city, state }) => formatAddress(null, city, state, null) || "-",
      width: "15%",
    },
    {
      ...stringColumnDefaults,
      header: "Vendor ID",
      id: "vendorId",
      sortStrategy: null,
      value: ({ vendorCostCode }) => vendorCostCode || "-",
      width: "10%",
    },
    {
      ...stringColumnDefaults,
      filterStrategy: null,
      header: "Projects",
      id: "projects",
      sortStrategy: null,
      value: (vendor) =>
        get(vendor, "projects", [])
          .map((project) => project.name)
          .join(", ") || "-",
      valueFormatter: (value) => (
        <Shortener limit={250} size={300} text={value} />
      ),
      width: "20%",
    },
    {
      ...stringColumnDefaults,
      header: "",
      id: "select",
      sortStrategy: null,
      value: (vendor) => (
        <Button height={20} onClick={() => onSelect(vendor)}>
          Select
        </Button>
      ),
      width: "10%",
    },
  ];
};
