import { isFinite } from "lodash";
import numbro from "numbro";

export default function formatPercent(
  value,
  defaultValue = "-",
  decimalPlaces = 0
) {
  if (!value && value !== 0) return defaultValue;
  if (!isFinite(value)) return defaultValue;
  if (decimalPlaces > 0)
    return numbro(value).format({
      output: "percent",
      mantissa: decimalPlaces,
      trimMantissa: true,
    });
  if (value > 0 && value < 0.01) return "1%";
  if (value > 0.99 && value < 1) return "99%";
  return `${Math.round(value * 100)}%`;
}
