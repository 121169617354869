import gql from "graphql-tag";

export const DEVELOPER_PORTFOLIO_OVERVIEW_QUERY = gql`
  query ReportsPortfolioInsightsDeveloperQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      projects {
        id
        insertedAt
        acres
        amount
        city
        contingencyOriginalAmount
        draws {
          id
          daysSinceFunded
          fundedByUser {
            id
            firstName
            lastName
          }
          fundedDate
          name
        }
        expectedProjectLength
        fundingSourceGroups {
          id
          amount
          disbursedAmount
          label
          type
          organization {
            id
            name
          }
        }
        tasks {
          id
          projectName
          eventName
          projectedCompletionDate
          status
          isOverdue
        }
        grossPercentComplete
        grossRequestedAmount
        lastDrawFunded {
          id
          daysSinceFunded
          fundedDate
          name
        }
        lineItems {
          id
          scopeId
          budgetAmount
          isContingency
          hardCosts
          isInterestReserves
          softCosts
        }
        name
        organizationId
        originalAmount
        percentOfContingencySpent
        percentOfProjectTimeElapsed
        projectRegion {
          id
          region
        }
        productType {
          id
          type
        }
        requestedAmount
        squareFeet
        stakeholders {
          id
          role
          organization {
            id
            name
          }
        }
        startDate
        state
        status
        team {
          id
          name
        }
        typeId
        zip
      }
      projectTemplates {
        id
        name
      }
    }
  }
`;

export const LENDER_PORTFOLIO_OVERVIEW_QUERY = gql`
  query ReportsPortfolioInsightsLenderQuery(
    $organizationId: String!
    $currentDate: Date
  ) {
    organization(id: $organizationId) {
      id
      projects {
        id
        amount
        city
        contingencyOriginalAmount
        draws {
          id
          createdAt
          daysOutstanding
          daysSinceFunded
          daysCreatedToFunded
          processingData(currentDate: $currentDate)
          fundedByUser {
            id
            firstName
            lastName
          }
          fundedDate
          name
          state
          submittedDate
        }
        fundingSourceGroups {
          id
          amount
          disbursedAmount
          label
          type
          organization {
            id
            name
          }
        }
        grossPercentComplete
        grossRequestedAmount
        lastDrawFunded {
          id
          daysSinceFunded
          fundedDate
          name
        }
        name
        organizationId
        originalAmount
        percentOfContingencySpent
        percentOfProjectTimeElapsed
        projectRegion {
          id
          region
        }
        productType {
          id
          type
        }
        requestedAmount
        stakeholders {
          id
          role
          organization {
            id
            name
          }
        }
        startDate
        state
        status
        team {
          id
          name
        }
        typeId
        zip
      }
      projectTemplates {
        id
        name
      }
    }
  }
`;

export const PORTFOLIO_VENDORS_QUERY = gql`
  query ReportsPortfolioInsightsVendorsQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      projects {
        id
        vendors {
          id
          name
        }
      }
    }
  }
`;
