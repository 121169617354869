import { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Divider, Form, Menu, Modal, Text } from "components/materials";
import { Formik } from "formik";
import { columnShape, groupShape } from "../FastDataTableUtils";

const GroupBy = ({
  dataColumns: columns,
  applyGroupConfig,
  groupConfig,
  closeDropdown,
  pageSize,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderModal = () => {
    const groupableColumns = columns.filter(
      (column) => column.groupable && !column.hidden
    );

    return (
      <Formik initialValues={groupConfig} onSubmit={applyGroupConfig}>
        {({ handleReset, handleSubmit, _values }) => (
          <Modal
            open={isModalOpen}
            title="Group By"
            hasClose={false}
            hasFooter
            confirmLabel="Apply"
            withoutAutofocus
            onConfirm={(close) => {
              handleSubmit();
              close();
            }}
            onCloseComplete={() => {
              handleReset();
              setIsModalOpen(false);
              closeDropdown();
            }}
          >
            <Form.Select
              name="columnId"
              options={groupableColumns.map((column) => ({
                key: column.id,
                value: column.id,
                text: column.header,
              }))}
            />
            <Divider />
            {pageSize && (
              <Alert intent="warning">
                Warning, grouping large tables of data can result in slow load
                times
              </Alert>
            )}
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <Menu.Item is={Text} onSelect={() => setIsModalOpen(true)}>
      Group By...
      {renderModal()}
    </Menu.Item>
  );
};

GroupBy.propTypes = {
  columns: PropTypes.arrayOf(columnShape),
  applyGroupConfig: PropTypes.func,
  groupConfig: groupShape,
  closeDropdown: PropTypes.func,
};

export default GroupBy;
