import { CaretDownIcon } from "evergreen-ui";
import {
  Checkbox,
  IconButton,
  Pane,
  SelectMenu,
  Shortener,
  TextInput,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { difference, escapeRegExp, get, uniq, xor, intersection } from "lodash";
import isBlank from "helpers/isBlank";
import { stringComparator } from "helpers/comparators";

export const enumColumnDefaults = {
  aggregate: () => null,
  aggregateFormatter: (value) => value,
  filterControl: (index, form, column, items) => {
    const enumValues =
      column.enumValues || uniq(items.map((item) => column.value(item)));
    const formattedOptions = enumValues.map((value) => ({
      key: value,
      label: getFilterLabel(value),
      value,
      isEmptyValue: checkValueEmpty(value),
    }));
    const sortedOptions = formattedOptions.sort((optionA, optionB) => {
      if (optionA.isEmptyValue) return 1;
      if (optionB.isEmptyValue) return -1;
      return stringComparator(optionA.value, optionB.value);
    });

    const values = sortedOptions.map(({ value }) => value);
    const selected = get(form.values, `filters.${index}.enum`, []);
    const checked = difference(values, selected).length === 0;
    const indeterminate = !checked && selected.length > 0;
    return (
      <Pane marginTop={majorScale(1)}>
        <SelectMenu
          hasFilter={false}
          isMultiSelect
          onDeselect={({ value }) =>
            form.setFieldValue(`filters.${index}.enum`, xor(selected, [value]))
          }
          onSelect={({ value }) =>
            form.setFieldValue(`filters.${index}.enum`, xor(selected, [value]))
          }
          options={sortedOptions}
          selected={selected}
          titleView={() => (
            <Pane marginLeft={majorScale(1)}>
              <Checkbox
                checked={checked}
                indeterminate={indeterminate}
                label="SELECT ALL"
                onChange={() =>
                  form.setFieldValue(
                    `filters.${index}.enum`,
                    checked ? [] : values
                  )
                }
              />
            </Pane>
          )}
        >
          <Pane display="flex">
            <TextInput
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
              onChange={() => {}}
              value={`${selected.length} Selected`}
            />
            <IconButton
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
              icon={CaretDownIcon}
              type="button"
            />
          </Pane>
        </SelectMenu>
      </Pane>
    );
  },
  filterFormatter: (filterConfig, column) => {
    // filterConfig for an enum column that didn't used to be enum column will not have an enum key
    if (!filterConfig.enum) return null;
    const filterValues = filterConfig.enum.map((value) =>
      getFilterLabel(value)
    );
    return (
      <Shortener
        limit={36}
        size={300}
        text={`${column.header}: ${filterValues.join(", ")}`}
      />
    );
  },
  filterStrategy: (value, filterConfig) => {
    const { enum: filterConfigEnum, __isSearch, input } = filterConfig;
    const isEmptyValue = checkValueEmpty(value);

    if (__isSearch === true) {
      return isBlank(input)
        ? true
        : !isEmptyValue && new RegExp(escapeRegExp(input), "i").test(value);
    }
    if (filterConfigEnum) {
      return isEmptyValue
        ? intersection(filterConfigEnum, [undefined, null, "-"]).length > 0
        : filterConfigEnum.includes(value);
    }
    return true;
  },
  sortStrategy: (valueA, valueB, sortOrder) => {
    const { compare } = new Intl.Collator();
    if (!sortOrder) {
      if (checkValueEmpty(valueA)) return 1;
      if (checkValueEmpty(valueB)) return -1;
      return stringComparator(valueA, valueB);
    }
    const indexA = sortOrder.indexOf(valueA);
    const indexB = sortOrder.indexOf(valueB);
    if (indexA === -1 && indexB === -1) return compare(valueA, valueB);
    return indexA - indexB;
  },
  type: "enum",
  valueFormatter: (value) => value,
  width: 200,
};

function getFilterLabel(value) {
  return isBlank(value) || value === "-" ? "(None)" : value;
}

function checkValueEmpty(value) {
  return isBlank(value) || value === "-";
}
