import { Fragment } from "react";
import PropTypes from "prop-types";
import { Divider, Form, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

export function RegistrationForm({ handleSubmit, loading, error, isSsoUser }) {
  return (
    <Pane paddingBottom={majorScale(2)}>
      <Form.Generic handleSubmit={handleSubmit} loading={loading} error={error}>
        <Form.Input label="First Name" name="firstName" />
        <Divider height={majorScale(1)} />
        <Form.Input label="Last Name" name="lastName" />
        <Divider height={majorScale(1)} />
        <Form.Input label="Email" name="email" disabled={isSsoUser} />

        {!isSsoUser && (
          <Fragment>
            <Divider height={majorScale(1)} />
            <Form.Input label="Password" name="password" type="password" />
            <Divider height={majorScale(1)} />
            <Form.Input
              label="Password Confirmation"
              name="passwordConfirmation"
              type="password"
            />
          </Fragment>
        )}

        <Divider height={majorScale(1)} />
      </Form.Generic>
    </Pane>
  );
}

export function BorrowerRegistrationForm({ handleSubmit, loading, error }) {
  return (
    <Pane paddingBottom={majorScale(2)}>
      <Form.Generic handleSubmit={handleSubmit} loading={loading} error={error}>
        <Form.Input label="Email" name="email" disabled />
        <Divider height={majorScale(1)} />
        <Form.Input label="Name" name="name" />
        <Divider height={majorScale(1)} />
        <Form.Input label="Password" name="password" type="password" />
        <Divider height={majorScale(1)} />
        <Form.Input
          label="Password Confirmation"
          name="passwordConfirmation"
          type="password"
        />
        <Divider height={majorScale(1)} />
      </Form.Generic>
    </Pane>
  );
}

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  isSsoUser: PropTypes.bool,
};

export default RegistrationForm;
