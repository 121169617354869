import { useContext } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { Paragraph, Form } from "components/materials";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import isBlank from "helpers/isBlank";
import isInvalidEmail from "helpers/isInvalidEmail";
import t from "helpers/translate";

const CREATE = gql`
  mutation OrganizationMemberModalCreate(
    $organizationId: String!
    $name: String!
    $email: String
    $phone: String
    $sourceOrganizationId: String!
  ) {
    addOrganizationMember(
      organizationId: $organizationId
      name: $name
      email: $email
      phone: $phone
      sourceOrganizationId: $sourceOrganizationId
    ) {
      id
      members {
        id
        name
        email
        phone
      }
    }
  }
`;

const UPDATE = gql`
  mutation OrganizationMemberModalUpdate(
    $memberId: String!
    $name: String!
    $email: String
    $phone: String
    $sourceOrganizationId: String!
  ) {
    updateOrganizationMember(
      memberId: $memberId
      name: $name
      email: $email
      phone: $phone
      sourceOrganizationId: $sourceOrganizationId
    ) {
      id
      name
      email
      phone
    }
  }
`;

function initialValues(member) {
  return {
    memberId: member?.id ?? null,
    name: member?.name ?? "",
    email: member?.email ?? "",
    phone: member?.phone ?? "",
  };
}

function validate(values) {
  const errors = {};

  const phoneDigits = values.phone.replace(/\D/g, "");
  if (!isBlank(values.email) && isInvalidEmail(values.email)) {
    errors.email = "Please enter a valid email";
  }

  if (!isBlank(phoneDigits) && phoneDigits.length !== 10) {
    errors.phone = "Please enter a valid phone number";
  }

  if (isBlank(values.name)) {
    errors.name = "Please enter a name";
  }

  return errors;
}

export function OrganizationMemberModal({
  member,
  onClose,
  refetchQueries,
  vendor,
}) {
  const { organizationId } = useContext(UserContext);
  const [createMember, createResult] = useMutation(CREATE, {
    onCompleted: onClose,
    refetchQueries,
  });
  const [updateMember, updateResult] = useMutation(UPDATE, {
    onCompleted: onClose,
    refetchQueries,
  });

  const handleSubmit = (values) => {
    if (values.memberId) {
      updateMember({
        variables: { ...values, sourceOrganizationId: organizationId },
      });
    } else {
      const variables = {
        ...values,
        organizationId: vendor.id,
        sourceOrganizationId: organizationId,
      };
      createMember({ variables });
    }
  };

  const loading = createResult.loading || updateResult.loading;
  const error = createResult.error || updateResult.error;
  const vendorName = vendor.name;

  return (
    <Formik
      initialValues={initialValues(member)}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <Form.Modal
          confirmLabel={member ? "Save Changes" : "Add Member"}
          error={error}
          hasClose={false}
          header={
            member
              ? `Edit ${vendorName} Member`
              : `Add New Member to ${vendorName}`
          }
          loading={loading}
          onSubmit={handleSubmit}
          onClose={onClose}
        >
          {!member?.id && (
            <Paragraph marginBottom={majorScale(2)}>
              {t("createOrganizationMember")}
            </Paragraph>
          )}
          <Form.Group>
            <Form.Input label="Name" name="name" />
          </Form.Group>

          <Form.Group>
            <Form.Input label="Email" name="email" />
            <Form.Input label="Phone Number" name="phone" type="tel" />
          </Form.Group>
        </Form.Modal>
      )}
    </Formik>
  );
}

OrganizationMemberModal.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  refetchQueries: PropTypes.object,
  vendor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

OrganizationMemberModal.defaultProps = {
  refetchQueries: null,
};
