import PropTypes from "prop-types";
import { Form, Paragraph, TextArea } from "components/materials";
import { connect, getIn } from "formik";

function FormTextArea({
  formik,
  name,
  label,
  labelProps,
  fieldProps,
  ...props
}) {
  const disabled =
    getIn(formik.values, "__disabled") ||
    getIn(formik.status, "disabled") ||
    props.disabled;
  const value = getIn(formik.values, name);
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const hasError = !!error && !!touch;

  return (
    <Form.Field
      label={label && <Paragraph {...labelProps}>{label}</Paragraph>}
      validationMessage={hasError && error}
      {...fieldProps}
    >
      <TextArea
        {...props}
        autoComplete="new-password"
        name={name}
        value={value}
        onChange={formik.handleChange}
        disabled={disabled}
      />
    </Form.Field>
  );
}

FormTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default connect(FormTextArea);
