import { useContext } from "react";
import { PortfolioProjectTable } from "components/templates";
import { Pane } from "components/materials";
import { get } from "lodash";
import { PERMISSION_ACTION } from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import { majorScale } from "helpers/utilities";
import { PortfolioProjectCards } from "./PortfolioProjectCards";
import { PortfolioProjectsHeader } from "./PortfolioProjectsHeader";

export function PortfolioProjects({
  countTodos,
  dispatchViewConfig,
  history,
  orgData,
  projects,
  scopeOrganizationsProps,
  viewConfig,
}) {
  const { hasPermissionAcrossProfiles } = useContext(UserContext);

  const canCreateProjects = hasPermissionAcrossProfiles(
    PERMISSION_ACTION.CREATE_PROJECT
  );

  const combinedOrgData = get(orgData, "organizations", []).reduce(
    (acc, organization) => ({
      productTypes: [...acc.productTypes, ...organization.productTypes],
      projectRegions: [...acc.projectRegions, ...organization.projectRegions],
      projectTemplates: [
        ...acc.projectTemplates,
        ...organization.projectTemplates,
      ],
      teams: [...acc.teams, ...organization.teams],
    }),
    { productTypes: [], projectRegions: [], projectTemplates: [], teams: [] }
  );

  const maxWidthCards = projects.length > 1 ? 1050 : 575;

  return (
    <Pane
      flex="1 1 auto"
      marginX={majorScale(2)}
      maxWidth={viewConfig.showCards ? maxWidthCards : undefined}
      position="relative"
      width="100%"
    >
      <PortfolioProjectsHeader
        canCreateProjects={canCreateProjects}
        countTodos={countTodos}
        dispatchViewConfig={dispatchViewConfig}
        viewConfig={viewConfig}
      />
      {viewConfig.showCards ? (
        <PortfolioProjectCards
          canCreateProjects={canCreateProjects}
          hasPermissionAcrossProfiles={hasPermissionAcrossProfiles}
          history={history}
          projects={projects}
        />
      ) : (
        <Pane overflowX="auto" position="absolute" left={0} right={0}>
          <PortfolioProjectTable
            canCreateProjects={canCreateProjects}
            dataLoading={viewConfig.tableLoading || !viewConfig.configLoaded}
            history={history}
            onConfigChange={(config) =>
              dispatchViewConfig({ type: "UPDATE_TABLE", value: config })
            }
            orgData={combinedOrgData}
            paginatedProjects={projects}
            scopeOrganizationsProps={scopeOrganizationsProps}
            tableName="PortfolioProjectTable"
          />
        </Pane>
      )}
    </Pane>
  );
}
