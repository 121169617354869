import { useContext } from "react";
import PropTypes from "prop-types";
import { CaretDownIcon } from "evergreen-ui";
import { every, includes } from "lodash";
import { UserContext } from "helpers/behaviors";
import { userCanApproveDocument } from "helpers/documentHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import { AssignToDropdown } from "./AssignToDropdown";
import { BulkMoveToDrawDropdown } from "./BulkMoveToDrawDropdown";

const BulkDocumentToolbar = ({
  children,
  suggestedDocumentAssignees,
  draws,
  drawId,
  documentReviewers,
  hasAssignedToPermission,
  hasStampDocumentsPermission,
  mutationLoading,
  onApprove,
  onAssignTo,
  onComplete,
  onDownload,
  onDownloadStamped,
  onIgnore,
  onMarkBackup,
  onMoveToDraw,
  onRemove,
  selectedItems,
  tableName,
  users,
}) => {
  const actions = [];
  const { hasPermission, user } = useContext(UserContext);
  const bulkMoveDraws = draws.filter(
    ({ id, isFrozen }) => !isFrozen && id !== drawId
  );
  const showBulkMove =
    includes(["ProjectDocumentTable", "DrawDocumentTable"], tableName) &&
    bulkMoveDraws.length > 0;

  if (
    every(selectedItems, (document) =>
      userCanApproveDocument(document, user, documentReviewers, hasPermission)
    )
  ) {
    actions.push({
      color: "green",
      label: "Approve",
      onClick: onApprove(onComplete),
      disabled: mutationLoading,
    });
  }

  if (hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT)) {
    actions.push({
      label: "Ignore",
      onClick: onIgnore(onComplete),
      disabled: mutationLoading,
    });
  }

  if (
    hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT) &&
    hasPermission(PERMISSION_ACTION.DELETE_DOCUMENTS)
  ) {
    actions.push({
      color: "red",
      label: "Delete",
      onClick: onRemove(onComplete),
      disabled: mutationLoading,
    });
  }

  if (tableName === "DrawDocumentTable") {
    actions.push({
      label: "Do Not Apply To Draw",
      onClick: onMarkBackup(onComplete),
      disabled: mutationLoading,
    });
  }

  actions.push({
    label: "Download",
    onClick: (documents) => {
      onDownload(documents.map(({ id }) => id)).then(onComplete);
    },
    disabled: mutationLoading,
  });

  if (hasAssignedToPermission) {
    actions.push({
      container: ({ children, selectedItems }) => (
        <AssignToDropdown
          hideTitle
          disabled={mutationLoading}
          suggestedDocumentAssignees={suggestedDocumentAssignees}
          onSelect={(userId) => {
            onAssignTo({
              variables: {
                documentIds: selectedItems.map(({ id }) => id),
                userId,
              },
            }).then(onComplete);
          }}
          users={users}
        >
          {children}
        </AssignToDropdown>
      ),
      label: "Assign To",
      labelIcon: <CaretDownIcon />,
    });
  }

  if (showBulkMove) {
    const bulkMoveAction =
      bulkMoveDraws.length === 1
        ? {
            label: `Move To ${bulkMoveDraws[0].name}`,
            onClick: () =>
              onMoveToDraw({
                documents: selectedItems,
                drawId: bulkMoveDraws[0].id,
                onComplete,
              }),
            disabled: mutationLoading,
          }
        : {
            container: ({ children, selectedItems }) => (
              <BulkMoveToDrawDropdown
                disabled={mutationLoading}
                onSelect={(drawId) => {
                  onMoveToDraw({
                    documents: selectedItems,
                    drawId,
                    onComplete,
                  });
                }}
                draws={bulkMoveDraws}
              >
                {children}
              </BulkMoveToDrawDropdown>
            ),
            label: "Move To",
            labelIcon: <CaretDownIcon />,
          };

    actions.push(bulkMoveAction);
  }

  if (hasStampDocumentsPermission) {
    actions.push({
      label: "Download Stamped",
      onClick: (documents) => {
        onDownloadStamped(documents.map(({ id }) => id)).then(onComplete);
      },
      disabled: mutationLoading,
    });
  }

  return children({ bulkActionGroups: [actions] });
};

BulkDocumentToolbar.propTypes = {
  hasDownloadPermission: PropTypes.bool,
  onCombine: PropTypes.func,
  onComplete: PropTypes.func,
  onDownload: PropTypes.func,
  onIgnore: PropTypes.func,
  onRemove: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
  suggestedDocumentAssignees: PropTypes.arrayOf(PropTypes.object),
  documentReviewers: PropTypes.arrayOf(PropTypes.object),
};

BulkDocumentToolbar.defaultProps = {
  onComplete: () => {},
  users: [],
  suggestedDocumentAssignees: [],
  documentReviewers: [],
};

export default BulkDocumentToolbar;
