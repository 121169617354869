import { useState, useContext, Fragment } from "react";
import { ChevronDownIcon, ChevronRightIcon, ShareIcon } from "evergreen-ui";
import { Card, Link, Pane, Paragraph, Table, Text } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { add } from "helpers/math";
import { ThemeContext, majorScale, minorScale } from "helpers/utilities";
import { NetAdjustmentPill } from "../../AdjustmentsOverlay/AdjustmentTransactions";

function AdjustmentCard({ budgetAdjustment, isSlideoutLineItem, projectId }) {
  const { agreement, comment, transactions } = budgetAdjustment;
  return (
    <Card margin={majorScale(1)} padding={majorScale(2)}>
      {comment && (
        <Paragraph
          size={300}
          marginBottom={majorScale(2)}
          marginTop={majorScale(1)}
        >
          {comment}
        </Paragraph>
      )}
      {agreement && (
        <Pane marginBottom={majorScale(2)} marginTop={majorScale(1)} s>
          <Link
            purpose="to agreements page"
            size={300}
            target="_blank"
            to={`/projects/${projectId}/agreements/${agreement.id}`}
          >
            {agreement.name}
            <ShareIcon size="12" marginLeft={minorScale(1)} />
          </Link>
        </Pane>
      )}
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.TextHeaderCell>Line Item</Table.TextHeaderCell>
            <Table.TextHeaderCell>Adjustment</Table.TextHeaderCell>
            <Table.TextHeaderCell>Budget After Adjustment</Table.TextHeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {transactions.map(({ amount, lineItem }) => (
            <Table.Row
              key={lineItem.id}
              state={
                isSlideoutLineItem(lineItem)
                  ? { background: "info" }
                  : undefined
              }
            >
              <Table.TextCell>{`${lineItem.name} (${lineItem.division.name})`}</Table.TextCell>
              <Table.TextCell>{formatCurrency(amount)}</Table.TextCell>
              <Table.TextCell>
                {formatCurrency(lineItem.budgetAmount)}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pane textAlign="right" marginTop={majorScale(1)}>
        <NetAdjustmentPill transactions={transactions} showNetZeroAdjustment />
      </Pane>
    </Card>
  );
}

function DrawAdjustments({
  draw,
  isSlideoutLineItem,
  projectId,
  openAdjustmentsOverlay,
}) {
  const [expandAdjustments, setExpandAdjustments] = useState(false);
  const theme = useContext(ThemeContext);

  const { adjustmentsAmount, budgetAdjustments } = draw.lineItem;

  return (
    <Pane>
      <Paragraph
        onClick={() => setExpandAdjustments(!expandAdjustments)}
        background={theme.tableColors.sectionHeader}
        display="flex"
        alignItems="center"
        size={300}
        paddingX={majorScale(1)}
        paddingY={minorScale(1)}
      >
        {expandAdjustments ? (
          <ChevronDownIcon size={12} marginRight={majorScale(1)} />
        ) : (
          <ChevronRightIcon size={12} marginRight={majorScale(1)} />
        )}
        <Pane flexGrow={1}>
          <Link size={300} onClick={() => openAdjustmentsOverlay(draw.id)}>
            {draw.name}
          </Link>
        </Pane>
        <Text fontWeight={500} size={300}>
          {formatCurrency(adjustmentsAmount)}
        </Text>
      </Paragraph>
      {expandAdjustments && (
        <Pane padding={majorScale(2)} background="#f6f7f8">
          {budgetAdjustments.map((adjustment) => (
            <AdjustmentCard
              key={adjustment.id}
              budgetAdjustment={adjustment}
              isSlideoutLineItem={isSlideoutLineItem}
              projectId={projectId}
            />
          ))}
        </Pane>
      )}
    </Pane>
  );
}

export function LineItemAdjustments({
  project,
  lineItemId,
  openAdjustmentsOverlay,
}) {
  const draws = project.draws.filter(
    (draw) => draw.lineItem.budgetAdjustments.length > 0
  );

  if (draws.length === 0)
    return <Paragraph marginTop={majorScale(1)}>No Adjustments</Paragraph>;

  const {
    originalBudgetAmount,
    adjustmentsAmount,
    previousAdjustmentsAmount,
    lineItemIds,
  } = project.recentDraw.lineItem;

  const adjustmentsToDate = add(adjustmentsAmount, previousAdjustmentsAmount);

  function isSlideoutLineItem(lineItem) {
    return (
      lineItem.id === lineItemId || (lineItemIds || []).includes(lineItem.id)
    );
  }

  return (
    <Fragment>
      <Paragraph
        fontWeight={500}
        size={300}
        textAlign="right"
        paddingRight={majorScale(1)}
      >
        Original Budget: {formatCurrency(originalBudgetAmount)}
      </Paragraph>
      <Pane marginY={majorScale(1)}>
        {draws
          .slice()
          .reverse()
          .map((draw) => (
            <DrawAdjustments
              key={draw.id}
              draw={draw}
              isSlideoutLineItem={isSlideoutLineItem}
              projectId={project.id}
              openAdjustmentsOverlay={openAdjustmentsOverlay}
            />
          ))}
      </Pane>
      <Paragraph
        fontWeight={500}
        size={300}
        textAlign="right"
        paddingRight={majorScale(1)}
      >
        Current Budget:{" "}
        {formatCurrency(add(originalBudgetAmount, adjustmentsToDate))}
      </Paragraph>
    </Fragment>
  );
}
