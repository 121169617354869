import { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Loadable } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { toaster } from "helpers/utilities";
import t from "helpers/translate";
import {
  PaymentsContext,
  PaymentsContextProvider,
} from "contexts/paymentsContext";
import { POST_PAYMENTS_TO_AVID } from "./graphql";
import { PostPaymentForm } from "./PostPaymentForm";

function getWarningTooltip({
  updatedSincePosted,
  isGCWithEditedDirectPays,
  cannotDirectPayVendor,
}) {
  if (isGCWithEditedDirectPays)
    return t("paymentIntegration.gcDirectPayUpdatedSincePostingTooltip");
  if (updatedSincePosted)
    return t("paymentIntegration.updatedSincePostedTooltip");
  if (cannotDirectPayVendor)
    return t("paymentIntegration.cannotDirectPayVendor");
  return undefined;
}

function getRowState({
  updatedSincePosted,
  isGCWithEditedDirectPays,
  cannotDirectPayVendor,
}) {
  if (isGCWithEditedDirectPays) {
    return "error";
  }
  if (updatedSincePosted) return "error";
  if (cannotDirectPayVendor) return "pending";
  return undefined;
}

function getCanBePosted({
  isPayableType,
  canDirectPayVendor,
  documentHasBeenPosted,
}) {
  // "Do not pay directly" can always be posted
  const isDoNotPayDirectly = !isPayableType;
  const isPayableTypeAndCanBePosted =
    !documentHasBeenPosted && canDirectPayVendor;
  return isDoNotPayDirectly || isPayableTypeAndCanBePosted;
}

function InnerAvidPaymentPage({ drawId, projectId }) {
  const {
    documentsToPost,
    queryLoading,
    setDocumentsToPost,
    onPaymentPosted,
  } = useContext(PaymentsContext);

  const [postPaymentsToAvid, { loading: postPaymentsLoading }] = useMutation(
    POST_PAYMENTS_TO_AVID,
    {
      onCompleted: () => {
        setDocumentsToPost([]);
        onPaymentPosted();
        toaster.success("Your payments have successfully posted.", {
          duration: 2.5,
        });
      },
      onError: () => {
        toaster.danger("Something went wrong. Please try again.", {
          duration: 2.5,
        });
      },
    }
  );

  function postPayments(formValues) {
    const payments = Object.entries(formValues.payments).reduce(
      (acc, [id, vendorPaymentType]) => {
        const document = documentsToPost.find((document) => document.id === id);
        if (!document || document.postedAt) return acc;

        return [
          ...acc,
          {
            documentId: id,
            amount: formatCurrency(document.paymentDueAmount),
            vendorPaymentType,
          },
        ];
      },
      []
    );

    postPaymentsToAvid({
      variables: {
        drawId,
        projectId,
        payments,
      },
    });
  }

  if (queryLoading) return <Loadable loading />;
  return (
    <PostPaymentForm
      accountsPayableSystem="Avid"
      handleSubmit={postPayments}
      onSubmitLoading={postPaymentsLoading}
    />
  );
}

export function AvidPaymentPage({ match }) {
  const { drawId, projectId } = match.params;

  return (
    <PaymentsContextProvider
      drawId={drawId}
      projectId={projectId}
      includeLineItems={false}
      includeJobCostCodes={false}
      getWarningTooltip={getWarningTooltip}
      getRowState={getRowState}
      getCanBePosted={getCanBePosted}
    >
      <InnerAvidPaymentPage drawId={drawId} projectId={projectId} />
    </PaymentsContextProvider>
  );
}
