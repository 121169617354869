import { useContext, Fragment } from "react";
import { v4 as uuid } from "uuid";
import { Formik } from "formik";
import { Pane, Button } from "components/materials";
import { EditableBudgetTable } from "components/templates";
import { get, set } from "lodash";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import unformatNumber from "helpers/unformatNumber";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import formatConstant from "helpers/formatConstant";
import { areWordsEqual } from "helpers/stringHelpers";
import { STEPS, RABBET_DEFAULT_BUDGET } from "./utils";
import { Header } from "./Header";
import { PersistStep } from "./PersistStep";

function defaultDivisions() {
  return [
    {
      id: uuid(),
      name: "",
      lineItems: [
        { id: uuid(), name: "", amount: 0 },
        { id: uuid(), name: "", amount: 0 },
        { id: uuid(), name: "", amount: 0 },
      ],
    },
  ];
}
function getDivisionsForProject(project) {
  const onboardingDataBudgetDivisions = get(
    project,
    "onboardingData.budget.divisions",
    []
  );
  if (onboardingDataBudgetDivisions.length > 0) {
    return onboardingDataBudgetDivisions;
  }
  if (get(project, "onboardingData.useRabbetBudget")) {
    return RABBET_DEFAULT_BUDGET;
  }
  return defaultDivisions();
}

function hasLineItemNumber(divisions) {
  return divisions.some((division) =>
    division.lineItems.some((lineItem) => !isBlank(lineItem.number))
  );
}

/**
 * Sets a couple of boilerplate Formik bits
 * and revises masterFormatDivision value so it will map properly into the select menu
 */
function buildInitialLineItem(lineItem, lineItemIndex) {
  const lineItemCopy = { ...lineItem }; // lineItem object is frozen...
  if (lineItem.masterFormatDivision) {
    lineItemCopy.masterFormatDivision = formatConstant(
      lineItem.masterFormatDivision
    );
  }

  return {
    amount: 0,
    position: lineItemIndex,
    ...lineItemCopy,
  };
}

function initialValues(project) {
  const divisions = getDivisionsForProject(project);
  const initialDivisions = divisions.map(
    ({ lineItems, ...rest }, divisionIndex) => {
      const initialLineItems = lineItems.map(buildInitialLineItem);

      return {
        lineItems: initialLineItems,
        position: divisionIndex,
        ...rest,
      };
    }
  );
  return {
    divisions: initialDivisions,
    showLineItemNumberDefault: hasLineItemNumber(divisions),
  };
}

function validate(values) {
  const errors = {};
  values.divisions.forEach((division, divisionIndex, divisionsArray) => {
    if (
      divisionsArray.some(
        ({ name, id }) =>
          areWordsEqual(name, division.name) && id !== division.id
      )
    ) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Division names must be unique"
      );
    }
    if (division.name.length > 60) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Division names cannot be longer than 60 characters"
      );
    }
    if (isBlank(division.name)) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Please enter a division name"
      );
    }
    if (
      division.lineItems.length === 0 ||
      division.lineItems.every(({ name }) => !name)
    ) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Please create line items for this division"
      );
    }
    division.lineItems.forEach((lineItem, lineItemIndex, lineItemsArray) => {
      if (
        lineItemsArray.some(
          ({ name, id }) =>
            !isBlank(name) &&
            areWordsEqual(name, lineItem.name) &&
            id !== lineItem.id
        )
      ) {
        set(
          errors,
          `divisions.${divisionIndex}.lineItems.${lineItemIndex}.name`,
          "Line item names within a division must be unique"
        );
      }
      if (isBlank(lineItem.name) && unformatNumber(lineItem.amount) !== 0) {
        set(
          errors,
          `divisions.${divisionIndex}.lineItems.${lineItemIndex}.name`,
          "Please enter a line item name"
        );
      }
    });
  });
  return errors;
}

export function EnterBudgetStep({
  goBack,
  loading,
  navigateToStep,
  project,
  updateOnboardingData,
}) {
  const { hasPermission, isDeveloper } = useContext(UserContext);
  const { hasDraws } = project.onboardingData;

  const getMutationData = (budget) => {
    const preparedDivisions = budget.divisions.map(
      ({ lineItems, ...division }) => {
        const preparedLineItems = lineItems.filter(
          ({ name }) => !isBlank(name)
        );
        return {
          lineItems: preparedLineItems,
          ...division,
        };
      }
    );
    return { budget: { divisions: preparedDivisions } };
  };

  const handleFinalizeBudget = (budget) => {
    const mutationData = getMutationData(budget);

    updateOnboardingData(mutationData).then(() => {
      if (isDeveloper && !hasDraws)
        navigateToStep(STEPS.DEVELOPER_HAS_FUNDING_SOURCES);
      else navigateToStep(STEPS.FUNDING_SOURCES);
    });
  };

  const tableWidth =
    800 +
    (hasPermission(PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING)
      ? 220
      : 0) +
    (hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS) ? 200 : 0);

  return (
    <Pane>
      <Header
        header={t("onboardingWizard.enterBudget.title")}
        subheader={t("onboardingWizard.enterBudget.subtitle")}
      />
      <Pane width={tableWidth}>
        <Formik
          initialValues={initialValues(project)}
          onSubmit={handleFinalizeBudget}
          validate={validate}
          validateOnMount
        >
          {(propsFormik) => (
            <Fragment>
              <EditableBudgetTable propsFormik={propsFormik} />

              <Pane
                display="flex"
                justifyContent="space-between"
                marginBottom={majorScale(4)}
                marginTop={majorScale(2)}
              >
                <Button
                  isLoading={loading}
                  onClick={() => {
                    goBack();
                  }}
                >
                  Back
                </Button>
                <Button
                  appearance="primary"
                  isLoading={loading}
                  onClick={propsFormik.handleSubmit}
                >
                  Next
                </Button>
              </Pane>
              <PersistStep
                mutationData={getMutationData(propsFormik.values)}
                projectId={project.id}
              />
            </Fragment>
          )}
        </Formik>
      </Pane>
    </Pane>
  );
}
