import { Fragment } from "react";
import { Modal, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

export function EditDocumentWarningModal({
  document,
  onClose,
  handleMarkUnpaid,
  markDocumentUnpaidLoading,
}) {
  function handleConfirm() {
    handleMarkUnpaid();
    onClose();
  }

  const translatedDocumentType = t(`documentTypeName.${document.type}`);
  return (
    <Fragment>
      <Modal
        hasFooter
        onConfirm={handleConfirm}
        confirmLabel="Edit Document"
        isConfirmLoading={markDocumentUnpaidLoading}
        onClose={() => onClose()}
        open
        size="small"
        title={`Edit ${translatedDocumentType}`}
      >
        <Modal.Content>
          <Pane marginBottom={majorScale(2)} fontSize={15}>
            {t("editDocumentWarnings.warning1")}
          </Pane>
          <Pane marginBottom={majorScale(2)} fontSize={15}>
            {t("editDocumentWarnings.warning2")}
          </Pane>
          <Pane fontSize={15}>{t("editDocumentWarnings.warning3")}</Pane>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}
