import PropTypes from "prop-types";
import { CommentForm, ListComments } from "components/templates";
import { Form } from "components/materials";
import { get } from "lodash";
import t from "helpers/translate";

function DocumentComments(props) {
  const {
    collapsedPanels,
    commentMutation: [onSubmitComment, submitCommentResult],
    document,
    onDirtyOrFocus,
    togglePanel,
    users,
  } = props;

  const comments = get(document, "comments", []);
  return (
    <Form.Section
      borderBottom
      heading="Internal Comments"
      isCollapsible
      isCollapsed={collapsedPanels.includes("comments")}
      onCollapse={() => togglePanel("comments")}
      summary={t("documentReview.commentsSummary", {
        count: comments.length,
      })}
    >
      <ListComments comments={comments} />
      <CommentForm
        mutation={onSubmitComment}
        initialValues={{ documentId: document.id, body: "" }}
        onDirtyOrFocus={onDirtyOrFocus}
        users={users}
        {...submitCommentResult}
      />
    </Form.Section>
  );
}

DocumentComments.propTypes = {
  collapsedPanels: PropTypes.arrayOf(PropTypes.string),
  document: PropTypes.shape({
    comments: PropTypes.array,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitComment: PropTypes.func.isRequired,
  submitCommentResult: PropTypes,
  onDirtyOrFocus: PropTypes.func,
  togglePanel: PropTypes.func,
};

DocumentComments.defaultProps = {
  collapsedPanels: [],
};

export default DocumentComments;
