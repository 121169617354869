import PropTypes from "prop-types";
import { createNumberMask } from "text-mask-addons";
import { FastField } from "formik";
import ReactTextMask from "react-text-mask";
import { TextInputField } from "components/materials";

const MASK = {
  currency: createNumberMask({
    allowDecimal: true,
    allowLeadingZeroes: true,
    allowNegative: true,
    prefix: "$",
  }),
  percentage: createNumberMask({
    allowDecimal: true,
    decimalLimit: 3,
    integerLimit: 3,
    prefix: "",
    suffix: "%",
  }),
};

export function FastMaskInput({ mask, name, onChange, ...rest }) {
  const handleChange = (field) => (e) => {
    field.onChange(e);
    onChange && onChange(e);
  };

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <ReactTextMask
          {...field}
          onChange={handleChange(field)}
          mask={MASK[mask]}
          render={(setRef, propsReactTextMask) => (
            <TextInputField
              autoComplete="new-password"
              margin={0}
              validationMessage={meta && meta.touched && meta.error}
              {...rest}
              {...propsReactTextMask}
              ref={setRef}
            />
          )}
        />
      )}
    </FastField>
  );
}

FastMaskInput.propTypes = {
  mask: PropTypes.oneOf(Object.keys(MASK)).isRequired,
  name: PropTypes.string.isRequired,
};
