import { useState, Fragment } from "react";
import { PROJECTION_EVENT } from "components/containers/ProjectionsPage";
import {
  NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE,
  UPDATING_PROJECTION_STATE,
} from "hooks/use-projections";
import { Button, Checkbox, Confirm, Pane, Text } from "components/materials";
import analytics from "helpers/analytics";
import { majorScale } from "helpers/utilities";
import { ProjectionDownloads } from "./ProjectionDownloads";

export function ProjectionTableActions({
  chartId,
  isLoading,
  onChange,
  projection,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const {
    actualsCount,
    columns,
    divisions,
    fundingSourceTotals,
    project,
    state: projectionState,
    projectionTotals,
    tableFundingSources,
    views: { showProjectionCharts },
  } = projection;

  const { months } = project;

  return (
    <Pane
      alignItems="center"
      display="flex"
      height={majorScale(10)}
      justifyContent="space-between"
      marginX={majorScale(4)}
    >
      <Pane display="flex" alignItems="center">
        <ToggleColumnActions
          onChange={onChange}
          projectionState={projectionState}
          columns={columns}
        />
        {!showProjectionCharts && (
          <Button
            marginLeft={majorScale(2)}
            onClick={() =>
              onChange({
                type: PROJECTION_EVENT.PROJECTION_CHARTS_UPDATED,
                data: { showProjectionCharts: true },
              })
            }
          >
            Show Graphs
          </Button>
        )}
      </Pane>
      <Pane display="flex" alignItems="center">
        <ProjectionDownloads
          actualsCount={actualsCount}
          chartId={chartId}
          columns={columns}
          divisions={divisions}
          fundingSourceTotals={fundingSourceTotals}
          months={months}
          projectionState={projectionState}
          projectionTotals={projectionTotals}
          showProjectionCharts={showProjectionCharts}
          tableFundingSources={tableFundingSources}
        />
        <ProjectionEditActions
          isLoading={isLoading}
          onEdit={() => setOpenConfirm(true)}
          onUndo={() => onChange({ type: PROJECTION_EVENT.UNDO })}
          onSave={() => onChange({ type: PROJECTION_EVENT.SAVE_PROJECTION })}
          projectionState={projectionState}
        />
      </Pane>
      <Confirm
        content="You will lose your 'Original Projection' line if you update your projection"
        header="Warning"
        onCloseComplete={() => setOpenConfirm(false)}
        onConfirm={() => {
          onChange({ type: PROJECTION_EVENT.UPDATE_PROJECTION });
          analytics.track("Projection Updated To Reflect Actuals", {
            projectId: project.id,
          });
          setOpenConfirm(false);
        }}
        open={openConfirm}
      />
    </Pane>
  );
}

function ProjectionEditActions({
  projectionState,
  onUndo,
  onSave,
  onEdit,
  isLoading,
}) {
  switch (projectionState) {
    case UPDATING_PROJECTION_STATE:
      return (
        <Pane display="flex">
          <Button
            disabled={isLoading}
            marginRight={majorScale(2)}
            onClick={onUndo}
          >
            Undo
          </Button>
          <Button appearance="primary" isLoading={isLoading} onClick={onSave}>
            Save
          </Button>
        </Pane>
      );

    case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
      return (
        <Button
          appearance="primary"
          isLoading={isLoading}
          marginRight={majorScale(2)}
          onClick={onEdit}
        >
          Update Projection
        </Button>
      );

    default:
      return null;
  }
}

function ToggleColumnActions({ projectionState, onChange, columns }) {
  const { showOriginalProjection, showUpdatedProjection } = columns;
  switch (projectionState) {
    case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
      return (
        <Fragment>
          <Text size={300} fontWeight={600}>
            Columns:
          </Text>
          <Checkbox
            checked={showOriginalProjection}
            disabled={showOriginalProjection && !showUpdatedProjection}
            label="Original Projection"
            marginLeft={majorScale(2)}
            onChange={(e) =>
              onChange({
                type: PROJECTION_EVENT.COLUMN_WAS_UPDATED,
                data: {
                  columnType: "originalProjection",
                  checked: e.target.checked,
                },
              })
            }
          />
          <Checkbox
            checked={showUpdatedProjection}
            disabled={showUpdatedProjection && !showOriginalProjection}
            label="Actuals + Updated Projection"
            marginLeft={majorScale(2)}
            onChange={(e) =>
              onChange({
                type: PROJECTION_EVENT.COLUMN_WAS_UPDATED,
                data: {
                  columnType: "updatedProjection",
                  checked: e.target.checked,
                },
              })
            }
          />
        </Fragment>
      );

    default:
      return null;
  }
}
