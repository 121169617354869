import { Fragment, useContext, useState } from "react";
import { CaretDownIcon, InfoSignIcon } from "evergreen-ui";
import {
  Button,
  Confirm,
  Menu,
  Pane,
  Popover,
  Text,
  Tooltip,
} from "components/materials";
import { FastDataTableBulkActionControls } from "components/materials/FastDataTable";
import {
  isAgreementDocument,
  isAgreementWithAdjustments,
} from "helpers/documentHelpers";
import { majorScale, minorScale, Position, withTheme } from "helpers/utilities";
import { cloneDeep, get, isInteger, max, min, set } from "lodash";
import { setUpdatedDocuments } from "helpers/uploadHelpers";
import { UserContext, UploadSplitterContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";
import { CannotDeleteWithAdjustmentModal } from "components/containers/Agreements";
import { AlsoDeleteAgreementModal } from "../DocumentReview/AlsoDeleteAgreementModal";

const canBulkMerge = (selectedIndices = []) => {
  if (selectedIndices.length <= 1) return false;

  const maxIndex = max(selectedIndices);
  const minIndex = min(selectedIndices);

  return (
    isInteger(maxIndex) &&
    isInteger(minIndex) &&
    maxIndex - minIndex + 1 === selectedIndices.length
  );
};

function UploadActionsDropdown({ disabled, dropdownActions }) {
  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      content={({ close }) => (
        <Menu>
          {dropdownActions.map(({ label, onSelect, ...props }) => (
            <Menu.Item
              is={Text}
              marginY={minorScale(1)}
              onSelect={() => {
                if (!disabled) {
                  onSelect();
                  close();
                }
              }}
              {...props}
            >
              {label}
            </Menu.Item>
          ))}
        </Menu>
      )}
    >
      <Button
        purpose="upload actions"
        marginLeft={majorScale(1)}
        iconAfter={CaretDownIcon}
      >
        Actions
      </Button>
    </Popover>
  );
}

function UploadFormControls({
  drawId,
  fieldArray,
  form,
  getProjectVendorSearchQuery,
  mutationLoading,
  newlyAddedVendors,
  parseDocumentInformation,
  projectId,
  searchedVendors,
  setConfirmClassifyOpen,
  setConfirmSplitAndClassifyOpen,
  setNewlyAddedVendors,
  setReparseOnSubmit,
  theme,
  upload,
  uploadMutations,
  vendors,
  viewerDirty,
  ...controlsProps
}) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [
    alsoDeleteAgreementModalOpen,
    setAlsoDeleteAgreementModalOpen,
  ] = useState(false);
  const [
    cannotDeleteWithAdjustmentOpen,
    setCannotDeleteWithAdjustmentOpen,
  ] = useState(false);

  const [confirmParseOpen, setConfirmParseOpen] = useState(false);

  const { hasPermission } = useContext(UserContext);
  const { setOpenSplitter } = useContext(UploadSplitterContext);

  const hasAgreementManagement = hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  );
  const canCreateAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );

  const isAgreement = upload.documents.some(isAgreementDocument);
  const hasAdjustments = upload.documents.some(isAgreementWithAdjustments);

  const handleDeleteUpload = () => {
    if (hasAdjustments && !canCreateAdjustments) {
      return setCannotDeleteWithAdjustmentOpen(true);
    }
    if (isAgreement && hasAgreementManagement) {
      return setAlsoDeleteAgreementModalOpen(true);
    }
    return setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = () => {
    uploadMutations.deleteUpload({ variables: { uploadId: upload.id } });
    setConfirmDeleteOpen(false);
  };

  const handleParseDocumentsOnSave = () => {
    setConfirmParseOpen(true);
  };

  const handleParseDocuments = () => {
    setConfirmParseOpen(false);
    setReparseOnSubmit(true);
    form.handleSubmit();
  };

  const handleBulkMerge = (_selectedItems, selectedIndices) => {
    const minIndex = min(selectedIndices);
    const maxIndex = max(selectedIndices);
    const endPage = get(form.values, `documents[${maxIndex}].endPage`);
    const updatedDocuments = setUpdatedDocuments(
      form.values.documents,
      minIndex,
      { endPage }
    );

    form.setValues({
      documents: updatedDocuments,
    });

    controlsProps.setSelectedItems([]);
  };

  const handleBulkDelete = (selectedItems, selectedIndices) => {
    const selectedDocsWithAgreements = selectedItems.filter((document) =>
      get(document, "agreement.id")
    );
    const selectionHasAdjustments = selectedDocsWithAgreements.some(
      ({ agreement }) => get(agreement, "budgetAdjustment.id")
    );

    if (selectionHasAdjustments && !canCreateAdjustments) {
      return setCannotDeleteWithAdjustmentOpen(true);
    }

    const updatedDocuments = selectedIndices.reduce(
      (updatedDocuments, updatedIndex) => {
        set(updatedDocuments, [updatedIndex, "deleted"], true);
        return updatedDocuments;
      },
      cloneDeep(form.values.documents)
    );

    form.setValues({
      documents: updatedDocuments,
    });
    return controlsProps.setSelectedItems([]);
  };

  const dropdownActions = hasPermission(PERMISSION_ACTION.DELETE_DOCUMENTS)
    ? [
        {
          onSelect: handleDeleteUpload,
          label: t("uploadsViewer.deleteUpload"),
          intent: "danger",
        },
      ]
    : [];

  const getDropdownActions = () =>
    hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT)
      ? [
          ...dropdownActions,
          {
            onSelect: () => setConfirmClassifyOpen(true),
            label: (
              <Pane>
                <Text marginRight={minorScale(2)}>
                  {t("uploadsViewer.autoClassify")}
                </Text>
                <Tooltip content={t("uploadsViewer.autoClassifyTooltip")}>
                  <InfoSignIcon />
                </Tooltip>
              </Pane>
            ),
            "data-purpose": "upload actions auto-classify",
          },
          {
            onSelect: () => setConfirmSplitAndClassifyOpen(true),

            label: (
              <Pane>
                <Text marginRight={minorScale(2)}>
                  {t("uploadsViewer.autoSplitAndClassify")}
                </Text>
                <Tooltip
                  content={t("uploadsViewer.autoSplitAndClassifyTooltip")}
                >
                  <InfoSignIcon />
                </Tooltip>
              </Pane>
            ),
            "data-purpose": "upload actions auto-split-and-classify",
          },
        ]
      : dropdownActions;

  const bulkActions = [
    {
      disabled: !canBulkMerge(controlsProps.selectedIndices),
      label: t("uploadsViewer.bulkMerge"),
      onClick: handleBulkMerge,
    },
    {
      label: t("uploadsViewer.bulkDelete"),
      onClick: handleBulkDelete,
      color: "red",
    },
  ];

  const renderFormControls = () =>
    viewerDirty ? (
      <Fragment>
        <Button
          isLoading={mutationLoading}
          marginLeft={majorScale(1)}
          onClick={form.handleReset}
          purpose="upload undo"
        >
          Undo Changes
        </Button>
        <Button
          appearance="primary"
          isLoading={mutationLoading}
          marginLeft={majorScale(1)}
          purpose="upload submit"
          onClick={
            parseDocumentInformation
              ? handleParseDocumentsOnSave
              : form.handleSubmit
          }
        >
          Save Changes
        </Button>
      </Fragment>
    ) : (
      <Fragment>
        <Button
          appearance={upload.toBeSplit && "primary"}
          marginLeft={majorScale(1)}
          onClick={() => setOpenSplitter({ projectId, uploadId: upload.id })}
          purpose="upload splitter open"
        >
          Quick Splitter
        </Button>
        <UploadActionsDropdown
          disabled={mutationLoading}
          dropdownActions={getDropdownActions()}
          key="actionsDropdown"
        />
      </Fragment>
    );

  const fileName = get(upload, "file.name");

  return (
    <Fragment>
      <Pane height={48} padding={minorScale(1)} width="100%">
        <FastDataTableBulkActionControls
          bulkActionGroups={[bulkActions]}
          {...controlsProps}
        >
          <Pane
            height="100%"
            display="flex"
            alignItems="center"
            paddingX={majorScale(1)}
          >
            <Text
              size={500}
              fontWeight={theme.fontWeights.MEDIUM}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              flexGrow={1}
            >
              {`Documents from ${fileName}`}
            </Text>
            <Pane display="flex" alignItems="center">
              {renderFormControls()}
            </Pane>
          </Pane>
        </FastDataTableBulkActionControls>
      </Pane>
      <Confirm
        content={t("uploadsViewer.confirmDelete")}
        header="Delete Upload"
        hideViewer
        onCloseComplete={() => setConfirmDeleteOpen(false)}
        onConfirm={handleConfirmDelete}
        open={confirmDeleteOpen}
        cancelLabel="Cancel"
        confirmLabel="Delete Upload"
      />
      <Confirm
        content={t("uploadsViewer.confirmExtractDocumentInformation")}
        header="Extract information?"
        hideViewer
        onCloseComplete={() => setConfirmParseOpen(false)}
        onConfirm={handleParseDocuments}
        open={confirmParseOpen}
        cancelLabel="Close"
        confirmLabel="Extract"
      />
      {alsoDeleteAgreementModalOpen && (
        <AlsoDeleteAgreementModal
          documents={upload.documents}
          onClose={() => setAlsoDeleteAgreementModalOpen(false)}
          onDelete={(alsoDeleteAgreements) =>
            uploadMutations.deleteUpload({
              variables: { uploadId: upload.id, ...alsoDeleteAgreements },
            })
          }
        />
      )}
      {cannotDeleteWithAdjustmentOpen && (
        <CannotDeleteWithAdjustmentModal
          context="document"
          onClose={() => setCannotDeleteWithAdjustmentOpen(false)}
        />
      )}
    </Fragment>
  );
}

export default withTheme(UploadFormControls);
