import { useContext, useState, useMemo } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { Form, Paragraph, Text } from "components/materials";
import { ThemeContext } from "helpers/utilities";
import { find, zip } from "lodash";
import {
  displayTransform,
  getDataMention,
  getStyleMention,
  getStyleMentionsInput,
} from "./utils";

const MENTIONS_PLACEHOLDER = "_@_";

export function MentionsBody({ comment, textProps }) {
  const theme = useContext(ThemeContext);
  const { body, mentions } = comment;
  const mentionUsers = mentions.map(({ user }) => user);
  let mentionNames = [];
  const matched = body
    ? body.replace(
        /@\[([^\]]*?)\]\(([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})\)/gi,
        (_markup, fullName, id) => {
          const foundMention = find(mentionUsers, (user) => user.id === id);
          mentionNames = mentionNames.concat(
            foundMention ? foundMention.fullName : fullName
          );
          return MENTIONS_PLACEHOLDER;
        }
      )
    : "";

  const formattedMentions = mentionNames.map((name) => (
    <Text
      key={name}
      fontWeight={theme.fontWeights.MEDIUM}
      {...textProps}
    >{`@${name}`}</Text>
  ));

  const bodyChunks = matched.split(MENTIONS_PLACEHOLDER).map((bodyChunk) => (
    <Text key={bodyChunk} {...textProps}>
      {bodyChunk}
    </Text>
  ));

  return zip(bodyChunks, formattedMentions);
}

export function MentionsCommentField({
  label,
  labelProps,
  meta,
  onBlur,
  onFocus,
  users,
  ...rest
}) {
  const theme = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const styleMentionsInput = useMemo(() => {
    return getStyleMentionsInput(theme, focused);
  }, [focused, theme]);
  const styleMention = useMemo(() => {
    return getStyleMention(theme);
  }, [theme]);
  const dataMention = useMemo(() => {
    return getDataMention(users);
  }, [users]);

  const handleBlur = (e) => {
    onBlur && onBlur(e);
    setFocused(false);
  };

  const handleFocus = () => {
    onFocus && onFocus();
    setFocused(true);
  };

  return (
    <Form.Field
      hint="Type @ to mention a user"
      label={label && <Paragraph {...labelProps}>{label}</Paragraph>}
      validationMessage={meta && meta.touched && meta.error}
    >
      <MentionsInput
        {...rest}
        allowSpaceInQuery
        onBlur={handleBlur}
        onFocus={handleFocus}
        style={styleMentionsInput}
      >
        <Mention
          appendSpaceOnAdd
          data={dataMention}
          displayTransform={displayTransform}
          style={styleMention}
        />
      </MentionsInput>
    </Form.Field>
  );
}
