import PropTypes from "prop-types";
import { concat, filter, find, uniqBy } from "lodash";
import analytics from "helpers/analytics";
import { Tab, TabNavigation } from "components/materials";

function getTableViews({ views, scopeOrganizationsProps }) {
  if (scopeOrganizationsProps.allOrganizations.length <= 1) {
    return uniqBy(views, "name");
  }

  const namedMultiOrgViews = views.map((view) => {
    if (view.isDefault) return view;

    const viewOrganization = find(
      scopeOrganizationsProps.allOrganizations,
      (organization) => organization.id === view.organizationId
    );
    const viewName = viewOrganization?.name
      ? `${viewOrganization.name} - ${view.name}`
      : view.name;

    return {
      ...view,
      name: viewName,
    };
  });

  const defaultViews = uniqBy(
    filter(namedMultiOrgViews, (view) => view.isDefault),
    "name"
  );
  const nonDefaultViews = filter(namedMultiOrgViews, (view) => !view.isDefault);
  return concat(defaultViews, nonDefaultViews);
}

const SavedViews = ({
  hydrateFromSerialized,
  scopeOrganizationsProps,
  serialized,
  tableName,
  views,
}) => {
  const tableViews = getTableViews({ views, scopeOrganizationsProps });

  return (
    <TabNavigation>
      {tableViews.map(
        ({ id, isDefault, isPrivate, config, name, formattedName }, index) => (
          <Tab
            key={`${id}${name}`}
            isSelected={serialized === config}
            onSelect={() => {
              analytics.track("Table View Opened", {
                tableName,
                isPrivateView: isPrivate,
                isUsersDefaultView: index === 0,
                isCustomView: !isDefault,
              });
              hydrateFromSerialized(config);
            }}
          >
            {formattedName || name}
          </Tab>
        )
      )}
    </TabNavigation>
  );
};

SavedViews.propTypes = {
  scopeOrganizationsProps: PropTypes.shape({
    disabledOrganizations: PropTypes.array,
    allOrganizations: PropTypes.array,
    selectedOrganization: PropTypes.object,
  }),
  views: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      config: PropTypes.string,
      name: PropTypes.string,
    }).isRequired
  ).isRequired,
};

SavedViews.defaultProps = {
  scopeOrganizationsProps: {
    disabledOrganizations: [],
    allOrganizations: [],
    selectedOrganization: {},
  },
};

export default SavedViews;
