import { useEffect, useState } from "react";
import { Form, Table } from "components/materials";
import { isNumber } from "lodash";
import isBlank from "helpers/isBlank";
import { formatCurrency } from "helpers/formatCurrency";
import { parseCurrencyToFloat } from "helpers/parseCurrencyToFloat";
import { BRAND_COLORS } from "helpers/colors";
import { preventEventBubbling } from "helpers/preventEventBubbling";

export function DrawSummaryTableCell({
  onManualEntry,
  override,
  parsedAmount,
  path,
  testId,
  disabled,
  isIgnored,
  setFieldValue,
}) {
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (
      !isEditable &&
      (isBlank(override) || isNaN(parseCurrencyToFloat(override)))
    ) {
      setFieldValue(path, null);
    }
  }, [isEditable, override, path, setFieldValue]);

  const clickProps = disabled
    ? {}
    : {
        onClick: () => setIsEditable(true),
        cursor: "pointer",
      };

  if (isIgnored) {
    return <Table.Cell />;
  }

  if (isEditable) {
    return (
      <Table.Cell>
        <Form.Input
          autoFocus
          name={path}
          type="currency"
          onBlur={() => setIsEditable(false)}
          onChange={onManualEntry}
          onKeyDown={(event) => {
            if (["Enter", "Tab", "Escape"].includes(event.key)) {
              preventEventBubbling(event);
              setIsEditable(false);
            }
          }}
          placeholder={formatParsedValue(parsedAmount)}
          marginTop={-8}
          testId={testId}
        />
      </Table.Cell>
    );
  }

  if (
    !isBlank(override) &&
    !isNaN(parseCurrencyToFloat(override)) &&
    parsedAmount !== parseCurrencyToFloat(override)
  ) {
    return (
      <Table.TextCell
        testId={testId}
        textAlign="right"
        textProps={{ color: BRAND_COLORS.PRIMARY }}
        {...clickProps}
      >
        {formatCurrency(override)}
      </Table.TextCell>
    );
  }

  return (
    <Table.TextCell testId={testId} textAlign="right" {...clickProps}>
      {formatParsedValue(parsedAmount)}
    </Table.TextCell>
  );
}

function formatParsedValue(value) {
  if (isNumber(value)) return formatCurrency(value);
  return null;
}
