import { Children, PureComponent } from "react";
import PropTypes from "prop-types";
import { add, divide, subtract } from "helpers/math";
import CalculationOperation from "./CalculationOperation";

import "./Calculation.css";

class Calculation extends PureComponent {
  static Operation = CalculationOperation;

  totalValue() {
    const { children: operations, initialValue } = this.props;
    return Children.toArray(operations).reduce(
      (total, { props: { operator, value } }) => {
        switch (operator) {
          case "+":
            return add(total, value);
          case "-":
            return subtract(total, value);
          case "/":
            return divide(total, value);
          default:
            return total;
        }
      },
      initialValue
    );
  }

  render() {
    const {
      initialValue,
      initialLabel,
      totalLabel,
      totalType,
      totalSymbol,
      initialColor,
      children: operations,
    } = this.props;

    return (
      <div className="calculation">
        <CalculationOperation
          value={initialValue}
          label={initialLabel}
          className="initial"
          color={initialColor}
        />

        {operations}

        <CalculationOperation
          value={this.totalValue()}
          label={totalLabel}
          type={totalType}
          symbol={totalSymbol}
          className="total"
        />
      </div>
    );
  }
}

Calculation.propTypes = {
  initialValue: PropTypes.number,
  initialLabel: PropTypes.string,
  children: PropTypes.node,
  initialColor: PropTypes.string,
};

export default Calculation;
