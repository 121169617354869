export function PDFViewer({ file, minHeight, height }) {
  const style = {
    display: "block",
    height: height || "100%",
    minHeight: minHeight || "500px",
    width: "100%",
  };

  return (
    <iframe
      alt={file.name}
      title={file.name}
      src={`/pdfjs/web/viewer.html?file=${encodeURIComponent(file.url)}`}
      style={style}
    />
  );
}
