import { useState } from "react";
import { Button, Dropzone, Modal } from "components/materials";
import { minorScale } from "helpers/utilities";

export function UploadPictureModal({
  allowMultiple,
  disabled,
  onCancel,
  onSubmit,
  restrictTypes,
  title,
}) {
  const [files, setFiles] = useState([]);

  return (
    <Modal open title={title} onClose={onCancel}>
      <Modal.Content>
        <Dropzone
          allowMultiple={allowMultiple}
          disabled={disabled}
          files={files}
          onAdd={(addedFiles) => {
            allowMultiple
              ? setFiles((curFiles) => [...curFiles, ...addedFiles])
              : setFiles([...addedFiles]);
          }}
          onCancel={onCancel}
          onRemove={(removeIndex) =>
            setFiles((curFiles) =>
              curFiles.filter((_val, index) => index !== removeIndex)
            )
          }
          onSubmit={onSubmit}
          restrictTypes={restrictTypes}
          uploadTypeMain={allowMultiple ? "images" : "image"}
          uploadTypeButton={allowMultiple ? "Images" : "Image"}
        />
      </Modal.Content>
      <Modal.Actions display="flex" alignItems="center">
        <Button
          marginLeft="auto"
          marginRight={minorScale(3)}
          onClick={onCancel}
          purpose="upload-picture cancel"
        >
          Cancel
        </Button>
        <Button
          appearance="primary"
          disabled={!allowMultiple && files.length === 0}
          isLoading={disabled}
          onClick={() => onSubmit(files)}
          purpose="upload-picture submit"
        >
          Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
