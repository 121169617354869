import PropTypes from "prop-types";
import { AddIcon } from "evergreen-ui";
import {
  Dimmer,
  Divider,
  Link,
  Pane,
  Paragraph,
  Segment,
} from "components/materials";

export function BlankSlate({ attached, hideIcon, prompt, src, to }) {
  return (
    <Segment attached={attached}>
      <Dimmer.Dimmable>
        <Link purpose="blank-slate" to={to}>
          <Dimmer active>
            <Pane alignItems="center" display="flex" flexDirection="column">
              {!hideIcon && <AddIcon color="info" />}
              <Divider color="transparent" />
              <Paragraph>{prompt}</Paragraph>
            </Pane>
          </Dimmer>
        </Link>
        <img alt="blank" src={src} />
      </Dimmer.Dimmable>
    </Segment>
  );
}

BlankSlate.propTypes = {
  attached: PropTypes.bool,
  prompt: PropTypes.node.isRequired,
  src: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

BlankSlate.defaultProps = {
  attached: false,
};
