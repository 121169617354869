import { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  BlankIcon,
  CloudUploadIcon,
  ComparisonIcon,
  CrossIcon,
  TickCircleIcon,
  WarningSignIcon,
} from "evergreen-ui";
import {
  Banner,
  Button,
  Card,
  IconButton,
  Link,
  Pane,
  Progress,
  Shortener,
  Spinner,
  Text,
} from "components/materials";
import { UploadSplitterContext } from "helpers/behaviors";
import { majorScale, minorScale } from "helpers/utilities";
import formatPercent from "helpers/formatPercent";
import { STORAGE_KEYS } from "helpers/localStorageKeys";
import { divide, subtract, sumBy } from "helpers/math";
import { rabbet } from "images";
import t from "helpers/translate";

function uploadIsPending(upload) {
  return (
    get(upload, "file.type") === "application/pdf" &&
    (get(upload, "documents", []).length === 0 ||
      get(upload, "documents", []).some(({ state }) => state === "ADDED"))
  );
}

function uploadNeedsToBeSplit({ toBeSplit, processingFinishedAt }) {
  return toBeSplit && processingFinishedAt;
}

function File({ upload, progress }) {
  return (
    <Pane display="flex" flex="1" alignItems="center">
      <Upload upload={upload} />

      <Pane
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        paddingX={majorScale(2)}
        flex="1"
      >
        <Progress
          height={majorScale(1)}
          color="#1d7ce2"
          value={progress}
          total={1}
        />

        <Text width={34} paddingLeft={majorScale(2)}>
          {formatPercent(divide(progress, 1))}
        </Text>
      </Pane>
    </Pane>
  );
}

File.propTypes = {
  progress: PropTypes.number,
};

File.defaultProps = {
  progress: 0,
};

function Upload({ setOpen, setOpenSplitter, upload }) {
  const UploadLink =
    upload.state !== "UPLOADING" && upload.state !== "ERROR" ? Link : Text;

  const uploadLinkURL = upload.draw
    ? `/projects/${upload.projectId}/draws/${upload.draw.id}/documentation`
    : `/projects/${upload.projectId}/documentation`;

  return (
    <Pane
      alignItems="center"
      display="flex"
      flex="1"
      justifyContent="space-between"
    >
      <Pane display="flex" alignItems="center">
        <Pane paddingRight={majorScale(2)}>
          <UploadIcon upload={upload} />
        </Pane>
        <UploadLink to={uploadLinkURL} fontWeight={600}>
          <Shortener
            isLink
            limit={uploadNeedsToBeSplit(upload) ? 40 : 50}
            text={upload.file.name}
          />
        </UploadLink>
      </Pane>
      {uploadNeedsToBeSplit(upload) && (
        <Button
          marginLeft={majorScale(2)}
          purpose="file-toast split open"
          onClick={() => {
            setOpen(false);
            setOpenSplitter({
              projectId: upload.projectId,
              uploadId: upload.id,
            });
          }}
        >
          Split
        </Button>
      )}
    </Pane>
  );
}

function UploadIcon({ upload }) {
  if (upload.state === "UPLOADING") return <CloudUploadIcon size={16} />;

  if (upload.state === "ERROR")
    return <WarningSignIcon color="warning" size={16} />;

  if (uploadNeedsToBeSplit(upload)) return <ComparisonIcon size={16} />;

  if (uploadIsPending(upload))
    return <Spinner theme={{ spinnerColor: "#1d7ce2" }} size={16} />;

  return <TickCircleIcon color="success" size={16} />;
}

function AggregateIcon({ uploads }) {
  const pendingUploads = uploads.filter(
    (upload) =>
      uploadIsPending(upload) ||
      upload.toBeSplit ||
      upload.state === "UPLOADING"
  );
  const pendingSplitUploads = uploads.filter((upload) =>
    uploadNeedsToBeSplit(upload)
  );
  const erroredUploads = uploads.filter((upload) => upload.state === "ERROR");
  const uploadingUploads = uploads.filter(
    (upload) => upload.state === "UPLOADING"
  );

  if (uploads.length === 0) return <BlankIcon size={24} />;

  if (uploadingUploads.length > 0)
    return <Spinner theme={{ spinnerColor: "#1d7ce2" }} size={24} />;

  if (erroredUploads.length > 0)
    return <WarningSignIcon color="warning" size={24} />;

  if (pendingSplitUploads.length > 0) return <ComparisonIcon size={16} />;

  if (pendingUploads.length > 0)
    return <Spinner theme={{ spinnerColor: "#1d7ce2" }} size={24} />;

  return <TickCircleIcon color="success" size={16} />;
}
export function FileUploads({
  onHide,
  setHiddenErrorUploads,
  uploadProgress,
  uploads,
}) {
  const [open, setOpen] = useState(false);
  const [toastHiddenByUser, setToastHiddenByUser] = useState(
    JSON.parse(localStorage.getItem(STORAGE_KEYS.HIDE_TOAST))
  );
  const { setOpenSplitter } = useContext(UploadSplitterContext);

  useEffect(() => {
    if (uploads.length > 0) {
      setToastHiddenByUser(
        JSON.parse(localStorage.getItem(STORAGE_KEYS.HIDE_TOAST))
      );
    }
  }, [uploads.length]);

  const pendingUploads = uploads.filter(
    (upload) =>
      uploadIsPending(upload) ||
      upload.toBeSplit ||
      upload.state === "UPLOADING"
  );
  const pendingSplitUploads = uploads.filter((upload) =>
    uploadNeedsToBeSplit(upload)
  );
  const erroredUploads = uploads.filter((upload) => upload.state === "ERROR");
  const uploadingUploads = uploads.filter(
    (upload) => upload.state === "UPLOADING"
  );
  const completeUploads = uploads.filter(
    (upload) =>
      !(
        uploadIsPending(upload) ||
        uploadNeedsToBeSplit(upload) ||
        upload.state === "UPLOADING" ||
        upload.state === "ERROR"
      )
  );

  const message = () => {
    if (uploads.length === 0) return "uploadProgress.noUploads";
    if (uploadingUploads.length > 0) return "uploadProgress.uploading";
    if (erroredUploads.length > 0) return "uploadProgress.error";
    if (pendingSplitUploads.length > 0) return "uploadProgress.pendingSplit";
    if (pendingUploads.length > 0) return "uploadProgress.inProgress";
    return "uploadProgress.complete";
  };

  if (toastHiddenByUser) return null;

  const datapointCount = sumBy(
    uploads.map(({ parsedDatapointCount }) => parsedDatapointCount)
  );

  return (
    <Card width={500} paddingY={majorScale(2)}>
      {uploads.length > 0 && (
        <Pane
          borderBottom={open && "1px solid #e4eaf0"}
          display="flex"
          flexDirection="column"
          marginBottom={open && majorScale(2)}
          transition="max-height 0.3s"
        >
          <Pane
            paddingX={majorScale(2)}
            maxHeight={open ? 300 : 0}
            overflowY={open ? "auto" : "hidden"}
            paddingBottom={open && minorScale(1)}
          >
            <Pane paddingBottom={majorScale(1)}>
              <Button
                marginRight={majorScale(1)}
                purpose="file-toast hide-all"
                onClick={() => {
                  const [uploadIds, cacheOnlyUploadIds] = uploads.reduce(
                    ([accUploadIds, accCacheOnlyUploadIds], upload) => {
                      if (
                        upload.state === "UPLOADING" ||
                        upload.state === "ERROR"
                      ) {
                        accCacheOnlyUploadIds.push(upload.id);
                      } else {
                        accUploadIds.push(upload.id);
                      }
                      return [accUploadIds, accCacheOnlyUploadIds];
                    },
                    [[], []]
                  );

                  onHide(uploadIds);
                  cacheOnlyUploadIds.forEach((uploadId) => {
                    setHiddenErrorUploads((hiddenErrorUploads) => [
                      ...hiddenErrorUploads,
                      uploadId,
                    ]);
                  });
                }}
              >
                Clear All
              </Button>
              <Button
                purpose="file-toast hide-completed"
                disabled={completeUploads.length === 0}
                onClick={() => {
                  const uploadIds = completeUploads.map(({ id }) => id);
                  onHide(uploadIds);
                }}
              >
                Clear Complete
              </Button>
            </Pane>
            {uploads.map((upload) => (
              <Pane
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                key={upload.id}
                marginY={majorScale(1)}
              >
                {upload.state === "UPLOADING" ? (
                  <File progress={uploadProgress[upload.id]} upload={upload} />
                ) : (
                  <Pane
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Upload
                      setOpen={setOpen}
                      setOpenSplitter={setOpenSplitter}
                      upload={upload}
                    />
                    <IconButton
                      appearance="minimal"
                      icon={CrossIcon}
                      marginLeft={majorScale(1)}
                      onClick={() =>
                        upload.state === "ERROR"
                          ? setHiddenErrorUploads((hiddenErrorUploads) => [
                              ...hiddenErrorUploads,
                              upload.id,
                            ])
                          : onHide(upload.id)
                      }
                      purpose="file-toast hide-one"
                      type="button"
                    />
                  </Pane>
                )}
              </Pane>
            ))}
          </Pane>
          {open && datapointCount > 0 && (
            <Banner borderTop paddingX={majorScale(2)}>
              <Pane display="flex" alignItems="center" justifyContent="center">
                <Pane marginRight={majorScale(2)}>
                  <img alt="Rabbet" src={rabbet} style={{ width: 16 }} />
                </Pane>

                <Text>
                  {t("uploadProgress.datapointBanner", {
                    count: datapointCount,
                    fileCount: t("uploadProgress.fileCount", {
                      count: uploads.length,
                    }),
                  })}
                </Text>
              </Pane>
            </Banner>
          )}
        </Pane>
      )}
      <Pane
        paddingX={majorScale(2)}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontWeight={600}>{t(message())}</Text>

        <Pane paddingX={majorScale(2)}>
          <AggregateIcon uploads={uploads} />
        </Pane>

        <Text>
          {t("uploadProgress.uploadsComplete", {
            pendingCount: subtract(uploads.length, pendingUploads.length),
            count: uploads.length,
          })}
        </Text>

        {uploads.length > 0 && (
          <Pane width={100}>
            <Button
              appearance="minimal"
              marginLeft={majorScale(1)}
              iconBefore={open ? ArrowDownIcon : ArrowUpIcon}
              cursor={uploads.length > 0 ? "pointer" : "inherit"}
              purpose={`file-toast ${open ? "collapse" : "expand"}`}
              onClick={() => uploads.length > 0 && setOpen(!open)}
            >
              {open ? "Collapse" : "Expand"}
            </Button>
          </Pane>
        )}
        <IconButton
          appearance="minimal"
          icon={CrossIcon}
          onClick={() => {
            localStorage.setItem(STORAGE_KEYS.HIDE_TOAST, true);
            setToastHiddenByUser(true);
          }}
          type="button"
        />
      </Pane>
    </Card>
  );
}

FileUploads.propTypes = {
  pendingCount: PropTypes.number,
  total: PropTypes.number,
};
