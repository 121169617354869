import { memo, forwardRef } from "react";
import merge from "lodash.merge";
import PropTypes from "prop-types";
import {
  Pane,
  TableRow,
  Text,
  TextTableCell,
  minorScale,
  TickIcon,
} from "evergreen-ui";
import { useStyleConfig } from "evergreen-ui/commonjs/hooks/use-style-config";
import Shortener from "../../Shortener/index";

// https://github.com/segmentio/evergreen/blob/c011a50e92ec9de090421b077b90469c03f047fe/src/select-menu/src/Option.js
// originally implemented within Rabbet here: https://github.com/Rabbet/lift/pull/1386/files#diff-22fb10e7cbefaf1877f4bf6b17239b6551e8bb63039e6a6ef641d25c837d4267
// Rabbet additions:
//   - styling via `textProps` and `itemTextProps`
//   - `fullBorder` implementation
//   - accept an `icon` field on the item
//   - render item `text`, `meta`, and `description` instead of rendering `children` directly

export const pseudoSelectors = {
  _active: '&[aria-current="true"]:active,&[data-isselectable="true"]:active',
  _before: "&:before",
  _disabled: "&[disabled]",
  _focus: ":focus",
  _hover: ":hover",
  _isSelectable: '&[data-isselectable="true"]',
  _selected: '&[aria-current="true"]',
};

const internalStyles = {
  alignItems: "center",
  display: "flex",
};

const emptyObject = {};

const Option = memo(
  forwardRef(function Option(props, ref) {
    const {
      _children,
      disabled,
      height,
      isHighlighted,
      isSelectable,
      isSelected,
      item,
      onDeselect,
      onSelect,
      style: styleProp,
      fullBorder,
      ...rest
    } = props;

    const { style: themedStyle, ...themedProps } = useStyleConfig(
      "Option",
      emptyObject,
      pseudoSelectors,
      internalStyles
    );

    const style = merge({}, styleProp, themedStyle);

    const textProps = {};
    if (disabled) {
      textProps.color = "muted";
    }

    if (isSelected) {
      textProps.color = "selected";
    }

    const itemTextProps = {
      alignItems: "center",
      display: "flex",
    };

    if (item.highlighted) {
      itemTextProps.color = "info";
    }
    return (
      <TableRow
        isSelectable={isSelectable && !disabled}
        isHighlighted={isHighlighted}
        onSelect={onSelect}
        onDeselect={onDeselect}
        isSelected={isSelected}
        style={style}
        {...themedProps}
        {...rest}
        borderBottom={fullBorder}
        ref={ref}
      >
        <Pane
          paddingLeft={12}
          paddingRight={8}
          flexGrow={0}
          opacity={isSelected || item.icon ? 1 : 0}
          paddingTop={4}
        >
          {isSelected || !item.icon ? (
            <TickIcon color="selected" icon={TickIcon} size={14} />
          ) : (
            item.icon
          )}
        </Pane>
        <TextTableCell
          borderRight={null}
          borderBottom={!fullBorder && "muted"}
          flex={1}
          alignSelf="stretch"
          height={height}
          cursor={disabled ? "default" : "pointer"}
          textProps={textProps}
        >
          <Pane alignItems="center" display="flex">
            {item.description && typeof item.text === "string" ? (
              <Shortener text={item.text} limit={80} {...itemTextProps} />
            ) : (
              <Text {...itemTextProps}>{item.text}</Text>
            )}

            {item.meta}

            {item.description && (
              <Pane flex="1 1 auto" justifyContent="flex-end" display="flex">
                <Text
                  color="muted"
                  fontStyle="italic"
                  size={300}
                  paddingTop={2}
                  marginLeft={minorScale(2)}
                  {...itemTextProps}
                >
                  {item.description}
                </Text>
              </Pane>
            )}
          </Pane>
        </TextTableCell>
      </TableRow>
    );
  })
);

Option.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  height: PropTypes.number,
  isHighlighted: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  item: PropTypes.any,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  style: PropTypes.any,
};

export default Option;
