import { Fragment } from "react";
import PropTypes from "prop-types";
import { get, find, every } from "lodash";
import { CrossIcon } from "evergreen-ui";
import { VendorLink } from "components/templates";
import { Form, IconButton, Pane, Table } from "components/materials";
import { minorScale } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import t from "helpers/translate";
import {
  formatLineItems,
  getLineItemOptions,
} from "helpers/invoiceLineItemHelpers";
import {
  getEmptyLineItem,
  getInitialValues,
  getVendorIdForDocument,
  getVendorIdFromForm,
  MethodSelector,
} from "helpers/lienReviewHelpers";
import { sumBy } from "helpers/math";
import isBlank from "helpers/isBlank";
import DocumentReviewLayout from "./DocumentReviewLayout";
import DocumentLineItems from "./DocumentLineItems";

const getDocumentOptions = (documents) => {
  return documents.map((document) => {
    return {
      key: document.invoiceId,
      value: { id: document.invoiceId },
      text: document.amount
        ? `${t(`documentTypeName.${document.type}`)} - ${
            document.file.name
          } - ${formatCurrency(document.amount)}`
        : `${t(`documentTypeName.${document.type}`)} - ${document.file.name}`,
    };
  });
};

const getDocumentDataFromForm = (values) => {
  if (values.isManual) {
    return {
      isBackup: true,
      isFinal: values.isFinal,
      lineItems: formatLineItems(values.lineItems),
      payApplicationId: null,
      invoiceId: null,
    };
  }

  return {
    isBackup: true,
    isFinal: values.isFinal,
    payApplicationId:
      values.target.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION
        ? values.target.id
        : null,
    invoiceId:
      values.target.type === DOCUMENT_TYPE_NAME.INVOICE
        ? values.target.id
        : null,
  };
};

function getInformationSummary(form) {
  const summary = [get(form.values.vendor, "name")];
  return summary.filter((value) => !!value).join(", ");
}

function isMissingInformation(values) {
  const lineItemsMissingInformation =
    get(values, "lineItems.length") === 0 ||
    every(
      values.lineItems,
      (lineItem) =>
        lineItem.amount === "" || !get(lineItem, "lineItemObject.id")
    );
  if (
    values.isManual &&
    (!get(values, "vendor.id") || lineItemsMissingInformation)
  ) {
    return true;
  }
  if (!values.isManual && !get(values, "target.id")) {
    return true;
  }
  return false;
}

function ConditionalLienWaiverReview(props) {
  const { form, selectedDraw } = props;

  if (!form.values.lineItems) return null;

  return (
    <DocumentReviewLayout
      {...props}
      meta={(content) => (
        <Pane display="flex" width="100%" alignItems="center">
          {content}

          <Pane display="flex" flexGrow={1} alignItems="center">
            <Form.Checkbox
              name="isFinal"
              label="Final Lien Waiver"
              margin={0}
            />
          </Pane>
        </Pane>
      )}
      information={
        form.values.isManual
          ? (content) => (
              <Pane
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                width="100%"
              >
                {content}

                <Pane
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <VendorLink
                    vendorId={form.values.vendor.id}
                    vendors={props.allAvailableVendors}
                  />
                </Pane>
              </Pane>
            )
          : null
      }
      informationSummary={getInformationSummary(form)}
      selector={<MethodSelector form={form} />}
    >
      <Fragment>
        {!form.values.isManual ? (
          <Form.Section
            heading="Pay Application or Invoice"
            isCollapsible
            isCollapsed={props.collapsedPanels.includes("assignedTo")}
            onCollapse={() => props.togglePanel("assignedTo")}
          >
            <Pane display="flex" width="100%">
              <Form.Select
                popoverMinWidth={600}
                label="Assign to"
                name="target"
                noNull
                isWarned={isBlank(get(form, "values.target.id"))}
                onChange={(selectedDocument) => {
                  form.setFieldValue(
                    "targetVendorId",
                    getVendorIdForDocument(
                      selectedDraw.documents,
                      selectedDocument
                    )
                  );
                }}
                options={
                  selectedDraw
                    ? getDocumentOptions(
                        selectedDraw.documents.filter((document) =>
                          [
                            DOCUMENT_TYPE_NAME.PAY_APPLICATION,
                            DOCUMENT_TYPE_NAME.INVOICE,
                          ].includes(document.type)
                        )
                      )
                    : []
                }
              />
            </Pane>
          </Form.Section>
        ) : (
          <DocumentLineItems getEmptyLineItem={getEmptyLineItem} {...props}>
            {({ remove }) => (
              <Table paddingBottom={0}>
                <Table.Head>
                  <Table.Row>
                    <Table.TextHeaderCell width="50%">
                      Line Item
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell width="50%">
                      Amount
                    </Table.TextHeaderCell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {form.values.lineItems.map((lineItem, index) => (
                    <Table.Row key={lineItem.lineItemObject.id || index}>
                      <Table.TextCell id="lineItem">
                        {!form.values.__disabled && (
                          <IconButton
                            appearance="minimal"
                            icon={CrossIcon}
                            marginRight={minorScale(1)}
                            onClick={() => remove(index)}
                            type="button"
                          />
                        )}
                        <Form.Select
                          isWarned={isBlank(
                            get(
                              form,
                              `values.lineItems.${index}.lineItemObject.id`
                            )
                          )}
                          name={`lineItems.${index}.lineItemObject`}
                          noNull
                          onChange={(selected) => {
                            const dli = find(
                              selectedDraw.lineItems,
                              (li) => li.id === selected.id
                            );
                            form.setFieldValue(
                              `lineItems.${index}.amount`,
                              formatCurrency(get(dli, "requestedAmount", 0))
                            );
                          }}
                          options={
                            selectedDraw
                              ? getLineItemOptions(selectedDraw.lineItems)
                              : []
                          }
                          placeholder="Select Line Item..."
                        />
                      </Table.TextCell>
                      <Table.TextCell id="amount">
                        <Form.Input
                          isWarned={isBlank(
                            get(form, `values.lineItems.${index}.amount`)
                          )}
                          name={`lineItems.${index}.amount`}
                          textAlign="right"
                          type="currency"
                        />
                      </Table.TextCell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Foot>
                  <Table.Row>
                    <Table.TextFooterCell />
                    <Table.TextFooterCell textAlign="right">
                      {formatCurrency(sumBy(form.values.lineItems, "amount"))}
                    </Table.TextFooterCell>
                  </Table.Row>
                </Table.Foot>
              </Table>
            )}
          </DocumentLineItems>
        )}
      </Fragment>
    </DocumentReviewLayout>
  );
}

ConditionalLienWaiverReview.isMissingInformation = isMissingInformation;

ConditionalLienWaiverReview.propTypes = {
  children: PropTypes.node,
  selectedDraw: PropTypes.object,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      number: PropTypes.string,
      date: PropTypes.string,
      lineItems: PropTypes.arrayOf({
        amount: PropTypes.number,
        lineItemObject: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    }),
  }),
  onApprove: PropTypes.func,
  onComment: PropTypes.func,
  onChangeDraw: PropTypes.func,
  onChangeType: PropTypes.func,
};

// TODO: remove dot notation
ConditionalLienWaiverReview.getEmptyLineItem = getEmptyLineItem;
ConditionalLienWaiverReview.getInitialValues = getInitialValues;
ConditionalLienWaiverReview.getDocumentDataFromForm = getDocumentDataFromForm;
ConditionalLienWaiverReview.getVendorIdFromForm = getVendorIdFromForm;

export default ConditionalLienWaiverReview;
