import { useEffect, Fragment, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Heading, Loadable, Sidebar, Table, Text } from "components/materials";
import { get, map, sumBy } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";
import { formatDate } from "helpers/dateHelpers";
import formatPercent from "helpers/formatPercent";
import { majorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";
import { AGREEMENT_SIDEBAR_QUERY } from "./graphql-queries";
import { Document } from "../Document";

function Agreements({ agreements, lineItemId, onClickAgreement, title }) {
  const theme = useContext(ThemeContext);
  const isExposures = title === "Pending Agreements";
  const testidPrefix = isExposures ? "exposure" : "agreement";
  const mutedBorder = `1px solid ${theme.colors.gray200}`;
  const cellBorder = isExposures
    ? `1px solid ${theme.tableColors.specialBorder}`
    : mutedBorder;

  if (agreements.length === 0) return null;

  const cellStyles = isExposures
    ? {
        backgroundColor: theme.tableColors.specialRow,
        borderLeft: cellBorder,
      }
    : { borderLeft: cellBorder };

  const total = sumBy(agreements, (agreement) =>
    sumBy(
      agreement.lineItems.filter(
        (lineItem) => lineItem.lineItemId === lineItemId
      ),
      "amount"
    )
  );

  return (
    <Sidebar.Section>
      <Heading marginBottom={majorScale(1)}>{title}</Heading>
      <Table paddingBottom={0}>
        <Table.Head>
          <Table.Row>
            <Table.TextHeaderCell borderLeft={mutedBorder}>
              Type
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Document</Table.TextHeaderCell>
            <Table.TextHeaderCell>Notes</Table.TextHeaderCell>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="right">
              Expected Retainage %
            </Table.TextHeaderCell>
            <Table.TextHeaderCell borderRight={mutedBorder} textAlign="right">
              Amount
            </Table.TextHeaderCell>
          </Table.Row>
        </Table.Head>
        {agreements.map((agreement) => (
          <Table.Row
            key={agreement.id}
            onClick={() => onClickAgreement(agreement)}
          >
            <Table.TextCell
              {...cellStyles}
              id={`${testidPrefix}-type`}
              borderLeft={mutedBorder}
            >
              {t(`agreementType.${agreement.type}`)}
            </Table.TextCell>
            <Table.TextCell
              {...cellStyles}
              id={`${testidPrefix}-document`}
              maxWidth={150}
            >
              {get(agreement, "document.file.name")}
            </Table.TextCell>
            <Table.TextCell
              {...cellStyles}
              id={`${testidPrefix}-description`}
              maxWidth={150}
            >
              {agreement.agreementDescription}
            </Table.TextCell>
            <Table.TextCell {...cellStyles} id={`${testidPrefix}-start`}>
              {formatDate(agreement.startDate)}
            </Table.TextCell>
            <Table.TextCell
              {...cellStyles}
              textAlign="right"
              id={`${testidPrefix}-retainage`}
            >
              {formatPercent(
                get(
                  agreement.lineItems.find(
                    (lineItem) => lineItem.lineItemId === lineItemId
                  ),
                  "retainagePercentage",
                  0
                ),
                "0%"
              )}
            </Table.TextCell>
            <Table.TextCell
              id={`${testidPrefix}-amount`}
              {...cellStyles}
              borderRight={mutedBorder}
              textAlign="right"
            >
              {formatCurrency(
                sumBy(
                  agreement.lineItems.filter(
                    (lineItem) => lineItem.lineItemId === lineItemId
                  ),
                  "amount"
                )
              )}
            </Table.TextCell>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Cell
            id={`${testidPrefix}-total`}
            border="none"
            colSpan={7}
            textAlign="right"
          >
            <Text fontWeight={500} size={300} marginRight={majorScale(1)}>
              Total:
            </Text>
            <Text fontWeight={500} size={300}>
              {formatCurrency(total)}
            </Text>
          </Table.Cell>
        </Table.Row>
      </Table>
    </Sidebar.Section>
  );
}

function Spent({ documentation, onClickDocument }) {
  const theme = useContext(ThemeContext);
  const muted = `1px solid ${theme.colors.gray200}`;

  if (documentation.length === 0) return null;

  const total = sumBy(documentation, "amount");

  return (
    <Sidebar.Section>
      <Heading marginBottom={majorScale(1)}>Spent</Heading>
      <Table paddingBottom={0}>
        <Table.Head>
          <Table.Row>
            <Table.TextHeaderCell borderLeft={muted}>Draw</Table.TextHeaderCell>
            <Table.TextHeaderCell>Document</Table.TextHeaderCell>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            <Table.TextHeaderCell borderRight={muted} textAlign="right">
              Amount
            </Table.TextHeaderCell>
          </Table.Row>
        </Table.Head>
        {documentation.map((documentation) => (
          <Table.Row
            key={documentation.id}
            onClick={() => onClickDocument(documentation.document.id)}
          >
            <Table.TextCell id="spent-draw" borderLeft={muted}>
              {get(documentation, "document.draw.name", "Project")}
            </Table.TextCell>
            <Table.TextCell
              id="spent-document"
              borderLeft={muted}
              maxWidth={175}
            >
              {get(documentation, "document.file.name")}
            </Table.TextCell>
            <Table.TextCell id="spent-date" borderLeft={muted}>
              {formatDate(
                get(documentation, "document.date") ||
                  get(documentation, "document.periodToDate")
              )}
            </Table.TextCell>
            <Table.TextCell
              id="spent-amount"
              borderLeft={muted}
              borderRight={muted}
              textAlign="right"
              width={115}
            >
              {formatCurrency(documentation.amount)}
            </Table.TextCell>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Cell
            id="spent-total"
            border="none"
            colSpan={4}
            textAlign="right"
          >
            <Text fontWeight={500} size={300} marginRight={majorScale(1)}>
              Total:
            </Text>
            <Text fontWeight={500} size={300}>
              {formatCurrency(total)}
            </Text>
          </Table.Cell>
        </Table.Row>
      </Table>
    </Sidebar.Section>
  );
}

function AgreementsSidebarContent({
  loading,
  onClickDocument,
  onClickAgreement,
  vendorLineItem,
}) {
  if (loading) return <Loadable loading />;
  const {
    agreements,
    documentation,
    pendingAgreements,
    lineItem,
    vendor,
  } = vendorLineItem;

  const sharedAgreementsProps = {
    lineItemId: lineItem?.id || null,
    onClickDocument,
    onClickAgreement,
  };

  return (
    <Fragment>
      <Sidebar.Heading
        breadcrumbs={[
          get(vendor, "name", "(No Vendor)"),
          get(lineItem, "name")
            ? `${lineItem.division.name} - ${lineItem.name}`
            : "(Unassigned)",
        ]}
      />
      <Agreements
        {...sharedAgreementsProps}
        agreements={pendingAgreements}
        title="Pending Agreements"
      />
      <Agreements
        {...sharedAgreementsProps}
        agreements={agreements}
        title="Agreements"
      />
      <Spent documentation={documentation} onClickDocument={onClickDocument} />
    </Fragment>
  );
}

export function AgreementsSidebar({
  closeSidebar,
  history,
  match,
  onClickAgreement,
  refetchQueries,
}) {
  const { documentId, lineItemId, projectId, vendorId } = match.params;
  const queryParams = {
    lineItemId: lineItemId === "null" ? null : lineItemId,
    projectId,
    vendorId: vendorId === "null" ? null : vendorId,
  };

  const { data, loading } = useQuery(AGREEMENT_SIDEBAR_QUERY, {
    skip: !lineItemId || !vendorId,
    variables: queryParams,
  });

  const vendorLineItem = get(data, "project.agreementVendorLineItem", {
    agreements: [],
    documentation: [],
    pendingAgreements: [],
  });

  const sideBarIsEmpty =
    vendorLineItem.agreements.length === 0 &&
    vendorLineItem.documentation.length === 0 &&
    vendorLineItem.pendingAgreements.length === 0;

  useEffect(() => {
    if (lineItemId && !loading && sideBarIsEmpty) {
      closeSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorLineItem]);

  const openDocumentsViewer = (documentId) => {
    history.push({
      pathname: `${history.location.pathname}/${documentId}`,
      search: history.location.search,
    });
  };

  const open = lineItemId && vendorId && !documentId;

  const documentViewerDocuments = data
    ? map(
        get(data, "project.agreementVendorLineItem.documentation", []),
        "document"
      )
    : [];

  // This prevents the sidebar from showing again
  // after the last agreement content has been deleted
  if (sideBarIsEmpty) {
    return null;
  }

  return (
    <Fragment>
      <Sidebar isShown={open} onCloseComplete={closeSidebar} width={740}>
        <AgreementsSidebarContent
          loading={loading}
          onClickDocument={openDocumentsViewer}
          onClickAgreement={onClickAgreement}
          vendorLineItem={vendorLineItem}
        />
      </Sidebar>
      {documentId && (
        <Document
          documents={documentViewerDocuments}
          history={history}
          match={match}
          refetch={[
            ...refetchQueries,
            { query: AGREEMENT_SIDEBAR_QUERY, variables: queryParams },
          ]}
        />
      )}
    </Fragment>
  );
}
