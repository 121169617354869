import { useState } from "react";
import PropTypes from "prop-types";
import { useHiddenViewerOnMount } from "helpers/behaviors/InternetExplorerContext";
import { Modal, Pane, Paragraph, TextInput } from "components/materials";

const SuperConfirm = ({
  title,
  hideViewer,
  onCancel,
  onCloseComplete,
  onConfirm,
  open,
  warningMessage,
  confirmMatchText,
  ...props
}) => {
  useHiddenViewerOnMount(hideViewer && open);

  const [confirmed, setConfirmed] = useState(false);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onClose={() => {
        setConfirmed(false);
        onCloseComplete();
      }}
      onConfirm={onConfirm}
      isConfirmDisabled={!confirmed}
      title={title}
      hasFooter
      {...props}
    >
      {warningMessage && (
        <Pane>
          <Paragraph paddingBottom="16px">{warningMessage}</Paragraph>
          <TextInput
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={(e) =>
              e.target.value === confirmMatchText
                ? setConfirmed(true)
                : setConfirmed(false)
            }
          />
        </Pane>
      )}
    </Modal>
  );
};

SuperConfirm.propTypes = {
  hasCancel: PropTypes.bool,
  hideViewer: PropTypes.bool,
  onCancel: PropTypes.func,
  onCloseComplete: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
};

SuperConfirm.defaultProps = {
  hideViewer: false,
  onCancel: undefined,
  cancelLabel: "Cancel",
  confirmLabel: "Delete",
};

export default SuperConfirm;
