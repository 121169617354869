import { Form, Paragraph } from "components/materials";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";

export function DrawSummaryOptions({
  isCollapsed,
  lockdownDraw,
  onCollapse,
  fontWeight,
  values,
}) {
  const optionLabelProps = {
    fontWeight,
    marginBottom: -majorScale(1),
    size: 300,
  };
  return (
    <Form.Section
      heading="Draw Summary Options"
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={onCollapse}
      summary={getOptionsSummary(values)}
    >
      <Paragraph marginBottom={majorScale(2)}>
        {t("drawSummaryReview.optionsPrompt")}
      </Paragraph>
      <Form.RadioGroup
        display="flex"
        label={t("drawSummaryReview.adjustmentsPrompt")}
        labelProps={optionLabelProps}
        marginBottom={majorScale(1)}
        name="adjustmentsMethod"
        options={getDrawSummaryOptions("adjustments", lockdownDraw)}
      />
      <Form.RadioGroup
        display="flex"
        label={t("drawSummaryReview.requestedPrompt")}
        labelProps={optionLabelProps}
        marginBottom={majorScale(1)}
        name="requestedMethod"
        options={getDrawSummaryOptions("requested", lockdownDraw)}
      />
      <Form.RadioGroup
        display="flex"
        label={t("drawSummaryReview.retainagePrompt")}
        labelProps={optionLabelProps}
        name="retainageMethod"
        options={getDrawSummaryOptions("retainage", lockdownDraw)}
      />
    </Form.Section>
  );
}

export function Label({ label }) {
  return (
    <Paragraph size={300} width={100}>
      {label}
    </Paragraph>
  );
}

function getDrawSummaryOptions(field, isDisabled) {
  switch (field) {
    case "retainage":
      return [
        {
          label: <Label label={t("drawSummaryReview.optionLabel.toDate")} />,
          value: "toDate",
          isDisabled,
        },
        {
          label: (
            <Label label={t("drawSummaryReview.optionLabel.thisPeriod")} />
          ),
          value: "thisPeriod",
          isDisabled,
        },
        {
          label: (
            <Label label={t("drawSummaryReview.optionLabel.noRetainage")} />
          ),
          value: "noRetainage",
          isDisabled,
        },
      ];
    case "requested":
      return [
        {
          label: <Label label={t("drawSummaryReview.optionLabel.gross")} />,
          value: "gross",
          isDisabled,
        },
        {
          label: <Label label={t("drawSummaryReview.optionLabel.net")} />,
          value: "net",
          isDisabled,
        },
      ];
    case "adjustments":
      return [
        {
          label: <Label label={t("drawSummaryReview.optionLabel.toDate")} />,
          value: "toDate",
          isDisabled,
        },
        {
          label: (
            <Label label={t("drawSummaryReview.optionLabel.thisPeriod")} />
          ),
          value: "thisPeriod",
          isDisabled,
        },
        {
          label: (
            <Label label={t("drawSummaryReview.optionLabel.currentBudget")} />
          ),
          value: "currentBudget",
          isDisabled,
        },
      ];
    default:
      return [];
  }
}

function getOptionsSummary({
  requestedMethod,
  retainageMethod,
  adjustmentsMethod,
}) {
  return [
    t(`drawSummaryReview.optionLabel.${adjustmentsMethod}`),
    t(`drawSummaryReview.optionLabel.${requestedMethod}`),
    t(`drawSummaryReview.optionLabel.${retainageMethod}`),
  ].join(", ");
}
