export const PROJECTION_EVENT = {
  COLUMN_WAS_UPDATED: "COLUMN_WAS_UPDATED",
  DIVISION_SETTING_CHANGED: "DIVISION_SETTING_CHANGED",
  FUNDING_SOURCE_CHART_UPDATED: "FUNDING_SOURCE_CHART_UPDATED",
  LINE_ITEM_SETTING_CHANGED: "LINE_ITEM_SETTING_CHANGED",
  LINE_ITEM_VALUE_CHANGED: "LINE_ITEM_VALUE_CHANGED",
  PROJECTION_CHARTS_UPDATED: "PROJECTION_CHARTS_UPDATED",
  PROJECTION_WAS_SAVED: "PROJECTION_WAS_SAVED",
  RESET_ALL_VALUES: "RESET_ALL_VALUES",
  SAVE_PROJECTION: "SAVE_PROJECTION",
  UNDO: "UNDO",
  UPDATE_PROJECTION: "UPDATE_PROJECTION",
  VIEW_WAS_UPDATED: "VIEW_WAS_UPDATED",
};
