import { useMemo, useEffect, useState } from "react";
import { debounce, get } from "lodash";
import PropTypes from "prop-types";
import { Form, MaskedInput, Paragraph } from "components/materials";

function sanitizeValue(value, type) {
  // TODO: sanitize other types
  switch (type) {
    case "currency":
      return sanitizeCurrencyInputValue(value);
    default:
      return value;
  }
}

function sanitizeCurrencyInputValue(value) {
  if (value === "$") return null;
  return value;
}

// This controlled input handles its own input value independent on any form it may be connected to;
// however, it will also allows parent components to receive state changes via a debounced onChange handler.
// TODO(kelly): invalid type inputs will still get saved as the value
function Input({ initialValues, name, onChange, type, ...selectProps }) {
  // `get` handles deeply nested values nicely, as opposed to initialValues[name]
  const initialValue = get(initialValues, name);
  const [inputValue, setInputValue] = useState(initialValue);

  // Reset the inner value if the form resets
  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue, initialValues]);

  const handleOnChange = useMemo(
    () => debounce((inputValue) => onChange(name, inputValue), 500),
    [name, onChange]
  );

  function handleOnInput(e) {
    const { value } = e.target;
    const sanitizedValue = sanitizeValue(value, type);

    setInputValue(sanitizedValue);
    handleOnChange(sanitizedValue);
  }

  return (
    <MaskedInput
      {...selectProps}
      onInput={handleOnInput}
      type={type}
      value={inputValue}
    />
  );
}

export function FormNewInput({
  disabled,
  error,
  fieldProps,
  initialValues,
  isHighlighted,
  isWarned,
  label,
  labelProps,
  name,
  onChange,
  type,
  ...props
}) {
  return (
    <Form.Field
      isHighlighted={isHighlighted}
      isWarned={isWarned}
      label={label && <Paragraph {...labelProps}>{label}</Paragraph>}
      validationMessage={error}
      {...fieldProps}
    >
      <Input
        disabled={disabled}
        initialValues={initialValues}
        name={name}
        onChange={onChange}
        type={type}
        {...props}
      />
    </Form.Field>
  );
}

FormNewInput.propTypes = {
  disabled: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isWarned: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  fieldProps: PropTypes.object,
  type: PropTypes.oneOf([
    "email",
    "text",
    "currency",
    "number",
    "integer",
    "percentage",
    "tel",
    "password",
    "zip",
  ]),
};

FormNewInput.defaultProps = {
  disabled: false,
  isHighlighted: false,
  isWarned: false,
  label: "",
  labelProps: {},
  onChange: () => {},
  fieldProps: {},
  type: "text",
};
