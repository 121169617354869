import PropTypes from "prop-types";
import { Button } from "components/materials";

const FormSubmitButton = ({ disabled, isLoading, label, ...otherProps }) => {
  return (
    <Button
      appearance="primary"
      content={label}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      type="submit"
      {...otherProps}
    />
  );
};

FormSubmitButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
};

FormSubmitButton.defaultProps = {
  disabled: false,
  isLoading: false,
  label: "Submit",
};

export default FormSubmitButton;
