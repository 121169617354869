import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { EnvelopeIcon } from "evergreen-ui";
import { AcceptInvitationModal } from "components/containers";
import { Alert, Pane, Text } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";
import { get } from "lodash";

export function PendingInvitationLink({ invitation }) {
  const [acceptInvitationModalOpen, setAcceptInvitationModalOpen] = useState(
    false
  );

  const organizationName = get(invitation, "organization.name");
  const closeModal = () => setAcceptInvitationModalOpen(false);

  return (
    <Fragment>
      <Alert
        appearance="card"
        cursor="pointer"
        data-testid="pending-invitations-link"
        display="flex"
        flex="0 0 auto"
        hasIcon={false}
        intent="success"
        onClick={() => setAcceptInvitationModalOpen(true)}
        paddingX={majorScale(2)}
        paddingY={minorScale(1)}
        title={
          <Pane display="flex" alignItems="center">
            {/* Subtract 3px from marginRight to accommodate for the Alert's left border */}
            <Text paddingTop={2} marginRight={majorScale(2) - 3}>
              <EnvelopeIcon color="success" size={12} />
            </Text>

            <Text fontWeight="normal" fontSize={12}>
              {t("acceptInvitation.modalTitle", { organizationName })}
            </Text>
          </Pane>
        }
      />
      {acceptInvitationModalOpen && (
        <AcceptInvitationModal onClose={closeModal} invitation={invitation} />
      )}
    </Fragment>
  );
}

PendingInvitationLink.propTypes = {
  invitation: PropTypes.object.isRequired,
};
