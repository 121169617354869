import { useContext, useMemo, useState, Fragment } from "react";
import PropTypes from "prop-types";
import {
  BoxIcon,
  CaretDownIcon,
  CommentIcon,
  EnvelopeIcon,
  TickCircleIcon,
  WarningSignIcon,
} from "evergreen-ui";
import {
  ApproveDocumentButton,
  getNotifyAssigneesQueryObject,
  DocumentActions,
  EditTableViews,
  NotifyDocumentAssignees,
} from "components/containers";
import {
  BulkDocumentToolbar,
  DocumentCard,
  DocumentStatusIcon,
  IssuesViewName,
  PendingApprovalsBadge,
  IssuesInfoModal,
} from "components/templates";
import {
  Alert,
  Checkbox,
  IconButton,
  Link,
  Pane,
  Paragraph,
  SelectMenu,
  Shortener,
  Spinner,
  Text,
  TextInput,
  Tooltip,
} from "components/materials";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  COMPARATORS,
  actionsUtilityColumnDefaults,
  booleanColumnDefaults,
  bulkSelectUtilityColumnDefaults,
  currencyColumnDefaults,
  dateColumnDefaults,
  dateTimeColumnDefaults,
  enumColumnDefaults,
  fractionColumnDefaults,
  listColumnDefaults,
  numberColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
  toBase64,
} from "components/materials/FastDataTable";
import { UserContext } from "helpers/behaviors";
import {
  aggregateDocumentApprovalsData,
  isApprovableDocumentType,
  isPayableDocument,
  PAYMENT_OPTIONS,
  getCalculatedFileName,
  getDocumentTypes,
  getLastApproval,
  getNextOrderedReviewer,
  userCanApproveDocumentAmount,
  PAYABLE_DOCUMENT_TYPE_NAMES,
} from "helpers/documentHelpers";
import {
  getIssuesFilterConfig,
  getIssuesRowState,
  getIssuesTableValue,
  getIssuesTableValueFormatter,
  GUIDING_EXPLANATION_SCOPES,
} from "helpers/issues";
import {
  getDateRangeAggregate,
  getDateTimeRangeAggregate,
  getDefaultAggregate,
} from "helpers/tableAggregateHelpers";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { majorScale, ThemeContext } from "helpers/utilities";
import {
  difference,
  flatten,
  flatMap,
  get,
  map,
  partition,
  uniq,
  xor,
  values,
} from "lodash";
import { formatDateTime } from "helpers/dateHelpers";
import { add, sumBy } from "helpers/math";
import {
  DOCUMENT_STATE,
  DOCUMENT_TYPE_NAME,
  INVOICE_TYPE,
  PERMISSION_ACTION,
  PROJECT_STATUS_TYPE,
} from "helpers/enums";
import { getEnumValuesForOrganization } from "helpers/reportHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import * as Documents from "../../Documents";

const getLineItems = (document) => {
  const lineItems = get(document, "lineItems", [])
    .reduce((lineItemNames, lineItem) => {
      const lineItemName =
        document.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION
          ? lineItem.descriptionOfWork
          : lineItem.name;

      if (lineItemName) {
        return [...lineItemNames, lineItemName];
      }
      return lineItemNames;
    }, [])
    .join(", ");
  return lineItems.length === 0 ? "(No Line Item)" : lineItems;
};

const getDivisions = (document) => {
  const divisions = get(document, "divisionNames", []);
  return isBlank(divisions) ? "(No Division)" : divisions.join(", ");
};

const getErrors = (document) => {
  const errors = get(document, "errors", []).map((error) =>
    t(`documentErrors.${error.message}`, { defaultValue: error.message })
  );
  if (!document.draw) errors.push("This document must be assigned to a draw");

  return uniq(errors).join(", ");
};

const getPaymentStatus = (document) => {
  if (!PAYABLE_DOCUMENT_TYPE_NAMES.includes(document.type)) return null;

  return document.isPaid ? "Paid" : "Not Paid";
};

export function ApprovalTooltipContent({ approvedReviews, pendingReviewers }) {
  return (
    <Pane>
      {approvedReviews.length > 0 && (
        <Paragraph size={300} color="white">
          Approved: {approvedReviews.map(({ userName }) => userName).join(", ")}
        </Paragraph>
      )}
      {pendingReviewers.length > 0 && (
        <Paragraph size={300} color="white">
          Pending:{" "}
          {pendingReviewers.map(({ user }) => user.fullName).join(", ")}
        </Paragraph>
      )}
    </Pane>
  );
}

function getColumns(
  hasOrgLevelPermission,
  hasPermission,
  isDrawDocuments,
  isDocumentCards,
  isReport,
  isSubmission,
  match,
  refetch,
  setIssueItemForModal,
  documentReviewersByProject,
  currentUser,
  orgData
) {
  const showApprovalsColumns =
    hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS) && !isSubmission;
  const useAssignDocuments = hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS);
  const hasTeamManagement = hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT);
  const canPostPayments =
    hasPermission(PERMISSION_ACTION.AVID_AP_INTEGRATION) ||
    hasPermission(PERMISSION_ACTION.NEXUS_INTEGRATION) ||
    hasPermission(PERMISSION_ACTION.YARDI_INTEGRATION);
  const hasPaymentTracking = hasPermission(PERMISSION_ACTION.PAYMENT_TRACKING);
  const hasPullDataFromYardi = hasPermission(
    PERMISSION_ACTION.PULL_DATA_FROM_YARDI
  );
  const orgEnumValues = orgData ? getEnumValuesForOrganization(orgData) : {};

  const isTrackingCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );

  const canBeTiedToAgreements = (document) =>
    values(INVOICE_TYPE).includes(document.type);

  return [
    {
      hidden: !isDocumentCards,
      id: "documentCard",
      // category not needed - never available in Customize Columns
      value: (document) => document,
      valueFormatter: (document) => (
        <DocumentCard document={document} match={match} />
      ),
      cellProps: { borderRight: "none" },
    },
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Document",
      id: "document",
      // category not needed - primary column
      value: (document) => {
        return getCalculatedFileName(document);
      },
      valueFormatter: (value, document) => (
        <Fragment>
          <Shortener limit={40} size={300} text={value} />
          {(get(document, "comments.length", 0) > 0 ||
            document.hasComments) && (
            <CommentIcon size={10} marginLeft={majorScale(1)} />
          )}
        </Fragment>
      ),
      width: 300,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(
          groupDocuments,
          (document) => get(document, "draw.name") || "Project Documents"
        ),
      groupable: true,
      header: "Draw",
      hidden: !!isDrawDocuments,
      id: "draw",
      category: "Project Information",
      value: (document) => get(document, "draw.name") || "Project Documents",
      width: 120,
    },
    {
      ...stringColumnDefaults,
      header: "Project Custom ID",
      hidden: !isReport,
      id: "projectCustomId",
      category: "Project Information",
      value: (document) => get(document, "project.customId"),
    },
    {
      ...enumColumnDefaults,
      enumValues: getDocumentTypes(hasPermission),
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (document) =>
          t(`documentTypeName.${document.type}`)
        ),
      groupable: true,
      header: "Type",
      id: "type",
      category: "File Information",
      value: (document) => t(`documentTypeName.${document.type}`),
      width: 120,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (document) =>
          get(document, "vendor.id") ? get(document, "vendor.name") : null
        ),
      groupable: true,
      header: "Vendor",
      id: "vendor",
      category: "Vendor Information",
      value: (document) =>
        get(document, "vendor.id") ? get(document, "vendor.name") : null,
      width: 300,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, Documents.getNumber),
      groupable: true,
      header: "Document Number",
      id: "documentNumber",
      category: "Document Values",
      value: Documents.getNumber,
      width: 120,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, (document) => document.amount),
      header: "Current Amount Requested (Net)",
      id: "currentAmountRequested",
      category: "Document Values",
      value: (document) => document.amount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, (document) =>
          add(document.grossAmount, document.storedMaterialsAmount)
        ),
      header: "Current Amount Requested (Gross)",
      id: "currentAmountRequested (Gross)",
      category: "Document Values",
      value: (document) =>
        document.grossAmount
          ? add(document.grossAmount, document.storedMaterialsAmount)
          : null,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, (document) => document.adjustedAmount),
      header: "Adjusted Amount",
      id: "adjustedAmount",
      category: "Document Values",
      value: (document) => document.adjustedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, (document) => document.agreementAmount),
      header: "Agreement Amount",
      id: "agreementAmount",
      category: "Document Values",
      value: (document) => document.agreementAmount,
    },
    {
      ...booleanColumnDefaults,
      header: "Fully Tied to Agreements",
      hidden: !isTrackingCostToAgreements,
      id: "amountFullyAllocatedToAgreements",
      category: "Document Values",
      textAlign: "center",
      value: (document) =>
        canBeTiedToAgreements(document)
          ? document.amountFullyAllocatedToAgreements
          : "N/A",
      valueFormatter: (value) => {
        if (value === true) return <TickCircleIcon color="success" />;
        if (value === false) return <WarningSignIcon color="warning" />;

        return value;
      },
      width: 120,
    },
    {
      ...currencyColumnDefaults,
      header: "Amount Not Tied to Agreements",
      hidden: !isTrackingCostToAgreements,
      id: "amountNotAllocatedToAgreements",
      category: "Document Values",
      value: (document) =>
        canBeTiedToAgreements(document)
          ? document.amountNotAllocatedToAgreements
          : null,
      valueFormatter: (value) =>
        value === null ? "N/A" : formatCurrency(value),
    },
    {
      ...dateTimeColumnDefaults,
      aggregate: (groupDocuments) =>
        getDateTimeRangeAggregate(groupDocuments, "insertedAt"),
      header: "Uploaded",
      id: "uploadedAt",
      category: "File Information",
      value: (document) => get(document, "insertedAt"),
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, "uploadedBy"),
      header: "Uploaded By",
      id: "uploadedBy",
      category: "File Information",
      value: (document) => get(document, "uploadedBy"),
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDocuments) => {
        const normalized = groupDocuments.map((document) => ({
          date: document.periodToDate || document?.agreement?.startDate,
          ...document,
        }));
        return getDateRangeAggregate(normalized, "date");
      },
      header: "Document Date",
      id: "date",
      category: "Document Values",
      value: (document) =>
        document.date ||
        document.periodToDate ||
        document?.agreement?.startDate,
    },
    {
      ...stringColumnDefaults,
      header: "Document Description",
      id: "description",
      category: "Document Values",
      value: (document) => document.description,
    },
    {
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, "pages"),
      aggregateFormatter: (value) => {
        if (value === "-") return "-";
        return value.length < 3
          ? value.join(", ")
          : `${value[0]} - ${value.slice(-1)[0]}`;
      },
      header: "Pages",
      id: "pages",
      category: "File Information",
      sortStrategy: (a, b) => get(a, "0", 0) - get(b, "0", 0),
      value: (document) => get(document, "pages"),
      valueFormatter: (value, document) => {
        const url = get(document, "fileUrl");
        const content =
          value.length < 3
            ? value.join(", ")
            : `${value[0]} - ${value.slice(-1)[0]}`;
        return isSubmission ? (
          <Link purpose="documents submission download" href={url}>
            {content}
          </Link>
        ) : (
          content
        );
      },
      width: 65,
    },
    {
      ...stringColumnDefaults,
      aggregate: (allDocuments) => {
        return allDocuments.filter(
          (document) => get(document, "postedAt") !== null
        );
      },
      aggregateFormatter: (value, groupDocuments) => {
        const postedDocumentsCount = value.length;
        const postableDocumentsCount = groupDocuments.filter((document) =>
          isPayableDocument(document)
        ).length;
        return `${postedDocumentsCount}/${postableDocumentsCount}`;
      },
      disableAggregateExport: true,
      filterControl: (index, form) => {
        const options = Object.keys(PAYMENT_OPTIONS).map((keyName) => {
          return {
            key: keyName,
            value: PAYMENT_OPTIONS[keyName],
            label: PAYMENT_OPTIONS[keyName],
          };
        });
        const values = options.map(({ value }) => value);
        const selected = get(form.values, `filters.${index}.enum`, []);
        const checked = difference(values, selected).length === 0;
        const indeterminate = !checked && selected.length > 0;
        return (
          <SelectMenu
            hasFilter={false}
            isMultiSelect
            onDeselect={({ value }) =>
              form.setFieldValue(
                `filters.${index}.enum`,
                xor(selected, [value])
              )
            }
            onSelect={({ value }) =>
              form.setFieldValue(
                `filters.${index}.enum`,
                xor(selected, [value])
              )
            }
            options={options}
            selected={selected}
            titleView={() => (
              <Pane marginLeft={majorScale(1)}>
                <Checkbox
                  checked={checked}
                  indeterminate={indeterminate}
                  label="SELECT ALL"
                  onChange={() =>
                    form.setFieldValue(
                      `filters.${index}.enum`,
                      checked ? [] : values
                    )
                  }
                />
              </Pane>
            )}
          >
            <Pane display="flex">
              <TextInput
                onChange={() => {}}
                value={`${selected.length} Selected`}
              />
              <IconButton icon={CaretDownIcon} type="button" />
            </Pane>
          </SelectMenu>
        );
      },
      filterFormatter: (fc) => fc.enum.join(", "),
      filterStrategy: (value, fc) => {
        const filters = fc.enum;
        const includeIsPosted =
          filters.includes(PAYMENT_OPTIONS.POSTED) &&
          value === PAYMENT_OPTIONS.POSTED;
        const includeNotPosted =
          filters.includes(PAYMENT_OPTIONS.NOT_POSTED) &&
          value === PAYMENT_OPTIONS.NOT_POSTED;
        const includeNotPostable =
          filters.includes(PAYMENT_OPTIONS.NOT_POSTABLE) &&
          value === PAYMENT_OPTIONS.NOT_POSTABLE;
        return includeIsPosted || includeNotPosted || includeNotPostable;
      },
      groupable: true,
      header: "Payment Posted",
      hidden: !canPostPayments,
      id: "postedAt",
      category: "File Information",
      sortStrategy: (docOne, docTwo) => {
        const docOneCanBePosted = docOne !== PAYMENT_OPTIONS.NOT_POSTABLE;
        const docTwoCanBePosted = docTwo !== PAYMENT_OPTIONS.NOT_POSTABLE;
        if (docOneCanBePosted !== docTwoCanBePosted) {
          return docTwoCanBePosted ? 1 : -1;
        }
        return docOne.indexOf(PAYMENT_OPTIONS.NOT_POSTED);
      },
      value: (document) => {
        if (isPayableDocument(document)) {
          const posted = get(document, "postedAt");
          return posted ? PAYMENT_OPTIONS.POSTED : PAYMENT_OPTIONS.NOT_POSTED;
        }
        return PAYMENT_OPTIONS.NOT_POSTABLE;
      },
      valueFormatter: (value, document, options) => {
        if (options.isGroupedValue) return value;
        if (value === PAYMENT_OPTIONS.NOT_POSTABLE) {
          return "";
        }
        if (value === PAYMENT_OPTIONS.POSTED) {
          return "Posted";
        }
        return "Not Posted";
      },
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (document) =>
          get(document, "originalFile.name")
        ),
      groupable: true,
      header: "Original File",
      id: "originalFile",
      category: "File Information",
      value: (document) => get(document, "originalFile.name"),
      valueFormatter: (value, item, { isGroupedValue }) => {
        if (isGroupedValue) return value;
        return <Shortener limit={40} size={300} text={value} />;
      },
      width: 250,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (document) =>
          get(document, "assignedUser.fullName")
        ),
      aggregateFormatter: (user) => {
        if (user === "-") return "-";
        return user && <Shortener limit={40} size={300} text={user} />;
      },
      disableAggregateExport: true,
      groupable: true,
      header: "Assigned To",
      hidden: !useAssignDocuments,
      id: "assignedTo",
      category: "Approvals",
      value: (document) => get(document, "assignedUser.fullName"),
      valueExporter: (user) => user || "",
      valueFormatter: (user, item, { isGroupedValue }) => {
        if (isGroupedValue) return user;
        return user ? <Shortener limit={40} size={300} text={user} /> : null;
      },
      width: 250,
    },
    {
      ...listColumnDefaults,
      id: "documentIssues",
      category: "Approvals",
      groupable: true,
      header: "Issues",
      hidden: !hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL),
      value: getIssuesTableValue,
      valueFormatter: (lineItemIssues, item, config) => {
        function onClick() {
          setIssueItemForModal(item);
        }
        // The Reports page will not have the modal for now,
        // due to performance concerns around getting the data necessary to populate it
        return getIssuesTableValueFormatter(
          lineItemIssues,
          item,
          config,
          isReport ? null : onClick
        );
      },
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupDocuments) => {
        const hasDraw = groupDocuments.every(
          (document) => !!get(document, "draw")
        );

        const state = get(groupDocuments, "0.state");
        const same = groupDocuments.every(
          (document) => get(document, "state") === state
        );
        return hasDraw && same ? state : null;
      },
      aggregateFormatter: (value, groupDocuments) => {
        const draw = get(groupDocuments, "0.draw");
        return value && <DocumentStatusIcon state={value} draw={draw} />;
      },
      groupable: true,
      header: "Status",
      id: "status",
      category: "Approvals",
      sortOrder: [
        DOCUMENT_STATE.FAILED,
        DOCUMENT_STATE.PARSED,
        DOCUMENT_STATE.ADDED,
        DOCUMENT_STATE.APPLIED,
        DOCUMENT_STATE.CORRECTED,
        DOCUMENT_STATE.IGNORED,
      ],
      value: (document) => get(document, "state"),
      valueFormatter: (value, document, { isGroupedValue }) => {
        if (isGroupedValue) return value;
        const draw = get(document, "draw");
        return (
          <DocumentStatusIcon state={value} document={document} draw={draw} />
        );
      },
      textAlign: "center",
      width: 80,
    },
    {
      ...booleanColumnDefaults,
      aggregate: (groupDocuments) =>
        !groupDocuments.some((document) => !get(document, "isBackup")),
      aggregateFormatter: (value) => value && <BoxIcon />,
      header: "Backup",
      id: "backup",
      category: "File Information",
      value: (document) => !!get(document, "isBackup"),
      valueFormatter: (value, item, { isGroupedValue }) => {
        if (isGroupedValue)
          return item.isBackup ? "Backup Document" : "Document";
        return value && <BoxIcon />;
      },
      width: 80,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, getLineItems),
      groupable: true,
      header: "Line Items",
      id: "lineItems",
      category: "Document Values",
      value: getLineItems,
      valueFormatter: (value, _item, { isGroupedValue }) => {
        if (isGroupedValue) return value;
        return <Shortener limit={40} size={300} text={value} />;
      },
      width: 300,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (document) =>
          get(document, "lineItemNumbers", []).join(", ")
        ),
      groupable: true,
      header: "Line Item Numbers",
      id: "lineItemNumbers",
      category: "Document Values",
      value: (document) => get(document, "lineItemNumbers", []).join(", "),
      width: 200,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (document) =>
          get(document, "superLineItems", []).join(", ")
        ),
      groupable: true,
      header: "Summary Line Items",
      id: "summaryLineItems",
      category: "Document Values",
      value: (document) => get(document, "superLineItems", []).join(", "),
      width: 200,
    },
    {
      ...booleanColumnDefaults,
      aggregate: (groupDocuments) =>
        groupDocuments.some(
          (document) =>
            get(document, "comments", []).length > 0 || document.hasComments
        ),
      aggregateFormatter: (value) => value && <CommentIcon />,
      header: "Comments",
      id: "comments",
      category: "Approvals",
      value: (document) =>
        get(document, "comments", []).length > 0 || document.hasComments,
      valueFormatter: (value, item, { isGroupedValue }) => {
        if (isGroupedValue)
          return item.hasComments || get(item, "comments.length", 0) > 0
            ? "With Comments"
            : "Without Comments";
        return value && <CommentIcon />;
      },
      width: 85,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, getErrors),
      header: "Status Issues",
      id: "issues",
      category: "Approvals",
      value: getErrors,
      valueFormatter: (value) => (
        <Shortener limit={40} size={300} text={value} />
      ),
      width: 250,
    },
    {
      ...numberColumnDefaults,
      aggregate: (groupDocuments) => sumBy(groupDocuments, "pages.length"),
      header: "Page Count",
      id: "pageCount",
      category: "File Information",
      value: (document) => get(document, "pages", []).length,
      width: 100,
      textAlign: "center",
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (document) =>
          get(document, "project.name")
        ),
      groupable: true,
      header: "Project",
      id: "project",
      category: "Project Information",
      value: (document) => get(document, "project.name"),
      width: 150,
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.teams,
      header: "Team",
      hidden: !isReport || !hasTeamManagement,
      id: "team",
      category: "Project Information",
      groupable: true,
      value: ({ team }) => team,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, "team"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectTemplates,
      header: "Project Type",
      hidden: !isReport,
      id: "projectType",
      category: "Project Information",
      groupable: true,
      value: ({ projectType }) => projectType,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, "projectType"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.productTypes,
      header: "Product Type",
      hidden: !isReport,
      id: "productType",
      category: "Project Information",
      groupable: true,
      value: ({ productType }) => productType,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, "productType"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectRegions,
      header: "Project Region",
      hidden: !isReport,
      id: "projectRegion",
      category: "Project Information",
      groupable: true,
      value: ({ projectRegion }) => projectRegion,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, "projectRegion"),
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(PROJECT_STATUS_TYPE).map((status) =>
        t(`projectStatus.${status}`)
      ),
      header: "Project Status",
      hidden: !isReport,
      id: "projectStatus",
      category: "Project Information",
      groupable: true,
      value: ({ projectStatus }) => projectStatus,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, "projectStatus"),
      width: 120,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (document) =>
          get(document, "vendor.vendorCostCode")
        ),
      id: "vendorCostCode",
      category: "Vendor Information",
      header: "Vendor ID",
      hidden: !hasPermission(PERMISSION_ACTION.VENDOR_COST_CODES),
      maxWidth: 150,
      value: (document) => get(document, "vendor.vendorCostCode"),
    },
    {
      ...listColumnDefaults,
      header: "Job Cost Codes",
      hidden: !hasPermission(PERMISSION_ACTION.JOB_COST_CODES),
      id: "jobCostCodes",
      category: "Document Values",
      value: (document) => {
        const jobCostCodes = get(document, "jobCostCodes", []);
        const codes = map(jobCostCodes, "code");
        return codes.join(", ");
      },
      valueFormatter: (value) => (
        <Shortener limit={75} size={300} text={value} />
      ),
      width: 120,
    },
    {
      ...fractionColumnDefaults,
      aggregate: (groupDocuments) =>
        groupDocuments
          .filter((document) => isApprovableDocumentType(document))
          .reduce(
            (acc, document) => {
              const documentReviewers =
                documentReviewersByProject[document.projectId] || [];

              const {
                approvedCount,
                reviewersCount,
              } = aggregateDocumentApprovalsData(document, documentReviewers);

              return {
                numerator: acc.numerator + approvedCount,
                denominator: acc.denominator + reviewersCount,
              };
            },
            { numerator: 0, denominator: 0 }
          ),
      aggregateFormatter: ({ numerator, denominator }) =>
        denominator === 0 ? null : `${numerator} / ${denominator}`,
      disableAggregateExport: true,
      header: "Approval Status",
      hidden: !showApprovalsColumns,
      id: "approval",
      category: "Approvals",
      value: (document) => {
        if (!isApprovableDocumentType(document)) return null;
        const documentReviewers =
          documentReviewersByProject[document.projectId] || [];
        const {
          approvedCount,
          reviewersCount,
          approvedReviews,
          pendingReviewers,
        } = aggregateDocumentApprovalsData(document, documentReviewers);
        return {
          numerator: approvedCount,
          denominator: reviewersCount,
          approvedReviews,
          pendingReviewers,
          documentReviewers,
        };
      },
      valueFormatter: (value, document) => {
        if (value === null) return null;
        const {
          numerator,
          denominator,
          approvedReviews,
          pendingReviewers,
          documentReviewers,
        } = value;
        const isUnorderedWorkflow = documentReviewers.length === 0;
        const documentHasNoApprovals = numerator === 0;
        const showApprovalButtonForUnorderedWorkflow =
          isUnorderedWorkflow &&
          documentHasNoApprovals &&
          userCanApproveDocumentAmount(document, currentUser);

        if (showApprovalButtonForUnorderedWorkflow) {
          return (
            <ApproveDocumentButton
              document={document}
              refetchQueries={
                isReport ? [] : [getNotifyAssigneesQueryObject(match)]
              }
            />
          );
        }

        const showTooltip =
          approvedReviews.length > 0 || pendingReviewers.length > 0;
        return showTooltip ? (
          <Tooltip
            content={
              <ApprovalTooltipContent
                approvedReviews={approvedReviews}
                pendingReviewers={pendingReviewers}
              />
            }
          >
            <Text size={300}>{`${numerator} / ${denominator}`}</Text>
          </Tooltip>
        ) : (
          `${numerator} / ${denominator}`
        );
      },
      valueExporter: (value) => {
        if (value === null) return null;
        const { numerator, denominator } = value;
        return `${numerator} / ${denominator}`;
      },
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) => {
        const approveableDocuments = groupDocuments.filter((document) =>
          isApprovableDocumentType(document)
        );
        return getDefaultAggregate(approveableDocuments, (document) => {
          const documentReviewers =
            documentReviewersByProject[document.projectId] || [];
          return getLastApproval(
            document.recentApprovalReviews,
            documentReviewers
          )?.userName;
        });
      },
      header: "Last Approval",
      hidden: !showApprovalsColumns,
      id: "lastApproval",
      category: "Approvals",
      value: (document) => {
        if (!isApprovableDocumentType(document)) return null;
        const documentReviewers =
          documentReviewersByProject[document.projectId] || [];
        return (
          getLastApproval(document.recentApprovalReviews, documentReviewers)
            ?.userName || null
        );
      },
      valueFormatter: (value, document) => {
        if (value === null) return null;
        const documentReviewers =
          documentReviewersByProject[document.projectId] || [];
        const mostRecentApproval = getLastApproval(
          document.recentApprovalReviews,
          documentReviewers
        );
        return (
          <Pane>
            <Paragraph size={300}>{mostRecentApproval?.userName}</Paragraph>
            <Paragraph color="muted" size={300}>
              {formatDateTime(mostRecentApproval?.insertedAt)}
            </Paragraph>
          </Pane>
        );
      },
      valueExporter: (value, document) => {
        if (value === null) return null;
        const documentReviewers =
          documentReviewersByProject[document.projectId] || [];
        const mostRecentApproval = getLastApproval(
          document.recentApprovalReviews,
          documentReviewers
        );
        return mostRecentApproval
          ? `${mostRecentApproval.userName} - ${formatDateTime(
              mostRecentApproval.insertedAt
            )}`
          : null;
      },
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) => {
        const approveableDocuments = groupDocuments.filter((document) =>
          isApprovableDocumentType(document)
        );

        return getDefaultAggregate(approveableDocuments, (document) => {
          const documentReviewers =
            documentReviewersByProject[document.projectId] || [];
          return getNextOrderedReviewer(document, documentReviewers)?.user
            ?.fullName;
        });
      },
      header: "Next Approval",
      hidden: !showApprovalsColumns,
      id: "nextApproval",
      category: "Approvals",
      value: (document) => {
        if (!isApprovableDocumentType(document)) return null;
        const documentReviewers =
          documentReviewersByProject[document.projectId] || [];
        return (
          getNextOrderedReviewer(document, documentReviewers)?.user?.fullName ||
          null
        );
      },
      valueFormatter: (value, document) => {
        if (value === null) return null;
        const documentReviewers =
          documentReviewersByProject[document.projectId] || [];
        const nextReviewer = getNextOrderedReviewer(
          document,
          documentReviewers
        );
        if (nextReviewer.user.id === currentUser.id) {
          return (
            <ApproveDocumentButton
              document={document}
              refetchQueries={
                isReport ? [] : [getNotifyAssigneesQueryObject(match)]
              }
            />
          );
        }
        return value;
      },
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, getDivisions),
      groupable: true,
      header: "Divisions",
      id: "division",
      category: "Project Information",
      value: getDivisions,
      width: 250,
    },
    {
      ...booleanColumnDefaults,
      aggregate: (groupDocuments) =>
        !groupDocuments.some((document) => !get(document, "wasEmailImported")),
      aggregateFormatter: (value) => value && <EnvelopeIcon />,
      header: "Email Import",
      id: "isEmailImport",
      category: "File Information",
      value: (document) => !!get(document, "wasEmailImported"),
      valueFormatter: (value) => {
        return value && <EnvelopeIcon />;
      },
      textAlign: "center",
      width: 80,
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, getPaymentStatus),
      enumValues: ["Paid", "Not Paid"],
      header: "Payment Status",
      id: "paymentStatus",
      hidden: !hasPaymentTracking,
      category: "Document Values",
      value: getPaymentStatus,
      textAlign: "left",
      width: 80,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, (document) => document.amountPaid),
      header: "Amount Paid",
      id: "amountPaid",
      hidden: !hasPaymentTracking,
      category: "Document Values",
      value: (document) => get(document, "amountPaid", null),
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDocuments) =>
        getDateRangeAggregate(groupDocuments, "datePaid"),
      header: "Date Paid",
      hidden: !hasPaymentTracking,
      id: "datePaid",
      category: "Document Values",
      value: (document) => get(document, "datePaid", null),
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Yardi Status",
      hidden: !hasPullDataFromYardi,
      id: "yardiStatus",
      category: "File Information",
      value: (document) => get(document, "importSourceMetadata.yardiStatus"),
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Last Yardi Sync",
      hidden: !hasPullDataFromYardi,
      id: "lastYardiSync",
      category: "File Information",
      value: (document) =>
        get(document, "importSourceMetadata.lastYardiSync", null),
      valueFormatter: (value, document) => {
        if (value === null) return null;
        return formatDateTime(value);
      },
    },
  ];
}

function getControls(
  controlsProps,
  isDocumentCards,
  isReport,
  propsDocumentActions,
  propsEditTableViews,
  rightControls,
  theme,
  documents,
  match,
  hasPermission,
  hasOrgLevelPermission,
  users,
  suggestedDocumentAssignees,
  tableName,
  draws,
  drawId,
  projectId,
  documentReviewersByProject,
  pinnedFilters
) {
  if (isDocumentCards) {
    const preparedItemCount = controlsProps.preparedItems.groups
      ? flatten(Object.values(controlsProps.preparedItems.groups)).length
      : controlsProps.preparedItems.length;

    const totalCount = controlsProps.items.length;

    return (
      <Pane
        background={theme.colors.lightPurple}
        borderBottom
        display="flex"
        justifyContent="space-between"
        padding={majorScale(1)}
      >
        <Text color={theme.colors.gray800} fontWeight={theme.fontWeights.DEMI}>
          {t("documentsViewer.sidebarFilterHeader", {
            selectedCount: preparedItemCount,
            count: totalCount,
          })}
        </Text>

        {preparedItemCount < totalCount && (
          <Link
            purpose="documents-sidebar show-all"
            onClick={() => controlsProps.filterBy([])}
          >
            Show All
          </Link>
        )}
      </Pane>
    );
  }

  if (isReport) {
    return (
      <FastDataTableAdvancedControls
        {...controlsProps}
        {...propsEditTableViews}
        isReport={isReport}
        pinnedFilters={pinnedFilters}
        searchPlaceholder="Search Documents..."
      />
    );
  }

  const documentReviewers = documentReviewersByProject[projectId] || [];

  return (
    <Fragment>
      <BulkDocumentToolbar
        {...propsDocumentActions}
        selectedItems={controlsProps.selectedItems}
        tableName={tableName}
        users={users}
        suggestedDocumentAssignees={suggestedDocumentAssignees}
        onComplete={() => controlsProps.setSelectedItems([])}
        draws={draws}
        drawId={drawId}
        documentReviewers={documentReviewers}
      >
        {(propsBulkDocumentToolbar) => (
          <FastDataTableAdvancedControls
            {...controlsProps}
            {...propsEditTableViews}
            {...propsBulkDocumentToolbar}
            rightControls={(defaultRightControls) => [
              defaultRightControls,
              rightControls,
            ]}
            searchPlaceholder="Search Documents..."
          >
            {hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS) &&
              // do not show the badge if an ordered workflow is configured - we don't make a determination about when a document is "approved" in that case
              documentReviewers.length === 0 && (
                <PendingApprovalsBadge documents={documents} />
              )}
            {hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS) && (
              <NotifyDocumentAssignees match={match} />
            )}
          </FastDataTableAdvancedControls>
        )}
      </BulkDocumentToolbar>
    </Fragment>
  );
}

function getUtilityColumns(
  isDocumentCards,
  isReport,
  isSubmission,
  canUpdateDocument,
  canDeleteDocuments,
  propsDocumentActions
) {
  const { hasDownloadPermission } = propsDocumentActions;
  const cannotViewDocuments = !hasDownloadPermission;

  if (isDocumentCards || isReport || isSubmission || cannotViewDocuments)
    return undefined;

  const rowActions = [
    {
      label: "Download",
      onClick: (docs) => {
        propsDocumentActions.onDownload(docs.map((doc) => doc.id));
      },
    },
    {
      label: "Download Original",
      onClick: (docs) => {
        docs.map(propsDocumentActions.onDownloadOriginal);
      },
    },
  ];
  if (canUpdateDocument) {
    rowActions.push({
      label: "Ignore",
      onClick: propsDocumentActions.onIgnore(() => {}),
    });
  }

  if (canDeleteDocuments) {
    rowActions.push({
      label: "Delete",
      labelProps: { color: "danger" },
      onClick: propsDocumentActions.onRemove(() => {}),
    });
  }

  return [
    bulkSelectUtilityColumnDefaults,
    actionsUtilityColumnDefaults(rowActions),
  ];
}

function getDefaultViews(
  defaultColumns,
  defaultFilters,
  defaultGroup,
  defaultSort,
  defaultViewProps,
  useFilterConfig
) {
  return defaultViewProps.reduce(
    (
      filters,
      { columnConfig, filterConfig, sortConfig, formattedName, name, isDefault }
    ) => {
      filters.push({
        config: toBase64({
          columnConfig: columnConfig || defaultColumns,
          filterConfig: defaultFilters || (useFilterConfig ? filterConfig : []),
          groupConfig: defaultGroup,
          sortConfig: sortConfig || defaultSort || {},
        }),
        name,
        formattedName,
        isDefault,
      });
      return filters;
    },
    []
  );
}

export function DocumentTable({
  deleteRefetch,
  defaultColumns,
  defaultFilters,
  defaultGroup,
  defaultSort,
  documentReviewersByProject,
  documents,
  drawId,
  draws,
  getRowState,
  history,
  match,
  onChange,
  onClickDocumentCard,
  organizationId,
  orgData,
  pinnedFilters,
  projectId,
  rightControls,
  selectedOrganization,
  suggestedDocumentAssignees,
  tableName,
  users,
}) {
  const theme = useContext(ThemeContext);
  const isReport = tableName === "ReportDocumentTable";
  const isSubmission = tableName === "SubmissionDocumentTable";
  const isDocumentCards = tableName === "DocumentCardsListTable";
  const isDrawDocuments = tableName === "DrawDocumentTable";
  const isLineItems = match.url.includes("/line_items/");
  const isVendors = match.url.includes("/organizations/");

  const {
    hasOrgLevelPermission,
    hasPermission,
    user,
    userFullName,
  } = useContext(UserContext);

  const hasDownloadPermission = hasPermission(
    PERMISSION_ACTION.DOWNLOAD_DOCUMENT
  );
  const [classifiedDocuments, unclassifiedDocuments] = useMemo(() => {
    return isReport
      ? [documents, []]
      : partition(documents, (document) => !!document.type);
  }, [documents, isReport]);

  const [issueItemForModal, setIssueItemForModal] = useState(null);

  const columns = useMemo(() => {
    return getColumns(
      hasOrgLevelPermission,
      hasPermission,
      isDrawDocuments,
      isDocumentCards,
      isReport,
      isSubmission,
      match,
      deleteRefetch,
      setIssueItemForModal,
      documentReviewersByProject,
      user,
      orgData
    );
  }, [
    hasOrgLevelPermission,
    hasPermission,
    isDrawDocuments,
    isDocumentCards,
    isReport,
    isSubmission,
    match,
    deleteRefetch,
    setIssueItemForModal,
    documentReviewersByProject,
    user,
    orgData,
  ]);

  const useFilterConfig = !isSubmission && !isLineItems && !isVendors;

  const documentIssues = flatMap(documents, ({ issues }) => issues || []);

  const defaultViewProps = [
    {
      filterConfig: [],
      name: "Default",
      isDefault: true,
    },
    ...(hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL)
      ? [
          {
            filterConfig: getIssuesFilterConfig(
              documentIssues,
              "documentIssues"
            ),
            formattedName: <IssuesViewName issues={documentIssues} />,
            name: "Issues",
            isDefault: true,
          },
        ]
      : []),
    {
      filterConfig: [
        {
          enum: [t(`documentTypeName.${DOCUMENT_TYPE_NAME.INVOICE}`)],
          key: "type",
        },
      ],
      name: "Invoices",
      isDefault: true,
    },
    {
      filterConfig: [
        {
          enum: [t(`documentTypeName.${DOCUMENT_TYPE_NAME.PAY_APPLICATION}`)],
          key: "type",
        },
      ],
      name: "Pay Applications",
      isDefault: true,
    },
    ...(hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS)
      ? [
          {
            filterConfig: [
              {
                enum: [],
                input: userFullName,
                key: "assignedTo",
                operator: COMPARATORS.EXACT.value,
              },
            ],
            name: "Assigned to Me",
            isDefault: true,
          },
        ]
      : []),
    {
      columnConfig: ["document", "type", "uploadedAt", "uploadedBy", "draw"],
      filterConfig: [
        {
          enum: [true],
          key: "isEmailImport",
        },
      ],
      sortConfig: { columnId: "uploadedAt", direction: "desc" },
      name: "Email Import",
      isDefault: true,
    },
  ];

  const defaultViews = getDefaultViews(
    defaultColumns,
    defaultFilters,
    defaultGroup,
    defaultSort,
    defaultViewProps,
    useFilterConfig
  );

  const onSerialize = (serialized) =>
    mergeSearch(
      history,
      isDocumentCards ? { list: serialized } : { table: serialized }
    );

  const onClickRow = hasDownloadPermission
    ? (document) => {
        return onClickDocumentCard
          ? onClickDocumentCard(document)
          : history.push({
              pathname: `${history.location.pathname}/${document.id}`,
              search: history.location.search,
            });
      }
    : () => {};

  const canUpdateDocuments = hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT);
  const canDeleteDocuments = hasPermission(PERMISSION_ACTION.DELETE_DOCUMENTS);

  return (
    <Fragment>
      {unclassifiedDocuments.length > 0 && (
        <Alert intent="none" marginX={majorScale(2)}>
          <Pane display="flex" justifyContent="space-between">
            <Text size={400}>
              {t("documentsPage.documentsProcessing", {
                count: unclassifiedDocuments.length,
              })}
            </Text>
            <Spinner size={majorScale(2)} />
          </Pane>
        </Alert>
      )}
      <EditTableViews
        canManagePublicViews={hasPermission(
          PERMISSION_ACTION.SAVE_TABLE_VIEWS,
          selectedOrganization
        )}
        config={getSearchByKey(history, isDocumentCards ? "list" : "table")}
        organizationIdToScopeViews={organizationId}
        defaultViews={defaultViews}
        tableName={tableName}
      >
        {(propsEditTableViews) => (
          <DocumentActions
            deleteRefetch={deleteRefetch}
            documents={classifiedDocuments}
            drawId={drawId}
            projectId={projectId}
            documentReviewers={documentReviewersByProject[projectId] || []}
            frozenDrawIds={
              draws
                ? draws.filter(({ isFrozen }) => !!isFrozen).map(({ id }) => id)
                : null
            }
          >
            {(propsDocumentActions) => (
              <FastDataTable
                columns={columns}
                controls={(controlsProps) =>
                  getControls(
                    controlsProps,
                    isDocumentCards,
                    isReport,
                    propsDocumentActions,
                    propsEditTableViews,
                    rightControls,
                    theme,
                    documents,
                    match,
                    hasPermission,
                    hasOrgLevelPermission,
                    users,
                    suggestedDocumentAssignees,
                    tableName,
                    draws,
                    drawId,
                    projectId,
                    documentReviewersByProject,
                    pinnedFilters
                  )
                }
                disableCollapse={isDocumentCards}
                footerTotals
                getRowState={(document) => {
                  return (
                    getRowState ||
                    (hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL) &&
                      getIssuesRowState(get(document, "issues", [])))
                  );
                }}
                hideTableHeader={isDocumentCards}
                items={classifiedDocuments}
                onChange={onChange}
                onClickRow={onClickRow}
                onSerialize={onSerialize}
                serialized={
                  getSearchByKey(history, isDocumentCards ? "list" : "table") ||
                  get(propsEditTableViews, "views.0.config")
                }
                utilityColumns={getUtilityColumns(
                  isDocumentCards,
                  isReport,
                  isSubmission,
                  canUpdateDocuments,
                  canDeleteDocuments,
                  propsDocumentActions
                )}
              />
            )}
          </DocumentActions>
        )}
      </EditTableViews>
      {issueItemForModal && (
        <IssuesInfoModal
          issueItem={issueItemForModal}
          scope={GUIDING_EXPLANATION_SCOPES.DOCUMENT}
          onClose={() => setIssueItemForModal(null)}
        />
      )}
    </Fragment>
  );
}

DocumentTable.propTypes = {
  organizationId: PropTypes.string,
  defaultColumns: PropTypes.array.isRequired,
  defaultFilters: PropTypes.array,
  defaultGroup: PropTypes.object.isRequired,
  defaultSort: PropTypes.object,
  deleteRefetch: PropTypes.array,
  documents: PropTypes.array.isRequired,
  drawId: PropTypes.string,
  getRowState: PropTypes.func,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onClickDocumentCard: PropTypes.func,
  onChange: PropTypes.func,
  projectId: PropTypes.string,
  rightControls: PropTypes.arrayOf(PropTypes.element),
  tableName: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  suggestedDocumentAssignees: PropTypes.arrayOf(PropTypes.object),
  documentReviewersByProject: PropTypes.object,
};

DocumentTable.defaultProps = {
  defaultFilters: undefined,
  defaultSort: undefined,
  deleteRefetch: [],
  drawId: undefined,
  getRowState: undefined,
  onChange: undefined,
  onClickDocumentCard: undefined,
  projectId: undefined,
  rightControls: undefined,
  users: [],
  suggestedDocumentAssignees: [],
  documentReviewersByProject: {},
};
