import { useEffect } from "react";
import PropTypes from "prop-types";
import { Accordion } from "components/materials";
import { PROJECT_SETUP_STEP } from "helpers/enums";
import EditProjectSettingsButtons from "./EditProjectSettingsButtons";
import { LineItemSettings } from "./LineItemSettings";

export function EditLineItemTypesPanel({
  completeSetupStep,
  dirty,
  divisions,
  form,
  loading,
  lineItemIds,
  onToggle,
  open,
  panelKey,
  recentDraw,
  setPanelDirty,
  ...props
}) {
  useEffect(() => {
    if (open) {
      completeSetupStep(PROJECT_SETUP_STEP.REVIEW_LINE_ITEM_TYPES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const { isDirty } = form.formState;

  useEffect(() => {
    if (isDirty !== dirty) {
      setPanelDirty(panelKey, isDirty);
    }
    return undefined;
  }, [isDirty, dirty, panelKey, setPanelDirty]);

  return (
    <Accordion.Panel
      panelKey={panelKey}
      title="Line Item Settings"
      onClick={() => onToggle(panelKey)}
      open={open}
      actionContent={
        <EditProjectSettingsButtons
          dirty={isDirty}
          form={form}
          loading={loading}
        />
      }
      {...props}
    >
      <LineItemSettings
        divisions={divisions}
        form={form}
        lineItemIds={lineItemIds}
        recentDraw={recentDraw}
      />
    </Accordion.Panel>
  );
}

EditLineItemTypesPanel.propTypes = {
  contentStyles: PropTypes.object,
  dirty: PropTypes.bool,
  divisions: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  panelKey: PropTypes.string.isRequired,
  panelStyles: PropTypes.object,
  setPanelDirty: PropTypes.func,
  titleStyles: PropTypes.object,
};
