import PropTypes from "prop-types";
import { Table } from "components/materials";
import { FastDataTableCell } from "./FastDataTableCell";
import { FastDataTableUtilityCell } from "./FastDataTableUtilityCell";

export function FastDataTableRow({
  disableRowClick,
  getRowState,
  onClickRow,
  preparedColumns,
  preparedItem,
  preparedItems,
  rowIndex,
  selectedItemIds,
  setSelectedItemIds,
  utilityColumns,
}) {
  return (
    <Table.Row
      onClick={
        onClickRow && !disableRowClick(preparedItem)
          ? () => onClickRow(preparedItem)
          : undefined
      }
      state={getRowState(preparedItem, rowIndex)}
    >
      {utilityColumns.map((utilityColumn) => (
        <FastDataTableUtilityCell
          key={utilityColumn.id}
          preparedItem={preparedItem}
          selectedItemIds={selectedItemIds}
          setSelectedItemIds={setSelectedItemIds}
          utilityColumn={utilityColumn}
        />
      ))}
      {preparedColumns.map((preparedColumn) => (
        <FastDataTableCell
          key={preparedColumn.id}
          preparedColumn={preparedColumn}
          preparedItem={preparedItem}
          preparedItems={preparedItems}
          rowIndex={rowIndex}
        />
      ))}

      <Table.Cell />
    </Table.Row>
  );
}

FastDataTableRow.propTypes = {
  disableRowClick: PropTypes.func,
  getRowState: PropTypes.func,
  onClickRow: PropTypes.func,
  preparedColumns: PropTypes.array,
  preparedItem: PropTypes.object,
  selectedItemIds: PropTypes.array,
  setSelectedItemIds: PropTypes.func,
  utilityColumns: PropTypes.array,
};

FastDataTableRow.defaultProps = {
  disableRowClick: () => false,
};
