import { useState, useContext, useEffect } from "react";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Loadable, Pane, Progress, Text } from "components/materials";
import { get, last } from "lodash";
import { BRAND_COLORS } from "helpers/colors";
import formatPercent from "helpers/formatPercent";
import { majorScale, ThemeContext, toaster } from "helpers/utilities";
import t from "helpers/translate";
import { useFeatureFlags } from "FeatureFlags";
import { BudgetDecisionStep } from "./BudgetDecisionStep";
import { BudgetUploadStep } from "./BudgetUploadStep";
import { ConfirmProjectSetupStep } from "./ConfirmProjectSetupStep";
import { CopyOrBuildBudgetStep } from "./CopyOrBuildBudgetStep";
import { DeveloperHasDrawsStep } from "./DeveloperHasDrawsStep";
import { DeveloperHasFundingSourcesStep } from "./DeveloperHasFundingSourcesStep";
import { DrawDecisionStep } from "./DrawDecisionStep";
import { EnterBudgetStep } from "./EnterBudgetStep";
import { FundingSourcesStep } from "./FundingSourcesStep";
import { LenderHasDrawsStep } from "./LenderHasDrawsStep";
import { PreviousDrawAmountsStep } from "./PreviousDrawAmountsStep";
import { PreviousDrawNamesStep } from "./PreviousDrawNamesStep";
import { ProjectDetailsStep } from "./ProjectDetailsStep";
import { ProjectStatusStep } from "./ProjectStatusStep";
import { ToDateDrawStep } from "./ToDateDrawStep";
import { prepareProject, STEPS, UPDATE_ONBOARDING_DATA } from "./utils";

const DEFAULT_STEP = STEPS.DETAILS;

const DEFAULT_STEP_WIDTH = "70%";

export const ONBOARDING_WIZARD_LAYOUT_QUERY = gql`
  query OnboardingWizardLayoutQuery($projectId: String!) {
    project(id: $projectId) {
      id
      name
      onboardingData
      status
      startDate
      expectedProjectLength
      streetAddress
      city
      state
      zip
      acres
      squareFeet
      productTypeId
      projectRegionId
      drawUpdateSource
      setupComplete
      customFields {
        id
        scopeId
        label
        options
        type
        value
      }
      fundingSourceGroups {
        id
        amount
        label
        type
        organization {
          id
          name
        }
        lineItemIds
      }
      organization {
        id
        userAssignablePermissions
        vendors {
          id
          name
        }
        projectRegions {
          id
          region
        }
        productTypes {
          id
          type
        }
      }
    }
  }
`;

const ADD_VISITED_ONBOARDING_STEP = gql`
  mutation AddVisitedOnboardingStep($projectId: String!, $step: String!) {
    addVisitedOnboardingStep(projectId: $projectId, step: $step) {
      id
      onboardingData
    }
  }
`;

const GO_BACK = gql`
  mutation removeRecentlyVisitedOnboardingStep($projectId: String!) {
    removeRecentlyVisitedOnboardingStep(projectId: $projectId) {
      id
      onboardingData
    }
  }
`;
const FINALIZE_PROJECT = gql`
  mutation FinishProjectOnboardingSetup(
    $automaticAllocationEnabled: Boolean!
    $budget: [OnboardedDivisionInput]!
    $draws: [OnboardedDrawInput]!
    $fundingSourceGroups: [FundingSourceInput]!
    $projectDetails: OnboardedProjectDetailsInput!
    $projectId: String!
    $usesOfFundsEnabled: Boolean!
  ) {
    finishProjectOnboardingSetup(
      automaticAllocationEnabled: $automaticAllocationEnabled
      budget: $budget
      draws: $draws
      fundingSourceGroups: $fundingSourceGroups
      projectDetails: $projectDetails
      projectId: $projectId
      usesOfFundsEnabled: $usesOfFundsEnabled
    ) {
      id
      customId
      status
      draws {
        id
        name
        requestedAmount
      }
      setupSteps {
        id
      }
    }
  }
`;

const STEP_MAPPINGS = {
  [STEPS.BUDGET_DECISION]: BudgetDecisionStep,
  [STEPS.BUDGET_UPLOAD]: BudgetUploadStep,
  [STEPS.DETAILS]: ProjectDetailsStep,
  [STEPS.COPY_OR_BUILD_BUDGET]: CopyOrBuildBudgetStep,
  [STEPS.DEVELOPER_HAS_DRAWS]: DeveloperHasDrawsStep,
  [STEPS.PROJECT_STATUS]: ProjectStatusStep,
  [STEPS.ENTER_BUDGET]: EnterBudgetStep,
  [STEPS.DEVELOPER_HAS_FUNDING_SOURCES]: DeveloperHasFundingSourcesStep,
  [STEPS.FUNDING_SOURCES]: FundingSourcesStep,
  [STEPS.LENDER_HAS_DRAWS]: LenderHasDrawsStep,
  [STEPS.DRAW_DECISION]: DrawDecisionStep,
  [STEPS.PREVIOUS_DRAW_AMOUNTS]: PreviousDrawAmountsStep,
  [STEPS.PREVIOUS_DRAW_NAMES]: PreviousDrawNamesStep,
  [STEPS.TO_DATE_DRAW]: ToDateDrawStep,
  [STEPS.CONFIRM_PROJECT_SETUP]: ConfirmProjectSetupStep,
};

const STEP_PROGRESS_PERCENTAGE = {
  [STEPS.DETAILS]: 0,
  [STEPS.DRAW_SENT_YET]: 0.15,
  [STEPS.DEVELOPER_HAS_DRAWS]: 0.15,
  [STEPS.PROJECT_STATUS]: 0.2,
  [STEPS.BUDGET_DECISION]: 0.25,
  [STEPS.COPY_OR_BUILD_BUDGET]: 0.3,
  [STEPS.BUDGET_UPLOAD]: 0.35,
  [STEPS.ENTER_BUDGET]: 0.4,
  [STEPS.DEVELOPER_HAS_FUNDING_SOURCES]: 0.65,
  [STEPS.FUNDING_SOURCES]: 0.7,
  [STEPS.LENDER_HAS_DRAWS]: 0.75,
  [STEPS.DRAW_DECISION]: 0.8,
  [STEPS.PREVIOUS_DRAW_NAMES]: 0.85,
  [STEPS.PREVIOUS_DRAW_AMOUNTS]: 0.9,
  [STEPS.TO_DATE_DRAW]: 0.9,
  [STEPS.CONFIRM_PROJECT_SETUP]: 1,
};

function getStepWidth(currentStep) {
  if (currentStep === STEPS.ENTER_BUDGET) return "80%";
  if (currentStep === STEPS.FUNDING_SOURCES) return "90%";
  return DEFAULT_STEP_WIDTH;
}

function LayoutHeader({ history, percentComplete, theme }) {
  return (
    <Pane
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      elevation={1}
      paddingX={majorScale(3)}
      paddingY={majorScale(1)}
    >
      <Pane display="flex" flex={1}>
        <Text fontWeight={theme.fontWeights.MEDIUM} fontSize={16}>
          Setup New Project
        </Text>
        <Pane
          alignItems="center"
          display="flex"
          marginLeft={majorScale(2)}
          width="40%"
        >
          <Progress
            background="#cadefa"
            color={BRAND_COLORS.LIGHT_PRIMARY}
            percent={percentComplete * 100}
          />
          <Text marginLeft={majorScale(1)}>
            {formatPercent(percentComplete)}
          </Text>
        </Pane>
      </Pane>
      <Button
        appearance="default"
        content="Cancel"
        onClick={() => history.push("/")}
      />
    </Pane>
  );
}

function CurrentStep({ project, currentStep, setCurrentStep, theme, width }) {
  const history = useHistory();
  const featureFlags = useFeatureFlags();
  const useNewDashboard = featureFlags.isEnabled(
    "use-redesigned-project-dashboard"
  );
  const [addVisitedStep, addVisitedResult] = useMutation(
    ADD_VISITED_ONBOARDING_STEP
  );
  const [update, updateResult] = useMutation(UPDATE_ONBOARDING_DATA, {
    refetchQueries: [
      {
        query: ONBOARDING_WIZARD_LAYOUT_QUERY,
        variables: { projectId: project.id },
      },
    ],
  });
  const [finalizeProject, finalizeProjectResult] = useMutation(
    FINALIZE_PROJECT,
    {
      onCompleted: (result) => {
        const setupSteps = get(
          result,
          "finishProjectOnboardingSetup.setupSteps",
          []
        );
        !useNewDashboard &&
          toaster.success(t("projectSetupStepsToast.createProject"), {
            description: t("projectSetupStepsToast.summary", {
              complete: 1,
              total: setupSteps.length,
            }),
            duration: 2.5,
          });
        const projectPath = `/projects/${project.id}`;
        const hasDraws = get(project, "onboardingData.hasDraws") === true;
        history.push(
          hasDraws ? `${projectPath}?isNewProject=true` : projectPath
        );
      },
    }
  );
  const [goBack] = useMutation(GO_BACK);

  if (finalizeProjectResult.loading) {
    return <Loadable loading />;
  }

  const Step = STEP_MAPPINGS[currentStep];
  return (
    <Step
      theme={theme}
      project={project}
      navigateToStep={(step) => {
        addVisitedStep({
          variables: { projectId: project.id, step },
        });
        setCurrentStep(step);
      }}
      goBack={() => {
        const steps = get(project, "onboardingData.visitedOnboardingSteps", []);
        // Fetch the 2nd to last step
        const previousStep = steps[steps.length - 2] || DEFAULT_STEP;
        goBack({
          variables: { projectId: project.id },
        });
        setCurrentStep(previousStep);
      }}
      loading={
        addVisitedResult.loading ||
        updateResult.loading ||
        finalizeProjectResult.loading
      }
      updateOnboardingData={(data) =>
        update({
          variables: {
            projectId: project.id,
            data: JSON.stringify(data),
          },
        })
      }
      handleSubmit={(pendingUpdates) => {
        if (pendingUpdates && Object.keys(pendingUpdates).length > 0) {
          update({
            variables: {
              projectId: project.id,
              data: JSON.stringify(pendingUpdates),
            },
          }).then((result) => {
            const onboardingData = get(
              result,
              "data.updateOnboardingData.onboardingData"
            );
            finalizeProject({
              variables: {
                projectId: project.id,
                ...prepareProject(onboardingData),
              },
            });
          });
        } else {
          finalizeProject({
            variables: {
              projectId: project.id,
              ...prepareProject(project.onboardingData),
            },
          });
        }
      }}
      width={width}
    />
  );
}

export function OnboardingWizardLayout({ history, match }) {
  const { projectId } = match.params;
  const [currentStep, setCurrentStep] = useState(null);
  const theme = useContext(ThemeContext);
  const { data, loading } = useQuery(ONBOARDING_WIZARD_LAYOUT_QUERY, {
    variables: { projectId },
    onCompleted: (result) => {
      const lastStep =
        last(get(result, "project.onboardingData.visitedOnboardingSteps")) ||
        DEFAULT_STEP;
      setCurrentStep(lastStep);
    },
  });

  const project = get(data, "project");
  // There is a *slight* delay between when the query finishes loading before the project and currentStep get populated.
  // Keep showing the loading icon until both are present.
  const showLoading = loading || !project || !currentStep;

  useEffect(() => {
    // If this route is accessed after project setup is complete, reroute to the main project page
    if (!showLoading && project.setupComplete)
      history.push(`/projects/${project.id}`);
  }, [showLoading, project, history]);

  return (
    <Pane>
      <LayoutHeader
        history={history}
        percentComplete={STEP_PROGRESS_PERCENTAGE[currentStep]}
        theme={theme}
      />
      <Pane>
        {showLoading ? (
          <Loadable loading />
        ) : (
          <Pane
            display="flex"
            justifyContent="center"
            marginTop={majorScale(7)}
          >
            <Pane width={getStepWidth(currentStep)}>
              <CurrentStep
                project={project}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                theme={theme}
                width={getStepWidth(currentStep)}
              />
            </Pane>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}
