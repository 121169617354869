import { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import { majorScale } from "helpers/utilities";
import { Button, Pane, RedesignLayout } from "components/materials";
import { css } from "glamor";

const verticalStyles = {
  height: "100%",
  width: "50%",
};

const FileView = ({
  blankSlate,
  children,
  fileViewer,
  header,
  nextDisabled,
  nextHighlighted,
  onClose,
  onNext,
  onPrevious,
  previousDisabled,
  setViewerOpen,
  sidebar,
  tabs,
  viewerOpen,
}) => {
  const [cardsOpen, setCardsOpen] = useState(true);

  useLayoutEffect(() => {
    setViewerOpen(true);
  }, [setViewerOpen]);

  const sidebarClassName = css({
    transition: "margin-left .35s",
    marginLeft: cardsOpen ? 0 : -200,
  });

  const viewerClassName = css({
    overflow: "hidden",
    transition: viewerOpen
      ? "transform .35s ease-out"
      : "transform .35s ease-in",
    transform: viewerOpen ? "translate(0, 0%)" : "translate(0, 100%)",
  });

  const renderHeaderControls = () => (
    <Pane display="flex" alignItems="center" paddingRight={majorScale(2)}>
      <Button
        disabled={previousDisabled}
        onClick={onPrevious}
        marginLeft={majorScale(1)}
        purpose="file-view previous"
      >
        Previous
      </Button>
      <Button
        appearance={!nextDisabled && nextHighlighted ? "primary" : "default"}
        disabled={nextDisabled}
        onClick={onNext}
        marginLeft={majorScale(1)}
        purpose="file-view next"
      >
        Next
      </Button>
      <Button
        marginLeft={majorScale(1)}
        onClick={onClose}
        purpose="file-view close"
      >
        Close
      </Button>
    </Pane>
  );

  return (
    <RedesignLayout.Panel className={viewerClassName} paddingTop={56}>
      <Pane
        alignItems="center"
        background="white"
        borderBottom
        boxShadow="0px 0px 5px 0px rgba(149, 161, 174, 0.39)"
        display="flex"
        height={56}
        position="absolute"
        top={0}
        width="100%"
        zIndex={5}
      >
        <Pane
          alignItems="center"
          display="flex"
          flexGrow={1}
          marginLeft={majorScale(3)}
        >
          {header}
        </Pane>
        {renderHeaderControls()}
      </Pane>
      {blankSlate || (
        <Pane height="100%" display="flex">
          <Pane
            className={sidebarClassName}
            height="100%"
            minWidth={200}
            overflowY="auto"
            width={200}
          >
            {sidebar}
          </Pane>

          <Pane display="flex" flexGrow={1} flexDirection="row" height="100%">
            <Pane
              {...verticalStyles}
              overflowY="auto"
              boxShadow="0px 0px 5px 0px rgba(149, 161, 174, 0.39)"
              zIndex={6}
            >
              {children(cardsOpen, setCardsOpen)}
            </Pane>
            <Pane {...verticalStyles}>
              {tabs}
              {fileViewer}
            </Pane>
          </Pane>
        </Pane>
      )}
    </RedesignLayout.Panel>
  );
};

FileView.propTypes = {
  children: PropTypes.func.isRequired,
  fileViewer: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  sidebar: PropTypes.node.isRequired,
  nextHighlighted: PropTypes.bool,
};

export default FileView;
