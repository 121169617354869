import { SelectMenu } from "components/materials";

export function BulkMoveToDrawDropdown({
  children,
  disabled,
  onSelect,
  draws,
}) {
  return (
    <SelectMenu
      closeOnSelect
      disabled={disabled}
      hasFilter={false}
      hasTitle={false}
      options={draws.map((draw) => ({
        label: draw.name,
        value: draw.id,
      }))}
      onSelect={({ value: drawId }) => {
        onSelect(drawId);
      }}
      maxWidth={200}
    >
      {children}
    </SelectMenu>
  );
}
