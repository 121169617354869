import { intersection, range } from "lodash";
import isBlank from "helpers/isBlank";
import {
  add,
  subtract,
  sumBy,
  multiplyCurrency,
  divideCurrency,
} from "helpers/math";
import { PROJECTION_CURVE_TYPE } from "../components/templates/ProjectionTable/util";

export const amountFieldNames = {
  ACTUALS: "actuals",
  MONTHLY_PROJECTED_AMOUNTS: "monthlyProjectedAmounts",
  UPDATED_PROJECTION_AMOUNTS: "updatedProjectionAmounts",
};

export const blankSelectValue = "none";

export function selectIsBlank(value) {
  return value === blankSelectValue;
}

export function getStartMonthIndex(value) {
  return subtract(value, 1);
}

export function getEndMonthIndex(startMonthIndex, duration) {
  return add(startMonthIndex, duration - 1);
}

export function getHasDurationAndStartMonth(duration, startMonthValue) {
  return (
    duration !== null &&
    startMonthValue !== null &&
    !selectIsBlank(duration) &&
    !selectIsBlank(startMonthValue)
  );
}

export function getStartMonthOutsideOfDurationBounds(
  startMonthIndex,
  duration,
  expectedProjectLength
) {
  return add(startMonthIndex, duration) > expectedProjectLength;
}

export function getHasProjection(project) {
  return !!project.projection;
}

export function isInsufficientFunds(fundingSource) {
  return fundingSource.id === "insufficientFunds";
}

export function isValidInsufficientFunds(insufficientFunds) {
  return insufficientFunds.loaded && insufficientFunds.amount !== 0;
}

export function getMonthHasActuals(monthIndex, numberOfMonthsWithActuals) {
  return monthIndex <= numberOfMonthsWithActuals - 1;
}

export function getHasValidProjection(divisions) {
  return divisions.some(({ lineItems }) =>
    lineItems.some(({ curveType, duration, startMonth }) => {
      const isManual = curveType === "MANUAL";
      const projectionFilledOutCompletely =
        !!curveType &&
        !!duration &&
        !!startMonth &&
        !selectIsBlank(curveType) &&
        !selectIsBlank(duration) &&
        !selectIsBlank(startMonth);

      return projectionFilledOutCompletely || isManual;
    })
  );
}

export function getHasCompleteProjection(divisions) {
  return divisions.every(({ lineItems }) =>
    lineItems.every(
      ({ curveType, duration, startMonth }) =>
        !!curveType &&
        !!duration &&
        !!startMonth &&
        !selectIsBlank(curveType) &&
        !selectIsBlank(duration) &&
        !selectIsBlank(startMonth)
    )
  );
}

export function getCalculatedValues({
  hasDurationAndStartMonth,
  lineItem,
  startMonthOutsideOfDurationBounds,
}) {
  const { curveType } = lineItem;
  if (
    isLinearCalculation(
      curveType,
      hasDurationAndStartMonth,
      startMonthOutsideOfDurationBounds
    )
  ) {
    return calculateLinearValues(lineItem);
  }

  if (
    isSCurveCalculation(
      curveType,
      hasDurationAndStartMonth,
      startMonthOutsideOfDurationBounds
    )
  ) {
    return calculateSCurveValue(lineItem);
  }

  if (curveType === PROJECTION_CURVE_TYPE.MANUAL) {
    return calculateManualValue(lineItem);
  }

  return [];
}

export function getTotalBudgetAmount(divisions) {
  return divisions.reduce(
    (total, { divisionBudgetAmount: amt }) => add(total, amt),
    0
  );
}

export function getTotalPreviousBudgetAmount(divisions) {
  return divisions.reduce(
    (total, { divisionPreviousBudgetAmount: amt }) => add(total, amt),
    0
  );
}

export function getTotalFundingSourcesAmount(fundingSources) {
  return fundingSources.reduce((total, { amount }) => add(total, amount), 0);
}

export function getTotalPreviousFundingSourcesAmount(fundingSources) {
  return fundingSources.reduce(
    (total, { previousAmount }) => add(total, previousAmount),
    0
  );
}

export function getProjectionTotals(divisions, months) {
  const emptyAccumulator = months.map((_month) => ({
    projected: 0,
    updatedProjection: 0,
  }));

  const subtotals = divisions.map(({ divisionSubtotals }) => divisionSubtotals);

  return subtotals.reduce(
    (acc, divisionSubtotals) =>
      acc.map((totals, monthIndex) => ({
        projected: add(
          totals.projected,
          divisionSubtotals[monthIndex].projected
        ),
        updatedProjection: add(
          totals.updatedProjection,
          divisionSubtotals[monthIndex].updatedProjection
        ),
      })),
    emptyAccumulator
  );
}

function isLinearCalculation(
  curveType,
  hasDurationAndStartMonth,
  startMonthOutsideOfDurationBounds
) {
  return (
    curveType === PROJECTION_CURVE_TYPE.LINEAR &&
    hasDurationAndStartMonth &&
    !startMonthOutsideOfDurationBounds
  );
}

function isSCurveCalculation(
  curveType,
  hasDurationAndStartMonth,
  startMonthOutsideOfDurationBounds
) {
  return (
    curveType === PROJECTION_CURVE_TYPE.S_CURVE &&
    hasDurationAndStartMonth &&
    !startMonthOutsideOfDurationBounds
  );
}

function calculateLinearMoneyToDisperse(lineItem) {
  const { duration, startMonth } = lineItem;

  const lineItemActuals = lineItem.values
    .filter(({ actual }) => actual !== null)
    .map(({ actual }) => actual);

  const lineItemActualsCount = lineItemActuals.length;

  const lineItemActualsSum = sumBy(lineItemActuals);

  const totalRemainingBudgetAmount = subtract(
    lineItem.budgetAmount,
    lineItemActualsSum
  );

  const startMonthIndex = getStartMonthIndex(startMonth);
  const endMonthIndex = getEndMonthIndex(startMonthIndex, duration);

  const numberOfMonthsActualsOverlap = intersection(
    range(0, lineItemActualsCount),
    range(startMonthIndex, add(endMonthIndex, 1))
  ).length;

  const weightedDuration = subtract(duration, numberOfMonthsActualsOverlap);

  return lineItem.values.reduce((acc, value, valueIndex) => {
    if (!isBlank(value.actual)) {
      return [...acc, value.actual];
    }

    const totalSpendToDate = sumBy(acc);
    const remainingBudgetForPeriod = subtract(
      lineItem.budgetAmount,
      totalSpendToDate
    );
    const isActiveMonth =
      valueIndex >= startMonthIndex && valueIndex <= endMonthIndex;

    const isLastMonth = valueIndex === endMonthIndex;

    const lineItemDurationIsOver = valueIndex > endMonthIndex;

    if (!isActiveMonth) {
      // If it is not an active month and the line item "duration" is over
      // We need to check if there is a remaining budget to rollover to the next month
      if (lineItemDurationIsOver && totalSpendToDate < lineItem.budgetAmount) {
        return [...acc, remainingBudgetForPeriod];
      }
      return [...acc, 0];
    }

    if (isLastMonth && remainingBudgetForPeriod >= 0) {
      return [...acc, remainingBudgetForPeriod];
    }

    const total = divideCurrency(totalRemainingBudgetAmount, weightedDuration);

    // If the actuals is higher than the budget the linear formula will set the remaining months to a negative value
    // Here we just check if the value is negative and if it is set it to 0
    const finalAmount = total < 0 ? 0 : total;

    return [...acc, finalAmount];
  }, []);
}

export function calculateLinearValues(lineItem) {
  return calculateLinearMoneyToDisperse(lineItem);
}

export function calculateSCurveValue(lineItem) {
  const duration = parseInt(lineItem.duration, 10);
  const steepness = calculateSteepness(duration);
  const { startMonth } = lineItem;
  const startMonthIndex = getStartMonthIndex(startMonth);
  const endMonthIndex = getEndMonthIndex(startMonthIndex, duration);

  // stretch the formula to make up for the delta calculations later on.
  // If we don't stretch it the first line item value will be greater than its second line item value.
  const adjustedDuration = duration + 1;

  const durationAvg = () => {
    const sum = Array.from({ length: adjustedDuration }).reduce(
      (acc, _, index) => acc + (index + 1),
      0
    );
    return sum / adjustedDuration;
  };

  // 1/(1+exp(-k*(x-x0))
  // k is the steepness of the curve
  // x is the current period
  /// x0 is the avg of all the periods
  const scurvePercentage = (period) => {
    const exp = Math.exp(steepness * (period - durationAvg()));
    return 1 / (1 + exp);
  };

  const monthlyCumulativePercentage = () => {
    return Array.from({ length: adjustedDuration }).map((_, index) =>
      scurvePercentage(index)
    );
  };

  // Delta is the current month - previous month
  const monthlyDeltas = () => {
    const monthlyValues = monthlyCumulativePercentage();

    const deltas = monthlyValues.map((_value, index) => {
      // we set the first monthly delta to 0 to account for the duration stretching
      if (index === 0) return 0;

      return monthlyValues[index] - monthlyValues[index - 1];
    });

    return deltas;
  };

  const generateFullDelta = (deltas) => {
    const projectLength = lineItem.values.length;

    const { fullDeltas } = Array.from({ length: projectLength }).reduce(
      (acc, _, index) => {
        const isActiveMonth =
          index >= startMonthIndex && index <= endMonthIndex;

        if (isActiveMonth) {
          return {
            fullDeltas: [...acc.fullDeltas, deltas[acc.deltaIndex]],
            deltaIndex: acc.deltaIndex + 1,
          };
        }

        return { ...acc, fullDeltas: [...acc.fullDeltas, 0] };
      },
      { fullDeltas: [], deltaIndex: 1 }
    );

    return fullDeltas;
  };

  const adjustedMonthlyValueFromDelta = () => {
    const deltas = monthlyDeltas();

    const lineItemActualsCount = lineItem.values.filter(
      (v) => v.actual !== null
    ).length;

    const fullDeltas = generateFullDelta(deltas);

    const deltaToBeSpliced = lineItemActualsCount;

    // we must remove deltas that are actuals so when we sum them up we are only adding the left over deltas
    const remainingDeltas =
      lineItemActualsCount > 0
        ? [...fullDeltas].splice(deltaToBeSpliced)
        : [...fullDeltas];

    const totalDelta = sumBy(remainingDeltas);

    return deltas.map((delta) => {
      return delta / totalDelta;
    });
  };

  const calculatedValues = () => {
    const adjustedMonthlyValues = adjustedMonthlyValueFromDelta();

    // We splice the final adjusted values to remove the first value which
    // is 0 since we set the first delta to 0.
    const spliced = adjustedMonthlyValues.splice(1);

    return lineItem.values.reduce((acc, _value, index) => {
      if (!isBlank(lineItem.values[index].actual)) {
        return [...acc, lineItem.values[index].actual];
      }

      const totalSpendToDate = sumBy(acc);
      const remainingBudgetForPeriod = subtract(
        lineItem.budgetAmount,
        totalSpendToDate
      );
      const isActiveMonth = index >= startMonthIndex && index <= endMonthIndex;
      const isLastMonth = index === endMonthIndex;
      const lineItemDurationIsOver = index > endMonthIndex;

      if (!isActiveMonth) {
        // If it is not an active month and the line item "duration" is over
        // We need to check if there is a remaining budget to rollover to the next month
        if (
          lineItemDurationIsOver &&
          totalSpendToDate < lineItem.budgetAmount
        ) {
          return [...acc, remainingBudgetForPeriod];
        }
        return [...acc, 0];
      }

      if (isLastMonth && remainingBudgetForPeriod >= 0) {
        return [...acc, remainingBudgetForPeriod];
      }

      const lineItemActualsSum = sumBy(lineItem.values, "actual");
      const totalRemainingBudgetAmount = subtract(
        lineItem.budgetAmount,
        lineItemActualsSum
      );

      const adjustedMonthlyValueIndex = index - startMonthIndex;

      const total = multiplyCurrency(
        spliced[adjustedMonthlyValueIndex],
        totalRemainingBudgetAmount
      );

      // If the actuals is higher than the budget the scurve formula will set the remaining months to a negative value
      // Here we just check if the value is negative and if it is set it to 0
      const finalAmount = total < 0 ? 0 : total;

      return [...acc, finalAmount];
    }, []);
  };

  return calculatedValues();
}

function calculateSteepness(duration) {
  switch (true) {
    case duration <= 20:
      return -0.6;
    case duration > 20 && duration <= 35:
      return -0.4;
    case duration > 35 && duration <= 50:
      return -0.3;
    case duration > 50 && duration <= 60:
      return -0.2;
    case duration > 60:
      return -0.1;
    default:
      return -0.6;
  }
}

function calculateManualValue(lineItem) {
  return lineItem.values.map((_value, index) => {
    if (!isBlank(lineItem.values[index].actual)) {
      return lineItem.values[index].actual;
    }

    return 0;
  });
}
