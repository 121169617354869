import { Fragment } from "react";
import { Formik, FieldArray } from "formik";
import { Button, Form, Pane, Paragraph, Text } from "components/materials";
import t from "helpers/translate";
import { get, set } from "lodash";
import { majorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import { formatDate } from "helpers/dateHelpers";
import { css } from "glamor";
import { Header } from "./Header";
import { PersistStep } from "./PersistStep";
import { STEPS } from "./utils";

const initialValues = (project) => {
  const draws = get(project, "onboardingData.draws", []);
  return { draws };
};

const validate = ({ draws }) => {
  const errors = {};
  draws.forEach((draw, index) => {
    if (isBlank(draw.targetAmount)) {
      set(errors, `draws.${index}.targetAmount`, "Please enter a draw amount");
    }
  });
  return errors;
};

export function PreviousDrawAmountsStep({
  goBack,
  loading,
  navigateToStep,
  project,
  updateOnboardingData,
  theme,
}) {
  const values = initialValues(project);

  const tableClassName = css({
    borderCollapse: "separate",
    borderSpacing: `0 ${majorScale(1)}px`,
    width: 425,
  });

  return (
    <Formik
      initialValues={values}
      onSubmit={(draws) =>
        updateOnboardingData(draws).then(() =>
          navigateToStep(STEPS.CONFIRM_PROJECT_SETUP)
        )
      }
      validate={validate}
    >
      {(formikProps) => (
        <Fragment>
          <Header
            header={t("onboardingWizard.previousDrawAmounts.header")}
            subheader={t("onboardingWizard.previousDrawAmounts.subheader")}
          />
          <FieldArray name="draws">
            {() => (
              <Pane>
                <table className={tableClassName}>
                  <thead>
                    <tr>
                      <th aria-label="Draw name" />
                      <th>
                        <Paragraph
                          textAlign="right"
                          fontWeight={theme.fontWeights.MEDIUM}
                        >
                          Draw total amount (net)
                        </Paragraph>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formikProps.values.draws.map((draw, index) => (
                      <tr key={index}>
                        <td>
                          <Text
                            fontWeight={theme.fontWeights.MEDIUM}
                            marginRight={majorScale(1)}
                            size={300}
                          >
                            {draw.name}
                          </Text>
                          <Text paddingRight={majorScale(2)} size={300}>
                            {formatDate(draw.date)}
                          </Text>
                        </td>
                        <td aria-label="progress">
                          <Form.Input
                            name={`draws.${index}.targetAmount`}
                            type="currency"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Pane>
            )}
          </FieldArray>
          <Pane marginTop={majorScale(3)}>
            <Button
              onClick={() => {
                goBack();
              }}
            >
              Back
            </Button>
            <Button
              appearance="primary"
              isLoading={loading}
              onClick={formikProps.handleSubmit}
              purpose="onboarding-wizard finalize historical draws"
              marginLeft={majorScale(2)}
            >
              Next
            </Button>
          </Pane>
          <PersistStep
            mutationData={formikProps.values}
            projectId={project.id}
          />
        </Fragment>
      )}
    </Formik>
  );
}
