import { Fragment } from "react";
import { NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE } from "hooks/use-projections";
import { Table } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import {
  amountFieldNames,
  getMonthHasActuals,
  getTotalBudgetAmount,
  getTotalPreviousBudgetAmount,
} from "helpers/projectionHelpers";
import { getCellState, tableBorder } from "./util";

export function ProjectionTableTotal({
  actualsCount,
  columns,
  divisions,
  project,
  projectionState,
  projectionTotals,
}) {
  const { months } = project;
  const totalBudgetAmount = formatCurrency(getTotalBudgetAmount(divisions));
  const totalPreviousBudgetAmount = formatCurrency(
    getTotalPreviousBudgetAmount(divisions)
  );
  return (
    <Table.Row>
      <Table.TextFooterCell textAlign="right">Total</Table.TextFooterCell>
      {columns.showPreviousBudget && (
        <Table.TextFooterCell colSpan={4} textAlign="right">
          {totalPreviousBudgetAmount}
        </Table.TextFooterCell>
      )}
      <Table.TextFooterCell
        colSpan={columns.showPreviousBudget ? 0 : 4}
        textAlign="right"
      >
        {totalBudgetAmount}
      </Table.TextFooterCell>
      {months.map((_, monthIndex) => {
        const monthHasActuals = getMonthHasActuals(monthIndex, actualsCount);
        const totals = projectionTotals[monthIndex];

        switch (projectionState) {
          case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
            return (
              <Fragment>
                {columns.showOriginalProjection && (
                  <Table.TextFooterCell
                    borderLeft={tableBorder}
                    textAlign="right"
                    state={getCellState(
                      amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                    )}
                  >
                    {formatCurrency(totals.projected)}
                  </Table.TextFooterCell>
                )}
                {columns.showUpdatedProjection && (
                  <Table.TextFooterCell
                    borderLeft={tableBorder}
                    textAlign="right"
                    state={getCellState(
                      monthHasActuals
                        ? amountFieldNames.ACTUALS
                        : amountFieldNames.UPDATED_PROJECTION_AMOUNTS
                    )}
                  >
                    {formatCurrency(totals.updatedProjection)}
                  </Table.TextFooterCell>
                )}
              </Fragment>
            );
          default:
            return (
              <Table.TextFooterCell
                borderLeft={tableBorder}
                textAlign="right"
                state={getCellState(
                  monthHasActuals
                    ? amountFieldNames.ACTUALS
                    : amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                )}
              >
                {formatCurrency(totals.updatedProjection)}
              </Table.TextFooterCell>
            );
        }
      })}
      <Table.TextFooterCell borderLeft={tableBorder} />
    </Table.Row>
  );
}
