import { useRef, useContext } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { Route, useRouteMatch } from "react-router-dom";
import { AppLayout } from "components/templates";
import { get, isEqual, some } from "lodash";
import { validate as validateUUID } from "uuid";
import { ThemeContext } from "helpers/utilities";
import { mergeIntoTheme } from "helpers/themeHelpers";
import { getRecentProjectIds } from "helpers/localStorage";
import { useAuth } from "../../Auth";

export const QUERY = gql`
  query AppLayoutPageQuery {
    user {
      id
      email
      permissionConfig
      pendingInvitations {
        id
        organization {
          id
          name
        }
      }
    }
    organizations {
      id
      headerColor
      name
      primaryColor
      primaryLogo {
        url
      }
      permissionConfig
    }
  }
`;

export const RECENT_PROJECTS_QUERY = gql`
  query RecentProjectsQuery($filters: [FilterInput]) {
    projects(filters: $filters) {
      id
      amount
      customId
      inProgressAmount
      fundingSourceGroups {
        id
        amount
        disbursedAmount
      }
      name
      organization {
        id
      }
      template {
        id
        icon
      }
      draws {
        id
        name
        requestedAmount
      }
    }
  }
`;

const GUEST_SUBMISSION_QUERY = gql`
  query GuestSubmissionQuery($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      submitter {
        id
        headerColor
        primaryColor
        primaryLogo {
          url
        }
      }
    }
  }
`;

const REGISTER_QUERY = gql`
  query NewUserQuery($invitationToken: String!) {
    user(invitationToken: $invitationToken) {
      id
      organization {
        id
        headerColor
        primaryColor
        primaryLogo {
          url
        }
      }
    }
  }
`;

const REPORT_SUBMISSION_QUERY = gql`
  query ReportSubmissionQuery($reportSubmissionId: String!) {
    reportSubmission(reportSubmissionId: $reportSubmissionId) {
      id
      sourceDraw {
        id
        submitter {
          id
          headerColor
          primaryColor
          primaryLogo {
            url
          }
        }
      }
    }
  }
`;

const GUEST_UPLOAD_QUERY = gql`
  query GuestUploadQuery($accessTokenId: String!) {
    guestProject(accessTokenId: $accessTokenId) {
      id
      owner {
        id
        headerColor
        primaryColor
        primaryLogo {
          url
        }
      }
    }
  }
`;

function selectGuestQuery({ submission, guestUpload, register }) {
  if (submission) return GUEST_SUBMISSION_QUERY;
  if (guestUpload) return GUEST_UPLOAD_QUERY;
  if (register) return REGISTER_QUERY;
  return REPORT_SUBMISSION_QUERY;
}

function getGuestParams({
  submission,
  guestUpload,
  register,
  reportSubmission,
}) {
  if (submission) return submission.params;
  if (guestUpload) return guestUpload.params;
  if (register) return register.params;
  if (reportSubmission) return reportSubmission.params;
  return {};
}

function parseGuestQuery(
  data,
  { guestUpload, submission, register, reportSubmission }
) {
  if (guestUpload) return get(data, "guestProject.owner", {});
  if (submission) return get(data, "submission.submitter", {});
  if (register) return get(data, "user.organization", {});
  if (reportSubmission)
    return get(data, "reportSubmission.sourceDraw.submitter", {});
  return {};
}

const App = ({ children }) => {
  const theme = useContext(ThemeContext);
  const colors = useRef({});

  const submission = useRouteMatch("/submissions/:submissionId");
  const register = useRouteMatch("/register/:invitationToken");
  const guestUpload = useRouteMatch("/guest/:accessTokenId");
  const reportSubmission = useRouteMatch(
    "/report_submissions/:reportSubmissionId"
  );
  const guestRouteMatches = {
    submission,
    register,
    guestUpload,
    reportSubmission,
  };
  const invalidUUID = some(guestRouteMatches, (match) => {
    const params = get(match, "params", {});
    return some(params, (value) => !validateUUID(value));
  });

  const hasGuestTheming = some(guestRouteMatches, (match) => {
    return !!match;
  });

  const GUEST_QUERY = selectGuestQuery(guestRouteMatches);
  const variables = getGuestParams(guestRouteMatches);

  const [getQuery, query] = useLazyQuery(QUERY);
  const auth = useAuth();
  if (!query.called && auth.isAuthenticated()) {
    getQuery();
  }

  const [getRecentProjectsQuery, recentProjectsQuery] = useLazyQuery(
    RECENT_PROJECTS_QUERY,
    {
      variables: {
        filters: {
          field: "id",
          comparator: "IN",
          values: getRecentProjectIds(),
        },
      },
    }
  );

  const [getGuestQuery, guestQuery] = useLazyQuery(GUEST_QUERY, {
    variables,
  });

  if (hasGuestTheming && !guestQuery.called && !invalidUUID) getGuestQuery();

  if (query.loading || guestQuery.loading) return null;

  const { primaryColor, headerColor, primaryLogo } = parseGuestQuery(
    guestQuery.data,
    guestRouteMatches
  );
  const incomingColors = { primaryColor, headerColor, primaryLogo };

  if (hasGuestTheming && !isEqual(colors.current, incomingColors)) {
    colors.current = { ...incomingColors };
    mergeIntoTheme(theme, incomingColors);
  }

  return (
    <AppLayout
      organizations={get(query.data, "organizations", [])}
      pendingInvitations={get(query.data, "user.pendingInvitations", [])}
      recentProjectsQuery={recentProjectsQuery}
      fetchRecentProjects={getRecentProjectsQuery}
      useGuestTheme={hasGuestTheming}
      userEmail={get(query.data, "user.email")}
      userPermissions={query?.data?.user?.permissionConfig || {}}
    >
      {children}
    </AppLayout>
  );
};

export function AppLayoutPage({ children }) {
  return <Route render={() => <App>{children}</App>} />;
}

AppLayoutPage.propTypes = {
  children: PropTypes.node.isRequired,
};
