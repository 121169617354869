import { Fragment } from "react";
import { Formik } from "formik";
import { Form, Modal, Pane, Text } from "components/materials";
import { majorScale } from "helpers/utilities";
import analytics from "helpers/analytics";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";

function getInitialValues({ amountPaid, datePaid }) {
  return {
    amountPaid,
    datePaid,
  };
}

export function MarkPaidModal({
  document,
  setShowMarkPaidModal,
  handleMarkPaid,
  markDocumentPaidLoading,
}) {
  const translatedDocumentType = t(`documentTypeName.${document.type}`);

  function validate(values) {
    const errors = {};

    if (isBlank(values.datePaid)) errors.datePaid = "Date must be selected";
    if (isBlank(values.amountPaid))
      errors.amountPaid = "Amount must be selected";

    return errors;
  }

  function handleSubmit(values) {
    handleMarkPaid(values);
    analytics.track(`${translatedDocumentType} marked paid`, {
      documentId: document.id,
    });
    setShowMarkPaidModal(false);
  }
  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={getInitialValues(document)}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(formikProps) => {
          return (
            <Modal
              hasFooter
              onConfirm={formikProps.handleSubmit}
              confirmLabel="Mark Paid"
              isConfirmLoading={markDocumentPaidLoading}
              onClose={() => {
                setShowMarkPaidModal(false);
              }}
              open
              size="small"
              title="Mark Paid"
            >
              <Modal.Content>
                <Pane marginBottom={majorScale(2)}>
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    marginBottom={majorScale(1)}
                  >
                    Amount Paid
                  </Text>
                  <Form.Input name="amountPaid" type="currency" />
                </Pane>
                <Pane marginBottom={majorScale(2)}>
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    marginBottom={majorScale(1)}
                  >
                    Date Paid
                  </Text>
                  <Form.DateInput
                    name="datePaid"
                    popperPlacement="bottom-end"
                    popperProps={{
                      positionFixed: true,
                    }}
                  />
                </Pane>
              </Modal.Content>
            </Modal>
          );
        }}
      </Formik>
    </Fragment>
  );
}
