import { useMemo, useContext } from "react";
import { TickCircleIcon } from "evergreen-ui";
import { EditTableViews } from "components/containers";
import { Button, Shortener } from "components/materials";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
  booleanColumnDefaults,
  currencyColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import GroupBy from "components/materials/FastDataTable/Controls/GroupBy";
import { get } from "lodash";
import { UserContext } from "helpers/behaviors";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";

const defaultViews = [
  {
    config: toBase64({
      columnConfig: [
        "vendorName",
        "vendorCostCode",
        "type",
        "emailAddress",
        "phoneNumber",
      ],
      filterConfig: [],
      groupConfig: {},
      pageConfig: {
        page: 0,
      },
      sortConfig: {},
    }),
    isDefault: true,
    name: "Default",
  },
];

function getColumns(hasPermission, organization) {
  const hasAvidAP = hasPermission(
    PERMISSION_ACTION.AVID_AP_INTEGRATION,
    organization
  );

  const hasVisionDeltekAP = hasPermission(
    PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION,
    organization
  );

  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Organization",
      id: "vendorName",
      value: (vendor) => vendor.name,
      width: 300,
    },
    {
      ...stringColumnDefaults,
      header: "Type",
      id: "type",
      value: (vendor) => vendor.type,
      valueFormatter: (value) =>
        value ? t(`organizationType.${value}`) : null,
      width: 150,
    },
    {
      ...stringColumnDefaults,
      header: "Vendor ID",
      hidden: !hasPermission(PERMISSION_ACTION.VENDOR_COST_CODES, organization),
      id: "vendorCostCode",
      value: (vendor) => vendor.vendorCostCode,
      width: 150,
    },
    {
      ...stringColumnDefaults,
      filterStrategy: null,
      header: "Email",
      id: "emailAddress",
      value: (vendor) => vendor.emailAddresses.join(", "),
      width: 250,
    },
    {
      ...stringColumnDefaults,
      filterStrategy: null,
      header: "Phone",
      id: "phoneNumber",
      value: (vendor) => vendor.phoneNumbers.join(", "),
      width: 150,
    },
    {
      ...stringColumnDefaults,
      header: "Street Address",
      id: "streetAddress",
      value: (vendor) => vendor.streetAddress,
      width: 150,
    },
    {
      ...stringColumnDefaults,
      header: "City",
      id: "city",
      value: (vendor) => vendor.city,
      width: 150,
    },
    {
      ...stringColumnDefaults,
      header: "State/Province",
      id: "state",
      value: (vendor) => vendor.state,
      width: 100,
    },
    {
      ...stringColumnDefaults,
      header: "Postal Code",
      id: "zip",
      value: (vendor) => vendor.zip,
      width: 150,
    },
    {
      ...booleanColumnDefaults,
      filterStrategy: null,
      header: "Is Payable",
      hidden: !(hasAvidAP || hasVisionDeltekAP),
      id: "isPayable",
      textAlign: "center",
      value: (vendor) => vendor.isPayable,
      valueFormatter: (value) => value && <TickCircleIcon color="success" />,
      width: 80,
    },
    {
      ...booleanColumnDefaults,
      filterStrategy: null,
      header: "Is 1099",
      hidden: !(hasAvidAP || hasVisionDeltekAP),
      id: "is1099",
      textAlign: "center",
      value: (vendor) => vendor.is1099,
      valueFormatter: (value) => value && <TickCircleIcon color="success" />,
      width: 80,
    },
    {
      ...stringColumnDefaults,
      filterStrategy: null,
      header: "Projects",
      id: "projects",
      value: (vendor) =>
        get(vendor, "projects", [])
          .map((project) => project.name)
          .join(", ") || "-",
      sortStrategy: null,
      valueFormatter: (value) => (
        <Shortener limit={250} size={300} text={value} />
      ),
      width: 250,
    },
    {
      ...currencyColumnDefaults,
      header: "Agreement Amount Total",
      hidden: !hasPermission(
        PERMISSION_ACTION.AGREEMENT_MANAGEMENT,
        organization
      ),
      id: "agreementAmountTotal",
      value: (vendor) => vendor.committedAmount,
      width: 300,
    },
    {
      ...currencyColumnDefaults,
      filterStrategy: null,
      header: "Paid To Date",
      id: "paidToDate",
      value: (vendor) => vendor.paidToDateAmount,
      width: 300,
    },
  ];
}

function getControls(
  hasPermission,
  propsControls,
  propsEditTableViews,
  onAddVendor
) {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      disable={[FastDataTableDownloadDocuments, GroupBy]}
      rightControls={
        hasPermission(PERMISSION_ACTION.CREATE_EDIT_ORGANIZATION) ? (
          <Button appearance="primary" onClick={onAddVendor}>
            Add Organization
          </Button>
        ) : null
      }
      searchPlaceholder="Search Organizations..."
    />
  );
}

export function VendorsTable({
  history,
  onConfigChange,
  selectedOrganization,
  setVendorId,
  vendorCount,
  vendors,
}) {
  const { hasPermission } = useContext(UserContext);
  const columns = useMemo(() => {
    return getColumns(hasPermission, selectedOrganization);
  }, [hasPermission, selectedOrganization]);

  const onAddVendor = () => history.push("/organizations/new");

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(
        PERMISSION_ACTION.SAVE_TABLE_VIEWS,
        selectedOrganization
      )}
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={selectedOrganization.id}
      defaultViews={defaultViews}
      scopeViewsToOrganization
      tableName="VendorsTable"
    >
      {(propsEditTableViews) => (
        <FastDataTable
          columns={columns}
          controls={(propsControls) =>
            getControls(
              hasPermission,
              propsControls,
              propsEditTableViews,
              onAddVendor
            )
          }
          itemCountForPagination={vendorCount}
          items={vendors}
          onClickRow={(vendor) => setVendorId(vendor.id)}
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(propsEditTableViews, "views.0.config")
          }
          onConfigChange={onConfigChange}
          prepareOnFrontend={false}
        />
      )}
    </EditTableViews>
  );
}
