import { Pane } from "components/materials";

export function ImageViewer({ file }) {
  const style = {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "auto",
  };

  return (
    <Pane
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      background="rgba(233, 236, 243, 0.24)"
    >
      <img alt={file.name} src={file.url} style={style} />
    </Pane>
  );
}
