import { useQuery } from "@apollo/react-hooks";
import { ErrorMessage } from "formik";
import { Button, Heading, Loadable, Pane, Text } from "components/materials";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { AGREEMENT_TYPE } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import { UPGRADEABLE_AGREEMENTS_QUERY } from "./graphql-queries";
import { AgreementsTable } from "./AgreementsTable";

export function PromptToUpgradeAgreement({ form }) {
  const {
    setFieldValue,
    setValues,
    values: { agreementManagementDisabled, projectId, type: agreementType },
  } = form;

  const { data, loading } = useQuery(UPGRADEABLE_AGREEMENTS_QUERY, {
    variables: { projectId },
  });
  const closeUpgradeAgreement = () =>
    setFieldValue("promptUserToUpgrade", false);

  if (loading) return <Loadable loading />;

  const validAgreementTypes = [AGREEMENT_TYPE.EXPOSURE].concat(
    agreementType === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
      ? [AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER]
      : []
  );

  const validAgreements = data.project.agreements.filter((agreement) =>
    validAgreementTypes.includes(agreement.type)
  );

  if (agreementManagementDisabled || validAgreements.length === 0) {
    closeUpgradeAgreement();
  }

  return (
    <Pane height="80vh">
      <Pane height="calc(80vh - 64px)" overflow="auto" padding={majorScale(2)}>
        <Button
          appearance="primary"
          marginTop={majorScale(2)}
          marginBottom={majorScale(4)}
          onClick={closeUpgradeAgreement}
        >
          {`Create a New ${t(`agreementType.${agreementType}`)}`}
        </Button>
        <ErrorMessage
          name="promptUserToUpgrade"
          render={(error) => (
            <Pane marginTop={-majorScale(2)} marginBottom={majorScale(1)}>
              <Text color="red" small>
                {error}
              </Text>
            </Pane>
          )}
        />
        <Heading marginBottom={majorScale(1)}>
          {t(`agreementsPage.upgrade.${agreementType}`)}
        </Heading>
        <AgreementsTable
          isDocumentViewer
          isSelectionContext
          agreements={validAgreements}
          onClickRow={setUpgradeAgreementValues}
          selectionButtonCopy="Select"
        />
      </Pane>
    </Pane>
  );

  function setUpgradeAgreementValues({
    agreementNumber,
    changeOrderReason,
    correlationId,
    daysImpacted,
    lineItems,
    vendor,
    startDate: date,
  }) {
    const agreementLineItems = lineItems.map(
      ({ lineItemId, amount, retainagePercentage }) => ({
        lineItemId,
        amount: formatCurrency(amount),
        retainagePercentage: formatPercent(retainagePercentage),
      })
    );

    const upgradeValues = {
      ...form.values,
      agreementLineItems,
      budgetAdjustmentTransactions: [],
      agreementNumber,
      changeOrderReason,
      correlationId,
      date,
      daysImpacted,
      promptUserToUpgrade: false,
      vendor: vendor || { id: null },
    };

    setValues(upgradeValues);
  }
}
