import { useCallback, useContext, useMemo } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { get, range } from "lodash";
import { useHistory } from "react-router-dom";
import { Loadable } from "components/materials";
import { differenceInSeconds } from "helpers/dateHelpers";
import { toaster } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import Layout from "./Layout";

const QUERY = gql`
  query UploadQuery($id: String!) {
    documentUpload(id: $id) {
      id
      drawId
      fileName
      hidden
      projectId
      split
      status
      file {
        id
        name
        pageCount
        downloadUrl
      }
      splits {
        id
        indexes
      }
    }

    user {
      id
      splitterHelp
    }
  }
`;

const DISMISS = gql`
  mutation DismissSplitterHelp($organizationId: String!) {
    dismissSplitterHelp(organizationId: $organizationId) {
      id
      splitterHelp
    }
  }
`;

const SPLIT = gql`
  mutation SplitUpload(
    $splits: [DocumentUploadSplitInput]!
    $uploadId: String!
  ) {
    confirmSplits(splits: $splits, uploadId: $uploadId) {
      id
      hidden
      fileName
      status
      split
      file {
        id
        name
        downloadUrl
      }
      splits {
        id
        indexes
        classification
      }
    }
  }
`;

function useSplitterForm(upload) {
  const startTime = useMemo(() => Date.now(), []);

  const [split, splitResult] = useMutation(SPLIT, {
    onError: () => {
      toaster.danger(
        "Splitting the document failed, this may be an invalid pdf format",
        { duration: 2.5 }
      );
    },
  });

  const initialValues = useMemo(() => {
    if (!upload.splits) return {};

    const splits = upload.splits
      .map((split) => Math.max(...split.indexes))
      .sort((a, b) => a - b);

    return { splits };
  }, [upload.splits]);

  const onSubmit = useCallback(
    ({ splits }) => {
      const secondsToSplit = differenceInSeconds(Date.now(), startTime);
      const { splits: newSplits } = splits
        .sort((a, b) => a - b)
        .reduce(
          (acc, lastPage) => {
            const split = { indexes: range(acc.lastSplit, lastPage + 1) };
            return {
              splits: [...acc.splits, split],
              lastSplit: lastPage + 1,
            };
          },
          { splits: [], lastSplit: 1 }
        );

      return split({
        variables: { secondsToSplit, splits: newSplits, uploadId: upload.id },
      });
    },
    [split, startTime, upload.id]
  );

  return {
    onSubmit,
    initialValues,
    result: splitResult,
  };
}

function useDismissHelp(uploadQuery) {
  const { organizationId } = useContext(UserContext);
  const [dismiss] = useMutation(DISMISS);

  const dismissed = get(uploadQuery.data, "user.splitterHelp");

  const onDismiss = useCallback(() => {
    dismiss({ variables: { organizationId } });
  }, [dismiss, organizationId]);

  return {
    dismissed,
    onDismiss,
  };
}

export function UploadSplitter({ uploadId }) {
  const history = useHistory();
  const handleClose = () => history.goBack();

  const uploadQuery = useQuery(QUERY, {
    variables: { id: uploadId },
  });

  const upload = get(uploadQuery.data, "documentUpload", {});

  const help = useDismissHelp(uploadQuery);
  const form = useSplitterForm(upload);

  if (uploadQuery.loading || !uploadQuery.called || !uploadQuery.data) {
    return <Loadable />;
  }

  return (
    <Formik
      initialValues={form.initialValues}
      onSubmit={(values) => form.onSubmit(values).then(handleClose)}
    >
      {(propsFormik) => {
        return (
          <Layout
            upload={upload}
            onClose={handleClose}
            submitting={form.result.loading}
            helpDismissed={help.dismissed}
            onDismissHelp={help.onDismiss}
            propsFormik={propsFormik}
          />
        );
      }}
    </Formik>
  );
}

export default UploadSplitter;
