import { map, every, isEqual, omit, reduce } from "lodash";

export default function isEqualWithout(paths = [], ...comparisonObjects) {
  if (comparisonObjects.length < 2) {
    return true;
  }
  if (typeof paths == "string") {
    paths = [paths];
  }
  if (paths.length > 0) {
    comparisonObjects = reduce(
      paths,
      (objects, path) => map(objects, (object) => omit(object, path)),
      comparisonObjects
    );
  }
  return every(comparisonObjects, (object) =>
    isEqual(comparisonObjects[0], object)
  );
}
