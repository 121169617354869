import gql from "graphql-tag";

export const FINALIZE_BUDGET = gql`
  mutation FinalizeBudget(
    $budget: [OnboardedDivisionInput]!
    $projectId: String!
  ) {
    setUploadedBudget(budget: $budget, projectId: $projectId) {
      id
    }
  }
`;

export const UPDATE_ONBOARDING_DATA = gql`
  mutation UpdateOnboardingData($projectId: String!, $data: Data!) {
    updateOnboardingData(projectId: $projectId, data: $data) {
      id
      onboardingData
    }
  }
`;
