export const CHANGE_ORDER_REASON = {
  ARCHITECT_SPECIFICATION: "ARCHITECT_SPECIFICATION",
  CLIENT_REQUEST: "CLIENT_REQUEST",
  DESIGN_CHANGE: "DESIGN_CHANGE",
  OTHER: "OTHER",
  OWNER_REQUEST: "OWNER_REQUEST",
  REQUIRED_PERMITS: "REQUIRED_PERMITS",
  UNFORESEEN_CONDITIONS: "UNFORESEEN_CONDITIONS",
  UNSPECIFIED: "UNSPECIFIED",
};
