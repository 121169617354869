import { DOCUMENT_TYPE_NAME } from "helpers/enums";

export const PARSABLE_DOCUMENT_TYPES = [
  DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET,
  DOCUMENT_TYPE_NAME.INVOICE,
  DOCUMENT_TYPE_NAME.PAY_APPLICATION,
  DOCUMENT_TYPE_NAME.DRAW_SUMMARY,
  DOCUMENT_TYPE_NAME.HUD_2448,
  DOCUMENT_TYPE_NAME.HUD_92464,
];
