import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Confirm, Heading, Modal, Pane } from "components/materials";
import { majorScale, toaster } from "helpers/utilities";
import t from "helpers/translate";
import { AGREEMENT_TYPE } from "helpers/enums";
import { ASSOCIATE_PROJECT_AGREEMENTS } from "./graphql-queries";
import { AgreementsTable } from "./AgreementsTable";

export function AssociateAgreementsModal({
  agreement,
  associableAgreements,
  associateWithType,
  navigateOnAssociate,
  onClose,
  refetchQueries,
}) {
  const { type: agreementType, id: agreementId } = agreement;
  const artifactType = [agreementType, associateWithType].includes(
    AGREEMENT_TYPE.EXPOSURE
  )
    ? AGREEMENT_TYPE.EXPOSURE
    : AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER;
  const [
    confirmAssociateWithAgreementId,
    setConfirmAssociateWithAgreementId,
  ] = useState(null);

  const selectedAgreement = associableAgreements.find(
    ({ id }) => id === confirmAssociateWithAgreementId
  );
  const upgradedAgreement = [agreement]
    .concat(selectedAgreement || {})
    .find(({ type }) => type !== artifactType);

  const [associateProjectAgreements, { loading }] = useMutation(
    ASSOCIATE_PROJECT_AGREEMENTS,
    {
      onCompleted: () => {
        setConfirmAssociateWithAgreementId(null);
        onClose();
        navigateOnAssociate(upgradedAgreement.id);
      },
      onError: ({ message }) => {
        setConfirmAssociateWithAgreementId(null);
        toaster.warning(message, {
          duration: 7,
        });
      },
      refetchQueries,
    }
  );

  const getModalHeader = () => {
    switch (associateWithType) {
      case AGREEMENT_TYPE.EXPOSURE:
        return "Associate with an Exposure";
      case AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER:
        return "Associate with a Potential Change Order";
      default:
        return "Associate with an Executed Change Order";
    }
  };

  const handleConfirmAssociateAgreements = () => {
    const agreementIds = [confirmAssociateWithAgreementId, agreementId];
    associateProjectAgreements({
      variables: { agreementIds },
    });
  };

  return (
    <Fragment>
      <Modal
        hasConfirm={false}
        onClose={onClose}
        open
        width="85%"
        title={getModalHeader()}
      >
        <Pane>
          <Heading marginBottom={majorScale(1)}>{`${t(
            `agreementType.${associateWithType}`
          )}s`}</Heading>
          <AgreementsTable
            isSelectionContext
            agreements={associableAgreements}
            disableRowClick={({ disabled }) => disabled}
            onClickRow={({ id }) => setConfirmAssociateWithAgreementId(id)}
            selectionButtonCopy="Select"
          />
        </Pane>
      </Modal>
      {confirmAssociateWithAgreementId !== null && (
        <Confirm
          content={t("agreementsPage.confirmAssociate", {
            artifactType: t(`agreementType.${artifactType}`),
          })}
          header="Associate Agreements"
          open
          onConfirm={() => {
            handleConfirmAssociateAgreements();
          }}
          onCloseComplete={() => setConfirmAssociateWithAgreementId(null)}
          cancelLabel="Cancel"
          confirmLabel="Associate"
          isConfirmLoading={loading}
        />
      )}
    </Fragment>
  );
}

AssociateAgreementsModal.propTypes = {
  navigateOnAssociate: PropTypes.func,
};

AssociateAgreementsModal.defaultProps = {
  navigateOnAssociate: () => {},
};
