import { useContext, Fragment } from "react";
import { Form, Paragraph } from "components/materials";
import { omit } from "lodash";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import DocumentReviewLayout from "./DocumentReviewLayout";

const getInitialValues = ({ document, selectedDraw, selectedType }) => {
  const {
    comments,
    documentReviewActivity,
    reviews,
    inspectionNote,
    ...restOfDocument
  } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);
  return {
    ...documentProperties,
    draw: selectedDraw,
    type: selectedType,
    inspectionNote: inspectionNote || "",
  };
};

const getDocumentDataFromForm = ({ inspectionNote }) => ({ inspectionNote });

export function InspectionImageReview(props) {
  const { hasPermission } = useContext(UserContext);

  return (
    <DocumentReviewLayout disableInformation {...props}>
      {hasPermission(PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW) && (
        <Form.Section
          heading="Inspector Notes"
          isCollapsible
          isCollapsed={props.collapsedPanels.includes("inspectorNotes")}
          onCollapse={() => props.togglePanel("inspectorNotes")}
        >
          <Fragment>
            <Paragraph marginBottom={majorScale(1)}>
              This note appears alongside the change order on this draw&apos;s
              inspection report.
            </Paragraph>
            <Form.TextArea name="inspectionNote" />
          </Fragment>
        </Form.Section>
      )}
    </DocumentReviewLayout>
  );
}

// TODO: remove dot notation
InspectionImageReview.getInitialValues = getInitialValues;
InspectionImageReview.getDocumentDataFromForm = getDocumentDataFromForm;
