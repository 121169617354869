import gql from "graphql-tag";

export default gql`
  fragment DrawFundingSourcesFragment on Draw {
    fundingSources {
      id
      scopeId
      amount
      disbursedAmount
      disbursedPreviouslyAmount
      disbursedToDateAmount
      label
      type
    }
  }
`;
