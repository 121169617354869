import { useCallback } from "react";
import PropTypes from "prop-types";
import {
  CollapseAllIcon,
  HelpIcon,
  ExpandAllIcon,
  LockIcon,
} from "evergreen-ui";
import { Table, Tooltip } from "components/materials";
import { minorScale, Position } from "helpers/utilities";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import t from "helpers/translate";

const NEXT_SORT_DIRECTION = {
  asc: "desc",
  desc: null,
  null: "asc",
  undefined: "asc",
};

function HeaderGroupingRow({ preparedColumns, theme, utilityColumns }) {
  if (preparedColumns.every((preparedColumn) => !preparedColumn.headerGrouping))
    return null;

  return (
    <Table.Row background="white">
      {utilityColumns.map((utilityColumn) => (
        <Table.TextHeaderCell
          background="none"
          border="none"
          key={utilityColumn.id}
        />
      ))}
      {preparedColumns.reduce((acc, preparedColumn, index) => {
        if (!preparedColumn.headerGrouping) {
          acc.push(
            <Table.TextHeaderCell
              background="none"
              border="none"
              key={preparedColumn.id}
            />
          );
        } else if (
          preparedColumns.findIndex(
            (pc) => pc.headerGrouping === preparedColumn.headerGrouping
          ) === index
        ) {
          acc.push(
            <Table.TextHeaderCell
              background="none"
              border="none"
              borderLeft={`2px solid ${theme.colors.gray500}`}
              colSpan={
                preparedColumns.filter(
                  (pc) => pc.headerGrouping === preparedColumn.headerGrouping
                ).length
              }
              key={preparedColumn.id}
              textAlign="center"
            >
              {preparedColumn.headerGroupFormatted ||
                preparedColumn.headerGrouping}
            </Table.TextHeaderCell>
          );
        }
        return acc;
      }, [])}
      <Table.TextHeaderCell background="none" border="none" />
    </Table.Row>
  );
}

export function FastDataTableHeader({
  config,
  hideTableHeader,
  preparedColumns,
  preparedItems,
  selectedItemIds,
  setConfig,
  setSelectedItemIds,
  theme,
  utilityColumns,
}) {
  const toggleAllSections = useCallback(
    (e) => {
      preventEventBubbling(e);
      const { groupConfig } = config;
      const expandAll =
        groupConfig.expanded && groupConfig.expanded.length === 0;
      const payload = {
        ...config,
        groupConfig: { ...groupConfig, expanded: expandAll ? null : [] },
      };
      setConfig({ payload });
    },
    [config, setConfig]
  );

  if (hideTableHeader) return null;

  const iconProps = {
    size: 12,
    marginRight: minorScale(2),
    marginBottom: -2,
    onClick: toggleAllSections,
    cursor: "pointer",
  };

  return (
    <Table.Head>
      <HeaderGroupingRow
        preparedColumns={preparedColumns}
        theme={theme}
        utilityColumns={utilityColumns}
      />
      <Table.Row>
        {utilityColumns.map((utilityColumn) => (
          <Table.TextHeaderCell
            cursor="default"
            id={utilityColumn.id}
            key={utilityColumn.id}
            maxWidth={utilityColumn.maxWidth}
            onClick={preventEventBubbling}
            width={utilityColumn.width}
          >
            {utilityColumn.header(
              preparedItems,
              selectedItemIds,
              setSelectedItemIds
            )}
          </Table.TextHeaderCell>
        ))}
        {preparedColumns.map((preparedColumn) => (
          <Table.TextHeaderCell
            border={null}
            cellProps={preparedColumn.cellProps}
            cursor={preparedColumn.sortStrategy ? "pointer" : "default"}
            id={preparedColumn.id}
            key={preparedColumn.id}
            testId={preparedColumn.testId}
            maxWidth={preparedColumn.maxWidth}
            onClick={() => {
              if (!preparedColumn.sortStrategy) return;
              const payload = {
                ...config,
                sortConfig: {
                  columnId: preparedColumn.id,
                  direction:
                    preparedColumn.id === config.sortConfig.columnId
                      ? NEXT_SORT_DIRECTION[config.sortConfig.direction]
                      : "asc",
                },
              };
              setConfig({ payload });
            }}
            sortDirection={
              preparedColumn.id === config.sortConfig.columnId
                ? config.sortConfig.direction
                : null
            }
            state={preparedColumn.state}
            textAlign={preparedColumn.textAlign || "left"}
            width={preparedColumn.width}
          >
            {config.groupConfig.columnId &&
              preparedColumn.primary &&
              (config.groupConfig.expanded &&
              config.groupConfig.expanded.length === 0 ? (
                <ExpandAllIcon {...iconProps} />
              ) : (
                <CollapseAllIcon {...iconProps} />
              ))}
            {preparedColumn.header}
            {preparedColumn.internal && (
              <Tooltip
                content={t("drawLineItem.internalColumnTooltip")}
                position={Position.TOP}
              >
                <LockIcon
                  marginLeft={minorScale(1)}
                  size={12}
                  marginBottom={-2}
                />
              </Tooltip>
            )}
            {preparedColumn.tooltip && (
              <Tooltip content={preparedColumn.tooltip} position={Position.TOP}>
                <HelpIcon
                  marginLeft={minorScale(1)}
                  size={12}
                  marginBottom={-2}
                />
              </Tooltip>
            )}
          </Table.TextHeaderCell>
        ))}
        <Table.HeaderCell />
      </Table.Row>
    </Table.Head>
  );
}

FastDataTableHeader.propTypes = {
  config: PropTypes.object,
  preparedColumns: PropTypes.array,
  preparedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedItemIds: PropTypes.array,
  setConfig: PropTypes.func,
  setSelectedItemIds: PropTypes.func,
  utilityColumns: PropTypes.array,
};

FastDataTableHeader.defaultProps = {};
