import { CrossIcon } from "evergreen-ui";
import { Form, Pane, Table } from "components/materials";
import { minorScale } from "helpers/utilities";
import { getFullLineItemName } from "helpers/hud92464Helpers";

function getLineItemDisplayName(lineItem) {
  const prefix = lineItem?.number ? `(${lineItem?.number}) ` : "";
  return `${prefix}${getFullLineItemName(lineItem)}`;
}

function getLineItems(lineItems) {
  return lineItems.map((lineItem) => {
    return {
      key: lineItem.id,
      text: getLineItemDisplayName(lineItem),
      value: {
        requestNumber: getFullLineItemName(lineItem),
        drawLineItemId: lineItem.id,
      },
    };
  });
}

export function LineItemRow({ draw, disableForm, form, remove, index }) {
  const updateLineItemIndices = (lineItems) => {
    form.setFieldValue(
      "lineItems",
      lineItems.map((lineItem, index) => ({
        ...lineItem,
        index,
      }))
    );
  };

  const lineItemError = form.errors.lineItems
    ? form.errors.lineItems[index]
    : null;

  return (
    <Table.Row>
      <Table.TextCell>
        <Pane>
          <Pane display="flex">
            <CrossIcon
              size={14}
              cursor={disableForm ? "not-allowed" : "pointer"}
              marginRight={minorScale(3)}
              marginTop={minorScale(2)}
              onClick={() => {
                if (!disableForm) {
                  const currentLineItems = form.values.lineItems;
                  remove(index);
                  updateLineItemIndices(
                    currentLineItems.filter(
                      (_, lineItemIndex) => lineItemIndex !== index
                    )
                  );
                }
              }}
            />
            <Form.Select
              disabled={disableForm}
              name={`lineItems.${index}.lineItemObject`}
              options={draw ? getLineItems(draw.lineItems) : []}
              noNull
              placeholder="Select Line Item..."
              width={200}
              popoverMinWidth={700}
            />
          </Pane>
          {lineItemError && (
            <Pane color="#ec4c47" marginTop={minorScale(1)}>
              {lineItemError}
            </Pane>
          )}
        </Pane>
      </Table.TextCell>
      <Table.Cell>
        <Form.Input
          disabled={disableForm}
          initialValues={form.initialValues}
          name={`lineItems.${index}.amountsCompleted`}
          type="currency"
        />
      </Table.Cell>
      <Table.Cell>
        <Form.Input
          disabled={disableForm}
          initialValues={form.initialValues}
          name={`lineItems.${index}.hudApprovedAmount`}
          type="currency"
        />
      </Table.Cell>
    </Table.Row>
  );
}
