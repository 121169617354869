import {
  DOCUMENT_TYPE_NAME,
  PERMISSION_ACTION,
  ORGANIZATION_TYPE,
} from "helpers/enums";
import { find, get } from "lodash";
import { add, subtract, sumBy } from "helpers/math";
import { getPreviousLineItem } from "helpers/retainageLineItemHelpers";
import isBlank from "helpers/isBlank";
import unformatNumber from "helpers/unformatNumber";
import {
  getAmountDueThisRequisition,
  getLessPreviousPayments,
  getLessRetainedAmount,
  getMatchedLineItems,
  getTotalAmount,
} from "./hud92464Helpers";

export const getIsGeneralContractor = ({ project, vendor }) => {
  return (
    !isBlank(vendor.id) &&
    !!find(
      get(project, "stakeholderGroups", []),
      (stakeholder) =>
        stakeholder.organizationId === vendor.id &&
        stakeholder.role === ORGANIZATION_TYPE.CONTRACTOR
    )
  );
};

export const getCurrentPaymentDue = (formValues, lineItem) => {
  const previousLineItem = getPreviousLineItem(formValues.draw, lineItem);

  return subtract(
    add(
      lineItem.applicationAmount,
      lineItem.materialsStoredAmount,
      get(previousLineItem, "materialsStoredAmount", 0)
    ),
    lineItem.retainageAmount
  );
};

// Do not include line items where the description dropdown is blank or line items that should not have amounts auto-calculated
function filterToValidLineItems(formValues) {
  return get(formValues, "lineItems", []).filter((lineItem) => {
    const drawLineItem = get(formValues, "draw.lineItems", []).find(
      ({ id }) => get(lineItem, "lineItemObject.id") === id
    );
    return (
      get(lineItem, "lineItemObject.id") && !get(drawLineItem, "setManually")
    );
  });
}

function filterToValidLineItemsFor92464(formValues) {
  return get(formValues, "lineItems", []).filter((lineItem) => {
    return get(formValues, "draw.lineItems", []).find(
      ({ id }) => get(lineItem, "lineItemObject.drawLineItemId") === id
    );
  });
}

function getPayApplicationRequestedAmount(formValues, hasPermission) {
  const lineItems = get(formValues, "lineItems", []);
  const isGeneralContractor = getIsGeneralContractor({
    project: formValues.project,
    vendor: formValues.vendor,
  });
  const showFullLineItemsTable =
    isGeneralContractor ||
    !hasPermission(PERMISSION_ACTION.SUBCONTRACTOR_FORM) ||
    formValues.showFullFields ||
    // Do not show the simplified form if we've parsed out multiple line items
    lineItems.length > 1;
  const validLineItems = filterToValidLineItems(formValues);

  if (!showFullLineItemsTable && get(validLineItems, "0.lineItemObject.id")) {
    // If the form is a simplified pay app,
    // use the current amount due field directly
    return unformatNumber(formValues.currentPaymentDue);
  }

  return sumBy(validLineItems, (lineItem) => {
    return getCurrentPaymentDue(formValues, lineItem);
  });
}

function getInvoiceRequestedAmount(formValues) {
  const validLineItems = filterToValidLineItems(formValues);
  return sumBy(validLineItems, (lineItem) => {
    return get(lineItem, "amount", 0);
  });
}

function get92464RequestedAmount(formValues) {
  const validLineItems = filterToValidLineItemsFor92464(formValues);

  const matchedLineItems = getMatchedLineItems(
    validLineItems,
    formValues.draw.lineItems
  );
  const total = getTotalAmount(validLineItems);
  const lessPreviousPayments = getLessPreviousPayments(matchedLineItems);
  const lessRetainedAmount = getLessRetainedAmount(matchedLineItems);
  return getAmountDueThisRequisition(
    total,
    lessPreviousPayments,
    lessRetainedAmount
  );
}

export function getRequestedAmountFromForm(
  formValues,
  hasPermission,
  manuallyRequestedAmount = 0
) {
  if (!formValues.draw) return 0;
  if (!get(formValues, "vendor.id")) return 0;

  if (
    (formValues.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION ||
      formValues.type === DOCUMENT_TYPE_NAME.INVOICE) &&
    formValues.isBackup
  ) {
    return -manuallyRequestedAmount;
  }

  if (formValues.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION) {
    return subtract(
      getPayApplicationRequestedAmount(formValues, hasPermission),
      manuallyRequestedAmount
    );
  }

  if (formValues.type === DOCUMENT_TYPE_NAME.INVOICE) {
    return subtract(
      getInvoiceRequestedAmount(formValues),
      manuallyRequestedAmount
    );
  }

  if (formValues.type === DOCUMENT_TYPE_NAME.HUD_92464) {
    return get92464RequestedAmount(formValues);
  }

  return 0;
}

export function getPreviouslyManuallyRequestedAmounts(
  formLineItems,
  drawLineItems
) {
  // TODO: make this work for line items in objects
  // Specifically the 2448
  if (!Array.isArray(formLineItems)) return 0;

  return formLineItems.reduce((amountManuallyRequested, formLineItem) => {
    const drawLineItem = drawLineItems.find(
      ({ id }) => id === get(formLineItem, "lineItemObject.id")
    );

    // setManually refers to whether the backend should update its requestedAmount
    // and does not mean that the amount was set manually by the user
    const amountIsManuallyRequested =
      get(drawLineItem, "grossRequestedAmount", 0) !==
        get(drawLineItem, "grossDocumentedRequestedAmount", 0) &&
      !get(drawLineItem, "setManually");

    if (amountIsManuallyRequested) {
      return add(amountManuallyRequested, drawLineItem.requestedAmount);
    }
    return amountManuallyRequested;
  }, 0);
}
