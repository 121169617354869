import { useState, useEffect } from "react";
import pdfjs from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function usePDFToImages(url) {
  const [pdfData, setPdfData] = useState({ numPages: 0 });

  useEffect(() => {
    async function fetchPdfs() {
      if (!url) return;

      const pdf = await pdfjs.getDocument(url).promise;
      setPdfData({ numPages: pdf.numPages, images: {} });

      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum += 1) {
        (async () => {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement("canvas");
          const canvasContext = canvas.getContext("2d");
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          await page.render({ canvasContext, viewport }).promise;

          setPdfData((pdfData) => ({
            ...pdfData,
            images: {
              ...pdfData.images,
              [pageNum]: canvas.toDataURL(),
            },
          }));
        })();
      }
    }

    fetchPdfs();
  }, [url]);

  return pdfData;
}
