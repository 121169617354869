import { useEffect } from "react";
import { Prompt } from "react-router-dom";
import t from "helpers/translate";

const getPromptContent = () =>
  JSON.stringify({
    confirmText: "Continue without saving",
    messageText: t("confirmNavigation.warning"),
  });

const unloadWarning = (e) => {
  const returnValue = `${t(`confirmNavigation.warning`)}`;
  e.preventDefault();
  e.returnValue = returnValue;
  return returnValue;
};

export const useBeforeUnload = (trigger) => {
  useEffect(() => {
    if (trigger === true) {
      window.addEventListener("beforeunload", unloadWarning);

      return () => {
        window.removeEventListener("beforeunload", unloadWarning);
      };
    }
    return undefined;
  }, [trigger]);
};

export const suppressNavigationWarning = () =>
  window.removeEventListener("beforeunload", unloadWarning);

export function NavigationWarnings({ dirty }) {
  useBeforeUnload(dirty);
  return <Prompt when={dirty} message={getPromptContent()} />;
}
