import { useContext, useMemo } from "react";
import { EditTableViews } from "components/containers";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  primaryColumnDefaults,
  stringColumnDefaults,
  dateColumnDefaults,
  enumColumnDefaults,
  numberColumnDefaults,
  toBase64,
} from "components/materials/FastDataTable";
import {
  getDateRangeAggregate,
  getDefaultAggregate,
  getNumberRangeAggregate,
} from "helpers/tableAggregateHelpers";
import { get } from "lodash";
import { UserContext } from "helpers/behaviors";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import {
  PERMISSION_ACTION,
  PROJECT_STATUS_TYPE,
  TASK_STATUS,
} from "helpers/enums";
import { getEnumValuesForOrganization } from "helpers/reportHelpers";
import { formatNumber } from "helpers/formatNumber";
import t from "helpers/translate";

function getColumns(
  isReport,
  dependencyTrackingEnabled,
  orgData,
  hasPermission
) {
  const orgEnumValues = orgData ? getEnumValuesForOrganization(orgData) : {};

  const defaultColumns = [
    {
      ...primaryColumnDefaults,
      ...stringColumnDefaults,
      header: "Task",
      // keep legacy id to maintain legacy saved views
      id: "milestone",
      value: ({ eventName }) => eventName,
      width: 350,
    },
    {
      ...stringColumnDefaults,
      header: "Owner",
      id: "owner",
      category: "Task Details",
      groupable: true,
      value: ({ ownerName }) => ownerName,
      aggregate: (tasks) => getDefaultAggregate(tasks, "ownerName"),
      width: 175,
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(TASK_STATUS).map((status) =>
        t(`taskStatus.${status}`)
      ),
      header: "Status",
      id: "status",
      category: "Task Details",
      value: ({ status }) => t(`taskStatus.${status}`),
      aggregate: (tasks) =>
        getDefaultAggregate(tasks, ({ status }) => t(`taskStatus.${status}`)),
      width: 110,
    },
    {
      ...dateColumnDefaults,
      header: "Original Start Date",
      // keep legacy id to maintain legacy saved views
      id: "targetDate",
      category: "Task Details",
      value: ({ originalStartDate }) => {
        return originalStartDate;
      },
      aggregate: (tasks) => getDateRangeAggregate(tasks, "originalStartDate"),
      width: 140,
    },
    {
      ...dateColumnDefaults,
      header: "Projected Start Date",
      id: "projectedStartDate",
      category: "Task Details",
      tooltip: dependencyTrackingEnabled
        ? "The most accurate estimation of task start date. If dependent on another task, this date is calculated according to the configured offset"
        : undefined,
      value: ({ projectedStartDate }) => projectedStartDate,
      aggregate: (tasks) => getDateRangeAggregate(tasks, "projectedStartDate"),
      width: 150,
    },
    {
      ...dateColumnDefaults,
      header: "Actual Start Date",
      id: "actualStartDate",
      category: "Task Details",
      value: ({ actualStartDate }) => actualStartDate,
      aggregate: (tasks) => getDateRangeAggregate(tasks, "actualStartDate"),
      width: 140,
    },
    {
      ...dateColumnDefaults,
      header: "Projected / Actual Start Date",
      id: "projectedOrActualStartDate",
      category: "Task Details",
      tooltip:
        "The Actual Start Date if the task has been started, or the Projected Start Date if not started",
      value: ({ projectedOrActualStartDate }) => projectedOrActualStartDate,
      aggregate: (tasks) =>
        getDateRangeAggregate(tasks, "projectedOrActualStartDate"),
      width: 140,
    },
    {
      ...numberColumnDefaults,
      header: "Original Duration",
      id: "duration",
      category: "Task Details",
      value: ({ originalDuration }) => originalDuration,
      valueFormatter: (originalDuration) =>
        originalDuration === null
          ? null
          : `${formatNumber(originalDuration)} days`,
      aggregate: (tasks) => getNumberRangeAggregate(tasks, "originalDuration"),
      aggregateFormatter: (range) =>
        range === "-" ? range : `${formatNumber(range)} days`,
      width: 140,
    },
    {
      ...numberColumnDefaults,
      header: "Actual Duration",
      id: "actualDuration",
      category: "Task Details",
      tooltip:
        "The difference in business days, excluding holidays, between Actual Start Date and Actual Completion Date, inclusive of the start date",
      value: ({ actualDuration }) => actualDuration,
      valueFormatter: (actualDuration) =>
        actualDuration === null ? null : `${formatNumber(actualDuration)} days`,
      aggregate: (tasks) => getNumberRangeAggregate(tasks, "actualDuration"),
      aggregateFormatter: (range) =>
        range === "-" ? range : `${formatNumber(range)} days`,
      width: 140,
    },
    {
      ...dateColumnDefaults,
      header: "Original Completion Date",
      // keep legacy id to maintain legacy saved views
      id: "expectedCompletion",
      category: "Task Details",
      tooltip:
        "Calculated as the date after running the Original Duration of business days, excluding holidays, from the Original Start Date, inclusive of the start date",
      value: ({ originalCompletionDate }) => originalCompletionDate,
      aggregate: (tasks) =>
        getDateRangeAggregate(tasks, "originalCompletionDate"),
      width: 140,
    },
    {
      ...dateColumnDefaults,
      header: "Projected Completion Date",
      id: "projectedCompletionDate",
      category: "Task Details",
      tooltip:
        "Calculated as the date after running the Original Duration of business days, excluding holidays, from the Projected Start Date, inclusive of the start date",
      value: ({ projectedCompletionDate }) => projectedCompletionDate,
      aggregate: (tasks) =>
        getDateRangeAggregate(tasks, "projectedCompletionDate"),
      width: 140,
    },
    {
      ...dateColumnDefaults,
      header: "Actual Completion Date",
      id: "actualCompletionDate",
      category: "Task Details",
      value: ({ actualCompletionDate }) => actualCompletionDate,
      aggregate: (tasks) =>
        getDateRangeAggregate(tasks, "actualCompletionDate"),
      width: 140,
    },
    {
      ...dateColumnDefaults,
      header: "Projected / Actual Completion Date",
      id: "projectedOrActualCompletionDate",
      category: "Task Details",
      tooltip:
        "The Actual Completion Date if the task has been completed, or the Projected Completion Date if not complete",
      value: ({ projectedOrActualCompletionDate }) =>
        projectedOrActualCompletionDate,
      aggregate: (tasks) =>
        getDateRangeAggregate(tasks, "projectedOrActualCompletionDate"),
      width: 140,
    },
    {
      ...numberColumnDefaults,
      header: "Start Date Variance",
      id: "startDateVariance",
      category: "Task Details",
      tooltip:
        "The difference in business days, excluding holidays, between Projected / Actual Start Date and Original Start Date",
      value: ({ startDateVariance }) => startDateVariance,
      valueFormatter: (startDateVariance) =>
        startDateVariance === null
          ? null
          : `${formatNumber(startDateVariance)} days`,
      aggregate: (tasks) => getNumberRangeAggregate(tasks, "startDateVariance"),
      aggregateFormatter: (range) =>
        range === "-" ? range : `${formatNumber(range)} days`,
      width: 145,
    },

    {
      ...numberColumnDefaults,
      header: "Completion Date Variance",
      id: "completionDateVariance",
      category: "Task Details",
      tooltip:
        "The difference in business days, excluding holidays, between Projected / Actual Completion Date and Original Completion Date",
      value: ({ completionDateVariance }) => completionDateVariance,
      valueFormatter: (completionDateVariance) =>
        completionDateVariance === null
          ? null
          : `${formatNumber(completionDateVariance)} days`,
      aggregate: (tasks) =>
        getNumberRangeAggregate(tasks, "completionDateVariance"),
      aggregateFormatter: (range) =>
        range === "-" ? range : `${formatNumber(range)} days`,
      width: 140,
    },
  ];

  return !isReport
    ? defaultColumns
    : defaultColumns.concat([
        {
          ...stringColumnDefaults,
          header: "Project",
          hidden: !isReport,
          id: "project",
          category: "Project Information",
          groupable: true,
          value: ({ projectName }) => projectName,
          aggregate: (tasks) => getDefaultAggregate(tasks, "projectName"),
          width: 250,
        },
        {
          ...enumColumnDefaults,
          enumValues: orgEnumValues.teams,
          header: "Team",
          hidden:
            !isReport || !hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT),
          id: "team",
          category: "Project Information",
          groupable: true,
          value: ({ team }) => team,
          aggregate: (tasks) => getDefaultAggregate(tasks, "team"),
        },
        {
          ...enumColumnDefaults,
          enumValues: orgEnumValues.projectTemplates,
          header: "Project Type",
          hidden: !isReport,
          id: "projectType",
          category: "Project Information",
          groupable: true,
          value: ({ projectType }) => projectType,
          aggregate: (tasks) => getDefaultAggregate(tasks, "projectType"),
        },
        {
          ...enumColumnDefaults,
          enumValues: orgEnumValues.productTypes,
          header: "Product Type",
          hidden: !isReport,
          id: "productType",
          category: "Project Information",
          groupable: true,
          value: ({ productType }) => productType,
          aggregate: (tasks) => getDefaultAggregate(tasks, "productType"),
        },
        {
          ...enumColumnDefaults,
          enumValues: orgEnumValues.projectRegions,
          header: "Project Region",
          hidden: !isReport,
          id: "projectRegion",
          category: "Project Information",
          groupable: true,
          value: ({ projectRegion }) => projectRegion,
          aggregate: (tasks) => getDefaultAggregate(tasks, "projectRegion"),
        },
        {
          ...enumColumnDefaults,
          enumValues: Object.values(PROJECT_STATUS_TYPE).map((status) =>
            t(`projectStatus.${status}`)
          ),
          header: "Project Status",
          hidden: !isReport,
          id: "projectStatus",
          category: "Project Information",
          groupable: true,
          value: ({ projectStatus }) => projectStatus,
          aggregate: (tasks) => getDefaultAggregate(tasks, "projectStatus"),
          width: 120,
        },
      ]);
}

function getDefaultViews(isReport) {
  return isReport
    ? [
        {
          config: toBase64({
            columnConfig: [
              "milestone",
              "project",
              "owner",
              "status",
              "targetDate",
              "projectedOrActualStartDate",
              "expectedCompletion",
              "projectedOrActualCompletionDate",
              "completionDateVariance",
            ],
            filterConfig: [],
            groupConfig: {},
            sortConfig: {},
          }),
          isDefault: true,
          name: "Default",
        },
      ]
    : [
        {
          config: toBase64({
            columnConfig: [
              "milestone",
              "owner",
              "status",
              "targetDate",
              "projectedOrActualStartDate",
              "expectedCompletion",
              "projectedOrActualCompletionDate",
              "completionDateVariance",
            ],
            filterConfig: [
              { key: "status", enum: ["Not Started", "In Progress"] },
            ],
            groupConfig: {},
            sortConfig: {
              columnId: "projectedOrActualCompletionDate",
              direction: "asc",
            },
          }),
          isDefault: true,
          name: "Default",
        },
      ];
}

function getControls(
  propsControls,
  propsEditTableViews,
  isReport,
  rightControls = [],
  pinnedFilters
) {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      searchPlaceholder="Search Tasks..."
      isReport={isReport}
      pinnedFilters={pinnedFilters}
      rightControls={(defaultRightControls) => [
        defaultRightControls,
        ...rightControls,
      ]}
    />
  );
}

function prepareTasks(tasks) {
  return tasks.map((task) => ({
    ...task,
    projectedOrActualStartDate: task.actualStartDate || task.projectedStartDate,
    projectedOrActualCompletionDate:
      task.actualCompletionDate || task.projectedCompletionDate,
  }));
}

export function TasksTable({
  history,
  isReport,
  orgData,
  rightControls,
  selectedOrganization,
  tasks,
  pinnedFilters,
}) {
  const { hasPermission } = useContext(UserContext);

  const dependencyTrackingEnabled = hasPermission(
    PERMISSION_ACTION.DEPENDENCY_TRACKING
  );

  const columns = useMemo(() => {
    return getColumns(
      isReport,
      dependencyTrackingEnabled,
      orgData,
      hasPermission
    );
  }, [isReport, dependencyTrackingEnabled, orgData, hasPermission]);

  const defaultViews = useMemo(() => {
    return getDefaultViews(isReport);
  }, [isReport]);

  const preparedTasks = prepareTasks(tasks);

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(
        PERMISSION_ACTION.SAVE_TABLE_VIEWS,
        selectedOrganization
      )}
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={selectedOrganization.id}
      defaultViews={defaultViews}
      // keep legacy tableName for Tasks Report to maintain legacy saved views
      tableName={isReport ? "ReportsMilestonesTable" : "ProjectTasksTable"}
    >
      {(propsEditTableViews) => (
        <FastDataTable
          columns={columns}
          controls={(propsControls) =>
            getControls(
              propsControls,
              propsEditTableViews,
              isReport,
              rightControls,
              pinnedFilters
            )
          }
          items={preparedTasks}
          onClickRow={(task) =>
            history.push(
              `/projects/${task.projectId}/timeline/${task.id}${
                isReport ? "" : history.location.search
              }`
            )
          }
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(propsEditTableViews, "views.0.config")
          }
        />
      )}
    </EditTableViews>
  );
}
