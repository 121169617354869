import { useState, useEffect, useContext, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "components/materials";
import { UploadContext } from "helpers/behaviors";
import { STORAGE_KEYS } from "helpers/localStorageKeys";
import { v4 as uuid } from "uuid";
import { BorrowerDrawUploadFilesModal } from "./BorrowerDrawUploadFilesModal";
import { UPLOAD_FILE_TO_BORROWER_DRAW } from "./graphql";

function uploadFile(file, variables, mutation) {
  const id = uuid();

  mutation({
    variables: { ...variables, file, id },
  });
}

export function AddDocumentsButton({
  borrowerDrawId,
  borrowerDrawName,
  buttonProps,
  documentTemplates,
  openModal,
  projectId,
  documentType,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openModal) setOpen(true);
  }, [openModal]);

  const [upload] = useMutation(UPLOAD_FILE_TO_BORROWER_DRAW, {
    refetchQueries: ["BorrowerDrawFormQuery"],
  });

  const { setUploadProgress } = useContext(UploadContext);

  return (
    <Fragment>
      <Button
        {...buttonProps}
        purpose="add-document open"
        onClick={() => setOpen(true)}
        width={265}
      >
        Add Documents
      </Button>
      {open && (
        <BorrowerDrawUploadFilesModal
          containerProps={{
            "data-testid": "add-documents-modal",
          }}
          documentTemplates={documentTemplates}
          borrowerDrawId={borrowerDrawId}
          onCancel={() => {
            setOpen(false);
          }}
          onSubmit={({ files }) => {
            const filesVariables = {
              borrowerDrawId,
              projectId,
            };
            files.forEach((file) => {
              const variables = {
                ...filesVariables,
                pageCount: file.numberOfPages ? file.numberOfPages : 1,
                type: documentType,
              };
              uploadFile(file, variables, upload, setUploadProgress);
            });
            localStorage.setItem(STORAGE_KEYS.HIDE_TOAST, false);
            setOpen(false);
          }}
          projectId={projectId}
          title={`Add Documents to ${borrowerDrawName}`}
        />
      )}
    </Fragment>
  );
}
