import PropTypes from "prop-types";
import { Pane, Paragraph, Select } from "components/materials";
import { majorScale } from "helpers/utilities";
import { find } from "lodash";

export function OrganizationSelector({
  fallbackTitle,
  onOrganizationSelected,
  disabledOrganizations,
  organizations,
  selectedOrganization,
  title,
  ...props
}) {
  const disabledOrganizationIds = disabledOrganizations.map(({ id }) => id);
  if (organizations.length === 1) return fallbackTitle;

  return (
    <Pane display="flex" alignItems="center" {...props}>
      <Paragraph marginRight={majorScale(1)}>{title}</Paragraph>
      <Pane>
        <Select
          label="Organization"
          name="organizationId"
          value={selectedOrganization.id}
          onChange={(event) => {
            const orgId = event.target.value;
            const org = find(organizations, ({ id }) => id === orgId);
            onOrganizationSelected(org);
          }}
        >
          {organizations.map((org) => {
            return (
              <option
                value={org.id}
                key={org.id}
                disabled={disabledOrganizationIds.indexOf(org.id) !== -1}
              >
                {org.name}
              </option>
            );
          })}
        </Select>
      </Pane>
    </Pane>
  );
}

OrganizationSelector.propTypes = {
  fallbackTitle: PropTypes.string,
  title: PropTypes.node.isRequired,
  disabledOrganizations: PropTypes.array,
  organizations: PropTypes.array,
  onOrganizationSelected: PropTypes.func,
  selectedOrganization: PropTypes.object,
};

OrganizationSelector.defaultProps = {
  fallbackTitle: null,
  disabledOrganizations: [],
  organizations: [],
  onOrganizationSelected: () => {},
  selectedOrganization: undefined,
};
