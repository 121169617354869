import { TickIcon, WarningSignIcon } from "evergreen-ui";
import { Pane, Text, Tooltip } from "components/materials";
import {
  booleanColumnDefaults,
  currencyColumnDefaults,
  defaultSelectFormatter,
  FastDataTable,
  fractionColumnDefaults,
  primaryColumnDefaults,
  selectColumnDefaults,
  stringColumnDefaults,
  toBase64,
} from "components/materials/FastDataTable";
import { aggregateDocumentApprovalsData } from "helpers/documentHelpers";
import { sumBy } from "helpers/math";
import { formatDateTime } from "helpers/dateHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { PostPaymentTableStatus } from "./PostPaymentTableStatus";
import { ApprovalTooltipContent } from "../DocumentTable";

function getColumns(
  actionColumnFormatter,
  vendorPaymentTypeOptions,
  documentReviewers
) {
  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Documents",
      id: "document",
      value: (document) => document.file.name,
      valueFormatter: (fileName, document) => {
        return (
          <Pane display="flex">
            {document.warning && (
              <Pane>
                <Tooltip content={document.warning}>
                  <WarningSignIcon size={14} marginRight={majorScale(2)} />
                </Tooltip>
              </Pane>
            )}
            {fileName}
          </Pane>
        );
      },
      width: 250,
    },
    {
      ...stringColumnDefaults,
      header: "Document #",
      id: "documentNumber",
      value: (document) => document.number,
      width: 100,
    },
    {
      ...stringColumnDefaults,
      header: "Vendor",
      id: "vendorName",
      value: (document) => document.vendor.name,
      width: 150,
    },
    {
      ...fractionColumnDefaults,
      aggregate: (groupDocuments) =>
        groupDocuments.reduce(
          (acc, document) => {
            const {
              approvedCount,
              reviewersCount,
            } = aggregateDocumentApprovalsData(document, documentReviewers);

            return {
              numerator: acc.numerator + approvedCount,
              denominator: acc.denominator + reviewersCount,
            };
          },
          { numerator: 0, denominator: 0 }
        ),
      aggregateFormatter: ({ numerator, denominator }) =>
        denominator === 0 ? null : `${numerator} / ${denominator}`,
      disableAggregateExport: true,
      header: "Approval Status",
      id: "approval",
      value: (document) => {
        const {
          approvedCount,
          reviewersCount,
          approvedReviews,
          pendingReviewers,
        } = aggregateDocumentApprovalsData(document, documentReviewers);
        return {
          numerator: approvedCount,
          denominator: reviewersCount,
          approvedReviews,
          pendingReviewers,
        };
      },
      valueFormatter: (value) => {
        if (value === null) return null;
        const {
          numerator,
          denominator,
          approvedReviews,
          pendingReviewers,
        } = value;
        const showTooltip =
          approvedReviews.length > 0 || pendingReviewers.length > 0;
        const showCheckmark = numerator === denominator;
        const approvalRowContent = showCheckmark ? (
          <TickIcon color="green" />
        ) : (
          <Text size={300}>{`${numerator} / ${denominator}`}</Text>
        );
        return showTooltip ? (
          <Tooltip
            content={
              <ApprovalTooltipContent
                approvedReviews={approvedReviews}
                pendingReviewers={pendingReviewers}
              />
            }
          >
            {approvalRowContent}
          </Tooltip>
        ) : (
          `${numerator} / ${denominator}`
        );
      },
      valueExporter: (value) => {
        if (value === null) return null;
        const { numerator, denominator } = value;
        return `${numerator} / ${denominator}`;
      },
    },
    {
      ...selectColumnDefaults,
      disabled: (_, { postedAt }) => !!postedAt,
      header: "Vendor Payment Type",
      id: "vendorPaymentType",
      name: ({ id }) => `payments.${id}`,
      options: vendorPaymentTypeOptions,
      popoverMinWidth: 530,
      valueFormatter: (value, document, props) => {
        const testId = `vpt-${document.vendor.name}-${document.number}`;
        return defaultSelectFormatter(value, document, { ...props, testId });
      },
      width: 200,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (documents) => sumBy(documents, "amount"),
      aggregateFormatter: (documentsAmount, documents) => {
        return t("paymentIntegration.documentedAmountTotalRow", {
          amount: formatCurrency(documentsAmount),
          backupAmount: formatCurrency(
            sumBy(documents, ({ amount, isBackup }) => (isBackup ? amount : 0))
          ),
        });
      },
      header: "Documented Amount (Net)",
      id: "documentedAmount",
      value: (document) => document.amount,
      width: 125,
    },
    {
      ...booleanColumnDefaults,
      header: "Status",
      id: "status",
      value: (document) => !!document.postedAt,
      valueFormatter: (value, document) => (
        <PostPaymentTableStatus document={document} isPosted={value} />
      ),
      width: 100,
    },
    {
      ...stringColumnDefaults,
      header: "Posted At",
      id: "postedAt",
      value: (document) => formatDateTime(document.postedAt),
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (documents) => {
        return sumBy(documents, "paymentAmount");
      },
      header: "Payment Amount",
      id: "paymentAmount",
      value: (document) => document.paymentAmount,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (documents) => sumBy(documents, "paymentDueAmount"),
      header: "Payment Due",
      id: "paymentDue",
      value: (document) => document.paymentDueAmount,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (documents) => sumBy(documents, "toBePosted"),
      header: "To Be Posted",
      id: "toBePosted",
      value: (document) => document.toBePosted,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (documents) => sumBy(documents, "postedAmount"),
      header: "Posted",
      id: "posted",
      value: (document) => document.postedAmount,
      width: 125,
    },
    {
      ...(actionColumnFormatter && {
        ...stringColumnDefaults,
        header: "",
        id: "action",
        textAlign: "center",
        value: () => "",
        valueFormatter: actionColumnFormatter,
        width: 150,
      }),
    },
  ];
}

export function PostPaymentTable({
  actionColumnFormatter,
  documents,
  documentReviewers,
  tableConfig,
  vendorPaymentTypeOptions,
}) {
  const columns = getColumns(
    actionColumnFormatter,
    vendorPaymentTypeOptions,
    documentReviewers
  );

  return (
    <FastDataTable
      columns={columns}
      footerTotals
      getRowState={({ rowState }) => rowState}
      items={documents}
      serialized={toBase64(tableConfig)}
    />
  );
}
