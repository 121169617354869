import gql from "graphql-tag";

const PROJECT_PROJECTION_FRAGMENT = gql`
  fragment ProjectProjectionFragment on Project {
    id
    adjustmentsMostRecentlyUpdatedAt
    automaticAllocationEnabled
    usesOfFundsEnabled
    budgetMostRecentlyUpdatedAt
    expectedProjectLength
    startDate
    amount
    updatedAt
    projection {
      id
      updatedAt
      projectionLineItems {
        id
        lineItemId
        budget
        curveType
        duration
        monthlyProjectedAmounts
        startMonth
        updatedAt
      }
      projectionFundingSources {
        id
        fundingSourceId
        monthlyProjectedAmounts
        amount
        fundsRemaining
      }
    }
    actuals {
      drawNames
      lineItemActualAmounts
      fundingSourceActualAmounts
    }
    fundingSourceGroups {
      id
      amount
      disbursedAmount
      label
      type
      organization {
        id
        name
      }
    }
    divisions {
      id
      scopeId
      name
      lineItems {
        id
        scopeId
        budgetAmount
        name
      }
    }
  }
`;

export const QUERY = gql`
  query ProjectionQuery($projectId: String!) {
    project(id: $projectId) {
      ...ProjectProjectionFragment
    }
  }
  ${PROJECT_PROJECTION_FRAGMENT}
`;

export const FUNDING_SOURCE_PROJECTIONS_QUERY = gql`
  query FundingSourceProjectionsQuery(
    $projectId: String!
    $lineItemProjections: [LineItemProjectionsInput]
  ) {
    project(id: $projectId) {
      id
      fundingSourceProjections(lineItemProjections: $lineItemProjections) {
        fundingSourceId
        monthlyProjectedAmounts
      }
    }
  }
`;

export const SAVE_PROJECTION = gql`
  mutation SaveProjection(
    $projectId: String!
    $lineItems: [ProjectionLineItemInput]
    $actualsCount: Int!
  ) {
    saveProjection(
      projectId: $projectId
      lineItems: $lineItems
      actualsCount: $actualsCount
    ) {
      ...ProjectProjectionFragment
    }
  }
  ${PROJECT_PROJECTION_FRAGMENT}
`;

export const UPDATE_USER_VIEW_CONFIG = gql`
  mutation UpdateUserViewConfig($showProjectionCharts: Boolean!) {
    updateUserViewConfig(showProjectionCharts: $showProjectionCharts) {
      id
      showProjectionCharts
    }
  }
`;
