import { FastDataTable } from "components/materials/FastDataTable";
import { Pane, Text } from "components/materials";
import { majorScale } from "helpers/utilities";

export function SelectExistingOrganization({
  vendorCount,
  vendors,
  columns,
  onPageChanged,
}) {
  return (
    <Pane marginTop={majorScale(4)}>
      <Pane marginBottom={majorScale(2)}>
        <Text size={600}>Or, select an existing organization</Text>
      </Pane>
      <FastDataTable
        columns={columns}
        itemCountForPagination={vendorCount}
        items={vendors}
        pageSize={10}
        prepareOnFrontend={false}
        setPage={onPageChanged}
      />
    </Pane>
  );
}
