export const PROJECT_ICON = {
  AIRPLANE: "AIRPLANE",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  BRIEFCASE: "BRIEFCASE",
  BUILD: "BUILD",
  CALCULATOR: "CALCULATOR",
  CELL_TOWER: "CELL_TOWER",
  FLAG: "FLAG",
  GLASS: "GLASS",
  GLOBE: "GLOBE",
  HOME: "HOME",
  OFFICE: "OFFICE",
  OIL_FIELD: "OIL_FIELD",
  PEOPLE: "PEOPLE",
  SHOP: "SHOP",
  TAXI: "TAXI",
  TRAIN: "TRAIN",
  WALK: "WALK",
};
