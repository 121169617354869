import { Fragment, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Dropzone,
  FileViewer,
  Loadable,
  Modal,
  Pane,
  Tab,
  Tablist,
  Text,
} from "components/materials";
import { AGREEMENT_TYPE } from "helpers/enums";
import { minorScale, toaster } from "helpers/utilities";
import t from "helpers/translate";
import { UPLOAD_AND_ASSOCIATE_WITH_AGREEMENT } from "../graphql-queries";
import { CHANGE_ORDER_TYPES } from "../enums";

function ChangeOrderFileViewer({
  adjustmentDrawId,
  agreementType,
  changeOrderDocuments,
  uploadMutation,
}) {
  const [selectedTab, setSelectedTab] = useState(agreementType);
  useEffect(() => setSelectedTab(agreementType), [agreementType]);

  const [
    fileToUploadPendingDestination,
    setFileToUploadPendingDestination,
  ] = useState(null);

  const documentForSelectedTab = changeOrderDocuments.find(
    ({ type }) => type === selectedTab
  );

  return (
    <Fragment>
      <Tablist
        marginX={minorScale(3)}
        marginTop={minorScale(3)}
        marginBottom={minorScale(2)}
      >
        {ecoTab()}
        {pcoTab()}
        {exposureTab()}
      </Tablist>
      {documentForSelectedTab ? (
        <FileViewer file={documentForSelectedTab.file} />
      ) : (
        <Pane
          alignItems="center"
          display="flex"
          justifyContent="center"
          height="60%"
          padding="15px"
          width="100%"
        >
          <Dropzone
            allowMultiple={false}
            files={[]}
            onAdd={({ 0: file }) => {
              if (
                adjustmentDrawId &&
                selectedTab === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
              ) {
                setFileToUploadPendingDestination(file);
              } else {
                uploadMutation({
                  variables: { file, agreementType: selectedTab },
                });
              }
            }}
            uploadTypeButton="File"
            uploadTypeMain="document"
          />
          {fileToUploadPendingDestination !== null && (
            <Modal
              cancelLabel="Upload to Project"
              confirmLabel="Upload to Draw"
              hasClose={false}
              hasFooter
              onCancel={() =>
                uploadMutation({
                  variables: {
                    agreementType: selectedTab,
                    file: fileToUploadPendingDestination,
                  },
                })
              }
              onConfirm={() =>
                uploadMutation({
                  variables: {
                    agreementType: selectedTab,
                    drawId: adjustmentDrawId,
                    file: fileToUploadPendingDestination,
                  },
                })
              }
              open
              title="Upload Document"
            >
              <Text>{t("agreementsViewer.uploadDocumentDestination")}</Text>
            </Modal>
          )}
        </Pane>
      )}
    </Fragment>
  );

  function ecoTab() {
    if (agreementType !== AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER) return null;
    return (
      <Tab
        isSelected={selectedTab === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER}
        onSelect={() => setSelectedTab(AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER)}
      >
        Executed Change Order
      </Tab>
    );
  }

  function pcoTab() {
    if (agreementType === AGREEMENT_TYPE.EXPOSURE) return null;
    return (
      <Tab
        isSelected={selectedTab === AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER}
        onSelect={() => setSelectedTab(AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER)}
      >
        Potential Change Order
      </Tab>
    );
  }

  function exposureTab() {
    return (
      <Tab
        isSelected={selectedTab === AGREEMENT_TYPE.EXPOSURE}
        onSelect={() => setSelectedTab(AGREEMENT_TYPE.EXPOSURE)}
      >
        Exposure
      </Tab>
    );
  }
}

function OtherAgreementFileViewer({
  agreementType,
  agreementDocument,
  uploadMutation,
}) {
  return agreementDocument ? (
    <FileViewer file={agreementDocument.file} />
  ) : (
    <Pane
      alignItems="center"
      justifyContent="center"
      display="flex"
      height="60%"
      width="100%"
    >
      <Dropzone
        allowMultiple={false}
        files={[]}
        onAdd={({ 0: file }) =>
          uploadMutation({
            variables: { file, agreementType },
          })
        }
        uploadTypeButton="File"
        uploadTypeMain="document"
      />
    </Pane>
  );
}

export function AgreementsFileViewer({
  adjustmentDrawId,
  agreementDocument,
  agreementId,
  agreementType,
  changeOrderDocuments,
  refetchQueries,
}) {
  const [uploadMutation, { loading }] = useMutation(
    UPLOAD_AND_ASSOCIATE_WITH_AGREEMENT,
    {
      variables: { agreementId },
      refetchQueries,
      onCompleted: () =>
        toaster.success("Your document has been uploaded", { duration: 2.5 }),
      onError: () =>
        toaster.danger("Something went wrong. Please try again.", {
          duration: 2.5,
        }),
    }
  );

  if (loading) return <Loadable loading />;

  const sharedProps = {
    agreementType,
    uploadMutation,
  };
  return CHANGE_ORDER_TYPES.includes(agreementType) ? (
    <ChangeOrderFileViewer
      {...sharedProps}
      adjustmentDrawId={adjustmentDrawId}
      changeOrderDocuments={changeOrderDocuments}
    />
  ) : (
    <OtherAgreementFileViewer
      {...sharedProps}
      agreementDocument={agreementDocument}
    />
  );
}
