import { useState, useEffect, Fragment, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Accordion, Loadable, Pane, Sidebar } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { uniqBy } from "lodash";
import { Document } from "components/containers";
import { getSuperLineItemAggregates } from "helpers/lineItemTableHelpers";
import {
  SlideoutLineItemIssues,
  SuperLineItemIssuesAccordion,
} from "components/templates";
import { GUIDING_EXPLANATION_SCOPES } from "helpers/issues";
import { AdjustmentsOverlay } from "../../AdjustmentsOverlay";
import { LineItemSummarySection } from "./LineItemSummarySection";
import { LineItemDocuments } from "./LineItemDocuments";
import { LineItemAdjustments } from "./LineItemAdjustments";
import { LineItemAgreements } from "./LineItemAgreements";
import { LINE_ITEM_QUERY, SUPER_LINE_ITEM_QUERY } from "../graphql-queries";

export function BudgetLineItemSlideout({ history, match, onCloseComplete }) {
  const { params } = match;
  const { projectId, lineItemId } = params;

  const { data, loading } = useQuery(LINE_ITEM_QUERY, {
    variables: { lineItemId, projectId },
  });

  if (loading) return <Loadable loading />;

  return (
    <SlideoutContent
      project={data.project}
      lineItemId={lineItemId}
      history={history}
      match={match}
      onCloseComplete={onCloseComplete}
    />
  );
}

export function BudgetSuperLineItemSlideout({
  history,
  match,
  onCloseComplete,
}) {
  const { params } = match;
  const { projectId, superLineItemId } = params;

  const { data: superData, loading } = useQuery(SUPER_LINE_ITEM_QUERY, {
    variables: { superLineItemId, projectId },
    fetchPolicy: "no-cache",
  });

  if (loading) return <Loadable loading />;

  const preparedData = prepareSlideoutData(superData);

  return (
    <SlideoutContent
      project={preparedData.project}
      lineItemId={superLineItemId}
      history={history}
      match={match}
      onCloseComplete={onCloseComplete}
    />
  );
}

export function SlideoutContent({
  project,
  history,
  match,
  onCloseComplete,
  lineItemId,
}) {
  const { params } = match;
  const { projectId, documentId } = params;

  const { hasPermission } = useContext(UserContext);
  const hasRulesRedesignEnabled = hasPermission(
    PERMISSION_ACTION.RULES_REDESIGN_CLERICAL
  );
  const hasAgreementManagement = hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  );

  const [adjustmentsDrawId, setAdjustmentsDrawId] = useState(null);

  useEffect(
    () =>
      history.listen((_newLocation, action) => {
        if (!!adjustmentsDrawId && action === "POP") {
          setAdjustmentsDrawId(null);
        }
      }),
    // eslint-disable-next-line
    [adjustmentsDrawId]
  );

  function openAdjustmentsOverlay(drawId) {
    setAdjustmentsDrawId(drawId);
    history.push(history.location);
  }

  const lineItemName = project.lineItem.name;
  const divisionName = project.lineItem.division.name;
  const documentViewerDocuments = uniqBy(
    project.lineItem.expenses
      .map(({ document }) => document)
      .filter((document) => !!document),
    "id"
  );

  const panels = (hasRulesRedesignEnabled
    ? [
        {
          key: "issuesPanel",
          title: "Issues",
          content: (
            <Pane marginY={majorScale(2)}>
              {project.lineItem.isSuperLineItem ? (
                <SuperLineItemIssuesAccordion
                  scope={GUIDING_EXPLANATION_SCOPES.BUDGET_LINE_ITEM}
                  lineItem={project.lineItem}
                />
              ) : (
                <SlideoutLineItemIssues
                  lineItem={project.lineItem}
                  scope={GUIDING_EXPLANATION_SCOPES.BUDGET_LINE_ITEM}
                  showBlankSlate
                />
              )}
            </Pane>
          ),
        },
      ]
    : []
  )
    .concat([
      {
        key: "documentsPanel",
        title: "Documents",
        content: (
          <LineItemDocuments
            history={history}
            match={match}
            lineItem={project.lineItem}
          />
        ),
      },
      {
        key: "adjustmentsPanel",
        title: "Adjustments",
        content: (
          <LineItemAdjustments
            project={project}
            lineItemId={lineItemId}
            openAdjustmentsOverlay={openAdjustmentsOverlay}
          />
        ),
      },
    ])
    .concat(
      hasAgreementManagement
        ? [
            {
              key: "agreementsPanel",
              title: "Agreements",
              content: <LineItemAgreements project={project} />,
            },
          ]
        : []
    );

  // we temporarily close the sidebar if we are showing the doc viewer or adjustments viewer
  // the slidebar shows on top of those overlays otherwise
  const showSidebar = !!lineItemId && !documentId && !adjustmentsDrawId;

  return (
    <Fragment>
      {showSidebar && (
        <Sidebar isShown={showSidebar} onCloseComplete={onCloseComplete}>
          <Sidebar.Heading breadcrumbs={[divisionName, lineItemName]} />
          <Sidebar.Section>
            <LineItemSummarySection
              recentDrawLineItem={project.recentDraw?.lineItem}
              lineItem={project.lineItem}
            />
            <Accordion
              defaultActiveKeys={panels.map(({ key }) => key)}
              contentStyles={{
                paddingX: majorScale(3),
                paddingY: minorScale(3),
              }}
              headerStyles={{ size: 400 }}
              panels={panels}
              panelStyles={{ paddingY: minorScale(3) }}
            />
          </Sidebar.Section>
        </Sidebar>
      )}
      {adjustmentsDrawId && (
        <AdjustmentsOverlay
          drawId={adjustmentsDrawId}
          projectId={projectId}
          onClose={() => {
            setAdjustmentsDrawId(null);
            history.goBack();
          }}
        />
      )}
      {lineItemId && documentId && (
        <Document
          documents={documentViewerDocuments}
          history={history}
          match={match}
        />
      )}
    </Fragment>
  );
}

function prepareSlideoutData(data) {
  const { project } = data;

  const preparedRecentDraw = prepareRecentDraw(project);
  const preparedSuperLineItem = prepareSuperLineItem(project);
  const preparedDraws = prepareDraws(project);

  return {
    project: {
      ...project,
      recentDraw: preparedRecentDraw,
      lineItem: preparedSuperLineItem,
      draws: preparedDraws,
    },
  };
}

function prepareRecentDraw({ recentDraw }) {
  if (!recentDraw) return null;
  const { superLineItem } = recentDraw;
  return {
    ...recentDraw,
    lineItem: {
      ...superLineItem,
      ...getSuperLineItemAggregates(superLineItem.lineItems),
    },
  };
}

function prepareSuperLineItem({ superLineItem }) {
  return {
    ...superLineItem,
    ...getSuperLineItemAggregates(superLineItem.lineItems),
    isSuperLineItem: true,
  };
}

function prepareDraws({ draws }) {
  return draws.map((draw) => ({
    ...draw,
    lineItem: {
      ...draw.superLineItem,
      ...getSuperLineItemAggregates(draw.superLineItem.lineItems),
    },
  }));
}
