import { useState } from "react";
import { AddIcon } from "evergreen-ui";
import { StakeholdersModal } from "components/containers";
import { StakeholdersTable } from "components/templates";
import { Form, Link } from "components/materials";
import { majorScale } from "helpers/utilities";
import { get } from "lodash";
import t from "helpers/translate";

export function AddStakeholdersForm({
  deleteStakeholderGroup,
  disableEdits,
  projectId,
  setStakeholderIds,
  stakeholderIds,
  stakeholders,
}) {
  const [openStakeholdersModal, setOpenStakeholdersModal] = useState(false);

  const [selectedStakeholderGroup, setSelectedStakeholderGroup] = useState({});

  const closeModal = () => setOpenStakeholdersModal(false);

  return (
    <Form.Section
      heading="Send to"
      borderLeft="1px solid #d7e0ea"
      borderRight="1px solid #d7e0ea"
      action={
        !disableEdits ? (
          <Link
            color="blue"
            cursor="pointer"
            onClick={() => {
              setOpenStakeholdersModal(true);
            }}
            purpose="add-stakeholders open"
            size={300}
          >
            <AddIcon size={12} marginRight={majorScale(1)} />
            Add Project Stakeholder
          </Link>
        ) : null
      }
      padding={0}
    >
      {openStakeholdersModal && (
        <StakeholdersModal
          initialOrganizationId={get(selectedStakeholderGroup, "id", undefined)}
          initialRole={get(selectedStakeholderGroup, "role", undefined)}
          onClose={closeModal}
          projectId={projectId}
          getMissingEmailTooltipMessage={() =>
            t("submitDraw.recipientMissingEmail")
          }
          hasBorrowerExperience={false}
        />
      )}
      <StakeholdersTable
        deleteStakeholderGroup={deleteStakeholderGroup}
        disableEdits={disableEdits}
        projectId={projectId}
        setOpenStakeholdersModal={setOpenStakeholdersModal}
        setSelectedStakeholderGroup={setSelectedStakeholderGroup}
        setStakeholderIds={setStakeholderIds}
        stakeholderIds={stakeholderIds}
        stakeholders={stakeholders}
        requireEmail
        width="100%"
      />
    </Form.Section>
  );
}
