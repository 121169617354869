import { Card, Form, Pane, Paragraph } from "components/materials";
import { majorScale } from "helpers/utilities";

export function BorrowerDrawDetails({
  borrowerDraw,
  drawHasBeenSubmitted,
  organizationName,
}) {
  const { checklistItems } = borrowerDraw;
  return (
    <Card padding={majorScale(3)} marginLeft={majorScale(2)} width="50%">
      <Form>
        <Pane>
          <Paragraph color="#001F40" size={500}>
            Requested Amount
          </Paragraph>
          <Pane paddingTop={10}>
            <Form.Input
              disabled={drawHasBeenSubmitted}
              name="requestedAmount"
              textAlign="right"
              type="currency"
              width={280}
            />
          </Pane>
        </Pane>
        <Pane paddingTop={20}>
          <Paragraph color="#001F40" size={500}>
            Draw Checklist
          </Paragraph>
          <Pane>
            {checklistItems.map((item, key) => (
              <Form.Checkbox
                disabled={drawHasBeenSubmitted}
                key={key}
                name={`checklistItems.${key}.complete`}
                label={item.label}
              />
            ))}
          </Pane>
        </Pane>
        <Pane paddingTop={20}>
          <Paragraph color="#001F40" size={500}>
            Notes for {organizationName} (optional)
          </Paragraph>
          <Pane paddingTop={10}>
            <Form.TextArea
              disabled={drawHasBeenSubmitted}
              height={250}
              name="notes"
              size={300}
            />
          </Pane>
        </Pane>
      </Form>
    </Card>
  );
}
