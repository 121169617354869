import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Document } from "react-pdf";
import { useDropzone } from "react-dropzone";
import { CrossIcon } from "evergreen-ui";
import {
  Button,
  IconButton,
  Pane,
  Paragraph,
  RadioGroup,
  Spinner,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { uploadFile } from "images";
import { get } from "lodash";
import t from "helpers/translate";

const SPLIT_FILE_PAGE_LIMIT = 250;

export function Dropzone({
  allowMultiple,
  disabled,
  onAdd,
  restrictTypes,
  uploadTypeButton,
  uploadTypeMain,
  containerProps,
  textProps,
  files,
  isBorrowerDraw,
  onEdit,
  onRemove,
}) {
  const acceptableTypes = get(restrictTypes, "acceptableTypes");
  const restrictionMessage = get(restrictTypes, "restrictionMessage", "");
  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    onDrop: onAdd,
    multiple: allowMultiple,
    accept: acceptableTypes,
  });

  return (
    <Fragment>
      <div {...getRootProps()}>
        <Pane border="default" marginBottom={majorScale(2)}>
          <input {...getInputProps()} />
          <Pane
            alignItems="center"
            display="flex"
            justifyContent="center"
            paddingX={majorScale(2)}
            paddingY={majorScale(5)}
            {...containerProps}
          >
            <Pane is="img" src={uploadFile} maxHeight={majorScale(15)} />
            <Pane {...textProps}>
              <Paragraph size={400} textAlign="center">
                {t("dropzone.mainText", { type: uploadTypeMain })}
              </Paragraph>
              <Pane
                display="flex"
                justifyContent="center"
                marginTop={minorScale(1)}
              >
                <Button appearance="primary" disabled={disabled}>
                  {t("dropzone.buttonText", { type: uploadTypeButton })}
                </Button>
              </Pane>
              {restrictionMessage && (
                <Pane
                  marginTop={minorScale(1)}
                  display="flex"
                  justifyContent="center"
                >
                  <Paragraph size={300} color="muted">
                    {restrictionMessage}
                  </Paragraph>
                </Pane>
              )}
            </Pane>
          </Pane>
        </Pane>
      </div>
      {files.length > 0 && (
        <FileList
          files={files}
          isBorrowerDraw={isBorrowerDraw}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      )}
    </Fragment>
  );
}

Dropzone.propTypes = {
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  uploadTypeButton: PropTypes.string,
  uploadTypeMain: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  textProps: PropTypes.object,
};

Dropzone.defaultProps = {
  allowMultiple: true,
  uploadTypeMain: "documents",
  uploadTypeButton: "Files",
  textProps: { marginLeft: majorScale(8) },
};

function FileList({ files, isBorrowerDraw, onEdit, onRemove }) {
  return (
    <Fragment>
      {onEdit && (
        <Pane display="flex">
          <Pane width={460} />
          <Paragraph paddingX={5} textAlign="center" width={80}>
            {t("dropzone.pagesLabel")}
          </Paragraph>
          {!isBorrowerDraw && (
            <Paragraph textAlign="center" width={80}>
              {t("dropzone.singleDocumentLabel")}
            </Paragraph>
          )}
          {!isBorrowerDraw && (
            <Paragraph textAlign="center" width={80}>
              {t("dropzone.splitDocumentLabel")}
            </Paragraph>
          )}
        </Pane>
      )}
      {files.map((file, index) => (
        <FileRow
          file={file}
          index={index}
          isBorrowerDraw={isBorrowerDraw}
          key={`${index}${file.name}`}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ))}
    </Fragment>
  );
}

function FileRow({ file, index, isBorrowerDraw, onEdit, onRemove }) {
  const [documentSplitOption, setDocumentSplitOption] = useState("oneDocument");
  const isPDF = file.type === "application/pdf";
  const disableSplitSelection =
    file.numberOfPages === 1 ||
    file.numberOfPages > SPLIT_FILE_PAGE_LIMIT ||
    !isPDF;

  return (
    <Pane display="flex" alignItems="center">
      <Pane display="flex" justifyContent="center" width={50}>
        <IconButton
          appearance="minimal"
          icon={CrossIcon}
          onClick={() => onRemove(index)}
          type="button"
        />
      </Pane>
      <Paragraph width={410}>{file.name}</Paragraph>
      {onEdit && (
        <Fragment>
          <Pane textAlign="center" width={80}>
            {file.numberOfPages ? (
              <Text>{file.numberOfPages}</Text>
            ) : (
              file.type === "application/pdf" && (
                <Document
                  file={file}
                  loading={
                    <Pane display="flex" justifyContent="center">
                      <Spinner size={24} />
                    </Pane>
                  }
                  onLoadSuccess={({ numPages }) => {
                    onEdit(index, "numberOfPages", numPages);
                  }}
                />
              )
            )}
          </Pane>
          {(file.numberOfPages > 0 || !isPDF) && !isBorrowerDraw && (
            <Pane width={160}>
              <RadioGroup
                display="flex"
                justifyContent="space-around"
                value={documentSplitOption}
                options={[
                  {
                    value: "oneDocument",
                    isDisabled: disableSplitSelection,
                  },
                  {
                    value: "toBeSplit",
                    isDisabled: disableSplitSelection,
                  },
                ]}
                onChange={(event) => {
                  const { value } = event.target;
                  setDocumentSplitOption(value);
                  onEdit(index, "toBeSplit", value === "toBeSplit");
                }}
              />
            </Pane>
          )}
        </Fragment>
      )}
    </Pane>
  );
}

FileRow.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    numberOfPages: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
