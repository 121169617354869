import { v4 as uuid } from "uuid";
import gql from "graphql-tag";
import { cloneDeep, get, isNumber, omit } from "lodash";
import { dateFormToServer } from "helpers/dateHelpers";
import { CUSTOM_FIELD_TYPE } from "helpers/enums";
import unformatNumber from "helpers/unformatNumber";
import isBlank from "helpers/isBlank";
import { formatCurrency } from "helpers/formatCurrency";
import { divide } from "helpers/math";
import t from "helpers/translate";

export const UPDATE_ONBOARDING_DATA = gql`
  mutation UpdateOnboardingData($projectId: String!, $data: Data!) {
    updateOnboardingData(projectId: $projectId, data: $data) {
      id
      onboardingData
    }
  }
`;

export const STEPS = {
  BUDGET_DECISION: "BUDGET_DECISION",
  BUDGET_UPLOAD: "BUDGET_UPLOAD",
  CONFIRM_PROJECT_SETUP: "CONFIRM_PROJECT_SETUP",
  COPY_OR_BUILD_BUDGET: "COPY_OR_BUILD_BUDGET",
  DETAILS: "DETAILS",
  DEVELOPER_HAS_DRAWS: "DEVELOPER_HAS_DRAWS",
  DRAW_DECISION: "DRAW_DECISION",
  ENTER_BUDGET: "ENTER_BUDGET",
  FUNDING_SOURCES: "FUNDING_SOURCES",
  DEVELOPER_HAS_FUNDING_SOURCES: "DEVELOPER_HAS_FUNDING_SOURCES",
  LENDER_HAS_DRAWS: "LENDER_HAS_DRAWS",
  PREVIOUS_DRAW_NAMES: "PREVIOUS_DRAW_NAMES",
  PREVIOUS_DRAW_AMOUNTS: "PREVIOUS_DRAW_AMOUNTS",
  PROJECT_STATUS: "PROJECT_STATUS",
  TO_DATE_DRAW: "TO_DATE_DRAW",
};

export const PROJECT_STATUS = {
  FINALIZED_BUDGET: "FINALIZED_BUDGET",
  NO_BUDGET: "NO_BUDGET",
  UNFINISHED_BUDGET: "UNFINISHED_BUDGET",
};

function stringToFloat(value) {
  return isBlank(value) ? null : unformatNumber(value);
}

function cleanSuperLineItem(name) {
  if (isBlank(name)) return null;
  if (isNumber(name)) return name.toString();
  return name.trim();
}

function prepareProjectDetails(values) {
  const details = omit(values, "stateValue") || {};
  const stateValue = get(values, "stateValue");
  const startDate = get(values, "startDate");
  const customFields = get(values, "customFields", []);
  const expectedProjectLength = get(details, "expectedProjectLength");
  const squareFeet = get(details, "squareFeet");
  const acres = get(details, "acres");
  const drawUpdateSource = get(details, "drawUpdateSource");

  return {
    ...details,
    state: stateValue,
    startDate: startDate ? dateFormToServer(startDate) : null,
    expectedProjectLength: stringToFloat(expectedProjectLength),
    squareFeet: stringToFloat(squareFeet),
    acres: stringToFloat(acres),
    drawUpdateSource,
    retainagePercentage: divide(parseFloat(details.retainagePercentage), 100),
    ...(customFields.length > 0 && {
      customFields: values.customFields.map(({ id, type, value }) => {
        if (type === CUSTOM_FIELD_TYPE.BOOLEAN) {
          return { id, value: value.toString() };
        }
        return { id, value };
      }),
    }),
  };
}

/**
 * This is the data shaper used by the complete project setup useMutation.
 *
 * Changes to the budget upload spreadsheet or manual division and line item entry
 * need to be represented here.
 *
 */
function prepareBudget(divisions) {
  return divisions.map(
    ({
      id: divisionId,
      name: divisionName,
      position: divisionPosition,
      lineItems,
    }) => {
      const divisionLineItems = lineItems.map(
        ({
          id: lineItemId,
          masterFormatDivision,
          name: lineItemName,
          number,
          position: lineItemPosition,
          amount,
          superLineItem,
          retainagePercentage,
          lineItemType,
          lineItemCategories,
        }) => ({
          id: lineItemId,
          name: lineItemName,
          position: lineItemPosition,
          amount: formatCurrency(amount),
          masterFormatDivision,
          number,
          retainagePercentage,
          lineItemType,
          lineItemCategories,
          superLineItem: cleanSuperLineItem(superLineItem),
        })
      );
      return {
        id: divisionId,
        name: divisionName,
        position: divisionPosition,
        lineItems: divisionLineItems,
      };
    }
  );
}

export function prepareProject(values) {
  const {
    automaticAllocationEnabled,
    budget,
    details,
    draws,
    fundingSourceGroups,
    usesOfFundsEnabled,
  } = cloneDeep(values);

  return {
    automaticAllocationEnabled: automaticAllocationEnabled || false,
    budget: prepareBudget(budget.divisions),
    draws: draws || [],
    fundingSourceGroups: fundingSourceGroups || [],
    projectDetails: prepareProjectDetails(details),
    usesOfFundsEnabled: usesOfFundsEnabled || false,
  };
}

export function getBudgetHeaders(projectStatus, hasDraws) {
  if (hasDraws) {
    return {
      header: t("onboardingWizard.budgetDecision.drawsSent.header"),
      subheader: t("onboardingWizard.budgetDecision.drawsSent.subheader"),
    };
  }
  if (projectStatus === PROJECT_STATUS.NO_BUDGET) {
    return {
      header: t("onboardingWizard.budgetDecision.noBudget.header"),
      subheader: t("onboardingWizard.budgetDecision.noBudget.subheader"),
    };
  }
  if (projectStatus === PROJECT_STATUS.UNFINISHED_BUDGET) {
    return {
      header: t("onboardingWizard.budgetDecision.unfinishedBudget.header"),
    };
  }
  return {
    header: t("onboardingWizard.budgetDecision.finalizedBudget.header"),
    subheader: t("onboardingWizard.budgetDecision.finalizedBudget.subheader"),
  };
}

export const TEMPLATE_QUERY = gql`
  query OnboardingTemplateQuery($projectId: String!) {
    project(id: $projectId) {
      id
      organization {
        id
        projects {
          id
          name
          template {
            id
          }
        }
      }
    }
  }
`;

export function getMatchingProjectTypes(project) {
  if (!project) return [];
  const { projects } = project.organization;
  const currentProject = projects.find(({ id }) => id === project.id);
  return projects.filter(
    ({ id, template }) =>
      id !== get(currentProject, "id") &&
      template.id === get(currentProject, "template.id")
  );
}

export const RABBET_DEFAULT_BUDGET = [
  {
    id: uuid(),
    name: "Acquisition Costs",
    lineItems: [
      { id: uuid(), name: "Land Acquisition", amount: 0 },
      { id: uuid(), name: "Title Insurance", amount: 0 },
      { id: uuid(), name: "Appraisal", amount: 0 },
      { id: uuid(), name: "Financing/Closing Costs", amount: 0 },
      { id: uuid(), name: "Broker Fee", amount: 0 },
    ],
  },
  {
    id: uuid(),
    name: "Soft Costs",
    lineItems: [
      { id: uuid(), name: "Legal", amount: 0 },
      { id: uuid(), name: "Architect", amount: 0 },
      { id: uuid(), name: "Engineering", amount: 0 },
      { id: uuid(), name: "Civil Engineering", amount: 0 },
      { id: uuid(), name: "Interest Reserves", amount: 0 },
      { id: uuid(), name: "Surveying", amount: 0 },
      { id: uuid(), name: "Building Permit Fees", amount: 0 },
      { id: uuid(), name: "Environmental", amount: 0 },
      { id: uuid(), name: "Other Taxes", amount: 0 },
      { id: uuid(), name: "Real Estate Taxes", amount: 0 },
      { id: uuid(), name: "Construction Management", amount: 0 },
      { id: uuid(), name: "Consulting", amount: 0 },
      { id: uuid(), name: "Development Fee", amount: 0 },
      { id: uuid(), name: "Building Plan Check Fees", amount: 0 },
      { id: uuid(), name: "Inspections", amount: 0 },
      { id: uuid(), name: "Marketing", amount: 0 },
      { id: uuid(), name: "Permits", amount: 0 },
      { id: uuid(), name: "Accounting", amount: 0 },
      { id: uuid(), name: "Appraisal", amount: 0 },
      { id: uuid(), name: "Builder’s Risk Insurance", amount: 0 },
      { id: uuid(), name: "Insurance", amount: 0 },
      { id: uuid(), name: "Soft Cost Contingency", amount: 0 },
      { id: uuid(), name: "Misc.", amount: 0 },
    ],
  },
  {
    id: uuid(),
    name: "Hard Costs",
    lineItems: [
      { id: uuid(), name: "Carpentry", amount: 0 },
      { id: uuid(), name: "Concrete", amount: 0 },
      { id: uuid(), name: "Demolition", amount: 0 },
      { id: uuid(), name: "Electrical", amount: 0 },
      { id: uuid(), name: "Elevators", amount: 0 },
      { id: uuid(), name: "Painting", amount: 0 },
      { id: uuid(), name: "Finishes", amount: 0 },
      { id: uuid(), name: "Fire Systems", amount: 0 },
      { id: uuid(), name: "Foundation", amount: 0 },
      { id: uuid(), name: "Framing", amount: 0 },
      { id: uuid(), name: "Furniture, Fixtures and Equipment", amount: 0 },
      { id: uuid(), name: "General Conditions", amount: 0 },
      { id: uuid(), name: "General Requirements", amount: 0 },
      { id: uuid(), name: "HVAC", amount: 0 },
      { id: uuid(), name: "Landscaping", amount: 0 },
      { id: uuid(), name: "Lighting", amount: 0 },
      { id: uuid(), name: "Masonry", amount: 0 },
      { id: uuid(), name: "Openings", amount: 0 },
      { id: uuid(), name: "Plumbing", amount: 0 },
      { id: uuid(), name: "Roofing", amount: 0 },
      { id: uuid(), name: "Site Utilities", amount: 0 },
      { id: uuid(), name: "Sitework", amount: 0 },
      { id: uuid(), name: "Waterproofing", amount: 0 },
      { id: uuid(), name: "Hard Cost Contingency", amount: 0 },
    ],
  },
];
