import { Fragment } from "react";
import { Accordion } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import Comments from "./Comments";
import Documentation from "./Documentation";
import DocumentationBreakdown from "./DocumentationBreakdown";
import Issues from "./Issues";
import RequestSummary from "./RequestSummary";

export function DrawLineItemDetails({
  lineItem,
  commentForm,
  requestedAmountForm,
  masterFormatDivisionForm,
  numberForm,
  getDocumentPath,
  isSuperLineItem,
}) {
  const panels = [
    {
      key: "IssuesPanel",
      title: "Issues",
      content: <Issues isSuperLineItem={isSuperLineItem} lineItem={lineItem} />,
    },
    {
      key: "documentsPanel",
      title: "Documents",
      content: (
        <Documentation lineItem={lineItem} getDocumentPath={getDocumentPath} />
      ),
    },
    {
      key: "breakdownPanel",
      title: "Breakdown",
      content: <DocumentationBreakdown lineItem={lineItem} />,
    },
    ...(numberForm
      ? [
          {
            key: "numberPanel",
            title: "Budget - Line Item #",
            content: numberForm,
          },
        ]
      : []),
    ...(masterFormatDivisionForm
      ? [
          {
            key: "masterFormatPanel",
            title: "Budget - Master Format Division",
            content: masterFormatDivisionForm,
          },
        ]
      : []),
    {
      content: <Comments lineItem={lineItem} commentForm={commentForm} />,
      key: "commentsPanel",
      title: "Internal Comments",
    },
  ];

  return (
    <Fragment>
      <RequestSummary
        lineItem={lineItem}
        requestedAmountForm={requestedAmountForm}
        getDocumentPath={getDocumentPath}
      />
      <Accordion
        defaultActiveKeys={panels.map(({ key }) => key)}
        contentStyles={{ paddingX: majorScale(3), paddingY: minorScale(3) }}
        headerStyles={{ size: 400 }}
        panels={panels}
        panelStyles={{ paddingY: minorScale(3) }}
      />
    </Fragment>
  );
}
