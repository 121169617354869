import { Fragment } from "react";
import PropTypes from "prop-types";
import { Formik, FieldArray } from "formik";
import { AddIcon, CrossIcon } from "evergreen-ui";
import { isEmpty, noop, set } from "lodash";
import { Button, Grid, IconButton, Form, Pane } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { DATE_RANGE } from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import {
  filterRequiresInput,
  filterShape,
  getColumnHeaderText,
} from "../FastDataTableUtils";

const newFilter = () => ({ key: null });

function FiltersForm({
  close,
  dataColumns: columns,
  filterConfig,
  items,
  onSubmit,
  pinnedFilters = [],
  removeFilter,
}) {
  const filterConfigIsSearch = filterConfig.filter(
    ({ __isSearch }) => __isSearch
  );

  const filterConfigIsNotSearch = filterConfig.filter(
    ({ __isSearch }) => !__isSearch
  );

  const pinnedColumns = pinnedFilters
    .map((id) => columns.find((column) => column.id === id))
    .filter((column) => !!column);

  const otherColumns = columns.filter(
    (column) => !pinnedFilters.includes(column.id)
  );

  const filterableColumns = [...pinnedColumns, ...otherColumns].filter(
    (column) =>
      column.filterControl &&
      column.filterFormatter &&
      column.filterStrategy &&
      !column.hidden
  );

  const options = filterableColumns.map((column) => ({
    key: column.id,
    text: getColumnHeaderText(column),
    value: column.id,
  }));

  const renderFilterControl = (index, form) => {
    const column = filterableColumns.find(
      (column) => column.id === form.values.filters[index].key
    );
    return column && column.filterControl(index, form, column, items);
  };

  const validate = ({ filters }) => {
    const errors = {};
    filters.forEach((filter, index) => {
      const enumFilterBlank = isBlank(filter.enum);
      const listFilterBlank = isBlank(filter.list);
      const inputFilterBlank =
        isBlank(filter.operator) ||
        (filterRequiresInput(filter) && isBlank(filter.input));

      const dateFilterBlank = (() => {
        switch (filter.type) {
          // "legacy" values from saved views are custom ranges w/o a type defined
          case undefined:
          case DATE_RANGE.CustomRange:
            return isBlank(filter.start) || isBlank(filter.finish);
          case DATE_RANGE.MonthToDate:
          case DATE_RANGE.QuarterToDate:
          case DATE_RANGE.Today:
          case DATE_RANGE.WeekToDate:
          case DATE_RANGE.YearToDate:
          case DATE_RANGE.Yesterday:
            return false;
          default:
            return isBlank(filter.value);
        }
      })();

      if (isBlank(filter.key)) {
        set(errors, `filters.${index}.key`, "Please select a field");
      } else if (
        enumFilterBlank &&
        listFilterBlank &&
        dateFilterBlank &&
        inputFilterBlank
      ) {
        set(errors, `filters.${index}.key`, "Please enter a criteria");
      }
    });
    return errors;
  };

  const renderForm = (form) => (
    <Pane
      autoComplete="off"
      is="form"
      onReset={form.handleReset}
      onSubmit={form.handleSubmit}
      padding={majorScale(2)}
      maxHeight={450}
      overflowY="scroll"
    >
      <FieldArray name="filters">
        {({ remove, push }) => (
          <Fragment>
            <Grid>
              {form.values.filters.map((_filter, index) => (
                <Grid.Row key={`${index}:${form.values.filters[index].key}`}>
                  <Grid.Column columns={6}>
                    <Form.Select
                      width={225}
                      name={`filters.${index}.key`}
                      onChange={(key) => {
                        form.setFieldValue(`filters.${index}`, { key });
                      }}
                      options={options}
                      placeholder="Select Field..."
                    />
                  </Grid.Column>
                  <Grid.Column columns={9}>
                    {renderFilterControl(index, form)}
                  </Grid.Column>
                  <Grid.Column columns={1}>
                    <IconButton
                      marginTop={minorScale(2)}
                      appearance="minimal"
                      icon={CrossIcon}
                      onClick={() =>
                        removeFilter(form, index, remove, newFilter)
                      }
                      type="button"
                    />
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
            <Button
              marginTop={minorScale(1)}
              type="button"
              appearance="minimal"
              iconBefore={AddIcon}
              purpose="tables filter-form add-filter"
              onClick={() => push(newFilter())}
            >
              Add Filter
            </Button>
          </Fragment>
        )}
      </FieldArray>
      <Pane textAlign="right" marginTop={majorScale(2)}>
        <Button
          purpose="tables filter-form cancel"
          type="reset"
          marginRight={majorScale(2)}
        >
          Cancel
        </Button>
        <Button
          purpose="tables filter-form apply"
          type="submit"
          appearance="primary"
        >
          Apply
        </Button>
      </Pane>
    </Pane>
  );

  return (
    <Formik
      initialValues={{
        filters: isEmpty(filterConfigIsNotSearch)
          ? [newFilter()]
          : filterConfigIsNotSearch,
      }}
      onReset={close}
      validate={validate}
      onSubmit={onSubmit(filterConfigIsSearch)}
      render={renderForm}
    />
  );
}

FiltersForm.propTypes = {
  columns: PropTypes.array,
  filterBy: PropTypes.func,
  filterConfig: PropTypes.arrayOf(filterShape),
  items: PropTypes.array,
  marginRight: PropTypes.number,
};

FiltersForm.defaultProps = {
  columns: [],
  items: [],
  marginRight: 0,
  filterBy: noop,
};

export default FiltersForm;
