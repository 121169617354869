import PropTypes from "prop-types";

export function Slider({ onChange, value }) {
  return (
    <input
      onChange={(event) => {
        onChange(event.target.value);
      }}
      type="range"
      value={value}
    />
  );
}

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
