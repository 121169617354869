import { first, get, isEqual, isFunction, last, sortBy } from "lodash";
import { add, divide, multiply } from "helpers/math";
import { compareAsc, formatDateTimeAsDate, formatDate } from "./dateHelpers";

const evaluate = (item, predicate, ...args) =>
  isFunction(predicate) ? predicate(item, ...args) : get(item, predicate);

export const getDefaultAggregate = (groupItems, predicate, ...args) => {
  if (!groupItems || groupItems.length === 0) return "-";

  const getValue = (item) => evaluate(item, predicate, ...args);
  const checkValue = getValue(groupItems[0]);

  if (groupItems.length === 1) return checkValue || "-";

  return groupItems.every((item) => isEqual(getValue(item), checkValue)) &&
    !!checkValue
    ? checkValue
    : "-";
};

export const getDateRangeAggregate = (groupItems, predicate) => {
  const orderedDates = groupItems
    .map((item) => evaluate(item, predicate))
    .filter((value) => !!value)
    .sort((dateA, dateB) => compareAsc(dateA, dateB));

  if (orderedDates.length === 0) return "-";

  if (orderedDates.every((date) => isEqual(date, first(orderedDates))))
    return `${formatDate(first(orderedDates))}`;

  return `${formatDate(first(orderedDates))} - ${formatDate(
    last(orderedDates)
  )}`;
};

export const getDateTimeRangeAggregate = (groupItems, path) => {
  const filteredItems = groupItems.filter((item) => !!item[path]);
  if (filteredItems.length === 0) return "-";
  const orderedItems = sortBy(filteredItems, path);

  const checkValue = formatDateTimeAsDate(first(orderedItems)[path]);
  if (
    orderedItems.every((item) =>
      isEqual(formatDateTimeAsDate(item[path]), checkValue)
    )
  )
    return `${formatDateTimeAsDate(first(orderedItems)[path])}`;

  return orderedItems.length === 1
    ? `${formatDateTimeAsDate(first(orderedItems)[path])}`
    : `${formatDateTimeAsDate(
        first(orderedItems)[path]
      )} - ${formatDateTimeAsDate(last(orderedItems)[path])}`;
};

export const getNumberRangeAggregate = (groupItems, predicate) => {
  const filteredItems = groupItems.filter((item) => {
    const value = evaluate(item, predicate);
    return !!value || value === 0;
  });
  if (filteredItems.length === 0) return "-";
  const orderedItems = sortBy(filteredItems, (item) =>
    evaluate(item, predicate)
  );

  return orderedItems.length === 1
    ? `${evaluate(first(orderedItems), predicate)}`
    : `${evaluate(first(orderedItems), predicate)} - ${evaluate(
        last(orderedItems),
        predicate
      )}`;
};

export function getPercentAggregateFromPercents(groupItems, percentKey) {
  const percentsSum = groupItems.reduce((sum, item) => {
    return add(sum, item[percentKey]);
  }, 0);

  return divide(percentsSum, groupItems.length);
}

export function getPercentAggregate(
  groupItems,
  percentagePredicate,
  denominatorPredicate
) {
  const [numeratorTotal, denominatorTotal] = groupItems.reduce(
    ([numerator, denominator], item) => {
      const denominatorItem = evaluate(item, denominatorPredicate);
      const weightedPercentage = multiply(
        evaluate(item, percentagePredicate),
        denominatorItem
      );
      return [
        add(numerator, weightedPercentage),
        add(denominator, denominatorItem),
      ];
    },
    [0, 0]
  );
  return denominatorTotal === 0 ? 0 : divide(numeratorTotal, denominatorTotal);
}
