import { get, partition, uniqBy } from "lodash";
import { Badge, ListItem, Tooltip, UnorderedList } from "components/materials";
import t from "helpers/translate";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { ISSUES_COLORS } from "./colors";

export const HIGH_SEVERITY_ICON_PROPS = { icon: "error", color: "danger" };
export const NORMAL_SEVERITY_ICON_PROPS = {
  icon: "warning-sign",
  color: "warning",
};

export const GUIDING_EXPLANATION_SCOPES = {
  DOCUMENT: "Document",
  BUDGET_LINE_ITEM: "BudgetLineItem",
  DRAW_LINE_ITEM: "DrawLineItem",
};

export function getIssuesRowState(issues) {
  if (issues.length === 0) return undefined;

  if (issues.some(({ severity }) => severity === t("issues.severity.high"))) {
    return "error";
  }

  return "pending";
}

export function getIssueIconProps(issues) {
  if (issues.length === 0) return {};

  // High severity = red circle icon with exclamation mark
  if (issues.some(({ severity }) => severity === t("issues.severity.high")))
    return HIGH_SEVERITY_ICON_PROPS;

  // Normal severity = yellow triangle icon with exclamation mark
  return NORMAL_SEVERITY_ICON_PROPS;
}

export function getIssueIconType(issues) {
  if (issues.length === 0) return {};

  // High severity = red circle icon with exclamation mark
  if (issues.some(({ severity }) => severity === t("issues.severity.high")))
    return "highSeverityIcon";

  // Normal severity = yellow triangle icon with exclamation mark
  return "normalSeverityIcon";
}

// As more issues get added, the logic will expand / become more complex
// but for now, it's as easy as determining if an issue has a certain foreign key attached to it
export function getTabIssues(issues) {
  return {
    documents: issues.filter(({ documentId }) => !!documentId),
    lineItems: issues.filter(({ lineItemId }) => !!lineItemId),
  };
}

export function groupIssuesBySeverity(issues) {
  const [highSeverityIssues, normalSeverityIssues] = partition(
    issues,
    ({ severity }) => severity === t("issues.severity.high")
  );

  return { highSeverityIssues, normalSeverityIssues };
}

export function getIssuesFilterConfig(issues, issuesColumnKey = "issues") {
  return [
    {
      containsAnyInList: "true",
      key: issuesColumnKey,
      list: uniqBy(issues, "name").map(({ name }) =>
        t(`issues.badge.${name}.text`)
      ),
    },
  ];
}

export function getIssuesTableValue(item) {
  const uniqueIssues = uniqBy(get(item, "issues", []), "name");
  const issues = uniqueIssues.map(({ name }) => t(`issues.badge.${name}.text`));
  return issues.length > 0 ? issues.join(", ") : "";
}

export function getIssuesTableValueFormatter(
  lineItemIssues,
  item,
  { isGroupedValue },
  onBadgeClick
) {
  if (isGroupedValue) {
    return lineItemIssues;
  }
  // TODO: come up with custom filter strategy because splitting on commas is bug prone
  const issues = lineItemIssues ? lineItemIssues.split(", ") : [];
  if (issues.length === 0) return null;

  if (issues.length === 1) {
    const issue = item.issues[0];
    return (
      <Badge
        color={getIssueColor(issue.severity)}
        isInteractive={!!onBadgeClick}
        onClick={(e) => {
          if (onBadgeClick) {
            preventEventBubbling(e);
            onBadgeClick();
          }
        }}
      >
        {issues[0]}
      </Badge>
    );
  }

  return (
    <Tooltip
      content={
        <UnorderedList>
          {item.issues.map(({ id, name }) => {
            return (
              <ListItem key={id} color="white">
                {t(`issues.badge.${name}.text`)}
              </ListItem>
            );
          })}
        </UnorderedList>
      }
    >
      <Badge
        color={ISSUES_COLORS.MULTIPLE_ISSUES}
        isInteractive={!!onBadgeClick}
        onClick={(e) => {
          preventEventBubbling(e);
          onBadgeClick && onBadgeClick(item.issues);
        }}
      >
        {t("issues.badge.multipleIssues.text")}
      </Badge>
    </Tooltip>
  );
}

export function getIssueColor(severity) {
  if (severity === t("issues.severity.high")) {
    return ISSUES_COLORS.HIGH;
  }
  return ISSUES_COLORS.NORMAL;
}
