import { get, mapValues, set } from "lodash";
import { add, divide, subtract, multiply } from "helpers/math";
import isBlank from "helpers/isBlank";
import { parseCurrencyToFloat } from "helpers/parseCurrencyToFloat";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";

export function getNetAmount(columnB, columnC, previousAmount) {
  const sanitizedColumnB = parseCurrencyToFloat(columnB);
  const sanitizedColumnC = parseCurrencyToFloat(columnC);

  return isBlank(sanitizedColumnC)
    ? subtract(sanitizedColumnB, previousAmount)
    : subtract(sanitizedColumnC, previousAmount);
}

export function getNetPercentage(columnA, columnB, columnC) {
  const sanitizedColumnA = parseCurrencyToFloat(columnA);
  const sanitizedColumnB = parseCurrencyToFloat(columnB);
  const sanitizedColumnC = parseCurrencyToFloat(columnC);

  return isBlank(sanitizedColumnC)
    ? (divide(sanitizedColumnB, sanitizedColumnA, 4) * 100).toFixed(2)
    : (divide(sanitizedColumnC, sanitizedColumnA, 4) * 100).toFixed(2);
}

export function prepareValuesForValidation(values) {
  const preparedBreakdownItems = mapValues(
    values.breakdownItems,
    (breakdownItemValues) => ({
      ...breakdownItemValues,
      columnA: parseCurrencyToFloat(breakdownItemValues.columnA),
      columnB: parseCurrencyToFloat(breakdownItemValues.columnB),
      columnC: parseCurrencyToFloat(breakdownItemValues.columnC),
    })
  );
  return {
    ...values,
    requisitionAmount: parseCurrencyToFloat(values.requisitionAmount),
    breakdownItems: preparedBreakdownItems,
    page2Values: mapValues(values.page2Values, (page2ItemValues) => ({
      ...page2ItemValues,
      columnA: parseCurrencyToFloat(page2ItemValues.columnA),
      columnB: parseCurrencyToFloat(page2ItemValues.columnB),
      columnC: parseCurrencyToFloat(page2ItemValues.columnC),
    })),
    ...Object.values(preparedBreakdownItems).reduce(
      (acc, { columnA, columnB, columnC }) => {
        const hasColumnC = !isBlank(columnC);
        return {
          columnASubtotal: add(acc.columnASubtotal, columnA),
          columnBSubtotal: add(acc.columnBSubtotal, columnB),
          columnCSubtotal: add(
            acc.columnCSubtotal,
            hasColumnC ? columnC : columnB
          ),
          hasColumnCBreakdownItem: acc.hasColumnCBreakdownItem || hasColumnC,
        };
      },
      {}
    ),
  };
}

export function setErrorsForLineItemMatchesCurrentBudget(
  drawLineItem,
  breakdownValues,
  errors,
  fieldName
) {
  if (
    drawLineItem &&
    drawLineItem.budgetAmount > 0 &&
    breakdownValues.columnA !== drawLineItem.budgetAmount
  ) {
    set(
      errors,
      `breakdownItems.[${fieldName}].columnA`,
      `${
        breakdownValues.humanReadableName
      } does not match current budget (${formatCurrency(
        drawLineItem.budgetAmount
      )})`
    );
  }
}

export function setErrorsForAmountCompleteDecreased(
  drawLineItem,
  breakdownValues,
  errors,
  fieldName
) {
  if (!drawLineItem) return;
  if (
    isBlank(breakdownValues.columnC) &&
    breakdownValues.columnB < drawLineItem.requestedPreviouslyAmount
  ) {
    set(
      errors,
      `breakdownItems.[${fieldName}].columnB`,
      `Amount Complete (B) decreased (previous draw: ${formatCurrency(
        drawLineItem.requestedPreviouslyAmount
      )})`
    );
    return;
  }

  if (
    !isBlank(breakdownValues.columnC) &&
    breakdownValues.columnC < drawLineItem.requestedPreviouslyAmount
  ) {
    set(
      errors,
      `breakdownItems.[${fieldName}].columnC`,
      `Amount Complete (C) decreased (previous draw: ${formatCurrency(
        drawLineItem.requestedPreviouslyAmount
      )})`
    );
  }
}

export function setErrorsForColumnASubtotalMatchesTradeItemTotal(
  errors,
  values
) {
  if (
    values.columnASubtotal !==
    values.page2Values.subtotalOfBreakdownItems.columnA
  ) {
    set(
      errors,
      "page2Values.subtotalOfBreakdownItems.columnA",
      "Column A subtotal does not match the trade item total"
    );
  }
}

export function setErrorsForCurrentSubtotalMatchesTradeItemTotal(
  errors,
  values
) {
  const hasColumnCSubtotal = !isBlank(
    values.page2Values.subtotalOfBreakdownItems.columnC
  );

  if (
    (values.hasColumnCBreakdownItem || hasColumnCSubtotal) &&
    values.columnCSubtotal !==
      values.page2Values.subtotalOfBreakdownItems.columnC
  ) {
    set(
      errors,
      "page2Values.subtotalOfBreakdownItems.columnC",
      "Column C subtotal does not match the trade item total"
    );
    return;
  }
  if (
    !hasColumnCSubtotal &&
    values.columnBSubtotal !==
      values.page2Values.subtotalOfBreakdownItems.columnB
  ) {
    set(
      errors,
      "page2Values.subtotalOfBreakdownItems.columnB",
      "Column B subtotal does not match the trade item total"
    );
  }
}

// Builder's Overhead amount complete is not commensurate with trade completion (<percentage> = <recommended builders overhead for column B>)
// when Builder’s Overhead Column B / Column A does not equal the “Subtotal of Breakdown Items” Column B / Column A
export function setErrorsForBuildersOverheadIsCommensurateWithTradeCompletion(
  errors,
  values
) {
  const {
    columnA: columnASubtotal,
    columnB: columnBSubtotal,
    columnC: columnCSubtotal,
  } = values.page2Values.subtotalOfBreakdownItems;

  const {
    columnA: columnABuildersOverhead,
    columnB: columnBBuildersOverhead,
    columnC: columnCBuildersOverhead,
  } = values.page2Values.buildersOverhead;

  const hasColumnASubtotal = !isBlank(columnASubtotal);
  const hasColumnCSubtotal = !isBlank(columnCSubtotal);
  const hasColumnABuildersOverhead = !isBlank(columnABuildersOverhead);
  const hasColumnBBuildersOverhead = !isBlank(columnBBuildersOverhead);
  const hasColumnCBuildersOverhead = !isBlank(columnCBuildersOverhead);

  if (!hasColumnABuildersOverhead || !hasColumnASubtotal) return;

  if (hasColumnCSubtotal || hasColumnCBuildersOverhead) {
    const subtotalPercentComplete = divide(columnCSubtotal, columnASubtotal, 4);
    const buildersOverheadPercentComplete = divide(
      columnCBuildersOverhead,
      columnABuildersOverhead,
      4
    );

    const recommendedBuildersOverhead = Number(
      multiply(subtotalPercentComplete, columnABuildersOverhead).toFixed(2)
    );

    if (
      !hasColumnCBuildersOverhead ||
      buildersOverheadPercentComplete !== subtotalPercentComplete
    ) {
      set(
        errors,
        "page2Values.buildersOverhead.columnC",
        `Builder's Overhead amount complete is not commensurate with trade completion (${formatPercent(
          subtotalPercentComplete,
          null,
          2
        )} = ${formatCurrency(recommendedBuildersOverhead)})`
      );
    }
    return;
  }

  if (!hasColumnCBuildersOverhead) {
    const subtotalPercentComplete = divide(columnBSubtotal, columnASubtotal, 4);
    const buildersOverheadPercentComplete = divide(
      columnBBuildersOverhead,
      columnABuildersOverhead,
      4
    );
    const recommendedBuildersOverhead = Number(
      multiply(subtotalPercentComplete, columnABuildersOverhead).toFixed(2)
    );
    if (
      !hasColumnBBuildersOverhead ||
      buildersOverheadPercentComplete !== subtotalPercentComplete
    ) {
      set(
        errors,
        "page2Values.buildersOverhead.columnB",
        `Builder's Overhead amount complete is not commensurate with trade completion (${formatPercent(
          subtotalPercentComplete,
          null,
          2
        )} = ${formatCurrency(recommendedBuildersOverhead)})`
      );
    }
  }
}

// Builder's Profit amount complete is not commensurate with trade completion (<percentage> = <recommended builders profit for column B>)
// when Builder’s Profit Column B / Column A does not equal the “Subtotal of Breakdown Items” Column B / Column A
export function setErrorsForBuildersProfitIsCommensurateWithTradeCompletion(
  errors,
  values
) {
  const {
    columnA: columnASubtotal,
    columnB: columnBSubtotal,
    columnC: columnCSubtotal,
  } = values.page2Values.subtotalOfBreakdownItems;

  const {
    columnA: columnABuildersProfit,
    columnB: columnBBuildersProfit,
    columnC: columnCBuildersProfit,
  } = values.page2Values.buildersProfit;

  const hasColumnASubtotal = !isBlank(columnASubtotal);
  const hasColumnCSubtotal = !isBlank(columnCSubtotal);
  const hasColumnABuildersProfit = !isBlank(columnABuildersProfit);
  const hasColumnBBuildersProfit = !isBlank(columnBBuildersProfit);
  const hasColumnCBuildersProfit = !isBlank(columnCBuildersProfit);

  if (!hasColumnABuildersProfit || !hasColumnASubtotal) return;

  if (hasColumnCSubtotal || hasColumnCBuildersProfit) {
    const subtotalPercentComplete = divide(columnCSubtotal, columnASubtotal, 4);
    const buildersProfitPercentComplete = divide(
      columnCBuildersProfit,
      columnABuildersProfit,
      4
    );
    const recommendedBuildersProfit = Number(
      multiply(subtotalPercentComplete, columnABuildersProfit).toFixed(2)
    );

    if (
      !hasColumnCBuildersProfit ||
      buildersProfitPercentComplete !== subtotalPercentComplete
    ) {
      set(
        errors,
        "page2Values.buildersProfit.columnC",
        `Builder's Profit amount complete is not commensurate with trade completion (${formatPercent(
          subtotalPercentComplete,
          null,
          2
        )} = ${formatCurrency(recommendedBuildersProfit)})`
      );
    }
    return;
  }

  if (!hasColumnCBuildersProfit) {
    const subtotalPercentComplete = divide(columnBSubtotal, columnASubtotal, 4);
    const buildersProfitPercentComplete = divide(
      columnBBuildersProfit,
      columnABuildersProfit,
      4
    );
    const recommendedBuildersProfit = Number(
      multiply(subtotalPercentComplete, columnABuildersProfit).toFixed(2)
    );
    if (
      !hasColumnBBuildersProfit ||
      buildersProfitPercentComplete !== subtotalPercentComplete
    ) {
      set(
        errors,
        "page2Values.buildersProfit.columnB",
        `Builder's Profit amount complete is not commensurate with trade completion (${formatPercent(
          subtotalPercentComplete,
          null,
          2
        )} = ${formatCurrency(recommendedBuildersProfit)})`
      );
    }
  }
}

export function setErrorsForColumnATotalMatchesSubtotalPlusBuilders(
  errors,
  values
) {
  const columnATotal = add(
    values.page2Values.subtotalOfBreakdownItems.columnA,
    values.page2Values.buildersOverhead.columnA,
    values.page2Values.buildersProfit.columnA
  );
  if (columnATotal !== values.page2Values.totalOfCostBreakdownItems.columnA) {
    set(
      errors,
      "page2Values.totalOfCostBreakdownItems.columnA",
      `Column A total does not match (${formatCurrency(columnATotal)})`
    );
  }
}

export function setErrorsForCurrentTotalMatchesSubtotalPlusBuilders(
  errors,
  values
) {
  const hasColumnCSubtotal = !isBlank(
    values.page2Values.subtotalOfBreakdownItems.columnC
  );
  const hasColumnCTotal = !isBlank(
    values.page2Values.totalOfCostBreakdownItems.columnC
  );

  if (hasColumnCSubtotal || hasColumnCTotal) {
    const total = add(
      values.page2Values.subtotalOfBreakdownItems.columnC,
      values.page2Values.buildersOverhead.columnC,
      values.page2Values.buildersProfit.columnC
    );
    if (total !== values.page2Values.totalOfCostBreakdownItems.columnC) {
      set(
        errors,
        "page2Values.totalOfCostBreakdownItems.columnC",
        `Column C total does not match (${formatCurrency(total)})`
      );
    }
    return;
  }
  if (!hasColumnCTotal) {
    const total = add(
      values.page2Values.subtotalOfBreakdownItems.columnB,
      values.page2Values.buildersOverhead.columnB,
      values.page2Values.buildersProfit.columnB
    );
    if (total !== values.page2Values.totalOfCostBreakdownItems.columnB) {
      set(
        errors,
        "page2Values.totalOfCostBreakdownItems.columnB",
        `Column B total does not match (${formatCurrency(total)})`
      );
    }
  }
}

export function setErrorsForCostBreakdownPlusMaterialsMatch(errors, values) {
  const hasColumnCBreakdownTotal = !isBlank(
    values.page2Values.totalOfCostBreakdownItems.columnC
  );
  const hasColumnCSum = !isBlank(
    values.page2Values.sumOfCostBreakdownItems.columnC
  );

  if (hasColumnCBreakdownTotal || hasColumnCSum) {
    const total = add(
      values.page2Values.totalOfCostBreakdownItems.columnC,
      values.page2Values.materialsStoredOnsite.columnC,
      values.page2Values.materialsStoredOffsite.columnC
    );
    if (total !== values.page2Values.sumOfCostBreakdownItems.columnC) {
      set(
        errors,
        "page2Values.sumOfCostBreakdownItems.columnC",
        `Cost Breakdown Plus Materials does not match (${formatCurrency(
          total
        )})`
      );
    }
    return;
  }
  if (!hasColumnCSum) {
    const total = add(
      values.page2Values.totalOfCostBreakdownItems.columnB,
      values.page2Values.materialsStoredOnsite.columnB,
      values.page2Values.materialsStoredOffsite.columnB
    );
    if (total !== values.page2Values.sumOfCostBreakdownItems.columnB) {
      set(
        errors,
        "page2Values.sumOfCostBreakdownItems.columnB",
        `Cost Breakdown Plus Materials does not match (${formatCurrency(
          total
        )})`
      );
    }
  }
}

export function setErrorsForLessNetDecreaseShouldBeZero(errors, values) {
  const hasColumnCLessNetDecrease = !isBlank(
    values.page2Values.lessNetDecreaseInCost.columnC
  );
  const hasColumnBLessNetDecrease = !isBlank(
    values.page2Values.lessNetDecreaseInCost.columnB
  );

  if (
    hasColumnCLessNetDecrease &&
    values.page2Values.lessNetDecreaseInCost.columnC !== 0
  ) {
    set(
      errors,
      "page2Values.lessNetDecreaseInCost.columnC",
      "Less Net Decrease in Cost should be zero"
    );
    return;
  }

  if (
    hasColumnBLessNetDecrease &&
    values.page2Values.lessNetDecreaseInCost.columnB !== 0
  ) {
    set(
      errors,
      "page2Values.lessNetDecreaseInCost.columnB",
      "Less Net Decrease in Cost should be zero"
    );
  }
}

export function setErrorsForTotalAfterAdjustingMatches(errors, values) {
  const hasColumnCSum = !isBlank(
    values.page2Values.sumOfCostBreakdownItems.columnC
  );
  const hasColumnCTotalAfterAdjusting = !isBlank(
    values.page2Values.totalAfterAdjusting.columnC
  );

  if (hasColumnCSum || hasColumnCTotalAfterAdjusting) {
    const totalAfterAdjusting = add(
      values.page2Values.sumOfCostBreakdownItems.columnC,
      values.page2Values.lessNetDecreaseInCost.columnC
    );
    if (
      totalAfterAdjusting !== values.page2Values.totalAfterAdjusting.columnC
    ) {
      set(
        errors,
        "page2Values.totalAfterAdjusting.columnC",
        `Total After Adjusting does not match (${formatCurrency(
          totalAfterAdjusting
        )})`
      );
    }
    return;
  }
  if (!hasColumnCTotalAfterAdjusting) {
    const totalAfterAdjusting = add(
      values.page2Values.sumOfCostBreakdownItems.columnB,
      values.page2Values.lessNetDecreaseInCost.columnB
    );
    if (
      totalAfterAdjusting !== values.page2Values.totalAfterAdjusting.columnB
    ) {
      set(
        errors,
        "page2Values.totalAfterAdjusting.columnB",
        `Total After Adjusting does not match (${formatCurrency(
          totalAfterAdjusting
        )})`
      );
    }
  }
}

export function setErrorsForRetainageIsTenPercent(errors, values) {
  const retainagePercentage = 0.1;

  const hasColumnCRetainage = !isBlank(values.page2Values.lessRetained.columnC);
  const hasColumnCTotalAfterAdjusting = !isBlank(
    values.page2Values.totalAfterAdjusting.columnC
  );

  if (hasColumnCRetainage || hasColumnCTotalAfterAdjusting) {
    const recommendedRetainage = Number(
      multiply(
        values.page2Values.totalAfterAdjusting.columnC,
        retainagePercentage
      ).toFixed(2)
    );

    if (recommendedRetainage !== values.page2Values.lessRetained.columnC) {
      set(
        errors,
        "page2Values.lessRetained.columnC",
        `Retainage isn't 10% (${formatCurrency(recommendedRetainage)})`
      );
    }
    return;
  }

  if (!hasColumnCRetainage) {
    const recommendedRetainage = Number(
      multiply(
        values.page2Values.totalAfterAdjusting.columnB,
        retainagePercentage
      ).toFixed(2)
    );

    if (recommendedRetainage !== values.page2Values.lessRetained.columnB) {
      set(
        errors,
        "page2Values.lessRetained.columnB",
        `Retainage isn't 10% (${formatCurrency(recommendedRetainage)})`
      );
    }
  }
}

export function setErrorsForTotalAmountDueToDateMatches(errors, values) {
  const hasColumnCTotalAfterAdjusting = !isBlank(
    values.page2Values.totalAfterAdjusting.columnC
  );
  const hasColumnCDueToDate = !isBlank(
    values.page2Values.totalAmountDueToDate.columnC
  );

  if (hasColumnCTotalAfterAdjusting || hasColumnCDueToDate) {
    const totalAmountDue = subtract(
      values.page2Values.totalAfterAdjusting.columnC,
      values.page2Values.lessRetained.columnC
    );
    if (totalAmountDue !== values.page2Values.totalAmountDueToDate.columnC) {
      set(
        errors,
        "page2Values.totalAmountDueToDate.columnC",
        `Total Amount Due to Date does not match (${formatCurrency(
          totalAmountDue
        )})`
      );
    }
    return;
  }

  if (!hasColumnCDueToDate) {
    const totalAmountDue = subtract(
      values.page2Values.totalAfterAdjusting.columnB,
      values.page2Values.lessRetained.columnB
    );
    if (totalAmountDue !== values.page2Values.totalAmountDueToDate.columnB) {
      set(
        errors,
        "page2Values.totalAmountDueToDate.columnB",
        `Total Amount Due to Date does not match (${formatCurrency(
          totalAmountDue
        )})`
      );
    }
  }
}

export function setErrorsForLessPreviousPaymentsMatchPreviousAdvance(
  errors,
  values
) {
  const previousDrawTotalDueToDate =
    get(
      values,
      "draw.previousDraw.documents[0].lineItems.totalAmountDueToDate.complete"
    ) ||
    get(
      values,
      "draw.previousDraw.documents[0].lineItems.totalAmountDueToDate.hudFha"
    ) ||
    0;

  const hasColumnCLessPreviousPayments = !isBlank(
    values.page2Values.lessPreviousPayments.columnC
  );

  if (
    hasColumnCLessPreviousPayments &&
    values.page2Values.lessPreviousPayments.columnC !==
      previousDrawTotalDueToDate
  ) {
    set(
      errors,
      "page2Values.lessPreviousPayments.columnC",
      `Less Previous Payments doesn't match previous advance (${formatCurrency(
        previousDrawTotalDueToDate
      )})`
    );
    return;
  }

  if (
    values.page2Values.lessPreviousPayments.columnB !==
    previousDrawTotalDueToDate
  ) {
    set(
      errors,
      "page2Values.lessPreviousPayments.columnB",
      `Less Previous Payments doesn't match previous advance (${formatCurrency(
        previousDrawTotalDueToDate
      )})`
    );
  }
}

export function setErrorsForNetAmountOfThisRequisitionMatches(errors, values) {
  const hasColumnCTotalDueToDate = !isBlank(
    values.page2Values.totalAmountDueToDate.columnC
  );
  const hasColumnCNetAmount = !isBlank(values.page2Values.netAmount.columnC);

  if (hasColumnCTotalDueToDate || hasColumnCNetAmount) {
    const netAmount = subtract(
      values.page2Values.totalAmountDueToDate.columnC,
      values.page2Values.lessPreviousPayments.columnC
    );
    if (netAmount !== values.page2Values.netAmount.columnC) {
      set(
        errors,
        "page2Values.netAmount.columnC",
        `Net Amount of This Requisition does not match (${formatCurrency(
          netAmount
        )})`
      );
    }
    return;
  }

  if (!hasColumnCNetAmount) {
    const netAmount = subtract(
      values.page2Values.totalAmountDueToDate.columnB,
      values.page2Values.lessPreviousPayments.columnB
    );
    if (netAmount !== values.page2Values.netAmount.columnB) {
      set(
        errors,
        "page2Values.netAmount.columnB",
        `Net Amount of This Requisition does not match (${formatCurrency(
          netAmount
        )})`
      );
    }
  }
}

export function setErrorsForRequisitionAmountMatches(errors, values) {
  const hasColumnCNetAmount = !isBlank(values.page2Values.netAmount.columnC);
  if (
    hasColumnCNetAmount &&
    values.page2Values.netAmount.columnC !== values.requisitionAmount
  ) {
    set(
      errors,
      "page2Values.netAmount.columnC",
      `Requisition Amount doesn't match (${formatCurrency(
        values.requisitionAmount
      )})`
    );
    return;
  }

  if (values.page2Values.netAmount.columnB !== values.requisitionAmount) {
    set(
      errors,
      "page2Values.netAmount.columnB",
      `Requisition Amount doesn't match (${formatCurrency(
        values.requisitionAmount
      )})`
    );
  }
}
