import { useContext, useState, Fragment } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { StakeholdersModal } from "components/containers";
import { StakeholdersTable } from "components/templates";
import { Accordion, Button, Paragraph } from "components/materials";
import { majorScale } from "helpers/utilities";
import { flatten, get, uniqBy } from "lodash";
import t from "helpers/translate";
import { UserContext } from "helpers/behaviors";
import { STAKEHOLDERS_SETTINGS_FRAGMENT } from "helpers/fragments";
import {
  DOCUMENT_TYPE_NAME,
  FUNDING_SOURCE_TYPE,
  ORGANIZATION_TYPE,
  PERMISSION_ACTION,
} from "helpers/enums";

const DOCUMENTS_QUERY = gql`
  query EditProjectStakeholdersPanelDocumentsQuery($projectId: String!) {
    project(id: $projectId) {
      id
      documents {
        id
        type
        vendor {
          id
          name
        }
      }
    }
  }
`;

const USER_QUERY = gql`
  query EditProjectStakeholdersPanelUserQuery {
    user {
      id
      dismissedStakeholders {
        organizationId
        role
      }
    }
  }
`;

const DELETE = gql`
  mutation EditProjectStakeholdersPanelDelete(
    $organizationId: String!
    $projectId: String!
    $role: OrganizationType!
  ) {
    deleteStakeholderGroup(
      organizationId: $organizationId
      projectId: $projectId
      role: $role
    ) {
      id
      ...StakeholdersSettingsFragment
    }
  }
  ${STAKEHOLDERS_SETTINGS_FRAGMENT}
`;

const DISMISS_STAKEHOLDER = gql`
  mutation EditProjectStakeholdersPanelDismissStakeholder(
    $organizationId: String!
    $role: OrganizationType!
  ) {
    updateUsersDismissedStakeholders(
      organizationId: $organizationId
      role: $role
    ) {
      id
    }
  }
`;

const EditProjectStakeholdersPanel = ({
  fundingSourceGroups,
  borrowers,
  onToggle,
  panelKey,
  projectId,
  stakeholders,
  vendors,
  ...props
}) => {
  const { hasPermission } = useContext(UserContext);
  const [openStakeholdersModal, setOpenStakeholdersModal] = useState(false);

  const [selectedStakeholderGroup, setSelectedStakeholderGroup] = useState({});

  const [deleteStakeholderGroup] = useMutation(DELETE);

  const [dismissStakeholderGroup] = useMutation(DISMISS_STAKEHOLDER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: USER_QUERY,
      },
    ],
  });

  const { data } = useQuery(DOCUMENTS_QUERY, {
    variables: { projectId },
  });

  const documents = get(data, "project.documents", []);

  const { data: userData } = useQuery(USER_QUERY);

  const dismissedStakeholders = get(userData, "user.dismissedStakeholders", []);

  const fundingSources = flatten(fundingSourceGroups).map(
    ({ organization, type }) => {
      const role =
        type === FUNDING_SOURCE_TYPE.LOAN
          ? ORGANIZATION_TYPE.LENDER
          : ORGANIZATION_TYPE.EQUITY_PARTNER;
      return {
        role,
        organization,
      };
    }
  );

  const generalContractorsAndInspectors = documents.reduce(
    (accumulator, { vendor, type }) => {
      if (!get(vendor, "id")) return accumulator;
      if (type === DOCUMENT_TYPE_NAME.INSPECTION_REPORT) {
        accumulator.push({
          role: ORGANIZATION_TYPE.INSPECTOR,
          organization: vendor,
        });
      }
      return accumulator;
    },
    []
  );

  const organizationsToCheck = uniqBy(
    fundingSources.concat(generalContractorsAndInspectors),
    ({ role, organization }) => {
      return `${organization.id}-${role}`;
    }
  );

  const closeModal = () => {
    setOpenStakeholdersModal(false);
    setSelectedStakeholderGroup({});
  };

  const addEmptyOrganizationsToStakeholders = (
    stakeholders,
    organizationsToCheck
  ) => {
    const organizationsToAdd = organizationsToCheck.reduce(
      (accumulator, { organization, role }) => {
        const shouldAdd =
          !stakeholders.some(
            (stakeholder) =>
              stakeholder.organization.id === organization.id &&
              stakeholder.role === role
          ) &&
          !dismissedStakeholders.some(
            (dismissed) =>
              dismissed.organizationId === organization.id &&
              dismissed.role === role
          );
        if (shouldAdd) {
          accumulator.push({
            role,
            organization,
          });
        }
        return accumulator;
      },
      []
    );
    return stakeholders.concat(organizationsToAdd);
  };

  const stakeholdersAndEmptyOrganizations = organizationsToCheck.length
    ? addEmptyOrganizationsToStakeholders(stakeholders, organizationsToCheck)
    : stakeholders;

  const hasBorrowerExperience = hasPermission(
    PERMISSION_ACTION.BORROWER_PORTAL
  );

  return (
    <Fragment>
      <Accordion.Panel
        panelKey={panelKey}
        title="Stakeholders"
        onClick={() => onToggle(panelKey)}
        {...props}
      >
        <Paragraph>
          {stakeholders.length === 0
            ? t(`projectSettings.stakeholdersPanelEmpty`)
            : t(`projectSettings.stakeholdersPanel`)}
        </Paragraph>

        <Button
          appearance="primary"
          marginY={majorScale(2)}
          purpose="project-settings stakeholders open"
          onClick={() => setOpenStakeholdersModal(true)}
        >
          Add Stakeholder
        </Button>

        {openStakeholdersModal && (
          <StakeholdersModal
            onClose={closeModal}
            projectId={projectId}
            initialOrganizationId={get(
              selectedStakeholderGroup,
              "id",
              undefined
            )}
            initialRole={get(selectedStakeholderGroup, "role", undefined)}
            getMissingEmailTooltipMessage={(role) =>
              hasBorrowerExperience && role === ORGANIZATION_TYPE.BORROWER
                ? "The borrower must have an email address in order to access the project."
                : null
            }
            hasBorrowerExperience={hasBorrowerExperience}
          />
        )}

        {stakeholdersAndEmptyOrganizations.length > 0 && (
          <StakeholdersTable
            borrowers={borrowers}
            deleteStakeholderGroup={deleteStakeholderGroup}
            dismissStakeholderGroup={dismissStakeholderGroup}
            projectId={projectId}
            setOpenStakeholdersModal={setOpenStakeholdersModal}
            setSelectedStakeholderGroup={setSelectedStakeholderGroup}
            stakeholders={stakeholdersAndEmptyOrganizations}
            vendors={vendors}
            width="40%"
          />
        )}
      </Accordion.Panel>
    </Fragment>
  );
};

EditProjectStakeholdersPanel.propTypes = {
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  panelKey: PropTypes.string.isRequired,
  stakeholders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      member: PropTypes.shape({
        email: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      organization: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      role: PropTypes.string,
    })
  ),
};

export default EditProjectStakeholdersPanel;
