import { useContext, useMemo, Fragment } from "react";
import { ErrorIcon, TickCircleIcon } from "evergreen-ui";
import { EditTableViews } from "components/containers";
import {
  Heading,
  InterpolatedTranslation,
  Link,
  Pane,
  Progress,
  Shortener,
  Text,
  Tooltip,
} from "components/materials";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  COMPARATORS,
  FastDataTableDownloadDocuments,
  toBase64,
  booleanColumnDefaults,
  currencyColumnDefaults,
  listColumnDefaults,
  numberColumnDefaults,
  percentColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { UserContext } from "helpers/behaviors";
import {
  Position,
  ThemeContext,
  majorScale,
  minorScale,
} from "helpers/utilities";
import {
  formatCurrency,
  formatShortenedCurrency,
} from "helpers/formatCurrency";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { failedRules, hasFailedRules } from "helpers/ruleHelpers";
import { getDefaultAggregate } from "helpers/tableAggregateHelpers";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { getVendorLineItemPosition } from "helpers/lineItemTableHelpers";
import { divide, multiply, subtract, sumBy } from "helpers/math";
import { PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";
import { get, uniqBy } from "lodash";

function aggregateExpectedRetainge(vendorLineItems) {
  return divide(
    sumBy(vendorLineItems, (vli) =>
      multiply(
        vli.drawLineItem.retainagePercentage,
        vli.drawLineItem.budgetAmount
      )
    ),
    sumBy(vendorLineItems, "drawLineItem.budgetAmount")
  );
}

function aggregateActualRetainage(vendorLineItems) {
  return divide(
    sumBy(vendorLineItems, "drawLineItem.retainageAmount"),
    sumBy(vendorLineItems, "drawLineItem.grossRequestedAmount")
  );
}

const defaultViews = [
  {
    config: toBase64({
      columnConfig: [
        "vendorLineItems",
        "requestedAmount",
        "requestedAmountGC",
        "retainageAmountGC",
        "conditionalLienReleaseGC",
        "unconditionalLienReleaseGC",
        "requestedAmountSC",
        "retainageAmountSC",
        "conditionalLienReleaseSC",
        "unconditionalLienReleaseSC",
      ],
      filterConfig: [
        {
          input: formatCurrency(0),
          key: "requestedAmount",
          operator: COMPARATORS.GREATER_THAN.value,
        },
      ],
      groupConfig: { columnId: "lineItem", expanded: [] },
      sortConfig: { columnId: "position", direction: "asc" },
    }),
    isDefault: true,
    name: "Default",
  },
];

function getColumns({ baseUrl, hasPermission }) {
  const isSubmission = baseUrl.includes("/submissions/");

  function formatLink(value, vendorLineItem, documentId) {
    return (
      documentId &&
      value !== null && (
        <Pane onClick={preventEventBubbling}>
          <Link
            disabled={isSubmission}
            to={`${baseUrl}/line_items/${vendorLineItem.id}/documents/${documentId}?direct`}
          >
            {formatCurrency(value)}
          </Link>
        </Pane>
      )
    );
  }

  function lienReleaseAmount(lienReleases) {
    const hasLienRelease = lienReleases && lienReleases.length > 0;

    if (hasLienRelease) {
      return subtract(
        sumBy(lienReleases, "amount"),
        sumBy(lienReleases, "retainageAmount")
      );
    }

    return null;
  }

  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Vendor Line Items",
      id: "vendorLineItems",
      value: (vendorLineItem) => get(vendorLineItem, "vendor.name"),
      valueFormatter: (value, vendorLineItem) => {
        if (!value) {
          return "No documentation found";
        }

        return (
          <Pane display="flex" alignItems="center">
            {value}
            {hasFailedRules(vendorLineItem.drawLineItem) && (
              <Tooltip content={failedRules(vendorLineItem.drawLineItem)}>
                <ErrorIcon
                  size={10}
                  marginLeft={majorScale(1)}
                  color="danger"
                />
              </Tooltip>
            )}
          </Pane>
        );
      },
      width: 300,
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Line Item",
      id: "lineItem",
      value: (vendorLineItem) => get(vendorLineItem, "drawLineItem.name"),
      width: 300,
    },
    {
      ...numberColumnDefaults,
      header: "Position",
      hidden: true,
      id: "position",
      value: getVendorLineItemPosition,
    },
    {
      ...stringColumnDefaults,
      aggregate: (vendorLineItems) =>
        getDefaultAggregate(vendorLineItems, "vendor.vendorCostCode"),
      header: "Vendor ID",
      hidden: !hasPermission(PERMISSION_ACTION.VENDOR_COST_CODES),
      id: "vendorCostCode",
      value: (vendorLineItem) => get(vendorLineItem, "vendor.vendorCostCode"),
    },
    {
      ...listColumnDefaults,
      header: "Job Cost Codes",
      hidden: !hasPermission(PERMISSION_ACTION.JOB_COST_CODES),
      id: "jobCostCodes",
      value: (vendorLineItem) => {
        const codes = get(vendorLineItem, "jobCostCodes", []).map(
          (jobCostCode) => jobCostCode.code
        );
        return codes.join(", ");
      },
      width: 120,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(
          uniqBy(vendorLineItems, "drawLineItem.id"),
          "drawLineItem.grossRequestedAmount"
        ),
      exportValue: "-",
      header: "Current Amount Requested (Gross)",
      id: "grossRequestedAmount",
      value: (vendorLineItem) =>
        get(vendorLineItem, "drawLineItem.grossRequestedAmount"),
      valueFormatter: () => null,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(
          uniqBy(vendorLineItems, "drawLineItem.id"),
          "drawLineItem.requestedAmount"
        ),
      exportValue: "-",
      header: "Current Amount Requested (Net)",
      id: "requestedAmount",
      value: (vendorLineItem) =>
        get(vendorLineItem, "drawLineItem.requestedAmount"),
      valueFormatter: () => null,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(
          uniqBy(vendorLineItems, "drawLineItem.id"),
          "drawLineItem.retainageAmount"
        ),
      header: "Retainage",
      id: "retainageAmount",
      value: (vendorLineItem) =>
        get(vendorLineItem, "drawLineItem.retainageAmount"),
      valueFormatter: () => null,
    },
    {
      ...percentColumnDefaults,
      aggregate: (vendorLineItems) =>
        aggregateExpectedRetainge(vendorLineItems),
      header: "Expected Retainage %",
      id: "expectedRetainagePercent",
      value: (vendorLineItem) =>
        get(vendorLineItem, "drawLineItem.retainagePercentage"),
      valueFormatter: () => null,
    },
    {
      ...percentColumnDefaults,
      aggregate: (vendorLineItems) => aggregateActualRetainage(vendorLineItems),
      header: "Actual Retainage %",
      id: "actualRetainagePercent",
      value: (vendorLineItem) =>
        divide(
          get(vendorLineItem, "drawLineItem.retainageAmount"),
          get(vendorLineItem, "drawLineItem.grossRequestedAmount")
        ),
      valueFormatter: () => null,
    },
    {
      ...booleanColumnDefaults,
      aggregate: (vendorLineItems) =>
        vendorLineItems.every((vli) => vli.isGeneralContractor),
      aggregateFormatter: (value) =>
        value && <TickCircleIcon color="success" />,
      header: "GC",
      id: "isGC",
      value: (vendorLineItem) => vendorLineItem.isGeneralContractor,
      valueFormatter: (_, { isGeneralContractor }, { isGroupedValue }) => {
        if (isGroupedValue)
          return isGeneralContractor ? "General Contractor" : "Subcontractor";
        return isGeneralContractor && <TickCircleIcon color="success" />;
      },
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          vendorLineItem.isGeneralContractor
            ? vendorLineItem.requestedAmount
            : null
        ),
      header: "GC Amount Requested (Gross)",
      headerGrouping: "General Contractor",
      id: "grossRequestedAmountGC",
      value: (vendorLineItem) =>
        vendorLineItem.isGeneralContractor
          ? vendorLineItem.requestedAmount
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "invoices.0.document.id")
        ),
      width: 130,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          vendorLineItem.isGeneralContractor
            ? subtract(
                vendorLineItem.requestedAmount,
                vendorLineItem.retainageAmount
              )
            : null
        ),
      header: "GC Amount Requested (Net)",
      headerGrouping: "General Contractor",
      id: "requestedAmountGC",
      value: (vendorLineItem) =>
        vendorLineItem.isGeneralContractor
          ? subtract(
              vendorLineItem.requestedAmount,
              vendorLineItem.retainageAmount
            )
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "invoices.0.document.id")
        ),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          vendorLineItem.isGeneralContractor
            ? vendorLineItem.retainageAmount
            : null
        ),
      header: "Retainage",
      headerGrouping: "General Contractor",
      id: "retainageAmountGC",
      value: (vendorLineItem) =>
        vendorLineItem.isGeneralContractor
          ? vendorLineItem.retainageAmount
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "invoices.0.document.id")
        ),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          vendorLineItem.isGeneralContractor
            ? lienReleaseAmount(get(vendorLineItem, "conditionalLienWaivers"))
            : null
        ),
      header: "Conditional Lien",
      headerGrouping: "General Contractor",
      id: "conditionalLienReleaseGC",
      value: (vendorLineItem) =>
        vendorLineItem.isGeneralContractor
          ? lienReleaseAmount(get(vendorLineItem, "conditionalLienWaivers"))
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "conditionalLienWaivers.0.document.id")
        ),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          vendorLineItem.isGeneralContractor
            ? lienReleaseAmount(get(vendorLineItem, "unconditionalLienWaivers"))
            : null
        ),
      header: "Unconditional Lien",
      headerGrouping: "General Contractor",
      id: "unconditionalLienReleaseGC",
      value: (vendorLineItem) =>
        vendorLineItem.isGeneralContractor
          ? lienReleaseAmount(get(vendorLineItem, "unconditionalLienWaivers"))
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "unconditionalLienWaivers.0.document.id")
        ),
      width: 130,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          !vendorLineItem.isGeneralContractor
            ? vendorLineItem.requestedAmount
            : null
        ),
      header: "Subcontractor Amount Requested (Gross)",
      headerGrouping: "Subcontractor",
      id: "grossRequestedAmountSC",
      value: (vendorLineItem) =>
        !vendorLineItem.isGeneralContractor
          ? vendorLineItem.requestedAmount
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "invoices.0.document.id")
        ),
      width: 145,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          !vendorLineItem.isGeneralContractor
            ? subtract(
                vendorLineItem.requestedAmount,
                vendorLineItem.retainageAmount
              )
            : null
        ),
      header: "Subcontractor Amount Requested (Net)",
      headerGrouping: "Subcontractor",
      id: "requestedAmountSC",
      value: (vendorLineItem) =>
        !vendorLineItem.isGeneralContractor
          ? subtract(
              vendorLineItem.requestedAmount,
              vendorLineItem.retainageAmount
            )
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "invoices.0.document.id")
        ),
      width: 145,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          !vendorLineItem.isGeneralContractor
            ? vendorLineItem.retainageAmount
            : null
        ),
      header: "Retainage",
      headerGrouping: "Subcontractor",
      id: "retainageAmountSC",
      value: (vendorLineItem) =>
        !vendorLineItem.isGeneralContractor
          ? vendorLineItem.retainageAmount
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "invoices.0.document.id")
        ),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          !vendorLineItem.isGeneralContractor
            ? lienReleaseAmount(get(vendorLineItem, "conditionalLienWaivers"))
            : null
        ),
      header: "Conditional Lien",
      headerGrouping: "Subcontractor",
      id: "conditionalLienReleaseSC",
      value: (vendorLineItem) =>
        !vendorLineItem.isGeneralContractor
          ? lienReleaseAmount(get(vendorLineItem, "conditionalLienWaivers"))
          : null,
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "conditionalLienWaivers.0.document.id")
        ),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(vendorLineItems, (vendorLineItem) =>
          !vendorLineItem.isGeneralContractor
            ? lienReleaseAmount(get(vendorLineItem, "unconditionalLienWaivers"))
            : null
        ),
      header: "Unconditional Lien",
      headerGrouping: "Subcontractor",
      id: "unconditionalLienReleaseSC",
      value: (vendorLineItem) => {
        return !vendorLineItem.isGeneralContractor
          ? lienReleaseAmount(get(vendorLineItem, "unconditionalLienWaivers"))
          : null;
      },
      valueFormatter: (value, vendorLineItem) =>
        formatLink(
          value,
          vendorLineItem,
          get(vendorLineItem, "unconditionalLienWaivers.0.document.id")
        ),
      width: 130,
    },
  ];
}

export function DrawHardCostsTable({
  baseUrl,
  gcLienWaiver,
  gcName,
  gcPayApplication,
  hcBudgetAmount,
  hcContingencyBudget,
  hcContingencyRequestedThisDraw,
  hcContingencyRequestedToDate,
  hcRequestedThisDraw,
  hcRequestedToDate,
  history,
  onClickRow,
  projectId,
  vendorLineItems,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission, organizationId } = useContext(UserContext);
  const columns = useMemo(() => getColumns({ baseUrl, hasPermission }), [
    baseUrl,
    hasPermission,
  ]);

  const isSubmission = baseUrl.includes("/submissions/");
  const displayNoHardCostsMessage =
    !isSubmission && vendorLineItems.length === 0;

  return (
    <Fragment>
      <Pane display="flex" margin={majorScale(3)}>
        <Pane marginRight={majorScale(4)}>
          <Heading size={200}>HARD COSTS REQUESTED</Heading>
          <Shortener
            size={400}
            text={formatCurrency(hcRequestedThisDraw)}
            limit={25}
          />
        </Pane>
        <Pane marginRight={majorScale(4)}>
          <Heading size={200}>GENERAL CONTRACTOR</Heading>
          {gcName ? <Shortener size={400} text={gcName} limit={25} /> : "-"}
        </Pane>
        <Pane marginRight={majorScale(4)}>
          <Heading size={200}>GC PAY APPLICATION</Heading>
          {get(gcPayApplication, "document.file.name") ? (
            <Link
              disabled={isSubmission}
              to={`${baseUrl}/documents/${get(
                gcPayApplication,
                "document.id"
              )}`}
              purpose="hard-costs gc pay-app document"
            >
              <Shortener
                color={theme.colors.baseBlue}
                size={300}
                text={get(gcPayApplication, "document.file.name")}
                limit={25}
              />
            </Link>
          ) : (
            <Text>None</Text>
          )}
        </Pane>
        <Pane marginRight={majorScale(4)}>
          <Heading size={200}>GC LIEN WAIVER</Heading>
          {get(gcLienWaiver, "document.file.name") ? (
            <Link
              disabled={isSubmission}
              purpose="hard-costs gc lien-waiver document"
              to={`${baseUrl}/documents/${get(gcLienWaiver, "document.id")}`}
            >
              <Shortener
                color={theme.colors.baseBlue}
                size={300}
                text={get(gcLienWaiver, "document.file.name", "-")}
                limit={25}
              />
            </Link>
          ) : (
            <Text>None</Text>
          )}
        </Pane>
        <Pane marginRight={majorScale(4)} maxWidth={300} flexGrow={1}>
          <Tooltip
            position={Position.TOP}
            content={
              <Pane display="flex" flexDirection="column">
                <Text color="white">
                  Previous Draws:{" "}
                  {formatCurrency(
                    subtract(hcRequestedToDate, hcRequestedThisDraw)
                  )}
                </Text>
                <Text color="white">
                  Current Draw: {formatCurrency(hcRequestedThisDraw)}
                </Text>
              </Pane>
            }
          >
            <Pane>
              <Progress
                color="#8FBCE6"
                hasTwoPercentages
                marginBottom={minorScale(1)}
                secondColor="#1070CA"
                secondValue={subtract(hcRequestedToDate, hcRequestedThisDraw)}
                total={hcBudgetAmount}
                value={hcRequestedToDate}
              />
              <Progress.Label
                title="Hard Costs Progress"
                total={hcBudgetAmount}
                value={hcRequestedToDate}
                valueFormatter={formatShortenedCurrency}
              />
            </Pane>
          </Tooltip>
        </Pane>
        <Pane marginRight={majorScale(4)} maxWidth={300} flexGrow={1}>
          <Tooltip
            position={Position.TOP}
            content={
              <Pane display="flex" flexDirection="column">
                <Text color="white">
                  Previous Draws:{" "}
                  {formatCurrency(
                    subtract(
                      hcContingencyRequestedToDate,
                      hcContingencyRequestedThisDraw
                    )
                  )}
                </Text>
                <Text color="white">
                  Current Draw: {formatCurrency(hcContingencyRequestedThisDraw)}
                </Text>
              </Pane>
            }
          >
            <Pane>
              <Progress
                color="#8FBCE6"
                hasTwoPercentages
                marginBottom={minorScale(1)}
                secondColor="#1070CA"
                secondValue={subtract(
                  hcContingencyRequestedToDate,
                  hcContingencyRequestedThisDraw
                )}
                total={hcContingencyBudget}
                value={hcContingencyRequestedToDate}
              />
              <Progress.Label
                title="Hard Costs Contingency Used"
                total={hcContingencyBudget}
                value={hcContingencyRequestedToDate}
                valueFormatter={formatShortenedCurrency}
              />
            </Pane>
          </Tooltip>
        </Pane>
      </Pane>
      <EditTableViews
        canManagePublicViews={hasPermission(PERMISSION_ACTION.SAVE_TABLE_VIEWS)}
        config={getSearchByKey(history, "table")}
        organizationIdToScopeViews={organizationId}
        defaultViews={defaultViews}
        tableName="DrawHardCostsTable"
      >
        {(propsEditTableViews) => (
          <FastDataTable
            columns={columns}
            controls={(propsControls) => (
              <FastDataTableAdvancedControls
                {...propsControls}
                {...propsEditTableViews}
                disable={[FastDataTableDownloadDocuments]}
                searchPlaceholder="Search Organizations..."
              />
            )}
            empty={
              displayNoHardCostsMessage ? (
                <InterpolatedTranslation
                  fontSize={13}
                  string={t("drawLineItem.noHardCosts")}
                  values={[
                    <Link
                      fontSize={13}
                      to={`/projects/${projectId}/settings?settings=lineItemTypes`}
                      marginX={minorScale(1)}
                      purpose="hard-costs set-line-item-types"
                    >
                      project settings
                    </Link>,
                  ]}
                />
              ) : undefined
            }
            footerTotals
            items={vendorLineItems}
            onClickRow={onClickRow}
            onSerialize={(table) => mergeSearch(history, { table })}
            serialized={
              getSearchByKey(history, "table") ||
              get(propsEditTableViews, "views.0.config")
            }
          />
        )}
      </EditTableViews>
    </Fragment>
  );
}
