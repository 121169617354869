import { useContext, Fragment } from "react";
import { Banner, Form, Pane, Paragraph } from "components/materials";
import { dateServerToForm } from "helpers/dateHelpers";
import {
  AGREEMENT_TYPE,
  CHANGE_ORDER_REASON,
  PERMISSION_ACTION,
} from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import { add, subtract } from "helpers/math";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { get, sumBy } from "lodash";
import {
  AgreementForm,
  validateAgreementForm,
} from "components/containers/Agreements";
import DocumentReviewLayout from "./DocumentReviewLayout";

export function AgreementReview(props) {
  const { hasPermission } = useContext(UserContext);
  const {
    agreementVendorLineItems,
    document: { agreement },
    form,
    lineItems,
    vendors,
  } = props;

  const {
    agreementManagementDisabled,
    agreementLineItems,
    agreementType,
    isArtifact,
    projectId,
    promptUserToUpgrade,
  } = form.values;
  const hasAgreementManagement = !agreementManagementDisabled;

  const showInspectionNote =
    hasPermission(PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW) &&
    agreementType === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER;

  const informationSummary = [get(form.values, "vendor.name")]
    .filter((value) => !!value)
    .join(", ");
  return (
    <DocumentReviewLayout
      disableActivity={promptUserToUpgrade}
      disableDescription={promptUserToUpgrade}
      disableLineItemDrawer
      information={() => (
        <Pane width="100%">
          {isArtifact && hasAgreementManagement && (
            <Banner
              icon="infoSignIcon"
              mainText={t("documentsViewer.isArtifactAgreement", {
                artifactType: t(`agreementType.${agreementType}`),
              })}
              marginX={-majorScale(2)}
              marginTop={-majorScale(2)}
              marginBottom={majorScale(1)}
            />
          )}
          <AgreementForm
            agreement={agreement}
            agreementLineItems={agreementLineItems}
            agreementVendorLineItems={agreementVendorLineItems}
            formikProps={form}
            lineItems={lineItems}
            projectId={projectId}
            vendors={vendors}
          />
        </Pane>
      )}
      informationSummary={informationSummary}
      {...props}
    >
      {showInspectionNote && !promptUserToUpgrade && (
        <Form.Section
          heading="Inspector Notes"
          isCollapsible
          isCollapsed={props.collapsedPanels.includes("inspectorNotes")}
          onCollapse={() => props.togglePanel("inspectorNotes")}
        >
          <Fragment>
            <Paragraph marginBottom={majorScale(1)}>
              This note appears alongside the change order on the draw
              inspection report.
            </Paragraph>
            <Form.TextArea name="inspectionNote" />
          </Fragment>
        </Form.Section>
      )}
    </DocumentReviewLayout>
  );
}

// TODO: remove dot notation
AgreementReview.getInitialValues = ({
  document,
  hasPermission,
  selectedDraw,
  selectedType,
}) => {
  const agreementTypeHasChanged = document.type !== selectedType;

  const {
    agreement,
    id: documentId,
    project: { id: projectId, lineItems },
  } = document;

  const totalFromInvoices = sumBy(
    agreement?.trackedAgreementAmounts || [],
    "amount"
  );

  const existingAgreement = agreementTypeHasChanged ? null : agreement;

  const preparedLineItems = lineItems.map((lineItem) => {
    // this calculation is needed to show up-to-date current budget + adjustment = new budget info while editing
    // i.e. get the current budget without any of the adjustments (ali) that are on this change order
    const adjustmentsThisLineItemOnThisAgreementTotal = get(
      existingAgreement,
      "budgetAdjustment.transactions",
      []
    )
      .filter((transaction) => get(transaction, "lineItem.id") === lineItem.id)
      .reduce((total, { amount }) => add(total, amount), 0);

    return {
      ...lineItem,
      budgetAmount: subtract(
        lineItem.budgetAmount,
        adjustmentsThisLineItemOnThisAgreementTotal
      ),
    };
  });

  const agreementLineItems = existingAgreement
    ? existingAgreement.lineItems.map(
        ({ lineItemId, amount, retainagePercentage }) => ({
          lineItemId,
          amount: formatCurrency(amount),
          retainagePercentage: formatPercent(retainagePercentage),
          initialAmount: formatCurrency(amount),
          initialLineItemId: lineItemId,
        })
      )
    : [];

  const budgetAdjustmentTransactions = get(
    existingAgreement,
    "budgetAdjustment.transactions",
    []
  ).map((transaction) => ({
    ...transaction,
    amount: formatCurrency(transaction.amount),
  }));

  const agreementManagementDisabled = !hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  );

  const promptUserToUpgrade =
    !existingAgreement &&
    [
      AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
      AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER,
    ].includes(selectedType);

  return {
    projectId,
    agreementManagementDisabled,
    agreementId: get(existingAgreement, "id"),
    agreementType: selectedType,
    budgetAdjustmentDrawId: get(existingAgreement, "budgetAdjustment.drawId"),
    budgetAdjustmentTransactions,
    correlationId: get(existingAgreement, "correlationId", null),
    documentId,
    draw: selectedDraw,
    vendor: get(existingAgreement, "vendor") || { id: null },
    date: dateServerToForm(get(existingAgreement, "startDate")),
    agreementNumber: get(existingAgreement, "agreementNumber", ""),
    changeOrderReason: get(
      existingAgreement,
      "changeOrderReason",
      CHANGE_ORDER_REASON.UNSPECIFIED
    ),
    userTouchedName: get(existingAgreement, "userTouchedName"),
    daysImpacted: get(existingAgreement, "daysImpacted", ""),
    agreementDescription: get(existingAgreement, "agreementDescription", null),
    description: get(existingAgreement, "documentDescription", null),
    inspectionNote: get(existingAgreement, "inspectionNote", null),
    isArtifact: get(existingAgreement, "isArtifact", false),
    preparedLineItems,
    promptUserToUpgrade,
    agreementLineItems,
    totalFromInvoices,
    type: selectedType,
    name: get(existingAgreement, "name"),
  };
};

// TODO: remove dot notation
AgreementReview.validate = validateAgreementForm;
