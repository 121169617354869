import { cloneDeep, find, findIndex, get } from "lodash";
import isBlank from "helpers/isBlank";

export const EVENTS = { ADD: "add", DELETE: "delete", UPDATE: "update" };

export const OPERATIONS = {
  DELETE: "delete",
  UPSERT: "upsert",
};

export const ROW_TYPE = {
  DIVISION: "division",
  LINE_ITEM: "line_item",
};

export const DIRECTIONS = {
  UP: "up",
  DOWN: "down",
};

function moveDivisionRow(divisionsAndLineItems, id, direction) {
  const newData = cloneDeep(divisionsAndLineItems);

  const divisionCount = divisionsAndLineItems.length;
  const originalPosition = findIndex(divisionsAndLineItems, (d) => d.id === id);

  // Use max and min checks to avoid going past the lower and upper bound for division positions
  // (eg - trying to move the first division "up" even more)
  const targetPosition =
    direction === DIRECTIONS.UP
      ? Math.max(originalPosition - 1, 0)
      : Math.min(originalPosition + 1, divisionCount - 1);
  const divisionToUpdate = newData[originalPosition];

  newData.splice(originalPosition, 1);
  newData.splice(targetPosition, 0, divisionToUpdate);

  return newData.map((division, index) => {
    // Update the positions for a division
    return { ...division, position: index };
  });
}

function moveLineItemRow(divisionsAndLineItems, id, direction) {
  const newData = cloneDeep(divisionsAndLineItems);
  const originalDivision = find(divisionsAndLineItems, ({ lineItems }) => {
    return find(lineItems, (li) => li.id === id);
  });
  const originalPosition = findIndex(
    originalDivision.lineItems,
    (li) => li.id === id
  );

  const targetPosition =
    direction === DIRECTIONS.UP ? originalPosition - 1 : originalPosition + 1;

  const divisionToUpdate = find(newData, (d) => originalDivision.id === d.id);
  const targetLineItem = find(divisionToUpdate.lineItems, (li) => li.id === id);

  if (targetPosition === -1) {
    // Attempt to move the line item to the previous division
    const previousDivision = find(
      newData,
      (d) => d.position === divisionToUpdate.position - 1
    );

    if (previousDivision) {
      divisionToUpdate.lineItems.splice(originalPosition, 1);
      previousDivision.lineItems.splice(
        previousDivision.lineItems.length,
        0,
        targetLineItem
      );
    }
  } else if (targetPosition >= divisionToUpdate.lineItems.length) {
    // Attempt to move the line item to the next division
    const nextDivision = find(
      newData,
      (d) => d.position === divisionToUpdate.position + 1
    );

    if (nextDivision) {
      divisionToUpdate.lineItems.splice(originalPosition, 1);
      nextDivision.lineItems.splice(0, 0, targetLineItem);
    }
  } else {
    divisionToUpdate.lineItems.splice(originalPosition, 1);
    divisionToUpdate.lineItems.splice(targetPosition, 0, targetLineItem);
  }

  return newData.map((division) => {
    const lineItems = division.lineItems.map((li, i) => {
      const newDivision =
        division.id !== get(li, "division.id")
          ? {
              division: {
                id: division.id,
                name: division.name,
                position: division.position,
              },
            }
          : {};
      return { ...li, position: i, ...newDivision };
    });

    return { ...division, lineItems };
  });
}

export function moveBudgetRow(divisionsAndLineItems, id, type, direction) {
  return type === ROW_TYPE.DIVISION
    ? moveDivisionRow(divisionsAndLineItems, id, direction)
    : moveLineItemRow(divisionsAndLineItems, id, direction);
}

export function getItemsToDelete(
  existingIdAndTypeObject,
  idAndOperationObject
) {
  return Object.entries(existingIdAndTypeObject).reduce(
    ([divisionIdsToDelete, lineItemIdsToDelete], existingIdAndType) => {
      const [id, type] = existingIdAndType;
      if (
        idAndOperationObject[id] &&
        idAndOperationObject[id] === OPERATIONS.DELETE
      ) {
        if (type === "lineItem") {
          return [divisionIdsToDelete, [...lineItemIdsToDelete, id]];
        }
        return [[...divisionIdsToDelete, id], lineItemIdsToDelete];
      }
      return [divisionIdsToDelete, lineItemIdsToDelete];
    },
    [[], []]
  );
}

export function getItemsToUpsert(divisions, idAndOperationObject) {
  return divisions.reduce(
    ([divisionsToUpsert, lineItemsToUpsert], division) => {
      const divisionData = {
        id: division.id,
        name: division.name.trim(),
        position: division.position,
      };
      const divisionLineItemsToUpsert = division.lineItems.reduce(
        (divisionLineItemsToUpsert, lineItem) => {
          if (idAndOperationObject[lineItem.id] === OPERATIONS.UPSERT) {
            const lineItemData = {
              id: lineItem.id,
              masterFormatDivision: lineItem.masterFormatDivision,
              number: lineItem.number,
              originalBudgetAmount: (lineItem.amount || 0).toString(),
              divisionId: division.id,
              divisionName: division.name.trim(),
              name: lineItem.name.trim(),
              position: lineItem.position,
              lineItemCategories: lineItem.lineItemCategories,
              superLineItem: isBlank(lineItem.superLineItem)
                ? null
                : lineItem.superLineItem.trim(),
            };
            return [...divisionLineItemsToUpsert, lineItemData];
          }
          return divisionLineItemsToUpsert;
        },
        []
      );
      const updatedDivisions =
        idAndOperationObject[division.id] === OPERATIONS.UPSERT
          ? [...divisionsToUpsert, divisionData]
          : divisionsToUpsert;
      const updatedLineItems =
        divisionLineItemsToUpsert.length > 0
          ? [...lineItemsToUpsert, ...divisionLineItemsToUpsert]
          : lineItemsToUpsert;

      return [updatedDivisions, updatedLineItems];
    },
    [[], []]
  );
}
