import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const MUTATION = gql`
  mutation CreateInvoiceMutation(
    $date: Date
    $drawId: String
    $grossAmount: Currency
    $lineItems: [InvoiceLineItemInput]
    $netAmount: Currency
    $number: String
    $projectId: String!
    $retainageAmount: Currency
    $templateId: String!
  ) {
    createInvoiceFromTemplate(
      date: $date
      drawId: $drawId
      grossAmount: $grossAmount
      lineItems: $lineItems
      netAmount: $netAmount
      number: $number
      projectId: $projectId
      retainageAmount: $retainageAmount
      templateId: $templateId
    ) {
      id
    }
  }
`;

export default function useCreateInvoiceMutation(options) {
  return useMutation(MUTATION, options);
}
