import { Fragment } from "react";
import { SmallTickIcon } from "evergreen-ui";
import { Pane, Paragraph } from "components/materials";
import { formatDate } from "helpers/dateHelpers";
import { formatList } from "helpers/formatList";
import { minorScale } from "helpers/utilities";
import { REVIEW_STATE } from "helpers/enums";

/*
  This file deals with getting either:
  * The draw reviewer information for the latest draw of a project, or
  * The draw reviewer information for a project when there are no draws yet

  I chose to make separate function names for each case, rather than make the same function handle
  both reports and draws
*/

// expected values for the optional "role" arg are "isPreparer" and "isFinal"
function getDrawReviewsForType(draw, role) {
  return (draw?.reviews ?? []).filter((review) => {
    if (!role) return !review.isPreparer && !review.isFinal;

    return !!review[role];
  });
}

function getProjectReviewsForType(project, role) {
  let newRole;
  if (role === "isFinal") {
    newRole = "isSignatory";
  } else {
    newRole = role;
  }

  return (project?.drawReviewers ?? []).filter((review) => {
    if (!newRole) return !review.isPreparer && !review.isSignatory;

    return !!review[newRole];
  });
}

function prepareReviewers(reviewers, role) {
  // "Reviewers" column is a ListColumn
  if (!role) return formatList(reviewers);
  // Role-specific columns (for preparer, signatory) are StringColumns
  // We only allow the designation of a single preparer or signatory
  return reviewers.length === 1 ? reviewers[0] : null;
}
export function getReviewersForDraw(draw, role) {
  const reviewers = getDrawReviewsForType(draw, role).map(
    (review) => review.reviewer.fullName
  );

  return prepareReviewers(reviewers, role);
}

export function getReviewersForProject(project, role) {
  if (!project.recentDraw) {
    // There are no draws yet
    const reviewers = getProjectReviewsForType(project, role).map(
      (reviewer) => reviewer.fullName
    );
    return prepareReviewers(reviewers, role);
  }
  // There are draws, return the reviewers for the recent draw
  return getReviewersForDraw(project.recentDraw, role);
}

function doRenderReviews(reviews) {
  return (
    <Pane>
      {reviews.map((review) => (
        <Paragraph key={review.id} size={300}>
          {review.reviewer.fullName}
          {" - "}
          {review.state === REVIEW_STATE.APPROVED ? (
            <Fragment>
              {formatDate(review.processedAt)}
              <SmallTickIcon marginLeft={minorScale(1)} marginBottom={-2} />
            </Fragment>
          ) : (
            "unapproved"
          )}
        </Paragraph>
      ))}
    </Pane>
  );
}

export function renderReviewsForDraw(draw, role) {
  const reviews = getDrawReviewsForType(draw, role);
  return doRenderReviews(reviews);
}

export function renderReviewsForProject(project, role) {
  let reviews;
  if (project.recentDraw == null) {
    reviews = getProjectReviewsForType(project, role);
    return (
      <Pane>
        {reviews.map((review) => (
          <Paragraph key={review.id} size={300}>
            {`${review.fullName} - unapproved`}
          </Paragraph>
        ))}
      </Pane>
    );
  }
  reviews = getDrawReviewsForType(project.recentDraw, role);
  return doRenderReviews(reviews);
}
