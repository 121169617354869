import { useState, Fragment, useContext, useReducer } from "react";
import { AddIcon } from "evergreen-ui";
import { JobCostCodeModal } from "components/containers";
import {
  Alert,
  Badge,
  Button,
  Confirm,
  Form,
  IconButton,
  Link,
  Modal,
  Pane,
  Table,
} from "components/materials";
import { NavigationWarnings, UserContext } from "helpers/behaviors";
import { DocumentContext } from "contexts/documentContext";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale, minorScale } from "helpers/utilities";
import { subtract, sumBy } from "helpers/math";
import unformatNumber from "helpers/unformatNumber";
import t from "helpers/translate";
import {
  cloneDeep,
  difference,
  isEmpty,
  isEqual,
  omit,
  set,
  uniq,
  values,
} from "lodash";
import { v4 as uuid } from "uuid";

const ACTIONS = {
  ADD_COST_CODE: "ADD_COST_CODE",
  ADD_NEW_COST_CODE: "ADD_NEW_COST_CODE",
  CHANGE_COST_CODE: "CHANGE_COST_CODE",
  REMOVE_COST_CODE: "REMOVE_COST_CODE",
  UPDATE_COST_CODE_GROSS_AMOUNT: "UPDATE_GROSS_AMOUNT",
  UPDATE_COST_CODE_RETAINAGE_AMOUNT: "UPDATE_RETAINAGE_AMOUNT",
};

const isNegative = (value) => value < 0;

export function AssignCostCodesModal({ lineItem, onClose, onConfirm }) {
  const { jobCostCodes: allJobCostCodes } = useContext(DocumentContext);
  const { organizationId } = useContext(UserContext);
  const [
    {
      jobCostCodes,
      initialJobCostCodes,
      errors,
      lineItemGrossAmount,
      lineItemName,
      lineItemRetainageAmount,
      totals,
    },
    dispatch,
  ] = useReducer(
    costCodeReducer,
    { lineItem, allJobCostCodes },
    getInitialValues
  );

  const formDirty = getFormDirty(initialJobCostCodes, jobCostCodes);

  const [newCostCode, setNewCostCode] = useState({
    input: null,
    index: null,
  });

  const [confirmClose, setConfirmClose] = useState(false);

  const handleClose = (close) => {
    if (formDirty) setConfirmClose(true);
    else close();
  };

  const closeJobCostCodeModal = () => {
    setNewCostCode({ input: null, index: null });
  };

  const setCreatedJobCostCode = (costCodeIndex) => (data) => {
    const createdCostCode = data.addJobCostCode;
    dispatch({
      action: ACTIONS.ADD_NEW_COST_CODE,
      payload: { costCodeIndex, createdCostCode },
    });
    setNewCostCode({ input: null, index: null });
  };

  const onAddCostCode = () => dispatch({ action: ACTIONS.ADD_COST_CODE });

  const overAssignedCellProps = { textProps: { color: "danger" } };

  return (
    <Fragment>
      <Modal
        shouldCloseOnOverlayClick={false}
        hasClose={false}
        hasFooter
        isConfirmDisabled={!isEmpty(errors)}
        minHeightContent={200}
        onCancel={handleClose}
        onCloseComplete={onClose}
        onConfirm={() => {
          onClose();
          onConfirm(
            jobCostCodes.map(
              ({ grossAmount, jobCostCodeId, retainageAmount }) => ({
                grossAmount,
                jobCostCodeId,
                retainageAmount,
              })
            )
          );
        }}
        open
        size="medium"
        title={`${lineItemName} Cost Codes`}
      >
        <Pane paddingX={majorScale(2)}>
          <Pane
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            marginBottom={majorScale(2)}
          >
            <CostCodeModalAlerts errors={errors} />
            <Button onClick={onAddCostCode}>Add Cost Code</Button>
          </Pane>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell width="20%">
                  Cost Code
                </Table.TextHeaderCell>
                <Table.TextHeaderCell textAlign="right" width={80}>
                  Gross Amount
                </Table.TextHeaderCell>
                <Table.TextHeaderCell textAlign="right" width={80}>
                  Retainage Amount
                </Table.TextHeaderCell>
                <Table.TextHeaderCell textAlign="right" width={80}>
                  Payment Due
                </Table.TextHeaderCell>
                <Table.TextHeaderCell width={100} />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {jobCostCodes.map((costCode, costCodeIndex) => (
                <CostCodesTableRow
                  allJobCostCodes={allJobCostCodes}
                  costCode={costCode}
                  costCodeIndex={costCodeIndex}
                  dispatch={dispatch}
                  setNewCostCode={setNewCostCode}
                  key={`${costCode.rowKey}-${costCodeIndex}`}
                />
              ))}
            </Table.Body>
            <Table.Foot>
              <Table.Row>
                <Table.TextFooterCell textAlign="right">
                  Cost Codes Subtotal
                </Table.TextFooterCell>
                <Table.TextFooterCell textAlign="right">
                  {formatCurrency(totals.costCodeGrossAmount)}
                </Table.TextFooterCell>
                <Table.TextFooterCell textAlign="right">
                  {formatCurrency(totals.costCodeRetainageAmount)}
                </Table.TextFooterCell>
                <Table.FooterCell colSpan={3} />
              </Table.Row>
              <Table.Row>
                <Table.TextFooterCell textAlign="right">{`${lineItemName} Subtotal`}</Table.TextFooterCell>
                <Table.TextFooterCell textAlign="right">
                  {formatCurrency(lineItemGrossAmount)}
                </Table.TextFooterCell>
                <Table.TextFooterCell textAlign="right">
                  {formatCurrency(lineItemRetainageAmount)}
                </Table.TextFooterCell>
                <Table.FooterCell colspan={3} />
              </Table.Row>
              <Table.Row>
                <Table.TextFooterCell textAlign="right">
                  {totals.deltaGrossAmount === 0 &&
                  totals.deltaRetainageAmount === 0 ? (
                    <Badge>Fully Assigned</Badge>
                  ) : (
                    <Badge>Not Fully Assigned</Badge>
                  )}
                </Table.TextFooterCell>
                <Table.TextFooterCell
                  textAlign="right"
                  {...(totals.deltaGrossAmount < 0 && {
                    ...overAssignedCellProps,
                  })}
                >
                  {formatCurrency(totals.deltaGrossAmount)}
                </Table.TextFooterCell>
                <Table.TextFooterCell
                  textAlign="right"
                  {...(totals.deltaRetainageAmount < 0 && {
                    ...overAssignedCellProps,
                  })}
                >
                  {formatCurrency(totals.deltaRetainageAmount)}
                </Table.TextFooterCell>
                <Table.FooterCell colspan={3} />
              </Table.Row>
            </Table.Foot>
          </Table>
        </Pane>
      </Modal>
      {newCostCode.index !== null && (
        <JobCostCodeModal
          costCode={{ code: newCostCode.input }}
          onClose={closeJobCostCodeModal}
          onMutationCompleted={setCreatedJobCostCode(newCostCode.index)}
          organizationCostCodes={allJobCostCodes}
          organizationId={organizationId}
        />
      )}
      <NavigationWarnings dirty={formDirty} />
      {confirmClose && (
        <Confirm
          content={t("confirmNavigation.warning")}
          header="Warning"
          onCloseComplete={() => setConfirmClose(false)}
          onConfirm={(close) => {
            close();
            onClose();
          }}
          open={confirmClose}
          cancelLabel="Cancel"
          confirmLabel="Continue without saving"
        />
      )}
    </Fragment>
  );
}

function CostCodesTableRow({
  allJobCostCodes,
  costCode,
  costCodeIndex,
  dispatch,
  setNewCostCode,
}) {
  const onChangeCostCode = (_, event) => {
    // Form.NewSelect fires onChange when Escape is pressed when has focus
    if (event?.value) {
      dispatch({
        action: ACTIONS.CHANGE_COST_CODE,
        payload: { newId: event.value, costCodeIndex },
      });
    }
  };

  const onRemove = () =>
    dispatch({ action: ACTIONS.REMOVE_COST_CODE, payload: costCodeIndex });

  const onChangeAmount = (_, value) =>
    dispatch({
      action: ACTIONS.UPDATE_COST_CODE_GROSS_AMOUNT,
      payload: { amount: value, costCodeIndex },
    });

  const onChangeRetainage = (_, value) =>
    dispatch({
      action: ACTIONS.UPDATE_COST_CODE_RETAINAGE_AMOUNT,
      payload: { amount: value, costCodeIndex },
    });

  return (
    <Table.Row>
      <Table.TextCell>
        <Form.NewSelect
          width={200}
          name="jobCostCodeId"
          onChange={onChangeCostCode}
          options={getCostCodeOptions(
            costCode.availableCostCodeIds,
            allJobCostCodes
          )}
          value={costCode.jobCostCodeId}
          addName="New Job Cost Code"
          onAddItem={(input) => setNewCostCode({ input, index: costCodeIndex })}
        />
        <IconButton
          appearance="minimal"
          icon={AddIcon}
          intent="info"
          marginTop={minorScale(1)}
          onClick={() => setNewCostCode({ index: costCodeIndex })}
          type="button"
        />
      </Table.TextCell>
      <Table.Cell>
        <Form.NewInput
          initialValues={costCode}
          name="grossAmount"
          onChange={onChangeAmount}
          textAlign="right"
          type="currency"
        />
      </Table.Cell>
      <Table.Cell>
        <Form.NewInput
          initialValues={costCode}
          name="retainageAmount"
          onChange={onChangeRetainage}
          textAlign="right"
          type="currency"
        />
      </Table.Cell>
      <Table.TextCell textAlign="right">
        {formatCurrency(costCode.amount)}
      </Table.TextCell>
      <Table.TextCell>
        <Link
          onClick={onRemove}
          purpose="assign-cost-codes remove-cost-code"
          size={300}
        >
          Remove
        </Link>
      </Table.TextCell>
    </Table.Row>
  );
}

function CostCodeModalAlerts({ errors }) {
  const messages = values(errors);
  // if no Alert, keep placeholder element so that justifyContent: space-between
  // maintains placement of Add Cost Code Button and any future Alerts
  return isEmpty(messages) ? (
    <Fragment>&nbsp;</Fragment>
  ) : (
    <Pane>
      {messages.map((msg) => (
        <Alert intent="danger" marginBottom={minorScale(1)} title={msg} />
      ))}
    </Pane>
  );
}

function costCodeReducer(state, { action, payload }) {
  /* State Structure
  {
    __allJobCostCodes: List of jobCostCode objects for the org, helps avoid prop drilling to TableRow etc.
    __allJobCostCodeIds: List of jobCostCodeIds, used in multiple ops to determine available options for a row, etc.
    errors: Object where: {
      errorName: errorMessageText
    }
    jobCostCodes:  List of jobCostCode rows in this Modal context, with the following keys: 
      {
        amount: Derived from grossAmount - retainageAmount
        availableJobCostCodeIds: List of available jobCostCodeIds for this jobCostCode Row
        grossAmount: User-editable
        retainageAmount: User-editable
        rowKey: Stable UUID for React list management
    }
    initialJobCostCodes: the jobCostCodes produced by getInitialValues,
    lineItemName
    lineItemGrossAmount
    lineItemRetainageAmount
    totals: Derived aggregates with keys: {
      costCodeGrossAmount: Sum of all jobCostCodes.grossAmount
      costCodeRetainageAmount: Sum of all jobCostCodes.retainageAmount
      deltaGrossAmount: lineItemGrossAmount - costCodeGrossAmount
      deltaRetainageAmount: lineItemRetainageAmount - costCodeRetainageAmount
    }
  }  */
  switch (action) {
    case ACTIONS.ADD_COST_CODE: {
      const blankJobCostCode = {
        amount: 0,
        grossAmount: 0,
        jobCostCodeId: null,
        retainageAmount: 0,
        rowKey: uuid(),
      };
      const { __allJobCostCodeIds, jobCostCodes } = state;
      const selectedIds = jobCostCodes.map(
        ({ jobCostCodeId }) => jobCostCodeId
      );
      const availableCostCodeIds = uniq(
        difference(__allJobCostCodeIds, selectedIds)
      );

      return withDerivedValues({
        ...state,
        jobCostCodes: [
          ...jobCostCodes,
          { ...blankJobCostCode, availableCostCodeIds },
        ],
      });
    }
    case ACTIONS.ADD_NEW_COST_CODE: {
      const { costCodeIndex, createdCostCode } = payload;
      const { __allJobCostCodes, __allJobCostCodeIds } = state;

      const stateWithNewCode = {
        ...cloneDeep(state),
        __allJobCostCodes: __allJobCostCodes.concat({
          ...createdCostCode,
        }),
        __allJobCostCodeIds: __allJobCostCodeIds.concat(createdCostCode.id),
      };

      const stateWithUpdatedLineItem = set(
        cloneDeep(stateWithNewCode),
        `jobCostCodes.${costCodeIndex}.jobCostCodeId`,
        createdCostCode.id
      );

      return withDerivedValues(stateWithUpdatedLineItem);
    }
    case ACTIONS.CHANGE_COST_CODE: {
      const { costCodeIndex, newId } = payload;
      const newState = set(
        cloneDeep(state),
        `jobCostCodes.${costCodeIndex}.jobCostCodeId`,
        newId
      );
      return withDerivedValues(newState);
    }
    case ACTIONS.REMOVE_COST_CODE: {
      const newState = {
        ...state,
        jobCostCodes: state.jobCostCodes.filter(
          (_, index) => index !== payload
        ),
      };
      return withDerivedValues(newState);
    }

    case ACTIONS.UPDATE_COST_CODE_GROSS_AMOUNT: {
      const { amount, costCodeIndex } = payload;

      const newState = set(
        cloneDeep(state),
        `jobCostCodes.${costCodeIndex}.grossAmount`,
        amount
      );
      return withDerivedValues(newState);
    }
    case ACTIONS.UPDATE_COST_CODE_RETAINAGE_AMOUNT: {
      const { amount, costCodeIndex } = payload;
      const newState = set(
        cloneDeep(state),
        `jobCostCodes.${costCodeIndex}.retainageAmount`,
        amount
      );
      return withDerivedValues(newState);
    }

    default:
      throw new Error("Reducer action undefined");
  }
}

function getInitialValues({ lineItem, allJobCostCodes }) {
  const allJobCostCodeIds = allJobCostCodes.map(({ id }) => id);
  const allSelectedJobCostCodeIds = lineItem.jobCostCodes.map(
    ({ jobCostCodeId }) => jobCostCodeId
  );

  const jobCostCodes = isEmpty(lineItem.jobCostCodes)
    ? [
        {
          grossAmount: lineItem.applicationAmount,
          jobCostCodeId: null,
          retainageAmount: lineItem.retainageAmount,
        },
      ]
    : lineItem.jobCostCodes;

  const preparedJobCostCodes = jobCostCodes.map(
    ({ grossAmount, jobCostCodeId, retainageAmount }) => ({
      amount: subtract(grossAmount, retainageAmount),
      availableCostCodeIds: uniq([
        ...difference(allJobCostCodeIds, allSelectedJobCostCodeIds),
        jobCostCodeId,
      ]),
      grossAmount: formatCurrency(grossAmount),
      jobCostCodeId,
      retainageAmount: formatCurrency(retainageAmount),
      rowKey: uuid(),
    })
  );

  const values = {
    __allJobCostCodes: allJobCostCodes,
    __allJobCostCodeIds: allJobCostCodeIds,
    jobCostCodes: preparedJobCostCodes,
    initialJobCostCodes: preparedJobCostCodes,
    lineItemGrossAmount: lineItem.applicationAmount,
    lineItemName: lineItem.lineItemObject.descriptionOfWork,
    lineItemRetainageAmount: lineItem.retainageAmount,
  };

  return withDerivedValues(values);
}

function withCostCodeTotals(values) {
  const { jobCostCodes, lineItemGrossAmount, lineItemRetainageAmount } = values;
  return {
    ...values,
    jobCostCodes: jobCostCodes.map((jobCostCode) => ({
      ...jobCostCode,
      amount: subtract(
        unformatNumber(jobCostCode.grossAmount),
        unformatNumber(jobCostCode.retainageAmount)
      ),
    })),
    totals: {
      costCodeGrossAmount: sumBy(jobCostCodes, "grossAmount"),
      costCodeRetainageAmount: sumBy(jobCostCodes, "retainageAmount"),
      deltaGrossAmount: subtract(
        lineItemGrossAmount,
        sumBy(jobCostCodes, "grossAmount")
      ),
      deltaRetainageAmount: subtract(
        lineItemRetainageAmount,
        sumBy(jobCostCodes, "retainageAmount")
      ),
    },
  };
}

function withAvailableCostCodeOptions(values) {
  const { __allJobCostCodeIds, jobCostCodes } = values;
  const selectedJobCostCodeIds = jobCostCodes.map(
    ({ jobCostCodeId }) => jobCostCodeId
  );

  const getAvailableIds = (costCode) =>
    difference(__allJobCostCodeIds, selectedJobCostCodeIds).concat(
      costCode.jobCostCodeId
    );

  return {
    ...values,
    jobCostCodes: jobCostCodes.map((costCode) => ({
      ...costCode,
      availableCostCodeIds: getAvailableIds(costCode),
    })),
  };
}

function withErrors(values) {
  const errors = {};

  const {
    jobCostCodes,
    lineItemGrossAmount,
    lineItemRetainageAmount,
    totals: { deltaGrossAmount, deltaRetainageAmount },
  } = values;

  if (
    isNegative(deltaGrossAmount) &&
    unformatNumber(lineItemGrossAmount) >= 0
  ) {
    set(
      errors,
      "deltaGrossAmount",
      "Cost Code Gross Amount cannot exceed Line Item Gross Amount."
    );
  }
  if (
    isNegative(deltaRetainageAmount) &&
    unformatNumber(lineItemRetainageAmount) >= 0
  ) {
    set(
      errors,
      "deltaRetainageAmount",
      "Cost Code Retainage Amount cannot exceed Line Item Retainage Amount."
    );
  }
  if (jobCostCodes.some(({ jobCostCodeId }) => !jobCostCodeId)) {
    set(errors, "missingCostCodeSelection", "Cost Code selection is required.");
  }

  return { ...values, errors };
}

function withDerivedValues(values) {
  const withTotals = withCostCodeTotals(values);
  const withCCOptions = withAvailableCostCodeOptions(withTotals);
  return withErrors(withCCOptions);
}

function getCostCodeOptions(availableIds, allJobCostCodes) {
  const costCodeDescription = ({ code, description }) =>
    description ? `${code}: ${description}` : code;

  return availableIds
    .filter((id) => id !== null)
    .map((id) => ({
      key: id,
      text: costCodeDescription(
        allJobCostCodes.find(({ id: jobCostCodeId }) => id === jobCostCodeId)
      ),
      value: id,
    }));
}

function getFormDirty(initialCostCodes, currentCostCodes) {
  const comparisonObjects = [
    initialCostCodes,
    currentCostCodes,
  ].map((costCodes) =>
    costCodes.map((costCode) => omit(costCode, "availableCostCodeIds"))
  );
  return !isEqual(...comparisonObjects);
}
