import { useState, memo, Fragment } from "react";
import LineItemRow from "./LineItemRow";
import { PROJECTION_EVENT } from "../../containers/ProjectionsPage";
import { DivisionRowHeader } from "./DivisionRowHeader";
import { DivisionRowSubtotal } from "./DivisionRowSubtotal";

function DivisionRows({
  actualsCount,
  columns,
  division,
  onChange,
  project,
  projectionState,
}) {
  const [showLineItems, setShowLineItems] = useState(true);

  function handleProjectionSettingChanged(e, option, divisionId) {
    const { value } = e.target;

    const data = { id: divisionId, option, value };

    onChange({
      type: PROJECTION_EVENT.DIVISION_SETTING_CHANGED,
      data,
    });
  }

  return (
    <Fragment>
      <DivisionRowHeader
        projectionState={projectionState}
        project={project}
        division={division}
        columns={columns}
        isOpen={showLineItems}
        onClick={() => setShowLineItems(!showLineItems)}
        onSettingChanged={(e, settingType) =>
          handleProjectionSettingChanged(e, settingType, division.id)
        }
      />
      {showLineItems &&
        division.lineItems.map((lineItem) => {
          return (
            <LineItemRow
              actualsCount={actualsCount}
              columns={columns}
              divisionId={division.id}
              lineItem={lineItem}
              onChange={onChange}
              project={project}
              projectionState={projectionState}
            />
          );
        })}
      <DivisionRowSubtotal
        actualsCount={actualsCount}
        columns={columns}
        division={division}
        project={project}
        projectionState={projectionState}
      />
    </Fragment>
  );
}

export default memo(DivisionRows);
