import { useState, useEffect, Fragment } from "react";
import { Formik } from "formik";
import { get, uniqBy } from "lodash";
import { DashboardTodos } from "components/templates";
import {
  Button,
  Card,
  Form,
  MentionsBody,
  Pane,
  Paragraph,
  Tab,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { formatDateTime } from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import analytics from "helpers/analytics";
import { DashboardSetupSteps } from "./DashboardSetupSteps";

const projectHasTodos = (project) =>
  project.pendingDrawApprovals.length > 0 ||
  project.assignedDocuments.length > 0 ||
  project.documentsPendingApprovalForUser.length > 0 ||
  project.mentions.filter((mention) => !!mention.comment.target).length > 0;

const getDocumentURL = (document) =>
  `/projects/${document.project.id}/assigned_documents/${document.id}`;

const onComment = (mutation) => (values, formikBag) => {
  mutation({ variables: values });
  formikBag.resetForm();
  analytics.track("Comment Created", {
    hasMentions: !!values.body.match(
      /@\[[^\]]*?\]\([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\)/
    ),
  });
};

function ProjectComment({ comment, theme }) {
  return (
    <Pane marginBottom={minorScale(3)} marginRight={majorScale(2)}>
      <Pane display="flex">
        <Text flex="1 1 auto" size={300} fontWeight={theme.fontWeights.DEMI}>
          {comment.author.fullName}
        </Text>
        <Text fontStyle="italic" size={300} color="muted">
          {formatDateTime(comment.insertedAt)}
        </Text>
      </Pane>
      <Paragraph paddingLeft={majorScale(2)} size={300}>
        <MentionsBody comment={comment} textProps={{ size: 300 }} />
      </Paragraph>
    </Pane>
  );
}

function ProjectCommentForm({ addCommentMutation, project }) {
  const [addComment, addCommentResult] = addCommentMutation;
  const { users } = project.organization;

  return (
    <Formik
      initialValues={{ body: "", projectId: project.id }}
      onSubmit={onComment(addComment)}
    >
      {(form) => {
        return (
          <Pane flex="0 0 auto" marginBottom={majorScale(1)}>
            <Form.MentionsTextArea
              allowSuggestionsAboveCursor
              name="body"
              className="rbt-comment-form"
              data-testid="comment-box"
              users={users}
            />
            <Pane
              textAlign="right"
              marginBottom={minorScale(1)}
              marginTop={-majorScale(2)}
            >
              <Button
                appearance="primary"
                content="Add Comment"
                disabled={isBlank(form.values.body)}
                isLoading={addCommentResult.loading}
                onClick={form.handleSubmit}
                type="submit"
                purpose="comment form submit"
              />
            </Pane>
          </Pane>
        );
      }}
    </Formik>
  );
}

function ProjectComments({ addCommentMutation, project, theme }) {
  return (
    <Fragment>
      <Paragraph
        minHeight={40}
        display="flex"
        alignItems="center"
        color="muted"
        fontWeight={theme.fontWeights.MEDIUM}
      >
        PROJECT CHAT
      </Paragraph>
      <Card
        flex="1 1 auto"
        paddingX={majorScale(2)}
        paddingTop={majorScale(1)}
        paddingBottom={majorScale(2)}
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <ProjectCommentForm
          addCommentMutation={addCommentMutation}
          project={project}
        />
        <Pane flex="0 1 auto" overflowY="auto">
          {project.comments.length > 0 ? (
            project.comments.map((comment) => (
              <ProjectComment
                key={comment.id}
                comment={comment}
                theme={theme}
              />
            ))
          ) : (
            <Pane marginY={majorScale(3)}>
              <Paragraph marginBottom={majorScale(1)} textAlign="center">
                Nobody has commented on this project yet.
              </Paragraph>
              <Paragraph textAlign="center">Leave a comment above.</Paragraph>
            </Pane>
          )}
        </Pane>
      </Card>
    </Fragment>
  );
}

function SetupAndTodosHeader({
  hasSetupChecklist,
  hasTodos,
  showTodos,
  setShowTodos,
  theme,
}) {
  if (hasTodos && hasSetupChecklist) {
    return (
      <Paragraph minHeight={40} display="flex" alignItems="center">
        <Tab isSelected={showTodos} onSelect={() => setShowTodos(true)}>
          TO DOS
        </Tab>
        <Tab isSelected={!showTodos} onSelect={() => setShowTodos(false)}>
          PROJECT SETUP
        </Tab>
      </Paragraph>
    );
  }
  return (
    <Paragraph
      minHeight={40}
      display="flex"
      alignItems="center"
      color="muted"
      fontWeight={theme.fontWeights.MEDIUM}
    >
      {hasSetupChecklist ? "PROJECT SETUP" : "YOUR TO-DOS"}
    </Paragraph>
  );
}

function ProjectSetupAndTodos({
  dismissProjectSetup,
  history,
  project,
  setSelectedSetupStep,
  theme,
  todos,
  updateSetupStepMutation,
}) {
  const hasTodos = projectHasTodos(project);
  const { id: projectId, setupSteps } = project;
  const [showTodos, setShowTodos] = useState(hasTodos);

  useEffect(() => setShowTodos(hasTodos), [hasTodos]);

  const hasSetupChecklist =
    !project.projectSetupDismissed && setupSteps.length > 0;

  if (!hasSetupChecklist && !hasTodos) return null;

  return (
    <Fragment>
      <SetupAndTodosHeader
        hasSetupChecklist={hasSetupChecklist}
        hasTodos={hasTodos}
        showTodos={showTodos}
        setShowTodos={setShowTodos}
        theme={theme}
      />
      {showTodos && hasTodos && (
        <Pane
          flex="0 0 auto"
          maxHeight="45%"
          overflowY="auto"
          padding={1}
          paddingRight={majorScale(2)}
          marginBottom={minorScale(1)}
        >
          <DashboardTodos
            getDocumentURL={getDocumentURL}
            history={history}
            todos={todos}
          />
        </Pane>
      )}
      {!showTodos && hasSetupChecklist && (
        <DashboardSetupSteps
          dismissProjectSetup={dismissProjectSetup}
          projectId={projectId}
          setupSteps={setupSteps}
          setSelectedSetupStep={setSelectedSetupStep}
          setShowTodos={setShowTodos}
          theme={theme}
          updateSetupStepMutation={updateSetupStepMutation}
        />
      )}
    </Fragment>
  );
}

export function ProjectActivity({
  addCommentMutation,
  dismissProjectSetup,
  history,
  project,
  setSelectedSetupStep,
  theme,
  updateSetupStepMutation,
}) {
  const uniqueDocuments = uniqBy(
    [...project.assignedDocuments, ...project.documentsPendingApprovalForUser],
    "id"
  );
  const todos = {
    documents: uniqueDocuments,
    drawApprovals: get(project, "pendingDrawApprovals", []),
    mentions: get(project, "mentions", []),
  };

  return (
    <Pane display="flex" flexDirection="column" height={739} maxHeight={739}>
      <ProjectSetupAndTodos
        dismissProjectSetup={dismissProjectSetup}
        history={history}
        project={project}
        setSelectedSetupStep={setSelectedSetupStep}
        theme={theme}
        todos={todos}
        updateSetupStepMutation={updateSetupStepMutation}
      />
      <ProjectComments
        addCommentMutation={addCommentMutation}
        project={project}
        theme={theme}
      />
    </Pane>
  );
}
