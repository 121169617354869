import { Pane } from "evergreen-ui";
import { majorScale, withTheme } from "helpers/utilities";

const Segment = withTheme(
  ({ attached, bleed, children, theme, rounded, ...props }) => {
    return (
      <Pane
        backgroundColor="white"
        padding={bleed ? 0 : majorScale(2)}
        marginBottom={attached ? 0 : majorScale(2)}
        borderRadius={rounded ? 5 : 0}
        borderBottom={attached ? `1px solid ${theme.colors.gray300}` : "none"}
        {...props}
      >
        {children}
      </Pane>
    );
  }
);

export default Segment;
