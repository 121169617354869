import PropTypes from "prop-types";
import { SelectionPill } from "components/materials";
import { columnShape, groupShape } from "../FastDataTableUtils";

const CurrentGrouping = ({
  groupConfig,
  dataColumns: columns,
  applyGroupConfig,
  ...props
}) => {
  if (!groupConfig.columnId) return null;

  const column = columns.find((column) => column.id === groupConfig.columnId);
  if (!column) return null;

  return (
    <SelectionPill
      icon="duplicate"
      text={`Grouped By: ${column.header}`}
      onClick={() => applyGroupConfig({})}
      color="green100"
      {...props}
    />
  );
};

CurrentGrouping.propTypes = {
  groupConfig: groupShape,
  applyGroupConfig: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(columnShape),
};

export default CurrentGrouping;
