import { majorScale } from "helpers/utilities";
import { mapValues, get } from "lodash";

const reset = {
  border: "none",
  borderRadius: 3,
  lineHeight: 1.5,
  margin: 0,
  outline: "none",
  padding: 0,
};

export function getStyleMentionsInput(theme, focused) {
  const boxShadow = focused
    ? `inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14)`
    : `inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14)`;

  const paragraphStyles = mapValues(
    theme.components.Paragraph.sizes[300],
    // evergreen represents the component styles
    // as paths to values in other namespaces in the theme (`fontSizes`, `fontWeights`, etc.)
    (value) => get(theme, value)
  );

  return {
    control: {
      ...reset,
      size: 300,
      ...paragraphStyles,
      boxShadow,
      fontFamily: theme.fontFamilies.display,
      minHeight: majorScale(10),
    },
    input: {
      ...reset,
      color: theme.colors.text.default,
      padding: majorScale(1),
    },
    highlighter: {
      ...reset,
      padding: majorScale(1),
    },
    suggestions: {
      item: {
        padding: "4px 8px",
        "&focused": { backgroundColor: "rgba(16, 112, 202, 0.09)" },
      },
      list: {
        ...reset,
        size: 300,
        ...paragraphStyles,
        boxShadow: `0 0 1px rgba(67, 90, 111, 0.3), 0 8px 10px -4px rgba(67, 90, 111, 0.47)`,
        color: theme.colors.text.default,
        fontFamily: theme.fontFamilies.display,
      },
      zIndex: 999,
    },
  };
}

export function getStyleMention(theme) {
  return { backgroundColor: "rgba(16, 112, 202, 0.09)" };
}

export function getDataMention(users) {
  return users.map((user) => ({ display: user.fullName, id: user.id }));
}

export function displayTransform(id, display) {
  return `@${display}`;
}
