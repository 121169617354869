export const LINE_ITEMS = {
  concrete: { name: "Concrete", isLineItem: true, hasColumnA: true },
  masonry: { name: "Masonry", isLineItem: true, hasColumnA: true },
  metals: { name: "Metals", isLineItem: true, hasColumnA: true },
  roughCarpentry: {
    name: "Rough Carpentry",
    isLineItem: true,
    hasColumnA: true,
  },
  finishCarpentry: {
    name: "Finish Carpentry",
    isLineItem: true,
    hasColumnA: true,
  },
  waterproofing: { name: "Waterproofing", isLineItem: true, hasColumnA: true },
  insulation: { name: "Insulation", isLineItem: true, hasColumnA: true },
  roofing: { name: "Roofing", isLineItem: true, hasColumnA: true },
  sheetMetal: { name: "Sheet Metal", isLineItem: true, hasColumnA: true },
  doors: { name: "Doors", isLineItem: true, hasColumnA: true },
  windows: { name: "Windows", isLineItem: true, hasColumnA: true },
  glass: { name: "Glass", isLineItem: true, hasColumnA: true },
  lathAndPlaster: {
    name: "Lath and Plaster",
    isLineItem: true,
    hasColumnA: true,
  },
  drywall: { name: "Drywall", isLineItem: true, hasColumnA: true },
  tileWork: { name: "Tile Work", isLineItem: true, hasColumnA: true },
  acoustical: { name: "Acoustical", isLineItem: true, hasColumnA: true },
  woodFlooring: { name: "Wood Flooring", isLineItem: true, hasColumnA: true },
  resilientFlooring: {
    name: "Resilient Flooring",
    isLineItem: true,
    hasColumnA: true,
  },
  paintingAndDecorating: {
    name: "Painting and Decorating",
    isLineItem: true,
    hasColumnA: true,
  },
  specialties: { name: "Specialties", isLineItem: true, hasColumnA: true },
  specialEquipment: {
    name: "Special Equipment",
    isLineItem: true,
    hasColumnA: true,
  },
  cabinets: { name: "Cabinets", isLineItem: true, hasColumnA: true },
  appliances: { name: "Appliances", isLineItem: true, hasColumnA: true },
  blindsAndShadesArtwork: {
    name: "Blinds and Shades, Artwork",
    isLineItem: true,
    hasColumnA: true,
  },
  carpets: { name: "Carpets", isLineItem: true, hasColumnA: true },
  specialConstruction: {
    name: "Special Construction",
    isLineItem: true,
    hasColumnA: true,
  },
  elevators: { name: "Elevators", isLineItem: true, hasColumnA: true },
  plumbingAndHotWater: {
    name: "Plumbing and Hot Water",
    isLineItem: true,
    hasColumnA: true,
  },
  heatAndVentilation: {
    name: "Heat and Ventilation",
    isLineItem: true,
    hasColumnA: true,
  },
  airConditioning: {
    name: "Air Conditioning",
    isLineItem: true,
    hasColumnA: true,
  },
  electrical: { name: "Electrical", isLineItem: true, hasColumnA: true },
  accessoryBuildings: {
    name: "Accessory Buildings",
    isLineItem: true,
    hasColumnA: true,
  },
  earthWork: { name: "Earth Work", isLineItem: true, hasColumnA: true },
  siteUtilities: { name: "Site Utilities", isLineItem: true, hasColumnA: true },
  roadsAndWalks: {
    name: "Roads and Walks",
    isLineItem: true,
    hasColumnA: true,
  },
  siteImprovement: {
    name: "Site Improvement",
    isLineItem: true,
    hasColumnA: true,
  },
  lawnsAndPlanting: {
    name: "Lawns and Planting",
    isLineItem: true,
    hasColumnA: true,
  },
  unusualSiteConditions: {
    name: "Unusual Site Conditions",
    isLineItem: true,
    hasColumnA: true,
  },
  generalRequirements: {
    name: "General Requirements",
    isLineItem: true,
    hasColumnA: true,
  },
};

export const PAGE_2_ROWS = {
  subtotalOfBreakdownItems: {
    name: "Subtotal of Breakdown Items",
    isLineItem: false,
    hasColumnA: true,
  },
  buildersOverhead: {
    name: "Builder's Overhead",
    isLineItem: true,
    hasColumnA: true,
  },
  buildersProfit: {
    name: "Builder's Profit",
    isLineItem: true,
    hasColumnA: true,
  },
  totalOfCostBreakdownItems: {
    name: "Total of Cost Breakdown Items",
    isLineItem: false,
    hasColumnA: true,
  },
  materialsStoredOnsite: {
    name: "Materials Stored On-site",
    isLineItem: true,
    hasColumnA: false,
  },
  materialsStoredOffsite: {
    name: "Materials Stored Off-site",
    isLineItem: true,
    hasColumnA: false,
  },
  sumOfCostBreakdownItems: {
    name: "Cost Breakdown Plus Materials",
    isLineItem: false,
    hasColumnA: false,
  },
  lessNetDecreaseInCost: {
    name: "Less Net Decrease in Cost",
    isLineItem: false,
    hasColumnA: false,
  },
  totalAfterAdjusting: {
    name: "Total After Adjusting",
    isLineItem: false,
    hasColumnA: false,
  },
  lessRetained: { name: "Retainage", isLineItem: true, hasColumnA: false },
  totalAmountDueToDate: {
    name: "Total Amount Due to Date",
    isLineItem: false,
    hasColumnA: false,
  },
  lessPreviousPayments: {
    name: "Less Previous Payments",
    isLineItem: false,
    hasColumnA: false,
  },
  netAmount: {
    name: "Net Amount of This Requisition",
    isLineItem: false,
    hasColumnA: false,
  },
};
