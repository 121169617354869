import { useContext, useMemo } from "react";
import { EditTableViews } from "components/containers";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
  primaryColumnDefaults,
  booleanColumnDefaults,
  currencyColumnDefaults,
  dateColumnDefaults,
  enumColumnDefaults,
  stringColumnDefaults,
  dateTimeColumnDefaults,
} from "components/materials/FastDataTable";
import {
  getDateRangeAggregate,
  getDateTimeRangeAggregate,
  getDefaultAggregate,
} from "helpers/tableAggregateHelpers";
import { get, omit } from "lodash";
import { UserContext } from "helpers/behaviors";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import {
  DOCUMENT_TYPE_NAME,
  PERMISSION_ACTION,
  PROJECT_STATUS_TYPE,
} from "helpers/enums";
import { sumBy } from "helpers/math";
import { getEnumValuesForOrganization } from "helpers/reportHelpers";
import t from "helpers/translate";
import { customFieldFastColumns } from "./CustomFieldColumns";

const defaultViews = [
  {
    config: toBase64({
      columnConfig: [
        "projectName",
        "drawName",
        "vendorName",
        "lineItem",
        "type",
        "uploadedDate",
        "documentNumber",
        "documentDate",
        "requestedAmount",
      ],
      filterConfig: [],
      groupConfig: {},
      sortConfig: {},
    }),
    isDefault: true,
    name: "Default",
  },
];

function getColumns(orgData, hasPermission) {
  const orgEnumValues = getEnumValuesForOrganization(orgData);

  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Project",
      id: "projectName",
      // category not needed - primary column
      groupable: true,
      value: (lineItemDocumentation) => lineItemDocumentation.projectName,
    },
    {
      ...stringColumnDefaults,
      header: "Draw",
      id: "drawName",
      category: "Document Information",
      groupable: true,
      value: (lineItemDocumentation) => get(lineItemDocumentation, "drawName"),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, "drawName"),
    },
    {
      ...dateTimeColumnDefaults,
      header: "Draw Creation Date",
      id: "drawCreatedDate",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "drawCreatedAt"),
      aggregate: (group) => getDateTimeRangeAggregate(group, "drawCreatedAt"),
    },
    {
      ...stringColumnDefaults,
      header: "Organization",
      id: "vendorName",
      category: "Document Information",
      groupable: true,
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "vendorName"),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, "vendorName"),
    },
    {
      ...stringColumnDefaults,
      header: "Division",
      id: "division",
      category: "Document Information",
      groupable: true,
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "divisionName"),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, "divisionName"),
    },
    {
      ...stringColumnDefaults,
      header: "Line Item",
      id: "lineItem",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "drawLineItemName"),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, "drawLineItemName"),
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Line Item Number",
      id: "lineItemNumber",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "drawLineItemNumber"),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, "drawLineItemNumber"),

      width: 200,
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Summary Line Item",
      id: "summaryLineItem",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "superLineItem"),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, "superLineItem"),
      width: 200,
    },
    {
      ...dateTimeColumnDefaults,
      header: "Uploaded",
      id: "uploadedDate",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "documentUploadedDate"),
      aggregate: (groupDocuments) =>
        getDateRangeAggregate(groupDocuments, "documentUploadedDate"),
    },
    {
      ...enumColumnDefaults,
      enumValues: [
        DOCUMENT_TYPE_NAME.INVOICE,
        DOCUMENT_TYPE_NAME.PAY_APPLICATION,
      ].map((type) => t(`documentTypeName.${type}`)),
      header: "Document Type",
      id: "type",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        t(`documentTypeName.${lineItemDocumentation.documentType}`),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, (lineItemDocumentation) =>
          t(`documentTypeName.${lineItemDocumentation.documentType}`)
        ),
    },
    {
      ...stringColumnDefaults,
      header: "Document #",
      id: "documentNumber",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "documentNumber"),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, "documentNumber"),
    },
    {
      ...stringColumnDefaults,
      header: "Document Description",
      id: "documentDescription",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "documentDescription"),
    },
    {
      ...dateColumnDefaults,
      header: "Document Date",
      id: "documentDate",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "documentDate"),
      aggregate: (groupDocuments) =>
        getDateRangeAggregate(groupDocuments, "documentDate"),
    },
    {
      ...currencyColumnDefaults,
      header: "Amount Requested (Gross)",
      id: "requestedAmountGross",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "requestedAmountGross"),
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, "requestedAmountGross"),
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      header: "Amount Requested (Net)",
      id: "requestedAmount",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "requestedAmount"),
      aggregate: (groupDocuments) => sumBy(groupDocuments, "requestedAmount"),
    },
    {
      ...currencyColumnDefaults,
      header: "Retainage To Date Amount",
      id: "retainageToDateAmount",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "retainageToDateAmount"),
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, "retainageToDateAmount"),
    },
    {
      ...currencyColumnDefaults,
      header: "Stored Materials",
      id: "materialsStoredAmount",
      category: "Document Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "materialsStoredAmount"),
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, "materialsStoredAmount"),
    },
    {
      ...stringColumnDefaults,
      header: hasPermission(
        PERMISSION_ACTION.ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES
      )
        ? "Job Cost Codes"
        : "Job Cost Code",
      id: "jobCostCodes",
      category: "Document Information",
      hidden: !hasPermission(PERMISSION_ACTION.JOB_COST_CODES),
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "jobCostCodes")?.join(", "),
    },
    {
      ...stringColumnDefaults,
      header: "Vendor ID",
      id: "vendorCostCode",
      category: "Document Information",
      hidden: !hasPermission(PERMISSION_ACTION.VENDOR_COST_CODES),
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "vendorCostCode"),
      aggregate: (lineItemDocumentation) =>
        getDefaultAggregate(lineItemDocumentation, "vendorCostCode"),
    },
    {
      ...stringColumnDefaults,
      header: "File URL",
      id: "fileURL",
      category: "Document Information",
      value: (lineItemDocumentation) => get(lineItemDocumentation, "fileUrl"),
    },
    {
      ...booleanColumnDefaults,
      aggregate: (groupDocuments) =>
        getDefaultAggregate(groupDocuments, (lineItemDocumentation) =>
          lineItemDocumentation.documentIsPaid ? "Paid" : "Not Paid"
        ),
      header: "Document Payment Status",
      id: "documentPaymentStatus",
      category: "Document Information",
      hidden: !hasPermission(PERMISSION_ACTION.PAYMENT_TRACKING),
      value: (lineItemDocumentation) =>
        lineItemDocumentation.documentIsPaid ? "Paid" : "Not Paid",
      textAlign: "left",
      width: 80,
    },
    {
      ...currencyColumnDefaults,
      header: "Document Amount Paid",
      id: "documentAmountPaid",
      category: "Document Information",
      hidden: !hasPermission(PERMISSION_ACTION.PAYMENT_TRACKING),
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "documentAmountPaid"),
      aggregate: (groupDocuments) =>
        sumBy(groupDocuments, "documentAmountPaid"),
    },
    {
      ...dateColumnDefaults,
      header: "Document Date Paid",
      id: "documentDatePaid",
      category: "Document Information",
      hidden: !hasPermission(PERMISSION_ACTION.PAYMENT_TRACKING),
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "documentDatePaid", null),
      aggregate: (groupDocuments) =>
        getDateRangeAggregate(groupDocuments, "documentDatePaid"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.teams,
      header: "Team",
      hidden: !hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT),
      id: "team",
      category: "Project Information",
      groupable: true,
      value: ({ team }) => team,
      aggregate: (documentation) => getDefaultAggregate(documentation, "team"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectTemplates,
      header: "Project Type",
      id: "projectType",
      category: "Project Information",
      groupable: true,
      value: ({ projectType }) => projectType,
      aggregate: (documentation) =>
        getDefaultAggregate(documentation, "projectType"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.productTypes,
      header: "Product Type",
      id: "productType",
      category: "Project Information",
      groupable: true,
      value: ({ productType }) => productType,
      aggregate: (documentation) =>
        getDefaultAggregate(documentation, "productType"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectRegions,
      header: "Project Region",
      id: "projectRegion",
      category: "Project Information",
      groupable: true,
      value: ({ projectRegion }) => projectRegion,
      aggregate: (documentation) =>
        getDefaultAggregate(documentation, "projectRegion"),
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(PROJECT_STATUS_TYPE).map((status) =>
        t(`projectStatus.${status}`)
      ),
      header: "Project Status",
      id: "projectStatus",
      category: "Project Information",
      groupable: true,
      value: ({ projectStatus }) => projectStatus,
      aggregate: (documentation) =>
        getDefaultAggregate(documentation, "projectStatus"),
      width: 120,
    },
    {
      ...stringColumnDefaults,
      header: "Project Custom ID",
      id: "projectCustomId",
      category: "Project Information",
      value: (lineItemDocumentation) =>
        get(lineItemDocumentation, "projectCustomId", null),
      aggregate: (documentation) =>
        getDefaultAggregate(documentation, "projectCustomId"),
    },
  ];
}

function getControls(propsControls, propsEditTableViews) {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      disable={[FastDataTableDownloadDocuments]}
      isReport
      pinnedFilters={[
        "projectStatus",
        "projectType",
        "productType",
        "projectRegion",
        "team",
      ]}
      searchPlaceholder="Search..."
    />
  );
}

export function LineItemDocumentationTable({ organization, history }) {
  const { hasPermission } = useContext(UserContext);

  const onClickRow = hasPermission(
    PERMISSION_ACTION.DOWNLOAD_DOCUMENT,
    organization
  )
    ? (documentation) =>
        history.push({
          pathname: `/projects/${get(
            documentation,
            "projectId"
          )}/documentation/${get(documentation, "documentId")}`,
        })
    : () => {};

  const { invoiceSummaryReports, projects } = organization;
  const orgData = omit(organization, ["invoiceSummaryReports", "projects"]);
  const customFieldColumns = customFieldFastColumns(projects, "customFields");

  const columns = useMemo(
    () =>
      getColumns(orgData, (permission) =>
        hasPermission(permission, organization)
      ).concat(customFieldColumns),
    [organization, hasPermission, customFieldColumns, orgData]
  );

  const lineItemDocumentation = prepareDocumentationWithProjectData(
    invoiceSummaryReports,
    projects
  );

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(
        PERMISSION_ACTION.SAVE_TABLE_VIEWS,
        organization
      )}
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={organization.id}
      defaultViews={defaultViews}
      tableName="LineItemDocumentationTable"
    >
      {(propsEditTableViews) => (
        <FastDataTable
          columns={columns}
          controls={(propsControls) =>
            getControls(propsControls, propsEditTableViews)
          }
          items={lineItemDocumentation}
          onClickRow={onClickRow}
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(propsEditTableViews, "views.0.config")
          }
        />
      )}
    </EditTableViews>
  );
}

function prepareDocumentationWithProjectData(invoiceSummaryReports, projects) {
  const projectsLibrary = projects.reduce(
    (acc, project) => ({
      ...acc,
      [project.id]: project,
    }),
    {}
  );

  return invoiceSummaryReports.map((item) => {
    const project = projectsLibrary[item.projectId];
    return {
      ...item,
      customFields: project.customFields,
      productType: project.productType?.type,
      projectRegion: project.projectRegion?.region,
      projectStatus: t(`projectStatus.${project.status}`),
      projectType: project.template.name,
      team: project.team?.name,
    };
  });
}
