import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { ContentLayout } from "components/templates";
import { Form } from "components/materials";
import isBlank from "helpers/isBlank";
import { CREATE_BORROWER_DRAW } from "./graphql";

export function BorrowerNewDrawPage({ history, match }) {
  const { projectId } = match.params;

  const [createBorrowerDraw, createBorrowerDrawResult] = useMutation(
    CREATE_BORROWER_DRAW,
    {
      onCompleted: ({ createBorrowerDraw }) => {
        history.push(
          `/borrower/projects/${projectId}/draws/${createBorrowerDraw.id}`
        );
      },
    }
  );

  const validate = (values) => {
    const errors = {};

    if (isBlank(values.name)) {
      errors.name = "Please enter a name";
    }

    return errors;
  };

  const onSubmit = (values) => {
    const variables = {
      name: values.name,
      projectId,
    };

    createBorrowerDraw({ variables });
  };

  return (
    <ContentLayout fixedWidth title="New Draw">
      <Formik
        initialValues={{ name: "" }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formikProps) => (
          <Form.Generic {...formikProps} {...createBorrowerDrawResult}>
            <Form.Input name="name" label="Draw Name" />
          </Form.Generic>
        )}
      </Formik>
    </ContentLayout>
  );
}
