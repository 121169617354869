const humanReadableList = (values) => {
  let result = values.join("");
  if (values.length === 2) {
    result = values.join(" and ");
  } else if (values.length > 2) {
    const firstValues = values.slice(0, -1);
    const lastValue = values.slice(-1);
    result = `${firstValues.join(", ")}, and ${lastValue}`;
  }
  return result;
};

export default humanReadableList;
