import { Fragment } from "react";
import PropTypes from "prop-types";
import { DocumentIcon, FormIcon, ThListIcon } from "evergreen-ui";
import { get } from "lodash";
import {
  Link,
  MentionsBody,
  Pane,
  Paragraph,
  Text,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { formatDateTime } from "helpers/dateHelpers";
import { RULE_TYPE } from "helpers/enums";
import { RuleLabel } from "./DrawRules/RuleLabel";

const TARGET_TYPENAME = {
  BASIC_DOCUMENT: "BasicDocument",
  LINE_ITEM: "LineItem",
  RULE_TRANSITION: "RuleTransition",
  RULE: "Rule",
};

export function getTargetProps(target) {
  switch (target.__typename) {
    case TARGET_TYPENAME.BASIC_DOCUMENT: {
      return {
        icon: <DocumentIcon />,
        text: target.file.name,
        to: target.drawId
          ? `/projects/${target.projectId}/draws/${target.drawId}/documentation/${target.id}`
          : `/projects/${target.projectId}/documentation/${target.id}`,
      };
    }
    case TARGET_TYPENAME.LINE_ITEM: {
      return {
        icon: <ThListIcon />,
        text: target.name,
        to: `/projects/${target.projectId}/draws/${target.drawId}/line_items/${target.id}`,
      };
    }
    case TARGET_TYPENAME.RULE_TRANSITION: {
      return {
        icon: <FormIcon />,
        text:
          target.rule.type === RULE_TYPE.AUTOMATED ? (
            <RuleLabel color="inherit" rule={target.rule} />
          ) : (
            target.rule.name
          ),
        to: `/projects/${target.rule.projectId}/draws/${target.rule.scopeId}/reviews/${target.rule.id}?showAllRules=true`,
      };
    }
    case TARGET_TYPENAME.RULE: {
      return {
        icon: <FormIcon />,
        text:
          target.type === RULE_TYPE.AUTOMATED ? (
            <RuleLabel color="inherit" rule={target} />
          ) : (
            target.name
          ),
        to: `/projects/${target.projectId}/draws/${target.scopeId}/reviews/${target.id}?showAllRules=true`,
      };
    }
    default: {
      return null;
    }
  }
}

function Target({ target }) {
  if (!target) return <Text>&lt;Removed&gt;</Text>;

  const targetProps = getTargetProps(target);

  if (!targetProps) return null;

  return (
    <Fragment>
      <Pane height={16} width={16}>
        {targetProps.icon}
      </Pane>
      <Paragraph flex="1 1 auto" marginX={majorScale(1)}>
        <Link purpose="comments target" to={targetProps.to}>
          {targetProps.text}
        </Link>
      </Paragraph>
    </Fragment>
  );
}

function Comment({ comment, showTarget }) {
  return (
    <Pane
      borderBottom="default"
      paddingY={majorScale(2)}
      marginX={majorScale(4)}
    >
      <Pane display="flex">
        {showTarget && <Target target={comment.target} />}
        <Pane flex="0 0 auto">
          <Text marginRight={majorScale(1)}>
            {get(comment, "author.fullName", "System")}
          </Text>
          <Text color="muted">{formatDateTime(comment.insertedAt)}</Text>
        </Pane>
      </Pane>
      <Paragraph paddingTop={majorScale(1)} paddingX={majorScale(4)}>
        <MentionsBody comment={comment} />
      </Paragraph>
    </Pane>
  );
}

function ListComments({ comments, showTarget, showEmptyMessage }) {
  if (showEmptyMessage && comments.length === 0)
    return <Text>Draw has no comments</Text>;

  return comments.map((comment) => (
    <Comment comment={comment} key={comment.id} showTarget={showTarget} />
  ));
}

ListComments.propTypes = {
  comments: PropTypes.array.isRequired,
  showTarget: PropTypes.bool,
  showEmptyMessage: PropTypes.bool,
};

ListComments.defaultProps = {
  showTarget: false,
  showEmptyMessage: false,
};

export default ListComments;
