import { TickIcon, TimeIcon } from "evergreen-ui";
import { Badge } from "components/materials";
import { majorScale } from "helpers/utilities";
import {
  isApprovableDocumentType,
  getMostRecentDocumentReview,
} from "helpers/documentHelpers";

export function PendingApprovalsBadge({ documents }) {
  const approvableDocuments = documents.filter(isApprovableDocumentType);
  const pendingCount = approvableDocuments.filter((document) => {
    const recentReview = getMostRecentDocumentReview(
      document.recentApprovalReviews
    );
    return !recentReview || recentReview.reviewType !== "approved";
  }).length;

  if (approvableDocuments.length === 0) return null;

  return pendingCount === 0 ? (
    <Badge color="green">
      <TickIcon size={10} marginRight={majorScale(1)} />
      ALL APPROVED
    </Badge>
  ) : (
    <Badge color="yellow">
      <TimeIcon color="orange" size={10} marginRight={majorScale(1)} />
      {`${pendingCount} Pending Approval`}
    </Badge>
  );
}
