import { Fragment } from "react";
import { Formik } from "formik";
import { Button, Form, Pane } from "components/materials";
import t from "helpers/translate";
import { first, get, set } from "lodash";
import { majorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import { Header } from "./Header";
import { PersistStep } from "./PersistStep";
import { STEPS } from "./utils";

const initialValues = (project) => {
  const toDateDraw = first(get(project, "onboardingData.draws", []));
  return {
    targetAmount: get(toDateDraw, "targetAmount", 0),
    name: "To-date",
  };
};

const validate = ({ targetAmount }) => {
  const errors = {};
  if (isBlank(targetAmount)) {
    set(errors, `targetAmount`, "Please enter a draw amount");
  }
  return errors;
};

export function ToDateDrawStep({
  goBack,
  loading,
  navigateToStep,
  updateOnboardingData,
  project,
}) {
  const values = initialValues(project);

  const getMutationData = (draw) => {
    return {
      draws: [draw],
    };
  };

  return (
    <Formik
      initialValues={values}
      onSubmit={(draw) =>
        updateOnboardingData(getMutationData(draw)).then(() =>
          navigateToStep(STEPS.CONFIRM_PROJECT_SETUP)
        )
      }
      validate={validate}
    >
      {(formikProps) => (
        <Fragment>
          <Header
            header={t("onboardingWizard.toDateDraw.header")}
            subheader={t("onboardingWizard.toDateDraw.subheader")}
          />
          <Form.Input name="targetAmount" type="currency" width={200} />

          <Pane marginTop={majorScale(3)}>
            <Button
              onClick={() => {
                goBack();
              }}
            >
              Back
            </Button>
            <Button
              appearance="primary"
              isLoading={loading}
              onClick={formikProps.handleSubmit}
              purpose="onboarding-wizard finalize draws"
              marginLeft={majorScale(2)}
            >
              Next
            </Button>
          </Pane>
          <PersistStep
            mutationData={getMutationData(formikProps.values)}
            projectId={project.id}
          />
        </Fragment>
      )}
    </Formik>
  );
}
