import PropTypes from "prop-types";
import { Pane, Text } from "components/materials";

const Group = ({ children }) => <Pane>{children}</Pane>;
const Value = ({ children }) => <Text>{children}</Text>;
const Label = ({ children }) => <Text>{children}</Text>;

const Statistic = ({ children }) => {
  return <Pane>{children}</Pane>;
};

Statistic.propTypes = {
  children: PropTypes.node,
};

// TODO: remove dot notation
Statistic.Group = Group;
Statistic.Value = Value;
Statistic.Label = Label;

export default Statistic;
