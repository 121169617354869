import { Fragment, useState } from "react";
import { CaretRightIcon, CaretDownIcon } from "evergreen-ui";
import { Grid, Heading, Text, Button, Link } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { formatDateTime } from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { get } from "lodash";
import { majorScale } from "helpers/utilities";
import {
  getTransactionsForSource,
  totalForField,
  transactionsForField,
} from "helpers/ledgerHelpers";

export default function RequestSummary({
  getDocumentPath,
  lineItem,
  requestedAmountForm,
}) {
  const { requestedAmount } = lineItem;

  return (
    <Fragment>
      <Heading flexGrow="1">Amount Requested</Heading>
      <Grid marginTop={majorScale(3)}>
        <FieldSummary
          lineItem={lineItem}
          getDocumentPath={getDocumentPath}
          field="grossRequestedAmount"
          label="Amount Requested (Gross)"
        />
      </Grid>
      <Grid marginTop={majorScale(3)}>
        <FieldSummary
          lineItem={lineItem}
          getDocumentPath={getDocumentPath}
          field="retainageAmount"
          label="Retainage Amount"
        />
      </Grid>
      <Grid marginTop={majorScale(3)}>
        <Grid.Row>
          <Grid.Column columns={3} textAlign="right" borderTop="1px solid #ccc">
            <Text data-testid="netRequestedAmount" fontWeight="bold">
              {formatCurrency(requestedAmount)}
            </Text>
          </Grid.Column>
          <Grid.Column columns={12}>
            <Text fontWeight="bold">Requested this Draw (Net)</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column columns={15} textAlign="right">
            {requestedAmountForm}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
}

function FieldSummary({ getDocumentPath, lineItem, field, label }) {
  return (
    <Fragment>
      <Heading.SubHeading flexGrow="1" marginTop={majorScale(3)}>
        {label}
      </Heading.SubHeading>
      <EditSummary
        hideIfNone
        lineItem={lineItem}
        field={field}
        label="via Prior Changes"
      />
      <EditSummary
        hideIfNone
        getDocumentPath={getDocumentPath}
        lineItem={lineItem}
        field={field}
        source="draw_summary"
        label="via Draw Summary"
      />
      <EditSummary
        hideIfNone
        getDocumentPath={getDocumentPath}
        lineItem={lineItem}
        field={field}
        source={["document", "invoice", "pay_application"]}
        label="via Documentation"
      />
      <EditSummary
        lineItem={lineItem}
        field={field}
        source="user"
        label="via Manual Edits"
      />
      <Grid.Row>
        <Grid.Column columns={3} textAlign="right" borderTop="1px solid #ccc">
          <Text fontWeight="bold" data-testid={field}>
            {formatCurrency(lineItem[field])}
          </Text>
        </Grid.Column>
        <Grid.Column columns={12}>
          <Text fontWeight="bold">{label}</Text>
        </Grid.Column>
      </Grid.Row>
    </Fragment>
  );
}

function EditSummary({
  lineItem,
  field,
  source,
  label,
  hideIfNone,
  getDocumentPath,
}) {
  const [showEdits, setShowEdits] = useState(false);
  const edits = transactionsForField(
    getTransactionsForSource(lineItem.transactions, source),
    field
  );
  const hasEdits = edits.length > 0;
  const editTotal = totalForField(edits, field);

  if (hideIfNone && !hasEdits) return null;

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Column columns={3} textAlign="right">
          <Text data-testid={`${source}-edits`}>
            {formatCurrency(editTotal)}
          </Text>
        </Grid.Column>
        <Grid.Column columns={12}>
          {hasEdits ? (
            <Button
              appearance="minimal"
              height="extraSmall"
              onClick={() => setShowEdits(!showEdits)}
            >
              {showEdits ? <CaretDownIcon /> : <CaretRightIcon />}
              <Text>{label}</Text>
            </Button>
          ) : (
            <Text>{label}</Text>
          )}
        </Grid.Column>
      </Grid.Row>
      {showEdits &&
        edits.map((transaction) => (
          <Grid.Row key={transaction.id}>
            <Grid.Column columns={3} textAlign="right">
              <Text fontStyle="italic">
                {formatCurrency(totalForField(transaction, field))}
              </Text>
            </Grid.Column>
            <Grid.Column columns={12}>
              <Text fontStyle="italic">
                <TransactionSource
                  transaction={transaction}
                  getDocumentPath={getDocumentPath}
                />{" "}
                on {formatDateTime(transaction.insertedAt)}
                {!isBlank(transaction.memo) && (
                  <Fragment>
                    <br />
                    Description: {transaction.memo.trim()}
                  </Fragment>
                )}
              </Text>
            </Grid.Column>
          </Grid.Row>
        ))}
    </Fragment>
  );
}

const DOCUMENT_SOURCES = [
  "document",
  "invoice",
  "pay_application",
  "draw_summary",
];

function TransactionSource({ transaction, getDocumentPath }) {
  if (transaction.user) {
    return transaction.user.fullName;
  }

  const document =
    transaction.document ?? transaction.drawSummary ?? transaction.invoice;
  if (DOCUMENT_SOURCES.includes(transaction.sourceType)) {
    return (
      <Fragment>
        {getDocumentPath && document ? (
          <Link
            purpose="line-item-slideout document"
            to={getDocumentPath(document.id)}
          >
            {t(`documentTypeName.${get(document, "type")}`)}
          </Link>
        ) : (
          <Text>Deleted Document</Text>
        )}{" "}
        {get(document, "vendor.name") && (
          <Text>
            {t(`drawLineItem.ledgerDocumentLabel`, {
              vendorName: document.vendor.name,
            })}
          </Text>
        )}
      </Fragment>
    );
  }

  return "System Update";
}
