import { Modal, Pane, Text } from "components/materials";

export function ResetPaymentModal({
  handleReset,
  loading,
  onClose,
  resetWarningText,
}) {
  return (
    <Modal
      hasFooter
      isConfirmLoading={loading}
      onClose={onClose}
      onConfirm={handleReset}
      open
      size="small"
      title="Reset Posted Payments"
    >
      <Modal.Content>
        <Pane display="flex" flexDirection="column">
          <Text>{resetWarningText}</Text>
        </Pane>
      </Modal.Content>
    </Modal>
  );
}
