export const PROJECT_SETUP_STEP = {
  COMPLETE_HISTORICAL_DRAWS: "COMPLETE_HISTORICAL_DRAWS",
  CREATE_DRAW: "CREATE_DRAW",
  CREATE_FIRST_AGREEMENT: "CREATE_FIRST_AGREEMENT",
  CREATE_PROJECT: "CREATE_PROJECT",
  REVIEW_FORM_CONFIGURATIONS: "REVIEW_FORM_CONFIGURATIONS",
  REVIEW_LINE_ITEM_TYPES: "REVIEW_LINE_ITEM_TYPES",
  REVIEW_RULES: "REVIEW_RULES",
  REVIEW_USER_ACCESS: "REVIEW_USER_ACCESS",
};
