import { Banner, Link } from "components/materials";
import { isValidInsufficientFunds } from "helpers/projectionHelpers";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";

function ProjectionWarningBanner({ projectId, translationKey }) {
  return (
    <Banner
      backgroundColor="FloralWhite"
      icon="warningSignIcon"
      mainText={t(
        `projection.fundingSourceWarnings.${translationKey}.mainText`
      )}
      marginX={majorScale(6)}
      marginBottom={majorScale(1)}
      secondaryText={t(
        `projection.fundingSourceWarnings.${translationKey}.secondaryText`
      )}
    >
      <Link
        href={`/projects/${projectId}/settings?settings=fundingSources`}
        marginLeft={minorScale(1)}
      >
        {t(`projection.fundingSourceWarnings.${translationKey}.linkText`)}
      </Link>
    </Banner>
  );
}

export function FundingSourceProjectionWarning({
  projection,
  showUsesOfFundsWarning,
}) {
  const { fundingSources, insufficientFunds, project } = projection;
  const { automaticAllocationEnabled } = project;
  const showAllocationWarning =
    fundingSources.length > 1 && !automaticAllocationEnabled;

  if (fundingSources.length === 0)
    return (
      <ProjectionWarningBanner
        projectId={project.id}
        translationKey="noSources"
      />
    );
  if (showAllocationWarning)
    return (
      <ProjectionWarningBanner
        projectId={project.id}
        translationKey="noAllocation"
      />
    );
  if (showUsesOfFundsWarning)
    return (
      <ProjectionWarningBanner
        projectId={project.id}
        translationKey="usesOfFundsMisallocation"
      />
    );
  if (isValidInsufficientFunds(insufficientFunds))
    return (
      <ProjectionWarningBanner
        projectId={project.id}
        translationKey="insufficientFunds"
      />
    );

  return null;
}
