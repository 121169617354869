import { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { EvergreenRadio } from "components/materials";

export const Radio = memo(
  forwardRef(function Radio({ checked, ...rest }, ref) {
    return (
      <EvergreenRadio
        checked={checked}
        data-checked={checked ? "true" : "false"}
        ref={ref}
        {...rest}
      />
    );
  })
);

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
};
