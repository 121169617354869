import { useContext } from "react";
import PropTypes from "prop-types";
import { ChevronLeftIcon, ChevronRightIcon } from "evergreen-ui";
import { IconButton, Pane } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { getButtonBackgroundStylingProps } from "helpers/themeHelpers";

const ScrollButtons = ({ scrollRefs }) => {
  const theme = useContext(ThemeContext);
  let interval;

  const handleStartScrollLeft = () => {
    interval = setInterval(() => {
      scrollRefs.forEach((ref) => {
        ref.scrollLeft -= 5;
      });
    }, 10);
  };

  const handleStopScrollLeft = () => {
    clearInterval(interval);
  };

  const handleStartScrollRight = () => {
    interval = setInterval(() => {
      scrollRefs.forEach((ref) => {
        ref.scrollLeft += 5;
      });
    }, 10);
  };

  const handleStopScrollRight = () => {
    clearInterval(interval);
  };

  const overrideButtonProps = {
    ...getButtonBackgroundStylingProps(theme.colors.gray100),
    appearance: "minimal",
    color: theme.colors.gray900,
    border: `1px solid ${theme.colors.gray300}`,
  };

  return (
    <Pane display="flex">
      <IconButton
        icon={ChevronLeftIcon}
        marginRight={majorScale(1)}
        onMouseDown={handleStartScrollLeft}
        onMouseUp={handleStopScrollLeft}
        type="button"
        {...overrideButtonProps}
      />
      <IconButton
        icon={ChevronRightIcon}
        onMouseDown={handleStartScrollRight}
        onMouseUp={handleStopScrollRight}
        type="button"
        {...overrideButtonProps}
      />
    </Pane>
  );
};

ScrollButtons.propTypes = {
  scrollRefs: PropTypes.array,
};

export default ScrollButtons;
