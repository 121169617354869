import { Fragment } from "react";
import { BoxIcon } from "evergreen-ui";
import {
  UnorderedList,
  ListItem,
  Pane,
  Link,
  Text,
} from "components/materials";
import { get } from "lodash";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";

export default function Documentation({ getDocumentPath, lineItem }) {
  // (Note that adjustments won't have a vendor name)
  const supportingExpenses = lineItem.expenses.filter(
    ({ document }) =>
      !!get(document, "vendor.name") &&
      get(document, "type") !== DOCUMENT_TYPE_NAME.DRAW_SUMMARY
  );
  if (supportingExpenses.length === 0) {
    return <Text>No supporting documents</Text>;
  }

  return (
    <UnorderedList listStyleType="none">
      {supportingExpenses.map((expense) => (
        <ListItem key={expense.id}>
          {!expense.document ? (
            formatCurrency(-expense.amount)
          ) : (
            <Fragment>
              <Pane display="inline">
                {getDocumentPath ? (
                  <Link
                    purpose="line-item-slideout document"
                    to={getDocumentPath(expense.document.id)}
                  >
                    {t(`documentTypeName.${get(expense.document, "type")}`)}
                  </Link>
                ) : (
                  <Text>
                    {t(`documentTypeName.${get(expense.document, "type")}`)}
                  </Text>
                )}{" "}
                <Text>
                  {t(
                    `drawLineItem.supportingDocumentLabel${
                      expense.isBackup ? "Backup" : ""
                    }`,
                    {
                      vendorName: expense.document.vendor.name,
                    }
                  )}{" "}
                </Text>
              </Pane>
              <Pane display="inline-flex" alignItems="center">
                <Text
                  fontStyle={expense.isBackup ? "italic" : undefined}
                  color={expense.isBackup && "muted"}
                >
                  {formatCurrency(-expense.amount)}
                </Text>
                {expense.isBackup && <BoxIcon marginX={majorScale(1)} />}
              </Pane>
            </Fragment>
          )}
        </ListItem>
      ))}
    </UnorderedList>
  );
}
