import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ContentLayout } from "components/templates";
import { Button, Loadable, Pane, Text } from "components/materials";
import { get } from "lodash";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

export const INVITATION_QUERY = gql`
  query InvitationQuery {
    user {
      id
      email
      organization {
        id
        name
      }
      pendingInvitations {
        id
        organization {
          id
          name
        }
      }
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($invitationToken: String!) {
    registerExistingUser(invitationToken: $invitationToken) {
      id
    }
  }
`;

export function AcceptInvitation({ history, match }) {
  const { invitationToken } = match.params;
  const { data, loading } = useQuery(INVITATION_QUERY, {
    variables: { invitationToken },
  });
  const [registerExisting, result] = useMutation(ACCEPT_INVITATION, {
    // Use an explicit refetch query since the user record
    // returned by the mutation isn't the same one as the user logged in
    refetchQueries: [{ query: INVITATION_QUERY }],
    // after mutation fires, redirect user to portfolio page
    onCompleted: () => history.push("/"),
  });
  const organizationName = get(data, "user.organization.name");

  if (loading || result.loading) return <Loadable loading />;
  return (
    <ContentLayout
      fixedWidth
      title={t("acceptInvitation.title", { organizationName })}
    >
      <Text>{t("acceptInvitation.header", { organizationName })}</Text>
      <Pane marginTop={majorScale(2)}>
        <Button
          appearance="primary"
          onClick={() => registerExisting({ variables: { invitationToken } })}
          purpose="invitation accept"
        >
          Accept Invitation
        </Button>
      </Pane>
    </ContentLayout>
  );
}
