import { useEffect, useMemo, useState } from "react";
import {
  COMPARATORS,
  filterItems,
  toBase64,
} from "components/materials/FastDataTable";
import { countBy, flatten, isEmpty } from "lodash";
import { filterFields } from "components/templates/PortfolioInsights/helpers";
import { PROJECT_STATUS_TYPE } from "helpers/enums";

const DEFAULT_FILTERED_PROJECT_TYPES = {
  [PROJECT_STATUS_TYPE.ACTIVE]: "Active",
  [PROJECT_STATUS_TYPE.PREDEVELOPMENT]: "Pre-Development",
  [PROJECT_STATUS_TYPE.UNDER_CONTRACT]: "Under Contract",
};

function getStartingFilterConfig(projects) {
  const projectsByType = countBy(projects, "status");

  const filters = flatten(
    Object.entries(
      DEFAULT_FILTERED_PROJECT_TYPES
    ).map(([snakeCaseValue, humanReadableValue]) =>
      projectsByType[snakeCaseValue] ? [humanReadableValue] : []
    )
  );

  return filters.length > 0
    ? [
        {
          enum: filters,
          input: filters,
          key: "projectStatus",
          operator: COMPARATORS.EXACT.value,
        },
      ]
    : [];
}

export function useFilteredProjects(
  projects,
  filterableFields = filterFields,
  startingFilter = {}
) {
  const startingFilterConfig = isEmpty(startingFilter)
    ? getStartingFilterConfig(projects)
    : startingFilter;
  const [filterConfig, setFilterConfig] = useState(startingFilterConfig);

  // making sure the starting config is set properly after all projects load
  // projects should always be the same after all the projects have been loaded so this should not repeat
  useEffect(
    () => {
      if (!isEmpty(projects)) {
        setFilterConfig(startingFilterConfig);
      }
    },
    // eslint-disable-next-line
    [projects]
  );

  const filteredProjects = useMemo(
    () => filterItems(projects, filterableFields, filterConfig),
    [projects, filterConfig, filterableFields]
  );

  const filteredTeams = flatten(
    filteredProjects.map((project) => project.team)
  ).filter((team) => team !== null);

  const serializedFilterConfig = toBase64({ filterConfig });

  return [
    filterConfig,
    filteredProjects,
    filteredTeams,
    setFilterConfig,
    serializedFilterConfig,
  ];
}
