import { useEffect } from "react";
import { Loadable } from "components/materials";
import { useCreateInvoice, CreateInvoice } from "features/create-invoice";

export default function CreateInvoiceTab({
  selected = false,
  setModalProps,
  enableTab,
  projectId,
  drawId,
  onCancel,
  onSubmit,
}) {
  const createInvoice = useCreateInvoice({ drawId, projectId, onSubmit });

  useEffect(() => {
    if (createInvoice.templates.length > 0) {
      enableTab();
    }
  }, [enableTab, createInvoice.templates]);

  useEffect(() => {
    if (selected && createInvoice.lineItems.length > 0) {
      return setModalProps({ size: "large" });
    }

    return () => {};
  }, [setModalProps, selected, createInvoice.lineItems]);

  if (!selected) return null;
  if (createInvoice.loading) return <Loadable />;

  return (
    <CreateInvoice
      {...createInvoice}
      projectId={projectId}
      drawId={drawId}
      onCancel={onCancel}
      loading={createInvoice.submitLoading}
    />
  );
}
