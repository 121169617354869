import { Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

const SidebarSection = ({ children, ...props }) => {
  return (
    <Pane paddingX={majorScale(2)} marginTop={majorScale(2)} {...props}>
      {children}
    </Pane>
  );
};

export default SidebarSection;
