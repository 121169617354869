import { useContext, useEffect } from "react";
import { Formik } from "formik";
import { Accordion, Form } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { configurationPanelStyles } from "./helpers";
import { EditConfigurationButtons } from "./EditConfigurationButtons";

export const PANEL_KEY = "mri";

export function Mri({
  dirtyPanels,
  setPanelDirty,
  onSubmit,
  loading,
  organization,
  expandedPanelKeys,
  toggle,
}) {
  return (
    <Formik
      initialValues={{
        externalRetainageCode: organization.externalRetainageCode,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(form) => (
        <MriPanel
          dirtyPanels={dirtyPanels}
          setPanelDirty={setPanelDirty}
          form={form}
          loading={loading}
          toggle={toggle}
          expandedPanelKeys={expandedPanelKeys}
        />
      )}
    </Formik>
  );
}

function MriPanel({
  form,
  loading,
  expandedPanelKeys,
  toggle,
  dirtyPanels,
  setPanelDirty,
}) {
  const dirty = dirtyPanels[PANEL_KEY];
  const open = !!expandedPanelKeys[PANEL_KEY];

  const { hasPermission } = useContext(UserContext);

  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(PANEL_KEY, isDirty);
    }
    return undefined;
  }, [dirty, form, setPanelDirty]);

  return (
    <Accordion.Panel
      panelKey={PANEL_KEY}
      title="MRI"
      onClick={() => toggle(PANEL_KEY)}
      open={open}
      actionContent={
        <EditConfigurationButtons dirty={dirty} form={form} loading={loading} />
      }
      {...configurationPanelStyles}
    >
      <Form.Input
        label="External Retainage Code"
        disabled={!hasPermission(PERMISSION_ACTION.PUSH_TO_MRI)}
        name="externalRetainageCode"
        width="65%"
      />
    </Accordion.Panel>
  );
}
