import gql from "graphql-tag";

export default gql`
  fragment DocumentReviewActivityFragment on DocumentReview {
    id
    reviewType
    body
    insertedAt
    user {
      id
      fullName
    }
    mentions {
      id
      user {
        id
        fullName
      }
    }
  }
`;
