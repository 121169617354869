import { useState } from "react";
import { v4 as uuid } from "uuid";
import gql from "graphql-tag";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Button, Combobox, Loadable, Pane } from "components/materials";
import t from "helpers/translate";
import { get } from "lodash";
import { majorScale } from "helpers/utilities";
import { copyLineItems, importPreview } from "images";
import { OnboardingWizardDecisionCard } from "./OnboardingWizardDecisionCard";
import {
  getBudgetHeaders,
  getMatchingProjectTypes,
  STEPS,
  TEMPLATE_QUERY,
} from "./utils";
import { Header } from "./Header";

const COPY = gql`
  query OnboardingBudgetCopyQuery($projectId: String!) {
    project(id: $projectId) {
      id
      divisions {
        id
        scopeId
        name
        lineItems {
          id
          scopeId
          name
        }
      }
      setupComplete
      setupData
      status
    }
  }
`;

export function CopyLineItemsCard({
  matchingProjectTypes,
  navigateToStep,
  updateOnboardingData,
}) {
  const [selectedProject, setSelectedProject] = useState(
    matchingProjectTypes[0]
  );

  const [copy] = useLazyQuery(COPY, {
    onCompleted: ({ project }) => {
      const divisionsToCopy = !project.setupComplete
        ? get(project, "setupData.onboarding_data.budget.divisions", [])
        : project.divisions;
      const divisions = divisionsToCopy.map((division) => {
        const lineItems = division.lineItems.map((lineItem) => {
          return { ...lineItem, id: uuid() };
        });
        return { ...division, lineItems, id: uuid() };
      });

      updateOnboardingData({
        budget: { divisions },
        useRabbetBudget: false,
      }).then(() => navigateToStep(STEPS.ENTER_BUDGET));
    },
  });

  return (
    <OnboardingWizardDecisionCard
      disableCardClick
      header={t("onboardingWizard.budgetSetupStep.copyHeader")}
      subHeader={t("onboardingWizard.budgetSetupStep.copySubheader")}
      buttonText={t("onboardingWizard.budgetSetupStep.copyButton")}
      image={copyLineItems}
      onClick={() => copy({ variables: { projectId: selectedProject.id } })}
      combobox={() => (
        <Combobox
          alignSelf="stretch"
          initialSelectedItem={selectedProject}
          items={matchingProjectTypes}
          itemToString={(item) => (item ? item.name : "")}
          onChange={(project) => setSelectedProject(project)}
          width="100%"
        />
      )}
    />
  );
}

export function CopyOrBuildBudgetStep({
  goBack,
  project,
  navigateToStep,
  updateOnboardingData,
}) {
  const { data, loading } = useQuery(TEMPLATE_QUERY, {
    variables: { projectId: project.id },
  });

  const matchingProjectTypes = loading
    ? []
    : getMatchingProjectTypes(get(data, "project"));

  const { header, subheader } = getBudgetHeaders(
    get(project, "onboardingData.projectStatus")
  );

  if (loading) return <Loadable loading={loading} />;
  return (
    <Pane>
      <Header header={header} subheader={subheader} />
      <Pane display="flex" marginY={majorScale(4)} justifyContent="center">
        <OnboardingWizardDecisionCard
          backgroundColor
          buttonPrimary
          buttonText={t("onboardingWizard.budgetSetupStep.budgetBuilderButton")}
          header={t("onboardingWizard.budgetSetupStep.budgetBuilderHeader")}
          subHeader={t(
            "onboardingWizard.budgetSetupStep.budgetBuilderSubheader"
          )}
          image={importPreview}
          onClick={() =>
            updateOnboardingData({
              budget: [],
              useRabbetBudget: false,
            }).then(() => navigateToStep(STEPS.ENTER_BUDGET))
          }
        />
        <CopyLineItemsCard
          matchingProjectTypes={matchingProjectTypes}
          navigateToStep={navigateToStep}
          project={project}
          updateOnboardingData={updateOnboardingData}
        />
      </Pane>
      <Button
        onClick={() => {
          goBack();
        }}
        marginTop={majorScale(2)}
        marginBottom={majorScale(4)}
      >
        Back
      </Button>
    </Pane>
  );
}
