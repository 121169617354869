import { Button, Divider, FileViewer, Pane, Text } from "components/materials";

export default function Preview({ isLoading, isValid, onSubmit, document }) {
  if (document) {
    return (
      <Document
        document={document}
        isValid={isValid}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <Placeholder isValid={isValid} isLoading={isLoading} onSubmit={onSubmit} />
  );
}

function Placeholder({ isLoading, isValid, onSubmit }) {
  return (
    <Pane
      background="overlay"
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100%"
    >
      {isLoading ? (
        <Text>Loading...</Text>
      ) : (
        <Pane>
          <Text color="white">
            Preview your document when details are complete
          </Text>
          <Divider opacity={0} />
          <Button
            content="Preview"
            disabled={!isValid}
            isLoading={isLoading}
            onClick={onSubmit}
            purpose="create-document-form preview"
          />
        </Pane>
      )}
    </Pane>
  );
}

function Document({ document, isValid, isLoading, onSubmit }) {
  return (
    <Pane>
      <Button
        content="Refresh Preview"
        disabled={!isValid}
        isLoading={isLoading}
        onClick={onSubmit}
        purpose="create-document-form preview refresh"
      />
      <FileViewer file={document} />
    </Pane>
  );
}
