import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";

export function ChartTemplate({
  backgroundColor,
  chartId,
  datasets,
  height,
  isStacked,
  labels,
  setChartDownloadImage,
  suggestedMax = 50000000,
  type,
  valueFormatter,
}) {
  const ref = useRef(null);
  const chart = useRef(null);

  function updateData(chart, labels, datasets) {
    chart.data.labels = labels;
    chart.data.datasets = datasets;
    chart.update();
  }

  useEffect(() => {
    const ctx = ref.current.getContext("2d");
    ref.current.height = height;
    /* eslint-disable no-new */
    chart.current = new Chart(ctx, {
      type,
      data: {
        labels,
        datasets,
      },
      options: {
        animation: {
          onComplete: () => {
            setChartDownloadImage &&
              setChartDownloadImage(chart.current.toBase64Image());
          },
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              beginAtZero: true,
              stacked: !!isStacked,
              suggestedMax,
              ticks: {
                beginAtZero: true,
                callback: valueFormatter,
              },
            },
          ],
          xAxes: [
            {
              stacked: !!isStacked,
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: ({ yLabel }) => {
              return valueFormatter(yLabel);
            },
          },
        },
      },
      plugins: [
        {
          beforeDraw: (chart) => {
            const ctx = chart.canvas.getContext("2d");
            ctx.fillStyle = backgroundColor;
            ctx.fillRect(0, 0, chart.width, chart.height);
          },
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chart.current) return;
    updateData(chart.current, labels, datasets);
  }, [datasets, labels]);

  return <canvas id={chartId} ref={ref} />;
}

ChartTemplate.propTypes = {
  backgroundColor: PropTypes.string,
  valueFormatter: PropTypes.func,
};

ChartTemplate.defaultProps = {
  backgroundColor: "#FFF",
  valueFormatter: (value) => value,
};
