import { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { ChangeProjectPicture } from "components/containers";
import { Form, Pane } from "components/materials";
import { chunk, values } from "lodash";
import { UserContext } from "helpers/behaviors";
import {
  ADDRESS_STATE,
  CUSTOM_FIELD_TYPE,
  DRAW_SUMMARY_TEMPLATE_TYPE,
  DRAW_UPDATE_SOURCE_OPTION,
  PERMISSION_ACTION,
  PROJECT_STATUS_TYPE,
} from "helpers/enums";
import { userAssignablePermissions } from "helpers/permissionsHelpers";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import getOptions from "helpers/getOptions";

const PRE_ACTIVE_PROJECT_STATUSES = [
  PROJECT_STATUS_TYPE.PREDEVELOPMENT,
  PROJECT_STATUS_TYPE.UNDER_CONTRACT,
];

const TYPE_MAPPING = {
  [CUSTOM_FIELD_TYPE.INTEGER]: "integer",
  [CUSTOM_FIELD_TYPE.MONEY]: "currency",
  [CUSTOM_FIELD_TYPE.NUMBER]: "number",
  [CUSTOM_FIELD_TYPE.PERCENT]: "percentage",
  [CUSTOM_FIELD_TYPE.TEXT]: "text",
};

const getTemplateOptions = (hasPermission) => {
  const validOptions = hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS)
    ? values(DRAW_SUMMARY_TEMPLATE_TYPE)
    : values(DRAW_SUMMARY_TEMPLATE_TYPE).filter(
        (template) => template !== DRAW_SUMMARY_TEMPLATE_TYPE.SUPER_LINE_ITEMS
      );

  return [{ key: "default", text: "Default", value: undefined }].concat(
    validOptions.map((type) => ({
      key: type,
      text: t(`drawSummaryTemplateType.${type}`),
      value: type,
    }))
  );
};

const getStatusOptions = (project) => {
  const isPreactive = PRE_ACTIVE_PROJECT_STATUSES.includes(project.status);
  const disablePreActiveStatuses =
    !isPreactive && (project.hasPayApplication || project.hasHardCostRequested);
  const enabledProjectStatuses = disablePreActiveStatuses
    ? values(PROJECT_STATUS_TYPE).filter(
        (status) => !PRE_ACTIVE_PROJECT_STATUSES.includes(status)
      )
    : values(PROJECT_STATUS_TYPE);
  return enabledProjectStatuses.map((type) => ({
    key: type,
    text: t(`projectStatus.${type}`),
    value: type,
  }));
};

const getUpdateSourceOptions = () =>
  values(DRAW_UPDATE_SOURCE_OPTION).map((option) => ({
    key: option,
    text: t(`drawUpdateSource.${option}`),
    value: option,
  }));

const nullOption = { key: "blank", value: null, text: "" };

const stateOptions = [nullOption].concat(
  values(ADDRESS_STATE).map((stateValue) => ({
    key: stateValue,
    value: stateValue,
    text: stateValue,
  }))
);

const getRegionOptions = (regions) =>
  [nullOption].concat(
    regions.map(({ id, region }) => ({
      key: id,
      value: id,
      text: region,
    }))
  );

const getTeamOptions = (teams) => [nullOption].concat(getOptions(teams));

const getProductTypeOptions = (types) =>
  [nullOption].concat(
    types.map(({ id, type }) => ({
      key: id,
      value: id,
      text: type,
    }))
  );

const getProjectTypeOptions = (types) =>
  types.map(({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  }));

const getDropdownOptions = (options) =>
  [{ key: "blankOption", text: "", value: null }].concat(
    options.map((value) => ({ key: value, text: value, value }))
  );

const ProjectDetailsForm = ({
  customFields,
  project,
  showAllFields,
  showCustomFields,
  showName,
}) => {
  const { hasPermission, isDeveloper } = useContext(UserContext);
  const { organization } = project;

  const orgPermissions = userAssignablePermissions(organization);

  const projectIDName = orgPermissions.AVID_AP_INTEGRATION
    ? "Entity"
    : "Custom ID";

  const needsDrawUpdateSource =
    hasPermission(
      PERMISSION_ACTION.UPDATE_REQUESTED_AMOUNT_WITH_DOCUMENTATION
    ) && hasPermission(PERMISSION_ACTION.IMPORT_DRAW_SUMMARY);

  const showTeamSelector =
    showAllFields && hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT);

  return (
    <Fragment>
      {(showAllFields || showName) && (
        <Form.Input
          label="Name"
          name="name"
          outerProps={{ marginBottom: majorScale(2) }}
          width="40%"
        />
      )}
      <Form.Input
        label="Street Address"
        name="streetAddress"
        outerProps={{ marginBottom: majorScale(2) }}
        width="65%"
      />
      <Form.Group aligned renderedChildrenDirectly>
        <Pane width="35%">
          <Form.Input label="City" name="city" />
        </Pane>
        <Pane width={100}>
          <Form.Select
            label="State/Province"
            name="stateValue"
            options={stateOptions}
            popoverMinWidth={85}
            width={100}
            fullBorder
          />
        </Pane>
        <Pane width="30%">
          <Form.Input label="Postal Code" name="zip" type="zip" />
        </Pane>
      </Form.Group>
      {(showAllFields || showCustomFields) &&
        chunk(customFields, 2).map((customFieldArray, arrayIndex) => (
          <Form.Group>
            {customFieldArray.map((customField, index) => {
              const fieldIndex = arrayIndex * 2 + index;
              if (customField.type === CUSTOM_FIELD_TYPE.BOOLEAN) {
                return (
                  <Form.Checkbox
                    label={customField.label}
                    name={`customFields.${fieldIndex}.value`}
                  />
                );
              }
              if (customField.type === CUSTOM_FIELD_TYPE.DATE) {
                return (
                  <Form.DateInput
                    label={customField.label}
                    name={`customFields.${fieldIndex}.value`}
                  />
                );
              }
              if (customField.type === CUSTOM_FIELD_TYPE.DROPDOWN) {
                return (
                  <Form.Select
                    label={customField.label}
                    name={`customFields.${fieldIndex}.value`}
                    options={getDropdownOptions(customField.options)}
                  />
                );
              }
              return (
                <Form.Input
                  label={customField.label}
                  name={`customFields.${fieldIndex}.value`}
                  type={TYPE_MAPPING[customField.type]}
                />
              );
            })}
          </Form.Group>
        ))}
      <Form.Group aligned renderedChildrenDirectly>
        <Pane width="24%">
          <Form.DateInput
            label={`Expected ${
              isDeveloper ? "Construction" : "Project"
            } Start Date`}
            name="startDate"
            showError
          />
        </Pane>
        {showAllFields && (
          <Pane width="24%">
            <Form.DateInput
              label="Loan Maturity Date"
              name="loanMaturityDate"
            />
          </Pane>
        )}
        <Pane width="22%">
          <Form.Input
            label="Expected Project Length (mo)"
            name="expectedProjectLength"
            type="integer"
          />
        </Pane>
      </Form.Group>
      <Form.Group aligned renderedChildrenDirectly>
        <Pane width="22%">
          <Form.Input label={projectIDName} name="customId" />
        </Pane>
        <Pane width="22%">
          <Form.Input label="Area (sqft)" name="squareFeet" type="number" />
        </Pane>
        <Pane width="22%">
          <Form.Input label="Parcel Size (Acres)" name="acres" type="number" />
        </Pane>
      </Form.Group>
      <Form.Group aligned renderedChildrenDirectly>
        {showTeamSelector && (
          <Pane width="25%">
            <Form.Select
              fullBorder
              label="Team"
              name="teamId"
              options={getTeamOptions(organization.teams)}
            />
          </Pane>
        )}
        <Pane width="25%">
          <Form.Select
            fullBorder
            label="Region"
            name="projectRegionId"
            options={getRegionOptions(organization.projectRegions)}
          />
        </Pane>
        {showAllFields && (
          <Pane marginLeft={majorScale(2)} width="25%">
            <Form.Select
              disabled
              label="Project Type"
              name="projectTypeId"
              options={getProjectTypeOptions(organization.projectTemplates)}
            />
          </Pane>
        )}
        <Pane marginLeft={majorScale(2)} width="25%">
          <Form.Select
            label="Product Type"
            name="productTypeId"
            options={getProductTypeOptions(organization.productTypes)}
          />
        </Pane>
      </Form.Group>
      {showAllFields && (
        <Form.Group aligned marginTop={majorScale(2)} renderedChildrenDirectly>
          <Pane width="25%">
            <Form.Select
              fullBorder
              label="Project Status"
              name="status"
              options={getStatusOptions(project)}
            />
          </Pane>
          <Pane marginLeft={majorScale(2)} width="25%">
            <Form.Select
              fullBorder
              label="Draw Summary Template"
              name="drawSummaryTemplate"
              options={getTemplateOptions(hasPermission)}
            />
          </Pane>
        </Form.Group>
      )}
      {needsDrawUpdateSource && (
        <Form.Group>
          <Pane width="30%">
            <Form.Select
              fullBorder
              label="Do you receive draw summaries for this project?"
              name="drawUpdateSource"
              options={getUpdateSourceOptions()}
            />
          </Pane>
        </Form.Group>
      )}
      <Form.Group>
        <Pane marginTop={majorScale(1)}>
          <ChangeProjectPicture />
        </Pane>
      </Form.Group>
    </Fragment>
  );
};

ProjectDetailsForm.propTypes = {
  customFields: PropTypes.arrayOf(PropTypes.object),
  disableStatus: PropTypes.bool,
  showAllFields: PropTypes.bool,
  showCustomFields: PropTypes.bool,
};

ProjectDetailsForm.defaultProps = {
  customFields: [],
};

export default ProjectDetailsForm;
