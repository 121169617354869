import { majorScale } from "helpers/utilities";

export const configurationPanelStyles = {
  contentStyles: {
    paddingX: majorScale(7),
    paddingY: majorScale(2),
    maxWidth: 800,
  },
  panelStyles: {
    paddingY: majorScale(2),
  },
  titleStyles: {
    height: majorScale(4),
    paddingRight: majorScale(4),
  },
};
