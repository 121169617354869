import { useState, Fragment } from "react";
import { CreateProjectModal } from "components/containers";
import { AddIcon } from "evergreen-ui";
import { Button } from "components/materials";

export function AddProjectButton(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      {showModal && <CreateProjectModal onClose={() => setShowModal(false)} />}
      <Button
        appearance="primary"
        iconBefore={AddIcon}
        onClick={() => setShowModal(true)}
        purpose="add-project open"
        {...props}
      >
        Add Project
      </Button>
    </Fragment>
  );
}
