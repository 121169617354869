import { useContext, Fragment } from "react";
import { CrossIcon } from "evergreen-ui";
import { AddLineItem } from "components/templates";
import {
  Button,
  Form,
  IconButton,
  Pane,
  Pill,
  Table,
  Text,
  Paragraph,
} from "components/materials";
import { FieldArray } from "formik";
import { find, includes, reduce, sumBy } from "lodash";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import unformatNumber from "helpers/unformatNumber";
import { add, subtract } from "helpers/math";
import { formatAdjustment } from "helpers/adjustments";

export function getEmptyTransaction() {
  return {
    lineItemId: null,
    amount: 0,
    initialAmount: 0,
    isNewLineItem: false,
  };
}

function getLineItemName({ division, name, number }) {
  const prefix = number ? `(${number}) ` : "";
  return `${prefix}${name} (${division.name})`;
}

function getNewLineItemName(lineItemName, divisionId, divisions) {
  const division = find(divisions, ({ id }) => id === divisionId);
  return `${lineItemName} (${division.name})`;
}

function getLineItemOptions({
  lineItems,
  currentTransactionLineItemIds,
  currentLineItemId,
}) {
  return lineItems
    .filter(
      ({ id }) =>
        !includes(currentTransactionLineItemIds, id) || id === currentLineItemId
    )
    .map((lineItem) => ({
      key: lineItem.id,
      text: getLineItemName(lineItem),
      value: lineItem.id,
    }));
}

export function NetAdjustmentPill({ transactions, showNetZeroAdjustment }) {
  const netAdjustment = sumBy(transactions, ({ amount }) =>
    unformatNumber(amount)
  );

  if (netAdjustment === 0 && !showNetZeroAdjustment) return null;

  const content = `Gross budget change: ${formatAdjustment(netAdjustment)}`;

  if (netAdjustment === 0)
    return (
      <Text fontWeight={500} color="muted" size={300}>
        {content}
      </Text>
    );

  return (
    <Pill color="yellow" textTransform="none">
      {content}
    </Pill>
  );
}

export function AdjustmentTransactions({
  adjustment,
  divisions,
  formik,
  isEditable,
  lineItemAdjustmentIds,
  lineItems,
  setHasChangesAffectingProjection,
  transactions,
}) {
  const theme = useContext(ThemeContext);

  const lineItemLibrary = lineItems.reduce(
    (library, lineItem) => ({
      ...library,
      [lineItem.id]: {
        ...lineItem,
        previousBudgetAmount: lineItem.previousLineItem
          ? lineItem.previousLineItem.budgetAmount
          : lineItem.originalBudgetAmount,
      },
    }),
    {}
  );

  const currentTransactionLineItemIds = transactions.reduce(
    (ids, { lineItemId }) => (lineItemId ? [...ids, lineItemId] : ids),
    []
  );

  const lineItemsOnOtherAdjustments = !adjustment
    ? Object.keys(lineItemAdjustmentIds)
    : reduce(
        lineItemAdjustmentIds,
        (lineItemIds, adjustmentIds, lineItemId) => {
          const hasOtherAdjustments =
            adjustmentIds.filter((id) => id !== adjustment.id).length > 0;
          return hasOtherAdjustments
            ? [...lineItemIds, lineItemId]
            : lineItemIds;
        },
        []
      );

  const showMultipleAdjustmentsGuidance = currentTransactionLineItemIds.some(
    (id) => includes(lineItemsOnOtherAdjustments, id)
  );

  const cellProps = {
    borderTop: "none",
    borderBottom: `1px solid ${theme.colors.gray500}`,
    textAlign: "right",
    paddingY: majorScale(1),
  };

  return (
    <FieldArray name="transactions">
      {({ push, remove }) => (
        <Fragment>
          <Table paddingBottom={0} hover={false} marginTop={-majorScale(1)}>
            <Table.Body>
              {transactions.map(
                (
                  {
                    amount,
                    divisionId,
                    initialAmount,
                    lineItemId,
                    lineItemName,
                    isNewLineItem,
                  },
                  index
                ) => {
                  const adjustmentDifference = subtract(
                    unformatNumber(amount),
                    initialAmount
                  );

                  const hasOtherAdjustments = includes(
                    lineItemsOnOtherAdjustments,
                    lineItemId
                  );

                  return (
                    <Table.Row key={`${lineItemId}${index}`}>
                      <Table.TextCell
                        id="lineItem"
                        {...cellProps}
                        textProps={{ fontWeight: theme.fontWeights.DEMI }}
                        textAlign="left"
                        width="32%"
                      >
                        {isEditable && !isNewLineItem && (
                          <Fragment>
                            <IconButton
                              appearance="minimal"
                              icon={CrossIcon}
                              marginRight={minorScale(1)}
                              onClick={() => {
                                setHasChangesAffectingProjection(true);
                                remove(index);
                              }}
                              type="button"
                            />
                            <Form.Select
                              name={`transactions.${index}.lineItemId`}
                              options={getLineItemOptions({
                                lineItems,
                                currentTransactionLineItemIds,
                                currentLineItemId: lineItemId,
                              })}
                              placeholder="Select Line Item..."
                              popoverMinWidth={500}
                              onChange={() => {
                                formik.setFieldValue(
                                  `transactions.${index}.initialAmount`,
                                  0
                                );
                                setHasChangesAffectingProjection(true);
                              }}
                            />
                          </Fragment>
                        )}
                        {isEditable && !!isNewLineItem && (
                          <Fragment>
                            <IconButton
                              appearance="minimal"
                              icon={CrossIcon}
                              marginRight={minorScale(1)}
                              onClick={() => {
                                remove(index);
                              }}
                              type="button"
                            />
                            {getNewLineItemName(
                              lineItemName,
                              divisionId,
                              divisions
                            )}
                            <Pill marginLeft={majorScale(2)} color="blue">
                              NEW
                            </Pill>
                          </Fragment>
                        )}
                        {!isEditable &&
                          getLineItemName(lineItemLibrary[lineItemId])}
                      </Table.TextCell>
                      <Table.TextCell {...cellProps} width="17%">
                        {lineItemId
                          ? formatCurrency(
                              lineItemLibrary[lineItemId]?.previousBudgetAmount
                            )
                          : null}
                      </Table.TextCell>
                      <Table.TextCell
                        {...cellProps}
                        textProps={{ fontWeight: theme.fontWeights.DEMI }}
                        width="17%"
                      >
                        {isEditable ? (
                          <Form.Input
                            name={`transactions.${index}.amount`}
                            textAlign="right"
                            type="currency"
                            onChange={() =>
                              setHasChangesAffectingProjection(true)
                            }
                          />
                        ) : (
                          formatCurrency(amount)
                        )}
                      </Table.TextCell>
                      <Table.TextCell {...cellProps} width="17%">
                        {lineItemId ? (
                          <Fragment>
                            {formatCurrency(
                              add(
                                lineItemLibrary[lineItemId]?.budgetAmount,
                                adjustmentDifference
                              )
                            )}
                            {hasOtherAdjustments && (
                              <Text
                                color={theme.colors.blue900}
                                size={300}
                                fontWeight={theme.fontWeights.DEMI}
                                marginLeft={3}
                              >
                                *
                              </Text>
                            )}
                          </Fragment>
                        ) : null}
                      </Table.TextCell>
                      <Table.TextCell {...cellProps} width="17%">
                        {lineItemId ? (
                          <Fragment>
                            {formatCurrency(
                              add(
                                lineItemLibrary[lineItemId]
                                  ?.balanceToFundAmount,
                                adjustmentDifference
                              )
                            )}
                            {hasOtherAdjustments && (
                              <Text
                                color={theme.colors.blue900}
                                size={300}
                                fontWeight={theme.fontWeights.DEMI}
                                marginLeft={3}
                              >
                                *
                              </Text>
                            )}
                          </Fragment>
                        ) : null}
                      </Table.TextCell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height={24}
          >
            <Pane flex="1 1 auto" marginTop={majorScale(3)}>
              {isEditable && (
                <Button
                  marginLeft={majorScale(2)}
                  onClick={() => {
                    setHasChangesAffectingProjection(true);
                    push(getEmptyTransaction());
                  }}
                >
                  Add Line Item Adjustment
                </Button>
              )}
              {isEditable && (
                <AddLineItem
                  divisions={divisions}
                  lineItems={lineItems}
                  unsavedNewLineItems={transactions.filter(
                    (transaction) => transaction.isNewLineItem
                  )}
                  onAddLineItem={(values) => {
                    push({
                      ...values,
                      isNewLineItem: true,
                      amount: 0,
                      initialAmount: 0,
                    });
                  }}
                />
              )}
            </Pane>
            <Paragraph flex="0 0 auto" color={theme.colors.blue900} size={300}>
              {showMultipleAdjustmentsGuidance &&
                "* this line item has multiple adjustments on this draw"}
            </Paragraph>
          </Pane>
          <Pane
            textAlign="right"
            marginTop={majorScale(1)}
            marginBottom={isEditable ? majorScale(2) : 0}
          >
            <NetAdjustmentPill
              transactions={transactions}
              showNetZeroAdjustment={isEditable}
            />
          </Pane>
        </Fragment>
      )}
    </FieldArray>
  );
}
