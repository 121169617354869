import PropTypes from "prop-types";
import { Pane } from "components/materials";

const WizardContent = ({ children, ...props }) => {
  return <Pane {...props}>{children}</Pane>;
};

WizardContent.propTypes = {
  children: PropTypes.node,
};

export default WizardContent;
