import { Fragment } from "react";
import {
  Button,
  Card,
  Pane,
  Paragraph,
  Progress,
  Shortener,
} from "components/materials";
import { uniq } from "lodash";
import { BRAND_COLORS } from "helpers/colors";
import { add } from "helpers/math";
import {
  getSerializedURLForProjectReport,
  trackPortfolioAnalysis,
} from "./helpers";
import { HeaderTextCell, TextCell } from ".";

export function TeamAllocation({
  history,
  numberOfProjects,
  organizationId,
  stylingProps,
  teams,
}) {
  const countForTeam = teams.reduce(
    (acc, team) => ({
      ...acc,
      [team.name]: acc[team.name] ? add(acc[team.name], 1) : 1,
    }),
    {}
  );

  const uniqueTeams = uniq(teams);
  const sortedTeams = [...uniqueTeams].sort(
    (teamA, teamB) => countForTeam[teamB.name] - countForTeam[teamA.name]
  );

  const assignedTotal = Object.keys(countForTeam).reduce(
    (acc, key) => acc + countForTeam[key],
    0
  );

  const noTeamAssignedTotal = numberOfProjects - assignedTotal;

  const maxNumProjects = Math.max(
    countForTeam[sortedTeams[0].name],
    noTeamAssignedTotal
  );

  return (
    <Fragment>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        Team Allocation
      </Paragraph>
      <Card {...stylingProps.card}>
        <Pane>
          <table {...stylingProps.table}>
            <thead>
              <tr>
                <HeaderTextCell>Team</HeaderTextCell>
                <HeaderTextCell colSpan={2}>Projects Assigned</HeaderTextCell>
              </tr>
            </thead>
            <tbody>
              {sortedTeams.map((team) => {
                const { name: teamName } = team;
                const navigateToProjects = () => {
                  trackPortfolioAnalysis(
                    organizationId,
                    "Go to Projects (filtered by team)"
                  );
                  return history.push(
                    getSerializedURLForProjectReport(
                      "team",
                      {
                        enum: [teamName],
                      },
                      organizationId
                    )
                  );
                };

                return (
                  <TeamAllocationRow
                    key={team.id}
                    {...team}
                    maxNumProjects={maxNumProjects}
                    navigateToProjects={navigateToProjects}
                    activeProjectCount={countForTeam[team.name]}
                  />
                );
              })}
              {noTeamAssignedTotal !== 0 && (
                <TeamAllocationRow
                  navigateToProjects={() => {
                    trackPortfolioAnalysis(
                      organizationId,
                      "Go to Projects (filtered by team)"
                    );
                    return history.push(
                      getSerializedURLForProjectReport("team", { enum: ["-"] })
                    );
                  }}
                  maxNumProjects={maxNumProjects}
                  name="No Team Assigned"
                  activeProjectCount={noTeamAssignedTotal}
                />
              )}
            </tbody>
          </table>
        </Pane>
      </Card>
    </Fragment>
  );
}

function TeamAllocationRow({
  navigateToProjects,
  activeProjectCount,
  maxNumProjects,
  name,
}) {
  return (
    <tr>
      <TextCell>
        <Shortener limit={17} size={300} text={name} />
      </TextCell>
      <TextCell>{activeProjectCount}</TextCell>
      <td width="100%" aria-label="progress">
        <Progress
          value={activeProjectCount}
          total={maxNumProjects}
          color={BRAND_COLORS.LIGHT_PRIMARY}
          background="transparent"
        />
      </td>
      <td>
        <Button onClick={navigateToProjects}>Go to Projects</Button>
      </td>
    </tr>
  );
}
