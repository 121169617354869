import { useContext } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { Button, Form, Loadable, Pane } from "components/materials";
import { Formik } from "formik";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { add } from "helpers/math";
import { majorScale } from "helpers/utilities";
import {
  fundingSourceIsBlank,
  newFundingSource,
  parseFundingSourceGroups,
  restructureFormValues,
} from "helpers/fundingSourceHelpers";
import t from "helpers/translate";
import { PROJECT_VENDOR_SEARCH_QUERY } from "helpers/queries";
import { STEPS } from "./utils";
import { Header } from "./Header";
import { PersistStep } from "./PersistStep";
import {
  FundingSourcesForm,
  initialValues,
  validate,
} from "../../templates/FundingSourcesForm";

const QUERY = gql`
  query OnboardingWizardFundingSourcesQuery($projectId: String!) {
    project(id: $projectId) {
      id
      vendors {
        id
        name
      }
    }
  }
`;

function getInitialFundingSourcesForWizard(project) {
  // funding sources created via API are not present in "onboardingData" until the user has taken an action in the form
  // once the API-created source has been added to "onboardingData", it will be duplicated in the form if the user returns
  // when encountering duplicates, we want to defer to the record present in the "onboardingData",
  // since that record will contain any edits made to the source by a user in the wizard
  // when the onboarding wizard is finalized, any pre-existing API-created funding sources will be deleted or updated based on actions taken in the wizard
  const wizardSources = project?.onboardingData?.fundingSourceGroups || [];

  const previouslyUnseenProjectFundingSources = project.fundingSourceGroups
    .flat()
    .filter((projectSource) => {
      return !wizardSources
        .flat()
        .find((wizardSource) => wizardSource.id === projectSource.id);
    });

  return previouslyUnseenProjectFundingSources.length === 0
    ? wizardSources
    : wizardSources.concat(
        previouslyUnseenProjectFundingSources.map((source) => [source])
      );
}

export function FundingSourcesStep({
  goBack,
  loading,
  navigateToStep,
  project,
  updateOnboardingData,
  width,
}) {
  const { data, loading: queryLoading } = useQuery(QUERY, {
    variables: { projectId: project.id },
  });
  const [getProjectVendorSearchQuery, projectVendorSearchQuery] = useLazyQuery(
    PROJECT_VENDOR_SEARCH_QUERY
  );
  const { isDeveloper, hasPermission } = useContext(UserContext);
  const { hasDraws } = project.onboardingData;

  if (queryLoading) return <Loadable loading />;

  function getMutationData(values) {
    const restructuredFormValues = restructureFormValues(values);
    const fundingSourceGroups = parseFundingSourceGroups(
      restructuredFormValues
    ).reduce((validFundingSourceGroups, fundingSourceGroup) => {
      const filteredFundingSourceGroup = fundingSourceGroup.filter(
        (fundingSource) => !fundingSourceIsBlank(fundingSource)
      );
      return filteredFundingSourceGroup.length > 0
        ? [...validFundingSourceGroups, filteredFundingSourceGroup]
        : validFundingSourceGroups;
    }, []);

    return {
      automaticAllocationEnabled: values.automaticAllocationEnabled,
      fundingSourceGroups,
      usesOfFundsEnabled: values.usesOfFundsEnabled,
    };
  }

  function updateFundingSources(values) {
    const mutationData = getMutationData(values);
    updateOnboardingData(mutationData).then(() => {
      if (isDeveloper && !hasDraws) navigateToStep(STEPS.CONFIRM_PROJECT_SETUP);
      else if (isDeveloper) navigateToStep(STEPS.DRAW_DECISION);
      else navigateToStep(STEPS.LENDER_HAS_DRAWS);
    });
  }

  const divisions = get(project, "onboardingData.budget.divisions", []);
  const projectAmount = divisions.reduce((projectTotal, division) => {
    const divisionTotal = division.lineItems.reduce(
      (divisionTotal, lineItem) => add(lineItem.amount, divisionTotal),
      0
    );
    return add(divisionTotal, projectTotal);
  }, 0);
  const projectValues = { amount: projectAmount, draws: [] };
  const { newFundingSourceGroup } = newFundingSource(divisions);
  const currentFundingSourceGroups = getInitialFundingSourcesForWizard(project);
  const defaultInitialValues = initialValues(
    currentFundingSourceGroups,
    get(project, "onboardingData.automaticAllocationEnabled", false),
    get(project, "onboardingData.usesOfFundsEnabled", false),
    projectValues
  );

  defaultInitialValues.fundingSourceGroups =
    defaultInitialValues.fundingSourceGroups.length === 0
      ? [[newFundingSourceGroup]]
      : defaultInitialValues.fundingSourceGroups;

  return (
    <Pane alignItems="center" display="flex" flexDirection="column">
      <Pane width={width}>
        <Header
          header={t("onboardingWizard.fundingSources.header")}
          subheader={t("onboardingWizard.fundingSources.subheader")}
        />
      </Pane>
      <Pane minWidth={width} maxWidth="95%">
        <Formik
          initialValues={defaultInitialValues}
          validate={validate(defaultInitialValues, {
            allowBlankAmount: true,
            skipBlankFundingSources: true,
          })}
          onSubmit={updateFundingSources}
        >
          {(formikProps) => (
            <Form>
              <FundingSourcesForm
                divisions={get(project, "onboardingData.budget.divisions", [])}
                form={formikProps}
                getProjectVendorSearchQuery={getProjectVendorSearchQuery}
                hasAutoAllocateOnOrg={hasPermission(
                  PERMISSION_ACTION.AUTO_ALLOCATE
                )}
                hasUsesOfFundsOnOrg={hasPermission(
                  PERMISSION_ACTION.USES_OF_FUNDS
                )}
                newProject
                organizations={get(data, "project.vendors", [])}
                project={projectValues}
                searchedVendors={get(
                  projectVendorSearchQuery,
                  "data.project.organization.paginatedVendors.results",
                  []
                )}
              />
              <Pane
                display="flex"
                justifyContent="space-between"
                marginBottom={majorScale(4)}
                marginTop={majorScale(2)}
              >
                <Button
                  isLoading={loading}
                  onClick={() => {
                    goBack();
                  }}
                >
                  Back
                </Button>
                <Button
                  appearance="primary"
                  isLoading={loading}
                  onClick={formikProps.handleSubmit}
                >
                  Next
                </Button>
              </Pane>
              <PersistStep
                projectId={project.id}
                mutationData={getMutationData(formikProps.values)}
              />
            </Form>
          )}
        </Formik>
      </Pane>
    </Pane>
  );
}
