export const LINE_ITEM_TYPE = {
  ALLOWANCES: "ALLOWANCES",
  CONTINGENCY: "CONTINGENCY",
  DEVELOPER_FEES: "DEVELOPER_FEES",
  FEES: "FEES",
  FINANCING: "FINANCING",
  HARD_COSTS: "HARD_COSTS",
  INTEREST_RESERVES: "INTEREST_RESERVES",
  LAND_ACQUISITION: "LAND_ACQUISITION",
  SOFT_COSTS: "SOFT_COSTS",
  TENANT_IMPROVEMENTS: "TENANT_IMPROVEMENTS",
};
