import { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { EditTableViews } from "components/containers";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
  primaryColumnDefaults,
  stringColumnDefaults,
  currencyColumnDefaults,
  numberColumnDefaults,
} from "components/materials/FastDataTable";
import { sumBy } from "helpers/math";
import { getDefaultAggregate } from "helpers/tableAggregateHelpers";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { get } from "lodash";

const getRowState = (vendorLineItem) => {
  if (vendorLineItem.spentToDateAmount > vendorLineItem.agreementsAmount)
    return "pending";
  return undefined;
};

const getControls = (propsControls, propsEditTableViews, addButton) => {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      disable={[FastDataTableDownloadDocuments]}
      rightControls={(defaultRightControls) => [
        defaultRightControls,
        addButton,
      ]}
      searchPlaceholder="Search Line Items..."
    />
  );
};

export function LegacyAgreementsTable({
  addButton,
  history,
  onClickRow,
  organizationId,
  project,
}) {
  const { agreementVendorLineItems } = project;
  const { hasPermission } = useContext(UserContext);
  const columns = useMemo(
    () => [
      {
        ...stringColumnDefaults,
        ...primaryColumnDefaults,
        header: "Line Item",
        groupable: true,
        id: "lineItem",
        value: (vendorLineItem) =>
          get(vendorLineItem, "lineItem.name", "(Unassigned)"),
        aggregate: (vendorLineItems) =>
          getDefaultAggregate(vendorLineItems, (vendorLineItem) =>
            get(vendorLineItem, "lineItem.name", "(Unassigned)")
          ),
        width: 200,
      },
      {
        ...stringColumnDefaults,
        header: "Division",
        groupable: true,
        id: "division",
        value: (vendorLineItem) =>
          get(vendorLineItem, "lineItem.division.name"),
        aggregate: (vendorLineItems) =>
          getDefaultAggregate(vendorLineItems, "lineItem.division.name"),
        width: 200,
      },
      {
        ...stringColumnDefaults,
        header: "Vendor",
        groupable: true,
        id: "vendor",
        value: (vendorLineItem) =>
          get(vendorLineItem, "vendor.name", "(No Vendor)"),
        aggregate: (vendorLineItems) =>
          getDefaultAggregate(vendorLineItems, (vendorLineItem) =>
            get(vendorLineItem, "vendor.name", "(No Vendor)")
          ),
        width: 200,
      },
      {
        ...numberColumnDefaults,
        header: "Pay Apps / Invoices",
        id: "spentToDateCount",
        value: (vendorLineItem) => vendorLineItem.spentToDateCount,
        width: 100,
      },
      {
        ...currencyColumnDefaults,
        header: "Spent To Date",
        id: "spentToDate",
        value: (vendorLineItem) => vendorLineItem.spentToDateAmount,
        aggregate: (vendorLineItems) =>
          sumBy(vendorLineItems, "spentToDateAmount"),
        width: 150,
      },
      {
        ...numberColumnDefaults,
        header: "Agreements",
        id: "agreementsCount",
        value: (vendorLineItem) => vendorLineItem.agreementsCount,
        width: 100,
      },
      {
        ...currencyColumnDefaults,
        header: "Agreements Amount",
        id: "agreementsAmount",
        value: (vendorLineItem) => vendorLineItem.agreementsAmount,
        aggregate: (vendorLineItems) =>
          sumBy(vendorLineItems, "agreementsAmount"),
        width: 150,
      },
      {
        ...numberColumnDefaults,
        header: "Pending Agreements",
        id: "exposuresCount",
        value: (vendorLineItem) => vendorLineItem.pendingAgreementsCount,
        width: 100,
      },
      {
        ...currencyColumnDefaults,
        header: "Pending Agreements Amount",
        id: "exposuresAmount",
        value: (vendorLineItem) => vendorLineItem.pendingAgreementsAmount,
        aggregate: (vendorLineItems) =>
          sumBy(vendorLineItems, "pendingAgreementsAmount"),
        state: (vendorLineItem) =>
          vendorLineItem.pendingAgreementsAmount > 0 ? "special" : null,
        width: 150,
      },
    ],
    []
  );

  const tableColumns = useMemo(() => columns.map(({ id }) => id), [columns]);

  const defaultViews = useMemo(
    () => [
      {
        config: toBase64({
          columnConfig: tableColumns.filter((column) => column !== "vendor"),
          filterConfig: [],
          groupConfig: { columnId: "vendor", direction: "asc" },
          sortConfig: {},
        }),
        isDefault: true,
        name: "Default",
      },
    ],
    [tableColumns]
  );

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(PERMISSION_ACTION.SAVE_TABLE_VIEWS)}
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={organizationId}
      defaultViews={defaultViews}
      scopeViewsToOrganization
      tableName="ProjectAgreements"
    >
      {(propsEditTableViews) => (
        <FastDataTable
          footerTotals
          columns={columns}
          controls={(propsControls) =>
            getControls(propsControls, propsEditTableViews, addButton)
          }
          getRowState={getRowState}
          items={agreementVendorLineItems}
          onClickRow={onClickRow}
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(propsEditTableViews, "views.0.config")
          }
        />
      )}
    </EditTableViews>
  );
}

LegacyAgreementsTable.propTypes = {
  agreements: PropTypes.array.isRequired,
  onClickRow: PropTypes.func,
  history: PropTypes.object.isRequired,
};

LegacyAgreementsTable.defaultProps = {
  onClickRow: () => {},
};
