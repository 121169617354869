import gql from "graphql-tag";

export const PROJECT_VENDOR_SEARCH_QUERY = gql`
  query ProjectVendorSearchQuery(
    $projectId: String!
    $filters: [FilterInput]
    $pagination: PaginationInput
  ) {
    project(id: $projectId) {
      id
      organization {
        id
        paginatedVendors(filters: $filters, pagination: $pagination) {
          id
          total
          results {
            id
            isPlaceholderVendor
            city
            name
            state
            agreementProjectIds
          }
        }
      }
    }
  }
`;
