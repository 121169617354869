import { useContext } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { MentionsBody, Pane, Paragraph } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { TRIM_DATE_FORMAT, formatDateTime } from "helpers/dateHelpers";

export function Comment({ comment }) {
  const { userId } = useContext(UserContext);
  const authorId = get(comment, "author.id", null);
  const userIsAuthorOfComment = userId === authorId;

  const commentPlacementStyles = userIsAuthorOfComment
    ? { marginLeft: "auto" }
    : { marginRight: "auto" };

  return (
    <Pane
      backgroundColor={userIsAuthorOfComment ? "#06033A" : "#F9F9FA"}
      width="90%"
      cursor="pointer"
      borderRadius={minorScale(1)}
      padding={minorScale(3)}
      {...commentPlacementStyles}
    >
      <Pane paddingX={majorScale(1)} width="85%">
        <Paragraph size={300}>
          <MentionsBody
            comment={comment}
            textProps={{
              size: 400,
              color: userIsAuthorOfComment ? "white" : "auto",
            }}
          />
        </Paragraph>
      </Pane>
      <Pane>
        <Paragraph
          color="muted"
          size={300}
          marginTop={minorScale(1)}
          textAlign={userIsAuthorOfComment ? "right" : "left"}
        >
          {userIsAuthorOfComment ? "You" : comment.author.fullName} ·{" "}
          {formatDateTime(comment.insertedAt, "", TRIM_DATE_FORMAT)} ·{" "}
          {formatDateTime(comment.insertedAt, "", "hh:mmaaa")}
        </Paragraph>
      </Pane>
    </Pane>
  );
}

Comment.propTypes = {
  mention: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onDismissCompleted: PropTypes.func.isRequired,
};
