import { useContext, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { EyeOffIcon, TickCircleIcon } from "evergreen-ui";
import {
  Badge,
  Heading,
  Link,
  Pane,
  Progress,
  Spacer,
  Table,
  Text,
  Tooltip,
} from "components/materials";
import { RULE_STATE, RULE_TYPE } from "helpers/enums";
import t from "helpers/translate";
import {
  Position,
  majorScale,
  minorScale,
  ThemeContext,
} from "helpers/utilities";
import { find, get, groupBy } from "lodash";
import DrawRuleSelect from "./DrawRuleSelect";
import DrawRuleModal from "./DrawRuleModal";
import { RuleLabel } from "./RuleLabel";

function getIcon(iconProps) {
  if (iconProps.icon === "tickCircle") {
    return <TickCircleIcon color={iconProps.color} />;
  }
  return <EyeOffIcon color={iconProps.color} />;
}

function ShowRulesLink({
  actionLinkTranslation,
  purpose,
  iconProps,
  setShowRules,
}) {
  return (
    <Pane display="flex" alignItems="center">
      {getIcon(iconProps)}
      <Text marginRight={minorScale(1)}>{actionLinkTranslation}</Text>
      <Link
        purpose={purpose}
        onClick={() => setShowRules(true)}
        color="gray"
        underline
      >
        Show
      </Link>
    </Pane>
  );
}

function NoPendingRuleMessage({ message, theme }) {
  return (
    <Pane>
      <Table hover={false}>
        <Table.Body>
          <Table.Row key="all-passed" state={{ background: "success" }}>
            <Table.TextCell
              textProps={{ fontWeight: theme.fontWeights.DEMI }}
              border={null}
            >
              {message}
            </Table.TextCell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Pane>
  );
}

const RuleProgress = ({
  numberOfIgnoredRules,
  numberOfPassedRules,
  numberOfPendingRules,
  rulesLength,
  theme,
}) => (
  <Pane>
    <Progress
      sideBySide
      hasThreePercentages
      color="green"
      secondColor="lightgrey"
      thirdColor="yellow"
      value={numberOfPassedRules}
      secondValue={numberOfIgnoredRules}
      thirdValue={numberOfPendingRules}
      total={rulesLength}
    />
    <Text
      color={theme.defaultColors.label.light}
      size={300}
      fontWeight={600}
    >{`${numberOfPassedRules} PASSED | ${numberOfIgnoredRules} IGNORED | ${numberOfPendingRules} PENDING`}</Text>
  </Pane>
);

const getRowState = ({ state }) => {
  if (state === RULE_STATE.PENDING) return "pending";
  if (state === RULE_STATE.PASSED) return "success";
  if (state === RULE_STATE.IGNORED) return "ignored";
  return undefined;
};

const AutomaticRuleTable = ({
  closeRuleModal,
  comment,
  commentResult,
  drawId,
  modalRuleId,
  openRuleModal,
  projectId,
  rules,
  setShowAutoIgnoredRules,
  setShowAutoPassedRules,
  showAutoPassedRules,
  showAutoIgnoredRules,
  theme,
  transition,
  transitionResult,
  users,
}) => {
  const groupedRules = groupBy(rules, "state");
  const numberOfPassedRules = get(groupedRules, RULE_STATE.PASSED, []).length;
  const numberOfPendingRules = get(groupedRules, RULE_STATE.PENDING, []).length;
  const numberOfIgnoredRules = get(groupedRules, RULE_STATE.IGNORED, []).length;
  const displayRulesTable =
    numberOfPendingRules > 0 || showAutoIgnoredRules || showAutoPassedRules;

  return (
    <Fragment>
      <Pane display="flex" marginBottom={majorScale(2)}>
        <Heading size={600} marginRight={majorScale(4)}>
          Automatic Rules
        </Heading>
        <RuleProgress
          rulesLength={rules.length}
          numberOfIgnoredRules={numberOfIgnoredRules}
          numberOfPassedRules={numberOfPassedRules}
          numberOfPendingRules={numberOfPendingRules}
          theme={theme}
        />
      </Pane>
      {displayRulesTable ? (
        <Pane paddingLeft={majorScale(4)}>
          <Table>
            <Table.Body>
              {rules.map((rule) => {
                const ruleActivities = get(rule, "activities", []);
                const lastActivity = ruleActivities.filter(
                  (activity) => activity.__typename === "RuleTransition"
                )[0];
                const setManually = lastActivity
                  ? lastActivity.user !== null
                  : false;
                const autoRuleActivity = find(
                  ruleActivities,
                  (activity) => activity.user === null
                );
                const autoRuleState = autoRuleActivity
                  ? autoRuleActivity.toState
                  : RULE_STATE.PENDING;
                const showRule =
                  (rule.state === RULE_STATE.PASSED && showAutoPassedRules) ||
                  (rule.state === RULE_STATE.IGNORED && showAutoIgnoredRules) ||
                  rule.state === RULE_STATE.PENDING;

                return (
                  showRule && (
                    <Table.Row key={rule.id} state={getRowState(rule)}>
                      <Table.Cell width={141} border={null}>
                        <DrawRuleSelect
                          autoRuleState={autoRuleState}
                          drawId={drawId}
                          isModal={false}
                          modalRuleId={modalRuleId}
                          rule={rule}
                          ruleState={rule.state}
                          setManually={setManually}
                          transition={transition}
                          transitionResult={transitionResult}
                          users={users}
                        />
                      </Table.Cell>
                      <Table.TextCell
                        onClick={() => openRuleModal(rule.id)}
                        border={null}
                        cursor="pointer"
                      >
                        {setManually && (
                          <Fragment>
                            <Tooltip
                              content="This automatic rule was manually overridden by a user"
                              position={Position.TOP}
                            >
                              <Badge color="blue">MANUAL</Badge>
                            </Tooltip>
                            <Spacer />
                          </Fragment>
                        )}
                        <RuleLabel
                          cursor="pointer"
                          rule={rule}
                          data-testid="ruleName"
                        />
                      </Table.TextCell>
                      {modalRuleId === rule.id && (
                        <DrawRuleModal
                          autoRuleState={autoRuleState}
                          closeRuleModal={closeRuleModal}
                          comment={comment}
                          commentResult={commentResult}
                          drawId={drawId}
                          modalRuleId={modalRuleId}
                          projectId={projectId}
                          rule={rule}
                          setManually={setManually}
                          transition={transition}
                          transitionResult={transitionResult}
                          users={users}
                        />
                      )}
                    </Table.Row>
                  )
                );
              })}
            </Table.Body>
          </Table>
        </Pane>
      ) : (
        <NoPendingRuleMessage
          message={t("reviewsPage.noMorePendingRules", {
            ruleType: "automatic",
          })}
          theme={theme}
        />
      )}
      <Pane paddingLeft={majorScale(4)}>
        {numberOfPassedRules > 0 && !showAutoPassedRules && (
          <ShowRulesLink
            actionLinkTranslation={t("reviewsPage.actionLinkMessage", {
              count: numberOfPassedRules,
              ruleType: "automatic",
              ruleState: "passed",
            })}
            purpose="rules automatic show-passed"
            iconProps={{ icon: "tickCircle", color: "success" }}
            setShowRules={setShowAutoPassedRules}
          />
        )}
        {numberOfIgnoredRules > 0 && !showAutoIgnoredRules && (
          <ShowRulesLink
            actionLinkTranslation={t("reviewsPage.actionLinkMessage", {
              count: numberOfIgnoredRules,
              ruleType: "automatic",
              ruleState: "ignored",
            })}
            purpose="rules automatic show-ignored"
            iconProps={{ icon: "eyeOff", color: "muted" }}
            setShowRules={setShowAutoIgnoredRules}
          />
        )}
      </Pane>
    </Fragment>
  );
};

const ManualRuleTable = ({
  closeRuleModal,
  comment,
  commentResult,
  drawId,
  modalRuleId,
  openRuleModal,
  projectId,
  rules,
  setShowManualIgnoredRules,
  setShowManualPassedRules,
  showManualPassedRules,
  showManualIgnoredRules,
  theme,
  transition,
  transitionResult,
  users,
}) => {
  const groupedRules = groupBy(rules, "state");
  const numberOfPassedRules = get(groupedRules, RULE_STATE.PASSED, []).length;
  const numberOfPendingRules = get(groupedRules, RULE_STATE.PENDING, []).length;
  const numberOfIgnoredRules = get(groupedRules, RULE_STATE.IGNORED, []).length;
  const displayRulesTable =
    numberOfPendingRules > 0 || showManualIgnoredRules || showManualPassedRules;

  return (
    <Fragment>
      <Pane
        display="flex"
        justifyContent="flex-start"
        marginBottom={majorScale(2)}
      >
        <Heading size={600} marginRight={majorScale(4)}>
          Manual Rules
        </Heading>
        <RuleProgress
          rulesLength={rules.length}
          numberOfIgnoredRules={numberOfIgnoredRules}
          numberOfPassedRules={numberOfPassedRules}
          numberOfPendingRules={numberOfPendingRules}
          theme={theme}
        />
      </Pane>

      {displayRulesTable ? (
        <Pane paddingLeft={majorScale(4)}>
          <Table>
            <Table.Body>
              {rules.map((rule) => {
                const showRule =
                  (rule.state === RULE_STATE.PASSED && showManualPassedRules) ||
                  (rule.state === RULE_STATE.IGNORED &&
                    showManualIgnoredRules) ||
                  rule.state === RULE_STATE.PENDING;
                return (
                  showRule && (
                    <Table.Row key={rule.id} state={getRowState(rule)}>
                      <Table.Cell width={141} border={null}>
                        <DrawRuleSelect
                          drawId={drawId}
                          isModal={false}
                          modalRuleId={modalRuleId}
                          rule={rule}
                          ruleState={rule.state}
                          transition={transition}
                          transitionResult={transitionResult}
                          users={users}
                        />
                      </Table.Cell>
                      <Table.TextCell
                        onClick={() => openRuleModal(rule.id)}
                        border={null}
                        cursor="pointer"
                      >
                        <Text cursor="pointer" data-testid="ruleName">
                          {rule.name}
                        </Text>
                      </Table.TextCell>
                      {modalRuleId === rule.id && (
                        <DrawRuleModal
                          closeRuleModal={closeRuleModal}
                          comment={comment}
                          commentResult={commentResult}
                          drawId={drawId}
                          modalRuleId={modalRuleId}
                          projectId={projectId}
                          rule={rule}
                          transition={transition}
                          transitionResult={transitionResult}
                          users={users}
                        />
                      )}
                    </Table.Row>
                  )
                );
              })}
            </Table.Body>
          </Table>
        </Pane>
      ) : (
        <NoPendingRuleMessage
          message={t("reviewsPage.noMorePendingRules", {
            ruleType: "manual",
          })}
          theme={theme}
        />
      )}
      <Pane paddingLeft={majorScale(4)}>
        {numberOfPassedRules > 0 && !showManualPassedRules && (
          <ShowRulesLink
            actionLinkTranslation={t("reviewsPage.actionLinkMessage", {
              count: numberOfPassedRules,
              ruleType: "manual",
              ruleState: "passed",
            })}
            purpose="rules manual show-passed"
            iconProps={{ icon: "tickCircle", color: "success" }}
            setShowRules={setShowManualPassedRules}
          />
        )}
        {numberOfIgnoredRules > 0 && !showManualIgnoredRules && (
          <ShowRulesLink
            actionLinkTranslation={t("reviewsPage.actionLinkMessage", {
              count: numberOfIgnoredRules,
              ruleType: "manual",
              ruleState: "ignored",
            })}
            purpose="rules manual show-ignored"
            iconProps={{ icon: "eyeOff", color: "muted" }}
            setShowRules={setShowManualIgnoredRules}
          />
        )}
      </Pane>
    </Fragment>
  );
};

export const DrawRules = ({
  closeRuleModal,
  comment,
  commentResult,
  drawId,
  modalRuleId,
  openRuleModal,
  projectId,
  rules,
  showAllRules,
  transition,
  transitionResult,
  users,
}) => {
  const theme = useContext(ThemeContext);
  const [showAutoIgnoredRules, setShowAutoIgnoredRules] = useState(false);
  const [showAutoPassedRules, setShowAutoPassedRules] = useState(false);
  const [showManualIgnoredRules, setShowManualIgnoredRules] = useState(false);
  const [showManualPassedRules, setShowManualPassedRules] = useState(false);
  const [automaticRules, manualRules] = rules.reduce(
    ([accAutomatic, accManual], rule) => {
      rule.type === RULE_TYPE.AUTOMATED
        ? accAutomatic.push(rule)
        : accManual.push(rule);
      return [accAutomatic, accManual];
    },
    [[], []]
  );

  return (
    <Fragment>
      <Pane marginBottom={majorScale(2)}>
        <AutomaticRuleTable
          closeRuleModal={closeRuleModal}
          comment={comment}
          commentResult={commentResult}
          drawId={drawId}
          modalRuleId={modalRuleId}
          openRuleModal={openRuleModal}
          projectId={projectId}
          rules={automaticRules}
          setShowAutoIgnoredRules={setShowAutoIgnoredRules}
          setShowAutoPassedRules={setShowAutoPassedRules}
          showAutoIgnoredRules={showAutoIgnoredRules || showAllRules}
          showAutoPassedRules={showAutoPassedRules || showAllRules}
          theme={theme}
          transition={transition}
          transitionResult={transitionResult}
          users={users}
        />
      </Pane>
      <Pane marginBottom={majorScale(2)}>
        <ManualRuleTable
          closeRuleModal={closeRuleModal}
          comment={comment}
          commentResult={commentResult}
          drawId={drawId}
          modalRuleId={modalRuleId}
          openRuleModal={openRuleModal}
          projectId={projectId}
          rules={manualRules}
          setShowManualIgnoredRules={setShowManualIgnoredRules}
          setShowManualPassedRules={setShowManualPassedRules}
          showManualIgnoredRules={showManualIgnoredRules || showAllRules}
          showManualPassedRules={showManualPassedRules || showAllRules}
          theme={theme}
          transition={transition}
          transitionResult={transitionResult}
          users={users}
        />
      </Pane>
    </Fragment>
  );
};

DrawRules.propTypes = {
  projectId: PropTypes.string.isRequired,
  drawId: PropTypes.string.isRequired,
  rules: PropTypes.array.isRequired,
  transition: PropTypes.func.isRequired,
  transitionResult: PropTypes.object.isRequired,
  comment: PropTypes.func.isRequired,
  commentResult: PropTypes.object.isRequired,
};
