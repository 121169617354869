import PropTypes from "prop-types";
import { Button, Grid } from "components/materials";

const SelectionListItem = ({ prepend, onRemove, verticalAlign, children }) => {
  const prependWidth = 3;
  const onRemoveWidth = 1;
  const totalWidth = 16;
  let contentWidth = totalWidth - prependWidth - onRemoveWidth;

  if (!prepend && !onRemove) {
    contentWidth = totalWidth;
  } else if (!prepend) {
    contentWidth = totalWidth - onRemoveWidth;
  } else if (!onRemove) {
    contentWidth = totalWidth - prependWidth;
  }

  return (
    <Grid verticalAlign={verticalAlign}>
      <Grid.Row>
        {prepend && (
          <Grid.Column textAlign="right" columns={prependWidth}>
            {prepend}
          </Grid.Column>
        )}
        <Grid.Column columns={contentWidth}>{children}</Grid.Column>
        {onRemove && (
          <Grid.Column columns={onRemoveWidth}>
            <Button
              purpose="selection-list remove"
              appearance="minimal"
              onClick={onRemove}
            >
              Remove
            </Button>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

SelectionListItem.propTypes = {
  prepend: PropTypes.node,
  onRemove: PropTypes.func,
  verticalAlign: PropTypes.oneOf(["bottom", "middle", "top"]),
};

SelectionListItem.defaultProps = {
  verticalAlign: "top",
};

export default SelectionListItem;
