import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { ChevronDownIcon, ChevronRightIcon } from "evergreen-ui";
import { Pane, Paragraph, Spinner, Text } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";
import {
  uploadIsClassifying,
  uploadIsProcessing,
} from "helpers/documentHelpers";
import { style } from "glamor";

const overflowProps = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const getCardClassName = (theme, isActive) => {
  const styles = {
    ":hover": {
      backgroundColor: theme.tableColors.rowHovered,
      boxShadow: `inset 3px 0 0 ${theme.tableColors.infoBorder}`,
    },
  };

  if (isActive) {
    styles.backgroundColor = theme.tableColors.infoRow;
  }

  return style(styles);
};

const getPageCount = (upload, isProcessing) => {
  if (isProcessing) return "Processing";
  const count =
    upload.file.type === "application/pdf" ? upload.documents.length : 1;
  return t("uploadsViewer.documentCount", { count });
};

function UploadCardsGroup({
  name,
  onSelect,
  selectedUploadId,
  theme,
  uploads,
}) {
  const [expanded, setExpanded] = useState(true);

  return (
    <Fragment>
      {name && (
        <Pane
          alignItems="center"
          backgroundColor={theme.tableColors.sectionHeader}
          borderBottom
          cursor="pointer"
          display="flex"
          onClick={() => setExpanded(!expanded)}
          paddingY={majorScale(1)}
          paddingX={minorScale(1)}
          width="100%"
        >
          {expanded ? (
            <ChevronDownIcon marginRight={minorScale(1)} size={12} />
          ) : (
            <ChevronRightIcon marginRight={minorScale(1)} size={12} />
          )}
          <Text
            fontWeight={theme.fontWeights.DEMI}
            size={300}
            textTransform="uppercase"
            {...overflowProps}
          >
            {name}
          </Text>
        </Pane>
      )}
      {expanded &&
        uploads.map((upload) => {
          return (
            <UploadCard
              key={upload.id}
              upload={upload}
              onSelect={onSelect}
              selectedUploadId={selectedUploadId}
              theme={theme}
            />
          );
        })}
    </Fragment>
  );
}

function UploadCard({ onSelect, selectedUploadId, theme, upload }) {
  const isProcessing =
    uploadIsClassifying(upload) || uploadIsProcessing(upload);
  const isActive = selectedUploadId === upload.id;
  const className = getCardClassName(theme, isActive);

  return (
    <Pane
      borderBottom
      className={className}
      cursor="pointer"
      onClick={() => (isActive ? null : onSelect(upload.id))}
      paddingY={majorScale(1)}
      paddingLeft={minorScale(3)}
      width="100%"
    >
      <Pane display="flex" justifyContent="space-between" width="100%">
        <Paragraph
          width={180}
          size={300}
          color={isActive ? theme.colors.blue900 : null}
          {...overflowProps}
        >
          {upload.file.name}
        </Paragraph>
      </Pane>
      <Pane display="flex" justifyContent="space-between" width="100%">
        <Text size={300} color="muted" {...overflowProps}>
          {getPageCount(upload, isProcessing)}
        </Text>
        {isProcessing && (
          <Spinner marginRight={minorScale(1)} size={majorScale(2)} />
        )}
      </Pane>
    </Pane>
  );
}

export function UploadsViewerSidebar({
  onSelect,
  selectedUploadId,
  theme,
  uploadGroups,
}) {
  return uploadGroups.map(({ name, uploads }) => (
    <UploadCardsGroup
      key={name}
      name={name}
      onSelect={onSelect}
      selectedUploadId={selectedUploadId}
      theme={theme}
      uploads={uploads}
    />
  ));
}

UploadsViewerSidebar.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedUploadId: PropTypes.string.isRequired,
  uploadGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UploadCardsGroup.propTypes = {
  name: PropTypes.string,
  uploads: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UploadCard.propTypes = {
  upload: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        processingFinishedAt: PropTypes.string,
      })
    ).isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    processingFinishedAt: PropTypes.string,
  }).isRequired,
};
