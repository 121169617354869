import { Fragment, useContext } from "react";
import { AddProjectButton } from "components/templates";
import {
  Button,
  Divider,
  Heading,
  IconButton,
  Pane,
  Pill,
} from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { AlignJustifyIcon, GridViewIcon } from "evergreen-ui";

export function PortfolioProjectsHeader({
  canCreateProjects,
  countTodos,
  viewConfig,
  dispatchViewConfig,
}) {
  const theme = useContext(ThemeContext);
  const { showCards, showTodos } = viewConfig;

  return (
    <Pane display="flex">
      <Pane flex="1 1 auto">
        <Pane alignItems="center" display="flex" height={majorScale(4)}>
          <Heading size={400} data-testid={showCards ? "gridView" : "listView"}>
            PROJECTS
          </Heading>
          {!viewConfig.hideViewToggle && (
            <Fragment>
              <IconButton
                appearance="minimal"
                icon={AlignJustifyIcon}
                intent={showCards ? "none" : "info"}
                onClick={() => dispatchViewConfig({ type: "SHOW_TABLE" })}
                purpose="dashboard show-table"
                type="button"
              />
              <Pane
                backgroundColor={theme.colors.gray500}
                height={majorScale(2)}
                width={1}
              />
              <IconButton
                appearance="minimal"
                icon={GridViewIcon}
                intent={showCards ? "info" : "none"}
                onClick={() => dispatchViewConfig({ type: "SHOW_CARDS" })}
                purpose="dashboard show-cards"
                type="button"
              />
            </Fragment>
          )}
        </Pane>
        <Divider color={theme.colors.gray500} height={1} />
      </Pane>
      {canCreateProjects && <AddProjectButton marginLeft={majorScale(2)} />}
      {!showTodos && countTodos > 0 && (
        <Button
          purpose="dashboard todos show"
          marginLeft={majorScale(2)}
          onClick={() => dispatchViewConfig({ type: "SHOW_TODOS" })}
        >
          <Pill color="blue" isSolid marginRight={majorScale(1)}>
            {countTodos}
          </Pill>
          To-Dos
        </Button>
      )}
    </Pane>
  );
}
