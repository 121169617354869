import { useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { CrossIcon, DragHandleHorizontalIcon } from "evergreen-ui";
import { Pane, Text } from "components/materials";
import { majorScale } from "helpers/utilities";
import { Draggable } from "react-beautiful-dnd";

let portal;

function renderDefaultDragItemContent({ item, onRemove }) {
  return (
    <Fragment>
      <Text
        flexGrow="1"
        fontWeight="bold"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        marginRight={majorScale(2)}
      >
        {item.text}
      </Text>
      <Pane display="flex" alignItems="center">
        {item.action && <Pane marginRight={majorScale(2)}>{item.action}</Pane>}
        {item.secondaryText && (
          <Text color="muted" marginRight={majorScale(2)}>
            {item.secondaryText}
          </Text>
        )}
        {item.canRemove && (
          <CrossIcon
            size={12}
            cursor="pointer"
            onClick={() => onRemove(item.key)}
            marginRight={majorScale(2)}
          />
        )}
      </Pane>
    </Fragment>
  );
}

function getDragItemStyles(itemHeight) {
  return {
    display: "flex",
    alignItems: "center",
    height: itemHeight,
    backgroundColor: "#fafafa",
    borderBottom: true,
    justifyContent: "center",
  };
}

export function DragItem({
  item,
  index,
  itemHeight,
  onRemove,
  handleManualOrderSelection,
  children,
}) {
  // fixes an issue caused by having the drag and drop in a modal
  // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/using-a-portal.md
  useEffect(() => {
    if (!portal) {
      portal = document.createElement("div");
      document.body.appendChild(portal);
    }
  }, []);

  const itemProps = {
    item,
    index,
    onRemove,
    handleManualOrderSelection,
  };
  return (
    <Draggable draggableId={item.key} index={index}>
      {(provided, snapshot) => {
        const usePortal = snapshot.isDragging;
        const node = (
          <Pane
            {...getDragItemStyles(itemHeight)}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={majorScale(6)}
              minWidth={majorScale(6)}
              {...provided.dragHandleProps}
            >
              <DragHandleHorizontalIcon />
            </Pane>
            {children(itemProps)}
          </Pane>
        );

        return usePortal ? ReactDOM.createPortal(node, portal) : node;
      }}
    </Draggable>
  );
}

DragItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  itemHeight: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
  children: PropTypes.func,
};

DragItem.defaultProps = {
  children: renderDefaultDragItemContent,
};
