import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { Form, Modal } from "components/materials";
import { set } from "lodash";
import isBlank from "helpers/isBlank";
import { EDIT_TARGET_AMOUNT } from "./graphql";

export function EditTargetAmount({ draw, open, onClose }) {
  const [editTargetAmount, { loading }] = useMutation(EDIT_TARGET_AMOUNT, {
    onCompleted: onClose,
  });

  function handleSubmit(values) {
    return editTargetAmount({
      variables: {
        drawId: draw.id,
        targetAmount: values.targetAmount,
      },
    });
  }

  return (
    <Formik
      initialValues={{ targetAmount: draw.targetAmount }}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {(form) => (
        <Modal
          confirmLabel="Save"
          hasFooter
          isConfirmLoading={loading}
          onClose={() => {
            form.handleReset();
            onClose();
          }}
          onConfirm={form.handleSubmit}
          open={open}
          size="small"
          title="Edit Target Amount"
        >
          <Modal.Content>
            <Form
              onSubmit={form.handleSubmit}
              error={!!form.error}
              loading={loading}
            >
              <Form.Input
                label="Target Amount"
                name="targetAmount"
                type="currency"
              />
            </Form>
          </Modal.Content>
        </Modal>
      )}
    </Formik>
  );
}

function validate(values) {
  const errors = {};

  if (isBlank(values.targetAmount)) {
    set(errors, "targetAmount", "Please enter a target amount");
  }

  return errors;
}

EditTargetAmount.propTypes = {
  draw: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

EditTargetAmount.defaultProps = {
  onClose: () => {},
};
