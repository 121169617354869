import {
  addBreadcrumb,
  captureException,
  configureScope,
} from "@sentry/browser";
import * as FullStory from "@fullstory/browser";
import { lowerCase, startCase } from "lodash";

const segmentId = process.env.REACT_APP_SEGMENT_ID;
const fullStoryId = process.env.REACT_APP_FULLSTORY_ID;
const intercomId = process.env.REACT_APP_INTERCOM_ID;
const totangoId = process.env.REACT_APP_TOTANGO_ID;
const env = process.env.REACT_APP_ENV;

class Analytics {
  constructor() {
    return Analytics.instance;
  }

  error(error) {
    // eslint-disable-next-line no-console
    console.debug("analytics.error", error);

    captureException(error);
    return this;
  }

  // General function that takes a Rabbet user, and initializes all the tracking: FullStory, Intercom, Segment, Sentry
  identify(user) {
    // Optimize the user display variables for FullStory
    const fsuser = {
      displayName: this.getDisplayName(user),
      email: user.email,
      environment: this.titleCase(env),
      firstName: user.firstName,
      id: this.getTrackingID(user),
      lastName: user.lastName,
      organization: user.organization.name,
      tier: this.formatText(user.organization.tier),
      type: this.titleCase(user.organization.type),
    };

    this.initializeFullStory(fsuser);
    this.initializeIntercom(user);
    this.initializeSegment(user);
    this.initializeSentry(user);
    this.initializeTotango(user);
  }

  // Takes a user with these notable fields, minimally: displayName, email, id
  initializeFullStory(user) {
    // Only initialize FullStory in Staging / Prod
    if (!fullStoryId) return;
    if (user.organization.match(/Rabbet QA Org|^Bevel/)) {
      // Cease FullStory recording for test users. Also prevents `FullStory.event` calls from taking effect
      FullStory.shutdown();
    } else {
      // Identify the user to Full Story
      FullStory.identify(this.getTrackingID(user), user);
    }
  }

  initializeIntercom(user) {
    if (!intercomId || this.intercomInstalled || this.intercomInstalling)
      return;
    this.intercomInstalling = true;

    const script = document.createElement("script");
    const x = document.getElementsByTagName("script")[0];
    const intercom = (...args) => {
      intercom.c(args);
    };

    if (typeof window.Intercom === "function") {
      window.Intercom("reattach_activator");
      window.Intercom("update", { app_id: intercomId });
    } else {
      intercom.q = [];
      intercom.c = (args) => {
        intercom.q.push(args);
      };

      script.type = "text/javascript";
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${intercomId}`;

      window.Intercom = intercom;

      x.parentNode.insertBefore(script, x);

      window.Intercom("boot", {
        app_id: intercomId,
        email: user.email,
        name: user.fullName,
        user_id: this.getTrackingID(user),
        company: {
          company_id: user.organization?.id,
          name: user.organization?.name,
        },
      });
    }
    this.intercomInstalling = false;
    this.intercomInstalled = true;
  }

  initializeSegment(user) {
    // Only initialize Segment on Production
    if (!segmentId) return;

    // Don't track test users or employees
    if (this.shouldNotTrackUser(user)) {
      return;
    }

    // Install Segment if not already installed
    if (!this.segmentInstalled) {
      window.analytics.load(segmentId);

      this.segmentInstalled = true;
    }

    this.segmentIdentify(user);
    this.segmentGroup(user);
  }

  initializeSentry(user) {
    // Sets the user to be tracked for Sentry error reporting
    configureScope((scope) =>
      scope.setUser({ email: user.email, id: this.getTrackingID(user) })
    );
  }

  /* eslint-disable camelcase, no-var, vars-on-top, func-names */
  initializeTotango(user) {
    if (!totangoId || this.totangoInstalled) {
      return;
    }

    // Don't track test users or employees
    if (this.shouldNotTrackUser(user)) {
      return;
    }

    const user_properties = {
      env,
      id: this.getTrackingID(user),
      name: user.fullName,
      ...this.getUserProperties(user),
    };

    const org_properties = {
      id: user.organization.id,
      ...this.getOrgProperties(user.organization),
    };

    window.totango_options = {
      service_id: totangoId,
      user: user_properties,
      account: org_properties,
      enableHierarchy: false,
    };
    (function () {
      window.totango_tmp_stack = [];
      window.totango = {
        go() {
          return -1;
        },
        setAccountAttributes() {},
        identify() {},
        track(t, o, n, a) {
          window.totango_tmp_stack.push({
            activity: t,
            module: o,
            org: n,
            user: a,
          });
          return -1;
        },
      };

      const e = document.createElement("script");
      e.type = "text/javascript";
      e.async = true;
      e.src = "https://tracker.totango.com/totango6.0.0.js";

      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(e, s);
    })();

    this.totangoInstalled = true;
  }
  /* eslint-enable camelcase, no-var */

  // TO DO: Call Segment Identify on page loads
  segmentIdentify(user) {
    // Don't try to run segment things when segment is not installed
    if (!this.segmentInstalled) {
      return;
    }
    // The following window.analytics.identify specifically calls Segment, and is not to be confused with Analytics.identify,
    // which is this file's overarching `identify`
    window.analytics.identify(
      this.getTrackingID(user),
      this.getUserProperties(user)
    );
  }

  segmentGroup(user) {
    // Don't try to run segment things when segment is not installed
    if (!this.segmentInstalled) {
      return;
    }
    // Note: If you want to track events to a group or users to a group, you need to make sure that the user, group,
    // and event all have the same key with the same name. For instance, note that group, user, and the event (track) all have
    // organization_id.
    window.analytics.group(
      user.organization.id,
      this.getOrgProperties(user.organization)
    );
  }

  // Calling the args Event, Properties, and Context because that's what they are in Segment-speak
  // The userId is inferred via prior `identify` calls, and is supplied "under the covers"
  segmentTrack(event, properties, context) {
    // Don't try to run segment things when segment is not installed
    if (!this.segmentInstalled) {
      return;
    }

    const augmentedContext = {
      ...context,
      groupId: window.analytics.group().id(),
      active: true, // For anything we're `track`ing in Lift (so far), the user is considered active
      traits: {
        organizationId: window.analytics.group().id(),
      },
    };
    window.analytics.track(event, properties, augmentedContext);
  }

  // Totango tracking takes the name of an event, and optionally the name of a module.
  totangoTrack(event, module = "") {
    if (!this.totangoInstalled) {
      return;
    }
    window.totango.track(event, module);
  }

  reset() {
    try {
      window.analytics.reset();

      return null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug("analytics.reset", e);
      return null;
    }
  }

  page() {
    try {
      window.analytics.page();

      return null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug("analytics.page", e);
      return null;
    }
  }

  track(event, data) {
    try {
      // Logs a breadcrumb to Sentry
      addBreadcrumb({
        category: "action",
        data,
        message: event,
      });

      // Logs event to Segment
      this.segmentTrack(event, data);

      // Logs event to Totango
      this.totangoTrack(event);

      // Logs event to FullStory
      if (fullStoryId) FullStory.event(event, data);

      return null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug("analytics.track", e);
      return null;
    }
  }

  titleCase(str) {
    return startCase(lowerCase(str));
  }

  formatText(str) {
    return this.titleCase(str?.replace("_", " "));
  }

  getUserProperties(user) {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      approvalAmountLimit: user.approvalAmountLimit,
      showDashboardCards: user.showDashboardCards,
      showDashboardTodos: user.showDashboardTodos,
      invitedAt: user.insertedAt,
      createdAt: user.registeredAt,
      organizationId: user.organization.id,
      organizationName: user.organization.name,
      demoAccount: user.organization.demoAccount,
      inactiveAccount: user.organization.inactiveAccount,
      type: user.organization.type.toLowerCase(),
      tier: user.organization.tier?.toLowerCase(),
    };
  }

  getOrgProperties(organization) {
    return {
      organizationId: organization.id,
      name: organization.name,
      streetAddress: organization.streetAddress,
      city: organization.city,
      state: organization.state,
      zip: organization.zip,
      createdAt: organization.inserted_at,
      phoneNumber: organization.phoneNumbers[0],
      email: organization.emailAddresses[0],
      type: organization.type.toLowerCase(),
      demoAccount: organization.demoAccount,
      inactiveAccount: organization.inactiveAccount,
      totalProjects: organization.totalAddressableProjectCount,
      projectsInRabbet: organization.projectCount,
    };
  }

  // Return either the user's name, or if it's "Rabbet Support" then the org name + "Support"
  getDisplayName(user) {
    const name = this.titleCase(`${user.firstName} ${user.lastName}`);
    if (name === "Rabbet Support") {
      return `${user.organization.name} Support`;
    }

    return name;
  }

  // The ID for trackiing purposes is usually just the user's id. However in a handful of cases (MOU),
  // there are multiple records for a user with the same email, unique IDs, but the same trackingId.
  // These should be tracked as the same user.
  getTrackingID(user) {
    return user.trackingId || user.id;
  }

  // Regardless of environment, don't track test users or employees
  shouldNotTrackUser(user) {
    return user.email.match(
      /(@rabbb?et.com$)|(@contractsimply.com$)|rabbettestcafe/i
    );
  }
}

// Segment Install
/* eslint-disable */
!(function () {
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error("Segment snippet included twice.");
    else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
      ];
      analytics.factory = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (var e = 0; e < analytics.methods.length; e++) {
        var t = analytics.methods[e];
        analytics[t] = analytics.factory(t);
      }
      analytics.load = function (e, t) {
        var n = document.createElement("script");
        n.type = "text/javascript";
        n.async = true;
        n.src =
          "https://cdn.segment.com/analytics.js/v1/" + e + "/analytics.min.js";
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = t;
      };
      analytics.SNIPPET_VERSION = "4.1.0";
    }
})();
/* eslint-enable */

export function drawValues(draw) {
  return {
    drawId: draw.id,
    documentCount: draw.documentCount,
    outstandingDocumentCount: draw.outstandingDocumentCount,
    invoiceCount: draw.invoiceCount,
    payAppsCount: draw.payAppsCount,
    passedRulesCount: draw.passedRulesCount,
    failedRulesCount: draw.failedRulesCount,
    ignoredRulesCount: draw.ignoredRulesCount,
    totalRulesCount: draw.totalRulesCount,
    reviewersCount: draw.reviewersCount,
    approved: !draw.isPendingReview,
    createdAt: draw.insertedAt,
    requestedAmount: draw.requestedAmount,
  };
}

export function documentValues(doc) {
  return {
    documentId: doc.id,
    drawId: doc.targetId,
    projectId: doc.projectId,
    possibleApproversCount: doc.possibleApproversCount,
    type: doc.type,
    value: doc.requestedAmount,
    wasAssigned: !!doc.assignedUser,
    createdAt: doc.insertedAt,
  };
}

const instance = new Analytics();

export default instance;
