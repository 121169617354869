import { some, snakeCase } from "lodash";
import { subtract, sumBy } from "helpers/math";

export const getTransactionsForSource = (transactions, source) => {
  if (!source) {
    return transactions.filter(
      (transaction) => transaction.sourceType === "unknown"
    );
  }

  return transactions.filter((transaction) =>
    Array.isArray(source)
      ? source.includes(transaction.sourceType)
      : transaction.sourceType === source
  );
};

export const transactionsForField = (transactions, field) =>
  transactions.filter(({ entries }) =>
    some(entries, ({ account }) => account === snakeCase(field))
  );

export const entriesForField = (transactions, field) => {
  const fieldLookup = snakeCase(field);
  const entries = Array.isArray(transactions)
    ? transactions.flatMap((t) => t.entries)
    : transactions.entries;

  return entries.filter(({ account }) => account === fieldLookup);
};

export const totalForField = (transactions, field) => {
  if (field === "requestedAmount") {
    return subtract(
      sumBy(entriesForField(transactions, "grossRequestedAmount"), "amount"),
      sumBy(entriesForField(transactions, "retainageAmount"), "amount")
    );
  }
  return sumBy(entriesForField(transactions, field), "amount");
};
