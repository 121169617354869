import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert, Divider, Text } from "components/materials";
import { FieldArray } from "formik";

import GroupsInput from "./GroupsInput";

const FundingSourcesFormInner = ({
  divisions,
  draws,
  form,
  getProjectVendorSearchQuery,
  hasAutoAllocateOnOrg,
  hasUsesOfFundsOnOrg,
  organizations,
  projectTotal,
  searchedVendors,
}) => {
  const [expandedDivisionIds, setExpandedDivisionIds] = useState([]);
  const [newlyAddedVendors, setNewlyAddedVendors] = useState([]);

  return (
    <Fragment>
      <FieldArray
        name="fundingSourceGroups"
        render={({ push }) => (
          <GroupsInput
            divisions={divisions}
            draws={draws}
            expandedDivisionIds={expandedDivisionIds}
            form={form}
            getProjectVendorSearchQuery={getProjectVendorSearchQuery}
            hasAutoAllocateOnOrg={hasAutoAllocateOnOrg}
            hasUsesOfFundsOnOrg={hasUsesOfFundsOnOrg}
            newlyAddedVendors={newlyAddedVendors}
            organizations={organizations}
            projectTotal={projectTotal}
            push={push}
            searchedVendors={searchedVendors}
            setExpandedDivisionIds={setExpandedDivisionIds}
            setNewlyAddedVendors={setNewlyAddedVendors}
          />
        )}
      />
      <Divider />
      {form.errors.warning && (
        <Alert intent="danger" title={<Text>{form.errors.warning}</Text>} />
      )}
    </Fragment>
  );
};
FundingSourcesFormInner.propTypes = {
  divisions: PropTypes.array.isRequired,
  draws: PropTypes.array.isRequired,
  expandedDivisionIds: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  hasAutoAllocateOnOrg: PropTypes.bool,
  hasUsesOfFundsOnOrg: PropTypes.bool,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectTotal: PropTypes.number.isRequired,
};

export default FundingSourcesFormInner;
