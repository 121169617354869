import { get } from "lodash";
import { DRAW_REVIEW_STATE, REVIEW_STATE } from "./enums";

export enum RelativeDrawReviewState {
  APPROVED = "APPROVED",
  NO_REVIEWERS = "NO_REVIEWERS",
  PENDING_YOUR_APPROVAL = "PENDING_YOUR_APPROVAL",
  PENDING_OTHER_APPROVAL = "PENDING_OTHER_APPROVAL",
}

export function getApprovalStatus(draw: Draw, userId: string) {
  const reviewState = get(draw, "reviewState");
  // possible draw review states: "pending", "approved", or "no reviewers"
  // if the review state is pending,
  // we signal to the user whether it's waiting on their approval, or someone else's approval
  return reviewState === DRAW_REVIEW_STATE.PENDING
    ? getRelativeReviewState(get(draw, "reviews", []), userId)
    : reviewState;
}

function getRelativeReviewState(drawReviews: DrawReview[], userId: string) {
  const currentUserHasReviewPending = !!drawReviews.find((review) => {
    const isCurrentUser = userId && review?.reviewer?.id === userId;
    const isPending = review.state === REVIEW_STATE.PENDING;
    return isCurrentUser && isPending;
  });

  if (currentUserHasReviewPending) {
    return RelativeDrawReviewState.PENDING_YOUR_APPROVAL;
  }
  return RelativeDrawReviewState.PENDING_OTHER_APPROVAL;
}

export function getApprovalsCount(draw: Draw) {
  const reviews = get(draw, "reviews", []);

  const approvalCount = reviews.filter(
    ({ state }) => state === REVIEW_STATE.APPROVED
  ).length;

  return {
    numerator: approvalCount,
    denominator: reviews.length,
  };
}

enum ReviewState {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

enum DrawReviewState {
  APPROVED = "APPROVED",
  NO_REVIEWERS = "NO_REVIEWERS",
  PENDING = "PENDING",
}

type Draw = {
  reviews: DrawReview[];
  reviewState: DrawReviewState;
};

type DrawReview = {
  reviewer: {
    id: string;
  };
  state: ReviewState;
};
