import PropTypes from "prop-types";
import { Menu, Text } from "components/materials";
import { groupShape } from "../FastDataTableUtils";

const ToggleSections = ({ toggleAllSections, groupConfig, closeDropdown }) => {
  const getMessage = () => {
    const collapseMessage = "Collapse All Rows";
    const expandMessage = "Expand All Rows";

    if (!groupConfig.expanded) return collapseMessage;
    if (groupConfig.expanded.length > 0) return collapseMessage;
    return expandMessage;
  };

  return (
    <Menu.Item
      is={Text}
      onSelect={() => {
        toggleAllSections();
        closeDropdown();
      }}
    >
      {getMessage()}
    </Menu.Item>
  );
};

// TODO: remove dot notation
ToggleSections.shouldDisplay = ({ groupConfig }) => !!groupConfig.columnId;

ToggleSections.propTypes = {
  groupConfig: groupShape,
  toggleAllSections: PropTypes.func,
  closeDropdown: PropTypes.func,
};

export default ToggleSections;
