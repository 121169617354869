import { Fragment, useContext, useCallback, useEffect } from "react";
import { Button, Modal, Text, Pane } from "components/materials";
import { minorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { useCreate92403Page2Mutation } from "features/create-92403";

export default function Create92403Page2Tab({
  selected = false,
  enableTab,
  drawId,
  onCancel,
  onSubmit,
}) {
  const { hasPermission } = useContext(UserContext);

  useEffect(() => {
    if (hasPermission(PERMISSION_ACTION.HUD_FEATURES) && !!drawId) {
      enableTab();
    }
  }, [enableTab, hasPermission, drawId]);

  const [create92403Page2, { loading }] = useCreate92403Page2Mutation({
    variables: { drawId },
  });

  const handleSubmit = useCallback(async () => {
    await create92403Page2();
    onSubmit && onSubmit();
  }, [create92403Page2, onSubmit]);

  if (!selected) return null;

  return (
    <Fragment>
      <Modal.Content>
        <Modal.Content>
          <Pane display="flex" justifyContent="center">
            <Text>
              Press &quot;Create&quot; to generate Page 2 of the 92403
            </Text>
          </Pane>
        </Modal.Content>
      </Modal.Content>
      <Modal.Actions display="flex" justifyContent="flex-end">
        <Button
          marginLeft="auto"
          marginRight={minorScale(3)}
          onClick={onCancel}
          purpose="upload-files cancel"
        >
          Cancel
        </Button>
        <Button
          appearance="primary"
          isLoading={loading}
          onClick={handleSubmit}
          purpose="upload-files create-2403"
        >
          Create
        </Button>
      </Modal.Actions>
    </Fragment>
  );
}
