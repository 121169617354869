import { useEffect, useState } from "react";
import { cumulativeSum } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import {
  NEW_PROJECTION_STATE,
  UPDATED_PROJECTION_STATE,
  UPDATING_PROJECTION_STATE,
  NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE,
} from "hooks/use-projections";
import { ChartTemplate } from "../ChartTemplate";

const initialData = {
  labels: [],
  datasets: [{ data: [] }],
};

function generateValues(divisions, expectedLength, actuals = false) {
  const totals = Array(expectedLength).fill(0);

  const lineItems = divisions.map((d) => d.lineItems).flat();

  lineItems.forEach((lineItem) => {
    lineItem.values.forEach((value, valueIndex) => {
      const prop = actuals ? "updatedProjection" : "projected";
      totals[valueIndex] += value[prop] ? value[prop] : 0;
    });
  });

  return cumulativeSum(totals);
}

export function LineItemProjectionChart({
  chartId,
  projection,
  setChartDownloadImage,
}) {
  const [data, setData] = useState(initialData);
  const {
    actualsCount,
    divisions,
    project,
    state: projectionState,
  } = projection;

  const { expectedLength, months, budget } = project;

  useEffect(() => {
    let datasets = [
      {
        data: Array(expectedLength).fill(budget),
        backgroundColor: "rgba(100, 100, 100, 0.2)",
        borderColor: "rgba(100, 100, 100, 1)",
        borderWidth: 1,
        label: "Budget",
        pointRadius: 0,
        fill: false,
        lineTension: 0,
      },
    ];

    switch (projectionState) {
      case NEW_PROJECTION_STATE:
        datasets = [
          {
            data: generateValues(divisions, expectedLength),
            backgroundColor: "rgba(195, 150, 5, 0.2)",
            borderColor: "rgba(195, 150, 5, 1)",
            borderWidth: 3,
            label: "Original Projection",
            fill: false,
            lineTension: 0,
          },
          ...datasets,
        ];
        break;
      case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
        datasets = [
          {
            data: generateValues(divisions, expectedLength, true),
            backgroundColor: "rgba(71, 186, 132, 0.2)",
            borderColor: "rgba(71, 186, 132, 1)",
            borderWidth: 3,
            label: "Projection",
            borderDash: [10, 5],
            fill: false,
            lineTension: 0,
          },
          {
            data: generateValues(divisions, expectedLength, true).slice(
              0,
              actualsCount
            ),
            backgroundColor: "rgba(71, 186, 132, 0.2)",
            borderColor: "rgba(71, 186, 132, 1)",
            borderWidth: 3,
            label: "Actuals",
            fill: false,
            lineTension: 0,
          },
          {
            data: generateValues(divisions, expectedLength),
            backgroundColor: "rgba(195, 150, 5, 0.2)",
            borderColor: "rgba(195, 150, 5, 1)",
            borderWidth: 3,
            label: "Original Projection",
            fill: false,
            lineTension: 0,
          },
          ...datasets,
        ];
        break;
      case UPDATED_PROJECTION_STATE:
        datasets = [
          {
            data: generateValues(divisions, expectedLength, true).slice(
              0,
              actualsCount
            ),
            backgroundColor: "rgba(71, 186, 132, 0.2)",
            borderColor: "rgba(71, 186, 132, 1)",
            borderWidth: 3,
            fill: false,
            lineTension: 0,
            label: "Actuals",
          },
          {
            data: generateValues(divisions, expectedLength, true),
            backgroundColor: "rgba(195, 150, 5, 0.2)",
            borderColor: "rgba(195, 150, 5, 1)",
            borderWidth: 3,
            label: "Projection",
            fill: false,
            lineTension: 0,
          },
          ...datasets,
        ];
        break;
      case UPDATING_PROJECTION_STATE:
        datasets = [
          {
            data: generateValues(divisions, expectedLength, true),
            backgroundColor: "rgba(195, 150, 5, 0.2)",
            borderColor: "rgba(195, 150, 5, 1)",
            borderWidth: 3,
            label: "Projection",
            fill: false,
            lineTension: 0,
          },
          ...datasets,
        ];
        break;
      default:
        datasets = [
          {
            data: generateValues(divisions, expectedLength),
            backgroundColor: "rgba(195, 150, 5, 0.2)",
            borderColor: "rgba(195, 150, 5, 1)",
            borderWidth: 3,
            label: "Original Projection",
            fill: false,
            lineTension: 0,
          },
          ...datasets,
        ];
    }

    const updatedData = {
      labels: months,
      datasets,
    };
    setData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...divisions.map((division) => division.lineItems), projectionState]);

  return (
    <ChartTemplate
      chartId={chartId}
      datasets={data.datasets}
      height={375}
      labels={data.labels}
      setChartDownloadImage={setChartDownloadImage}
      suggestedMax={budget}
      type="line"
      valueFormatter={(value) => formatCurrency(value)}
    />
  );
}
