import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { debounce, get } from "lodash";
import { Form, Paragraph, TextArea } from "components/materials";

// This controlled input handles its own input value independent on any form it may be connected to;
// however, it will also allows parent components to receive state changes via a debounced onChange handler.
function Input({ initialValues, name, onChange, ...props }) {
  // `get` handles deeply nested values nicely, as opposed to initialValues[name]
  const initialValue = get(initialValues, name);
  const [inputValue, setInputValue] = useState(initialValue);

  // Reset the inner value if the form resets
  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue, initialValues]);

  const handleOnChange = useMemo(
    () => debounce((inputValue) => onChange(name, inputValue), 500),
    [name, onChange]
  );

  function handleOnInput(e) {
    setInputValue(e.target.value);
    handleOnChange(e.target.value);
  }

  return (
    <TextArea
      {...props}
      autoComplete="new-password"
      name={name}
      onInput={handleOnInput}
      value={inputValue}
    />
  );
}

export function FormNewTextArea({
  disabled,
  error,
  initialValues,
  fieldProps,
  label,
  labelProps,
  name,
  onChange,
  value,
  ...props
}) {
  return (
    <Form.Field
      label={label && <Paragraph {...labelProps}>{label}</Paragraph>}
      validationMessage={error}
      {...fieldProps}
    >
      <Input
        {...props}
        // "new-password" is a hack to prevent browser autocompletion
        autoComplete="new-password"
        disabled={disabled}
        name={name}
        initialValues={initialValues}
        onChange={onChange}
        value={value}
      />
    </Form.Field>
  );
}

FormNewTextArea.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  fieldProps: PropTypes.object,
  value: PropTypes.string,
};

FormNewTextArea.defaultProps = {
  disabled: false,
  label: "",
  labelProps: {},
  onBlur: () => {},
  fieldProps: {},
  value: "",
};
