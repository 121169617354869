import { Pane, Button } from "components/materials";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { PROJECT_STATUS, STEPS } from "./utils";
import { Header } from "./Header";

export function ProjectStatusStep({
  goBack,
  navigateToStep,
  updateOnboardingData,
}) {
  function updateProjectStatus(projectStatus) {
    updateOnboardingData({ projectStatus }).then(() =>
      navigateToStep(STEPS.BUDGET_DECISION)
    );
  }
  return (
    <Pane>
      <Header header={t("onboardingWizard.projectStatus.title")} />
      <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
        <Button
          appearance="primary"
          onClick={() => updateProjectStatus(PROJECT_STATUS.NO_BUDGET)}
          marginBottom={majorScale(2)}
          minWidth={225}
          width="20%"
        >
          {t("onboardingWizard.projectStatus.noBudgetYet")}
        </Button>

        <Button
          appearance="primary"
          onClick={() => updateProjectStatus(PROJECT_STATUS.UNFINISHED_BUDGET)}
          marginBottom={majorScale(2)}
          minWidth={225}
          width="20%"
        >
          {t("onboardingWizard.projectStatus.unfinishedBudget")}
        </Button>

        <Button
          appearance="primary"
          onClick={() => updateProjectStatus(PROJECT_STATUS.FINALIZED_BUDGET)}
          minWidth={225}
          width="20%"
        >
          {t("onboardingWizard.projectStatus.finalizedBudget")}
        </Button>
      </Pane>

      <Button
        onClick={() => {
          goBack();
        }}
      >
        Back
      </Button>
    </Pane>
  );
}
