import { Fragment } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Button, Pane, Spinner, Text } from "components/materials";
import t from "helpers/translate";
import { get } from "lodash";
import { majorScale } from "helpers/utilities";
import { uploadFile } from "images";

export function BudgetDropzone({
  buttonAppearance,
  dropzoneStyling,
  header,
  loading,
  onAdd,
  uploadedBudget,
  photoPaneStyling,
  textPaneStyling,
}) {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onAdd });
  return (
    <div {...getRootProps()}>
      <Pane
        display="flex"
        background="tint2"
        border="default"
        borderStyle="dotted"
        borderWidth={2}
        alignItems="center"
        {...dropzoneStyling}
      >
        {!loading && <input {...getInputProps()} />}
        <Pane display="flex" justifyContent="center" {...photoPaneStyling}>
          <Pane alt="" height={100} is="img" src={uploadFile} />
        </Pane>
        <Pane display="flex" flexDirection="column" {...textPaneStyling}>
          {loading ? (
            <Pane display="flex" alignItems="center">
              <Spinner size={majorScale(3)} marginRight={majorScale(1)} />
              <Text>
                {t("onboardingWizard.uploadTemplateStep.uploadingTemplate", {
                  uploadName: get(uploadedBudget, "name"),
                })}
              </Text>
            </Pane>
          ) : (
            <Fragment>
              {header}
              <Text>
                {t("onboardingWizard.uploadTemplateStep.dropzoneDescription")}
              </Text>
              <Pane>
                <Button
                  purpose="budget-dropzone"
                  appearance={buttonAppearance}
                  paddingX={majorScale(4)}
                >
                  {t("onboardingWizard.uploadTemplateStep.dropzoneButton")}
                </Button>
              </Pane>
            </Fragment>
          )}
        </Pane>
      </Pane>
    </div>
  );
}

BudgetDropzone.propTypes = {
  buttonAppearance: PropTypes.string.isRequired,
  dropzoneStyling: PropTypes.object,
  header: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  uploadedBudget: PropTypes.object.isRequired,
  photoPaneStyling: PropTypes.object,
  textPaneStyling: PropTypes.object,
};

BudgetDropzone.defaultProps = {
  dropzoneStyling: {},
  header: undefined,
  photoPaneStyling: {},
  textPaneStyling: {},
};
