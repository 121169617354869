import { useContext, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { EditIcon, CrossIcon } from "evergreen-ui";
import {
  Checkbox,
  Confirm,
  Pane,
  Paragraph,
  Table,
  Text,
} from "components/materials";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { ORGANIZATION_TYPE } from "helpers/enums";
import t from "helpers/translate";
import { formatDate } from "helpers/dateHelpers";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { find, get, groupBy, map, xor } from "lodash";
import { peopleGears } from "images";

function Member({ borrowers, member, submittedAt, role }) {
  const { id: memberId, name, email } = member;
  const foundBorrower = find(borrowers, ({ members }) =>
    members.some(({ id }) => id === memberId)
  );

  return (
    <Pane display="flex" width="100%">
      <Paragraph
        marginX={majorScale(3)}
        fontSize={12}
        fontWeight={500}
        width="30%"
      >
        {name}
      </Paragraph>
      <Paragraph
        fontSize={12}
        width={submittedAt || foundBorrower ? "35%" : "70%"}
        wordBreak="break-all"
        marginRight={majorScale(3)}
      >
        {email}
      </Paragraph>
      {foundBorrower && role === ORGANIZATION_TYPE.BORROWER && (
        <Pane width="35%" display="flex">
          <Paragraph
            fontSize={12}
            fontStyle="italic"
            marginRight={majorScale(1)}
          >
            {foundBorrower.registrationComplete
              ? "Access to Borrower Experience"
              : "Invited to Borrower Experience"}
          </Paragraph>
        </Pane>
      )}
      {submittedAt && (
        <Pane width="30%" display="flex">
          <Paragraph fontSize={12} fontWeight={500} marginRight={majorScale(1)}>
            SENT
          </Paragraph>
          <Paragraph fontSize={12}>{formatDate(submittedAt)}</Paragraph>
        </Pane>
      )}
    </Pane>
  );
}

function EmptyCard({
  isGuestOrganization,
  organization: { id },
  role,
  setSelectedStakeholderGroup,
  setOpenStakeholdersModal,
}) {
  return (
    <Pane display="inline-flex">
      <Pane marginLeft={majorScale(4)} marginRight={majorScale(6)}>
        <img alt="Missing Stakeholders" height={40} src={peopleGears} />
      </Pane>
      <Pane alignSelf="center" width={250}>
        <Text fontSize={12} marginRight={minorScale(1)}>
          {isGuestOrganization
            ? t("projectSettings.stakeholdersPanelImportCard", {
                role: t(`organizationType.${role}`),
              })
            : t("projectSettings.stakeholdersPanelEmptyCard")}
        </Text>
        {!isGuestOrganization && (
          <Text
            fontSize={12}
            color="blue"
            cursor="pointer"
            textDecoration="underline"
            onClick={() => {
              setSelectedStakeholderGroup({
                id,
                role,
              });
              setOpenStakeholdersModal(true);
            }}
          >
            Select Members
          </Text>
        )}
      </Pane>
    </Pane>
  );
}

function StakeholderHeaderFormatter({
  disableEdits,
  handleDelete,
  handleDismiss,
  id,
  member,
  name,
  role,
  setOpenStakeholdersModal,
  setSelectedStakeholderGroup,
  theme,
}) {
  return (
    <Pane display="inline-flex" width="95%" justifyContent="space-between">
      <Pane marginRight={majorScale(4)}>
        <Text
          fontSize={12}
          fontWeight={theme.fontWeights.DEMI}
          marginRight={minorScale(2)}
        >
          {name}
        </Text>
        <Text fontSize={12} fontStyle="italic">
          {t(`organizationType.${role}`)}
        </Text>
      </Pane>
      {!disableEdits && (
        <Pane>
          <EditIcon
            color="selected"
            cursor="pointer"
            marginRight={minorScale(2)}
            onClick={(e) => {
              preventEventBubbling(e);
              setSelectedStakeholderGroup({
                id,
                role,
              });
              setOpenStakeholdersModal(true);
            }}
          />
          <CrossIcon
            cursor="pointer"
            onClick={(e) => {
              preventEventBubbling(e);
              member
                ? handleDelete({ id, name, role })
                : handleDismiss({ id, role });
            }}
          />
        </Pane>
      )}
    </Pane>
  );
}

export function StakeholdersTable({
  borrowers,
  deleteStakeholderGroup,
  disableEdits,
  dismissStakeholderGroup,
  projectId,
  setOpenStakeholdersModal,
  setSelectedStakeholderGroup,
  setStakeholderIds,
  stakeholderIds,
  stakeholders,
  requireEmail,
  vendors,
  width,
}) {
  const theme = useContext(ThemeContext);

  const [confirmDeleteStakeholder, setConfirmDeleteStakeholder] = useState(
    null
  );

  const onDelete = ({ id, role }) => {
    deleteStakeholderGroup({
      variables: { projectId, organizationId: id, role },
    });
  };

  const handleDismiss = ({ id, role }) => {
    dismissStakeholderGroup({
      variables: { organizationId: id, role },
    });
  };

  const stakeholderItems = stakeholders.map((stakeholder) => {
    const isGuestOrganization =
      !!vendors &&
      !find(vendors, (vendor) => vendor.id === stakeholder.organization.id);

    return { ...stakeholder, isGuestOrganization };
  });

  return (
    <Pane width={width}>
      <Table>
        <Table.Body>
          {map(
            groupBy(stakeholderItems, (item) => [
              item.role,
              item.organization.id,
            ]),
            (items, key) => (
              <Fragment key={key}>
                <Table.Row>
                  <Table.TextSectionHeaderCell>
                    <StakeholderHeaderFormatter
                      disableEdits={
                        disableEdits || items[0].isGuestOrganization
                      }
                      handleDelete={setConfirmDeleteStakeholder}
                      handleDismiss={handleDismiss}
                      id={items[0].organization.id}
                      member={items[0].member}
                      name={items[0].organization.name}
                      role={items[0].role}
                      setOpenStakeholdersModal={setOpenStakeholdersModal}
                      setSelectedStakeholderGroup={setSelectedStakeholderGroup}
                      theme={theme}
                    />
                  </Table.TextSectionHeaderCell>
                </Table.Row>
                {items.map((item) => {
                  const showMember = requireEmail
                    ? !!get(item, "member.email")
                    : !!get(item, "member.name");
                  return (
                    <Table.Row key={item.id}>
                      <Table.TextCell>
                        {showMember ? (
                          <Fragment>
                            {setStakeholderIds && (
                              <Checkbox
                                checked={stakeholderIds.includes(item.id)}
                                marginY={minorScale(1)}
                                onChange={() =>
                                  setStakeholderIds(
                                    xor(stakeholderIds, [item.id])
                                  )
                                }
                              />
                            )}
                            <Member
                              member={item.member}
                              submittedAt={item.submittedAt}
                              role={item.role}
                              borrowers={borrowers}
                            />
                          </Fragment>
                        ) : (
                          <EmptyCard
                            isGuestOrganization={item.isGuestOrganization}
                            organization={item.organization}
                            role={item.role}
                            setOpenStakeholdersModal={setOpenStakeholdersModal}
                            setSelectedStakeholderGroup={
                              setSelectedStakeholderGroup
                            }
                          />
                        )}
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Fragment>
            )
          )}
        </Table.Body>
      </Table>
      {confirmDeleteStakeholder && (
        <Confirm
          open
          content={t("stakeholders.confirmDelete", {
            org: confirmDeleteStakeholder.name,
            role: t(`organizationType.${confirmDeleteStakeholder.role}`),
          })}
          header="Remove Stakeholder(s)"
          onCloseComplete={() => setConfirmDeleteStakeholder(null)}
          onConfirm={(close) => {
            onDelete(confirmDeleteStakeholder);
            close();
          }}
          cancelLabel="Cancel"
          confirmLabel="Remove"
        />
      )}
    </Pane>
  );
}

StakeholdersTable.propTypes = {
  width: PropTypes.string,
};

StakeholdersTable.defaultProps = {
  width: "100%",
};
