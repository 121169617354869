import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { lowerCase, startCase } from "lodash";
import { Button, Pane, Text } from "components/materials";
import { downloadUrl, downloadXlsx } from "helpers/downloadHelpers";
import {
  getNumberFormat,
  headerAlignment,
  headerFill,
  headerFont,
  totalsFill,
  totalsFont,
} from "helpers/exportXlsxHelpers";
import {
  getMonthHasActuals,
  getTotalPreviousBudgetAmount,
  getTotalBudgetAmount,
  getTotalFundingSourcesAmount,
  getTotalPreviousFundingSourcesAmount,
  isInsufficientFunds,
  selectIsBlank,
} from "helpers/projectionHelpers";
import {
  NEW_PROJECTION_STATE,
  UPDATED_PROJECTION_STATE,
  UPDATING_PROJECTION_STATE,
  NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE,
} from "hooks/use-projections";
import unformatNumber from "helpers/unformatNumber";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

function formatTitleCase(curveType) {
  return startCase(lowerCase(curveType));
}

function formatStartMonth(month, months) {
  return months[month - 1];
}

function formatDuration(duration) {
  // Excel gives a warning if a string contains only numbers
  return duration ? unformatNumber(duration) : "";
}

function formatSetting(settingName, value, months) {
  if (selectIsBlank(value)) return "";
  if (value === "MIXED") return formatTitleCase(value);
  if (settingName === "duration") return formatDuration(value);
  if (settingName === "startMonth") return formatStartMonth(value, months);
  if (settingName === "curveType") return formatTitleCase(value);
  return "";
}

function getMonthProjectionValues(
  month,
  projectionState,
  showOriginalProjection,
  showUpdatedProjection
) {
  switch (projectionState) {
    case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
      return [
        ...(showOriginalProjection ? [month.projected || 0] : []),
        ...(showUpdatedProjection ? [month.updatedProjection || 0] : []),
      ];
    case UPDATING_PROJECTION_STATE ||
      UPDATED_PROJECTION_STATE ||
      NEW_PROJECTION_STATE:
      return [month.updatedProjection || 0];
    default:
      return [month.updatedProjection || 0];
  }
}

function getProjectionColumnValues(
  values,
  projectionState,
  showOriginalProjection,
  showUpdatedProjection
) {
  return values.reduce(
    (projectionValues, month) => [
      ...projectionValues,
      ...getMonthProjectionValues(
        month,
        projectionState,
        showOriginalProjection,
        showUpdatedProjection
      ),
    ],
    []
  );
}

function getMonthColumnNames(
  projectionState,
  monthHasActuals,
  showOriginalProjection,
  showUpdatedProjection
) {
  const updatedProjectionName = monthHasActuals
    ? "Actual"
    : "Updated Projection";

  switch (projectionState) {
    case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
      return [
        ...(showOriginalProjection ? ["Projected"] : []),
        ...(showUpdatedProjection ? [updatedProjectionName] : []),
      ];
    case UPDATING_PROJECTION_STATE ||
      UPDATED_PROJECTION_STATE ||
      NEW_PROJECTION_STATE:
      return monthHasActuals ? ["Actual"] : ["Projection"];
    default:
      return monthHasActuals ? ["Actual"] : ["Projection"];
  }
}

function getMonthColumns(
  months,
  projectionState,
  actualsCount,
  showOriginalProjection,
  showUpdatedProjection
) {
  return months.reduce((monthAcc, month, monthIndex) => {
    const monthHasActuals = getMonthHasActuals(monthIndex, actualsCount);
    const headers = getMonthColumnNames(
      projectionState,
      monthHasActuals,
      showOriginalProjection,
      showUpdatedProjection
    );
    const columns = headers.map((name) => ({
      name: `${name} - ${month}`,
      type: "currency",
    }));

    return [...monthAcc, ...columns];
  }, []);
}

function getColumns(
  baseColumns,
  months,
  projectionState,
  actualsCount,
  showOriginalProjection,
  showUpdatedProjection
) {
  const projectionColumns = getMonthColumns(
    months,
    projectionState,
    actualsCount,
    showOriginalProjection,
    showUpdatedProjection
  );

  const columns = [...baseColumns, ...projectionColumns];

  return columns.map(({ name, textAlign, type }) => ({
    id: name,
    header: name,
    width: 25,
    style: {
      alignment: { horizontal: textAlign || "right" },
      numFmt: getNumberFormat(type),
    },
  }));
}

function getLineItemRow(
  lineItem,
  showOriginalProjection,
  showUpdatedProjection,
  showPreviousBudget,
  months,
  projectionState
) {
  const baseColumns = [
    lineItem.name,
    formatSetting("curveType", lineItem.curveType),
    formatSetting("startMonth", lineItem.startMonth, months),
    formatSetting("duration", lineItem.duration),
    ...(showPreviousBudget ? [lineItem.previousBudgetAmount] : []),
    lineItem.budgetAmount,
  ];

  const projectionColumns = getProjectionColumnValues(
    lineItem.values,
    projectionState,
    showOriginalProjection,
    showUpdatedProjection
  );

  return [...baseColumns, ...projectionColumns];
}

function getLineItemRows(
  division,
  showOriginalProjection,
  showUpdatedProjection,
  showPreviousBudget,
  months,
  projectionState
) {
  return division.lineItems.reduce((lineItemRows, lineItem) => {
    return [
      ...lineItemRows,
      getLineItemRow(
        lineItem,
        showOriginalProjection,
        showUpdatedProjection,
        showPreviousBudget,
        months,
        projectionState
      ),
    ];
  }, []);
}

function getDivisionHeaderRow(division, months) {
  return [
    division.name,
    formatSetting("curveType", division.curveType),
    formatSetting("startMonth", division.startMonth, months),
    formatSetting("duration", division.duration),
  ];
}

function getDivisionFooterRow(
  division,
  showOriginalProjection,
  showUpdatedProjection,
  showPreviousBudget,
  projectionState
) {
  const {
    divisionBudgetAmount,
    divisionSubtotals,
    divisionPreviousBudgetAmount,
  } = division;

  const baseColumns = [
    "Subtotal",
    "",
    "",
    "",
    ...(showPreviousBudget ? [divisionPreviousBudgetAmount] : []),
    divisionBudgetAmount,
  ];

  const projectionColumns = getProjectionColumnValues(
    divisionSubtotals,
    projectionState,
    showOriginalProjection,
    showUpdatedProjection
  );

  return [...baseColumns, ...projectionColumns];
}

function getProjectionFooterRow(
  divisions,
  projectionTotals,
  showOriginalProjection,
  showUpdatedProjection,
  showPreviousBudget,
  projectionState
) {
  const totalBudgetAmount = getTotalBudgetAmount(divisions);
  const totalPreviousBudgetAmount = getTotalPreviousBudgetAmount(divisions);

  const baseColumns = [
    "Total",
    "",
    "",
    "",
    ...(showPreviousBudget ? [totalPreviousBudgetAmount] : []),
    totalBudgetAmount,
  ];

  const projectionColumns = getProjectionColumnValues(
    projectionTotals,
    projectionState,
    showOriginalProjection,
    showUpdatedProjection
  );

  return [...baseColumns, ...projectionColumns];
}

function getFundingSourceRow(
  fundingSource,
  showOriginalProjection,
  showUpdatedProjection,
  showPreviousFundingSourceAmount,
  projectionState
) {
  const previousAmount = isInsufficientFunds(fundingSource)
    ? ""
    : fundingSource.previousAmount;
  const baseColumns = [
    fundingSource.name,
    fundingSource.organization,
    ...(showPreviousFundingSourceAmount ? [previousAmount] : []),
    fundingSource.amount,
    fundingSource.type ? t(`fundingSourceType.${fundingSource.type}`) : "",
    fundingSource.firstMonth,
    fundingSource.finalMonth,
  ];

  const projectionColumns = getProjectionColumnValues(
    fundingSource.values,
    projectionState,
    showOriginalProjection,
    showUpdatedProjection
  );

  return [...baseColumns, ...projectionColumns];
}

function getFundingSourcesFooterRow(
  fundingSources,
  fundingSourceTotals,
  showOriginalProjection,
  showUpdatedProjection,
  showPreviousFundingSourceAmount,
  projectionState
) {
  const totalFundingSourceAmount = getTotalFundingSourcesAmount(fundingSources);
  const totalPreviousFundingSourceAmount = getTotalPreviousFundingSourcesAmount(
    fundingSources
  );

  const baseColumns = [
    "Total",
    "",
    ...(showPreviousFundingSourceAmount
      ? [totalPreviousFundingSourceAmount]
      : []),
    totalFundingSourceAmount,
    "",
    "",
    "",
  ];

  const projectionColumns = getProjectionColumnValues(
    fundingSourceTotals,
    projectionState,
    showOriginalProjection,
    showUpdatedProjection
  );

  return [...baseColumns, ...projectionColumns];
}

function addBudgetSheet(workbook, data) {
  const {
    actualsCount,
    divisions,
    months,
    projectionState,
    projectionTotals,
    showPreviousBudget,
    showOriginalProjection,
    showUpdatedProjection,
  } = data;
  const budgetSheet = workbook.addWorksheet("Budget");

  const baseColumns = [
    { name: "Line Item", textAlign: "left" },
    { name: "Curve Type" },
    { name: "Start Month" },
    { name: "Duration (months)" },
    ...(showPreviousBudget
      ? [{ name: "Previous Budget", type: "currency" }]
      : []),
    { name: "Current Budget", type: "currency" },
  ];

  budgetSheet.columns = getColumns(
    baseColumns,
    months,
    projectionState,
    actualsCount,
    showOriginalProjection,
    showUpdatedProjection
  );

  const header = budgetSheet.getRow(1);
  header.font = headerFont;
  header.fill = headerFill;
  header.alignment = headerAlignment;

  divisions.forEach((division) => {
    const divisionHeader = budgetSheet.addRow(
      getDivisionHeaderRow(division, months)
    );
    divisionHeader.font = { bold: true };
    budgetSheet.addRows(
      getLineItemRows(
        division,
        showOriginalProjection,
        showUpdatedProjection,
        showPreviousBudget,
        months,
        projectionState
      )
    );
    const divisionFooter = budgetSheet.addRow(
      getDivisionFooterRow(
        division,
        showOriginalProjection,
        showUpdatedProjection,
        showPreviousBudget,
        projectionState
      )
    );
    budgetSheet.getCell(`A${divisionFooter._number}`).alignment = {
      horizontal: "right",
    };
    divisionFooter.font = totalsFont;
    divisionFooter.fill = totalsFill;
  });

  const projectionFooter = budgetSheet.addRow(
    getProjectionFooterRow(
      divisions,
      projectionTotals,
      showOriginalProjection,
      showUpdatedProjection,
      showPreviousBudget,
      projectionState
    )
  );
  budgetSheet.getCell(`A${projectionFooter._number}`).alignment = {
    horizontal: "right",
  };
  projectionFooter.font = totalsFont;
  projectionFooter.fill = totalsFill;

  return workbook;
}

function addFundingSourcesSheet(workbook, data) {
  const {
    actualsCount,
    months,
    projectionState,
    showPreviousFundingSourceAmount,
    showOriginalProjection,
    showUpdatedProjection,
    tableFundingSources,
    fundingSourceTotals,
  } = data;
  const fundingSourcesSheet = workbook.addWorksheet("Funding Sources");

  const baseColumns = [
    { name: "Name" },
    { name: "Organization" },
    ...(showPreviousFundingSourceAmount
      ? [{ name: "Previous Amount", type: "currency" }]
      : []),
    { name: "Amount", type: "currency" },
    { name: "Type" },
    { name: "Projected Start" },
    { name: "Projected End" },
  ];

  fundingSourcesSheet.columns = getColumns(
    baseColumns,
    months,
    projectionState,
    actualsCount,
    showOriginalProjection,
    showUpdatedProjection
  );

  const header = fundingSourcesSheet.getRow(1);
  header.font = headerFont;
  header.fill = headerFill;
  header.alignment = headerAlignment;

  tableFundingSources.forEach((fundingSource) => {
    fundingSourcesSheet.addRow(
      getFundingSourceRow(
        fundingSource,
        showOriginalProjection,
        showUpdatedProjection,
        showPreviousFundingSourceAmount,
        projectionState
      )
    );
  });

  const fundingSourcesFooter = fundingSourcesSheet.addRow(
    getFundingSourcesFooterRow(
      tableFundingSources,
      fundingSourceTotals,
      showOriginalProjection,
      showUpdatedProjection,
      showPreviousFundingSourceAmount,
      projectionState
    )
  );
  fundingSourcesSheet.getCell(`A${fundingSourcesFooter._number}`).alignment = {
    horizontal: "right",
  };
  fundingSourcesFooter.font = totalsFont;
  fundingSourcesFooter.fill = totalsFill;

  return workbook;
}

function exportXlsx(data) {
  let workbook = new ExcelJS.Workbook();

  workbook = addBudgetSheet(workbook, data);
  workbook = addFundingSourcesSheet(workbook, data);
  downloadXlsx(workbook, "RabbetProjection.xlsx");
}

export function ProjectionDownloads({
  actualsCount,
  chartId,
  columns,
  divisions,
  fundingSourceTotals,
  months,
  projectionState,
  projectionTotals,
  showProjectionCharts,
  tableFundingSources,
}) {
  const {
    showOriginalProjection,
    showUpdatedProjection,
    showPreviousBudget,
    showPreviousFundingSourceAmount,
  } = columns;
  return (
    <Pane display="flex" alignItems="center" marginRight={majorScale(3)}>
      <Text>Download:</Text>
      {showProjectionCharts && (
        <Button
          marginLeft={majorScale(3)}
          onClick={() => {
            const chart = document.getElementById(chartId);
            const base64ImageUrl = chart ? chart.toDataURL("image/jpeg") : "";
            downloadUrl(base64ImageUrl, "ProjectionGraph.jpeg");
          }}
        >
          Graph
        </Button>
      )}
      <Button
        marginLeft={majorScale(2)}
        onClick={() =>
          exportXlsx({
            divisions,
            projectionTotals,
            tableFundingSources,
            fundingSourceTotals,
            months,
            actualsCount,
            projectionState,
            showPreviousBudget,
            showPreviousFundingSourceAmount,
            showOriginalProjection,
            showUpdatedProjection,
          })
        }
      >
        Excel
      </Button>
    </Pane>
  );
}
