import { useContext } from "react";
import PropTypes from "prop-types";
import { DocumentTable } from "components/templates";
import { fromBase64 } from "components/materials/FastDataTable";
import { DocumentContext } from "contexts/documentContext";
import { UserContext } from "helpers/behaviors";
import { getSearchByKey } from "helpers/queryStringHelpers";

const defaultColumns = ["documentCard"];

function DocumentsViewerSidebar({
  closeViewer,
  onClickDocumentCard,
  onCardOrderChange,
}) {
  const {
    document: selectedDocument,
    documents,
    defaultGroup,
    history,
    match,
  } = useContext(DocumentContext);
  const { organizationId } = useContext(UserContext);

  const getRowState = (document) => {
    return selectedDocument.id === document.id
      ? { background: "info" }
      : { background: "default" };
  };

  const serialized = getSearchByKey(history, "table");
  const { filterConfig, sortConfig, groupConfig } = serialized
    ? fromBase64(serialized)
    : {};

  return (
    <DocumentTable
      defaultColumns={defaultColumns}
      defaultFilters={!serialized ? [] : filterConfig}
      defaultGroup={groupConfig || defaultGroup}
      defaultSort={sortConfig}
      documents={documents}
      getHeaderState={() => ({ zIndex: -1 })}
      getRowState={getRowState}
      history={history}
      match={match}
      onChange={onCardOrderChange}
      onClickDocumentCard={onClickDocumentCard}
      organizationId={organizationId}
      tableName="DocumentCardsListTable"
    />
  );
}

DocumentsViewerSidebar.propTypes = {
  onCardOrderChange: PropTypes.func,
  onClickDocumentCard: PropTypes.func,
};

DocumentsViewerSidebar.defaultProps = {
  onCardOrderChange: undefined,
};

export default DocumentsViewerSidebar;
