import { Fragment } from "react";
import { NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE } from "hooks/use-projections";
import { Table } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import {
  amountFieldNames,
  getMonthHasActuals,
  getTotalFundingSourcesAmount,
  getTotalPreviousFundingSourcesAmount,
} from "helpers/projectionHelpers";
import { getCellState, tableBorder } from "./util";

const cellProps = {
  borderLeft: tableBorder,
  textAlign: "right",
};

export function FundingSourceProjectionTableTotal({
  actualsCount,
  columns,
  fundingSources,
  fundingSourceTotals,
  project,
  projectionState,
}) {
  const { months } = project;
  const totalFundingSourceAmount = getTotalFundingSourcesAmount(fundingSources);
  const totalPreviousFundingSourceAmount = getTotalPreviousFundingSourcesAmount(
    fundingSources
  );

  return (
    <Table.Row>
      <Table.TextFooterCell textAlign="right">Total</Table.TextFooterCell>
      <Table.TextFooterCell />
      {columns.showPreviousFundingSourceAmount && (
        <Table.TextCell>
          {formatCurrency(totalPreviousFundingSourceAmount)}
        </Table.TextCell>
      )}
      <Table.TextFooterCell>
        {formatCurrency(totalFundingSourceAmount)}
      </Table.TextFooterCell>
      <Table.TextFooterCell colSpan={3} />
      {months.map((_, monthIndex) => {
        const monthHasActuals = getMonthHasActuals(monthIndex, actualsCount);

        const totals = fundingSourceTotals[monthIndex];

        switch (projectionState) {
          case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
            return (
              <Fragment>
                {columns.showOriginalProjection && (
                  <Table.TextFooterCell
                    {...cellProps}
                    state={getCellState(
                      amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                    )}
                  >
                    {formatCurrency(totals.projected)}
                  </Table.TextFooterCell>
                )}
                {columns.showUpdatedProjection && (
                  <Table.TextFooterCell
                    {...cellProps}
                    state={getCellState(
                      monthHasActuals
                        ? amountFieldNames.ACTUALS
                        : amountFieldNames.UPDATED_PROJECTION_AMOUNTS
                    )}
                  >
                    {formatCurrency(totals.updatedProjection)}
                  </Table.TextFooterCell>
                )}
              </Fragment>
            );
          default:
            return (
              <Table.TextFooterCell
                {...cellProps}
                state={getCellState(
                  monthHasActuals
                    ? amountFieldNames.ACTUALS
                    : amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                )}
              >
                {formatCurrency(totals.updatedProjection)}
              </Table.TextFooterCell>
            );
        }
      })}
      <Table.TextFooterCell borderLeft={tableBorder} />
    </Table.Row>
  );
}
