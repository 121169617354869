import { useContext, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { CaretDownIcon } from "evergreen-ui";
import { Button, Menu, Popover } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { Position } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import CustomizeColumns from "./CustomizeColumns";
import DownloadDocuments from "./DownloadDocuments";
import ExportToCSV from "./ExportToCSV";
import ExportToXLSX from "./ExportToXLSX";
import GroupBy from "./GroupBy";
import ManageViews from "./ManageViews";
import SaveCurrentView from "./SaveCurrentView";
import ToggleSections from "./ToggleSections";

const ActionsDropdown = (props) => {
  const { hasPermission } = useContext(UserContext);
  const [saveRedirect, setSaveRedirect] = useState(false);
  const {
    onSaveView,
    onUpdateViews,
    canManagePublicViews,
    dataColumns,
    groupConfig,
    tableName,
    views,
    scopeOrganizationsProps,
  } = props;

  const isEnabled = (control) => {
    return !props.disable.includes(control);
  };

  return (
    <Popover
      shouldCloseOnExternalClick={false}
      position={Position.BOTTOM_RIGHT}
      content={({ close }) => (
        <Menu>
          <Menu.Group>
            {isEnabled(CustomizeColumns) && (
              <CustomizeColumns {...props} closeDropdown={close} />
            )}
            {isEnabled(GroupBy) && <GroupBy {...props} closeDropdown={close} />}
            <Fragment>
              {isEnabled(SaveCurrentView) && onSaveView && (
                <SaveCurrentView
                  canManagePublicViews={canManagePublicViews}
                  dataColumns={dataColumns}
                  closeDropdown={close}
                  groupConfig={groupConfig}
                  onSaveView={onSaveView}
                  onUpdateViews={onUpdateViews}
                  saveRedirect={saveRedirect}
                  scopeOrganizationsProps={scopeOrganizationsProps}
                  setSaveRedirect={setSaveRedirect}
                  tableName={tableName}
                  views={views}
                />
              )}
              {isEnabled(ManageViews) && onUpdateViews && (
                <ManageViews
                  canManagePublicViews={canManagePublicViews}
                  closeDropdown={close}
                  onUpdateViews={onUpdateViews}
                  saveRedirect={saveRedirect}
                  setSaveRedirect={setSaveRedirect}
                  scopeOrganizationsProps={scopeOrganizationsProps}
                  views={views}
                />
              )}
            </Fragment>
          </Menu.Group>

          <Menu.Divider />

          {hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT) &&
            isEnabled(DownloadDocuments) && (
              <Fragment>
                <Menu.Group>
                  <DownloadDocuments {...props} closeDropdown={close} />
                  {hasPermission(PERMISSION_ACTION.STAMP_DOCUMENTS) && (
                    <DownloadDocuments
                      {...props}
                      closeDropdown={close}
                      stamped
                    />
                  )}
                </Menu.Group>
                <Menu.Divider />
              </Fragment>
            )}

          {isEnabled(ToggleSections) && ToggleSections.shouldDisplay(props) && (
            <Fragment>
              <Menu.Group>
                <ToggleSections {...props} closeDropdown={close} />
              </Menu.Group>

              <Menu.Divider />
            </Fragment>
          )}

          {(isEnabled(ExportToCSV) || isEnabled(ExportToXLSX)) && (
            <Menu.Group>
              {isEnabled(ExportToCSV) && (
                <ExportToCSV {...props} closeDropdown={close} />
              )}
              {isEnabled(ExportToXLSX) && (
                <ExportToXLSX {...props} closeDropdown={close} />
              )}
            </Menu.Group>
          )}
        </Menu>
      )}
    >
      <Button purpose="tables actions open">
        Table Actions <CaretDownIcon />
      </Button>
    </Popover>
  );
};

ActionsDropdown.propTypes = {
  onSaveView: PropTypes.func,
  onUpdateViews: PropTypes.func,
  disable: PropTypes.array,
};

ActionsDropdown.defaultProps = {
  disable: [],
};

export default ActionsDropdown;
