import { Table, Text } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";

function VendorProjects({ projects, onProject }) {
  return projects.length > 0 ? (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TextHeaderCell content="Project" />
          <Table.TextHeaderCell content="Amount" />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {projects.map((project) => (
          <Table.Row key={project.id} onClick={() => onProject(project)}>
            <Table.TextCell content={project.name} />
            <Table.TextCell content={formatCurrency(project.amount)} />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Text>{t("vendorSidebar.noProjects")}</Text>
  );
}

export default VendorProjects;
