import { useEffect } from "react";
import { isEmpty, isObject, keys, merge, reduce } from "lodash";

/*  The formikProps has a nested errors object, corresponding to field names with errors
    Extract the field(s) with errors and scroll the first error into view every time a submit is atttempted
    and there are errors
*/

/* this function traverses a  nested object and returns an object with dot.notation.keys
  {pizza: {available: true, type: "sicilian"}} ----->  {"pizza.available": true, "pizza.type": sicilian}
*/
const flattenKeys = (obj, path = []) =>
  !isObject(obj)
    ? { [path.join(".")]: obj }
    : reduce(
        obj,
        (acc, next, key) => merge(acc, flattenKeys(next, [...path, key])),
        {}
      );

export function ScrollToFormikError({ formikProps: { errors, submitCount } }) {
  useEffect(() => {
    if (submitCount !== 0 && !isEmpty(errors)) {
      const errorField = keys(flattenKeys(errors))[0];
      const element = window.document.querySelector(`[name="${errorField}"]`);
      // Ensure the element was found and has the needed function
      typeof element?.scrollIntoView === "function" && element.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);
  return null;
}
