import { AGREEMENT_TYPE } from "helpers/enums";

export const CHANGE_ORDER_TYPES = [
  AGREEMENT_TYPE.EXPOSURE,
  AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER,
  AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
];

export const PENDING_CHANGE_ORDER_TYPES = [
  AGREEMENT_TYPE.EXPOSURE,
  AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER,
];
