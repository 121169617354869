import { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { DuplicateIcon } from "evergreen-ui";
import { VendorSidebar } from "components/containers";
import { GET } from "components/containers/VendorsPage";
import { Link, Sidebar } from "components/materials";
import { UserContext } from "helpers/behaviors";
import analytics from "helpers/analytics";

export function VendorLink({ linkText, noIcon, vendorId, vendors }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { organizationId } = useContext(UserContext);
  const vendor = vendors.find(({ id }) => id === vendorId);
  const isGuestVendor = !vendor;
  const [getQuery, query] = useLazyQuery(GET, {
    skip: isGuestVendor,
    variables: { organizationId, vendorId },
  });
  const history = useHistory();

  const navigateToProject = (project) =>
    history.push(`/projects/${project.id}`);

  const navigateToDocument = (document) =>
    history.push(
      `/projects/${document.project.id}/documentation/${document.id}`
    );

  if (isGuestVendor) return null;

  return (
    <Fragment>
      <Link
        purpose="vendor open"
        onClick={() => {
          getQuery();
          setSidebarOpen(true);
          analytics.track("Vendor Opened");
        }}
        size={400}
      >
        {linkText || `More information on ${vendor.name} `}
        {!noIcon && <DuplicateIcon size={12} />}
      </Link>
      <Sidebar
        isShown={sidebarOpen}
        onCloseComplete={() => setSidebarOpen(false)}
      >
        {sidebarOpen && (
          <VendorSidebar
            navigateToDocument={navigateToDocument}
            navigateToProject={navigateToProject}
            query={query}
            refetchQueries={[
              {
                query: GET,
                variables: { organizationId, vendorId },
              },
            ]}
            vendorId={vendorId}
          />
        )}
      </Sidebar>
    </Fragment>
  );
}

VendorLink.propTypes = {
  linkText: PropTypes.string,
  noIcon: PropTypes.bool,
  vendorId: PropTypes.string.isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired })
  ).isRequired,
};
