import gql from "graphql-tag";

export default gql`
  fragment DrawDivisionsFragment on Draw {
    lineItems {
      id
      scopeId
      adjustmentsAmount
      balanceToFundAmount
      budgetAmount
      committedAmount
      debtDisbursedToDateAmount
      documentedRequestedAmount
      equityDisbursedToDateAmount
      exposedAmount
      grossDocumentedRequestedAmount
      grossEndorsedPercentComplete
      grossEndorsedRequestedToDateAmount
      grossInspectedRequestedToDateAmount
      grossPercentComplete
      grossPercentRemaining
      grossRequestedAmount
      grossRequestedPreviouslyAmount
      grossRequestedToDateAmount
      grossUndocumentedRequestedAmount
      grossUnendorsedRequestedToDateAmount
      inspectionPercentComplete
      masterFormatDivision
      materialsStoredAmount
      name
      number
      originalBudgetAmount
      outOfContractAmount
      percentComplete
      percentRemaining
      position
      previousAdjustmentsAmount
      previousInspectionPercentComplete
      requestedAmount
      requestedPreviouslyAmount
      requestedToDateAmount
      retainageAmount
      retainagePercentage
      retainageToDateAmount
      totalAgreementsAmount
      totalCommitmentsAmount
      types
      undocumentedRequestedAmount
      untrackedContingencyAmount
      comments {
        id
        body
        insertedAt
        author {
          id
          fullName
        }
      }
      division {
        id
        scopeId
        name
        position
      }
      issues {
        id
        lineItemId
        name
        severity
      }
      jobCostCodes {
        id
        code
      }
      rules(state: "pending", enabled: true) {
        id
        scopeId
        enabled
        name
        state
        type
      }
      superLineItem {
        id
        name
      }
    }
  }
`;
