import { Banner } from "components/materials";
import t from "helpers/translate";
import humanReadableList from "helpers/humanReadableList";
import { isBefore } from "helpers/dateHelpers";

export function ProjectionWarning({ projectionUpdatedAt, project }) {
  const {
    adjustmentsMostRecentlyUpdatedAt,
    budgetMostRecentlyUpdatedAt,
    updatedAt,
  } = project;

  const adjustmentsChangedSinceLastProjection = isBefore(
    projectionUpdatedAt,
    adjustmentsMostRecentlyUpdatedAt
  );
  const budgetChangedSinceLastProjection = isBefore(
    projectionUpdatedAt,
    budgetMostRecentlyUpdatedAt
  );
  const projectChangedSinceLastProjection = isBefore(
    projectionUpdatedAt,
    updatedAt
  );

  const noChangesSinceLastProjection =
    (!adjustmentsChangedSinceLastProjection &&
      !budgetChangedSinceLastProjection &&
      !projectChangedSinceLastProjection) ||
    !projectionUpdatedAt;

  const warnings = [];
  if (budgetChangedSinceLastProjection) warnings.push("budget");
  if (adjustmentsChangedSinceLastProjection) warnings.push("adjustments");
  if (projectChangedSinceLastProjection) warnings.push("project settings");

  if (noChangesSinceLastProjection) return null;

  // true + true = 2, true + false = 1, false + false = 0
  const count =
    adjustmentsChangedSinceLastProjection +
    projectChangedSinceLastProjection +
    1;

  return (
    <Banner
      border
      icon="infoSignIcon"
      mainText={t("projection.warningBanner", {
        count,
        warnings: humanReadableList(warnings),
      })}
      secondaryText="Please review projections, as calculations may have changed."
    />
  );
}
