import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import { get } from "lodash";
import { EditTableViews } from "components/containers";
import { Link, Text } from "components/materials";
import {
  COMPARATORS,
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
} from "components/materials/FastDataTable";
import { ActionItemBlankSlate, AddProjectButton } from "components/templates";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  getCommitmentAggregates,
  getFundingSourceAggregates,
} from "helpers/fundingSourceHelpers";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { majorScale } from "helpers/utilities";
import { useColumns } from "./columns";

function getDefaultViews({ defaultViews, isMultiOrgUser, isDeveloper }) {
  // "defaultViews" are passed directly from the ReportsProjectsPage
  if (defaultViews.length > 0) return defaultViews;
  const columnConfig = ["projectName"]
    .concat(isDeveloper ? ["lendersList"] : [])
    .concat(["projectLocation"])
    .concat(isDeveloper ? ["equityCommitted"] : ["commitments"])
    .concat([
      "projectTotal",
      "amountRemaining",
      "percentComplete",
      "currentDraw",
      "timeOutstanding",
    ]);
  const filterConfig = [
    {
      enum: ["Active", "Pre-Development", "Test", "Under Contract"],
      input: ["Active", "Pre-Development", "Test", "Under Contract"],
      key: "projectStatus",
      operator: COMPARATORS.EXACT.value,
    },
  ];
  const groupConfig = isMultiOrgUser ? { columnId: "organizationName" } : {};
  const sortConfig = { columnId: "timeOutstanding", direction: "desc" };

  return [
    {
      config: toBase64({ columnConfig, filterConfig, groupConfig, sortConfig }),
      name: "Default",
      isDefault: true,
    },
  ];
}

export function PortfolioProjectTable({
  canCreateProjects,
  dataLoading,
  defaultViews,
  history,
  isReport,
  onConfigChange,
  orgData,
  paginatedProjects,
  scopeOrganizationsProps,
  selectedOrganization,
  tableName,
}) {
  const {
    hasPermission,
    hasPermissionAcrossProfiles,
    isDeveloper,
    isMultiOrgUser,
    userId,
  } = useContext(UserContext);

  const projects = get(paginatedProjects, "results", []);

  const columns = useColumns({
    hasPermission,
    hasPermissionAcrossProfiles,
    isDeveloper,
    isReport,
    orgData,
    selectedOrganization,
    userId,
  });

  const preparedProjects = useMemo(
    () =>
      projects.map((project) => {
        const shouldRedactFundingSources = !hasPermission(
          PERMISSION_ACTION.ACCESS_FUNDING_SOURCES,
          project.organization
        );
        return {
          ...project,
          fundingSourceAggregates: shouldRedactFundingSources
            ? null
            : getFundingSourceAggregates(project.fundingSourceGroups),
          commitmentAggregates: shouldRedactFundingSources
            ? null
            : getCommitmentAggregates(project),
          shouldRedactFundingSources,
          shouldRedactStakeholders: !hasPermission(
            PERMISSION_ACTION.ACCESS_STAKEHOLDERS,
            project.organization
          ),
          shouldRedactTeam: !hasPermission(
            PERMISSION_ACTION.TEAM_MANAGEMENT,
            project.organization
          ),
        };
      }),
    [projects, hasPermission]
  );

  return (
    <EditTableViews
      canManagePublicViews={
        selectedOrganization
          ? hasPermission(
              PERMISSION_ACTION.SAVE_TABLE_VIEWS,
              selectedOrganization
            )
          : (organization) =>
              hasPermission(PERMISSION_ACTION.SAVE_TABLE_VIEWS, organization)
      }
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={get(selectedOrganization, "id")}
      defaultViews={getDefaultViews({
        defaultViews,
        isMultiOrgUser,
        isDeveloper,
      })}
      tableName={tableName}
    >
      {(editTableViewsProps) => (
        <FastDataTable
          columns={columns}
          controls={(controlsProps) => (
            <FastDataTableAdvancedControls
              {...controlsProps}
              {...editTableViewsProps}
              disable={[FastDataTableDownloadDocuments]}
              isReport={isReport}
              pinnedFilters={[
                "projectStatus",
                "type",
                "productType",
                "region",
                "team",
              ]}
              scopeOrganizationsProps={scopeOrganizationsProps}
              searchPlaceholder="Search Projects..."
            />
          )}
          empty={
            projects.length === 0 &&
            (dataLoading ? (
              "Loading..."
            ) : (
              <ActionItemBlankSlate
                actionItem={
                  canCreateProjects ? (
                    <AddProjectButton marginLeft={majorScale(1)} />
                  ) : null
                }
                content={
                  !isMultiOrgUser && !canCreateProjects ? (
                    <Text>
                      You don’t have permission to create a new project.{" "}
                      {<Link href="mailto:help@rabbet.com">Contact us</Link>} if
                      you have any questions, or to learn more about projects in
                      Rabbet.
                    </Text>
                  ) : (
                    <Text>You have no projects yet.</Text>
                  )
                }
              />
            ))
          }
          items={preparedProjects}
          onClickRow={(project) => history.push(`/projects/${project.id}`)}
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(editTableViewsProps, "views.0.config")
          }
          onConfigChange={onConfigChange}
        />
      )}
    </EditTableViews>
  );
}

PortfolioProjectTable.propTypes = {
  canCreateProjects: PropTypes.bool,
  defaultViews: PropTypes.array,
  history: PropTypes.object.isRequired,
  isReport: PropTypes.bool,
  projects: PropTypes.array.isRequired,
  scopeOrganizationsProps: PropTypes.object,
  scopeViewsToOrganization: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
};

PortfolioProjectTable.defaultProps = {
  defaultViews: [],
};
