import { countBy, get, set } from "lodash";
import {
  dateServerToForm,
  dateFormToServer,
  isInvalidDate,
} from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import { formatCurrency } from "helpers/formatCurrency";
import { add } from "helpers/math";
import unformatNumber from "helpers/unformatNumber";

export function validate(values) {
  const errors = {};
  if (isBlank(values.templateId)) {
    errors.templateId = "Please select a template";
  }
  if (isBlank(values.number)) errors.number = "Please enter a number";
  if (isBlank(values.date)) errors.date = "Please enter a date";
  else if (isInvalidDate(values.date)) errors.date = "Date is invalid";
  if (values.lineItems.length === 0) {
    errors._lineItems = "Please add a line item";
  }
  const count = countBy(values.lineItems, "lineItemObject.id");
  values.lineItems.forEach((lineItem, index) => {
    if (!get(lineItem, "lineItemObject.id")) {
      set(
        errors,
        `lineItems.${index}.lineItemObject`,
        "Please select a line item"
      );
    } else if (count[lineItem.lineItemObject.id] > 1) {
      set(
        errors,
        `lineItems.${index}.lineItemObject`,
        "Please remove duplicate line item"
      );
    }
    if (isBlank(lineItem.amount)) {
      set(errors, `lineItems.${index}.amount`, "Please enter an amount");
    }
  });
  return errors;
}

export function initialValues(projectId, drawId, templateId, organizationId) {
  return {
    projectId,
    drawId,
    templateId,
    number: "",
    date: dateServerToForm(Date.now()),
    lineItems: [
      {
        lineItemObject: { id: null, name: null },
        amount: 0,
        retainageAmount: 0,
        retainageToDateAmount: 0,
      },
    ],
    type: "INVOICE",
    vendor: { id: organizationId },
  };
}

export function submitValues(values, lineItems) {
  const formattedLineItems = values.lineItems.map(
    ({
      amount,
      grossAmount,
      lineItemObject,
      lineItemIndex,
      retainageAmount,
      retainageToDateAmount,
    }) => ({
      amount: formatCurrency(amount),
      grossAmount:
        unformatNumber(retainageAmount) === 0
          ? formatCurrency(amount)
          : formatCurrency(grossAmount),
      lineItemIndex,
      lineItemId: lineItemObject.id,
      name: lineItems.find((li) => li.id === lineItemObject.id).name,
      retainageAmount: formatCurrency(retainageAmount),
      retainageToDateAmount: formatCurrency(retainageToDateAmount),
    })
  );

  const { netAmount, grossAmount, retainageAmount } = values.lineItems.reduce(
    (lineItemTotals, lineItem) => ({
      netAmount: add(lineItemTotals.netAmount, lineItem.amount),
      grossAmount: add(lineItemTotals.grossAmount, lineItem.grossAmount),
      retainageAmount: add(
        lineItemTotals.retainageAmount,
        lineItem.retainageAmount
      ),
    }),
    {}
  );

  const { date, drawId, number, projectId, templateId } = values;

  return {
    drawId,
    projectId,
    number,
    templateId,
    date: dateFormToServer(date),
    grossAmount: formatCurrency(grossAmount),
    lineItems: formattedLineItems,
    netAmount: formatCurrency(netAmount),
    retainageAmount: formatCurrency(retainageAmount),
  };
}

export function previewValues(values, lineItems) {
  return {
    projectId: values.projectId,
    drawId: values.drawId,
    templateId: values.templateId,
    data: JSON.stringify({
      number: values.number,
      date: dateFormToServer(values.date),
      lineItems: values.lineItems.map(
        ({
          lineItemObject,
          amount,
          grossAmount,
          retainageAmount,
          retainageToDateAmount,
        }) => ({
          name: lineItems.find((li) => li.id === lineItemObject.id).name,
          amount,
          grossAmount:
            unformatNumber(retainageAmount) === 0
              ? amount
              : grossAmount.toString(),
          retainageAmount,
          retainageToDateAmount,
        })
      ),
    }),
  };
}
