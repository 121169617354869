import PropTypes from "prop-types";
import { Divider, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

const ActionItemBlankSlate = ({ content, actionItem, ...props }) => (
  <Pane
    display="flex"
    justifyContent="center"
    width="100%"
    height={majorScale(30)}
    {...props}
  >
    <Pane display="flex" flexDirection="column" alignItems="center">
      {content}
      <Divider />
      {actionItem}
    </Pane>
  </Pane>
);

ActionItemBlankSlate.propTypes = {
  actionItem: PropTypes.node,
  alignItems: PropTypes.string,
  content: PropTypes.node,
};

ActionItemBlankSlate.defaultProps = {
  alignItems: "center",
};

export default ActionItemBlankSlate;
