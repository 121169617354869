import { Fragment, useContext, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import {
  EditProjectSettingsButtons,
  MissingFundingSources,
} from "components/templates";
import { Accordion } from "components/materials";
import { Formik } from "formik";
import { get } from "lodash";
import { UserContext } from "helpers/behaviors";
import { DRAW_STATE, PERMISSION_ACTION } from "helpers/enums";
import {
  parseFundingSourceGroups,
  restructureFormValues,
} from "helpers/fundingSourceHelpers";
import t from "helpers/translate";
import { FUNDING_SOURCES_SETTINGS_FRAGMENT } from "helpers/fragments";
import {
  initialValues,
  FundingSourcesForm,
  validate,
} from "./FundingSourcesForm";

const CHANGE_LOAN_DETAILS = gql`
  mutation ChangeLoanDetails(
    $fundingSourceGroups: [FundingSourceInput]!
    $automaticAllocationEnabled: Boolean!
    $usesOfFundsEnabled: Boolean!
    $projectId: String!
  ) {
    changeLoanDetails(
      fundingSourceGroups: $fundingSourceGroups
      automaticAllocationEnabled: $automaticAllocationEnabled
      usesOfFundsEnabled: $usesOfFundsEnabled
      projectId: $projectId
    ) {
      id
      ...FundingSourcesSettingsFragment
    }
  }
  ${FUNDING_SOURCES_SETTINGS_FRAGMENT}
`;

const handleLoanDetails = (project, changeLoanDetails) => (formValues) => {
  const restructuredFormValues = restructureFormValues(formValues);
  const fundingSourceGroups = parseFundingSourceGroups(restructuredFormValues);
  const projectSettingsInfo = {
    projectId: project.id,
    automaticAllocationEnabled: !!formValues.automaticAllocationEnabled,
    usesOfFundsEnabled: !!formValues.usesOfFundsEnabled,
    fundingSourceGroups,
  };

  return changeLoanDetails({ variables: projectSettingsInfo });
};

const EditProjectFundingSourcesPanel = (props) => {
  const { hasPermission } = useContext(UserContext);
  const [showMissingSourcesModal, setShowMissingSourcesModal] = useState(false);

  const {
    allOrganizations,
    dirty,
    divisions,
    getProjectVendorSearchQuery,
    onToggle,
    panelKey,
    project,
    searchedVendors,
    setPanelDirty,
    showErrorToast,
    showSuccessToast,
    ...rest
  } = props;

  const [changeLoanDetails, changeLoanDetailsResult] = useMutation(
    CHANGE_LOAN_DETAILS,
    {
      onCompleted: () => {
        showSuccessToast();
        setShowMissingSourcesModal(true);
      },
      onError: () => showErrorToast(),
    }
  );

  const loading = get(changeLoanDetailsResult, "loading");

  const fundedDraws = get(project, "draws", []).filter(
    (draw) => draw.state === DRAW_STATE.FUNDED
  );
  const drawFundingSourcesIncomplete = fundedDraws.some(
    (draw) => !draw.fundingConfirmed
  );

  const fundingSourceGroups = get(project, "fundingSourceGroups", []);

  const autoAllocateOnProject = project.automaticAllocationEnabled;
  const usesOfFundsOnProject = project.usesOfFundsEnabled;

  const initValues = initialValues(
    fundingSourceGroups,
    autoAllocateOnProject,
    usesOfFundsOnProject,
    project
  );

  return (
    <Fragment>
      {showMissingSourcesModal &&
        fundingSourceGroups.length > 0 &&
        drawFundingSourcesIncomplete && (
          <MissingFundingSources
            fundedDraws={fundedDraws}
            projectId={project.id}
            usesOfFundsOn={usesOfFundsOnProject}
            isModal
            projectSettings
          />
        )}
      <Formik
        initialValues={initValues}
        validate={validate(initValues)}
        onSubmit={handleLoanDetails(project, changeLoanDetails)}
        enableReinitialize
      >
        {(form) => (
          <Accordion.Panel
            panelKey={panelKey}
            title={
              hasPermission(PERMISSION_ACTION.USES_OF_FUNDS) &&
              usesOfFundsOnProject
                ? t("fundingSources.usesOfFundsOn")
                : t("fundingSources.usesOfFundsOff")
            }
            onClick={() => onToggle(panelKey)}
            actionContent={
              <EditProjectSettingsButtons
                dirty={dirty}
                form={form}
                loading={loading}
              />
            }
            {...rest}
          >
            <FundingSourcesForm
              dirty={dirty}
              divisions={divisions}
              form={form}
              getProjectVendorSearchQuery={getProjectVendorSearchQuery}
              hasAutoAllocateOnOrg={hasPermission(
                PERMISSION_ACTION.AUTO_ALLOCATE
              )}
              hasUsesOfFundsOnOrg={hasPermission(
                PERMISSION_ACTION.USES_OF_FUNDS
              )}
              organizations={allOrganizations}
              panelKey={panelKey}
              project={project}
              searchedVendors={searchedVendors}
              setPanelDirty={setPanelDirty}
            />
          </Accordion.Panel>
        )}
      </Formik>
    </Fragment>
  );
};

EditProjectFundingSourcesPanel.propTypes = {
  allOrganizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoAllocateOnProject: PropTypes.bool,
  contentStyles: PropTypes.object,
  dirty: PropTypes.bool,
  divisions: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  panelKey: PropTypes.string.isRequired,
  panelStyles: PropTypes.object,
  setPanelDirty: PropTypes.func,
  project: PropTypes.object.isRequired,
  titleStyles: PropTypes.object,
  usesOfFundsOnProject: PropTypes.bool,
};

export default EditProjectFundingSourcesPanel;
