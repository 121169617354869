import { StickyHeaderTable, Table, Pane } from "components/materials";
import { ProjectionTableHeader } from "./ProjectionTableHeader";
import { FundingSourceProjectionTableBody } from "./FundingSourceProjectionTableBody";
import { FundingSourceProjectionTableTotal } from "./FundingSourceProjectionTableTotal";

function getProjectionHeaderCells(columns) {
  return [
    {
      header: "Name",
      props: { width: 225 },
    },
    {
      header: "Organization",
      props: { width: 175 },
    },
    ...(columns.showPreviousFundingSourceAmount
      ? [
          {
            header: "Previous Amount",
            props: { width: 150 },
          },
        ]
      : []),
    {
      header: "Amount",
      props: { width: 150 },
    },
    {
      header: "Type",
      props: { width: 75 },
    },
    {
      header: "Projected Start",
      props: { width: 100 },
    },
    {
      header: "Projected End",
      props: { width: 100 },
    },
  ];
}

export function FundingSourceProjectionTable({
  projection,
  showUsesOfFundsWarning,
}) {
  const {
    actualsCount,
    columns,
    drawNames,
    tableFundingSources,
    fundingSourceTotals,
    project,
    state: projectionState,
  } = projection;
  if (tableFundingSources.length === 0) return null;
  return (
    <Pane paddingBottom={85}>
      <StickyHeaderTable
        bodyProps={{ allowHorizontalScroll: true, stickyColumn: true }}
        headerProps={{ stickyColumn: true }}
        header={
          <ProjectionTableHeader
            actualsCount={actualsCount}
            columns={columns}
            project={project}
            projectionState={projectionState}
            drawNames={drawNames}
            headerCells={getProjectionHeaderCells(columns)}
            superheaderColSpan={5}
          />
        }
      >
        <Table.Body>
          <FundingSourceProjectionTableBody
            actualsCount={actualsCount}
            columns={columns}
            fundingSources={tableFundingSources}
            project={project}
            projectionState={projectionState}
            showUsesOfFundsWarning={showUsesOfFundsWarning}
          />
          <FundingSourceProjectionTableTotal
            actualsCount={actualsCount}
            columns={columns}
            fundingSources={tableFundingSources}
            fundingSourceTotals={fundingSourceTotals}
            project={project}
            projectionState={projectionState}
          />
        </Table.Body>
      </StickyHeaderTable>
    </Pane>
  );
}
