export const ADDRESS_STATE = {
  AA: "AA",
  AB: "AB",
  AE: "AE",
  AK: "AK",
  AL: "AL",
  AP: "AP",
  AR: "AR",
  AS: "AS",
  AZ: "AZ",
  BC: "BC",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DC: "DC",
  DE: "DE",
  FL: "FL",
  FM: "FM",
  GA: "GA",
  GU: "GU",
  HI: "HI",
  IA: "IA",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  MA: "MA",
  MB: "MB",
  MD: "MD",
  ME: "ME",
  MH: "MH",
  MI: "MI",
  MN: "MN",
  MO: "MO",
  MP: "MP",
  MS: "MS",
  MT: "MT",
  NB: "NB",
  NC: "NC",
  ND: "ND",
  NE: "NE",
  NH: "NH",
  NJ: "NJ",
  NL: "NL",
  NM: "NM",
  NS: "NS",
  NT: "NT",
  NU: "NU",
  NV: "NV",
  NY: "NY",
  OH: "OH",
  OK: "OK",
  ON: "ON",
  OR: "OR",
  PA: "PA",
  PE: "PE",
  PR: "PR",
  PW: "PW",
  QC: "QC",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  SK: "SK",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VA: "VA",
  VI: "VI",
  VT: "VT",
  WA: "WA",
  WI: "WI",
  WV: "WV",
  WY: "WY",
  YT: "YT",
};
