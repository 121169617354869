import { Fragment } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import {
  Button,
  Form,
  ListItem,
  SelectionListItem,
  UnorderedList,
} from "components/materials";
import { set, isEmpty } from "lodash";
import { SUBMISSION_ROLE_TYPE } from "helpers/enums";
import isBlank from "helpers/isBlank";
import isInvalidEmail from "helpers/isInvalidEmail";
import t from "helpers/translate";
import getOptions from "helpers/getOptions";

// List SUBMISSION_ROLE_TYPE values explicitly to ensure their order
const ROLES = () =>
  [
    SUBMISSION_ROLE_TYPE.LENDER,
    SUBMISSION_ROLE_TYPE.BORROWER,
    SUBMISSION_ROLE_TYPE.INSPECTOR,
    SUBMISSION_ROLE_TYPE.TITLE_COMPANY,
    SUBMISSION_ROLE_TYPE.OTHER,
  ].map((role) => {
    return {
      id: role,
      name: t(`submissionRoleType.${role}`),
    };
  });

const NEW_RECIPIENT = { name: "", email: "", role: "" };

const initialValues = (recipients) => {
  return isEmpty(recipients) ? { recipients: [NEW_RECIPIENT] } : { recipients };
};

const validate = (values) => {
  const errors = {};

  values.recipients.forEach((recipient, index) => {
    if (isBlank(recipient.name)) {
      set(errors, `recipients.${index}.name`, "Please select a name");
    }
    if (isBlank(recipient.email)) {
      set(errors, `recipients.${index}.email`, "Please enter an email");
    } else if (isInvalidEmail(recipient.email)) {
      set(errors, `recipients.${index}.email`, "Please enter a valid email");
    }
    if (isBlank(recipient.role)) {
      set(errors, `recipients.${index}.role`, "Please select a role");
    }
  });

  return errors;
};

const renderRecipients = (recipients, hideRoles) => ({ push, remove }) => (
  <Fragment>
    {recipients.map((recipient, index) => (
      <ListItem key={`recipient-${index}`} index={index}>
        <SelectionListItem
          onRemove={recipients.length > 1 ? () => remove(index) : undefined}
          verticalAlign="middle"
        >
          <Form.Group>
            <Form.Input
              name={`recipients[${index}].name`}
              placeholder="Full name"
            />
            <Form.Input
              name={`recipients[${index}].email`}
              placeholder="E-mail address"
            />
            {!hideRoles && (
              <Form.Select
                name={`recipients[${index}].role`}
                placeholder="Role"
                options={getOptions(ROLES())}
              />
            )}
          </Form.Group>
        </SelectionListItem>
      </ListItem>
    ))}

    <ListItem>
      <SelectionListItem>
        <Button
          purpose="add-recipients add"
          appearance="minimal"
          onClick={() => push(NEW_RECIPIENT)}
        >
          Add Recipient
        </Button>
      </SelectionListItem>
    </ListItem>
  </Fragment>
);

const AddRecipientsForm = ({ values, hideRoles }) => (
  <Form>
    <UnorderedList listStyleType="none">
      <FieldArray name="recipients">
        {renderRecipients(values.recipients, hideRoles)}
      </FieldArray>
    </UnorderedList>
  </Form>
);

// TODO: remove dot notation
AddRecipientsForm.initialValues = initialValues;
AddRecipientsForm.validate = validate;
AddRecipientsForm.NEW_RECIPIENT = NEW_RECIPIENT;

AddRecipientsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hideRoles: PropTypes.bool,
};

export default AddRecipientsForm;
