import { Fragment } from "react";
import { Button, Link, Pane, Text } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";
import { drawsAlreadyFunded, newProject } from "images";
import { STEPS } from "./utils";
import { OnboardingWizardDecisionCard } from "./OnboardingWizardDecisionCard";
import { Header } from "./Header";

export function LenderHasDrawsStep({
  goBack,
  navigateToStep,
  updateOnboardingData,
}) {
  return (
    <Fragment>
      <Header header="How far along is the project?" />
      <Pane display="flex">
        <OnboardingWizardDecisionCard
          backgroundColor
          buttonPrimary
          header={t("onboardingWizard.lenderHasDraws.drawsFundedHeader")}
          image={drawsAlreadyFunded}
          onClick={() => {
            updateOnboardingData({ draws: [], hasDraws: true }).then(() =>
              navigateToStep(STEPS.DRAW_DECISION)
            );
          }}
          subHeader={t("onboardingWizard.lenderHasDraws.drawsFundedSubheader")}
        />
        <OnboardingWizardDecisionCard
          backgroundColor
          buttonPrimary
          header={t("onboardingWizard.lenderHasDraws.newProjectHeader")}
          image={newProject}
          imageHeight={200}
          onClick={() =>
            updateOnboardingData({ draws: [], hasDraws: false }).then(() =>
              navigateToStep(STEPS.CONFIRM_PROJECT_SETUP)
            )
          }
          subHeader={t("onboardingWizard.lenderHasDraws.newProjectSubheader")}
        />
      </Pane>
      <Pane marginTop={majorScale(3)}>
        <Pane marginBottom={majorScale(1)}>
          <Text>{t("onboardingWizard.help")}</Text>
          <Link
            href="mailto:help@rabbet.com"
            marginLeft={minorScale(1)}
            purpose="project-wizard-draw-history-help"
          >
            help@rabbet.com
          </Link>
        </Pane>
        <Button
          onClick={() => {
            goBack();
          }}
        >
          Back
        </Button>
      </Pane>
    </Fragment>
  );
}
