import { Form, Table } from "components/materials";
import { LineItemRow, TableHeaders, PAGE_2_ROWS } from ".";

export function Page2Section({
  collapsedPanels,
  disabled,
  form,
  matchedDrawLineItems,
  togglePanel,
}) {
  const sectionName = "page2Values";
  const isCollapsed = collapsedPanels.includes(sectionName);
  return (
    <Form.Section
      heading="Page 2"
      padding={0}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={() => togglePanel(sectionName)}
    >
      <Table paddingBottom={0}>
        <TableHeaders />
        <Table.Body>
          {Object.entries(PAGE_2_ROWS).map(
            ([lineItemFieldName, { hasColumnA, name, isLineItem }]) => {
              return (
                <LineItemRow
                  key={lineItemFieldName}
                  hasColumnA={hasColumnA}
                  disabled={disabled}
                  drawLineItem={matchedDrawLineItems[lineItemFieldName]}
                  form={form}
                  isLineItem={isLineItem}
                  lineItemFieldName={lineItemFieldName}
                  sectionName={sectionName}
                  lineItemName={name}
                />
              );
            }
          )}
        </Table.Body>
      </Table>
    </Form.Section>
  );
}
