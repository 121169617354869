import { Fragment } from "react";
import { DotIcon } from "evergreen-ui";
import {
  Alert,
  Button,
  Card,
  GoogleMap,
  Pane,
  Paragraph,
  Shortener,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";
import { getStatusIconColor } from "components/templates/ProjectDraws";
import { compact } from "lodash";

export function BorrowerDrawHeader({
  borrowerDraw,
  drawHasBeenSubmitted,
  project,
  setModalOpen,
}) {
  return (
    <Fragment>
      <Pane
        display="flex"
        justifyContent="space-between"
        marginBottom={majorScale(2)}
        alignItems="flex-end"
      >
        <Pane display="flex">
          <Card
            display="flex"
            width={415}
            height={75}
            marginRight={majorScale(3)}
          >
            <Pane width={140} display="flex" justifyContent="center">
              {project.picture ? (
                <div
                  style={{
                    backgroundImage: `url("${project.picture.url}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                    borderRadius: "4px 0 0 4px",
                  }}
                />
              ) : (
                <GoogleMap height="100%" projects={[project]} />
              )}
            </Pane>
            <Pane
              maxWidth={310}
              paddingLeft={minorScale(3)}
              paddingTop={majorScale(2)}
              position="relative"
            >
              <Shortener
                fontSize={20}
                limit={27}
                minimumFontSize={16}
                text={project.name}
                useDynamicFontSize
                wordBreak="break-word"
              />
              {(project.city || project.state) && (
                <Paragraph
                  color="#425A70"
                  fontWeight={300}
                  marginTop={minorScale(1)}
                  overflow="hidden"
                  size={300}
                  whiteSpace="nowrap"
                >
                  {compact([
                    project.streetAddress,
                    project.city,
                    project.state,
                  ]).join(", ")}
                </Paragraph>
              )}
            </Pane>
          </Card>

          <Card
            maxWidth={250}
            minWidth={150}
            height={75}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={majorScale(1)}
          >
            <Paragraph
              color="#425A70"
              fontWeight={700}
              overflow="hidden"
              size={500}
              whiteSpace="normal"
            >
              {borrowerDraw.name}
            </Paragraph>
            <Pane display="flex">
              <DotIcon
                color={getStatusIconColor(borrowerDraw)}
                marginLeft={-minorScale(1)}
                marginTop={2}
              />
              <Paragraph>{t(`drawStates.${borrowerDraw.state}`)}</Paragraph>
            </Pane>
          </Card>
        </Pane>
        {!drawHasBeenSubmitted && (
          <Button
            appearance="primary"
            purpose="submit borrower draw"
            content="Submit Draw"
            onClick={() => {
              setModalOpen(true);
            }}
            width={265}
          />
        )}
      </Pane>
      {!drawHasBeenSubmitted && (
        <Pane marginBottom={majorScale(2)}>
          <Alert
            intent="none"
            title="Documents & information will not be visible to lender until draw is submitted"
          />
        </Pane>
      )}
    </Fragment>
  );
}
