import PropTypes from "prop-types";
import { ContentLayout } from "components/templates";
import { Alert } from "components/materials";
import t from "helpers/translate";

export function ExpiredLink({ sender }) {
  return (
    <ContentLayout bleedContent>
      <Alert
        intent="warning"
        title={
          sender ? t("guest.expiredWithSender", { sender }) : t("guest.expired")
        }
      />
    </ContentLayout>
  );
}

ExpiredLink.propTypes = {
  sender: PropTypes.string,
};
