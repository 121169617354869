import { useContext } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Menu, Text } from "components/materials";
import { ThemeContext } from "helpers/utilities";

const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;
function DownloadDocuments({ items, stamped }) {
  const theme = useContext(ThemeContext);

  const documentIds = map(items, "id").join(",");

  const urlAction = stamped
    ? "download_multiple_stamped_documents"
    : "download_multiple_documents";

  const onDownload = () => {
    window.open(`${baseUrl}/${urlAction}/${documentIds}`);
  };

  const menuItemDisabled = documentIds.length === 0;

  return (
    <Menu.Item
      is={Text}
      style={{
        cursor: menuItemDisabled ? "not-allowed" : "pointer",
        color: menuItemDisabled && theme.colors.gray300,
      }}
      onSelect={menuItemDisabled ? null : () => onDownload(documentIds)}
    >
      Download All {stamped && "Stamped"}
    </Menu.Item>
  );
}

DownloadDocuments.propTypes = {
  closeDropdown: PropTypes.func,
  items: PropTypes.array.isRequired,
  stamped: PropTypes.bool,
};

DownloadDocuments.defaultProps = {
  closeDropdown: () => {},
  stamped: false,
};

export default DownloadDocuments;
