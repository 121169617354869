import { Fragment } from "react";
import { HelpIcon } from "evergreen-ui";
import { Table, Pane, Pill, Tooltip } from "components/materials";
import {
  amountFieldNames,
  getMonthHasActuals,
  isInsufficientFunds,
} from "helpers/projectionHelpers";
import {
  NEW_PROJECTION_STATE,
  UPDATED_PROJECTION_STATE,
  UPDATING_PROJECTION_STATE,
  NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE,
} from "hooks/use-projections";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { getCellState, tableBorder } from "./util";

function ProjectionCell({ amount, state }) {
  return (
    <Table.TextCell
      textAlign="right"
      borderLeft={tableBorder}
      state={state}
      width={125}
    >
      {formatCurrency(amount)}
    </Table.TextCell>
  );
}

function shouldShowUnderProjectedTooltip(
  showUsesOfFundsWarning,
  fundingSource
) {
  return showUsesOfFundsWarning && fundingSource.unprojectedFunds > 0;
}

function UnderProjectedFundsTooltip({ amount }) {
  return (
    <Tooltip
      content={t("projection.underProjectedFund", {
        unprojected: formatCurrency(amount),
      })}
    >
      <HelpIcon marginLeft={majorScale(1)} color="danger" />
    </Tooltip>
  );
}

export function FundingSourceProjectionTableBody({
  actualsCount,
  columns,
  fundingSources,
  project,
  projectionState,
  showUsesOfFundsWarning,
}) {
  return (
    <Fragment>
      {fundingSources.map((fundingSource) => (
        <Table.Row>
          <Table.TextCell width={225}>
            <Pane
              height={10}
              width={10}
              borderRadius="50%"
              background={fundingSource.color}
              marginRight={majorScale(1)}
            />
            {fundingSource.name}
          </Table.TextCell>
          <Table.TextCell width={175}>
            {fundingSource.organization}
          </Table.TextCell>
          {columns.showPreviousFundingSourceAmount && (
            <Table.TextCell width={150}>
              {!isInsufficientFunds(fundingSource) &&
                formatCurrency(fundingSource.previousAmount)}
            </Table.TextCell>
          )}
          <Table.TextCell width={150}>
            {isInsufficientFunds(fundingSource) ? (
              <Pill color="red" textTransform="none">
                {formatCurrency(fundingSource.amount)}
              </Pill>
            ) : (
              <Fragment>
                {formatCurrency(fundingSource.amount)}
                {shouldShowUnderProjectedTooltip(
                  showUsesOfFundsWarning,
                  fundingSource
                ) && (
                  <UnderProjectedFundsTooltip
                    amount={fundingSource.unprojectedFunds}
                  />
                )}
              </Fragment>
            )}
          </Table.TextCell>
          <Table.TextCell width={75}>
            {fundingSource.type && t(`fundingSourceType.${fundingSource.type}`)}
          </Table.TextCell>
          <Table.TextCell width={100}>
            {fundingSource.firstMonth}
          </Table.TextCell>
          <Table.TextCell width={100}>
            {fundingSource.finalMonth}
          </Table.TextCell>
          {project.months.map((_month, monthIndex) => {
            const monthHasActuals = getMonthHasActuals(
              monthIndex,
              actualsCount
            );
            switch (projectionState) {
              case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
                return (
                  <Fragment>
                    {columns.showOriginalProjection && (
                      <ProjectionCell
                        amount={fundingSource.values[monthIndex].projected}
                        state={getCellState(
                          amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                        )}
                      />
                    )}

                    {columns.showUpdatedProjection && (
                      <ProjectionCell
                        amount={
                          fundingSource.values[monthIndex].updatedProjection
                        }
                        state={getCellState(
                          monthHasActuals
                            ? amountFieldNames.ACTUALS
                            : amountFieldNames.UPDATED_PROJECTION_AMOUNTS
                        )}
                      />
                    )}
                  </Fragment>
                );
              case NEW_PROJECTION_STATE:
                return (
                  <ProjectionCell
                    amount={fundingSource.values[monthIndex].projected}
                  />
                );
              case UPDATING_PROJECTION_STATE:
                return (
                  <ProjectionCell
                    amount={fundingSource.values[monthIndex].updatedProjection}
                    state={getCellState(
                      monthHasActuals
                        ? amountFieldNames.ACTUALS
                        : amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                    )}
                  />
                );
              case UPDATED_PROJECTION_STATE:
                return (
                  <ProjectionCell
                    amount={fundingSource.values[monthIndex].updatedProjection}
                    state={getCellState(
                      monthHasActuals
                        ? amountFieldNames.ACTUALS
                        : amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                    )}
                  />
                );
              default:
                return null;
            }
          })}
          <Table.TextCell borderLeft={tableBorder} />
        </Table.Row>
      ))}
    </Fragment>
  );
}
