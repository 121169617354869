import { useContext, Fragment } from "react";
import { FieldArray } from "formik";
import { SmallTickIcon, SmallCrossIcon, AddIcon } from "evergreen-ui";
import { Alert, Form, Pane, Text, Divider, Button } from "components/materials";
import {
  ADDRESS_STATE,
  ORGANIZATION_TYPE,
  PERMISSION_ACTION,
} from "helpers/enums";
import { get, trim, values } from "lodash";
import { isValidZipcode } from "helpers/addressValidation";
import isBlank from "helpers/isBlank";
import isInvalidEmail from "helpers/isInvalidEmail";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";

const getTypeOptions = () =>
  values(ORGANIZATION_TYPE).map((name) => ({
    key: name,
    text: t(`organizationType.${name}`),
    value: name,
  }));

const nullState = { key: "blank", value: undefined, text: "" };

const stateOptions = [nullState].concat(
  values(ADDRESS_STATE).map((state) => ({
    key: state,
    value: state,
    text: state,
  }))
);

function VendorForm({
  creatingNewVendor,
  disableFields,
  form: {
    errors,
    setFieldValue,
    values: { terms },
  },
  hasPermission,
  isUserOrganization,
  selectedCustomerAccountId,
}) {
  const theme = useContext(ThemeContext);
  const renderTerms = ({ push, remove }) => {
    const content = terms.map((term, index) =>
      term.new && term.confirmed === false ? (
        <Pane display="flex" key={index}>
          <Pane width={300}>
            <Form.Input disabled={disableFields} name={`terms.${index}.name`} />
          </Pane>
          <Fragment>
            <Button
              appearance="minimal"
              size="small"
              iconBefore={SmallTickIcon}
              intent="success"
              purpose="vendor-form alias-form submit"
              onClick={() => {
                setFieldValue(`terms.${index}.confirmed`, true);
              }}
            >
              Confirm
            </Button>
            <Button
              appearance="minimal"
              size="small"
              iconBefore={SmallCrossIcon}
              purpose="vendor-form alias-form remove"
              intent="danger"
              onClick={() => remove(index)}
            >
              Remove
            </Button>
          </Fragment>
        </Pane>
      ) : (
        <Form.Switch
          disabled={disableFields}
          key={index}
          label={term.name}
          marginY={minorScale(1)}
          name={`terms.${index}.enabled`}
        />
      )
    );

    return (
      <Fragment>
        {content}
        <Divider />
        {!disableFields && (
          <Button
            appearance="minimal"
            color={theme.colors.blue600}
            size="small"
            iconBefore={AddIcon}
            purpose="vendor-form aliases add"
            onClick={() =>
              push({
                new: true,
                name: "",
                enabled: true,
                confirmed: false,
              })
            }
          >
            Add an alias
          </Button>
        )}
      </Fragment>
    );
  };
  const hasAvidAP = hasPermission(PERMISSION_ACTION.AVID_AP_INTEGRATION);
  const hasVisionDeltekAP = hasPermission(
    PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION
  );
  const hasNexusAP = hasPermission(PERMISSION_ACTION.NEXUS_INTEGRATION);
  const hasYardiAP = hasPermission(PERMISSION_ACTION.YARDI_INTEGRATION);

  const { requiredWhenPayable } = errors;
  return (
    <Pane>
      {requiredWhenPayable && !hasNexusAP && !hasYardiAP && (
        <Alert intent="danger" marginBottom={majorScale(2)}>
          {t("createOrganization.requiredWhenPayable")}
        </Alert>
      )}
      <Form.Group>
        <Form.Input disabled={disableFields} name="name" label="Name" />
        <Form.Select
          disabled={disableFields || isUserOrganization}
          name="type"
          label={<Text>Type</Text>}
          options={getTypeOptions()}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input disabled={disableFields} label="Email" name="email" />
        <Form.Input
          disabled={disableFields}
          label="Phone Number"
          type="tel"
          name="phone"
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          disabled={disableFields}
          label="Street Address"
          name="streetAddress"
        />
      </Form.Group>
      <Form.Group aligned renderedChildrenDirectly>
        <Pane width="45%">
          <Form.Input disabled={disableFields} label="City" name="city" />
        </Pane>
        <Pane width={100}>
          <Form.Select
            disabled={disableFields}
            label="State/Province"
            name="state"
            options={stateOptions}
            width={100}
            popoverMinWidth={85}
            fullBorder
          />
        </Pane>
        <Pane width="40%">
          <Form.Input
            disabled={disableFields}
            label="Postal Code"
            name="zip"
            type="zip"
          />
        </Pane>
      </Form.Group>
      <Form.Group
        aligned
        renderedChildrenDirectly
        marginBottom={creatingNewVendor ? 0 : majorScale(2)}
      >
        {hasPermission(PERMISSION_ACTION.VENDOR_COST_CODES, {
          id: selectedCustomerAccountId,
        }) && (
          <Pane width="60%">
            <Form.Input
              disabled={disableFields}
              name="vendorCostCode"
              label="Vendor ID"
            />
          </Pane>
        )}
        {(hasAvidAP || hasVisionDeltekAP) && (
          <Fragment>
            <Pane width="15%" marginTop={minorScale(5)}>
              <Form.Checkbox
                disabled={disableFields}
                label={<Text>1099?</Text>}
                name="is1099"
              />
            </Pane>
            <Pane width="15%" marginTop={minorScale(5)}>
              <Form.Checkbox
                disabled={disableFields}
                label={<Text>Payable?</Text>}
                name="isPayable"
              />
            </Pane>
          </Fragment>
        )}
      </Form.Group>

      {!creatingNewVendor && (
        <Pane>
          <Text>Aliases</Text>
          <FieldArray name="terms" render={renderTerms} />
        </Pane>
      )}
    </Pane>
  );
}

// TODO: remove dot notation
VendorForm.initialValues = (vendor) => {
  const terms = [
    ...new Set((vendor.aliases || []).concat(vendor.antialiases || []).sort()),
  ];

  return {
    id: vendor.id,
    name: vendor.name,
    type: vendor.type || ORGANIZATION_TYPE.OTHER,
    streetAddress: vendor.streetAddress,
    city: vendor.city,
    state: vendor.state,
    zip: vendor.zip,
    terms: terms.map((term) => ({
      name: term,
      enabled: vendor.aliases.includes(term),
    })),
    email: get(vendor, "emailAddresses.0", ""),
    phone: get(vendor, "phoneNumbers.0", ""),
    // vendorCostCode value can be null, which will not trigger the get() default value
    vendorCostCode: get(vendor, "vendorCostCode") || "",
    is1099: vendor.is1099,
    isPayable: vendor.isPayable,
  };
};

// TODO: remove dot notation
VendorForm.validate = (values, hasPermission) => {
  const hasAvidAP = hasPermission(PERMISSION_ACTION.AVID_AP_INTEGRATION);
  const hasNexusAP = hasPermission(PERMISSION_ACTION.NEXUS_INTEGRATION);
  const hasYardiAP = hasPermission(PERMISSION_ACTION.YARDI_INTEGRATION);

  const errors = {};
  if (isBlank(values.name)) errors.name = "Please enter a name";

  if (isBlank(values.type)) errors.type = "Please select a type";

  if (!isBlank(values.email) && isInvalidEmail(values.email))
    errors.email = "Please enter a valid email address";

  if (!isBlank(values.phone)) {
    const phoneDigits = values.phone.replace(/\D/g, "");
    if (phoneDigits.length !== 10)
      errors.phone = "Please enter a valid phone number";
  }

  if (!isValidZipcode(values.zip)) {
    errors.zip = "Postal Code is invalid";
  }

  if (
    !isBlank(values.vendorCostCode) &&
    trim(values.vendorCostCode).length > 100
  ) {
    errors.vendorCostCode = "Vendor ID must be 100 characters or fewer";
  }

  if (values.isPayable && !hasAvidAP && !hasNexusAP && !hasYardiAP) {
    ["city", "streetAddress", "state", "zip"].forEach((field) => {
      if (isBlank(values[field])) {
        errors[field] = "Required";
        errors.requiredWhenPayable = true;
      }
    });
  }
  return errors;
};

// TODO: remove dot notation
VendorForm.onSubmit = (accountId, mutation) => ({
  id,
  name,
  streetAddress,
  city,
  state,
  zip,
  type,
  email,
  phone,
  vendorCostCode,
  terms,
  is1099,
  isPayable,
}) => {
  const variables = {
    id,
    name,
    streetAddress,
    city,
    state: state || null,
    zip,
    type,
    email,
    phoneNumber: phone,
    emailAddresses: isBlank(email) ? [] : [email],
    phoneNumbers: isBlank(phone) ? [] : [phone],
    vendorCostCode: isBlank(vendorCostCode) ? null : vendorCostCode,
    aliases: terms
      .filter((term) => term.name !== "" && term.enabled)
      .map((term) => term.name),
    antialiases: terms
      .filter((term) => term.name !== "" && !term.enabled)
      .map((term) => term.name),
    sourceOrganizationId: accountId,
    is1099,
    isPayable,
  };

  mutation({ variables });
};

export default VendorForm;
