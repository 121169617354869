import { Fragment } from "react";
import { Button, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

export function PostPaymentActions({
  documents,
  isDirty,
  loading,
  onPost,
  onReset,
  onSavePaymentTypes,
  onUndo,
}) {
  const hasDocumentsToPost = documents.some(({ postedAt }) => !postedAt);
  const hasDocumentsToReset = documents.some(({ postedAt }) => postedAt);

  return (
    <Pane
      display="flex"
      justifyContent="flex-end"
      marginRight={majorScale(2)}
      marginY={majorScale(2)}
    >
      {isDirty && (
        <Fragment>
          <Button
            appearance="default"
            isLoading={loading}
            marginRight={majorScale(1)}
            onClick={onUndo}
          >
            Undo
          </Button>
          <Button
            appearance="default"
            onClick={onSavePaymentTypes}
            isLoading={loading}
            marginRight={majorScale(1)}
          >
            Save
          </Button>
        </Fragment>
      )}
      {hasDocumentsToReset && (
        <Button
          appearance="default"
          isLoading={loading}
          onClick={onReset}
          marginRight={majorScale(2)}
        >
          Reset Posted
        </Button>
      )}
      {hasDocumentsToPost && (
        <Button
          disabled={documents.some(({ canBePosted }) => !canBePosted)}
          appearance="primary"
          isLoading={loading}
          onClick={onPost}
          marginRight={majorScale(2)}
        >
          {isDirty ? "Save and Post" : "Post"}
        </Button>
      )}
    </Pane>
  );
}
