import gql from "graphql-tag";

export default gql`
  fragment ReviewFragment on Review {
    id
    processedAt
    reviewer {
      id
      fullName
    }
    state
    type
    isFinal
    isPreparer
  }
`;
