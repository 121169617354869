import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Text, Pane } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import cx from "classnames";

class CalculationOperation extends PureComponent {
  render() {
    const {
      value,
      label,
      operator,
      className,
      type,
      symbol,
      color,
    } = this.props;

    return (
      <Pane
        className={cx("calculation-operation", className)}
        data-testid={`${operator || ""}${symbol}${value} ${label}`}
      >
        <Pane className="operator">
          <Text size={300} color={color}>
            {operator}
          </Text>
        </Pane>
        <Pane className="symbol">
          <Text size={300} color={color}>
            {symbol}
          </Text>
        </Pane>
        <Pane className="value">
          <Pane className="value-content">
            <Text size={300} color={color}>
              {type === "percent"
                ? Math.round(value * 100)
                : formatCurrency(value, { withoutCurrencySymbol: true })}
            </Text>
          </Pane>
        </Pane>
        <Text size={300} color={color}>
          {label}
        </Text>
      </Pane>
    );
  }
}

CalculationOperation.propTypes = {
  operator: PropTypes.oneOf(["-", "+"]),
  value: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  symbol: PropTypes.string,
  color: PropTypes.string,
};

CalculationOperation.defaultProps = {
  symbol: "$",
  color: "#8694a3",
};

export default CalculationOperation;
