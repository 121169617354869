import { useState } from "react";
import { Button, Dropzone, Link, Modal } from "components/materials";
import { minorScale } from "helpers/utilities";
import t from "helpers/translate";

export function BorrowerDrawUploadFilesModal({
  documentTemplates,
  drawId,
  lineItems,
  onCancel,
  onSubmit,
  preview,
  previewDocument,
  previewResult,
  projectId,
  title,
  ...props
}) {
  const [files, setFiles] = useState([]);

  function editFile(index, fieldName, value) {
    setFiles((files) => {
      return files.reduce((acc, file, fileIndex) => {
        if (fileIndex === index) {
          file[fieldName] = value;
          return [...acc, file];
        }
        return [...acc, file];
      }, []);
    });
  }

  function concatFiles(newFiles) {
    setFiles((oldFiles) => oldFiles.concat(newFiles));
  }

  function removeFile(index) {
    setFiles((oldFiles) => oldFiles.filter((_oldFile, i) => i !== index));
  }

  function ModalContent() {
    return (
      <Modal.Content>
        <Dropzone
          files={files}
          isBorrowerDraw
          onAdd={concatFiles}
          onEdit={editFile}
          onRemove={removeFile}
        />
      </Modal.Content>
    );
  }

  function UploadModalActions() {
    return (
      <Modal.Actions display="flex" alignItems="center">
        <Link purpose="splitter help" href={t("splitterHelp.learnMoreLink")}>
          {t("splitterHelp.learnMoreUploadDescription")}
        </Link>
        <Button
          marginLeft="auto"
          marginRight={minorScale(3)}
          onClick={onCancel}
          purpose="upload-files cancel"
        >
          Cancel
        </Button>
        <Button
          appearance="primary"
          disabled={
            files.length === 0 ||
            files.some(
              ({ numberOfPages, type }) =>
                type === "application/pdf" && !numberOfPages
            )
          }
          onClick={() => {
            onSubmit({ files });
          }}
          purpose="upload-files submit"
        >
          Upload
        </Button>
      </Modal.Actions>
    );
  }

  return (
    <Modal open title={title} onClose={onCancel} {...props}>
      <ModalContent />
      <UploadModalActions />
    </Modal>
  );
}
