/**
 * Shared form helpers used in both new project onboarding wizard
 * and the existing project budget upload flow
 *
 */
import { v4 as uuid } from "uuid";
import { get, set } from "lodash";
import isBlank from "helpers/isBlank";
import { areWordsEqual } from "helpers/stringHelpers";
import unformatNumber from "helpers/unformatNumber";
import { RABBET_DEFAULT_BUDGET } from "../components/containers/OnboardingWizard/utils";

function defaultDivisions() {
  return [
    {
      id: uuid(),
      name: "",
      lineItems: [
        { id: uuid(), name: "", amount: 0 },
        { id: uuid(), name: "", amount: 0 },
        { id: uuid(), name: "", amount: 0 },
      ],
    },
  ];
}

function getDivisionsForProject(project) {
  const onboardingDataBudgetDivisions = get(
    project,
    "onboardingData.budget.divisions",
    []
  );
  if (onboardingDataBudgetDivisions.length > 0) {
    return onboardingDataBudgetDivisions;
  }
  if (get(project, "onboardingData.useRabbetBudget")) {
    return RABBET_DEFAULT_BUDGET;
  }
  return defaultDivisions();
}

function hasLineItemNumber(divisions) {
  return divisions.some((division) =>
    division.lineItems.some((lineItem) => !isBlank(lineItem.number))
  );
}

export function initialValues(project) {
  const divisions = getDivisionsForProject(project);
  const initialDivisions = divisions.map(
    ({ lineItems, ...rest }, divisionIndex) => {
      const initialLineItems = lineItems.map((lineItem, lineItemIndex) => {
        return {
          amount: 0,
          position: lineItemIndex,
          ...lineItem,
        };
      });

      return {
        lineItems: initialLineItems,
        position: divisionIndex,
        ...rest,
      };
    }
  );
  return {
    divisions: initialDivisions,
    showLineItemNumberDefault: hasLineItemNumber(divisions),
  };
}

export function validate(values) {
  const errors = {};
  values.divisions.forEach((division, divisionIndex, divisionsArray) => {
    if (
      divisionsArray.some(
        ({ name, id }) =>
          areWordsEqual(name, division.name) && id !== division.id
      )
    ) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Division names must be unique"
      );
    }
    if (division.name.length > 60) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Division names cannot be longer than 60 characters"
      );
    }
    if (isBlank(division.name)) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Please enter a division name"
      );
    }
    if (
      division.lineItems.length === 0 ||
      division.lineItems.every(({ name }) => !name)
    ) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Please create line items for this division"
      );
    }
    division.lineItems.forEach((lineItem, lineItemIndex, lineItemsArray) => {
      if (
        lineItemsArray.some(
          ({ name, id }) =>
            !isBlank(name) &&
            areWordsEqual(name, lineItem.name) &&
            id !== lineItem.id
        )
      ) {
        set(
          errors,
          `divisions.${divisionIndex}.lineItems.${lineItemIndex}.name`,
          "Line item names within a division must be unique"
        );
      }
      if (isBlank(lineItem.name) && unformatNumber(lineItem.amount) !== 0) {
        set(
          errors,
          `divisions.${divisionIndex}.lineItems.${lineItemIndex}.name`,
          "Please enter a line item name"
        );
      }
    });
  });
  return errors;
}

export const getMutationData = (budget) => {
  const preparedDivisions = budget.divisions.map(
    ({ lineItems, ...division }) => {
      const preparedLineItems = lineItems.filter(({ name }) => !isBlank(name));
      return {
        lineItems: preparedLineItems,
        ...division,
      };
    }
  );
  return { budget: { divisions: preparedDivisions } };
};
