import { useContext, useEffect, useState } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  findIndex,
  findLastIndex,
  get,
  range,
  cloneDeep,
  isEqual,
  find,
  flatMap,
} from "lodash";
import analytics from "helpers/analytics";
import { addMonths, formatDate } from "helpers/dateHelpers";
import {
  blankSelectValue,
  getCalculatedValues,
  getHasValidProjection,
  getProjectionTotals,
  getStartMonthIndex,
  selectIsBlank,
  isValidInsufficientFunds,
} from "helpers/projectionHelpers";
import unformatNumber from "helpers/unformatNumber";
import { add, multiply, subtract, sumBy } from "helpers/math";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import isBlank from "helpers/isBlank";
import { getGraphColor } from "helpers/colors";
import { PROJECTION_CURVE_TYPE } from "components/templates/ProjectionTable/util";
import { PROJECTION_EVENT } from "../../components/containers/ProjectionsPage";
import {
  NEW_PROJECTION_STATE,
  UPDATED_PROJECTION_STATE,
  UPDATING_PROJECTION_STATE,
  NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE,
} from "./enum";
import {
  QUERY,
  SAVE_PROJECTION,
  FUNDING_SOURCE_PROJECTIONS_QUERY,
  UPDATE_USER_VIEW_CONFIG,
} from "./graphql-queries";

export const useProjection = (projectId) => {
  const { hasPermission, user } = useContext(UserContext);
  const { showProjectionCharts: showChartsPreference } = user;

  const [isDirty, setIsDirty] = useState();
  const [projection, setProjection] = useState();
  const [initialProjection, setInitialProjection] = useState();
  const [loadingProjection, setLoadingProjection] = useState(true);
  const [loadingFundingSources, setLoadingFundingSources] = useState(false);
  const [errors, setErrors] = useState();
  const [
    projectionStateBeforeUpdates,
    setProjectionStateBeforeUpdates,
  ] = useState(null);
  const [saveProjection, saveProjectionResult] = useMutation(SAVE_PROJECTION);
  const [updateUserViewConfig] = useMutation(UPDATE_USER_VIEW_CONFIG);

  const { data, error, queryLoading } = useQuery(QUERY, {
    variables: { projectId },
    skip: !hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING),
  });

  const [getFundingSourceProjections] = useLazyQuery(
    FUNDING_SOURCE_PROJECTIONS_QUERY,
    {
      variables: {
        projectId,
        lineItemProjections: getFundingSourceQueryValues(projection),
      },
      onCompleted: (data) => {
        handleFundingSourceProjectionsUpdated(data);
      },
    }
  );

  useEffect(() => {
    if (error) return handleError(error);

    if (queryLoading || !data) return setLoadingProjection(true);

    return transformDataToProjection(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loadingProjection, error]);

  const autoAllocationEnabled = projection?.project?.automaticAllocationEnabled;
  const showProjectionCharts = projection?.views?.showProjectionCharts;

  useEffect(() => {
    if (loadingFundingSources && autoAllocationEnabled) {
      getFundingSourceProjections();
    }
    if (loadingFundingSources && !autoAllocationEnabled) {
      handleUpdateSourcesWithoutAllocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFundingSources, autoAllocationEnabled]);

  useEffect(() => {
    const dirty = !isEqual(projection, initialProjection);

    if (dirty !== isDirty) {
      setIsDirty(dirty);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProjection, projection, isDirty]);

  useEffect(() => {
    if (showProjectionCharts !== undefined) {
      updateUserViewConfig({
        variables: {
          showProjectionCharts,
        },
      });
    }
  }, [showProjectionCharts, updateUserViewConfig]);

  const handleError = (err) => {
    setErrors(err);
    setLoadingProjection(false);
  };

  const transformDataToProjection = (data) => {
    const {
      id,
      actuals,
      amount,
      automaticAllocationEnabled,
      divisions,
      expectedProjectLength,
      fundingSourceGroups,
      projection,
      startDate,
      adjustmentsMostRecentlyUpdatedAt,
      budgetMostRecentlyUpdatedAt,
      updatedAt,
      usesOfFundsEnabled,
    } = data.project;

    const {
      lineItemActualAmounts,
      fundingSourceActualAmounts,
      drawNames,
    } = actuals;

    const months = generateMonths(startDate, expectedProjectLength);

    const divisionsWithProjections = mapProjectionsToDivisions(
      divisions,
      projection
    );

    const transformedDivisions = transformDivisions(
      divisionsWithProjections,
      lineItemActualAmounts,
      months
    );

    const projectionTotals = getProjectionTotals(transformedDivisions, months);

    const {
      fundingSources: transformedFundingSources,
      insufficientFunds,
      fundingSourceTotals,
      remainingFunds,
    } = transformFundingSources(
      fundingSourceGroups,
      fundingSourceActualAmounts,
      projection,
      months,
      projectionTotals,
      automaticAllocationEnabled
    );

    const gatedInsufficientFunds = {
      ...insufficientFunds,
      loaded: !automaticAllocationEnabled,
    };

    const {
      chartFundingSources,
      tableFundingSources,
    } = formatFundingSourcesForDisplay(
      transformedFundingSources,
      gatedInsufficientFunds,
      remainingFunds
    );

    const hasDates =
      expectedProjectLength && expectedProjectLength > 0 && startDate;
    const hasBudget = divisions.length > 0;

    const isLocked = isProjectionLocked(transformedDivisions);

    const actualsCount = !isBlank(lineItemActualAmounts)
      ? lineItemActualAmounts[Object.keys(lineItemActualAmounts)[0]].length
      : 0;

    const project = {
      id,
      automaticAllocationEnabled,
      expectedLength: expectedProjectLength,
      months,
      budget: amount,
      startDate,
      adjustmentsMostRecentlyUpdatedAt,
      budgetMostRecentlyUpdatedAt,
      updatedAt,
      usesOfFundsEnabled,
    };

    const state = generateState(isLocked, actualsCount, isDirty);

    const columns = {
      showUpdatedProjection: true,
      showOriginalProjection: true,
      showPreviousBudget: shouldShowPreviousBudget(transformedDivisions),
      showPreviousFundingSourceAmount: shouldShowPreviousFundingSourceAmount(
        transformedFundingSources
      ),
    };

    const views = {
      showFundingSources: false,
      fundingSourceChart: "updatedProjection",
      showProjectionCharts: showChartsPreference,
    };

    const updatedProjection = {
      divisions: transformedDivisions,
      fundingSources: transformedFundingSources,
      remainingFunds,
      insufficientFunds: gatedInsufficientFunds,
      chartFundingSources,
      tableFundingSources,
      fundingSourceTotals,
      projectionTotals,
      actualsCount,
      hasDates,
      hasBudget,
      project,
      state,
      isDirty,
      columns,
      drawNames,
      views,
      updatedAt: projection?.updatedAt,
    };

    setProjection(updatedProjection);
    setInitialProjection(cloneDeep(updatedProjection));

    setLoadingFundingSources(true);
  };

  const handleFundingSourceProjectionsUpdated = (data) => {
    const { fundingSourceProjections } = data.project;
    const {
      actualsCount,
      fundingSources,
      project,
      projectionTotals,
    } = projection;

    const newFundingSources = fundingSources.map((fundingSource) => {
      const { monthlyProjectedAmounts } = find(
        fundingSourceProjections,
        ({ fundingSourceId }) => fundingSourceId === fundingSource.id
      );

      const { amount, values } = fundingSource;
      const actualValues = values.slice(0, actualsCount);
      const updatedProjectionValues = values
        .slice(actualsCount)
        .map((value, projectedValueIndex) => ({
          ...value,
          updatedProjection: monthlyProjectedAmounts[projectedValueIndex],
        }));

      const newValues = actualValues.concat(updatedProjectionValues);

      const newFundingSourceDates = getFundingSourceDates(
        newValues,
        project.months
      );

      const totalProjectedForSource = sumBy(newValues, "updatedProjection");
      const unprojectedFunds = subtract(amount, totalProjectedForSource);

      return {
        ...fundingSource,
        ...newFundingSourceDates,
        unprojectedFunds,
        values: newValues,
      };
    });

    const {
      insufficientFunds,
      fundingSourceTotals,
    } = generateInsufficientFunds(
      newFundingSources,
      projectionTotals,
      project.months
    );

    const {
      chartFundingSources,
      tableFundingSources,
    } = formatFundingSourcesForDisplay(
      newFundingSources,
      insufficientFunds,
      null
    );

    setProjection({
      ...projection,
      fundingSources: newFundingSources,
      insufficientFunds,
      fundingSourceTotals,
      chartFundingSources,
      tableFundingSources,
    });

    setLoadingFundingSources(false);
    setLoadingProjection(false);
  };

  const handleUpdateSourcesWithoutAllocation = () => {
    const { fundingSources, projectionTotals, project } = projection;

    const { months } = project;

    const remainingFunds = generateRemainingFunds(
      fundingSources,
      projectionTotals,
      months
    );

    const {
      insufficientFunds,
      fundingSourceTotals,
    } = generateInsufficientFunds(
      fundingSources,
      projectionTotals,
      months,
      remainingFunds
    );

    const {
      chartFundingSources,
      tableFundingSources,
    } = formatFundingSourcesForDisplay(
      fundingSources,
      insufficientFunds,
      remainingFunds
    );

    setProjection({
      ...projection,
      remainingFunds,
      insufficientFunds,
      fundingSourceTotals,
      chartFundingSources,
      tableFundingSources,
    });

    setLoadingFundingSources(false);
    setLoadingProjection(false);
  };

  const handleViewUpdate = ({ showFundingSources }) => {
    const updates = showFundingSources
      ? { showFundingSources }
      : { showFundingSources, fundingSourceChart: "updatedProjection" };

    const views = { ...projection.views, ...updates };
    setProjection({ ...projection, views });
  };

  const handleFundingSourceChartUpdate = ({ fundingSourceChart }) => {
    const views = {
      ...projection.views,
      fundingSourceChart,
    };
    setProjection({ ...projection, views });
  };

  const handleChartsUpdate = ({ showProjectionCharts }) => {
    const views = {
      ...projection.views,
      showProjectionCharts,
    };
    setProjection({ ...projection, views });
  };

  const handleColumnUpdate = ({ columnType, checked }) => {
    const prop =
      columnType === "updatedProjection"
        ? "showUpdatedProjection"
        : "showOriginalProjection";

    const columns = { ...projection.columns, [prop]: checked };

    setProjection({ ...projection, columns });
  };

  const handleUpdateProjection = () => {
    const { state, columns } = projection;
    if (projectionStateBeforeUpdates === null) {
      setProjectionStateBeforeUpdates(state);
    }
    const updatedColumns = { ...columns, showPreviousBudget: false };
    setProjection({
      ...projection,
      state: UPDATING_PROJECTION_STATE,
      columns: updatedColumns,
    });
  };

  const handleLineItemSettingChanged = (data) => {
    const { divisions, project, state } = projection;

    if (projectionStateBeforeUpdates === null) {
      setProjectionStateBeforeUpdates(state);
    }

    const isBlankValue = isBlank(data.value);
    const value = isBlankValue ? blankSelectValue : data.value;

    let division = divisions.find((d) => d.id === data.divisionId);
    if (division[data.option] !== value) {
      // ignore old value of select field
      // get other values of select field(s)
      // add incoming value of select field
      const types = [
        ...new Set(
          division.lineItems
            .filter((lineItem) => lineItem.id !== data.id)
            .map((lineItem) => get(lineItem, data.option))
            .concat([value])
        ),
      ];

      const newDivisionValue = getDivisionSettingState(types);
      division = { ...division, [data.option]: newDivisionValue };
    }

    const updatedLineItems = division.lineItems.map((lineItem) => {
      if (lineItem.id !== data.id) return lineItem;
      lineItem[data.option] = value;

      // if updating the duration make sure if a line item has a start month that would
      // "overflow" the project duration that we adjust that line item duration accordigly
      if (data.option === "duration" || data.option === "startMonth") {
        const startMonthIndex = getStartMonthIndex(lineItem.startMonth);

        const totalDuration = add(lineItem.duration, startMonthIndex);

        if (totalDuration > project.expectedLength) {
          lineItem.duration = (
            project.expectedLength - startMonthIndex
          ).toString();
        }
      }

      // When a user selects manual they shouldn't have to select start month and duration
      if (lineItem.curveType === PROJECTION_CURVE_TYPE.MANUAL) {
        lineItem.startMonth = "1";
        lineItem.duration = lineItem.values.length;
      }

      lineItem = setLineItemValues(lineItem);
      return lineItem;
    });
    const {
      divisionSubtotals,
      divisionBudgetAmount,
      divisionPreviousBudgetAmount,
    } = generateDivisionSubtotals(updatedLineItems, project.months);

    division = {
      ...division,
      lineItems: updatedLineItems,
      divisionSubtotals,
      divisionBudgetAmount,
      divisionPreviousBudgetAmount,
    };

    const divisionIndex = divisions.findIndex((d) => d.id === data.divisionId);

    divisions[divisionIndex] = division;

    const projectionTotals = getProjectionTotals(divisions, project.months);

    setProjection({
      ...projection,
      divisions,
      projectionTotals,
      state: UPDATING_PROJECTION_STATE,
    });
    setLoadingFundingSources(true);
  };

  const handleDivisionSettingChanged = (data) => {
    const { project, divisions, state } = projection;

    if (projectionStateBeforeUpdates === null) {
      setProjectionStateBeforeUpdates(state);
    }

    const isBlankValue = isBlank(data.value);
    const value = isBlankValue ? blankSelectValue : data.value;

    let division = divisions.find((d) => d.id === data.id);
    division = { ...division, [data.option]: value };

    if (division.curveType === PROJECTION_CURVE_TYPE.MANUAL) {
      division = {
        ...division,
        startMonth: "1",
        duration: division.lineItems[0].values.length,
      };
    }

    const updatedLineItems = division.lineItems.map((lineItem) => {
      lineItem[data.option] = value;

      // if updating the duration make sure if a line item has a start month that would
      // "overflow" the project duration that we adjust that line item duration accordigly
      if (data.option === "duration" || data.option === "startMonth") {
        const startMonthIndex = getStartMonthIndex(lineItem.startMonth);

        const totalDuration = add(lineItem.duration, startMonthIndex);

        if (totalDuration > project.expectedLength) {
          lineItem.duration = (
            project.expectedLength - startMonthIndex
          ).toString();
          division = { ...division, duration: "MIXED" };
        }
      }

      // When a user selects manual they shouldn't have to select start month and duration
      if (lineItem.curveType === PROJECTION_CURVE_TYPE.MANUAL) {
        lineItem.startMonth = "1";
        lineItem.duration = lineItem.values.length;
      }

      lineItem = setLineItemValues(lineItem);
      return lineItem;
    });
    const {
      divisionSubtotals,
      divisionBudgetAmount,
      divisionPreviousBudgetAmount,
    } = generateDivisionSubtotals(updatedLineItems, project.months);

    division = {
      ...division,
      lineItems: updatedLineItems,
      divisionSubtotals,
      divisionBudgetAmount,
      divisionPreviousBudgetAmount,
    };

    const divisionIndex = divisions.findIndex((d) => d.id === data.id);
    divisions[divisionIndex] = division;

    const projectionTotals = getProjectionTotals(divisions, project.months);

    setProjection({
      ...projection,
      divisions,
      projectionTotals,
      state: UPDATING_PROJECTION_STATE,
    });
    setLoadingFundingSources(true);
  };

  const handleLineItemValueChanged = (data) => {
    const { divisions, state, project } = projection;

    if (projectionStateBeforeUpdates === null) {
      setProjectionStateBeforeUpdates(state);
    }

    let division = divisions.find((d) => d.id === data.divisionId);

    const updatedLineItems = division.lineItems.map((lineItem) => {
      if (lineItem.id !== data.id) return lineItem;
      lineItem.values[data.monthIndex].updatedProjection = data.value;
      return lineItem;
    });
    const {
      divisionBudgetAmount,
      divisionSubtotals,
      divisionPreviousSubtotals,
    } = generateDivisionSubtotals(updatedLineItems, project.months);

    division = {
      ...division,
      lineItems: updatedLineItems,
      divisionBudgetAmount,
      divisionSubtotals,
      divisionPreviousSubtotals,
    };

    const divisionIndex = divisions.findIndex((d) => d.id === data.divisionId);

    divisions[divisionIndex] = division;

    const projectionTotals = getProjectionTotals(divisions, project.months);

    setProjection({
      ...projection,
      divisions,
      projectionTotals,
      state: UPDATING_PROJECTION_STATE,
    });
    setLoadingFundingSources(true);
  };

  const handleUndo = () => {
    setProjection({
      ...cloneDeep(initialProjection),
      state: projectionStateBeforeUpdates,
    });
    if (
      projectionStateBeforeUpdates ===
      "NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE"
    ) {
      analytics.track("Projection Actuals Update Undone", {
        projectId: projection.project.id,
      });
    }
    setProjectionStateBeforeUpdates(null);
    setLoadingFundingSources(true);
  };

  const handleSaveProjection = () => {
    const { actualsCount, divisions, project } = projection;

    const lineItems = divisions.reduce(
      (divisionAcc, division) => [
        ...divisionAcc,
        ...division.lineItems.reduce(
          (lineItemAcc, lineItem) => [
            ...lineItemAcc,
            formatLineItemForSave(lineItem),
          ],
          []
        ),
      ],
      []
    );

    saveProjection({
      variables: {
        projectId: project.id,
        lineItems,
        actualsCount,
      },
    }).then(() => {
      setProjection({ ...projection, state: UPDATED_PROJECTION_STATE });
      setProjectionStateBeforeUpdates(null);
    });
  };

  const dispatch = (event, data) => {
    switch (event) {
      case PROJECTION_EVENT.VIEW_WAS_UPDATED:
        handleViewUpdate(data);
        break;
      case PROJECTION_EVENT.FUNDING_SOURCE_CHART_UPDATED:
        handleFundingSourceChartUpdate(data);
        break;
      case PROJECTION_EVENT.PROJECTION_CHARTS_UPDATED:
        handleChartsUpdate(data);
        break;
      case PROJECTION_EVENT.COLUMN_WAS_UPDATED:
        handleColumnUpdate(data);
        break;
      case PROJECTION_EVENT.DIVISION_SETTING_CHANGED:
        handleDivisionSettingChanged(data);
        break;
      case PROJECTION_EVENT.UPDATE_PROJECTION:
        handleUpdateProjection();
        break;
      case PROJECTION_EVENT.LINE_ITEM_VALUE_CHANGED:
        handleLineItemValueChanged(data);
        break;
      case PROJECTION_EVENT.LINE_ITEM_SETTING_CHANGED:
        handleLineItemSettingChanged(data);
        break;
      case PROJECTION_EVENT.UNDO:
        handleUndo();
        break;
      case PROJECTION_EVENT.SAVE_PROJECTION:
        handleSaveProjection();
        break;
      default:
        break;
    }
  };

  const isSaving = saveProjectionResult.loading;

  return [
    projection,
    loadingProjection,
    loadingFundingSources,
    isSaving,
    errors,
    { dispatch },
  ];
};

function shouldShowPreviousBudget(divisions) {
  return divisions.some((division) => {
    return division.lineItems.some(
      (lineItem) => lineItem.budgetAmount !== lineItem.previousBudgetAmount
    );
  });
}

function shouldShowPreviousFundingSourceAmount(fundingSources) {
  return fundingSources.some(
    (fundingSource) =>
      fundingSource.previousAmount &&
      fundingSource.amount !== fundingSource.previousAmount
  );
}

function setLineItemValues(lineItem) {
  if (
    lineItem.curveType === "none" ||
    lineItem.startMonth === "none" ||
    lineItem.duration === "none"
  ) {
    return resetLineItem(lineItem);
  }
  const hasSettingsSet =
    !isBlank(lineItem.curveType) &&
    lineItem.curveType !== "none" &&
    !isBlank(lineItem.startMonth) &&
    lineItem.startMonth !== "none" &&
    !isBlank(lineItem.duration) &&
    lineItem.duration !== "none";

  if (!hasSettingsSet) return { ...lineItem };

  const updatedProjectionAmounts = getCalculatedValues({
    hasDurationAndStartMonth: true,
    lineItem,
    startMonthOutsideOfDurationBounds: false,
  });

  const values = lineItem.values.map((_item, valueIndex) => {
    return {
      projected: lineItem.values[valueIndex].projected,
      actual: lineItem.values[valueIndex].actual,
      updatedProjection: updatedProjectionAmounts[valueIndex],
    };
  });

  return { ...lineItem, values };
}

function getDivisionSettingState(lineItemSettings) {
  if (lineItemSettings.length > 1) {
    return "MIXED";
  }
  if (lineItemSettings[0] === null) {
    return "none";
  }
  return lineItemSettings[0];
}

function generateState(isLockedProjection, actualsCount, isDirty) {
  if (isLockedProjection)
    return NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE;

  if (!isLockedProjection && actualsCount > 0 && !isDirty)
    return UPDATED_PROJECTION_STATE;

  if (isDirty) return UPDATING_PROJECTION_STATE;

  return NEW_PROJECTION_STATE;
}

function isProjectionLocked(divisions) {
  const hasValidProjection = getHasValidProjection(divisions);

  if (!hasValidProjection) return false;

  const isLocked = divisions.some((division) =>
    division.lineItems.some((lineItem) =>
      lineItem.values.some(({ projected, updatedProjection }) => {
        // hacky workaround sometimes a value is undefined sometimes it is null
        projected = projected === undefined ? null : projected;
        updatedProjection =
          updatedProjection === undefined ? null : updatedProjection;
        return projected !== updatedProjection;
      })
    )
  );

  return isLocked;
}

function transformFundingSources(
  fundingSourceGroups,
  actuals,
  projection,
  months,
  projectionTotals,
  automaticAllocationEnabled
) {
  const projectionFundingSources = get(
    projection,
    "projectionFundingSources",
    []
  );

  const fundingSources = flatMap(fundingSourceGroups).map(
    (fundingSource, index) => {
      const {
        id,
        amount,
        disbursedAmount,
        label,
        organization,
        type,
      } = fundingSource;

      const projectionFundingSource = find(
        projectionFundingSources,
        (projection) => projection.fundingSourceId === id
      ) || { monthlyProjectedAmounts: [], fundsRemaining: 0, amount: 0 };

      const {
        monthlyProjectedAmounts: sourceProjections,
      } = projectionFundingSource;
      const sourceActuals = actuals[id] || [];

      const values = generateInitialFundingSourceValues(
        months,
        sourceProjections,
        sourceActuals
      );

      const { firstMonth, finalMonth } = getFundingSourceDates(values, months);

      const remainingAmount = subtract(amount, disbursedAmount);
      const totalProjectedForSource = sumBy(values, "updatedProjection");
      const unprojectedFunds = subtract(amount, totalProjectedForSource);
      const hideFinalMonth = !automaticAllocationEnabled && remainingAmount > 0;

      return {
        id,
        amount,
        previousAmount: projectionFundingSource.amount,
        remainingAmount,
        previousRemainingAmount: projectionFundingSource.fundsRemaining,
        unprojectedFunds,
        type,
        name: label,
        organization: organization.name,
        firstMonth,
        finalMonth: hideFinalMonth ? null : finalMonth,
        values,
        color: getGraphColor(index),
      };
    }
  );

  const remainingFunds = automaticAllocationEnabled
    ? null
    : generateRemainingFunds(fundingSources, projectionTotals, months);

  const { insufficientFunds, fundingSourceTotals } = generateInsufficientFunds(
    fundingSources,
    projectionTotals,
    months,
    remainingFunds
  );

  return {
    fundingSources,
    insufficientFunds,
    remainingFunds,
    fundingSourceTotals,
  };
}

function getFundingSourceDates(values, months) {
  const firstMonthWithFunding = findIndex(
    values,
    ({ updatedProjection }) => updatedProjection !== 0
  );
  const finalMonthWithFunding = findLastIndex(
    values,
    ({ updatedProjection }) => updatedProjection !== 0
  );

  return {
    firstMonth: months[firstMonthWithFunding],
    finalMonth: months[finalMonthWithFunding],
  };
}

function generateInitialFundingSourceValues(months, projectedAmounts, actuals) {
  const hasProjectionSaved = projectedAmounts.length > 0;

  return months.map((_, monthIndex) => ({
    projected: hasProjectionSaved
      ? projectedAmounts[monthIndex] || 0
      : actuals[monthIndex] || 0,
    actual: !isBlank(actuals[monthIndex]) ? actuals[monthIndex] : null,
    updatedProjection: !isBlank(actuals[monthIndex]) ? actuals[monthIndex] : 0,
  }));
}

function generateFundingSourceTotals(fundingSources, months) {
  const emptyAccumulator = months.map((_month) => ({
    projected: 0,
    updatedProjection: 0,
  }));
  return fundingSources
    .map(({ values }) => values)
    .reduce(
      (acc, fundingSource) =>
        acc.map((totals, monthIndex) => ({
          projected: add(totals.projected, fundingSource[monthIndex].projected),
          updatedProjection: add(
            totals.updatedProjection,
            fundingSource[monthIndex].updatedProjection
          ),
        })),
      emptyAccumulator
    );
}

function getFundingSourcesRemainingBalances(fundingSources) {
  return fundingSources.reduce(
    (
      [remainingtotal, previousRemainingTotal],
      { remainingAmount, previousRemainingAmount }
    ) => [
      add(remainingtotal, remainingAmount),
      add(previousRemainingTotal, previousRemainingAmount),
    ],
    [0, 0]
  );
}

function getRemainingFundsValue(
  projectionTotal,
  fundingSourceTotal,
  balanceRemaining
) {
  const projectedDifference = subtract(projectionTotal, fundingSourceTotal);

  return projectedDifference <= balanceRemaining
    ? projectedDifference
    : balanceRemaining;
}

function generateRemainingFunds(fundingSources, projectionTotals, months) {
  const [
    balanceRemaining,
    previousBalanceRemaining,
  ] = getFundingSourcesRemainingBalances(fundingSources);

  const fundingSourceTotals = generateFundingSourceTotals(
    fundingSources,
    months
  );

  const { values } = months.reduce(
    (
      { projectedRemaining, updatedProjectionRemaining, values },
      _month,
      monthIndex
    ) => {
      const projected = getRemainingFundsValue(
        projectionTotals[monthIndex].projected,
        fundingSourceTotals[monthIndex].projected,
        projectedRemaining
      );
      const updatedProjection = getRemainingFundsValue(
        projectionTotals[monthIndex].updatedProjection,
        fundingSourceTotals[monthIndex].updatedProjection,
        updatedProjectionRemaining
      );

      const value = {
        projected,
        updatedProjection,
      };

      const newProjectedRemaining = subtract(projectedRemaining, projected);

      const newUpdatedProjectionRemaining = subtract(
        updatedProjectionRemaining,
        updatedProjection
      );

      return {
        projectedRemaining: newProjectedRemaining,
        updatedProjectionRemaining: newUpdatedProjectionRemaining,
        values: values.concat(value),
      };
    },
    {
      projectedRemaining: previousBalanceRemaining,
      updatedProjectionRemaining: balanceRemaining,
      values: [],
    }
  );

  const remainingFunds = {
    id: "remainingFunds",
    name: "Funded",
    color: "#575757",
    amount: null,
    values,
    type: null,
    organization: null,
  };

  return remainingFunds;
}

function generateInsufficientFunds(
  fundingSources,
  projectionTotals,
  months,
  remainingFunds
) {
  const activeFundingSources = remainingFunds
    ? fundingSources.concat(remainingFunds)
    : fundingSources;
  const fundingSourceTotals = generateFundingSourceTotals(
    activeFundingSources,
    months
  );

  const values = months.map((_month, monthIndex) => ({
    projected: subtract(
      projectionTotals[monthIndex].projected,
      fundingSourceTotals[monthIndex].projected
    ),
    updatedProjection: subtract(
      projectionTotals[monthIndex].updatedProjection,
      fundingSourceTotals[monthIndex].updatedProjection
    ),
  }));
  const amount = values.reduce(
    (acc, period) => add(acc, period.updatedProjection),
    0
  );
  const dates = getFundingSourceDates(values, months);
  const insufficientFunds = {
    id: "insufficientFunds",
    name: "Insufficient Funds",
    color: "#d6d6d6",
    amount,
    values,
    type: null,
    organization: null,
    loaded: true,
    ...dates,
  };
  const updatedFundingSourceTotals = generateFundingSourceTotals(
    activeFundingSources.concat(insufficientFunds),
    months
  );
  return { insufficientFunds, fundingSourceTotals: updatedFundingSourceTotals };
}

function mapProjectionsToDivisions(divisions, projection) {
  const lineItemProjections = get(projection, "projectionLineItems", []).reduce(
    (acc, projection) => ({ ...acc, [projection.lineItemId]: projection }),
    {}
  );
  return divisions.map((division) => ({
    ...division,
    lineItems: division.lineItems.map((lineItem) => ({
      ...lineItem,
      projection: lineItemProjections[lineItem.id],
    })),
  }));
}

function transformDivisions(divisions, actuals, months) {
  return divisions.map(({ id, lineItems, name }) => {
    const transformedLineItems = transformLineItems(lineItems, actuals, months);

    const {
      divisionSubtotals,
      divisionBudgetAmount,
      divisionPreviousBudgetAmount,
    } = generateDivisionSubtotals(transformedLineItems, months);

    return {
      id,
      name,
      curveType: getInitialDivisionSettingValue(lineItems, "curveType"),
      startMonth: getInitialDivisionSettingValue(lineItems, "startMonth"),
      duration: getInitialDivisionSettingValue(lineItems, "duration"),
      lineItems: transformedLineItems,
      divisionBudgetAmount,
      divisionSubtotals,
      divisionPreviousBudgetAmount,
    };
  });
}

function transformLineItems(lineItems, actuals, months = []) {
  return lineItems.map(({ id, budgetAmount, name, projection }) => {
    const { duration, startMonth, curveType, budget } = projection || {
      duration: null,
      startMonth: null,
      curveType: null,
      budget: null,
    };
    const lineItem = {
      id,
      budgetAmount,
      previousBudgetAmount: budget || budgetAmount,
      name,
      curveType: curveType || blankSelectValue,
      duration: duration ? duration.toString() : blankSelectValue,
      startMonth: startMonth ? startMonth.toString() : blankSelectValue,
    };
    return {
      ...lineItem,
      values: generateLineItemValues(
        projection,
        get(actuals, id, []),
        months,
        lineItem
      ),
    };
  });
}

function generateDivisionSubtotals(lineItems, months) {
  const emptyAccumulator = months.map((_month) => ({
    projected: 0,
    updatedProjection: 0,
  }));

  const byLineItem = lineItems.map(({ values }) => values);

  const divisionSubtotals = byLineItem.reduce(
    (acc, lineItem) =>
      acc.map((totals, monthIndex) => ({
        projected: add(totals.projected, lineItem[monthIndex].projected),
        updatedProjection: add(
          totals.updatedProjection,
          lineItem[monthIndex].updatedProjection
        ),
      })),
    emptyAccumulator
  );
  const divisionBudgetAmount = lineItems.reduce(
    (total, { budgetAmount }) => add(total, budgetAmount),
    0
  );

  const divisionPreviousBudgetAmount = lineItems.reduce(
    (total, { previousBudgetAmount }) => add(total, previousBudgetAmount),
    0
  );

  return {
    divisionSubtotals,
    divisionBudgetAmount,
    divisionPreviousBudgetAmount,
  };
}

function generateLineItemValues(projection, actuals, months, lineItem) {
  const projectedAmounts = projection?.monthlyProjectedAmounts || [];

  const hasProjectionSaved = projectedAmounts.length > 0;

  const initialLineItemValues = generateInitialLineItemValues(
    months,
    projectedAmounts,
    actuals
  );

  const hasSettingsSet =
    !isBlank(lineItem.curveType) &&
    lineItem.curveType !== "none" &&
    !isBlank(lineItem.startMonth) &&
    lineItem.startMonth !== "none" &&
    !isBlank(lineItem.duration) &&
    lineItem.duration !== "none";

  const updatedLineItem = {
    ...lineItem,
    values: initialLineItemValues,
  };

  const updatedProjectionAmounts = hasSettingsSet
    ? getUpdatedValues(updatedLineItem)
    : [];

  return months.map((_, monthIndex) => ({
    projected: hasProjectionSaved
      ? initialLineItemValues[monthIndex].projected || 0
      : initialLineItemValues[monthIndex].actual || 0,
    actual: initialLineItemValues[monthIndex].actual,
    updatedProjection: getUpdatedProjectionAmount(
      initialLineItemValues[monthIndex].actual,
      updatedProjectionAmounts[monthIndex]
    ),
  }));
}

function generateInitialLineItemValues(months, projectedAmounts, actuals) {
  return months.map((_, monthIndex) => ({
    projected: projectedAmounts[monthIndex],
    actual: !isBlank(actuals[monthIndex]) ? actuals[monthIndex] : null,
    updatedProjection: projectedAmounts[monthIndex],
  }));
}

function getInitialDivisionSettingValue(lineItems, type) {
  const types = [
    ...new Set(
      lineItems.map((lineItem) => get(lineItem, `projection.${type}`))
    ),
  ];

  return getDivisionSettingState(types);
}

function generateMonths(startDate, expectedProjectLength) {
  // format it to noon so when we format it to a date it doesn't move to the previous
  // day when it's formatted to UTC
  const startDateTime = `${startDate}T12:00:00.000000`;

  return range(1, expectedProjectLength + 1).map((monthNumber) => {
    // Don't add the month to itself
    const futureMonth = addMonths(startDateTime, monthNumber - 1);
    return `${monthNumber} (${formatDate(futureMonth, null, "MMM ''yy")})`;
  });
}

function getUpdatedValues(lineItem) {
  if (lineItem.curveType === PROJECTION_CURVE_TYPE.MANUAL) {
    return lineItem.values.reduce((acc, value) => {
      acc.push(value.projected);
      return acc;
    }, []);
  }

  return getCalculatedValues({
    hasDurationAndStartMonth: true,
    lineItem,
    startMonthOutsideOfDurationBounds: false,
  });
}

function resetLineItem(lineItem) {
  // only reset updatedProjection if month has no actuals
  const values = lineItem.values.map((month) =>
    month.actual === null
      ? {
          ...month,
          updatedProjection: 0,
        }
      : { ...month }
  );
  return { ...lineItem, values };
}

function getUpdatedProjectionAmount(actual, updatedAmount) {
  if (actual === null) {
    return updatedAmount || 0;
  }
  return actual;
}

function getFundingSourceQueryValues(projection) {
  if (!projection?.divisions) return [];
  const { actualsCount } = projection;
  return projection.divisions.flatMap(({ lineItems }) =>
    lineItems.map(({ id, values }) => ({
      lineItemId: id,
      monthlyProjectedAmounts: values
        .slice(actualsCount)
        .map(({ updatedProjection }) =>
          updatedProjection ? updatedProjection.toString() : updatedProjection
        ),
    }))
  );
}

function formatLineItemForSave({
  id: lineItemId,
  curveType,
  duration,
  startMonth,
  values,
  budgetAmount,
}) {
  return {
    lineItemId,
    budget: multiply(budgetAmount, 100),
    curveType: selectIsBlank(curveType) ? null : curveType,
    duration: selectIsBlank(duration) ? null : unformatNumber(duration),
    startMonth: selectIsBlank(startMonth) ? null : unformatNumber(startMonth),
    monthlyProjectedAmounts: values.map(({ updatedProjection }) =>
      updatedProjection ? updatedProjection.toString() : updatedProjection
    ),
  };
}

function formatFundingSourcesForDisplay(
  fundingSources,
  insufficientFunds,
  remainingFunds
) {
  const validInsufficientFunds = isValidInsufficientFunds(insufficientFunds);
  const hasSources = fundingSources.length > 0;

  let chartFundingSources = fundingSources;
  let tableFundingSources = fundingSources;

  // remainingFunds are only shown in the chart
  chartFundingSources = remainingFunds
    ? chartFundingSources.concat(remainingFunds)
    : chartFundingSources;
  chartFundingSources = validInsufficientFunds
    ? chartFundingSources.concat(insufficientFunds)
    : chartFundingSources;
  tableFundingSources =
    validInsufficientFunds && hasSources
      ? tableFundingSources.concat(insufficientFunds)
      : tableFundingSources;

  return { chartFundingSources, tableFundingSources };
}
