import { isValidElement } from "react";
import PropTypes from "prop-types";
import { Pane } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import ActionsDropdown from "./ActionsDropdown";
import BulkActionControls from "./BulkActionControls";
import CurrentFilters from "./CurrentFilters";
import CurrentGrouping from "./CurrentGrouping";
import ExportToCSV from "./ExportToCSV";
import Filters from "./Filters";
import SavedViews from "./SavedViews";
import ScrollButtons from "./ScrollButtons";
import Search from "./Search";

const AdvancedControls = ({
  children,
  isReport,
  leftControls,
  rightControls,
  searchPlaceholder,
  ...props
}) => {
  const { views, filterConfig, groupConfig } = props;

  const isEnabled = (control) => {
    return !props.disable.includes(control);
  };

  const defaultRightControls = (
    <Pane key="rightControls" display="flex" alignItems="center">
      {isEnabled(CurrentGrouping) && groupConfig.columnId && (
        <Pane marginLeft={minorScale(3)}>
          <CurrentGrouping {...props} />
        </Pane>
      )}
      {isEnabled(ActionsDropdown) && (
        <Pane marginLeft={minorScale(3)}>
          <ActionsDropdown {...props} />
        </Pane>
      )}
      {isEnabled(ScrollButtons) && (
        <Pane marginLeft={minorScale(3)}>
          <ScrollButtons {...props} />
        </Pane>
      )}
    </Pane>
  );

  const defaultLeftControls = (
    <Pane key="leftControls" display="flex" alignItems="center">
      {isEnabled(Search) && (
        <Pane marginRight={minorScale(3)}>
          <Search
            placeholder={searchPlaceholder}
            columns={props.dataColumns}
            columnConfig={props.columnConfig}
            filterConfig={props.filterConfig}
            filterBy={props.filterBy}
          />
        </Pane>
      )}
      {isEnabled(Filters) && (
        <Pane marginRight={minorScale(3)} marginTop={3}>
          <Filters {...props} />
        </Pane>
      )}
      {isEnabled(CurrentFilters) && filterConfig.length > 0 && (
        <Pane display="flex" marginRight={minorScale(3)}>
          <CurrentFilters {...props} />
        </Pane>
      )}
    </Pane>
  );

  return (
    <Pane margin={majorScale(2)}>
      <Pane
        marginBottom={majorScale(2)}
        display="flex"
        justifyContent="space-between"
      >
        <Pane>
          {isEnabled(SavedViews) && views.length > 1 && (
            <SavedViews {...props} />
          )}
        </Pane>
        {children && <Pane marginBottom={majorScale(2)}>{children}</Pane>}
        {isReport && <ExportToCSV isButton {...props} />}
      </Pane>

      <Pane display="flex" height={48} justifyContent="space-between">
        <BulkActionControls {...props}>
          <Pane display="flex" alignItems="center">
            {leftControls && typeof leftControls === "function"
              ? leftControls(defaultLeftControls, props)
              : defaultLeftControls}
            {leftControls && isValidElement(leftControls) && (
              <Pane>{leftControls}</Pane>
            )}
          </Pane>
        </BulkActionControls>

        <Pane display="flex" alignItems="center">
          {rightControls && isValidElement(rightControls) && (
            <Pane>{rightControls}</Pane>
          )}
          {rightControls && typeof rightControls === "function"
            ? rightControls(defaultRightControls, props)
            : defaultRightControls}
        </Pane>
      </Pane>
    </Pane>
  );
};

AdvancedControls.propTypes = {
  isReport: PropTypes.bool,
  views: PropTypes.array,
  searchPlaceholder: PropTypes.string,
  leftControls: PropTypes.node,
  rightControls: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  filterConfig: PropTypes.array,
  groupConfig: PropTypes.shape({
    columnId: PropTypes.string,
  }),
  disable: PropTypes.array,
};

AdvancedControls.defaultProps = {
  isReport: false,
  views: [],
  searchPlaceholder: "Search...",
  disable: [],
};

export default AdvancedControls;
