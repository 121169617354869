import { Checkbox } from "components/materials";
import { difference, intersection, union, xor } from "lodash";
import { preventEventBubbling } from "helpers/preventEventBubbling";

export const bulkSelectUtilityColumnDefaults = {
  aggregate: (groupItems, selectedItemIds, setSelectedItemIds) => {
    const groupIds = groupItems.map((item) => item.id);
    const groupSelectedIds = intersection(selectedItemIds, groupIds);
    const indeterminate = !!groupIds.length && groupSelectedIds.length > 0;
    const checked =
      !!groupIds.length && groupIds.length === groupSelectedIds.length;

    return (
      <Checkbox
        checked={checked}
        indeterminate={!checked && indeterminate}
        margin={0}
        onClick={(e) => {
          preventEventBubbling(e);

          if (checked || indeterminate) {
            setSelectedItemIds(difference(selectedItemIds, groupIds));
          } else {
            setSelectedItemIds(union(selectedItemIds, groupIds));
          }
        }}
      />
    );
  },
  header: (preparedItems, selectedItemIds, setSelectedItemIds) => {
    const preparedIds = Array.isArray(preparedItems)
      ? preparedItems.map((item) => item.id)
      : Object.entries(preparedItems.groups).reduce(
          (acc, [value, items]) => acc.concat(items.map((item) => item.id)),
          []
        );
    const indeterminate = !!preparedIds.length && selectedItemIds.length > 0;
    const checked =
      !!preparedIds.length && preparedIds.length === selectedItemIds.length;

    return (
      <Checkbox
        checked={checked}
        indeterminate={!checked && indeterminate}
        margin={0}
        onClick={(e) => {
          preventEventBubbling(e);

          if (checked || indeterminate) {
            setSelectedItemIds([]);
          } else {
            setSelectedItemIds(preparedIds);
          }
        }}
      />
    );
  },
  id: "bulkSelect",
  utility: true,
  value: (item, selectedItemIds, setSelectedItemIds) => {
    return (
      <Checkbox
        checked={selectedItemIds.includes(item.id)}
        margin={0}
        onClick={(e) => {
          preventEventBubbling(e);
          setSelectedItemIds(xor(selectedItemIds, [item.id]));
        }}
      />
    );
  },
  width: 40,
};
