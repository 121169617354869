import { useContext, useState, useRef, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { Button, Loadable, Pane, Tab, Tablist } from "components/materials";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { UserContext, withNotFound } from "helpers/behaviors";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";
import { getSearchByKey } from "helpers/queryStringHelpers";
import { get, includes } from "lodash";
import {
  AgreementsPageAggregates,
  AgreementsViewer,
  AddAgreementModal,
  AgreementsTable,
  LegacyAgreementsTable,
  AgreementsSidebar,
  ChangeOrdersChart,
  AGREEMENTS_PAGE_QUERY,
  ADD_PROJECT_AGREEMENT,
  CHANGE_ORDER_TYPES,
} from "./Agreements";

const UPDATE_USER_VIEW_CONFIG = gql`
  mutation UpdateUserViewConfig($showAgreementsCharts: Boolean!) {
    updateUserViewConfig(showAgreementsCharts: $showAgreementsCharts) {
      id
      showAgreementsCharts
    }
  }
`;

function AgreementViewControls({
  hasChangeOrders,
  showGraph,
  setShowGraph,
  showAgreementsView,
  setShowAgreementsView,
}) {
  return (
    <Pane display="flex" alignItems="center">
      <Tablist marginX={minorScale(3)}>
        <Tab
          isSelected={!!showAgreementsView}
          onSelect={() => setShowAgreementsView(true)}
        >
          View Agreements
        </Tab>
        <Tab
          isSelected={!showAgreementsView}
          onSelect={() => setShowAgreementsView(false)}
        >
          View Vendors
        </Tab>
      </Tablist>
      {hasChangeOrders && !showGraph && (
        <Button marginLeft={majorScale(1)} onClick={() => setShowGraph(true)}>
          Show Change Orders Graph
        </Button>
      )}
    </Pane>
  );
}

function AddAgreementButton({ setShowAddAgreementModal }) {
  return (
    <Button
      appearance="primary"
      marginLeft={majorScale(2)}
      onClick={() => setShowAddAgreementModal(true)}
      purpose="agreements add open"
    >
      {t("agreementsPage.addAgreementButton")}
    </Button>
  );
}

export function AgreementsPage({ history, match }) {
  const { agreementId, documentId, projectId } = match.params;
  const { organizationId, user } = useContext(UserContext);

  const { showAgreementsCharts } = user;
  const viewConfig = getSearchByKey(history, "view");
  const [showAgreementsView, setShowAgreementsView] = useState(
    !viewConfig || viewConfig === "agreements"
  );

  const initialUpdate = useRef(true);
  useEffect(() => {
    if (initialUpdate.current === true) {
      initialUpdate.current = false;
    } else {
      history.replace({
        ...history.location,
        search: `?view=${showAgreementsView ? "agreements" : "vendors"}`,
      });
    }
  }, [history, showAgreementsView]);

  const [showAddAgreementModal, setShowAddAgreementModal] = useState(false);
  const [showGraph, setShowGraph] = useState(showAgreementsCharts);

  const [updateUserViewConfig] = useMutation(UPDATE_USER_VIEW_CONFIG);

  useEffect(() => {
    updateUserViewConfig({
      variables: {
        showAgreementsCharts: showGraph,
      },
    });
  }, [showGraph, updateUserViewConfig]);

  const agreementsTableRefetchQueries = [
    { query: AGREEMENTS_PAGE_QUERY, variables: { projectId } },
  ];

  const [addProjectAgreement, addProjectAgreementResult] = useMutation(
    ADD_PROJECT_AGREEMENT,
    {
      refetchQueries: agreementsTableRefetchQueries,
      onCompleted: () => {
        setShowAddAgreementModal(false);
      },
    }
  );

  const queryResult = useQuery(AGREEMENTS_PAGE_QUERY, {
    variables: {
      projectId,
    },
  });

  const baseUrl = `/projects/${projectId}/agreements`;

  const handleSidebarOpen = ({ lineItem, vendor }) => {
    const vendorId = get(vendor, "id", "null");
    const lineItemId = get(lineItem, "id", "null");
    history.push(
      `${baseUrl}/${vendorId}/${lineItemId}${history.location.search}`
    );
  };

  const handleSidebarClose = () => {
    if (documentId) return;
    history.push(`${baseUrl}${history.location.search}`);
  };

  const handleAgreementsViewerOpen = ({ id: agreementId }) => {
    history.push(`${baseUrl}/${agreementId}${history.location.search}`);
  };

  const handleAgreementsViewerClose = () => {
    if (history.length === 1) {
      history.push(baseUrl);
    } else {
      history.goBack();
    }
  };

  const renderAgreementsPage = ({ data, loading }) => {
    if (loading) return <Loadable loading />;
    const { project } = data;
    const { agreements } = project;
    const changeOrders = agreements.filter(({ type }) =>
      includes(CHANGE_ORDER_TYPES, type)
    );
    const hasChangeOrders = changeOrders.length > 0;
    const sharedTableProps = {
      addButton: (
        <AddAgreementButton
          setShowAddAgreementModal={setShowAddAgreementModal}
        />
      ),
      project,
      agreements,
      history,
      organizationId,
    };

    return (
      <Fragment>
        {hasChangeOrders && showGraph && (
          <ChangeOrdersChart
            changeOrders={changeOrders}
            setShowGraph={setShowGraph}
          />
        )}
        <Pane display="flex" alignItems="center" marginBottom={-majorScale(2)}>
          <Pane flex="0 0 auto">
            <AgreementViewControls
              hasChangeOrders={hasChangeOrders}
              showGraph={showGraph}
              setShowGraph={setShowGraph}
              showAgreementsView={showAgreementsView}
              setShowAgreementsView={setShowAgreementsView}
            />
          </Pane>
          <AgreementsPageAggregates project={project} />
        </Pane>
        {showAgreementsView ? (
          <AgreementsTable
            {...sharedTableProps}
            onClickRow={handleAgreementsViewerOpen}
            refetchQueries={agreementsTableRefetchQueries}
          />
        ) : (
          <LegacyAgreementsTable
            {...sharedTableProps}
            onClickRow={handleSidebarOpen}
          />
        )}

        {agreementId && (
          <AgreementsViewer
            handleAgreementsViewerClose={handleAgreementsViewerClose}
            history={history}
            match={match}
            project={data.project}
            agreementsTableRefetchQueries={agreementsTableRefetchQueries}
          />
        )}
        {showAddAgreementModal && (
          <AddAgreementModal
            agreements={data.project.agreements}
            isSaving={addProjectAgreementResult.loading}
            onClose={() => setShowAddAgreementModal(false)}
            project={data.project}
            projectAgreementMutation={addProjectAgreement}
          />
        )}
        <AgreementsSidebar
          closeSidebar={handleSidebarClose}
          history={history}
          match={match}
          onClickAgreement={handleAgreementsViewerOpen}
          refetchQueries={agreementsTableRefetchQueries}
        />
      </Fragment>
    );
  };

  return withNotFound(renderAgreementsPage)(queryResult);
}
