import { useEffect } from "react";
import PropTypes from "prop-types";

export function DirtyEffect({ dirty, setFormDirty }) {
  useEffect(() => {
    setFormDirty(dirty);
  }, [dirty, setFormDirty]);
  return null;
}

DirtyEffect.propTypes = {
  dirty: PropTypes.bool.isRequired,
  setFormDirty: PropTypes.func.isRequired,
};
