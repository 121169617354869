import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { AddIcon } from "evergreen-ui";
import {
  Accordion,
  Badge,
  DragAndDrop,
  Menu,
  Modal,
  Pane,
  SearchInput,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { stringComparator } from "helpers/comparators";
import { differenceBy, escapeRegExp, groupBy } from "lodash";

function CustomizeColumns({
  applyColumnConfig,
  closeDropdown,
  columnConfig,
  dataColumns,
}) {
  const columns = dataColumns.reduce((acc, column) => {
    if (!column.__utility && !column.hidden && !column.idRepeated) {
      acc.push(column);
    } else if (
      column.idRepeated &&
      !column.hidden &&
      !acc.some((c) => c.id === column.idRepeated)
    ) {
      acc.push({
        ...column,
        id: column.idRepeated,
        sortHeader: column.header,
        header: (
          <Fragment>
            <Badge marginRight={minorScale(1)}>{column.headerRepeated}</Badge>
            {column.header}
          </Fragment>
        ),
      });
    }
    return acc;
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [columnSearch, setColumnSearch] = useState("");
  const [activeColumns, setActiveColumns] = useState(
    columnConfig.reduce((acc, columnId) => {
      const foundColumn = columns.find((column) => column.id === columnId);
      return foundColumn ? [...acc, foundColumn] : acc;
    }, [])
  );
  const allInactiveColumns = differenceBy(columns, activeColumns, "id");
  const sortedFilteredInactiveColumns = prepareInactiveColumns(
    allInactiveColumns,
    columnSearch
  );

  const handleAdd = (column) => {
    setActiveColumns([...activeColumns, column]);
  };

  const handleUpdate = (values) => {
    setActiveColumns(values.map(({ value }) => value));
  };

  const handleRemove = ({ value: removedColumn }) => {
    const columns = activeColumns.filter(
      (column) => column.id !== removedColumn.id
    );
    setActiveColumns(columns);
  };

  return (
    <Fragment>
      <Menu.Item is={Text} onSelect={() => setIsModalOpen(true)}>
        Customize Columns...
      </Menu.Item>
      <Modal
        open={isModalOpen}
        title="Customize Columns"
        hasClose={false}
        hasFooter
        confirmLabel="Apply"
        size="medium"
        withoutAutofocus
        onConfirm={(close) => {
          applyColumnConfig(activeColumns.map((column) => column.id));
          close();
        }}
        onCloseComplete={() => {
          setIsModalOpen(false);
          closeDropdown();
        }}
      >
        <Pane
          display="flex"
          justifyContent="space-between"
          minHeight={250}
          maxHeight={450}
        >
          <Pane width="50%" paddingX={majorScale(2)}>
            <Pane marginBottom={majorScale(1)}>
              <Text textTransform="uppercase" marginBottom={majorScale(2)}>
                Active
              </Text>
            </Pane>

            <DragAndDrop
              items={prepareActiveColumnsForDragAndDrop(activeColumns)}
              onUpdate={handleUpdate}
              onRemove={handleRemove}
            />
          </Pane>

          <Pane width="50%">
            <Pane marginBottom={majorScale(1)}>
              <Text textTransform="uppercase" marginBottom={majorScale(2)}>
                Inactive
              </Text>
            </Pane>
            <SearchInput
              onChange={(e) => setColumnSearch(e.target.value)}
              value={columnSearch}
              placeholder="Search Inactive Columns"
              marginBottom={majorScale(2)}
            />
            {hasColumnCategories(sortedFilteredInactiveColumns) ? (
              <GroupedInactiveColumns
                columns={sortedFilteredInactiveColumns}
                handleAdd={handleAdd}
                searchActive={!!columnSearch}
              />
            ) : (
              <InactiveColumns
                columns={sortedFilteredInactiveColumns}
                handleAdd={handleAdd}
              />
            )}
          </Pane>
        </Pane>
      </Modal>
    </Fragment>
  );
}

function InactiveColumns({ columns, handleAdd }) {
  return columns.map((column) => (
    <InactiveColumn key={column.id} column={column} handleAdd={handleAdd} />
  ));
}

function GroupedInactiveColumns({ columns, handleAdd, searchActive }) {
  const inactiveColumnGroups = groupInactiveColumns(columns);

  const columnGroupPanels = inactiveColumnGroups.map(
    ([groupName, columns]) => ({
      key: groupName,
      title: groupName,
      content: <InactiveColumns columns={columns} handleAdd={handleAdd} />,
    })
  );

  return (
    <Accordion
      allPanelKeys={columnGroupPanels.map(({ key }) => key)}
      contentStyles={{ paddingLeft: majorScale(2), paddingTop: minorScale(3) }}
      defaultActiveKeys={[]}
      headerStyles={{ size: 400 }}
      panels={columnGroupPanels}
      panelStyles={{ paddingY: majorScale(1) }}
      shouldExpandAllPanels={searchActive}
    />
  );
}

function InactiveColumn({ column, handleAdd }) {
  return (
    <Pane
      cursor="pointer"
      key={column.id}
      onClick={() => handleAdd(column)}
      marginBottom={majorScale(1)}
      data-purpose="table columns add"
    >
      <AddIcon color="info" marginBottom={-2} marginRight={minorScale(3)} />
      <Text color="info">{column.header}</Text>
    </Pane>
  );
}

function prepareActiveColumnsForDragAndDrop(columns) {
  return columns.map((column) => ({
    canRemove: !column.primary,
    isDraggable: !column.primary,
    key: column.id,
    secondaryText: column.primary ? "primary" : null,
    text: column.header,
    value: column,
  }));
}

function prepareInactiveColumns(allInactiveColumns, columnSearch) {
  return allInactiveColumns
    .map((column) => ({
      ...column,
      sortHeader: column.sortHeader || column.header,
    }))
    .filter((column) =>
      new RegExp(escapeRegExp(columnSearch), "i").test(column.sortHeader)
    )
    .sort((a, b) => stringComparator(a.sortHeader, b.sortHeader));
}

function hasColumnCategories(inactiveColumns) {
  return inactiveColumns.some((column) => !!column.category);
}

function groupInactiveColumns(inactiveColumns) {
  const groupedColumns = groupBy(inactiveColumns, "category");
  return Object.entries(
    groupedColumns
  ).sort(([groupNameA, _groupA], [groupNameB, _groupB]) =>
    stringComparator(groupNameA, groupNameB)
  );
}

CustomizeColumns.propTypes = {
  applyColumnConfig: PropTypes.func,
  closeDropdown: PropTypes.func,
  columnConfig: PropTypes.array,
  dataColumns: PropTypes.array,
};

export default CustomizeColumns;
