import { createContext, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/react-hooks";
import { get } from "lodash";
import analytics, { documentValues } from "helpers/analytics";
import { Frozen, UserContext } from "helpers/behaviors";
import { documentIsProcessing } from "helpers/documentHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";
import { PROJECT_VENDOR_SEARCH_QUERY } from "helpers/queries";
import { toaster } from "helpers/utilities";
import {
  DRAW_QUERY as notifyAssigneesDrawQuery,
  PROJECT_QUERY as notifyAssigneesProjectQuery,
} from "components/containers/NotifyDocumentAssignees";
import {
  ADD_GENERAL_CONTRACTOR_STAKEHOLDER,
  ADD_AGREEMENT_FROM_DOCUMENT,
  APPROVE_DOCUMENT,
  ASSIGN_DOCUMENTS_TO_USER,
  ASSIGN_LIEN_WAIVER,
  COMMENT,
  CORRECT,
  CREATE_AND_ASSIGN_LIEN_WAIVER,
  CREATE_INVOICE,
  CREATE_LIEN_WAIVER,
  CREATE_LINE_ITEM,
  DOCUMENT_PROCESSING_SUBSCRIPTION,
  DOCUMENT_QUERY,
  DRAW_QUERY,
  DRAWS_QUERY,
  IGNORE,
  MARK_DOCUMENT_PAID,
  MARK_DOCUMENT_UNPAID,
  MARK_REVIEWED,
  ORGANIZATION_QUERY,
  REMOVE,
  TRACKABLE_AGREEMENTS_QUERY,
  UNCONDITIONAL_WAIVER_QUERY,
  UNDO_APPROVE_DOCUMENT,
  UPDATE_AGREEMENT_FROM_DOCUMENT,
  UPDATE_HIDE_EXHAUSTED_AGREEMENTS,
  UPDATE_INVOICE,
  UPDATE_LIEN_WAIVER,
} from "./graphql";

export const DocumentContext = createContext({});

function InnerDocumentContextProvider({
  children,
  defaultGroup,
  documentId,
  documents,
  drawId,
  frozenError,
  frozenSubmit,
  hasPermission,
  history,
  match,
  projectId,
  refetch,
  refetchUserContextQuery,
}) {
  const documentQueryObject = {
    query: DOCUMENT_QUERY,
    variables: {
      documentId,
      projectId,
      drawDocumentIdsToExclude: [documentId],
    },
  };

  const notifyAssigneesQueryObject = {
    query: drawId ? notifyAssigneesDrawQuery : notifyAssigneesProjectQuery,
    variables: drawId ? { drawId, projectId } : { projectId },
  };
  const documentProcessingSubscription = useSubscription(
    DOCUMENT_PROCESSING_SUBSCRIPTION,
    {
      variables: { documentId },
    }
  );

  const documentQuery = useQuery(DOCUMENT_QUERY, documentQueryObject);

  useEffect(() => {
    if (documentQuery.data) return documentQuery.stopPolling();

    /* Excluding documentId from documentQuery.data.draw.documents list
    should fix the Apollo 2.x bug where a successful query
    has undefined in the query data return.  If this clause is not
    reached within a few weeks, we can probably remove it 5/4/24
    */
    if (
      !documentQuery.error &&
      !documentQuery.data &&
      !documentQuery.loading &&
      documentQuery.called
    ) {
      documentQuery.startPolling(3000);
      analytics.track("Document Query completed and returned no data");
    }

    return documentQuery.stopPolling;
  }, [documentQuery]);

  const drawsQuery = useQuery(DRAWS_QUERY, {
    variables: { projectId },
  });

  const [getDrawQuery, drawQuery] = useLazyQuery(DRAW_QUERY, {
    variables: { drawId },
  });

  const projectVendorsQuery = useQuery(ORGANIZATION_QUERY, {
    variables: { projectId },
  });

  const [getProjectVendorSearchQuery, projectVendorSearchQuery] = useLazyQuery(
    PROJECT_VENDOR_SEARCH_QUERY
  );

  const [
    getUnconditionalWaiverQuery,
    unconditionalWaiverQuery,
  ] = useLazyQuery(UNCONDITIONAL_WAIVER_QUERY, { variables: { projectId } });

  const [getAgreementsQuery, agreementsQuery] = useLazyQuery(
    TRACKABLE_AGREEMENTS_QUERY
  );

  const commentMutation = useMutation(COMMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [...refetch, documentQueryObject],
  });

  const correctMutationRefetchQueries = [
    documentQueryObject,
    ...refetch,
    { query: DRAWS_QUERY, variables: { projectId } },
    { query: ORGANIZATION_QUERY, variables: { projectId } },
  ];

  const [correct, correctResult] = useMutation(CORRECT, {
    onError: (error) =>
      frozenError(
        error,
        `There was an error saving ${get(
          documentQuery,
          "data.project.document.file.name"
        )}`
      ),
    awaitRefetchQueries: true,
    refetchQueries: correctMutationRefetchQueries,
  });

  const [
    updateInvoiceMutation,
    { loading: updateInvoiceLoading },
  ] = useMutation(UPDATE_INVOICE, {
    onError: (error) => {
      if (error.message.includes("vendor_id_mismatch")) {
        toaster.warning(
          t("documentWarnings.vendorMismatchTrackedAgreementAmounts"),
          { duration: 5 }
        );
      } else {
        frozenError(
          error,
          `There was an error saving ${get(
            documentQuery,
            "data.project.document.file.name"
          )}`
        );
      }
    },
    // Async refetching causes form values to reset before updating.
    // See: https://rabbet.atlassian.net/browse/RAB-3940
    // For the moment this seems like a hack.
    awaitRefetchQueries: false,
    refetchQueries: correctMutationRefetchQueries,
  });

  const [
    createInvoiceMutation,
    { loading: createInvoiceLoading },
  ] = useMutation(CREATE_INVOICE, {
    onError: (error) => {
      if (error.message.includes("vendor_id_mismatch")) {
        toaster.warning(
          t("documentWarnings.vendorMismatchTrackedAgreementAmounts"),
          { duration: 5 }
        );
      } else {
        frozenError(
          error,
          `There was an error saving ${get(
            documentQuery,
            "data.project.document.file.name"
          )}`
        );
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: correctMutationRefetchQueries,
  });

  const [
    createLienWaiverMutation,
    { loading: createLienWaiverLoading },
  ] = useMutation(CREATE_LIEN_WAIVER, {
    onError: (error) =>
      frozenError(
        error,
        `There was an error saving ${get(
          documentQuery,
          "data.project.document.file.name"
        )}`
      ),
    awaitRefetchQueries: true,
    refetchQueries: correctMutationRefetchQueries,
  });

  const [
    updateLienWaiverMutation,
    { loading: updateLienWaiverLoading },
  ] = useMutation(UPDATE_LIEN_WAIVER, {
    onError: (error) =>
      frozenError(
        error,
        `There was an error saving ${get(
          documentQuery,
          "data.project.document.file.name"
        )}`
      ),
    awaitRefetchQueries: true,
    refetchQueries: correctMutationRefetchQueries,
  });

  const [
    assignLienWaiverMutation,
    { loading: assignLienWaiverLoading },
  ] = useMutation(ASSIGN_LIEN_WAIVER, {
    onError: (error) =>
      frozenError(
        error,
        `There was an error saving ${get(
          documentQuery,
          "data.project.document.file.name"
        )}`
      ),
    awaitRefetchQueries: true,
    refetchQueries: correctMutationRefetchQueries,
  });

  const [
    createAndAssignLienWaiverMutation,
    { loading: createAndAssignLienWaiverLoading },
  ] = useMutation(CREATE_AND_ASSIGN_LIEN_WAIVER, {
    onError: (error) =>
      frozenError(
        error,
        `There was an error saving ${get(
          documentQuery,
          "data.project.document.file.name"
        )}`
      ),
    awaitRefetchQueries: true,
    refetchQueries: correctMutationRefetchQueries,
  });
  const [
    createAgreementMutation,
    { loading: createAgreementLoading },
  ] = useMutation(ADD_AGREEMENT_FROM_DOCUMENT, {
    onError: (error) => {
      const isAgreementNameTaken = error.message.includes(
        t("agreementsForm.agreementNameError")
      );
      const errorMessage = isAgreementNameTaken
        ? t("agreementsForm.agreementNameTaken")
        : `There was an error saving ${get(
            documentQuery,
            "data.project.document.file.name"
          )}`;
      frozenError(error, errorMessage);
    },
    awaitRefetchQueries: true,
    refetchQueries: correctMutationRefetchQueries,
  });
  const [
    updateAgreementMutation,
    { loading: updateAgreementLoading },
  ] = useMutation(UPDATE_AGREEMENT_FROM_DOCUMENT, {
    onError: (error) => {
      const isAgreementNameTaken = error.message.includes(
        t("agreementsForm.agreementNameError")
      );
      const errorMessage = isAgreementNameTaken
        ? t("agreementsForm.agreementNameTaken")
        : `There was an error saving ${get(
            documentQuery,
            "data.project.document.file.name"
          )}`;
      frozenError(error, errorMessage);
    },
    awaitRefetchQueries: true,
    refetchQueries: correctMutationRefetchQueries,
  });

  const [approve, approveResult] = useMutation(APPROVE_DOCUMENT, {
    awaitRefetchQueries: true,
    onError: frozenError,
    refetchQueries: [
      ...refetch,
      documentQueryObject,
      notifyAssigneesQueryObject,
    ],
    onCompleted: () => {
      analytics.track(
        "Document Approved",
        documentValues(get(documentQuery, "data.project.document"))
      );
    },
  });
  const [undoApprove, undoApproveResult] = useMutation(UNDO_APPROVE_DOCUMENT, {
    awaitRefetchQueries: true,
    onError: frozenError,
    refetchQueries: [...refetch, documentQueryObject],
  });
  const [markReviewed, markReviewedResult] = useMutation(MARK_REVIEWED, {
    onError: frozenError,
    refetchQueries: [...refetch, documentQueryObject],
    onCompleted: () => {
      analytics.track(
        "Document Marked As Reviewed",
        documentValues(get(documentQuery, "data.project.document"))
      );
    },
  });
  const [ignore, ignoreResult] = useMutation(IGNORE, {
    onError: frozenError,
    awaitRefetchQueries: true,
    refetchQueries: [...refetch, documentQueryObject],
  });
  const [remove, removeResult] = useMutation(REMOVE, {
    onError: frozenError,
    awaitRefetchQueries: true,
    refetchQueries: [...refetch],
  });
  const addGeneralContractorMutation = useMutation(
    ADD_GENERAL_CONTRACTOR_STAKEHOLDER
  );

  const [markPaid, { loading: markDocumentPaidLoading }] = useMutation(
    MARK_DOCUMENT_PAID,
    {
      awaitRefetchQueries: true,
      onError: frozenError,
      refetchQueries: [
        ...refetch,
        documentQueryObject,
        notifyAssigneesQueryObject,
      ],
    }
  );
  const [markUnpaid, { loading: markDocumentUnpaidLoading }] = useMutation(
    MARK_DOCUMENT_UNPAID,
    {
      awaitRefetchQueries: true,
      onError: frozenError,
      refetchQueries: [
        ...refetch,
        documentQueryObject,
        notifyAssigneesQueryObject,
      ],
    }
  );

  const assignUserToDocumentsMutation = useMutation(ASSIGN_DOCUMENTS_TO_USER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      ...refetch,
      documentQueryObject,
      notifyAssigneesQueryObject,
    ],
  });
  const [updateHideExhaustedAgreements] = useMutation(
    UPDATE_HIDE_EXHAUSTED_AGREEMENTS,
    {
      onCompleted: refetchUserContextQuery,
    }
  );

  const [
    createLineItem,
    { data: createLineItemData, loading: createLineItemLoading },
  ] = useMutation(CREATE_LINE_ITEM);

  const approveMutation = [frozenSubmit(approve), approveResult];
  const correctMutation = [frozenSubmit(correct), correctResult];
  const createInvoice = frozenSubmit(createInvoiceMutation);
  const createLienWaiver = frozenSubmit(createLienWaiverMutation);
  const updateInvoice = frozenSubmit(updateInvoiceMutation);
  const updateLienWaiver = frozenSubmit(updateLienWaiverMutation);
  const assignLienWaiver = frozenSubmit(assignLienWaiverMutation);
  const createAndAssignLienWaiver = frozenSubmit(
    createAndAssignLienWaiverMutation
  );
  // todo   do we want to be frozen? only if ECO w/ adjustment?
  const createAgreement = createAgreementMutation;
  const updateAgreement = updateAgreementMutation;
  const ignoreMutation = [frozenSubmit(ignore), ignoreResult];
  const markReviewedMutation = [frozenSubmit(markReviewed), markReviewedResult];
  const undoApproveMutation = [frozenSubmit(undoApprove), undoApproveResult];
  const removeMutation = [frozenSubmit(remove), removeResult];

  const document = get(documentQuery, "data.project.document", null);
  const agreementVendorLineItems = hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  )
    ? get(projectVendorsQuery, "data.project.agreementVendorLineItems", [])
    : [];
  const disableWarnings = !get(
    drawsQuery,
    "data.project.documentWarningsEnabled",
    true
  );
  const draws = get(drawsQuery, "data.project.draws", []);
  const draw = get(drawQuery, "data.draw", {});
  const invoiceNumbersByVendor = get(
    drawsQuery,
    "data.project.invoiceNumbersByVendor",
    []
  );
  const processingDocument = get(
    documentProcessingSubscription,
    "data.documentUpdated"
  );

  const isProcessingDocument =
    processingDocument && processingDocument.id === get(document, "id")
      ? processingDocument
      : document;

  const jobCostCodes = get(
    projectVendorsQuery,
    "data.project.organization.jobCostCodes",
    []
  );
  const lineItems = get(drawsQuery, "data.project.lineItems", []);

  const [openLineItemDrawer, setOpenLineItemDrawer] = useState(false);

  const projectRules = get(drawsQuery, "data.project.rules", []);
  const documentReviewers = get(
    drawsQuery,
    "data.project.documentReviewers",
    []
  );
  const searchedVendors = get(
    projectVendorSearchQuery,
    "data.project.organization.paginatedVendors.results",
    []
  );
  const users = get(projectVendorsQuery, "data.project.users", []);
  const vendors = get(projectVendorsQuery, "data.project.vendors", []);
  const isProcessing = documentIsProcessing(isProcessingDocument);

  const contextMutations = {
    addGeneralContractorMutation,
    approveMutation,
    assignLienWaiver,
    assignLienWaiverLoading,
    assignUserToDocumentsMutation,
    commentMutation,
    correctMutation,
    createAgreement,
    createAgreementLoading,
    createAndAssignLienWaiver,
    createAndAssignLienWaiverLoading,
    createInvoice,
    createInvoiceLoading,
    createLienWaiver,
    createLienWaiverLoading,
    createLineItem,
    createLineItemLoading,
    createLineItemData,
    ignoreMutation,
    markReviewedMutation,
    markPaid,
    markUnpaid,
    markDocumentPaidLoading,
    markDocumentUnpaidLoading,
    removeMutation,
    undoApproveMutation,
    updateAgreement,
    updateAgreementLoading,
    updateHideExhaustedAgreements,
    updateInvoice,
    updateInvoiceLoading,
    updateLienWaiver,
    updateLienWaiverLoading,
  };

  const contextQueries = {
    agreementsQuery,
    documentProcessingSubscription,
    drawQuery,
    drawsQuery,
    fetchDraw: getDrawQuery,
    fetchDrawLoading: drawQuery.loading,
    fetchDocument: documentQuery?.refetch || (() => {}),
    getAgreementsQuery,
    getProjectVendorSearchQuery,
    getUnconditionalWaiverQuery,
    projectVendorSearchQuery,
    projectVendorsQuery,
    unconditionalWaiverQuery,
  };

  const contextData = {
    agreementVendorLineItems,
    defaultGroup,
    disableWarnings,
    document,
    documents,
    documentReviewers,
    draw,
    drawId,
    draws,
    history,
    invoiceNumbersByVendor,
    isProcessing,
    jobCostCodes,
    lineItems,
    match,
    openLineItemDrawer,
    projectId,
    projectRules,
    searchedVendors,
    users,
    vendors,
  };

  const contextBehavior = {
    setOpenLineItemDrawer,
  };

  return (
    <DocumentContext.Provider
      value={{
        ...contextBehavior,
        ...contextData,
        ...contextMutations,
        ...contextQueries,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
}

export function DocumentContextProvider({
  children,
  defaultGroup,
  documents,
  history,
  match,
  refetch,
}) {
  const { documentId, drawId, lineItemId, projectId } = match.params;
  const { hasPermission, refetchUserContextQuery } = useContext(UserContext);

  return (
    <Frozen projectId={projectId}>
      {({ frozenError, frozenSubmit }) => (
        <InnerDocumentContextProvider
          defaultGroup={defaultGroup}
          documentId={documentId}
          documents={documents}
          drawId={drawId}
          frozenError={frozenError}
          frozenSubmit={frozenSubmit}
          hasPermission={hasPermission}
          history={history}
          lineItemId={lineItemId}
          match={match}
          projectId={projectId}
          refetch={refetch}
          refetchUserContextQuery={refetchUserContextQuery}
        >
          {children}
        </InnerDocumentContextProvider>
      )}
    </Frozen>
  );
}

DocumentContextProvider.propTypes = {
  defaultGroup: PropTypes.object,
  documents: PropTypes.array,
};

DocumentContextProvider.defaultProps = {
  defaultGroup: {},
  documents: [],
};
