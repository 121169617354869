import { Fragment, useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { set, isEmpty, values } from "lodash";
import { Button, Confirm } from "components/materials";
import {
  PostPaymentModal,
  ResetPaymentModal,
  PostPaymentTable,
  PostPaymentActions,
} from "components/templates";
import { PaymentsContext } from "contexts/paymentsContext";
import { NavigationWarnings } from "helpers/behaviors";
import { aggregateDocumentApprovalsData } from "helpers/documentHelpers";
import { VENDOR_PAYMENT_TYPE } from "helpers/enums";
import t from "helpers/translate";

function getInitialValues(documents) {
  return {
    payments: documents.reduce((acc, document) => {
      acc[document.id] = document.vendorPaymentType;
      return acc;
    }, {}),
  };
}

function getVendorPaymentTypeOptions(types) {
  const vendorPaymentTypes =
    types ||
    values(VENDOR_PAYMENT_TYPE).filter(
      (vendorPaymentType) =>
        vendorPaymentType !==
        VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_DIRECT_PAY_SEND_TO_GC
    );
  return vendorPaymentTypes.map((name) => ({
    key: name,
    text: t(`paymentIntegration.avidVendorPaymentType.${name}`),
    selectedText: t(
      `paymentIntegration.truncatedVendorPaymentType.${name}`,
      t(`paymentIntegration.avidVendorPaymentType.${name}`)
    ),
    value: name,
  }));
}

function validate(values, documents) {
  const errors = {};

  Object.keys(values.payments).forEach((id) => {
    const document = documents.find((document) => id === document.id);

    if (document && !document.canBePosted) {
      set(
        errors,
        `payments.${id}`,
        t("paymentIntegration.cannotDirectPayVendor")
      );
    }
  });

  return errors;
}

const tableConfig = {
  columnConfig: [
    "document",
    "documentNumber",
    "vendorName",
    "documentedAmount",
    "approval",
    "vendorPaymentType",
    "paymentAmount",
    "postedAt",
    "status",
    "action",
  ],
  filterConfig: [],
  groupConfig: {},
  sortConfig: {},
};

export function PostPaymentForm({
  confirmationMessage,
  handleSubmit,
  accountsPayableSystem,
  onSubmitLoading,
  vendorPaymentTypes,
}) {
  const {
    documentsToPost,
    documentsToReset,
    documentReviewers,
    getDocuments,
    mutationLoading,
    setDocumentsToReset,
    setDocumentsToPost,
    resetPayments,
    saveVendorPaymentTypes,
  } = useContext(PaymentsContext);

  const [unapprovedDocumentsToPost, setUnapprovedDocumentsToPost] = useState(
    []
  );

  useEffect(() => {
    const unapprovedDocumentsToPost = documentsToPost.filter((document) => {
      const { approvedCount } = aggregateDocumentApprovalsData(
        document,
        documentReviewers
      );
      return approvedCount === 0;
    });
    setUnapprovedDocumentsToPost(unapprovedDocumentsToPost);
  }, [documentsToPost, documentReviewers]);

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(getDocuments([]))}
      onSubmit={handleSubmit}
      validate={(values) => validate(values, documentsToPost)}
    >
      {(formikProps) => {
        const documents = getDocuments(formikProps.values.payments);

        function handlePost(documents) {
          const errors = validate(formikProps.values, documents);
          if (isEmpty(errors)) {
            setDocumentsToPost(documents);
          } else {
            formikProps.setTouched(formikProps.values.payments);
          }
        }

        return (
          <Fragment>
            <NavigationWarnings dirty={formikProps.dirty} />
            <PostPaymentActions
              documents={documents}
              isDirty={formikProps.dirty}
              onPost={() => {
                handlePost(documents);
              }}
              onReset={() => setDocumentsToReset(documents)}
              onSavePaymentTypes={() =>
                saveVendorPaymentTypes(formikProps.values.payments)
              }
              onUndo={formikProps.handleReset}
            />
            <PostPaymentTable
              actionColumnFormatter={(_, document) => {
                if (document.postedAt) {
                  return (
                    <Button
                      onClick={() => {
                        setDocumentsToReset([document]);
                      }}
                    >
                      Reset
                    </Button>
                  );
                }
                return (
                  <Button
                    disabled={!document.canBePosted}
                    onClick={() => {
                      handlePost([document]);
                    }}
                  >
                    Post
                  </Button>
                );
              }}
              documents={documents}
              documentReviewers={documentReviewers}
              tableConfig={tableConfig}
              vendorPaymentTypeOptions={getVendorPaymentTypeOptions(
                vendorPaymentTypes
              )}
            />
            {unapprovedDocumentsToPost.length > 0 && (
              <Confirm
                cancelLabel="Cancel"
                confirmLabel="Post Anyway"
                content={t("paymentIntegration.unapprovedDocuments.warning", {
                  count: unapprovedDocumentsToPost.length,
                })}
                header={t(
                  "paymentIntegration.unapprovedDocuments.warningHeader",
                  {
                    count: unapprovedDocumentsToPost.length,
                  }
                )}
                open
                onCloseComplete={() => {
                  setDocumentsToPost([]);
                  setUnapprovedDocumentsToPost([]);
                }}
                onConfirm={() => setUnapprovedDocumentsToPost([])}
              />
            )}
            {documentsToPost.length > 0 &&
              unapprovedDocumentsToPost.length === 0 && (
                <PostPaymentModal
                  confirmationMessage={confirmationMessage}
                  onClose={() => {
                    setDocumentsToPost([]);
                  }}
                  accountsPayableSystem={accountsPayableSystem}
                  onPost={formikProps.handleSubmit}
                  loading={onSubmitLoading}
                />
              )}
            {documentsToReset.length > 0 && (
              <ResetPaymentModal
                handleReset={resetPayments}
                loading={mutationLoading}
                onClose={() => setDocumentsToReset([])}
                resetWarningText={t("paymentIntegration.avidResetWarning", {
                  count: documentsToReset.length,
                })}
              />
            )}
          </Fragment>
        );
      }}
    </Formik>
  );
}
