import { useContext } from "react";
import { HelpIcon } from "evergreen-ui";
import { Heading, Pane, Paragraph, Tooltip } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { add, divide, subtract } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import t from "helpers/translate";

export function AgreementValue({ name, tooltip, value, width }) {
  return (
    <Pane paddingX={minorScale(3)} paddingY={majorScale(1)} width={width}>
      <Pane display="flex" alignItems="center">
        <Heading size={200} textTransform="uppercase">
          {name}
        </Heading>
        {tooltip && (
          <Tooltip content={tooltip}>
            <HelpIcon
              color="default"
              marginLeft={minorScale(1)}
              size={minorScale(3)}
            />
          </Tooltip>
        )}
      </Pane>
      <Paragraph size={400}>{value}</Paragraph>
    </Pane>
  );
}

export function AgreementsPageAggregates({ project }) {
  const theme = useContext(ThemeContext);
  const {
    amount,
    committedAmount,
    contingencyAdjustmentsAmount,
    contingencyOriginalAmount,
    exposedAmount,
    agreementVendorLineItems,
  } = project;
  const percentBoughtOut = divide(committedAmount, amount);
  const uncommittedAmount = subtract(amount, committedAmount);
  const contingency = add(
    contingencyOriginalAmount,
    contingencyAdjustmentsAmount
  );
  const totalAnticipated = add(committedAmount, exposedAmount);
  const anticipatedUncommitted = subtract(amount, totalAnticipated);
  const hasExposures = agreementVendorLineItems.some(
    ({ pendingAgreementsCount }) => pendingAgreementsCount > 0
  );

  return (
    <Pane
      display="flex"
      alignItems="center"
      paddingX={minorScale(3)}
      paddingY={majorScale(2)}
    >
      <AgreementValue name="Current Budget" value={formatCurrency(amount)} />
      <AgreementValue
        name="Percent Bought Out"
        tooltip={t("agreementsPage.percentBoughtTooltip")}
        value={formatPercent(percentBoughtOut)}
      />
      <AgreementValue
        name="Total Committed"
        tooltip={t("agreementsPage.totalCommittedTooltip")}
        value={formatCurrency(committedAmount)}
      />
      <AgreementValue
        name="Total Uncommitted"
        tooltip={t("agreementsPage.totalUncommittedTooltip")}
        value={formatCurrency(uncommittedAmount)}
      />
      <AgreementValue
        name="Total Contingency"
        tooltip={t("agreementsPage.totalContingencyTooltip")}
        value={formatCurrency(contingency)}
      />
      {hasExposures && (
        <Pane
          backgroundColor={theme.colors.lightPurple}
          borderRadius={minorScale(1)}
          display="inline-flex"
        >
          <AgreementValue
            name="Total PCOs & Exposures"
            tooltip={t("agreementsPage.totalPendingTooltip")}
            value={formatCurrency(exposedAmount)}
          />
          <AgreementValue
            name="Total Anticipated"
            tooltip={t("agreementsPage.totalAnticipatedTooltip")}
            value={formatCurrency(totalAnticipated)}
          />
          <AgreementValue
            name="Anticipated Uncommitted"
            tooltip={t("agreementsPage.anticipatedUncommittedTooltip")}
            value={formatCurrency(anticipatedUncommitted)}
          />
        </Pane>
      )}
    </Pane>
  );
}
