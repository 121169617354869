import { find, get, uniqBy } from "lodash";
import { add, multiply, subtract } from "helpers/math";
import { parseCurrencyToFloat } from "helpers/parseCurrencyToFloat";

export function getFullLineItemName(
  { division, name },
  excludeDivision = false
) {
  return excludeDivision
    ? name
    : `${name} ${division.name ? `(${division.name})` : ""}`;
}

export function getMatch(drawLineItems, lineItem) {
  const items = drawLineItems.map((drawLineItem) => ({
    ...drawLineItem,
    fullName: getFullLineItemName(drawLineItem),
  }));
  return find(items, (item) => {
    return get(lineItem, "lineItemObject", null)
      ? item.id === lineItem.lineItemObject.drawLineItemId
      : item.id === lineItem.drawLineItemId;
  });
}

export const matchLineItem = (drawLineItems) => (lineItem) => {
  const match = getMatch(drawLineItems, lineItem);
  const lineItemObject = match
    ? {
        drawLineItemId: match.id,
        requestNumber: match.fullName,
      }
    : { drawLineItemId: null, requestNumber: null };
  return {
    amountsCompleted: get(lineItem, "amountsCompleted"),
    hudApprovedAmount: get(lineItem, "hudApprovedAmount"),
    lineItemObject,
  };
};

export function getEmptyLineItem(lineItemIndex) {
  return {
    amountsCompleted: null,
    hudApprovedAmount: null,
    lineItemIndex,
    lineItemObject: {
      requestNumber: null,
      drawLineItemId: null,
    },
  };
}

export function getAmountToUse(lineItem) {
  // if NO hudApprovedAmount, we use amountsCompleted and vice versa
  return lineItem.hudApprovedAmount === null ||
    parseCurrencyToFloat(lineItem.hudApprovedAmount) === 0
    ? lineItem.amountsCompleted
    : lineItem.hudApprovedAmount;
}

export function getTotalAmount(hudLineItems) {
  return hudLineItems?.reduce((total, lineItem) => {
    return add(total, getAmountToUse(lineItem));
  }, 0);
}

export function getMatchedLineItems(hudLineItems, drawLineItems) {
  return hudLineItems?.map((hudLineItem) => {
    const match = getMatch(drawLineItems, hudLineItem);
    return !match
      ? { ...hudLineItem, ...match, retainagePercentage: 0 }
      : { ...hudLineItem, ...match };
  });
}

export function getLessPreviousPayments(matchedLineItems) {
  const uniqueMatchedLineItems = uniqBy(matchedLineItems, "drawLineItemId");

  return uniqueMatchedLineItems.reduce((total, lineItem) => {
    return !lineItem ? total : add(total, lineItem.requestedPreviouslyAmount);
  }, 0);
}

export function getLessRetainedAmount(matchedLineItems) {
  return matchedLineItems?.reduce((total, lineItem) => {
    const lineItemRetainedAmount = multiply(
      lineItem.retainagePercentage,
      getAmountToUse(lineItem)
    );
    return add(total, lineItemRetainedAmount);
  }, 0);
}

export function getTotalAmountDueToDate(totalAmount, lessRetainedAmount) {
  return subtract(totalAmount, lessRetainedAmount);
}

export function getDocumentGrossAmount(totalAmount, lessPreviousPayments) {
  return subtract(totalAmount, lessPreviousPayments);
}

// This would also be the document's net amount
export function getAmountDueThisRequisition(
  totalAmount,
  lessPreviousPayments,
  lessRetained
) {
  return subtract(totalAmount, lessPreviousPayments, lessRetained);
}
