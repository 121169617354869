import { Fragment, useState } from "react";
import { CrossIcon } from "evergreen-ui";
import {
  Button,
  IconButton,
  Pane,
  Paragraph,
  RadioGroup,
  Spinner,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { uploadFile } from "images";
import t from "helpers/translate";

export default function Dropzone({
  disabled = false,
  uploadTypeButton = "Files",
  uploadTypeMain = "document",
  disableSplit = false,
  containerProps = {},
  textProps = { marginLeft: majorScale(8) },
  dropzone,
  restrictionMessage = "",
}) {
  return (
    <Fragment>
      <div {...dropzone.getRootProps()}>
        <Pane border="default" marginBottom={majorScale(2)}>
          <input {...dropzone.getInputProps()} />
          <Pane
            alignItems="center"
            display="flex"
            justifyContent="center"
            paddingX={majorScale(2)}
            paddingY={majorScale(5)}
            {...containerProps}
          >
            <Pane is="img" src={uploadFile} maxHeight={majorScale(15)} />
            <Pane {...textProps}>
              <Paragraph size={400} textAlign="center">
                {t("dropzone.mainText", { type: uploadTypeMain })}
              </Paragraph>
              <Pane
                display="flex"
                justifyContent="center"
                marginTop={minorScale(1)}
              >
                <Button appearance="primary" disabled={disabled}>
                  {t("dropzone.buttonText", { type: uploadTypeButton })}
                </Button>
              </Pane>
              {restrictionMessage && (
                <Pane
                  marginTop={minorScale(1)}
                  display="flex"
                  justifyContent="center"
                >
                  <Paragraph size={300} color="muted">
                    {restrictionMessage}
                  </Paragraph>
                </Pane>
              )}
            </Pane>
          </Pane>
        </Pane>
      </div>
      <FileList
        files={dropzone.files}
        disableSplit={disableSplit}
        onEdit={dropzone.onEdit}
        onRemove={dropzone.onRemove}
      />
    </Fragment>
  );
}

function FileList({ files, disableSplit, onEdit, onRemove }) {
  if (files.length === 0) return null;

  return (
    <Fragment>
      <Pane display="flex">
        <Pane width={460} />
        <Paragraph paddingX={5} textAlign="center" width={80}>
          {t("dropzone.pagesLabel")}
        </Paragraph>
        {!disableSplit && (
          <Fragment>
            <Paragraph textAlign="center" width={80}>
              {t("dropzone.singleDocumentLabel")}
            </Paragraph>
            <Paragraph textAlign="center" width={80}>
              {t("dropzone.splitDocumentLabel")}
            </Paragraph>
          </Fragment>
        )}
      </Pane>
      {files.map((file, index) => (
        <FileRow
          file={file}
          index={index}
          disableSplit={disableSplit}
          key={`${index}${file.name}`}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ))}
    </Fragment>
  );
}

function FileRow({ file, index, disableSplit, onEdit, onRemove }) {
  const [documentSplitOption, setDocumentSplitOption] = useState(
    "splitDisabled"
  );

  return (
    <Pane display="flex" alignItems="center">
      <Pane display="flex" justifyContent="center" width={50}>
        <IconButton
          appearance="minimal"
          icon={CrossIcon}
          onClick={() => onRemove(index)}
          type="button"
        />
      </Pane>
      <Paragraph width={410}>{file.name}</Paragraph>
      {onEdit && (
        <Fragment>
          <Pane textAlign="center" width={80}>
            {file.loading ? (
              <Pane display="flex" justifyContent="center">
                <Spinner size={24} />
              </Pane>
            ) : (
              <Text>{file.numberOfPages}</Text>
            )}
          </Pane>
          {!disableSplit && !file.loading && (
            <Pane width={160}>
              <RadioGroup
                display="flex"
                justifyContent="space-around"
                value={documentSplitOption}
                options={[
                  {
                    value: "splitDisabled",
                    isDisabled: file.disableSplit,
                  },
                  {
                    value: "split",
                    isDisabled: file.disableSplit,
                  },
                ]}
                onChange={(event) => {
                  const { value } = event.target;
                  setDocumentSplitOption(value);
                  onEdit(index, "split", value === "split");
                }}
              />
            </Pane>
          )}
        </Fragment>
      )}
    </Pane>
  );
}
