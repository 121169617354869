import PropTypes from "prop-types";
import Box from "ui-box";
import { withTheme } from "evergreen-ui";
import { boxProps, boxPropTypes } from "components/materials/utils";
import { getProps, compose, withMemo } from "./utils";

const TableBody = compose(
  (props) => {
    const { children } = props;

    return (
      <Box is="tbody" {...boxProps(props)}>
        {children}
      </Box>
    );
  },
  [withMemo, withTheme]
);

export const propTypes = {
  ...boxPropTypes,
  children: PropTypes.node,
};

TableBody.propTypes = propTypes;

// TODO: remove dot notation
TableBody.getProps = (props) => getProps(propTypes, props);

TableBody.defaultProps = {};

export default TableBody;
