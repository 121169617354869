import { Fragment, useContext } from "react";
import { Pane, Alert, Text } from "components/materials";
import { uniqBy } from "lodash";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { GUIDING_EXPLANATION_SCOPES } from "helpers/issues";
import { validRuleFailed } from "helpers/ruleHelpers";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import {
  SlideoutLineItemIssues,
  SuperLineItemIssuesAccordion,
} from "components/templates";

export default function Issues({ lineItem, isSuperLineItem }) {
  const { issues, rules } = lineItem;
  const errors = uniqBy(rules, "name").filter(validRuleFailed);
  const { hasPermission } = useContext(UserContext);
  const hasErrors = errors.length > 0;

  if (!hasErrors && issues.length === 0) return <Text>No issues</Text>;

  return (
    <Fragment>
      {errors.map((rule) => {
        // Check to see if there's an existing "FAILED_LINE_ITEM" version of message,
        // if not, fall back to the "FAILED" version of the message.
        const fallbackMessage = t(`drawChecks.${rule.name}.${rule.state}`);

        return (
          <Alert intent="danger" key={rule.id} marginBottom={majorScale(1)}>
            <Text>
              {t(
                `drawChecks.${rule.name}.${rule.state}LineItem`,
                fallbackMessage
              )}
            </Text>
          </Alert>
        );
      })}
      {hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL) && (
        <Pane marginY={hasErrors ? majorScale(2) : 0}>
          {isSuperLineItem ? (
            <SuperLineItemIssuesAccordion
              scope={GUIDING_EXPLANATION_SCOPES.DRAW_LINE_ITEM}
              lineItem={lineItem}
            />
          ) : (
            <SlideoutLineItemIssues
              lineItem={lineItem}
              showBlankSlate={false}
              scope={GUIDING_EXPLANATION_SCOPES.DRAW_LINE_ITEM}
            />
          )}
        </Pane>
      )}
    </Fragment>
  );
}
