import { get, merge, omit } from "lodash";
import { parse, stringify } from "querystring";

export const mergeSearch = (history, searchObject) => {
  const oldSearch = history.location.search.slice(1);
  const search = getMergeSearch(history, searchObject);
  if (oldSearch !== search) {
    history.replace({ ...history.location, search });
  }
};

export const getMergeSearch = (history, searchObject) => {
  const oldSearch = history.location.search.slice(1);
  const oldSearchObject = parse(oldSearch);
  return stringify(merge(oldSearchObject, searchObject));
};

export const removeKey = (history, key) => {
  const oldSearch = history.location.search.slice(1);
  const oldSearchObject = parse(oldSearch);
  const search = stringify(omit(oldSearchObject, [key]));
  history.replace({ ...history.location, search });
};

export const getSearchByKey = (history, key) =>
  get(parse(history.location.search.slice(1)), key);
