import { memo } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

export { boxProps, getProps } from "components/materials/utils";

export const coordinatesShape = PropTypes.shape({
  x: PropTypes.number,
  y: PropTypes.number,
});

export const compose = (component, functions) => {
  return functions.reduce((composedComponent, fn) => {
    return fn(composedComponent);
  }, component);
};

export const withMemo = (component) => {
  return memo(component);
};

export const withStrictMemo = (component) => {
  return memo(component, (oldProps, newProps) => {
    if (newProps.renderOnChanged) {
      return oldProps.renderOnChanged === newProps.renderOnChanged;
    }

    return isEqual(oldProps, newProps);
  });
};
