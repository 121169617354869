import {
  compareAsc,
  DATE_RANGE,
  endOfDay,
  formatDate,
  formatDateTimeAsDate,
  getDateInterval,
  isAtOrBefore,
  isValid,
  isWithinInterval,
  parseDate,
  startOfDay,
} from "helpers/dateHelpers";
import { identity } from "lodash";
import { DateFilter } from "./DateFilter";

export const DATE_RANGE_OPTIONS = {
  [DATE_RANGE.Today]: "Today",
  [DATE_RANGE.Yesterday]: "Yesterday",
  [DATE_RANGE.LastXDays]: "Last Days",
  [DATE_RANGE.PreviousXWeeks]: "Previous Weeks",
  [DATE_RANGE.PreviousXMonths]: "Previous Months",
  [DATE_RANGE.PreviousXYears]: "Previous Years",
  [DATE_RANGE.PreviousXQuarters]: "Previous Quarters",
  [DATE_RANGE.WeekToDate]: "Week To Date",
  [DATE_RANGE.MonthToDate]: "Month To Date",
  [DATE_RANGE.YearToDate]: "Year To Date",
  [DATE_RANGE.QuarterToDate]: "Quarter To Date",
  [DATE_RANGE.Since]: "Since Date",
  [DATE_RANGE.NextXDays]: "Next Days",
  [DATE_RANGE.NextXWeeks]: "Next Weeks",
  [DATE_RANGE.NextXMonths]: "Next Months",
  [DATE_RANGE.NextXQuarters]: "Next Quarters",
  [DATE_RANGE.NextXYears]: "Next Years",
  [DATE_RANGE.CustomRange]: "Custom Range",
};

export const dateColumnDefaults = {
  aggregate: () => null,
  aggregateFormatter: identity,
  filterControl: (index) => <DateFilter fieldPath={`filters[${index}]`} />,
  filterFormatter: (fc, column) => {
    const valueText = (() => {
      switch (fc.type) {
        // "legacy" values from saved views are custom ranges w/o a type defined
        case undefined:
        case DATE_RANGE.CustomRange:
          return `${formatDate(fc.start)} - ${formatDate(fc.finish)}`;
        case DATE_RANGE.LastXDays:
          return fc.value === "1" ? "Last Day" : `Last ${fc.value} Days`;
        case DATE_RANGE.PreviousXWeeks:
          return fc.value === "1"
            ? "Previous Week"
            : `Previous ${fc.value} Weeks`;
        case DATE_RANGE.PreviousXMonths:
          return fc.value === "1"
            ? "Previous Month"
            : `Previous ${fc.value} Months`;
        case DATE_RANGE.PreviousXYears:
          return fc.value === "1"
            ? "Previous Year"
            : `Previous ${fc.value} Years`;
        case DATE_RANGE.PreviousXQuarters:
          return fc.value === "1"
            ? "Previous Quarter"
            : `Previous ${fc.value} Quarters`;
        case DATE_RANGE.NextXDays:
          return fc.value === "1" ? "Next Day" : `Next ${fc.value} Days`;
        case DATE_RANGE.NextXWeeks:
          return fc.value === "1" ? "Next Week" : `Next ${fc.value} Weeks`;
        case DATE_RANGE.NextXMonths:
          return fc.value === "1" ? "Next Month" : `Next ${fc.value} Months`;
        case DATE_RANGE.NextXQuarters:
          return fc.value === "1"
            ? "Next Quarter"
            : `Next ${fc.value} Quarters`;
        case DATE_RANGE.NextXYears:
          return fc.value === "1" ? "Next Year" : `Next ${fc.value} Years`;
        case DATE_RANGE.Since:
          return `Since ${formatDate(fc.value)}`;
        default:
          return DATE_RANGE_OPTIONS[fc.type];
      }
    })();

    return `${column.header}: ${valueText}`;
  },
  filterStrategy: (value, fc) => {
    const parsedValue = startOfDay(parseDate(value));

    if (!isValid(parsedValue)) return false;

    switch (fc.type) {
      // "legacy" values from saved views are custom ranges w/o a type defined
      case undefined:
      case DATE_RANGE.CustomRange:
        return isWithinInterval(parsedValue, {
          start: startOfDay(fc.start),
          end: endOfDay(fc.finish),
        });
      case DATE_RANGE.Since:
        return isAtOrBefore(parseDate(fc.value), parsedValue);
      default:
        return isWithinInterval(
          parsedValue,
          getDateInterval(fc.type, fc.value)
        );
    }
  },
  sortStrategy: (value1, value2) => {
    if (!value1) return 1;
    if (!value2) return -1;

    return compareAsc(value1, value2);
  },
  type: "date",
  valueFormatter: (value) => formatDate(value),
  width: 100,
};

export const dateTimeColumnDefaults = {
  ...dateColumnDefaults,
  valueFormatter: formatDateTimeAsDate,
};
