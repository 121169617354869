import { Text, Tooltip } from "components/materials";
import { formatDateTime } from "helpers/dateHelpers";
import { Position } from "helpers/utilities";

export function PostPaymentTableStatus({ document, isPosted }) {
  return (
    <Tooltip
      content={
        isPosted
          ? `Posted at ${formatDateTime(document.postedAt)}`
          : "Document has not been posted or was reset"
      }
      position={Position.TOP}
    >
      <Text size={200}>{isPosted ? "Posted" : "Unposted"}</Text>
    </Tooltip>
  );
}
