import { Fragment } from "react";
import PropTypes from "prop-types";
import { HelpIcon } from "evergreen-ui";
import { Form, Pane, Text, Tooltip } from "components/materials";
import t from "helpers/translate";
import { majorScale, minorScale } from "helpers/utilities";

export default function IsBackupCheckbox({ isDeveloper }) {
  return (
    <Pane display="flex" alignItems="center" marginTop={minorScale(2)}>
      <Form.Checkbox
        name="isBackup"
        fieldProps={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: majorScale(2),
        }}
        label={
          <Fragment>
            <Text>{t("documentReview.doNotApply")}</Text>
            <Tooltip
              content={
                isDeveloper
                  ? t("documentReview.doNotApplyInfoDeveloper")
                  : t("documentReview.doNotApplyInfoLender")
              }
            >
              <HelpIcon size={minorScale(3)} marginLeft={minorScale(2)} />
            </Tooltip>
          </Fragment>
        }
      />
    </Pane>
  );
}

IsBackupCheckbox.propTypes = {
  isDeveloper: PropTypes.bool,
};
