import { useContext, useState, useEffect } from "react";
import { Link, Pane, Paragraph, Text } from "components/materials";
import { ThemeContext, majorScale, minorScale } from "helpers/utilities";
import { AddIcon, DotIcon } from "evergreen-ui";
import FormField from "../FormField";
import Combobox from "./RabbetCombobox";

// this component exists for use with "performik"
// it is used by the formik-connected `Select` wrapper, and is hardly ever called directly

export function InnerSelect({
  name,
  options,
  value,
  form,
  formValue,
  label,
  labelProps,
  disabled,
  isHighlighted,
  hasError,
  error,
  isWarned,
  isLoading,
  hasSuggestions,
  noNull,
  onAddItem,
  addName,
  onChange,
  placeholder,
  onFilter,
  popoverMinWidth,
  fullBorder,
  testId,
  outerProps,
  textProps,
  height,
  isRequired,
  comboboxProps,
  confirmSelect,
}) {
  const theme = useContext(ThemeContext);
  const [filter, setFilter] = useState("");

  // Only call the `onFilter` function when the filter input change to prevent infinity loop
  // Chris note: I don't know what this means but I'm leaving it in place
  useEffect(() => {
    if (onFilter) {
      onFilter(filter);
    }
  }, [filter, onFilter]);

  const addItemProps =
    !formValue && isHighlighted
      ? {
          backgroundColor: theme.colors.lightBlue,
          boxShadow: theme.boxShadowHighlighted,
          padding: minorScale(1),
        }
      : {};

  return (
    <FormField
      isHighlighted={
        formValue !== null && formValue !== undefined && isHighlighted
      }
      isWarned={isWarned}
      label={
        label && (
          <Paragraph
            {...labelProps}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Pane display="flex" alignItems="center">
              {isRequired && <DotIcon color="danger" />}
              <Paragraph {...labelProps}>{label}</Paragraph>
            </Pane>
            {onAddItem && (
              <Pane display="flex" alignItems="center">
                <Link
                  onClick={() => (disabled ? null : onAddItem(filter))}
                  cursor={disabled ? "not-allowed" : "pointer"}
                  display="flex"
                  alignItems="center"
                  purpose="form add-item"
                  {...addItemProps}
                >
                  <AddIcon
                    size={12}
                    marginRight={majorScale(1)}
                    color={disabled ? "disabled" : "info"}
                  />
                  <Text size={300}>{addName}</Text>
                </Link>
              </Pane>
            )}
          </Paragraph>
        )
      }
      validationMessage={hasError && error}
      {...outerProps}
    >
      <Combobox
        openOnFocus
        name={name}
        items={options}
        value={value}
        onChange={onChange}
        form={form}
        formValue={formValue}
        disabled={disabled}
        isLoading={isLoading}
        hasSuggestions={hasSuggestions}
        noNull={noNull}
        onAddItem={onAddItem}
        addName={addName}
        placeholder={placeholder}
        popoverMinWidth={popoverMinWidth}
        fullBorder={fullBorder}
        testId={testId}
        textProps={textProps}
        height={height}
        filter={filter}
        setFilter={setFilter}
        confirmSelect={confirmSelect}
        {...comboboxProps}
      />
    </FormField>
  );
}
