import { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Form, Pane } from "components/materials";
import { MASTER_FORMAT_DIVISION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { values } from "lodash";
import t from "helpers/translate";
import { v4 as uuid } from "uuid";

export function getMasterFormatDivisions() {
  const { compare } = new Intl.Collator();

  const options = values(MASTER_FORMAT_DIVISION).map((division) => ({
    key: division,
    text: t(`masterFormatDivisions.${division}`),
    value: division,
  }));

  return [{ key: uuid(), value: null, text: "" }].concat(
    options.sort((a, b) => compare(a.text, b.text))
  );
}

export function MasterFormatDivisionForm({ form, result }) {
  return (
    <Fragment>
      <Form.Select
        name="division"
        options={getMasterFormatDivisions()}
        placeholder="Select Master Format Division..."
      />
      {form.dirty && (
        <Pane
          display="flex"
          justifyContent="flex-end"
          marginTop={majorScale(2)}
        >
          <Button
            purpose="line-item-number-form undo"
            marginRight={majorScale(1)}
            onClick={form.handleReset}
          >
            Undo
          </Button>
          <Button
            appearance="primary"
            purpose="line-item-number-form submit"
            isLoading={result.loading}
            onClick={form.handleSubmit}
          >
            Save
          </Button>
        </Pane>
      )}
    </Fragment>
  );
}

MasterFormatDivisionForm.propTypes = {
  form: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
};
