import { Fragment, useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { get } from "lodash";
import {
  Button,
  Dropzone,
  Heading,
  Link,
  Modal,
  Pane,
  Text,
} from "components/materials";
import { Formik } from "formik";
import { dateFormToServer } from "helpers/dateHelpers";
import { AGREEMENT_TYPE } from "helpers/enums";
import { majorScale, minorScale, toaster } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import isBlank from "helpers/isBlank";
import unformatNumber from "helpers/unformatNumber";
import t from "helpers/translate";
import {
  AGREEMENTS_PAGE_QUERY,
  BULK_CREATE_AGREEMENTS,
} from "./graphql-queries";
import { CHANGE_ORDER_TYPES } from "./enums";
import {
  AgreementForm,
  getInitialValues,
  formatAgreementLineItems,
  formatTransactions,
  formatNewLineItems,
  validateAgreementForm,
} from "./AgreementForm";
import { AgreementsTable } from "./AgreementsTable";
import { AssignAdjustmentToDrawModal } from "./AssignAdjustmentToDrawModal";

// ordered so that the change order types are in the same column when flex-wrapped
const ORDERED_AGREEMENT_TYPES = [
  AGREEMENT_TYPE.ADDENDUM,
  AGREEMENT_TYPE.EXPOSURE,
  AGREEMENT_TYPE.CONTRACT,
  AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER,
  AGREEMENT_TYPE.WORK_AUTHORIZATION,
  AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
];

function canUpgradeToSelectedType(type, existingExposures, existingPCOs) {
  const hasExposures = existingExposures.length > 0;
  const hasPCOs = existingPCOs.length > 0;

  if (type === AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER && hasExposures)
    return true;
  if (
    type === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER &&
    (hasExposures || hasPCOs)
  )
    return true;
  return false;
}

function pendingExistingSelection(
  newAgreementType,
  canSelectFromExisting,
  selectedExistingAgreement
) {
  return (
    !!newAgreementType &&
    canSelectFromExisting &&
    selectedExistingAgreement === undefined
  );
}

function showAgreementForm(
  newAgreementType,
  canSelectFromExisting,
  selectedExistingAgreement
) {
  return (
    !!newAgreementType &&
    (!canSelectFromExisting ||
      (canSelectFromExisting && selectedExistingAgreement !== undefined))
  );
}

function getModalView(
  newAgreementType,
  canSelectFromExisting,
  selectedExistingAgreement
) {
  if (!newAgreementType) return "SELECT_TYPE";
  if (
    pendingExistingSelection(
      newAgreementType,
      canSelectFromExisting,
      selectedExistingAgreement
    )
  )
    return "SELECT_FROM_EXISTING";
  if (
    showAgreementForm(
      newAgreementType,
      canSelectFromExisting,
      selectedExistingAgreement
    )
  )
    return "AGREEMENT_FORM";
  return null;
}

function AddAgreementModalActions({ isSaving, onBack, onCancel, onCreate }) {
  return (
    <Pane padding={majorScale(2)} borderTop textAlign="right" height={64}>
      {onBack && <Button onClick={onBack}>Back</Button>}
      <Button marginLeft={majorScale(2)} onClick={onCancel}>
        Cancel
      </Button>
      {onCreate && (
        <Button
          appearance="primary"
          purpose="agreement submit"
          isLoading={isSaving}
          onClick={onCreate}
          marginLeft={majorScale(2)}
        >
          Create
        </Button>
      )}
    </Pane>
  );
}

function SelectAgreementType({ onSelectType }) {
  return (
    <Pane marginRight={majorScale(2)} width={450}>
      <Heading marginBottom={majorScale(2)}>Select Agreement Type:</Heading>
      <Pane marginBottom={minorScale(3)} display="flex" flexWrap="wrap">
        {ORDERED_AGREEMENT_TYPES.map((type) => (
          <Pane marginBottom={majorScale(1)}>
            <Button
              key={type}
              marginRight={majorScale(2)}
              width={200}
              onClick={() => onSelectType(type)}
            >
              {t(`agreementType.${type}`)}
            </Button>
          </Pane>
        ))}
      </Pane>
    </Pane>
  );
}

function SelectFromExisting({
  close,
  existingExposures,
  existingPCOs,
  isUpgradeFlow,
  newAgreementType,
  setCanSelectFromExisting,
  setNewAgreementType,
  setSelectedExistingAgreement,
}) {
  const validExistingAgreements =
    newAgreementType === AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER
      ? existingExposures
      : existingExposures.concat(existingPCOs);
  return (
    <Pane height="80vh">
      <Pane height="calc(80vh - 64px)" overflow="auto" padding={majorScale(2)}>
        <Button
          appearance="primary"
          onClick={() => setSelectedExistingAgreement(null)}
          marginTop={majorScale(2)}
          marginBottom={majorScale(4)}
        >
          {`Create a New ${t(`agreementType.${newAgreementType}`)}`}
        </Button>
        <Heading marginBottom={majorScale(1)}>
          {t(`agreementsPage.upgrade.${newAgreementType}`)}
        </Heading>
        <AgreementsTable
          isSelectionContext
          agreements={validExistingAgreements}
          onClickRow={setSelectedExistingAgreement}
          selectionButtonCopy={
            newAgreementType === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
              ? "Execute"
              : "Select"
          }
        />
      </Pane>
      <AddAgreementModalActions
        onCancel={close}
        onBack={
          isUpgradeFlow
            ? null
            : () => {
                setNewAgreementType(null);
                setCanSelectFromExisting(false);
              }
        }
      />
    </Pane>
  );
}

function CreateAgreement({
  agreementVendorLineItems,
  canSelectFromExisting,
  close,
  drawId,
  draws,
  handleSubmit,
  isSaving,
  isUpgradeFlow,
  lineItems,
  newAgreementType,
  projectId,
  selectedExistingAgreement,
  setNewAgreementType,
  setSelectedExistingAgreement,
  vendors,
}) {
  const [
    assignAdjustmentToDrawModal,
    setAssignAdjustmentToDrawModalOpen,
  ] = useState(false);

  const { hasPermission } = useContext(UserContext);

  function submitWithDrawCheck(values, formikBag) {
    const { budgetAdjustmentTransactions, drawId } = values;
    if (budgetAdjustmentTransactions.length > 0 && !drawId) {
      setAssignAdjustmentToDrawModalOpen(true);
    } else {
      handleSubmit(values, formikBag);
    }
  }

  return (
    <Pane height="80vh">
      <Formik
        initialValues={getInitialValues({
          existingAgreement: selectedExistingAgreement,
          isUpgradeFlow,
          lineItems,
          projectId,
          drawId,
          newAgreementType,
        })}
        onReset={close}
        onSubmit={submitWithDrawCheck}
        validate={(values) => validateAgreementForm(values, hasPermission)}
      >
        {(formikProps) => {
          return (
            <Fragment>
              <Pane
                height="calc(80vh - 64px)"
                overflow="auto"
                padding={majorScale(2)}
              >
                <AgreementForm
                  agreementVendorLineItems={agreementVendorLineItems}
                  formikProps={formikProps}
                  isAddAgreementModal
                  lineItems={lineItems}
                  projectId={projectId}
                  vendors={vendors}
                />
              </Pane>
              <AddAgreementModalActions
                isSaving={isSaving}
                onCancel={close}
                onBack={
                  isUpgradeFlow && !canSelectFromExisting
                    ? null
                    : () => {
                        if (!canSelectFromExisting) {
                          setNewAgreementType(null);
                        }
                        setSelectedExistingAgreement(undefined);
                      }
                }
                onCreate={formikProps.handleSubmit}
              />
              {assignAdjustmentToDrawModal && (
                <AssignAdjustmentToDrawModal
                  availableDraws={draws.filter(
                    ({ isLockedDown }) => !isLockedDown
                  )}
                  hasECODocument={!!formikProps.values.file}
                  onConfirmDrawSelect={(drawId) => {
                    setAssignAdjustmentToDrawModalOpen(false);
                    formikProps.setFieldValue("drawId", drawId);
                    formikProps.handleSubmit();
                  }}
                  onClose={() => setAssignAdjustmentToDrawModalOpen(false)}
                  projectId={projectId}
                  setFieldValue={formikProps.setFieldValue}
                />
              )}
            </Fragment>
          );
        }}
      </Formik>
    </Pane>
  );
}

function AddAgreementModalContent({
  agreements,
  agreementVendorLineItems,
  canSelectFromExisting,
  close,
  drawId,
  draws,
  handleSubmit,
  isSaving,
  isUpgradeFlow,
  lineItems,
  modalView,
  newAgreementType,
  projectId,
  selectedExistingAgreement,
  setCanSelectFromExisting,
  setNewAgreementType,
  setSelectedExistingAgreement,
  vendors,
}) {
  const [file, setFile] = useState(null);
  const [upload, uploadResult] = useMutation(BULK_CREATE_AGREEMENTS, {
    variables: { projectId, file },
    onCompleted: () => {
      toaster.success("Agreement(s) created", { duration: 2.5 });
      setFile(null);
      close();
    },
    onError: () => {
      toaster.danger("Upload failed", { duration: 2.5 });
      setFile(null);
    },
    refetchQueries: [
      { query: AGREEMENTS_PAGE_QUERY, variables: { projectId } },
    ],
  });

  const existingExposures = agreements.filter(
    ({ type }) => type === AGREEMENT_TYPE.EXPOSURE
  );
  const existingPCOs = agreements.filter(
    ({ type }) => type === AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER
  );

  function onSelectType(type) {
    if (canUpgradeToSelectedType(type, existingExposures, existingPCOs)) {
      setCanSelectFromExisting(true);
    }
    setNewAgreementType(type);
  }

  if (modalView === "SELECT_TYPE") {
    return (
      <Pane height="50vh">
        <Pane
          height="calc(50vh - 64px)"
          display="flex"
          justifyContent="center"
          paddingTop={majorScale(5)}
          paddingX={majorScale(4)}
        >
          <SelectAgreementType onSelectType={onSelectType} />
          <Pane width={450} marginLeft={majorScale(2)}>
            <Heading>Or upload a .csv to create multiple agreements:</Heading>
            <Pane marginY={2}>
              <Link href="https://help.rabbet.com/en/articles/3764714-agreements-management">
                Learn more
              </Link>{" "}
              <Text>about bulk agreement creation</Text>
            </Pane>
            <Dropzone
              containerProps={{ height: 150 }}
              uploadTypeMain="agreements"
              uploadTypeButton="File"
              allowMultiple={false}
              disabled={uploadResult.loading}
              files={file ? [file] : []}
              onAdd={(files) => setFile(files[0])}
              onRemove={() => setFile(null)}
              restrictTypes={{
                acceptableTypes: ["text/csv"],
                restrictionMessage: "*csv",
              }}
            />
          </Pane>
        </Pane>
        <AddAgreementModalActions
          onCancel={close}
          onCreate={file ? upload : null}
          isSaving={uploadResult.loading}
        />
      </Pane>
    );
  }

  if (modalView === "SELECT_FROM_EXISTING") {
    return (
      <SelectFromExisting
        close={close}
        existingExposures={existingExposures}
        existingPCOs={existingPCOs}
        isUpgradeFlow={isUpgradeFlow}
        newAgreementType={newAgreementType}
        setCanSelectFromExisting={setCanSelectFromExisting}
        setNewAgreementType={setNewAgreementType}
        setSelectedExistingAgreement={setSelectedExistingAgreement}
      />
    );
  }

  if (modalView === "AGREEMENT_FORM") {
    return (
      <CreateAgreement
        agreementVendorLineItems={agreementVendorLineItems}
        canSelectFromExisting={canSelectFromExisting}
        close={close}
        drawId={drawId}
        draws={draws}
        handleSubmit={handleSubmit}
        isSaving={isSaving}
        isUpgradeFlow={isUpgradeFlow}
        lineItems={lineItems}
        newAgreementType={newAgreementType}
        projectId={projectId}
        selectedExistingAgreement={selectedExistingAgreement}
        setNewAgreementType={setNewAgreementType}
        setSelectedExistingAgreement={setSelectedExistingAgreement}
        vendors={vendors}
      />
    );
  }
}

export function AddAgreementModal({
  agreements,
  drawId,
  isSaving,
  isUpgradeFlow,
  knownExistingAgreement,
  knownNewAgreementType,
  knownCanSelectFromExisting,
  onClose,
  projectAgreementMutation,
  project,
}) {
  const [newAgreementType, setNewAgreementType] = useState(
    knownNewAgreementType
  );
  const [canSelectFromExisting, setCanSelectFromExisting] = useState(
    !!knownCanSelectFromExisting
  );
  const [selectedExistingAgreement, setSelectedExistingAgreement] = useState(
    knownExistingAgreement
  );

  const {
    id: projectId,
    draws,
    lineItems,
    agreementVendorLineItems,
    vendors,
  } = project;

  function handleSubmit(
    {
      agreementLineItems,
      agreementType,
      budgetAdjustmentTransactions: transactions,
      agreementNumber,
      changeOrderReason,
      correlationId,
      date,
      daysImpacted,
      agreementDescription,
      drawId,
      file,
      moveDocumentToDraw,
      name,
      projectId,
      userTouchedName,
      vendor,
    },
    { setErrors }
  ) {
    const variables = {
      projectId,
      correlationId,
      type: agreementType,
      vendorId: get(vendor, "id"),
      agreementLineItems: formatAgreementLineItems(agreementLineItems),
      budgetAdjustmentTransactions: formatTransactions(transactions),
      newLineItems: formatNewLineItems(transactions),
      agreementNumber,
      changeOrderReason: CHANGE_ORDER_TYPES.includes(agreementType)
        ? changeOrderReason
        : null,
      daysImpacted: isBlank(daysImpacted) ? null : unformatNumber(daysImpacted),
      agreementDescription,
      name,
      userTouchedName,
      budgetAdjustmentDrawId: drawId,
      file,
      moveDocumentToDraw,
      startDate: dateFormToServer(date),
    };

    projectAgreementMutation({ variables }).catch((error) => {
      if (error.message.includes(t("agreementsForm.agreementNameError"))) {
        setErrors({
          name: t("agreementsForm.agreementNameTaken"),
        });
      } else {
        toaster.warning("Something went wrong.", { duration: 2.5 });
      }
    });
  }

  const modalTitle = newAgreementType
    ? `Create ${t(`agreementType.${newAgreementType}`)}`
    : "Add Agreement";

  const modalView = getModalView(
    newAgreementType,
    canSelectFromExisting,
    selectedExistingAgreement
  );

  return (
    <Modal
      hideViewer
      onClose={onClose}
      open
      width="85%"
      title={modalTitle}
      topOffset={modalView === "SELECT_TYPE" ? 150 : majorScale(2)}
      contentContainerProps={{
        overflow: "hidden",
        height: modalView === "SELECT_TYPE" ? "50vh" : "80vh",
        padding: 0,
      }}
    >
      {({ close }) => (
        <AddAgreementModalContent
          agreements={agreements}
          agreementVendorLineItems={agreementVendorLineItems}
          canSelectFromExisting={canSelectFromExisting}
          close={close}
          drawId={drawId}
          draws={draws}
          handleSubmit={handleSubmit}
          isSaving={isSaving}
          isUpgradeFlow={isUpgradeFlow}
          lineItems={lineItems}
          modalView={modalView}
          newAgreementType={newAgreementType}
          projectId={projectId}
          selectedExistingAgreement={selectedExistingAgreement}
          setCanSelectFromExisting={setCanSelectFromExisting}
          setNewAgreementType={setNewAgreementType}
          setSelectedExistingAgreement={setSelectedExistingAgreement}
          vendors={vendors}
        />
      )}
    </Modal>
  );
}

AddAgreementModal.propTypes = {
  agreements: PropTypes.array,
  isUpgradeFlow: PropTypes.bool,
};

AddAgreementModal.defaultProps = {
  agreements: [],
  isUpgradeFlow: false,
};
