import { useContext, useMemo } from "react";
import { EditIcon } from "evergreen-ui";
import { EditTableViews } from "components/containers";
import { Button, IconButton } from "components/materials";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import GroupBy from "components/materials/FastDataTable/Controls/GroupBy";
import { get } from "lodash";
import { UserContext } from "helpers/behaviors";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { PERMISSION_ACTION } from "helpers/enums";

const defaultViews = [
  {
    config: toBase64({
      columnConfig: ["code", "description", "glCode"],
      filterConfig: [],
      groupConfig: {},
      sortConfig: {},
    }),
    isDefault: true,
    name: "Default",
  },
];

function getColumns({ hasOrgLevelPermission }) {
  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Code",
      id: "code",
      value: (jcc) => jcc.code,
    },
    {
      ...stringColumnDefaults,
      header: "Description",
      id: "description",
      value: (jcc) => jcc.description,
    },
    {
      ...stringColumnDefaults,
      header: "GL Account",
      hidden: !hasOrgLevelPermission(PERMISSION_ACTION.YARDI_INTEGRATION),
      id: "glCode",
      value: (jcc) => jcc.glCode,
    },
  ];
}

function getControls(propsControls, propsEditTableViews, setEditCostCode) {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      rightControls={
        <Button
          appearance="primary"
          onClick={() => setEditCostCode({ code: "" })}
        >
          Add Cost Code
        </Button>
      }
      disable={[FastDataTableDownloadDocuments, GroupBy]}
      searchPlaceholder="Search Job Cost Codes..."
      isReport
    />
  );
}

export function JobCostCodesTable({
  history,
  onClickRow,
  jobCostCodes,
  selectedOrganization,
  setEditCostCode,
}) {
  const { hasPermission } = useContext(UserContext);
  const { hasOrgLevelPermission } = useContext(UserContext);

  const columns = useMemo(() => {
    return getColumns({
      hasOrgLevelPermission,
    });
  }, [hasOrgLevelPermission]);

  const editCostCodeColumn = {
    aggregate: () => null,
    header: () => null,
    id: "editCostCode",
    utility: true,
    value: (jcc) => (
      <IconButton
        appearance="minimal"
        icon={EditIcon}
        onClick={() => setEditCostCode(jcc)}
        type="button"
      />
    ),
    width: 35,
  };

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(
        PERMISSION_ACTION.SAVE_TABLE_VIEWS,
        selectedOrganization
      )}
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={selectedOrganization.id}
      defaultViews={defaultViews}
      tableName="JobCostCodesTable"
    >
      {(propsEditTableViews) => (
        <FastDataTable
          columns={columns}
          controls={(propsControls) =>
            getControls(propsControls, propsEditTableViews, setEditCostCode)
          }
          items={jobCostCodes}
          onClickRow={onClickRow}
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(propsEditTableViews, "views.0.config")
          }
          utilityColumns={[editCostCodeColumn]}
        />
      )}
    </EditTableViews>
  );
}
