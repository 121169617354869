import PropTypes from "prop-types";
import { FastField } from "formik";
import { FormField, Paragraph, RadioGroup } from "components/materials";

const FormRadioGroup = ({
  name,
  label,
  labelProps,
  fieldProps = {},
  ...props
}) => {
  return (
    <FastField name={name}>
      {({ field, form }) => (
        <FormField
          label={<Paragraph {...labelProps}>{label}</Paragraph>}
          {...fieldProps}
        >
          <RadioGroup
            {...props}
            {...field}
            onChange={(event) => {
              const { value } = event.target;
              if (props.onChange) props.onChange(value);
              return !form.values.__disabled
                ? form.setFieldValue(name, value)
                : null;
            }}
            data-testid={name}
            data-value={field.value}
          />
        </FormField>
      )}
    </FastField>
  );
};

FormRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      value: PropTypes.isRequired,
    }).isRequired
  ).isRequired,
};

export default FormRadioGroup;
