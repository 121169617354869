import { Fragment } from "react";
import { ShareIcon } from "evergreen-ui";
import { Badge, Heading, Link, Pane, Table, Text } from "components/materials";
import { subtract, sumBy } from "helpers/math";
import { minorScale, majorScale } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { formatDate } from "helpers/dateHelpers";
import t from "helpers/translate";

export function AssociatedAgreementInvoicesTable({
  agreement,
  agreementLineItems,
  errors,
  projectId,
  vendorIdHasChanged,
}) {
  const agreementTotal = sumBy(agreementLineItems, "amount");

  const totalFromInvoices = sumBy(agreement.trackedAgreementAmounts, "amount");

  const totalRemaining = subtract(agreementTotal, totalFromInvoices);
  return (
    <Pane>
      <Heading marginBottom={majorScale(1)}>Invoices</Heading>
      {vendorIdHasChanged ? (
        <ChangeVendorRemovesCostsWarning />
      ) : (
        <Fragment>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell>Number</Table.TextHeaderCell>
                <Table.TextHeaderCell minWidth={50}>Draw</Table.TextHeaderCell>
                <Table.TextHeaderCell minWidth={50}>Date</Table.TextHeaderCell>
                <Table.TextHeaderCell>Line Item(s)</Table.TextHeaderCell>
                <Table.TextHeaderCell>
                  Amount Against This Agreement
                </Table.TextHeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {agreement.trackedAgreementAmounts.map((agreementAmount) => {
                const amountAgainstThisAgreement = agreementAmount.amount;
                const {
                  invoice: {
                    date,
                    documentId,
                    documentName,
                    drawName,
                    lineItemNames,
                  },
                } = agreementAmount;
                return (
                  <Table.Row>
                    <Table.TextCell>
                      <Link
                        purpose="to document page"
                        size={300}
                        target="_blank"
                        to={`/projects/${projectId}/documentation/${documentId}`}
                      >
                        {documentName}
                        <ShareIcon size="12" marginLeft={minorScale(1)} />
                      </Link>
                    </Table.TextCell>
                    <Table.TextCell>{drawName}</Table.TextCell>
                    <Table.TextCell>
                      {date ? formatDate(date) : "-"}
                    </Table.TextCell>
                    <Table.TextCell>{lineItemNames.join(", ")}</Table.TextCell>
                    <Table.TextCell>
                      {formatCurrency(amountAgainstThisAgreement)}
                    </Table.TextCell>
                  </Table.Row>
                );
              })}
              <Table.Row>
                <Table.Cell border="none" colSpan={4} textAlign="right">
                  <Text fontWeight={500} size={300} marginRight={majorScale(1)}>
                    TOTAL REMAINING:
                  </Text>
                </Table.Cell>
                <Table.Cell border="none">
                  <Badge>{formatCurrency(totalRemaining)}</Badge>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Fragment>
      )}
    </Pane>
  );
}

function ChangeVendorRemovesCostsWarning() {
  return (
    <Pane
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height={100}
    >
      <Text>{t("agreementsViewer.changeVendorRemovesTrackedCosts")}</Text>
    </Pane>
  );
}
