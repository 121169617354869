import { Button, Modal, Pane, Text } from "components/materials";
import { isAgreementWithAdjustments } from "helpers/documentHelpers";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

export function AlsoDeleteAgreementModal({ documents, onClose, onDelete }) {
  const count = documents.length;
  const modalTitle = count === 1 ? "Delete Document" : "Delete Documents";

  const hasBudgetAdjustments = documents.some(isAgreementWithAdjustments);

  return (
    <Modal
      onCloseComplete={onClose}
      open
      shouldCloseOnOverlayClick={false}
      title={modalTitle}
      width="40%"
    >
      <Pane marginLeft={majorScale(2)}>
        <Text>
          {t("documentsViewer.promptAlsoDeleteAgreement", {
            count,
          })}
        </Text>
        {hasBudgetAdjustments && (
          <Pane marginTop={majorScale(1)}>
            <Text>{t("documentsViewer.deleteAgreementRemovesAdjustment")}</Text>
          </Pane>
        )}
      </Pane>
      <Pane borderTop marginTop={majorScale(2)}>
        <Pane
          display="flex"
          justifyContent="flex-end"
          marginTop={majorScale(1)}
        >
          <Button marginRight={majorScale(1)} onClick={onClose}>
            Cancel
          </Button>
          <Button
            marginRight={majorScale(1)}
            onClick={() => onDelete({ alsoDeleteAgreements: false })}
          >
            {t("documentsViewer.deleteDocumentOnly", { count })}
          </Button>
          <Button
            appearance="primary"
            onClick={() => onDelete({ alsoDeleteAgreements: true })}
          >
            {t("documentsViewer.deleteDocumentAndAgreement", { count })}
          </Button>
        </Pane>
      </Pane>
    </Modal>
  );
}
