import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Button, Checkbox, Modal, Pane, Text } from "components/materials";
import { majorScale, toaster } from "helpers/utilities";
import t from "helpers/translate";
import { DELETE_AGREEMENT_AND_DOCUMENTATION } from "./graphql-queries";

export function DeleteAgreementModal({
  agreement,
  navigateOnDelete,
  onClose,
  refetchQueries,
}) {
  const [deleteBudgetAdjustment, setDeleteBudgetAdjustment] = useState(false);

  const [deleteAgreement, { loading }] = useMutation(
    DELETE_AGREEMENT_AND_DOCUMENTATION,
    {
      onCompleted: () => {
        onClose();
        navigateOnDelete();
      },
      onError: () => {
        toaster.warning("Something went wrong.  Please try again", {
          duration: 2.5,
        });
      },
      refetchQueries,
    }
  );

  const handleDelete = () => {
    deleteAgreement({
      variables: { agreementId: agreement.id, deleteBudgetAdjustment },
    });
  };

  const hasDocument = agreement.correlatedDocuments.length > 0;
  const hasAdjustment = agreement.budgetAdjustment !== null;

  return (
    <Fragment>
      <Modal
        open
        width="40%"
        onCloseComplete={onClose}
        title="Delete Agreement"
      >
        <Pane padding={majorScale(1)} marginBottom={majorScale(2)}>
          <Text>
            {t(
              `agreementsViewer.${
                hasDocument ? "confirmDeleteWithDocuments" : "confirmDelete"
              }`
            )}
          </Text>
          {hasAdjustment && (
            <Checkbox
              label={t("agreementsViewer.deleteBudgetAdjustment")}
              onChange={() => setDeleteBudgetAdjustment((checked) => !checked)}
              checked={deleteBudgetAdjustment}
            />
          )}
        </Pane>
        <Pane
          marginX={-majorScale(2)}
          paddingX={majorScale(2)}
          paddingTop={majorScale(2)}
          borderTop
          textAlign="right"
        >
          <Button marginLeft={majorScale(2)} onClick={onClose}>
            Cancel
          </Button>
          <Button
            appearance="primary"
            purpose="agreement submit"
            isLoading={loading}
            onClick={handleDelete}
            marginLeft={majorScale(2)}
          >
            Delete Agreement
          </Button>
        </Pane>
      </Modal>
    </Fragment>
  );
}

DeleteAgreementModal.propTypes = {
  navigateOnDelete: PropTypes.func,
};

DeleteAgreementModal.defaultProps = {
  navigateOnDelete: () => {},
};
