import { useRef, useEffect } from "react";
import { deepEqual } from "fast-equals";

/**
 * Returns a memoized value that only updates if the compare function returns
 * false.
 *
 * It's worth noting that this hook isn't meant to avoid expensive calculations.
 * The calculation is performed on every render regardless. This hook is meant
 * to avoid unnecessary re-renders.
 * @param currentValue
 * @param compare
 * @returns The memoized value.
 */
export function useMemoCompare<T>(
  currentValue: T,
  compare: (prev: T, currentValue: T) => boolean
) {
  // Ref for storing previous value
  const previousRef = useRef(currentValue);
  const previous = previousRef.current;

  const isEqual = compare(previous, currentValue);
  // If not equal update previousRef to currentValue.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = currentValue;
    }
  });
  // Finally, if equal then return the previous value
  return isEqual ? previous : currentValue;
}

/**
 * Returns a memoized value that only updates if the value has changed.
 * @param currentValue
 * @returns The memoized value.
 */
export function useMemoDeep<T>(currentValue: T) {
  return useMemoCompare(currentValue, deepEqual);
}
