import { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { debounce } from "lodash";
import { UPDATE_ONBOARDING_DATA } from "./utils";

export function PersistStep({ mutationData, projectId }) {
  const [update] = useMutation(UPDATE_ONBOARDING_DATA);
  const debouncedUpdate = debounce(update, 1000);

  useEffect(() => {
    debouncedUpdate({
      variables: {
        projectId,
        data: JSON.stringify(mutationData),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationData]);

  return null;
}
