import { useContext } from "react";
import PropTypes from "prop-types";
import { Button, Pane, Paragraph } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { preventEventBubbling } from "helpers/preventEventBubbling";

export function OnboardingWizardDecisionCard({
  backgroundColor,
  buttonPrimary,
  buttonText,
  combobox,
  disableCardClick,
  header,
  image,
  imageHeight,
  onClick,
  subHeader,
}) {
  const theme = useContext(ThemeContext);
  return (
    <Pane
      alignItems="center"
      backgroundColor={backgroundColor ? theme.colors.lightBlue : undefined}
      border="default"
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      justifyContent="center"
      height={450}
      width="40%"
      marginRight={majorScale(4)}
      onClick={() => (!disableCardClick ? onClick() : null)}
      cursor={disableCardClick ? undefined : "pointer"}
    >
      {image && (
        <Pane
          is="img"
          src={image}
          alt=""
          height={imageHeight || 124}
          marginBottom={majorScale(4)}
        />
      )}
      <Pane
        width="70%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Paragraph
          fontSize={24}
          fontWeight={theme.fontWeights.MEDIUM}
          marginBottom={majorScale(2)}
          textAlign="center"
          width="100%"
        >
          {header}
        </Paragraph>
        <Paragraph size={500} textAlign="center" width="100%">
          {subHeader}
        </Paragraph>
        {combobox && (
          <Pane marginTop={majorScale(2)} width="100%">
            {combobox()}
          </Pane>
        )}
        {buttonText && (
          <Button
            appearance={buttonPrimary ? "primary" : undefined}
            onClick={(e) => {
              preventEventBubbling(e);
              onClick();
            }}
            marginTop={majorScale(4)}
            purpose="project-wizard decision"
          >
            {buttonText}
          </Button>
        )}
      </Pane>
    </Pane>
  );
}

OnboardingWizardDecisionCard.propTypes = {
  backgroundColor: PropTypes.bool,
  buttonText: PropTypes.string,
  combobox: PropTypes.func,
  buttonPrimary: PropTypes.bool,
  header: PropTypes.string.isRequired,
  image: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  subHeader: PropTypes.string.isRequired,
};
