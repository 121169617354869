const baseUrl = "https://view.officeapps.live.com/op/embed.aspx?src=";

export function OfficeViewer({ file }) {
  const src = `${baseUrl}${encodeURIComponent(file.url)}`;

  const style = {
    display: "block",
    height: "100%",
    minHeight: "500px",
    width: "100%",
  };

  return <iframe frameBorder="0" src={src} style={style} title={file.name} />;
}
