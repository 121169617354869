import { v4 as uuid } from "uuid";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { get, has, isNumber, toString } from "lodash";
import { formatCurrency } from "./formatCurrency";
import isBlank from "./isBlank";
import formatConstant from "./formatConstant";

const MASTER_FORMAT_DIVISION_VALUES = Object.freeze({
  "general requirement": "GENERAL_REQUIREMENT",
  "existing conditions": "EXISTING_CONDITIONS",
  concrete: "CONCRETE",
  masonry: "MASONRY",
  metals: "METALS",
  "wood and plastics": "WOOD_AND_PLASTICS",
  "thermal and moisture protection": "THERMAL_AND_MOISTURE_PROTECTION",
  "doors and windows": "DOORS_AND_WINDOWS",
  finishes: "FINISHES",
  specialties: "SPECIALTIES",
  equipment: "EQUIPMENT",
  furnishings: "FURNISHINGS",
  "special construction": "SPECIAL_CONSTRUCTION",
  "conveying systems": "CONVEYING_SYSTEMS",
  "mechanical/plumbing": "MECHANICAL_PLUMBING",
  electrical: "ELECTRICAL",
});

function getCellAmount(budgetAmount) {
  const amount = get(budgetAmount, "result") || budgetAmount;
  if (isNumber(amount)) {
    return formatCurrency(amount);
  }
  return formatCurrency(0);
}

function getCellValue(parsedData) {
  if (has(parsedData, "richText")) {
    return parsedData.richText.reduce(
      (output, richTextItem) => output.concat(richTextItem.text),
      ""
    );
  }
  return get(parsedData, "result") || parsedData;
}

function getLineItemNumber({ row, cell }) {
  const parsedNumber = getCellValue(row.values[cell]);

  const trimmedNumber =
    typeof parsedNumber === "string" ? parsedNumber.trim() : parsedNumber;
  if (trimmedNumber === "<Line Item Number>") return null;
  return isBlank(trimmedNumber) ? "" : toString(trimmedNumber);
}

function parseCategory(category) {
  const categoryText = getCellValue(category);
  return categoryText ? categoryText.replace(/\//g, " ") : "";
}

function parseMasterFormatDivision(division) {
  if (isBlank(division) || typeof division !== "string") return null;

  // remove forward slashes that came in from the spreadsheet template...
  division = division.replace(/\//g, " ");
  const foundKey = Object.keys(MASTER_FORMAT_DIVISION_VALUES).find((key) =>
    division.toLowerCase().includes(key)
  );
  return foundKey ? MASTER_FORMAT_DIVISION_VALUES[foundKey] : null;
}

function parseRowValues({ row, hasMasterFormatDivision, actualColumnCount }) {
  if (actualColumnCount === 5) {
    const masterFormatDivision = getCellValue(row.values[2]);
    return {
      divisionName: getCellValue(row.values[1]).trim(),
      masterFormatDivision:
        hasMasterFormatDivision && masterFormatDivision
          ? parseMasterFormatDivision(masterFormatDivision)
          : null,
      number: getLineItemNumber({ row, cell: 3 }),
      lineItemName: getCellValue(row.values[4]).trim(),
      amount: getCellAmount(row.values[5]),
    };
  }

  if (actualColumnCount === 4) {
    return {
      divisionName: getCellValue(row.values[1]).trim(),
      masterFormatDivision: null,
      number: getLineItemNumber({ row, cell: 2 }),
      lineItemName: getCellValue(row.values[3]).trim(),
      amount: getCellAmount(row.values[4]),
    };
  }

  return {
    divisionName: getCellValue(row.values[1]).trim(),
    number: getLineItemNumber({ row, cell: 2 }),
    lineItemName: getCellValue(row.values[3]).trim(),
    superLineItem: getCellValue(row.values[4]),
    originalBudgetAmount: getCellAmount(row.values[5]),
    amount: getCellAmount(row.values[5]),
    lineItemType: getCellValue(row.values[6]),
    category: parseCategory(row.values[7]),
    retainagePercentage: getCellValue(row.values[8]),
    masterFormatDivision: parseMasterFormatDivision(
      getCellValue(row.values[9])
    ),
  };
}

/**
 * Extracts data from spreadsheets that conform to the budget upload template format
 *
 * @param {File} file
 * @param {Boolean} hasMasterFormatDivision
 * @param {Function} callback
 * @param {Function} onError
 * @returns FileReader
 */
export function parseExcelBudgetFile(
  file,
  hasMasterFormatDivision,
  callback,
  onError
) {
  const uploadFile = file[0];
  const wb = new ExcelJS.Workbook();
  const reader = new FileReader();

  reader.readAsArrayBuffer(uploadFile);

  reader.onload = () => {
    const buffer = reader.result;
    wb.xlsx
      .load(buffer)
      .then((workbook) => {
        const { actualColumnCount } = wb.worksheets[0];
        const divisions = {};
        let currentDivision = {};

        wb.worksheets[0].eachRow((row, rowIndex) => {
          if (rowIndex === 1) return;

          const {
            divisionName,
            lineItemName,
            superLineItem,
            category,
            originalBudgetAmount,
            amount,
            lineItemType,
            retainagePercentage,
            number,
            masterFormatDivision,
          } = parseRowValues({
            row,
            hasMasterFormatDivision,
            actualColumnCount,
          });

          if (!!divisionName && divisionName !== currentDivision.name) {
            currentDivision = divisions[divisionName] || {
              id: uuid(),
              name: divisionName,
              lineItems: [],
            };
          }

          currentDivision.lineItems = [
            ...currentDivision.lineItems,
            {
              id: uuid(),
              name: lineItemName,
              superLineItem,
              lineItemCategories: [formatConstant(category)],
              originalBudgetAmount,
              amount,
              lineItemType,
              retainagePercentage,
              number,
              masterFormatDivision,
            },
          ];

          divisions[currentDivision.name] = currentDivision;
        });

        return divisions;
      })
      .then(callback)
      .catch(onError);
  };

  return reader;
}
