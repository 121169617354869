import PropTypes from "prop-types";
import {
  VictoryTheme,
  VictoryContainer,
  VictoryPie,
  VictoryLabel,
} from "victory";
import { BRAND_COLORS } from "helpers/colors";

const theme = VictoryTheme.grayscale;
const backgroundData = [{ y: 100 }];
const backgroundColorScale = [BRAND_COLORS.OFF_WHITE];
const colorScale = [BRAND_COLORS.LIGHT_PRIMARY, "transparent"];

function ProgressCircular({ height, percent }) {
  const style = { height, width: "100%" };
  const data = [{ y: percent }, { y: 100 - percent }];
  const text = `${Math.round(percent)}%`;
  return (
    <div style={style}>
      <VictoryContainer height={theme.pie.height} width={theme.pie.width}>
        <VictoryPie
          standalone={false}
          theme={theme}
          data={backgroundData}
          colorScale={backgroundColorScale}
          padding={20}
          cornerRadius={25}
          innerRadius={130}
          labels={() => null}
          animate={false}
          startAngle={-150}
          endAngle={150}
        />
        <VictoryPie
          standalone={false}
          theme={theme}
          data={data}
          colorScale={colorScale}
          padding={20}
          cornerRadius={25}
          innerRadius={130}
          labels={() => null}
          animate={false}
          startAngle={-150}
          endAngle={150}
        />
        <VictoryLabel
          standalone={false}
          theme={theme}
          text={text}
          textAnchor="middle"
          verticalAnchor="middle"
          x={theme.pie.width / 2}
          y={theme.pie.height / 2}
          style={{ fontSize: 60 }}
        />
      </VictoryContainer>
    </div>
  );
}

ProgressCircular.propTypes = {
  height: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
};

export default ProgressCircular;
