import { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { UserContext } from "helpers/behaviors";
import { Form, Pane } from "components/materials";
import { useDocumentFormContext } from "contexts/documentFormContext";
import { getDocumentTypeOptions } from "helpers/documentHelpers";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import getOptions from "helpers/getOptions";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";

function DocumentMeta(props) {
  const {
    children,
    collapsedPanels,
    disabled,
    draws,
    form,
    isProjectOnly,
    onChangeDraw,
    onChangeType,
    projectName,
    togglePanel,
  } = props;
  const { FormSelect } = useDocumentFormContext();
  const drawOptions = [
    {
      key: "Project",
      value: undefined,
      text: "Project",
    },
  ].concat(getOptions(draws));

  const { hasPermission } = useContext(UserContext);

  const documentTypeOptions =
    form.values.type === DOCUMENT_TYPE_NAME.DEPRECATED_DRAW_SUMMARY
      ? getDocumentTypeOptions(hasPermission)
      : getDocumentTypeOptions(hasPermission, [
          DOCUMENT_TYPE_NAME.DEPRECATED_DRAW_SUMMARY,
        ]);

  const content = (
    <Fragment>
      <Pane
        alignItems="center"
        display="flex"
        flexGrow={1}
        marginRight={majorScale(2)}
      >
        <FormSelect
          disabled={disabled}
          form={form}
          formValue={form.values.type}
          name="type"
          noNull
          options={documentTypeOptions}
          onChange={(type) => {
            onChangeType(type);
          }}
          width={175}
        />
      </Pane>

      <Pane flexGrow={1} marginRight={majorScale(2)}>
        <FormSelect
          disabled={isProjectOnly || disabled}
          form={form}
          formValue={form.values.draw?.id}
          name="draw.id"
          noNull
          onChange={onChangeDraw}
          options={drawOptions}
          width={175}
        />
      </Pane>
    </Fragment>
  );

  const summaryValues =
    [DOCUMENT_TYPE_NAME.INVOICE, DOCUMENT_TYPE_NAME.PAY_APPLICATION].includes(
      form.values.type
    ) && form.values.isBackup
      ? [
          t(`documentTypeName.${form.values.type}`),
          get(form.values, "draw.name", projectName),
          t("documentReview.notAppliedToDraw"),
        ]
      : [
          t(`documentTypeName.${form.values.type}`),
          get(form.values, "draw.name", projectName),
        ];
  const summary = summaryValues.filter((value) => !!value).join(", ");
  return (
    <Form.Section
      heading="Document Information"
      isCollapsible
      isCollapsed={collapsedPanels.includes("meta")}
      onCollapse={() => togglePanel("meta")}
      summary={summary}
    >
      <Pane display="flex" justifyContent="space-between">
        {children(content)}
      </Pane>
    </Form.Section>
  );
}

DocumentMeta.propTypes = {
  children: PropTypes.func,
  collapsedPanels: PropTypes.arrayOf(PropTypes.string),
  documentTypes: PropTypes.array,
  draws: PropTypes.array,
  form: PropTypes.shape({ values: PropTypes.object.isRequired }).isRequired,
  isProjectOnly: PropTypes.bool,
  onChangeDraw: PropTypes.func,
  onChangeType: PropTypes.func,
  projectName: PropTypes.string,
  togglePanel: PropTypes.func,
};

DocumentMeta.defaultProps = {
  children: (content) => content,
  collapsedPanels: [],
  documentTypes: Object.values(DOCUMENT_TYPE_NAME),
  draws: [],
  isProjectOnly: false,
};

export default DocumentMeta;
