import { useContext, Fragment } from "react";
import { Pane, Paragraph, Progress, Text, Tooltip } from "components/materials";
import {
  majorScale,
  minorScale,
  ThemeContext,
  Position,
} from "helpers/utilities";
import { sumBy } from "helpers/math";
import { BRAND_COLORS } from "helpers/colors";
import { formatCurrency } from "helpers/formatCurrency";
import { DivisionTooltip } from "./DetailedDivisionSummary";

function SimpleSummaryBody({ divisions, theme }) {
  const isCompactView = divisions.length > 16;

  return (
    <Pane
      flex="1 1 auto"
      display="flex"
      flexWrap="wrap"
      paddingY={minorScale(1)}
      paddingX={majorScale(1)}
    >
      {divisions.map((division) => (
        <Tooltip
          key={division.id}
          position={Position.TOP_LEFT}
          content={<DivisionTooltip division={division} theme={theme} />}
        >
          <Pane
            display="flex"
            alignItems="center"
            paddingX={majorScale(1)}
            width={isCompactView ? "33.3%" : "50%"}
          >
            <Paragraph
              marginY={-3}
              maxHeight={20}
              width={isCompactView ? "30%" : "50%"}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              <Text color="muted" fontSize={11}>
                {isCompactView ? division.name.slice(0, 4) : division.name}
              </Text>
            </Paragraph>
            <Pane
              display="flex"
              alignItems="center"
              paddingLeft={minorScale(1)}
              width={isCompactView ? "70%" : "50%"}
            >
              <Progress
                background="#cadefa"
                color={BRAND_COLORS.LIGHT_PRIMARY}
                value={division.requested}
                total={division.budget}
                height={majorScale(1)}
              />
            </Pane>
          </Pane>
        </Tooltip>
      ))}
    </Pane>
  );
}

function SimpleSummaryTotal({ divisions, theme }) {
  const totalRequested = sumBy(divisions, "requested");
  const totalBudget = sumBy(divisions, "budget");

  return (
    <Pane
      flex="0 0 32px"
      display="flex"
      alignItems="center"
      paddingX={majorScale(2)}
      background={theme.colors.gray200}
    >
      <table width="100%">
        <tbody>
          <tr>
            <td>
              <Paragraph
                fontSize={11}
                fontWeight={theme.fontWeights.DEMI}
                color="muted"
                paddingRight={majorScale(1)}
              >
                TOTAL
              </Paragraph>
            </td>
            <td>
              <Paragraph
                fontSize={11}
                fontWeight={theme.fontWeights.MEDIUM}
                lineHeight="12px"
                textAlign="center"
              >
                {formatCurrency(totalRequested)} used
              </Paragraph>
            </td>
            <td width="30%" aria-label="progress">
              <Pane paddingX={minorScale(1)}>
                <Progress
                  background="lightgrey"
                  color={theme.colors.gray800}
                  value={totalRequested}
                  total={totalBudget}
                  height={majorScale(1)}
                  width="100%"
                />
              </Pane>
            </td>
            <td>
              <Paragraph
                fontSize={11}
                fontWeight={theme.fontWeights.MEDIUM}
                lineHeight="12px"
                textAlign="center"
              >
                {formatCurrency(totalBudget)} budget
              </Paragraph>
            </td>
          </tr>
        </tbody>
      </table>
    </Pane>
  );
}

export function SimpleDivisionsSummary({ divisions }) {
  const theme = useContext(ThemeContext);

  return (
    <Fragment>
      <SimpleSummaryBody divisions={divisions} theme={theme} />
      <SimpleSummaryTotal divisions={divisions} theme={theme} />
    </Fragment>
  );
}
