import PropTypes from "prop-types";
import { CrossIcon } from "evergreen-ui";
import { Pane, Text } from "components/materials";
import { majorScale } from "helpers/utilities";

function dragItemStyles(itemHeight) {
  return {
    display: "flex",
    alignItems: "center",
    height: itemHeight,
    backgroundColor: "#fafafa",
    borderBottom: true,
    justifyContent: "center",
  };
}
export function StaticItem({ item, itemHeight, onRemove }) {
  return (
    <Pane {...dragItemStyles(itemHeight)}>
      <Pane minWidth={majorScale(6)} />
      <Text
        flexGrow="1"
        fontWeight="bold"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {item.text}
      </Text>
      <Pane display="flex" alignItems="center" paddingX={majorScale(2)}>
        {item.secondaryText && <Text color="muted">{item.secondaryText}</Text>}
        {item.canRemove && (
          <CrossIcon
            size={12}
            cursor="pointer"
            onClick={() => onRemove(item.key)}
          />
        )}
      </Pane>
    </Pane>
  );
}

StaticItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
  }).isRequired,
  itemHeight: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
};
