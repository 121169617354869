import { Fragment } from "react";
import { Calculation, Divider } from "components/materials";

export default function DocumentationBreakdown({ lineItem }) {
  const {
    grossRequestedAmount,
    grossDocumentedRequestedAmount,
    documentedRetainageAmount,
    retainageAmount,
  } = lineItem;

  return (
    <Fragment>
      <Calculation
        initialValue={grossRequestedAmount}
        initialLabel="Gross amount requested this draw"
        totalLabel="Difference"
      >
        <Calculation.Operation
          value={grossDocumentedRequestedAmount}
          operator="-"
          label="Supporting documents total"
        />
      </Calculation>

      {(retainageAmount !== 0 || documentedRetainageAmount !== 0) && (
        <Fragment>
          <Divider />
          <Calculation
            initialValue={retainageAmount}
            initialLabel="Retainage amount this draw"
            totalLabel="Difference"
          >
            <Calculation.Operation
              value={documentedRetainageAmount}
              operator="-"
              label="Supporting documents retainage total"
            />
          </Calculation>
        </Fragment>
      )}
    </Fragment>
  );
}
