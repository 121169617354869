import { useContext } from "react";
import { InterpolatedTranslation, Text } from "components/materials";
import { RULE_CONFIG_INPUT_TYPE } from "helpers/enums";
import { defaultRuleConfiguration, getRuleConfig } from "helpers/drawRules";
import { formatCurrency } from "helpers/formatCurrency";
import { map } from "lodash";
import { ThemeContext } from "helpers/utilities";
import t from "helpers/translate";

export function RuleLabel({ rule, ...props }) {
  const theme = useContext(ThemeContext);

  const defaultConfig = defaultRuleConfiguration(rule.name);
  const ruleWithConfig = {
    ...rule,
    config: getRuleConfig(defaultConfig, rule.config),
  };
  const { "data-testid": testId, ...remainingProps } = props;
  const testIdProps = testId ? { "data-testid": testId } : {};

  return (
    <div {...testIdProps}>
      <InterpolatedTranslation
        string={t(`drawRules.${ruleWithConfig.name}`)}
        values={map(ruleWithConfig.config, ({ value, type }, _varName) => {
          return (
            <Text
              key={value}
              fontWeight={theme.fontWeights.MEDIUM}
              {...remainingProps}
            >
              {type === RULE_CONFIG_INPUT_TYPE.CURRENCY
                ? formatCurrency(value)
                : value}
            </Text>
          );
        })}
        {...remainingProps}
      />
    </div>
  );
}

export function getPlainTextRuleLabel(rule) {
  const defaultConfig = defaultRuleConfiguration(rule.name);
  // currently automated rules can only hold one configurable value
  const config = Object.values(getRuleConfig(defaultConfig, rule.config))[0];
  const rawTranslation = t(`drawRules.${rule.name}`);

  if (!config) return rawTranslation;

  const configValue =
    config.type === RULE_CONFIG_INPUT_TYPE.CURRENCY
      ? formatCurrency(config.value)
      : config.value;

  return rawTranslation.replace("<?>", configValue);
}
