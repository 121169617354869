import PropTypes from "prop-types";
import { EvergreenCheckbox } from "components/materials";

export function Checkbox({ checked, ...rest }) {
  return (
    <EvergreenCheckbox
      checked={checked}
      data-checked={checked ? "true" : "false"}
      {...rest}
    />
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
};
