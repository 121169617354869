import { memo } from "react";
import PropTypes from "prop-types";
import { Table } from "components/materials";

function SlowDataTableCell({
  preparedColumn,
  preparedItem,
  preparedItems,
  rowIndex,
}) {
  const value = preparedColumn.value(preparedItem);
  const formatted = preparedColumn.valueFormatter(value, preparedItem, {
    ...preparedColumn,
    preparedItems,
    rowIndex,
    isGroupedValue: false,
  });
  return (
    <Table.TextCell
      cellProps={preparedColumn.cellProps}
      id={preparedColumn.id}
      testId={preparedColumn.testId}
      textAlign={preparedColumn.textAlign}
      textProps={preparedColumn.textProps}
      width={preparedColumn.width}
      state={
        typeof preparedColumn.state === "function"
          ? preparedColumn.state(preparedItem)
          : preparedColumn.state
      }
    >
      {formatted}
    </Table.TextCell>
  );
}

SlowDataTableCell.propTypes = {
  preparedColumn: PropTypes.object,
  preparedItem: PropTypes.object,
};

SlowDataTableCell.defaultProps = {};

export const FastDataTableCell = memo(SlowDataTableCell);
