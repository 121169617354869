import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const CREATE_DRAW_UPLOAD = gql`
  mutation CreateDocumentUpload(
    $id: String!
    $fileName: String!
    $pageCount: Int
    $split: Boolean
    $classify: Boolean
  ) {
    createDocumentUpload(
      drawId: $id
      fileName: $fileName
      pageCount: $pageCount
      split: $split
      classify: $classify
    ) {
      id
      uploadUrl
    }
  }
`;

const CREATE_PROJECT_UPLOAD = gql`
  mutation CreateDocumentUpload(
    $id: String!
    $fileName: String!
    $pageCount: Int
    $split: Boolean
    $classify: Boolean
  ) {
    createDocumentUpload(
      projectId: $id
      fileName: $fileName
      pageCount: $pageCount
      split: $split
      classify: $classify
    ) {
      id
      uploadUrl
    }
  }
`;

export default function useCreateDocumentUploadMutation(args) {
  const mutation = args.drawId ? CREATE_DRAW_UPLOAD : CREATE_PROJECT_UPLOAD;
  const id = args.drawId ?? args.projectId;

  return useMutation(mutation, {
    variables: {
      ...args,
      id,
    },
  });
}
