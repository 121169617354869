import { get } from "lodash";
import { Table, Text } from "components/materials";
import { formatDate } from "helpers/dateHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";

function VendorDocuments({ documents, onDocument }) {
  return documents.length > 0 ? (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TextHeaderCell content="Project" />
          <Table.TextHeaderCell content="Draw" />
          <Table.TextHeaderCell content="Type" />
          <Table.TextHeaderCell content="Date" />
          <Table.TextHeaderCell content="Amount" />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {documents.map((document) => {
          const amount =
            document.amount ||
            document.adjustedAmount ||
            document.agreementAmount;
          return (
            <Table.Row key={document.id} onClick={() => onDocument(document)}>
              <Table.TextCell content={document.project.name} />
              <Table.TextCell content={get(document, "draw.name", "-")} />
              <Table.TextCell
                content={t(`documentTypeName.${document.type}`)}
              />
              <Table.TextCell content={formatDate(document.insertedAt)} />
              <Table.TextCell content={formatCurrency(amount)} />
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  ) : (
    <Text>{t("vendorSidebar.noDocuments")}</Text>
  );
}

export default VendorDocuments;
