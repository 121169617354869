import PropTypes from "prop-types";
import { Button, Divider, Pane, Paragraph } from "components/materials";
import { newFundingSource } from "helpers/fundingSourceHelpers";
import t from "helpers/translate";

const FundingSourceBlankSlate = ({ divisions, form, setShowForm }) => (
  <Pane textAlign="center">
    <Paragraph size={500}>
      {t("fundingSources.noFundingSourcesLine1")}
    </Paragraph>
    <Paragraph size={500}>
      {t("fundingSources.noFundingSourcesLine2")}
    </Paragraph>
    <Divider />
    <Button
      appearance="primary"
      content="Add Funding Source"
      onClick={() => {
        const { values } = form;
        const {
          newFundingSourceGroup,
          newFundingSourceLineItems,
        } = newFundingSource(divisions);

        setShowForm(true);
        form.setFieldValue("fundingSourceGroups", [[newFundingSourceGroup]]);
        form.setFieldValue("fundingSource", {
          ...values.fundingSource,
          ...newFundingSourceLineItems,
        });
      }}
      purpose="funding-sources blank add"
    />
    <Divider />
  </Pane>
);

FundingSourceBlankSlate.propTypes = {
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  form: PropTypes.object.isRequired,
  setShowForm: PropTypes.func.isRequired,
};

export default FundingSourceBlankSlate;
