import { css } from "glamor";
import { add } from "helpers/math";
import { mergeWith } from "lodash";

// This was determined by looking at the CSS values set by Evergreen for the default button using the browser Inspect tool
// https://evergreen.segment.com/components/button
const buttonDifferences = {
  default: { r: -11, g: -10, b: -8 },
  hoverA: { r: -5, g: -4, b: -4 },
  hoverB: { r: -21, g: -19, b: -17 },
  active: { r: -243, g: -143, b: -53 },
};

export function getButtonBackgroundStylingProps(hex) {
  // TODO check if works in IE
  const rgb = hexToRGBObject(hex);
  return css({
    backgroundImage: `linear-gradient(to bottom, rgb(${rgb.r}, ${rgb.g}, ${
      rgb.b
    }), rgb(${determineNewRGBString(rgb, buttonDifferences.default)}))`,
    "&:not([disabled]):not([data-disabled]):hover": {
      backgroundImage: `linear-gradient(to bottom, rgb(${determineNewRGBString(
        rgb,
        buttonDifferences.hoverA
      )}), rgb(${determineNewRGBString(rgb, buttonDifferences.hoverB)}))`,
    },
    "&:not([disabled]):not([data-disabled]):active": {
      backgroundImage: "none",
      backgroundColor: `rgba(${determineNewRGBString(
        rgb,
        buttonDifferences.active
      )}, 0.09)`,
    },
  });
}

export function mergeIntoTheme(theme, values) {
  const { primaryLogo, ...newValues } = values;
  // If the incoming cobranding has a header color, generate the colors for Nav links
  if (newValues.headerColor) {
    generateCorrelatedHeaderColors(
      newValues,
      theme,
      newValues.headerColor === theme.rabbetHeaderColor
    );
  }
  mergeWith(theme.defaultColors, newValues, (defaultValue, newValue) => {
    return newValue || defaultValue;
  });
  theme.primaryLogo = primaryLogo ? primaryLogo.url : null;
}

function generateCorrelatedHeaderColors(values, theme, isRabbetDefault) {
  // stock Rabbet colors for the header on the HSL spectrum share the same Hue, but use differing saturation levels
  // The below saturation adjustments mimic that Rabbet color palate range ¯\_(ツ)_/¯
  const headerRGBColor = hexToRGBObject(values.headerColor);
  const headerHSLColor = rgbToHSLObject(headerRGBColor);

  values.headerLinkActive = decreaseSaturationAsHexFromHsl(headerHSLColor, 4);
  values.headerLinkActiveHover = decreaseSaturationAsHexFromHsl(
    headerHSLColor,
    4
  );

  values.headerLinkInactiveHover = decreaseSaturationAsHexFromHsl(
    headerHSLColor,
    5
  );
  values.headerLinkActiveBoxShadow = onFocusBoxShadowColor(headerRGBColor);
  // For all cobranding change background check to white
  values.headerButtonTextColor = isRabbetDefault
    ? theme.rabbetHeaderAltColor
    : "#ffffff";
  values.headerIconColor = isRabbetDefault
    ? theme.rabbetHeaderAltColor
    : "#ffffff";
  values.headerIconButtonColor = isRabbetDefault
    ? theme.rabbetHeaderAltColor
    : "#ffffff";
  values.headerTextOnHoverColor = isRabbetDefault
    ? theme.rabbetHeaderTextOnHoverColor
    : "#ffffff";
}

function determineNewRGBString(
  { r: rOriginal, g: gOriginal, b: bOriginal },
  { r: rDifference, g: gDifference, b: bDifference }
) {
  return [
    getValidRGBInteger(rOriginal, rDifference),
    getValidRGBInteger(gOriginal, gDifference),
    getValidRGBInteger(bOriginal, bDifference),
  ].join(", ");
}

function getValidRGBInteger(beginningInteger, difference) {
  const newInteger = add(beginningInteger, difference);
  if (newInteger > 255) return 255;
  if (newInteger < 0) return 0;
  return newInteger;
}

// Most of the components after the evergreen upgrade don't seem to like hsl
// so converting hsl to hex so that these custom colors can be used
function decreaseSaturationAsHexFromHsl(hsl, amount) {
  const { h, s, l } = hsl;
  // Came from previous function that changed the color saturation and
  // then converted the new hsl object to a string
  const saturation = s >= amount ? s - amount : 0;

  const hDecimal = l / 100;
  const a = (saturation * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function onFocusBoxShadowColor(rgb) {
  // stock Rabbet color for header button boxShadow uses RGBA spectrum
  // The below rgb adjustments mimic that Rabbet color palate range
  const red =
    rgb.r / 255 >= 0.9
      ? getValidRGBInteger(rgb.r, -30)
      : getValidRGBInteger(rgb.r, 30);
  const green =
    rgb.g / 255 >= 0.9
      ? getValidRGBInteger(rgb.g, -30)
      : getValidRGBInteger(rgb.g, 30);
  const blue =
    rgb.b / 255 >= 0.9
      ? getValidRGBInteger(rgb.b, -30)
      : getValidRGBInteger(rgb.b, 30);
  return `0px 0px 0px 3px rgba(${red}, ${green}, ${blue}, 0.3)`;
}

// https://css-tricks.com/converting-color-spaces-in-javascript/
function hexToRGBObject(hex) {
  if (hex.length === 4) {
    return {
      r: +`0x${hex[1]}${hex[1]}`,
      g: +`0x${hex[2]}${hex[2]}`,
      b: +`0x${hex[3]}${hex[3]}`,
    };
  }
  // else hex length is 7
  return {
    r: +`0x${hex[1]}${hex[2]}`,
    g: +`0x${hex[3]}${hex[4]}`,
    b: +`0x${hex[5]}${hex[6]}`,
  };
}

function rgbToHSLObject(rgb) {
  let h = 0;
  let s = 0;
  let l = 0;
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  const colorMin = Math.min(r, g, b);
  const colorMax = Math.max(r, g, b);
  const delta = colorMax - colorMin;

  if (delta !== 0) {
    if (colorMax === r) {
      h = ((g - b) / delta) % 6;
    } else if (colorMax === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }
  }
  h = Math.round(h * 60);
  if (h < 0) h += 360;

  l = (colorMax + colorMin) / 2;

  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  return { h, s, l };
}
