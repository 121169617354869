import gql from "graphql-tag";

export const UPLOADS_VIEWER_FRAGMENT = gql`
  fragment UploadsViewerFragment on OriginalDocument {
    id
    pages
    processingFinishedAt
    toBeSplit
    hasMixedTargets
    file {
      name
      url
      type
    }
    documents {
      id
      type
      pages
      processingFinishedAt
      state
      vendor {
        id
        name
      }
      ... on AgreementDocument {
        id
        agreement {
          id
          budgetAdjustment {
            id
          }
        }
      }
    }
    draw {
      id
      name
    }
  }
`;

export const UPLOADS_SPLITTER_FRAGMENT = gql`
  fragment UploadsSplitterFragment on OriginalDocument {
    id
    pages
    toBeSplit
    hasMixedTargets
    file {
      name
      url
      type
    }
    documents {
      id
      pages
    }
    draw {
      id
      name
    }
  }
`;

export const UPLOADS_PROCESSING_FINISHED_FRAGMENT = gql`
  fragment UploadsProcessingFinishedFragment on OriginalDocument {
    id
    processingFinishedAt
    documents {
      id
      type
      pages
      state
      processingFinishedAt
      vendor {
        id
        name
      }
    }
  }
`;

export const UPLOADS_FRAGMENT = gql`
  fragment UploadsFragment on OriginalDocument {
    id
    documents {
      id
      state
    }
    draw {
      id
    }
    file {
      url
      name
      type
    }
    isHidden
    parsedDatapointCount
    processingFinishedAt
    projectId
    state
    toBeSplit
  }
`;

// This object's shape needs to match the query
export function uploadsFragmentCacheObject(id, file, state, variables) {
  return {
    __typename: "OriginalDocument",
    id,
    ...(variables.drawId && {
      draw: {
        __typename: "Draw",
        id: variables.drawId,
      },
    }),
    documents: [],
    file: {
      __typename: "File",
      url: file.name,
      name: file.name,
      type: file.type,
    },
    isHidden: false,
    parsedDatapointCount: 0,
    processingFinishedAt: null,
    projectId: variables.projectId,
    state,
    toBeSplit: false,
  };
}
