import { useState } from "react";
import { debounce } from "lodash";
import { isInternetExplorer, isEdge } from "helpers/browserHelpers";

function createScrollSync() {
  const doSyncScroll = (refs, target) => {
    const position = target.scrollLeft;

    if (refs.every((r) => r.scrollLeft === position)) return;

    refs.forEach((ref) => {
      ref.scrollLeft = position;
    });
  };

  return isEdge || isInternetExplorer
    ? debounce(doSyncScroll, 10)
    : doSyncScroll;
}

export function useSyncScroll() {
  const [firstScrollRef, setFirstScrollRef] = useState(null);
  const [secondScrollRef, setSecondScrollRef] = useState(null);
  const scrollRefs = [firstScrollRef, secondScrollRef];
  const syncScroll = createScrollSync();

  function onScroll({ target }) {
    return syncScroll(scrollRefs, target);
  }

  return {
    getFirstScrollRef: setFirstScrollRef,
    getSecondScrollRef: setSecondScrollRef,
    onScroll,
    scrollRefs,
  };
}
