import { useEffect, useState } from "react";
import { Pane } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { ChartTemplate } from "../ChartTemplate";

const initialData = {
  labels: [],
  datasets: [{ data: [] }],
};

function generateValues(fundingSource, showUpdatedProjection) {
  return fundingSource.values.map((period) =>
    showUpdatedProjection ? period.updatedProjection : period.projected
  );
}

export function FundingSourceProjectionChart({
  chartId,
  projection,
  setChartDownloadImage,
}) {
  const [data, setData] = useState(initialData);
  const { chartFundingSources, project, views } = projection;

  const showUpdatedProjection =
    views?.fundingSourceChart === "updatedProjection";

  const { months } = project;

  useEffect(() => {
    const datasets = chartFundingSources
      .map((fundingSource, index) => ({
        data: generateValues(fundingSource, showUpdatedProjection),
        backgroundColor: fundingSource.color,
        hoverBackgroundColor: fundingSource.color,
        order: index,
        stack: "fundingForMonth",
        label: fundingSource.name,
      }))
      .filter(({ data }) => data.some((value) => value !== 0));

    const updatedData = {
      labels: months,
      datasets,
    };
    setData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...chartFundingSources, showUpdatedProjection]);

  return (
    <Pane>
      <ChartTemplate
        chartId={chartId}
        datasets={data.datasets}
        height={375}
        isStacked
        labels={data.labels}
        setChartDownloadImage={setChartDownloadImage}
        type="bar"
        valueFormatter={(value) => formatCurrency(value)}
      />
    </Pane>
  );
}
