import gql from "graphql-tag";

export const SLIDEOUT_DOCUMENTS_FRAGMNENT = gql`
  fragment SlideoutDocumentsFragment on Document {
    id
    amount
    grossAmount
    draw {
      id
      name
      createdAt
    }
    originalFile {
      name
      url
    }
    pages
    processingFinishedAt
    project {
      id
    }
    state
    type
    file {
      name
      url
    }
    vendor {
      id
      name
    }
    ... on DrawSummary {
      drawSummaryAmount
    }
    ... on AgreementDocument {
      adjustedAmount
      agreementAmount
    }
  }
`;
