import { Fragment, useContext } from "react";
import { Card, Link, Pane, Paragraph, Table, Text } from "components/materials";
import { formatDate } from "helpers/dateHelpers";
import { majorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";

export function ProjectTasksOverview({ project, history }) {
  const { tasks } = project;
  const theme = useContext(ThemeContext);
  const hasTasks = tasks.length > 0;

  function navigateToTasks() {
    history.push(`${project.id}/timeline`);
  }

  function navigateToTask(taskId) {
    history.push(`${project.id}/timeline/${taskId}`);
  }

  return (
    <Fragment>
      <Card
        display="flex"
        flexDirection="column"
        height={210}
        marginTop={majorScale(2)}
        padding={majorScale(2)}
      >
        <Pane display="flex" justifyContent="flex-end">
          <Text
            flex="1 1 auto"
            size={300}
            fontWeight={theme.fontWeights.MEDIUM}
          >
            Tasks
          </Text>
          {hasTasks && (
            <Link size={300} onClick={navigateToTasks}>
              Edit Tasks
            </Link>
          )}
        </Pane>
        {hasTasks ? (
          <Pane overflowY="auto" marginTop={majorScale(1)}>
            <TasksTable tasks={tasks} navigateToTask={navigateToTask} />
          </Pane>
        ) : (
          <Pane
            textAlign="center"
            height="100%"
            marginTop={majorScale(5)}
            width="100%"
          >
            <Paragraph>This project does not have any tasks.</Paragraph>
            <Link onClick={navigateToTasks}>Add Tasks</Link>
          </Pane>
        )}
      </Card>
    </Fragment>
  );
}

function TasksTable({ tasks, navigateToTask }) {
  return (
    <Table paddingBottom={0}>
      <Table.Body>
        {tasks.map((task) => {
          return (
            <Table.Row key={task.id}>
              <Table.TextCell
                width={200}
                maxWidth={200}
                onClick={() => navigateToTask(task.id)}
              >
                {task.eventName}
              </Table.TextCell>
              <Table.TextCell onClick={() => navigateToTask(task.id)}>
                {formatDate(task.originalStartDate)}
              </Table.TextCell>
              <Table.TextCell onClick={() => navigateToTask(task.id)}>
                {t(`taskStatus.${task.status}`)}
              </Table.TextCell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
