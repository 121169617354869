import { find, flatMap, get, isEqual, meanBy, uniqWith } from "lodash";
import { TickCircleIcon } from "evergreen-ui";
import { sumBy } from "helpers/math";
import {
  booleanColumnDefaults,
  currencyColumnDefaults,
  dateColumnDefaults,
  numberColumnDefaults,
  percentColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { CUSTOM_FIELD_TYPE } from "helpers/enums";
import unformatNumber from "helpers/unformatNumber";
import formatPercent from "helpers/formatPercent";
import { dateFormToServer } from "helpers/dateHelpers";

function getFastColumn({ label, type }, path) {
  const commonProps = {
    header: label,
    id: `customFields-${label}-${type}`,
    category: "Custom Fields",
  };

  switch (type) {
    case CUSTOM_FIELD_TYPE.BOOLEAN: {
      return {
        ...booleanColumnDefaults,
        ...commonProps,
        aggregate: (groupItems, groupName) => groupName === "true",
        aggregateFormatter: (value) =>
          value && <TickCircleIcon color="success" />,
        value: (item) => {
          const field = find(get(item, path, []), { label, type });
          const value = get(field, "value", null);
          return value === "true";
        },
        valueFormatter: (value, _items, options) => {
          if (options.isGroupedValue) return value;
          return value && <TickCircleIcon color="success" />;
        },
      };
    }
    case CUSTOM_FIELD_TYPE.DATE: {
      return {
        ...dateColumnDefaults,
        ...commonProps,
        groupable: true,
        value: (item) => {
          const field = find(get(item, path, []), { label, type });
          const value = get(field, "value", null);
          return dateFormToServer(value);
        },
      };
    }
    case CUSTOM_FIELD_TYPE.MONEY: {
      return {
        ...currencyColumnDefaults,
        ...commonProps,
        aggregate: (groupItems) =>
          sumBy(groupItems, (item) => {
            const field = find(get(item, path, []), { label, type });
            const value = get(field, "value", null);
            return unformatNumber(value);
          }),
        groupable: true,
        value: (item) => {
          const field = find(get(item, path, []), { label, type });
          const value = get(field, "value", null);
          return unformatNumber(value);
        },
      };
    }
    case CUSTOM_FIELD_TYPE.INTEGER:
    case CUSTOM_FIELD_TYPE.NUMBER: {
      return {
        ...numberColumnDefaults,
        ...commonProps,
        aggregate: (groupItems) =>
          sumBy(groupItems, (item) => {
            const field = find(get(item, path, []), { label, type });
            const value = get(field, "value", null);
            return unformatNumber(value);
          }),
        groupable: true,
        value: (item) => {
          const field = find(get(item, path, []), { label, type });
          const value = get(field, "value", null);
          return unformatNumber(value);
        },
      };
    }
    case CUSTOM_FIELD_TYPE.PERCENT: {
      return {
        ...percentColumnDefaults,
        ...commonProps,
        aggregate: (groupItems) =>
          meanBy(groupItems, (item) => {
            const field = find(get(item, path, []), { label, type });
            const value = get(field, "value", null);
            return unformatNumber(value);
          }),
        groupable: true,
        value: (item) => {
          const field = find(get(item, path, []), { label, type });
          const value = get(field, "value", null);
          return unformatNumber(value);
        },
        valueFormatter: (value, _items, options) => {
          if (options.isGroupedValue)
            return formatPercent(unformatNumber(value));
          return formatPercent(value);
        },
      };
    }
    case CUSTOM_FIELD_TYPE.TEXT: {
      return {
        ...stringColumnDefaults,
        ...commonProps,
        aggregate: (groupItems, groupValue) => groupValue,
        groupable: true,
        value: (item) => {
          const field = find(get(item, path, []), { label, type });
          const value = get(field, "value", null);
          if (!value) return "-";
          return value;
        },
      };
    }
    case CUSTOM_FIELD_TYPE.DROPDOWN: {
      return {
        ...stringColumnDefaults,
        ...commonProps,
        aggregate: (groupItems, groupValue) => groupValue,
        groupable: true,
        value: (item) => {
          const field = find(get(item, path, []), { label, type });
          const value = get(field, "value", null);
          if (!value) return "-";
          return value;
        },
      };
    }
    default: {
      return null;
    }
  }
}

export function customFieldFastColumns(items, path) {
  const fields = flatMap(items, (item) =>
    get(item, path, []).map(({ label, type }) => ({ label, type }))
  );
  return uniqWith(fields, isEqual).map((field) => getFastColumn(field, path));
}
