import gql from "graphql-tag";

export default gql`
  fragment DocumentFragment on Document {
    id
    pages
    type
    draw {
      id
      name
    }
    file {
      name
      type
      url
    }
  }
`;
