import { useState, Fragment } from "react";
import { Alert, Link, Pane, Text } from "components/materials";
import t from "helpers/translate";
import { groupBy, includes, map, xor } from "lodash";
import { majorScale } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";

export function OverdrawnLineItems({ overdrawnLineItems }) {
  const [expandedDivisions, setExpandedDivisions] = useState([]);
  const groupedLineItems = groupBy(overdrawnLineItems, "division.name");

  const toggleDivision = (divisionName) => {
    setExpandedDivisions(xor(expandedDivisions, [divisionName]));
  };

  return (
    <Alert
      intent="warning"
      title={t("adjustmentsOverlay.overdrawnLineItems", {
        count: overdrawnLineItems.length,
      })}
      marginBottom={majorScale(2)}
    >
      {map(groupedLineItems, (lineItems, divisionName) => {
        const shownItems = lineItems.slice(0, 4);
        const unshownItemCount = lineItems.length - shownItems.length;

        return (
          <Pane key={divisionName} display="flex">
            <Text marginRight={majorScale(1)} flex="0 0 auto">
              {divisionName}:
            </Text>
            <Pane>
              {includes(expandedDivisions, divisionName) ? (
                <Fragment>
                  {lineItems.map(({ id, name, balanceToFundAmount }) => (
                    <Pane key={id}>
                      <Text fontWeight={500}>{name}</Text>
                      <Text>
                        {` - ${formatCurrency(
                          Math.abs(balanceToFundAmount)
                        )} overdrawn`}
                      </Text>
                    </Pane>
                  ))}
                  <Link onClick={() => toggleDivision(divisionName)}>
                    {t("adjustmentsOverlay.hideAdditionalOverdrawnItems", {
                      divisionName,
                    })}
                  </Link>
                </Fragment>
              ) : (
                <Fragment>
                  {shownItems.map(
                    ({ id, name, balanceToFundAmount }, index) => {
                      const addComma =
                        unshownItemCount > 0 || index < shownItems.length - 1;
                      return (
                        <Fragment key={id}>
                          <Text fontWeight={500}>{name}</Text>
                          <Text>
                            {` - ${formatCurrency(
                              Math.abs(balanceToFundAmount)
                            )} overdrawn${addComma ? ", " : ""}`}
                          </Text>
                        </Fragment>
                      );
                    }
                  )}
                  {unshownItemCount > 0 && (
                    <Link onClick={() => toggleDivision(divisionName)}>
                      {t("adjustmentsOverlay.showAdditionalOverdrawnItems", {
                        count: unshownItemCount,
                      })}
                    </Link>
                  )}
                </Fragment>
              )}
            </Pane>
          </Pane>
        );
      })}
    </Alert>
  );
}
