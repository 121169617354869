import { getSanitizedNumbro, subtract } from "./math";

export const formatCurrency = (
  amount,
  { average, withoutCurrencySymbol, shortenedFormat } = {}
) => {
  const parsedNumber = getSanitizedNumbro(amount);

  const options = {
    average:
      shortenedFormat && Math.abs(parsedNumber) >= 1000 ? true : !!average,
    thousandSeparated: true,
    mantissa: shortenedFormat && Math.abs(parsedNumber) >= 1000 ? 1 : 2,
  };

  return !withoutCurrencySymbol
    ? parsedNumber.formatCurrency(options)
    : parsedNumber.format(options);
};

export const formatShortenedCurrency = (value) =>
  formatCurrency(value, { shortenedFormat: true });

export const formatCurrencyDifference = (amountOne, amountTwo) => {
  const difference = subtract(amountOne, amountTwo);
  const formatted = formatCurrency(difference);

  if (difference > 0) {
    return `+${formatted}`;
  }

  return formatted;
};
