import { Button, Form, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";
import { preventEventBubbling } from "helpers/preventEventBubbling";

export function EditConfigurationButtons({ dirty, form, loading, onSubmit }) {
  if (!dirty) return null;

  return (
    <Pane display="flex">
      <Pane>
        <Button
          onClick={(e) => {
            preventEventBubbling(e);
            form.handleReset();
          }}
          marginRight={majorScale(2)}
          disabled={loading}
        >
          Undo Changes
        </Button>
      </Pane>
      <Pane>
        <Form.SubmitButton
          onClick={(e) => {
            preventEventBubbling(e);

            onSubmit ? onSubmit(form) : form.handleSubmit(form);
          }}
          isLoading={loading}
          label="Save Changes"
        />
      </Pane>
    </Pane>
  );
}
