import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Shortener, Paragraph, Text } from "components/materials";
import { PortfolioProjectCardContent } from "components/templates";
import { minorScale } from "helpers/utilities";
import { getActiveDrawInformation } from "../PortfolioDashboard/PortfolioProjectCard";

export function DrawInformation({ draw }) {
  return (
    <Paragraph lineHeight={1} marginTop={minorScale(1)} alignItems="center">
      {draw ? (
        <Shortener
          limit={42}
          fontSize={12}
          text={getActiveDrawInformation(draw)}
        />
      ) : (
        <Text lineHeight={1} size={300}>
          No Active Draws
        </Text>
      )}
    </Paragraph>
  );
}

export function BorrowerPortfolioProjectCard({ project }) {
  const history = useHistory();

  function onCardClick() {
    history.push(`/borrower/projects/${project.id}`);
  }

  return (
    <PortfolioProjectCardContent onClick={onCardClick} project={project}>
      <DrawInformation draw={project.recentUnfundedDraw} />
    </PortfolioProjectCardContent>
  );
}

BorrowerPortfolioProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
};
