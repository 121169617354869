import { useState, useContext, Fragment } from "react";
import { v4 as uuid } from "uuid";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { get } from "lodash";
import { BudgetDropzone } from "components/templates";
import {
  InterpolatedTranslation,
  Link,
  Loadable,
  Pane,
  Text,
} from "components/materials";
import t from "helpers/translate";
import { formatCurrency } from "helpers/formatCurrency";
import {
  majorScale,
  minorScale,
  ThemeContext,
  toaster,
} from "helpers/utilities";
import { STEPS } from "./utils";
import { Header } from "./Header";

const MUTATION = gql`
  mutation UploadBudgetTemplate($file: Upload!, $projectId: String!) {
    uploadBudgetTemplate(file: $file, projectId: $projectId) {
      id
      data
    }
  }
`;

const QUERY = gql`
  query UploadParserStepQuery($projectId: String!) {
    project(id: $projectId) {
      id
      budgetParser {
        id
        name
        projects {
          id
          name
        }
      }
      template {
        id
        budgetParser {
          id
          name
          projects {
            id
            name
          }
        }
      }
    }
  }
`;

const handleError = (message, duration) => {
  toaster.danger(message, {
    duration,
  });
};

function parseError(result, theme) {
  const couldNotParse =
    get(result, "message").indexOf("could_not_parse") !== -1;

  const message = couldNotParse ? (
    <InterpolatedTranslation
      fontSize={13}
      fontWeight={theme.fontWeights.MEDIUM}
      string={t("onboardingWizard.uploadParserStep.parseError")}
      values={[
        <Link
          fontSize={13}
          href="mailto:help@rabbet.com"
          marginX={minorScale(1)}
          purpose="project-wizard custom-parser parse-error help"
        >
          {t("onboardingWizard.uploadParserStep.parseHelpLink")}
        </Link>,
      ]}
    />
  ) : null;

  const duration = couldNotParse ? 30 : 2.5;

  return { message, duration };
}

export function UploadParserStep({
  navigateToStep,
  project,
  setUseTemplate,
  updateOnboardingData,
}) {
  const [upload, { loading: uploadLoading }] = useMutation(MUTATION, {
    onCompleted: (result) => {
      const divisions = get(
        result,
        "uploadBudgetTemplate.data.divisions",
        []
      ).map((division) => ({
        id: uuid(),
        name: division.name || "",
        lineItems: division.lineItems.map((lineItem) => ({
          id: uuid(),
          name: lineItem.name || "",
          amount: formatCurrency(lineItem.originalAmount || 0),
        })),
      }));

      updateOnboardingData({ budget: { divisions } }).then(() =>
        navigateToStep(STEPS.ENTER_BUDGET)
      );
    },
    onError: (result) => {
      const { message, duration } = parseError(result, theme);
      setUploadedBudget(null);
      handleError(message, duration);
    },
  });
  const { data, loading } = useQuery(QUERY, {
    variables: { projectId: project.id },
  });

  const [uploadedBudget, setUploadedBudget] = useState(null);

  const theme = useContext(ThemeContext);

  if (loading) return <Loadable loading />;

  const parserOnProject = get(data, "project.budgetParser.id");

  const onAdd = (file) => {
    if (file.length > 1) {
      handleError(t("onboardingWizard.uploadParserStep.uploadError"), 2.5);
    } else {
      const uploadFile = file[0];
      const variables = {
        projectId: project.id,
        file: uploadFile,
      };
      setUploadedBudget(uploadFile);
      upload({ variables });
    }
  };

  const parserName = parserOnProject
    ? get(data, "project.budgetParser.name")
    : get(data, "project.template.budgetParser.name");
  const parserProjects = parserOnProject
    ? get(data, "project.budgetParser.projects", [])
    : get(data, "project.template.budgetParser.projects", []);

  return (
    <Fragment>
      <Header
        header={t("onboardingWizard.uploadParserStep.header")}
        subheader={t("onboardingWizard.uploadParserStep.subheader")}
      />
      <Pane
        display="flex"
        justifyContent="center"
        marginBottom={majorScale(3)}
        width="100%"
      >
        <Pane
          border="muted"
          borderRadius={majorScale(1)}
          display="flex"
          flexDirection="column"
          marginRight={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(2)}
          width="50%"
        >
          <Text
            fontSize={18}
            fontWeight={theme.fontWeights.MEDIUM}
            marginBottom={majorScale(1)}
          >
            {parserName}
          </Text>
          <Text marginBottom={majorScale(1)}>
            {t("onboardingWizard.uploadParserStep.explanation")}
          </Text>
          {parserProjects.length > 0 ? (
            parserProjects.map(
              (parserProject) =>
                parserProject.id !== project.id && (
                  <Text
                    key={parserProject.id}
                    fontSize={12}
                    fontWeight={theme.fontWeights.MEDIUM}
                    marginLeft={majorScale(4)}
                  >
                    {parserProject.name}
                  </Text>
                )
            )
          ) : (
            <Text
              fontSize={12}
              fontWeight={theme.fontWeights.MEDIUM}
              marginLeft={majorScale(4)}
            >
              -
            </Text>
          )}
        </Pane>
        <Pane width="50%">
          <BudgetDropzone
            buttonAppearance="primary"
            dropzoneStyling={{ height: "100%", paddingX: majorScale(1) }}
            loading={uploadLoading}
            onAdd={onAdd}
            photoPaneStyling={{ marginRight: majorScale(1) }}
            uploadedBudget={uploadedBudget}
          />
        </Pane>
      </Pane>
      <Pane
        backgroundColor="#f3f8fe"
        display="flex"
        justifyContent="center"
        padding={minorScale(3)}
      >
        <Pane>
          <InterpolatedTranslation
            fontSize={13}
            fontWeight={theme.fontWeights.MEDIUM}
            string={t("onboardingWizard.uploadParserStep.help")}
            values={[
              <Link
                fontSize={13}
                href="mailto:help@rabbet.com"
                marginX={minorScale(1)}
                purpose="project-wizard custom-parser help"
              >
                {t("onboardingWizard.uploadParserStep.helpLink")}
              </Link>,
              <Link
                fontSize={13}
                onClick={() => setUseTemplate(true)}
                marginX={minorScale(1)}
                purpose="project-wizard custom-parser use-default"
              >
                {t("onboardingWizard.uploadParserStep.defaultTemplate")}
              </Link>,
            ]}
          />
        </Pane>
      </Pane>
    </Fragment>
  );
}
