import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const MUTATION = gql`
  mutation PreviewInvoice(
    $data: Map!
    $drawId: String
    $projectId: String!
    $templateId: String!
  ) {
    createFileFromTemplate(
      data: $data
      drawId: $drawId
      projectId: $projectId
      templateId: $templateId
    ) {
      name
      type
      url
    }
  }
`;

export default function usePreviewMutation(options) {
  return useMutation(MUTATION, options);
}
