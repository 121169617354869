import { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ShareIcon } from "evergreen-ui";
import {
  Button,
  Form,
  Link,
  Pane,
  Paragraph,
  Switch,
  Table,
  Text,
} from "components/materials";
import { get, values } from "lodash";
import { DocumentContext } from "contexts/documentContext";
import { add, subtract, sumBy } from "helpers/math";
import { UserContext } from "helpers/behaviors";
import { AGREEMENT_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import unformatNumber from "helpers/unformatNumber";
import { majorScale, minorScale } from "helpers/utilities";

export function shouldShowTrackCostToAgreementsSection({
  isTrackingCostToAgreements,
  form,
  disableTrackCostToAgreements,
}) {
  if (!isTrackingCostToAgreements) return false;
  if (disableTrackCostToAgreements) return false;

  // if this document was backup and is still backup, suppress
  return !(form.initialValues.isBackup && form.values.isBackup);
}

export function shouldShowTrackCostToAgreementsWarnings({
  isTrackingCostToAgreements,
  limitTrackedAgreementWarnings,
  projectId,
  vendors,
  values,
}) {
  if (!isTrackingCostToAgreements) return false;
  if (values.isBackup) return false;

  if (limitTrackedAgreementWarnings) {
    if (!values?.vendor?.id) return false;
    const vendor = vendors.find(({ id }) => id === values.vendor.id);
    return vendor
      ? (vendor.agreementProjectIds ?? []).includes(projectId)
      : false;
  }

  return true;
}

export function TrackCostToAgreements(props) {
  const {
    collapsedPanels,
    document: { id: documentId },
    form,
    sectionHeading,
    selectedType: documentType,
    togglePanel,
  } = props;
  const {
    values: {
      agreementAmounts,
      isBackup,
      project: { id: projectId },
      vendor: { id: vendorId, name: vendorName },
    },
  } = form;
  const {
    getAgreementsQuery,
    agreementsQuery,
    updateHideExhaustedAgreements,
  } = useContext(DocumentContext);
  const { data } = agreementsQuery;
  const {
    user: { hideExhaustedAgreementsInDocReview },
    hasPermission,
  } = useContext(UserContext);

  const allowTrackingAcrossVendors = hasPermission(
    PERMISSION_ACTION.ENABLE_TRACKED_COSTS_ACROSS_VENDORS
  );

  const [hideExhaustedAgreements, setHideExhaustedAgreements] = useState(
    hideExhaustedAgreementsInDocReview
  );

  useEffect(() => {
    if (hideExhaustedAgreements !== hideExhaustedAgreementsInDocReview) {
      updateHideExhaustedAgreements({ variables: { hideExhaustedAgreements } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideExhaustedAgreements]);

  useEffect(() => {
    if (vendorId !== null && !isBackup) {
      getAgreementsQuery({
        variables: { vendorId, documentId, projectId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId, isBackup]);

  // Hack because can't use onCompleted with an instantiation of useLazyQuery
  // and can't add it to the definition, because it requires form which is out of scope
  useEffect(() => {
    if (data) {
      setFormAgreementAmounts(form, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // checking for vendorId correctly resets trackableAgreements to an
  // empty list when form is user-reset to null vendorId, even though query is skipped
  const trackableAgreements =
    (vendorId && !isBackup && data?.project?.trackableAgreements) || [];

  const hasExhaustedAgreements = values(agreementAmounts).some(
    ({ maxAmountToAllocate }) => maxAmountToAllocate <= 0
  );

  const isCollapsed = collapsedPanels.includes("agreements");
  return (
    <Form.Section
      heading={sectionHeading}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={() => togglePanel("agreements")}
      padding={0}
    >
      {/* If initialValues.isBackup, this component is not rendered.  The following only
      gates when isBackup set to true during doc edit */}
      {isBackup ? (
        <Pane
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height={150}
        >
          <Text>{t("documentReview.noCostTrackingWhenDocumentIsBackup")}</Text>
        </Pane>
      ) : (
        <Fragment>
          {trackableAgreements.length === 0 ? (
            <TrackableAgreementsEmptySlate
              linkToAgreements={`/projects/${projectId}/agreements/`}
              // only use vendorName if vendorId exists (auto assign related)
              vendorName={vendorId && vendorName}
              allowTrackingAcrossVendors={allowTrackingAcrossVendors}
            />
          ) : (
            <Fragment>
              {hasExhaustedAgreements && (
                <Pane
                  alignItems="center"
                  display="flex"
                  justifyContent="flex-end"
                  margin={majorScale(1)}
                >
                  <Switch
                    checked={hideExhaustedAgreements}
                    hasCheckIcon
                    marginRight={majorScale(1)}
                    onChange={() =>
                      setHideExhaustedAgreements((checked) => !checked)
                    }
                  />
                  <Text size={300}>Hide exhausted agreements</Text>
                </Pane>
              )}
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                    <Table.TextHeaderCell width="15%">
                      Type
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell textAlign="right" width="15%">
                      Amount Available
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell textAlign="right" width="15%">
                      {`This ${t(`documentTypeName.${documentType}`)}`}
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell textAlign="right" width="15%">
                      Amount Remaining
                    </Table.TextHeaderCell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {trackableAgreements.map((agreement) => (
                    <TrackableAgreementRow
                      key={agreement.id}
                      agreement={agreement}
                      agreementLink={`/projects/${projectId}/agreements/${agreement.id}`}
                      form={form}
                      hideExhaustedAgreements={hideExhaustedAgreements}
                    />
                  ))}
                  <TrackableAgreementTotalRows form={form} />
                </Table.Body>
              </Table>
            </Fragment>
          )}
        </Fragment>
      )}
    </Form.Section>
  );
}

function TrackableAgreementTotalRows({ form }) {
  const agreementAmounts = values(form.values.agreementAmounts);
  const { currentPaymentDue, lineItems } = form.values;

  const invoiceAmount =
    sumBy(lineItems, "amount") ||
    sumBy(lineItems, "applicationAmount") ||
    unformatNumber(currentPaymentDue);

  const totalAmountAvailable = sumBy(agreementAmounts, "maxAmountToAllocate");
  const totalAmountAllocated = sumBy(agreementAmounts, "amount");

  const notTiedToAgreements = subtract(invoiceAmount, totalAmountAllocated);
  const totalAmountRemaining = subtract(
    totalAmountAvailable,
    totalAmountAllocated
  );

  const fontProps = { textProps: { fontWeight: 500 } };

  return (
    <Fragment>
      <Table.Row state="special">
        <Table.TextCell colSpan={3} {...fontProps}>
          Not Tied to Agreements
        </Table.TextCell>
        <Table.TextCell textAlign="right" {...fontProps}>
          {formatCurrency(notTiedToAgreements)}
        </Table.TextCell>
        <Table.TextCell />
      </Table.Row>
      <Table.Row>
        <Table.TextCell colSpan={2} textAlign="right" {...fontProps}>
          TOTAL
        </Table.TextCell>
        <Table.TextCell textAlign="right" {...fontProps}>
          {formatCurrency(totalAmountAvailable)}
        </Table.TextCell>
        <Table.TextCell />
        <Table.TextCell textAlign="right" {...fontProps}>
          {formatCurrency(totalAmountRemaining)}
        </Table.TextCell>
      </Table.Row>
    </Fragment>
  );
}

function TrackableAgreementRow({
  agreement,
  agreementLink,
  form,
  hideExhaustedAgreements,
}) {
  const {
    amount: originalAmount,
    id: agreementId,
    name,
    spentToDate,
    spentForDocument,
    type: agreementType,
  } = agreement;

  const currentSpentForDocument = unformatNumber(
    get(form.values, `agreementAmounts.${agreementId}.amount`, 0)
  );

  // amount available on agreement not including this invoice
  const amountAvailable = add(
    subtract(originalAmount, spentToDate),
    spentForDocument
  );

  // amount remaining on the agreement after this invoice
  const amountRemaining = subtract(amountAvailable, currentSpentForDocument);

  const overbilled = amountRemaining < 0;

  const hasInvalidAmount =
    form?.errors?.agreementAmounts?.[`${agreementId}`] || overbilled;

  if (hideExhaustedAgreements && amountRemaining <= 0) {
    return null;
  }

  return (
    <Table.Row>
      <Table.TextCell>
        <Link
          href={agreementLink}
          size={300}
          display="inline-flex"
          alignItems="center"
        >
          {name}
          <ShareIcon size="12" marginLeft={minorScale(1)} marginBottom={2} />
        </Link>
      </Table.TextCell>
      <Table.TextCell>{t(`agreementType.${agreementType}`)}</Table.TextCell>
      <Table.TextCell textAlign="right">
        {formatCurrency(amountAvailable)}
      </Table.TextCell>
      <Table.TextCell width="15%">
        {agreementType !== AGREEMENT_TYPE.EXPOSURE && (
          <Form.Field
            validationMessage={overbilled && "Cannot overbill agreement"}
          >
            <Form.Input
              isInvalid={hasInvalidAmount}
              name={`agreementAmounts.${agreementId}.amount`}
              textAlign="right"
              type="currency"
            />
          </Form.Field>
        )}
      </Table.TextCell>
      <Table.TextCell textAlign="right">
        {formatCurrency(amountRemaining)}
      </Table.TextCell>
    </Table.Row>
  );
}

function TrackableAgreementsEmptySlate({
  linkToAgreements,
  vendorName,
  allowTrackingAcrossVendors,
}) {
  return (
    <Pane
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height={150}
    >
      <Paragraph marginBottom={majorScale(1)}>
        {getEmptyText(vendorName, allowTrackingAcrossVendors)}
      </Paragraph>
      <Link href={linkToAgreements} underline={false}>
        <Button>
          <Text size={300}>View All Agreements</Text>
          <ShareIcon size={10} marginLeft={minorScale(1)} />
        </Button>
      </Link>
    </Pane>
  );
}

function getEmptyText(vendorName, allowTrackingAcrossVendors) {
  if (vendorName === null) return "Select a Vendor to Track Cost to Agreements";
  if (allowTrackingAcrossVendors)
    return "There are no agreements on the project";
  return `${vendorName} has no agreements`;
}

function setFormAgreementAmounts(form, data) {
  const returnedAgreements = get(data, "project.trackableAgreements");

  const newAgreementAmounts = returnedAgreements.reduce(
    (
      acc,
      { id: agreementId, amount: originalAmount, spentToDate, spentForDocument }
    ) => {
      const amountAvailable = add(
        subtract(originalAmount, spentToDate),
        spentForDocument
      );
      return {
        ...acc,
        [agreementId]: {
          amount: formatCurrency(spentForDocument),
          maxAmountToAllocate: amountAvailable,
          originalAmount,
        },
      };
    },
    {}
  );

  form.setFieldValue("agreementAmounts", newAgreementAmounts);
}

TrackCostToAgreements.defaultProps = {
  collapsedPanels: [],
  name: "agreements",
  sectionHeading: "Agreements",
};

TrackCostToAgreements.propTypes = {
  collapsedPanels: PropTypes.array,
  name: PropTypes.string,
  sectionHeading: PropTypes.string,
};
