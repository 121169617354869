import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { ChevronDownIcon, ChevronRightIcon, DownloadIcon } from "evergreen-ui";
import { Checkbox, Table, Link, Text } from "components/materials";
import { get, includes } from "lodash";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

class DocumentSelector extends PureComponent {
  componentDidMount() {
    const { documents, onSelectDocument, setInitialSelections } = this.props;

    if (setInitialSelections) {
      documents.forEach((document) => {
        if (this.isDocumentDefaultSelected(document)) {
          onSelectDocument(document);
        }
      });
    }
  }

  isDocumentDefaultSelected = (document) => {
    const { disabledDocumentTypeNames } = this.props;

    return (
      includes(disabledDocumentTypeNames, document.type) ||
      (!!get(document, "vendor.id") &&
        document.state !== "IGNORED" &&
        document.isAcknowledged)
    );
  };

  handleToggleDocumentType = (documentType) => {
    const { onExpandDocumentType, onCollapseDocumentType } = this.props;
    if (this.isDocumentTypeExpanded(documentType)) {
      onCollapseDocumentType(documentType);
    } else {
      onExpandDocumentType(documentType);
    }
  };

  handleChangeDocumentCheckbox = (document) => {
    const { onSelectDocument, onDeselectDocument } = this.props;

    if (this.isDocumentSelected(document)) {
      onDeselectDocument(document);
    } else {
      onSelectDocument(document);
    }
  };

  handleChangeDocumentTypeCheckbox = (documentType) => {
    const { onSelectDocument, onDeselectDocument } = this.props;

    if (this.isDocumentTypeSelected(documentType)) {
      this.getDocumentsForType(documentType).forEach((document) => {
        if (!document.toggleDisabled) {
          onDeselectDocument(document);
        }
      });
    } else {
      this.getDocumentsForType(documentType).forEach((document) => {
        if (!document.toggleDisabled) {
          onSelectDocument(document);
        }
      });
    }
  };

  getDocumentTypes = () => {
    const { documents } = this.props;

    return documents.reduce((documentTypes, document) => {
      if (
        documentTypes.some((documentType) => documentType === document.type)
      ) {
        return documentTypes;
      }

      return [...documentTypes, document.type];
    }, []);
  };

  getDocumentsForType = (documentTypeName) => {
    const { documents } = this.props;

    return documents.filter((document) => document.type === documentTypeName);
  };

  isDocumentTypeExpanded = (documentTypeName) =>
    includes(this.props.expandedDocumentTypeNames, documentTypeName);

  isDocumentSelected = ({ id: documentId }) =>
    includes(this.props.selectedDocumentIds, documentId);

  isDocumentTypeSelected = (documentType) =>
    this.getDocumentsForType(documentType).every((document) =>
      this.isDocumentSelected(document)
    );

  isDocumentTypeIndeterminate = (documentType) =>
    !this.isDocumentTypeSelected(documentType) &&
    this.getDocumentsForType(documentType).some((document) =>
      this.isDocumentSelected(document)
    );

  isDocumentTypeDisabled = (documentType) =>
    includes(this.props.disabledDocumentTypeNames, documentType);

  renderDocuments = (documentType, indented) => {
    return this.getDocumentsForType(documentType).map((document) => {
      return (
        <Table.Row key={document.id}>
          <Table.TextCell indented={indented}>
            <Link
              purpose="document-selector download"
              fontSize={12}
              href={document.file.url}
            >
              {document.file.name}
              {document.type.name === "DRAW_SUMMARY" && (
                <DownloadIcon size={12} marginLeft={majorScale(1)} />
              )}
            </Link>
          </Table.TextCell>

          <Table.Cell textAlign="center">
            <Checkbox
              onChange={() => this.handleChangeDocumentCheckbox(document)}
              checked={this.isDocumentSelected(document)}
              disabled={
                document.toggleDisabled ||
                this.isDocumentTypeDisabled(documentType)
              }
              marginY={0}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  renderExpandableDocumentType = (documentType) => {
    return (
      <Table.Row onClick={() => this.handleToggleDocumentType(documentType)}>
        <Table.Cell width="80%">
          <Fragment>
            {this.isDocumentTypeExpanded(documentType) ? (
              <ChevronDownIcon
                size={majorScale(1)}
                marginRight={majorScale(2)}
              />
            ) : (
              <ChevronRightIcon
                size={majorScale(1)}
                marginRight={majorScale(2)}
              />
            )}
            <Text fontSize={12}>
              {t(`documentTypeName.${documentType}`)} (
              {this.getDocumentsForType(documentType).length})
            </Text>
          </Fragment>
        </Table.Cell>
        <Table.Cell width="20%">
          <Checkbox
            onChange={() => this.handleChangeDocumentTypeCheckbox(documentType)}
            checked={this.isDocumentTypeSelected(documentType)}
            indeterminate={this.isDocumentTypeIndeterminate(documentType)}
            disabled={this.isDocumentTypeDisabled(documentType)}
            marginY={0}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  renderDocumentRows = (documentType) => {
    if (this.getDocumentsForType(documentType).length > 1) {
      return [
        this.renderExpandableDocumentType(documentType),
        ...(this.isDocumentTypeExpanded(documentType)
          ? this.renderDocuments(documentType, true)
          : []),
      ];
    }
    return this.renderDocuments(documentType);
  };

  render() {
    return (
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.TextHeaderCell width="80%">
              Files to include
            </Table.TextHeaderCell>
            <Table.TextHeaderCell width="20%">Include?</Table.TextHeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {this.getDocumentTypes().map(this.renderDocumentRows)}
        </Table.Body>
      </Table>
    );
  }
}

DocumentSelector.propTypes = {
  defaultSelectedDocumentIds: PropTypes.arrayOf(PropTypes.string),
  disabledDocumentTypeNames: PropTypes.arrayOf(PropTypes.string),
  setInitialSelections: PropTypes.bool,
};

DocumentSelector.defaultProps = {
  disabledDocumentTypeNames: [],
};

export default DocumentSelector;
