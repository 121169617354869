import { useContext, Fragment } from "react";
import { TickCircleIcon } from "evergreen-ui";
import {
  Card,
  Heading,
  Link,
  Pane,
  Paragraph,
  Progress,
} from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { PROJECT_SETUP_STEP, PROJECT_SETUP_STEP_STATUS } from "helpers/enums";
import { compareAsc } from "helpers/dateHelpers";
import { compact, first, includes, partition } from "lodash";
import t from "helpers/translate";
import { checklistComplete, incomplete } from "images";

const ORDERED_PROJECT_SETUP_STEPS = [
  PROJECT_SETUP_STEP.CREATE_PROJECT,
  PROJECT_SETUP_STEP.REVIEW_USER_ACCESS,
  PROJECT_SETUP_STEP.COMPLETE_HISTORICAL_DRAWS,
  PROJECT_SETUP_STEP.CREATE_DRAW,
  PROJECT_SETUP_STEP.REVIEW_RULES,
  PROJECT_SETUP_STEP.CREATE_FIRST_AGREEMENT,
  PROJECT_SETUP_STEP.REVIEW_LINE_ITEM_TYPES,
  PROJECT_SETUP_STEP.REVIEW_FORM_CONFIGURATIONS,
];

export const getOrderedSteps = (steps) => {
  const [pendingSteps, completedOrSkippedSteps] = partition(
    steps,
    ({ status }) => status === PROJECT_SETUP_STEP_STATUS.PENDING
  );
  const orderedPendingSteps = compact(
    ORDERED_PROJECT_SETUP_STEPS.map((orderedStep) =>
      pendingSteps.find(({ step }) => step === orderedStep)
    )
  );
  return completedOrSkippedSteps
    .sort((a, b) => {
      return compareAsc(a.completedAt, b.completedAt);
    })
    .concat(orderedPendingSteps);
};

export function SetupProgress({ setupSteps }) {
  const theme = useContext(ThemeContext);
  const completed = setupSteps.filter(
    ({ status }) => status === PROJECT_SETUP_STEP_STATUS.COMPLETE
  ).length;
  const total = setupSteps.filter(
    ({ status }) => status !== PROJECT_SETUP_STEP_STATUS.SKIPPED
  ).length;

  return (
    <Pane display="flex" alignItems="center" flex="1 1 auto">
      <Paragraph fontSize={12} marginRight={minorScale(3)}>
        {t("onboardingChecklist.setupProgress", { completed, total })}
      </Paragraph>

      <Progress
        background={theme.colors.green300}
        color={theme.colors.green600}
        height={majorScale(1)}
        total={total}
        value={completed}
        width={125}
      />
    </Pane>
  );
}

function CompletedStep({ step, theme }) {
  return (
    <Pane flex="1 1 auto" display="flex" alignItems="center">
      <Pane flex="1 1 auto" display="flex" alignItems="center">
        <TickCircleIcon
          color="white"
          marginLeft={minorScale(1)}
          marginRight={majorScale(2)}
          size={22}
        />
        <Paragraph
          color="white"
          fontSize={12}
          fontWeight={theme.fontWeights.DEMI}
        >
          {t(`projectSetupSteps.${step.step}`)}
        </Paragraph>
      </Pane>
      <Paragraph
        color="white"
        fontSize={12}
        fontWeight={theme.fontWeights.DEMI}
      >
        complete
      </Paragraph>
    </Pane>
  );
}

function IncompleteStep({ step, setSelectedSetupStep, theme, handleSkip }) {
  return (
    <Pane flex="1 1 auto" display="flex" alignItems="center">
      <Pane marginRight={majorScale(1)} display="flex" alignItems="center">
        <img src={incomplete} alt="incomplete" />
      </Pane>
      <Paragraph fontSize={12} fontWeight={theme.fontWeights.DEMI}>
        {t(`projectSetupSteps.${step.step}`)}
      </Paragraph>
      <Link
        marginLeft={majorScale(1)}
        flex="1 1 auto"
        size={300}
        onClick={() => setSelectedSetupStep(step)}
      >
        View
      </Link>
      <Link size={300} onClick={() => handleSkip(step)}>
        Skip
      </Link>
    </Pane>
  );
}

function ChecklistComplete({
  dismissProjectSetup,
  setShowTodos,
  theme,
  onViewAll,
}) {
  return (
    <Card
      background="#47B881"
      marginBottom={minorScale(3)}
      padding={majorScale(2)}
    >
      <Heading
        color="white"
        size={800}
        textAlign="center"
        marginTop={majorScale(1)}
      >
        Project Setup Complete!
      </Heading>
      <Pane marginTop={majorScale(4)} textAlign="center">
        <img height={150} src={checklistComplete} alt="checklist complete" />
      </Pane>
      <Pane
        textAlign="right"
        marginTop={majorScale(3)}
        marginBottom={majorScale(1)}
        marginRight={majorScale(2)}
      >
        <Link
          underline
          color="white"
          size={300}
          marginRight={minorScale(3)}
          onClick={onViewAll}
        >
          View All Steps
        </Link>
        <Link
          underline
          color="white"
          size={300}
          onClick={() => {
            dismissProjectSetup();
            setShowTodos(true);
          }}
        >
          Dismiss Project Setup
        </Link>
      </Pane>
    </Card>
  );
}

export function DashboardSetupSteps({
  dismissProjectSetup,
  projectId,
  setupSteps,
  setSelectedSetupStep,
  setShowTodos,
  theme,
  updateSetupStepMutation,
}) {
  const [updateSetupStep] = updateSetupStepMutation;
  const handleSkip = ({ id }) => {
    updateSetupStep({
      variables: {
        id,
        projectId,
        status: PROJECT_SETUP_STEP_STATUS.SKIPPED,
      },
    });
  };

  const checklistSetupSteps = setupSteps.filter(
    ({ status }) => status !== PROJECT_SETUP_STEP_STATUS.SKIPPED
  );
  const orderedSteps = getOrderedSteps(checklistSetupSteps);
  const modalDefaultStep =
    first(
      orderedSteps.filter(
        ({ status }) => status === PROJECT_SETUP_STEP_STATUS.PENDING
      )
    ) || first(orderedSteps);
  const checklistComplete = setupSteps.every(({ status }) =>
    includes(
      [PROJECT_SETUP_STEP_STATUS.COMPLETE, PROJECT_SETUP_STEP_STATUS.SKIPPED],
      status
    )
  );

  const onViewAll = () => setSelectedSetupStep(modalDefaultStep);

  return (
    <Fragment>
      {!checklistComplete && (
        <Pane
          display="flex"
          alignItems="center"
          marginBottom={majorScale(1)}
          paddingRight={majorScale(2)}
          justifyContent="space-between"
        >
          <SetupProgress setupSteps={setupSteps} />
          <Link size={300} onClick={onViewAll}>
            View All
          </Link>
        </Pane>
      )}
      {checklistComplete ? (
        <ChecklistComplete
          dismissProjectSetup={dismissProjectSetup}
          setShowTodos={setShowTodos}
          theme={theme}
          onViewAll={onViewAll}
        />
      ) : (
        <Pane
          flex="0 0 auto"
          maxHeight="35%"
          overflowY="auto"
          padding={1}
          paddingRight={majorScale(2)}
          marginBottom={minorScale(3)}
        >
          {orderedSteps.map((step) => {
            const { id, status } = step;
            const isComplete = status === PROJECT_SETUP_STEP_STATUS.COMPLETE;
            return (
              <Card
                height={50}
                elevation={1}
                display="flex"
                alignItems="center"
                marginBottom={majorScale(1)}
                padding={minorScale(3)}
                paddingRight={majorScale(2)}
                key={id}
                background={isComplete ? theme.colors.green600 : undefined}
              >
                {isComplete ? (
                  <CompletedStep step={step} theme={theme} />
                ) : (
                  <IncompleteStep
                    step={step}
                    setSelectedSetupStep={setSelectedSetupStep}
                    theme={theme}
                    handleSkip={handleSkip}
                  />
                )}
              </Card>
            );
          })}
        </Pane>
      )}
    </Fragment>
  );
}
