import { useState } from "react";
import { CaretDownIcon, CaretRightIcon } from "evergreen-ui";
import { Alert, Button, Pane, Text } from "components/materials";
import t from "helpers/translate";
import { groupBy, reject } from "lodash";
import { majorScale } from "helpers/utilities";
import { AGREEMENT_TYPE } from "helpers/enums";
import humanReadableList from "helpers/humanReadableList";
import { AgreementsTable } from "../Agreements";

export function ChangeOrderAlert({
  changeOrderAgreements,
  handleExecuteChangeOrder,
  setEditInProgress,
}) {
  const [showTable, setShowTable] = useState(false);

  const {
    [AGREEMENT_TYPE.EXPOSURE]: exposures,
    [AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER]: potentialChangeOrders,
    [AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER]: executedChangeOrders,
  } = groupBy(changeOrderAgreements, "type");

  const pendingChangeOrders = [
    ...(exposures || []),
    ...(potentialChangeOrders || []),
  ];

  const showExecutedChangeOrderDefault = pendingChangeOrders.length === 0;
  const [showExecutedChangeOrders, setShowExecutedChangeOrders] = useState(
    showExecutedChangeOrderDefault
  );

  const visibleChangeOrders = showExecutedChangeOrders
    ? pendingChangeOrders.concat(executedChangeOrders || [])
    : pendingChangeOrders;

  return (
    <Alert
      title={getChangeOrderAlertTitle(
        exposures,
        potentialChangeOrders,
        executedChangeOrders
      )}
      marginBottom={majorScale(2)}
      overflow="scroll"
    >
      <Pane
        onClick={() => {
          setShowTable(!showTable);
        }}
        display="flex"
        alignItems="center"
        marginTop={majorScale(1)}
      >
        {showTable ? <CaretDownIcon /> : <CaretRightIcon />}
        <Text size={300}>{showTable ? "Hide All" : "View All"}</Text>
      </Pane>
      {showTable ? (
        <Pane marginTop={majorScale(2)}>
          <AgreementsTable
            isSelectionContext
            agreements={visibleChangeOrders}
            selectionButtonCopy={(agreement) =>
              agreement.type !== AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
                ? "Execute"
                : null
            }
            onClickRow={(agreement) => {
              setEditInProgress(true);
              handleExecuteChangeOrder(agreement);
            }}
            disableRowClick={(agreement) =>
              agreement.type === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
            }
          />
          {!showExecutedChangeOrders && (
            <Button onClick={() => setShowExecutedChangeOrders(true)}>
              Show Executed Change Orders
            </Button>
          )}
        </Pane>
      ) : null}
    </Alert>
  );
}

function getChangeOrderCount(changeOrders, type) {
  return changeOrders
    ? t(`agreementTypeCounts.${type}`, { count: changeOrders.length })
    : null;
}

function getChangeOrderAlertTitle(
  exposures,
  potentialChangeOrders,
  executedChangeOrders
) {
  const exposuresCount = getChangeOrderCount(
    exposures,
    AGREEMENT_TYPE.EXPOSURE
  );
  const potentialChangeOrdersCount = getChangeOrderCount(
    potentialChangeOrders,
    AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER
  );
  const executedChangeOrdersCount = getChangeOrderCount(
    executedChangeOrders,
    AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
  );
  const titleStrings = reject(
    [exposuresCount, potentialChangeOrdersCount, executedChangeOrdersCount],
    (string) => string === null
  );
  return `This project has ${humanReadableList(titleStrings)}`;
}
