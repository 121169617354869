import PropTypes from "prop-types";
import {
  Card as EvergreenCard,
  Heading,
  Pane,
  Paragraph,
  withTheme,
} from "evergreen-ui";
import { majorScale } from "helpers/utilities";

const Card = ({ background, children, ...props }) => {
  return (
    <EvergreenCard
      className="rbt-car"
      background={background || "white"}
      elevation={1}
      {...props}
    >
      {children}
    </EvergreenCard>
  );
};

const Header = ({ children, content, bleed }) => {
  const paddingProps = bleed ? null : { padding: majorScale(3) };

  return (
    <Heading
      className="rbt-header"
      size={600}
      color="default"
      height={majorScale(4)}
      {...paddingProps}
    >
      {content || children}
    </Heading>
  );
};

const Content = ({ children, bleed, ...props }) => {
  const paddingProps = bleed ? null : { padding: majorScale(3) };

  return (
    <Pane className="rbt-content" {...paddingProps} {...props}>
      {children}
    </Pane>
  );
};

const Meta = ({ children, theme }) => {
  return (
    <Pane>
      <Paragraph
        size={300}
        color={theme.colors.gray500}
        display="flex"
        alignItems="center"
      >
        {children}
      </Paragraph>
    </Pane>
  );
};

Card.propTypes = {
  children: PropTypes.node,
};

Header.propTypes = {
  content: PropTypes.string,
};

Content.propTypes = {
  children: PropTypes.node,
};

Meta.propTypes = {
  children: PropTypes.node,
};

// TODO: remove dot notation
Card.Content = Content;
Card.Meta = withTheme(Meta);
Card.Header = Header;

export default Card;
