import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { fromBase64 } from "./FastDataTableUtils";

export function FastDataTableControls({
  columns,
  config,
  controls,
  items,
  preparedColumns,
  preparedItems,
  scrollRefs,
  selectedItemIds,
  serialized,
  setConfig,
  setSelectedItemIds,
  showFooterTotals,
}) {
  const filterBy = useCallback(
    (filterConfig) => {
      const payload = { ...config, filterConfig };
      setConfig({ payload });
    },
    [config, setConfig]
  );

  const sortBy = useCallback(
    (sortConfig) => {
      const payload = { ...config, sortConfig };
      setConfig({ payload });
    },
    [config, setConfig]
  );

  const applyColumnConfig = useCallback(
    (columnConfig) => {
      const payload = { ...config, columnConfig, isDefault: false };
      setConfig({ payload });
    },
    [config, setConfig]
  );

  const applyGroupConfig = useCallback(
    (groupConfig) => {
      const payload = { ...config, groupConfig };
      setConfig({ payload });
    },
    [config, setConfig]
  );

  const toggleAllSections = useCallback(() => {
    const expanded =
      !config.groupConfig.expanded || config.groupConfig.expanded.length > 0
        ? []
        : null;
    const payload = {
      ...config,
      groupConfig: { ...config.groupConfig, expanded },
    };
    setConfig({ payload });
  }, [config, setConfig]);

  const hydrateFromSerialized = useCallback(
    (serialized) => {
      const payload = fromBase64(serialized);
      setConfig({ payload });
    },
    [setConfig]
  );

  const [selectedItems, selectedIndices] = useMemo(() => {
    return Array.isArray(preparedItems)
      ? preparedItems.reduce(
          (acc, item, index) => {
            if (selectedItemIds.includes(item.id)) {
              acc[0].push(item);
              acc[1].push(index);
            }
            return acc;
          },
          [[], []]
        )
      : Object.entries(preparedItems.groups).reduce(
          (acc, [value, items]) => {
            const inner = items.reduce((accInner, item, index) => {
              if (selectedItemIds.includes(item.id)) {
                accInner[0].push(item);
                accInner[1].push(index + acc[2]);
              }
              return accInner;
            }, acc);
            inner[2] += items.length;
            return inner;
          },
          [[], [], 0]
        );
  }, [preparedItems, selectedItemIds]);

  const props = {
    ...config,
    applyColumnConfig,
    applyGroupConfig,
    dataColumns: columns,
    filterBy,
    hydrateFromSerialized,
    items,
    preparedColumns,
    preparedItems,
    scrollRefs,
    selectedIndices,
    selectedItems,
    serialized,
    setSelectedItems: setSelectedItemIds,
    showFooterTotals,
    sortBy,
    toggleAllSections,
  };

  return controls ? controls(props) : null;
}

FastDataTableControls.propTypes = {
  columns: PropTypes.array,
  config: PropTypes.object,
  controls: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  items: PropTypes.array,
  preparedColumns: PropTypes.array,
  preparedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  scrollRefs: PropTypes.array,
  selectedItemIds: PropTypes.array,
  serialized: PropTypes.string,
  setConfig: PropTypes.func,
  setSelectedItemIds: PropTypes.func,
  showFooterTotals: PropTypes.bool,
};

FastDataTableControls.defaultProps = {};
