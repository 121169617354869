import { Fragment, useContext, useState } from "react";
import { ChevronDownIcon, ChevronRightIcon, InfoSignIcon } from "evergreen-ui";
import {
  Badge,
  Link,
  Pane,
  Paragraph,
  Shortener,
  Text,
  Tab,
  Table,
  Tablist,
  Tooltip,
} from "components/materials";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { sumBy } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import { minorScale, majorScale, ThemeContext } from "helpers/utilities";
import { groupBy, keys, sortBy } from "lodash";
import t from "helpers/translate";

const VIEWS = {
  type: prepareDocumentByType,
  draw: prepareDocumentByDraw,
  vendor: prepareDocumentByVendor,
};

function isLienReleaseType(documentType) {
  return (
    documentType === DOCUMENT_TYPE_NAME.CONDITIONAL_LIEN_RELEASE ||
    documentType === DOCUMENT_TYPE_NAME.UNCONDITIONAL_LIEN_RELEASE
  );
}

function sortItemsWithinGroup(groups) {
  return groups.map((group) => {
    return {
      ...group,
      expenses: sortBy(group.expenses, "document.processingFinishedAt"),
    };
  });
}

// Making sure lien release types won't be included in sum if view by draw or vendor is chosen
function sumItemsForDrawVendor(group) {
  return sumBy(
    group.filter(
      (item) => !isLienReleaseType(item.document.type) && !item.isBackup
    ),
    "amount"
  );
}

function prepareDocumentByType(expenses) {
  const groupedExpenses = groupBy(expenses, "document.type");

  const order = [
    DOCUMENT_TYPE_NAME.INVOICE,
    DOCUMENT_TYPE_NAME.PAY_APPLICATION,
    DOCUMENT_TYPE_NAME.CONDITIONAL_LIEN_RELEASE,
    DOCUMENT_TYPE_NAME.UNCONDITIONAL_LIEN_RELEASE,
  ];

  const sortedExpenses = Object.keys(groupedExpenses)
    .map((type) => ({
      expenses: groupedExpenses[type],
      type,
    }))
    .sort((a, b) => {
      return order.indexOf(a.type) - order.indexOf(b.type);
    });

  const formattedExpenses = sortedExpenses.map(({ expenses, type }) => {
    const groupTotal = sumBy(
      expenses.filter(
        ({ document, isBackup }) =>
          !isBackup || isLienReleaseType(document.type)
      ),
      "amount"
    );
    const groupName = t(`documentTypeName.${type}`);
    return {
      groupName: `${groupName}s`,
      subTotal: groupTotal,
      expenses,
    };
  });

  return sortItemsWithinGroup(formattedExpenses);
}

function prepareDocumentByDraw(expenses) {
  const groupedExpenses = groupBy(expenses, "document.draw.name");

  const formattedExpenses = Object.keys(groupedExpenses).map((drawName) => {
    const groupTotal = sumItemsForDrawVendor(groupedExpenses[drawName]);

    return {
      groupName: drawName,
      subTotal: groupTotal,
      expenses: groupedExpenses[drawName],
      drawDate: groupedExpenses[drawName][0].document.draw.createdAt,
    };
  });

  const sortedExpenses = sortBy(formattedExpenses, "drawDate");

  return sortItemsWithinGroup(sortedExpenses);
}

function prepareDocumentByVendor(expenses) {
  const groupedExpenses = groupBy(expenses, "document.vendor.name");

  const formattedExpenses = Object.keys(groupedExpenses).map((vendorName) => {
    const groupTotal = sumItemsForDrawVendor(groupedExpenses[vendorName]);
    return {
      groupName: vendorName,
      subTotal: groupTotal,
      expenses: groupedExpenses[vendorName],
    };
  });

  const sortedExpenses = sortBy(formattedExpenses, [
    ({ groupName }) => groupName.toLowerCase(),
  ]);

  return sortItemsWithinGroup(sortedExpenses);
}

function GroupTableSection({ group, history, selectedView }) {
  const [groupCollapsed, setGroupCollapsed] = useState(true);
  const theme = useContext(ThemeContext);
  return (
    <Table.Body>
      <Table.Row onClick={() => setGroupCollapsed(!groupCollapsed)}>
        <Table.TextSectionHeaderCell colSpan={4}>
          <Pane display="flex" alignItems="center">
            {groupCollapsed ? (
              <ChevronRightIcon size={12} marginRight={majorScale(1)} />
            ) : (
              <ChevronDownIcon size={12} marginRight={majorScale(1)} />
            )}
            {group.groupName} ({group.expenses.length})
          </Pane>
        </Table.TextSectionHeaderCell>
      </Table.Row>
      {!groupCollapsed &&
        group.expenses.map(({ amount, document, id, isBackup }) => {
          const documentId = document.id;
          const useLienWaiverStyle =
            isLienReleaseType(document.type) && selectedView !== "type";
          const useBackupStyle = isBackup && !isLienReleaseType(document.type);
          return (
            <Table.Row key={id}>
              {selectedView !== "vendor" && (
                <Table.TextCell>
                  <Shortener
                    size={400}
                    text={document.vendor.name}
                    limit={15}
                  />
                </Table.TextCell>
              )}
              {selectedView !== "type" && (
                <Table.TextCell>
                  {t(`documentTypeName.${document.type}`)}
                </Table.TextCell>
              )}
              {selectedView !== "draw" && (
                <Table.TextCell>
                  <Shortener size={400} text={document.draw.name} limit={15} />
                </Table.TextCell>
              )}
              <Table.TextCell>
                <Link
                  purpose="to document viewer"
                  size={300}
                  to={`${history.location.pathname}/documents/${documentId}${history.location.search}`}
                >
                  <Shortener
                    size={400}
                    text={document.file.name}
                    limit={15}
                    color={theme.colors.baseBlue}
                  />
                </Link>
              </Table.TextCell>
              <Table.TextCell
                fontStyle={
                  useBackupStyle || useLienWaiverStyle ? "italic" : undefined
                }
                textProps={
                  useBackupStyle || useLienWaiverStyle ? { color: "muted" } : {}
                }
              >
                {formatCurrency(-amount)}
              </Table.TextCell>
            </Table.Row>
          );
        })}
      {!groupCollapsed && (
        <Table.Row>
          <Table.Cell border="none" colSpan={3} textAlign="right">
            <Text
              fontWeight={500}
              size={300}
              marginRight={majorScale(1)}
              color="grey"
            >
              {group.groupName.toUpperCase()} SUBTOTAL:
            </Text>
          </Table.Cell>
          <Table.Cell border="none">
            <Badge>{formatCurrency(-group.subTotal)}</Badge>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
}

export function LineItemDocuments({ history, lineItem }) {
  const [selectedView, setSelectedView] = useState(keys(VIEWS)[0]);
  const expenseGroups = VIEWS[selectedView](lineItem.expenses);

  const showBackupDocTooltip =
    selectedView === "type"
      ? lineItem.expenses.some(
          ({ isBackup, document }) =>
            isBackup && !isLienReleaseType(document.type)
        )
      : lineItem.expenses.some(({ isBackup }) => isBackup);

  if (expenseGroups.length === 0) {
    return <Paragraph marginTop={majorScale(1)}>No Documents</Paragraph>;
  }

  return (
    <Fragment>
      <Pane marginBottom={majorScale(2)}>
        <Tablist>
          {Object.keys(VIEWS).map((key) => (
            <Tab
              key={key}
              isSelected={selectedView === key}
              onSelect={() => setSelectedView(key)}
            >
              {t(`budgetLineItemSlideout.documentTableViewType.${key}`)}
            </Tab>
          ))}
        </Tablist>
      </Pane>
      <Table>
        <Table.Head>
          <Table.Row>
            {selectedView !== "vendor" && (
              <Table.TextHeaderCell width={130}>Vendor</Table.TextHeaderCell>
            )}
            {selectedView !== "type" && (
              <Table.TextHeaderCell width={160}>Type</Table.TextHeaderCell>
            )}
            {selectedView !== "draw" && (
              <Table.TextHeaderCell width={130}>Draw</Table.TextHeaderCell>
            )}
            <Table.TextHeaderCell width={130}>Document</Table.TextHeaderCell>
            <Table.TextHeaderCell width={145}>
              <Pane display="flex" alignItems="center">
                <Shortener
                  size={400}
                  text={`${lineItem.name} Amount`}
                  limit={38}
                  marginRight={majorScale(1)}
                />
                {showBackupDocTooltip && (
                  <Pane display="inline">
                    <Tooltip content="Subtotals do not reflect backup documentation.">
                      <InfoSignIcon size={12} marginTop={minorScale(1)} />
                    </Tooltip>
                  </Pane>
                )}
              </Pane>
            </Table.TextHeaderCell>
          </Table.Row>
        </Table.Head>
        {expenseGroups.map((group) => (
          <GroupTableSection
            key={group.groupName}
            group={group}
            history={history}
            selectedView={selectedView}
          />
        ))}
      </Table>
    </Fragment>
  );
}
