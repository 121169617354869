import { useContext, Fragment } from "react";
import {
  AddIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CrossIcon,
} from "evergreen-ui";
import {
  Button,
  FastInput,
  IconButton,
  Pane,
  Table,
  Text,
  Tooltip,
} from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import {
  majorScale,
  minorScale,
  Position,
  ThemeContext,
} from "helpers/utilities";
import { EVENTS, ROW_TYPE } from "./utils";
import RowOrderButtons from "./RowOrderButtons";
import { getColumnSubtotal } from "./EditableBudgetTable";
import { BudgetTableLineItem } from "./BudgetTableLineItemInner";

function AddLineItemButton({
  divisionIndex,
  divisionName,
  isCollapsed,
  pushLineItem,
  setHasChangeAffectingProjection,
  setHasNewLineItem,
}) {
  const theme = useContext(ThemeContext);
  // If a user accidentally uploads the old template,
  // the division name will be an object
  // and this will cause the page to error
  return (
    <Pane maxWidth={200}>
      <Button
        appearance="minimal"
        color={theme.colors.blue600}
        size="small"
        iconBefore={AddIcon}
        purpose="project-budget line-item add"
        onClick={() => {
          setHasChangeAffectingProjection(true);
          setHasNewLineItem(true);
          pushLineItem(divisionIndex);
        }}
        marginLeft={isCollapsed ? majorScale(2) : -majorScale(1)}
      >
        Add Line Item in {(divisionName || "").toString()}
      </Button>
    </Pane>
  );
}

function columnSubtotal(division, column) {
  const subTotal = getColumnSubtotal(division, column.value);
  return formatCurrency(subTotal);
}

export default function BudgetTableDivision({
  additionalColumns,
  collapsed,
  collapsedToggle,
  division,
  divisionCount,
  divisionIndex,
  onDivisionEvent,
  onLineItemEvent,
  onMoveRow,
  pushLineItem,
  readOnlyAmount,
  removeDivision,
  removeLineItem,
  setHasChangeAffectingProjection,
  setHasNewLineItem,
  showLineItemNumber,
  showSuperLineItems,
  useEnhancedLineItemReporting,
}) {
  const inputProps = { inputHeight: 26, marginTop: -minorScale(1) };
  const isCollapsed = collapsed.includes(division.id);
  const userNotPermittedToRemoveDivision =
    readOnlyAmount && division.lineItems.some(({ amount }) => amount > 0);
  const removeIsDisabled =
    division.lineItems.some(({ disabled }) => disabled) ||
    userNotPermittedToRemoveDivision;

  return [
    <Table.Row key={`${division.id}-header`}>
      <Table.TextSectionHeaderCell
        textProps={{ alignItems: "center", display: "flex" }}
        colSpan={2}
      >
        <RowOrderButtons
          isFirstItem={divisionIndex === 0}
          isLastItem={divisionIndex === divisionCount - 1}
          id={division.id}
          rowType={ROW_TYPE.DIVISION}
          onMoveRow={onMoveRow}
        />

        {removeIsDisabled ? (
          <Tooltip
            content={
              <Text color="white">
                {userNotPermittedToRemoveDivision
                  ? t("budgetPage.userDisabledDivisionTooltip")
                  : t("budgetPage.disabledDivisionTooltip")}
              </Text>
            }
            position={Position.RIGHT}
          >
            <Pane>
              <IconButton
                appearance="minimal"
                disabled
                flex="0 0 auto"
                size="small"
                icon={CrossIcon}
                marginX={minorScale(1)}
              />
            </Pane>
          </Tooltip>
        ) : (
          <IconButton
            appearance="minimal"
            flex="0 0 auto"
            size="small"
            icon={CrossIcon}
            marginX={minorScale(1)}
            onClick={() => {
              removeDivision(divisionIndex);
              setHasChangeAffectingProjection(true);
            }}
            type="button"
          />
        )}
        <IconButton
          appearance="minimal"
          flex="0 0 auto"
          size="small"
          icon={isCollapsed ? ChevronRightIcon : ChevronDownIcon}
          marginX={minorScale(1)}
          onClick={() => collapsedToggle(division.id)}
          type="button"
        />
        <FastInput
          flex="1 1 auto"
          name={`divisions.${divisionIndex}.name`}
          placeholder="Division Name..."
          onChange={() => onDivisionEvent(division, EVENTS.UPDATE)}
          {...inputProps}
        />
      </Table.TextSectionHeaderCell>
      <Table.TextSectionHeaderCell
        textProps={{ alignItems: "center", display: "flex" }}
      >
        {isCollapsed ? (
          <Text size={300} fontWeight={600}>
            {formatCurrency(
              getColumnSubtotal(division, ({ amount }) => amount)
            )}
          </Text>
        ) : (
          <AddLineItemButton
            divisionIndex={divisionIndex}
            divisionName={division.name}
            isCollapsed={isCollapsed}
            pushLineItem={pushLineItem}
            setHasChangeAffectingProjection={setHasChangeAffectingProjection}
            setHasNewLineItem={setHasNewLineItem}
          />
        )}
      </Table.TextSectionHeaderCell>
      {showSuperLineItems && <Table.TextSectionHeaderCell />}
      {showLineItemNumber && <Table.TextSectionHeaderCell />}
      {useEnhancedLineItemReporting && <Table.TextSectionHeaderCell />}
      {additionalColumns.map((column, columnIndex) => {
        const isLastAdditionalColumn =
          columnIndex === additionalColumns.length - 1;
        return (
          <Table.TextSectionHeaderCell
            key={`${column.name}-division-header`}
            width={isLastAdditionalColumn ? 325 : 125}
            textProps={{ alignItems: "center", display: "flex" }}
          >
            {isCollapsed && (
              <Fragment>
                <Text size={300} fontWeight={600}>
                  {formatCurrency(getColumnSubtotal(division, column.value))}
                </Text>
                {isLastAdditionalColumn && (
                  <AddLineItemButton
                    divisionIndex={divisionIndex}
                    divisionName={division.name}
                    isCollapsed={isCollapsed}
                    pushLineItem={pushLineItem}
                    setHasChangeAffectingProjection={
                      setHasChangeAffectingProjection
                    }
                    setHasNewLineItem={setHasNewLineItem}
                  />
                )}
              </Fragment>
            )}
          </Table.TextSectionHeaderCell>
        );
      })}
      <Table.TextSectionHeaderCell />
    </Table.Row>,
    !isCollapsed &&
      division.lineItems.map((lineItem, index) => (
        <BudgetTableLineItem
          additionalColumns={additionalColumns}
          divisionCount={divisionCount}
          divisionIndex={divisionIndex}
          key={lineItem.id}
          lineItem={lineItem}
          lineItemCount={division.lineItems.length}
          lineItemIndex={index}
          onLineItemEvent={onLineItemEvent}
          onMoveRow={onMoveRow}
          readOnlyAmount={readOnlyAmount}
          removeLineItem={removeLineItem}
          setHasChangeAffectingProjection={setHasChangeAffectingProjection}
          showLineItemNumber={showLineItemNumber}
          showSuperLineItems={showSuperLineItems}
          useEnhancedLineItemReporting={useEnhancedLineItemReporting}
        />
      )),
    !isCollapsed && (
      <Table.Row key={`${division.id}-footer`}>
        <Table.TextFooterCell
          textAlign="right"
          colSpan={2}
        >{`${division.name} Subtotal`}</Table.TextFooterCell>
        <Table.TextFooterCell>
          {formatCurrency(getColumnSubtotal(division, ({ amount }) => amount))}
        </Table.TextFooterCell>
        {showSuperLineItems && <Table.TextFooterCell />}
        {showLineItemNumber && <Table.TextFooterCell />}
        {useEnhancedLineItemReporting && <Table.TextFooterCell />}
        {additionalColumns.map((column) => (
          <Table.TextFooterCell key={`${column.name}-division-footer`}>
            {columnSubtotal(division, column)}
          </Table.TextFooterCell>
        ))}
      </Table.Row>
    ),
  ];
}
