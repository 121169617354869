import gql from "graphql-tag";

export const POST_PAYMENTS_TO_AVID = gql`
  mutation PostPaymentsToAvid(
    $drawId: String!
    $projectId: String!
    $payments: [PaymentInput]!
  ) {
    postPaymentsToAvid(
      drawId: $drawId
      projectId: $projectId
      payments: $payments
    ) {
      id
    }
  }
`;

export const POST_PAYMENTS_TO_BILL_COM = gql`
  mutation PostPaymentsToBillCom(
    $drawId: String!
    $projectId: String!
    $payments: [PaymentInput]!
  ) {
    postPaymentsToBillCom(
      drawId: $drawId
      projectId: $projectId
      payments: $payments
    ) {
      id
    }
  }
`;

export const POST_PAYMENTS_TO_DELTEK = gql`
  mutation PostPayments(
    $projectId: String!
    $payments: [PaymentInput]!
    $note: String
  ) {
    postPayments(projectId: $projectId, payments: $payments, note: $note) {
      id
      webhook_url
      results {
        id
        message
        payload
        code
      }
    }
  }
`;

export const POST_PAYMENTS_TO_NEXUS = gql`
  mutation PostPaymentsToNexus(
    $drawId: String!
    $projectId: String!
    $payments: [PaymentInput]!
  ) {
    postPaymentsToNexus(
      drawId: $drawId
      projectId: $projectId
      payments: $payments
    ) {
      id
    }
  }
`;

export const POST_PAYMENTS_TO_QUICK_BOOKS_ONLINE = gql`
  mutation PostPaymentsToQuickBooksOnline(
    $drawId: String!
    $projectId: String!
    $payments: [PaymentInput]!
  ) {
    postPaymentsToQuickBooksOnline(
      drawId: $drawId
      projectId: $projectId
      payments: $payments
    ) {
      id
    }
  }
`;

export const POST_PAYMENTS_TO_MRI = gql`
  mutation PostPaymentsToMri(
    $drawId: String!
    $projectId: String!
    $payments: [PaymentInput]!
  ) {
    postPaymentsToMri(
      drawId: $drawId
      projectId: $projectId
      payments: $payments
    ) {
      id
    }
  }
`;

export const POST_PAYMENTS_TO_YARDI = gql`
  mutation PostPaymentsToYardi(
    $drawId: String!
    $projectId: String!
    $payments: [PaymentInput]!
  ) {
    postPaymentsToYardi(
      drawId: $drawId
      projectId: $projectId
      payments: $payments
    ) {
      id
    }
  }
`;
