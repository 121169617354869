import { Accordion } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { SlideoutLineItemIssues } from "components/templates";

export function SuperLineItemIssuesAccordion({ lineItem, scope }) {
  const panels = lineItem.lineItems.map((subLineItem) => {
    return {
      key: subLineItem.name,
      title: subLineItem.name,
      content: (
        <SlideoutLineItemIssues
          lineItem={subLineItem}
          scope={scope}
          showBlankSlate
        />
      ),
    };
  });

  return (
    <Accordion
      defaultActiveKeys={panels.map(({ key }) => key)}
      contentStyles={{ paddingX: majorScale(3), paddingY: minorScale(3) }}
      headerStyles={{ size: 400 }}
      panels={panels}
      panelStyles={{ paddingY: minorScale(3) }}
    />
  );
}
