import { Fragment } from "react";
import { Form } from "components/materials";
import { identity } from "lodash";

export function defaultSelectFormatter(value, item, props) {
  const {
    addName,
    disabled,
    isWarned,
    leftControl,
    name,
    onAddItem,
    onChange,
    options,
    placeholder,
    rightControl,
    rowIndex,
    testId,
  } = props;

  return (
    <Fragment>
      {leftControl && leftControl(value, props)}
      <Form.Select
        addName={addName && addName}
        disabled={disabled && disabled(props, item)}
        name={name(value, rowIndex)}
        options={options}
        placeholder={placeholder}
        onAddItem={onAddItem && onAddItem(value, rowIndex)}
        onChange={(value, selectedItem) =>
          onChange ? onChange(value, rowIndex, selectedItem) : undefined
        }
        isWarned={isWarned(value, rowIndex)}
        popoverMinWidth={props.popoverMinWidth}
        testId={testId}
        width={200}
      />
      {rightControl && rightControl(value, props)}
    </Fragment>
  );
}

export const selectColumnDefaults = {
  disabled: () => false,
  isWarned: () => false,
  value: identity,
  valueFormatter: defaultSelectFormatter,
  width: 200,
};
