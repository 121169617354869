import { useContext, Fragment } from "react";
import { AddIcon, CrossIcon, EditIcon } from "evergreen-ui";
import { Button, Table, Pane, Paragraph, Tooltip } from "components/materials";
import t from "helpers/translate";
import { majorScale, ThemeContext } from "helpers/utilities";
import { clone, some } from "lodash";
import formatPhoneNumber from "helpers/formatPhoneNumber";
import { stringComparator } from "helpers/comparators";

function VendorMembers({ members, onAdd, onEdit, onRemove, vendor }) {
  const theme = useContext(ThemeContext);
  const hasEmail = some(members, (member) => !!member.email);
  const hasPhoneNumber = some(members, (member) => !!member.phone);

  return (
    <Fragment>
      {members.length > 0 ? (
        <Table>
          <Table.Body>
            {clone(members)
              .sort((a, b) => stringComparator(a.name, b.name))
              .map((member) => (
                <Table.Row key={member.id}>
                  <Table.TextCell content={member.name || "--"} />
                  {hasEmail && (
                    <Table.TextCell content={member.email || "--"} />
                  )}
                  {hasPhoneNumber && (
                    <Table.TextCell content={formatPhoneNumber(member.phone)} />
                  )}
                  <Table.TextCell
                    content={
                      <Pane
                        display="flex"
                        justifyContent="flex-end"
                        width="100%"
                      >
                        <EditIcon
                          cursor="pointer"
                          color="selected"
                          onClick={() => onEdit(member)}
                        />
                        {member.isUsedAsStakeholder ? (
                          <Tooltip
                            content={`You cannot delete ${member.name} as a member of ${vendor.name} because they are currently being used as a stakeholder on one or more projects.`}
                          >
                            <CrossIcon
                              marginLeft={majorScale(1)}
                              color="muted"
                            />
                          </Tooltip>
                        ) : (
                          <CrossIcon
                            cursor="pointer"
                            marginLeft={majorScale(1)}
                            onClick={() => onRemove(member)}
                          />
                        )}
                      </Pane>
                    }
                  />
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      ) : (
        <Paragraph marginBottom={majorScale(1)}>
          {t("vendorSidebar.noMembers")}
        </Paragraph>
      )}
      <Button
        appearance="minimal"
        color={theme.colors.blue600}
        size="small"
        iconBefore={AddIcon}
        onClick={onAdd}
        purpose="vendor members add"
      >
        Add Member
      </Button>
    </Fragment>
  );
}

export default VendorMembers;
