// import { DOCUMENT_TYPE_NAME } from "../helpers/enums/DOCUMENT_TYPE_NAME";
import { z as Z } from "zod";

const AgreementDocumentType = Z.enum([
  "ADDENDUM",
  "CONTRACT",
  "EXECUTED_CHANGE_ORDER",
  "EXPOSURE",
  "POTENTIAL_CHANGE_ORDER",
  "WORK_AUTHORIZATION",
]);

interface Document {
  type?: string; // Should be DOCUMENT_TYPE_NAME, going lazy for now
  number?: string;
  agreementNumber?: string;
}

export const getNumber = (document: Document) => {
  return AgreementDocumentType.safeParse(document.type).success
    ? document?.agreementNumber
    : document?.number;
};
