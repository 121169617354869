import { useContext } from "react";
import { css } from "glamor";
import { withTheme } from "evergreen-ui";
import { FormField as EvergreenFormField } from "components/materials";
import { ThemeContext } from "helpers/utilities";

const FormField = (props) => {
  const {
    className,
    isWarned,
    isHighlighted,
    backgroundColor,
    ...rest
  } = props;

  // If no label prop is provided, then don't render the margin from the label
  const labelProps = props.label === null ? { margin: 0 } : {};
  const theme = useContext(ThemeContext);

  function getClassName({ isWarned, isHighlighted, backgroundColor }) {
    if (isHighlighted) {
      return css({
        "& input": {
          backgroundColor: theme.colors.lightBlue,
          boxShadow: theme.boxShadowHighlighted,
        },
      });
    }
    if (isWarned) {
      return css({
        "& input": {
          backgroundColor: theme.colors.yellow100,
          boxShadow: theme.boxShadowHighlighted,
        },
      });
    }
    if (backgroundColor) {
      return css({
        "& input": {
          backgroundColor,
          boxShadow: theme.boxShadowHighlighted,
        },
      });
    }
    return css({
      "& input": {},
    });
  }

  return (
    <EvergreenFormField
      width="100%"
      labelProps={labelProps}
      className={`${getClassName({
        isWarned,
        isHighlighted,
        backgroundColor,
      })} ${className}`}
      {...rest}
    />
  );
};

FormField.defaultProps = {
  label: null,
};

export default withTheme(FormField);
