import PropTypes from "prop-types";
import { ChevronLeftIcon, ChevronRightIcon } from "evergreen-ui";
import { Button, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

export default function WizardActions({
  backLabel,
  children,
  disableNext,
  hideBack,
  hideNext,
  nextButtonStyles,
  nextLabel,
  onBack,
  onNext,
  ...props
}) {
  return (
    <Pane
      display="flex"
      justifyContent="space-between"
      marginTop={majorScale(2)}
      {...props}
    >
      <Pane display="flex" justifyContent="flex-start">
        {!hideBack && (
          <Button
            purpose="project-wizard back"
            content={backLabel || "Back"}
            iconBefore={ChevronLeftIcon}
            onClick={onBack}
          />
        )}
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        {children}
        {!hideNext && (
          <Button
            disabled={disableNext}
            purpose="project-wizard next"
            content={nextLabel || "Next"}
            iconAfter={ChevronRightIcon}
            onClick={onNext}
            {...nextButtonStyles}
          />
        )}
      </Pane>
    </Pane>
  );
}

WizardActions.propTypes = {
  disableNext: PropTypes.bool,
  hideBack: PropTypes.bool,
  hideNext: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  children: PropTypes.node,
};
