import gql from "graphql-tag";

export default gql`
  fragment RuleFragment on Rule {
    id
    scopeId
    config
    name
    enabled
    state
    type
    lineItems {
      id
      scopeId
      name
    }
    activities {
      ... on RuleTransition {
        __typename
        id
        toState
        user {
          id
          fullName
        }
        insertedAt
        comments {
          id
          body
          insertedAt
          author {
            id
            fullName
          }
          mentions {
            id
            user {
              id
              fullName
            }
          }
        }
      }
      ... on Comment {
        __typename
        id
        body
        insertedAt
        author {
          id
          fullName
        }
        mentions {
          id
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;
