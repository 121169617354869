import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  compareAsc,
  endOfMonth,
  endOfQuarter,
  endOfToday,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  format,
  getMonth,
  getTime,
  getYear,
  isMatch,
  isValid,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import { isNull } from "lodash";

export const DATE_FORMAT = "MM/dd/yyyy";
export const SERVER_DATE_FORMAT = "yyyy-MM-dd";
export const TRIM_DATE_FORMAT = "M/dd/yyyy";
export const SERVER_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS";

export function parseDate(date) {
  if (isValid(date)) return date;
  if (typeof date !== "string") return null;

  // Because we use multiple formats, this is does a fuzzy check on
  // multiple formats used across the codebase
  let parsedDate;
  if (isMatch(date, DATE_FORMAT)) {
    parsedDate = parse(date, DATE_FORMAT, new Date());
  } else if (isMatch(date, SERVER_DATE_FORMAT)) {
    parsedDate = parse(date, SERVER_DATE_FORMAT, new Date());
  } else if (isMatch(date, TRIM_DATE_FORMAT)) {
    parsedDate = parse(date, TRIM_DATE_FORMAT, new Date());
  } else if (isMatch(date, SERVER_DATE_TIME_FORMAT)) {
    parsedDate = parseISO(date);
  } else {
    parsedDate = date;
  }

  if (isValid(parsedDate)) {
    return parsedDate;
  }

  return null;
}

export const DATE_RANGE = {
  Today: "Today",
  Yesterday: "Yesterday",
  LastXDays: "LastXDays",
  PreviousXWeeks: "PreviousXWeeks",
  PreviousXMonths: "PreviousXMonths",
  PreviousXQuarters: "PreviousXQuarters",
  PreviousXYears: "PreviousXYears",
  WeekToDate: "WeekToDate",
  MonthToDate: "MonthToDate",
  QuarterToDate: "QuarterToDate",
  YearToDate: "YearToDate",
  Since: "Since",
  NextXDays: "NextXDays",
  NextXWeeks: "NextXWeeks",
  NextXMonths: "NextXMonths",
  NextXQuarters: "NextXQuarters",
  NextXYears: "NextXYears",
  CustomRange: "CustomRange",
};

export function getDateInterval(rangeType, rangeValue) {
  const startOfThisWeek = startOfWeek(startOfToday(), { weekStartsOn: 0 });
  const startOfNextWeek = addWeeks(startOfThisWeek, 1);

  const endOfThisWeek = endOfWeek(startOfToday(), { weekStartsOn: 0 });
  const endOfLastWeek = subWeeks(endOfThisWeek, 1);

  const startOfThisMonth = startOfMonth(startOfToday());
  const startOfNextMonth = addMonths(startOfThisMonth, 1);

  const endOfThisMonth = endOfMonth(startOfToday());
  const endOfLastMonth = subMonths(endOfThisMonth, 1);

  const startOfThisQuarter = startOfQuarter(startOfToday());
  const startOfNextQuarter = addQuarters(startOfThisQuarter, 1);

  const endOfThisQuarter = endOfQuarter(startOfToday());
  const endOfLastQuarter = subQuarters(endOfThisQuarter, 1);

  const startOfThisYear = startOfYear(startOfToday());
  const startOfNextYear = addYears(startOfThisYear, 1);

  const endOfThisYear = endOfYear(startOfToday());
  const endOfLastYear = subYears(endOfThisYear, 1);

  const [start, end] = (() => {
    switch (rangeType) {
      case DATE_RANGE.Today:
        return [startOfToday(), endOfToday()];
      case DATE_RANGE.Yesterday:
        return [startOfYesterday(), endOfYesterday()];
      case DATE_RANGE.LastXDays:
        return [subDays(startOfToday(), rangeValue), endOfToday()];
      case DATE_RANGE.PreviousXWeeks:
        return [subWeeks(startOfThisWeek, rangeValue), endOfLastWeek];
      case DATE_RANGE.PreviousXMonths:
        return [subMonths(startOfThisMonth, rangeValue), endOfLastMonth];
      case DATE_RANGE.PreviousXQuarters:
        return [subQuarters(startOfThisQuarter, rangeValue), endOfLastQuarter];
      case DATE_RANGE.PreviousXYears:
        return [subYears(startOfThisYear, rangeValue), endOfLastYear];
      case DATE_RANGE.WeekToDate:
        return [startOfThisWeek, endOfToday()];
      case DATE_RANGE.MonthToDate:
        return [startOfThisMonth, endOfToday()];
      case DATE_RANGE.YearToDate:
        return [startOfThisYear, endOfToday()];
      case DATE_RANGE.QuarterToDate:
        return [startOfThisQuarter, endOfToday()];
      case DATE_RANGE.Since:
        return [startOfDay(rangeValue), null];
      case DATE_RANGE.NextXDays:
        return [startOfToday(), addDays(endOfToday(), rangeValue)];
      case DATE_RANGE.NextXWeeks:
        return [startOfNextWeek, addWeeks(endOfThisWeek, rangeValue)];
      case DATE_RANGE.NextXMonths:
        return [startOfNextMonth, addMonths(endOfThisMonth, rangeValue)];
      case DATE_RANGE.NextXQuarters:
        return [startOfNextQuarter, addQuarters(endOfThisQuarter, rangeValue)];
      case DATE_RANGE.NextXYears:
        return [startOfNextYear, addYears(endOfThisYear, rangeValue)];
      default:
        return [null, null];
    }
  })();

  return { start, end };
}

export function formatDate(date, blankValue = "", dateFormat = DATE_FORMAT) {
  if (!date) return blankValue;
  const parsedDate = parseDate(date);
  return parsedDate ? format(parsedDate, dateFormat) : "Invalid Date";
}

export function formatTrimDate(date) {
  if (!date) return null;
  const parsedDate = parseDate(date);
  return parsedDate ? format(parsedDate, TRIM_DATE_FORMAT) : "Invalid Date";
}

export function formatTime(date) {
  if (!date) return null;
  const parsedDate = parseDate(date);
  return parsedDate ? format(parsedDate, "hh:mmaaa") : "Invalid Date";
}

export function timeInMilliseconds(date) {
  const parsedDate = parseDate(date);
  return getTime(parsedDate, DATE_FORMAT);
}

export function isInvalidDate(date) {
  if (date === null || date === undefined) return false;

  return (
    formatDate(date) === "Invalid Date" || date.length !== DATE_FORMAT.length
  );
}

export function dateServerToForm(date) {
  if (!date) return "";
  const parsedDate = parseDate(date);
  return format(parsedDate, "MM/dd/yyyy");
}

export function dateFormToServer(date) {
  if (!date) return null;
  const parsedDate = parseDate(date);
  return format(parsedDate, "yyyy-MM-dd");
}

export function formatDateTime(
  isoDateTime,
  blankValue = "-",
  dateTimeFormat = "MM/dd/yyyy h:mma"
) {
  if (!isoDateTime) return blankValue;
  const isoParsed = parseISO(`${isoDateTime}Z`);
  if (isValid(isoParsed)) {
    return format(isoParsed, dateTimeFormat);
  }
  return null;
}

export function formatDateTimeAsDate(dateTime) {
  return formatDateTime(dateTime, "-", "MM/dd/yyyy");
}

export function getDateFromDateTime(isoDateTime) {
  const date = new Date(isoDateTime);
  return format(date, SERVER_DATE_FORMAT);
}

// Returns true if date1 occurs before date2
export function isBefore(date1, date2) {
  return compareAsc(date1, date2) === -1;
}

// Returns true if date1 occurs at or before date2
export function isAtOrBefore(date1, date2) {
  const comparison = compareAsc(date1, date2);
  return comparison !== 1;
}

// Format form input time value
export function formatTimeToISO(time) {
  if (isNull(time)) return null;
  return `${time}:00`;
}

// Format Dozer time format to form input time
export function formatIsoToTime(time) {
  if (isNull(time)) return null;
  return time.slice(0, 5);
}

export function getMonthYearFromServerDate(date) {
  return `${getMonth(date)}-${getYear(date)}`;
}

export {
  addDays,
  addMonths,
  addYears,
  compareAsc,
  compareDesc,
  differenceInSeconds,
  differenceInDays,
  differenceInMonths,
  endOfDay,
  format,
  isValid,
  isWithinInterval,
  max,
  min,
  parse,
  startOfDay,
  subDays,
} from "date-fns";
