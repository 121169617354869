import { Fragment, useContext } from "react";
import { ProjectDetailsForm } from "components/templates";
import { Button, Pane } from "components/materials";
import { Formik } from "formik";
import { isValidZipcode } from "helpers/addressValidation";
import { UserContext } from "helpers/behaviors";
import { formatDate, isInvalidDate } from "helpers/dateHelpers";
import { CUSTOM_FIELD_TYPE } from "helpers/enums";
import isBlank from "helpers/isBlank";
import { majorScale } from "helpers/utilities";
import { get, isEmpty } from "lodash";
import t from "helpers/translate";
import { Header } from "./Header";
import { PersistStep } from "./PersistStep";
import { STEPS } from "./utils";

function initialValues(project) {
  const projectCustomFields = get(project, "customFields", []);
  const setupDetails = get(project, "onboardingData.details", {});

  const {
    name,
    customId,
    streetAddress,
    city,
    state,
    zip,
    acres,
    squareFeet,
    projectRegionId,
    productTypeId,
    drawUpdateSource,
    startDate,
    expectedProjectLength,
  } = project;

  const { customFields = projectCustomFields } = setupDetails;

  return {
    name: setupDetails.name || name,
    customId: setupDetails.customId || customId,
    streetAddress: setupDetails.streetAddress || streetAddress,
    city: setupDetails.city || city,
    stateValue: setupDetails.stateValue || state,
    zip: setupDetails.zip || zip,
    drawUpdateSource: setupDetails.drawUpdateSource || drawUpdateSource,
    startDate: formatDate(setupDetails.startDate || startDate) || "",
    expectedProjectLength:
      setupDetails.expectedProjectLength || expectedProjectLength,
    acres: setupDetails.acres || acres,
    squareFeet: setupDetails.squareFeet || squareFeet,
    projectRegionId: setupDetails.projectRegionId || projectRegionId,
    productTypeId: setupDetails.productTypeId || productTypeId,
    ...(customFields.length > 0 && {
      customFields: customFields.map(({ id, label, options, type, value }) => {
        if (type === CUSTOM_FIELD_TYPE.BOOLEAN) {
          return { id, label, type, value: value === "true" };
        }
        return { id, label, type, options, value };
      }),
    }),
  };
}

const validate = (isDeveloper) => (values) => {
  const errors = {};
  // Start date is required for lenders
  if (!isDeveloper && isBlank(values.startDate)) {
    errors.startDate = "Please enter a date";
  }
  if (!isBlank(values.startDate) && isInvalidDate(values.startDate))
    errors.startDate = "Date is invalid";

  if (!isValidZipcode(values.zip)) {
    errors.zip = "Postal Code is invalid";
  }
  return errors;
};

export function ProjectDetailsStep({
  navigateToStep,
  updateOnboardingData,
  project,
}) {
  const { isDeveloper } = useContext(UserContext);
  const values = initialValues(project);

  const getMutationData = (values) => {
    return {
      details: values,
    };
  };

  const updateProjectDetails = (values) => {
    const mutationData = getMutationData(values);
    updateOnboardingData(mutationData).then(() => {
      if (isDeveloper) navigateToStep(STEPS.DEVELOPER_HAS_DRAWS);
      else navigateToStep(STEPS.BUDGET_DECISION);
    });
  };

  return (
    <Formik
      initialValues={values}
      isInitialValid={isEmpty(validate(isDeveloper)(values))}
      validate={validate(isDeveloper)}
      onSubmit={updateProjectDetails}
    >
      {(formikProps) => (
        <Fragment>
          <Header
            header={t("onboardingWizard.projectDetails.title", {
              projectName: values.name,
            })}
            learnLink="https://help.rabbet.com/en/articles/2554529-edit-project-settings"
            subheader={t("onboardingWizard.projectDetails.subtitle")}
          />
          <ProjectDetailsForm
            customFields={get(formikProps, "values.customFields", [])}
            project={project}
            showName
            showCustomFields
          />
          <Pane marginTop={majorScale(2)}>
            <Button
              appearance="primary"
              onClick={formikProps.handleSubmit}
              purpose="onboarding-wizard next"
            >
              Next
            </Button>
          </Pane>
          <PersistStep
            mutationData={getMutationData(formikProps.values)}
            projectId={project.id}
          />
        </Fragment>
      )}
    </Formik>
  );
}
