import { Form } from "components/materials";

export function DrawSummarySelect({
  value,
  onChange,
  options,
  disabled,
  isWarned,
  confirmSelect,
  comboboxProps,
  testId,
  theme,
}) {
  return (
    <Form.NewSelect
      value={value}
      onChange={onChange}
      options={options}
      disabled={disabled}
      isWarned={isWarned}
      confirmSelect={confirmSelect}
      comboboxProps={comboboxProps}
      outerProps={{ backgroundColor: theme.colors.green200 }}
      textProps={{ color: theme.colors.green600, fontStyle: "italic" }}
      testId={testId}
    />
  );
}
