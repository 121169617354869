import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Mutation } from "@apollo/react-components";
import { get, find } from "lodash";
import { REVIEW_FRAGMENT } from "helpers/fragments";
import { toaster } from "helpers/utilities";
import { FreezeConfirmModal } from "../../components/containers/FreezeConfirmModal";

const DRAW_MUTATION = gql`
  mutation FrozenDrawMutation($targetId: String!, $projectId: String!) {
    unfreezeDraw(targetId: $targetId, projectId: $projectId) {
      id
      isFrozen
      state
    }
    resetReviewsOnTarget(targetId: $targetId, projectId: $projectId) {
      ...ReviewFragment
    }
  }
  ${REVIEW_FRAGMENT}
`;

const DOCUMENT_MUTATION = gql`
  mutation FrozenDocumentMutation($targetId: String!) {
    allowUpdateOnPostedDocument(documentId: $targetId) {
      id
    }
    resetReviewsOnDocument(documentId: $targetId) {
      id
      approvedBy {
        id
        fullName
      }
    }
  }
`;

const FROZEN_ERROR_MESSAGE = "Target is frozen and cannot be edited";

const getFrozenError = (error) => {
  const errors = get(error, "graphQLErrors", []);
  return find(errors, ["message", FROZEN_ERROR_MESSAGE]);
};

const isFrozenError = (error) => !!getFrozenError(error);
const noOp = () => {};

function isPromise(obj) {
  return obj && typeof obj.then === "function";
}

class Frozen extends Component {
  state = {
    targetType: undefined,
    targetId: undefined,
    execute: noOp,
    onCompleted: noOp,
  };

  handleSubmit = (func) => (...args) => {
    const onCompleted = get(args, "0.onCompleted", noOp);

    const execute = () => func(...args);
    const { drawId, isFrozen } = this.props;

    if (isFrozen && drawId) {
      this.setState({
        execute,
        targetId: drawId,
        targetType: "draw",
      });
    } else {
      this.setState({ execute, onCompleted }, () => {
        const executionResult = execute();

        if (isPromise(executionResult)) {
          executionResult.then((result) => {
            if (result && result.data) {
              onCompleted(result);
            }
          });
        }
      });
    }
  };

  handleError = (error, errorToastMessage) => {
    const frozenError = getFrozenError(error);
    if (frozenError) {
      const targetId = get(frozenError, "targets.0.id");
      const targetType = get(frozenError, "targets.0.type");

      this.setState({ targetId, targetType });
    } else if (errorToastMessage) {
      toaster.notify(errorToastMessage, { duration: 5 });
    }
  };

  handleCancel = () => {
    this.setState({ targetId: undefined, execute: noOp });
  };

  handleConfirm = (mutation) => () => {
    const { projectId } = this.props;
    const { targetId } = this.state;
    mutation({ variables: { targetId, projectId } });
  };

  handleCompleted = () => {
    const executionResult = this.state.execute();

    if (isPromise(executionResult)) {
      executionResult.then((result) => {
        if (result && result.data) {
          this.state.onCompleted(result);
        }
      });
    }

    this.handleCancel();
  };

  render() {
    const { children, projectId } = this.props;
    const { targetId, targetType } = this.state;

    return (
      <Fragment>
        {targetType === "draw" ? (
          <Mutation mutation={DRAW_MUTATION} onCompleted={this.handleCompleted}>
            {(mutation, result) => (
              <FreezeConfirmModal
                loading={result.loading}
                targetId={targetId}
                projectId={projectId}
                targetType={targetType}
                open={!!targetId}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm(mutation)}
              />
            )}
          </Mutation>
        ) : (
          <Mutation
            mutation={DOCUMENT_MUTATION}
            onCompleted={this.handleCompleted}
          >
            {(mutation, _result) => (
              <FreezeConfirmModal
                targetId={targetId}
                projectId={projectId}
                targetType={targetType}
                open={!!targetId}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm(mutation)}
              />
            )}
          </Mutation>
        )}
        {children({
          frozenError: this.handleError,
          frozenSubmit: this.handleSubmit,
          isFrozenError,
        })}
      </Fragment>
    );
  }
}

Frozen.propTypes = {
  projectId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default Frozen;
