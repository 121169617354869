import { Fragment } from "react";
import PropTypes from "prop-types";
import { Pane } from "components/materials";
import { ChevronRightIcon, majorScale } from "evergreen-ui";

export function Breadcrumb({ breadcrumbs }) {
  return (
    <Pane
      className="rbt-breadcrumb"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
    >
      {breadcrumbs.slice(0, -1).map((crumb, i) => (
        <Fragment key={i}>
          <Pane>{crumb}</Pane>
          <ChevronRightIcon marginX={majorScale(1)} />
        </Fragment>
      ))}
      <Pane>{breadcrumbs.slice(-1)}</Pane>
    </Pane>
  );
}

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.node).isRequired,
};
