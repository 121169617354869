import { useState, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Document } from "components/containers";
import { Loadable } from "components/materials";
import {
  COMMENT_FRAGMENT,
  DASHBOARD_DOCUMENTS_FRAGMENT,
} from "helpers/fragments";
import { UPDATE_PROJECT_SETUP_STEP } from "helpers/projectSetupStep";
import { differenceBy, find, get } from "lodash";
import { ProjectDashboard } from "./LegacyProjectDashboard/index";

const COMMENT_FRAGMENT_WITHOUT_TARGET = gql`
  fragment CommentFragmentWithoutTarget on Comment {
    id
    body
    insertedAt
    author {
      id
      fullName
    }
    mentions {
      id
      user {
        id
        fullName
      }
    }
  }
`;

export const QUERY = gql`
  query ProjectOverviewPageQuery($projectId: String!) {
    project(id: $projectId) {
      id
      amount
      assignedDocuments: documents(assigned: true) {
        ...DashboardDocumentsFragment
      }
      city
      comments {
        ...CommentFragmentWithoutTarget
      }
      contingencySpentAmount
      contingencyOriginalAmount
      coordinates {
        lat
        lng
      }
      disbursedAmount
      divisions {
        id
        scopeId
        name
        lineItems {
          id
          scopeId
          name
          budgetAmount
          originalBudgetAmount
          requestedToDateAmount
          lineItemCategories
        }
      }
      documentsPendingApprovalForUser {
        ...DashboardDocumentsFragment
      }
      documentReviewers {
        id
        user {
          id
        }
      }
      suggestedDocumentAssignees {
        id
        userId
      }
      draws {
        id
        issues {
          id
          documentId
          lineItemId
          name
          severity
        }
        name
        requestedAmount
        state
        createdAt
        fundedDate
        receivedDate
        submittedDate
        updatedAt
        targetAmount
        recentStateUpdate {
          id
          state
          date
        }
      }
      expectedProjectLength
      fundingSourceGroups {
        id
        amount
        disbursedAmount
        label
        type
      }
      hasFullDrawHistory
      inProgressAmount
      setupComplete
      tasks {
        id
        eventName
        originalStartDate
        status
      }
      mentions(dismissed: false) {
        id
        comment {
          ...CommentFragment
        }
      }
      pendingDrawApprovals {
        id
        draw {
          id
          requestedAmount
          name
          project {
            id
            name
          }
        }
      }
      name
      organization {
        id
        drawAssessmentQuestions {
          id
        }
        inspectionReportQuestions {
          id
        }
        users {
          id
          fullName
        }
        vendors {
          id
        }
      }
      picture {
        url
      }
      projectSetupDismissed
      recentUnfundedDraw {
        id
        createdAt
        name
        receivedDate
        state
        submittedDate
        updatedAt
        recentStateUpdate {
          id
          state
          date
        }
      }
      requestedAmount
      stakeholders {
        id
        role
        organization {
          id
          name
        }
      }
      startDate
      state
      status
      setupSteps {
        id
        step
        status
        completedAt
      }
      users {
        id
        fullName
      }
      contingencySegments {
        all {
          segments {
            name
            value
            id
          }
          drawnContingency
          originalContingency
          percentUsed
          remainingContingency
          totalContingency
          untrackedContingency
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
  ${COMMENT_FRAGMENT_WITHOUT_TARGET}
  ${DASHBOARD_DOCUMENTS_FRAGMENT}
`;

const COMMENT = gql`
  mutation AddProjectComment($body: String!, $projectId: String!) {
    addProjectComment(body: $body, projectId: $projectId) {
      id
    }
  }
`;

const DISMISS_PROJECT_SETUP = gql`
  mutation DismissProjectSetup($projectId: String!) {
    dismissProjectSetup(projectId: $projectId) {
      id
      projectSetupDismissed
    }
  }
`;

const ISSUES_SUBSCRIPTION = gql`
  subscription projectIssuesUpdated($projectId: String!) {
    projectIssuesUpdated(projectId: $projectId) {
      id
      draws {
        id
        issues {
          id
          documentId
          lineItemId
          name
          severity
        }
      }
    }
  }
`;
const COMMENT_SUBSCRIPTION = gql`
  subscription projectCommentAdded($projectId: String!) {
    projectCommentAdded(projectId: $projectId) {
      ...CommentFragmentWithoutTarget
    }
  }
  ${COMMENT_FRAGMENT_WITHOUT_TARGET}
`;

const MENTION_SUBSCRIPTION = gql`
  subscription projectMentions($projectId: String!) {
    projectMentions(projectId: $projectId) {
      id
      comment {
        ...CommentFragment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

const ASSIGNED_DOCUMENTS_SUBSCRIPTION = gql`
  subscription assignedDocuments($projectId: String!) {
    assignedDocuments(projectId: $projectId) {
      ...DashboardDocumentsFragment
    }
  }
  ${DASHBOARD_DOCUMENTS_FRAGMENT}
`;

export function ProjectOverviewPage({ history, match }) {
  const { documentId, projectId } = match.params;

  const addCommentMutation = useMutation(COMMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY, variables: { projectId } }],
  });

  const updateSetupStepMutation = useMutation(UPDATE_PROJECT_SETUP_STEP, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY, variables: { projectId } }],
  });

  const [dismissProjectSetup] = useMutation(DISMISS_PROJECT_SETUP, {
    variables: { projectId },
  });

  const { data, loading, subscribeToMore } = useQuery(QUERY, {
    variables: { projectId },
  });

  const [assignedDocuments, setAssignedDocuments] = useState(
    get(data, "project.assignedDocuments", [])
  );

  useEffect(
    () => setAssignedDocuments(get(data, "project.assignedDocuments", [])),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  useEffect(() => {
    return subscribeToMore({
      document: ISSUES_SUBSCRIPTION,
      variables: { projectId },
    });
  }, [projectId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: MENTION_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const mentions = get(subscriptionData, "data.projectMentions", []);
        return { project: { ...prev.project, mentions } };
      },
    });
  }, [projectId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: COMMENT_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const comments = get(subscriptionData, "data.projectCommentAdded", []);
        return { project: { ...prev.project, comments } };
      },
    });
  }, [projectId, subscribeToMore]);
  useEffect(() => {
    return subscribeToMore({
      document: ASSIGNED_DOCUMENTS_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const assignedSubscription = get(
          subscriptionData,
          "data.assignedDocuments",
          []
        );

        const newlyAssignedDocuments = differenceBy(
          assignedSubscription,
          assignedDocuments,
          ({ id }) => id
        ).map((document) => ({ ...document, newlyAssigned: true }));

        const result = assignedDocuments
          .reduce((acc, document) => {
            if (!find(assignedSubscription, ["id", document.id])) {
              return acc.concat({ ...document, reassigned: true });
            }
            return acc.concat(document);
          }, [])
          .concat(newlyAssignedDocuments);

        setAssignedDocuments(result);

        return { project: { ...prev.project, assignedDocuments: result } };
      },
    });
  }, [assignedDocuments, projectId, subscribeToMore]);

  if (loading) return <Loadable loading />;

  return (
    <Fragment>
      <ProjectDashboard
        addCommentMutation={addCommentMutation}
        dismissProjectSetup={dismissProjectSetup}
        history={history}
        project={data.project}
        updateSetupStepMutation={updateSetupStepMutation}
      />
      {documentId && (
        <Document
          documents={data.project.assignedDocuments}
          history={history}
          match={match}
          refetch={[{ query: QUERY, variables: { projectId } }]}
        />
      )}
    </Fragment>
  );
}
