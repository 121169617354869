import PropTypes from "prop-types";
import Box from "ui-box";
import { withTheme } from "evergreen-ui";
import { get } from "lodash";
import { boxProps, boxPropTypes } from "components/materials/utils";
import { minorScale, majorScale } from "helpers/utilities";
import { getProps, compose, withStrictMemo } from "./utils";

const TableCell = compose(
  (props) => {
    const {
      border = `1px solid ${props.theme.colors.gray200}`,
      borderLeft,
      borderRight,
      children,
      className,
      colSpan,
      content,
      highlighted,
      id,
      state,
      testId,
      onClick,
    } = props;

    const styleProps = {
      border,
      borderLeft,
      borderRight,
      margin: 0,
      paddingY: minorScale(1),
      paddingX: majorScale(1),
      boxShadow: highlighted
        ? `${props.theme.colors.warning} 0px 0px 0px 1px inset`
        : undefined,
      maxWidth: props.width,
      minWidth: props.width,
      ...props,
    };

    return (
      <Box
        {...boxProps(styleProps)}
        className={className}
        colSpan={colSpan}
        data-testid={testId || id}
        is={props.is}
        onClick={onClick}
        data-border-state={
          typeof state === "string" ? state : get(state, "border")
        }
        data-background-state={
          typeof state === "string" ? state : get(state, "background")
        }
      >
        {content || children}
      </Box>
    );
  },
  [withStrictMemo, withTheme]
);

export const propTypes = {
  ...boxPropTypes,
  children: PropTypes.node,
  colSpan: PropTypes.number,
  highlighted: PropTypes.bool,
  id: PropTypes.string,
  index: PropTypes.number,
  is: PropTypes.oneOf(["td", "th"]),
  renderOnChanged: PropTypes.any,
  rowIndex: PropTypes.number,
  state: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  testId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  border: PropTypes.string,
  borderLeft: PropTypes.string,
  borderRight: PropTypes.string,
};

TableCell.propTypes = propTypes;

// TODO: remove dot notation
TableCell.getProps = (props) => getProps(propTypes, props);

TableCell.defaultProps = {
  colSpan: 1,
  highlighted: false,
  is: "td",
};

export default TableCell;
