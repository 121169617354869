import { Fragment } from "react";
import {
  Card,
  Pane,
  Paragraph,
  Progress,
  Shortener,
} from "components/materials";
import { has, isEmpty, set, values } from "lodash";
import { BRAND_COLORS } from "helpers/colors";
import { HeaderTextCell, PortfolioInsightBlankSlateCard, TextCell } from ".";

export function UserActivity({ projects, stylingProps }) {
  const DAYS_SINCE_FUNDED_LIMIT = 31;

  const usersWithFundedDrawCount = projects.reduce((acc, { draws }) => {
    const drawsInRangeFundedByAUser = draws.filter(
      ({ daysSinceFunded, fundedByUser }) =>
        daysSinceFunded !== null &&
        fundedByUser !== null &&
        daysSinceFunded <= DAYS_SINCE_FUNDED_LIMIT
    );

    drawsInRangeFundedByAUser.forEach((draw) => {
      const { fundedByUser } = draw;

      if (has(acc, fundedByUser.id)) {
        acc[fundedByUser.id].numDrawsFunded += 1;
      } else {
        set(acc, fundedByUser.id, {
          userId: fundedByUser.id,
          numDrawsFunded: 1,
          userName: `${fundedByUser.firstName} ${fundedByUser.lastName}`,
        });
      }
    });
    return acc;
  }, {});

  if (isEmpty(usersWithFundedDrawCount))
    return <PortfolioInsightBlankSlateCard cardName="userActivity" />;

  const sortedUsers = values(usersWithFundedDrawCount).sort(
    (userA, userB) => userB.numDrawsFunded - userA.numDrawsFunded
  );
  const maxDrawsFunded = sortedUsers[0].numDrawsFunded;

  return (
    <Fragment>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        User Activity
      </Paragraph>
      <Card {...stylingProps.card}>
        <Pane maxHeight={300} overflowY="scroll">
          <table {...stylingProps.table}>
            <thead>
              <tr>
                <HeaderTextCell>User</HeaderTextCell>
                <HeaderTextCell colSpan={2}>
                  Draws Funded in the Past Month
                </HeaderTextCell>
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map((user) => {
                return (
                  <UserActivityRow
                    key={user.userId}
                    {...user}
                    maxDrawsFunded={maxDrawsFunded}
                  />
                );
              })}
            </tbody>
          </table>
        </Pane>
      </Card>
    </Fragment>
  );
}

function UserActivityRow({ maxDrawsFunded, numDrawsFunded, userName }) {
  return (
    <tr>
      <TextCell>
        <Shortener limit={30} size={300} text={userName} />
      </TextCell>
      <TextCell>{numDrawsFunded}</TextCell>
      <td width="100%" aria-label="progress">
        <Progress
          value={numDrawsFunded}
          total={maxDrawsFunded}
          color={BRAND_COLORS.SECONDARY}
          background="transparent"
        />
      </td>
    </tr>
  );
}
