import { Pane, Text } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { add, subtract } from "helpers/math";
import { majorScale, minorScale } from "helpers/utilities";

export function LineItemSummarySection({ recentDrawLineItem, lineItem }) {
  const amounts = recentDrawLineItem || {
    adjustmentsAmount: 0,
    previousAdjustmentsAmount: 0,
    originalBudgetAmount: lineItem.originalBudgetAmount,
    requestedToDateAmount: 0,
    retainageToDateAmount: 0,
  };

  const adjustmentsToDate = add(
    amounts.adjustmentsAmount,
    amounts.previousAdjustmentsAmount
  );

  const formattedAdjustmentsToDate = `${
    adjustmentsToDate > 0 ? "+" : ""
  }${formatCurrency(adjustmentsToDate)}`;

  const currentBudget = add(amounts.originalBudgetAmount, adjustmentsToDate);
  const netAmountRemaining = subtract(
    currentBudget,
    amounts.requestedToDateAmount
  );

  const informationToDisplay = [
    {
      name: "Original Budget",
      data: formatCurrency(amounts.originalBudgetAmount),
    },
    {
      name: "Adjustments to date",
      data: formattedAdjustmentsToDate,
    },
    {
      name: "Current Budget",
      data: formatCurrency(currentBudget),
    },
    {
      name: "Requested to date",
      data: formatCurrency(amounts.requestedToDateAmount),
    },
    {
      name: "Retainage withheld",
      data: formatCurrency(amounts.retainageToDateAmount),
    },
    {
      name: "Amount Remaining (Net)",
      data: formatCurrency(netAmountRemaining),
    },
  ];

  return (
    <Pane marginY={majorScale(4)} marginX="20%">
      {informationToDisplay.map((information) => (
        <Pane
          key={information.name}
          display="flex"
          justifyContent="space-between"
          margin={minorScale(1)}
        >
          <Text fontWeight={500} size={400}>
            {information.name}
          </Text>
          <Text fontWeight={500} size={400}>
            {information.data}
          </Text>
        </Pane>
      ))}
    </Pane>
  );
}
