import { useState, useEffect, Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DotIcon,
  ErrorIcon,
  InfoSignIcon,
  SymbolCircleIcon,
  WarningSignIcon,
} from "evergreen-ui";
import {
  Card,
  LinkButton,
  ListItem,
  Pane,
  Paragraph,
  Shortener,
  Text,
  Tooltip,
  UnorderedList,
} from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { UserContext, useResize } from "helpers/behaviors";
import { DRAW_STATE, PERMISSION_ACTION } from "helpers/enums";
import { formatDate } from "helpers/dateHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import { times, uniqBy } from "lodash";
import t from "helpers/translate";
import { getIssueIconType, groupIssuesBySeverity } from "helpers/issues";
import { BRAND_COLORS } from "helpers/colors";
import { getDrawStateDate } from "./PortfolioDashboard/PortfolioProjectCard";

const BUTTON_WIDTH = 150;
const BUTTON_MARGIN = majorScale(2);
const DRAW_WIDTH = BUTTON_WIDTH + BUTTON_MARGIN;
const PAGE_BUTTON_WIDTH = majorScale(6);

const getNumPages = (numDraws, width) => {
  if (width === 0) {
    return 0;
  }

  return Math.ceil(
    (numDraws * DRAW_WIDTH - 16) / (width - PAGE_BUTTON_WIDTH * 2)
  );
};

export const getStatusIconColor = ({ state }) => {
  switch (state) {
    case DRAW_STATE.FUNDED:
      return "success";
    case DRAW_STATE.PENDING_RESUBMISSION:
    case DRAW_STATE.RECEIVED:
    case DRAW_STATE.RECEIVED_AND_CHANGED:
    case DRAW_STATE.STARTED:
    case DRAW_STATE.SUBMITTED:
    case DRAW_STATE.INCOMPLETE:
      return "warning";
    default:
      return "warning";
  }
};

export const getDrawStatusColor = (state, theme) => {
  switch (state) {
    case DRAW_STATE.FUNDED:
      return theme.colors.green600;
    default:
      return theme.colors.orange500;
  }
};

const getDrawButtonStyles = ({ draw, isRecentDraw, theme }) => {
  if (draw.state === DRAW_STATE.INCOMPLETE) {
    return {
      background: theme.colors.lightYellow,
      borderLeft: `1px solid ${theme.colors.baseYellow}`,
      borderTop: `1px solid ${theme.colors.baseYellow}`,
      borderRight: `1px solid ${theme.colors.baseYellow}`,
      borderBottom: `1px solid ${theme.colors.baseYellow}`,
    };
  }
  if (isRecentDraw) {
    return {
      background: theme.colors.blue25,
      borderLeft: `8px solid ${BRAND_COLORS.LIGHT_PRIMARY}`,
    };
  }
  return {
    background: theme.colors.gray75,
  };
};

function CreateDrawButton({ appearance, newDrawPath, canCreateDraws }) {
  return (
    <LinkButton
      appearance={appearance}
      disabled={!canCreateDraws}
      height={24}
      marginBottom={majorScale(1)}
      purpose="project-dashboard draw new"
      to={canCreateDraws ? newDrawPath : null}
    >
      Create New Draw
    </LinkButton>
  );
}

function DrawCreationDisabledInfoTooltip({
  hasAllowMultipleUnfundedBorrowerDraws,
}) {
  return (
    <Tooltip
      content={
        hasAllowMultipleUnfundedBorrowerDraws
          ? t("borrowerDraw.info.previousActiveDraw")
          : t("borrowerDraw.info.previousUnfundedDraw")
      }
    >
      <InfoSignIcon
        color="info"
        marginLeft={majorScale(1)}
        size={minorScale(3)}
      />
    </Tooltip>
  );
}

function ScrollIcon({ page, direction, setPage, numPages }) {
  const hideIcon =
    (direction === "left" && page === 0) ||
    (direction === "right" && page === numPages - 1);

  const chevronIconProps = {
    color: "muted",
    cursor: "pointer",
    onClick: () => {
      const change = direction === "right" ? 1 : -1;
      setPage(page + change);
    },
    size: 24,
  };
  return (
    <Pane
      minWidth={PAGE_BUTTON_WIDTH}
      width={PAGE_BUTTON_WIDTH}
      display="flex"
      alignItems="flex"
      justifyContent={direction === "left" ? "flex-end" : "flex-start"}
      height="100%"
    >
      {!hideIcon &&
        (direction === "left" ? (
          <ChevronLeftIcon {...chevronIconProps} />
        ) : (
          <ChevronRightIcon {...chevronIconProps} />
        ))}
    </Pane>
  );
}

function DrawIssues({ issues }) {
  if (issues.length === 0) return null;

  const { highSeverityIssues, normalSeverityIssues } = groupIssuesBySeverity(
    issues
  );

  return (
    <Tooltip
      content={
        <Fragment>
          <Pane display="flex">
            <Text color="white">{"Issues: "}</Text>
            {highSeverityIssues.length > 0 && (
              <Pane
                display="flex"
                alignItems="center"
                marginLeft={majorScale(1)}
              >
                <Text color="white">{highSeverityIssues.length}</Text>
                <ErrorIcon color="danger" marginX={minorScale(1)} size={14} />
              </Pane>
            )}
            {normalSeverityIssues.length > 0 && (
              <Pane display="flex" alignItems="center">
                <Text color="white">{normalSeverityIssues.length}</Text>
                <WarningSignIcon
                  color="warning"
                  marginLeft={minorScale(1)}
                  size={14}
                />
              </Pane>
            )}
          </Pane>
          <UnorderedList>
            {uniqBy(issues, "name").map((issue) => {
              return (
                <ListItem key={issue.id} color="white">
                  {t(`issues.draw.${issue.name}`)}
                </ListItem>
              );
            })}
          </UnorderedList>
        </Fragment>
      }
    >
      {getIssueIconType(issues) === "highSeverityIcon" ? (
        <ErrorIcon color="danger" />
      ) : (
        <WarningSignIcon color="warning" />
      )}
    </Tooltip>
  );
}

function DrawButton({ draw, drawLink, isLastDraw, isRecentDraw, theme }) {
  const history = useHistory();
  const drawStyles = getDrawButtonStyles({ draw, isRecentDraw, theme });
  const isIncompleteDraw = draw.state === DRAW_STATE.INCOMPLETE;
  const isActiveRecentDraw = isRecentDraw && !isIncompleteDraw;
  const { hasPermission } = useContext(UserContext);

  return (
    <Pane
      {...drawStyles}
      borderRadius={majorScale(1)}
      cursor="pointer"
      display="flex"
      elevation={1}
      flexDirection="column"
      height={130}
      hoverElevation={2}
      marginRight={isLastDraw ? null : BUTTON_MARGIN}
      onClick={() => history.push(drawLink)}
      paddingY={majorScale(1)}
      paddingX={isRecentDraw ? majorScale(1) : majorScale(2)}
      width={BUTTON_WIDTH}
      minWidth={BUTTON_WIDTH}
      data-purpose="project-dashboard draw open"
      data-testid="drawTile"
    >
      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Shortener
          color={isActiveRecentDraw ? theme.colors.baseBlue : undefined}
          fontWeight={theme.fontWeights.DEMI}
          limit={14}
          marginTop={minorScale(1)}
          text={draw.name}
        />
        {hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL) && (
          <DrawIssues issues={draw.issues} />
        )}
      </Pane>
      <Pane display="flex" alignItems="center">
        {/* Set marginLeft to a negative value to accommodate extra padding given to the underlying icon's svg */}
        <DotIcon color={getStatusIconColor(draw)} marginLeft={-minorScale(1)} />
        <Shortener
          limit={14}
          size={300}
          tooltip
          data-testid="drawTileState"
          text={t(`drawStates.${draw.state}`)}
        />
      </Pane>
      <Paragraph
        fontSize={10}
        marginLeft={minorScale(3)}
        marginTop={-minorScale(1)}
        data-testid="drawTileDate"
      >
        {formatDate(getDrawStateDate(draw))}
      </Paragraph>
      <Paragraph fontSize={10} fontWeight={theme.fontWeights.DEMI}>
        DRAW TOTAL
      </Paragraph>
      <Paragraph
        color={isActiveRecentDraw ? theme.colors.baseBlue : undefined}
        fontWeight={theme.fontWeights.DEMI}
        marginTop={-minorScale(1)}
        data-testid="drawTileTotal"
      >
        {formatCurrency(draw.requestedAmount)}
      </Paragraph>
      {isIncompleteDraw && (
        <Fragment>
          <Paragraph
            marginTop={-minorScale(1)}
            color={theme.colors.baseYellow}
            fontSize={10}
            fontWeight={theme.fontWeights.DEMI}
          >
            TARGET
          </Paragraph>
          <Paragraph
            color={theme.colors.baseYellow}
            fontWeight={theme.fontWeights.DEMI}
            marginTop={-minorScale(1)}
            data-testid="drawTileTotal"
          >
            {formatCurrency(draw.targetAmount)}
          </Paragraph>
        </Fragment>
      )}
    </Pane>
  );
}

export function ProjectDraws({
  getDrawPath,
  newDrawPath,
  canCreateDraws,
  hasAllowMultipleUnfundedBorrowerDraws,
  project,
}) {
  const theme = useContext(ThemeContext);
  const noDraws = project.draws.length === 0;
  const [page, setPage] = useState(0);
  const [ref, size, handleResize] = useResize();
  useEffect(() => {
    handleResize();
  }, [handleResize]);
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.children[0].children[1].scrollLeft =
        page * (size.width - PAGE_BUTTON_WIDTH * 2);
    }
  }, [page, ref, size.width]);

  const numPages = getNumPages(project.draws.length, size.width);

  const buttonProps =
    noDraws || !project.recentUnfundedDraw ? { appearance: "primary" } : {};

  return (
    <Pane width="100%" marginTop={majorScale(1)}>
      <Paragraph
        height={40}
        display="flex"
        alignItems="center"
        color="muted"
        fontWeight={theme.fontWeights.MEDIUM}
      >
        DRAWS
      </Paragraph>
      <Card height={210} paddingY={majorScale(2)}>
        {noDraws ? (
          <Pane
            textAlign="center"
            height="100%"
            marginTop={majorScale(5)}
            width="100%"
          >
            <Paragraph>This project has no draws.</Paragraph>
            <CreateDrawButton
              {...buttonProps}
              newDrawPath={newDrawPath}
              canCreateDraws={canCreateDraws}
            />
          </Pane>
        ) : (
          <Pane>
            <Pane display="flex" alignItems="center" marginX={majorScale(6)}>
              <Pane flex="1 1 auto">
                <CreateDrawButton
                  {...buttonProps}
                  newDrawPath={newDrawPath}
                  canCreateDraws={canCreateDraws}
                />
                {!canCreateDraws && (
                  <DrawCreationDisabledInfoTooltip
                    hasAllowMultipleUnfundedBorrowerDraws={
                      hasAllowMultipleUnfundedBorrowerDraws
                    }
                  />
                )}
              </Pane>
              {numPages > 1 &&
                times(numPages, (index) => (
                  <SymbolCircleIcon
                    key={index}
                    color={index === page ? "muted" : "disabled"}
                    cursor="pointer"
                    onClick={() => setPage(index)}
                  />
                ))}
            </Pane>
            {/*
            The div is needed here because of evergreen's treatment of the ref is pants on head stupid
            (and also seemingly completely undocumented).  They set the ref to the react component
            itself. That doesn't have any of the actual dom properties on it, so we can't use that.
            I couldn't figure out a way to get to the actual underlying component, so just wrap this in an
            otherwise useless div.
            */}
            <div ref={ref}>
              <Pane
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                paddingY={majorScale(1)}
              >
                <ScrollIcon
                  page={page}
                  direction="left"
                  setPage={setPage}
                  numPages={numPages}
                />
                <Pane
                  className="draws-list"
                  display="flex"
                  justifyContent="flex-start"
                  overflow="hidden"
                  paddingY={3}
                  paddingLeft={1}
                >
                  {project.draws.map((draw, index) => (
                    <DrawButton
                      key={`${index}-${draw.id}`}
                      draw={draw}
                      drawLink={getDrawPath(draw.id)}
                      isRecentDraw={index === 0}
                      isLastDraw={index === project.draws.length - 1}
                      theme={theme}
                    />
                  ))}
                  <Pane minWidth={1} />
                </Pane>
                <ScrollIcon
                  page={page}
                  direction="right"
                  setPage={setPage}
                  numPages={numPages}
                />
              </Pane>
            </div>
          </Pane>
        )}
      </Card>
    </Pane>
  );
}
