import PropTypes from "prop-types";
import { Pane, Text, Spinner } from "components/materials";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale, minorScale, withTheme } from "helpers/utilities";
import t from "helpers/translate";
import { documentIsProcessing } from "helpers/documentHelpers";
import { get } from "lodash";

const formatPages = (pages) => {
  if (!Array.isArray(pages) || pages.length < 1) return null;
  if (pages.length === 1) return `page ${pages[0]}`;
  if (pages.length <= 5) return `pages ${pages.join(", ")}`;
  return `pages ${pages.slice(0, 4).join(", ")}...${pages[pages.length - 1]}`;
};

function DocumentCard({ document, theme }) {
  const drawName = get(document, "draw.name", "");
  const amount =
    document.amount ||
    document.adjustedAmount ||
    document.agreementAmount ||
    document.drawSummaryAmount;
  const documentType = get(document, "type", "");
  const pages = get(document, "pages", []);

  const isProcessing = documentIsProcessing(document);

  return (
    <Pane width="100%">
      <Pane display="flex" justifyContent="space-between">
        <Text
          textTransform="uppercase"
          color="#7f8f9f"
          fontSize={11}
          fontWeight={theme.fontWeights.DEMI}
        >
          {documentType && documentType !== DOCUMENT_TYPE_NAME.UNKNOWN
            ? t(`documentTypeName.${documentType}`)
            : "-"}
        </Text>
        {isProcessing && (
          <Spinner marginLeft={minorScale(1)} size={majorScale(2)} />
        )}
      </Pane>
      <Pane>
        <Text fontWeight={theme.fontWeights.MEDIUM}>
          {get(document, "vendor.id")
            ? get(document, "vendor.name")
            : get(document, "originalFile.name", "")}
        </Text>
      </Pane>
      <Pane display="flex" justifyContent="space-between">
        <Text color="#282e34" fontSize={11} marginRight={majorScale(1)}>
          {amount ? formatCurrency(amount) : formatPages(pages)}
        </Text>
        <Text color="#282e34" fontSize={11} textTransform="uppercase">
          {drawName && `${drawName}`}
        </Text>
      </Pane>
    </Pane>
  );
}

DocumentCard.propTypes = {
  document: PropTypes.object.isRequired,
};

export default withTheme(DocumentCard);
