import PropTypes from "prop-types";
import { Text, Tooltip, Position, majorScale, withTheme } from "evergreen-ui";
import { getProps, compose, withMemo } from "./utils";
import TableCell, { propTypes as cellPropTypes } from "./Cell";

const TableTextCell = compose(
  ({
    cellProps,
    children,
    content,
    textProps = {},
    indented,
    textAlign,
    ...props
  }) => {
    const getContentJustification = () => {
      switch (textAlign) {
        case "left":
          return "flex-start";
        case "right":
          return "flex-end";
        case "center":
          return "center";
        case "space-between":
          return "space-between";
        default:
          return "flex-start";
      }
    };

    textProps = {
      display: props.maxWidth ? "inline" : "flex",
      alignItems: "center",
      justifyContent: getContentJustification(),
      fontWeight: 400,
      paddingLeft: indented ? majorScale(3) : null,
      size: 300,
      width: "100%",
      ...textProps,
    };

    const getOverflowProps = () => {
      if (!props.maxWidth) return {};

      return {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
    };

    const tableCellProps = {
      ...props,
      ...cellProps,
      ...getOverflowProps(),
    };

    const cellContent = <Text {...textProps}>{content || children}</Text>;
    return (
      <TableCell {...tableCellProps}>
        {props.maxWidth ? (
          <Tooltip
            content={content || children}
            position={Position.BOTTOM_LEFT}
          >
            {cellContent}
          </Tooltip>
        ) : (
          cellContent
        )}
      </TableCell>
    );
  },
  [withMemo, withTheme]
);

export const propTypes = {
  ...cellPropTypes,
  children: PropTypes.node,
  content: PropTypes.node,
  indented: PropTypes.bool,
  textProps: PropTypes.object,
};

TableTextCell.propTypes = propTypes;

// TODO: remove dot notation
TableTextCell.getProps = (props) => getProps(propTypes, props);

export default TableTextCell;
