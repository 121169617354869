import PropTypes from "prop-types";
import { Pane } from "components/materials";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { cloneDeep, find } from "lodash";
import { majorScale } from "helpers/utilities";
import { DragItem } from "./DragItem";
import { StaticItem } from "./StaticItem";

export function DragAndDrop({
  items,
  onUpdate,
  onRemove,
  itemHeight,
  children,
  ...props
}) {
  const draggableItems = items.filter(({ isDraggable }) => !!isDraggable);
  const draggableItemCount = draggableItems.length;
  const staticItems = items.filter(({ isDraggable }) => !isDraggable);

  function constructReorderedItems(key, source, destination) {
    const item = find(draggableItems, (item) => item.key === key);
    const newDraggableItems = cloneDeep(draggableItems);
    newDraggableItems.splice(source.index, 1);
    newDraggableItems.splice(destination.index, 0, item);
    return newDraggableItems;
  }

  function onDragEnd({ draggableId, source, destination }) {
    if (!destination || destination.index === source.index) return;
    const newDraggableItems = constructReorderedItems(
      draggableId,
      source,
      destination
    );
    onUpdate(staticItems.concat(newDraggableItems));
  }

  // this function can be used by an implementation's custom drag item content to trigger an update to the drag and drop's state outisde of a drag action
  function handleManualOrderSelection(key, startPosition, endPosition) {
    onDragEnd({
      draggableId: key,
      source: { index: startPosition },
      destination: { index: endPosition },
    });
  }

  function handleRemove(key) {
    const removedItem = find(draggableItems, (item) => item.key === key);
    onRemove(removedItem);
  }

  return (
    <Pane {...props}>
      <Pane
        display="flex"
        flexDirection="column"
        minHeight={itemHeight * staticItems.length}
      >
        {staticItems.map((item) => (
          <StaticItem
            key={item.key}
            item={item}
            itemHeight={itemHeight}
            onRemove={handleRemove}
          />
        ))}
      </Pane>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="dropColumn">
          {(provided) => (
            <Pane
              display="flex"
              flexDirection="column"
              minHeight={itemHeight * draggableItemCount}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {draggableItems.map((item, index) => (
                <DragItem
                  key={`${item.key}-${index}`}
                  index={index}
                  item={item}
                  itemHeight={itemHeight}
                  onRemove={handleRemove}
                  handleManualOrderSelection={handleManualOrderSelection}
                >
                  {children}
                </DragItem>
              ))}
              {provided.placeHolder}
            </Pane>
          )}
        </Droppable>
      </DragDropContext>
    </Pane>
  );
}

DragAndDrop.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      canRemove: PropTypes.bool,
      isDraggable: PropTypes.bool,
      key: PropTypes.string.isRequired,
      value: PropTypes.object.isRequired,
      text: PropTypes.string,
      secondaryText: PropTypes.string,
    })
  ),
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  itemHeight: PropTypes.number,
};

DragAndDrop.defaultProps = {
  itemHeight: majorScale(5),
};
