import { Alert, Text } from "components/materials";
import { majorScale } from "helpers/utilities";

export default function NoBudgetAlert({ drawId, projectId }) {
  const documentURL = drawId
    ? `/projects/${projectId}/draws/${drawId}/documentation?uploadDocument=true`
    : `/projects/${projectId}/documentation?uploadDocument=true`;

  return (
    <Alert marginX={majorScale(1)} marginY={majorScale(3)}>
      <Text>
        A budget is required to review this document. Please{" "}
        <a href={documentURL}>upload a budget</a>.
      </Text>
    </Alert>
  );
}
