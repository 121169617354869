import { Fragment } from "react";
import { InfoSignIcon } from "evergreen-ui";
import { Table, Pane, Tooltip, Text, Shortener } from "components/materials";
import { getMonthHasActuals } from "helpers/projectionHelpers";
import {
  NEW_PROJECTION_STATE,
  UPDATED_PROJECTION_STATE,
  UPDATING_PROJECTION_STATE,
  NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE,
} from "hooks/use-projections";
import { minorScale } from "helpers/utilities";

const TABLE_BORDER = "2px solid #ebedf0";

export function ProjectionTableHeader({
  actualsCount,
  columns,
  headerCells,
  project,
  projectionState,
  drawNames,
  superheaderColSpan,
}) {
  function showExtraCell(projectionState) {
    return (
      projectionState === NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE &&
      columns.showUpdatedProjection &&
      columns.showOriginalProjection
    );
  }

  return (
    <Table.Head>
      <Table.Row>
        <Table.TextHeaderCell />
        <Table.TextHeaderCell colSpan={superheaderColSpan} />
        {project.months.map((month) => {
          return (
            <Fragment>
              <Table.TextHeaderCell width={125} borderLeft={TABLE_BORDER}>
                {month}
              </Table.TextHeaderCell>
              {showExtraCell(projectionState) && <Table.TextHeaderCell />}
            </Fragment>
          );
        })}
        <Table.TextHeaderCell borderLeft={TABLE_BORDER} />
      </Table.Row>
      <Table.Row>
        {headerCells.map(({ header, props }) => (
          <Table.TextHeaderCell key={header} {...props}>
            {header}
          </Table.TextHeaderCell>
        ))}
        {project.months.map((_month, monthIndex) => {
          const monthHasActuals = getMonthHasActuals(monthIndex, actualsCount);

          switch (projectionState) {
            case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
              return (
                <Fragment>
                  {columns.showOriginalProjection && (
                    <Table.TextHeaderCell
                      borderLeft={TABLE_BORDER}
                      textAlign="center"
                      width={125}
                    >
                      Projected
                    </Table.TextHeaderCell>
                  )}
                  {columns.showUpdatedProjection && (
                    <Table.TextHeaderCell
                      borderLeft={TABLE_BORDER}
                      textAlign="center"
                      width={125}
                    >
                      {monthHasActuals ? (
                        <Pane>
                          Actual
                          <ActualDrawNames names={drawNames[monthIndex]} />
                        </Pane>
                      ) : (
                        "Updated Projection"
                      )}
                    </Table.TextHeaderCell>
                  )}
                </Fragment>
              );
            case UPDATING_PROJECTION_STATE ||
              UPDATED_PROJECTION_STATE ||
              NEW_PROJECTION_STATE:
              return (
                <Table.TextHeaderCell
                  borderLeft={TABLE_BORDER}
                  textAlign="center"
                  width={125}
                >
                  {monthHasActuals ? (
                    <Pane>
                      Actual
                      <ActualDrawNames names={drawNames[monthIndex]} />
                    </Pane>
                  ) : (
                    "Projection"
                  )}
                </Table.TextHeaderCell>
              );
            default:
              return (
                <Table.TextHeaderCell
                  borderLeft={TABLE_BORDER}
                  textAlign="center"
                  width={125}
                >
                  {monthHasActuals ? (
                    <Pane>
                      Actual
                      <ActualDrawNames names={drawNames[monthIndex]} />
                    </Pane>
                  ) : (
                    "Projection"
                  )}
                </Table.TextHeaderCell>
              );
          }
        })}
        <Table.TextHeaderCell borderLeft={TABLE_BORDER} />
      </Table.Row>
    </Table.Head>
  );
}

function ActualDrawNames({ names }) {
  names = names || [];

  if (names.length === 0) {
    return (
      <Pane>
        <Text size={300}>0 DRAWS</Text>
      </Pane>
    );
  }

  if (names.length === 1) {
    const name = names[0];
    return (
      <Pane cursor="pointer">
        <Shortener limit={12} text={name.toUpperCase()} tooltip size={300} />
      </Pane>
    );
  }

  return (
    <Pane cursor="pointer">
      <Tooltip content={names.join(", ")}>
        <Text size={300}>
          {names.length} DRAWS{" "}
          <InfoSignIcon
            color="default"
            marginLeft={minorScale(1)}
            size={minorScale(3)}
          />
        </Text>
      </Tooltip>
    </Pane>
  );
}
