import { useContext } from "react";
import {
  EyeOffIcon,
  InfoSignIcon,
  NotificationsIcon,
  WarningSignIcon,
} from "evergreen-ui";
import { Pane, Text } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";

export function Banner({
  borderBottom,
  children = null,
  icon = null,
  mainText,
  secondaryText,
  textProps = {},
  ...props
}) {
  const theme = useContext(ThemeContext);

  const bannerIconProps = {
    marginRight: majorScale(1),
    color: theme.colors.icon.default,
  };

  const BANNER_ICONS = {
    "info-sign": <InfoSignIcon {...bannerIconProps} />,
    "warning-sign": <WarningSignIcon {...bannerIconProps} />,
    "eye-off": <EyeOffIcon {...bannerIconProps} />,
    notifications: <NotificationsIcon {...bannerIconProps} />,
  };

  return (
    <Pane
      alignItems="flex-start"
      backgroundColor={theme.colors.lightBlue}
      borderBottom={
        borderBottom ? `1px solid ${theme.layoutColors.border}` : null
      }
      display="flex"
      flex="0 0 auto"
      padding={majorScale(1)}
      paddingLeft={majorScale(3)}
      {...props}
    >
      {icon && BANNER_ICONS[icon]}
      {mainText && (
        <Text
          fontWeight={theme.fontWeights.MEDIUM}
          marginRight={minorScale(1)}
          {...textProps}
        >
          {mainText}
        </Text>
      )}
      {secondaryText && <Text {...textProps}>{secondaryText}</Text>}
      {children}
    </Pane>
  );
}
