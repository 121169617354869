import { memo, useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { dimensions, spacing, position, layout } from "ui-box";
import fuzzaldrin from "fuzzaldrin-plus";
import {
  AddIcon,
  IconButton,
  TextInput,
  CaretDownIcon,
  LightbulbIcon,
  Group,
  Pane,
  majorScale,
} from "evergreen-ui";
import isBlank from "helpers/isBlank";
import { getButtonBackgroundStylingProps } from "helpers/themeHelpers";
import { ThemeContext } from "helpers/utilities";
import { isEqual, some } from "lodash";
import Autocomplete from "./Autocomplete";

// https://github.com/segmentio/evergreen/blob/c011a50e92ec9de090421b077b90469c03f047fe/src/combobox/src/Combobox.js
// Rabbet additions:
//   - the "add item" flow, by which a new dropdown option can be created on the fly
//   - a handleChange function that launches the "add item" flow, and accepts formik and non-formik state updates
//   - a custom filter that uses the same "fuzzaldrin" library, always shuffles the "add item" to the end if present
//   - bypass the native `selectedItem` prop, and determine the selectedItem based on other props
//   - `getSuggestedToggleButtonProps` styles the button for a couple cases in which `hasSuggestions` prop is true
//   - tracking the use of the escape key, seemingly in an effort to maintain proper state in the search field
//   - explicit handling of the `filter` state and what is shown in the filter input field under particular circumstances

function orderComparator(a, b) {
  return (a || 0) - (b || 0);
}
function fuzzyfilter(addName, itemKey = "text") {
  return (items, input) => {
    const fuzzed = fuzzaldrin
      .filter(items, input, { key: itemKey })
      .filter((item) => item.text !== addName)
      .sort((a, b) => orderComparator(a.order, b.order));

    const addNewItemOption = (item) => item.text === addName;

    if (!addName || !items.find(addNewItemOption)) {
      return fuzzed;
    }

    return [...fuzzed, ...[items.find(addNewItemOption)]];
  };
}

function getSuggestedToggleButtonProps() {
  return {
    iconBefore: LightbulbIcon,
    width: majorScale(6),
    ...getButtonBackgroundStylingProps("#FCECA5"),
  };
}

const itemToKey = (item) => item?.key || "";
const itemToString = (item) => item?.selectedText || item?.text || "";
const findByKey = (options, key) =>
  options.find((option) => isEqual(option.key, key));
const findByValue = (options, value) =>
  options.find((option) => isEqual(option.value, value));
const getItemStringField = (items) => {
  const useSelectedText = some(items, (item) => !isBlank(item.selectedText));
  return useSelectedText ? "selectedText" : "text";
};

const RabbetCombobox = memo(function Combobox(props) {
  const {
    name,
    items,
    onChange,
    openOnFocus,
    isLoading = false,
    addName,
    fullBorder,
    disabled: propsDisabled,
    form,
    formValue,
    hasSuggestions,
    height,
    noNull,
    onAddItem,
    placeholder,
    popoverMinWidth,
    testId,
    textProps,
    value: selectValue,
    width = 240,
    filter,
    setFilter,
    confirmSelect,
    // currently unused within the app, can be passed to <Select /> and will be spread here via the combobox props
    autocompleteProps,
    buttonProps,
    initialSelectedItem,
    inputProps,
    itemToString: _propsItemToString,
    selectedItem: _passedSelectedItem,
    size = "medium",
    // all other props - margins, padding, width, etc. are spread onto the Group surrounding the combobox input + button
    ...inputGroupProps
  } = props;
  const [isOpenedByButton, setIsOpenedByButton] = useState(false);
  const [isEscape, setIsEscape] = useState(false);

  const disabled = propsDisabled || isLoading;

  function handleChange(selectedItem) {
    const selectedValue = selectedItem?.value;
    if (selectedValue === "add") {
      onAddItem(filter);
    } else if (!(noNull && selectedItem === null)) {
      form && name && form.setFieldValue(name, selectedValue);
      const newlySelectedItem = findByValue(items, selectedValue);
      onChange && onChange(selectedValue, newlySelectedItem);
    }

    setFilter("");
  }

  const handleStateChange = useCallback(
    (changes, stateAndHelpers) => {
      if (Object.prototype.hasOwnProperty.call(changes, "isOpen")) {
        if (!changes.isOpen) {
          setIsOpenedByButton(false);
        }
      }

      if (
        autocompleteProps &&
        typeof autocompleteProps.onStateChange === "function"
      ) {
        autocompleteProps.onStateChange(changes, stateAndHelpers);
      }
    },
    [autocompleteProps]
  );

  const value =
    (noNull && formValue === null) || formValue === undefined
      ? selectValue
      : formValue;

  const preparedItems = onAddItem
    ? items.concat({
        key: "add",
        value: "add",
        icon: <AddIcon color="selected" size={14} />,
        text: addName,
      })
    : items;

  const selectedItem = findByValue(preparedItems, value);

  // Rabbet theme to use to add gradient effect to dropdown button
  const theme = useContext(ThemeContext);

  // Rabbet added in order to have the dropdown button in the select component to have a gradient effect
  const overrideButtonProps = {
    ...getButtonBackgroundStylingProps(theme.colors.gray100),
    appearance: "minimal",
    color: theme.colors.gray900,
    border: `1px solid ${theme.colors.gray300}`,
  };

  return (
    <Pane
      display="flex"
      alignItems="center"
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          setIsEscape(true);
        } else {
          setIsEscape(false);
        }
      }}
    >
      <Autocomplete
        key={selectedItem?.key}
        confirmSelect={confirmSelect}
        // these props passed directly to downshift
        items={preparedItems}
        itemToString={itemToString}
        selectedItem={selectedItem}
        onChange={handleChange}
        selectedItemChanged={(prevItem, item) =>
          itemToKey(prevItem) !== itemToKey(item)
        }
        defaultHighlightedIndex={0}
        initialSelectedItem={initialSelectedItem}
        {...autocompleteProps}
        // rusty - this is intentionally after the autoComplete props are spread for some reason
        onStateChange={handleStateChange}
        // ---------
        itemsFilter={fuzzyfilter(addName, getItemStringField(items))}
        isFilterDisabled={isOpenedByButton || filter === ""}
        popoverMinWidth={popoverMinWidth}
        fullBorder={fullBorder}
      >
        {({
          clearSelection,
          getInputProps,
          getRef,
          getToggleButtonProps,
          inputValue,
          isShown,
          openMenu,
          setState,
        }) => {
          const inputKey =
            inputValue ||
            (noNull && isEscape && selectedItem && selectedItem.key) ||
            null;

          const foundItem = findByKey(preparedItems, inputKey);

          const shownValue =
            foundItem?.selectedText || foundItem?.text || inputValue;

          return (
            <Group ref={getRef} size={size} width={width} {...inputGroupProps}>
              <TextInput
                width={0}
                flex={1}
                height={height}
                value={shownValue}
                data-testid={testId}
                borderTopRightRadius={0}
                borderBottomRightRadius={0}
                disabled={disabled}
                name={name}
                {...textProps}
                {...getInputProps({
                  ...inputProps,
                  value: shownValue,
                  placeholder: itemToString(selectedItem) || placeholder,
                  onFocus: () => {
                    if (openOnFocus) openMenu();
                    setState({ inputValue: "" });
                  },
                  onChange: (e) => {
                    if (isOpenedByButton) {
                      setIsOpenedByButton(false);
                    }

                    if (e.target.value.trim() === "") {
                      // Prevent the selected item from sticking around
                      clearSelection();
                    }

                    setFilter(e.target.value);
                  },
                })}
              />
              <IconButton
                color="muted"
                icon={isLoading ? undefined : CaretDownIcon}
                appearance="default"
                height={height}
                marginTop={0}
                marginBottom={0}
                marginLeft={-1}
                paddingLeft={isLoading ? 12 : 0}
                paddingRight={0}
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}
                disabled={disabled}
                isLoading={isLoading}
                tabIndex={-1}
                type="button"
                {...overrideButtonProps}
                {...getToggleButtonProps({
                  ...buttonProps,
                  onClick: () => {
                    if (!isShown) {
                      setIsOpenedByButton(true);
                    }
                    setState({
                      inputValue: isShown ? itemToString(selectedItem) : "",
                    });
                    if (filter) setFilter("");
                  },
                })}
                {...(hasSuggestions
                  ? getSuggestedToggleButtonProps()
                  : undefined)}
              />
            </Group>
          );
        }}
      </Autocomplete>
    </Pane>
  );
});

RabbetCombobox.propTypes = {
  /**
   * Implements some APIs from ui-box.
   */
  ...dimensions.propTypes,
  ...spacing.propTypes,
  ...position.propTypes,
  ...layout.propTypes,

  /**
   * The options to show in the menu.
   */
  items: PropTypes.array.isRequired,

  /**
   * The selected item when controlled.
   */
  selectedItem: PropTypes.any,

  /**
   * Function called when value changes.
   */
  onChange: PropTypes.func,

  /**
   * When true, open the autocomplete on focus.
   */
  openOnFocus: PropTypes.bool,

  /**
   * Default selected item when uncontrolled.
   */
  initialSelectedItem: PropTypes.any,

  /**
   * The placeholder text when there is no value present.
   */
  placeholder: PropTypes.string,

  /**
   * In case the array of items is not an array of strings,
   * this function is used on each item to return the string that will be shown on the filter
   */
  itemToString: PropTypes.func,

  /**
   * Properties forwarded to the input. Use with caution.
   */
  inputProps: PropTypes.object,

  /**
   * Properties forwarded to the button. Use with caution.
   */
  buttonProps: PropTypes.object,

  /**
   * Properties forwarded to the autocomplete component. Use with caution.
   */
  autocompleteProps: PropTypes.object,

  /**
   * Makes the input element disabled.
   */
  disabled: PropTypes.bool,

  /**
   * When true, show a loading spinner. This also disables the button.
   */
  isLoading: PropTypes.bool,

  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default RabbetCombobox;
