import { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { InfoSignIcon, MoreIcon, TickIcon } from "evergreen-ui";
import { AssignToDropdown } from "components/templates";
import {
  Badge,
  Button,
  Confirm,
  ListItem,
  Menu,
  Pane,
  Popover,
  Text,
  Tooltip,
  UnorderedList,
  Spinner,
} from "components/materials";
import { brokenLink } from "images";
import { subtract } from "helpers/math";
import t from "helpers/translate";
import { get, includes, keys, map, uniq } from "lodash";
import { majorScale, minorScale, Position } from "helpers/utilities";
import {
  DOCUMENT_STATE,
  DOCUMENT_TYPE_NAME,
  DOCUMENT_IMPORT_SOURCE,
  DRAW_UPDATE_SOURCE_OPTION,
  PERMISSION_ACTION,
} from "helpers/enums";
import {
  suppressNavigationWarning,
  UploadSplitterContext,
  UploadsViewerContext,
  UserContext,
} from "helpers/behaviors";
import {
  isSummaryWithAdjustments,
  userCanApproveDocument,
  getDocumentApprovalStatus,
  PAYABLE_DOCUMENT_TYPE_NAMES,
} from "helpers/documentHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import {
  getPreviouslyManuallyRequestedAmounts,
  getRequestedAmountFromForm,
} from "helpers/RequestedAmountHelpers";
import { dateServerToForm } from "helpers/dateHelpers";
import DocumentWarnings from "./DocumentWarnings";
import { MarkPaidModal } from "./MarkPaidModal";
import { EditDocumentWarningModal } from "./EditDocumentWarningModal";

const BASE_URL = process.env.REACT_APP_GRAPHQL_HOST;

function getMissingInfoTooltipContent(values, canEditAmountRequested) {
  if (
    get(values, "draw.id") &&
    includes(
      [DOCUMENT_TYPE_NAME.INVOICE, DOCUMENT_TYPE_NAME.PAY_APPLICATION],
      values.type
    )
  ) {
    return canEditAmountRequested
      ? t("documentReview.missingInformationDeveloper")
      : t("documentReview.missingInformationLender");
  }
  return t("documentReview.missingInformationGeneral");
}

export function getRequestedAmountDifference(
  newValues,
  initialValues,
  hasPermission,
  documentReclassified,
  document
) {
  const { drawUpdateSource } = document.project;
  const canFloodRequestedAmounts =
    hasPermission(PERMISSION_ACTION.EDIT_AMOUNT_REQUESTED) &&
    hasPermission(
      PERMISSION_ACTION.UPDATE_REQUESTED_AMOUNT_WITH_DOCUMENTATION
    ) &&
    drawUpdateSource !== DRAW_UPDATE_SOURCE_OPTION.DRAW_SUMMARY;

  if (canFloodRequestedAmounts && !get(newValues, "draw.isLockedDown")) {
    const persistedAmount = isAppliedDocument(document, initialValues.type)
      ? get(document, "amount", 0)
      : 0;

    const previouslyManuallyRequestedAmounts = getPreviouslyManuallyRequestedAmounts(
      get(newValues, "lineItems", []),
      get(newValues, "draw.lineItems", [])
    );

    return documentReclassified
      ? // if the document is reclassified, also subtract the last persisted value for that document
        subtract(
          getRequestedAmountFromForm(
            newValues,
            hasPermission,
            previouslyManuallyRequestedAmounts
          ),
          persistedAmount
        )
      : subtract(
          getRequestedAmountFromForm(
            newValues,
            hasPermission,
            previouslyManuallyRequestedAmounts
          ),
          getRequestedAmountFromForm(initialValues, hasPermission)
        );
  }
  return 0;
}

// Is used to show the amount change to the draw (if applicable) when saving an Invoice, Pay Application, or 92464
function isAppliedDocument(document, type) {
  const isApplied = get(document, "state") === DOCUMENT_STATE.APPLIED;

  return (
    isApplied &&
    [
      DOCUMENT_TYPE_NAME.INVOICE,
      DOCUMENT_TYPE_NAME.PAY_APPLICATION,
      DOCUMENT_TYPE_NAME.HUD_92464,
    ].includes(type)
  );
}

function DropdownAction({ close, disabled, form, label, onSelect, ...props }) {
  return (
    <Menu.Item
      is={Text}
      marginY={minorScale(1)}
      onSelect={() => {
        if (!disabled) {
          onSelect(form);
          close();
        }
      }}
      {...props}
    >
      {label}
    </Menu.Item>
  );
}

function ReviewActionsDropdown({ actionGroups, form, loading }) {
  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      content={({ close }) => (
        <Menu>
          <Menu.Group>
            {actionGroups[0]
              .filter(({ disabled }) => !disabled)
              .map((action) => (
                <DropdownAction
                  {...action}
                  close={close}
                  disabled={loading}
                  key={action.label}
                />
              ))}
          </Menu.Group>
          <Menu.Divider />
          {actionGroups[1] && (
            <Menu.Group>
              {actionGroups[1].map((action) => (
                <DropdownAction
                  {...action}
                  close={close}
                  disabled={loading}
                  form={form}
                  key={action.label}
                />
              ))}
            </Menu.Group>
          )}
        </Menu>
      )}
    >
      <Button purpose="document-review actions open" marginLeft={majorScale(1)}>
        <MoreIcon />
      </Button>
    </Popover>
  );
}

function DocumentReviewButton({
  commentTouched,
  currentUser,
  document,
  documentReviewers,
  form,
  handleApprove,
  handleUndoApprove,
  isLoading,
  showMarkPaidFunctionality,
  warnings,
}) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleOpenConfirmWarnings = () => setIsConfirmOpen(true);
  const handleCloseConfirmWarnings = () => setIsConfirmOpen(false);

  const documentHasWarnings = warnings && keys(warnings).length > 0;

  const {
    documentHasApprovedStatusForUser,
    hasDocumentReviewersConfigured,
    documentIsApprovedForUnorderedWorkflow,
    userHasApprovedInOrderedWorkflow,
  } = getDocumentApprovalStatus(document, currentUser, documentReviewers);

  const showApproveButton = !documentHasApprovedStatusForUser;

  return (
    <Fragment>
      <Confirm
        content={
          <Fragment>
            <Text>The document has the following issue(s):</Text>
            <UnorderedList>
              {map(DocumentWarnings.warningsByType(warnings), (names, type) => (
                <ListItem key={type}>
                  {t(`documentWarnings.modal.${type}`, {
                    values: DocumentWarnings.join(uniq(names)),
                    count: uniq(names).length,
                  })}
                </ListItem>
              ))}
            </UnorderedList>

            <Text>Are you sure you&apos;d like to approve?</Text>
          </Fragment>
        }
        header="Continue with Approval?"
        hideViewer
        onCloseComplete={handleCloseConfirmWarnings}
        confirmLabel="Yes, Approve"
        onConfirm={(close) => {
          handleApprove(form);
          close();
        }}
        open={isConfirmOpen}
        marginRight={majorScale(1)}
      />
      <Pane display="flex" alignItems="center" marginRight={majorScale(1)}>
        {!hasDocumentReviewersConfigured &&
          documentIsApprovedForUnorderedWorkflow && (
            <Badge color="green" data-testid="approvedBadge">
              <TickIcon size={10} />
              APPROVED
            </Badge>
          )}
        {hasDocumentReviewersConfigured && userHasApprovedInOrderedWorkflow && (
          <Badge color="green" data-testid="approvedBadge">
            <TickIcon size={10} />
            APPROVED BY YOU
          </Badge>
        )}
        {!showMarkPaidFunctionality &&
          (showApproveButton ? (
            <Button
              appearance={
                !commentTouched && !documentHasWarnings
                  ? "primary"
                  : "secondary"
              }
              data-testid="documentReviewApproveButton"
              isLoading={isLoading}
              marginLeft={majorScale(1)}
              onClick={() =>
                documentHasWarnings
                  ? handleOpenConfirmWarnings()
                  : handleApprove(form)
              }
              purpose="document-review actions approve"
            >
              Approve
            </Button>
          ) : (
            <Button
              appearance="secondary"
              data-testid="documentReviewApproveButton"
              intent="success"
              isLoading={isLoading}
              marginLeft={majorScale(1)}
              onClick={() => handleUndoApprove(form)}
              purpose="document-review actions approve"
            >
              {hasDocumentReviewersConfigured
                ? "Undo Your Approval"
                : "Undo Approval"}
            </Button>
          ))}
      </Pane>
    </Fragment>
  );
}

function MarkPaidButton({ setShowMarkPaidModal }) {
  return (
    <Pane marginLeft={majorScale(1)}>
      <Button
        onClick={() => {
          setShowMarkPaidModal(true);
        }}
        purpose="document-review actions mark-paid"
      >
        Mark Paid
      </Button>
    </Pane>
  );
}

function EditDocumentButton({ document, setShowEditDocumentWarningModal }) {
  return (
    <Pane marginLeft={majorScale(1)}>
      <Button
        onClick={() => {
          setShowEditDocumentWarningModal(true);
        }}
        purpose="document-review actions edit-document"
      >
        Edit {t(`documentTypeName.${document.type}`)}
      </Button>
    </Pane>
  );
}

export function DocumentReviewActions({
  assignUserToDocumentsMutation: [
    assignUserToDocuments,
    assignUserToDocumentsResult,
  ],
  commentTouched,
  dirty,
  document,
  documentIgnored,
  documentMissingInformation,
  documentReviewers,
  form,
  handleApprove,
  handleUndoApprove,
  handleMarkPaid,
  handleMarkUnpaid,
  markDocumentPaidLoading,
  markDocumentUnpaidLoading,
  mutationLoading,
  onChangeType,
  onChangeDraw,
  onDelete,
  onIgnore,
  onReset,
  setOpenConfirmDrawSummary,
  setOpenConfirmMoveFile,
  setRemoveDrawSummaryAction,
  users,
  warnings,
}) {
  const [
    showIgnoreRemovesCostsWarning,
    setShowIgnoreRemovesCostsWarning,
  ] = useState(false);
  const [showMarkPaidModal, setShowMarkPaidModal] = useState(false);
  const [
    showEditDocumentWarningModal,
    setShowEditDocumentWarningModal,
  ] = useState(false);

  const { setOpenUploads } = useContext(UploadsViewerContext);
  const { setOpenSplitter } = useContext(UploadSplitterContext);
  const { hasPermission, user } = useContext(UserContext);

  const canDownload = hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT);
  const canStamp = hasPermission(PERMISSION_ACTION.STAMP_DOCUMENTS);
  const hasPaymentTracking = hasPermission(PERMISSION_ACTION.PAYMENT_TRACKING);

  const { drawUpdateSource } = document.project;
  const canImportDrawSummary =
    hasPermission(PERMISSION_ACTION.IMPORT_DRAW_SUMMARY) &&
    drawUpdateSource !== DRAW_UPDATE_SOURCE_OPTION.DOCUMENTATION;
  const showApprovalComponents = userCanApproveDocument(
    document,
    user,
    documentReviewers,
    hasPermission
  );

  const documentId = get(document, "id");
  const drawId = get(document, "draw.id");
  const projectId = get(document, "project.id");
  const uploadId = get(document, "upload.id");
  const suggestedDocumentAssignees = get(
    document,
    "project.suggestedDocumentAssignees"
  );
  const documentReclassified =
    get(document, "type") !== get(form, "values.type");
  const canUpdateDocuments = hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT);
  const canDeleteDocuments = hasPermission(PERMISSION_ACTION.DELETE_DOCUMENTS);
  const documentMarkedPaid = get(document, "isPaid", false);
  const markPaidEnabled =
    hasPaymentTracking && PAYABLE_DOCUMENT_TYPE_NAMES.includes(document.type);
  const showMarkPaidFunctionality = markPaidEnabled && documentMarkedPaid;
  const assignmentButtonContent = document.assignedUser
    ? `Assigned to ${document.assignedUser.fullName}`
    : "Assign to...";
  const reviewActionsDocumentActionsGroup =
    canDeleteDocuments &&
    !(
      document.importSource === DOCUMENT_IMPORT_SOURCE.YARDI &&
      !document.isBackup
    )
      ? [
          {
            label: documentIgnored ? "Unignore" : "Ignore",
            onSelect: documentIgnored ? form.handleSubmit : handleIgnore,
          },
          {
            label: "Delete",
            onSelect: onDelete,
            intent: "danger",
          },
        ]
      : [
          {
            label: documentIgnored ? "Unignore" : "Ignore",
            onSelect: documentIgnored ? form.handleSubmit : handleIgnore,
          },
        ];

  /* If completing a split from the document viewer, close the underlying document
     viewer, as its state will possibly be invalid. This will also allow the user to potentially
     select a newly split document to edit from the table, which will update via subscription
  */
  const history = useHistory();
  const match = useRouteMatch({
    path: "*/documentation/:documentId",
  });

  const onSplit = () => {
    if (match.params.documentId) {
      const newPathname = history.location.pathname.replace(
        `/${match.params.documentId}`,
        ""
      );

      suppressNavigationWarning();
      // Delay for a tick to allow the eventListener to unregister in the fn above
      setTimeout(() =>
        history.replace({ ...history.location, pathname: newPathname })
      );
    }
  };

  const reviewActions = [
    [
      {
        label: "Download Document",
        onSelect: () =>
          window.open(`${BASE_URL}/download_multiple_documents/${documentId}`),
        disabled: !canDownload,
      },
      {
        label: "Download Stamped Document",
        onSelect: () =>
          window.open(`${BASE_URL}/download_stamped_document/${documentId}`),
        disabled:
          !canDownload ||
          !canStamp ||
          get(document, "file.type") !== "application/pdf",
      },
      {
        label: "View Original Upload",
        onSelect: () => setOpenUploads(uploadId, drawId, projectId),
      },
      {
        label: "Split Upload",
        onSelect: () => setOpenSplitter({ onSplit, projectId, uploadId }),
        disabled: get(document, "upload.pages", []).length < 2,
      },
    ],
    ...(canUpdateDocuments ? [reviewActionsDocumentActionsGroup] : []),
  ];

  const requestedAmountDifference = getRequestedAmountDifference(
    form.values,
    form.initialValues,
    hasPermission,
    documentReclassified,
    document
  );

  function handleIgnore() {
    if (form.values.hasTrackedAgreements) {
      return setShowIgnoreRemovesCostsWarning(true);
    }
    return onIgnore();
  }

  function handleSave() {
    if (drawId !== get(form.values, "draw.id")) {
      setOpenConfirmMoveFile(true);
    } else if (
      form.values.type === DOCUMENT_TYPE_NAME.DRAW_SUMMARY &&
      !documentReclassified &&
      get(form.values, "draw.hasAdjustments") &&
      canImportDrawSummary
    ) {
      setOpenConfirmDrawSummary(true);
    } else if (isSummaryWithAdjustments(document) && documentReclassified) {
      setRemoveDrawSummaryAction("reclassify");
    } else {
      form.handleSubmit();
    }
  }

  return (
    <Pane
      display="flex"
      justifyContent="flex-end"
      paddingY={majorScale(1)}
      paddingX={majorScale(2)}
    >
      {documentMissingInformation && (
        <Pane
          display="flex"
          alignItems="center"
          data-testid="documentMissingInformation"
        >
          <Tooltip
            content={getMissingInfoTooltipContent(
              form.values,
              hasPermission(PERMISSION_ACTION.EDIT_AMOUNT_REQUESTED) &&
                hasPermission(
                  PERMISSION_ACTION.UPDATE_REQUESTED_AMOUNT_WITH_DOCUMENTATION
                )
            )}
          >
            <Pane
              marginRight={majorScale(1)}
              display="flex"
              alignItems="center"
            >
              <Text
                color="muted"
                fontStyle="italic"
                marginRight={minorScale(1)}
              >
                Missing information
              </Text>
              <img
                src={brokenLink}
                alt="Document not applied"
                width={majorScale(2)}
                data-testid="brokenLinkIcon"
              />
            </Pane>
          </Tooltip>
        </Pane>
      )}
      {dirty && !form.values.__disabled ? (
        <Fragment>
          <Button
            isLoading={mutationLoading}
            purpose="document-review actions undo"
            onClick={() => {
              onChangeType(get(document, "type"));
              onChangeDraw(drawId);
              onReset ? onReset(form) : form.handleReset();
            }}
          >
            Undo
          </Button>
          <Button
            appearance={documentMissingInformation ? "default" : "primary"}
            isLoading={mutationLoading}
            marginLeft={majorScale(1)}
            purpose="document-review actions submit"
            onClick={handleSave}
          >
            Save
            {requestedAmountDifference !== 0 && (
              <Fragment>
                <Pane fontStyle="italic" marginLeft={majorScale(1)}>
                  {requestedAmountDifference > 0 ? "+" : "-"}
                  {formatCurrency(Math.abs(requestedAmountDifference))}
                </Pane>
                <Tooltip
                  content={`Saving this document will change the amount requested by ${formatCurrency(
                    requestedAmountDifference
                  )}`}
                  position={Position.TOP}
                >
                  <InfoSignIcon
                    marginLeft={majorScale(1)}
                    size={minorScale(3)}
                  />
                </Tooltip>
              </Fragment>
            )}
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          {showMarkPaidFunctionality && (
            <Pane
              fontSize={14}
              marginTop={minorScale(2)}
              marginRight={majorScale(1)}
            >
              Marked Paid {dateServerToForm(document.datePaid)}
            </Pane>
          )}
          {showApprovalComponents && (
            <DocumentReviewButton
              commentTouched={commentTouched}
              currentUser={user}
              document={document}
              documentMarkedPaid={documentMarkedPaid}
              documentReviewers={documentReviewers}
              form={form}
              handleApprove={handleApprove}
              handleUndoApprove={handleUndoApprove}
              isLoading={mutationLoading}
              showMarkPaidFunctionality={showMarkPaidFunctionality}
              warnings={warnings}
            />
          )}
          {!showMarkPaidFunctionality &&
            hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS) && (
              <Pane>
                <AssignToDropdown
                  users={users}
                  suggestedDocumentAssignees={suggestedDocumentAssignees}
                  onSelect={(userId) => {
                    assignUserToDocuments({
                      variables: {
                        documentIds: [document.id],
                        userId,
                      },
                    }).then(form.resetForm);
                  }}
                  selectedUserId={document?.assignedUser?.id}
                >
                  <Button
                    purpose="document-review actions assign-to"
                    disabled={assignUserToDocumentsResult.loading}
                  >
                    {assignUserToDocumentsResult.loading ? (
                      <Fragment>
                        <Spinner size={14} marginRight={majorScale(1)} />{" "}
                        Loading...
                      </Fragment>
                    ) : (
                      assignmentButtonContent
                    )}
                  </Button>
                </AssignToDropdown>
              </Pane>
            )}
          {markPaidEnabled && !documentMarkedPaid && (
            <MarkPaidButton
              document={document}
              setShowMarkPaidModal={setShowMarkPaidModal}
            />
          )}
          {showMarkPaidFunctionality && (
            <EditDocumentButton
              document={document}
              setShowEditDocumentWarningModal={setShowEditDocumentWarningModal}
            />
          )}
          <ReviewActionsDropdown
            actionGroups={reviewActions}
            form={form}
            isLoading={mutationLoading}
          />
        </Fragment>
      )}
      {showMarkPaidModal && (
        <MarkPaidModal
          document={document}
          handleMarkPaid={handleMarkPaid}
          setShowMarkPaidModal={setShowMarkPaidModal}
          markDocumentPaidLoading={markDocumentPaidLoading}
        />
      )}
      {showEditDocumentWarningModal && (
        <EditDocumentWarningModal
          document={document}
          handleMarkUnpaid={handleMarkUnpaid}
          markDocumentUnpaidLoading={markDocumentUnpaidLoading}
          onClose={() => setShowEditDocumentWarningModal(false)}
        />
      )}
      {showIgnoreRemovesCostsWarning && (
        <Confirm
          cancelLabel="Cancel"
          confirmLabel="Continue"
          content={
            <Text>{t("documentReview.ignoringDocumentRemovesCosts")}</Text>
          }
          onCloseComplete={() => setShowIgnoreRemovesCostsWarning(false)}
          onConfirm={() => {
            setShowIgnoreRemovesCostsWarning(false);
            onIgnore();
          }}
          open
          title="Ignore Document"
        />
      )}
    </Pane>
  );
}

DocumentReviewActions.propTypes = {
  commentTouched: PropTypes.bool,
  dirty: PropTypes.bool,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assignUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    draw: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    file: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    type: PropTypes.string.isRequired,
    upload: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  documentIgnored: PropTypes.bool,
  form: PropTypes.object.isRequired,
  handleApprove: PropTypes.func.isRequired,
  handleUndoApprove: PropTypes.func.isRequired,
  handleMarkPaid: PropTypes.func.isRequired,
  handleMarkUnpaid: PropTypes.func.isRequired,
  markDocumentPaidLoading: PropTypes.func.isRequired,
  markDocumentUnpaidLoading: PropTypes.func.isRequired,
  mutationLoading: PropTypes.bool,
  onChangeType: PropTypes.func.isRequired,
  onChangeDraw: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  setOpenConfirmMoveFile: PropTypes.func.isRequired,
  warnings: PropTypes.object,
};
