import { Heading, Pane, Paragraph } from "components/materials";
import { majorScale } from "helpers/utilities";
import { invoiceSummaryBlankSlate1, invoiceSummaryBlankSlate2 } from "images";
import t from "helpers/translate";

export function InvoiceSummaryBlankSlate() {
  return (
    <Pane
      padding={majorScale(4)}
      height="100%"
      paddingBottom={majorScale(8)}
      overflowY="auto"
    >
      <Heading size={800}>{t("invoiceSummaryBlankSlate.header")}</Heading>

      <Paragraph>
        <Pane marginTop={majorScale(2)}>
          {t("invoiceSummaryBlankSlate.intro1")}
        </Pane>
        <Pane marginTop={majorScale(2)}>
          {t("invoiceSummaryBlankSlate.intro2")}
        </Pane>
        <Pane marginTop={majorScale(2)}>
          {t("invoiceSummaryBlankSlate.step1")}
          <Pane
            marginTop={majorScale(2)}
            alt=""
            width="100%"
            is="img"
            src={invoiceSummaryBlankSlate1}
          />
        </Pane>
        <Pane marginTop={majorScale(2)}>
          {t("invoiceSummaryBlankSlate.step2")}
          <Pane
            marginTop={majorScale(2)}
            alt=""
            width="100%"
            is="img"
            src={invoiceSummaryBlankSlate2}
          />
        </Pane>
        <Pane marginTop={majorScale(2)}>
          {t("invoiceSummaryBlankSlate.step3")}
        </Pane>
      </Paragraph>
    </Pane>
  );
}
