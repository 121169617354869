import { useContext, Fragment } from "react";
import { TickCircleIcon } from "evergreen-ui";
import {
  Badge,
  Button,
  Heading,
  Modal,
  Pane,
  Paragraph,
} from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { PROJECT_SETUP_STEP, PROJECT_SETUP_STEP_STATUS } from "helpers/enums";
import t from "helpers/translate";
import { incomplete } from "images";
import { getOrderedSteps, SetupProgress } from "./DashboardSetupSteps";
import { StepModalContent } from "./StepModalContent";

const getStepBackgroundColor = ({ theme, status, backgroundType }) => {
  const colors = {
    [PROJECT_SETUP_STEP_STATUS.COMPLETE]: {
      default: theme.colors.green600,
      selected: theme.colors.green700,
    },
    [PROJECT_SETUP_STEP_STATUS.PENDING]: {
      default: "white",
      selected: theme.colors.blue25,
    },
    [PROJECT_SETUP_STEP_STATUS.SKIPPED]: {
      default: theme.colors.gray300,
      selected: theme.colors.gray400,
    },
  };
  return colors[status][backgroundType];
};

const getStepBorderBottom = ({ status, theme }) => {
  switch (status) {
    case PROJECT_SETUP_STEP_STATUS.COMPLETE:
      return `1px solid ${theme.colors.green700}`;

    case PROJECT_SETUP_STEP_STATUS.SKIPPED:
      return "1px solid #d3d9de";

    case PROJECT_SETUP_STEP_STATUS.PENDING:
      return true;

    default:
      return true;
  }
};

function StepsModalHeader({ steps }) {
  return (
    <Pane display="flex" alignItems="baseline">
      <Heading size={600} marginRight={majorScale(2)}>
        Project Setup Steps
      </Heading>
      <SetupProgress setupSteps={steps} />
    </Pane>
  );
}

function StepsModalList({ selectedStep, setSelectedSetupStep, steps, theme }) {
  const orderedSteps = getOrderedSteps(steps);

  return (
    <Pane flex="0 0 auto" borderRight>
      {orderedSteps.map((orderedStep) => {
        const { id, status, step } = orderedStep;
        const isComplete = status === PROJECT_SETUP_STEP_STATUS.COMPLETE;
        const isSelected = selectedStep.id === id;
        const backgroundType = isSelected ? "selected" : "default";
        const backgroundColor = getStepBackgroundColor({
          theme,
          status,
          backgroundType,
        });
        const borderBottom = getStepBorderBottom({ status, theme });
        return (
          <Pane
            display="flex"
            alignItems="center"
            height={50}
            backgroundColor={backgroundColor}
            onClick={() => setSelectedSetupStep(orderedStep)}
            cursor={isSelected ? "default" : "pointer"}
            borderBottom={borderBottom}
            paddingRight={majorScale(4)}
          >
            <Pane width={50} textAlign="center">
              {isComplete ? (
                <TickCircleIcon color="white" size={22} />
              ) : (
                <Pane marginTop={2}>
                  <img src={incomplete} alt="incomplete" />
                </Pane>
              )}
            </Pane>
            <Paragraph
              color={isComplete ? "white" : undefined}
              fontWeight={theme.fontWeights.DEMI}
            >
              {t(`projectSetupSteps.${step}`)}
            </Paragraph>
          </Pane>
        );
      })}
    </Pane>
  );
}

function StepsModalBody({
  handleUpdateStatus,
  loading,
  project,
  selectedStep,
  theme,
}) {
  const { step, status } = selectedStep;
  return (
    <Pane padding={majorScale(3)} flex="1 1 auto">
      <Pane display="flex" alignItems="center" marginBottom={majorScale(3)}>
        <Pane display="flex" alignItems="center" flex="1 1 auto">
          <Heading is="h2" size={600} fontWeight={theme.fontWeights.DEMI}>
            {t(`projectSetupSteps.${step}`)}
          </Heading>
          {status === PROJECT_SETUP_STEP_STATUS.SKIPPED && (
            <Badge color="neutral" marginLeft={majorScale(1)}>
              Skipped
            </Badge>
          )}
        </Pane>
        {status === PROJECT_SETUP_STEP_STATUS.PENDING && (
          <Button
            onClick={() =>
              handleUpdateStatus(PROJECT_SETUP_STEP_STATUS.SKIPPED)
            }
            isLoading={loading}
            marginLeft={majorScale(2)}
          >
            Skip This Step
          </Button>
        )}
        {status === PROJECT_SETUP_STEP_STATUS.SKIPPED && (
          <Button
            onClick={() =>
              handleUpdateStatus(PROJECT_SETUP_STEP_STATUS.PENDING)
            }
            isLoading={loading}
            marginLeft={majorScale(2)}
          >
            Unskip This Step
          </Button>
        )}
        {status === PROJECT_SETUP_STEP_STATUS.COMPLETE && (
          <Button
            onClick={() =>
              handleUpdateStatus(PROJECT_SETUP_STEP_STATUS.PENDING)
            }
            isLoading={loading}
            marginLeft={majorScale(2)}
            disabled={step === PROJECT_SETUP_STEP.CREATE_PROJECT}
          >
            Mark Incomplete
          </Button>
        )}
      </Pane>
      <StepModalContent
        organization={project.organization}
        projectId={project.id}
        step={step}
      />
    </Pane>
  );
}

export function SetupStepsModal({
  onClose,
  selectedStep,
  project,
  updateSetupStepMutation,
  setSelectedSetupStep,
}) {
  const theme = useContext(ThemeContext);

  const [updateSetupStep, updateSetupStepResult] = updateSetupStepMutation;
  const handleUpdateStatus = (status) => {
    updateSetupStep({
      variables: {
        id: selectedStep.id,
        projectId: project.id,
        status,
      },
    }).then((result) =>
      setSelectedSetupStep(result.data.updateProjectSetupStep)
    );
  };

  return (
    <Modal
      open
      width="60%"
      containerProps={{ minWidth: 750, maxWidth: 1100 }}
      contentContainerProps={{ padding: 0 }}
      title={<StepsModalHeader steps={project.setupSteps} />}
      onClose={onClose}
    >
      {({ close }) => (
        <Fragment>
          <Pane display="flex">
            <StepsModalList
              selectedStep={selectedStep}
              setSelectedSetupStep={setSelectedSetupStep}
              steps={project.setupSteps}
              theme={theme}
            />
            <StepsModalBody
              handleUpdateStatus={handleUpdateStatus}
              loading={updateSetupStepResult.loading}
              project={project}
              selectedStep={selectedStep}
              theme={theme}
            />
          </Pane>
          <Pane
            borderTop
            padding={majorScale(2)}
            minHeight={65}
            textAlign="right"
          >
            <Button onClick={close}>Close</Button>
          </Pane>
        </Fragment>
      )}
    </Modal>
  );
}
