import { Form, Pane } from "components/materials";
import { divide, subtract } from "helpers/math";
import unformatNumber from "helpers/unformatNumber";
import { majorScale } from "helpers/utilities";
import { COMPARATORS } from "../FastDataTableUtils";

const options = [
  COMPARATORS.GREATER_THAN,
  COMPARATORS.LESS_THAN,
  COMPARATORS.EQUAL,
  COMPARATORS.NOT_EQUAL,
];

export const fractionColumnDefaults = {
  aggregate: () => null,
  aggregateFormatter: ({ numerator, denominator }) =>
    `${numerator} / ${denominator}`,
  filterControl: (index) => (
    <Pane display="flex">
      <Form.Select name={`filters.${index}.operator`} options={options} />
      <Form.Input
        name={`filters.${index}.input`}
        type="number"
        marginLeft={majorScale(1)}
      />
    </Pane>
  ),
  filterFormatter: (fc, column) =>
    `${column.header}: ${COMPARATORS[fc.operator].text} ${fc.input}`,
  filterStrategy: (value, fc) => {
    if (value === null) return false;
    const lhs = value.numerator;
    const rhs = unformatNumber(fc.input);
    switch (fc.operator) {
      case COMPARATORS.GREATER_THAN.value:
        return lhs > rhs;
      case COMPARATORS.LESS_THAN.value:
        return lhs < rhs;
      case COMPARATORS.EQUAL.value:
        return lhs === rhs;
      case COMPARATORS.NOT_EQUAL.value:
        return lhs !== rhs;
      default:
        return true;
    }
  },
  sortStrategy: (valueA, valueB) => {
    if (valueA === null && valueB === null) return 0;
    if (valueA === null) return -1;
    if (valueB === null) return 1;
    return subtract(
      divide(valueA.numerator, valueA.denominator),
      divide(valueB.numerator, valueB.denominator)
    );
  },
  type: "fraction",
  valueFormatter: ({ numerator, denominator }) =>
    `${numerator} / ${denominator}`,
  width: 100,
};
