import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Switch, Text } from "components/materials";
import { connect, getIn } from "formik";
import { get } from "lodash";
import { majorScale } from "helpers/utilities";

// Lets never use PureComponent again
class FormSwitch extends PureComponent {
  handleChange = (event) => {
    const { formik, name, onChange } = this.props;
    formik.setFieldValue(name, event.target.checked);
    onChange(event.target.checked);
  };

  render() {
    const {
      disabled,
      formik,
      name,
      hint,
      label,
      labelClickDisabled,
      fieldProps,
      onClick,
      textProps,
      ...props
    } = this.props;
    const value = getIn(formik.values, name);
    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);
    const hasError = !!error && !!touch;
    const labelOnClick = (event) => {
      this.handleChange(event);
      onClick(event);
    };

    const disableSwitch = disabled || get(formik.status, "disabled");
    const disableSwitchLabel = disabled || get(formik.status, "disabled");

    return (
      <Form.Field
        label=""
        hint={hint}
        validationMessage={hasError && error}
        data-testid="field"
        display="flex"
        alignItems="top"
        {...fieldProps}
      >
        <Switch
          {...props}
          disabled={disableSwitch}
          data-testid="checkbox"
          name={name}
          checked={value}
          data-checked={value ? "true" : "false"}
          onChange={this.handleChange}
          onClick={onClick}
          marginRight={majorScale(1)}
        />
        <Text
          is="label"
          cursor={
            disableSwitchLabel && !labelClickDisabled
              ? "not-allowed"
              : "pointer"
          }
          htmlFor={name}
          onClick={() =>
            disableSwitchLabel || labelClickDisabled
              ? null
              : labelOnClick({ target: { checked: !value } })
          }
          width="100%"
          {...textProps}
        >
          {label}
        </Text>
      </Form.Field>
    );
  }
}

export const BaseSwitch = ({
  name,
  value,
  onChange,
  disabled = false,
  disableLabel = disabled,
  label,
  ...props
}) => {
  const onClick = disabled
    ? () => {}
    : () => onChange({ target: { checked: !value } });

  return (
    <Form.Field label="" data-testid="field" display="flex" alignItems="top">
      <Switch
        {...props}
        hasCheckIcon
        disabled={disabled}
        data-testid="checkbox"
        name={name}
        checked={value}
        data-checked={value ? "true" : "false"}
        onChange={onChange}
        marginRight={majorScale(1)}
      />
      <Text
        is="label"
        cursor={disableLabel ? "not-allowed" : "pointer"}
        htmlFor={name}
        onClick={onClick}
        width="100%"
      >
        {label}
      </Text>
    </Form.Field>
  );
};

FormSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  hint: PropTypes.node,
  label: PropTypes.node,
  fieldProps: PropTypes.object,
  hasCheckIcon: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

FormSwitch.defaultProps = {
  fieldProps: {},
  hasCheckIcon: true,
  onClick: () => {},
  onChange: () => {},
};

export default connect(FormSwitch);
