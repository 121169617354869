import { useContext } from "react";
import { get } from "lodash";
import { Form, Pane } from "components/materials";
import { VendorLink } from "components/templates";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale } from "helpers/utilities";
import { AgreementDetailsForVendor } from "./AgreementDetailsForVendor";
import DocumentReviewLayout from "./DocumentReviewLayout";
import {
  getNetAmount,
  BreakdownItemsSection,
  Page2Section,
  PAGE_2_ROWS,
  LINE_ITEMS,
  setErrorsForAmountCompleteDecreased,
  setErrorsForBuildersOverheadIsCommensurateWithTradeCompletion,
  setErrorsForBuildersProfitIsCommensurateWithTradeCompletion,
  setErrorsForColumnASubtotalMatchesTradeItemTotal,
  setErrorsForColumnATotalMatchesSubtotalPlusBuilders,
  setErrorsForCurrentSubtotalMatchesTradeItemTotal,
  setErrorsForCurrentTotalMatchesSubtotalPlusBuilders,
  setErrorsForCostBreakdownPlusMaterialsMatch,
  setErrorsForLessNetDecreaseShouldBeZero,
  setErrorsForLessPreviousPaymentsMatchPreviousAdvance,
  setErrorsForLineItemMatchesCurrentBudget,
  setErrorsForNetAmountOfThisRequisitionMatches,
  setErrorsForRequisitionAmountMatches,
  setErrorsForRetainageIsTenPercent,
  setErrorsForTotalAfterAdjustingMatches,
  setErrorsForTotalAmountDueToDateMatches,
  prepareValuesForValidation,
} from "./ContractorsRequisition";

function getRowInitialValues(lineItems, document) {
  return Object.entries(lineItems).reduce(
    (lineItemAcc, [name, { hasColumnA, name: humanReadableName }]) => {
      return {
        ...lineItemAcc,
        [name]: {
          id: get(document, `lineItems.${name}.id`),
          columnA: hasColumnA
            ? get(document, `lineItems.${name}.breakdown`, null)
            : null,
          columnB: get(document, `lineItems.${name}.complete`, null),
          columnC: get(document, `lineItems.${name}.hudFha`, null),
          humanReadableName,
        },
      };
    },
    {}
  );
}

function getInitialValues({ document, selectedDraw, selectedType }) {
  const {
    description,
    requisitionAmount,
    requisitionNumber,
    vendor,
  } = document;

  return {
    breakdownItems: getRowInitialValues(LINE_ITEMS, document),
    description,
    draw: {
      id: selectedDraw?.id,
      lineItems: get(selectedDraw, "lineItems", []),
      previousDraw: {
        documents: [
          {
            lineItems: {
              totalAmountDueToDate: {
                complete: get(
                  selectedDraw,
                  "previousDraw.documents[0].lineItems.totalAmountDueToDate.complete"
                ),
              },
            },
          },
        ],
      },
    },
    page2Values: getRowInitialValues(PAGE_2_ROWS, document),
    requisitionAmount: requisitionAmount || 0,
    requisitionNumber: requisitionNumber || "",
    type: selectedType,
    vendor,
    // The draw line items are not used in the form values,
    // and are defined here for form validation
    _lineItemsId: get(document, "lineItems.id"),
  };
}

function getDocumentDataFromForm(values) {
  const {
    breakdownItems,
    draw,
    page2Values,
    requisitionAmount,
    requisitionNumber,
    _lineItemsId,
  } = values;
  const matchedDrawLineItems = getMatchedDrawLineItems(
    get(draw, "lineItems", [])
  );
  const lineItems = Object.entries({
    ...breakdownItems,
    ...page2Values,
  }).reduce(
    (allLineItems, [lineItemName, lineItem]) => {
      const requestedPreviouslyAmount =
        matchedDrawLineItems[lineItemName]?.requestedPreviouslyAmount;
      const previousAmount =
        lineItemName === "lessRetained"
          ? requestedPreviouslyAmount * -1
          : requestedPreviouslyAmount;
      return {
        ...allLineItems,
        [lineItemName]: {
          id: lineItem.id,
          drawLineItem: matchedDrawLineItems[lineItemName]?.id,
          breakdown: lineItem.columnA ? formatCurrency(lineItem.columnA) : null,
          complete: lineItem.columnB ? formatCurrency(lineItem.columnB) : null,
          hudFha: lineItem.columnC ? formatCurrency(lineItem.columnC) : null,
          netAmount: formatCurrency(
            getNetAmount(lineItem.columnB, lineItem.columnC, previousAmount)
          ),
        },
      };
    },
    { id: _lineItemsId }
  );

  return {
    lineItems,
    requisitionAmount: formatCurrency(requisitionAmount),
    requisitionNumber,
  };
}

export function validate(values) {
  const errors = {};

  const preparedValues = prepareValuesForValidation(values);

  /* BREAKDOWN ITEM VALIDATIONS */
  Object.entries(preparedValues.breakdownItems).forEach(
    ([fieldName, breakdownValues]) => {
      const drawLineItem = get(values, "draw.lineItems", []).find(
        (dli) => dli.name === breakdownValues.humanReadableName
      );
      // <line-item-name> does not match current budget
      setErrorsForLineItemMatchesCurrentBudget(
        drawLineItem,
        breakdownValues,
        errors,
        fieldName
      );

      // Amount Complete (B) decreased (previous draw: <previous draw amount>)
      setErrorsForAmountCompleteDecreased(
        drawLineItem,
        breakdownValues,
        errors,
        fieldName
      );
    }
  );

  /* PAGE 2 VALIDATIONS */
  setErrorsForColumnASubtotalMatchesTradeItemTotal(errors, preparedValues);
  setErrorsForCurrentSubtotalMatchesTradeItemTotal(errors, preparedValues);
  setErrorsForBuildersOverheadIsCommensurateWithTradeCompletion(
    errors,
    preparedValues
  );
  setErrorsForBuildersProfitIsCommensurateWithTradeCompletion(
    errors,
    preparedValues
  );
  setErrorsForColumnATotalMatchesSubtotalPlusBuilders(errors, preparedValues);
  setErrorsForCurrentTotalMatchesSubtotalPlusBuilders(errors, preparedValues);
  setErrorsForCostBreakdownPlusMaterialsMatch(errors, preparedValues);
  setErrorsForLessNetDecreaseShouldBeZero(errors, preparedValues);
  setErrorsForTotalAfterAdjustingMatches(errors, preparedValues);
  setErrorsForRetainageIsTenPercent(errors, preparedValues);
  setErrorsForTotalAmountDueToDateMatches(errors, preparedValues);
  setErrorsForLessPreviousPaymentsMatchPreviousAdvance(errors, preparedValues);
  setErrorsForNetAmountOfThisRequisitionMatches(errors, preparedValues);
  setErrorsForRequisitionAmountMatches(errors, preparedValues);

  return errors;
}

function getMatchedDrawLineItems(drawLineItems) {
  return Object.entries({ ...LINE_ITEMS, ...PAGE_2_ROWS }).reduce(
    (matchedLineItems, [camelCaseName, { name, isLineItem }]) => {
      const matchedLineItem = isLineItem
        ? drawLineItems.find((drawLineItem) => drawLineItem.name === name)
        : null;

      if (matchedLineItem)
        return { ...matchedLineItems, [camelCaseName]: matchedLineItem };
      return matchedLineItems;
    },
    {}
  );
}

export function ContractorsRequisitionReview(props) {
  const {
    agreementVendorLineItems,
    allAvailableVendors,
    collapsedPanels,
    form,
    lockdownDraw,
    mutationLoading,
    selectedDraw,
    togglePanel,
  } = props;

  const disabled = lockdownDraw || mutationLoading;

  const matchedDrawLineItems = getMatchedDrawLineItems(
    get(selectedDraw, "lineItems", [])
  );

  const { hasPermission } = useContext(UserContext);

  return (
    <DocumentReviewLayout
      disableFields={disabled}
      information={(content) => (
        <Pane display="flex" flexDirection="column" width="100%">
          <Pane display="flex" flexDirection="row" flexWrap="wrap">
            {content}
            <Pane flexGrow={1} marginRight={majorScale(2)}>
              <Form.NewInput
                disabled={disabled}
                error={form.errors.requisitionNumber}
                initialValues={form.initialValues}
                label="Requisition Number"
                name="requisitionNumber"
                onChange={form.setFieldValue}
              />
            </Pane>
            <Pane flexGrow={1}>
              <Form.NewInput
                disabled={disabled}
                initialValues={form.initialValues}
                label="Requisition Amount"
                name="requisitionAmount"
                onChange={form.setFieldValue}
                type="currency"
              />
            </Pane>
          </Pane>
          <Pane display="flex" justifyContent="flex-start" alignItems="center">
            <VendorLink
              vendorId={form.values.vendor.id}
              vendors={allAvailableVendors}
            />
            {hasPermission(PERMISSION_ACTION.AGREEMENT_MANAGEMENT) && (
              <Pane marginLeft={majorScale(2)}>
                <AgreementDetailsForVendor
                  agreementVendorLineItems={agreementVendorLineItems}
                  vendorId={form.values.vendor.id}
                />
              </Pane>
            )}
          </Pane>
        </Pane>
      )}
      form={form}
      {...props}
      handleCancelParsing={false}
      requireSaveToParse
    >
      <BreakdownItemsSection
        collapsedPanels={collapsedPanels}
        disabled={disabled}
        form={form}
        matchedDrawLineItems={matchedDrawLineItems}
        togglePanel={togglePanel}
      />
      <Page2Section
        collapsedPanels={collapsedPanels}
        disabled={disabled}
        form={form}
        matchedDrawLineItems={matchedDrawLineItems}
        togglePanel={togglePanel}
      />
    </DocumentReviewLayout>
  );
}

ContractorsRequisitionReview.getDocumentDataFromForm = getDocumentDataFromForm;
ContractorsRequisitionReview.getInitialValues = getInitialValues;
ContractorsRequisitionReview.validate = validate;
