import { Form, Pane, Paragraph } from "components/materials";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { DrawSummaryTable } from "./DrawSummaryTable";
import DrawLockdownBanner from "../../DrawLockdownBanner";

export function DrawSummaryInformation({
  fileData,
  fileType,
  form,
  isCollapsed,
  lockdownDraw,
  theme,
  onCollapse,
}) {
  const sheetOptions = getSheetNameOptions(fileData);

  return (
    <Form.Section
      heading="Draw Summary Information"
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={onCollapse}
      paddingLeft={0}
      paddingRight={12}
    >
      {lockdownDraw && (
        <DrawLockdownBanner title={t("lockdownBanner.drawSummaryTitle")} />
      )}
      {sheetOptions.length > 1 && (
        <Pane
          display="flex"
          alignItems="center"
          marginBottom={majorScale(1)}
          paddingLeft={majorScale(2)}
        >
          <Paragraph
            fontWeight={theme.fontWeights.MEDIUM}
            minWidth={200}
            size={300}
          >
            {t("drawSummaryReview.sheetNamePrompt")}
          </Paragraph>
          <Form.Select maxWidth={250} name="sheetName" options={sheetOptions} />
        </Pane>
      )}
      <DrawSummaryTable
        fileData={fileData}
        fileType={fileType}
        form={form}
        lockdownDraw={lockdownDraw}
        theme={theme}
      />
    </Form.Section>
  );
}

function getSheetNameOptions(fileData) {
  return Object.keys(fileData || {}).map((name, index) => ({
    key: `sheet-${index}`,
    value: name,
    text: name,
  }));
}
