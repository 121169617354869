import { useState, useMemo, Fragment, useContext } from "react";
import { HelpIcon, ImportIcon, TickCircleIcon } from "evergreen-ui";
import { CreateOrganizationModal } from "components/containers";
import {
  Button,
  Form,
  Table,
  Pane,
  Paragraph,
  SelectionPill,
  Text,
  Tooltip,
} from "components/materials";
import { SuggestedOptionDescription } from "components/templates";
import { debounce, find, isEmpty, get, partition, uniqBy } from "lodash";
import { UserContext } from "helpers/behaviors";
import { stringComparator } from "helpers/comparators";
import { ORGANIZATION_TYPE, PERMISSION_ACTION } from "helpers/enums";
import formatAddress from "helpers/formatAddress";
import isBlank from "helpers/isBlank";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";

function formatVendorAddress(city, state) {
  return formatAddress(null, city, state).toUpperCase();
}

function getOrganizationLabel(label, isInspectionReport) {
  if (isInspectionReport) return "Inspection Company";
  return label;
}

function getVendorOption({ id, name, meta, style, description, order }) {
  return {
    meta,
    key: id,
    value: id,
    text: name,
    style,
    description,
    order,
  };
}

function NewToProjectLabel({ theme }) {
  return (
    <Fragment>
      <ImportIcon
        color={theme.colors.baseBlue}
        size={10}
        marginX={majorScale(1)}
      />
      <Text fontWeight={600} color={theme.colors.baseBlue} size={300}>
        NEW TO PROJECT
      </Text>
    </Fragment>
  );
}

function getVendorOptions(vendors, searchedVendors, suggestedVendors, theme) {
  const [
    projectSuggestedVendors,
    newToProjectSuggestedVendors,
  ] = partition(suggestedVendors, ({ id }) =>
    vendors.find((projectVendor) => projectVendor.id === id)
  );

  const projectSuggestedVendorOptions = projectSuggestedVendors.map(
    ({ id, city, name, state }) => {
      return getVendorOption({
        description: (
          <SuggestedOptionDescription
            description={formatVendorAddress(city, state)}
          />
        ),
        id,
        name,
        order: 0,
        style: { backgroundColor: theme.colors.lightYellow },
      });
    }
  );

  const newToProjectSuggestedVendorOptions = newToProjectSuggestedVendors.map(
    ({ id, city, name, state }) =>
      getVendorOption({
        description: (
          <SuggestedOptionDescription
            description={formatVendorAddress(city, state)}
          />
        ),
        id,
        meta: <NewToProjectLabel theme={theme} />,
        name,
        order: 1,
        style: { backgroundColor: theme.colors.lightYellow },
      })
  );

  const vendorOptions = vendors.map(
    ({ id, isPlaceholderVendor, city, name, state }) =>
      getVendorOption({
        description: formatVendorAddress(city, state),
        id,
        name,
        order: 2,
        style: isPlaceholderVendor
          ? { background: theme.colors.lightYellow }
          : {},
      })
  );

  const searchedVendorsOptions = searchedVendors.map(
    ({ id, isPlaceholderVendor, city, name, state }) =>
      getVendorOption({
        description: formatVendorAddress(city, state),
        id,
        meta: <NewToProjectLabel theme={theme} />,
        name,
        order: 3,
        style: isPlaceholderVendor
          ? { backgroundColor: theme.colors.lightYellow }
          : { backgroundColor: theme.colors.lightBlue },
      })
  );

  const allOptions = projectSuggestedVendorOptions
    .concat(newToProjectSuggestedVendorOptions)
    .concat(vendorOptions)
    .concat(searchedVendorsOptions);

  return uniqBy(allOptions, "key");
}

function ParsedVendorDetails({
  isHighlighted,
  parsedVendorName,
  onSelectMyOwn,
  onUseVendor,
  ...props
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);

  const canCreateEditOrganizations = hasPermission(
    PERMISSION_ACTION.CREATE_EDIT_ORGANIZATION
  );

  return (
    <Pane
      alignItems="center"
      backgroundColor={theme.colors.lightBlue}
      borderRadius={majorScale(1)}
      boxShadow={isHighlighted ? theme.boxShadowHighlighted : theme.boxShadow}
      display="flex"
      justifyContent="space-between"
      marginBottom={majorScale(1)}
      paddingY={majorScale(2)}
      paddingX={majorScale(4)}
    >
      <Pane flex="1 1 auto">
        <Paragraph>{t("documentReview.vendorInformationFound")}</Paragraph>
        <Text fontWeight={theme.fontWeights.DEMI}>{parsedVendorName}</Text>
        <ParsedInfoTooltip {...props} />
      </Pane>
      <Pane marginLeft={majorScale(2)}>
        <Button
          purpose="document-review vendor-info-banner select-existing"
          onClick={onSelectMyOwn}
        >
          Choose My Own
        </Button>
      </Pane>
      {canCreateEditOrganizations && (
        <Pane marginLeft={majorScale(1)}>
          <Button
            appearance="primary"
            purpose="document-review vendor-info-banner add"
            onClick={onUseVendor}
          >
            Create New
          </Button>
        </Pane>
      )}
    </Pane>
  );
}

function AutoAssignRow({
  name,
  title,
  parsedInfo,
  autoAssignedOn,
  notAutoAssignedReason,
}) {
  return (
    <Table.Row>
      <Table.TextCell>{title}</Table.TextCell>
      <Table.TextCell textProps={{ color: !parsedInfo && "grey" }}>
        {parsedInfo || (notAutoAssignedReason && "Not found")}
      </Table.TextCell>
      <Table.TextCell>
        {autoAssignedOn.find((matchedTerm) => matchedTerm === name) ? (
          <Fragment>
            <TickCircleIcon
              color="success"
              marginRight={minorScale(1)}
              size={minorScale(3)}
            />
            {t("documentReview.projectVendorMatchFound")}
          </Fragment>
        ) : (
          parsedInfo &&
          t(
            `documentReview.couldNotAutoAssignBecause_${
              notAutoAssignedReason || "notFound"
            }`
          )
        )}
      </Table.TextCell>
    </Table.Row>
  );
}

function ParsedInfoTooltip({
  parsedInfo,
  autoAssignedOn,
  couldNotAutoAssignOn,
}) {
  const wasAutoAssigned = !isEmpty(autoAssignedOn);
  if (!wasAutoAssigned && isEmpty(couldNotAutoAssignOn)) return null;

  const content = (
    <Pane margin={majorScale(2)}>
      <Pane marginBottom={majorScale(2)}>
        <Paragraph>
          {t(
            wasAutoAssigned
              ? "documentReview.vendorAutoAssigned"
              : "documentReview.noVendorAutoAssigned"
          )}
        </Paragraph>
        {!wasAutoAssigned &&
          !!Object.values(couldNotAutoAssignOn).find(
            (reason) => reason === "project_match_found"
          ) && (
            <Paragraph>
              {t("documentReview.noVendorAutoAssignedDespiteProjectMatchFound")}
            </Paragraph>
          )}
      </Pane>

      <Table>
        <Table.Head>
          <Table.Row>
            <Table.TextHeaderCell>Field</Table.TextHeaderCell>
            <Table.TextHeaderCell>Read</Table.TextHeaderCell>
            <Table.TextHeaderCell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <AutoAssignRow
            name="phone_number"
            title="Phone"
            parsedInfo={get(parsedInfo, "vendorPhoneNumber")}
            autoAssignedOn={autoAssignedOn}
            notAutoAssignedReason={get(couldNotAutoAssignOn, "phoneNumber")}
          />
          <AutoAssignRow
            name="email"
            title="Email"
            parsedInfo={get(parsedInfo, "vendorEmailAddress")}
            autoAssignedOn={autoAssignedOn}
            notAutoAssignedReason={get(couldNotAutoAssignOn, "emailAddress")}
          />
          <AutoAssignRow
            name="street_address"
            title="Address"
            parsedInfo={get(parsedInfo, "vendorAddress")}
            autoAssignedOn={autoAssignedOn}
            notAutoAssignedReason={get(couldNotAutoAssignOn, "streetAddress")}
          />
          <AutoAssignRow
            name="name"
            title="Name"
            parsedInfo={get(parsedInfo, "vendorName")}
            autoAssignedOn={autoAssignedOn}
            notAutoAssignedReason={get(couldNotAutoAssignOn, "name")}
          />
          <AutoAssignRow
            name="name_alias"
            title="Alias"
            parsedInfo={get(parsedInfo, "vendorName")}
            autoAssignedOn={autoAssignedOn}
            notAutoAssignedReason={get(couldNotAutoAssignOn, "nameAlias")}
          />
          {!!autoAssignedOn.find(
            (matchedTerm) => matchedTerm === "line_item"
          ) && (
            <AutoAssignRow
              name="line_item"
              title="Line Item"
              autoAssignedOn={autoAssignedOn}
            />
          )}
        </Table.Body>
      </Table>
    </Pane>
  );

  return (
    <Tooltip
      statelessProps={{ maxWidth: "400px" }}
      appearance="card"
      content={content}
    >
      <HelpIcon color="selected" marginX={minorScale(1)} size={minorScale(3)} />
    </Tooltip>
  );
}

function GuestVendorFieldLabel({
  isGeneralContractor,
  organizationLabel,
  theme,
}) {
  return isGeneralContractor ? (
    <Pane>
      <Text marginRight={minorScale(1)}>{organizationLabel}</Text>
      <SelectionPill
        height={16}
        paddingX={minorScale(1)}
        text="GC"
        textProps={{ fontWeight: theme.fontWeights.DEMI }}
        tooltipText={t("documentReview.vendorGeneralContractor")}
        color="green100"
      />
    </Pane>
  ) : (
    organizationLabel
  );
}

function VendorFieldLabel({
  isGeneralContractor,
  organizationLabel,
  parsedInfoTooltipProps,
  theme,
}) {
  return isGeneralContractor ? (
    <Pane>
      <Text marginRight={minorScale(1)}>{organizationLabel}</Text>
      <ParsedInfoTooltip {...parsedInfoTooltipProps} />
      <SelectionPill
        height={16}
        paddingX={minorScale(1)}
        text="GC"
        textProps={{ fontWeight: theme.fontWeights.DEMI }}
        tooltipText={t("documentReview.vendorGeneralContractor")}
        color="green100"
      />
    </Pane>
  ) : (
    <Pane>
      {organizationLabel}
      <ParsedInfoTooltip {...parsedInfoTooltipProps} />
    </Pane>
  );
}

export function VendorFormPartial({
  autoAssignedOn,
  couldNotAutoAssignOn,
  disableFields,
  formikProps,
  getProjectVendorSearchQuery,
  hideLabel,
  initialVendors,
  isHighlighted,
  isWarned,
  label,
  marginBottom,
  marginRight,
  modalProps,
  newlyAddedVendors,
  newName,
  parsedInfo,
  projectId,
  searchedVendors,
  setNewlyAddedVendors,
  suggestedVendors,
  usePerformik,
  vendorObjectFieldName,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);
  const [addNewName, setAddNewName] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const vendors = initialVendors
    .concat(newlyAddedVendors)
    .sort((a, b) => stringComparator(a.name, b.name));

  const handleOpen = (nameInput) => {
    setAddNewName(nameInput || newName || "");
  };
  const handleCancel = () => {
    setAddNewName(null);
  };
  const handleCreate = (data) => {
    formikProps.setFieldValue(vendorObjectFieldName, {
      id: get(data, "addOrganization.id"),
      email: get(data, "addOrganization.emailAddresses.0") || "",
      name: get(data, "addOrganization.name") || "",
      phoneNumber: get(data, "addOrganization.phoneNumbers.0") || "",
      type: get(data, "addOrganization.type"),
      ...get(data, "addOrganization"),
    });

    setAddNewName(null);

    setNewlyAddedVendors((newVendors) =>
      newVendors.concat({
        id: get(data, "addOrganization.id"),
        city: get(data, "addOrganization.city") || "",
        name: get(data, "addOrganization.name") || "",
        state: get(data, "addOrganization.state") || "",
        ...get(data, "addOrganization"),
      })
    );
  };
  const handleSelect = (vendor) => {
    formikProps.setFieldValue(vendorObjectFieldName, {
      id: vendor.id,
      email: vendor.emailAddresses[0],
      name: vendor.name,
      phoneNumber: vendor.phoneNumbers[0],
      type: vendor.type,
      ...vendor,
    });

    setAddNewName(null);

    setNewlyAddedVendors((newVendors) => {
      if (!vendors.some(({ id }) => id === vendor.id)) {
        return newVendors.concat({
          id: vendor.id,
          name: vendor.name,
          city: vendor.city,
          state: vendor.state,
          ...vendor,
        });
      }
      return newVendors;
    });
  };
  const isInspectionReport = formikProps.values.type === "INSPECTION_REPORT";

  const isGuestVendor =
    !isBlank(get(formikProps, `values[${vendorObjectFieldName}].id`)) &&
    !vendors.some(
      (vendor) =>
        vendor.id === get(formikProps, `values[${vendorObjectFieldName}].id`)
    );

  const isGeneralContractor =
    !isBlank(get(formikProps, `values[${vendorObjectFieldName}].id`)) &&
    !!find(
      get(formikProps, "values.project.stakeholderGroups", []),
      (stakeholder) =>
        stakeholder.organizationId ===
          get(formikProps, `values[${vendorObjectFieldName}].id`) &&
        stakeholder.role === ORGANIZATION_TYPE.CONTRACTOR
    );

  const organizationLabel = getOrganizationLabel(label, isInspectionReport);

  const showParsedVendorDetails =
    !showSelect &&
    !addNewName &&
    newName &&
    suggestedVendors.length === 0 &&
    !get(formikProps, `values[${vendorObjectFieldName}].id`);

  const parsedInfoTooltipProps = {
    autoAssignedOn,
    couldNotAutoAssignOn,
    parsedInfo,
  };

  const containerProps = showParsedVendorDetails
    ? { width: "100%", flexShrink: 0 }
    : { flex: "1 1 auto", marginRight };

  const FormInput = usePerformik ? Form.NewInput : Form.Input;
  const FormSelect = usePerformik ? Form.NewSelect : Form.Select;

  const searchQuery = useMemo(
    // TODO: Find a better way to clear input when something is selected
    () =>
      debounce((input) => {
        getProjectVendorSearchQuery({
          variables: {
            projectId,
            filters: [
              {
                field: "name",
                comparator: "LIKE",
                value: input,
              },
            ],
            pagination: { limit: input ? 50 : 0, page: 0 },
          },
        });
      }, 250),
    [getProjectVendorSearchQuery, projectId]
  );

  return (
    <Pane {...containerProps}>
      {isGuestVendor ? (
        <Pane display="flex" justifyContent="flex-start">
          <FormInput
            disabled
            label={
              !hideLabel && (
                <GuestVendorFieldLabel
                  isGeneralContractor={isGeneralContractor}
                  organizationLabel={organizationLabel}
                  theme={theme}
                />
              )
            }
            name="vendor.name"
          />{" "}
        </Pane>
      ) : (
        <Fragment>
          {showParsedVendorDetails ? (
            <ParsedVendorDetails
              isHighlighted={isHighlighted}
              parsedVendorName={newName}
              onSelectMyOwn={() => setShowSelect(true)}
              onUseVendor={() => setAddNewName(newName)}
              {...parsedInfoTooltipProps}
            />
          ) : (
            <FormSelect
              noNull
              outerProps={{ marginBottom }}
              disabled={disableFields}
              hasSuggestions={suggestedVendors.length > 0}
              isWarned={isWarned}
              isHighlighted={isHighlighted}
              label={
                !hideLabel && (
                  <VendorFieldLabel
                    isGeneralContractor={isGeneralContractor}
                    organizationLabel={organizationLabel}
                    parsedInfoTooltipProps={parsedInfoTooltipProps}
                    theme={theme}
                  />
                )
              }
              name={`${vendorObjectFieldName}.id`}
              onChange={(id) => {
                const vendor = vendors.find((vendor) => vendor.id === id);
                const searchedVendor = searchedVendors
                  .concat(suggestedVendors)
                  .find((vendor) => vendor.id === id);

                if (!vendor && searchedVendor) {
                  setNewlyAddedVendors((newVendors) => {
                    return newVendors.concat(searchedVendor);
                  });
                }

                formikProps.setFieldValue(
                  vendorObjectFieldName,
                  vendor || searchedVendor
                );
              }}
              onFilter={searchQuery}
              placeholder={disableFields ? null : "Start typing to search..."}
              popoverMinWidth={700}
              onAddItem={
                hasPermission(PERMISSION_ACTION.CREATE_EDIT_ORGANIZATION) &&
                !disableFields
                  ? handleOpen
                  : null
              }
              addName={
                isInspectionReport
                  ? "Add Inspection Company"
                  : t("createOrganization.add")
              }
              options={getVendorOptions(
                vendors,
                searchedVendors,
                suggestedVendors,
                theme
              )}
              form={formikProps}
            />
          )}
        </Fragment>
      )}
      {/* if no value has been entered in the search box, addNewName will be set to an empty string
      empty string is falsy so need to check against null directly */}
      {addNewName !== null && (
        <CreateOrganizationModal
          containerProps={modalProps}
          initialValues={{
            name: addNewName,
            streetAddress: get(parsedInfo, "vendorAddress"),
            city: get(parsedInfo, "vendorCity"),
            state: get(parsedInfo, "vendorState"),
            zip: get(parsedInfo, "vendorZip"),
            email: get(parsedInfo, "vendorEmail"),
            phoneNumber: get(parsedInfo, "vendorPhoneNumber"),
          }}
          onCancel={handleCancel}
          onCreate={handleCreate}
          onSelect={handleSelect}
        />
      )}
    </Pane>
  );
}

VendorFormPartial.defaultProps = {
  getProjectVendorSearchQuery: () => {},
  label: "Vendor",
  marginRight: 0,
  marginBottom: 0,
  setNewlyAddedVendors: () => {},
  searchedVendors: [],
  suggestedVendors: [],
  vendorObjectFieldName: "vendor",
};
