import { useContext } from "react";
import PropTypes from "prop-types";
import { get, omit } from "lodash";
import { VendorLink } from "components/templates";
import { Pane, Form } from "components/materials";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { DOCUMENT_TYPE_NAME, PERMISSION_ACTION } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import DocumentReviewLayout from "./DocumentReviewLayout";
import { getAutofilledFields } from "./AutofilledFieldsBadge";

const getInitialValues = ({ document, selectedDraw, selectedType }) => {
  const {
    comments,
    dismissedWarnings,
    documentReviewActivity,
    reviews,
    ...restOfDocument
  } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);
  return {
    ...documentProperties,
    draw: selectedDraw,
    type: selectedType,
    isBackup: document.isBackup ? "true" : "false",
  };
};

const getDocumentDataFromForm = (values) => {
  return {
    amount: (values.amount || 0).toString(),
    isBackup: !!(values.isBackup === "true"),
  };
};

function getInformationSummary(form) {
  const summary = [
    get(form.values.vendor, "name"),
    formatCurrency(get(form.values, "amount", 0)),
  ];
  return summary.filter((value) => !!value).join(", ");
}

function DrawCoverSheetReview(props) {
  const { document, form } = props;
  const { hasPermission } = useContext(UserContext);

  const showParsedFields = hasPermission(PERMISSION_ACTION.SHOW_PARSED_FIELDS);

  const autofilledFields = getAutofilledFields(
    get(document, "autofilledFields", []),
    form
  );

  const shouldShowAutofilledField = (fieldName) => {
    return showParsedFields && autofilledFields.indexOf(fieldName) !== -1;
  };

  return (
    <DocumentReviewLayout
      {...props}
      autofilledFieldCount={autofilledFields.length}
      shouldShowAutofilledField={shouldShowAutofilledField}
      information={() => (
        <Pane display="flex" flexDirection="column" width="100%">
          <Pane display="flex" flexDirection="row" flexWrap="wrap">
            <Pane flexGrow={1} marginRight={majorScale(2)}>
              <Form.Input
                isHighlighted={shouldShowAutofilledField("amount")}
                name="amount"
                label="Amount"
                type="currency"
              />
            </Pane>
          </Pane>

          <Pane display="flex" justifyContent="flex-start" alignItems="center">
            <VendorLink
              vendorId={form.values.vendor.id}
              vendors={props.allAvailableVendors}
            />
          </Pane>
        </Pane>
      )}
      informationSummary={getInformationSummary(form)}
    />
  );
}

DrawCoverSheetReview.propTypes = {
  children: PropTypes.node,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      amount: PropTypes.number,
    }),
  }),
  onApprove: PropTypes.func,
  onComment: PropTypes.func,
  onChangeDraw: PropTypes.func,
  onChangeType: PropTypes.func,
};

// TODO: remove dot notation
DrawCoverSheetReview.getInitialValues = getInitialValues;
DrawCoverSheetReview.getDocumentDataFromForm = getDocumentDataFromForm;

export default DrawCoverSheetReview;
