import { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { CommentIcon } from "evergreen-ui";
import {
  Badge,
  Button,
  Card,
  MentionsBody,
  Pane,
  Paragraph,
  Text,
} from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { get, includes } from "lodash";
import { preventEventBubbling } from "helpers/preventEventBubbling";

const DISMISS_MENTION = gql`
  mutation DismissMention($id: String) {
    dismissMention(id: $id) {
      id
    }
  }
`;

const formatTargetName = (target) => {
  switch (target.__typename) {
    case "Rule":
    case "RuleTransition":
      return "Rules";
    case "LineItem":
      return `Line Item: ${target.name}`;
    case "BasicDocument":
      return get(target, "file.name");
    case "Project":
      return get(target, "name");
    case "Task":
      return target.eventName;
    default:
      return "";
  }
};

function MentionCardHeader({
  dismissedMentions,
  handleDismiss,
  loading,
  mention,
  theme,
}) {
  const { target } = mention.comment;
  const isDismissed = includes(dismissedMentions, mention.id);
  return (
    <Pane display="flex">
      <Pane height={16} width={16} marginRight={majorScale(1)}>
        <CommentIcon color="muted" />
      </Pane>
      <Text
        color={theme.colors.gray500}
        flex="1 1 auto"
        fontWeight={600}
        overflow="hidden"
        size={300}
        textOverflow="ellipsis"
        textTransform="uppercase"
        whiteSpace="nowrap"
      >
        {formatTargetName(target)}
      </Text>
      {isDismissed ? (
        <Badge color="red" height={majorScale(2)}>
          Dismissed
        </Badge>
      ) : (
        <Button
          appearance="default"
          height={majorScale(2)}
          isLoading={loading}
          marginLeft={minorScale(1)}
          onClick={(e) => {
            preventEventBubbling(e);
            handleDismiss();
          }}
          purpose="action-items mention dismiss"
        >
          Dismiss
        </Button>
      )}
    </Pane>
  );
}

function MentionCardBody({ comment }) {
  return (
    <Fragment>
      <Paragraph marginTop={majorScale(1)} paddingX={majorScale(3)} size={300}>
        <MentionsBody comment={comment} textProps={{ size: 300 }} />
      </Paragraph>
      <Paragraph color="muted" size={300} textAlign="right">
        {`- ${comment.author.fullName}`}
      </Paragraph>
    </Fragment>
  );
}

export function DashboardMentionCard({
  dismissedMentions,
  history,
  mention,
  onDismissCompleted,
}) {
  const theme = useContext(ThemeContext);
  const { id, comment } = mention;
  const { target } = comment;

  const [dismiss, dismissResult] = useMutation(DISMISS_MENTION, {
    onCompleted: onDismissCompleted,
    variables: { id },
  });

  function handleClick() {
    switch (target.__typename) {
      case "Rule":
        history.push({
          pathname: `/projects/${target.projectId}/draws/${target.scopeId}/reviews/${target.id}`,
          search: "?showAllRules=true",
        });
        break;
      case "RuleTransition":
        history.push({
          pathname: `/projects/${target.rule.projectId}/draws/${target.rule.scopeId}/reviews/${target.rule.id}`,
          search: "?showAllRules=true",
        });
        break;
      case "LineItem":
        history.push(
          `/projects/${target.projectId}/draws/${target.drawId}/line_items/${target.id}`
        );
        break;
      case "Project":
        history.push(`/projects/${target.id}`);
        break;

      case "BasicDocument":
        history.push(
          target.drawId
            ? `/projects/${target.projectId}/draws/${target.drawId}/documentation/${target.id}`
            : `/projects/${target.projectId}/documentation/${target.id}`
        );
        break;
      default:
        break;
    }
  }

  return (
    <Card
      cursor="pointer"
      elevation={1}
      hoverElevation={2}
      marginBottom={majorScale(1)}
      onClick={handleClick}
      padding={minorScale(3)}
    >
      <MentionCardHeader
        dismissedMentions={dismissedMentions}
        handleDismiss={dismiss}
        loading={dismissResult.loading}
        mention={mention}
        theme={theme}
      />
      <MentionCardBody comment={comment} />
    </Card>
  );
}

DashboardMentionCard.propTypes = {
  dismissedMentions: PropTypes.array.isRequired,
  mention: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onDismissCompleted: PropTypes.func.isRequired,
};
