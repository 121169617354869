import { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Loadable, Pane, Paragraph } from "components/materials";
import {
  PaymentsContext,
  PaymentsContextProvider,
} from "contexts/paymentsContext";
import t from "helpers/translate";
import { formatCurrency } from "helpers/formatCurrency";
import { VENDOR_PAYMENT_TYPE } from "helpers/enums";
import { toaster } from "helpers/utilities";
import { get, has, values } from "lodash";
import { BillComErrorModal } from "./BillComErrorModal";
import { PostPaymentForm } from "./PostPaymentForm";
import { POST_PAYMENTS_TO_BILL_COM } from "./graphql";

function getWarningTooltip({
  updatedSincePosted,
  documentHasExternalVendorId,
  projectHasCustomId,
}) {
  if (updatedSincePosted)
    return t("paymentIntegration.updatedSincePostedTooltip");
  if (!documentHasExternalVendorId)
    return t("paymentIntegration.externalVendorMissing", {
      company: "Bill.com",
    });
  if (!projectHasCustomId)
    return t("paymentIntegration.missingProjectCustomId");
  return undefined;
}

function getRowState({
  updatedSincePosted,
  documentHasExternalVendorId,
  projectHasCustomId,
}) {
  if (updatedSincePosted) return "error";
  if (!documentHasExternalVendorId) return "pending";
  if (!projectHasCustomId) return "pending";
  return undefined;
}

function getCanBePosted({
  documentHasExternalVendorId,
  documentHasBeenPosted,
  projectHasCustomId,
}) {
  return (
    !documentHasBeenPosted && projectHasCustomId && documentHasExternalVendorId
  );
}

/* build up an array of error objects for display in an informational modal
[
  {
    documentName: "Document_1.pdf", 
    errors: ["error message 1", "error message 2"...]
  }
]
*/
function formatErrors({ graphQLErrors, documents }) {
  const errorsByDocument = graphQLErrors.reduce(
    (errors, { details: documentId, message }) => {
      if (!has(errors, documentId)) {
        const document = documents.find(({ id }) => id === documentId);
        const documentName = get(document, "file.name");

        errors[documentId] = { documentName, errors: [] };
      }
      const errorMessage = message.includes(" => ")
        ? message.split(" => ")[1]
        : message;

      errors[documentId].errors.push(errorMessage);

      return errors;
    },
    {}
  );
  return values(errorsByDocument);
}

function InnerBillComPaymentPage({ drawId, projectId }) {
  const {
    queryLoading,
    documentsToPost,
    setDocumentsToPost,
    onPaymentPosted,
  } = useContext(PaymentsContext);

  const [returnedBillComErrors, setReturnedBillComErrors] = useState([]);

  const [postPaymentsToBillCom, { loading: postPaymentsLoading }] = useMutation(
    POST_PAYMENTS_TO_BILL_COM,
    {
      onCompleted: () => {
        setDocumentsToPost([]);
        onPaymentPosted();
        toaster.success("Your payments have successfully posted.", {
          duration: 2.5,
        });
      },
      onError: ({ graphQLErrors }) => {
        setDocumentsToPost([]);
        setReturnedBillComErrors(
          formatErrors({ documents: documentsToPost, graphQLErrors })
        );
      },
    }
  );

  function postPayments(formValues) {
    const payments = Object.entries(formValues.payments).reduce(
      (acc, [id, vendorPaymentType]) => {
        const document = documentsToPost.find((document) => document.id === id);
        if (!document || document.postedAt) return acc;

        return [
          ...acc,
          {
            documentId: id,
            amount: formatCurrency(document.paymentDueAmount),
            vendorPaymentType,
          },
        ];
      },
      []
    );

    postPaymentsToBillCom({
      variables: {
        drawId,
        projectId,
        payments,
      },
    });
  }

  if (queryLoading) return <Loadable loading />;

  if (returnedBillComErrors.length !== 0)
    return (
      <BillComErrorModal
        billComErrors={returnedBillComErrors}
        onCloseComplete={() => {
          setReturnedBillComErrors([]);
          onPaymentPosted();
        }}
      />
    );

  return (
    <PostPaymentForm
      confirmationMessage={
        <Pane>
          <Paragraph>This is a confirmation message.</Paragraph>
        </Pane>
      }
      accountsPayableSystem="Bill.com"
      handleSubmit={postPayments}
      onSubmitLoading={postPaymentsLoading}
      vendorPaymentTypes={[
        VENDOR_PAYMENT_TYPE.SOFT_COSTS_DIRECT_PAY,
        VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_REIMBURSABLE,
      ]}
    />
  );
}

export function BillComPaymentPage({ match }) {
  const { drawId, projectId } = match.params;

  return (
    <PaymentsContextProvider
      drawId={drawId}
      projectId={projectId}
      includeLineItems
      includeJobCostCodes={false}
      getWarningTooltip={getWarningTooltip}
      getRowState={getRowState}
      getCanBePosted={getCanBePosted}
    >
      <InnerBillComPaymentPage drawId={drawId} projectId={projectId} />
    </PaymentsContextProvider>
  );
}
