import { useContext, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query, Mutation } from "@apollo/react-components";
import { Formik } from "formik";
import {
  CommentForm,
  DrawLineItemDetails,
  DrawLockdownBanner,
  LineItemNumberForm,
  MasterFormatDivisionForm,
  RequestedAmountForm,
} from "components/templates";
import {
  Button,
  HiddenFocusElement,
  Loadable,
  Pane,
  Sidebar,
  Text,
} from "components/materials";
import { get } from "lodash";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  COMMENT_FRAGMENT,
  RULE_FRAGMENT,
  SLIDEOUT_DOCUMENTS_FRAGMNENT,
} from "helpers/fragments";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { AdjustmentsOverlay } from "./AdjustmentsOverlay";
import { Document } from "./Document";
import { SubmissionDocument } from "./SubmissionDocument";

const FRAGMENT = gql`
  fragment DrawLineItemSlideoutFragment on Draw {
    id
    state
    isLockedDown
    lineItems {
      id
      scopeId
      balanceToFundAmount
      name
      retainageAmount
    }
    lineItem(id: $lineItemId) {
      id
      scopeId
      masterFormatDivision
      name
      number
      division {
        id
        scopeId
        name
      }
      issues {
        id
        name
        severity
      }
      documentedRetainageAmount
      grossDocumentedRequestedAmount
      grossRequestedAmount
      grossRequestedPreviouslyAmount
      grossRequestedToDateAmount
      transactions {
        id
        memo
        sourceType
        insertedAt
        entries {
          id
          account
          amount
        }
        user {
          id
          fullName
        }
        invoice {
          ...SlideoutDocumentsFragment
        }
        drawSummary {
          ...SlideoutDocumentsFragment
        }
      }
      retainageAmount
      retainagePreviouslyAmount
      retainageToDateAmount
      requestedAmount
      requestedToDateAmount
      retainagePercentage
      setManually
      expenses {
        id
        amount
        grossAmount
        isBackup
        document {
          ...SlideoutDocumentsFragment
          comments {
            ...CommentFragment
          }
        }
      }
      comments {
        id
        body
        insertedAt
        author {
          id
          fullName
        }
        mentions {
          id
          user {
            id
            fullName
          }
        }
      }
      rules {
        ...RuleFragment
      }
    }
  }
  ${COMMENT_FRAGMENT}
  ${RULE_FRAGMENT}
  ${SLIDEOUT_DOCUMENTS_FRAGMNENT}
`;

export const QUERY = gql`
  query DrawLineItemSlideoutQuery(
    $projectId: String!
    $drawId: String!
    $lineItemId: String!
  ) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        ...DrawLineItemSlideoutFragment
      }
      users {
        id
        fullName
      }
    }
  }
  ${FRAGMENT}
`;

const SUBMISSION = gql`
  query DrawLineItemSlideoutSubmission(
    $submissionId: String!
    $lineItemId: String!
  ) {
    submission(submissionId: $submissionId) {
      id
      sourceDraw {
        ...DrawLineItemSlideoutFragment
      }
    }
  }
  ${FRAGMENT}
`;

const ADD_LINE_ITEM_COMMENT = gql`
  mutation AddLineItemComment(
    $body: String!
    $drawId: String!
    $lineItemId: String!
  ) {
    addLineItemComment(body: $body, drawId: $drawId, lineItemId: $lineItemId) {
      id
    }
  }
`;

const CHANGE_LINE_ITEM_NUMBER = gql`
  mutation ChangeLineItemNumber(
    $drawId: String!
    $lineItemId: String!
    $number: String
  ) {
    changeLineItemNumber(
      drawId: $drawId
      lineItemId: $lineItemId
      number: $number
    ) {
      id
      scopeId
      number
    }
  }
`;
const CHANGE_MASTER_FORMAT_DIVISION = gql`
  mutation ChangeMasterFormatDivision(
    $drawId: String!
    $lineItemId: String!
    $division: MasterFormatDivision
  ) {
    changeMasterFormatDivision(
      drawId: $drawId
      lineItemId: $lineItemId
      division: $division
    ) {
      id
      scopeId
      masterFormatDivision
    }
  }
`;

export function DrawLineItemSlideout({ closeSidebar, history, match }) {
  const { params } = match;
  const { documentId, lineItemId, drawId, projectId, submissionId } = params;
  const { hasPermission } = useContext(UserContext);
  const [showAdjustmentsOverlay, setShowAdjustmentsOverlay] = useState(false);

  useEffect(
    () =>
      history.listen((_newLocation, action) => {
        if (showAdjustmentsOverlay && action === "POP") {
          setShowAdjustmentsOverlay(false);
        }
      }),
    // eslint-disable-next-line
    [showAdjustmentsOverlay]
  );

  const queryProps = submissionId
    ? {
        skip: !lineItemId,
        query: SUBMISSION,
        variables: { lineItemId, submissionId },
      }
    : {
        skip: !lineItemId,
        query: QUERY,
        variables: { lineItemId, drawId, projectId },
      };

  const showSidebar = !!lineItemId && !documentId;

  const getDocumentPath = (documentId) => {
    return `${history.location.pathname}/documents/${documentId}${history.location.search}`;
  };

  const renderNumberForm = (number) => {
    if (submissionId) return <Text>{number || "None"}</Text>;

    return (
      <Mutation mutation={CHANGE_LINE_ITEM_NUMBER}>
        {(mutation, result) => (
          <Formik
            enableReinitialize
            initialValues={{ drawId, lineItemId, number: number || "" }}
            onSubmit={(values) => mutation({ variables: values })}
          >
            {(form) => <LineItemNumberForm form={form} result={result} />}
          </Formik>
        )}
      </Mutation>
    );
  };

  const renderMasterFormatDivision = (division) => {
    if (submissionId) return <Text>{division || "None"}</Text>;

    return (
      <Mutation mutation={CHANGE_MASTER_FORMAT_DIVISION}>
        {(mutation, result) => (
          <Formik
            enableReinitialize
            initialValues={{ drawId, lineItemId, division: division || "" }}
            onSubmit={(values) => mutation({ variables: values })}
          >
            {(form) => <MasterFormatDivisionForm form={form} result={result} />}
          </Formik>
        )}
      </Mutation>
    );
  };

  const renderCommentForm = (users) => {
    if (submissionId) return null;

    const refetchQueries = [{ query: QUERY, variables: params }];

    const initialValues = {
      body: "",
      drawId,
      lineItemId,
    };

    return (
      <Mutation
        awaitRefetchQueries
        mutation={ADD_LINE_ITEM_COMMENT}
        refetchQueries={refetchQueries}
      >
        {(mutation, result) => (
          <CommentForm
            initialValues={initialValues}
            mutation={mutation}
            showCommentForm
            users={users}
            {...result}
          />
        )}
      </Mutation>
    );
  };

  const renderRequestedAmountForm = (draw, lockdownDraw) => {
    if (submissionId) return null;

    return (
      <RequestedAmountForm
        projectId={projectId}
        drawId={drawId}
        lineItem={draw.lineItem}
        lockdownDraw={lockdownDraw}
      />
    );
  };

  const renderSidebar = (query) => {
    if (query.loading) return <Loadable loading />;

    const users = get(query.data, "project.users", []);
    const draw = get(
      query.data,
      submissionId ? "submission.sourceDraw" : "project.draw"
    );

    const lineItemName = get(draw, "lineItem.name", "");
    const lineItemNumber = get(draw, "lineItem.number", "");
    const masterFormatDivision = get(draw, "lineItem.masterFormatDivision", "");
    const divisionName = get(draw, "lineItem.division.name", "");
    const lockdownDraw = get(draw, "isLockedDown");

    return (
      <Fragment>
        <HiddenFocusElement />
        <Sidebar.Heading breadcrumbs={[divisionName, lineItemName]}>
          {!submissionId && (
            <Button
              appearance="minimal"
              onClick={() => {
                setShowAdjustmentsOverlay(true);
                history.push(history.location);
              }}
            >
              Budget Adjustments
            </Button>
          )}
        </Sidebar.Heading>
        <Sidebar.Section
          padding={0}
          marginTop={0}
          data-testid="sidebar-lineitem"
        >
          {lockdownDraw && (
            <DrawLockdownBanner title={t("lockdownBanner.lineItemTitle")} />
          )}
          <Pane padding={majorScale(2)}>
            <DrawLineItemDetails
              lineItem={draw.lineItem}
              isSuperLineItem={false}
              masterFormatDivisionForm={
                hasPermission(
                  PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING
                )
                  ? renderMasterFormatDivision(masterFormatDivision)
                  : null
              }
              numberForm={renderNumberForm(lineItemNumber)}
              commentForm={renderCommentForm(users)}
              requestedAmountForm={renderRequestedAmountForm(
                draw,
                lockdownDraw
              )}
              getDocumentPath={
                hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT)
                  ? getDocumentPath
                  : null
              }
            />
          </Pane>
        </Sidebar.Section>
      </Fragment>
    );
  };

  return (
    <Query {...queryProps}>
      {(query) => (
        <Fragment>
          {showAdjustmentsOverlay ? (
            <AdjustmentsOverlay
              drawId={drawId}
              projectId={projectId}
              onClose={() => {
                setShowAdjustmentsOverlay(false);
                history.goBack();
              }}
            />
          ) : (
            <Sidebar isShown={showSidebar} onCloseComplete={closeSidebar}>
              {showSidebar && renderSidebar(query)}
            </Sidebar>
          )}
          {documentId &&
            lineItemId &&
            (submissionId ? (
              <SubmissionDocument
                documents={get(
                  query,
                  "data.submission.sourceDraw.lineItem.expenses",
                  []
                )
                  .map(({ document }) => document)
                  .filter((document) => !!document)}
                history={history}
                match={match}
              />
            ) : (
              <Document
                documents={get(query, "data.project.draw.lineItem.expenses", [])
                  .map(({ document }) => document)
                  .filter((document) => !!document)}
                history={history}
                match={match}
              />
            ))}
        </Fragment>
      )}
    </Query>
  );
}

DrawLineItemSlideout.propTypes = {
  match: PropTypes.object.isRequired,
};
