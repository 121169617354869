import { Fragment, useContext, useState } from "react";
import {
  Badge,
  Combobox,
  Form,
  Heading,
  InterpolatedTranslation,
  Link,
  Pane,
  Radio,
  SearchInput,
  Table,
  Text,
  Tooltip,
} from "components/materials";
import { minorScale, majorScale, Position } from "helpers/utilities";
import t from "helpers/translate";
import { cloneDeep, escapeRegExp, forEach, get, includes, set } from "lodash";
import getOptions from "helpers/getOptions";
import { UserContext } from "helpers/behaviors";
import { stringComparator } from "helpers/comparators";
import { PERMISSION_ACTION } from "helpers/enums";

function TeamDropdown({ teams, onTeamDropdownChange, orgTeamId }) {
  const teamOptions = [{ key: "blank", value: null, text: "" }].concat(
    getOptions(teams)
  );

  const currentTeam = teamOptions.find(
    (option) => option.value === (orgTeamId || null)
  );

  return (
    <Combobox
      openOnFocus
      placeholder="No Team Selected"
      selectedItem={currentTeam}
      items={teamOptions}
      itemToString={(item) => item?.text}
      onChange={(teamOption) => onTeamDropdownChange(teamOption?.value || null)}
      marginBottom={majorScale(2)}
    />
  );
}

function UserAccessTableHeader({
  hasSuggestedDocumentAssignees,
  isOrganizationConfigurationTable,
}) {
  return (
    <Table.Head>
      <Table.Row>
        <Table.TextHeaderCell content="Name" />
        {!isOrganizationConfigurationTable && (
          <Table.TextHeaderCell content="Project Access" textAlign="center" />
        )}
        {hasSuggestedDocumentAssignees && (
          <Table.TextHeaderCell
            content="Suggested Document Assignee"
            textAlign="center"
          />
        )}
        <Table.TextHeaderCell content="Preparer" textAlign="center" />
        <Table.TextHeaderCell content="Draw Reviewer" textAlign="center" />
        <Table.TextHeaderCell content="Signatory" textAlign="center" />
      </Table.Row>
    </Table.Head>
  );
}

function UserAccessTableRow({
  form,
  teamName,
  hasSuggestedDocumentAssignees,
  hasTeamManagement,
  hasTeamViewAllAccess,
  user,
  userId,
  isOrganizationConfigurationTable,
}) {
  const userPath = `users.${userId}`;
  const { users, documentReviewerIds } = form.values;

  // the org-level form will always have an empty list for `documentReviewerIds`
  // since we don't currently accommodate org-level config for document reviewers
  // and this value will evaluate to false
  const isDocumentReviewer = documentReviewerIds.includes(userId);

  const tooltipProps = user.hasSignatoryPermission
    ? { isShown: false }
    : {
        content: t("projectSettings.userAccess.signatoryTooltip"),
        position: Position.RIGHT,
      };

  const signatoryDisabled = !user.hasSignatoryPermission;
  const { hasAllProjectAccess } = user;

  return (
    <Table.Row>
      <Table.TextCell width={300}>
        {user.fullName}
        {isOrganizationConfigurationTable && hasTeamManagement && (
          <Pane>
            <Badge
              key={teamName}
              color={hasAllProjectAccess ? "blue" : "yellow"}
              marginLeft={majorScale(2)}
              marginBottom={minorScale(1)}
            >
              {hasAllProjectAccess ? "All" : teamName}
            </Badge>
          </Pane>
        )}
        {isDocumentReviewer && (
          <Badge color="purple" marginLeft={majorScale(2)}>
            Document Reviewer
          </Badge>
        )}
      </Table.TextCell>
      {!isOrganizationConfigurationTable && (
        <Table.Cell id="user-project-access" textAlign="Center" width={125}>
          {user.viewAll || hasTeamViewAllAccess ? (
            <Badge color={user.viewAll ? "blue" : "yellow"}>
              {user.viewAll ? "All" : teamName}
            </Badge>
          ) : (
            <Form.Checkbox
              disabled
              justifyContent="center"
              marginY={minorScale(1)}
              name={`${userPath}.canAccessProject`}
            />
          )}
        </Table.Cell>
      )}
      {/* org-level table does not support suggested document assignees */}
      {hasSuggestedDocumentAssignees && (
        <Table.Cell width={125}>
          <Form.Checkbox
            justifyContent="center"
            marginY={minorScale(1)}
            onChange={(checked) => {
              // if designating a suggested assignee, grant access to the project
              if (checked && user.canAccessProject === false)
                form.setFieldValue(`${userPath}.canAccessProject`, true);
            }}
            name={`${userPath}.isSuggestedAssignee`}
          />
        </Table.Cell>
      )}
      <Table.Cell width={100}>
        <Radio
          // this key is confusing but without it things don't work
          key={user.isPreparer}
          justifyContent="center"
          marginY={minorScale(1)}
          onClick={() => {
            if (user.isPreparer) {
              form.setFieldValue(`${userPath}.isPreparer`, false);
            } else {
              const newUserObject = cloneDeep(users);

              // when designating a new Preparer
              // un-designate them as Reviewer or Signatory,
              // and make sure no other users are designated Preparer
              forEach(newUserObject, (userValues, id) => {
                if (id === userId) {
                  set(newUserObject, id, {
                    ...userValues,
                    isDrawReviewer: false,
                    isPreparer: true,
                    isSignatory: false,
                  });
                } else {
                  set(newUserObject, `${id}.isPreparer`, false);
                }
              });
              form.setFieldValue("users", newUserObject);
            }
          }}
          checked={user.isPreparer}
        />
      </Table.Cell>
      <Table.Cell width={100}>
        <Form.Checkbox
          justifyContent="center"
          marginY={minorScale(1)}
          name={`${userPath}.isDrawReviewer`}
          onChange={(checked) => {
            const currentUser = form.values.users[userId];
            // if designating a draw reviewer,
            // un-designate them as Preparer or Signatory
            if (checked) {
              form.setFieldValue(userPath, {
                ...currentUser,
                isDrawReviewer: true,
                isPreparer: false,
                isSignatory: false,
              });
            }
          }}
        />
      </Table.Cell>
      <Table.Cell width={100}>
        <Tooltip {...tooltipProps}>
          <Radio
            checked={user.isSignatory}
            disabled={signatoryDisabled}
            // this key is confusing but without it things don't work
            key={user.isSignatory}
            justifyContent="center"
            marginY={minorScale(1)}
            onClick={() => {
              if (signatoryDisabled) return;

              if (user.isSignatory) {
                form.setFieldValue(`${userPath}.isSignatory`, false);
              } else {
                const newUserObject = cloneDeep(users);

                // when designating a new Signatory
                // un-designate them as Reviewer or Preparer,
                // and make sure no other users are designated Signatory
                forEach(newUserObject, (userValues, id) => {
                  if (id === userId) {
                    set(newUserObject, id, {
                      ...userValues,
                      isSignatory: true,
                      isPreparer: false,
                      isDrawReviewer: false,
                    });
                  } else {
                    set(newUserObject, `${id}.isSignatory`, false);
                  }
                });
                form.setFieldValue("users", newUserObject);
              }
            }}
          />
        </Tooltip>
      </Table.Cell>
    </Table.Row>
  );
}

function UserAccessGuidance({ isOrganizationConfigurationTable }) {
  const { hasPermission } = useContext(UserContext);
  const hasSuggestedDocumentAssignees =
    hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS) &&
    !isOrganizationConfigurationTable;
  const hasUserManagement = hasPermission(PERMISSION_ACTION.MANAGE_USER);

  return (
    <Pane marginLeft={majorScale(2)} width="80%">
      <Heading
        size={400}
        textTransform="uppercase"
        marginBottom={majorScale(3)}
      >
        Role Descriptions
      </Heading>

      {!isOrganizationConfigurationTable && (
        <Pane marginBottom={majorScale(2)}>
          <Heading size={300}>Project Access</Heading>
          <Text size={300}>
            {t("projectSettings.userAccess.projectAccess")}
            {hasUserManagement ? (
              <InterpolatedTranslation
                size={300}
                string={t("projectSettings.userAccess.projectAccessAdmin1")}
                values={[
                  <Link href="/admin" size={300} target="_blank">
                    {t("projectSettings.userAccess.projectAccessAdmin2")}
                  </Link>,
                ]}
              />
            ) : (
              t("projectSettings.userAccess.projectAccessNonAdmin")
            )}
          </Text>
        </Pane>
      )}

      {hasSuggestedDocumentAssignees && (
        <Pane marginBottom={majorScale(2)}>
          <Heading size={300}>Suggested Document Assignee</Heading>
          <Text size={300}>
            {t("projectSettings.userAccess.accessSuggestedDocumentAssignees")}
          </Text>
        </Pane>
      )}

      <Pane marginBottom={majorScale(2)}>
        <Heading size={300}>Preparer</Heading>
        <Text size={300}>{t("projectSettings.userAccess.accessPreparer")}</Text>
      </Pane>

      <Pane marginBottom={majorScale(2)}>
        <Heading size={300}>Draw Reviewer</Heading>
        <Text size={300}>
          {t("projectSettings.userAccess.accessDrawReviewers")}
        </Text>
      </Pane>

      <Heading size={300}>Signatory</Heading>
      <Text size={300}>{t("projectSettings.userAccess.accessSignatory")}</Text>
    </Pane>
  );
}

export function UserAccessTable({
  form,
  teams,
  hasSuggestedDocumentAssignees,
  hasTeamManagement,
  isOrganizationConfigurationTable,
  onTeamDropdownChange,
  orgTeamId,
}) {
  const [userFilter, setUserFilter] = useState("");
  const { teamId, users } = form.values;

  const sortedFilteredUsers = prepareUsers(users, userFilter);

  const team = teams.find((team) => [teamId, orgTeamId].includes(team.id));
  const teamName = get(team, "name");

  const teamMembersWithAccess = get(team, "memberships", [])
    .filter((member) => get(member, "permissionConfig.viewAllTeamProjects"))
    .map((member) => member.userId);

  const showTeamDropdown =
    isOrganizationConfigurationTable && hasTeamManagement;

  return (
    <Fragment>
      {showTeamDropdown && (
        <TeamDropdown
          onTeamDropdownChange={onTeamDropdownChange}
          orgTeamId={orgTeamId}
          teams={teams}
        />
      )}
      <SearchInput
        marginBottom={minorScale(2)}
        onChange={(e) => setUserFilter(e.target.value)}
        placeholder="Find User"
        value={userFilter}
        width={200}
      />
      <Pane display="flex">
        <Pane
          borderLeft="muted"
          borderRight="muted"
          height={400}
          overflowY="scroll"
          width="200%"
        >
          <Table paddingBottom={0}>
            <UserAccessTableHeader
              hasSuggestedDocumentAssignees={hasSuggestedDocumentAssignees}
              isOrganizationConfigurationTable={
                isOrganizationConfigurationTable
              }
            />
            <Table.Body>
              {sortedFilteredUsers.map((user) => {
                const hasTeamViewAllAccess =
                  hasTeamManagement && includes(teamMembersWithAccess, user.id);
                return (
                  <UserAccessTableRow
                    key={user.id}
                    form={form}
                    hasSuggestedDocumentAssignees={
                      hasSuggestedDocumentAssignees
                    }
                    hasTeamManagement={hasTeamManagement}
                    hasTeamViewAllAccess={hasTeamViewAllAccess}
                    isOrganizationConfigurationTable={
                      isOrganizationConfigurationTable
                    }
                    orgTeamId={orgTeamId}
                    teamName={teamName}
                    user={user}
                    userId={user.id}
                  />
                );
              })}
            </Table.Body>
          </Table>
        </Pane>
        <UserAccessGuidance
          isOrganizationConfigurationTable={isOrganizationConfigurationTable}
        />
      </Pane>
    </Fragment>
  );
}

function prepareUsers(userLibrary, userFilter) {
  return Object.values(userLibrary)
    .filter((user) =>
      new RegExp(escapeRegExp(userFilter), "i").test(user.fullName)
    )
    .sort((a, b) => stringComparator(a.fullName, b.fullName));
}
