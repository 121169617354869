import { majorScale } from "helpers/utilities";
import { Form, Pane, Text } from "components/materials";

export const SinceControl = ({ fieldPath }) => {
  const fieldName = `${fieldPath}.value`;

  return (
    <Pane alignItems="center" display="flex">
      <Form.Field>
        <Text marginRight={majorScale(1)}>Since</Text>
      </Form.Field>
      <Form.DateInput minWidth={90} name={fieldName} />
    </Pane>
  );
};
