import { useContext, useMemo } from "react";
import { EditTableViews } from "components/containers";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
  primaryColumnDefaults,
  currencyColumnDefaults,
  dateColumnDefaults,
  enumColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import {
  getDateRangeAggregate,
  getDefaultAggregate,
} from "helpers/tableAggregateHelpers";
import { get } from "lodash";
import { UserContext } from "helpers/behaviors";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { subtract, sumBy } from "helpers/math";
import {
  PERMISSION_ACTION,
  PROJECT_STATUS_TYPE,
  FUNDING_SOURCE_TYPE,
} from "helpers/enums";
import { getEnumValuesForOrganization } from "helpers/reportHelpers";
import t from "helpers/translate";
import { customFieldFastColumns } from "./CustomFieldColumns";

const defaultViews = [
  {
    config: toBase64({
      columnConfig: [
        "name",
        "project",
        "vendorName",
        "type",
        "amount",
        "fundedToDate",
        "closeDate",
        "maturityDate",
      ],
      filterConfig: [],
      groupConfig: {},
      sortConfig: {},
    }),
    isDefault: true,
    name: "Default",
  },
];

function getColumns(orgData, hasPermission) {
  const orgEnumValues = getEnumValuesForOrganization(orgData);

  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Name",
      id: "name",
      // category not needed - primary column
      value: (fundingSource) => fundingSource.label,
      width: 250,
    },
    {
      ...stringColumnDefaults,
      header: "Project",
      id: "project",
      category: "Project Information",
      groupable: true,
      value: (fundingSource) => fundingSource.project.name,
      aggregate: (groupedFundingSources) =>
        getDefaultAggregate(groupedFundingSources, "project.name"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.teams,
      header: "Team",
      id: "team",
      category: "Project Information",
      hidden: !hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT),
      groupable: true,
      value: (fundingSource) => fundingSource.team,
      aggregate: (groupedFundingSources) =>
        getDefaultAggregate(groupedFundingSources, "team"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectTemplates,
      header: "Project Type",
      id: "projectType",
      category: "Project Information",
      groupable: true,
      value: (fundingSource) => fundingSource.projectType,
      aggregate: (groupedFundingSources) =>
        getDefaultAggregate(groupedFundingSources, "projectType"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.productTypes,
      header: "Product Type",
      id: "productType",
      category: "Project Information",
      groupable: true,
      value: (fundingSource) => fundingSource.productType,
      aggregate: (groupedFundingSources) =>
        getDefaultAggregate(groupedFundingSources, "productType"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectRegions,
      header: "Project Region",
      id: "projectRegion",
      category: "Project Information",
      groupable: true,
      value: (fundingSource) => fundingSource.projectRegion,
      aggregate: (groupedFundingSources) =>
        getDefaultAggregate(groupedFundingSources, "projectRegion"),
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(PROJECT_STATUS_TYPE).map((status) =>
        t(`projectStatus.${status}`)
      ),
      header: "Project Status",
      id: "projectStatus",
      category: "Project Information",
      groupable: true,
      value: (fundingSource) => fundingSource.projectStatus,
      aggregate: (groupedFundingSources) =>
        getDefaultAggregate(groupedFundingSources, "projectStatus"),
      width: 120,
    },
    {
      ...stringColumnDefaults,
      header: "Organization",
      id: "vendorName",
      category: "Funding Source Information",
      groupable: true,
      value: (fundingSource) => fundingSource?.organization?.name,
      aggregate: (groupedFundingSources) =>
        getDefaultAggregate(groupedFundingSources, "organization.name"),
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(FUNDING_SOURCE_TYPE).map((type) =>
        t(`fundingSourceType.${type}`)
      ),
      header: "Type",
      id: "type",
      category: "Funding Source Information",
      groupable: true,
      value: (fundingSource) => fundingSource.type,
      aggregate: (groupedFundingSources) =>
        getDefaultAggregate(groupedFundingSources, "type"),
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      header: "Amount",
      id: "amount",
      category: "Funding Source Information",
      value: (fundingSource) => fundingSource.amount,
      aggregate: (groupedFundingSources) =>
        sumBy(groupedFundingSources, "amount"),
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      header: "Funded to Date",
      id: "fundedToDate",
      category: "Funding Source Information",
      value: (fundingSource) => fundingSource.disbursedAmount,
      aggregate: (groupedFundingSources) =>
        sumBy(groupedFundingSources, "disbursedAmount"),
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      header: "Remaining Balance",
      id: "remainingBalance",
      category: "Funding Source Information",
      value: (fundingSource) =>
        subtract(fundingSource.amount, fundingSource.disbursedAmount),
      aggregate: (groupedFundingSources) =>
        sumBy(groupedFundingSources, (fundingSource) =>
          subtract(fundingSource.amount, fundingSource.disbursedAmount)
        ),
      width: 150,
    },
    {
      ...dateColumnDefaults,
      header: "Close Date",
      id: "closeDate",
      category: "Funding Source Information",
      value: (fundingSource) => fundingSource.closeDate,
      aggregate: (groupedFundingSources) =>
        getDateRangeAggregate(groupedFundingSources, "closeDate"),
    },
    {
      ...dateColumnDefaults,
      header: "Maturity Date",
      id: "maturityDate",
      category: "Funding Source Information",
      value: (fundingSource) => fundingSource.maturityDate,
      aggregate: (groupedFundingSources) =>
        getDateRangeAggregate(groupedFundingSources, "maturityDate"),
    },
  ];
}

function prepareFundingSources(projects) {
  return projects.flatMap((project) =>
    project.fundingSourceGroups.flatMap((fundingSourceGroup) =>
      fundingSourceGroup.map((fundingSource) => ({
        ...fundingSource,
        type: t(`fundingSourceType.${fundingSource.type}`),
        project,
        productType: project.productType?.type,
        projectRegion: project.projectRegion?.region,
        projectStatus: t(`projectStatus.${project.status}`),
        projectType: project.template.name,
        team: project.team?.name,
        customFields: project.customFields,
      }))
    )
  );
}

function getControls(propsControls, propsEditTableViews) {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      disable={[FastDataTableDownloadDocuments]}
      isReport
      pinnedFilters={[
        "projectStatus",
        "projectType",
        "productType",
        "projectRegion",
        "team",
      ]}
      searchPlaceholder="Search..."
    />
  );
}

export function FundingSourcesReportTable({
  projects,
  history,
  selectedOrganization,
  orgData,
}) {
  const { hasPermission } = useContext(UserContext);

  const projectCustomFieldColumns = customFieldFastColumns(
    projects,
    "customFields"
  );

  const columns = useMemo(
    () =>
      getColumns(orgData, (permission) =>
        hasPermission(permission, selectedOrganization)
      ).concat(projectCustomFieldColumns),
    [selectedOrganization, hasPermission, projectCustomFieldColumns, orgData]
  );

  const preparedFundingSources = prepareFundingSources(projects);

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(
        PERMISSION_ACTION.SAVE_TABLE_VIEWS,
        selectedOrganization
      )}
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={selectedOrganization.id}
      defaultViews={defaultViews}
      tableName="FundingSourcesReport"
    >
      {(propsEditTableViews) => (
        <FastDataTable
          columns={columns}
          controls={(propsControls) =>
            getControls(propsControls, propsEditTableViews)
          }
          items={preparedFundingSources}
          onClickRow={(fundingSource) =>
            history.push(`/projects/${fundingSource.project.id}`)
          }
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(propsEditTableViews, "views.0.config")
          }
        />
      )}
    </EditTableViews>
  );
}
