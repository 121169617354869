import { Loader } from "@googlemaps/js-api-loader";

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const loader = new Loader({
  apiKey: googleApiKey,
  version: "weekly",
});

export default loader.load();
