import { useState, useContext } from "react";
import PropTypes from "prop-types";
import t from "helpers/translate";
import {
  majorScale,
  minorScale,
  Position,
  ThemeContext,
} from "helpers/utilities";
import { Pane, Tooltip } from "components/materials";
import { rabbet } from "images";
import { get } from "lodash";

export function getAutofilledFields(autofilledFields, form) {
  return autofilledFields.filter((fieldName) => {
    return get(form.values, fieldName) === get(form.initialValues, fieldName);
  });
}

export function AutofilledFieldsBadge({ count, onClick }) {
  const theme = useContext(ThemeContext);

  const [selected, setSelected] = useState(false);

  if (count === 0) {
    return null;
  }

  return (
    <Tooltip
      content={t("documentReview.autofilledFieldsTooltip", {
        count,
      })}
      position={Position.TOP}
    >
      <Pane
        backgroundColor={
          selected ? theme.colors.gray200 : theme.colors.lightBlue
        }
        borderRadius={minorScale(3)}
        color={theme.colors.blue900}
        cursor="pointer"
        fontSize={14}
        fontWeight={theme.fontWeights.MEDIUM}
        onClick={() => {
          setSelected(!selected);
          onClick();
        }}
        paddingX={majorScale(1)}
        paddingY={minorScale(1)}
      >
        <img alt="Rabbet" src={rabbet} style={{ width: 12 }} />
      </Pane>
    </Tooltip>
  );
}

AutofilledFieldsBadge.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
};

AutofilledFieldsBadge.defaultProps = {
  count: 0,
  onClick: () => {},
};
