import PropTypes from "prop-types";
import { Heading, Pane } from "components/materials";
import { divide } from "helpers/math";
import formatPercent from "helpers/formatPercent";

const toPercent = (value, total) => {
  if (total === 0) return "0%";
  return formatPercent(divide(value, total), "0%");
};

export function ProgressLabel({
  children,
  title,
  total,
  value,
  valueFormatter,
}) {
  return (
    <Pane
      alignItems="center"
      display="flex"
      justifyContent="center"
      width="100%"
    >
      <Heading fontWeight={600} size={300}>
        {title && <Pane flex="0 0 auto">{title}</Pane>}
      </Heading>
      <Pane flex="1 1 auto">{children}</Pane>
      <Heading size={100}>
        {`${valueFormatter(value)}/${valueFormatter(total)} (${toPercent(
          value,
          total
        )})`}
      </Heading>
    </Pane>
  );
}

ProgressLabel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  total: PropTypes.number,
  value: PropTypes.number,
  valueFormatter: PropTypes.func,
};
