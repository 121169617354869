import { Breadcrumb, Heading, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

const SidebarHeading = ({ children, breadcrumbs = [], textProps }) => {
  return (
    <Pane
      alignItems="center"
      borderBottom
      display="flex"
      justifyContent="space-between"
      padding={majorScale(2)}
      height={54}
    >
      <Heading {...textProps}>
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </Heading>
      <Heading>{children}</Heading>
    </Pane>
  );
};

export default SidebarHeading;
