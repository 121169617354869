import { FastCheckbox, FormCheckbox } from "./FormCheckbox";
import { FormMentionsTextArea } from "./FormMentionsTextArea";
import { FormTimeInput } from "./FormTimeInput";
import FormBlurInput from "./FormBlurInput";
import { FormDateInput } from "./FormDateInput";
import FormField from "./FormField";
import FormGeneric from "./FormGeneric";
import FormGroup from "./FormGroup";
import FormInput from "./FormInput";
import FormModal from "./FormModal";
import FormRadioGroup from "./FormRadioGroup";
import FormSection from "./FormSection";
import FormSubmitButton from "./FormSubmitButton";
import FormSwitch, { BaseSwitch } from "./FormSwitch";
import FormTextArea from "./FormTextArea";
import { FormNewInput } from "./FormNewInput";
import { FormNewTextArea } from "./FormNewTextArea";
import { InnerSelect } from "./Select/InnerSelect";
import Select from "./Select/Select";

function Form(props) {
  return <form autoComplete="off" {...props} />;
}

// TODO: remove dot notation
Form.NewInput = FormNewInput;
Form.NewSelect = InnerSelect;
Form.NewTextArea = FormNewTextArea;

// Formik-wrapped components
Form.BlurInput = FormBlurInput;
Form.Checkbox = FormCheckbox;
Form.DateInput = FormDateInput;
Form.FastCheckbox = FastCheckbox;
Form.Field = FormField;
Form.Generic = FormGeneric;
Form.Group = FormGroup;
Form.Input = FormInput;
Form.MentionsTextArea = FormMentionsTextArea;
Form.Modal = FormModal;
Form.RadioGroup = FormRadioGroup;
Form.Section = FormSection;
Form.Select = Select;
Form.SubmitButton = FormSubmitButton;
Form.Switch = FormSwitch;
Form.BaseSwitch = BaseSwitch;
Form.TextArea = FormTextArea;
Form.TimeInput = FormTimeInput;

export default Form;
export { FastInput } from "./FastInput";
export { FastMaskInput } from "./FastMaskInput";
