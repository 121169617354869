import { useState } from "react";
import PropTypes from "prop-types";
import { CrossIcon } from "evergreen-ui";
import {
  Button,
  Form,
  MentionsCommentField,
  Pane,
  Popover,
  Text,
  SelectField,
} from "components/materials";
import { get, values } from "lodash";
import isBlank from "helpers/isBlank";
import { RULE_STATE } from "helpers/enums";
import { minorScale, majorScale, Position } from "helpers/utilities";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import t from "helpers/translate";

const TransitionCommentPopover = ({
  change,
  isModal,
  onClose,
  setChange,
  setModalTransitionVariables,
  transition,
  users,
}) => (
  <Pane padding={majorScale(1)} width={375}>
    <Form onSubmit={preventEventBubbling}>
      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Text>Reason</Text>
        <CrossIcon
          color="#4f5f70"
          onClick={onClose}
          size={12}
          cursor="pointer"
        />
      </Pane>
      <MentionsCommentField
        autoFocus
        onChange={(event) => {
          if (isModal)
            setModalTransitionVariables({
              ...change,
              body: event.target.value,
            });
          setChange({ ...change, body: event.target.value });
        }}
        placeholder="Add a reason"
        users={users}
        value={get(change, "body")}
      />
      <Pane display="flex" justifyContent="flex-end">
        {isModal ? (
          <Button
            content="Ignore"
            marginTop={minorScale(1)}
            disabled={isBlank(get(change, "body"))}
            purpose="rules ignore submit"
            onClick={() => {
              setModalTransitionVariables(change);
              setChange(null);
            }}
          />
        ) : (
          <Form.SubmitButton
            content="Ignore"
            disabled={isBlank(get(change, "body"))}
            marginTop={minorScale(1)}
            onClick={() => {
              transition({ variables: change });
              setChange(null);
            }}
            purpose="rules ignore submit"
            type="button"
          />
        )}
      </Pane>
    </Form>
  </Pane>
);

const isBodyRequired = (state) => state === RULE_STATE.IGNORED;

const DrawRuleSelect = ({
  autoRuleState,
  drawId,
  isModal,
  modalRuleId,
  modalTransitionVariables,
  rule,
  setManually,
  setModalTransitionVariables,
  transition,
  transitionResult,
  users,
}) => {
  const [change, setChange] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const initialSelectValue =
    autoRuleState && !setManually
      ? "auto"
      : get(modalTransitionVariables, "state") || rule.state;

  return (
    <Popover
      content={
        <TransitionCommentPopover
          change={change}
          isModal={isModal}
          setChange={setChange}
          onClose={() => setIsShown(false)}
          setModalTransitionVariables={setModalTransitionVariables}
          transition={transition}
          users={users}
        />
      }
      isShown={
        isShown &&
        !!change &&
        change.ruleId === rule.id &&
        isBodyRequired(change.state) &&
        !!modalRuleId === isModal
      }
      onClose={() => {
        setIsShown(false);
        setChange(null);
      }}
      position={Position.BOTTOM_LEFT}
    >
      <SelectField
        margin={0}
        disabled={transitionResult.loading}
        isInvalid={
          change && change.ruleId === rule.id && transitionResult.loading
        }
        width={125}
        value={get(modalTransitionVariables, "state") || initialSelectValue}
        onChange={(event) => {
          const auto = event.target.value === "auto";
          const state = auto ? autoRuleState : event.target.value;
          const variables = {
            drawId,
            ruleId: rule.id,
            state,
            auto,
          };
          if (!isBodyRequired(variables.state) && !isModal) {
            transition({ variables });
          }
          if (isModal) {
            setModalTransitionVariables(variables);
          }

          // Delay updating this state to prevent a race condition
          // in IE which eagerly calls `close` on the Popover element
          setTimeout(() => {
            setIsShown(true);
            setChange(variables);
          }, 100);
        }}
      >
        {autoRuleState && (
          <option value="auto" key="auto">
            {`${t(`ruleStates.${autoRuleState}`)} (Auto)`}
          </option>
        )}
        {values(RULE_STATE).map((name) => {
          const ruleName = t(`ruleStates.${name}`);
          return (
            <option value={name} key={name}>
              {ruleName}
            </option>
          );
        })}
      </SelectField>
    </Popover>
  );
};

DrawRuleSelect.propTypes = {
  drawId: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  modalRuleId: PropTypes.string,
  rule: PropTypes.object.isRequired,
  transition: PropTypes.func.isRequired,
  transitionResult: PropTypes.object.isRequired,
};

DrawRuleSelect.defaultProps = {
  modalRuleId: null,
};

export default DrawRuleSelect;
