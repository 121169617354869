import PropTypes from "prop-types";
import { Pane } from "components/materials";
import { PROJECT_STATUS_TYPE } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { PortfolioProjects } from "./PortfolioProjects";
import { PortfolioTodos } from "./PortfolioTodos";

// This doesn't match what's defined in Dozer for active projects.
export function getFilteredProjects(projects) {
  return projects.filter(
    ({ setupComplete, status }) =>
      status === PROJECT_STATUS_TYPE.ACTIVE ||
      status === PROJECT_STATUS_TYPE.PREDEVELOPMENT ||
      status === PROJECT_STATUS_TYPE.UNDER_CONTRACT ||
      !setupComplete
  );
}

export function PortfolioDashboard({
  dispatchViewConfig,
  history,
  orgData,
  projects,
  scopeOrganizationsProps,
  todos,
  viewConfig,
}) {
  const countTodos =
    todos.drawApprovals.length +
    todos.documents.length +
    todos.mentions.filter((mention) => !!mention.comment.target).length;

  const showTodosList = viewConfig.showTodos && countTodos > 0;

  return (
    <Pane
      display="flex"
      justifyContent="center"
      marginTop={majorScale(2)}
      width="100vw"
    >
      <PortfolioProjects
        countTodos={countTodos}
        dispatchViewConfig={dispatchViewConfig}
        history={history}
        orgData={orgData}
        projects={projects}
        scopeOrganizationsProps={scopeOrganizationsProps}
        viewConfig={viewConfig}
      />
      {showTodosList && (
        <Pane flex="0 0 375px" marginRight={majorScale(2)} width={375}>
          <PortfolioTodos
            history={history}
            todos={todos}
            dispatchViewConfig={dispatchViewConfig}
          />
        </Pane>
      )}
    </Pane>
  );
}

PortfolioDashboard.propTypes = {
  scopeOrganizationsProps: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
};
