import { Fragment } from "react";
import { Button, Menu, Popover } from "components/materials";
import { chunk, cloneDeep, concat } from "lodash";
import isBlank from "helpers/isBlank";
import arrayMove from "helpers/arrayMove";
import t from "helpers/translate";
import { deleteGroup, getGroupNames } from "helpers/fundingSourceHelpers";
import { Position, withTheme } from "helpers/utilities";
import { CaretDownIcon } from "evergreen-ui";

const addToGroup = (arr, targetIndex, sourceIndex) => {
  const clonedArr = cloneDeep(arr);
  clonedArr[targetIndex] = concat(arr[sourceIndex], arr[targetIndex]);
  clonedArr.splice(sourceIndex, 1); // Remove the original group
  return clonedArr;
};

const splitIntoGroups = (arr, index) => {
  const section1 = arr.slice(0, index);
  const section2 = chunk(arr[index], 1);
  const section3 = arr.slice(index + 1);
  return concat(concat(section1, section2), section3);
};

const newPositionTipLabel = (groups, currentIndex, newIndex) => {
  if (newIndex === 0) return null;

  const groupNames = getGroupNames(groups);

  let groupName = groupNames[newIndex - 1];
  if (newIndex >= currentIndex) {
    groupName = groupNames[newIndex];
  }

  return isBlank(groupName) ? " (after unnamed)" : ` (after ${groupName})`;
};

const keyForGroup = (group) => group.map(({ id, key }) => id || key).join(":");

const MoveToMenuItems = ({ form, groups, index }) => {
  return (
    <Fragment>
      <Menu.Group title="Move to">
        {groups.map((group, menuIndex) => {
          if (index !== menuIndex) {
            const translationIndex =
              menuIndex + 1 > 3 ? "other" : menuIndex + 1;
            return (
              <Menu.Item
                key={keyForGroup(group)}
                onSelect={() => {
                  const oldValues = form.values.fundingSourceGroups;
                  const result = arrayMove(oldValues, index, menuIndex);
                  form.setFieldValue("fundingSourceGroups", result);
                }}
                data-purpose="funding-sources order move-to"
              >
                {t(`fundingSources.payOrder_${translationIndex}`, {
                  position: menuIndex + 1,
                })}
                {newPositionTipLabel(groups, index, menuIndex)}
              </Menu.Item>
            );
          }
          return null;
        })}
      </Menu.Group>
      <Menu.Divider />
    </Fragment>
  );
};

const PariPassuMenuItems = ({ form, groups, index }) => {
  return (
    <Fragment>
      <Menu.Group title="Pari Passu with">
        {groups.map((group, menuIndex) => {
          if (index !== menuIndex) {
            const groupNames = getGroupNames(groups);

            return (
              <Menu.Item
                key={keyForGroup(group)}
                onSelect={() => {
                  const oldValues = form.values.fundingSourceGroups;
                  const result = addToGroup(oldValues, index, menuIndex);
                  form.setFieldValue("fundingSourceGroups", result);
                }}
                data-purpose="funding-sources order pari-passu"
              >
                {isBlank(groupNames[menuIndex])
                  ? "(unnamed)"
                  : groupNames[menuIndex]}
              </Menu.Item>
            );
          }
          return null;
        })}
      </Menu.Group>
      <Menu.Divider />
    </Fragment>
  );
};

const SplitMenuItem = ({ form, index }) => {
  return (
    <Fragment>
      <Menu.Item
        onSelect={() => {
          const oldValues = form.values.fundingSourceGroups;
          const result = splitIntoGroups(oldValues, index);
          form.setFieldValue("fundingSourceGroups", result);
        }}
        data-purpose="funding-sources order split"
      >
        Split Group
      </Menu.Item>
      <Menu.Divider />
    </Fragment>
  );
};

const DeleteMenuItem = ({ form, index }) => {
  return (
    <Fragment>
      <Menu.Item
        onSelect={() => {
          const groups = form.values.fundingSourceGroups;

          const fundingSourceUsed = groups[index].find(
            (fundingSource) => fundingSource.disbursedAmount > 0
          );

          if (fundingSourceUsed) {
            form.setFieldValue(
              "errorWarning",
              "Cannot delete funding source(s) which have already have been used on draws"
            );
          } else {
            const result = deleteGroup(groups, index);
            form.setFieldValue("fundingSourceGroups", result);
            form.setFieldValue("errorWarning", "");
          }
        }}
        data-purpose="funding-sources order delete"
      >
        {form.values.fundingSourceGroups[index].length === 1
          ? "Delete Funding Source"
          : "Delete Group"}
      </Menu.Item>
    </Fragment>
  );
};

const GroupMenu = ({ form, allGroups, currentGroup, index, theme }) => (
  <Popover
    position={Position.BOTTOM_LEFT}
    content={
      <Menu>
        {allGroups.length > 1 && (
          <Fragment>
            <MoveToMenuItems form={form} groups={allGroups} index={index} />
            <PariPassuMenuItems form={form} groups={allGroups} index={index} />
          </Fragment>
        )}
        {currentGroup.length > 1 && <SplitMenuItem form={form} index={index} />}
        <DeleteMenuItem form={form} index={index} />
      </Menu>
    }
  >
    <Button
      backgroundColor={theme.colors.gray300}
      color="default"
      iconAfter={CaretDownIcon}
      purpose="funding-sources order close"
      type="button"
    >
      Order
    </Button>
  </Popover>
);

export default withTheme(GroupMenu);
