import { Fragment, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal, Pane, Form, Text, TextInputField } from "components/materials";
import { majorScale } from "helpers/utilities";
import getOptions from "helpers/getOptions";
import { get } from "lodash";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { ADD_DRAW } from "./graphql-queries";

export function AssignAdjustmentToDrawModal({
  availableDraws,
  hasECODocument,
  onClose,
  onConfirmDrawSelect,
  projectId,
  setFieldValue,
}) {
  const [selectedDrawId, setSelectedDrawId] = useState(null);
  const [showCreateNewDraw, setShowCreateNewDraw] = useState(false);
  const [newDrawName, setNewDrawName] = useState("");

  const [addDraw, { loading }] = useMutation(ADD_DRAW, {
    variables: { projectId, name: newDrawName },
    onCompleted: ({ addDraw: { id: newDrawId } }) => {
      onConfirmDrawSelect(newDrawId);
    },
  });

  const createNewDrawOption = {
    key: "createNewDraw",
    text: "Create New Draw...",
    value: "createNewDraw",
  };

  const drawName = get(
    availableDraws.find(({ id }) => id === selectedDrawId),
    "name",
    ""
  );

  const modalProps = showCreateNewDraw
    ? {
        confirmLabel: "Create Draw and Execute",
        title: "Execute Change Order - New Draw",
        onCancel: () => {
          setShowCreateNewDraw(false);
          setSelectedDrawId(null);
          setFieldValue("drawId", null);
        },
        onClose,
        isConfirmDisabled: isBlank(newDrawName),
        isConfirmLoading: loading,
        onConfirm: addDraw,
      }
    : {
        confirmLabel: `Execute Change Order${
          drawName ? ` on ${drawName}` : ""
        }`,
        title: "Execute Change Order",
        onClose,
        isConfirmDisabled: selectedDrawId === null,
        onCancel: () => {
          setFieldValue("drawId", null);
          onClose();
        },
        onConfirm: () => onConfirmDrawSelect(selectedDrawId),
      };

  return (
    <Modal hasFooter open width="40%" {...modalProps}>
      <Pane marginLeft={majorScale(2)}>
        {showCreateNewDraw ? (
          <Fragment>
            <Pane>
              <TextInputField
                label="Draw Name"
                hint={t("drawLockdown.newDraw")}
                onChange={({ target: { value } }) => setNewDrawName(value)}
                width={300}
              />
              {hasECODocument && (
                <Form.Checkbox
                  name="moveDocumentToDraw"
                  label={t("agreementsViewer.moveDocumentToDraw")}
                />
              )}
            </Pane>
          </Fragment>
        ) : (
          <Fragment>
            <Pane>
              <Text marginBottom={majorScale(1)}>
                {t("agreementsViewer.assignAdjustmentToDraw")}
              </Text>
            </Pane>
            <Pane marginTop={majorScale(2)}>
              <Form.Select
                name="drawId"
                onChange={(drawId) => {
                  if (drawId === createNewDrawOption.value) {
                    setSelectedDrawId(null);
                    setShowCreateNewDraw(true);
                  } else {
                    setSelectedDrawId(drawId);
                  }
                }}
                options={getOptions(availableDraws).concat(createNewDrawOption)}
                placeholder="Select or Create a Draw"
                width={300}
              />
              {hasECODocument && (
                <Form.Checkbox
                  name="moveDocumentToDraw"
                  label={t("agreementsViewer.moveDocumentToDraw")}
                />
              )}
            </Pane>
          </Fragment>
        )}
      </Pane>
    </Modal>
  );
}
