import PropTypes from "prop-types";
import { Table } from "components/materials";
import { flatten, values } from "lodash";

export function FastDataTableFooter({
  footerContent,
  showFooterTotals,
  preparedColumns,
  preparedItems,
  utilityColumns,
}) {
  const items = preparedItems.groups
    ? flatten(values(preparedItems.groups))
    : preparedItems;

  return (
    <Table.Foot>
      {showFooterTotals && (
        <Table.Row>
          {utilityColumns.map((utilityColumn) => (
            <Table.TextFooterCell key={utilityColumn.id} />
          ))}
          {preparedColumns.map((preparedColumn) => (
            <Table.TextFooterCell
              cellProps={preparedColumn.cellProps}
              id={preparedColumn.id}
              key={preparedColumn.id}
              testId={preparedColumn.testId}
              textAlign={
                preparedColumn.primary ? "right" : preparedColumn.textAlign
              }
              textProps={preparedColumn.textProps}
              width={preparedColumn.width}
            >
              {preparedColumn.primary
                ? "Total"
                : preparedColumn.aggregate &&
                  preparedColumn.aggregateFormatter(
                    preparedColumn.aggregate(items),
                    items
                  )}
            </Table.TextFooterCell>
          ))}
          <Table.FooterCell />
        </Table.Row>
      )}
      {footerContent}
    </Table.Foot>
  );
}

FastDataTableFooter.propTypes = {
  footerContent: PropTypes.node,
  showFooterTotals: PropTypes.bool,
  preparedColumns: PropTypes.array,
  preparedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  utilityColumns: PropTypes.array,
};

FastDataTableFooter.defaultProps = {};
