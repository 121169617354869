import gql from "graphql-tag";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";

const DOCUMENT_FRAGMENT = gql`
  fragment PaymentDocumentFragment on Document {
    id
    amount
    isBackup
    isGeneralContractor
    hasBeenSubcontractorDirectPay
    hasSoftCosts
    postedAmount
    postedAt
    previousVendorPaymentType
    file {
      name
      url
    }
    recentApprovalReviews {
      id
      userId
      userName
      reviewType
      insertedAt
    }
    state
    type
    updatedSincePosted
    upload {
      id
      toBeSplit
    }
    vendor {
      id
      isPayable
      isPlaceholderVendor
      name
      vendorCostCode
      externalVendorMappings {
        id
        vendorId
        externalVendorId
        externalProjectId
      }
    }
    vendorPaymentType
  }
`;

const INVOICE_FRAGMENT = gql`
  fragment PaymentInvoiceFragment on Invoice {
    number
    lineItems @include(if: $includeLineItems) {
      id
      lineItemId
      budgetLineItem {
        id
        scopeId
        number
      }
      jobCostCodes {
        id
        code
      }
    }
    jobCostCodes @include(if: $includeJobCostCodes) {
      id
      glCode
    }
  }
`;

const PAY_APPLICATION_FRAGMENT = gql`
  fragment PaymentPayApplicationFragment on PayApplication {
    applicationNumber
    lineItems @include(if: $includeLineItems) {
      id
      lineItemId
      budgetLineItem {
        id
        scopeId
        number
      }
      jobCostCodes {
        id
        code
      }
    }
    jobCostCodes @include(if: $includeJobCostCodes) {
      id
      glCode
    }
  }
`;

export const QUERY = gql`
  query DrawPaymentsContextQuery($drawId: String!, $projectId: String!, $includeLineItems: Boolean, $includeJobCostCodes: Boolean) {
    project(id: $projectId) {
      id
      customId
      glCode
      jobCode
      jobPhaseCode
      recentPaymentNote
      documentReviewers {
        id
        position
        user {
          id
          approvalAmountLimit
          approvalAmountMinimum
          fullName
        }
      }
      draw(id: $drawId) {
        id
        payApplications: documents(type: ${DOCUMENT_TYPE_NAME.PAY_APPLICATION}) {
          ...PaymentDocumentFragment
          ...PaymentPayApplicationFragment
        }
        invoices: documents(type: ${DOCUMENT_TYPE_NAME.INVOICE}) {
          ...PaymentDocumentFragment
          ...PaymentInvoiceFragment
        }
      }
    }
  }
  ${INVOICE_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${PAY_APPLICATION_FRAGMENT}
`;

export const RESET_POSTED_DOCUMENTS_MUTATION = gql`
  mutation ResetPostedDocuments($documentIds: [String]!) {
    resetPostedDocuments(documentIds: $documentIds) {
      id
      postedAmount
      postedAt
      updatedSincePosted
    }
  }
`;

export const SAVE_VENDOR_PAYMENT_TYPES = gql`
  mutation SaveVendorPaymentTypes(
    $projectId: String!
    $paymentTypes: [PaymentInput]!
  ) {
    saveVendorPaymentTypes(projectId: $projectId, payments: $paymentTypes) {
      id
      vendorPaymentType
    }
  }
`;
