import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Form, Pane, Select } from "components/materials";
import { DATE_RANGE } from "helpers/dateHelpers";
import { get, map as lodashMap, set } from "lodash";
import { DATE_RANGE_OPTIONS } from "./FastDataTableDateColumn";
import { CustomRangeControl } from "./CustomRangeControl";
import { PreviousXControl } from "./PreviousXControl";
import { SinceControl } from "./SinceControl";

const DATE_RANGE_ELEMENT = {
  [DATE_RANGE.LastXDays]: (props) => (
    <PreviousXControl labels={["Last", "Days"]} {...props} />
  ),
  [DATE_RANGE.PreviousXWeeks]: (props) => (
    <PreviousXControl labels={["Previous", "Full Weeks"]} {...props} />
  ),
  [DATE_RANGE.PreviousXMonths]: (props) => (
    <PreviousXControl labels={["Previous", "Full Months"]} {...props} />
  ),
  [DATE_RANGE.PreviousXYears]: (props) => (
    <PreviousXControl labels={["Previous", "Full Years"]} {...props} />
  ),
  [DATE_RANGE.NextXDays]: (props) => (
    <PreviousXControl labels={["Next", "Days"]} {...props} />
  ),
  [DATE_RANGE.NextXWeeks]: (props) => (
    <PreviousXControl labels={["Next", "Weeks"]} {...props} />
  ),
  [DATE_RANGE.NextXMonths]: (props) => (
    <PreviousXControl labels={["Next", "Months"]} {...props} />
  ),
  [DATE_RANGE.NextXQuarters]: (props) => (
    <PreviousXControl labels={["Next", "Quarters"]} {...props} />
  ),
  [DATE_RANGE.NextXYears]: (props) => (
    <PreviousXControl labels={["Next", "Years"]} {...props} />
  ),
  [DATE_RANGE.Since]: (props) => <SinceControl {...props} />,
  [DATE_RANGE.CustomRange]: (props) => <CustomRangeControl {...props} />,
};

export const DateFilter = ({ fieldPath }) => {
  const [rangeType, setRangeType] = useState();

  const { setValues, values } = useFormikContext();
  const { key, type } = get(values, fieldPath, {});

  // Sync the type selector to initial, existing value
  useEffect(() => {
    setRangeType(type || DATE_RANGE.CustomRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rangeOptions = lodashMap(DATE_RANGE_OPTIONS, (label, value) => ({
    key: value,
    label,
    value,
  }));

  const rangeElement = DATE_RANGE_ELEMENT[rangeType] || (() => null);

  const onRangeTypeChange = (event) => {
    const newValues = set(values, fieldPath, {
      finish: "",
      key,
      start: "",
      type: event.target.value,
      value: "",
    });

    setValues(newValues);
    setRangeType(event.target.value);
  };

  return (
    <Pane
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      width={375}
    >
      <Form.Field>
        <Select
          onChange={onRangeTypeChange}
          options={rangeOptions}
          value={rangeType}
          minWidth={150}
        >
          {rangeOptions.map(({ label, ...rest }) => (
            <option {...rest}>{label}</option>
          ))}
        </Select>
      </Form.Field>
      {rangeElement({ fieldPath })}
    </Pane>
  );
};
