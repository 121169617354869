import { ZoomInIcon, ZoomOutIcon } from "evergreen-ui";
import {
  Button,
  IconButton,
  Link,
  Pane,
  Slider,
  Text,
} from "components/materials";
import { get } from "lodash";
import { majorScale, minorScale, useTheme } from "helpers/utilities";
import { add, subtract } from "helpers/math";
import t from "helpers/translate";

export default function Header({
  loading,
  onClose,
  onSubmit,
  scale,
  setScale,
  upload,
}) {
  const theme = useTheme();

  return (
    <Pane
      alignItems="center"
      background="white"
      borderBottom
      boxShadow="0px 0px 5px 0px rgba(149, 161, 174, 0.39)"
      display="flex"
      height={56}
      paddingX={majorScale(4)}
      position="absolute"
      top={0}
      width="100%"
      zIndex={5}
    >
      <Pane flexGrow={1}>
        <Text
          fontWeight={theme.fontWeights.MEDIUM}
          fontSize={16}
          marginRight={majorScale(1)}
        >
          {get(upload, "file.name")}
        </Text>
        <Link purpose="splitter help" href={t("splitterHelp.learnMoreLink")}>
          {t("splitterHelp.learnMoreDescription")}
        </Link>
      </Pane>
      <IconButton
        appearance="minimal"
        icon={ZoomOutIcon}
        marginX={minorScale(1)}
        onClick={() => {
          if (scale < 10) {
            setScale(0);
          } else {
            setScale(subtract(scale, 10));
          }
        }}
        type="button"
      />
      <Slider onChange={setScale} value={scale} />
      <IconButton
        appearance="minimal"
        icon={ZoomInIcon}
        marginX={minorScale(1)}
        onClick={() => {
          if (scale > 90) {
            setScale(100);
          } else {
            setScale(add(scale, 10));
          }
        }}
        type="button"
      />
      <Button isLoading={loading} marginLeft={minorScale(3)} onClick={onClose}>
        Close
      </Button>
      <Button
        appearance="primary"
        isLoading={loading}
        marginLeft={minorScale(3)}
        onClick={onSubmit}
      >
        Split
      </Button>
    </Pane>
  );
}
