import isBlank from "helpers/isBlank";

export default function formatPhoneNumber(phoneNumberString) {
  if (isBlank(phoneNumberString)) return "--";
  const match = phoneNumberString.match(/(\d{3})\D*(\d{3})\D*(\d{4})/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return "--";
}
