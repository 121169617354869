import { Fragment, useContext, useEffect } from "react";
import { Formik } from "formik";
import { get, isFunction } from "lodash";
import { AssignAdjustmentToDrawModal } from "components/containers/Agreements";
import { Confirm, Paragraph } from "components/materials";
import { DocumentContext } from "contexts/documentContext";
import {
  DocumentFormContext,
  useDocumentFormContext,
} from "contexts/documentFormContext";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { autoCalculateLineItemRetainage } from "helpers/retainageLineItemHelpers";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { usePerformik } from "hooks/usePerformik";

const noOp = () => {};

function Performik() {
  const { initialValues, handleSubmit, validate } = useContext(
    DocumentFormContext
  );
  const form = usePerformik({
    initialValues,
    handleSubmit,
    resetFormOnChangingInitialValues: true,
    validate,
  });

  return <DocumentForm form={form} />;
}

export function DocumentReview() {
  const {
    handleSubmit,
    initialValues,
    ReviewForm,
    selectedDrawId,
    selectedType,
    usePerformik,
  } = useDocumentFormContext();

  const { hasPermission } = useContext(UserContext);
  const hasEditPermission = hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT);

  return usePerformik ? (
    <Performik key={`${selectedType}-${selectedDrawId}`} />
  ) : (
    <Formik
      key={`${selectedType}-${selectedDrawId}`}
      // todo change orders make sure there wasn't a reason enableReinitialize wasn't active prop
      enableReinitialize
      initialValues={{
        ...initialValues,
        __disabled: !hasEditPermission,
      }}
      onSubmit={handleSubmit}
      validate={(values) =>
        isFunction(ReviewForm.validate)
          ? ReviewForm.validate(values, hasPermission)
          : noOp()
      }
    >
      {(form) => {
        return <DocumentForm form={form} />;
      }}
    </Formik>
  );
}

function DocumentForm({ form }) {
  const {
    addGeneralContractorMutation: [addGeneralContractor],
    agreementVendorLineItems,
    assignUserToDocumentsMutation,
    commentMutation,
    disableWarnings,
    document,
    documentReviewers,
    drawId,
    draws,
    getProjectVendorSearchQuery,
    getUnconditionalWaiverQuery,
    invoiceNumbersByVendor,
    isProcessing,
    jobCostCodes,
    lineItems,
    // eslint-disable-next-line
    markReviewedMutation: [_onMarkReviewed, markReviewedResult],
    openLineItemDrawer,
    projectRules,
    searchedVendors,
    setOpenLineItemDrawer,
    unconditionalWaiverQuery,
    users,
    vendors,
  } = useContext(DocumentContext);
  const {
    assignAdjustmentToDrawModalOpen,
    cancelParsing,
    cannotChangeTypeHasAdjustmentModalOpen,
    cardsOpen,
    collapsedPanels,
    confirmChangeAgreementTypeModalOpen,
    documentIgnored,
    handleApprove,
    handleChangeType,
    handleDelete,
    handleIgnore,
    handleMarkPaid,
    handleMarkUnpaid,
    handleMarkReviewed,
    handleUndoApprove,
    incompatibleImageModalOpen,
    mutationLoading,
    newlyAddedVendors,
    projectDrawUpdateSource,
    projectId,
    projectName,
    ReviewForm,
    selectedDraw,
    selectedType,
    setAssignAdjustmentToDrawModalOpen,
    setCancelParsing,
    setCannotChangeTypeHasAdjustmentModalOpen,
    setCardsOpen,
    setConfirmChangeAgreementTypeModalOpen,
    setIncompatibleImageModalOpen,
    setNewlyAddedVendors,
    setSelectedDrawId,
    setShowUserLockdownDrawConfirm,
    setViewerDirty,
    setWillDeleteAdjustmentModalOpen,
    showUserLockdownDrawConfirm,
    togglePanel,
    willDeleteAdjustmentModalOpen,
  } = useDocumentFormContext();

  const dirty =
    form.dirty ||
    get(form.values, "draw.id") !== get(document, "draw.id") ||
    get(form.values, "type") !== get(document, "type");

  function getAutocalculatedLineItemRetainage(amount, lineItemObject) {
    return autoCalculateLineItemRetainage(
      agreementVendorLineItems,
      amount,
      lineItemObject,
      selectedDraw,
      form.values.vendor
    );
  }

  useEffect(() => {
    setViewerDirty(dirty);
  }, [dirty, setViewerDirty]);

  return (
    <Fragment>
      <ReviewForm
        addGeneralContractor={addGeneralContractor}
        allAvailableVendors={vendors.concat(newlyAddedVendors)}
        agreementVendorLineItems={agreementVendorLineItems}
        assignUserToDocumentsMutation={assignUserToDocumentsMutation}
        cardsOpen={cardsOpen}
        cancelParsing={cancelParsing}
        collapsedPanels={collapsedPanels}
        commentMutation={commentMutation}
        disableWarnings={disableWarnings || documentIgnored}
        dirty={dirty}
        document={document}
        documentIgnored={documentIgnored}
        documentMissingInformation={
          ReviewForm.isMissingInformation
            ? ReviewForm.isMissingInformation(form.values)
            : false
        }
        documentReviewers={documentReviewers}
        drawId={drawId}
        draws={draws}
        form={form}
        getAutocalculatedLineItemRetainage={getAutocalculatedLineItemRetainage}
        getProjectVendorSearchQuery={getProjectVendorSearchQuery}
        getUnconditionalWaiverQuery={getUnconditionalWaiverQuery}
        handleApprove={handleApprove}
        handleMarkPaid={handleMarkPaid}
        handleMarkUnpaid={handleMarkUnpaid}
        handleMarkReviewed={handleMarkReviewed}
        handleUndoApprove={handleUndoApprove}
        handleCancelParsing={() => setCancelParsing(true)}
        invoiceNumbersByVendor={invoiceNumbersByVendor}
        isProcessing={isProcessing}
        jobCostCodes={jobCostCodes}
        lineItems={lineItems}
        markReviewedResult={markReviewedResult}
        mutationLoading={mutationLoading}
        newlyAddedVendors={newlyAddedVendors}
        onChangeDraw={setSelectedDrawId}
        onChangeType={handleChangeType}
        onDelete={handleDelete}
        onIgnore={handleIgnore}
        openLineItemDrawer={openLineItemDrawer}
        projectId={projectId}
        projectName={projectName}
        projectRules={projectRules}
        projectDrawUpdateSource={projectDrawUpdateSource}
        searchedVendors={searchedVendors}
        selectedDraw={selectedDraw}
        selectedType={selectedType}
        setCardsOpen={setCardsOpen}
        setNewlyAddedVendors={setNewlyAddedVendors}
        setOpenLineItemDrawer={setOpenLineItemDrawer}
        setShowUserLockdownDrawConfirm={setShowUserLockdownDrawConfirm}
        showUserLockdownDrawConfirm={showUserLockdownDrawConfirm}
        suggestedVendors={get(document, "suggestedVendors", [])}
        togglePanel={togglePanel}
        unconditionalWaiverQuery={unconditionalWaiverQuery}
        users={users}
        vendors={vendors}
      />
      {incompatibleImageModalOpen && (
        <Confirm
          content={t("documentWarnings.modal.incompatibleImageType")}
          hasCancel={false}
          header={t("documentWarnings.title.incompatibleImageType")}
          hideViewer
          onCloseComplete={() => setIncompatibleImageModalOpen(false)}
          open
          confirmLabel="OK"
        />
      )}
      {assignAdjustmentToDrawModalOpen && (
        <AssignAdjustmentToDrawModal
          availableDraws={draws.filter(({ isLockedDown }) => !isLockedDown)}
          onConfirmDrawSelect={(drawId) => {
            setAssignAdjustmentToDrawModalOpen(false);
            form.setFieldValue("budgetAdjustmentDrawId", drawId);
            form.handleSubmit();
          }}
          onClose={() => setAssignAdjustmentToDrawModalOpen(false)}
          projectId={projectId}
          setFieldValue={form.setFieldValue}
        />
      )}
      {cannotChangeTypeHasAdjustmentModalOpen && (
        <Confirm
          confirmLabel="OK"
          content={
            <Fragment>
              <Paragraph marginBottom={majorScale(2)}>
                {t("documentWarnings.modal.cannotChangeTypeWithAdjustment")}
              </Paragraph>
              <Paragraph>
                {t(
                  "documentWarnings.modal.cannotChangeTypeWithAdjustmentSecondary"
                )}
              </Paragraph>
            </Fragment>
          }
          hasCancel={false}
          onCloseComplete={() => {
            handleChangeType(document.type);
            setCannotChangeTypeHasAdjustmentModalOpen(false);
          }}
          open
          title="Change Document Type"
        />
      )}
      {confirmChangeAgreementTypeModalOpen && (
        <Confirm
          content={t(
            "documentWarnings.modal.changeAgreementTypeWillDisassociate",
            {
              oldType: t(`agreementType.${document.type}`),
            }
          )}
          hasClose={false}
          header={t("documentWarnings.title.changeAgreementType")}
          onCancel={() => {
            handleChangeType(document.type);
            setConfirmChangeAgreementTypeModalOpen(false);
          }}
          onConfirm={() => setConfirmChangeAgreementTypeModalOpen(false)}
          open
          cancelLabel="Undo"
          confirmLabel="Continue"
        />
      )}
      {willDeleteAdjustmentModalOpen && (
        <Confirm
          content={t(
            "documentWarnings.modal.changeAgreementTypeWillDeleteAdjustment"
          )}
          hasClose={false}
          header={t("documentWarnings.title.changeAgreementType")}
          onCancel={() => {
            handleChangeType(document.type);
            setWillDeleteAdjustmentModalOpen(false);
          }}
          onConfirm={() => setWillDeleteAdjustmentModalOpen(false)}
          open
          cancelLabel="Undo"
          confirmLabel="Continue"
        />
      )}
    </Fragment>
  );
}
