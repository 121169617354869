import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { ErrorIcon, FullCircleIcon, ShareIcon } from "evergreen-ui";
import {
  Button,
  Divider,
  Grid,
  Link,
  Modal,
  Pane,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";

export function MissingFundingSources({
  fundedDraws,
  isModal = false,
  projectId,
  projectSettings = false,
  usesOfFundsOn,
}) {
  const [modalOpen, setModalOpen] = useState(isModal);

  const headerContext = () => {
    if (projectSettings && usesOfFundsOn) {
      return <Text>{t("fundingSources.usesOfFundsOn")}</Text>;
    }
    if (projectSettings && !usesOfFundsOn) {
      return <Text>{t("fundingSources.usesOfFundsOff")}</Text>;
    }
    return <Text>Update funding sources on funded draws</Text>;
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const renderTrigger = () => (
    <Button
      purpose="missing-funding-sources open"
      appearance="minimal"
      onClick={openModal}
    >
      {t("fundingSources.missingDrawLine3")}
    </Button>
  );

  return (
    <Fragment>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        trigger={!isModal && renderTrigger()}
        title={headerContext()}
      >
        <Pane width={majorScale(65)}>
          <Modal.Content>
            <Pane>
              {projectSettings && (
                <Fragment>
                  <Text>
                    Funding source information has been added to this project.
                  </Text>
                  <Divider />
                  <Text>
                    Funding sources cannot be configured on active draws until
                    all funded draws have been configured.
                  </Text>
                  <Divider />
                </Fragment>
              )}
              <Text>Please fill out funding sources on all funded draws.</Text>
              <Divider />
              <Text>Funded Draws:</Text>
              <Divider />
            </Pane>
          </Modal.Content>
          <Grid justifyContent="center">
            <Grid.Row>
              <Grid.Column columns={6} />
              <Grid.Column columns={10} paddingLeft={majorScale(2)}>
                <Text size={400}>Funding Source Status</Text>
              </Grid.Column>
            </Grid.Row>
            {fundedDraws.map((draw, index) => (
              <Grid.Row key={index}>
                <Grid.Column columns={6} textAlign="right">
                  <Link
                    underline
                    to={`/projects/${projectId}/draws/${draw.id}/funding_sources`}
                    marginRight={minorScale(1)}
                    purpose="missing-funding-sources draw"
                  >
                    {draw.name}
                  </Link>
                  <ShareIcon color="info" />
                </Grid.Column>
                <Grid.Column columns={10} paddingLeft={majorScale(2)}>
                  {draw.fundingConfirmed ? (
                    <Pane display="flex" alignItems="center">
                      <FullCircleIcon
                        color="success"
                        marginRight={minorScale(1)}
                      />
                      <Text size={400}>Complete</Text>
                    </Pane>
                  ) : (
                    <Pane display="flex" alignItems="center">
                      <ErrorIcon color="danger" marginRight={minorScale(1)} />
                      <Text size={400}>Incomplete</Text>
                    </Pane>
                  )}
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Pane>
        <Modal.Actions>
          <Pane display="flex" justifyContent="flex-end">
            <Button
              content="Close"
              onClick={closeModal}
              purpose="missing-funding-sources close"
            />
          </Pane>
        </Modal.Actions>
        <Divider />
      </Modal>
    </Fragment>
  );
}

MissingFundingSources.propTypes = {
  fundedDraws: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
  usesOfFundsOn: PropTypes.bool,
};
