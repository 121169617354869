import { useContext, useState, useEffect, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Form, Pane, Text } from "components/materials";
import { debounce, values } from "lodash";
import { UserContext } from "helpers/behaviors";
import {
  ADDRESS_STATE,
  ORGANIZATION_TYPE,
  PERMISSION_ACTION,
} from "helpers/enums";
import t from "helpers/translate";
import { majorScale, minorScale } from "helpers/utilities";
import { getColumns } from "./columns";
import { SelectExistingOrganization } from "./SelectExistingOrganization";

const getTypeOptions = () =>
  values(ORGANIZATION_TYPE).map((name) => ({
    key: name,
    text: t(`organizationType.${name}`),
    value: name,
  }));

const nullState = { key: "blank", value: undefined, text: "" };

const stateOptions = [nullState].concat(
  values(ADDRESS_STATE).map((stateValue) => ({
    key: stateValue,
    value: stateValue,
    text: stateValue,
  }))
);

export function CreateOrganizationForm({
  formikProps,
  mutationResultProps,
  getSearchData,
  getFilters,
  onSelect,
  organizationId,
  vendorCount,
  vendors,
}) {
  const { hasPermission } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const filters = getFilters(formikProps.values);
  const { requiredWhenPayable } = formikProps.errors;

  useEffect(() => {
    const searchQuery = debounce(() => {
      getSearchData({
        variables: {
          organizationId,
          filters,
          pagination: { limit: 10, page },
        },
      });
    }, 250);
    searchQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formikProps.values.city,
    formikProps.values.name,
    formikProps.values.stateValue,
    formikProps.values.vendorCostCode,
    formikProps.values.zip,
    page,
  ]);

  const columns = useMemo(() => getColumns(onSelect), [onSelect]);

  const hasAvidAP = hasPermission(PERMISSION_ACTION.AVID_AP_INTEGRATION);

  const hasVisionDeltekAP = hasPermission(
    PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION
  );

  return (
    <Form data-testid="create-org-form">
      <Text size={600}>Create a new organization</Text>

      <Pane marginY={majorScale(2)}>
        {requiredWhenPayable && (
          <Alert marginBottom={majorScale(2)} intent="danger">
            {t("createOrganization.requiredWhenPayable")}
          </Alert>
        )}
        <Form.Group>
          <Form.Input label="Organization Name" name="name" />
          <Form.Input label="Contact Email" name="email" />
          <Form.Input
            label="Contact Phone Number"
            name="phoneNumber"
            type="tel"
          />
        </Form.Group>
        <Form.Group aligned renderedChildrenDirectly>
          <Pane width="35%">
            <Form.Input label="Street Address" name="streetAddress" />
          </Pane>
          <Pane width="30%">
            <Form.Input label="City" name="city" />
          </Pane>
          <Pane width={100}>
            <Form.Select
              width={100}
              label="State/Province"
              name="stateValue"
              options={stateOptions}
              popoverMinWidth={85}
              fullBorder
            />
          </Pane>
          <Pane width="30%">
            <Form.Input label="Postal Code" name="zip" type="zip" />
          </Pane>
        </Form.Group>
        <Form.Group aligned renderedChildrenDirectly>
          {hasPermission(PERMISSION_ACTION.VENDOR_COST_CODES) && (
            <Pane width="35%">
              <Form.Input label="Vendor ID" name="vendorCostCode" />
            </Pane>
          )}
          <Pane width="35%">
            <Form.Select
              width={200}
              label="Type"
              name="type"
              options={getTypeOptions()}
            />
          </Pane>
          {(hasAvidAP || hasVisionDeltekAP) && (
            <Fragment>
              <Pane width="15%" marginTop={minorScale(3)}>
                <Form.Checkbox label={<Text>1099?</Text>} name="is1099" />
              </Pane>
              <Pane width="15%" marginTop={minorScale(3)}>
                <Form.Checkbox label={<Text>Payable?</Text>} name="isPayable" />
              </Pane>
            </Fragment>
          )}
        </Form.Group>
      </Pane>
      <Pane marginY={majorScale(2)}>
        <Form.SubmitButton
          isLoading={mutationResultProps.loading}
          label="Create New Organization"
          onClick={formikProps.handleSubmit}
          purpose="organization create submit"
        />
      </Pane>
      <SelectExistingOrganization
        vendors={vendors}
        vendorCount={vendorCount}
        columns={columns}
        onPageChanged={setPage}
      />
      <Pane display="flex" justifyContent="flex-end" marginTop={majorScale(2)}>
        <Pane>
          <Button
            purpose="organization create cancel"
            onClick={formikProps.handleReset}
            marginRight={majorScale(2)}
          >
            Cancel
          </Button>
        </Pane>
      </Pane>
    </Form>
  );
}

CreateOrganizationForm.propTypes = {
  formikProps: PropTypes.shape({
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  mutationResultProps: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  onSelect: PropTypes.func,
};

CreateOrganizationForm.defaultProps = {
  onSelect: () => {},
};
