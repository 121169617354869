import gql from "graphql-tag";

export default gql`
  fragment BudgetAdjustmentFragment on BudgetAdjustment {
    id
    comment
    transactions {
      id
      amount
      lineItem {
        id
        scopeId
        name
        division {
          id
          scopeId
          name
        }
      }
    }
  }
`;
