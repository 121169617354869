import { Fragment } from "react";
import { Pane } from "components/materials";

const containerStyles = {
  position: "relative",
  overflow: "hidden",
};

const dimmerStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  zIndex: "100",
};

const Dimmer = ({ children, active, darker }) => {
  const overlayProps =
    (active && {
      background: "#7f8f9f",
      zIndex: "99",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      opacity: darker ? "0.5" : "0.1",
    }) ||
    {};

  return (
    <Fragment>
      <Pane {...overlayProps} />
      <Pane {...dimmerStyles}>{children}</Pane>
    </Fragment>
  );
};

const Dimmable = ({ children }) => {
  return (
    <Pane {...containerStyles}>
      <Pane>{children}</Pane>
    </Pane>
  );
};

// TODO: remove dot notation
Dimmer.Dimmable = Dimmable;

export default Dimmer;
