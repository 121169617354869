import PropTypes from "prop-types";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import DocumentReviewLayout from "./DocumentReviewLayout";

const getInitialValues = ({ document, selectedDraw, selectedType }) => {
  const {
    comments,
    documentReviewActivity,
    reviews,
    ...restOfDocument
  } = document;
  return {
    ...restOfDocument,
    draw: selectedDraw,
    type: selectedType,
  };
};

const getDocumentDataFromForm = (values) => {
  return {
    isBackup: values.isBackup,
  };
};

function GenericReview(props) {
  return <DocumentReviewLayout disableInformation {...props} />;
}

GenericReview.propTypes = {
  children: PropTypes.node,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
    }),
  }),
  onSubmit: PropTypes.func,
  onApprove: PropTypes.func,
  onComment: PropTypes.func,
  onChangeDraw: PropTypes.func,
  onChangeType: PropTypes.func,
};

// TODO: remove dot notation
GenericReview.getInitialValues = getInitialValues;
GenericReview.getDocumentDataFromForm = getDocumentDataFromForm;

export default GenericReview;
