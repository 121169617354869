import { Fragment, useContext, useRef } from "react";
import PropTypes from "prop-types";
import {
  Link as RouterLink,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { matchPath, useLocation } from "react-router";
import { CaretDownIcon, ChevronRightIcon, LightbulbIcon } from "evergreen-ui";
import { useFeatureFlags } from "FeatureFlags";
import { DrawHeader, SubmissionActions } from "components/containers";
import { UploadProgress } from "features/uploads";
import { isInternetExplorer } from "helpers/browserHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import { HelpButton } from "components/templates";
import {
  Button,
  Heading,
  IconButton,
  Menu,
  Pane,
  Popover,
  RedesignLayout,
  Text,
} from "components/materials";
import {
  Position,
  majorScale,
  minorScale,
  ThemeContext,
} from "helpers/utilities";
import { formatShortenedCurrency } from "helpers/formatCurrency";
import { getSearchByKey } from "helpers/queryStringHelpers";
import { mergeIntoTheme } from "helpers/themeHelpers";
import { getButtonProjectIcon } from "helpers/projectIconHelpers";
import { camelCase, isEqual, some, find, get } from "lodash";
import { css } from "glamor";
import { poweredByRabbet } from "images";
import { PendingInvitationLink } from "./PendingInvitationLink";

function getNavBarProjectName({ name, customId }) {
  const customIdString = customId ? ` (${customId})` : "";
  return `${name}${customIdString}`;
}

const userMenu = (match, theme) => ({ close }) => {
  const { menuSelectBackgroundColor, menuSelectTextColor } = theme.tableColors;
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item
          is={RouterLink}
          onSelect={close}
          style={
            match && match.path.includes("/admin")
              ? {
                  backgroundColor: menuSelectBackgroundColor,
                  cursor: "default",
                  pointerEvents: "none",
                }
              : undefined
          }
          textDecoration="none"
          to="/admin"
        >
          <Text color={menuSelectTextColor} fontSize={12} fontWeight={500}>
            Admin
          </Text>
        </Menu.Item>
        <Menu.Item
          is={RouterLink}
          onSelect={close}
          style={
            match && match.path.includes("/notifications")
              ? {
                  backgroundColor: menuSelectBackgroundColor,
                  cursor: "default",
                  pointerEvents: "none",
                }
              : undefined
          }
          textDecoration="none"
          to="/notifications"
        >
          <Text color={menuSelectTextColor} fontSize={12} fontWeight={500}>
            Notifications
          </Text>
        </Menu.Item>
        <Menu.Item
          is={RouterLink}
          onSelect={close}
          style={
            match && match.path.includes("/logout")
              ? {
                  backgroundColor: menuSelectBackgroundColor,
                  cursor: "default",
                  pointerEvents: "none",
                }
              : undefined
          }
          textDecoration="none"
          to="/logout"
        >
          <Text color={menuSelectTextColor} fontSize={12} fontWeight={500}>
            Logout
          </Text>
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

const AppLayout = ({
  children,
  organizations,
  pendingInvitations,
  recentProjectsQuery,
  fetchRecentProjects,
  useGuestTheme,
  userEmail,
  userPermissions,
}) => {
  const history = useHistory();
  const location = useLocation();
  const featureFlags = useFeatureFlags();
  const useNewPortfolioInsights = featureFlags.isEnabled(
    "use-redesigned-portfolio-insights"
  );
  const useNewUploads = featureFlags.isEnabled("use-upload-refactor");
  const recentProjects = get(recentProjectsQuery, "data.projects", []);
  const pendingInvitation = pendingInvitations[0];

  function atLeastOneOrgHasPermissionOn(permission) {
    return some(organizations, [
      `permissionConfig.${camelCase(permission)}`,
      true,
    ]);
  }

  // TODO: Will remove this once we have completely converted over to the new portfolio insights
  function getPortfolioInsightsPath() {
    if (
      useNewPortfolioInsights &&
      atLeastOneOrgHasPermissionOn(PERMISSION_ACTION.ADVANCED_REPORT)
    ) {
      return "/reports";
    }

    if (
      atLeastOneOrgHasPermissionOn(PERMISSION_ACTION.ADVANCED_REPORT_LENDER)
    ) {
      return "/reports/lender";
    }

    return "/reports/developer";
  }

  const getOrgProperties = (path) => {
    if (organizations.length === 1) {
      return organizations[0];
    }

    if (
      path &&
      (path === "/" ||
        path.includes("/organizations") ||
        path.includes("/reports") ||
        path.includes("/admin") ||
        path.includes("/notifications"))
    ) {
      return { name: "Home" };
    }

    if (path && path.includes("/projects")) {
      const { params } = matchPath(path, {
        path: "/projects/:projectId",
      });
      const project = find(recentProjects, ["id", get(params, "projectId")]);
      return project
        ? organizations.find((org) => org.id === project.organization.id)
        : { name: "Home" };
    }

    return { name: "Home" };
  };

  const {
    name: orgName,
    headerColor,
    primaryColor,
    primaryLogo,
  } = getOrgProperties(location.pathname);

  const colors = useRef({});
  const theme = useContext(ThemeContext);

  // if theme was imported in parent component (for guest users), skip
  if (!useGuestTheme) {
    // || clause below defaults back to Rabbet colors for multi-org users when navigating back to Home page
    // or to an org without cobranding value(s) when theme values have already been mutated
    const incomingColors = {
      headerColor: headerColor || theme.rabbetHeaderColor,
      primaryColor: primaryColor || theme.rabbetPrimaryColor,
      primaryLogo,
    };
    if (!isEqual(colors.current, incomingColors)) {
      colors.current = { ...incomingColors };
      mergeIntoTheme(theme, incomingColors);
    }
  }

  const {
    headerButtonTextColor,
    headerIconButtonColor,
    headerIconColor,
    headerLinkActive,
    headerLinkActiveBoxShadow,
    headerLinkActiveHover,
    headerLinkInactiveHover,
    headerTextOnHoverColor,
  } = theme.defaultColors;
  const {
    menuSelectAltTextColor,
    menuSelectBackgroundColor,
    menuSelectTextColor,
  } = theme.tableColors;
  const cobrandedLogo = theme.primaryLogo;
  // Until https://github.com/segmentio/evergreen/blob/469a339/src/menu/src/MenuItem.js#L126-L128
  // is updated to *not* use flex={1}, we need to change how the menu item itself is styled
  const ieMenuItemProps = isInternetExplorer
    ? { display: "block", marginTop: 5 }
    : {};

  const renderProject = (projectRoute) => {
    if (projectRoute.match.params.projectId === "new") return null;
    if (recentProjectsQuery.loading) return null;
    const { projectId } = projectRoute.match.params;
    const project = find(recentProjects, ["id", projectId]);
    if (!project) {
      fetchRecentProjects();
      return null;
    }
    const projectsMenu = ({ close }) => (
      <Menu>
        <Menu.Group title="Recent Projects">
          {recentProjects.map((p) => (
            <Menu.Item
              intent="default"
              is={RouterLink}
              key={p.id}
              onSelect={close}
              style={
                p.id === project.id
                  ? {
                      backgroundColor: menuSelectBackgroundColor,
                      cursor: "default",
                      pointerEvents: "none",
                    }
                  : undefined
              }
              textDecoration="none"
              to={`/projects/${p.id}`}
              {...ieMenuItemProps}
            >
              <Text color={menuSelectTextColor} fontSize={12} fontWeight={500}>
                {p.name}
              </Text>
            </Menu.Item>
          ))}
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>
          <Menu.Item
            intent="default"
            is={RouterLink}
            onSelect={close}
            textDecoration="none"
            to="/"
          >
            <Text color={menuSelectAltTextColor} fontSize={12} fontWeight={500}>
              See All
            </Text>
          </Menu.Item>
        </Menu.Group>
      </Menu>
    );

    const renderDraw = (drawRoute) => {
      if (drawRoute.match.params.drawId === "new") return null;
      const draws = get(project, "draws", []);
      const draw = find(draws, ["id", drawRoute.match.params.drawId]);
      const storeTableConfig = drawRoute.match.isExact;
      const tableConfig = getSearchByKey(drawRoute.history, "table");
      if (!draw) {
        fetchRecentProjects();
        return null;
      }
      const drawsMenu = ({ close }) => (
        <Menu>
          <Pane maxHeight={majorScale(33)} overflowY="auto">
            <Menu.Group>
              {draws.map((d) => (
                <Menu.Item
                  intent="default"
                  is={RouterLink}
                  key={d.id}
                  onSelect={close}
                  style={
                    d.id === draw.id
                      ? {
                          backgroundColor: menuSelectBackgroundColor,
                          cursor: "default",
                          pointerEvents: "none",
                        }
                      : undefined
                  }
                  textDecoration="none"
                  to={
                    storeTableConfig && tableConfig
                      ? `/projects/${project.id}/draws/${d.id}?table=${tableConfig}`
                      : `/projects/${project.id}/draws/${d.id}`
                  }
                >
                  <Text
                    color={menuSelectTextColor}
                    fontSize={12}
                    fontWeight={500}
                  >
                    {`${d.name} - ${formatShortenedCurrency(
                      d.requestedAmount
                    )}`}
                  </Text>
                </Menu.Item>
              ))}
            </Menu.Group>
          </Pane>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item
              intent="default"
              is={RouterLink}
              onSelect={close}
              textDecoration="none"
              to={`/projects/${project.id}/draws/new`}
            >
              <Text
                color={menuSelectAltTextColor}
                fontSize={12}
                fontWeight={500}
              >
                New Draw
              </Text>
            </Menu.Item>
          </Menu.Group>
        </Menu>
      );

      return (
        <Fragment>
          <ChevronRightIcon
            color={headerIconColor}
            marginX={minorScale(1)}
            size={14}
          />
          <Popover content={drawsMenu} position={Position.BOTTOM_LEFT}>
            <Button
              data-testid="navbar-draw-name"
              appearance="minimal"
              color={headerButtonTextColor}
              fontSize={15}
              fontWeight={800}
              iconAfter={CaretDownIcon}
              id="drawButton"
              isActive
              purpose="nav draw"
              style={{ backgroundColor: headerLinkActive }}
              {...css({
                "&#drawButton:focus": {
                  boxShadow: headerLinkActiveBoxShadow,
                },
              })}
            >
              {get(draw, "name")}
            </Button>
          </Popover>
        </Fragment>
      );
    };

    return (
      <Route path={`${projectRoute.match.path}/draws/:drawId`}>
        {(drawRoute) =>
          drawRoute.match ? (
            <Fragment>
              {!cobrandedLogo && (
                <ChevronRightIcon
                  color={headerIconColor}
                  marginX={minorScale(1)}
                  size={14}
                />
              )}
              <Button
                appearance="minimal"
                color={headerButtonTextColor}
                data-testid="navbar-project-name"
                purpose="nav project"
                fontSize={15}
                fontWeight={500}
                iconBefore={getButtonProjectIcon(get(project, "template.icon"))}
                id="navLink"
                is={RouterLink}
                to={projectRoute.match.url}
                {...css({
                  "&#navLink:hover": {
                    backgroundColor: headerLinkInactiveHover,
                    color: headerTextOnHoverColor,
                  },
                  "&#navLink:focus": {
                    boxShadow: headerLinkActiveBoxShadow,
                  },
                })}
              >
                {getNavBarProjectName(project)}
              </Button>
              {renderDraw(drawRoute)}
            </Fragment>
          ) : (
            <Fragment>
              {!cobrandedLogo && (
                <ChevronRightIcon
                  color={headerIconColor}
                  marginX={minorScale(1)}
                  size={14}
                />
              )}
              <Popover content={projectsMenu} position={Position.BOTTOM_LEFT}>
                <Button
                  appearance="minimal"
                  color={headerButtonTextColor}
                  data-testid="navbar-project-name"
                  fontSize={15}
                  fontWeight={800}
                  iconAfter={CaretDownIcon}
                  iconBefore={getButtonProjectIcon(
                    get(project, "template.icon")
                  )}
                  id="projectNavLink"
                  purpose="nav project"
                  isActive
                  style={{
                    backgroundColor: headerLinkActive,
                  }}
                  {...css({
                    "&#projectNavLink:hover": {
                      backgroundColor: headerLinkInactiveHover,
                      color: headerTextOnHoverColor,
                    },
                    "&#projectNavLink:focus": {
                      boxShadow: headerLinkActiveBoxShadow,
                    },
                  })}
                >
                  {getNavBarProjectName(project)}
                </Button>
              </Popover>
            </Fragment>
          )
        }
      </Route>
    );
  };

  return (
    <RedesignLayout>
      <RedesignLayout.Nav orgName={orgName}>
        {!cobrandedLogo && orgName && (
          <Button
            appearance="minimal"
            color={headerButtonTextColor}
            fontSize={15}
            fontWeight={500}
            id="navLink"
            data-testid="navbar-org-name"
            purpose="nav organization"
            is={RouterLink}
            to="/"
            {...css({
              "&#navLink:hover": {
                backgroundColor: headerLinkInactiveHover,
                color: headerTextOnHoverColor,
              },
              "&#navLink:focus": {
                boxShadow: headerLinkActiveBoxShadow,
              },
            })}
          >
            {orgName}
          </Button>
        )}
        <Route path="/projects/:projectId" render={renderProject} />
        <RedesignLayout.Spacer />
        {!userEmail ? (
          <Fragment>
            {cobrandedLogo && (
              <img
                alt="Powered By Rabbet"
                src={poweredByRabbet}
                style={{ height: "75%", marginRight: majorScale(2) }}
              />
            )}
            <Button
              appearance="minimal"
              color={headerButtonTextColor}
              fontSize={12}
              fontWeight={600}
              id="navLink"
              is={RouterLink}
              purpose="nav login"
              to="/login"
              {...css({
                "&#navLink:hover": {
                  backgroundColor: headerLinkInactiveHover,
                  color: headerTextOnHoverColor,
                },
                "&#navLink:focus": {
                  boxShadow: headerLinkActiveBoxShadow,
                },
              })}
            >
              Login
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            {pendingInvitation && (
              <PendingInvitationLink invitation={pendingInvitation} />
            )}
            {cobrandedLogo && (
              <img
                alt="Powered By Rabbet"
                src={poweredByRabbet}
                style={{ height: "75%" }}
              />
            )}
            <RedesignLayout.Link
              to="/organizations"
              color={headerButtonTextColor}
              data-testid="navbar-organizations-link"
              id="orgNavLink"
              {...css({
                "&#orgNavLink:focus": {
                  boxShadow: headerLinkActiveBoxShadow,
                },
                "&#orgNavLink:hover": {
                  backgroundColor: headerLinkInactiveHover,
                  color: headerTextOnHoverColor,
                },
              })}
            >
              Organizations
            </RedesignLayout.Link>
            {atLeastOneOrgHasPermissionOn(PERMISSION_ACTION.RUN_REPORT) && (
              <RedesignLayout.Link
                to={
                  (useNewPortfolioInsights &&
                    atLeastOneOrgHasPermissionOn(
                      PERMISSION_ACTION.ADVANCED_REPORT
                    )) ||
                  atLeastOneOrgHasPermissionOn(
                    PERMISSION_ACTION.ADVANCED_REPORT_LENDER
                  ) ||
                  atLeastOneOrgHasPermissionOn(
                    PERMISSION_ACTION.ADVANCED_REPORT_DEVELOPER
                  )
                    ? getPortfolioInsightsPath()
                    : "/reports/projects"
                }
                color={headerButtonTextColor}
                data-testid="navbar-reports-link"
                id="reportNavLink"
                {...css({
                  "&#reportNavLink:focus": {
                    boxShadow: headerLinkActiveBoxShadow,
                  },
                  "&#reportNavLink:hover": {
                    backgroundColor: headerLinkInactiveHover,
                    color: headerTextOnHoverColor,
                  },
                })}
              >
                Reports
              </RedesignLayout.Link>
            )}
            <HelpButton theme={theme} />
            {/* 
            this permission check does not consider multi-org users,
            for whom the selection of the graphql "user" object from the available profiles is not deterministic 
            */}
            {!!userPermissions[camelCase(PERMISSION_ACTION.AI_CHATBOT)] && (
              <IconButton
                appearance="minimal"
                color={headerIconButtonColor}
                data-testid="navbar-ai-chat"
                icon={LightbulbIcon}
                iconSize={14}
                id="chatLink"
                intent={null}
                onClick={() => history.push("/vantage")}
                rel="noopener noreferrer"
                target="_blank"
                marginLeft={majorScale(1)}
                {...css({
                  "&#chatLink:hover": {
                    backgroundColor: headerLinkInactiveHover,
                    color: headerTextOnHoverColor,
                  },
                  "&#chatLink:focus": {
                    boxShadow: headerLinkActiveBoxShadow,
                  },
                })}
              />
            )}
            <Route path={["/admin", "/notifications", "/logout"]}>
              {({ match }) => {
                return (
                  <Popover
                    content={userMenu(match, theme)}
                    position={Position.BOTTOM_RIGHT}
                  >
                    <Button
                      appearance="minimal"
                      color={headerButtonTextColor}
                      fontSize={12}
                      fontWeight={match ? 800 : 600}
                      iconAfter={CaretDownIcon}
                      id="navLink"
                      data-testid="navbar-user-menu"
                      purpose="nav user-menu open"
                      isActive={!!match}
                      marginLeft={majorScale(1)}
                      style={
                        match
                          ? {
                              backgroundColor: headerLinkActive,
                            }
                          : { backgroundColor: headerLinkActiveHover }
                      }
                      {...css({
                        "&#navLink:hover": {
                          backgroundColor: match
                            ? headerLinkActiveHover
                            : headerLinkInactiveHover,
                          color: headerTextOnHoverColor,
                        },
                        "&#navLink:focus": {
                          boxShadow: headerLinkActiveBoxShadow,
                        },
                      })}
                    >
                      {userEmail}
                    </Button>
                  </Popover>
                );
              }}
            </Route>
          </Fragment>
        )}
      </RedesignLayout.Nav>
      <Switch>
        <Route
          path="/submissions/:submissionId"
          render={(props) =>
            !props.match.isExact && (
              <RedesignLayout.SubNav>
                <RedesignLayout.Tab exact to={`${props.match.url}/overview`}>
                  Line Items
                </RedesignLayout.Tab>
                <RedesignLayout.Tab exact to={`${props.match.url}/hard_costs`}>
                  Hard Costs
                </RedesignLayout.Tab>
                <RedesignLayout.Tab exact to={`${props.match.url}/soft_costs`}>
                  Soft Costs
                </RedesignLayout.Tab>
                <RedesignLayout.Tab
                  exact
                  to={`${props.match.url}/documentation`}
                >
                  Documents
                </RedesignLayout.Tab>
                <RedesignLayout.Spacer />
                <SubmissionActions {...props} />
              </RedesignLayout.SubNav>
            )
          }
        />
        <Route
          path="/projects/:projectId/draws/:drawId"
          render={(props) => {
            const project = find(
              recentProjects,
              (project) => project.id === props.match.params.projectId
            );
            return (
              <DrawHeader
                projectForFundingSourceWarning={project}
                history={props.history}
                match={props.match}
              />
            );
          }}
        />
        {/* Project route submenus are authorized conditionally, see ProjectLayout */}
        <Route
          path="/reports"
          render={({ match }) => (
            <RedesignLayout.SubNav>
              <Fragment>
                {useNewPortfolioInsights &&
                  atLeastOneOrgHasPermissionOn(
                    PERMISSION_ACTION.ADVANCED_REPORT
                  ) && (
                    <RedesignLayout.Tab
                      exact
                      marginRight={majorScale(5)}
                      to={`${match.url}`}
                    >
                      Portfolio Overview
                    </RedesignLayout.Tab>
                  )}
                {!useNewPortfolioInsights &&
                  atLeastOneOrgHasPermissionOn(
                    PERMISSION_ACTION.ADVANCED_REPORT_LENDER
                  ) && (
                    <RedesignLayout.Tab
                      exact
                      marginRight={majorScale(5)}
                      to={`${match.url}/lender`}
                    >
                      Portfolio Overview
                    </RedesignLayout.Tab>
                  )}
                {!useNewPortfolioInsights &&
                  atLeastOneOrgHasPermissionOn(
                    PERMISSION_ACTION.ADVANCED_REPORT_DEVELOPER
                  ) && (
                    <RedesignLayout.Tab
                      exact
                      marginRight={majorScale(5)}
                      to={`${match.url}/developer`}
                    >
                      Portfolio Overview
                    </RedesignLayout.Tab>
                  )}
                <Heading
                  color="#7f8f9f"
                  fontSize={11}
                  fontWeight={600}
                  marginRight={majorScale(2)}
                  textTransform="uppercase"
                >
                  Report By:
                </Heading>
                <RedesignLayout.Tab exact to={`${match.url}/projects`}>
                  Projects
                </RedesignLayout.Tab>
                {atLeastOneOrgHasPermissionOn(
                  PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
                ) && (
                  <RedesignLayout.Tab exact to={`${match.url}/funding_sources`}>
                    Funding Sources
                  </RedesignLayout.Tab>
                )}
                <RedesignLayout.Tab exact to={`${match.url}/draws`}>
                  Draws
                </RedesignLayout.Tab>
                <RedesignLayout.Tab exact to={`${match.url}/documentation`}>
                  Documents
                </RedesignLayout.Tab>
                <RedesignLayout.Tab exact to={`${match.url}/invoice_summary`}>
                  Invoice Summary
                </RedesignLayout.Tab>
                {atLeastOneOrgHasPermissionOn(
                  PERMISSION_ACTION.JOB_COST_CODES
                ) && (
                  <RedesignLayout.Tab exact to={`${match.url}/job_cost_codes`}>
                    Cost Codes
                  </RedesignLayout.Tab>
                )}
                {atLeastOneOrgHasPermissionOn(
                  PERMISSION_ACTION.TASK_MANAGEMENT
                ) && (
                  <RedesignLayout.Tab exact to={`${match.url}/tasks`}>
                    Tasks
                  </RedesignLayout.Tab>
                )}
              </Fragment>
            </RedesignLayout.SubNav>
          )}
        />
        <Route
          path="/admin"
          render={({ match }) => (
            <RedesignLayout.SubNav>
              <Heading
                color="#7f8f9f"
                fontSize={11}
                fontWeight={600}
                marginRight={majorScale(2)}
                textTransform="uppercase"
              >
                Admin:
              </Heading>
              <RedesignLayout.Tab exact to={match.url}>
                Users
              </RedesignLayout.Tab>
              {atLeastOneOrgHasPermissionOn(
                PERMISSION_ACTION.CONFIGURE_ORGANIZATION
              ) && (
                <RedesignLayout.Tab exact to={`${match.url}/configure`}>
                  Configuration
                </RedesignLayout.Tab>
              )}
            </RedesignLayout.SubNav>
          )}
        />
      </Switch>
      <RedesignLayout.Content>{children}</RedesignLayout.Content>
      {useNewUploads && <UploadProgress />}
    </RedesignLayout>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object),
  pendingInvitations: PropTypes.arrayOf(PropTypes.object),
  refetch: PropTypes.func,
  userEmail: PropTypes.string,
};

AppLayout.defaultProps = {
  organizations: [],
  pendingInvitations: [],
  refetch: () => null,
  userEmail: undefined,
};

export default AppLayout;
