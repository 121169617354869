import { useState, useEffect } from "react";
import { cloneDeep, isEqual, set } from "lodash";

// This acts as a dumbed down, barebones Formik implementation,
// replacing the `form` or `formikProps` prop.
// This is by no means comprehensive
// and will need to be continuously expanded as its implemented in more places
export function usePerformik({
  handleSubmit: onSubmit,
  initialValues: initValues,
  resetFormOnChangingInitialValues,
  validate,
}) {
  // Initial values and values should be equivalent when the component calling the hook mounts
  const [initialValues, setInitialValues] = useState(initValues);
  const [values, setValues] = useState(initValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const initialValuesChanged = !isEqual(initialValues, initValues);
    if (resetFormOnChangingInitialValues && initialValuesChanged) {
      setInitialValues(initValues);
      setValues(initValues);
    }
  }, [initValues, initialValues, resetFormOnChangingInitialValues]);

  function setFieldValue(fieldName, value) {
    setValues((pastValues) => {
      const values = cloneDeep(pastValues);
      set(values, fieldName, value);
      setErrors(validate(values));
      return values;
    });
  }

  function reinitializeValues() {
    setInitialValues(values);
  }

  function handleSubmit() {
    onSubmit(values, {
      resetForm: reinitializeValues,
    });
  }

  function handleReset() {
    // Setting a new object reference for the initial values
    // indicates to the inputs that its value should be reset
    setInitialValues(cloneDeep(initialValues));
    setValues(initialValues);
    setErrors({});
  }

  const dirty = !isEqual(initialValues, values);

  /* 
  The Formik bag is generally passed around under the name of `formikProps`, `form`, or `propsFormik`.
  This is a list of the Formik bag's objects/methods that we're using 
    across the application that do not yet exist in the hook:
      bypassChecks
      error
      handleBlur
      handleChange
      isValid
      resetForm
      setStatus
      setValues
      touched
  */

  return {
    dirty,
    errors,
    handleReset,
    handleSubmit,
    initialValues,
    setFieldValue,
    values,
  };
}
