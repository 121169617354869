import { useState, useContext, useEffect } from "react";
import pattern from "patternomaly";
import { ChartTemplate } from "components/templates";
import { Button, Pane, Tab, Tablist, Text } from "components/materials";
import { groupBy, intersection, mapValues, values } from "lodash";
import { majorScale, ThemeContext } from "helpers/utilities";
import { sumBy } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import { AGREEMENT_TYPE, CHANGE_ORDER_REASON } from "helpers/enums";
import { stringComparator } from "helpers/comparators";
import t from "helpers/translate";

const VIEWS = ["Change Reason", "Vendor"];

const getChangeOrderColor = (changeOrderType, theme) => {
  const color = theme.changeOrderChart[changeOrderType];
  if (changeOrderType === AGREEMENT_TYPE.EXPOSURE)
    return pattern.draw("zigzag", color);
  if (changeOrderType === AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER) return color;
  if (changeOrderType === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER) return color;
  return null;
};

const initialData = {
  labels: [],
  datasets: [{ data: [] }],
};

function generateData(groupedByReasonAndType, sortedReasons, changeOrderType) {
  return sortedReasons.map(
    (reason) => groupedByReasonAndType[reason][changeOrderType] || 0
  );
}

function getAmountsByType(groupedAgreements) {
  return mapValues(groupedAgreements, (agreements) => {
    const groupedByType = groupBy(agreements, "type");
    const amountsByType = mapValues(groupedByType, (typeGroup) =>
      sumBy(typeGroup, "amount")
    );
    return amountsByType;
  });
}

function generateDatasets(groupedAmounts, labels, theme) {
  const sortedChangeOrderTypesForChart = [
    AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
    AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER,
    AGREEMENT_TYPE.EXPOSURE,
  ];
  return values(sortedChangeOrderTypesForChart).map(
    (changeOrderType, index) => {
      const changeOrderColor = getChangeOrderColor(changeOrderType, theme);
      return {
        data: generateData(groupedAmounts, labels, changeOrderType),
        backgroundColor: changeOrderColor,
        hoverBackgroundColor: changeOrderColor,
        order: index,
        stack: "agreements",
        label: t(`agreementType.${changeOrderType}`),
        maxBarThickness: 150,
      };
    }
  );
}

function generateChangeOrderData(agreements, view, theme) {
  if (view === "Change Reason") {
    const groupedByReason = groupBy(agreements, "changeOrderReason");
    const existingReasons = Object.keys(groupedByReason);
    const sortedReasons = intersection(
      values(CHANGE_ORDER_REASON),
      existingReasons
    );
    const amountsByReasonAndType = getAmountsByType(groupedByReason);
    const datasets = generateDatasets(
      amountsByReasonAndType,
      sortedReasons,
      theme
    );

    return {
      labels: sortedReasons.map((reason) => t(`changeOrderReason.${reason}`)),
      datasets,
    };
  }

  if (view === "Vendor") {
    const groupedByVendor = groupBy(
      agreements,
      (agreement) => agreement?.vendor?.name || "No Vendor"
    );
    const vendors = Object.keys(groupedByVendor);
    const sortedVendors = vendors.sort((a, b) => {
      if (a === "No Vendor" || b === "No Vendor")
        return a === "No Vendor" ? 1 : -1;
      return stringComparator(a.text, b.text);
    });

    const amountsByVendorAndType = getAmountsByType(groupedByVendor);
    const datasets = generateDatasets(
      amountsByVendorAndType,
      sortedVendors,
      theme
    );

    return {
      labels: sortedVendors,
      datasets,
    };
  }
  return initialData;
}

export function ChangeOrdersChart({ changeOrders, setShowGraph }) {
  const [changeOrderView, setChangeOrderView] = useState("Change Reason");
  const [data, setData] = useState(initialData);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    setData(generateChangeOrderData(changeOrders, changeOrderView, theme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...changeOrders, changeOrderView, setData, theme]);

  return (
    <Pane borderBottom>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={majorScale(2)}
        paddingY={majorScale(2)}
      >
        <Pane display="flex" alignItems="center">
          <Text size={300} fontWeight={500} marginRight={majorScale(1)}>
            View Change Orders By:
          </Text>
          <Tablist>
            {VIEWS.map((view) => (
              <Tab
                key={view}
                isSelected={changeOrderView === view}
                onSelect={() => setChangeOrderView(view)}
              >
                {view}
              </Tab>
            ))}
          </Tablist>
        </Pane>
        <Button marginLeft={majorScale(2)} onClick={() => setShowGraph(false)}>
          Hide Graph
        </Button>
      </Pane>
      <Pane position="relative" height="375px" marginX="80px">
        <Pane>
          <ChartTemplate
            chartId="change-orders-graph"
            datasets={data.datasets}
            height={375}
            isStacked
            labels={data.labels}
            type="bar"
            valueFormatter={(value) => formatCurrency(value)}
          />
        </Pane>
      </Pane>
    </Pane>
  );
}
