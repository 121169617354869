import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Link,
  Pane,
  Paragraph,
  Progress,
  Text,
} from "components/materials";
import { css } from "glamor";
import { chunk, flatten, groupBy, map, max, sumBy } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale } from "helpers/utilities";
import { BRAND_COLORS } from "helpers/colors";

const MAX_ROWS = 8;
const MAX_COLUMNS = 3;

function ProgressBar({ value, total, ...props }) {
  const over = value > total;

  const style = {
    color: BRAND_COLORS.DARK_BLUE,
    background: BRAND_COLORS.LIGHT_BLUE,
  };

  if (over || value < 0) {
    style.color = BRAND_COLORS.RED;
  }

  return <Progress value={value} total={total} {...style} {...props} />;
}

function SectionProgress({ name, used, budget, backFn }) {
  const selector = css({
    alignItems: "center",
    background: "rgba(255, 255, 255, .6)",
    borderBottom: "1px solid rgba(0, 0, 0, .1)",
    borderRadius: "3px",
    display: "flex",
    width: "100%",
    "& > div": {
      padding: majorScale(2),
    },
    "& a": {
      textDecoration: "underline",
    },
  });

  const back = backFn && (
    <Box>
      <Link onClick={() => backFn(false)}>Back</Link>
    </Box>
  );

  return (
    <Box {...selector}>
      {back}
      <Box>{name}</Box>
      <Box whiteSpace="nowrap" textAlign="right">
        <Paragraph>{usedBudgetFormatted(used, budget)}</Paragraph>
      </Box>
      <Box flex="1">
        <ProgressBar value={used} total={budget} />
      </Box>
    </Box>
  );
}

function Tables({ name, used, budget, chunks, detailsFn, backFn, scroll }) {
  const tables = chunks.map((sections) => {
    const rows = sections.map((section) => {
      const details = detailsFn && (
        <Box is="td">
          <Link
            size={300}
            onClick={() => detailsFn([section.name, section.lines])}
          >
            Details
          </Link>
        </Box>
      );

      return (
        <Box is="tr">
          <Box is="td" maxWidth="200px">
            <Text size={300}>{section.name}</Text>
          </Box>
          {details}
          <Box is="td">
            <Text size={300}>
              {usedBudgetFormatted(section.used, section.budget)}
            </Text>
          </Box>
          <Box is="td" width="99%">
            <ProgressBar
              value={section.used}
              total={section.budget}
              width={`${section.scale}%`}
            />
          </Box>
        </Box>
      );
    });

    const selector = css({
      borderCollapse: "collapse",
      width: "100%",
      "& tr": {
        borderBottom: "1px solid rgba(0, 0, 0, .1)",
      },
      "& td": {
        paddingBottom: "3px",
        paddingRight: majorScale(2),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& a": {
        textDecoration: "underline",
      },
    });

    return (
      <Box flexGrow="1" padding={majorScale(1)}>
        <Box is="table" {...selector}>
          <Box is="tbody">{rows}</Box>
        </Box>
      </Box>
    );
  });

  const overflow = scroll ? { overflow: "scroll", maxHeight: "210px" } : {};

  return (
    <Pane>
      <SectionProgress
        name={name}
        used={used}
        budget={budget}
        backFn={backFn}
      />
      <Box marginTop={majorScale(2)} {...overflow}>
        <Pane display="flex" columnGap={majorScale(2)}>
          {tables}
        </Pane>
      </Box>
    </Pane>
  );
}

function Divisions({ divisions, setViewLineItems }) {
  const [usedTotal, budgetTotal] = usedBudget(flatten(map(divisions, "lines")));
  const [chunkBy, scroll] = chunkAndScroll(divisions);
  const maxBudget = max(map(flatten(map(divisions, "lines")), "budgetAmount"));

  const chunks = chunk(divisions, chunkBy).map((divs) => {
    return divs.map(({ division, lines }) => {
      const [used, budget] = usedBudget(lines);
      return {
        name: division,
        scale: scale(budget, maxBudget),
        lines,
        used,
        budget,
      };
    });
  });

  return (
    <Tables
      name="Project"
      used={usedTotal}
      budget={budgetTotal}
      chunks={chunks}
      detailsFn={setViewLineItems}
      scroll={scroll}
    />
  );
}

function LineItems({ items, setViewLineItems }) {
  const [division, lineItems] = items;
  const [usedTotal, budgetTotal] = usedBudget(lineItems);
  const [chunkBy, scroll] = chunkAndScroll(lineItems);
  const maxBudget = max(map(lineItems, "budgetAmount"));

  const chunks = chunk(lineItems, chunkBy).map((items) => {
    return items.map((item) => {
      const [used, budget] = usedBudget([item]);
      return {
        name: item.name,
        scale: scale(budget, maxBudget),
        used,
        budget,
      };
    });
  });

  return (
    <Tables
      name={division}
      used={usedTotal}
      budget={budgetTotal}
      chunks={chunks}
      backFn={setViewLineItems}
      scroll={scroll}
    />
  );
}

function BudgetProgress({ project }) {
  const [viewLineItems, setViewLineItems] = useState(false);

  const { lineItems } = project;

  const divisions = map(
    groupBy(lineItems, "division.name"),
    (lines, division) => ({ division, lines })
  );

  const selector = css({
    paddingLeft: majorScale(4),
    paddingRight: majorScale(4),
  });

  const section = viewLineItems ? (
    <LineItems items={viewLineItems} setViewLineItems={setViewLineItems} />
  ) : (
    <Divisions divisions={divisions} setViewLineItems={setViewLineItems} />
  );

  return <Box {...selector}>{section}</Box>;
}

BudgetProgress.propTypes = {
  project: PropTypes.object,
};

function usedBudget(items) {
  const used = sumBy(items, (l) => l.requestedToDateAmount);
  const budget = sumBy(items, (l) => l.budgetAmount);
  return [used, budget];
}

function usedBudgetFormatted(used, budget) {
  const usedStr = formatCurrency(used, { shortenedFormat: true });
  const budgetStr = formatCurrency(budget, { shortenedFormat: true });
  return `${usedStr} / ${budgetStr}`;
}

function scale(val, max) {
  return Math.min(Math.max((val / max) * 100, 0), 100);
}

function chunkAndScroll(items) {
  let chunkBy = MAX_ROWS;
  let scroll = false;

  if (items.length > MAX_ROWS * MAX_COLUMNS) {
    chunkBy = Math.ceil(items.length / 3);
    scroll = true;
  }

  return [chunkBy, scroll];
}

export default BudgetProgress;
