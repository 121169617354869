import { isEqual } from "lodash";

/**
 * Returns whether or not two strings are equal, ignoring whitespace differences between all words
 *
 * @param a - The first string to compare
 * @param b - The second string to compare
 * @param disregardCaseDifferences - Whether or not to ignore case differences between the two strings, defaults to true
 * @returns Whether or not the two strings are equal, ignoring whitespace differences between all words
 */

export function areWordsEqual(
  a: string,
  b: string,
  disregardCaseDifferences = true
): boolean {
  const transformer = (input: string) => {
    const caseTransformed = disregardCaseDifferences
      ? input.toLowerCase()
      : input;

    /* Split and compare arrays so that the strings
  
       Racecar parts
       Race car parts
  
       are NOT considered equal
    */
    return caseTransformed.trim().split(/\s+/);
  };

  return isEqual(transformer(a), transformer(b));
}
