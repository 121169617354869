import { Fragment } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "evergreen-ui";
import { NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE } from "hooks/use-projections";
import { IconButton, Pane, Select, Table } from "components/materials";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { majorScale } from "helpers/utilities";
import {
  getCurveTypeOptions,
  getStartMonthOptions,
  getDurationOptions,
  selectProps,
  tableBorder,
  PROJECTION_CURVE_TYPE,
} from "./util";

export function DivisionRowHeader({
  onClick,
  onSettingChanged,
  projectionState,
  division,
  project,
  isOpen,
  columns,
}) {
  const { months, expectedLength } = project;

  function shouldDisableSetting(settingName, projectionState) {
    const isCurveTypeManual =
      division.curveType === PROJECTION_CURVE_TYPE.MANUAL;

    const isLockedProjection =
      projectionState === NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE;

    switch (settingName) {
      case "curveType":
        return isLockedProjection;
      case "startMonth":
        return isLockedProjection || isCurveTypeManual;
      case "duration":
        return isLockedProjection || isCurveTypeManual;
      default:
        return isLockedProjection;
    }
  }

  return (
    <Table.Row onClick={onClick}>
      <Table.TextSectionHeaderCell testId="division" width={300}>
        <Pane display="flex" alignItems="center">
          <IconButton
            appearance="minimal"
            flex="0 0 auto"
            size="small"
            icon={isOpen ? ChevronDownIcon : ChevronRightIcon}
            marginRight={majorScale(1)}
            type="button"
          />
          {division.name}
        </Pane>
      </Table.TextSectionHeaderCell>
      <Table.TextSectionHeaderCell width={125}>
        <Select
          {...selectProps}
          cursor={
            shouldDisableSetting("curveType", projectionState)
              ? "not-allowed"
              : "pointer"
          }
          disabled={shouldDisableSetting("curveType", projectionState)}
          value={division.curveType}
          data-curvetype={division.curveType || "none"}
          onClick={(e) => preventEventBubbling(e)}
          onChange={(e) => onSettingChanged(e, "curveType")}
        >
          <option disabled value="MIXED">
            Mixed
          </option>
          {getCurveTypeOptions()}
        </Select>
      </Table.TextSectionHeaderCell>
      <Table.TextSectionHeaderCell width={125}>
        <Select
          {...selectProps}
          cursor={
            shouldDisableSetting("startMonth", projectionState)
              ? "not-allowed"
              : "pointer"
          }
          disabled={shouldDisableSetting("startMonth", projectionState)}
          value={division.startMonth}
          data-startmonthnum={division.startMonth || "none"}
          onClick={(e) => preventEventBubbling(e)}
          onChange={(e) => onSettingChanged(e, "startMonth")}
        >
          <option disabled value="MIXED">
            Mixed
          </option>
          {getStartMonthOptions(months)}
        </Select>
      </Table.TextSectionHeaderCell>
      <Table.TextSectionHeaderCell width={125}>
        <Select
          {...selectProps}
          cursor={
            shouldDisableSetting("duration", projectionState)
              ? "not-allowed"
              : "pointer"
          }
          disabled={shouldDisableSetting("duration", projectionState)}
          value={division.duration}
          data-projectduration={division.duration || "none"}
          onClick={(e) => preventEventBubbling(e)}
          onChange={(e) => onSettingChanged(e, "duration")}
        >
          <option disabled value="MIXED">
            Mixed
          </option>
          {getDurationOptions(expectedLength)}
        </Select>
      </Table.TextSectionHeaderCell>
      <Table.TextSectionHeaderCell width={125} />
      {columns.showPreviousBudget && (
        <Table.TextSectionHeaderCell width={125} />
      )}
      {months.map((_) => {
        switch (projectionState) {
          case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
            return (
              <Fragment>
                <Table.TextSectionHeaderCell
                  borderLeft={tableBorder}
                  width={125}
                />
                {columns.showUpdatedProjection &&
                  columns.showOriginalProjection && (
                    <Table.TextSectionHeaderCell
                      borderLeft={tableBorder}
                      width={125}
                    />
                  )}
              </Fragment>
            );
          default:
            return (
              <Table.TextSectionHeaderCell
                borderLeft={tableBorder}
                width={125}
              />
            );
        }
      })}
      <Table.TextSectionHeaderCell borderLeft={tableBorder} />
    </Table.Row>
  );
}
