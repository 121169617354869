import { CaretDownIcon } from "evergreen-ui";
import {
  Checkbox,
  Divider,
  IconButton,
  Pane,
  RadioGroup,
  SelectMenu,
  Shortener,
  TextInput,
} from "components/materials";
import { difference, escapeRegExp, get, uniq, xor } from "lodash";
import { majorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";

export const listColumnDefaults = {
  aggregate: () => null,
  aggregateFormatter: (value) => (
    <Shortener text={value} limit={30} size={300} />
  ),
  filterControl: (index, form, column, items) => {
    const values = uniq(
      items.reduce((values, item) => {
        const listValue = column.value(item);
        return values.concat(listValue ? listValue.split(", ") : "Empty");
      }, [])
    );
    const options = values.map((value) => ({
      key: value,
      label: value,
      value,
    }));
    const selected = get(form.values, `filters.${index}.list`, []);
    // This value is used by a radio group, which requires a string value
    // so if the value is falsey, set it to the string "false"
    const defaultRadioValue =
      get(form.values, `filters.${index}.containsAnyInList`) || "false";
    const checked = difference(values, selected).length === 0;
    const indeterminate = !checked && selected.length > 0;
    return (
      <Pane marginTop={majorScale(1)}>
        <SelectMenu
          hasFilter={false}
          isMultiSelect
          onDeselect={({ value }) =>
            form.setFieldValue(`filters.${index}.list`, xor(selected, [value]))
          }
          onSelect={({ value }) =>
            form.setFieldValue(`filters.${index}.list`, xor(selected, [value]))
          }
          options={options}
          selected={selected}
          width={315}
          titleView={() => (
            <Pane marginLeft={majorScale(1)}>
              <Pane width={100} marginY={majorScale(1)}>
                <RadioGroup
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  defaultValue={defaultRadioValue}
                  options={[
                    { label: "AND", value: "false" },
                    {
                      label: "OR",
                      value: "true",
                    },
                  ]}
                  onChange={(event) => {
                    const { value } = event.target;
                    form.setFieldValue(
                      `filters.${index}.containsAnyInList`,
                      value
                    );
                  }}
                />
              </Pane>
              <Divider color="#e2e9f0" height={1} marginRight={majorScale(1)} />
              <Checkbox
                checked={checked}
                indeterminate={indeterminate}
                label="SELECT ALL"
                onChange={() =>
                  form.setFieldValue(
                    `filters.${index}.list`,
                    checked ? [] : values
                  )
                }
              />
            </Pane>
          )}
        >
          <Pane display="flex">
            <TextInput
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
              onChange={() => {}}
              value={`${selected.length} Selected`}
            />
            <IconButton
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
              icon={CaretDownIcon}
              type="button"
            />
          </Pane>
        </SelectMenu>
      </Pane>
    );
  },
  filterFormatter: (fc, column) => (
    <Shortener
      limit={36}
      size={300}
      text={`${column.header}: ${fc.list.join(", ")}`}
    />
  ),
  filterStrategy: (value, filterConfig) => {
    const {
      containsAnyInList,
      list: filterConfigList,
      __isSearch,
      input,
    } = filterConfig;

    if (__isSearch === true) {
      return isBlank(input)
        ? true
        : new RegExp(escapeRegExp(input), "i").test(value);
    }

    const values = value ? value.split(", ") : [];

    // This value is used by a radio group, which requires a string value
    if (containsAnyInList === "true") {
      const valueInFilterList = values.some((value) =>
        filterConfigList.find((filterConfigItem) => filterConfigItem === value)
      );
      return !!valueInFilterList;
    }

    return difference(filterConfigList, values).length === 0;
  },
  width: 215,
  sortStrategy: (valueA, valueB) =>
    (valueA || "").length - (valueB || "").length,
  type: "list",
  valueFormatter: (value) => <Shortener text={value} limit={30} size={300} />,
};
