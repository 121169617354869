import { Pane, Tablist, Tab } from "components/materials";
import { majorScale } from "helpers/utilities";

export function ProjectionViewControls({ showFundingSources, onViewChange }) {
  return (
    <Pane marginY={majorScale(2)}>
      <Tablist>
        <Tab
          isSelected={!showFundingSources}
          onSelect={() => onViewChange({ showFundingSources: false })}
          marginRight={majorScale(1)}
        >
          Line Items
        </Tab>
        <Tab
          isSelected={showFundingSources}
          onSelect={() => onViewChange({ showFundingSources: true })}
        >
          Funding Sources
        </Tab>
      </Tablist>
    </Pane>
  );
}
