import { Pane, Paragraph, Text } from "components/materials";
import { splitUpload } from "images";
import { majorScale, useTheme } from "helpers/utilities";
import t from "helpers/translate";

export default function Help({ dismissed = false, onDismiss }) {
  const theme = useTheme();

  if (dismissed) return null;

  return (
    <Pane
      alignItems="center"
      background={theme.colors.lightBlue}
      borderBottom
      display="flex"
      elevation={1}
      justifyContent="center"
      paddingY={majorScale(1)}
    >
      <img alt="" src={splitUpload} width={120} />
      <Paragraph marginLeft={majorScale(4)}>
        <Paragraph fontSize={14} fontWeight={theme.fontWeights.DEMI}>
          {t("splitterHelp.header")}
        </Paragraph>
        <Paragraph fontSize={12} lineHeight={1.25}>
          {t("splitterHelp.text1")}
        </Paragraph>
        <Paragraph fontSize={12} lineHeight={1.25}>
          {t("splitterHelp.text2")}
        </Paragraph>
        <Paragraph fontSize={12} lineHeight={1.25}>
          {t("splitterHelp.text3")}&quot;Split&quot;{t("splitterHelp.text4")}
        </Paragraph>
        <Text
          color="selected"
          cursor="pointer"
          fontSize={12}
          onClick={onDismiss}
          padding={0}
          textDecoration="underline"
        >
          Dismiss
        </Text>
      </Paragraph>
    </Pane>
  );
}
