import { Fragment } from "react";
import { AddProjectButton } from "components/templates";
import { Link, Text, Pane } from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { PortfolioProjectCard } from "./PortfolioProjectCard";

export function PortfolioProjectCards({
  canCreateProjects,
  hasPermissionAcrossProfiles,
  history,
  projects,
}) {
  return projects.length > 0 ? (
    <Pane display="flex" flexWrap="wrap" justifyContent="center">
      {projects.map((project) => (
        <Pane
          flex="1 1 auto"
          height={200}
          key={project.id}
          margin={majorScale(2)}
          maxWidth={525}
          minWidth={325}
          position="relative"
        >
          <PortfolioProjectCard history={history} project={project} />
        </Pane>
      ))}
    </Pane>
  ) : (
    <Fragment>
      {hasPermissionAcrossProfiles(PERMISSION_ACTION.CREATE_PROJECT) ? (
        <Pane
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop={majorScale(10)}
        >
          <Pane marginBottom={majorScale(2)}>
            <Text>You have no projects yet.</Text>
          </Pane>
          {canCreateProjects && <AddProjectButton />}
        </Pane>
      ) : (
        <Pane marginTop={majorScale(2)}>
          <Text>
            You don’t have permission to create a new project.{" "}
            {<Link href="mailto:help@rabbet.com">Contact us</Link>} if you have
            any questions, or to learn more about projects in Rabbet.
          </Text>
        </Pane>
      )}
    </Fragment>
  );
}
