import { Fragment } from "react";
import PropTypes from "prop-types";
import { ContentLayout, GuestProjectHeader } from "components/templates";
import { FileViewer, Heading, Link } from "components/materials";
import t from "helpers/translate";

const ReportSubmissionLayout = ({ reportSubmission }) => {
  const to = `/report_submissions/${reportSubmission.id}/import`;

  const primaryAction = {
    to,
    content: t("reportSubmission.importReport"),
  };

  const renderHeading = () => (
    <Fragment>
      <Heading floated="right" size="tiny">
        <Link
          purpose="report-submission download"
          href={reportSubmission.export.url}
          content={t("reportSubmission.downloadReport")}
        />
      </Heading>
      <GuestProjectHeader draw={reportSubmission.sourceDraw} />
    </Fragment>
  );

  const renderContent = () => (
    <div>
      {reportSubmission.uploads.map(({ id, previewUrl, contentType }) => {
        return (
          <FileViewer
            key={id}
            file={{ name: id, type: contentType, url: previewUrl }}
          />
        );
      })}
    </div>
  );

  return (
    <ContentLayout
      bleedContent
      primaryAction={primaryAction}
      renderHeading={renderHeading}
      renderContent={renderContent}
    />
  );
};

ReportSubmissionLayout.propTypes = {
  reportSubmission: PropTypes.object.isRequired,
};

export default ReportSubmissionLayout;
