import { Fragment } from "react";
import { Form, Table } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import { getNetAmount, getNetPercentage } from "./helpers";

export function LineItemRow({
  disabled,
  drawLineItem,
  form,
  hasColumnA,
  isLineItem,
  lineItemFieldName,
  lineItemName,
  sectionName,
}) {
  const alwaysShowInputs = !isLineItem;
  const { columnA, columnB, columnC } = form.values[sectionName][
    lineItemFieldName
  ];
  const requestedPreviouslyAmount = drawLineItem?.requestedPreviouslyAmount;
  const previousAmount =
    drawLineItem?.name === "Retainage"
      ? requestedPreviouslyAmount * -1
      : requestedPreviouslyAmount;
  const netAmount = getNetAmount(columnB, columnC, previousAmount);
  const netPercentage = getNetPercentage(columnA, columnB, columnC);

  return (
    <Table.Row key={lineItemName}>
      <Table.TextCell>{lineItemName}</Table.TextCell>
      {alwaysShowInputs || drawLineItem ? (
        <Fragment>
          <Table.Cell>
            <Form.NewInput
              disabled={disabled || !hasColumnA}
              error={form.errors[sectionName]?.[lineItemFieldName]?.columnA}
              initialValues={form.initialValues}
              name={`${sectionName}.${lineItemFieldName}.columnA`}
              onChange={form.setFieldValue}
              type="currency"
            />
          </Table.Cell>
          <Table.Cell>
            <Form.NewInput
              disabled={disabled}
              error={form.errors[sectionName]?.[lineItemFieldName]?.columnB}
              initialValues={form.initialValues}
              name={`${sectionName}.${lineItemFieldName}.columnB`}
              onChange={form.setFieldValue}
              type="currency"
            />
          </Table.Cell>
          <Table.Cell>
            <Form.NewInput
              disabled={disabled}
              error={form.errors[sectionName]?.[lineItemFieldName]?.columnC}
              initialValues={form.initialValues}
              name={`${sectionName}.${lineItemFieldName}.columnC`}
              onChange={form.setFieldValue}
              type="currency"
            />
          </Table.Cell>
          <Table.TextCell>{formatCurrency(previousAmount)}</Table.TextCell>
          <Table.TextCell>
            {hasColumnA ? `${netPercentage}%` : ""}
          </Table.TextCell>
          <Table.TextCell>{formatCurrency(netAmount)}</Table.TextCell>
        </Fragment>
      ) : (
        <Fragment>
          <Table.TextCell colSpan={5}>
            {t("documentReview.2448LineItemMissing", { lineItemName })}
          </Table.TextCell>
        </Fragment>
      )}
    </Table.Row>
  );
}
