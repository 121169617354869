import { majorScale } from "helpers/utilities";
import { useFormikContext } from "formik";
import { Form, Pane, Text } from "components/materials";
import { isBefore } from "helpers/dateHelpers";
import { get } from "lodash";

export const CustomRangeControl = ({ fieldPath }) => {
  const { setFieldValue, values } = useFormikContext();

  const startFieldName = `${fieldPath}.start`;
  const endFieldName = `${fieldPath}.finish`;

  const currentStartValue = get(values, startFieldName);
  const currentEndValue = get(values, endFieldName);

  return (
    <Pane alignItems="center" display="flex">
      <Form.DateInput
        minWidth={90}
        name={startFieldName}
        onChange={(date) => {
          if (isBefore(currentEndValue, date)) {
            setFieldValue(endFieldName, date);
          }
        }}
      />
      <Form.Field>
        <Text marginX={majorScale(1)}>to</Text>
      </Form.Field>
      <Form.DateInput
        minWidth={90}
        name={endFieldName}
        onChange={(date) => {
          if (isBefore(date, currentStartValue)) {
            setFieldValue(startFieldName, date);
          }
        }}
      />
    </Pane>
  );
};
