import gql from "graphql-tag";

export const BORROWER_APP_LAYOUT_QUERY = gql`
  query BorrowerAppLayoutPageQuery {
    borrower {
      id
      name
      email
      lender {
        id
        headerColor
        name
        primaryColor
        primaryLogo {
          url
        }
      }
    }
  }
`;

export const RECENT_BORROWER_PROJECTS_QUERY = gql`
  query RecentProjectsQuery($ids: [String]) {
    borrower {
      id
      projects(ids: $ids) {
        id
        name
        template {
          id
          icon
        }
        draws {
          id
          name
        }
      }
    }
  }
`;

export const BORROWER_PORTFOLIO_QUERY = gql`
  query BorrowerPortfolioPageCardsQuery {
    borrower {
      id
      projects {
        id
        name
        city
        state
        status
        amount
        disbursedAmount
        recentUnfundedDraw {
          id
          name
          state
          createdAt
          submittedDate
        }
        picture {
          url
        }
        coordinates {
          lat
          lng
        }
        fundingSourceGroups {
          id
          amount
          disbursedAmount
          label
          type
        }
      }
    }
  }
`;

export const BORROWER_PROJECT_OVERVIEW_QUERY = gql`
  query BorrowerProjectOverviewPageQuery($projectId: String!) {
    borrower {
      id
      lenderHasAllowMultipleUnfundedBorrowerDraws
      project(id: $projectId) {
        id
        name
        amount
        disbursedAmount
        inProgressAmount
        contingencySpentAmount
        contingencyOriginalAmount
        startDate
        expectedProjectLength
        status
        city
        state
        coordinates {
          lat
          lng
        }
        divisions {
          id
          scopeId
          name
          lineItems {
            id
            scopeId
            name
            budgetAmount
            originalBudgetAmount
            requestedToDateAmount
          }
        }
        draws {
          id
          name
          requestedAmount
          state
          isDeletedByLender
          createdAt
          submittedDate
          fundedDate
        }
        fundingSourceGroups {
          id
          amount
          disbursedAmount
          label
          type
        }
        picture {
          url
        }
        recentUnfundedDraw {
          id
          createdAt
          name
          state
          submittedDate
        }
      }
    }
  }
`;

export const BORROWER_DRAW_QUERY = gql`
  query BorrowerDrawFormQuery($projectId: String!, $drawId: String!) {
    borrower {
      id
      project(id: $projectId) {
        id
        name
        city
        organizationName
        state
        streetAddress
        draw(id: $drawId) {
          id
          documents {
            id
            insertedAt
            fileContentType
            fileName
            fileUrl
            pages
            type
          }
          checklistItems {
            id
            label
            complete
          }
          name
          notes
          projectId
          requestedAmount
          state
          submittedDate
        }
        coordinates {
          lat
          lng
        }
        picture {
          url
        }
      }
    }
  }
`;

export const CREATE_BORROWER_DRAW = gql`
  mutation NewDrawPageMutation($name: String!, $projectId: String!) {
    createBorrowerDraw(name: $name, projectId: $projectId) {
      id
    }
  }
`;

export const UPDATE_BORROWER_DRAW = gql`
  mutation UpdateBorrowerDrawMutation(
    $id: String!
    $projectId: String!
    $name: String
    $requestedAmount: Currency
    $notes: String
    $checklistItems: [ChecklistItemInput]
  ) {
    updateBorrowerDraw(
      id: $id
      projectId: $projectId
      name: $name
      requestedAmount: $requestedAmount
      notes: $notes
      checklistItems: $checklistItems
    ) {
      id
    }
  }
`;

export const UPLOAD_FILE_TO_BORROWER_DRAW = gql`
  mutation UploadFileToBorrowerDraw(
    $borrowerDrawId: String!
    $projectId: String!
    $file: Upload
    $pageCount: Int
    $type: BorrowerDocumentType!
  ) {
    uploadBorrowerDocument(
      borrowerDrawId: $borrowerDrawId
      projectId: $projectId
      file: $file
      pageCount: $pageCount
      type: $type
    ) {
      id
      borrowerDrawId
      insertedAt
      fileName
      pages
      type
    }
  }
`;

export const SUBMIT_BORROWER_DRAW = gql`
  mutation SubmitBorrowerDrawMutation($borrowerDrawId: String!) {
    submitBorrowerDraw(borrowerDrawId: $borrowerDrawId) {
      id
      state
      submittedDate
    }
  }
`;

export const DELETE_BORROWER_DOCUMENT = gql`
  mutation DeleteBorrowerDocument($documentId: String!, $projectId: String!) {
    deleteBorrowerDocument(id: $documentId, projectId: $projectId) {
      status
    }
  }
`;
