import { Fragment, useContext } from "react";
import { Alert, Pane } from "components/materials";
import { ThemeContext } from "helpers/utilities";
import { first, get, isInteger, omit } from "lodash";
import { UserContext } from "helpers/behaviors";
import t from "helpers/translate";
import isBlank from "helpers/isBlank";
import {
  DOCUMENT_TYPE_NAME,
  DRAW_UPDATE_SOURCE_OPTION,
  PERMISSION_ACTION,
} from "helpers/enums";
import { DocumentContext } from "contexts/documentContext";
import { parseCurrencyToFloat } from "helpers/parseCurrencyToFloat";
import DocumentReviewLayout from "../DocumentReviewLayout";
import { DrawSummaryInformation } from "./DrawSummaryInformation";
import { DrawSummaryOptions } from "./DrawSummaryOptions";
import { UnmappedLineItemsWarning } from "./UnmappedLineItemsWarning";
import ParseWarning from "../ParseWarning";

export function DrawSummaryReview(props) {
  const {
    collapsedPanels,
    document,
    form,
    projectDrawUpdateSource,
    selectedDraw,
    togglePanel,
  } = props;
  const { hasPermission, isDeveloper } = useContext(UserContext);

  const suppressForm =
    !hasPermission(PERMISSION_ACTION.IMPORT_DRAW_SUMMARY) ||
    projectDrawUpdateSource === DRAW_UPDATE_SOURCE_OPTION.DOCUMENTATION;
  const showDrawSummaryDisabledMessage = suppressForm && !isDeveloper;

  const theme = useContext(ThemeContext);
  const lockdownDraw = get(selectedDraw, "isLockedDown");

  const { fetchDocument, isProcessing } = useContext(DocumentContext);

  const reclassifiedToDrawSummary =
    document.type !== DOCUMENT_TYPE_NAME.DRAW_SUMMARY;

  if (suppressForm) {
    return (
      <DocumentReviewLayout
        {...props}
        cancelParsing
        disableInformation
        disableLineItemDrawer
      >
        {showDrawSummaryDisabledMessage && (
          <Pane>
            <Alert title={t("documentReview.drawSummariesDisabled")} />
          </Pane>
        )}
      </DocumentReviewLayout>
    );
  }

  return (
    <DocumentReviewLayout
      {...props}
      disableInformation
      disableLineItemDrawer
      lockdownDraw={lockdownDraw}
      meta={(content) => content}
      selectedDraw={selectedDraw}
      // if a new line item is added to the budget via Draw Summary Review,
      // that line item will not be present in the form's `initialValues`,
      // and resetting the form will make that line item disappear from view.
      // Therefore, when resetting the form, we first refetch the DOCUMENT_QUERY, which includes the draw's line items
      onReset={(form) => fetchDocument().then(form.handleReset)}
    >
      <DrawSummaryReviewContent
        collapsedPanels={collapsedPanels}
        document={document}
        form={form}
        isProcessing={isProcessing}
        lockdownDraw={lockdownDraw}
        reclassifiedToDrawSummary={reclassifiedToDrawSummary}
        theme={theme}
        togglePanel={togglePanel}
      />
    </DocumentReviewLayout>
  );
}

function DrawSummaryReviewContent({
  collapsedPanels,
  document,
  form,
  isProcessing,
  lockdownDraw,
  reclassifiedToDrawSummary,
  theme,
  togglePanel,
}) {
  if (reclassifiedToDrawSummary) {
    return (
      <ParseWarning
        document={document}
        isProcessing={isProcessing}
        requireSaveToParse
      />
    );
  }

  if (form.values.draw === null) {
    return (
      <Pane display="flex" justifyContent="center" marginY={30}>
        {t("drawSummaryReview.invalidAtProjectLevel")}
      </Pane>
    );
  }

  return (
    <Fragment>
      <UnmappedLineItemsWarning form={form} theme={theme} />
      <DrawSummaryOptions
        fontWeight={theme.fontWeights.MEDIUM}
        isCollapsed={collapsedPanels.includes("drawSummaryOptions")}
        lockdownDraw={lockdownDraw}
        onCollapse={() => togglePanel("drawSummaryOptions")}
        values={form.values}
      />
      <DrawSummaryInformation
        fileData={document.fileData}
        fileType={document.file.type}
        form={form}
        isCollapsed={collapsedPanels.includes("drawSummaryInformation")}
        lockdownDraw={lockdownDraw}
        onCollapse={() => togglePanel("drawSummaryInformation")}
        theme={theme}
      />
    </Fragment>
  );
}

const DEFAULT_COLUMN_MAPPINGS = {
  lineItemColumn: null,
  adjustmentsColumn: null,
  retainageColumn: null,
  requestedColumn: null,
};

function getInitialValues({ document, selectedDraw, selectedType }) {
  const drawSummaryConfig = get(document, "drawSummaryConfig", {});
  const fileData = get(document, "fileData") || {};
  const lineItemDataById = get(drawSummaryConfig, "rowMappings", []).reduce(
    (
      acc,
      {
        lineItemId,
        fileRow,
        adjustmentsAmount,
        requestedAmount,
        retainageAmount,
      }
    ) => ({
      ...acc,
      [lineItemId]: {
        fileRow,
        adjustmentsAmount,
        requestedAmount,
        retainageAmount,
      },
    }),
    {}
  );
  const lineItems = get(selectedDraw, "lineItems", []).map(
    (lineItem, index) => ({
      ...lineItem,
      formIndex: index,
      rowIndex: lineItemDataById[lineItem.id]?.fileRow ?? null,
      overrides: {
        adjustmentsAmount:
          lineItemDataById[lineItem.id]?.adjustmentsAmount ?? null,
        requestedAmount: lineItemDataById[lineItem.id]?.requestedAmount ?? null,
        retainageAmount: lineItemDataById[lineItem.id]?.retainageAmount ?? null,
      },
    })
  );

  const columnMappings =
    drawSummaryConfig.columnMappings || DEFAULT_COLUMN_MAPPINGS;

  // The state of the draw summary might be corrected or applied
  // based on the subscription returned first (the subscription when the draw summary is updated
  // or the subscription when a document is corrected), but regardless of the state, this shouldn't affect the form
  const { state: _state, ...restOfDocument } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);

  return {
    ...documentProperties,
    draw: selectedDraw
      ? {
          id: selectedDraw.id,
          name: selectedDraw.name,
          isLockedDown: selectedDraw.isLockedDown,
          lineItems: selectedDraw.lineItems,
          hasAdjustments: selectedDraw.hasAdjustments,
        }
      : null,
    type: selectedType,
    requestedMethod: get(drawSummaryConfig, "requestedMethod") || "gross",
    retainageMethod: get(drawSummaryConfig, "retainageMethod") || "toDate",
    adjustmentsMethod: get(drawSummaryConfig, "adjustmentsMethod") || "toDate",
    sheetName:
      get(drawSummaryConfig, "sheetName") || first(Object.keys(fileData)),
    ...columnMappings,
    lineItems,
  };
}

function getDocumentDataFromForm(values, _hasPermission, document) {
  const {
    adjustmentsColumn,
    adjustmentsMethod,
    lineItemColumn,
    lineItems,
    requestedColumn,
    requestedMethod,
    retainageColumn,
    retainageMethod,
    sheetName,
  } = values;

  return {
    requestedMethod,
    retainageMethod,
    adjustmentsMethod,
    sheetName,
    columnMappings: {
      lineItemColumn,
      adjustmentsColumn,
      requestedColumn,
      retainageColumn,
    },
    rowMappings: lineItems.map(({ id, rowIndex, overrides }) => {
      const sheetData = get(document.fileData ?? {}, sheetName, []);
      return {
        lineItemId: id,
        fileRow: rowIndex,
        adjustmentsAmount: formatOverride(
          overrides.adjustmentsAmount,
          sheetData,
          rowIndex,
          adjustmentsColumn
        ),
        requestedAmount: formatOverride(
          overrides.requestedAmount,
          sheetData,
          rowIndex,
          requestedColumn
        ),
        retainageAmount: formatOverride(
          overrides.retainageAmount,
          sheetData,
          rowIndex,
          retainageColumn
        ),
      };
    }),
  };
}

function formatOverride(override, sheetData, row, column) {
  const formattedOverride = parseCurrencyToFloat(override);
  if (isBlank(formattedOverride) || isNaN(formattedOverride)) return null;

  if (row === "none") return null;

  if (row === "manual" || isBlank(row) || isBlank(column)) {
    return formattedOverride;
  }

  if (isInteger(row) && row < sheetData.length && !isBlank(column)) {
    const parsedValue = sheetData[row][column];
    return formattedOverride !== parsedValue ? formattedOverride : null;
  }

  return null;
}

// TODO: remove dot notation
DrawSummaryReview.getInitialValues = getInitialValues;
DrawSummaryReview.getDocumentDataFromForm = getDocumentDataFromForm;
