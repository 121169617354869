import {
  cloneDeep,
  difference,
  inRange,
  max,
  pullAt,
  reject,
  set,
  sortBy,
} from "lodash";
import isBlank from "helpers/isBlank";
import { UPLOAD_DOCUMENT_OPERATION } from "helpers/enums";
import { v4 as uuid } from "uuid";
import unformatNumber from "helpers/unformatNumber";

export function buildDocument(attrs) {
  return {
    id: uuid(),
    startPage: null,
    endPage: null,
    sourceDocumentId: null,
    type: null,
    deleted: false,
    ...attrs,
  };
}

function getPageValue(value, originalDocument, key) {
  if (value === "") {
    return "";
  }

  return parseInt(value, 10) || originalDocument[key];
}

function prepareData(
  documents,
  targetRowIndex,
  proposedStartPage,
  proposedEndPage
) {
  const documentBeforeUpdate = documents[targetRowIndex];
  const startPage = getPageValue(
    proposedStartPage,
    documentBeforeUpdate,
    "startPage"
  );
  const endPage = getPageValue(
    proposedEndPage,
    documentBeforeUpdate,
    "endPage"
  );
  const updatedDocument = {
    ...documentBeforeUpdate,
    startPage,
    endPage,
  };
  const newDocuments = cloneDeep(documents);

  return {
    documentBeforeUpdate,
    updatedDocument,
    newDocuments,
  };
}

const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;
export const uploadFile = (file) => {
  const data = new FormData();
  data.append("file", file);

  return fetch(`${baseUrl}/api/uploads`, {
    method: "POST",
    body: data,
  }).then((response) => response.json());
};

function removeAt(arr, index) {
  pullAt(arr, index);
  return arr;
}

function updateAt(arr, index, newValue) {
  return set(arr, [index], newValue);
}

function hasInvalidRange({ startPage, endPage }, lastPage) {
  const endPageTooLow = endPage < startPage;
  const endPageTooHigh = endPage > lastPage;
  const startPageZero = startPage === 0;

  return endPageTooLow || endPageTooHigh || startPageZero;
}

export function lastPage(documents) {
  return max(documents.map(({ endPage }) => endPage));
}

function hasBlankPage({ startPage, endPage }) {
  return isBlank(startPage) || isBlank(endPage);
}

function handleBlankPageUpdate({
  updatedDocument,
  documentBeforeUpdate,
  targetRowIndex,
  newDocuments,
  documents,
}) {
  const lastUploadPage = lastPage(documents);
  // Only allow start page to be blank if it was blank before (eg - via "Add Document")
  if (
    !isBlank(updatedDocument.startPage) &&
    inRange(updatedDocument.startPage, 1, lastUploadPage + 1) &&
    isBlank(documentBeforeUpdate.startPage)
  ) {
    return updateAt(newDocuments, targetRowIndex, updatedDocument);
  }

  // Only allow end page to be blank if it was blank before (eg - via "Add Document")
  if (
    !isBlank(updatedDocument.endPage) &&
    inRange(updatedDocument.endPage, 1, lastUploadPage + 1) &&
    isBlank(documentBeforeUpdate.endPage)
  ) {
    return updateAt(newDocuments, targetRowIndex, updatedDocument);
  }

  // Else just ignore the input since
  // trying to blank out an existing value is invalid operation
  return documents;
}

// Returns true if document 1's pages fall completely within document 2's pages
function overshadowedByRange(document1, document2) {
  return (
    document2.endPage >= document1.endPage &&
    document2.startPage <= document1.startPage
  );
}

function rangesOverlap(document1, document2) {
  const startPageInRange = inRange(
    document2.startPage,
    document1.startPage,
    document1.endPage + 1
  );

  const endPageInRange = inRange(
    document2.endPage,
    document1.startPage,
    document1.endPage + 1
  );

  return startPageInRange || endPageInRange;
}

function updateOverlappingRow({
  newDocuments,
  documentAtRow,
  updatedDocument,
  index,
  newIndex,
}) {
  // If the overlapping row is BEFORE the target row modified by the user,
  // then update the end page on the overlapping row.

  // If the overlapping row is AFTER the target row modified by the user,
  // then update the start page on the overlapping row.
  const updatedDocumentAtRow =
    index < newIndex
      ? {
          ...documentAtRow,
          endPage: updatedDocument.startPage - 1,
        }
      : {
          ...documentAtRow,
          startPage: updatedDocument.endPage + 1,
        };

  updateAt(newDocuments, index, updatedDocumentAtRow);
}

function handleUpdatedPage({ newDocuments, targetRowIndex, updatedDocument }) {
  // Perform an update to the target document
  updateAt(newDocuments, targetRowIndex, updatedDocument);

  // Move the updated document to its correct spot in the list
  newDocuments = sortBy(newDocuments, "startPage");

  const newIndex = newDocuments.indexOf(updatedDocument);

  // Iterate from end to start in order to preserve indices
  // while removing elements from the document list
  for (let i = newDocuments.length - 1; i >= 0; i -= 1) {
    // Skip if on the updated row
    if (i !== newIndex) {
      const documentAtRow = newDocuments[i];

      if (overshadowedByRange(documentAtRow, updatedDocument)) {
        // Remove document that completely overshadowed by the new range
        removeAt(newDocuments, i);
      } else if (rangesOverlap(documentAtRow, updatedDocument)) {
        updateOverlappingRow({
          newDocuments,
          documentAtRow,
          updatedDocument,
          index: i,
          newIndex,
        });
      }
    }
  }

  return newDocuments;
}

export function setUpdatedDocuments(
  documents,
  targetRowIndex,
  { startPage, endPage }
) {
  const preparedData = prepareData(
    documents,
    targetRowIndex,
    startPage,
    endPage
  );
  const { documentBeforeUpdate, updatedDocument } = preparedData;
  let { newDocuments } = preparedData;

  // Bail early if there is at least one empty field
  if (hasBlankPage(updatedDocument)) {
    return handleBlankPageUpdate({
      updatedDocument,
      documentBeforeUpdate,
      targetRowIndex,
      newDocuments,
      documents,
    });
  }

  // Bail early if the provided value results in an invalid range
  if (hasInvalidRange(updatedDocument, lastPage(documents))) {
    return documents;
  }

  // Do the actual page update to the document and resolve
  // any conflicting ranges
  newDocuments = handleUpdatedPage({
    newDocuments,
    targetRowIndex,
    updatedDocument,
  });

  return fillBlankRanges(newDocuments, lastPage(documents));
}

function getOperation({ deleted, sourceDocumentId }) {
  if (deleted && sourceDocumentId) {
    return UPLOAD_DOCUMENT_OPERATION.DELETE;
  }

  if (sourceDocumentId) {
    return UPLOAD_DOCUMENT_OPERATION.UPDATE;
  }

  return UPLOAD_DOCUMENT_OPERATION.ADD;
}

// Fill any gaps that may exist between pages
export function fillBlankRanges(documents, lastUploadPage, defaultToDeleted) {
  const result = documents.reduce((memo, document, index) => {
    const previousDocument = documents[index - 1];
    const gapExists =
      previousDocument &&
      !isBlank(document.startPage) &&
      !isBlank(previousDocument.endPage) &&
      document.startPage - previousDocument.endPage > 1;

    if (index >= 1 && gapExists) {
      const placeholder = buildDocument({
        startPage: previousDocument.endPage + 1,
        endPage: document.startPage - 1,
        deleted: !!defaultToDeleted,
      });
      memo.push(placeholder);
    }

    if (index === 0 && document.startPage > 1) {
      const placeholder = buildDocument({
        startPage: 1,
        endPage: document.startPage - 1,
        deleted: !!defaultToDeleted,
      });
      memo.push(placeholder);
    }

    return memo.concat(document);
  }, []);

  if (result.length === 0) {
    result.push(
      buildDocument({
        startPage: 1,
        endPage: lastUploadPage,
        deleted: !!defaultToDeleted,
      })
    );
  } else if (lastPage(result) < lastUploadPage) {
    result.push(
      buildDocument({
        startPage: lastPage(result) + 1,
        endPage: lastUploadPage,
        deleted: !!defaultToDeleted,
      })
    );
  }

  return result;
}

export function getDocumentUpdates(documents, initialDocumentIds) {
  const updatedDocumentIds = documents.map(({ id }) => id);
  const explicitUpdates = reject(
    documents,
    ({ deleted, sourceDocumentId }) => deleted && !sourceDocumentId
  ).map((document) => ({
    ...document,
    startPage: unformatNumber(document.startPage),
    endPage: unformatNumber(document.endPage),
    operation: getOperation(document),
  }));

  const deletedDocumentOperations = difference(
    initialDocumentIds,
    updatedDocumentIds
  ).map((id) =>
    buildDocument({
      sourceDocumentId: id,
      deleted: true,
      operation: UPLOAD_DOCUMENT_OPERATION.DELETE,
    })
  );

  return [...explicitUpdates, ...deletedDocumentOperations];
}

export function mergeAllDocuments(documents) {
  return setUpdatedDocuments(documents, 0, {
    endPage: lastPage(documents),
  });
}
