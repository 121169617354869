import { Fragment } from "react";
import { NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE } from "hooks/use-projections";
import { Table } from "components/materials";
import {
  amountFieldNames,
  getMonthHasActuals,
} from "helpers/projectionHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import { getCellState, tableBorder } from "./util";

export function DivisionRowSubtotal({
  actualsCount,
  columns,
  division,
  project,
  projectionState,
}) {
  const { months } = project;

  return (
    <Table.Row>
      <Table.TextFooterCell textAlign="right">
        {division.name} Subtotal
      </Table.TextFooterCell>
      {columns.showPreviousBudget && (
        <Table.TextFooterCell colSpan={4} textAlign="right">
          {formatCurrency(division.divisionPreviousBudgetAmount)}
        </Table.TextFooterCell>
      )}
      <Table.TextFooterCell
        colSpan={columns.showPreviousBudget ? 0 : 4}
        textAlign="right"
      >
        {formatCurrency(division.divisionBudgetAmount)}
      </Table.TextFooterCell>
      {months.map((_, monthIndex) => {
        const monthHasActuals = getMonthHasActuals(monthIndex, actualsCount);
        const subtotals = division.divisionSubtotals[monthIndex];

        switch (projectionState) {
          case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
            return (
              <Fragment>
                {columns.showOriginalProjection && (
                  <Table.TextFooterCell
                    borderLeft={tableBorder}
                    textAlign="right"
                    state={getCellState(
                      amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                    )}
                  >
                    {formatCurrency(subtotals.projected)}
                  </Table.TextFooterCell>
                )}
                {columns.showUpdatedProjection && (
                  <Table.TextFooterCell
                    borderLeft={tableBorder}
                    textAlign="right"
                    state={getCellState(
                      monthHasActuals
                        ? amountFieldNames.ACTUALS
                        : amountFieldNames.UPDATED_PROJECTION_AMOUNTS
                    )}
                  >
                    {formatCurrency(subtotals.updatedProjection)}
                  </Table.TextFooterCell>
                )}
              </Fragment>
            );
          default:
            return (
              <Table.TextFooterCell
                borderLeft={tableBorder}
                textAlign="right"
                state={getCellState(
                  monthHasActuals
                    ? amountFieldNames.ACTUALS
                    : amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                )}
              >
                {formatCurrency(subtotals.updatedProjection)}
              </Table.TextFooterCell>
            );
        }
      })}
      <Table.TextFooterCell borderLeft={tableBorder} />
    </Table.Row>
  );
}
