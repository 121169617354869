import { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import { get, values } from "lodash";
import { DoubleChevronLeftIcon, DoubleChevronRightIcon } from "evergreen-ui";
import { LineItemDrawer } from "components/containers";
import {
  Banner,
  Confirm,
  Form,
  IconButton,
  Pane,
  Paragraph,
  Text,
  Tooltip,
} from "components/materials";
import { useDocumentFormContext } from "contexts/documentFormContext";
import { UserContext } from "helpers/behaviors";
import {
  isAgreementWithAdjustments,
  isSummaryWithAdjustments,
} from "helpers/documentHelpers";
import {
  AGREEMENT_TYPE,
  DOCUMENT_IMPORT_SOURCE,
  DOCUMENT_TYPE_NAME,
  PERMISSION_ACTION,
} from "helpers/enums";
import t from "helpers/translate";
import { minorScale, majorScale, ThemeContext } from "helpers/utilities";
import { yardiLogo } from "images";
import { PARSABLE_DOCUMENT_TYPES } from "./utils";
import DocumentActivity from "./DocumentActivity";
import DocumentComments from "./DocumentComments";
import DocumentInformation from "./DocumentInformation";
import DocumentMeta from "./DocumentMeta";
import { DocumentReviewActions } from "./DocumentReviewActions";
import DocumentWarnings from "./DocumentWarnings";
import { AlsoDeleteAgreementModal } from "./AlsoDeleteAgreementModal";
import ParseWarning from "./ParseWarning";

const CHAR_LIMIT = 256;

function DocumentReviewLayout(props) {
  const {
    assignUserToDocumentsMutation,
    autofilledFieldCount,
    cardsOpen,
    cancelParsing,
    children,
    documentMissingInformation,
    disableActions,
    disableActivity,
    disableComments,
    disableDescription,
    disableFields,
    disableHeader,
    disableInformation,
    disableLineItemDrawer,
    disableWarnings,
    dirty,
    document,
    documentIgnored,
    documentReviewers,
    drawId,
    getProjectVendorSearchQuery,
    handleApprove,
    handleMarkPaid,
    handleMarkUnpaid,
    markDocumentPaidLoading,
    markDocumentUnpaidLoading,
    handleCancelParsing,
    handleMarkReviewed,
    requireSaveToParse,
    handleUndoApprove,
    information,
    isProcessing,
    lockdownDraw,
    markReviewedResult,
    meta,
    mutationLoading,
    onApprove,
    onMarkPaid,
    onMarkUnpaid,
    onChangeDraw,
    onChangeType,
    onDelete,
    onIgnore,
    onReset,
    onReview,
    openLineItemDrawer,
    projectId,
    searchedVendors,
    selectedDraw,
    selectedType,
    selector,
    setCardsOpen,
    setOpenLineItemDrawer,
    setShowUserLockdownDrawConfirm,
    shouldShowAutofilledField,
    showUserLockdownDrawConfirm,
    title,
    users,
    ...otherProps
  } = props;
  const theme = useContext(ThemeContext);
  const { FormTextArea } = useDocumentFormContext();
  const { hasOrgLevelPermission, hasPermission } = useContext(UserContext);

  const [commentTouched, setCommentTouched] = useState(false);
  const [openConfirmMoveFile, setOpenConfirmMoveFile] = useState(false);
  const [openConfirmDrawSummary, setOpenConfirmDrawSummary] = useState(false);
  const [removeDrawSummaryAction, setRemoveDrawSummaryAction] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [
    alsoDeleteAgreementModalOpen,
    setAlsoDeleteAgreementModalOpen,
  ] = useState(false);
  const [
    cannotDeleteWithAdjustmentOpen,
    setCannotDeleteWithAdjustmentOpen,
  ] = useState(false);

  const needsParsing =
    !cancelParsing &&
    (isProcessing ||
      (document.type !== selectedType &&
        PARSABLE_DOCUMENT_TYPES.includes(selectedType)));

  const formDrawId = get(props.form.values, "draw.id");
  const fileLocation = !formDrawId
    ? "the project level"
    : get(
        props.draws.find((draw) => draw.id === formDrawId),
        "name"
      );

  const movingDocumentToLockedDownDraw =
    !get(document, "draw.isLockedDown") && lockdownDraw;

  const hasAdjustments = isAgreementWithAdjustments(document);
  const agreementContent = hasAdjustments
    ? t("documentsViewer.adjustmentsWillBeDeleted")
    : "";

  const isAgreement = values(AGREEMENT_TYPE).includes(document.type);
  const hasAgreementManagement = hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  );
  const canCreateAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );

  return (
    <Pane position="relative">
      <Confirm
        content={t("documentsViewer.confirmDelete").concat(agreementContent)}
        header="Delete Document"
        hideViewer
        onCloseComplete={() => setConfirmDeleteOpen(false)}
        onConfirm={onDelete}
        open={confirmDeleteOpen}
        cancelLabel="Cancel"
        confirmLabel="Delete Document"
      />
      <Confirm
        content={t("drawSummaryReview.confirmOverwriteAdjustments")}
        header="Save Draw Summary"
        hideViewer
        onCloseComplete={() => setOpenConfirmDrawSummary(false)}
        onConfirm={(close) => {
          props.form.handleSubmit();
          close();
        }}
        open={openConfirmDrawSummary}
        cancelLabel="Cancel"
        confirmLabel="Save"
      />
      <Confirm
        content={t(
          `drawSummaryReview.remove.${removeDrawSummaryAction}.content`
        )}
        header={t(`drawSummaryReview.remove.${removeDrawSummaryAction}.header`)}
        hideViewer
        onCloseComplete={() => setRemoveDrawSummaryAction(null)}
        onConfirm={(close) => {
          if (removeDrawSummaryAction === "reclassify")
            props.form.handleSubmit();
          if (removeDrawSummaryAction === "ignore") onIgnore(props.form);
          if (removeDrawSummaryAction === "delete") onDelete();
          close();
        }}
        open={removeDrawSummaryAction}
        cancelLabel="Cancel"
        confirmLabel={t(
          `drawSummaryReview.remove.${removeDrawSummaryAction}.submit`
        )}
      />
      <Confirm
        header="Move File"
        hideViewer
        content={`This file will be moved to ${fileLocation}.`}
        onCloseComplete={() => setOpenConfirmMoveFile(false)}
        onConfirm={(close) => {
          props.form.handleSubmit();
          close();
        }}
        open={openConfirmMoveFile}
        cancelLabel="Cancel"
        confirmLabel="Confirm"
      />
      <Confirm
        header="Move File to Previous Draw"
        hideViewer
        content={t("drawLockdown.moveDocument")}
        onCancel={() => {
          onChangeType(get(document, "type"));
          onChangeDraw(get(document, "draw.id"));
          props.form.handleReset();
        }}
        onCloseComplete={() => {
          setShowUserLockdownDrawConfirm(false);
        }}
        onConfirm={(close) => {
          close();
        }}
        open={showUserLockdownDrawConfirm && movingDocumentToLockedDownDraw}
        cancelLabel="Cancel"
        confirmLabel="Confirm"
      />
      <Confirm
        content={
          <Fragment>
            <Paragraph marginBottom={majorScale(2)}>
              {t("documentWarnings.modal.cannotDeleteWithAdjustment")}
            </Paragraph>
            <Paragraph>
              {t("documentWarnings.modal.cannotDeleteWithAdjustmentSecondary")}
            </Paragraph>
          </Fragment>
        }
        hasCancel={false}
        header={t("documentWarnings.title.cannotDeleteWithAdjustment")}
        onCloseComplete={() => setCannotDeleteWithAdjustmentOpen(false)}
        open={cannotDeleteWithAdjustmentOpen}
        confirmLabel="OK"
      />
      {alsoDeleteAgreementModalOpen && (
        <AlsoDeleteAgreementModal
          documents={[document]}
          onClose={() => setAlsoDeleteAgreementModalOpen(false)}
          onDelete={onDelete}
        />
      )}
      <Form>
        {!disableHeader && (
          <Pane
            alignItems="center"
            backgroundColor="#ffffff"
            borderBottom="1px solid #d7e0ea"
            display="flex"
            height={48}
            justifyContent="flex-start"
            paddingY={minorScale(1)}
            position="sticky"
            top={0}
            width="100%"
            zIndex={3}
          >
            <Pane flexGrow="0" width={32}>
              <IconButton
                appearance="minimal"
                icon={
                  cardsOpen ? DoubleChevronLeftIcon : DoubleChevronRightIcon
                }
                onClick={() => setCardsOpen(!cardsOpen)}
                type="button"
              />
            </Pane>
            <Text
              fontWeight={theme.fontWeights.MEDIUM}
              overflow="hidden"
              paddingX={minorScale(3)}
              size={500}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {title(document.file.name)}
            </Text>
            {document.importSource === DOCUMENT_IMPORT_SOURCE.YARDI && (
              <Tooltip content={t("yardiWarnings.documentCannotBeEdited")}>
                <Pane
                  is="img"
                  src={yardiLogo}
                  alt=""
                  height={30}
                  marginLeft="auto"
                  marginRight={majorScale(1)}
                  marginTop={minorScale(1)}
                />
              </Tooltip>
            )}
          </Pane>
        )}
        {!disableActions && (
          <Pane
            backgroundColor="#fcfcfd"
            borderBottom="1px solid #d7e0ea"
            flexGrow="0"
            position="sticky"
            textAlign="right"
            top={48}
            zIndex={3}
          >
            <DocumentReviewActions
              assignUserToDocumentsMutation={assignUserToDocumentsMutation}
              commentTouched={commentTouched}
              dirty={dirty}
              document={document}
              documentIgnored={documentIgnored}
              documentMissingInformation={documentMissingInformation}
              documentReviewers={documentReviewers}
              form={props.form}
              handleApprove={handleApprove}
              handleMarkPaid={handleMarkPaid}
              handleMarkUnpaid={handleMarkUnpaid}
              markDocumentPaidLoading={markDocumentPaidLoading}
              markDocumentUnpaidLoading={markDocumentUnpaidLoading}
              handleUndoApprove={handleUndoApprove}
              mutationLoading={mutationLoading}
              onChangeDraw={onChangeDraw}
              onChangeType={onChangeType}
              onDelete={() => {
                if (hasAdjustments && !canCreateAdjustments) {
                  return setCannotDeleteWithAdjustmentOpen(true);
                }
                if (isAgreement && hasAgreementManagement) {
                  return setAlsoDeleteAgreementModalOpen(true);
                }
                if (isSummaryWithAdjustments(document)) {
                  return setRemoveDrawSummaryAction("delete");
                }
                return setConfirmDeleteOpen(true);
              }}
              onIgnore={() => {
                if (isSummaryWithAdjustments(document))
                  setRemoveDrawSummaryAction("ignore");
                else onIgnore(props.form);
              }}
              onReset={onReset}
              selectedDraw={selectedDraw}
              setOpenConfirmMoveFile={setOpenConfirmMoveFile}
              setOpenConfirmDrawSummary={setOpenConfirmDrawSummary}
              setRemoveDrawSummaryAction={setRemoveDrawSummaryAction}
              users={users}
              warnings={otherProps.warnings}
            />
          </Pane>
        )}

        {selector}

        {!disableWarnings && <DocumentWarnings {...otherProps} />}

        <DocumentMeta
          {...otherProps}
          disabled={disableFields}
          document={document}
          onChangeType={onChangeType}
          onChangeDraw={onChangeDraw}
        >
          {meta}
        </DocumentMeta>

        {documentIgnored && (
          <Banner
            icon="eyeOffIcon"
            mainText={t("documentReview.ignoredMain")}
            secondaryText={t("documentReview.ignoredSecondary")}
          />
        )}

        {!disableInformation && !needsParsing && (
          <DocumentInformation
            disableFields={lockdownDraw || disableFields}
            {...otherProps}
            document={document}
            getProjectVendorSearchQuery={getProjectVendorSearchQuery}
            projectId={projectId}
            searchedVendors={searchedVendors}
            shouldShowAutofilledField={shouldShowAutofilledField}
          >
            {information}
          </DocumentInformation>
        )}

        {/* The Draw Summary form handles its own ParseWarning */}
        {!disableInformation &&
          needsParsing &&
          selectedType !== DOCUMENT_TYPE_NAME.DRAW_SUMMARY && (
            <ParseWarning
              document={document}
              isProcessing={isProcessing}
              handleCancelParsing={handleCancelParsing}
              requireSaveToParse={requireSaveToParse}
            />
          )}

        {/* The Draw Summary form handles its own ParseWarning */}
        {(!needsParsing || selectedType === DOCUMENT_TYPE_NAME.DRAW_SUMMARY) &&
          children}

        {!disableDescription && (
          <Form.Section
            heading="Description"
            isCollapsible
            isCollapsed={otherProps.collapsedPanels.includes("description")}
            onCollapse={() => otherProps.togglePanel("description")}
          >
            <Pane>
              <FormTextArea
                disabled={disableFields}
                fieldProps={{
                  hint: (
                    <Paragraph size={300} textAlign="right">
                      {t("submitDraw.noteLimit", {
                        count:
                          CHAR_LIMIT -
                          (get(otherProps, "form.values.description") || "")
                            .length,
                        limit: CHAR_LIMIT,
                      })}
                    </Paragraph>
                  ),
                }}
                height={60}
                initialValues={otherProps.form.initialValues}
                label="Description (optional)"
                maxLength={256}
                name="description"
                onChange={otherProps.form.setFieldValue}
                size={300}
              />
            </Pane>
          </Form.Section>
        )}

        {!disableActivity &&
          hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS) && (
            <DocumentActivity
              {...otherProps}
              handleMarkReviewed={handleMarkReviewed}
              markReviewedResult={markReviewedResult}
              onDirtyOrFocus={setCommentTouched}
              document={document}
              users={users}
            />
          )}

        {!disableComments &&
          !hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS) && (
            <DocumentComments
              {...otherProps}
              onDirtyOrFocus={setCommentTouched}
              document={document}
              users={users}
            />
          )}

        {!disableLineItemDrawer &&
          hasPermission(PERMISSION_ACTION.LINE_ITEM_DRAWER) &&
          formDrawId && (
            <LineItemDrawer
              cardsOpen={cardsOpen}
              drawId={formDrawId}
              openLineItemDrawer={openLineItemDrawer}
              projectId={projectId}
              setOpenLineItemDrawer={setOpenLineItemDrawer}
            />
          )}
      </Form>
      <Pane height={openLineItemDrawer ? 290 : 40} width="100%" />
    </Pane>
  );
}

DocumentReviewLayout.propTypes = {
  autofilledFieldCount: PropTypes.number,
  children: PropTypes.node,
  document: PropTypes.shape({
    comments: PropTypes.array,
  }),
  documentMissingInformation: PropTypes.bool,
  information: PropTypes.func,
  meta: PropTypes.func,
  form: PropTypes.object,
  title: PropTypes.func,
  onReview: PropTypes.func,
  onComment: PropTypes.func,
  onChangeDraw: PropTypes.func,
  disableInformation: PropTypes.bool,
  disableActivity: PropTypes.bool,
  disableHeader: PropTypes.bool,
  disableWarnings: PropTypes.bool,
  shouldShowAutofilledField: PropTypes.func,
};

DocumentReviewLayout.defaultProps = {
  autofilledFieldCount: 0,
  documentMissingInformation: false,
  shouldShowAutofilledField: () => false,
  title: (title) => title,
};

export default DocumentReviewLayout;
