import { RULE_STATE, RULE_TYPE } from "helpers/enums";
import { ListItem, UnorderedList } from "components/materials";
import { get, uniqBy } from "lodash";
import t from "helpers/translate";

export const hasFailedRules = (item) => {
  return get(item, "rules", []).some((rule) => validRuleFailed(rule));
};

export const failedRules = (item) => {
  return (
    <UnorderedList>
      {uniqBy(item.rules, "name").map((rule) => {
        if (validRuleFailed(rule)) {
          // Check to see if there's an existing "FAILED_LINE_ITEM" version of message,
          // if not, fall back to the "FAILED" version of the message.
          const fallbackMessage = t(`drawChecks.${rule.name}.${rule.state}`);

          return (
            <ListItem key={rule.id} color="white">
              {t(
                `drawChecks.${rule.name}.${rule.state}LineItem`,
                fallbackMessage
              )}
            </ListItem>
          );
        }
        return null;
      })}
    </UnorderedList>
  );
};

export const enabledRule = ({ enabled }) => enabled;

export const validRuleFailed = ({ enabled, state }) =>
  enabled && state === RULE_STATE.PENDING;

export const filterEnabledRuleByStatus = (rule, state) =>
  rule.enabled && rule.state === state;

export const formatRuleName = ({ name, type }) =>
  type === RULE_TYPE.AUTOMATED ? t(`drawRules.${name}`) : name;

export function getRulesCountsForDraw(draw) {
  const enabledRules = (draw?.rules ?? []).filter(enabledRule);
  return {
    totalRulesCount: enabledRules.length,
    passedRulesCount: enabledRules.filter(
      ({ state }) => state === RULE_STATE.PASSED
    ).length,
    failedRulesCount: enabledRules.filter(
      ({ state }) => state === RULE_STATE.PENDING
    ).length,
    ignoredRulesCount: enabledRules.filter(
      ({ state }) => state === RULE_STATE.IGNORED
    ).length,
  };
}
