import { useContext, useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { REVIEW_FRAGMENT } from "helpers/fragments";
import { FreezeConfirmModal } from "components/containers/FreezeConfirmModal";
import { Modal } from "components/materials";
import UploadFilesTab from "./UploadFilesTab";
import Navigation from "./Navigation";
import Create92403Page2Tab from "./Create92403Page2Tab";
import CreateInvoiceTab from "./CreateInvoiceTab";

const UNFREEZE_DRAW = gql`
  mutation UploadFilesButtonUnfreezeDraw(
    $projectId: String!
    $targetId: String!
  ) {
    unfreezeDraw(projectId: $projectId, targetId: $targetId) {
      id
      isFrozen
      state
    }
    resetReviewsOnTarget(projectId: $projectId, targetId: $targetId) {
      ...ReviewFragment
    }
  }
  ${REVIEW_FRAGMENT}
`;

export interface FileUploadModalProps {
  drawId?: string;
  projectId: string;
  isFrozen: boolean;
  open: boolean;
  targetName: string;
  onSubmit: Function;
  onCancel: (...args: any[]) => any;
}

const defaultTabs = [] as string[];
const defaultProps = { width: 750 };

export const TABS = {
  UPLOAD: "UPLOAD",
  CREATE: "CREATE",
  CREATE_92403: "CREATE_92403",
};

export function FileUploadModal({
  drawId,
  isFrozen = false,
  open,
  projectId,
  targetName,
  onSubmit,
  onCancel,
}: FileUploadModalProps) {
  const { hasPermission }: { hasPermission: Function } = useContext(
    UserContext
  ) as any;
  const [unfreeze, unfreezeResult] = useMutation(UNFREEZE_DRAW);
  const [selectedTab, setSelectedTab] = useState(TABS.UPLOAD);
  const [tabs, setTabs] = useState(defaultTabs);
  const [modalProps, setModalProps] = useState(defaultProps);

  useEffect(() => {
    if (!open) {
      setSelectedTab(TABS.UPLOAD);
    }
  }, [open]);

  const handleFreeze = useCallback(() => {
    const variables = { projectId, targetId: drawId };
    unfreeze({ variables });
  }, [drawId, projectId, unfreeze]);

  const handleEnableTab = useCallback(
    (tab: string) => () => {
      setTabs((prev: string[]) => {
        if (prev.includes(tab)) return prev;
        return [...prev, tab];
      });
    },
    [setTabs]
  );

  const handleSetModalProps = useCallback(
    (newProps: any) => {
      let originalProps = defaultProps;

      setModalProps((props) => {
        originalProps = props;
        return newProps;
      });

      return () => setModalProps(originalProps);
    },
    [setModalProps]
  );

  if (!open || !hasPermission(PERMISSION_ACTION.UPLOAD_DOCUMENT)) return null;

  return (
    <Fragment>
      <FreezeConfirmModal
        loading={unfreezeResult.loading}
        onCancel={onCancel}
        onConfirm={handleFreeze}
        open={isFrozen && open}
        projectId={projectId}
        targetId={drawId}
        targetType="draw"
      />
      <Modal
        open={!isFrozen && open}
        title={`Add Documents to ${targetName}`}
        onClose={onCancel}
        {...modalProps}
      >
        <Navigation
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <UploadFilesTab
          selected={selectedTab === TABS.UPLOAD}
          enableTab={handleEnableTab(TABS.UPLOAD)}
          drawId={drawId}
          projectId={projectId}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
        <Create92403Page2Tab
          selected={selectedTab === TABS.CREATE_92403}
          enableTab={handleEnableTab(TABS.CREATE_92403)}
          drawId={drawId}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
        <CreateInvoiceTab
          selected={selectedTab === TABS.CREATE}
          enableTab={handleEnableTab(TABS.CREATE)}
          setModalProps={handleSetModalProps}
          projectId={projectId}
          drawId={drawId}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    </Fragment>
  );
}
