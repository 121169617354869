import PropTypes from "prop-types";
import Box from "ui-box";
import { withTheme } from "evergreen-ui";
import { boxProps, boxPropTypes } from "components/materials/utils";
import { getProps, compose, withMemo } from "./utils";

const TableFoot = compose(
  ({ children, ...props }) => {
    return (
      <Box is="tfoot" {...boxProps(props)}>
        {children}
      </Box>
    );
  },
  [withMemo, withTheme]
);

export const propTypes = {
  ...boxPropTypes,
  children: PropTypes.node,
};

TableFoot.propTypes = propTypes;

// TODO: remove dot notation
TableFoot.getProps = (props) => getProps(propTypes, props);

export default TableFoot;
