import { get, map } from "lodash";

export function userAssignablePermissions(organization) {
  return get(organization, "userAssignablePermissions", []).reduce(
    (acc, action) => ({
      ...acc,
      [action.toUpperCase()]: true,
    }),
    {}
  );
}

export function formatPermissions(permissions) {
  return map(permissions, (enabled, action) => ({ action, enabled }));
}
