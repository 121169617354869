import { Fragment } from "react";
import { BlankSlate } from "components/templates";
import { Alert, Button, Loadable, Pane, Link } from "components/materials";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { tablePlaceholder } from "images";
import {
  useProjection,
  UPDATING_PROJECTION_STATE,
} from "hooks/use-projections";
import analytics from "helpers/analytics";
import { NavigationWarnings } from "helpers/behaviors";
import { LineItemProjectionsView } from "./LineItemProjectionsView";
import { FundingSourceProjectionsView } from "./FundingSourceProjectionsView";
import { ProjectionViewControls } from "./ProjectionViewControls";
import { ProjectionWarning } from "./ProjectionWarning";
import { PROJECTION_EVENT } from "./enums";

const LINE_ITEM_CHART_ID = "projection-graph";
const FUNDING_SOURCE_CHART_ID = "funding-source-projection-graph";

export function ProjectionsPage({ match }) {
  const { projectId } = match.params;

  const [
    projection,
    loadingProjection,
    loadingFundingSources,
    isSaving,
    errors,
    actions,
  ] = useProjection(projectId);

  function handleProjectionsChange(event) {
    actions.dispatch(event.type, event.data);
  }

  if (loadingProjection) return <Loadable loading />;

  if (errors) {
    return (
      <Alert intent="danger" title="We weren’t able to load your projections">
        Please refresh and try again. If the problem persists contact your
        partner success representative.
      </Alert>
    );
  }

  if (!projection.hasBudget) {
    return (
      <BlankSlate
        attached
        prompt={t("projection.missingBudget")}
        src={tablePlaceholder}
        to={`/projects/${projectId}/budget`}
      />
    );
  }

  if (!projection.hasDates) {
    return (
      <BlankSlate
        attached
        prompt={t("projection.missingRequiredInformation")}
        src={tablePlaceholder}
        to={`/projects/${projectId}/settings?settings=details`}
      />
    );
  }

  return (
    <Fragment>
      {projection.state === UPDATING_PROJECTION_STATE && (
        <NavigationWarnings dirty />
      )}
      <ProjectionWarning
        project={projection.project}
        projectionUpdatedAt={projection.updatedAt}
      />
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginY={majorScale(1)}
        marginX={majorScale(4)}
      >
        <ProjectionViewControls
          showFundingSources={projection.views.showFundingSources}
          onViewChange={({ showFundingSources }) => {
            if (showFundingSources) {
              analytics.track("Projection Funding Sources Viewed", {
                projectId,
              });
            } else {
              analytics.track("Projection Line Items Viewed", { projectId });
            }
            handleProjectionsChange({
              type: PROJECTION_EVENT.VIEW_WAS_UPDATED,
              data: { showFundingSources },
            });
          }}
        />
        <Pane>
          {projection.views.showProjectionCharts && (
            <Button
              marginRight={majorScale(2)}
              onClick={() => {
                handleProjectionsChange({
                  type: PROJECTION_EVENT.PROJECTION_CHARTS_UPDATED,
                  data: { showProjectionCharts: false },
                });
              }}
            >
              Hide Graphs
            </Button>
          )}
          <Link
            href="https://help.rabbet.com/en/articles/5180724-cost-projections-tracking"
            content="How to build projections"
          />
        </Pane>
      </Pane>

      {projection.views.showFundingSources ? (
        <FundingSourceProjectionsView
          fundingSourceChartId={FUNDING_SOURCE_CHART_ID}
          handleProjectionsChange={handleProjectionsChange}
          isSaving={isSaving}
          loadingFundingSources={loadingFundingSources}
          projection={projection}
        />
      ) : (
        <LineItemProjectionsView
          handleProjectionsChange={handleProjectionsChange}
          isSaving={isSaving}
          lineItemChartId={LINE_ITEM_CHART_ID}
          loadingFundingSources={loadingFundingSources}
          projection={projection}
        />
      )}
    </Fragment>
  );
}
