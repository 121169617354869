import { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Form,
  Heading,
  Link,
  ListItem,
  MentionsCommentField,
  Modal,
  Pane,
  Text,
  UnorderedList,
} from "components/materials";
import { RULE_TYPE } from "helpers/enums";
import { sortBy } from "lodash";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { majorScale, ThemeContext } from "helpers/utilities";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import DrawRuleSelect from "./DrawRuleSelect";
import { RuleLabel } from "./RuleLabel";
import { CommentList } from "../Comments/CommentList";

const DrawRuleModal = ({
  autoRuleState,
  closeRuleModal,
  comment,
  commentResult,
  drawId,
  modalRuleId,
  projectId,
  rule,
  setManually,
  transition,
  transitionResult,
  users,
}) => {
  const [modalBody, setModalBody] = useState(undefined);
  const [modalTransitionVariables, setModalTransitionVariables] = useState(
    undefined
  );
  const [open, setOpen] = useState(true);

  const theme = useContext(ThemeContext);

  return (
    <Modal
      open={modalRuleId && open}
      onClose={() => {
        closeRuleModal();
        setModalBody("");
      }}
      title={
        rule.type === RULE_TYPE.AUTOMATED ? (
          <RuleLabel
            rule={rule}
            size={600}
            fontWeight={theme.fontWeights.MEDIUM}
          />
        ) : (
          rule.name
        )
      }
    >
      <Modal.Content>
        {rule.type === RULE_TYPE.AUTOMATED && (
          <Pane>
            <Text>{t(`drawRulesExplanation.${rule.name}`)} </Text>
            <Link
              purpose="draw-rules help"
              href="https://help.rabbet.com/en/articles/3329395-reviewing-draw-rules"
            >
              Learn More
            </Link>
          </Pane>
        )}
        <Divider />
        <DrawRuleSelect
          autoRuleState={autoRuleState}
          drawId={drawId}
          isModal
          modalRuleId={modalRuleId}
          modalTransitionVariables={modalTransitionVariables}
          rule={rule}
          setManually={setManually}
          setModalTransitionVariables={setModalTransitionVariables}
          transition={transition}
          transitionResult={transitionResult}
          users={users}
        />
        <Divider />
        {rule.lineItems.length > 0 && rule.type === RULE_TYPE.AUTOMATED && (
          <Fragment>
            <Heading content="Applicable line items" />
            <UnorderedList listStyleType="none">
              {rule.lineItems.map((lineItem) => (
                <ListItem key={lineItem.id}>
                  <Link
                    purpose="draw-rules line-item"
                    content={lineItem.name}
                    to={`/projects/${projectId}/draws/${drawId}/line_items/${lineItem.id}`}
                  />
                </ListItem>
              ))}
            </UnorderedList>
          </Fragment>
        )}
        <Divider />
        <CommentList
          label="History"
          comments={sortBy(rule.activities, "insertedAt")}
          showTarget={false}
        />
        <Form onSubmit={preventEventBubbling}>
          <MentionsCommentField
            onChange={(event) => setModalBody(event.target.value)}
            placeholder="Add a comment"
            users={users}
            value={modalBody}
          />
          <Pane
            display="flex"
            justifyContent="flex-end"
            marginTop={majorScale(1)}
          >
            <Button
              content="Cancel"
              purpose="rule comment cancel"
              onClick={() => {
                setOpen(false);
              }}
              marginRight={majorScale(1)}
            />
            <Form.SubmitButton
              label="Save"
              purpose="rule comment submit"
              disabled={isBlank(modalBody) && !modalTransitionVariables}
              isLoading={commentResult.loading}
              onClick={() => {
                if (modalTransitionVariables)
                  transition({ variables: modalTransitionVariables });

                if (!isBlank(modalBody))
                  comment({
                    variables: { drawId, ruleId: modalRuleId, body: modalBody },
                  });

                setOpen(false);
              }}
            />
          </Pane>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

DrawRuleModal.propTypes = {
  closeRuleModal: PropTypes.func.isRequired,
  comment: PropTypes.func.isRequired,
  commentResult: PropTypes.object.isRequired,
  drawId: PropTypes.string.isRequired,
  modalRuleId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  rule: PropTypes.object.isRequired,
  transition: PropTypes.func.isRequired,
  transitionResult: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

export default DrawRuleModal;
