import { useContext } from "react";
import { Text, Tooltip, Pane, Paragraph, Progress } from "components/materials";
import {
  majorScale,
  minorScale,
  ThemeContext,
  Position,
} from "helpers/utilities";
import { subtract, sumBy } from "helpers/math";
import { BRAND_COLORS } from "helpers/colors";
import { formatCurrency } from "helpers/formatCurrency";
import { css } from "glamor";

export function DivisionTooltip({ division, theme }) {
  const textProps = {
    size: 300,
    color: "white",
  };

  const rowProps = {
    display: "flex",
    justifyContent: "space-between",
    width: 200,
  };

  return (
    <Pane padding={majorScale(1)}>
      <Paragraph
        {...textProps}
        lineHeight={1}
        fontWeight={theme.fontWeights.DEMI}
        marginBottom={minorScale(1)}
      >
        {division.name}
      </Paragraph>
      <Pane {...rowProps}>
        <Text {...textProps}>Original Budget:</Text>
        <Text {...textProps}>{formatCurrency(division.originalBudget)}</Text>
      </Pane>
      <Pane {...rowProps}>
        <Text {...textProps}>Current Budget:</Text>
        <Text {...textProps}>{formatCurrency(division.budget)}</Text>
      </Pane>
      <Pane {...rowProps}>
        <Text {...textProps}>Requested to Date:</Text>
        <Text {...textProps}>{formatCurrency(division.requested)}</Text>
      </Pane>
      <Pane {...rowProps}>
        <Text {...textProps}>Remaining:</Text>
        <Text {...textProps}>
          {formatCurrency(subtract(division.budget, division.requested))}
        </Text>
      </Pane>
    </Pane>
  );
}

function DetailedSummaryHeader() {
  return (
    <thead>
      <tr>
        <th aria-label="Division" />
        <th>
          <Paragraph color="muted" fontSize={11} textAlign="left">
            Used
          </Paragraph>
        </th>
        <th aria-label="Progress" width="35%" />
        <th>
          <Paragraph color="muted" fontSize={11} textAlign="left">
            Budget
          </Paragraph>
        </th>
      </tr>
    </thead>
  );
}

function DetailedSummaryRow({ division, theme }) {
  const className = css({
    maxWidth: "110px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  });

  return (
    <tr>
      <td className={className}>
        <Tooltip
          position={Position.TOP}
          content={<DivisionTooltip division={division} theme={theme} />}
        >
          <Text color="muted" fontSize={11}>
            {division.name}
          </Text>
        </Tooltip>
      </td>
      <td>
        <Text fontSize={11}>{formatCurrency(division.requested)}</Text>
      </td>
      <td aria-label="progress">
        <Pane paddingX={minorScale(1)}>
          <Progress
            background="#cadefa"
            color={BRAND_COLORS.LIGHT_PRIMARY}
            height={majorScale(1)}
            total={division.budget}
            value={division.requested}
            width="100%"
          />
        </Pane>
      </td>
      <td>
        <Text fontSize={11}>{formatCurrency(division.budget)}</Text>
      </td>
    </tr>
  );
}

function DetailedSummaryTotal({ divisions, theme }) {
  const totalRequested = sumBy(divisions, "requested");
  const totalBudget = sumBy(divisions, "budget");

  return (
    <tfoot>
      <tr>
        <td>
          <Paragraph
            color="muted"
            fontSize={11}
            fontWeight={theme.fontWeights.DEMI}
            paddingRight={majorScale(1)}
            textAlign="right"
          >
            Total
          </Paragraph>
        </td>
        <td>
          <Text fontSize={11} fontWeight={theme.fontWeights.MEDIUM}>
            {formatCurrency(totalRequested)}
          </Text>
        </td>
        <td aria-label="progress">
          <Pane paddingX={minorScale(1)}>
            <Progress
              background="lightgrey"
              color={theme.colors.gray800}
              height={majorScale(1)}
              total={totalBudget}
              value={totalRequested}
              width="100%"
            />
          </Pane>
        </td>
        <td>
          <Text fontSize={11} fontWeight={theme.fontWeights.MEDIUM}>
            {formatCurrency(totalBudget)}
          </Text>
        </td>
      </tr>
    </tfoot>
  );
}

export function DetailedDivisionsSummary({ divisions }) {
  const theme = useContext(ThemeContext);

  const compactProps = {
    lineHeight: "0px",
    padding: "0px",
    margin: "0px",
  };

  const compactStyles =
    divisions.length > 14
      ? {
          "& th": compactProps,
          "& td": compactProps,
        }
      : {
          "& tbody td": {
            borderTop: `1px solid ${theme.colors.gray500}`,
          },
        };

  const className = css({
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    "& tfoot td": {
      borderTop: `1px solid ${theme.colors.gray500}`,
    },
    ...compactStyles,
  });

  return (
    <Pane padding={majorScale(1)}>
      <table className={className}>
        <DetailedSummaryHeader />
        <tbody>
          {divisions.map((division) => (
            <DetailedSummaryRow
              key={division.id}
              division={division}
              theme={theme}
            />
          ))}
        </tbody>
        <DetailedSummaryTotal divisions={divisions} theme={theme} />
      </table>
    </Pane>
  );
}
