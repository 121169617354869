import { useContext, Fragment } from "react";
import { Button, Link, Pane, Text } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import t from "helpers/translate";
import { get } from "lodash";
import { toDateHistory, fullHistory } from "images";
import { STEPS } from "./utils";
import { OnboardingWizardDecisionCard } from "./OnboardingWizardDecisionCard";
import { Header } from "./Header";

export function DrawDecisionStep({
  goBack,
  navigateToStep,
  project,
  updateOnboardingData,
}) {
  const { isDeveloper } = useContext(UserContext);
  function updateDrawHistoryDecision(fullDrawHistory) {
    const isNewDecision =
      get(project, "onboardingData.fullDrawHistory") !== fullDrawHistory;
    const variables = {
      fullDrawHistory,
      ...(isNewDecision ? { draws: [] } : {}),
    };
    updateOnboardingData(variables).then(() => {
      if (fullDrawHistory) navigateToStep(STEPS.PREVIOUS_DRAW_NAMES);
      else navigateToStep(STEPS.TO_DATE_DRAW);
    });
  }

  return (
    <Fragment>
      <Header
        header={
          isDeveloper
            ? t("onboardingWizard.drawDecision.developerHeader")
            : t("onboardingWizard.drawDecision.lenderHeader")
        }
        subheader={t("onboardingWizard.drawDecision.subheader")}
      />
      <Pane display="flex">
        <OnboardingWizardDecisionCard
          backgroundColor
          buttonPrimary
          header="Full History"
          image={fullHistory}
          imageHeight={150}
          onClick={() => updateDrawHistoryDecision(true)}
          subHeader={t("onboardingWizard.drawDecision.fullHistory")}
        />
        <OnboardingWizardDecisionCard
          backgroundColor
          buttonPrimary
          header="To-Date Draw"
          image={toDateHistory}
          imageHeight={150}
          onClick={() => updateDrawHistoryDecision(false)}
          subHeader={t("onboardingWizard.drawDecision.toDateDraw")}
        />
      </Pane>
      <Pane marginTop={majorScale(3)}>
        <Pane marginBottom={majorScale(1)}>
          <Text>{t("onboardingWizard.help")}</Text>
          <Link
            href="mailto:help@rabbet.com"
            marginLeft={minorScale(1)}
            purpose="project-wizard-draw-history-help"
          >
            help@rabbet.com
          </Link>
        </Pane>
        <Button
          onClick={() => {
            goBack();
          }}
        >
          Back
        </Button>
      </Pane>
    </Fragment>
  );
}
