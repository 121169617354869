import { useState, Fragment } from "react";
import { Heading, IconButton, Pane, Text, Tooltip } from "components/materials";
import { ChangesIcon } from "evergreen-ui";
import { REVIEW_STATE } from "helpers/enums";
import { majorScale, minorScale } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { EditTargetAmount } from "./EditTargetAmount";

export function DrawDetails({ draw, isIncompleteDraw }) {
  const [showEditTargetAmount, setShowEditTargetAmount] = useState(false);

  const { name, requestedAmount, targetAmount, reviews } = draw;
  const drawHasUnsplitUploads = draw.uploads.length > 0;
  const drawApprovalsCount = `${
    reviews.filter((review) => review.state === REVIEW_STATE.APPROVED).length
  } / ${reviews.length}`;

  return (
    <Fragment>
      <Pane display="flex" alignItems="end" marginLeft={majorScale(2)}>
        <Text fontSize={24} fontWeight={700} marginRight={majorScale(5)}>
          {name}
        </Text>
        <DrawDetail
          label="Draw Total"
          value={formatCurrency(requestedAmount)}
          valueProps={{ color: "#3366FF" }}
          labelExplanation={
            drawHasUnsplitUploads
              ? "You have documents waiting to be split that may be contributing toward the draw total."
              : null
          }
          marginRight={majorScale(5)}
          marginBottom={-3}
        />
        {isIncompleteDraw && (
          <DrawDetail
            label="Target Amount"
            value={formatCurrency(targetAmount)}
            action={
              <IconButton
                icon={ChangesIcon}
                appearance="minimal"
                onClick={() => setShowEditTargetAmount(true)}
                marginLeft={minorScale(1)}
                height={20}
              />
            }
            marginRight={majorScale(5)}
            marginBottom={-3}
          />
        )}
        <DrawDetail
          label="Draw Approvals"
          value={drawApprovalsCount}
          marginBottom={-3}
        />
      </Pane>
      {/* MODALS */}
      <EditTargetAmount
        open={showEditTargetAmount}
        draw={draw}
        onClose={() => setShowEditTargetAmount(false)}
      />
    </Fragment>
  );
}

export function DrawDetail({
  label,
  value,
  labelExplanation,
  action,
  valueProps,
  ...containerProps
}) {
  return (
    <Pane minWidth={80} {...containerProps}>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={16}
      >
        <Heading
          color="#7f8f9f"
          fontSize={10}
          fontWeight={600}
          lineHeight={1.25}
          textTransform="uppercase"
        >
          {label}
        </Heading>
        {labelExplanation && (
          <Tooltip content={labelExplanation}>
            <Text
              cursor="default"
              fontSize={16}
              fontWeight={600}
              marginLeft={2}
            >
              *
            </Text>
          </Tooltip>
        )}
      </Pane>
      <Pane display="flex" alignItems="center" justifyContent="center">
        <Text fontSize={18} fontWeight={600} lineHeight={1.25} {...valueProps}>
          {value}
        </Text>
        {action}
      </Pane>
    </Pane>
  );
}
