import React from "react";
import { Pane, Heading, ConfigureButtons } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";

export default function EmptyCard({
  name,
  children,
  icon,
  isConfigurable,
  isDisabled,
  cardName,
  cards,
  setCards,
}) {
  const theme = React.useContext(ThemeContext);
  return (
    <Pane
      height="100%"
      width="100%"
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
    >
      <Pane
        id="header"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            lineHeight={1.2}
            fontWeight={500}
            marginRight={minorScale(3)}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            {name}
          </Heading>
          {icon}
        </Pane>
        {isConfigurable && (
          <ConfigureButtons
            name={cardName}
            cards={cards}
            setCards={setCards}
            isDisabled={isDisabled}
          />
        )}
      </Pane>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="75%"
        flexDirection="column"
      >
        {children}
      </Pane>
    </Pane>
  );
}
