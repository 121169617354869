import { Fragment } from "react";
import { TimeIcon } from "evergreen-ui";
import {
  Alert,
  Card,
  Link,
  Pane,
  Paragraph,
  Shortener,
} from "components/materials";
import { flattenDeep, sortBy } from "lodash";
import { formatDate, formatTrimDate, isBefore } from "helpers/dateHelpers";
import t from "helpers/translate";
import { TASK_STATUS } from "helpers/enums";
import { majorScale, minorScale } from "helpers/utilities";
import { PIE_CHART_COLORS } from "helpers/colors";
import {
  getSerializedURLForTasksReport,
  TASK_HISTORY_START_DATE,
} from "./helpers";
import { HeaderTextCell, TextCell } from ".";

function TaskRow({ task }) {
  return (
    <tr>
      <TextCell>
        <Shortener size={400} text={task.projectName} limit={30} />
      </TextCell>
      <TextCell>
        <Shortener size={400} text={task.eventName} limit={32} />
      </TextCell>
      <TextCell>{formatTrimDate(task.projectedCompletionDate)}</TextCell>
      <TextCell>
        <Pane display="flex" alignItems="center">
          {task.status === TASK_STATUS.IN_PROGRESS && (
            <TimeIcon
              size={12}
              color={PIE_CHART_COLORS.BASE_BLUE}
              marginRight={minorScale(1)}
            />
          )}
          <Fragment>{t(`taskStatus.${task.status}`)}</Fragment>
        </Pane>
      </TextCell>
    </tr>
  );
}

function OverdueTasksWarning({
  navigateToTasksReport,
  projectsWithOverdueTasks,
}) {
  return (
    <Alert
      intent="warning"
      borderRadius={majorScale(1)}
      width="80%"
      marginBottom={majorScale(1)}
      onClick={navigateToTasksReport}
      cursor="pointer"
    >
      <Link
        purpose="go to tasks report"
        onClick={navigateToTasksReport}
        size={300}
      >
        {t("portfolioInsightsPage.alerts.tasks", {
          count: projectsWithOverdueTasks.length,
        })}
      </Link>
    </Alert>
  );
}

export function Tasks({ history, organizationId, projects, stylingProps }) {
  function navigateToTasksReport(startDate, endDate, showCompleted) {
    return history.push(
      getSerializedURLForTasksReport(
        startDate,
        endDate,
        showCompleted,
        organizationId
      )
    );
  }
  const todayDate = formatDate(new Date());
  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const upcomingTasks = flattenDeep(
    projects.map((project) => project.tasks)
  ).filter(
    ({ projectedCompletionDate, status }) =>
      status !== TASK_STATUS.COMPLETE &&
      !isBefore(projectedCompletionDate, todayDate)
  );

  const sortedUpcomingTasks = sortBy(upcomingTasks, "projectedCompletionDate");
  const tasksToDisplay = sortedUpcomingTasks.slice(0, 10);
  const latestTaskCompletionDate = formatDate(
    sortedUpcomingTasks[sortedUpcomingTasks.length - 1]?.projectedCompletionDate
  );

  const projectsWithOverdueTasks = projects.filter(({ tasks }) =>
    tasks.some((task) => task.isOverdue)
  );

  return (
    <Fragment>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        {t(`portfolioInsightsPage.blankSlate.header.tasks`)}
      </Paragraph>
      <Card {...stylingProps.card}>
        <Pane
          display="flex"
          alignItems="center"
          marginTop={majorScale(3)}
          marginX={majorScale(1)}
          justifyContent="end"
        >
          {projectsWithOverdueTasks.length > 0 && (
            <OverdueTasksWarning
              navigateToTasksReport={() =>
                navigateToTasksReport(
                  TASK_HISTORY_START_DATE,
                  formatDate(yesterdayDate),
                  false
                )
              }
              projectsWithOverdueTasks={projectsWithOverdueTasks}
            />
          )}
          <Paragraph flexGrow={1} textAlign="end">
            <Link
              marginTop={minorScale(3)}
              purpose="go to tasks report"
              onClick={() =>
                navigateToTasksReport(todayDate, latestTaskCompletionDate, true)
              }
              size={300}
              justifyItem="end"
            >
              Go to Tasks
            </Link>
          </Paragraph>
        </Pane>

        <Pane>
          {upcomingTasks.length === 0 ? (
            <Paragraph
              alignItems="center"
              textAlign="center"
              padding={majorScale(3)}
            >
              {t(`portfolioInsightsPage.blankSlate.info.tasks`)}
            </Paragraph>
          ) : (
            <Fragment>
              <Pane
                maxHeight={250}
                overflowY="scroll"
                marginTop={majorScale(2)}
              >
                <table {...stylingProps.table}>
                  <thead>
                    <tr>
                      <HeaderTextCell>Project</HeaderTextCell>
                      <HeaderTextCell>Task</HeaderTextCell>
                      <HeaderTextCell>Projected Completion</HeaderTextCell>
                      <HeaderTextCell colSpan={2}>Status</HeaderTextCell>
                    </tr>
                  </thead>
                  <tbody>
                    {tasksToDisplay.map((task) => (
                      <TaskRow key={task.id} task={task} />
                    ))}
                  </tbody>
                </table>
              </Pane>
            </Fragment>
          )}
        </Pane>
      </Card>
    </Fragment>
  );
}
