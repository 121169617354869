import { Pane, Table, Paragraph } from "components/materials";
import { majorScale } from "helpers/utilities";

export function AdjustmentsHeader({ theme }) {
  const headerCellProps = {
    width: "17%",
    background: "white",
    borderTop: "none",
    borderBottom: "none",
    textAlign: "right",
    textProps: { fontWeight: theme.fontWeights.MEDIUM },
  };
  return (
    <Pane
      display="flex"
      alignItems="center"
      marginLeft={majorScale(6)}
      marginRight={123}
      maxWidth={1343}
    >
      <Paragraph
        flex="0 0 300px"
        size={300}
        fontWeight={theme.fontWeights.MEDIUM}
      >
        Description
      </Paragraph>
      <Pane flex="1 1 auto">
        <Table paddingBottom={0} hideHeaderBorder hover={false}>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell
                {...headerCellProps}
                textAlign="left"
                width="32%"
              >
                Line Item(s)
              </Table.TextHeaderCell>
              <Table.TextHeaderCell {...headerCellProps}>
                Current Budget Before Adjustment
              </Table.TextHeaderCell>
              <Table.TextHeaderCell {...headerCellProps}>
                Adjustment(s)
              </Table.TextHeaderCell>
              <Table.TextHeaderCell {...headerCellProps}>
                Current Budget After Adjustment
              </Table.TextHeaderCell>
              <Table.TextHeaderCell {...headerCellProps}>
                Balance Remaining After Adjustment
              </Table.TextHeaderCell>
            </Table.Row>
          </Table.Head>
        </Table>
      </Pane>
    </Pane>
  );
}
