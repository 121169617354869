import { useState, useContext } from "react";
import {
  Button,
  Card,
  Pane,
  Paragraph,
  Progress,
  Shortener,
  StackedBarChart,
  Tab,
  Tablist,
} from "components/materials";
import {
  VictoryTheme,
  VictoryContainer,
  VictoryPie,
  VictoryTooltip,
} from "victory";
import { find, groupBy, includes, keys, map, max, omit } from "lodash";
import { BRAND_COLORS, COLORS, getGraphColor } from "helpers/colors";
import { majorScale, minorScale } from "helpers/utilities";
import { ORGANIZATION_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import { getCommitmentAggregatesForGroupedProjects } from "helpers/fundingSourceHelpers";
import { add, divide, multiply } from "helpers/math";
import {
  formatCurrency,
  formatShortenedCurrency,
} from "helpers/formatCurrency";
import t from "helpers/translate";
import {
  getSerializedURLForProjectReport,
  trackPortfolioAnalysis,
} from "./helpers";
import { HeaderTextCell, PortfolioInsightBlankSlateCard, TextCell } from ".";

const COMPOSITION_VIEWS_CONFIG = {
  PRODUCT_TYPE: {
    groupingFunction: (project) => project?.productType?.type,
    defaultGroup: "(No Type)",
    tableColumnId: "productType",
    getTableFilter: (value) => ({ enum: [value] }),
    defaultTableValue: "-",
  },
  REGION: {
    groupingFunction: (project) => project?.projectRegion?.region,
    defaultGroup: "(No Region)",
    tableColumnId: "region",
    getTableFilter: (value) => ({ enum: [value] }),
    defaultTableValue: "-",
  },
  BORROWER: {
    groupingFunction: (project) =>
      getStakeholder(project, ORGANIZATION_TYPE.BORROWER),
    defaultGroup: "(No Borrower)",
    tableColumnId: "borrowers",
    getTableFilter: (value) => ({ list: [value] }),
    defaultTableValue: "None",
  },
  GENERAL_CONTRACTOR: {
    groupingFunction: (project) =>
      getStakeholder(project, ORGANIZATION_TYPE.CONTRACTOR),
    defaultGroup: "(No General Contractor)",
    tableColumnId: "generalContractors",
    getTableFilter: (value) => ({ list: [value] }),
    defaultTableValue: "None",
  },
};

function getCompositionViewsToUse(
  canAccessStakeholders,
  isAdvancedReportDeveloper
) {
  const onlyUseBorrowerConfig =
    isAdvancedReportDeveloper || !canAccessStakeholders;
  const borrowerPermissionViews = onlyUseBorrowerConfig
    ? omit(COMPOSITION_VIEWS_CONFIG, "BORROWER")
    : COMPOSITION_VIEWS_CONFIG;

  // now check for if has access to general contractor
  return canAccessStakeholders
    ? borrowerPermissionViews
    : omit(borrowerPermissionViews, "GENERAL_CONTRACTOR");
}

export function PortfolioComposition({
  history,
  projects,
  stylingProps,
  selectedOrganization,
}) {
  const { id: organizationId } = selectedOrganization;
  const [compositionView, setCompositionView] = useState(
    keys(COMPOSITION_VIEWS_CONFIG)[0]
  );

  const { hasPermission } = useContext(UserContext);

  const canAccessStakeholders = hasPermission(
    PERMISSION_ACTION.ACCESS_STAKEHOLDERS,
    selectedOrganization
  );

  const isAdvancedReportDeveloper = hasPermission(
    PERMISSION_ACTION.ADVANCED_REPORT_DEVELOPER,
    selectedOrganization
  );

  // The developer portfolio insights doesn't include borrower information
  const COMPOSITION_VIEWS_CONFIG_TO_USE = getCompositionViewsToUse(
    canAccessStakeholders,
    isAdvancedReportDeveloper
  );

  if (projects.length === 0)
    return <PortfolioInsightBlankSlateCard cardName="portfolioComposition" />;

  const tableData = getTableData(compositionView, projects, organizationId);

  const [totalFunded, totalCommitted, totalProjectCount] = tableData.reduce(
    ([funded, committed, projectCount], data) => [
      add(funded, data.funded),
      add(committed, data.committed),
      add(projectCount, data.numProjects),
    ],
    [0, 0, 0]
  );

  const [pieData, barDatasets, graphColors] = getGraphData(tableData);

  return (
    <Pane>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        Portfolio Composition
      </Paragraph>
      <Card {...stylingProps.card}>
        <Pane margin={minorScale(1)}>
          <Tablist>
            {keys(COMPOSITION_VIEWS_CONFIG_TO_USE).map((view) => (
              <Tab
                key={view}
                isSelected={compositionView === view}
                onSelect={() => setCompositionView(view)}
              >
                {t(`portfolioInsightsPage.compositionViews.${view}`)}
              </Tab>
            ))}
          </Tablist>
        </Pane>
        <Pane display="flex">
          <Pane width="45%" display="flex" alignItems="center">
            <Pane flex={1}>
              <div style={{ height: 200, width: "80%" }}>
                <VictoryContainer
                  height={VictoryTheme.grayscale.pie.height}
                  width={VictoryTheme.grayscale.pie.width}
                >
                  <VictoryPie
                    standalone={false}
                    theme={VictoryTheme.grayscale}
                    data={pieData}
                    colorScale={graphColors}
                    padding={20}
                    labelComponent={
                      <VictoryTooltip
                        cornerRadius={5}
                        pointerLength={5}
                        flyoutStyle={{
                          fill: COLORS.BLACK,
                          stroke: "none",
                        }}
                        style={{
                          fill: BRAND_COLORS.WHITE,
                          fontSize: 20,
                          fontFamily: "AvenirNext, arial, sans-serif",
                        }}
                      />
                    }
                    labels={({ x, y }) =>
                      `${x}\n${y} ${y === 1 ? "Project" : "Projects"}`
                    }
                  />
                </VictoryContainer>
              </div>
            </Pane>
            <Pane
              flex={1}
              marginLeft={-majorScale(1)}
              marginRight={majorScale(4)}
            >
              <StackedBarChart
                colors={graphColors}
                datasets={barDatasets}
                xFormat={(x) => `${multiply(x, 10)}%`}
                xLabel={["Budget Spent (%)"]}
                yFormat={formatShortenedCurrency}
                yLabel={["Project Size (total)"]}
              />
            </Pane>
          </Pane>
          <Pane width="55%" height={225} overflowY="scroll">
            <table {...stylingProps.table}>
              <thead>
                <tr>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th colSpan={2} />
                  <HeaderTextCell colSpan={2}>Projects</HeaderTextCell>
                  <HeaderTextCell colSpan={2}>
                    Commitments Funded / Total Commitments
                  </HeaderTextCell>
                </tr>
              </thead>
              <tbody>
                {tableData.map((rowData) => {
                  const navigateToProjects = () => {
                    trackPortfolioAnalysis(
                      organizationId,
                      `View Projects (filtered by ${compositionView})`
                    );
                    return history.push(rowData.navigateUrl);
                  };
                  return (
                    <CompositionRow
                      key={rowData.groupName}
                      data={rowData}
                      navigateToProjects={navigateToProjects}
                    />
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <TextCell
                    colSpan={2}
                    style={{ textAlign: "right" }}
                    textProps={boldProps}
                  >
                    TOTAL
                  </TextCell>
                  <TextCell colSpan={2} textProps={boldProps}>
                    {totalProjectCount}
                  </TextCell>
                  <TextCell colSpan={2} textProps={boldProps}>
                    {`${formatShortenedCurrency(
                      totalFunded
                    )} / ${formatShortenedCurrency(totalCommitted)}`}
                  </TextCell>
                </tr>
              </tfoot>
            </table>
          </Pane>
        </Pane>
      </Card>
    </Pane>
  );
}

function CompositionRow({ navigateToProjects, data }) {
  const {
    color,
    committed,
    funded,
    groupName,
    numProjects,
    progressBarProportion,
  } = data;

  return (
    <tr>
      <td aria-label="projects pane">
        <Pane height={10} width={10} borderRadius="50%" background={color} />
      </td>
      <TextCell width="180px">
        <Shortener limit={17} size={300} text={groupName} />
      </TextCell>
      <TextCell>{numProjects}</TextCell>
      <td>
        <Button onClick={navigateToProjects}>View Projects</Button>
      </td>
      <TextCell>
        {`${formatShortenedCurrency(funded)} / ${formatShortenedCurrency(
          committed
        )}`}
      </TextCell>
      <td width="55%" aria-label="progress">
        <Pane width={`${100 * progressBarProportion}%`}>
          <Progress
            value={funded}
            total={committed}
            background="#cadefa"
            color={BRAND_COLORS.LIGHT_PRIMARY}
          />
        </Pane>
      </td>
    </tr>
  );
}

const DEFAULT_GROUPS = map(
  COMPOSITION_VIEWS_CONFIG,
  (attrs) => attrs.defaultGroup
);

function getStakeholder(project, role) {
  const foundStakeholder = find(project.stakeholders, {
    role,
  });
  return foundStakeholder?.organization?.name;
}

function getTableData(view, projects, organizationId) {
  const {
    groupingFunction,
    defaultGroup,
    tableColumnId,
    getTableFilter,
    defaultTableValue,
  } = COMPOSITION_VIEWS_CONFIG[view];
  const groupedProjects = groupBy(
    projects,
    (project) => groupingFunction(project) || defaultGroup
  );

  const groupData = keys(groupedProjects).map((groupName, index) => {
    const projectGroup = groupedProjects[groupName];
    const commitmentAggregates = getCommitmentAggregatesForGroupedProjects(
      projectGroup
    );
    const isDefaultGroup = includes(DEFAULT_GROUPS, groupName);
    const tableValue = isDefaultGroup ? defaultTableValue : groupName;
    const tableFilter = getTableFilter(tableValue);

    return {
      groupName,
      projects: projectGroup,
      numProjects: projectGroup.length,
      color: getGraphColor(index),
      funded: commitmentAggregates.totalDisbursedAmount,
      committed: commitmentAggregates.totalFundedAmount,
      navigateUrl: getSerializedURLForProjectReport(
        tableColumnId,
        tableFilter,
        organizationId
      ),
    };
  });

  const maxCommitment = max(groupData.map(({ committed }) => committed));

  return groupData
    .map((data) => ({
      ...data,
      progressBarProportion: divide(data.committed, maxCommitment),
    }))
    .sort((groupA, groupB) => groupB.numProjects - groupA.numProjects);
}

function getGraphData(tableData) {
  return tableData.reduce(
    ([pieData, barData, colors], projectGroup) => {
      const { groupName, numProjects, color } = projectGroup;
      const groupBarData = getBarDataForGroup(projectGroup);
      return [
        [...pieData, { x: groupName, y: numProjects }],
        [...barData, groupBarData],
        [...colors, color],
      ];
    },
    [[], [], []]
  );
}

function getBarDataForGroup({ groupName, projects }) {
  return {
    groupName,
    tooltipFormat: ({ x, y }) =>
      `${groupName}\n${multiply(x, 10)}% Complete\n${formatCurrency(y)}`,
    data: getProjectProgressBarChartData(projects),
  };
}

function getProjectProgressBarChartData(projects) {
  const count = projects.reduce(
    (count, project) => {
      const value = project.requestedAmount;
      const total = project.amount;
      const key =
        value && total ? Math.floor(multiply(divide(value, total), 10)) : 0;
      if (key >= 0 && key <= 10) {
        count[key] = add(count[key], total);
      }
      return count;
    },
    {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
    }
  );

  return map(count, (y, x) => ({ x: Number(x), y }));
}

const boldProps = { fontWeight: 600, color: "muted" };
