import { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { get, every, find, flatten } from "lodash";
import { CrossIcon } from "evergreen-ui";
import { VendorLink } from "components/templates";
import { Form, IconButton, Pane, Table } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import t from "helpers/translate";
import {
  formatLineItems,
  getLineItemOptions,
} from "helpers/invoiceLineItemHelpers";
import {
  getEmptyLineItem,
  getInitialValues,
  getVendorIdForDocument,
  getVendorIdFromForm,
  MethodSelector,
} from "helpers/lienReviewHelpers";
import { sumBy } from "helpers/math";
import isBlank from "helpers/isBlank";
import getOptions from "helpers/getOptions";
import DocumentReviewLayout from "./DocumentReviewLayout";
import DocumentLineItems from "./DocumentLineItems";

const getDocumentOptions = (documents) => {
  return documents.map((document) => {
    return {
      key: document.invoiceId,
      value: {
        id: document.invoiceId,
        drawId: document.drawId,
      },
      text: document.amount
        ? `${document.drawName} - ${t(`documentTypeName.${document.type}`)} - ${
            document.file.name
          } - ${formatCurrency(document.amount)}`
        : `${document.drawName} - ${t(`documentTypeName.${document.type}`)} - ${
            document.file.name
          }`,
    };
  });
};

const getDocumentDataFromForm = (values) => {
  if (values.isManual) {
    return {
      invoiceId: null,
      isBackup: true,
      isFinal: values.isFinal,
      lineItems: formatLineItems(values.lineItems),
      payApplicationId: null,
      targetDrawId: values.manualTargetDraw,
    };
  }

  return {
    invoiceId:
      values.target.type === DOCUMENT_TYPE_NAME.INVOICE
        ? values.target.id
        : null,
    isBackup: true,
    isFinal: values.isFinal,
    payApplicationId:
      values.target.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION
        ? values.target.id
        : null,
    targetDrawId: values.target.drawId,
  };
};

function getInformationSummary(form, draws) {
  const manuallyAssignedDrawId = get(form.values, "manualTargetDraw");
  const manuallyAssignedDrawName = get(
    draws.find((draw) => draw.id === manuallyAssignedDrawId),
    "name"
  );
  const summary = [get(form.values.vendor, "name"), manuallyAssignedDrawName];
  return summary.filter((value) => !!value).join(", ");
}

function isMissingInformation(values) {
  const lineItemsMissingInformation =
    get(values, "lineItems.length") === 0 ||
    every(
      values.lineItems,
      (lineItem) =>
        isBlank(lineItem.amount) || !get(lineItem, "lineItemObject.id")
    );
  if (
    values.isManual &&
    (!get(values, "vendor.id") || lineItemsMissingInformation)
  ) {
    return true;
  }
  if (!values.isManual && !get(values, "target.id")) {
    return true;
  }
  return false;
}

function UnconditionalLienWaiverReview(props) {
  const {
    draws,
    form,
    selectedDraw,
    getUnconditionalWaiverQuery,
    unconditionalWaiverQuery,
  } = props;

  useEffect(() => {
    if (!unconditionalWaiverQuery.called) {
      getUnconditionalWaiverQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unconditionalWaiverQuery.called]);

  const lazyDraws = get(unconditionalWaiverQuery, "data.project.draws", []);
  const payAppsAndInvoices = flatten(
    lazyDraws.map((draw) =>
      draw.payApplications.concat(draw.invoices).map((document) => ({
        ...document,
        drawId: draw.id,
        drawName: draw.name,
      }))
    )
  );

  return (
    <DocumentReviewLayout
      {...props}
      meta={(content) => (
        <Pane display="flex" width="100%" alignItems="center">
          {content}

          <Pane display="flex" flexGrow={1} alignItems="center">
            <Form.Checkbox
              name="isFinal"
              label="Final Lien Waiver"
              margin={0}
            />
          </Pane>
        </Pane>
      )}
      information={
        form.values.isManual
          ? (content) => (
              <Pane
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                width="100%"
              >
                <Pane flexGrow={2} marginBottom={majorScale(1)}>
                  {content}
                </Pane>

                <Pane
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <VendorLink
                    vendorId={form.values.vendor.id}
                    vendors={props.allAvailableVendors}
                  />
                </Pane>
                <Pane width="40%">
                  <Form.Select
                    label="Assign to Draw"
                    name="manualTargetDraw"
                    noNull
                    options={getOptions(draws)}
                  />
                </Pane>
              </Pane>
            )
          : null
      }
      informationSummary={getInformationSummary(form, draws)}
      selector={<MethodSelector form={form} />}
    >
      <Fragment>
        {!form.values.isManual ? (
          <Form.Section
            heading="Pay Application or Invoice"
            isCollapsible
            isCollapsed={props.collapsedPanels.includes("assignedTo")}
            onCollapse={() => props.togglePanel("assignedTo")}
          >
            <Pane display="flex" width="100%">
              <Form.Select
                popoverMinWidth={600}
                label="Assign to"
                name="target"
                noNull
                onChange={(selectedDocument) => {
                  form.setFieldValue(
                    "targetVendorId",
                    getVendorIdForDocument(payAppsAndInvoices, selectedDocument)
                  );
                }}
                options={getDocumentOptions(payAppsAndInvoices)}
                isWarned={isBlank(get(form, "values.target.id"))}
              />
            </Pane>
          </Form.Section>
        ) : (
          <DocumentLineItems getEmptyLineItem={getEmptyLineItem} {...props}>
            {({ remove }) => (
              <Table paddingBottom={0}>
                <Table.Head>
                  <Table.Row>
                    <Table.TextHeaderCell width="50%">
                      Line Item
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell width="50%">
                      Amount
                    </Table.TextHeaderCell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {form.values.lineItems.map((lineItem, index) => (
                    <Table.Row key={lineItem.lineItemObject.id || index}>
                      <Table.TextCell id="lineItem">
                        {!form.values.__disabled && (
                          <IconButton
                            appearance="minimal"
                            icon={CrossIcon}
                            marginRight={minorScale(1)}
                            onClick={() => remove(index)}
                            type="button"
                          />
                        )}
                        <Form.Select
                          isWarned={isBlank(
                            get(
                              form,
                              `values.lineItems.${index}.lineItemObject.id`
                            )
                          )}
                          name={`lineItems.${index}.lineItemObject`}
                          noNull
                          onChange={(selected) => {
                            const dli = find(
                              selectedDraw.lineItems,
                              (li) => li.id === selected.id
                            );
                            form.setFieldValue(
                              `lineItems.${index}.amount`,
                              formatCurrency(get(dli, "requestedAmount", 0))
                            );
                          }}
                          options={
                            selectedDraw
                              ? getLineItemOptions(selectedDraw.lineItems)
                              : []
                          }
                          placeholder="Select Line Item..."
                        />
                      </Table.TextCell>
                      <Table.TextCell id="amount">
                        <Form.Input
                          isWarned={isBlank(
                            get(form, `values.lineItems.${index}.amount`)
                          )}
                          name={`lineItems.${index}.amount`}
                          textAlign="right"
                          type="currency"
                        />
                      </Table.TextCell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Foot>
                  <Table.Row>
                    <Table.TextFooterCell />
                    <Table.TextFooterCell textAlign="right">
                      {formatCurrency(sumBy(form.values.lineItems, "amount"))}
                    </Table.TextFooterCell>
                  </Table.Row>
                </Table.Foot>
              </Table>
            )}
          </DocumentLineItems>
        )}
      </Fragment>
    </DocumentReviewLayout>
  );
}

UnconditionalLienWaiverReview.propTypes = {
  children: PropTypes.node,
  selectedDraw: PropTypes.object,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      number: PropTypes.string,
      date: PropTypes.string,
      lineItems: PropTypes.arrayOf({
        amount: PropTypes.number,
        lineItemObject: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    }),
  }),
  onApprove: PropTypes.func,
  onComment: PropTypes.func,
  onChangeDraw: PropTypes.func,
  onChangeType: PropTypes.func,
};

// TODO: remove dot notation
UnconditionalLienWaiverReview.getEmptyLineItem = getEmptyLineItem;
UnconditionalLienWaiverReview.getInitialValues = getInitialValues;
UnconditionalLienWaiverReview.getDocumentDataFromForm = getDocumentDataFromForm;
UnconditionalLienWaiverReview.getVendorIdFromForm = getVendorIdFromForm;
UnconditionalLienWaiverReview.isMissingInformation = isMissingInformation;

export default UnconditionalLienWaiverReview;
