export enum UploadStatus {
  QUEUED = "QUEUED",
  PENDING_FILE = "PENDING_FILE",
  UPLOADING = "UPLOADING",
  PROCESSING = "PROCESSING",
  PENDING_CONFIRMATION = "PENDING_CONFIRMATION",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
}

export type Upload = {
  id: string;
  uploadUrl?: string;
  file?: File;
  fileName?: string;
  progress?: number;
  status?: UploadStatus;
  hidden?: boolean;
  splits?: Split[];
};

export type Split = {
  id: string;
  indexes: number[];
  classification?: string;
  pageCount?: number;
  file?: File;
};

export type DropzoneFile = {
  file: File;
  name: string;
  loading: boolean | null;
  split: boolean;
  numberOfPages: number | null;
  disableSplit: boolean;
};
