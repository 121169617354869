import PropTypes from "prop-types";
import { Pane, Form } from "components/materials";
import { majorScale } from "helpers/utilities";
import { subtract } from "helpers/math";
import { get } from "lodash";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import formatPercent from "helpers/formatPercent";
import unformatNumber from "helpers/unformatNumber";
import DocumentReviewLayout from "./DocumentReviewLayout";

const getInitialValues = ({ document, selectedDraw, selectedType }) => {
  const { actualProgress, scheduledProgress, description } = document;

  return {
    description,
    draw: selectedDraw
      ? { id: selectedDraw.id, name: selectedDraw.name }
      : null,
    type: selectedType,
    actualProgress: actualProgress
      ? formatPercent(actualProgress, null, 5)
      : null,
    scheduledProgress: scheduledProgress
      ? formatPercent(scheduledProgress, null, 5)
      : null,
  };
};

const getDocumentDataFromForm = ({ actualProgress, scheduledProgress }) => {
  return {
    actualProgress: unformatNumber(actualProgress),
    scheduledProgress: unformatNumber(scheduledProgress),
  };
};

function getWarnings(form) {
  const { values } = form;
  const warnings = {};

  if (values.scheduledProgress && values.actualProgress) {
    const difference = Math.abs(
      subtract(
        unformatNumber(values.scheduledProgress),
        unformatNumber(values.actualProgress)
      )
    );

    if (difference >= 0.1) {
      warnings.progressDifference = {
        type: "progressDifferenceAlert",
        name: formatPercent(difference),
        level: "warn",
      };
    }
  }

  return warnings;
}

export function Hud5372Review(props) {
  const { disableWarnings, form, selectedDraw } = props;

  const warnings =
    disableWarnings || get(selectedDraw, "isLockedDown")
      ? {}
      : getWarnings(form);

  return (
    <DocumentReviewLayout
      warnings={warnings}
      meta={(content) => (
        <Pane display="flex" width="100%" alignItems="center">
          {content}
        </Pane>
      )}
      information={() => (
        <Pane display="flex" marginTop={majorScale(2)} width="100%">
          <Pane flexGrow={1} marginRight={majorScale(2)}>
            <Form.Input
              type="percentage"
              name="actualProgress"
              label="Actual Progress"
            />
          </Pane>
          <Pane flexGrow={1} marginRight={majorScale(2)}>
            <Form.Input
              type="percentage"
              name="scheduledProgress"
              label="Scheduled Progress"
            />
          </Pane>
        </Pane>
      )}
      {...props}
    />
  );
}

Hud5372Review.propTypes = {
  selectedDraw: PropTypes.object,
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
    actualProgress: PropTypes.number,
    scheduledProgress: PropTypes.number,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      actualProgress: PropTypes.number,
      scheduledProgress: PropTypes.number,
    }),
  }),
};

// TODO: remove dot notation
Hud5372Review.getInitialValues = getInitialValues;
Hud5372Review.getDocumentDataFromForm = getDocumentDataFromForm;
