import { useEffect } from "react";
import PropTypes from "prop-types";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { VendorLink } from "components/templates";
import { Form, Pane, Table, Text } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { minorScale, majorScale } from "helpers/utilities";
import { FieldArray } from "formik";
import { blankClipboards, constructionManager } from "images";
import { get, omit } from "lodash";
import { sumBy } from "helpers/math";
import DocumentReviewLayout from "./DocumentReviewLayout";

function getLineItemName(lineItem) {
  return `${lineItem.name} (${lineItem.division.name})`;
}

function getVendorLineItems(selectedDraw, vendorId) {
  return get(selectedDraw, "vendorLineItems", []).filter(
    (vli) => vli.vendor.id === vendorId && vli.documentation.length > 0
  );
}

function getInformationSummary(form) {
  return get(form, "values.vendor.name");
}

function getInitialValues({ document, selectedDraw, selectedType }) {
  const {
    comments,
    dismissedWarnings,
    documentReviewActivity,
    reviews,
    retainageReleasedLineItems = [],
    ...restOfDocument
  } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);

  const vendorLineItems = getVendorLineItems(selectedDraw, document.vendor.id);

  const selectedVendorLineItems = vendorLineItems
    .filter((vli) =>
      retainageReleasedLineItems.includes(getLineItemName(vli.drawLineItem))
    )
    .map((vli) => vli.id);

  return {
    ...documentProperties,
    draw: selectedDraw,
    type: selectedType,
    selectedVendorLineItems,
    vendorLineItems,
  };
}

function getDocumentDataFromForm(values) {
  const lineItems = values.vendorLineItems
    .filter((vli) => values.selectedVendorLineItems.includes(vli.id))
    .map((vli) => ({ name: getLineItemName(vli.drawLineItem) }));
  return { lineItems };
}

function RetainageReleaseReview(props) {
  const { form, selectedDraw } = props;
  const vendorId = form.values.vendor.id;

  useEffect(() => {
    form.setFieldValue(
      "vendorLineItems",
      getVendorLineItems(selectedDraw, vendorId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDraw, vendorId]);

  return (
    <DocumentReviewLayout
      {...props}
      information={(content) => (
        <Pane display="flex" flexDirection="column" width="100%">
          <Pane display="flex" flexDirection="row" flexWrap="wrap" width="100%">
            {content}
            <Pane
              display="flex"
              width="50%"
              alignItems="flex-end"
              paddingBottom={minorScale(1)}
            >
              <VendorLink
                vendorId={form.values.vendor.id}
                vendors={props.allAvailableVendors}
              />
            </Pane>
          </Pane>
        </Pane>
      )}
      informationSummary={getInformationSummary(form)}
    >
      <FieldArray name="lineItems">
        {(_fieldArray) => (
          <Form.Section
            heading="Line Items"
            isCollapsible
            isCollapsed={props.collapsedPanels.includes("lineItems")}
            onCollapse={() => props.togglePanel("lineItems")}
            padding={0}
          >
            {!vendorId && (
              <Pane
                backgroundColor="#eef6ff"
                display="flex"
                alignItems="center"
                padding={majorScale(2)}
                width="100%"
              >
                <Pane
                  height="90px"
                  paddingLeft={majorScale(6)}
                  paddingRight={majorScale(2)}
                >
                  <img height="100%" alt="" src={constructionManager} />
                </Pane>
                <Pane height="100%">
                  <Text display="block" size={400}>
                    Please select a vendor first.
                  </Text>
                </Pane>
              </Pane>
            )}
            {vendorId && form.values.vendorLineItems.length === 0 && (
              <Pane
                backgroundColor="#eef6ff"
                display="flex"
                alignItems="center"
                padding={majorScale(2)}
                width="100%"
              >
                <Pane
                  height="90px"
                  paddingLeft={majorScale(6)}
                  paddingRight={majorScale(2)}
                >
                  <img height="100%" alt="" src={blankClipboards} />
                </Pane>
                <Pane height="100%">
                  <Text display="block" size={400}>
                    There are no line items associated with this vendor.
                  </Text>
                </Pane>
              </Pane>
            )}
            {vendorId && form.values.vendorLineItems.length > 0 && (
              <Table paddingBottom={0}>
                <Table.Head>
                  <Table.Row>
                    <Table.TextHeaderCell width="50%">
                      Line Item
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell width="50%" textAlign="right">
                      Retainage Released
                    </Table.TextHeaderCell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {form.values.vendorLineItems.map((vli) => (
                    <Table.Row key={null}>
                      <Table.TextCell>
                        <Form.FastCheckbox
                          label={getLineItemName(vli.drawLineItem)}
                          name="selectedVendorLineItems"
                          value={vli.id}
                        />
                      </Table.TextCell>
                      <Table.TextCell textAlign="right">
                        {formatCurrency(
                          vli.retainageAmount < 0 ? -vli.retainageAmount : 0
                        )}
                      </Table.TextCell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Foot>
                  <Table.Row>
                    <Table.TextFooterCell />
                    <Table.TextFooterCell textAlign="right">
                      {formatCurrency(
                        sumBy(form.values.vendorLineItems, (vli) =>
                          vli.retainageAmount < 0 &&
                          form.values.selectedVendorLineItems.includes(vli.id)
                            ? -vli.retainageAmount
                            : 0
                        )
                      )}
                    </Table.TextFooterCell>
                  </Table.Row>
                </Table.Foot>
              </Table>
            )}
          </Form.Section>
        )}
      </FieldArray>
    </DocumentReviewLayout>
  );
}

RetainageReleaseReview.propTypes = {
  children: PropTypes.node,
  selectedDraw: PropTypes.object,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      vendorLineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          drawLineItem: PropTypes.shape({
            id: PropTypes.string.isRequired,
            division: PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
            }),
            name: PropTypes.string.isRequired,
          }),
          retainageAmount: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      vendorLineItems: PropTypes.array,
    }),
  }),
  onReview: PropTypes.func,
};

// TODO: remove dot notation
RetainageReleaseReview.getInitialValues = getInitialValues;
RetainageReleaseReview.getDocumentDataFromForm = getDocumentDataFromForm;

export default RetainageReleaseReview;
