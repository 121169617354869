import { useState, useCallback, useEffect, Fragment } from "react";
import { MergeColumnsIcon, SplitColumnsIcon, ZoomInIcon } from "evergreen-ui";
import pdfjs from "pdfjs-dist";
import { Pane, Spinner, Text } from "components/materials";
import { useResize } from "helpers/behaviors";
import { range, xor } from "lodash";
import { css, hover } from "glamor";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const cssPage = (pageSize, pageSpacing) =>
  css({
    alignItems: "center",
    backgroundColor: "#838e9a",
    borderRadius: 8,
    cursor: "default",
    display: "flex",
    flex: "0 0 auto",
    height: pageSize + (pageSpacing * 2) / 3,
    justifyContent: "center",
    padding: pageSpacing / 3,
    paddingBottom: pageSpacing / 3 + 12,
    position: "relative",
    userSelect: "none",
    width: pageSize + (pageSpacing * 2) / 3,
  });
const hoverOpacity = hover({ opacity: 1 });

const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;

function usePDFToImages(upload) {
  const [images, setImages] = useState({});

  useEffect(() => {
    async function fetchPdfs() {
      const pdf = await pdfjs.getDocument(upload.file.url).promise;

      upload.pages.forEach(async (pageNum) => {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement("canvas");
        const canvasContext = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        await page.render({ canvasContext, viewport }).promise;

        setImages((images) => ({
          ...images,
          [pageNum]: canvas.toDataURL(),
        }));
      });
    }

    fetchPdfs();
  }, [upload]);

  return images;
}

function getZoomedDocumentUrl(documentId, pageNumber) {
  return `${baseUrl}/documents/${documentId}/pages/${pageNumber}?format=pdf`;
}

function ZoomedPage({ setZoomedPageNumber, upload, zoomedPageNumber }) {
  return (
    <Pane
      alignItems="center"
      backgroundColor="rgba(0, 0, 0, 0.3)"
      bottom={0}
      display="flex"
      justifyContent="center"
      left={0}
      onClick={() => setZoomedPageNumber(null)}
      position="fixed"
      right={0}
      top={0}
      zIndex={999}
    >
      <iframe
        title={`${upload.id}/${zoomedPageNumber}`}
        src={`/pdfjs/web/viewer.html?file=${encodeURIComponent(
          getZoomedDocumentUrl(upload.id, zoomedPageNumber)
        )}`}
        width="50%"
        height="90%"
        frameBorder="none"
      />
    </Pane>
  );
}

function Split({ disabled, isSplit, page, pageSpacing, toggleSplit, wrap }) {
  const split = isSplit(page);

  return (
    <Pane
      backgroundColor={split || disabled ? "transparent" : "#838e9a"}
      flex={wrap ? "1 0 auto" : "0 0 auto"}
      marginX={-pageSpacing / 3}
      position="relative"
      width={pageSpacing}
    >
      <Pane
        alignItems="center"
        bottom={0}
        className={hoverOpacity}
        cursor={disabled ? null : "pointer"}
        display="flex"
        justifyContent="center"
        left={0}
        onClick={disabled ? null : () => toggleSplit(page)}
        opacity={0}
        position="absolute"
        right={0}
        top={0}
        zIndex={1}
      >
        {!disabled && (split ? <MergeColumnsIcon /> : <SplitColumnsIcon />)}
      </Pane>
    </Pane>
  );
}

function Page({ pageNumber, pageSize, pageSpacing, setZoomedPageNumber, src }) {
  return (
    <Pane className={[cssPage(pageSize, pageSpacing)]}>
      <Pane
        alignItems="center"
        bottom={pageSpacing}
        className={hoverOpacity}
        cursor="pointer"
        display="flex"
        justifyContent="center"
        left={pageSpacing}
        onClick={() => setZoomedPageNumber(pageNumber)}
        opacity={src ? 0 : 1}
        position="absolute"
        right={pageSpacing}
        top={pageSpacing}
        zIndex={1}
      >
        {src ? <ZoomInIcon /> : <Spinner />}
      </Pane>
      <Text
        color="white"
        lineHeight={1}
        size={400}
        textAlign="center"
        position="absolute"
        bottom={2}
        left={0}
        right={0}
      >
        {pageNumber}
      </Text>
      {src && (
        <img
          style={{
            height: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            width: "auto",
          }}
          src={src}
          alt="Page to split"
        />
      )}
    </Pane>
  );
}

function SplitterRow({
  isSplit,
  numPages,
  pageEnd,
  pageSize,
  pageSpacing,
  pageStart,
  setZoomedPageNumber,
  toggleSplit,
  images,
}) {
  const style = {
    display: "flex",
    height: pageSize + (pageSpacing * 2) / 3,
    justifyContent: "center",
    marginBottom: pageSpacing,
    marginTop: pageSpacing,
    width: "100%",
  };

  return (
    <div style={style}>
      <Split
        disabled={pageStart === 1}
        isSplit={isSplit}
        page={pageStart - 1}
        pageSpacing={pageSpacing}
        toggleSplit={toggleSplit}
        wrap
      />
      {range(pageStart, pageEnd + 1).map((page) => (
        <Fragment key={page}>
          <Page
            pageNumber={page}
            pageSize={pageSize}
            pageSpacing={pageSpacing}
            setZoomedPageNumber={setZoomedPageNumber}
            src={images[page]}
          />
          <Split
            disabled={page === numPages}
            isSplit={isSplit}
            page={page}
            pageSpacing={pageSpacing}
            toggleSplit={toggleSplit}
            wrap={pageEnd === page}
          />
        </Fragment>
      ))}
    </div>
  );
}

function Splitter({
  isSplit,
  numPages,
  scale,
  setZoomedPageNumber,
  toggleSplit,
  upload,
  width,
}) {
  const mult = scale * 0.0125 + 0.75;
  const pageSize = 300 * mult;
  const pageSpacing = 36;
  const numPagesPerRow = Math.max(
    1,
    Math.floor((width - pageSpacing) / (pageSize + pageSpacing))
  );
  const numRows = Math.ceil(numPages / numPagesPerRow);
  const images = usePDFToImages(upload);

  return range(numRows).map((row) => (
    <SplitterRow
      isSplit={isSplit}
      key={row}
      numPages={numPages}
      pageEnd={Math.min(numPages, row * numPagesPerRow + numPagesPerRow)}
      pageSize={pageSize}
      pageSpacing={pageSpacing}
      pageStart={row * numPagesPerRow + 1}
      setZoomedPageNumber={setZoomedPageNumber}
      toggleSplit={toggleSplit}
      images={images}
    />
  ));
}

export function FileSplitter({ propsFormik, scale, upload }) {
  const [zoomedPageNumber, setZoomedPageNumber] = useState(null);
  const [ref, size, handleResize] = useResize();

  const isSplit = useCallback(
    (page) => {
      return propsFormik.values.splits.includes(page);
    },
    [propsFormik.values.splits]
  );

  const toggleSplit = useCallback(
    (page) => {
      propsFormik.setFieldValue(
        "splits",
        xor(propsFormik.values.splits, [page]).sort()
      );
    },
    [propsFormik]
  );

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upload.id]);

  return (
    <div ref={ref}>
      <Splitter
        isSplit={isSplit}
        numPages={propsFormik.values.numPages}
        scale={scale}
        setZoomedPageNumber={setZoomedPageNumber}
        toggleSplit={toggleSplit}
        upload={upload}
        width={size.width}
      />
      {zoomedPageNumber && (
        <ZoomedPage
          setZoomedPageNumber={setZoomedPageNumber}
          upload={upload}
          zoomedPageNumber={zoomedPageNumber}
        />
      )}
    </div>
  );
}
