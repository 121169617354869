import { Fragment } from "react";
import { Text } from "components/materials";
import { get, flatMap, uniqBy } from "lodash";
import { CommentList } from "../../Comments/CommentList";

export default function Comments({ commentForm, lineItem }) {
  const { comments, expenses } = lineItem;

  const uniqueExpenses = uniqBy(expenses, "document.id");
  const allComments = flatMap(uniqueExpenses, (expense) =>
    get(expense, "document.comments", [])
  )
    .concat(comments)
    .sort((a, b) => new Date(b.insertedAt) - new Date(a.insertedAt));

  if (allComments.length === 0 && !commentForm) return <Text>No Comments</Text>;

  return (
    <Fragment>
      <CommentList comments={allComments} />
      {commentForm}
    </Fragment>
  );
}
