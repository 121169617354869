import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { DrawLockdownBanner } from "components/templates";
import {
  Button,
  Confirm,
  Heading,
  Link,
  Loadable,
  Pane,
  RedesignLayout,
  Text,
  Banner,
} from "components/materials";
import t from "helpers/translate";
import { includes } from "lodash";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { AGREEMENT_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { Frozen, UserContext, NavigationWarnings } from "helpers/behaviors";
import { getNetAdjustment, formatAdjustment } from "helpers/adjustments";
import { DRAW_BUDGET_PAGE_QUERY } from "../DrawBudgetPage/graphql";
import {
  AddAgreementModal,
  ADD_PROJECT_AGREEMENT,
  PENDING_CHANGE_ORDER_TYPES,
} from "../Agreements";
import { AdjustmentCard } from "./AdjustmentCard";
import { AdjustmentsHeader } from "./AdjustmentsHeader";
import { ChangeOrderAlert } from "./ChangeOrderAlert";
import { OverdrawnLineItems } from "./OverdrawnLineItems";
import { ADJUSTMENTS_OVERLAY_QUERY } from "./graphql-queries";

// { lineItemId: [adjustment1Id, adjustemnt2Id, ...]}
export function getAdjustmentIdsByLineItem(adjustments) {
  return adjustments.reduce(
    (adjustmentIds, { id: adjustmentId, transactions }) =>
      transactions.reduce(
        (adjustmentIds, { lineItem: { id: lineItemId } }) => ({
          ...adjustmentIds,
          [lineItemId]: adjustmentIds[lineItemId]
            ? [...adjustmentIds[lineItemId], adjustmentId]
            : [adjustmentId],
        }),
        adjustmentIds
      ),
    {}
  );
}

function getOverdrawnLineItems(lineItems) {
  return lineItems.filter(({ balanceToFundAmount }) => balanceToFundAmount < 0);
}

function FrozenAdjustmentsOverlay({ drawId, frozenProps, onClose, projectId }) {
  const [confirmClose, setConfirmClose] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [editInProgress, setEditInProgress] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [addAgreementModalProps, setAddAgreementModalProps] = useState(null);
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);

  const { data, loading } = useQuery(ADJUSTMENTS_OVERLAY_QUERY, {
    variables: { drawId, projectId },
  });

  const adjustmentRefetchQueries = [
    { query: ADJUSTMENTS_OVERLAY_QUERY, variables: { drawId, projectId } },
    { query: DRAW_BUDGET_PAGE_QUERY, variables: { projectId, drawId } },
  ];

  const adjustmentRefetchOptions = {
    awaitRefetchQueries: true,
    refetchQueries: adjustmentRefetchQueries,
  };

  const [addProjectAgreement, addProjectAgreementResult] = useMutation(
    ADD_PROJECT_AGREEMENT,
    {
      ...adjustmentRefetchOptions,
      onCompleted: () => {
        setEditInProgress(false);
        setAddAgreementModalProps(null);
      },
    }
  );

  const handleClose = () => {
    if (formDirty) setConfirmClose(true);
    else onClose();
  };

  if (loading) return <Loadable loading />;

  const { project, draw } = data;
  const { agreements } = project;
  const { budgetAdjustments, divisions, isLockedDown, lineItems } = draw;

  const canMakeAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );

  const hasAgreementManagement = hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  );

  const getChangeOrderUrl = ({ id, document }) => {
    if (!hasAgreementManagement && !!document) {
      return document.drawId
        ? `/projects/${projectId}/draws/${document.drawId}/documentation/${document.id}`
        : `/projects/${projectId}/documentation/${document.id}`;
    }
    return `/projects/${projectId}/agreements/${id}`;
  };

  const netAdjustment = getNetAdjustment(budgetAdjustments);
  const lineItemAdjustmentIds = getAdjustmentIdsByLineItem(budgetAdjustments);
  const overdrawnLineItems = getOverdrawnLineItems(lineItems);

  const disableEdits = isLockedDown || editInProgress;

  const hasAdjustments = budgetAdjustments.length > 0;
  const pendingAgreements = agreements.filter((agreement) =>
    includes(PENDING_CHANGE_ORDER_TYPES, agreement.type)
  );

  const getAddAgreementModalProps = (knownExistingAgreement) => ({
    agreements: pendingAgreements,
    isSaving: addProjectAgreementResult.loading,
    isUpgradeFlow: true,
    onClose: () => {
      setEditInProgress(false);
      setAddAgreementModalProps(null);
    },
    project,
    projectAgreementMutation: addProjectAgreement,
    knownNewAgreementType: AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
    drawId,
    knownCanSelectFromExisting:
      !knownExistingAgreement && pendingAgreements.length > 0,
  });

  const handleExecuteChangeOrder = (knownExistingAgreement) => {
    const existingAgreement = knownExistingAgreement
      ? { knownExistingAgreement }
      : {};
    setAddAgreementModalProps({
      ...getAddAgreementModalProps(knownExistingAgreement),
      ...existingAgreement,
    });
  };

  return (
    <RedesignLayout.Panel display="flex" flexDirection="column" minWidth={1000}>
      <Pane
        flex="0 0 auto"
        display="flex"
        alignItems="center"
        borderBottom={`1px solid ${theme.layoutColors.border}`}
        padding={majorScale(2)}
      >
        <Heading size={600} marginRight="auto">
          {`${draw.name} Budget Adjustments`}
        </Heading>
        <Heading size={600} marginRight={majorScale(4)}>
          {`Total budget change:  ${formatAdjustment(netAdjustment)}`}
        </Heading>
        <Button purpose="adjustments close" onClick={handleClose}>
          Close
        </Button>
      </Pane>
      {!canMakeAdjustments && (
        <Banner
          borderBottom
          icon="infoSignIcon"
          mainText={t("adjustmentsOverlay.noPermissionMain")}
          secondaryText={t("adjustmentsOverlay.noPermissionSecondary")}
          paddingLeft={majorScale(4)}
        />
      )}
      {isLockedDown && <DrawLockdownBanner />}
      <Pane
        flex="0 0 auto"
        paddingX={majorScale(4)}
        paddingY={majorScale(2)}
        marginBottom={majorScale(2)}
      >
        <Pane>
          {overdrawnLineItems.length > 0 && (
            <OverdrawnLineItems overdrawnLineItems={overdrawnLineItems} />
          )}
          {hasAgreementManagement && agreements.length > 0 && (
            <ChangeOrderAlert
              changeOrderAgreements={agreements}
              handleExecuteChangeOrder={handleExecuteChangeOrder}
              setEditInProgress={setEditInProgress}
            />
          )}
          <Text>
            {t(
              `adjustmentsOverlay.${
                hasAdjustments ? "description" : "emptyStateDescription"
              }`
            )}
          </Text>
          <Link
            href="https://help.rabbet.com/en/articles/3351415-reallocate-funds-and-add-line-items-to-a-budget"
            purpose="adjustments help"
            marginLeft={minorScale(1)}
          >
            Learn More.
          </Link>
          {canMakeAdjustments && (
            <Pane
              marginTop={majorScale(1)}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                appearance="primary"
                disabled={disableEdits}
                onClick={() => {
                  setShowCreate(true);
                  setEditInProgress(true);
                }}
              >
                Create Adjustment
              </Button>
              {hasAgreementManagement && (
                <Button
                  marginLeft={majorScale(2)}
                  appearance="primary"
                  disabled={disableEdits}
                  onClick={() => {
                    setEditInProgress(true);
                    handleExecuteChangeOrder();
                  }}
                >
                  Update Budget from Executed Change Order
                </Button>
              )}
            </Pane>
          )}
        </Pane>
      </Pane>
      {(hasAdjustments || showCreate) && <AdjustmentsHeader theme={theme} />}
      <Pane flex="1 1 auto" overflowY="auto" paddingTop={1}>
        {budgetAdjustments.map((adjustment) => (
          <AdjustmentCard
            key={adjustment.id}
            adjustment={adjustment}
            divisions={divisions}
            drawId={drawId}
            canMakeAdjustments={canMakeAdjustments}
            disableEdits={disableEdits}
            frozenProps={frozenProps}
            getChangeOrderUrl={getChangeOrderUrl}
            lineItems={lineItems}
            lineItemAdjustmentIds={lineItemAdjustmentIds}
            projectId={projectId}
            refetchOptions={adjustmentRefetchOptions}
            setEditInProgress={setEditInProgress}
            setFormDirty={setFormDirty}
          />
        ))}
        {showCreate && (
          <AdjustmentCard
            divisions={divisions}
            drawId={drawId}
            frozenProps={frozenProps}
            getChangeOrderUrl={getChangeOrderUrl}
            lineItems={lineItems}
            lineItemAdjustmentIds={lineItemAdjustmentIds}
            onCloseCreate={() => {
              setShowCreate(false);
              setEditInProgress(false);
            }}
            projectId={projectId}
            refetchOptions={adjustmentRefetchOptions}
            setEditInProgress={setEditInProgress}
            setFormDirty={setFormDirty}
          />
        )}
        {addAgreementModalProps !== null && (
          <AddAgreementModal {...addAgreementModalProps} />
        )}
      </Pane>
      <NavigationWarnings dirty={formDirty} />
      {confirmClose && (
        <Confirm
          content={t("confirmNavigation.warning")}
          header="Warning"
          onCloseComplete={() => setConfirmClose(false)}
          onConfirm={(close) => {
            close();
            onClose();
          }}
          open={confirmClose}
          cancelLabel="Cancel"
          confirmLabel="Continue without saving"
        />
      )}
    </RedesignLayout.Panel>
  );
}

export function AdjustmentsOverlay({ projectId, drawId, onClose }) {
  return (
    <Frozen projectId={projectId}>
      {({ frozenError, frozenSubmit }) => (
        <FrozenAdjustmentsOverlay
          projectId={projectId}
          drawId={drawId}
          onClose={onClose}
          frozenProps={{ frozenError, frozenSubmit }}
        />
      )}
    </Frozen>
  );
}

AdjustmentsOverlay.propTypes = {
  drawId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};
