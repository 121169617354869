import gql from "graphql-tag";

export default gql`
  fragment ProjectFragment on Project {
    id
    accountsPayableLastSyncedAt
    accountsPayableSyncFailingSince
    accountsPayableSyncErrorMessage
    acres
    amount
    city
    customFields {
      id
      scopeId
      label
      type
      value
    }
    contingencySpentAmount
    contingencyOriginalAmount
    drawReviewers {
      id
      fullName
      isPreparer
      isSignatory
    }
    expectedProjectLength
    fundingSourceGroups {
      id
      amount
      disbursedAmount
      label
      type
      organization {
        id
        name
      }
    }
    grossPercentComplete
    hardCostsAmount
    hardCostsGrossPercentComplete
    team {
      id
      name
    }
    documentInspectionReport {
      id
      expectedCompletionDate
      inspectionDate
      inspectorName
      inspectorNotes
      percentComplete
      vendor {
        id
        name
      }
    }
    interestReservesAmount
    interestReservesUsedAmount
    setupComplete
    lastDrawFunded {
      id
      name
      fundedDate
    }
    latestDrawInspectionReport {
      id
      date
      insertedAt
      inspectorName
      submittedAt
    }
    loanMaturityDate
    tasks {
      id
      projectTypeTaskId
      status
      originalCompletionDate
      projectedCompletionDate
      actualCompletionDate
      completionDateVariance
    }
    name
    organization {
      id
      name
    }
    organizationId
    percentComplete
    projectRegion {
      id
      region
    }
    productType {
      id
      type
    }
    recentStateUpdate {
      id
      date
    }
    recentDraw {
      id
      reviewState
      reviews {
        id
        processedAt
        reviewer {
          id
          fullName
        }
        state
        isFinal
        isPreparer
      }
    }
    recentUnfundedDraw {
      id
      createdAt
      fundingConfirmed
      name
      requestedAmount
      retainageAmount
      reviews {
        id
        processedAt
        reviewer {
          id
          fullName
        }
        state
        type
        isFinal
        isPreparer
      }
      receivedDate
      reviewState
      state
      submittedDate
      updatedAt
    }
    requestedAmount
    squareFeet
    stakeholders {
      id
      role
      organization {
        id
        name
      }
    }
    startDate
    state
    status
    streetAddress
    template {
      id
      icon
      name
    }
    zip
  }
`;
