import analytics from "./analytics";

export function downloadUrl(url, filename) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export async function dataUrlToFile(dataUrl, fileName) {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}

export function getFilenameFromResponseHeaders(headers) {
  // Sample format for the content-disposition header:
  // 'inline; filename="123 submission st - Draw 1 - 20191126.zip"'
  const contentDisposition = headers.get("content-disposition");
  const filenameRegex = /filename="(.*)"/;
  const matches = filenameRegex.exec(contentDisposition);

  return matches && matches[1] ? matches[1].replace(/['"]/g, "") : null;
}

export function getUrl(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    downloadUrl(url, filename);
    window.URL.revokeObjectURL(url);
  }
}

export async function download({
  filename,
  onError,
  onTimeout,
  onSuccess,
  url,
}) {
  const response = await fetch(url);
  if (!response.ok && response.status === 408) return onTimeout(response);
  if (!response.ok) return onError(response);

  const blob = await response.blob();
  const name = getFilenameFromResponseHeaders(response.headers) || filename;
  return onSuccess({ blob, filename: name, headers: response.headers });
}

// https://en.wikipedia.org/wiki/Comma-separated_values#Basic_rules
function escapeCsv(item) {
  const string = (item === null || item === undefined ? "" : item)
    .toString()
    .replace(/(\r\n|\n|\r)/g, "")
    .replace(/"/g, '""')
    .trim();
  return `"${string}"`;
}

const translateReportType = {
  draw: "Draw",
  documentation: "Documents",
  invoice_summary: "Invoice Summary",
  job_cost_codes: "Chart of Accounts",
};

export async function downloadXlsx(workbook, filename) {
  const { pathname } = window.location;

  if (pathname.includes("report")) {
    const splitUrl = pathname.split("/");
    analytics.track(
      "Portfolio Report Exported",
      splitUrl.length === 2 ? "Project" : translateReportType[splitUrl[2]]
    );
  }
  const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
  getUrl(
    new Blob([xls64], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    filename
  );
}

export function downloadCsv(data, filename, match) {
  if (match.url.includes("report")) {
    const splitUrl = match.url.split("/");
    analytics.track(
      "Portfolio Report Exported",
      splitUrl.length === 2 ? "Project" : translateReportType[splitUrl[2]]
    );
  }
  const content = data.map((row) => row.map(escapeCsv).join(",")).join("\n");
  const type = "text/csv;charset=utf-8;";
  const blob = new Blob([content], { type });
  getUrl(blob, filename);
}
