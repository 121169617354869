import { useContext } from "react";
import { Pane, Text } from "components/materials";
import { FastDataTable } from "components/materials/FastDataTable";
import { majorScale, ThemeContext } from "helpers/utilities";
import { AgreementCard } from "./AgreementCard";

export function AgreementsViewerSidebar({
  agreements,
  onSelectAgreement,
  selectedAgreement,
}) {
  const theme = useContext(ThemeContext);
  const sidebarHeaderText =
    agreements.length > 1
      ? `${agreements.length} Agreements`
      : `${agreements.length} Agreement`;

  const getRowState = (agreement) => {
    return selectedAgreement.id === agreement.id
      ? { background: "info" }
      : { background: "default" };
  };

  return (
    <FastDataTable
      controls={() => (
        <Pane
          background={theme.colors.lightPurple}
          borderBottom
          display="flex"
          justifyContent="space-between"
          padding={majorScale(1)}
        >
          <Text
            color={theme.colors.gray800}
            fontWeight={theme.fontWeights.DEMI}
          >
            {sidebarHeaderText}
          </Text>
        </Pane>
      )}
      columns={[
        {
          id: "agreementCard",
          value: (agreement) => agreement,
          valueFormatter: (agreement) => (
            <AgreementCard agreement={agreement} />
          ),
          cellProps: { borderRight: "none" },
        },
      ]}
      disableCollapse
      getRowState={getRowState}
      hideTableHeader
      items={agreements}
      onClickRow={onSelectAgreement}
    />
  );
}
