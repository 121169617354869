import { useContext } from "react";
import { Card, Link, Text, Pane, Paragraph } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { DetailedDivisionsSummary } from "./DetailedDivisionSummary";
import { SimpleDivisionsSummary } from "./SimpleDivisionSummary";

const getDivisionAggregates = (division) =>
  division.lineItems.reduce(
    (division, lineItem) => ({
      ...division,
      budget: division.budget + lineItem.budgetAmount,
      originalBudget: division.originalBudget + lineItem.originalBudgetAmount,
      requested: division.requested + lineItem.requestedToDateAmount,
    }),
    {
      name: division.name,
      budget: 0,
      originalBudget: 0,
      requested: 0,
    }
  );

const renderDivisionsSummary = (project) => {
  const divisionAggregates = project.divisions.map(getDivisionAggregates);

  return divisionAggregates.length > 17 ? (
    <SimpleDivisionsSummary divisions={divisionAggregates} />
  ) : (
    <DetailedDivisionsSummary divisions={divisionAggregates} />
  );
};

export function ProjectBudgetOverview({ project, disableFullBudget }) {
  const noDivisions = project.divisions.length === 0;
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);

  return (
    <Card
      display="flex"
      flexDirection="column"
      height={436}
      marginTop={majorScale(2)}
    >
      <Pane
        display="flex"
        flex="0 0 auto"
        paddingX={majorScale(2)}
        paddingTop={majorScale(2)}
      >
        <Text flex="1 1 auto" size={300} fontWeight={theme.fontWeights.MEDIUM}>
          Budget Overview
        </Text>
        {!noDivisions && !disableFullBudget && (
          <Link
            purpose="project-dashboard navigate budget"
            size={300}
            to={`/projects/${project.id}/budget`}
          >
            View Full Budget
          </Link>
        )}
      </Pane>
      {noDivisions ? (
        <Pane
          textAlign="center"
          flex="1 1 auto"
          marginTop={majorScale(5)}
          width="100%"
        >
          <Paragraph>This project does not have a budget.</Paragraph>
          {hasPermission(PERMISSION_ACTION.EDIT_BUDGET) ? (
            <Link
              purpose="project-dashboard budget-blank"
              to={`/projects/${project.id}/budget`}
            >
              Add budget
            </Link>
          ) : (
            <Link href="mailto:help@rabbet.com">
              Contact our Partner Success team
            </Link>
          )}
        </Pane>
      ) : (
        renderDivisionsSummary(project)
      )}
    </Card>
  );
}
