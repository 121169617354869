import { Fragment, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, Route } from "react-router-dom";
import { useLocation } from "react-router";
import { CaretDownIcon, ChevronRightIcon } from "evergreen-ui";
import { isInternetExplorer } from "helpers/browserHelpers";
import { HelpButton } from "components/templates";
import {
  Button,
  Menu,
  Pane,
  Popover,
  RedesignLayout,
  Text,
} from "components/materials";
import {
  Position,
  majorScale,
  minorScale,
  ThemeContext,
} from "helpers/utilities";
import { mergeIntoTheme } from "helpers/themeHelpers";
import { getButtonProjectIcon } from "helpers/projectIconHelpers";
import { find, get, isEqual } from "lodash";
import { css } from "glamor";
import { poweredByRabbet } from "images";

function getNavBarProjectName({ name, customId }) {
  const customIdString = customId ? ` (${customId})` : "";
  return `${name}${customIdString}`;
}

const userMenu = (match, theme) => ({ close }) => {
  const { menuSelectBackgroundColor, menuSelectTextColor } = theme.tableColors;
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item
          is={RouterLink}
          onSelect={close}
          style={
            match && match.path.includes("/logout")
              ? {
                  backgroundColor: menuSelectBackgroundColor,
                  cursor: "default",
                  pointerEvents: "none",
                }
              : undefined
          }
          textDecoration="none"
          to="/logout"
        >
          <Text color={menuSelectTextColor} fontSize={12} fontWeight={500}>
            Logout
          </Text>
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export function BorrowerAppLayout({
  children,
  recentProjectsQuery,
  fetchRecentProjects,
  userEmail,
  organizations,
}) {
  const location = useLocation();
  const recentProjects = get(recentProjectsQuery, "data.borrower.projects", []);

  // When the time comes to implement multi-org borrowers, this will need to be updated. See: `AppLayout`
  const getOrgProperties = () => {
    return organizations[0];
  };

  const {
    name: orgName,
    headerColor,
    primaryColor,
    primaryLogo,
  } = getOrgProperties(location.pathname);

  const colors = useRef({});
  const theme = useContext(ThemeContext);

  // || clause below defaults back to Rabbet colors for multi-org users when navigating back to Home page
  // or to an org without cobranding value(s) when theme values have already been mutated
  const incomingColors = {
    headerColor: headerColor || theme.rabbetHeaderColor,
    primaryColor: primaryColor || theme.rabbetPrimaryColor,
    primaryLogo,
  };

  if (!isEqual(colors.current, incomingColors)) {
    colors.current = { ...incomingColors };
    mergeIntoTheme(theme, incomingColors);
  }

  const {
    headerButtonTextColor,
    headerIconColor,
    headerLinkActive,
    headerLinkActiveBoxShadow,
    headerLinkActiveHover,
    headerLinkInactiveHover,
    headerTextOnHoverColor,
  } = theme.defaultColors;
  const {
    menuSelectAltTextColor,
    menuSelectBackgroundColor,
    menuSelectTextColor,
  } = theme.tableColors;
  const cobrandedLogo = theme.primaryLogo;
  // Until https://github.com/segmentio/evergreen/blob/469a339/src/menu/src/MenuItem.js#L126-L128
  // is updated to *not* use flex={1}, we need to change how the menu item itself is styled
  const ieMenuItemProps = isInternetExplorer
    ? { display: "block", marginTop: 5 }
    : {};

  const renderProject = (projectRoute) => {
    if (projectRoute.match.params.projectId === "new") return null;
    if (recentProjectsQuery.loading) return null;
    const { projectId } = projectRoute.match.params;
    const project = find(recentProjects, ["id", projectId]);
    if (!project) {
      fetchRecentProjects();
      return null;
    }
    const projectsMenu = ({ close }) => (
      <Menu>
        <Menu.Group title="Recent Projects">
          {recentProjects.map((p) => (
            <Menu.Item
              intent="default"
              is={RouterLink}
              key={p.id}
              onSelect={close}
              style={
                p.id === project.id
                  ? {
                      backgroundColor: menuSelectBackgroundColor,
                      cursor: "default",
                      pointerEvents: "none",
                    }
                  : undefined
              }
              textDecoration="none"
              to={`/borrower/projects/${p.id}`}
              {...ieMenuItemProps}
            >
              <Text color={menuSelectTextColor} fontSize={12} fontWeight={500}>
                {p.name}
              </Text>
            </Menu.Item>
          ))}
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>
          <Menu.Item
            intent="default"
            is={RouterLink}
            onSelect={close}
            textDecoration="none"
            to="/borrower"
          >
            <Text color={menuSelectAltTextColor} fontSize={12} fontWeight={500}>
              See All
            </Text>
          </Menu.Item>
        </Menu.Group>
      </Menu>
    );

    const renderDraw = (drawRoute) => {
      if (drawRoute.match.params.drawId === "new") return null;
      const draws = get(project, "draws", []);
      const draw = find(draws, ["id", drawRoute.match.params.drawId]);

      if (!draw) {
        fetchRecentProjects();
        return null;
      }

      const drawsMenu = ({ close }) => (
        <Menu>
          <Pane maxHeight={majorScale(33)} overflowY="auto">
            <Menu.Group>
              {draws.map((d) => (
                <Menu.Item
                  intent="default"
                  is={RouterLink}
                  key={d.id}
                  onSelect={close}
                  style={
                    d.id === draw.id
                      ? {
                          backgroundColor: menuSelectBackgroundColor,
                          cursor: "default",
                          pointerEvents: "none",
                        }
                      : undefined
                  }
                  textDecoration="none"
                  to={`/borrower/projects/${project.id}/draws/${d.id}`}
                >
                  <Text
                    color={menuSelectTextColor}
                    fontSize={12}
                    fontWeight={500}
                  >
                    {d.name}
                  </Text>
                </Menu.Item>
              ))}
            </Menu.Group>
          </Pane>
          <Menu.Divider />
        </Menu>
      );

      return (
        <Fragment>
          <ChevronRightIcon
            color={headerIconColor}
            marginX={minorScale(1)}
            size={14}
          />
          <Popover content={drawsMenu} position={Position.BOTTOM_LEFT}>
            <Button
              data-testid="navbar-draw-name"
              appearance="minimal"
              color={headerButtonTextColor}
              fontSize={15}
              fontWeight={800}
              iconAfter={CaretDownIcon}
              id="drawButton"
              isActive
              purpose="nav draw"
              style={{ backgroundColor: headerLinkActive }}
              {...css({
                "&#drawButton:focus": {
                  boxShadow: headerLinkActiveBoxShadow,
                },
              })}
            >
              {get(draw, "name")}
            </Button>
          </Popover>
        </Fragment>
      );
    };

    return (
      <Route path={`${projectRoute.match.path}/draws/:drawId`}>
        {(drawRoute) =>
          drawRoute.match ? (
            <Fragment>
              {!cobrandedLogo && (
                <ChevronRightIcon
                  color={headerIconColor}
                  marginX={minorScale(1)}
                  size={14}
                />
              )}
              <Button
                appearance="minimal"
                color={headerButtonTextColor}
                data-testid="navbar-project-name"
                purpose="nav project"
                fontSize={15}
                fontWeight={500}
                iconBefore={getButtonProjectIcon(get(project, "template.icon"))}
                id="navLink"
                is={RouterLink}
                to={projectRoute.match.url}
                {...css({
                  "&#navLink:hover": {
                    backgroundColor: headerLinkInactiveHover,
                    color: headerTextOnHoverColor,
                  },
                  "&#navLink:focus": {
                    boxShadow: headerLinkActiveBoxShadow,
                  },
                })}
              >
                {getNavBarProjectName(project)}
              </Button>
              {renderDraw(drawRoute)}
            </Fragment>
          ) : (
            <Fragment>
              {!cobrandedLogo && (
                <ChevronRightIcon
                  color={headerIconColor}
                  marginX={minorScale(1)}
                  size={14}
                />
              )}
              <Popover content={projectsMenu} position={Position.BOTTOM_LEFT}>
                <Button
                  appearance="minimal"
                  color={headerButtonTextColor}
                  data-testid="navbar-project-name"
                  fontSize={15}
                  fontWeight={800}
                  iconAfter={CaretDownIcon}
                  iconBefore={getButtonProjectIcon(
                    get(project, "template.icon")
                  )}
                  id="projectNavLink"
                  purpose="nav project"
                  isActive
                  style={{
                    backgroundColor: headerLinkActive,
                  }}
                  {...css({
                    "&#projectNavLink:hover": {
                      backgroundColor: headerLinkInactiveHover,
                      color: headerTextOnHoverColor,
                    },
                    "&#projectNavLink:focus": {
                      boxShadow: headerLinkActiveBoxShadow,
                    },
                  })}
                >
                  {getNavBarProjectName(project)}
                </Button>
              </Popover>
            </Fragment>
          )
        }
      </Route>
    );
  };

  return (
    <RedesignLayout>
      <RedesignLayout.Nav orgName={orgName}>
        {!cobrandedLogo && orgName && (
          <Button
            appearance="minimal"
            color={headerButtonTextColor}
            fontSize={15}
            fontWeight={500}
            id="navLink"
            data-testid="navbar-org-name"
            purpose="nav organization"
            is={RouterLink}
            to="/borrower"
            {...css({
              "&#navLink:hover": {
                backgroundColor: headerLinkInactiveHover,
                color: headerTextOnHoverColor,
              },
              "&#navLink:focus": {
                boxShadow: headerLinkActiveBoxShadow,
              },
            })}
          >
            {orgName}
          </Button>
        )}
        <Route path="/borrower/projects/:projectId" render={renderProject} />
        <RedesignLayout.Spacer />
        {cobrandedLogo && (
          <img
            alt="Powered By Rabbet"
            src={poweredByRabbet}
            style={{ height: "75%" }}
          />
        )}
        <HelpButton theme={theme} />
        <Route path={["/logout"]}>
          {({ match }) => (
            <Popover
              content={userMenu(match, theme)}
              position={Position.BOTTOM_RIGHT}
            >
              <Button
                appearance="minimal"
                color={headerButtonTextColor}
                fontSize={12}
                fontWeight={match ? 800 : 600}
                iconAfter={CaretDownIcon}
                id="navLink"
                data-testid="navbar-user-menu"
                purpose="nav user-menu open"
                isActive={!!match}
                marginLeft={majorScale(1)}
                style={
                  match && {
                    backgroundColor: headerLinkActive,
                  }
                }
                {...css({
                  "&#navLink:hover": {
                    backgroundColor: match
                      ? headerLinkActiveHover
                      : headerLinkInactiveHover,
                    color: headerTextOnHoverColor,
                  },
                  "&#navLink:focus": {
                    boxShadow: headerLinkActiveBoxShadow,
                  },
                })}
              >
                {userEmail}
              </Button>
            </Popover>
          )}
        </Route>
      </RedesignLayout.Nav>
      <RedesignLayout.Content>{children}</RedesignLayout.Content>
    </RedesignLayout>
  );
}

BorrowerAppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object),
  refetch: PropTypes.func,
  userEmail: PropTypes.string,
};

BorrowerAppLayout.defaultProps = {
  organizations: [],
  refetch: () => null,
  userEmail: undefined,
};
