import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Confirm, Form } from "components/materials";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { Modal } from "../Modal";

const FormModal = ({
  header,
  children,
  onClose,
  onSubmit,
  dirty,
  confirmCancel,
  loading,
  size,
  error,
  ...props
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleCancel = (close) => {
    confirmCancel && dirty ? setConfirmOpen(true) : close();
  };

  return (
    <Fragment>
      <Modal
        hasFooter
        confirmLabel="Submit"
        isConfirmLoading={loading}
        onClose={onClose}
        onCancel={handleCancel}
        onConfirm={onSubmit}
        open
        size={size}
        title={header}
        {...props}
      >
        <Modal.Content>
          <Form
            onSubmit={(event) => {
              preventEventBubbling(event);
              onSubmit(event);
            }}
            loading={loading}
            error={error}
          >
            {children}
          </Form>
        </Modal.Content>
      </Modal>
      <Confirm
        content="Are you sure you want to cancel? All changes will be lost."
        header="Cancel Changes"
        onCloseComplete={() => setConfirmOpen(false)}
        onConfirm={(close) => {
          close();
          onClose();
        }}
        open={confirmOpen}
      />
    </Fragment>
  );
};

FormModal.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  confirmCancel: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

export default FormModal;
