import { useContext } from "react";
import { Formik } from "formik";
import { CreateDocumentForm } from "components/templates";
import { UserContext } from "helpers/behaviors";
import { get } from "lodash";

const CreateDocument = ({
  create,
  createResult,
  documentTemplates,
  drawId,
  lineItems,
  onClose,
  preview,
  previewDocument,
  previewResult,
  projectId,
}) => {
  const { organizationId } = useContext(UserContext);

  return (
    <Formik
      initialValues={CreateDocumentForm.initialValues(
        projectId,
        drawId,
        get(documentTemplates, "0.id"),
        organizationId
      )}
      onSubmit={CreateDocumentForm.onSubmit(create, lineItems)}
      validate={CreateDocumentForm.validate}
      validateOnMount
    >
      {(form) => (
        <CreateDocumentForm
          drawId={drawId}
          form={form}
          lineItems={lineItems}
          onClose={onClose}
          preview={preview}
          previewDocument={previewDocument}
          previewResult={previewResult}
          projectId={projectId}
          result={createResult}
          templates={documentTemplates}
        />
      )}
    </Formik>
  );
};
export default CreateDocument;
