import { useState, useRef, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { HelpIcon } from "evergreen-ui";
import {
  Button,
  Card,
  Heading,
  IconButton,
  ListItem,
  Pane,
  Paragraph,
  Sidebar,
  Text,
  TextInput,
  UnorderedList,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import { v4 as uuid } from "uuid";
import { css } from "glamor";

const ASK_QUESTION = gql`
  mutation AskQuestion($question: String!) {
    askQuestion(question: $question) {
      response
    }
  }
`;

const QUESTIONS_QUERY = gql`
  query QuestionsQuery {
    questions {
      id
      question
      answer
    }
  }
`;

export function ChatPage() {
  const { data, loading: queryLoading } = useQuery(QUESTIONS_QUERY);

  if (queryLoading) return null;

  const previousMessages = formatPreviousMessages(data?.questions);
  return <ChatWindow previousMessages={previousMessages} />;
}

function formatPreviousMessages(previousQuestions) {
  if (!previousQuestions) return [];
  return previousQuestions.flatMap((question) => [
    {
      id: question.id,
      message: question.question,
      isQuestion: true,
    },
    { id: question.id, message: question.answer, isQuestion: false },
  ]);
}

function ChatWindow({ previousMessages }) {
  const [messages, setMessages] = useState(previousMessages);
  const [question, setQuestion] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const [askQuestion, { loading }] = useMutation(ASK_QUESTION, {
    onCompleted: (data) => {
      setShowLoading(false);
      setMessages((previousMessages) =>
        previousMessages.concat({
          message: data.askQuestion.response,
          isQuestion: false,
          id: uuid(),
        })
      );
    },
  });

  const handleSubmit = () => {
    setMessages((previousMessages) =>
      previousMessages.concat({
        message: question,
        isQuestion: true,
        id: uuid(),
      })
    );
    askQuestion({ variables: { question } });
    setQuestion("");
  };

  const chatBottomRef = useRef(null);

  useEffect(() => {
    if (
      typeof chatBottomRef?.current?.scrollIntoView === "function" &&
      messages.length > 0
    )
      chatBottomRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages.length]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setShowLoading(true);
      }, 500);
    }
    return undefined;
  }, [loading]);

  return (
    <Fragment>
      <Pane
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="start"
        paddingTop={majorScale(2)}
      >
        <Card
          display="flex"
          flexDirection="column"
          height={620}
          width={600}
          marginTop={majorScale(2)}
          marginLeft={majorScale(3)}
          border
          borderRadius={majorScale(1)}
          backgroundColor="#fdfdfd"
        >
          <Pane
            flex="1 1 auto"
            overflowY="auto"
            display="flex"
            flexDirection="column"
            paddingTop={majorScale(2)}
            paddingX={majorScale(3)}
          >
            {messages.map(({ message, isQuestion, id }) => (
              <Message key={id} message={message} isQuestion={isQuestion} />
            ))}
            {showLoading && <Loading />}
            <div ref={chatBottomRef} />
          </Pane>
          <Pane
            flex="0 0 60px"
            display="flex"
            alignItems="center"
            borderTop="muted"
          >
            <Pane flexGrow="1" marginLeft={majorScale(2)}>
              <TextInput
                value={question}
                onChange={(event) => setQuestion(event.target.value)}
                onKeyUp={(event) =>
                  event.key === "Enter" ? handleSubmit() : null
                }
                disabled={loading}
                width="100%"
                borderRadius={majorScale(4)}
                placeholder="Ask any question about your Rabbet Portfolio!"
              />
            </Pane>
            <Button
              width={130}
              onClick={handleSubmit}
              disabled={isBlank(question) || loading}
              marginLeft={majorScale(2)}
              borderRadius={majorScale(2)}
            >
              Ask Rabbet AI
            </Button>
            <IconButton
              icon={HelpIcon}
              onClick={() => setShowHelp(true)}
              appearance="minimal"
              marginLeft={majorScale(1)}
              marginRight={majorScale(2)}
            />
          </Pane>
        </Card>
      </Pane>
      <Sidebar isShown={showHelp} onCloseComplete={() => setShowHelp(false)}>
        <Sidebar.Section
          paddingX={majorScale(3)}
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(3)}
        >
          <Guidance />
        </Sidebar.Section>
      </Sidebar>
    </Fragment>
  );
}

function Message({ message, isQuestion }) {
  const commonProps = {
    display: "inline-block",
    marginBottom: 10,
    borderRadius: 20,
    padding: 12,
    position: "relative",
    whiteSpace: "pre-line",
  };

  const authorSpecificProps = isQuestion
    ? {
        alignSelf: "end",
        backgroundColor: "#218aff",
        color: "white",
        marginLeft: "25%",
      }
    : { alignSelf: "start", backgroundColor: "#e5e5e5", marginRight: "25%" };

  return (
    <Text
      {...commonProps}
      {...authorSpecificProps}
      {...getMessagePseudoElementProps(isQuestion)}
    >
      {message}
    </Text>
  );
}

const getMessagePseudoElementProps = (isQuestion) => {
  return css({
    ":before": {
      content: '""',
      position: "absolute",
      zIndex: 0,
      bottom: 0,
      height: "20px",
      width: "20px",
      ...(isQuestion
        ? {
            right: "-8px",
            borderBottomLeftRadius: "15px",
            background: "#218aff",
          }
        : {
            left: "-8px",
            borderBottomRightRadius: "15px",
            background: "#e5e5e5",
          }),
    },
    ":after": {
      content: '""',
      position: "absolute",
      zIndex: 1,
      bottom: 0,
      height: "20px",
      width: "10px",
      background: "white",
      ...(isQuestion
        ? { right: "-10px", borderBottomLeftRadius: "10px" }
        : { left: "-10px", borderBottomRightRadius: "10px" }),
    },
  });
};

function Loading() {
  const containerProps = css({
    display: "inline-block",
    alignSelf: "start",
    height: "42px",
    width: "40px",
    background: "#e5e5e5",
    position: "relative",
    marginLeft: "30px",
    ":before": {
      content: '""',
      position: "absolute",
      zIndex: 1,
      height: "42px",
      width: "42px",
      left: "-21px",
      display: "block",
      background: "#e5e5e5",
      borderRadius: "50%",
    },
    ":after": {
      content: '""',
      position: "absolute",
      zIndex: 1,
      height: "42px",
      width: "42px",
      right: "-21px",
      display: "block",
      background: "#e5e5e5",
      borderRadius: "50%",
    },
  });

  const tailProps = css({
    height: "15px",
    width: "15px",
    background: "#e5e5e5",
    position: "absolute",
    left: "-25px",
    bottom: "2px",
    borderRadius: "50%",
    ":before": {
      content: '""',
      position: "absolute",
      height: "7px",
      width: "7px",
      left: "-7px",
      bottom: "-5px",
      display: "block",
      background: "#e5e5e5",
      borderRadius: "50%",
    },
  });

  const loadingContainerProps = {
    position: "absolute",
    zIndex: "10",
    width: "67px",
    left: "-6px",
    top: "15px",
  };

  const dotProps = {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    background: "#c1c1c1",
    display: "block",
    float: "left",
    marginLeft: "7px",
  };

  const cycleOne = css.keyframes({
    "0%": {
      background: "rgba(150, 150, 150, 0.4)",
    },
    "33.333%": {
      background: "rgba(150, 150, 150, 1)",
    },
    "66.6667%": {
      background: "rgba(150, 150, 150, 0.4)",
    },
    "100%": {
      background: "rgba(150, 150, 150, 0.4)",
    },
  });

  const cycleTwo = css.keyframes({
    "0%": {
      background: "rgba(150, 150, 150, 0.4)",
    },
    "33.333%": {
      background: "rgba(150, 150, 150, 0.4)",
    },
    "66.6667%": {
      background: "rgba(150, 150, 150, 1)",
    },
    "100%": {
      background: "rgba(150, 150, 150, 0.4)",
    },
  });

  const cycleThree = css.keyframes({
    "0%": {
      background: "rgba(150, 150, 150, 0.4)",
    },
    "33.333%": {
      background: "rgba(150, 150, 150, 1)",
    },
    "66.6667%": {
      background: "rgba(150, 150, 150, 0.4)",
    },
    "100%": {
      background: "rgba(150, 150, 150, 1)",
    },
  });

  const dotOneAnimationProps = css({
    animation: `${cycleOne} 1s ease-in-out infinite`,
    animationDirection: "normal",
  });
  const dotTwoAnimationProps = css({
    animation: `${cycleTwo} 1s ease-in-out infinite`,
    animationDirection: "normal",
  });
  const dotThreeAnimationProps = css({
    animation: `${cycleThree} 1s ease-in-out infinite`,
    animationDirection: "normal",
  });

  return (
    <Pane alignSelf="start">
      <Pane {...containerProps}>
        <Pane {...loadingContainerProps}>
          <Pane {...dotProps} marginLeft={0} {...dotOneAnimationProps} />
          <Pane {...dotProps} {...dotTwoAnimationProps} />
          <Pane {...dotProps} {...dotThreeAnimationProps} />
        </Pane>
        <Pane {...tailProps} />
      </Pane>
    </Pane>
  );
}

function Guidance() {
  return (
    <Fragment>
      <Heading size={600} fontWeight={700} marginBottom={majorScale(2)}>
        Welcome to Rabbet Vantage Beta!
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>
        We&apos;re excited to introduce Rabbet Vantage, our new AI-powered
        question answering service. Rabbet Vantage allows you to get quick
        answers to your construction project questions by simply asking in
        natural language.
      </Paragraph>
      <Paragraph marginBottom={majorScale(3)}>
        Rabbet Vantage is currently in closed beta and has limited capabilities.
        Here&apos;s what you need to know:
      </Paragraph>
      <Heading size={500} fontWeight={600} marginBottom={majorScale(1)}>
        What Rabbet Vantage can do in the beta
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>
        During the beta period, Rabbet Vantage is able to answer natural
        language questions related to:
      </Paragraph>
      <UnorderedList marginBottom={majorScale(1)}>
        <ListItem>
          <Text>Project loan maturity dates</Text>
        </ListItem>
        <ListItem>
          <Text>Project contingency amounts</Text>
        </ListItem>
        <ListItem>
          <Text>Project completion percentages</Text>
        </ListItem>
        <ListItem>
          <Text>Project near completion status</Text>
        </ListItem>
        <ListItem>
          <Text>Document approval status</Text>
        </ListItem>
        <ListItem>
          <Text>Lien release status</Text>
        </ListItem>
        <ListItem>
          <Text>Insurance certificate expiration dates</Text>
        </ListItem>
        <ListItem>
          <Text>Project budget overages</Text>
        </ListItem>
        <ListItem>
          <Text>Remaining budget amounts for specific line items</Text>
        </ListItem>
        <ListItem>
          <Text>Pending project draws</Text>
        </ListItem>
        <ListItem>
          <Text>Projects lacking recent draws</Text>
        </ListItem>
        <ListItem>
          <Text>Draw statuses (submitted, funded, etc.)</Text>
        </ListItem>
        <ListItem>
          <Text>Draw approvers</Text>
        </ListItem>
      </UnorderedList>
      <Paragraph marginBottom={majorScale(1)} fontWeight={500}>
        Sample questions:
      </Paragraph>
      <UnorderedList marginBottom={majorScale(3)}>
        <ListItem>
          <Text>
            What is the loan maturity date for the ____________ project?
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            How much contingency is left in the ____________ project budget?
          </Text>
        </ListItem>
        <ListItem>
          <Text>Which projects have less than 5% left to complete?</Text>
        </ListItem>
      </UnorderedList>
      <Heading size={500} fontWeight={600} marginBottom={majorScale(1)}>
        What Rabbet Vantage can&apos;t do yet
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>
        While Rabbet Vantage understands questions similar to the examples
        above, its capabilities are narrow during the beta period. Here are some
        things it can&apos;t handle yet:
      </Paragraph>
      <UnorderedList marginBottom={majorScale(1)}>
        <ListItem>
          <Text>Questions about projects not in the Rabbet system</Text>
        </ListItem>
        <ListItem>
          <Text>Complex questions spanning multiple queries</Text>
        </ListItem>
        <ListItem>
          <Text>Subjective questions or summaries</Text>
        </ListItem>
        <ListItem>
          <Text>Questions relying on context not stated explicitly</Text>
        </ListItem>
      </UnorderedList>
      <Paragraph marginBottom={majorScale(3)}>
        As the beta progresses, we&apos;ll continue expanding Rabbet
        Vantage&apos;s capabilities.
      </Paragraph>
      <Heading size={500} fontWeight={600} marginBottom={majorScale(1)}>
        How to use Rabbet Vantage effectively
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>
        To get the most out of Rabbet Vantage during the beta:
      </Paragraph>
      <UnorderedList marginBottom={majorScale(1)}>
        <ListItem>
          <Text>
            Ask short, direct questions similar to the examples provided
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            Be as specific as possible - include project names, budget line
            items, etc.
          </Text>
        </ListItem>
        <ListItem>
          <Text>Avoid hypotheticals, assumptions, or subjective questions</Text>
        </ListItem>
        <ListItem>
          <Text>
            Don&apos;t expect it to make inferences or read between the lines
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            Rephrase your question if you don&apos;t get a relevant answer
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight={500}>Expect responses within 20 seconds</Text>
        </ListItem>
      </UnorderedList>
      <Paragraph>
        We hope you enjoy using Rabbet Vantage Beta to get quick project
        insights! As the beta continues, the service will become smarter and
        more capable. Please share any feedback on your experience so we can
        improve Rabbet Vantage.
      </Paragraph>
    </Fragment>
  );
}
