import { Fragment } from "react";
import { ErrorIcon, WarningSignIcon } from "evergreen-ui";
import { groupIssuesBySeverity } from "helpers/issues";
import { minorScale } from "helpers/utilities";

function AmountOfIssues({ highSeverityIssues, normalSeverityIssues }) {
  const highSeverityIssueCount = highSeverityIssues.length;
  const normalSeverityIssueCount = normalSeverityIssues.length;

  if (highSeverityIssueCount === 0 && normalSeverityIssueCount === 0) {
    return "0";
  }

  return (
    <Fragment>
      {highSeverityIssueCount > 0 && (
        <Fragment>
          {highSeverityIssueCount}
          <ErrorIcon color="danger" marginX={minorScale(1)} size={14} />
        </Fragment>
      )}
      {normalSeverityIssueCount > 0 && (
        <Fragment>
          {normalSeverityIssueCount}
          <WarningSignIcon
            color="warning"
            marginLeft={minorScale(1)}
            size={14}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export function IssuesViewName({ issues }) {
  const { highSeverityIssues, normalSeverityIssues } = groupIssuesBySeverity(
    issues
  );

  // Not using <Text> here, because this ultimately
  // is rendered within <Tab> which has its own text styling
  return (
    <Fragment>
      {"Issues: "}
      <AmountOfIssues
        highSeverityIssues={highSeverityIssues}
        normalSeverityIssues={normalSeverityIssues}
      />
    </Fragment>
  );
}
