import { useContext } from "react";
import PropTypes from "prop-types";
import { Button, Pane, Text } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { blankClipboards } from "images";

export function LineItemFormBlankSlate({
  onAdd,
  addButtonText,
  text,
  containerProps,
}) {
  const theme = useContext(ThemeContext);
  return (
    <Pane
      backgroundColor={theme.colors.warning}
      border="muted"
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
      padding={majorScale(2)}
      {...containerProps}
    >
      <Pane height="90px" paddingRight={majorScale(2)}>
        <img height="100%" alt="" src={blankClipboards} />
      </Pane>
      <Pane height="100%">
        <Text size={400} display="block">
          {text}
        </Text>
        <Button
          marginTop={majorScale(1)}
          onClick={onAdd}
          purpose="document-review line-items add blank-add"
        >
          {addButtonText}
        </Button>
      </Pane>
    </Pane>
  );
}

LineItemFormBlankSlate.propTypes = {
  onAdd: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
