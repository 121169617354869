import { Fragment, useContext, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Loadable, Pane } from "components/materials";
import { useFilteredProjects, UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { get } from "lodash";
import { dateFormToServer } from "helpers/dateHelpers";
import {
  ContingencyRisk,
  DrawProcessingTime,
  Filters,
  OutstandingProjects,
  PortfolioComposition,
  ScheduleRisk,
  TeamAllocation,
  UserActivity,
  VendorDiversity,
} from ".";
import { filterFields, withProjectTypes } from "./helpers";
import { LENDER_PORTFOLIO_OVERVIEW_QUERY } from "./graphql-queries";

export function LenderPortfolioInsights({
  commonStyleRules,
  history,
  selectedOrganization,
}) {
  const { analyticsContext, hasOrgLevelPermission } = useContext(UserContext);
  const { id: organizationId } = selectedOrganization;
  const { data, loading } = useQuery(LENDER_PORTFOLIO_OVERVIEW_QUERY, {
    context: {
      ...analyticsContext,
      analyticsMessage: "Lender Portfolio Insights Page",
    },
    variables: { organizationId, currentDate: dateFormToServer(Date.now()) },
  });

  const projectTypes = get(data, "organization.projectTemplates", []);
  const projectsWithoutProjectType = get(data, "organization.projects", []);

  const projects = useMemo(
    () => withProjectTypes(projectsWithoutProjectType, projectTypes),
    [projectsWithoutProjectType, projectTypes]
  );

  const [
    filterConfig,
    filteredProjects,
    filteredTeams,
    setFilterConfig,
  ] = useFilteredProjects(projects);

  if (loading) return <Loadable loading />;

  const numberOfProjects = filteredProjects.length;

  return (
    <Fragment>
      <Filters
        filterConfig={filterConfig}
        filterFields={filterFields}
        projects={projects}
        setFilterConfig={setFilterConfig}
      />
      <Pane display="flex" justifyContent="center" padding={majorScale(1)}>
        <Pane maxWidth={1400}>
          <Pane padding={majorScale(1)}>
            <PortfolioComposition
              history={history}
              selectedOrganization={selectedOrganization}
              projects={filteredProjects}
              stylingProps={commonStyleRules}
            />
          </Pane>
          <Pane display="flex">
            <Pane width="50%" padding={majorScale(1)}>
              <DrawProcessingTime
                history={history}
                selectedOrganization={selectedOrganization}
                projects={filteredProjects}
                stylingProps={commonStyleRules}
              />
              {hasOrgLevelPermission(
                PERMISSION_ACTION.TEAM_MANAGEMENT,
                selectedOrganization
              ) &&
                filteredTeams.length !== 0 && (
                  <TeamAllocation
                    history={history}
                    numberOfProjects={numberOfProjects}
                    organizationId={organizationId}
                    stylingProps={commonStyleRules}
                    teams={filteredTeams}
                  />
                )}
              <UserActivity
                history={history}
                organizationId={organizationId}
                projects={filteredProjects}
                stylingProps={commonStyleRules}
              />
              <VendorDiversity
                history={history}
                organizationId={organizationId}
                selectedOrganization={selectedOrganization}
                stylingProps={commonStyleRules}
                projects={filteredProjects}
              />
            </Pane>
            <Pane width="50%" padding={majorScale(1)}>
              <OutstandingProjects
                history={history}
                organizationId={organizationId}
                projects={filteredProjects}
                stylingProps={commonStyleRules}
              />
              <ScheduleRisk
                history={history}
                organizationId={organizationId}
                projects={filteredProjects}
                stylingProps={commonStyleRules}
              />
              <ContingencyRisk
                history={history}
                organizationId={organizationId}
                projects={filteredProjects}
                stylingProps={commonStyleRules}
              />
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </Fragment>
  );
}
