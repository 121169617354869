import gql from "graphql-tag";

export default gql`
  fragment DashboardDocumentsFragment on Document {
    id
    amount
    reassigned
    newlyAssigned
    approvedBy {
      id
    }
    approvedUsers {
      id
    }
    assignedUser {
      id
      fullName
    }
    draw {
      id
      name
    }
    originalFile {
      name
      url
    }
    pages
    processingFinishedAt
    project {
      id
      name
      users {
        id
        fullName
      }
      documentReviewers {
        id
        user {
          id
        }
      }
      suggestedDocumentAssignees {
        id
        userId
      }
      organization {
        id
      }
    }
    state
    type
    vendor {
      id
      name
    }
    ... on DrawSummary {
      lineItems {
        id
        name
        amount
      }
    }
    ... on Invoice {
      lineItems {
        id
        name
        amount
      }
    }
    ... on LienRelease {
      lineItems {
        id
        name
        amount
      }
    }
    ... on PayApplication {
      lineItems {
        id
        name: descriptionOfWork
        amount: applicationAmount
      }
    }
    ... on AgreementDocument {
      adjustedAmount
      agreementAmount
    }
  }
`;
