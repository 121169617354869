import { splitBoxProps } from "ui-box";
import { isFunction, pick } from "lodash";

// boxProps delegates to ui-box's splitBoxProps
// function (https://github.com/segmentio/ui-box#splitboxpropsprops)
// in order to return only props that are accepted by a ui-box
// component. This is necessary to filter out props that will
// trigger warnings when they get passed along to the underlying
// html element.
export function boxProps(props) {
  const { matchedProps } = splitBoxProps(props);
  return matchedProps;
}

export { propTypes as boxPropTypes } from "ui-box";

// result calls the first paramter with the remaining paremters
// as arguments if the first parameter is a function, otherwise
// it returns the value provided as the first parameter.
export function result(value, ...args) {
  if (isFunction(value)) return value.call(this, ...args);
  return value;
}

export const getProps = (propTypes, props) => {
  return pick(props, Object.keys(propTypes));
};
