import { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Heading,
  Link,
  MentionsBody,
  Pane,
  Paragraph,
  Shortener,
  Strong,
  Text,
} from "components/materials";
import { formatDateTime } from "helpers/dateHelpers";
import { get } from "lodash";
import { RULE_TYPE } from "helpers/enums";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";

function CommentTarget({ comment, actionLabel, showTarget }) {
  if (!showTarget || !comment.target) return <Text>{actionLabel}</Text>;

  const { target } = comment;

  let targetName = null;
  let targetPath = null;

  switch (target.__typename) {
    case "Rule":
      targetName =
        target.type === RULE_TYPE.AUTOMATED
          ? t(`drawRules.${target.name}`)
          : target.name;
      break;
    case "RuleTransition":
      targetName =
        target.rule.type === RULE_TYPE.AUTOMATED
          ? t(`drawRules.${target.rule.name}`)
          : target.rule.name;
      break;
    case "LineItem":
      targetName = target.name;
      targetPath = `/projects/${target.projectId}/draws/${target.drawId}/line_items/${target.id}`;
      break;

    case "BasicDocument":
      targetName = get(comment, "target.file.name");
      targetPath = target.drawId
        ? `/projects/${target.projectId}/draws/${target.drawId}/documentation/${target.id}`
        : `/projects/${target.projectId}/documentation/${target.id}`;
      break;
    default:
      return "";
  }

  return (
    <Fragment>
      <Text>{`${actionLabel} on `}</Text>
      {targetPath === null ? (
        <Text>{targetName}</Text>
      ) : (
        <Link
          purpose="comment target"
          to={targetPath}
          content={<Shortener text={targetName} limit={50} isLink />}
        />
      )}
    </Fragment>
  );
}

function Comment({ comment, actionLabel, showTarget }) {
  return (
    <Pane key={comment.id} marginBottom={majorScale(2)} marginX={majorScale(1)}>
      <Pane>
        <Strong>{comment.author.fullName}</Strong>{" "}
        <CommentTarget
          comment={comment}
          actionLabel={actionLabel}
          showTarget={showTarget}
        />{" "}
        <Text fontStyle="italic">{`on ${formatDateTime(
          comment.insertedAt
        )}`}</Text>
      </Pane>
      {comment.body && (
        <Pane>
          <Paragraph wordBreak="break-word">
            <MentionsBody comment={comment} />
          </Paragraph>
        </Pane>
      )}
    </Pane>
  );
}

function Activity({ activity, showTarget }) {
  if (activity.comments.length > 0) {
    return activity.comments.map((comment) => (
      <Comment
        comment={comment}
        actionLabel={t(`ruleTransitions.${activity.toState}`)}
        showTarget={showTarget}
      />
    ));
  }

  return (
    <Comment
      comment={{
        target: activity,
        author: activity.user || { fullName: "System" },
        body: null,
        insertedAt: activity.insertedAt,
      }}
      actionLabel={t(`ruleTransitions.${activity.toState}`)}
    />
  );
}
export function CommentList({ label, comments, showTarget }) {
  if (comments.length === 0) return null;
  return (
    <Pane>
      {label && (
        <Heading marginBottom={majorScale(1)} size={500}>
          {label}
        </Heading>
      )}
      {comments.map((comment) =>
        comment.__typename === "Comment" ? (
          <Comment
            actionLabel="commented"
            comment={comment}
            key={comment.id}
            showTarget={showTarget}
          />
        ) : (
          <Activity
            activity={comment}
            key={comment.id}
            showTarget={showTarget}
          />
        )
      )}
    </Pane>
  );
}

CommentList.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      author: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  label: PropTypes.string,
  showTarget: PropTypes.bool,
};

CommentList.defaultProps = {
  showTarget: true,
};
