import { useContext } from "react";
import { Link, Pane, Paragraph } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";

export function Header({ header, subheader, learnLink }) {
  const theme = useContext(ThemeContext);
  return (
    <Pane marginBottom={majorScale(3)}>
      <Paragraph
        fontSize={24}
        fontWeight={theme.fontWeights.MEDIUM}
        marginY={minorScale(1)}
      >
        {header}
      </Paragraph>
      <Paragraph color="muted" size={400}>
        {subheader}
        {learnLink && (
          <Link
            href={learnLink || "#"}
            marginLeft={minorScale(1)}
            purpose="onboarding-wizard step help"
          >
            Learn More.
          </Link>
        )}
      </Paragraph>
    </Pane>
  );
}
