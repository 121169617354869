import { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { SmallTickIcon, SmallCrossIcon, AddIcon } from "evergreen-ui";
import {
  Button,
  DescriptionWithHelpLink,
  Divider,
  Form,
  Grid,
  Heading,
  InterpolatedTranslation,
  Pane,
} from "components/materials";
import { DRAW_RULES } from "helpers/drawRules";
import { RULE_CONFIG_INPUT_TYPE, RULE_TYPE } from "helpers/enums";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { map } from "lodash";
import t from "helpers/translate";

const projectRequiredDocumentationRules = [
  DRAW_RULES.INVOICES_RECONCILE_WITH_SUMMARY,
  DRAW_RULES.PROJECT_HAS_GENERAL_LIABILITY_INSURANCE_CERTIFICATE,
  DRAW_RULES.PROJECT_HAS_BUILDERS_RISK_CERTIFICATE,
  DRAW_RULES.PROJECT_HAS_PERFORMANCE_BOND,
  DRAW_RULES.REQUIRE_BILL_OF_SALE_FOR_STORED_MATERIALS,
  DRAW_RULES.REQUIRE_INSURANCE_FOR_STORED_MATERIALS,
  DRAW_RULES.REQUEST_NOTICE_OF_COMPLETION_AND_CERTIFICATE_OF_OCCUPANCY,
];
const drawRequiredDocumentationRules = [
  DRAW_RULES.DRAW_DOES_NOT_HAVE_NOTICE_TO_OWNER,
  DRAW_RULES.DRAW_HAS_COVER_SHEET,
  DRAW_RULES.DRAW_HAS_INSPECTION_REPORT,
  DRAW_RULES.DRAW_HAS_TITLE_ENDORSEMENT,
  DRAW_RULES.GENERAL_CONTRACTOR_PAY_APPLICATIONS_COVERED_BY_UNCONDITIONAL_LIEN_WAIVERS,
  DRAW_RULES.PAY_APPLICATIONS_COVERED_BY_UNCONDITIONAL_LIEN_WAIVERS,
  DRAW_RULES.REQUIRE_CHANGE_ORDER_FOR_DRAW_ADJUSTMENTS,
];
const documentInformationRules = [
  DRAW_RULES.DUPLICATE_INVOICE_NUMBERS,
  DRAW_RULES.FIRST_HARD_COST_DRAW_REQUIRES_FOUNDATION_ENDORSEMENT,
  DRAW_RULES.REQUIRE_CHANGE_ORDER_FOR_LINE_ITEM_ADJUSTMENTS_OVER_X,
  DRAW_RULES.REQUIRE_LIEN_WAIVER_FOR_HARD_COSTS_OVER_X,
  DRAW_RULES.REQUIRE_RETAINAGE_RELEASE_ON_REDUCED_RETAINAGE,
  DRAW_RULES.SUBCONTRACTOR_INVOICES_MATCH_GC_PAY_APPLICATION,
  DRAW_RULES.REQUIRE_LIEN_WAIVER_IF_VENDOR_HAS_NOTICE_TO_OWNER,
  DRAW_RULES.VENDOR_LINE_ITEM_AMOUNT_DOES_NOT_EXCEED_AGREEMENT_AMOUNT,
  DRAW_RULES.CONDITIONAL_LIEN_WAIVERS_MUST_MATCH_INVOICES,
  DRAW_RULES.RETAINAGE_REQUESTED_MATCHES_AGREEMENT_RETAINAGE,
];
const lineItemAmountsRules = [
  DRAW_RULES.COVER_SHEET_RECONCILES_WITH_SUMMARY,
  DRAW_RULES.OVERDRAWN_LINE_ITEMS,
  DRAW_RULES.INTEREST_RESERVES_REMAINING,
  DRAW_RULES.INSPECTION_LINE_ITEM_PERCENT_COMPLETE,
  DRAW_RULES.INSPECTION_WITHIN_X_PERCENT_OF_HARD_COSTS,
  DRAW_RULES.PROJECT_BEHIND_SCHEDULE,
  DRAW_RULES.LINE_ITEM_AMOUNT_DOES_NOT_EXCEED_AGREEMENT_AMOUNT,
  DRAW_RULES.LINE_ITEMS_HAVE_BUDGET_REMAINING_TO_PAY_OUTSTANDING_AGREEMENTS,
];
const processingRules = [
  DRAW_RULES.OVERUSED_CONTINGENCY,
  DRAW_RULES.RETAINAGE_RELEASED,
  DRAW_RULES.OVERDUE_TASKS,
];

function RuleLabel({ config, index, name }) {
  const inputs = map(config, (varSettings, varName) => {
    const getInputType = (inputType) => {
      // Find the type as to be used by `FormInput`
      const mappings = {
        [RULE_CONFIG_INPUT_TYPE.INTEGER]: "integer",
        [RULE_CONFIG_INPUT_TYPE.FLOAT]: "number",
        [RULE_CONFIG_INPUT_TYPE.STRING]: "text",
        [RULE_CONFIG_INPUT_TYPE.CURRENCY]: "currency",
      };

      return mappings[inputType] || "text";
    };

    // Use `preventEventBubbling` to prevent clicking on
    // the input from toggling the rule's switch control
    return (
      <Form.Input
        outerProps={{
          display: "inline-block",
          marginX: minorScale(1),
          width: "auto",
        }}
        key={varName}
        onClick={(e) => preventEventBubbling(e)}
        type={getInputType(varSettings.type)}
        name={`rules[${index}].config.${varName}.value`}
        width={100}
      />
    );
  });

  return (
    <InterpolatedTranslation string={t(`drawRules.${name}`)} values={inputs} />
  );
}

function RuleCategory({ categoryName, rules }) {
  return (
    <Pane>
      <Heading size={400}>{categoryName}</Heading>
      {rules.map(({ name, config, index }) => {
        return (
          <Form.Switch
            key={name}
            label={<RuleLabel index={index} name={name} config={config} />}
            marginY={minorScale(1)}
            name={`rules.${index}.enabled`}
          />
        );
      })}
    </Pane>
  );
}

const RulesForm = ({
  confirmManual,
  setFieldValue,
  values: { rules, customRules },
}) => {
  const theme = useContext(ThemeContext);
  function getRulesForCategory(rulesInCategory) {
    return rules.reduce((categoryRules, rule, index) => {
      if (rulesInCategory.indexOf(rule.name) >= 0) {
        return categoryRules.concat({
          ...rule,
          index,
        });
      }
      return categoryRules;
    }, []);
  }

  const renderRules = () => {
    const orgEnabledProjectRequiredDocumentationRules = getRulesForCategory(
      projectRequiredDocumentationRules
    );
    const orgEnabledDrawRequiredDocumentationRules = getRulesForCategory(
      drawRequiredDocumentationRules
    );
    const orgEnabledDocumentInformationRules = getRulesForCategory(
      documentInformationRules
    );
    const orgEnabledLineItemAmountsRules = getRulesForCategory(
      lineItemAmountsRules
    );
    const orgEnabledProcessingRules = getRulesForCategory(processingRules);
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column columns={8} paddingX={majorScale(3)}>
            {orgEnabledProjectRequiredDocumentationRules.length > 0 && (
              <Fragment>
                <RuleCategory
                  categoryName="Project Required Documentation"
                  rules={orgEnabledProjectRequiredDocumentationRules}
                />
                <Divider />
              </Fragment>
            )}
            {orgEnabledDrawRequiredDocumentationRules.length > 0 && (
              <Fragment>
                <RuleCategory
                  categoryName="Draw Required Documentation"
                  rules={orgEnabledDrawRequiredDocumentationRules}
                />
                <Divider />
              </Fragment>
            )}
            {orgEnabledDocumentInformationRules.length > 0 && (
              <RuleCategory
                categoryName="Document Information"
                rules={orgEnabledDocumentInformationRules}
              />
            )}
          </Grid.Column>
          <Grid.Column columns={8} paddingX={majorScale(3)}>
            {orgEnabledLineItemAmountsRules.length > 0 && (
              <Fragment>
                <RuleCategory
                  categoryName="Line Item Amounts"
                  rules={orgEnabledLineItemAmountsRules}
                />
                <Divider />
              </Fragment>
            )}
            {orgEnabledProcessingRules.length > 0 && (
              <RuleCategory
                categoryName="Processing"
                rules={orgEnabledProcessingRules}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderCustom = ({ push, remove }) => {
    const custom = customRules.map((rule, index) =>
      rule.id || rule.confirmed ? (
        <Form.Switch
          key={rule.id}
          label={rule.name}
          marginY={minorScale(1)}
          name={`customRules.${index}.enabled`}
        />
      ) : (
        <Pane
          key={index}
          marginTop={majorScale(2)}
          display="flex"
          alignItems="center"
        >
          <Pane marginRight={majorScale(1)} width={300}>
            <Form.Input name={`customRules.${index}.name`} />
          </Pane>
          {confirmManual && setFieldValue && (
            <Button
              appearance="minimal"
              size="small"
              iconBefore={SmallTickIcon}
              intent="success"
              purpose="rules-form confirm"
              onClick={() => {
                setFieldValue(`customRules.${index}.confirmed`, true);
              }}
            >
              Confirm
            </Button>
          )}
          <Button
            appearance="minimal"
            size="small"
            iconBefore={SmallCrossIcon}
            intent="danger"
            purpose="rules-form remove"
            onClick={() => remove(index)}
          >
            Remove
          </Button>
        </Pane>
      )
    );

    return (
      <Fragment>
        {custom}
        <Divider />
        <Button
          appearance="minimal"
          color={theme.colors.blue600}
          size="small"
          iconBefore={AddIcon}
          purpose="rules-form add"
          onClick={() =>
            push({
              name: "",
              enabled: true,
              type: RULE_TYPE.MANUAL,
              confirmed: false,
            })
          }
        >
          Add a manual rule
        </Button>
      </Fragment>
    );
  };

  return (
    <Pane justifyContent="space-between">
      <Pane marginBottom={majorScale(4)}>
        <Heading size={400}>Automatic</Heading>
        <Divider />
        <DescriptionWithHelpLink
          purpose="project-settings automatic-rules help"
          text={t(`projectSettings.automaticRules`)}
          helpLink="http://help.rabbet.com/en/articles/2554529-edit-project-settings"
        />
        <Divider />
        <FieldArray name="rules" render={renderRules} />
      </Pane>
      <Pane>
        <Heading size={400}>Manual</Heading>
        <Divider />
        <DescriptionWithHelpLink
          purpose="project-settings custom-rules help"
          text={t(`projectSettings.customRules`)}
          helpLink="http://help.rabbet.com/en/articles/2554529-edit-project-settings"
        />
        <Divider />
        <FieldArray name="customRules" render={renderCustom} />
      </Pane>
    </Pane>
  );
};

RulesForm.propTypes = {
  values: PropTypes.shape({
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        enabled: PropTypes.bool,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
    customRules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        enabled: PropTypes.bool,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default RulesForm;
