import PropTypes from "prop-types";
import { FastField, getIn } from "formik";
import { MentionsCommentField } from "components/materials";

export function FormMentionsTextArea(props) {
  const { name, onBlur, users, ...rest } = props;

  return (
    <FastField name={name}>
      {({ field, form, meta }) => {
        const disabled =
          getIn(form.values, "__disabled") ||
          getIn(form.status, "disabled") ||
          rest.disabled;

        const handleBlur = (e) => {
          field.onBlur(e);
          onBlur && onBlur(e);
        };

        return (
          <MentionsCommentField
            {...field}
            {...rest}
            disabled={disabled}
            meta={meta}
            name={name}
            onBlur={handleBlur}
            onChange={(e) => form.setFieldValue(name, e.target.value)}
            users={users}
          />
        );
      }}
    </FastField>
  );
}

FormMentionsTextArea.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    })
  ),
};
