import PropTypes from "prop-types";
import { CaretLeftIcon, CaretRightIcon } from "evergreen-ui";
import { Button, Grid, Pane, Tab, Tablist, Text } from "components/materials";
import t from "helpers/translate";
import { get } from "lodash";

export function FastDataTablePagination({
  config,
  itemCount,
  pageDisabled,
  pageSize,
  setConfig,
  setPage,
  simplePaginationControls: simple,
}) {
  if (pageDisabled || pageSize >= itemCount) {
    return null;
  }

  const pageNumber = get(config, "pageConfig.page", 0);

  const pageMin = 0;
  const pageMax = Math.floor((itemCount - 1) / pageSize);

  const setPageNumber = (page) => {
    const payload = {
      ...config,
      pageConfig: {
        ...config.pageConfig,
        page,
      },
    };
    // If bypassing the config is necessary,
    // we need to keep track of which page we're on
    // See CreateOrganizationForm for an example
    if (setPage) setPage(page);

    setConfig({ payload, type: "changePage" });
  };

  const renderTab = (page) => {
    return (
      <Tab
        isSelected={page === pageNumber}
        onSelect={() => setPageNumber(page)}
      >
        {page + 1}
      </Tab>
    );
  };

  return (
    <Grid alignItems="center">
      <Grid.Row>
        {!simple && (
          <Grid.Column columns={16 / 3}>
            <Text>
              {t("pagination.count", {
                end: Math.min((pageNumber + 1) * pageSize, itemCount),
                start: pageNumber * pageSize + 1,
                total: itemCount,
              })}
            </Text>
          </Grid.Column>
        )}
        <Grid.Column columns={simple ? 16 : 16 / 3}>
          <Pane alignItems="center" display="flex" justifyContent="center">
            <Button
              purpose="tables paginate previous"
              appearance="minimal"
              disabled={pageNumber === pageMin}
              iconBefore={CaretLeftIcon}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              {t("pagination.previous")}
            </Button>
            <Tablist>
              {pageNumber > pageMin && renderTab(pageMin)}
              {pageNumber > pageMin + 2 && <Text>...</Text>}
              {pageNumber > pageMin + 1 && renderTab(pageNumber - 1)}
              {renderTab(pageNumber)}
              {pageNumber < pageMax - 1 && renderTab(pageNumber + 1)}
              {pageNumber < pageMax - 2 && <Text>...</Text>}
              {pageNumber < pageMax && renderTab(pageMax)}
            </Tablist>
            <Button
              purpose="tables paginate next"
              appearance="minimal"
              disabled={pageNumber === pageMax}
              iconBefore={CaretRightIcon}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              {t("pagination.next")}
            </Button>
          </Pane>
        </Grid.Column>
        {!simple && <Grid.Column columns={16 / 3} />}
      </Grid.Row>
    </Grid>
  );
}

FastDataTablePagination.propTypes = {
  pageDisabled: PropTypes.bool,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  itemCount: PropTypes.number,
  setPageNumber: PropTypes.func,
};

FastDataTablePagination.defaultProps = {};
