import { Fragment } from "react";
import PropTypes from "prop-types";
import { SelectMenu } from "components/materials";
import { Position } from "helpers/utilities";
import { orderBy } from "lodash";

export function AssignToDropdown({
  children,
  disabled,
  suggestedDocumentAssignees,
  hideTitle,
  onSelect,
  selectedUserId,
  users,
}) {
  const suggestedDocumentAssigneeIds = suggestedDocumentAssignees.map(
    ({ userId }) => userId
  );

  let preparedUsers = users.map((user) => ({
    ...user,
    isSuggestedAssignee: suggestedDocumentAssigneeIds.includes(user.id),
  }));

  preparedUsers = orderBy(
    preparedUsers,
    ["isSuggestedAssignee", "fullName"],
    ["desc", "asc"]
  );

  return (
    <SelectMenu
      closeOnSelect
      disabled={disabled}
      hasFilter={false}
      hasTitle={!hideTitle}
      position={Position.TOP}
      options={[
        ...preparedUsers.map((user) => ({
          label: user.isSuggestedAssignee ? (
            <Fragment>
              <strong>{user.fullName}</strong>&nbsp;(Suggested)
            </Fragment>
          ) : (
            user.fullName
          ),
          value: user.id,
        })),
        { label: "Assign to no-one", value: null },
      ]}
      onSelect={({ value: userId }) => {
        onSelect(userId);
      }}
      selected={selectedUserId}
      title="Assign to..."
      maxWidth={200}
    >
      {children}
    </SelectMenu>
  );
}

AssignToDropdown.propTypes = {
  suggestedDocumentAssignees: PropTypes.arrayOf(PropTypes.object),
  hideTitle: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedUserId: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
};

AssignToDropdown.defaultProps = {
  suggestedDocumentAssignees: [],
  hideTitle: false,
  onSelect: () => {},
  selectedUserId: null,
  users: [],
};
export default AssignToDropdown;
