export function getEnumValuesForOrganization(orgData) {
  return {
    productTypes: orgData.productTypes.map((type) => type.type).concat(null),
    projectRegions: orgData.projectRegions
      .map((region) => region.region)
      .concat(null),
    projectTemplates: orgData.projectTemplates.map((template) => template.name),
    teams: orgData.teams.map((team) => team.name).concat(null),
  };
}
