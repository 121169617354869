export const CUSTOM_FIELD_TYPE = {
  BOOLEAN: "BOOLEAN",
  DATE: "DATE",
  DROPDOWN: "DROPDOWN",
  INTEGER: "INTEGER",
  MONEY: "MONEY",
  NUMBER: "NUMBER",
  PERCENT: "PERCENT",
  TEXT: "TEXT",
};
