import { Fragment, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { get, uniqBy } from "lodash";
import {
  DashboardDocumentCard,
  DashboardDrawApprovalCard,
  DashboardMentionCard,
} from "components/templates";
import { Heading } from "components/materials";
import { majorScale } from "helpers/utilities";

const APPROVE = gql`
  mutation DrawApprovalTodo($drawId: String!) {
    approveDraw(drawId: $drawId) {
      id
    }
  }
`;

export function DashboardTodos({
  getDocumentURL,
  history,
  isPortfolioPage,
  todos,
}) {
  const [approvedDocuments, setApprovedDocuments] = useState([]);
  const [dismissedMentions, setDismissedMentions] = useState([]);
  const [approvedDraws, setApprovedDraws] = useState([]);

  function onDrawApproveCompleted({ approveDraw }) {
    setApprovedDraws((approved) => [...approved, approveDraw.id]);
  }

  function onDocumentApproveCompleted({ approveDocuments }) {
    setApprovedDocuments((approved) => [
      ...approved,
      get(approveDocuments, "0.id"),
    ]);
  }

  function onDismissCompleted({ dismissMention }) {
    setDismissedMentions((dismissed) => [
      ...dismissed,
      get(dismissMention, "id"),
    ]);
  }

  const [approveDraw] = useMutation(APPROVE, {
    onCompleted: onDrawApproveCompleted,
  });

  const mentions = get(todos, "mentions", []).filter(
    (mention) => !!mention.comment.target
  );
  const documents = get(todos, "documents", []);
  const drawApprovals = get(todos, "drawApprovals", []);

  const projects = isPortfolioPage
    ? uniqBy(
        mentions
          .map(({ comment: { project } }) => project)
          .concat(documents.map(({ project }) => project))
          .concat(drawApprovals.map(({ draw }) => draw.project)),
        "id"
      ).map((project) => ({
        ...project,
        mentions: mentions.filter((mention) =>
          mention.comment.project
            ? mention.comment.project.id === project.id
            : mention.comment.target.projectId === project.id
        ),
        documents: documents.filter(
          (document) => document.project.id === project.id
        ),
        drawApprovals: drawApprovals.filter(
          ({ draw }) => draw.project.id === project.id
        ),
      }))
    : [todos];

  return projects.map((project) => (
    <Fragment key={project.id}>
      {isPortfolioPage && (
        <Heading
          marginBottom={majorScale(1)}
          marginTop={majorScale(2)}
          size={200}
        >
          {project.name}
        </Heading>
      )}
      {project.drawApprovals.map(({ draw }) => (
        <DashboardDrawApprovalCard
          approvedDraws={approvedDraws}
          draw={draw}
          key={draw.id}
          onApprove={(event) => {
            event.stopPropagation();
            approveDraw({ variables: { drawId: draw.id } });
          }}
          onClick={() => {
            history.push(`/projects/${draw.project.id}/draws/${draw.id}`);
          }}
          projectName={project.name}
        />
      ))}
      {project.mentions
        .filter((mention) => !!mention.comment.target)
        .map((mention) => (
          <DashboardMentionCard
            dismissedMentions={dismissedMentions}
            history={history}
            key={mention.id}
            mention={mention}
            onDismissCompleted={onDismissCompleted}
          />
        ))}
      {project.documents.map((document) => (
        <DashboardDocumentCard
          approvedDocuments={approvedDocuments}
          getDocumentURL={getDocumentURL}
          document={document}
          documentReviewers={document.project.documentReviewers}
          suggestedDocumentAssignees={
            document.project.suggestedDocumentAssignees
          }
          history={history}
          key={document.id}
          onApproveCompleted={onDocumentApproveCompleted}
          organization={document.project.organization}
          users={document.project.users}
        />
      ))}
    </Fragment>
  ));
}
