import { Fragment } from "react";
import { MoreIcon, TickIcon } from "evergreen-ui";
import { Badge, Grid, Pane, Text } from "components/materials";
import { compact, partition } from "lodash";
import { formatDateTime } from "helpers/dateHelpers";
import { majorScale, minorScale } from "helpers/utilities";
import { REVIEW_STATE } from "helpers/enums";

export function sortReviews(reviews) {
  const preparer = reviews.find(({ isPreparer }) => isPreparer);
  const signatory = reviews.find(({ isFinal }) => isFinal);
  const reviewers = reviews.filter(
    ({ id }) => id !== preparer?.id && id !== signatory?.id
  );

  return compact([preparer].concat(reviewers).concat(signatory));
}

function Review({ review }) {
  return (
    <Pane display="flex" alignItems="center">
      <Text>{review.reviewer.fullName}</Text>
      {review.isFinal && (
        <Text
          color="muted"
          fontStyle="italic"
          size={300}
          marginLeft={minorScale(1)}
        >
          signatory
        </Text>
      )}
      {review.isPreparer && (
        <Text
          color="muted"
          fontStyle="italic"
          size={300}
          marginLeft={minorScale(1)}
        >
          preparer
        </Text>
      )}
      {review.state === REVIEW_STATE.APPROVED && (
        <Fragment>
          <Pane flexGrow={1} flexShrink={1} />
          <Text color="muted" size={300}>
            {formatDateTime(review.processedAt, null)}
          </Text>
        </Fragment>
      )}
    </Pane>
  );
}

export function ListReviews({ reviews }) {
  if (reviews.length === 0) return <Text>Draw has no reviewers</Text>;

  const sortedReviews = sortReviews(reviews);

  const [approved, pending] = partition(
    sortedReviews,
    (review) => review.state === REVIEW_STATE.APPROVED
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column columns={8}>
          <Badge color="yellow" marginBottom={majorScale(1)}>
            <MoreIcon size={10} />
            {" PENDING APPROVAL "}
            {`(${pending.length}/${reviews.length})`}
          </Badge>
          {pending.map((pendingReview) => (
            <Review key={pendingReview.id} review={pendingReview} />
          ))}
        </Grid.Column>
        <Grid.Column columns={8}>
          <Badge color="green" marginBottom={majorScale(1)}>
            <TickIcon size={10} />
            {" APPROVED "}
            {`(${approved.length}/${reviews.length})`}
          </Badge>
          {approved.map((approvedReview) => (
            <Review key={approvedReview.id} review={approvedReview} />
          ))}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
