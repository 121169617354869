import { Fragment, useState } from "react";
import { CrossIcon, DownloadIcon } from "evergreen-ui";
import {
  Button,
  Card,
  FileViewer,
  Link,
  Loadable,
  Modal,
  Pane,
  Paragraph,
  Table,
  Tooltip,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { formatDateTime } from "helpers/dateHelpers";
import { BORROWER_DOCUMENT_TYPE } from "helpers/enums";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import t from "helpers/translate";
import { AddDocumentsButton } from "./AddDocumentsButton";

const BASE_URL = process.env.REACT_APP_GRAPHQL_HOST;

const categoryOrder = [
  BORROWER_DOCUMENT_TYPE.DRAW_SUMMARY,
  BORROWER_DOCUMENT_TYPE.LIEN_WAIVER_OR_PAY_APPLICATION,
  BORROWER_DOCUMENT_TYPE.INVOICE,
  BORROWER_DOCUMENT_TYPE.OTHER,
];

function getDocumentsByCategory(documents) {
  return documents.reduce(
    (groups, document) => ({
      ...groups,
      [document.type]: [...(groups[document.type] || []), document],
    }),
    {}
  );
}

function handleDeleteDocument(deleteUpload, documentId, projectId) {
  deleteUpload({ variables: { documentId, projectId } });
}

function FilePreview({ document, onClose }) {
  const file = {
    name: document.fileName,
    type: document.fileContentType,
    url: document.fileUrl,
  };
  if (!file.url) return <Loadable loading />;
  return (
    <Modal
      open
      onClose={onClose}
      size="fullscreen"
      title={file.name}
      zIndex={9999}
    >
      <Modal.Content>
        <FileViewer file={file} height="75vh" />
      </Modal.Content>
      <Modal.Actions>
        <Pane display="flex" justifyContent="flex-end">
          <Button content="Close" onClick={onClose} />
        </Pane>
      </Modal.Actions>
    </Modal>
  );
}

function DocumentTable({
  deleteUpload,
  documents,
  drawHasBeenSubmitted,
  projectId,
  setPreviewFileProps,
}) {
  return (
    <Pane paddingTop={10}>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.TextHeaderCell width={300}>
              File Uploaded
            </Table.TextHeaderCell>
            <Table.TextHeaderCell width={20} />
            <Table.TextHeaderCell width={50}>Pages</Table.TextHeaderCell>
            <Table.TextHeaderCell width={135}>Uploaded</Table.TextHeaderCell>
            {!drawHasBeenSubmitted && <Table.TextHeaderCell width={20} />}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {documents.map((document) => {
            return (
              <TableRow
                deleteUpload={deleteUpload}
                document={document}
                drawHasBeenSubmitted={drawHasBeenSubmitted}
                projectId={projectId}
                setPreviewFileProps={setPreviewFileProps}
              />
            );
          })}
        </Table.Body>
      </Table>
    </Pane>
  );
}

function TableRow({
  deleteUpload,
  document,
  drawHasBeenSubmitted,
  projectId,
  setPreviewFileProps,
}) {
  const pageCount = document.pages[document.pages.length - 1];

  return (
    <Table.Row>
      <Table.TextCell>
        <Link
          onClick={() => {
            setPreviewFileProps({ document });
          }}
          size={300}
        >
          {document.fileName}
        </Link>
      </Table.TextCell>
      <Table.TextCell>
        <Link
          onClick={() => {
            window.open(
              `${BASE_URL}/download_borrower_document/${document.id}`
            );
          }}
        >
          <DownloadIcon color="#001F40" size="12" />
        </Link>
      </Table.TextCell>
      <Table.TextCell textAlign="right">{pageCount}</Table.TextCell>
      <Table.TextCell>{formatDateTime(document.insertedAt)}</Table.TextCell>
      {!drawHasBeenSubmitted && (
        <Table.TextCell>
          <Link
            onClick={() =>
              handleDeleteDocument(deleteUpload, document.id, projectId)
            }
          >
            <Tooltip content="Delete the document.">
              <CrossIcon color="#001F40" size="12" />
            </Tooltip>
          </Link>
        </Table.TextCell>
      )}
    </Table.Row>
  );
}

export function BorrowerDrawDocuments({
  borrowerDraw,
  deleteUpload,
  drawHasBeenSubmitted,
}) {
  const documentsByCategory = getDocumentsByCategory(borrowerDraw.documents);
  const [previewFileProps, setPreviewFileProps] = useState(null);
  return (
    <Fragment>
      <Card padding={majorScale(3)} marginRight={majorScale(2)} width="50%">
        <Paragraph color="#001F40" size={500}>
          Documents
        </Paragraph>
        {categoryOrder.map((category) => {
          return (
            (!drawHasBeenSubmitted || documentsByCategory[category]) && (
              <Pane paddingTop={20}>
                <Paragraph color="#001F40">
                  {t(`borrowerDraw.categoryHeadings.${category}`)}
                </Paragraph>
                <Pane paddingTop={10}>
                  {!drawHasBeenSubmitted && (
                    <AddDocumentsButton
                      borrowerDrawId={borrowerDraw.id}
                      borrowerDrawName={borrowerDraw.name}
                      documentType={category}
                      projectId={borrowerDraw.projectId}
                    />
                  )}
                </Pane>
                {documentsByCategory[category] && (
                  <DocumentTable
                    deleteUpload={deleteUpload}
                    documents={documentsByCategory[category]}
                    drawHasBeenSubmitted={drawHasBeenSubmitted}
                    projectId={borrowerDraw.projectId}
                    setPreviewFileProps={setPreviewFileProps}
                  />
                )}
              </Pane>
            )
          );
        })}
      </Card>
      {previewFileProps && (
        <FilePreview
          onClose={(e) => {
            preventEventBubbling(e);
            setPreviewFileProps(null);
          }}
          document={previewFileProps.document}
        />
      )}
    </Fragment>
  );
}
