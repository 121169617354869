import { Fragment } from "react";
import PropTypes from "prop-types";
import { Table, Pane } from "components/materials";
import { useSyncScroll } from "hooks/use-sync-scroll";

export function StickyHeaderTable({
  bodyProps,
  children,
  tableControls,
  header,
  headerProps,
}) {
  const {
    getFirstScrollRef,
    getSecondScrollRef,
    onScroll,
    scrollRefs,
  } = useSyncScroll();

  // The sticky column's zIndex is set to 2,
  // and the sticky header's needs to be greater than that
  const zIndex = 3;

  return (
    <Fragment>
      {tableControls(scrollRefs)}
      <Pane position="sticky" top={0} zIndex={zIndex} marginBottom={-1}>
        <Table
          getTargetScrollRef={getFirstScrollRef}
          onScroll={onScroll}
          paddingBottom={0}
          {...headerProps}
        >
          {header}
        </Table>
      </Pane>
      <Table
        getTargetScrollRef={getSecondScrollRef}
        onScroll={onScroll}
        tableLayout="fixed"
        {...bodyProps}
      >
        {children}
      </Table>
    </Fragment>
  );
}

StickyHeaderTable.propTypes = {
  bodyProps: PropTypes.object,
  tableControls: PropTypes.func,
  headerProps: PropTypes.object,
};

StickyHeaderTable.defaultProps = {
  bodyProps: {},
  tableControls: () => {},
  headerProps: {},
};
