import { forwardRef, useContext } from "react";
import { EvergreenIconButton } from "components/materials";
import { ThemeContext } from "helpers/utilities";
import { majorScale } from "evergreen-ui";
import { getButtonBackgroundStylingProps } from "helpers/themeHelpers";

function getIntentColor(theme, intent) {
  if (intent === "success") return theme.colors.green700;
  if (intent === "danger") return theme.colors.red500;
  if (intent === "info") return theme.colors.info;
  if (intent === "warning") return theme.colors.warning;
  return null;
}

export default forwardRef(function IconButton(
  {
    appearance,
    color,
    children,
    content,
    intent,
    style,
    purpose,
    themeOverrides = {},
    ...rest
  },
  ref
) {
  const theme = useContext(ThemeContext);
  const { primaryColor } = theme.defaultColors;

  const overrideButtonProps = () => {
    if (appearance === "primary") {
      return {
        ...getButtonBackgroundStylingProps(
          themeOverrides.buttonColor || primaryColor
        ),
        appearance: "minimal",
      };
    }

    if (appearance === "minimal") {
      return {
        appearance: "minimal",
      };
    }

    return {
      ...getButtonBackgroundStylingProps(theme.colors.gray100),
      appearance: "minimal",
      border: `1px solid ${theme.colors.gray300}`,
    };
  };

  return (
    <EvergreenIconButton
      ref={ref}
      appearance={appearance}
      className="rbt-button"
      color={color || getIntentColor(theme, intent)}
      data-purpose={purpose}
      intent={intent}
      justifyContent="center"
      style={{ WebkitAppearance: "none", ...style }}
      type="button"
      whiteSpace="nowrap"
      height={majorScale(4)}
      {...overrideButtonProps()}
      {...rest}
    >
      {content || children}
    </EvergreenIconButton>
  );
});
