import { find, get } from "lodash";
import { add, multiplyCurrency, subtract } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";

export const getTotalRetainage = (lineItems) => {
  return lineItems.reduce((total, lineItem) => {
    return add(total, lineItem.retainageToDateAmount);
  }, 0);
};

export const getPreviousLineItem = (draw, lineItem) => {
  if (!draw) return null;

  const drawLineItem = find(draw.lineItems, {
    id: get(lineItem, "lineItemObject.id") || get(lineItem, "id"),
  });

  if (!drawLineItem) return null;

  return drawLineItem.previousLineItem;
};

export const getRetainageThisDraw = (vendor, selectedDraw) => {
  return (lineItem) => {
    return subtract(
      lineItem.retainageToDateAmount,
      getPreviousRetainage(vendor, selectedDraw)(lineItem)
    );
  };
};

export const getPreviousRetainage = (vendor, selectedDraw) => {
  return (lineItem) => {
    const previousLineItem = getPreviousLineItem(selectedDraw, lineItem);

    const previousVendorLineItem = getVendorLineItem(previousLineItem, vendor);

    return previousVendorLineItem
      ? previousVendorLineItem.retainageToDateAmount
      : 0;
  };
};

export function getExpectedRetainage(
  lineItemId,
  drawLineItems,
  agreementVendorLineItems,
  vendorId
) {
  const agreementLineItem = agreementVendorLineItems.find(
    ({ id, scopeId }) => id === lineItemId && scopeId === vendorId
  );
  const drawLineItem = drawLineItems.find(({ id }) => id === lineItemId);
  if (
    agreementLineItem &&
    (agreementLineItem.retainagePercentage ||
      agreementLineItem.retainagePercentage === 0)
  ) {
    return {
      expectedRetainage: get(agreementLineItem, "retainagePercentage"),
      source: "agreement",
    };
  }
  if (drawLineItem && drawLineItem.retainagePercentage) {
    return {
      expectedRetainage: get(drawLineItem, "retainagePercentage"),
      source: "draw",
    };
  }
  return { expectedRetainage: 0, source: null };
}

export const getVendorLineItem = (lineItem, vendor) => {
  if (!lineItem || !vendor) return null;
  return find(lineItem.vendorLineItems, ["vendor.id", vendor.id]);
};

export function autoCalculateLineItemRetainage(
  agreementVendorLineItems,
  amount,
  lineItemObject,
  selectedDraw,
  vendor
) {
  const { expectedRetainage } = getExpectedRetainage(
    lineItemObject.id,
    selectedDraw.lineItems,
    agreementVendorLineItems,
    vendor.id
  );
  const previousRetainage = getPreviousRetainage(
    vendor,
    selectedDraw
  )(lineItemObject);
  const retainageAmount = multiplyCurrency(amount, expectedRetainage);
  const retainageToDateAmount = add(previousRetainage, retainageAmount);

  return {
    autoCalculatedRetainageAmount: formatCurrency(retainageAmount),
    autoCalculatedRetainageToDateAmount: formatCurrency(retainageToDateAmount),
  };
}

export function calculateLineItemRetainageToDate(
  retainageAmount,
  lineItemObject,
  selectedDraw,
  vendor
) {
  const previousRetainage = getPreviousRetainage(
    vendor,
    selectedDraw
  )(lineItemObject);

  return formatCurrency(add(previousRetainage, retainageAmount));
}
export function calculateLineItemRetainage(
  retainageAmountToDate,
  lineItemObject,
  selectedDraw,
  vendor
) {
  const previousRetainage = getPreviousRetainage(
    vendor,
    selectedDraw
  )(lineItemObject);

  return formatCurrency(subtract(retainageAmountToDate, previousRetainage));
}

export function setLineItemRetainage(
  setFieldValue,
  retainageAmount,
  retainageToDateAmount,
  lineItemIndex
) {
  setFieldValue(`lineItems.${lineItemIndex}.retainageAmount`, retainageAmount);
  setFieldValue(
    `lineItems.${lineItemIndex}.retainageToDateAmount`,
    retainageToDateAmount
  );
}

export function updateLineItemRetainage(
  form,
  index,
  autoCalculateRetainage,
  lineItem,
  lineItemObject,
  selectedDraw,
  getAutocalculatedLineItemRetainage
) {
  let retainageAmount = get(form, `values.lineItems.${index}.retainageAmount`);
  let retainageToDateAmount;
  if (autoCalculateRetainage) {
    const {
      autoCalculatedRetainageAmount,
      autoCalculatedRetainageToDateAmount,
    } = getAutocalculatedLineItemRetainage(
      lineItem.applicationAmount,
      lineItemObject
    );
    retainageAmount = autoCalculatedRetainageAmount;
    retainageToDateAmount = autoCalculatedRetainageToDateAmount;
  } else {
    retainageToDateAmount = calculateLineItemRetainageToDate(
      retainageAmount,
      lineItemObject,
      selectedDraw,
      form.values.vendor
    );
  }

  setLineItemRetainage(
    form.setFieldValue,
    retainageAmount,
    retainageToDateAmount,
    index
  );
}

export function updateLineItemRetainageOnVendorChange(
  form,
  index,
  autoCalculateRetainage,
  lineItem,
  lineItemObject,
  selectedDraw,
  getAutocalculatedLineItemRetainage
) {
  let retainageAmount;
  const retainageToDateAmount = get(
    form,
    `values.lineItems.${index}.retainageToDateAmount`
  );
  if (autoCalculateRetainage) {
    updateLineItemRetainage(
      form,
      index,
      autoCalculateRetainage,
      lineItem,
      lineItemObject,
      selectedDraw,
      getAutocalculatedLineItemRetainage
    );
  } else {
    retainageAmount = calculateLineItemRetainage(
      retainageToDateAmount,
      lineItemObject,
      selectedDraw,
      form.values.vendor
    );

    setLineItemRetainage(
      form.setFieldValue,
      retainageAmount,
      retainageToDateAmount,
      index
    );
  }
}
