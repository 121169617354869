import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const HIDE_UPLOADS = gql`
  mutation HideUploads($uploadIds: [String!]) {
    hideDocumentUploads(uploadIds: $uploadIds) {
      id
      hidden
    }
  }
`;

export default function useHideUploadsMutation(variables = {}) {
  return useMutation(HIDE_UPLOADS, { variables });
}
