import { useContext } from "react";
import PropTypes from "prop-types";
import { Pane, Text } from "components/materials";
import { UserContext } from "helpers/behaviors";
import isBlank from "helpers/isBlank";
import { PERMISSION_ACTION } from "helpers/enums";
import { ImageViewer } from "./ImageViewer";
import { OfficeViewer } from "./OfficeViewer";
import { PDFViewer } from "./PDFViewer";
import { PDFEditor } from "./PDFEditor";

const PDF_TYPE = "application/pdf";

const OFFICE_TYPES = [
  "application/msword",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel.sheet.macroenabled.12",
];

function File({ file, height, minHeight, annotationProps }) {
  const { hasPermission } = useContext(UserContext);
  const canEditPDF =
    hasPermission(PERMISSION_ACTION.HUD_FEATURES) && !isBlank(annotationProps);

  if (file.type === PDF_TYPE)
    return canEditPDF ? (
      <PDFEditor
        file={file}
        minHeight={minHeight}
        height={height}
        annotationProps={annotationProps}
      />
    ) : (
      <PDFViewer file={file} minHeight={minHeight} height={height} />
    );
  if (OFFICE_TYPES.includes(file.type)) return <OfficeViewer file={file} />;
  if ((file.type || "").startsWith("image")) {
    return <ImageViewer file={file} />;
  }

  return (
    <Pane
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <Text size={800}>This document cannot be previewed in Rabbet.</Text>
    </Pane>
  );
}

export function FileViewer({ annotationProps, file, height, minHeight }) {
  return (
    <Pane
      background="rgba(233, 236, 243, 0.24)"
      border="1px solid #e9ecf3"
      height="100%"
      minHeight={minHeight || 500}
      width="100%"
    >
      <File
        minHeight={minHeight}
        height={height}
        file={file}
        key={file.url}
        annotationProps={annotationProps}
      />
    </Pane>
  );
}

FileViewer.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};
