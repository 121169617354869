import { useState, Fragment, useContext } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { HelpIcon } from "evergreen-ui";
import {
  VendorDocuments,
  VendorForm,
  VendorMembers,
  VendorProjects,
} from "components/templates";
import {
  Accordion,
  Button,
  Confirm,
  Form,
  Loadable,
  Pane,
  Sidebar,
  Tooltip,
  Banner,
} from "components/materials";
import { find, get } from "lodash";
import { majorScale, minorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { useHiddenViewerOnMount } from "helpers/behaviors/InternetExplorerContext";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";
import { OrganizationMemberModal } from "./OrganizationMemberModal";

const UPDATE_VENDOR = gql`
  mutation UpdateVendor(
    $emailAddresses: [String]!
    $id: String!
    $name: String!
    $streetAddress: String
    $city: String
    $state: AddressState
    $zip: String
    $phoneNumbers: [String]!
    $type: OrganizationType!
    $vendorCostCode: String
    $aliases: [String]
    $antialiases: [String]
    $sourceOrganizationId: String!
    $is1099: Boolean
    $isPayable: Boolean
  ) {
    updateVendor(
      emailAddresses: $emailAddresses
      id: $id
      streetAddress: $streetAddress
      city: $city
      state: $state
      zip: $zip
      name: $name
      phoneNumbers: $phoneNumbers
      type: $type
      vendorCostCode: $vendorCostCode
      aliases: $aliases
      antialiases: $antialiases
      sourceOrganizationId: $sourceOrganizationId
      is1099: $is1099
      isPayable: $isPayable
    ) {
      id
      emailAddresses
      name
      streetAddress
      city
      state
      zip
      phoneNumbers
      type
      vendorCostCode
      aliases
      antialiases
      is1099
      isPayable
    }
  }
`;

const DELETE_MEMBER = gql`
  mutation DeleteMember($memberId: String!, $sourceOrganizationId: String!) {
    deleteOrganizationMember(
      memberId: $memberId
      sourceOrganizationId: $sourceOrganizationId
    ) {
      id
      members {
        id
        name
        email
        phone
      }
    }
  }
`;

const DELETE_VENDOR = gql`
  mutation DeleteVendor($vendorId: String!, $sourceOrganizationId: String!) {
    deleteVendor(
      vendorId: $vendorId
      sourceOrganizationId: $sourceOrganizationId
    ) {
      status
    }
  }
`;

function VendorSidebarHeader({ form, loading, vendorName }) {
  return (
    <Sidebar.Heading breadcrumbs={["Edit Organization", vendorName]}>
      {form.dirty && (
        <Pane display="flex">
          <Pane>
            <Button
              onClick={() => form.handleReset()}
              marginRight={majorScale(2)}
              disabled={loading}
              purpose="vendor-sidebar edit undo"
            >
              Undo
            </Button>
          </Pane>
          <Pane>
            <Form.SubmitButton
              onClick={(e) => {
                preventEventBubbling(e);
                form.handleSubmit();
              }}
              purpose="vendor-sidebar edit submit"
              isLoading={loading}
              label="Save"
            />
          </Pane>
        </Pane>
      )}
    </Sidebar.Heading>
  );
}

function SidebarContent({
  form,
  handleAddMemberOpen,
  handleDeleteMember,
  handleDeleteVendor,
  handleEditMemberOpen,
  hasPermission,
  loading,
  navigateToDocument,
  navigateToProject,
  organizations,
  selectedCustomerAccountId,
  showDeleteOrganizationButton,
  vendor,
}) {
  const disableOrgEdit = !hasPermission(
    PERMISSION_ACTION.CREATE_EDIT_ORGANIZATION
  );
  const {
    isPlaceholderVendor,
    name,
    members,
    documents,
    projects,
    canDelete,
  } = vendor;
  const isUserOrganization = !!find(
    organizations,
    (organization) => organization.id === vendor.id
  );
  const canDeleteVendor = !isUserOrganization && canDelete;

  const membersPanelIfShown = isPlaceholderVendor
    ? []
    : [
        {
          key: "members",
          title: "Members",
          content: (
            <VendorMembers
              members={members}
              onAdd={handleAddMemberOpen}
              onEdit={handleEditMemberOpen}
              onRemove={handleDeleteMember}
              vendor={vendor}
            />
          ),
        },
      ];
  const vendorPanels = [
    ...membersPanelIfShown,
    {
      key: "projects",
      title: "Projects",
      content: (
        <VendorProjects projects={projects} onProject={navigateToProject} />
      ),
    },
    {
      key: "documents",
      title: "Documents",
      content: (
        <VendorDocuments
          documents={documents.filter(
            (document) => !get(document, "upload.toBeSplit")
          )}
          onDocument={navigateToDocument}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <VendorSidebarHeader form={form} loading={loading} vendorName={name} />
      <Sidebar.Section paddingX={majorScale(3)} data-testid="sidebar-org-form">
        {isPlaceholderVendor ? (
          <Banner border icon="infoSignIcon">
            {t("vendorSidebar.isPlaceholderVendor")}
          </Banner>
        ) : (
          <VendorForm
            disableFields={disableOrgEdit}
            form={form}
            hasPermission={hasPermission}
            isUserOrganization={isUserOrganization}
            selectedCustomerAccountId={selectedCustomerAccountId}
          />
        )}
      </Sidebar.Section>
      <Sidebar.Section
        paddingX={majorScale(3)}
        data-testid="sidebar-accordions"
      >
        <Accordion
          defaultActiveKeys={vendorPanels.map(({ key }) => key)}
          contentStyles={{ paddingX: minorScale(1), paddingTop: minorScale(3) }}
          headerStyles={{ size: 400 }}
          panels={vendorPanels}
          panelStyles={{ paddingY: majorScale(2) }}
        />
      </Sidebar.Section>
      {hasPermission(PERMISSION_ACTION.DELETE_ORGANIZATION) &&
        showDeleteOrganizationButton &&
        !isPlaceholderVendor && (
          <Sidebar.Section paddingBottom={majorScale(3)}>
            <Button
              content="Delete Organization"
              disabled={!canDeleteVendor}
              intent="danger"
              onClick={handleDeleteVendor}
            />
            {!canDeleteVendor && (
              <Tooltip content={t("vendorSidebar.cannotDeleteVendor")}>
                <HelpIcon marginLeft={minorScale(2)} size={minorScale(3)} />
              </Tooltip>
            )}
          </Sidebar.Section>
        )}
    </Fragment>
  );
}

export function VendorSidebar({
  closeSidebar,
  deleteRefetchQuery,
  query,
  refetchQueries,
  vendorId,
  organizations,
  selectedCustomerAccount,
  ...props
}) {
  useHiddenViewerOnMount(true);
  const { hasPermission, organizationId } = useContext(UserContext);
  const scopedHasPermission = (permission) =>
    hasPermission(permission, selectedCustomerAccount);
  const [updateVendor, updateVendorResult] = useMutation(UPDATE_VENDOR);
  const selectedCustomerAccountId = selectedCustomerAccount
    ? selectedCustomerAccount.id
    : organizationId;

  const [deleteMember] = useMutation(DELETE_MEMBER, {
    onCompleted: deleteRefetchQuery,
  });
  const [deleteVendor] = useMutation(DELETE_VENDOR, {
    onCompleted: deleteRefetchQuery,
  });

  const [memberModal, setMemberModal] = useState({
    open: false,
    member: null,
  });

  const [confirmDeleteMember, setConfirmDeleteMember] = useState(null);
  const [confirmDeleteVendor, setConfirmDeleteVendor] = useState(false);

  const handleAddMemberOpen = () => setMemberModal({ open: true });

  const handleEditMemberOpen = (member) =>
    setMemberModal({ open: true, member });

  const handleMemberClose = () => setMemberModal({ open: false });

  const onDeleteMember = (memberId) =>
    deleteMember({
      variables: { memberId, sourceOrganizationId: selectedCustomerAccountId },
    });
  const onDeleteVendor = (vendorId) =>
    deleteVendor({
      variables: { vendorId, sourceOrganizationId: selectedCustomerAccountId },
    });
  if (query.loading) return <Loadable loading />;
  const { vendors, vendor } = query.data.organization;

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={VendorForm.initialValues(vendor)}
        validate={(values) => VendorForm.validate(values, scopedHasPermission)}
        onSubmit={VendorForm.onSubmit(selectedCustomerAccountId, updateVendor)}
      >
        {(form) => (
          <SidebarContent
            form={form}
            vendor={vendor}
            selectedCustomerAccountId={selectedCustomerAccountId}
            handleAddMemberOpen={handleAddMemberOpen}
            handleEditMemberOpen={handleEditMemberOpen}
            handleDeleteMember={setConfirmDeleteMember}
            handleDeleteVendor={() => setConfirmDeleteVendor(true)}
            hasPermission={scopedHasPermission}
            loading={updateVendorResult.loading}
            memberModal={memberModal}
            organizations={organizations}
            {...props}
          />
        )}
      </Formik>
      {memberModal.open && (
        <OrganizationMemberModal
          member={memberModal.member}
          vendors={vendors}
          vendor={vendor}
          onClose={handleMemberClose}
          refetchQueries={refetchQueries}
        />
      )}
      {confirmDeleteMember && (
        <Confirm
          open
          content={t("vendorSidebar.confirmDeleteMember", {
            member: confirmDeleteMember.name,
            vendor: vendor.name,
          })}
          header="Remove Member"
          onCloseComplete={() => setConfirmDeleteMember(null)}
          onConfirm={(close) => {
            onDeleteMember(confirmDeleteMember.id);
            close();
          }}
          cancelLabel="Cancel"
          confirmLabel="Remove"
        />
      )}
      {confirmDeleteVendor && (
        <Confirm
          open
          content={t("vendorSidebar.confirmDeleteVendor", {
            vendor: vendor.name,
          })}
          header={`Delete ${vendor.name}`}
          onCloseComplete={() => setConfirmDeleteVendor(false)}
          onConfirm={(close) => {
            onDeleteVendor(vendor.id);
            closeSidebar();
            close();
          }}
          cancelLabel="Cancel"
          confirmLabel="Delete"
        />
      )}
    </Fragment>
  );
}
