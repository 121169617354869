import numbro from "numbro";
import { get, identity } from "lodash";
import isBlank from "./isBlank";
import unformatNumber from "./unformatNumber";

export const add = (...numbers) => {
  return numbers
    .reduce((sum, number) => sum.add(getSanitizedNumbro(number)), numbro(0))
    .value();
};

export const subtract = (firstValue, ...values) => {
  return values
    .reduce(
      (acc, item) => acc.subtract(getSanitizedNumbro(item)),
      getSanitizedNumbro(firstValue)
    )
    .value();
};

export const divide = (value1, value2, mantissa) => {
  const divisor = getSanitizedNumbro(value2);
  if (divisor._value === 0) return 0;
  const dividedValue = getSanitizedNumbro(value1).divide(divisor);

  return mantissa
    ? unformatNumber(dividedValue.format({ mantissa }))
    : dividedValue.value();
};

export const divideCurrency = (value1, value2) => {
  return unformatNumber(
    getSanitizedNumbro(value1)
      .divide(getSanitizedNumbro(value2))
      .format({ mantissa: 2 })
  );
};

export const multiply = (value1, value2) => {
  return getSanitizedNumbro(value1)
    .multiply(getSanitizedNumbro(value2))
    .value();
};

export const multiplyCurrency = (value1, value2) => {
  return unformatNumber(
    getSanitizedNumbro(value1)
      .multiply(getSanitizedNumbro(value2))
      .format({ mantissa: 2 })
  );
};

export const sumBy = (arr, iteratee) => {
  let fn = iteratee || identity;
  if (typeof iteratee === "string") {
    fn = (el) => get(el, iteratee, 0);
  }

  return arr
    .reduce(
      (acc, item, index) => acc.add(unformatNumber(fn(item || 0, index))),
      numbro(0)
    )
    .value();
};

export const cumulativeSum = (arr) => {
  const total = arr.reduce(
    (acc, val) => {
      let { sum, value } = acc;

      sum += val;

      value = [...value, sum];

      return { sum, value };
    },
    { sum: 0, value: [] }
  );

  return total.value;
};

// useful if it cannot be guaranteed that the number input can be parsed to a numbro object
export function getSanitizedNumbro(number) {
  if (isBlank(number)) return numbro(0);
  const parsed = numbro(number);
  return parsed._value === undefined ? numbro(0) : parsed;
}
