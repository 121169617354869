import { Fragment, useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { EditProjectSettingsButtons } from "components/templates";
import { Accordion, Button, Form, Pane } from "components/materials";
import { majorScale, toaster } from "helpers/utilities";
import { formatDateTime, isAtOrBefore } from "helpers/dateHelpers";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { PullDataFromYardiWarningModal } from "./PullDataFromYardiWarningModal";

const RESET_ACCOUNTS_PAYABLE_LAST_SYNCED_AT = gql`
  mutation ResetAccountsPayableLastSyncedAt($projectId: String!) {
    resetAccountsPayableLastSyncedAt(projectId: $projectId) {
      id
      accountsPayableLastSyncedAt
    }
  }
`;

export function EditYardiConfigurationPanel({
  dirty,
  form,
  loading,
  onToggle,
  open,
  panelKey,
  project,
  setPanelDirty,
  ...props
}) {
  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(panelKey, isDirty);
    }
    return undefined;
  }, [dirty, form, panelKey, setPanelDirty]);

  const { hasPermission } = useContext(UserContext);

  const [resetAccountsPayableLastSyncedAt] = useMutation(
    RESET_ACCOUNTS_PAYABLE_LAST_SYNCED_AT,
    {
      onCompleted: () =>
        toaster.success(
          "Your project's invoice last synced at time has been reset.",
          {
            duration: 2.5,
          }
        ),
    }
  );

  const [
    pullDataFromYardiWarningModalOpen,
    setPullDataFromYardiWarningModalOpen,
  ] = useState(false);

  const [
    pullDataFromYardiButtonDisabled,
    setPullDataFromYardiButtonDisabled,
  ] = useState(false);

  return (
    <Accordion.Panel
      panelKey={panelKey}
      title="Yardi Configuration"
      onClick={() => onToggle(panelKey)}
      open={open}
      actionContent={
        <EditProjectSettingsButtons
          dirty={dirty}
          form={form}
          loading={loading}
        />
      }
      {...props}
    >
      {hasPermission(PERMISSION_ACTION.PULL_DATA_FROM_YARDI) && (
        <Fragment>
          <Pane marginBottom={majorScale(1)} display="flex">
            <Pane>
              <Button
                purpose="pull payables from Yardi"
                disabled={pullDataFromYardiButtonDisabled}
                onClick={() => setPullDataFromYardiWarningModalOpen(true)}
              >
                Pull Payables and Journal Entries from Yardi
              </Button>
            </Pane>
            {pullDataFromYardiButtonDisabled && (
              <Pane
                marginLeft={majorScale(1)}
                marginTop={majorScale(1)}
                fontSize={14}
              >
                Hang tight, we are pulling your documents from Yardi, please
                wait a few minutes before you refresh this page.
              </Pane>
            )}
            {!pullDataFromYardiButtonDisabled &&
              (isAtOrBefore(
                project.accountsPayableSyncFailingSince,
                project.accountsPayableLastSyncedAt
              ) ? (
                <Pane
                  marginLeft={majorScale(1)}
                  marginTop={majorScale(1)}
                  fontSize={14}
                >
                  Last Synced:{" "}
                  {project.accountsPayableLastSyncedAt
                    ? formatDateTime(project.accountsPayableLastSyncedAt)
                    : "Never"}
                </Pane>
              ) : (
                <Pane
                  marginLeft={majorScale(1)}
                  marginTop={majorScale(1)}
                  fontSize={14}
                >
                  Erroring Since:{" "}
                  {formatDateTime(project.accountsPayableSyncFailingSince)},{" "}
                  {project.accountsPayableSyncErrorMessage}
                </Pane>
              ))}
          </Pane>
          <Pane marginTop={majorScale(2)}>
            <Button
              purpose="reset project's account payables last synced at time"
              onClick={() =>
                resetAccountsPayableLastSyncedAt({
                  variables: {
                    projectId: project.id,
                  },
                })
              }
            >
              Reset Project&apos;s Invoices Last Synced At Time
            </Button>
          </Pane>

          {pullDataFromYardiWarningModalOpen && (
            <PullDataFromYardiWarningModal
              projectId={project.id}
              onClose={() => setPullDataFromYardiWarningModalOpen(false)}
              setPullDataFromYardiButtonDisabled={() =>
                setPullDataFromYardiButtonDisabled(true)
              }
            />
          )}
        </Fragment>
      )}
      {hasPermission(PERMISSION_ACTION.YARDI_INTEGRATION) && (
        <Form.Group aligned renderedChildrenDirectly>
          <Pane width={250}>
            <Form.Select
              label="GL Account"
              name="glCode"
              options={getGlCodeOptions(project.organization.glCodes)}
              popoverMinWidth={85}
              fullBorder
            />
          </Pane>
        </Form.Group>
      )}
    </Accordion.Panel>
  );
}

function getGlCodeOptions(glCodes) {
  return glCodes.map((code) => ({ key: code, value: code, text: code }));
}
