import { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { DocumentIcon, MoreIcon } from "evergreen-ui";
import { ApproveDocumentButton } from "components/containers";
import { AssignToDropdown } from "components/templates";
import { Badge, Button, Card, Pane, Text } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { get, includes } from "lodash";
import { add } from "helpers/math";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  getDocumentApprovalStatus,
  userCanApproveDocument,
} from "helpers/documentHelpers";
import { UserContext } from "helpers/behaviors";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import t from "helpers/translate";

const REASSIGN_DOCUMENT = gql`
  mutation AssignUserToDocuments($documentIds: [String]!, $userId: String) {
    assignUserToDocuments(documentIds: $documentIds, userId: $userId) {
      id
    }
  }
`;

const removeDivisionName = (name) => {
  if (!name) return null;
  const sliceEnd = name.lastIndexOf("(");
  if (sliceEnd === -1) return name.trim();
  const slicedName = name.slice(0, sliceEnd);
  return slicedName.trim();
};

const filterLineItems = (lineItems) =>
  lineItems
    .filter(({ name }) => !!name)
    .map((lineItem) => ({
      ...lineItem,
      name: removeDivisionName(lineItem.name),
    }));

const getConfirmationLabel = (document, approvedDocuments) => {
  if (includes(approvedDocuments, document.id)) {
    return "approved";
  }
  if (document.reassigned) return "reassigned";
  return null;
};

function DocumentCardHeader({
  confirmationLabel,
  document,
  suggestedDocumentAssignees,
  handleReassign,
  onApproveCompleted,
  reassignLoading,
  showApproveButton,
  theme,
  users,
}) {
  const vendor = get(document, "vendor.name");

  return (
    <Pane display="flex">
      <Pane height={16} width={16} marginRight={majorScale(1)}>
        <DocumentIcon color="muted" />
      </Pane>
      {vendor && (
        <Text
          fontWeight={theme.fontWeights.DEMI}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          marginRight={majorScale(1)}
          size={300}
        >
          {vendor}
        </Text>
      )}
      <Text
        color={theme.colors.gray500}
        flex="1 1 auto"
        fontWeight={600}
        size={300}
        textTransform="uppercase"
        whiteSpace="nowrap"
      >
        {t(`documentTypeAbbreviation.${document.type}`)}
        {document.newlyAssigned && (
          <Badge color="blue" height={majorScale(2)} marginLeft={majorScale(1)}>
            NEW
          </Badge>
        )}
      </Text>
      {!confirmationLabel && (
        <Fragment>
          {showApproveButton && (
            <ApproveDocumentButton
              buttonStyles={{
                appearance: "minimal",
                backgroundColor: theme.colors.gray90,
                height: majorScale(2),
                id: "dashApprove",
                marginLeft: minorScale(1),
              }}
              document={document}
              onApproveCompleted={onApproveCompleted}
            />
          )}
          {handleReassign && (
            <Pane onClick={preventEventBubbling}>
              <AssignToDropdown
                users={users}
                suggestedDocumentAssignees={suggestedDocumentAssignees}
                onSelect={(userId) => handleReassign(userId)}
                selectedUserId={document?.assignedUser?.id}
              >
                <Button
                  appearance="minimal"
                  height={majorScale(2)}
                  isLoading={reassignLoading}
                  marginLeft={minorScale(1)}
                  purpose="dashboard document reassign"
                >
                  {!reassignLoading && <MoreIcon color="disabled" />}
                </Button>
              </AssignToDropdown>
            </Pane>
          )}
        </Fragment>
      )}
      {confirmationLabel && (
        <Badge
          color={confirmationLabel === "approved" ? "green" : "blue"}
          height={majorScale(2)}
        >
          {confirmationLabel}
        </Badge>
      )}
    </Pane>
  );
}

function DocumentCardLineItems({ lineItems, theme }) {
  const filteredLineItems = filterLineItems(lineItems);
  if (filteredLineItems.length === 0) return null;
  const singleItem = filteredLineItems.length === 1;
  const total = filteredLineItems.reduce(
    (sum, { amount }) => add(sum, amount),
    0
  );
  return (
    <Pane marginTop={majorScale(1)} paddingX={majorScale(4)}>
      {filteredLineItems.map(({ id, name, amount }) => (
        <Pane
          borderBottom
          display="flex"
          justifyContent="space-between"
          key={id}
          marginBottom={minorScale(1)}
        >
          <Text color="muted" size={300}>
            {name}
          </Text>
          <Text color="muted" size={300} fontWeight={singleItem ? 700 : 400}>
            {formatCurrency(amount)}
          </Text>
        </Pane>
      ))}
      {!singleItem && (
        <Pane display="flex" justifyContent="flex-end">
          <Text
            color={theme.colors.gray500}
            fontWeight={600}
            marginRight={majorScale(1)}
            size={300}
            textTransform="uppercase"
          >
            Total
          </Text>
          <Text color="muted" size={300} fontWeight={700}>
            {formatCurrency(total)}
          </Text>
        </Pane>
      )}
    </Pane>
  );
}

export function DashboardDocumentCard({
  approvedDocuments,
  document,
  documentReviewers,
  suggestedDocumentAssignees,
  getDocumentURL,
  history,
  onApproveCompleted,
  organization,
  users,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission, userProfiles } = useContext(UserContext);

  const user = userProfiles.find(
    (profile) => profile.organization.id === organization.id
  );

  const [reassign, reassignResult] = useMutation(REASSIGN_DOCUMENT);

  const handleReassign = (userId) => {
    reassign({ variables: { documentIds: [document.id], userId } });
  };

  const showReassign = hasPermission(
    PERMISSION_ACTION.ASSIGN_DOCUMENTS,
    organization
  );

  const canViewDocuments = hasPermission(
    PERMISSION_ACTION.DOWNLOAD_DOCUMENT,
    organization
  );

  const hasDocumentReviewers = hasPermission(
    PERMISSION_ACTION.APPROVE_DOCUMENTS,
    organization
  );

  const userCanApprove = userCanApproveDocument(
    document,
    user,
    documentReviewers,
    hasPermission
  );

  const { documentHasApprovedStatusForUser } = getDocumentApprovalStatus(
    document,
    user,
    documentReviewers
  );

  const showApproveButton =
    hasDocumentReviewers && userCanApprove && !documentHasApprovedStatusForUser;

  const confirmationLabel = getConfirmationLabel(document, approvedDocuments);
  const lineItems = get(document, "lineItems", []);

  return (
    <Card
      cursor={canViewDocuments ? "pointer" : null}
      elevation={1}
      hoverElevation={2}
      marginBottom={majorScale(1)}
      onClick={() =>
        canViewDocuments ? history.push(getDocumentURL(document)) : null
      }
      padding={minorScale(3)}
    >
      <DocumentCardHeader
        confirmationLabel={confirmationLabel}
        document={document}
        suggestedDocumentAssignees={suggestedDocumentAssignees}
        handleReassign={showReassign && handleReassign}
        onApproveCompleted={onApproveCompleted}
        reassignLoading={reassignResult.loading}
        showApproveButton={showApproveButton}
        theme={theme}
        users={users}
      />
      <DocumentCardLineItems lineItems={lineItems} theme={theme} />
    </Card>
  );
}

DashboardDocumentCard.propTypes = {
  approvedDocuments: PropTypes.array.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number,
    approvedBy: PropTypes.shape({
      id: PropTypes.string,
    }),
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    type: PropTypes.string.isRequired,
    vendor: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  suggestedDocumentAssignees: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  onApproveCompleted: PropTypes.func.isRequired,
  onReassignCompleted: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  reassignedDocuments: PropTypes.array.isRequired,
};
