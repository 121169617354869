import { useContext, useEffect, useState, Fragment } from "react";
import { Confirm, Table } from "components/materials";
import { DocumentContext } from "contexts/documentContext";
import { majorScale } from "helpers/utilities";
import { get, trim } from "lodash";
import fuzzaldrin from "fuzzaldrin-plus";
import { DrawSummaryTableHeader } from "./DrawSummaryTableHeader";
import { DrawSummaryTableBody } from "./DrawSummaryTableBody";

export function DrawSummaryTable({
  fileData,
  fileType,
  form,
  lockdownDraw,
  theme,
}) {
  const [confirmManualResetValues, setConfirmManualResetValues] = useState(
    null
  );

  const { lineItems, sheetName } = form.values;

  const sheetData = get(fileData, sheetName, []);

  function onLineItemColumnChange(lineItemColumnIndex, formHasOverrides) {
    const rowIndexesByName = getRowIndexesByName(
      sheetData,
      lineItemColumnIndex
    );

    const autofilledLineItems = lineItems.map((lineItem) => {
      const lineItemName = getNormalizedLineItemName(lineItem.name);
      const bestMatch = fuzzaldrin.filter(
        Object.keys(rowIndexesByName),
        lineItemName
      )[0];

      const foundIndices =
        bestMatch in rowIndexesByName ? rowIndexesByName[bestMatch] : [];
      const rowIndexForLineItem =
        foundIndices.length > 0 ? foundIndices.shift() : null;
      return {
        ...lineItem,
        rowIndex: rowIndexForLineItem,
        overrides: {
          adjustmentsAmount: null,
          requestedAmount: null,
          retainageAmount: null,
        },
      };
    });

    const newValues = {
      ...form.values,
      lineItemColumn: lineItemColumnIndex,
      lineItems: autofilledLineItems,
    };

    if (formHasOverrides) {
      setConfirmManualResetValues(newValues);
    } else {
      form.setValues(newValues);
    }
  }

  function onAmountColumnChange(
    columnPath,
    overridePath,
    newColumnSelection,
    columnHasOverrides
  ) {
    const resetLineItems = lineItems.map((lineItem) => {
      return {
        ...lineItem,
        overrides: {
          ...lineItem.overrides,
          [overridePath]: null,
        },
      };
    });

    const newValues = {
      ...form.values,
      lineItems: resetLineItems,
      [columnPath]: newColumnSelection,
    };

    if (columnHasOverrides) {
      setConfirmManualResetValues(newValues);
    } else {
      form.setValues(newValues);
    }
  }

  function onLineItemRowChange(formIndex, newRowIndex, hasOverrides) {
    const resetLineItems = lineItems.map((lineItem) => {
      return lineItem.formIndex === formIndex
        ? {
            ...lineItem,
            rowIndex: newRowIndex,
            overrides: {
              adjustmentsAmount: null,
              requestedAmount: null,
              retainageAmount: null,
            },
          }
        : lineItem;
    });

    const newValues = {
      ...form.values,
      lineItems: resetLineItems,
    };

    if (hasOverrides) {
      setConfirmManualResetValues(newValues);
    } else {
      form.setValues(newValues);
    }
  }

  function markRemainingAsNotIncluded() {
    const updatedLineItems = lineItems.map((lineItem) => ({
      ...lineItem,
      rowIndex: lineItem.rowIndex === null ? "none" : lineItem.rowIndex,
    }));

    const newValues = {
      ...form.values,
      lineItems: updatedLineItems,
    };

    form.setValues(newValues);
  }

  const { createLineItem, createLineItemData, projectId } = useContext(
    DocumentContext
  );

  // when a new line item is created, we splice it into the form data
  // this makes the new line item available in the form without disrupting other in-progress edits
  // (ideally, we could just refetch the line items,
  // but unfortunately the full set of line items is embedded in the form data,
  // and refetching the full set of line items does not place nicely with Formik's `enableReinitialize`)
  useEffect(() => {
    const newLineItemId = createLineItemData?.createLineItem?.id;

    if (newLineItemId && !lineItems.find(({ id }) => id === newLineItemId)) {
      const newLineItemDivision = createLineItemData.createLineItem.division;

      const newLineItemIndex =
        lineItems.findLastIndex(
          (lineItem) => lineItem.division.id === newLineItemDivision.id
        ) + 1;

      const precedingLineItems = lineItems.slice(0, newLineItemIndex);

      const followingLineItems = lineItems
        .slice(newLineItemIndex)
        .map((lineItem) => ({
          ...lineItem,
          formIndex: lineItem.formIndex + 1,
        }));

      const newLineItems = [
        ...precedingLineItems,
        {
          ...createLineItemData.createLineItem,
          formIndex: newLineItemIndex,
          rowIndex: null,
          overrides: {
            adjustmentsAmount: null,
            requestedAmount: null,
            retainageAmount: null,
          },
        },
        ...followingLineItems,
      ];

      form.setFieldValue("lineItems", newLineItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLineItemData]);

  return (
    <Fragment>
      <Table hideHeaderBorder hover={false} marginTop={majorScale(2)}>
        <DrawSummaryTableHeader
          fileType={fileType}
          form={form}
          lockdownDraw={lockdownDraw}
          onAmountColumnChange={onAmountColumnChange}
          onLineItemColumnChange={onLineItemColumnChange}
          sheetData={sheetData}
          theme={theme}
        />
        <DrawSummaryTableBody
          createLineItem={createLineItem}
          form={form}
          lockdownDraw={lockdownDraw}
          markRemainingAsNotIncluded={markRemainingAsNotIncluded}
          onLineItemRowChange={onLineItemRowChange}
          projectId={projectId}
          sheetData={sheetData}
          theme={theme}
        />
      </Table>
      <Confirm
        content="Changing the line or column will extract the information from the document. You will lose any values you have manually updated."
        header="Warning"
        onCloseComplete={() => setConfirmManualResetValues(null)}
        onConfirm={(close) => {
          form.setValues(confirmManualResetValues);
          close();
        }}
        open={confirmManualResetValues}
        cancelLabel="Cancel"
        confirmLabel="Continue"
      />
    </Fragment>
  );
}

function getRowIndexesByName(sheetData, lineItemColumnIndex) {
  return sheetData.reduce((acc, row, rowIndex) => {
    const lineItemName = row[lineItemColumnIndex];
    const normalizedName = getNormalizedLineItemName(lineItemName);
    const indexes = acc[normalizedName]
      ? acc[normalizedName].concat(rowIndex)
      : [rowIndex];
    return {
      ...acc,
      [normalizedName]: indexes,
    };
  }, {});
}

function getNormalizedLineItemName(lineItemName) {
  return trim(lineItemName).toLowerCase();
}
