import { range } from "lodash";
import {
  amountFieldNames,
  blankSelectValue,
  getStartMonthIndex,
  selectIsBlank,
} from "helpers/projectionHelpers";
import { add, subtract } from "helpers/math";
import { minorScale } from "helpers/utilities";
import t from "helpers/translate";

export const PROJECTION_CURVE_TYPE = {
  LINEAR: "LINEAR",
  MANUAL: "MANUAL",
  S_CURVE: "S_CURVE",
};

const blankOption = <option value={blankSelectValue}>{undefined}</option>;

export function getCurveTypeOptions() {
  const options = [
    <option value={PROJECTION_CURVE_TYPE.S_CURVE}>
      {t("projection.curveTypes.sCurve")}
    </option>,
    <option value={PROJECTION_CURVE_TYPE.MANUAL}>
      {t("projection.curveTypes.manual")}
    </option>,
    <option value={PROJECTION_CURVE_TYPE.LINEAR}>
      {t("projection.curveTypes.linear")}
    </option>,
  ];

  return [blankOption].concat(options);
}

export function getStartMonthOptions(months) {
  const options = months
    .map((month, monthNumber) => {
      // 0 is falsey according to `option` and is not a valid value
      // Across this file, startMonthValue and startMonthIndex are used to clarify what number is being referenced
      const startMonthValue = add(monthNumber, 1);
      return <option value={startMonthValue}>{month}</option>;
    })
    .filter((v) => v !== null);

  return [blankOption].concat(options);
}

export function getDurationOptions(expectedProjectLength, startMonthValue = 1) {
  const startMonthIndex = selectIsBlank(startMonthValue)
    ? 0
    : getStartMonthIndex(startMonthValue);
  const maxDuration = subtract(expectedProjectLength, startMonthIndex) + 1;
  const options = range(1, maxDuration).map((monthNumber) => (
    <option value={monthNumber}>{monthNumber}</option>
  ));

  return [blankOption].concat(options);
}

export const selectProps = {
  height: 20,
  marginTop: -minorScale(1),
  width: "100%",
};

export const tableBorder = "2px solid #ebedf0";

export function getCellState(fieldName) {
  if (fieldName === amountFieldNames.ACTUALS)
    return { background: "financial" };
  if (fieldName === amountFieldNames.MONTHLY_PROJECTED_AMOUNTS)
    return { background: "pending" };
  if (fieldName === amountFieldNames.UPDATED_PROJECTION_AMOUNTS)
    return { background: "financial2" };
  return {};
}
