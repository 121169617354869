import PropTypes from "prop-types";
import { Link, Button } from "components/materials";

const LinkButton = ({ children, to, href, linkProps, ...props }) => (
  <Link to={to} href={href} noUnderlineOnHover {...linkProps}>
    <Button {...props}>{children}</Button>
  </Link>
);

LinkButton.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

export default LinkButton;
