import { Fragment } from "react";
import {
  Button,
  Card,
  Pane,
  Paragraph,
  Progress,
  Shortener,
} from "components/materials";
import { get } from "lodash";
import { BRAND_COLORS } from "helpers/colors";
import { formatDate } from "helpers/dateHelpers";
import { trackPortfolioAnalysis } from "./helpers";
import { HeaderTextCell, PortfolioInsightBlankSlateCard, TextCell } from ".";

export function OutstandingProjects({
  history,
  organizationId,
  projects,
  stylingProps,
}) {
  if (projects.length === 0)
    return <PortfolioInsightBlankSlateCard cardName="outstandingProjects" />;

  const sortedProjects = [...projects].sort((projectA, projectB) => {
    const projectADaysSinceFunded = get(
      projectA,
      "lastDrawFunded.daysSinceFunded",
      null
    );
    const projectBDaysSinceFunded = get(
      projectB,
      "lastDrawFunded.daysSinceFunded",
      null
    );
    if (projectBDaysSinceFunded === null) return -1;
    return projectBDaysSinceFunded - projectADaysSinceFunded;
  });
  const maxDaysSinceFunded = get(
    sortedProjects[0].lastDrawFunded,
    "daysSinceFunded",
    null
  );

  return (
    <Fragment>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        Outstanding Projects
      </Paragraph>
      <Card {...stylingProps.card}>
        <Pane maxHeight={200} overflowY="scroll">
          <table {...stylingProps.table}>
            <thead>
              <tr>
                <HeaderTextCell>Project</HeaderTextCell>
                <HeaderTextCell>Last Draw</HeaderTextCell>
                <HeaderTextCell>Funded On</HeaderTextCell>
                <HeaderTextCell colSpan={3}>
                  Days Since Last Draw Funded
                </HeaderTextCell>
              </tr>
            </thead>
            <tbody>
              {sortedProjects.map((project) => {
                const navigateToProject = () => {
                  trackPortfolioAnalysis(organizationId, "Go to Project");
                  return history.push(`/projects/${project.id}`);
                };
                return (
                  <OutstandingProjectRow
                    key={project.id}
                    {...project}
                    maxDaysSinceFunded={maxDaysSinceFunded}
                    navigateToProject={navigateToProject}
                  />
                );
              })}
            </tbody>
          </table>
        </Pane>
      </Card>
    </Fragment>
  );
}

function OutstandingProjectRow({
  name: projectName,
  navigateToProject,
  lastDrawFunded,
  maxDaysSinceFunded,
}) {
  const daysSinceFunded = get(lastDrawFunded, "daysSinceFunded", null);
  const fundedDate = get(lastDrawFunded, "fundedDate", null);
  const lastDrawName = get(lastDrawFunded, "name", null);
  const formattedFundedDate = formatDate(fundedDate, "-", "MM/dd/yy");

  return (
    <tr>
      <TextCell>
        <Shortener limit={17} size={300} text={projectName} />
      </TextCell>
      <TextCell>
        <Shortener limit={17} size={300} text={lastDrawName} />
      </TextCell>
      <TextCell>{formattedFundedDate}</TextCell>
      <TextCell>{daysSinceFunded}</TextCell>
      <td width="100%" aria-label="progress">
        <Progress
          value={daysSinceFunded}
          total={maxDaysSinceFunded}
          color={BRAND_COLORS.LIGHT_PRIMARY}
          background="transparent"
        />
      </td>
      <td>
        <Button onClick={navigateToProject}>Go to Project</Button>
      </td>
    </tr>
  );
}
