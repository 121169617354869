import { Form, Pane, Text } from "components/materials";
import { minorScale } from "helpers/utilities";

export const PreviousXControl = ({ fieldPath, labels }) => {
  const fieldName = `${fieldPath}.value`;

  const [adjective, unit] = labels;

  return (
    <Pane alignItems="center" display="flex">
      <Form.Field>
        <Text size={300}>{adjective}</Text>
      </Form.Field>
      <Form.Input
        marginX={minorScale(1)}
        name={fieldName}
        type="integer"
        width={50}
      />
      <Form.Field>
        <Text size={300} whiteSpace="nowrap">
          {unit}
        </Text>
      </Form.Field>
    </Pane>
  );
};
