import { PureComponent } from "react";
import PropTypes from "prop-types";
import { majorScale } from "helpers/utilities";
import { Alert, Button, Pane, Form, Text } from "components/materials";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { map } from "lodash";

class FormGeneric extends PureComponent {
  render() {
    const {
      additionalActionButtons,
      children,
      contentStyles,
      error,
      handleCancel,
      handleSubmit,
      submitDisabled,
      isValid,
      loading,
      purpose,
      testId,
      cancelLabel,
      textSubmit,
      submitButtonProps,
    } = this.props;

    let errorMessage = error && error.message;

    if (error && error.graphQLErrors) {
      errorMessage = map(error.graphQLErrors, "message").join(", ");
    }

    return (
      <Form
        onSubmit={(event) => {
          preventEventBubbling(event);
          handleSubmit(event);
        }}
        data-testid={testId}
      >
        <Pane marginBottom={majorScale(2)} {...contentStyles}>
          {children}
        </Pane>
        {!!error && (
          <Alert intent="danger" title={<Text>{errorMessage}</Text>} />
        )}
        <Form.Group
          aligned
          justifyContent={submitButtonProps.justifyContent || "flex-end"}
        >
          {handleCancel && (
            <Button
              purpose={`${purpose} cancel`}
              onClick={handleCancel}
              content={cancelLabel}
            />
          )}
          {additionalActionButtons}
          <Form.SubmitButton
            disabled={!isValid || submitDisabled}
            isLoading={loading}
            label={textSubmit}
            purpose={`${purpose} submit`}
            {...submitButtonProps}
          />
        </Form.Group>
      </Form>
    );
  }
}

FormGeneric.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.object,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  submitButtonProps: PropTypes.object,
  testId: PropTypes.string,
  cancelLabel: PropTypes.string,
  textSubmit: PropTypes.string,
};

FormGeneric.defaultProps = {
  isValid: true,
  submitButtonProps: {},
  cancelLabel: "Cancel",
  textSubmit: "Submit",
};

export default FormGeneric;
