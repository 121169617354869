import gql from "graphql-tag";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import {
  AGREEMENT_DOCUMENT_FRAGMENT,
  BUDGET_ADJUSTMENT_FRAGMENT,
  DOCUMENT_REVIEW_ACTIVITY_FRAGMENT,
  DRAW_SUMMARY_FRAGMENT,
  HUD_5372_FRAGMENT,
  HUD_2448_FRAGMENT,
  HUD_92464_FRAGMENT,
  INSPECTION_IMAGE_FRAGMENT,
  DOCUMENT_INSPECTION_REPORT_FRAGMENT,
  INSURANCE_CERTIFICATE_FRAGMENT,
  INVOICE_FRAGMENT,
  LIEN_RELEASE_FRAGMENT,
  PAY_APPLICATION_ASSIGNMENT_FRAGMENT,
  PAY_APPLICATION_FRAGMENT,
  RETAINAGE_RELEASE_FRAGMENT,
  REVIEW_DOCUMENT_FRAGMENT,
  RULE_FRAGMENT,
} from "helpers/fragments";

export const DOCUMENT_PROCESSING_SUBSCRIPTION = gql`
  subscription onDocumentUpdated($documentId: String!) {
    documentUpdated(documentId: $documentId) {
      id
      processingFinishedAt
      state
    }
  }
`;

export const DOCUMENT_QUERY = gql`
  query DocumentDocumentQuery(
    $documentId: String!
    $projectId: String!
    $drawDocumentIdsToExclude: [String]
  ) {
    project(id: $projectId) {
      id
      document(id: $documentId) {
        ...AgreementDocumentFragment
        ...DrawSummaryFragment
        ...Hud2448Fragment
        ...Hud5372Fragment
        ...Hud92464Fragment
        ...InspectionImageFragment
        ...InspectionReportFragment
        ...InsuranceCertificateFragment
        ...InvoiceFragment
        ...LienReleaseFragment
        ...PayApplicationAssignmentFragment
        ...PayApplicationFragment
        ...RetainageReleaseFragment
        ...ReviewDocumentFragment
      }
    }
  }
  ${AGREEMENT_DOCUMENT_FRAGMENT}
  ${DRAW_SUMMARY_FRAGMENT}
  ${HUD_5372_FRAGMENT}
  ${HUD_2448_FRAGMENT}
  ${HUD_92464_FRAGMENT}
  ${INSPECTION_IMAGE_FRAGMENT}
  ${DOCUMENT_INSPECTION_REPORT_FRAGMENT}
  ${INSURANCE_CERTIFICATE_FRAGMENT}
  ${INVOICE_FRAGMENT}
  ${LIEN_RELEASE_FRAGMENT}
  ${PAY_APPLICATION_ASSIGNMENT_FRAGMENT}
  ${PAY_APPLICATION_FRAGMENT}
  ${RETAINAGE_RELEASE_FRAGMENT}
  ${REVIEW_DOCUMENT_FRAGMENT}
`;

export const DRAWS_QUERY = gql`
  query DocumentDrawsQuery($projectId: String!) {
    project(id: $projectId) {
      id
      draws {
        id
        isLockedDown
        name
        requestedAmount
        uploads(toBeSplit: true) {
          id
        }
      }
      documentReviewers {
        id
        user {
          id
        }
      }
      documentWarningsEnabled
      invoiceNumbersByVendor {
        id
        drawId
        invoiceNumber
        vendorId
      }
      lineItems {
        id
        scopeId
        budgetAmount
        name
        number
        requestedPreviouslyAmount
        division {
          id
          scopeId
          name
        }
      }
      rules {
        ...RuleFragment
      }
    }
  }
  ${RULE_FRAGMENT}
`;

export const DRAW_QUERY = gql`
  query DocumentDrawQuery($drawId: String!) {
    draw(id: $drawId) {
      id
      requestedAmount
      documents {
        id
        invoiceId
        type
        file {
          url
          name
        }
        vendor {
          id
        }
      }
      hasAdjustments
      isLockedDown
      fundedDate
      lineItems {
        id
        scopeId
        name
        number
        grossRequestedAmount
        division {
          id
          scopeId
          name
        }
      }
      budgetAdjustments {
        ...BudgetAdjustmentFragment
      }
      vendorLineItems {
        id
        scopeId
        retainageAmount
        drawLineItem {
          id
          scopeId
          name
          division {
            id
            scopeId
            name
          }
        }
        documentation {
          id
        }
        vendor {
          id
        }
      }
    }
  }
  ${BUDGET_ADJUSTMENT_FRAGMENT}
`;

export const ORGANIZATION_QUERY = gql`
  query DocumentVendorsQuery($projectId: String!) {
    project(id: $projectId) {
      id
      agreementVendorLineItems {
        id
        scopeId
        agreementsAmount
        spentToDateAmount
        retainagePercentage
        agreements {
          id
          startDate
          type
        }
        vendor {
          id
        }
      }
      organization {
        id
        jobCostCodes {
          id
          code
          description
        }
      }
      users {
        id
        fullName
      }
      vendors(includePlaceholderVendors: true) {
        id
        isPlaceholderVendor
        city
        name
        state
        agreementProjectIds
      }
    }
  }
`;

export const UNCONDITIONAL_WAIVER_QUERY = gql`
query DocumentDrawsQuery($projectId: String!) {
  project(id: $projectId) {
    id
    draws {
      id
      name
      payApplications: documents(type: ${DOCUMENT_TYPE_NAME.PAY_APPLICATION}) {
        id
        amount
        type
        invoiceId
        vendorId
        file {
          url
          name
        }
      }
      invoices: documents(type: ${DOCUMENT_TYPE_NAME.INVOICE}) {
        id
        amount
        type
        invoiceId
        vendorId
        file {
          url
          name
        }
      }
    }
  }
}
`;

export const COMMENT = gql`
  mutation DocumentCommentMutation($body: String!, $documentId: String!) {
    addDocumentComment(body: $body, documentId: $documentId) {
      id
    }
  }
`;

export const CORRECT = gql`
  mutation DocumentCorrectMutation(
    $autoCalculateRetainage: Boolean
    $cancelParsing: Boolean!
    $data: String!
    $documentId: String!
    $drawId: String
    $type: DocumentTypeName!
    $vendorId: String
  ) {
    correctDocument(
      autoCalculateRetainage: $autoCalculateRetainage
      cancelParsing: $cancelParsing
      data: $data
      documentId: $documentId
      drawId: $drawId
      type: $type
      vendorId: $vendorId
    ) {
      id
    }
  }
`;

export const APPROVE_DOCUMENT = gql`
  mutation ApproveDocumentMutation($documentIds: [String]!) {
    approveDocuments(documentIds: $documentIds) {
      id
      approvedBy {
        id
      }
      approvedUsers {
        id
      }
      documentReviewActivity {
        ...DocumentReviewActivityFragment
      }
    }
  }
  ${DOCUMENT_REVIEW_ACTIVITY_FRAGMENT}
`;

export const UNDO_APPROVE_DOCUMENT = gql`
  mutation UndoApproveDocumentMutation($documentIds: [String]!) {
    undoApproveDocuments(documentIds: $documentIds) {
      id
      approvedBy {
        id
      }
      approvedUsers {
        id
      }
      documentReviewActivity {
        ...DocumentReviewActivityFragment
      }
    }
  }
  ${DOCUMENT_REVIEW_ACTIVITY_FRAGMENT}
`;

export const MARK_REVIEWED = gql`
  mutation MarkDocumentsReviewed($documentIds: [String]!) {
    markDocumentsReviewed(documentIds: $documentIds) {
      id
      documentReviewActivity {
        ...DocumentReviewActivityFragment
      }
    }
  }
  ${DOCUMENT_REVIEW_ACTIVITY_FRAGMENT}
`;

export const MARK_DOCUMENT_PAID = gql`
  mutation MarkDocumentPaid(
    $documentId: String!
    $amountPaid: Currency!
    $datePaid: Date!
  ) {
    markDocumentPaid(
      documentId: $documentId
      amountPaid: $amountPaid
      datePaid: $datePaid
    ) {
      id
      isPaid
      amountPaid
      datePaid
    }
  }
`;

export const MARK_DOCUMENT_UNPAID = gql`
  mutation MarkDocumentUnpaid($documentId: String!) {
    markDocumentUnpaid(documentId: $documentId) {
      id
      isPaid
    }
  }
`;

export const IGNORE = gql`
  mutation IgnoreDocuments($documentIds: [String]!) {
    ignoreDocuments(documentIds: $documentIds) {
      id
      state
    }
  }
`;

export const REMOVE = gql`
  mutation DeleteDocuments(
    $alsoDeleteAgreements: Boolean
    $documentIds: [String]!
  ) {
    deleteDocuments(
      alsoDeleteAgreements: $alsoDeleteAgreements
      documentIds: $documentIds
    ) {
      status
    }
  }
`;

export const ADD_GENERAL_CONTRACTOR_STAKEHOLDER = gql`
  mutation AddGeneralContractorStakeholder(
    $projectId: String!
    $vendorId: String!
  ) {
    addGeneralContractorStakeholder(
      projectId: $projectId
      vendorId: $vendorId
    ) {
      id
    }
  }
`;

export const ASSIGN_DOCUMENTS_TO_USER = gql`
  mutation AssignUserToDocuments($documentIds: [String]!, $userId: String) {
    assignUserToDocuments(documentIds: $documentIds, userId: $userId) {
      id
      assignedUser {
        id
        fullName
      }
    }
  }
`;

export const TRACKABLE_AGREEMENTS_QUERY = gql`
  query TrackableAgreementsQuery(
    $documentId: String!
    $projectId: String!
    $vendorId: String!
  ) {
    project(id: $projectId) {
      id
      trackableAgreements(vendorId: $vendorId) {
        id
        amount
        lineItems {
          id
          lineItemId
        }
        name
        spentForDocument(documentId: $documentId)
        spentToDate
        type
      }
    }
  }
`;

export const UPDATE_HIDE_EXHAUSTED_AGREEMENTS = gql`
  mutation UpdateHideExhaustedAgreements($hideExhaustedAgreements: Boolean!) {
    updateUserViewConfig(
      hideExhaustedAgreementsInDocReview: $hideExhaustedAgreements
    ) {
      id
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation CreateInvoiceMutation(
    $assignedUserId: String
    $autoCalculateRetainage: Boolean
    $currentPaymentDue: Currency
    $date: Date
    $description: String
    $documentId: String!
    $drawId: String
    $isBackup: Boolean
    $grossAmount: Currency
    $lineItems: [InvoiceLineItemInput]
    $netAmount: Currency
    $number: String
    $projectId: String!
    $retainageAmount: Currency
    $totalRetainage: Currency
    $showAllHardCostLineItems: Boolean
    $showFullFields: Boolean
    $trackedAgreementAmounts: [TrackedAgreementAmountInput]
    $totalCompletedAndStoredToDate: Currency
    $type: InvoiceType
    $vendorId: String
  ) {
    createInvoice(
      assignedUserId: $assignedUserId
      autoCalculateRetainage: $autoCalculateRetainage
      currentPaymentDue: $currentPaymentDue
      date: $date
      description: $description
      documentId: $documentId
      drawId: $drawId
      grossAmount: $grossAmount
      isBackup: $isBackup
      lineItems: $lineItems
      netAmount: $netAmount
      number: $number
      projectId: $projectId
      retainageAmount: $retainageAmount
      totalRetainage: $totalRetainage
      showAllHardCostLineItems: $showAllHardCostLineItems
      showFullFields: $showFullFields
      totalCompletedAndStoredToDate: $totalCompletedAndStoredToDate
      trackedAgreementAmounts: $trackedAgreementAmounts
      type: $type
      vendorId: $vendorId
    ) {
      id
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoiceMutation(
    $assignedUserId: String
    $autoCalculateRetainage: Boolean
    $currentPaymentDue: Currency
    $date: Date
    $description: String
    $documentId: String!
    $drawId: String
    $id: String
    $isBackup: Boolean
    $grossAmount: Currency
    $lineItems: [InvoiceLineItemInput]
    $netAmount: Currency
    $number: String
    $projectId: String!
    $retainageAmount: Currency
    $totalRetainage: Currency
    $trackedAgreementAmounts: [TrackedAgreementAmountInput]
    $showAllHardCostLineItems: Boolean
    $showFullFields: Boolean
    $totalCompletedAndStoredToDate: Currency
    $type: InvoiceType
    $vendorId: String
  ) {
    updateInvoice(
      id: $id
      assignedUserId: $assignedUserId
      autoCalculateRetainage: $autoCalculateRetainage
      currentPaymentDue: $currentPaymentDue
      date: $date
      description: $description
      documentId: $documentId
      drawId: $drawId
      isBackup: $isBackup
      grossAmount: $grossAmount
      lineItems: $lineItems
      netAmount: $netAmount
      number: $number
      projectId: $projectId
      retainageAmount: $retainageAmount
      totalRetainage: $totalRetainage
      trackedAgreementAmounts: $trackedAgreementAmounts
      showAllHardCostLineItems: $showAllHardCostLineItems
      showFullFields: $showFullFields
      totalCompletedAndStoredToDate: $totalCompletedAndStoredToDate
      type: $type
      vendorId: $vendorId
    ) {
      ...AgreementDocumentFragment
      ...DrawSummaryFragment
      ...Hud2448Fragment
      ...Hud5372Fragment
      ...Hud92464Fragment
      ...InspectionImageFragment
      ...InspectionReportFragment
      ...InsuranceCertificateFragment
      ...InvoiceFragment
      ...LienReleaseFragment
      ...PayApplicationAssignmentFragment
      ...PayApplicationFragment
      ...RetainageReleaseFragment
    }
  }
  ${AGREEMENT_DOCUMENT_FRAGMENT}
  ${DRAW_SUMMARY_FRAGMENT}
  ${HUD_5372_FRAGMENT}
  ${HUD_2448_FRAGMENT}
  ${HUD_92464_FRAGMENT}
  ${INSPECTION_IMAGE_FRAGMENT}
  ${DOCUMENT_INSPECTION_REPORT_FRAGMENT}
  ${INSURANCE_CERTIFICATE_FRAGMENT}
  ${INVOICE_FRAGMENT}
  ${LIEN_RELEASE_FRAGMENT}
  ${PAY_APPLICATION_ASSIGNMENT_FRAGMENT}
  ${PAY_APPLICATION_FRAGMENT}
  ${RETAINAGE_RELEASE_FRAGMENT}
`;

export const ASSIGN_LIEN_WAIVER = gql`
  mutation AssignLienWaiverMutation(
    $id: String!
    $description: String
    $documentId: String
    $drawId: String
    $invoiceId: String
    $isFinal: Boolean
    $projectId: String
    $type: LienWaiverType!
  ) {
    assignLienWaiver(
      id: $id
      description: $description
      documentId: $documentId
      drawId: $drawId
      invoiceId: $invoiceId
      isFinal: $isFinal
      projectId: $projectId
      type: $type
    ) {
      id
    }
  }
`;

export const CREATE_LIEN_WAIVER = gql`
  mutation CreateLienWaiverMutation(
    $assignedDrawId: String
    $assignedUserId: String
    $description: String
    $documentId: String!
    $drawId: String
    $isFinal: Boolean
    $lineItems: [LienWaiverLineItemInput]
    $projectId: String!
    $type: LienWaiverType!
    $vendorId: String
  ) {
    createLienWaiver(
      assignedDrawId: $assignedDrawId
      assignedUserId: $assignedUserId
      description: $description
      documentId: $documentId
      drawId: $drawId
      isFinal: $isFinal
      lineItems: $lineItems
      projectId: $projectId
      type: $type
      vendorId: $vendorId
    ) {
      id
    }
  }
`;

export const CREATE_AND_ASSIGN_LIEN_WAIVER = gql`
  mutation CreateAndAssignLienWaiverMutation(
    $assignedUserId: String
    $description: String
    $documentId: String
    $drawId: String
    $invoiceId: String!
    $isFinal: Boolean
    $projectId: String
    $type: LienWaiverType!
  ) {
    createAndAssignLienWaiver(
      assignedUserId: $assignedUserId
      description: $description
      documentId: $documentId
      drawId: $drawId
      invoiceId: $invoiceId
      isFinal: $isFinal
      projectId: $projectId
      type: $type
    ) {
      id
    }
  }
`;

export const UPDATE_LIEN_WAIVER = gql`
  mutation UpdateLienWaiverMutation(
    $id: String!
    $assignedDrawId: String
    $assignedUserId: String
    $description: String
    $documentId: String!
    $drawId: String
    $isFinal: Boolean
    $lineItems: [LienWaiverLineItemInput]
    $projectId: String!
    $type: LienWaiverType!
    $vendorId: String
  ) {
    updateLienWaiver(
      id: $id
      assignedDrawId: $assignedDrawId
      assignedUserId: $assignedUserId
      description: $description
      documentId: $documentId
      drawId: $drawId
      isFinal: $isFinal
      lineItems: $lineItems
      projectId: $projectId
      type: $type
      vendorId: $vendorId
    ) {
      id
    }
  }
`;

export const ADD_AGREEMENT_FROM_DOCUMENT = gql`
  mutation AddAgreementFromDocument(
    $projectId: String!
    $documentId: String!
    $drawId: String
    $budgetAdjustmentDrawId: String
    $type: AgreementType!
    $vendorId: String
    $startDate: Date
    $agreementDescription: String
    $name: String!
    $documentDescription: String
    $inspectionNote: String
    $agreementNumber: String
    $changeOrderReason: ChangeOrderReason
    $correlationId: String
    $daysImpacted: Int
    $userTouchedName: Boolean
    $agreementLineItems: [AgreementLineItemInput]
    $budgetAdjustmentTransactions: [BudgetAdjustmentTransactionInput]
    $newLineItems: [NewLineItemInput]
  ) {
    addAgreementFromDocument(
      projectId: $projectId
      documentId: $documentId
      drawId: $drawId
      budgetAdjustmentDrawId: $budgetAdjustmentDrawId
      type: $type
      vendorId: $vendorId
      startDate: $startDate
      agreementDescription: $agreementDescription
      name: $name
      documentDescription: $documentDescription
      inspectionNote: $inspectionNote
      agreementNumber: $agreementNumber
      changeOrderReason: $changeOrderReason
      correlationId: $correlationId
      daysImpacted: $daysImpacted
      userTouchedName: $userTouchedName
      agreementLineItems: $agreementLineItems
      budgetAdjustmentTransactions: $budgetAdjustmentTransactions
      newLineItems: $newLineItems
    ) {
      id
    }
  }
`;

export const UPDATE_AGREEMENT_FROM_DOCUMENT = gql`
  mutation UpdateAgreementFromDocument(
    $agreementId: String!
    $documentId: String!
    $drawId: String
    $budgetAdjustmentDrawId: String
    $type: AgreementType!
    $vendorId: String
    $startDate: Date
    $agreementDescription: String
    $name: String
    $documentDescription: String
    $inspectionNote: String
    $agreementNumber: String
    $changeOrderReason: ChangeOrderReason
    $daysImpacted: Int
    $userTouchedName: Boolean
    $agreementLineItems: [AgreementLineItemInput]
    $budgetAdjustmentTransactions: [BudgetAdjustmentTransactionInput]
    $newLineItems: [NewLineItemInput]
  ) {
    updateAgreementFromDocument(
      agreementId: $agreementId
      documentId: $documentId
      drawId: $drawId
      budgetAdjustmentDrawId: $budgetAdjustmentDrawId
      type: $type
      vendorId: $vendorId
      startDate: $startDate
      agreementDescription: $agreementDescription
      name: $name
      documentDescription: $documentDescription
      inspectionNote: $inspectionNote
      agreementNumber: $agreementNumber
      changeOrderReason: $changeOrderReason
      daysImpacted: $daysImpacted
      userTouchedName: $userTouchedName
      agreementLineItems: $agreementLineItems
      budgetAdjustmentTransactions: $budgetAdjustmentTransactions
      newLineItems: $newLineItems
    ) {
      id
    }
  }
`;

// todo - add requestedPreviouslyAmount back to mutation return?
export const CREATE_LINE_ITEM = gql`
  mutation CreateLineItem(
    $projectId: ID!
    $divisionId: ID!
    $id: ID!
    $name: String!
    $number: String
    $superLineItem: String
    $masterFormatDivision: MasterFormatDivision
  ) {
    createLineItem(
      projectId: $projectId
      divisionId: $divisionId
      id: $id
      name: $name
      number: $number
      superLineItem: $superLineItem
      masterFormatDivision: $masterFormatDivision
    ) {
      id
      scopeId
      budgetAmount
      name
      number
      division {
        id
        scopeId
        name
      }
    }
  }
`;
