import { memo, Fragment, useEffect, useState } from "react";
import {
  Badge,
  MaskedInput,
  Pane,
  Popover,
  Select,
  Table,
  Text,
} from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import unformatNumber from "helpers/unformatNumber";
import { subtract, sumBy } from "helpers/math";
import {
  amountFieldNames,
  getMonthHasActuals,
} from "helpers/projectionHelpers";
import { majorScale } from "helpers/utilities";
import {
  NEW_PROJECTION_STATE,
  UPDATED_PROJECTION_STATE,
  UPDATING_PROJECTION_STATE,
  NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE,
} from "hooks/use-projections";
import {
  getCellState,
  getCurveTypeOptions,
  getStartMonthOptions,
  getDurationOptions,
  PROJECTION_CURVE_TYPE,
  selectProps,
  tableBorder,
} from "./util";
import { PROJECTION_EVENT } from "../../containers/ProjectionsPage";

function MonthlyValueCell({
  actualsCount,
  borderLeft,
  index,
  isManual,
  isProjectionLocked,
  lineItem,
  onBlur,
  projectionState,
  state,
  totalScheduled,
  value,
}) {
  const [innerValue, setInnerValue] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty) return;

    setInnerValue(value);
  }, [value, projectionState, lineItem, actualsCount, isDirty]);

  const remaining = subtract(lineItem.budgetAmount, totalScheduled);
  const monthHasActuals = getMonthHasActuals(index, actualsCount);

  function handleOnBlur() {
    onBlur(innerValue);
    setIsDirty(false);
  }

  function handleOnInput(e) {
    setIsDirty(true);
    setInnerValue(e.target.value);
  }

  if (isManual && !monthHasActuals) {
    return (
      <Table.Cell borderLeft={borderLeft} state={state}>
        <Popover
          content={
            <Pane
              background="black"
              height="100%"
              display="flex"
              flexDirection="column"
              padding={majorScale(1)}
            >
              <Text color="white">
                Total Scheduled: {formatCurrency(totalScheduled)}
              </Text>
              <Text color="white">Remaining: {formatCurrency(remaining)}</Text>
            </Pane>
          }
        >
          <Pane>
            <MaskedInput
              disabled={isProjectionLocked}
              textAlign="right"
              type="currency"
              value={innerValue}
              {...selectProps}
              onInput={handleOnInput}
              onBlur={handleOnBlur}
            />
          </Pane>
        </Popover>
      </Table.Cell>
    );
  }

  return (
    <Table.TextCell borderLeft={borderLeft} textAlign="right" state={state}>
      {formatCurrency(innerValue)}
    </Table.TextCell>
  );
}

function LineItemRow({
  actualsCount,
  columns,
  lineItem,
  onChange,
  project,
  projectionState,
  // Passing this in for now to find the updated line item easier in the ProjectionsPage.
  // for events that need to update line items.
  // could be a cleaner solution.
  divisionId,
}) {
  const {
    startMonth: startMonthValue,
    curveType,
    budgetAmount,
    values,
  } = lineItem;
  const isManual = curveType === PROJECTION_CURVE_TYPE.MANUAL;
  const totalScheduled = sumBy(
    values,
    ({ updatedProjection }) => updatedProjection
  );
  const amountRemaining = subtract(budgetAmount, totalScheduled);
  const manualAmountMismatch = isManual && amountRemaining !== 0;
  const rowState = manualAmountMismatch ? "error" : undefined;

  function handleLineItemSettingChanged(e, option, lineItemId) {
    const { value } = e.target;

    const data = { id: lineItemId, option, value, divisionId };

    onChange({
      type: PROJECTION_EVENT.LINE_ITEM_SETTING_CHANGED,
      data,
    });
  }

  function handleManualInputOnBlur(value, lineItemId, monthIndex) {
    const data = {
      id: lineItemId,
      value: unformatNumber(value),
      divisionId,
      monthIndex,
    };

    onChange({
      type: PROJECTION_EVENT.LINE_ITEM_VALUE_CHANGED,
      data,
    });
  }

  function shouldDisableSetting(settingName, projectionState) {
    const isCurveTypeManual =
      lineItem.curveType === PROJECTION_CURVE_TYPE.MANUAL;

    const isLockedProjection =
      projectionState === NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE;

    switch (settingName) {
      case "curveType":
        return isLockedProjection;
      case "startMonth":
        return isLockedProjection || isCurveTypeManual;
      case "duration":
        return isLockedProjection || isCurveTypeManual;
      default:
        return isLockedProjection;
    }
  }

  const { months, expectedLength } = project;

  return (
    <Table.Row state={rowState}>
      <Table.TextCell testId="lineitem">
        {lineItem.name}
        {manualAmountMismatch && (
          <Badge
            color="red"
            data-testid="redbg"
            borderRadius={majorScale(1)}
            marginLeft={majorScale(1)}
          >
            {amountRemaining > 0 ? "Under" : "Over"}
          </Badge>
        )}
      </Table.TextCell>
      <Table.Cell>
        <Select
          disabled={shouldDisableSetting("curveType", projectionState)}
          cursor={
            shouldDisableSetting("curveType", projectionState)
              ? "not-allowed"
              : "pointer"
          }
          onChange={(e) => {
            handleLineItemSettingChanged(e, "curveType", lineItem.id);
          }}
          {...selectProps}
          value={lineItem.curveType}
          data-curvetype={lineItem.curveType}
        >
          {getCurveTypeOptions()}
        </Select>
      </Table.Cell>
      <Table.Cell>
        <Select
          cursor={
            shouldDisableSetting("startMonth", projectionState)
              ? "not-allowed"
              : "pointer"
          }
          disabled={shouldDisableSetting("startMonth", projectionState)}
          onChange={(e) =>
            handleLineItemSettingChanged(e, "startMonth", lineItem.id)
          }
          {...selectProps}
          value={lineItem.startMonth}
          data-startmonthnum={lineItem.startMonth}
        >
          {getStartMonthOptions(months)}
        </Select>
      </Table.Cell>
      <Table.Cell>
        <Select
          cursor={
            shouldDisableSetting("duration", projectionState)
              ? "not-allowed"
              : "pointer"
          }
          isLockedProjection
          disabled={shouldDisableSetting("duration", projectionState)}
          onChange={(e) =>
            handleLineItemSettingChanged(e, "duration", lineItem.id)
          }
          {...selectProps}
          value={lineItem.duration}
          data-projectduration={lineItem.duration}
        >
          {getDurationOptions(expectedLength, startMonthValue)}
        </Select>
      </Table.Cell>
      {columns.showPreviousBudget && (
        <Table.TextCell textAlign="right">
          {formatCurrency(lineItem.previousBudgetAmount)}
        </Table.TextCell>
      )}
      <Table.TextCell textAlign="right">
        {formatCurrency(lineItem.budgetAmount)}
      </Table.TextCell>
      {months.map((_month, index) => {
        const monthHasActuals = getMonthHasActuals(index, actualsCount);
        switch (projectionState) {
          case NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE:
            return (
              <Fragment>
                {columns.showOriginalProjection && (
                  <MonthlyValueCell
                    actualsCount={actualsCount}
                    borderLeft={tableBorder}
                    index={index}
                    isManual={isManual}
                    isProjectionLocked
                    lineItem={lineItem}
                    onBlur={(e) =>
                      handleManualInputOnBlur(e, lineItem.id, index)
                    }
                    state={
                      rowState ||
                      getCellState(amountFieldNames.MONTHLY_PROJECTED_AMOUNTS)
                    }
                    totalScheduled={totalScheduled}
                    value={lineItem.values[index].projected}
                  />
                )}

                {columns.showUpdatedProjection && (
                  <MonthlyValueCell
                    actualsCount={actualsCount}
                    borderLeft={tableBorder}
                    index={index}
                    isManual={isManual}
                    isProjectionLocked
                    lineItem={lineItem}
                    projectionState={projectionState}
                    state={
                      rowState ||
                      getCellState(
                        monthHasActuals
                          ? amountFieldNames.ACTUALS
                          : amountFieldNames.UPDATED_PROJECTION_AMOUNTS
                      )
                    }
                    totalScheduled={totalScheduled}
                    value={lineItem.values[index].updatedProjection}
                    onBlur={(e) =>
                      handleManualInputOnBlur(e, lineItem.id, index)
                    }
                  />
                )}
              </Fragment>
            );
          case NEW_PROJECTION_STATE:
            return (
              <MonthlyValueCell
                actualsCount={actualsCount}
                borderLeft={tableBorder}
                index={index}
                isManual={isManual}
                isProjectionLocked={false}
                lineItem={lineItem}
                onBlur={(e) => handleManualInputOnBlur(e, lineItem.id, index)}
                projectionState={projectionState}
                state={rowState}
                totalScheduled={totalScheduled}
                value={lineItem.values[index].projected}
              />
            );
          case UPDATING_PROJECTION_STATE:
            return (
              <MonthlyValueCell
                actualsCount={actualsCount}
                borderLeft={tableBorder}
                index={index}
                isManual={isManual}
                isProjectionLocked={false}
                lineItem={lineItem}
                onBlur={(e) => handleManualInputOnBlur(e, lineItem.id, index)}
                projectionState={projectionState}
                state={
                  rowState ||
                  getCellState(
                    monthHasActuals
                      ? amountFieldNames.ACTUALS
                      : amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                  )
                }
                totalScheduled={totalScheduled}
                value={lineItem.values[index].updatedProjection}
              />
            );
          case UPDATED_PROJECTION_STATE:
            return (
              <MonthlyValueCell
                actualsCount={actualsCount}
                borderLeft={tableBorder}
                index={index}
                isManual={isManual}
                isProjectionLocked={false}
                lineItem={lineItem}
                onBlur={(e) => handleManualInputOnBlur(e, lineItem.id, index)}
                projectionState={projectionState}
                state={
                  rowState ||
                  getCellState(
                    monthHasActuals
                      ? amountFieldNames.ACTUALS
                      : amountFieldNames.MONTHLY_PROJECTED_AMOUNTS
                  )
                }
                totalScheduled={totalScheduled}
                value={lineItem.values[index].updatedProjection}
              />
            );
          default:
            return null;
        }
      })}
      <Table.TextCell borderLeft={tableBorder} />
    </Table.Row>
  );
}

export default memo(LineItemRow);
