import { Route } from "react-router-dom";
import analytics from "helpers/analytics";
import { UserContext } from "helpers/behaviors";
import { NotFoundPage } from "components/containers";

const PermissionRoute = ({
  analyticsTracker,
  permission,
  component: Component,
  render,
  ...otherProps
}) => {
  if (analyticsTracker) {
    analytics.track(analyticsTracker.event, analyticsTracker.data);
  }
  return (
    <Route
      {...otherProps}
      render={(props) => (
        <UserContext.Consumer>
          {({ userId, hasPermissionAcrossProfiles }) => {
            if (!hasPermissionAcrossProfiles(permission)) {
              return userId && <NotFoundPage />;
            }

            if (Component) return <Component {...props} />;
            if (render) return render(props);
            return null;
          }}
        </UserContext.Consumer>
      )}
    />
  );
};

export default PermissionRoute;
