import { Fragment } from "react";
import { Pane, Text } from "components/materials";
import { majorScale, withTheme } from "helpers/utilities";
import t from "helpers/translate";

function EmptyContainer({ children }) {
  return <Fragment>{children}</Fragment>;
}

const BulkActionControls = ({
  children,
  height,
  selectedItems,
  selectedIndices,
  bulkActionGroups,
  theme,
}) => {
  const toolbarProps = {
    display: "inline-flex",
    elevation: 2,
    height: height || "100%",
    borderRadius: majorScale(1),
    backgroundColor: theme.colors.lightBlue,
    paddingLeft: majorScale(1),
  };

  const titleProps = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: majorScale(1),
    paddingRight: majorScale(2),
  };

  const actionGroupProps = {
    display: "flex",
    height: "100%",
    borderLeft: `1px solid ${theme.tableColors.bulkActionsSectionDivider}`,
    paddingX: majorScale(1),
  };

  const actionProps = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingX: majorScale(1),
  };

  const labelColors = {
    green: "#167446",
    red: "#af100c",
  };

  return (
    <Fragment>
      {selectedItems.length > 0 ? (
        <Pane {...toolbarProps}>
          <Pane {...titleProps}>
            <Text
              size={300}
              color={theme.tableColors.bulkActionsInfo}
              fontWeight={theme.fontWeights.DEMI}
            >
              {t("bulkActionsToolbar.selectedItems", {
                count: selectedItems.length,
              })}
            </Text>
          </Pane>
          {bulkActionGroups.map((actionGroup, index) => (
            <Pane key={index} {...actionGroupProps}>
              {actionGroup.map(
                ({
                  color,
                  container,
                  disabled,
                  label,
                  labelIcon,
                  onClick,
                  ...rest
                }) => {
                  const Container = container || EmptyContainer;

                  let clickProps = {};

                  if (!disabled && onClick) {
                    clickProps = {
                      onClick: () => onClick(selectedItems, selectedIndices),
                    };
                  }

                  return (
                    <Pane key={label} {...actionProps}>
                      <Container selectedItems={selectedItems}>
                        <Pane
                          display="flex"
                          alignItems="center"
                          {...clickProps}
                        >
                          <Text
                            cursor={disabled ? "not-allowed" : "pointer"}
                            color={labelColors[color] || color}
                            display="flex"
                            size={300}
                            {...rest}
                          >
                            {label}
                          </Text>
                          {labelIcon && labelIcon}
                        </Pane>
                      </Container>
                    </Pane>
                  );
                }
              )}
            </Pane>
          ))}
        </Pane>
      ) : (
        children
      )}
    </Fragment>
  );
};

BulkActionControls.defaultProps = {
  selectedItems: [],
  bulkActionGroups: [],
};

export default withTheme(BulkActionControls);
