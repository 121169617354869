/**
 * Returns a given string formatted to be a constant
 *
 * example:
 * formatConstant("this is a constant") -> "THIS_IS_A_CONSTANT"
 * @param {string} str
 * @returns {string}
 */
export default function formatConstant(str) {
  if (typeof str === "string" || str instanceof String) {
    return str.toUpperCase().replace(/ /g, "_");
  }

  return "";
}
