import { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import { DocumentShareIcon, NotificationsIcon } from "evergreen-ui";
import { Banner, Spinner, Text } from "components/materials";
import { UploadSplitterContext } from "helpers/behaviors";
import { majorScale, ThemeContext } from "helpers/utilities";
import { get } from "lodash";
import t from "helpers/translate";

function UploadLink({ setUploadId, upload }) {
  const theme = useContext(ThemeContext);

  const content = (
    <Fragment>
      {!get(upload, "processingFinishedAt") ? (
        <Fragment>
          <Spinner marginRight={majorScale(1)} size={16} />
          <Text
            color="muted"
            fontStyle="italic"
            marginRight={majorScale(1)}
            size={300}
          >
            Auto-splitting
          </Text>
          <Text
            color="muted"
            fontWeight={theme.fontWeights.MEDIUM}
            marginRight={majorScale(2)}
            size={300}
          >
            {get(upload, "file.name")}
          </Text>
        </Fragment>
      ) : (
        <Fragment>
          <DocumentShareIcon marginRight={majorScale(1)} color="selected" />
          <Text
            color="selected"
            cursor="pointer"
            fontWeight={theme.fontWeights.MEDIUM}
            marginRight={majorScale(2)}
            onClick={() => setUploadId(upload.id)}
            size={300}
            textDecoration="underline"
          >
            {get(upload, "file.name")}
          </Text>
        </Fragment>
      )}
      <Text color="muted" fontStyle="italic" size={300}>
        {t("pendingUploads.pages", {
          count: get(upload, "pages", []).length,
        })}
      </Text>
    </Fragment>
  );

  return (
    <Banner borderBottom paddingLeft={majorScale(8)}>
      {content}
    </Banner>
  );
}

function UploadsList({ setUploadId, uploads }) {
  const [showAllUploads, setShowAllUploads] = useState(false);
  const toggleShowAll = () => setShowAllUploads((oldShowAll) => !oldShowAll);
  const visibleUploads = showAllUploads ? uploads : uploads.slice(0, 3);

  return (
    <Fragment>
      {visibleUploads.map((upload) => (
        <UploadLink key={upload.id} setUploadId={setUploadId} upload={upload} />
      ))}
      {uploads.length > 3 && (
        <Banner
          borderBottom
          paddingLeft={majorScale(8)}
          mainText={
            showAllUploads
              ? t("pendingUploads.hidePending", { count: uploads.length - 3 })
              : t("pendingUploads.showPending", { count: uploads.length - 3 })
          }
          textProps={{
            color: "selected",
            cursor: "pointer",
            onClick: toggleShowAll,
            size: 300,
          }}
        />
      )}
    </Fragment>
  );
}

export function PendingUploadsBanner({ drawId, projectId, uploads }) {
  const { setOpenSplitter } = useContext(UploadSplitterContext);

  return (
    uploads.length > 0 && (
      <Fragment>
        <Banner
          borderBottom
          icon={NotificationsIcon}
          mainText={t("pendingUploads.main")}
          secondaryText={t("pendingUploads.secondary")}
        />
        <UploadsList
          setUploadId={(uploadId) => setOpenSplitter({ projectId, uploadId })}
          uploads={uploads}
        />
      </Fragment>
    )
  );
}

PendingUploadsBanner.propTypes = {
  drawId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  uploads: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UploadsList.propTypes = {
  setUploadId: PropTypes.func.isRequired,
  uploads: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UploadLink.propTypes = {
  setUploadId: PropTypes.func.isRequired,
  upload: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    pages: PropTypes.arrayOf(PropTypes.number).isRequired,
    processingFinishedAt: PropTypes.string,
  }).isRequired,
};

PendingUploadsBanner.defaultProps = {
  drawId: undefined,
};
