import { useMemo, useRef, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  HelpIcon,
  InfoSignIcon,
} from "evergreen-ui";
import {
  Button,
  Checkbox,
  Divider,
  FastInput,
  FastMaskInput,
  Form,
  Link,
  Pane,
  Spacer,
  Table,
  Text,
  Tooltip,
} from "components/materials";
import { VendorFormPartial } from "components/templates";
import { FUNDING_SOURCE_TYPE } from "helpers/enums";
import { majorScale, minorScale, withTheme, Position } from "helpers/utilities";
import t from "helpers/translate";
import { subtract } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import unformatNumber from "helpers/unformatNumber";
import { chunk, cloneDeep, difference, get, isEmpty, xor } from "lodash";
import {
  deleteGroup,
  hasInsufficientDrawAmounts,
  isDivisionExpanded,
  newFundingSource,
} from "helpers/fundingSourceHelpers";
import formatPercent from "helpers/formatPercent";
import GroupMenu from "./GroupMenu";

function getFundingSourceTypeOptions() {
  return Object.values(FUNDING_SOURCE_TYPE).map((type) => ({
    text: t(`fundingSourceType.${type}`),
    value: type,
    key: type,
  }));
}

const splitAllIntoGroups = (arr) => {
  return arr.reduce((accumulator, currentValue) => {
    return accumulator.concat(chunk(currentValue, 1));
  }, []);
};

const isUseOfFundsDisabled = (draws, fundingSource, lineItem) => {
  return draws.some((draw) => {
    return draw.fundingSources.some((drawFundingSource) => {
      return (
        drawFundingSource.id === fundingSource.id &&
        drawFundingSource.disbursements.some((disbursement) => {
          return (
            disbursement.lineItemId === lineItem.id &&
            unformatNumber(disbursement.disbursedAmount) !== 0
          );
        })
      );
    });
  });
};

const checkDivisionLineItems = (fundingSourceValues, fsId, division) => {
  const fundingSource = cloneDeep(fundingSourceValues);

  if (isEmpty(get(fundingSource, `[${fsId}]`))) {
    fundingSource[fsId] = { lineItemIds: {} };
  }

  division.lineItems.forEach((lineItem) => {
    fundingSource[fsId].lineItemIds[lineItem.id] = true;
  });

  return fundingSource;
};

const uncheckDivisionLineItems = (
  fundingSourceValues,
  fsId,
  division,
  draws,
  fs
) => {
  const fundingSource = cloneDeep(fundingSourceValues);
  division.lineItems.forEach((lineItem) => {
    const useOfFundsDisabled = isUseOfFundsDisabled(draws, fs, lineItem);
    if (!useOfFundsDisabled) {
      fundingSource[fsId].lineItemIds[lineItem.id] = false;
    }
  });
  return fundingSource;
};

const DivisionRows = ({
  division,
  draws,
  form,
  expandedDivisionIds,
  setExpandedDivisionIds,
  ...props
}) => {
  const { values } = form;
  return (
    <Fragment>
      <Table.SectionHeader {...props}>
        <Table.TextSectionHeaderCell
          onClick={() => {
            setExpandedDivisionIds(xor(expandedDivisionIds, [division.id]));
          }}
          textProps={{ fontWeight: 500 }}
        >
          {isDivisionExpanded(division.id, expandedDivisionIds) ? (
            <ChevronDownIcon marginRight={minorScale(1)} />
          ) : (
            <ChevronRightIcon marginRight={minorScale(1)} />
          )}
          {division.name}
        </Table.TextSectionHeaderCell>

        {values.fundingSourceGroups.map((fundingSourceGroup) => {
          return fundingSourceGroup.map((fundingSource) => {
            const divisionLineItemIds = division.lineItems.reduce(
              (acc, lineItem) => {
                acc.push(lineItem.id);
                return acc;
              },
              []
            );

            const lineItemIds = Object.keys(
              get(values, `fundingSource[${fundingSource.id}].lineItemIds`, [])
            );

            const checkedLineItemIds = lineItemIds.filter(
              (id) => values.fundingSource[fundingSource.id].lineItemIds[id]
            );

            const lineItemDifference = difference(
              divisionLineItemIds,
              checkedLineItemIds
            ).length;

            return (
              <Table.SectionHeaderCell key={fundingSource.id}>
                <Checkbox
                  marginY={majorScale(1)}
                  justifyContent="center"
                  name={division.name + fundingSource.id}
                  checked={lineItemDifference === 0}
                  data-testid={`div-${division.name}-${fundingSource.label}`}
                  indeterminate={
                    lineItemDifference > 0 &&
                    lineItemDifference < division.lineItems.length
                  }
                  onChange={() => {
                    let result = {};
                    if (lineItemDifference === 0) {
                      result = uncheckDivisionLineItems(
                        values.fundingSource,
                        fundingSource.id,
                        division,
                        draws,
                        fundingSource
                      );
                    } else {
                      result = checkDivisionLineItems(
                        values.fundingSource,
                        fundingSource.id,
                        division
                      );
                    }
                    form.setFieldValue("fundingSource", result);
                  }}
                />
              </Table.SectionHeaderCell>
            );
          });
        })}
      </Table.SectionHeader>
      {isDivisionExpanded(division.id, expandedDivisionIds) && (
        <LineItemRows division={division} draws={draws} form={form} />
      )}
    </Fragment>
  );
};

const LineItemRows = ({ division, draws, form, ...props }) =>
  division.lineItems.map((lineItem) => (
    <Table.Row key={division.id + lineItem.id} {...props}>
      <Table.TextCell paddingLeft={majorScale(4)}>
        {lineItem.name}
      </Table.TextCell>
      {form.values.fundingSourceGroups.map((fundingSourceGroup) => {
        return fundingSourceGroup.map((fundingSource) => {
          const useOfFundsDisabled = isUseOfFundsDisabled(
            draws,
            fundingSource,
            lineItem
          );
          return (
            <Table.Cell key={fundingSource.id + lineItem.id} textAlign="center">
              {useOfFundsDisabled ? (
                <Tooltip
                  content={
                    <Text color="white">
                      {t("fundingSources.useOfFundsDisabled")}
                    </Text>
                  }
                  position={Position.TOP}
                >
                  <Form.Checkbox
                    marginY={majorScale(1)}
                    justifyContent="center"
                    name={`fundingSource[${fundingSource.id}].lineItemIds.${lineItem.id}`}
                    testId={`li-${lineItem.name}-${fundingSource.label}`}
                    disabled
                  />
                </Tooltip>
              ) : (
                <Form.Checkbox
                  marginY={majorScale(1)}
                  justifyContent="center"
                  name={`fundingSource[${fundingSource.id}].lineItemIds.${lineItem.id}`}
                />
              )}
            </Table.Cell>
          );
        });
      })}
    </Table.Row>
  ));

const useFundedAmountsWarnings = (
  fundingSourceGroups,
  initialFundingSourceGroups,
  projectTotal,
  setFieldValue
) => {
  // Just calculating the end sum of all funding sources is cheaper than
  // deep comparison of fundingSourceGroups, so we use that for the memo.
  const totalFundedAmountFromAllSources = fundingSourceGroups
    .flat()
    .map((source) => source.amount)
    .reduce((acc, amount) => acc + unformatNumber(amount), 0);

  const fundedAmount = useMemo(
    () => totalFundedAmountFromAllSources || projectTotal,
    [totalFundedAmountFromAllSources, projectTotal]
  );

  const initialFundingSourceGroupsRef = useRef(initialFundingSourceGroups);
  const isInsufficient = hasInsufficientDrawAmounts(
    fundingSourceGroups,
    initialFundingSourceGroupsRef.current
  );

  useEffect(() => {
    let warning = "";
    if (fundedAmount < projectTotal) {
      warning = `Under by: ${formatCurrency(
        subtract(projectTotal, fundedAmount)
      )}`;
    } else if (fundedAmount > projectTotal) {
      warning = `Over by: ${formatCurrency(
        subtract(fundedAmount, projectTotal)
      )}`;
    }
    setFieldValue("warning", warning);

    setFieldValue(
      "errorWarning",
      isInsufficient
        ? "Cannot change funding source amounts to less than the amounts on draws"
        : ""
    );
  }, [projectTotal, fundedAmount, isInsufficient, setFieldValue]);

  return fundedAmount;
};

const GroupsInput = ({
  divisions,
  draws,
  expandedDivisionIds,
  form,
  getProjectVendorSearchQuery,
  hasAutoAllocateOnOrg,
  hasUsesOfFundsOnOrg,
  newlyAddedVendors,
  organizations,
  projectTotal,
  push,
  searchedVendors,
  setExpandedDivisionIds,
  setNewlyAddedVendors,
  theme,
}) => {
  const { initialValues, setFieldValue, values } = form;

  const match = useRouteMatch();
  const { projectId } = match.params;
  const onAdd = () => {
    const {
      newFundingSourceGroup,
      newFundingSourceLineItems,
    } = newFundingSource(divisions);
    push([newFundingSourceGroup]);
    setFieldValue("fundingSource", {
      ...values.fundingSource,
      ...newFundingSourceLineItems,
    });
  };

  const fundedAmount = useFundedAmountsWarnings(
    values.fundingSourceGroups,
    initialValues.fundingSourceGroups,
    projectTotal,
    setFieldValue
  );

  return (
    <Fragment>
      <Text>
        Budget: {formatCurrency(projectTotal)}
        <Spacer />
        <Spacer />
        Total Funding Sources: {formatCurrency(fundedAmount)}
        <Spacer />
        <Spacer />
        {values.warning && (
          <Text padding={2} backgroundColor={theme.colors.baseYellow}>
            {values.warning}
          </Text>
        )}
      </Text>
      <Divider height={majorScale(1)} />
      <Pane display="flex" alignItems="baseline">
        <Pane display="flex" alignItems="baseline">
          {hasAutoAllocateOnOrg && (
            <Pane display="flex">
              <Form.Switch
                name="automaticAllocationEnabled"
                label="Auto allocate funds on draw"
                onClick={(e) => {
                  if (!e.target.checked) {
                    const oldValues = values.fundingSourceGroups;
                    const result = splitAllIntoGroups(oldValues);
                    form.setFieldValue("fundingSourceGroups", result);
                  }
                }}
              />
              <Spacer />
              <Link
                purpose="funding-sources auto-allocate help"
                href="https://help.rabbet.com/en/articles/4063767-project-funding-source-configuration"
              >
                <InfoSignIcon color="info" />
              </Link>
              <Spacer />
              <Spacer />
            </Pane>
          )}
          {hasUsesOfFundsOnOrg && (
            <Pane display="flex">
              <Form.Switch
                name="usesOfFundsEnabled"
                label="Set uses of funds"
              />
              <Spacer />
              <Link
                purpose="funding-sources uses help"
                href="https://help.rabbet.com/en/articles/4063767-project-funding-source-configuration"
              >
                <InfoSignIcon color="info" />
              </Link>
            </Pane>
          )}
        </Pane>
        <Pane flexGrow="1" textAlign="right">
          <Button
            appearance="primary"
            content="Add Funding Source"
            onClick={onAdd}
            purpose="funding-sources add"
          />
        </Pane>
      </Pane>
      <Divider />
      <Pane borderLeft="muted" borderRight="muted">
        <Table paddingBottom={0} allowHorizontalScroll stickyColumn>
          <Table.Head>
            <Table.Row>
              <Table.HeaderCell />
              {form.values.fundingSourceGroups.map((group, index) => {
                const translationIndex = index + 1 > 3 ? "other" : index + 1;
                return (
                  <Table.TextHeaderCell colSpan={group.length} key={index}>
                    {hasAutoAllocateOnOrg &&
                    values.automaticAllocationEnabled ? (
                      <Fragment>
                        <Text>
                          {t(`fundingSources.paysOrder_${translationIndex}`, {
                            position: index + 1,
                          })}
                          {group.length > 1 && " (Pari Passu)"}
                        </Text>
                        <Spacer />
                        <GroupMenu
                          form={form}
                          allGroups={form.values.fundingSourceGroups}
                          currentGroup={group}
                          index={index}
                        />
                      </Fragment>
                    ) : (
                      <Pane display="flex" justifyContent="flex-end">
                        <Button
                          purpose="funding-sources delete"
                          onClick={() => {
                            const fundingSourceUsed = form.values.fundingSourceGroups[
                              index
                            ].find(
                              (fundingSource) =>
                                fundingSource.disbursedAmount > 0
                            );

                            if (fundingSourceUsed) {
                              form.setFieldValue(
                                "errorWarning",
                                "Cannot delete funding source(s) which have already have been used on draws"
                              );
                            } else {
                              const result = deleteGroup(
                                form.values.fundingSourceGroups,
                                index
                              );
                              form.setFieldValue("fundingSourceGroups", result);
                              form.setFieldValue("errorWarning", "");
                            }
                          }}
                          content="Delete"
                          type="button"
                        />
                      </Pane>
                    )}
                  </Table.TextHeaderCell>
                );
              })}
            </Table.Row>
          </Table.Head>

          <Table.Body>
            <Table.Row>
              <Table.TextCell textProps={{ fontWeight: 500 }}>
                Name
              </Table.TextCell>
              {form.values.fundingSourceGroups.map((group, groupNumber) => {
                return group.map((fundingSource, fundingSourceNumber) => (
                  <Table.Cell key={fundingSource.id} minWidth={200}>
                    <FastInput
                      name={`fundingSourceGroups.${groupNumber}.${fundingSourceNumber}.label`}
                    />
                  </Table.Cell>
                ));
              })}
            </Table.Row>
            <Table.Row>
              <Table.TextCell textProps={{ fontWeight: 500 }}>
                Organization
              </Table.TextCell>
              {form.values.fundingSourceGroups.map((group, groupNumber) => {
                return group.map((fundingSource, fundingSourceNumber) => {
                  return (
                    <Fragment key={fundingSource.id}>
                      <Table.Cell minWidth={200}>
                        <VendorFormPartial
                          hideLabel
                          formikProps={form}
                          getProjectVendorSearchQuery={
                            getProjectVendorSearchQuery
                          }
                          initialVendors={organizations}
                          newlyAddedVendors={newlyAddedVendors}
                          projectId={projectId}
                          searchedVendors={searchedVendors}
                          setNewlyAddedVendors={setNewlyAddedVendors}
                          vendorObjectFieldName={`fundingSourceGroups[${groupNumber}][${fundingSourceNumber}].organization`}
                        />
                      </Table.Cell>
                    </Fragment>
                  );
                });
              })}
            </Table.Row>
            <Table.Row>
              <Table.TextCell textProps={{ fontWeight: 500 }}>
                Amount
              </Table.TextCell>
              {form.values.fundingSourceGroups.map((group, groupNumber) => {
                return group.map((fundingSource, fundingSourceNumber) => (
                  <Table.Cell key={fundingSource.id} minWidth={200}>
                    <FastMaskInput
                      mask="currency"
                      name={`fundingSourceGroups.${groupNumber}.${fundingSourceNumber}.amount`}
                      onChange={({ target: { value } }) => {
                        const name = `fundingSourceGroups.${groupNumber}.${fundingSourceNumber}.percent`;
                        value = unformatNumber(value);

                        const percent =
                          projectTotal === 0 ? 0 : value / projectTotal;

                        form.setFieldValue(
                          name,
                          formatPercent(percent, "-", 3)
                        );
                      }}
                    />
                  </Table.Cell>
                ));
              })}
            </Table.Row>
            <Table.Row>
              <Table.TextCell textProps={{ fontWeight: 500 }}>
                Percentage
                <Tooltip content={t("fundingSources.percentsAreEstimatesOnly")}>
                  <HelpIcon
                    color="default"
                    marginLeft={minorScale(1)}
                    size={minorScale(3)}
                  />
                </Tooltip>
              </Table.TextCell>
              {form.values.fundingSourceGroups.map((group, groupNumber) =>
                group.map((fundingSource, fundingSourceNumber) => (
                  <Table.Cell key={fundingSource.id} minWidth={200}>
                    <FastMaskInput
                      mask="percentage"
                      name={`fundingSourceGroups.${groupNumber}.${fundingSourceNumber}.percent`}
                      onChange={({ target: { value } }) => {
                        const name = `fundingSourceGroups.${groupNumber}.${fundingSourceNumber}.amount`;
                        value = projectTotal * unformatNumber(value);
                        form.setFieldValue(name, value);
                      }}
                    />
                  </Table.Cell>
                ))
              )}
            </Table.Row>
            <Table.Row>
              <Table.TextCell textProps={{ fontWeight: 500 }}>
                Type
              </Table.TextCell>
              {form.values.fundingSourceGroups.map((group, groupNumber) => {
                return group.map((fundingSource, fundingSourceNumber) => (
                  <Table.Cell key={fundingSource.id} minWidth={200}>
                    <Form.Select
                      name={`fundingSourceGroups[${groupNumber}][${fundingSourceNumber}].type`}
                      options={getFundingSourceTypeOptions()}
                    />
                  </Table.Cell>
                ));
              })}
            </Table.Row>

            <Table.Row>
              <Table.TextCell textProps={{ fontWeight: 500 }}>
                Close Date
              </Table.TextCell>
              {form.values.fundingSourceGroups.map((group, groupNumber) => {
                return group.map((fundingSource, fundingSourceNumber) => (
                  <Table.Cell key={fundingSource.id} minWidth={200}>
                    <Form.DateInput
                      name={`fundingSourceGroups[${groupNumber}][${fundingSourceNumber}].closeDate`}
                      popperProps={{
                        positionFixed: true,
                      }}
                    />
                  </Table.Cell>
                ));
              })}
            </Table.Row>

            <Table.Row>
              <Table.TextCell textProps={{ fontWeight: 500 }}>
                Maturity Date
              </Table.TextCell>
              {form.values.fundingSourceGroups.map((group, groupNumber) => {
                return group.map((fundingSource, fundingSourceNumber) => (
                  <Table.Cell key={fundingSource.id} minWidth={200}>
                    <Form.DateInput
                      name={`fundingSourceGroups[${groupNumber}][${fundingSourceNumber}].maturityDate`}
                      popperProps={{
                        positionFixed: true,
                      }}
                    />
                  </Table.Cell>
                ));
              })}
            </Table.Row>

            {hasUsesOfFundsOnOrg && values.usesOfFundsEnabled && (
              <Table.Row height={majorScale(5)}>
                <Table.TextCell textProps={{ fontWeight: 500 }}>
                  Uses of Funds:
                </Table.TextCell>
                {form.values.fundingSourceGroups.map((fsGroup) =>
                  fsGroup.map((fs) => (
                    <Table.Cell key={fsGroup.id + fs.id} minWidth={200} />
                  ))
                )}
              </Table.Row>
            )}

            {hasUsesOfFundsOnOrg &&
              values.usesOfFundsEnabled &&
              divisions.map((division) => (
                <Fragment key={division.id}>
                  <DivisionRows
                    division={division}
                    draws={draws}
                    form={form}
                    expandedDivisionIds={expandedDivisionIds}
                    setExpandedDivisionIds={setExpandedDivisionIds}
                  />
                </Fragment>
              ))}
          </Table.Body>
        </Table>
      </Pane>
    </Fragment>
  );
};

export default withTheme(GroupsInput);

GroupsInput.propTypes = {
  divisions: PropTypes.array.isRequired,
  draws: PropTypes.array.isRequired,
  expandedDivisionIds: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  hasAutoAllocateOnOrg: PropTypes.bool,
  hasUsesOfFundsOnOrg: PropTypes.bool,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  push: PropTypes.func.isRequired,
  projectTotal: PropTypes.number.isRequired,
  setExpandedDivisionIds: PropTypes.func.isRequired,
};
