import { Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Modal, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

const PULL_DATA_FROM_YARDI_MUTATION = gql`
  mutation PullDataFromYardi($projectId: String!) {
    pullPayablesFromYardi(projectId: $projectId) {
      id
      accountsPayableLastSyncedAt
    }
  }
`;

export function PullDataFromYardiWarningModal({
  projectId,
  onClose,
  setPullDataFromYardiButtonDisabled,
}) {
  const [
    pullDataFromYardi,
    { loading: pullDataFromYardiLoading },
  ] = useMutation(PULL_DATA_FROM_YARDI_MUTATION, {
    onCompleted: () => {
      setPullDataFromYardiButtonDisabled();
      onClose();
    },
  });

  function handleConfirm() {
    pullDataFromYardi({
      variables: {
        projectId,
      },
    });
  }

  return (
    <Fragment>
      <Modal
        hasFooter
        onConfirm={handleConfirm}
        confirmLabel="Pull Data from Yardi"
        isConfirmLoading={pullDataFromYardiLoading}
        onClose={() => onClose()}
        open
        size="small"
        title="Pull Payable Documents from Yardi"
      >
        <Modal.Content>
          <Pane marginBottom={majorScale(2)} fontSize={15}>
            After pulling payable documents from Yardi, the documents can be
            found in the project documents section.
          </Pane>
          <Pane marginBottom={majorScale(2)} fontSize={15}>
            Would you like to proceed?
          </Pane>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}
