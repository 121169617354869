import { InfoSignIcon } from "evergreen-ui";
import {
  Button,
  Card,
  Pane,
  Paragraph,
  Progress,
  ProgressGauge,
  Shortener,
  Text,
  Tooltip,
} from "components/materials";
import formatPercent from "helpers/formatPercent";
import { add, divide, multiply, subtract } from "helpers/math";
import {
  formatCurrency,
  formatShortenedCurrency,
} from "helpers/formatCurrency";
import { PIE_CHART_COLORS } from "helpers/colors";
import { minorScale, Position } from "helpers/utilities";
import { trackPortfolioAnalysis } from "./helpers";
import { HeaderTextCell, PortfolioInsightBlankSlateCard, TextCell } from ".";

function labelContent() {
  const tooltipContent =
    "Contingency Overage = (Budget Spent % - Contingency Spent %) * Original Contingency Amount";
  return (
    <Pane>
      <Text>Portfolio Contingency Overage</Text>
      <Tooltip content={tooltipContent} position={Position.TOP}>
        <InfoSignIcon
          color="info"
          size={minorScale(3)}
          marginLeft={minorScale(1)}
          position="absolute"
          top="80%"
        />
      </Tooltip>
    </Pane>
  );
}

export function ContingencyRisk({
  history,
  organizationId,
  projects,
  stylingProps,
}) {
  if (projects.length === 0)
    return <PortfolioInsightBlankSlateCard cardName="contingencyRisk" />;

  const sortedProjects = projects
    .map(
      ({
        contingencyOriginalAmount,
        grossPercentComplete,
        id: projectId,
        name: projectName,
        percentOfContingencySpent,
      }) => {
        // pending a change pre-release, the contingency overage is represented as:
        // contingency spent MORE than the proportional amount of the budget complete is a NEGATIVE #
        // contingency that has NOT BEEN SPENT compared to the proportional amount that the budget is funded
        // is a POSITIVE #
        // i.e.    $1k contingency   10% budget complete (funded)    10% contingency would be $100
        //  if $256 has been adjusted out of / requested from contingency line items, contingencyOverage would be -$156
        const contingencyOveragePercent = subtract(
          grossPercentComplete,
          percentOfContingencySpent
        );
        const contingencyOverage = multiply(
          contingencyOveragePercent,
          contingencyOriginalAmount
        );

        return {
          budgetSpent: grossPercentComplete,
          contingencyOriginalAmount,
          contingencyOverage,
          contingencySpent: percentOfContingencySpent,
          projectId,
          projectName,
        };
      }
    )
    .sort(
      (projectA, projectB) =>
        projectA.contingencyOverage - projectB.contingencyOverage
    );
  const maxAbsContingencyOverage = Math.max(
    Math.abs(sortedProjects[0].contingencyOverage),
    Math.abs(sortedProjects[sortedProjects.length - 1].contingencyOverage)
  );

  const totalContingencyOverage = sortedProjects.reduce(
    (total, { contingencyOverage }) => add(total, contingencyOverage),
    0
  );

  const expectedTotalContingencySpent = sortedProjects.reduce(
    (total, { budgetSpent, contingencyOriginalAmount }) =>
      add(total, multiply(budgetSpent, contingencyOriginalAmount)),
    0
  );

  const gaugePercent = multiply(
    100,
    divide(totalContingencyOverage, expectedTotalContingencySpent)
  );

  return (
    <Pane>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        Contingency Risk
      </Paragraph>
      <Card {...stylingProps.card}>
        <ProgressGauge
          dataValue={formatShortenedCurrency(totalContingencyOverage)}
          height={300}
          labelContent={labelContent()}
          percent={gaugePercent}
          iconTop="80%"
          tooltipContent="test test"
        />
        <Pane maxHeight={200} overflowY="scroll">
          <table {...stylingProps.table}>
            <thead>
              <tr>
                <HeaderTextCell>Project</HeaderTextCell>
                <HeaderTextCell style={{ textAlign: "center" }}>
                  Budget Spent
                </HeaderTextCell>
                <HeaderTextCell style={{ textAlign: "center" }}>
                  Contingency Spent
                </HeaderTextCell>
                <HeaderTextCell colSpan={3}>Contingency Overage</HeaderTextCell>
              </tr>
            </thead>
            <tbody>
              {sortedProjects.map((project) => {
                const { projectId } = project;
                const navigateToProject = () => {
                  trackPortfolioAnalysis(organizationId, "Go to Project");
                  return history.push(`/projects/${projectId}`);
                };
                return (
                  <ContingencyRiskRow
                    key={projectId}
                    {...project}
                    maxAbsContingencyOverage={maxAbsContingencyOverage}
                    navigateToProject={navigateToProject}
                  />
                );
              })}
            </tbody>
          </table>
        </Pane>
      </Card>
    </Pane>
  );
}

function ContingencyRiskRow({
  budgetSpent,
  contingencySpent,
  contingencyOverage,
  navigateToProject,
  projectName,
  maxAbsContingencyOverage,
}) {
  return (
    <tr>
      <TextCell>
        <Shortener limit={17} size={300} text={projectName} />
      </TextCell>
      <TextCell style={{ textAlign: "center" }}>
        {formatPercent(budgetSpent)}
      </TextCell>
      <TextCell style={{ textAlign: "center" }}>
        {formatPercent(contingencySpent)}
      </TextCell>
      <TextCell style={{ textAlign: "right" }}>
        {formatCurrency(contingencyOverage)}
      </TextCell>
      <td width="100%" aria-label="progress">
        <Progress
          value={Math.abs(contingencyOverage)}
          total={maxAbsContingencyOverage}
          color={
            Math.sign(contingencyOverage) === -1
              ? PIE_CHART_COLORS.BASE_RED
              : PIE_CHART_COLORS.BASE_GREEN
          }
          background="transparent"
        />
      </td>
      <td>
        <Button onClick={navigateToProject}>Go to Project</Button>
      </td>
    </tr>
  );
}
