import { Fragment } from "react";
import {
  LineItemProjectionChart,
  ProjectionTable,
  ProjectionTableActions,
} from "components/templates";
import { Loadable, Pane } from "components/materials";

export function LineItemProjectionsView({
  handleProjectionsChange,
  isSaving,
  lineItemChartId,
  loadingFundingSources,
  projection,
}) {
  const { showProjectionCharts } = projection.views;
  return (
    <Fragment>
      {showProjectionCharts && (
        <Pane position="relative" height="375px" marginX="80px">
          {loadingFundingSources ? (
            <Loadable loading />
          ) : (
            <LineItemProjectionChart
              chartId={lineItemChartId}
              projection={projection}
            />
          )}
        </Pane>
      )}
      <ProjectionTableActions
        chartId={lineItemChartId}
        isLoading={isSaving}
        onChange={handleProjectionsChange}
        projection={projection}
      />
      <ProjectionTable
        onChange={handleProjectionsChange}
        projection={projection}
      />
    </Fragment>
  );
}
