import {
  cloneDeep,
  compact,
  flatMap,
  flatten,
  get,
  includes,
  keys,
  map,
  reduce,
  uniqBy,
} from "lodash";
import { add, subtract, sumBy } from "helpers/math";
import isBlank from "helpers/isBlank";
import { v4 as uuid } from "uuid";
import { formatNumber } from "helpers/formatNumber";
import unformatNumber from "helpers/unformatNumber";
import { formatCurrency } from "helpers/formatCurrency";
import { FUNDING_SOURCE_TYPE } from "helpers/enums";
import { dateFormToServer } from "./dateHelpers";

export function isDivisionExpanded(divisionId, expandedDivisionIds) {
  return includes(expandedDivisionIds, divisionId);
}

export function getAllLineItems(divisions) {
  return reduce(
    divisions,
    (divisionAcc, { lineItems }) =>
      reduce(lineItems, (acc, { id }) => ({ ...acc, [id]: true }), divisionAcc),
    {}
  );
}

export function newFundingSource(divisions) {
  const id = uuid();
  const allLineItems = getAllLineItems(divisions);
  return {
    newFundingSourceGroup: {
      id,
      disbursedAmount: undefined,
      amount: undefined,
      label: undefined,
      type: undefined,
      organization: { id: undefined },
      closeDate: undefined,
      maturityDate: undefined,
      newOrganization: {
        name: undefined,
      },
      // default new funding sources to all uses enabled
      lineItemIds: keys(allLineItems),
    },
    newFundingSourceLineItems: { [id]: { lineItemIds: allLineItems } },
  };
}

export function deleteGroup(arr, index) {
  const clonedArr = cloneDeep(arr);
  clonedArr.splice(index, 1);
  return clonedArr;
}

export function sumFundingSourceGroups(fundingSourceGroups) {
  const fundingSources = flatMap(fundingSourceGroups);
  return sumBy(fundingSources, (fundingSource) =>
    unformatNumber(fundingSource.amount)
  );
}

export function hasFundingSourceValues(fundingSourceGroups) {
  return fundingSourceGroups.find((fsGroup) =>
    fsGroup.find((fundingSource) => fundingSource.amount)
  );
}

export function hasInsufficientDrawAmounts(
  fundingSourceGroups,
  initialFundingSourceGroups
) {
  return fundingSourceGroups.find((fsGroup) =>
    fsGroup.find((fundingSource) => {
      // Amount cannot be saved for less than the total disbursed amount across draws
      // unless a funding source has been excessively disbursed,
      // which is possible on the draw funding source page due to lack of validation for the customer's convenience.
      // In this case, no amount below the form's initial value is allowed.
      const initialFundingSource = flatten(initialFundingSourceGroups).find(
        ({ id }) => id === fundingSource.id
      );

      return (
        fundingSource.disbursedAmount > unformatNumber(fundingSource.amount) &&
        unformatNumber(fundingSource.amount) <
          (initialFundingSource?.amount ?? 0)
      );
    })
  );
}

// TODO create a delete uses of funds helper when a group is deleted

export function drawFundedTotal(values, usesOfFundsOn) {
  if (usesOfFundsOn) {
    return sumBy(values.fundingSources, (fundingSource) =>
      sumBy(
        fundingSource.disbursements,
        (disbursement) =>
          values.usesOfFunds[fundingSource.id][disbursement.lineItemId]
            .disbursedAmount
      )
    );
  }
  return values.fundingSources.reduce((acc, fundingSource) => {
    return add(acc, fundingSource.disbursedAmount);
  }, 0);
}

export function fundedThisDrawDifference(values, usesOfFundsOn) {
  return subtract(
    values.drawRequestedAmount,
    drawFundedTotal(values, usesOfFundsOn)
  );
}

export function fundingSourceFundedThisDraw(values, fundingSource) {
  return fundingSource.disbursements.reduce((acc, disbursement) => {
    return add(
      acc,
      values.usesOfFunds[fundingSource.id][disbursement.lineItemId]
        .disbursedAmount
    );
  }, 0);
}

export function lineItemDifference(lineItem, values) {
  const drawLineItemFundedThisDrawTotal = (lineItem, values) => {
    return values.fundingSources.reduce((acc, fundingSource) => {
      return add(
        acc,
        values.usesOfFunds[fundingSource.id][lineItem.id].disbursedAmount
      );
    }, 0);
  };

  const difference = subtract(
    lineItem.requestedAmount,
    drawLineItemFundedThisDrawTotal(lineItem, values)
  );

  return difference;
}

export function divisionRequestedAmount(lineItems) {
  return lineItems.reduce((acc, lineItem) => {
    return add(acc, lineItem.requestedAmount);
  }, 0);
}

export function divisionDifference(divisionLineItems, values) {
  const divisionFundedThisDrawAmount = (divisionLineItems, values) => {
    return sumBy(values.fundingSources, (fundingSource) =>
      sumBy(fundingSource.disbursements, (disbursement) =>
        sumBy(divisionLineItems, (lineItem) => {
          if (lineItem.id === disbursement.lineItemId) {
            return values.usesOfFunds[fundingSource.id][disbursement.lineItemId]
              .disbursedAmount;
          }
          return 0;
        })
      )
    );
  };

  return subtract(
    divisionRequestedAmount(divisionLineItems),
    divisionFundedThisDrawAmount(divisionLineItems, values)
  );
}

export function fundingSourceLineItemThisDrawAmount(
  lineItem,
  fundingSource,
  values
) {
  return fundingSource.disbursements.reduce((acc, disbursement) => {
    if (lineItem.id === disbursement.lineItemId) {
      acc = add(
        acc,
        values.usesOfFunds[fundingSource.id][disbursement.lineItemId]
          .disbursedAmount
      );
    }
    return acc;
  }, 0);
}

export function fundingSourceLineItemPreviouslyFundedAmount(
  lineItem,
  fundingSource,
  values
) {
  return fundingSource.disbursements.reduce((acc, disbursement) => {
    if (lineItem.id === disbursement.lineItemId) {
      acc = add(
        acc,
        values.usesOfFunds[fundingSource.id][disbursement.lineItemId]
          .disbursedPreviouslyAmount
      );
    }
    return acc;
  }, 0);
}

export function balanceRemaining(values, index, usesOfFundsOn) {
  const amount = get(values, `fundingSources[${index}].amount`, 0);
  const disbursedPreviouslyAmount = get(
    values,
    `fundingSources[${index}].disbursedPreviouslyAmount`,
    0
  );
  const disbursedAmount = usesOfFundsOn
    ? fundingSourceFundedThisDraw(values, values.fundingSources[index])
    : get(values, `fundingSources[${index}].disbursedAmount`, 0);

  return subtract(amount, disbursedPreviouslyAmount, disbursedAmount);
}

export function getGroupNames(groups) {
  return groups.map((group) => {
    const nameGrouping = group.map((fundingSource) => {
      return get(fundingSource, "label", "(unnamed)");
    });
    return nameGrouping.join(", ");
  });
}

export function getFundingSourceNames(fundingSourceList) {
  const nameGrouping = fundingSourceList.map((fundingSource) => {
    return (
      get(fundingSource, "organization.name") || get(fundingSource, "label")
    );
  });
  return nameGrouping.length === 0 ? "None" : nameGrouping.join(", ");
}

export function getDisbursements(formValues) {
  const { fundingSources, usesOfFunds, usesOfFundsOn } = formValues;

  if (usesOfFundsOn) {
    return flatMap(usesOfFunds, (useOfFunds) =>
      map(useOfFunds, (disbursement) => ({
        id: disbursement.disbursementId,
        amount: formatCurrency(disbursement.disbursedAmount),
      }))
    ).filter((disbursement) => disbursement.id !== undefined);
  }

  return fundingSources.map((fundingSource) => ({
    id: fundingSource.id,
    amount: formatCurrency(fundingSource.disbursedAmount),
  }));
}

export function getFundingSourceAutoAllocations(formValues) {
  const adjustmentsByDisbursement = getDisbursements({
    ...formValues,
    usesOfFunds: formValues.autoAllocatedUses,
    // Regardless of if "use of funds" is turned on,
    // consider it to be "on" when fetching disbursements
    usesOfFundsOn: true,
  });

  return formValues.fundingSources.map((fundingSource) => {
    const disbursementIdsForFundingSource = fundingSource.disbursements.map(
      ({ id }) => id
    );
    const adjustmentAmount = adjustmentsByDisbursement
      .filter(({ id }) => includes(disbursementIdsForFundingSource, id))
      .reduce((sum, { amount }) => add(sum, amount), 0);

    return {
      ...fundingSource,
      disbursedAmount: formatCurrency(adjustmentAmount),
    };
  });
}

export function fundingSourcesByType(targetType, fundingSourceGroups) {
  return flatMap(
    fundingSourceGroups.map((fundingSourceGroup) => {
      return fundingSourceGroup.filter((fs) => fs.type === targetType);
    })
  );
}

// project level helpers

// expects a 2D array of funding sources
export function getFundingSourceAggregates(fundingSourceGroups) {
  const startingAccumulator = Object.values(FUNDING_SOURCE_TYPE).reduce(
    (acc, type) => ({
      ...acc,
      [type]: { sources: [], total: 0, disbursed: 0 },
    }),
    { totalFundedAmount: 0, totalDisbursedAmount: 0 }
  );

  return fundingSourceGroups.flat().reduce((aggregates, fundingSource) => {
    const { amount, disbursedAmount, type } = fundingSource;
    const aggregatesForType = aggregates[type];

    return {
      ...aggregates,
      [type]: {
        sources: aggregatesForType.sources.concat(fundingSource),
        total: add(aggregatesForType.total, amount),
        disbursed: add(aggregatesForType.disbursed, disbursedAmount),
      },
      totalFundedAmount: add(aggregates.totalFundedAmount, amount),
      totalDisbursedAmount: add(
        aggregates.totalDisbursedAmount,
        disbursedAmount
      ),
    };
  }, startingAccumulator);
}

export function getFundingSourceAggregatesForGroupedProjects(projects) {
  return getFundingSourceAggregates(
    projects.flatMap(({ fundingSourceGroups }) => fundingSourceGroups)
  );
}

// "Commitments" are funding sources that belong to the project's owner (aka the logged-in user's organization)
function getCommitmentSourcesForProject({
  fundingSourceGroups,
  organizationId,
}) {
  return fundingSourceGroups.map((fundingSourceGroup) =>
    fundingSourceGroup.filter(
      (fundingSource) => fundingSource.organization.id === organizationId
    )
  );
}

export function getCommitmentAggregates(project) {
  return getFundingSourceAggregates(getCommitmentSourcesForProject(project));
}

export function getCommitmentAggregatesForGroupedProjects(projects) {
  return getFundingSourceAggregates(
    projects.flatMap(getCommitmentSourcesForProject)
  );
}

export function getBudgetAggregatesForGroupedProjects(projects) {
  return {
    totalBudgetAmount: sumBy(projects, "amount"),
    totalSpentAmount: sumBy(projects, "requestedAmount"),
  };
}

export function getTotalEquityAmount(fundingSourceGroups) {
  return fundingSourceGroups.reduce(
    (total, fundingSourceGroup) =>
      fundingSourceGroup.reduce(
        (groupTotal, fundingSource) =>
          fundingSource.type === FUNDING_SOURCE_TYPE.EQUITY
            ? add(groupTotal, get(fundingSource, "amount", 0))
            : groupTotal,
        total
      ),
    0
  );
}

export function getTotalDebtAmount(fundingSourceGroups) {
  return fundingSourceGroups.reduce(
    (total, fundingSourceGroup) =>
      fundingSourceGroup.reduce(
        (groupTotal, fundingSource) =>
          fundingSource.type === FUNDING_SOURCE_TYPE.LOAN
            ? add(groupTotal, get(fundingSource, "amount", 0))
            : groupTotal,
        total
      ),
    0
  );
}

// draw level helpers

export function getCurrentEquityDisbursedAmount(fundingSourceGroups) {
  const equityFundingSources = fundingSourcesByType(
    FUNDING_SOURCE_TYPE.EQUITY,
    fundingSourceGroups
  );

  return equityFundingSources.reduce((total, fundingSource) => {
    const fundingSourceDisbursedAmount = get(
      fundingSource,
      "disbursedAmount",
      0
    );
    return add(total, fundingSourceDisbursedAmount);
  }, 0);
}

export function getTotalEquityDisbursedAmount(fundingSourceGroups) {
  const equityFundingSources = fundingSourcesByType(
    FUNDING_SOURCE_TYPE.EQUITY,
    fundingSourceGroups
  );

  return equityFundingSources.reduce((total, fundingSource) => {
    const fundingSourceDisbursedAmount = get(
      fundingSource,
      "disbursedToDateAmount",
      0
    );
    return add(total, fundingSourceDisbursedAmount);
  }, 0);
}

export function getCurrentDebtDisbursedAmount(fundingSourceGroups) {
  const debtFundingSources = fundingSourcesByType(
    FUNDING_SOURCE_TYPE.LOAN,
    fundingSourceGroups
  );

  return debtFundingSources.reduce((total, fundingSource) => {
    const fundingSourceDisbursedAmount = get(
      fundingSource,
      "disbursedAmount",
      0
    );
    return add(total, fundingSourceDisbursedAmount);
  }, 0);
}

export function getTotalDebtDisbursedAmount(fundingSourceGroups) {
  const debtFundingSources = fundingSourcesByType(
    FUNDING_SOURCE_TYPE.LOAN,
    fundingSourceGroups
  );

  return debtFundingSources.reduce((total, fundingSource) => {
    const fundingSourceDisbursedAmount = get(
      fundingSource,
      "disbursedToDateAmount",
      0
    );
    return add(total, fundingSourceDisbursedAmount);
  }, 0);
}

export function parseFundingSourceGroups(values) {
  return values.fundingSourceGroups.map((fundingSourceGroup) =>
    fundingSourceGroup.map(
      ({
        id,
        amount,
        label,
        type,
        organization,
        lineItemIds,
        closeDate,
        maturityDate,
      }) => ({
        amount: (amount || 0).toString(),
        label,
        type,
        id,
        lineItemIds,
        closeDate: dateFormToServer(closeDate),
        maturityDate: dateFormToServer(maturityDate),
        organizationId: organization.id,
      })
    )
  );
}

export function restructureFormValues(values) {
  // Turn Formik values into mutation's structure
  const newValues = cloneDeep(values);

  newValues.fundingSourceGroups.forEach((fsGroup, fsGroupIndex) => {
    fsGroup.forEach((fs, fsIndex) => {
      if (newValues.fundingSource[fs.id]) {
        const formLineItemIds = Object.keys(
          newValues.fundingSource[fs.id].lineItemIds
        );

        const checkedLineItemIds = formLineItemIds.filter(
          (id) => newValues.fundingSource[fs.id].lineItemIds[id]
        );

        newValues.fundingSourceGroups[fsGroupIndex][
          fsIndex
        ].lineItemIds = checkedLineItemIds;
      }
    });
  });

  delete newValues.fundingSource;

  return newValues;
}

export function getAllOrganizations(fundingSourceGroups, vendors) {
  // Organizations listed in the dropdown for FundingSourcesForm are both:
  // - the current organization's vendors
  // - vendors that were created on the submitting organization's side (for imported funding sources)
  const orgsFromFundingSources = flatMap(fundingSourceGroups).map(
    ({ organization }) => organization
  );
  return compact(uniqBy(vendors.concat(orgsFromFundingSources), "id"));
}

export function fundingSourceAndBudgetDifference(project) {
  const totalBudgetAmount = get(project, "amount");
  const groups = get(project, "fundingSourceGroups", []);
  const fundingSourceTotal = sumFundingSourceGroups(groups);

  // If the funding sources are not present, just return a difference of zero
  return groups.length === 0
    ? 0
    : subtract(fundingSourceTotal, totalBudgetAmount);
}

export function fundingSourceIsBlank({ amount, label, organization, type }) {
  return (
    formatNumber(amount || 0) === "0" &&
    isBlank(label) &&
    isBlank(get(organization, "id")) &&
    isBlank(type)
  );
}
