import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Text } from "components/materials";
import { withTheme } from "helpers/utilities";
import { get } from "lodash";
import cx from "classnames";
import styles from "./Link.module.css";

const Link = ({
  children,
  color,
  content,
  disabled,
  href,
  italic,
  purpose,
  theme,
  to,
  underline,
  noUnderlineOnHover,
  ...props
}) => {
  const baseProps = {
    color: get(theme, `colors[${color}]`) || color,
    cursor: "pointer",
    textDecoration: underline ? "underline" : "none",
    fontStyle: italic ? "italic" : "",
    ...props,
  };
  const disabledProps = disabled
    ? {
        color: get(theme, "colors.gray500"),
        cursor: "not-allowed",
      }
    : {};
  const textProps = { ...baseProps, ...disabledProps };

  const cssClass = cx({
    [styles.link]: !underline,
    [styles.noUnderlineOnHover]: !underline && noUnderlineOnHover,
  });

  if (to && !disabled) {
    return (
      <RouterLink to={to} {...props} className={cssClass}>
        <Text {...textProps} className={cssClass} data-purpose={purpose}>
          {content || children}
        </Text>
      </RouterLink>
    );
  }

  if (href) {
    return (
      <Text
        is="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        data-purpose={purpose}
        className={cssClass}
        {...textProps}
      >
        {content || children}
      </Text>
    );
  }

  return (
    <Text is="a" {...textProps} className={cssClass}>
      {content || children}
    </Text>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  content: PropTypes.node,
  href: PropTypes.string,
  italic: PropTypes.bool,
  noUnderlineOnHover: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  underline: PropTypes.bool,
};

Link.defaultProps = {
  color: "#078DDE",
};

export default withTheme(Link);
