import { isFinite } from "lodash";
import numbro from "numbro";

export function formatNumber(value, mantissa = 0) {
  if (value === "$_") return "0";

  return isFinite(value)
    ? numbro(value).format({ thousandSeparated: true, mantissa })
    : value;
}
