import { useState, Fragment } from "react";
import gql from "graphql-tag";
import { useRouteMatch } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { UploadPictureModal } from "components/templates";
import { Button, Pane } from "components/materials";
import { majorScale, toaster } from "helpers/utilities";

const UPLOAD_PROJECT_PICTURE = gql`
  mutation UploadProjectPicture($file: Upload!, $projectId: String!) {
    uploadProjectPicture(file: $file, projectId: $projectId) {
      id
      picture {
        url
      }
    }
  }
`;

const PROJECT_PICTURE_QUERY = gql`
  query ProjectPictureQuery($projectId: String!) {
    project(id: $projectId) {
      id
      picture {
        url
      }
    }
  }
`;

const handleUpload = (projectId, uploadProjectPicture) => (files) => {
  uploadProjectPicture({ variables: { projectId, file: files[0] } });
};

export function ChangeProjectPicture({ buttonProps }) {
  const match = useRouteMatch();
  const { projectId } = match.params;
  const [open, setOpen] = useState(false);

  const { data, loading } = useQuery(PROJECT_PICTURE_QUERY, {
    variables: {
      projectId,
    },
  });
  const [uploadProjectPicture, uploadProjectPictureResult] = useMutation(
    UPLOAD_PROJECT_PICTURE,
    {
      onError: () =>
        toaster.danger("Something went wrong. Please try again.", {
          duration: 2.5,
        }),
      onCompleted: () => {
        setOpen(false);
        toaster.success("Your project picture has been successfully changed.", {
          duration: 2.5,
        });
      },
    }
  );

  const imageUrl = get(data, "project.picture.url");

  return (
    <Fragment>
      <Button
        content={`${imageUrl ? "Change" : "Add"} Project Picture`}
        purpose="change-project-picture open"
        onClick={() => setOpen(true)}
        {...buttonProps}
      />
      {imageUrl && (
        <Pane height={200} marginTop={majorScale(2)} width={200}>
          <div
            style={{
              backgroundImage: `url("${imageUrl}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              borderRadius: "4px",
            }}
          />
        </Pane>
      )}
      {open && (
        <UploadPictureModal
          allowMultiple={false}
          disabled={uploadProjectPictureResult.loading || loading}
          onCancel={() => setOpen(false)}
          onSubmit={handleUpload(projectId, uploadProjectPicture, setOpen)}
          restrictTypes={{ acceptableTypes: ["image/*"] }}
          title="Upload Project Picture"
        />
      )}
    </Fragment>
  );
}
