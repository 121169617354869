import PropTypes from "prop-types";
import { DocumentIcon } from "evergreen-ui";
import { Button, Menu, Text } from "components/materials";
import { downloadXlsx } from "helpers/downloadHelpers";
import isBlank from "helpers/isBlank";
import {
  getNumberFormat,
  headerAlignment,
  headerFill,
  headerFont,
  totalsFill,
  totalsFont,
} from "helpers/exportXlsxHelpers";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import {
  getAggregatesForExport,
  getColumnHeaderText,
  getTotalsForExport,
  getValuesForExport,
} from "../FastDataTableUtils";

function exportAction(preparedColumns, preparedItems, showFooterTotals) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Rabbet");
  worksheet.columns = preparedColumns.map((column) => ({
    id: column.id,
    header: getColumnHeaderText(column),
    width: column.width / 8,
    style: {
      alignment: { horizontal: column.textAlign },
      numFmt: getNumberFormat(column.type),
    },
  }));

  const header = worksheet.getRow(1);
  header.font = headerFont;
  header.fill = headerFill;
  header.alignment = headerAlignment;

  if (Array.isArray(preparedItems)) {
    worksheet.addRows(getValuesForExport(preparedColumns, preparedItems));
  } else {
    Object.entries(preparedItems.groups).forEach(([value, items]) => {
      const groupHeader = worksheet.addRow([
        isBlank(value) || ["-", "undefined", "null"].includes(value)
          ? "(None)"
          : value,
      ]);
      groupHeader.font = { bold: true };

      worksheet.addRows(getValuesForExport(preparedColumns, items));

      const summaryRow = worksheet.addRow(
        getAggregatesForExport(preparedColumns, items)
      );
      summaryRow.font = totalsFont;
      summaryRow.fill = totalsFill;
    });
  }

  if (showFooterTotals) {
    const totalRow = worksheet.addRow(
      getTotalsForExport(preparedColumns, preparedItems)
    );
    totalRow.font = totalsFont;
    totalRow.fill = totalsFill;
  }

  downloadXlsx(workbook, "RabbetExport.xlsx");
}

export default function ExportToXLSX({
  closeDropdown,
  isButton,
  preparedColumns,
  preparedItems,
  showFooterTotals,
}) {
  if (isButton) {
    return (
      <Button
        appearance="primary"
        purpose="tables actions export-xlsx"
        onClick={() => {
          exportAction(preparedColumns, preparedItems, showFooterTotals);
        }}
      >
        Export to Excel
      </Button>
    );
  }

  return (
    <Menu.Item
      icon={DocumentIcon}
      is={Text}
      data-purpose="tables actions export-xlsx"
      onClick={() => {
        exportAction(preparedColumns, preparedItems, showFooterTotals);
        closeDropdown();
      }}
    >
      Export to Excel
    </Menu.Item>
  );
}

ExportToXLSX.propTypes = {
  closeDropdown: PropTypes.func,
  isButton: PropTypes.bool,
  preparedColumns: PropTypes.array,
  preparedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  showFooterTotals: PropTypes.bool,
};

ExportToXLSX.defaultProps = {};
