import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Prompt } from "react-router";
import { get } from "lodash";
import { Document } from "components/containers";
import { Button, Loadable, Pane, Pill } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { ProjectSnapshots } from "./ProjectSnapshots";
import {
  ProjectDetails,
  PROJECT_DETAILS_CONFIGURATION_SETTINGS,
} from "./ProjectDetails";
import {
  MostRecentDraw,
  MOST_RECENT_DRAW_CONFIGURATION_SETTINGS,
} from "./MostRecentDraw/MostRecentDraw";
import {
  DrawHistory,
  DRAW_HISTORY_CONFIGURATION_SETTINGS,
} from "./DrawHistory/DrawHistory";
import { Budget, BUDGET_CONFIGURATION_SETTINGS } from "./Budget/Budget";
import {
  FundingSources,
  FUNDING_SOURCES_CONFIGURATION_SETTINGS,
} from "./FundingSources/FundingSources";
import {
  ScheduleRisk,
  SCHEDULE_RISK_CONFIGURATION_SETTINGS,
} from "./ScheduleRisk/ScheduleRisk";
import {
  ContingencyRisk,
  CONTINGENCY_RISK_CONFIGURATION_SETTINGS,
} from "./ContingencyRisk";
import {
  ContingencyUsage,
  CONTINGENCY_USAGE_CONFIGURATION_SETTINGS,
} from "./ContingencyUsage/ContingencyUsage";
import { Timeline, TIMELINE_CONFIGURATION_SETTINGS } from "./Timeline";
import {
  PROJECT_DASHBOARD_QUERY,
  SAVE_PROJECT_DASHBOARD_CONFIGURATIONS,
} from "./graphql";
import { CollaborationDrawer } from "./CollaborationDrawer/CollaborationDrawer";
import { getUniqueDocuments } from "./CollaborationDrawer/Approvals/Approvals";
import { getMentions } from "./CollaborationDrawer/Mentions/Mentions";

const ResponsiveGridLayout = WidthProvider(Responsive);

const DASHBOARD_CARDS = {
  projectDetails: ProjectDetails,
  mostRecentDraw: MostRecentDraw,
  drawHistory: DrawHistory,
  budget: Budget,
  fundingSources: FundingSources,
  timeline: Timeline,
  scheduleRisk: ScheduleRisk,
  contingencyRisk: ContingencyRisk,
  contingencyUsage: ContingencyUsage,
};

// i - card name, x - horizontal position, y - vertical position, h - height, w - width, disabled - is card visible
const DEFAULT_CARD_CONFIGURATION = [
  BUDGET_CONFIGURATION_SETTINGS,
  CONTINGENCY_RISK_CONFIGURATION_SETTINGS,
  CONTINGENCY_USAGE_CONFIGURATION_SETTINGS,
  DRAW_HISTORY_CONFIGURATION_SETTINGS,
  FUNDING_SOURCES_CONFIGURATION_SETTINGS,
  MOST_RECENT_DRAW_CONFIGURATION_SETTINGS,
  PROJECT_DETAILS_CONFIGURATION_SETTINGS,
  SCHEDULE_RISK_CONFIGURATION_SETTINGS,
  TIMELINE_CONFIGURATION_SETTINGS,
];

function getTodoCount(drawApprovals, mentions, uniqueDocuments) {
  const toDos = mentions.concat(uniqueDocuments, drawApprovals);

  return toDos.length;
}

export function ProjectDashboard({ history, match }) {
  const { documentId, projectId } = match.params;
  const { hasPermission, userId } = useContext(UserContext);

  const { data, loading } = useQuery(PROJECT_DASHBOARD_QUERY, {
    variables: { projectId, userId },
  });

  const theme = useContext(ThemeContext);
  const hasTaskManagement = hasPermission(PERMISSION_ACTION.TASK_MANAGEMENT);

  const projectDashboardConfigurations =
    data?.project?.userProjectDashboardConfigurations || [];
  const originalCardConfiguration =
    projectDashboardConfigurations.length > 0
      ? projectDashboardConfigurations
      : DEFAULT_CARD_CONFIGURATION;

  const availableCards = hasTaskManagement
    ? originalCardConfiguration
    : originalCardConfiguration.filter((card) => card.i !== "timeline");

  const [isConfigurable, setIsConfigurable] = useState(false);
  const [cards, setCards] = useState([]);
  const [previousConfig, setPreviousConfig] = useState([]);
  const [collaborationDrawerIsOpen, setCollaborationDrawerIsOpen] = useState(
    false
  );

  const toggleCollaborationDrawer = () => {
    setCollaborationDrawerIsOpen((prevState) => !prevState);
  };

  const handleResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1100) {
      setCards(previousConfig);
    }
  }, [previousConfig]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useDeepCompareEffect(() => {
    setCards(availableCards);
    setPreviousConfig(availableCards);
  }, [loading, availableCards]);

  const [
    saveProjectDashboardConfigurations,
    { loading: saveProjectDashboardConfigurationsLoading },
  ] = useMutation(SAVE_PROJECT_DASHBOARD_CONFIGURATIONS);

  const handleLayoutChange = (layout) => {
    const updatedLayout = layout.map((cardLayout) => {
      const { i, x, y, h, w } = cardLayout;
      const isDisabled = cards.find((card) => card.i === i).disabled;

      if (i === "timeline") {
        return {
          i,
          x,
          y,
          h,
          w: 2,
          disabled: isDisabled,
        };
      }

      return {
        i,
        x,
        y,
        h,
        w,
        disabled: isDisabled,
      };
    });

    setCards(updatedLayout);
  };

  const handleSave = () => {
    const cardsToSave = hasTaskManagement
      ? cards
      : [...cards, { i: "timeline", x: 0, y: 3, w: 2, h: 1, disabled: false }];

    const formattedCards = cardsToSave.map((card) =>
      card.i === "timeline" ? { ...card, w: 2 } : card
    );

    setPreviousConfig(formattedCards);

    return saveProjectDashboardConfigurations({
      variables: {
        userId,
        projectId,
        projectDashboardConfigurations: formattedCards,
      },
    });
  };

  if (loading || saveProjectDashboardConfigurationsLoading)
    return <Loadable loading />;

  const mentions = getMentions(data.project);
  const uniqueDocuments = getUniqueDocuments(data.project);
  const drawApprovals = get(data.project, "pendingDrawApprovals", []);

  const toDoCount = getTodoCount(drawApprovals, mentions, uniqueDocuments);

  return (
    <Fragment>
      <Fragment>
        <Prompt
          when={isConfigurable}
          message={`{"confirmText": "Continue without saving", "messageText": "Continue without saving changes?"}`}
        />
        <ProjectSnapshots project={data.project} />
        <Pane
          width="100%"
          backgroundColor={theme.colors.backgroundGray}
          height="auto"
          display="flex"
          justifyContent="center"
        >
          <Pane
            width="80%"
            maxWidth={1200}
            paddingBottom={majorScale(2)}
            paddingLeft={majorScale(2)}
            paddingRight={majorScale(2)}
          >
            <Pane
              backgroundColor={theme.colors.backgroundGray}
              display="flex"
              justifyContent="flex-end"
              paddingRight={majorScale(2)}
              paddingTop={majorScale(2)}
            >
              {isConfigurable ? (
                <Pane display="flex">
                  <Button
                    onClick={() => {
                      setIsConfigurable(false);
                      setCards(previousConfig);
                    }}
                    backgroundColor="white"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleSave();
                      setIsConfigurable(false);
                    }}
                    marginLeft={majorScale(1)}
                    appearance="primary"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                <Button
                  appearance="primary"
                  onClick={() => {
                    setIsConfigurable(true);
                  }}
                >
                  Customize
                </Button>
              )}
            </Pane>
            <ResponsiveGridLayout
              className="layout"
              layouts={{ lg: cards }}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 }}
              rowHeight={300}
              width={400}
              compactType="vertical"
              margin={[16, 16]}
              isResizable={false}
              isDraggable={isConfigurable}
              onLayoutChange={handleLayoutChange}
            >
              {cards.map((card) => {
                const DashboardCard = DASHBOARD_CARDS[card.i];
                if (
                  (card.disabled && !isConfigurable) ||
                  (card.i === "timeline" &&
                    !hasPermission(PERMISSION_ACTION.TASK_MANAGEMENT))
                ) {
                  return (
                    <Pane
                      backgroundColor={theme.colors.backgroundGray}
                      key={card.i}
                      height={300}
                      marginY={4}
                      marginX="auto"
                      width="47%"
                      minWidth={400}
                      borderRadius={majorScale(2)}
                      isDraggable={false}
                    />
                  );
                }
                return (
                  <Pane
                    backgroundColor={
                      card.disabled ? theme.colors.gray75 : "white"
                    }
                    key={card.i}
                    height={300}
                    marginY={4}
                    marginX="auto"
                    width={card.i === "timeline" ? "100%" : "47%"}
                    minWidth={400}
                    borderRadius={majorScale(2)}
                  >
                    <DashboardCard
                      project={data.project}
                      history={history}
                      isConfigurable={isConfigurable}
                      isDisabled={card.disabled}
                      name={card.i}
                      cards={cards}
                      setCards={setCards}
                    />
                  </Pane>
                );
              })}
            </ResponsiveGridLayout>
          </Pane>
          {toDoCount !== 0 && (
            <Pane position="fixed" top="49%" right={20} zIndex={10}>
              <Pill color="red">{toDoCount}</Pill>
            </Pane>
          )}
          <Pane
            position="fixed"
            top="55%"
            right="-39px"
            transform="rotate(-90deg)"
          >
            <Button
              appearance="primary"
              onClick={toggleCollaborationDrawer}
              purpose="Collaboration"
            >
              Collaboration
            </Button>
          </Pane>
        </Pane>
        <CollaborationDrawer
          isOpen={collaborationDrawerIsOpen}
          toggleClose={toggleCollaborationDrawer}
          project={data.project}
        />
      </Fragment>
      {documentId && (
        <Document
          documents={data.project.assignedDocuments}
          history={history}
          match={match}
          refetch={[{ query: PROJECT_DASHBOARD_QUERY }]}
        />
      )}
    </Fragment>
  );
}
