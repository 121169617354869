import { useEffect } from "react";
import PropTypes from "prop-types";
import { EditProjectSettingsButtons, RulesForm } from "components/templates";
import { Accordion } from "components/materials";
import { reject } from "lodash";
import {
  defaultRuleConfiguration,
  getRuleConfig,
  getConfigValue,
} from "helpers/drawRules";
import isBlank from "helpers/isBlank";
import { PROJECT_SETUP_STEP } from "helpers/enums";

const EditRulesPanel = ({
  completeSetupStep,
  dirty,
  form,
  loading,
  onToggle,
  open,
  panelKey,
  setPanelDirty,
  ...props
}) => {
  useEffect(() => {
    if (open) {
      completeSetupStep(PROJECT_SETUP_STEP.REVIEW_RULES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(panelKey, isDirty);
    }
    return undefined;
  }, [dirty, form, panelKey, setPanelDirty]);

  return (
    <Accordion.Panel
      panelKey={panelKey}
      title="Rules"
      onClick={() => onToggle(panelKey)}
      open={open}
      actionContent={
        <EditProjectSettingsButtons
          dirty={dirty}
          form={form}
          loading={loading}
        />
      }
      {...props}
    >
      <RulesForm values={form.values} />
    </Accordion.Panel>
  );
};

// TODO: remove dot notation
EditRulesPanel.initialValues = (rules, customRules) => {
  const rulesWithConfig = rules.map((rule) => {
    const defaultConfig = defaultRuleConfiguration(rule.name);

    return {
      ...rule,
      config: getRuleConfig(defaultConfig, rule.config),
    };
  });

  return { rules: rulesWithConfig, customRules };
};

// TODO: remove dot notation
EditRulesPanel.getRulesValues = (values) => {
  return reject([...values.rules, ...values.customRules], ({ name }) =>
    isBlank(name)
  ).map(({ id, name, enabled, type, config }) => {
    return { id, name, enabled, type, config: getConfigValue(config) };
  });
};

EditRulesPanel.propTypes = {
  contentStyles: PropTypes.object,
  dirty: PropTypes.bool,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    resetForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  panelKey: PropTypes.string.isRequired,
  panelStyles: PropTypes.object,
  setPanelDirty: PropTypes.func,
  titleStyles: PropTypes.object,
};

export default EditRulesPanel;
