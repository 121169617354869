import i18next from "i18next";
import { camelCase } from "lodash";

export const INTERPOLATION_PLACEHOLDER = "<?>";

const translate = (name, options) => {
  const key = name.split(".").map(camelCase).join(".");

  return i18next.t(key, options);
};

export default translate;
