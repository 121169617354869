import { map } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";
import { add, subtract, sumBy } from "helpers/math";
import unformatNumber from "helpers/unformatNumber";

export function formatAgreementAmounts(amounts, vendorId) {
  const mapped = map(amounts, ({ amount }, agreementId) => ({
    agreementId,
    amount,
    vendorId,
  }));
  return mapped.filter(({ amount }) => unformatNumber(amount) !== 0);
}

export function mapAgreementAmounts(amounts) {
  return amounts.reduce(
    (
      acc,
      { id: agreementId, amount: originalAmount, spentToDate, spentForDocument }
    ) => {
      const amountAvailable = add(
        subtract(originalAmount, spentToDate),
        spentForDocument
      );
      return {
        ...acc,
        [agreementId]: {
          amount: formatCurrency(spentForDocument),
          maxAmountToAllocate: amountAvailable,
          originalAmount,
        },
      };
    },
    {}
  );
}

export function invoiceAmountFullyNotAllocatedToAgreements(
  lineItems,
  agreementAmounts,
  currentPaymentDue
) {
  const invoiceAmount =
    sumBy(lineItems, "amount") ||
    sumBy(lineItems, "applicationAmount") ||
    unformatNumber(currentPaymentDue);
  const amountTrackedByAgreements = sumBy(agreementAmounts, "amount");
  return invoiceAmount !== amountTrackedByAgreements;
}

export function lineItemsNotCoveredByAgreements(lineItems, agreements) {
  const lineItemIds = lineItems.reduce((acc, { lineItemObject }) => {
    if (lineItemObject.id) {
      return [...acc, lineItemObject.id];
    }
    return acc;
  }, []);
  const agreementLineItemIds = agreements.reduce(
    (acc, { lineItems }) => [
      ...acc,
      ...lineItems.map(({ lineItemId }) => lineItemId),
    ],
    []
  );
  return lineItemIds.some(
    (lineItemId) => !agreementLineItemIds.find((id) => id === lineItemId)
  );
}
