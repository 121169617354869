import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Heading } from "components/materials";
import { get } from "lodash";
import { formatDate } from "helpers/dateHelpers";

class GuestProjectHeader extends PureComponent {
  render() {
    const { draw, user } = this.props;

    return (
      <Heading>
        {draw.project.name}
        {draw && ` - ${draw.name}`}
        <Heading.SubHeading content={draw.project.streetAddress} />
        <Heading.SubHeading>
          {draw.project.owner.name}
          {user && user.fullName && ` - ${get(user, "fullName", "")}`}
          {draw &&
            draw.submittedDate &&
            ` - ${formatDate(get(draw, "submittedDate", ""))}`}
        </Heading.SubHeading>
      </Heading>
    );
  }
}

GuestProjectHeader.propTypes = {
  draw: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      streetAddress: PropTypes.string,
      owner: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

export default GuestProjectHeader;
