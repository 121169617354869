import { useContext } from "react";
import { ChevronRightIcon } from "evergreen-ui";
import { ContentLayout } from "components/templates";
import {
  Divider,
  DownloadLink,
  Heading,
  Link,
  Pane,
  Paragraph,
  Text,
} from "components/materials";
import analytics from "helpers/analytics";
import t from "helpers/translate";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { getProjectIcon } from "helpers/projectIconHelpers";
import { importPreview } from "images";
import { SubmissionPreviewTable } from "./SubmissionPreviewTable";
import { useAuth } from "../../../Auth";

const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;
const SubmissionPreview = ({ submission }) => {
  const { hasDrawSummary } = submission;
  const { project } = submission.sourceDraw;
  const { icon } = project.template;
  const theme = useContext(ThemeContext);
  const {
    defaultColors: { dividerColor },
  } = theme;
  const to = `/draw_submissions/${submission.id}/import`;
  const auth = useAuth();

  const accessToken = auth.getAccessToken();

  const primaryAction = {
    to,
    content: t("guest.view", { name: submission.sourceDraw.name }),
  };

  const renderContent = () => (
    <Pane>
      <SubmissionPreviewTable
        draw={submission.sourceDraw}
        actionContent={
          <Pane>
            <Pane
              marginTop={majorScale(1)}
              display="flex"
              justifyContent="space-between"
            >
              <Pane>
                <Pane display="flex">
                  <Pane marginRight={majorScale(1)}>
                    {/* TODO: set standard color "#425a70" and maybe set icon to this color */}
                    {icon && getProjectIcon(icon, {})}
                  </Pane>
                  <Heading fontSize={20} fontWeight={theme.fontWeights.MEDIUM}>
                    {project.name}
                  </Heading>
                </Pane>

                <Text opacity="53%" fontWeight={theme.fontWeights.REGULAR}>
                  {project.owner.name}
                </Text>
              </Pane>
              <Pane alignSelf="center">
                <Heading fontSize={20} fontWeight={theme.fontWeights.MEDIUM}>
                  {submission.sourceDraw.name}
                </Heading>
              </Pane>
            </Pane>

            <Divider marginY={majorScale(2)} color={dividerColor} height={1} />

            <Pane
              display="flex"
              justifyContent="space-evenly"
              marginLeft={majorScale(4)}
            >
              <img height={166} alt="Import Preview" src={importPreview} />
              <Pane marginLeft={majorScale(4)} marginRight={majorScale(4)}>
                <Paragraph
                  fontWeight={theme.fontWeights.DEMI}
                  color="#05407f"
                  marginBottom={majorScale(3)}
                >
                  {t("guest.view", {
                    name: submission.sourceDraw.name.toUpperCase(),
                  })}
                </Paragraph>

                <Pane marginBottom={minorScale(1)}>
                  <DownloadLink
                    fontSize={12}
                    url={submission.export.url}
                    filename={`${submission.sourceDraw.name}_documents.zip`}
                    purpose="download documents zip"
                    iconName="folder-close"
                    label="Download Documents (.zip)"
                  />
                </Pane>
                <Pane marginBottom={minorScale(1)}>
                  <DownloadLink
                    fontSize={12}
                    url={`${baseUrl}/download_draw_package/import/${submission.id}`.concat(
                      accessToken ? `?access_token=${accessToken}` : ""
                    )}
                    filename={`${submission.sourceDraw.name}.pdf`}
                    purpose="download documents pdf"
                    iconName="document"
                    label="Download Package (.pdf)"
                    onSuccess={() => {
                      analytics.track(
                        "Draw Package Download - via Import Page",
                        {
                          projectId: submission.sourceDraw.project.id,
                          drawId: submission.sourceDraw.id,
                        }
                      );
                    }}
                  />
                </Pane>
                {hasDrawSummary && (
                  <Pane>
                    <DownloadLink
                      fontSize={12}
                      url={`${baseUrl}/download_submission_draw_summary/${submission.id}`}
                      filename={`${submission.sourceDraw.name}_draw_summary.xlsx`}
                      purpose="download draw-summary"
                      iconName="panel-table"
                      label="Download Draw Summary (.xlsx)"
                    />
                  </Pane>
                )}
              </Pane>
            </Pane>

            <Pane
              marginY={majorScale(2)}
              background="#f1f8ff"
              fontWeight={theme.fontWeights.DEMI}
              paddingY={majorScale(1)}
              paddingX={majorScale(2)}
              textAlign="center"
            >
              <Link
                purpose="submission lender cta"
                href="https://rabbet.com/construction-lenders/"
              >
                <Text
                  color="#05407f"
                  fontWeight={theme.fontWeights.DEMI}
                  fontSize={12}
                >
                  Are you a lender? Learn more about how Rabbet can help track
                  and manage your projects.
                </Text>
                {/* TODO: Move color into themes */}
                <ChevronRightIcon
                  marginLeft={majorScale(1)}
                  size={12}
                  color="#05407f"
                />
              </Link>
            </Pane>
          </Pane>
        }
      />
    </Pane>
  );

  return (
    <ContentLayout
      bleedContent
      primaryAction={primaryAction}
      renderContent={renderContent}
    />
  );
};

export default SubmissionPreview;
