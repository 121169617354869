export function getNumberFormat(type) {
  if (type === "percent") return "0.00%";
  if (type === "currency") return "$#,##0.00;[Red]-$#,##0.00";
  return 0;
}

export const headerFont = {
  bold: true,
  size: 12,
  color: { argb: "FFFFFFFF" },
};

export const headerFill = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FF001f40" },
};

export const headerAlignment = {
  horizontal: "left",
  vertical: "top",
  wrapText: true,
};

export const totalsFont = { bold: true };

export const totalsFill = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FFE2E9F0" },
};
