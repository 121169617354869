import { useState, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { VendorFormPartial } from "components/templates";
import { Divider, Form, Pane, Text, Tooltip } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";

const NewDrawForm = ({
  formikProps,
  getProjectVendorSearchQuery,
  isDeveloper,
  mutationResultProps,
  projectId,
  searchedVendors,
  showDrawLockdownMessage,
  vendors,
}) => {
  const [newlyAddedVendors, setNewlyAddedVendors] = useState([]);
  const {
    defaultColors: { dividerColor },
  } = useContext(ThemeContext);
  return (
    <Form.Generic {...formikProps} {...mutationResultProps}>
      <Form.Input name="name" label="Draw Name" />
      {isDeveloper ? (
        <Form.DateInput
          label="Submission Date"
          marginY={majorScale(2)}
          name="expectedSendDate"
        />
      ) : (
        <Fragment>
          <Form.DateInput
            label="Submission Date"
            marginY={majorScale(2)}
            name="submittedDate"
          />
          <VendorFormPartial
            formikProps={formikProps}
            getProjectVendorSearchQuery={getProjectVendorSearchQuery}
            initialVendors={vendors}
            label="Submitter"
            newlyAddedVendors={newlyAddedVendors}
            projectId={projectId}
            searchedVendors={searchedVendors}
            setNewlyAddedVendors={setNewlyAddedVendors}
            vendorObjectFieldName="submitter"
          />
        </Fragment>
      )}
      {showDrawLockdownMessage && (
        <Pane marginY={majorScale(3)}>
          <Text>{t("drawLockdown.newDraw")}</Text>
          <Divider color={dividerColor} height={1} marginTop={majorScale(3)} />
        </Pane>
      )}
      {formikProps.values.fundDraws.map((fundDraw, index) => {
        const drawState = t(`drawStates.${fundDraw.state}}`);

        const Checkbox = (
          <Form.Checkbox
            name={`fundDraws.${index}.checked`}
            label={`Mark ${fundDraw.name} Funded On:`}
            disabled={fundDraw.disabled}
          />
        );

        return (
          <Pane key={fundDraw.id}>
            {fundDraw.disabled ? (
              <Tooltip
                content={t("drawLockdown.fundDrawDisabled", { drawState })}
              >
                {Checkbox}
              </Tooltip>
            ) : (
              Checkbox
            )}
            <Form.DateInput
              name={`fundDraws.${index}.date`}
              disabled={fundDraw.disabled}
            />
          </Pane>
        );
      })}
    </Form.Generic>
  );
};

NewDrawForm.propTypes = {
  formikProps: PropTypes.object.isRequired,
  isDeveloper: PropTypes.bool.isRequired,
  mutationResultProps: PropTypes.object.isRequired,
  vendors: PropTypes.array.isRequired,
};

export default NewDrawForm;
