import { Fragment } from "react";
import { Pane, Table, Text } from "components/materials";
import { AddIcon, EditIcon, EyeOffIcon } from "evergreen-ui";
import { majorScale } from "helpers/utilities";
import { groupBy, isNull, map, trim, uniqBy } from "lodash";
import isBlank from "helpers/isBlank";
import { DrawSummarySelect } from "./DrawSummarySelect";
import { DrawSummaryTableCell } from "./DrawSummaryTableCell";
import {
  DrawSummaryTableTotalRow,
  getParsedLineItemValues,
} from "./DrawSummaryTableTotalRow";
import { AddLineItem } from "../../AddLineItem";

export function DrawSummaryTableBody({
  createLineItem,
  form,
  lockdownDraw,
  markRemainingAsNotIncluded,
  onLineItemRowChange,
  projectId,
  sheetData,
  theme,
}) {
  const {
    lineItemColumn,
    adjustmentsColumn,
    retainageColumn,
    requestedColumn,
    lineItems,
  } = form.values;

  const groupedLineItems = groupBy(lineItems, "division.id");
  const divisions = uniqBy(
    lineItems.map(({ division }) => division),
    "id"
  );

  return (
    <Table.Body>
      {map(groupedLineItems, (divisionLineItems, divisionId) => {
        const divisionName = divisions.find(({ id }) => id === divisionId)
          ?.name;
        return (
          <Fragment key={divisionId}>
            <Table.Row>
              <Table.TextSectionHeaderCell
                colSpan={1}
                textProps={{ size: 300 }}
              >
                {divisionName}
              </Table.TextSectionHeaderCell>
              <Table.TextSectionHeaderCell colSpan={4} paddingX={0}>
                {!lockdownDraw && (
                  <AddLineItem
                    onAddLineItem={({
                      lineItemId,
                      lineItemName,
                      lineItemNumber,
                      divisionId,
                      summaryLineItem,
                      masterFormatDivision,
                    }) => {
                      createLineItem({
                        variables: {
                          projectId,
                          id: lineItemId,
                          divisionId,
                          name: lineItemName.trim(),
                          number: lineItemNumber.trim() || null,
                          superLineItem: summaryLineItem.trim() || null,
                          masterFormatDivision,
                        },
                      });
                    }}
                    divisions={divisions}
                    lineItems={lineItems}
                    initialValues={{ divisionId }}
                    showOriginalBudgetAlert
                    renderTrigger={(onClick) => (
                      <Pane
                        display="flex"
                        alignItems="center"
                        cursor="pointer"
                        onClick={onClick}
                      >
                        <AddIcon
                          color={theme.colors.blue600}
                          marginRight={majorScale(1)}
                          size={12}
                        />
                        <Text color={theme.colors.blue600} size={300}>
                          Create a New Line Item
                        </Text>
                      </Pane>
                    )}
                  />
                )}
              </Table.TextSectionHeaderCell>
            </Table.Row>
            {divisionLineItems.map((lineItem) => {
              const { id, name, formIndex, rowIndex, overrides } = lineItem;
              const {
                adjustmentsAmount,
                requestedAmount,
                retainageAmount,
              } = getParsedLineItemValues(sheetData, rowIndex, form.values);

              const lineItemHasOverride = Object.values(overrides).some(
                (value) => !isBlank(value)
              );

              const isIgnored = rowIndex === "none";

              function onManualEntry(event) {
                if (!isBlank(event.target.value) && isBlank(rowIndex)) {
                  form.setFieldValue(
                    `lineItems.${formIndex}.rowIndex`,
                    "manual"
                  );
                }
              }

              // key needs to be unique so when any row changes which column it is getting its data from,
              // it updates the ui accordingly
              const tableRowKey = `${id}-${form.values.sheetName}-${lineItemColumn}${adjustmentsColumn}${requestedColumn}${retainageColumn}`;

              return (
                <Table.Row key={tableRowKey}>
                  <Table.TextCell
                    paddingX={majorScale(2)}
                    maxWidth={150}
                    testId={`lineItems.${formIndex}.name`}
                  >
                    {name}
                  </Table.TextCell>
                  <Table.TextCell padding={0}>
                    <DrawSummarySelect
                      value={rowIndex}
                      options={
                        isNull(lineItemColumn)
                          ? getStaticRowOptions()
                          : getRowOptions(sheetData, lineItemColumn)
                      }
                      onChange={(rowSelection) =>
                        onLineItemRowChange(
                          formIndex,
                          rowSelection,
                          lineItemHasOverride
                        )
                      }
                      disabled={lockdownDraw}
                      isWarned={!isNull(lineItemColumn) && isNull(rowIndex)}
                      confirmSelect={lineItemHasOverride}
                      comboboxProps={{
                        maxWidth: 175,
                        marginBottom: 8,
                      }}
                      theme={theme}
                      testId={`lineItems.${formIndex}.rowIndex`}
                    />
                  </Table.TextCell>
                  <DrawSummaryTableCell
                    parsedAmount={adjustmentsAmount}
                    override={overrides.adjustmentsAmount}
                    path={`lineItems.${formIndex}.overrides.adjustmentsAmount`}
                    testId={`lineItems.${formIndex}.adjustmentsAmount`}
                    onManualEntry={onManualEntry}
                    disabled={lockdownDraw}
                    isIgnored={isIgnored}
                    setFieldValue={form.setFieldValue}
                  />
                  <DrawSummaryTableCell
                    parsedAmount={requestedAmount}
                    override={overrides.requestedAmount}
                    path={`lineItems.${formIndex}.overrides.requestedAmount`}
                    testId={`lineItems.${formIndex}.requestedAmount`}
                    onManualEntry={onManualEntry}
                    disabled={lockdownDraw}
                    isIgnored={isIgnored}
                    setFieldValue={form.setFieldValue}
                  />
                  {form.values.retainageMethod !== "noRetainage" && (
                    <DrawSummaryTableCell
                      parsedAmount={retainageAmount}
                      override={overrides.retainageAmount}
                      path={`lineItems.${formIndex}.overrides.retainageAmount`}
                      testId={`lineItems.${formIndex}.retainageAmount`}
                      onManualEntry={onManualEntry}
                      disabled={lockdownDraw}
                      isIgnored={isIgnored}
                      setFieldValue={form.setFieldValue}
                    />
                  )}
                </Table.Row>
              );
            })}
            <DrawSummaryTableTotalRow
              data={sheetData}
              lineItems={divisionLineItems}
              formValues={form.values}
              label={`${divisionName} Subtotal`}
            />
          </Fragment>
        );
      })}
      <DrawSummaryTableTotalRow
        data={sheetData}
        lineItems={lineItems}
        formValues={form.values}
        label="Total"
      />
      {!lockdownDraw && (
        <Table.Row>
          <Table.Cell />
          <Table.Cell colSpan={4} paddingX={0}>
            <Pane
              display="flex"
              alignItems="center"
              cursor="pointer"
              onClick={markRemainingAsNotIncluded}
            >
              <EyeOffIcon marginRight={majorScale(1)} size={12} />
              <Text size={300}>Mark Remaining as Not Included</Text>
            </Pane>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
}

function getStaticRowOptions() {
  return [
    {
      key: "none",
      value: "none",
      text: "Not Included",
      icon: <EyeOffIcon color="default" />,
    },
    {
      key: "manual",
      value: "manual",
      text: "Manually Added",
      icon: <EditIcon color="default" />,
    },
  ];
}

function getRowOptions(sheetData, lineItemColumn) {
  return getStaticRowOptions().concat(
    sheetData.map((row, index) => ({
      key: `row-${index}`,
      value: index,
      text: `${index + 1} - ${trim(row[lineItemColumn])}`,
    }))
  );
}
