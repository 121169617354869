import { find, sortBy } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";
import { subtract } from "helpers/math";

// Combine the same line items into one
// Also cast the selected value (lineItemObject) to descriptionOfWork
export const formatLineItems = (lineItems) => {
  if (!lineItems) return undefined;

  return lineItems.map((lineItem) => {
    return {
      descriptionOfWork: lineItem.lineItemObject.descriptionOfWork,
      applicationAmount: lineItem.applicationAmount,
      materialsStoredAmount: lineItem.materialsStoredAmount,
      retainageAmount: lineItem.retainageAmount,
      retainageToDateAmount: lineItem.retainageToDateAmount,
      jobCostCodes: lineItem.jobCostCodes,
    };
  });
};

// Line items are idenified by name and not id
// We use "lineItemName (divisionName)" to prevent name collisions
export function getFullLineItemName(
  { division, name },
  excludeDivision = false
) {
  return excludeDivision
    ? name
    : `${name} ${division.name ? `(${division.name})` : ""}`;
}

export function getLineItemDisplayName(
  { division, name, number },
  excludeDivision = false
) {
  const prefix = number ? `(${number}) ` : "";
  return excludeDivision
    ? `${prefix}${name}`
    : `${prefix}${name} ${division.name ? `(${division.name})` : ""}`;
}

export const getDescription = ({
  grossDocumentedRequestedAmount,
  grossRequestedAmount,
}) => {
  const diff = subtract(grossRequestedAmount, grossDocumentedRequestedAmount);
  if (diff > 0) return `${formatCurrency(diff)} unreconciled`;
  if (diff < 0) return `${formatCurrency(-diff)} over-documented`;
  return null;
};

// Select options
// searchValue: the value used when typing into the select box
// value: the value saved in formik
// text: how an option appears
export const getLineItemOptions = (lineItems, excludeDivision = false) => {
  const formattedLineItems = sortBy(
    lineItems,
    (lineItem) =>
      -Math.abs(
        subtract(
          lineItem.grossDocumentedRequestedAmount,
          lineItem.grossRequestedAmount
        )
      )
  ).map((lineItem) => {
    return {
      description: getDescription(lineItem),
      key: lineItem.id,
      text: getLineItemDisplayName(lineItem, excludeDivision),
      value: {
        descriptionOfWork: getFullLineItemName(lineItem),
        hardCosts: lineItem.hardCosts,
        id: lineItem.id,
      },
    };
  });
  return formattedLineItems;
};

export const getMatch = (drawLineItems, lineItem) => {
  const items = drawLineItems.map((drawLineItem) => ({
    ...drawLineItem,
    fullName: getFullLineItemName(drawLineItem),
  }));
  return find(items, (item) => {
    return item.id === lineItem.lineItemId;
  });
};
