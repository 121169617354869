// polyfill IE support for unicode in regular expressions for a dependency of exceljs
// https://github.com/exceljs/exceljs/issues/1213
import rewritePattern from "regexpu-core";
import { generateRegexpuOptions } from "@babel/helper-create-regexp-features-plugin/lib/util";

const { RegExp } = global;
try {
  // eslint-disable-next-line no-new
  new RegExp("a", "u");
} catch (err) {
  // eslint-disable-next-line func-names
  global.RegExp = function (pattern, flags) {
    if (flags && flags.includes("u")) {
      return new RegExp(
        rewritePattern(
          pattern,
          flags,
          generateRegexpuOptions({ flags, pattern })
        )
      );
    }
    return new RegExp(pattern, flags);
  };
  global.RegExp.prototype = RegExp.prototype;
}
