import { useState, useCallback } from "react";
import { Formik } from "formik";
import { Form, Grid, Pane } from "components/materials";
import { get, xor } from "lodash";
import getOptions from "helpers/getOptions";
import { majorScale } from "helpers/utilities";
import { InvoiceReview } from "components/templates/DocumentReview/InvoiceReview";
import {
  initialValues,
  previewValues,
  submitValues,
  validate,
} from "./formHelpers";
import Preview from "./Preview";
import NoBudgetAlert from "./NoBudgetAlert";

export function CreateInvoice({
  organizationId = null,
  projectId,
  drawId = null,
  loading,
  templates,
  lineItems,
  onCancel,
  onPreview,
  onCreate,
  createResult,
  previewResult,
}) {
  const [collapsedPanels, setCollapsedPanels] = useState([]);

  const togglePanel = (panelName) =>
    setCollapsedPanels(xor(collapsedPanels, [panelName]));

  const handlePreview = useCallback(
    (values) => {
      onPreview(previewValues(values, lineItems));
    },
    [onPreview, lineItems]
  );

  const handleCreate = useCallback(
    (values) => {
      onCreate(submitValues(values, lineItems));
    },
    [onCreate, lineItems]
  );

  if (lineItems.length === 0)
    return <NoBudgetAlert drawId={drawId} projectId={projectId} />;

  const previewDocument = get(previewResult, "data.createFileFromTemplate");

  return (
    <Formik
      initialValues={initialValues(
        projectId,
        drawId,
        get(templates, "0.id"),
        organizationId
      )}
      onSubmit={handleCreate}
      validate={validate}
      validateOnMount
    >
      {(form) => (
        <Form.Generic
          textSubmit="Create"
          handleSubmit={form.handleSubmit}
          handleCancel={onCancel}
          cancelLabel="Cancel"
          loading={loading}
          error={createResult.error}
          submitButtonProps={{
            marginRight: majorScale(2),
          }}
          buttonGroupProps={{ marginY: majorScale(2) }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column columns={8} padding={0}>
                <InvoiceReview
                  collapsedPanels={collapsedPanels}
                  disableActions
                  disableActivity
                  disableComments
                  disableDescription
                  disableHeader
                  disableTrackCostToAgreements
                  disableWarnings
                  disableJobCostCodes
                  document={{}}
                  form={form}
                  information={() => (
                    <Pane display="flex">
                      <Pane flexGrow={1} marginRight={majorScale(2)}>
                        <Form.Input name="number" label="Invoice Number" />
                      </Pane>

                      <Pane flexGrow={1}>
                        <Form.DateInput name="date" label="Invoice Date" />
                      </Pane>
                    </Pane>
                  )}
                  meta={() => (
                    <Form.Select
                      width={250}
                      name="templateId"
                      options={getOptions(templates)}
                    />
                  )}
                  selectedDraw={{ lineItems }}
                  lineItemLimit={10}
                  togglePanel={togglePanel}
                />
              </Grid.Column>
              <Grid.Column columns={8} padding={0}>
                <Preview
                  isValid={form.isValid}
                  loading={loading}
                  onSubmit={async () => handlePreview(form.values)}
                  document={previewDocument}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Generic>
      )}
    </Formik>
  );
}

export default CreateInvoice;
