import { useQuery } from "@apollo/react-hooks";
import { BorrowerPortfolioDashboard } from "components/templates";
import { Loadable } from "components/materials";
import { get } from "lodash";
import { BORROWER_PORTFOLIO_QUERY } from "./graphql";

export function BorrowerPortfolioPage() {
  const { data, loading } = useQuery(BORROWER_PORTFOLIO_QUERY);

  if (loading) return <Loadable />;
  const projects = get(data, "borrower.projects", []);

  return <BorrowerPortfolioDashboard projects={projects} />;
}
