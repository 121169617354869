import { useContext, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AddIcon, CrossIcon, InfoSignIcon } from "evergreen-ui";
import { JobCostCodeModal } from "components/containers";
import { VendorLink } from "components/templates";
import { Form, IconButton, Pane, Table, Tooltip } from "components/materials";
import { DocumentContext } from "contexts/documentContext";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import {
  DOCUMENT_IMPORT_SOURCE,
  DOCUMENT_TYPE_NAME,
  PERMISSION_ACTION,
} from "helpers/enums";
import { dateFormToServer, formatDate } from "helpers/dateHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import isBlank from "helpers/isBlank";
import { DRAW_RULES } from "helpers/drawRules";
import { UserContext } from "helpers/behaviors";
import {
  formatLineItems,
  getLineItemOptionsWithSuggestions,
  getMatch,
} from "helpers/invoiceLineItemHelpers";
import {
  invoiceAmountFullyNotAllocatedToAgreements,
  lineItemsNotCoveredByAgreements,
  mapAgreementAmounts,
} from "helpers/agreementAmountHelpers";
import {
  autoCalculateLineItemRetainage,
  calculateLineItemRetainageToDate,
  getExpectedRetainage,
  getPreviousRetainage,
  getRetainageThisDraw,
  setLineItemRetainage,
} from "helpers/retainageLineItemHelpers";
import {
  getLineItemsWithInsufficientFunds,
  removeDismissedWarnings,
} from "helpers/documentHelpers";
import humanReadableList from "helpers/humanReadableList";
import formatPercent from "helpers/formatPercent";
import { add, subtract, sumBy } from "helpers/math";
import t from "helpers/translate";
import unformatNumber from "helpers/unformatNumber";
import { every, find, get, sortBy, omit } from "lodash";
import { v4 as uuid } from "uuid";
import { useDebounceDeep } from "hooks/useDebounce";
import { AgreementDetailsForVendor } from "./AgreementDetailsForVendor";
import { CalculationTooltip } from "./CalculationTooltip";
import DocumentReviewLayout from "./DocumentReviewLayout";
import DocumentLineItems from "./DocumentLineItems";
import {
  TrackCostToAgreements,
  shouldShowTrackCostToAgreementsSection,
  shouldShowTrackCostToAgreementsWarnings,
} from "./TrackCostToAgreements";
import { ExpectedRetainageTooltip } from "./ExpectedRetainageTooltip";
import IsBackupCheckbox from "./IsBackupCheckbox";
import { getAutofilledFields } from "./AutofilledFieldsBadge";

const DOCUMENT_SUGGESTIONS_QUERY = gql`
  query DocumentSuggestionsQuery(
    $data: String!
    $drawId: String
    $projectId: String!
    $type: DocumentTypeName!
    $vendorId: String
  ) {
    project(id: $projectId) {
      id
      lineItemSuggestions(
        data: $data
        drawId: $drawId
        vendorId: $vendorId
        type: $type
      ) {
        id
        lineItem {
          id
          scopeId
          name
        }
        reason
      }
    }
  }
`;

const getBlankCodeOption = () => ({ key: uuid(), value: null, text: "" });

const getCostCodeOptions = (jobCostCodes) => {
  const sortedCodes = sortBy(jobCostCodes, ({ code }) => code.toLowerCase());
  return [getBlankCodeOption()].concat(
    sortedCodes.map(({ code, description, id }) => ({
      key: id,
      text: description ? `${code}: ${description}` : code,
      value: id,
    }))
  );
};

const matchLineItem = (
  drawLineItems,
  selectedDraw,
  document,
  autoCalculateRetainage,
  agreementVendorLineItems,
  showInvoiceRetainage
) => (lineItem) => {
  const match = getMatch(drawLineItems, lineItem);

  const lineItemObject = match
    ? { id: match.id, name: match.fullName }
    : { id: null, name: null };

  const lineItemAmount = get(lineItem, "amount") || 0;
  const grossAmount = showInvoiceRetainage
    ? get(lineItem, "grossAmount") || lineItemAmount
    : lineItemAmount;

  // set retainage to zero if permission off (in case this is a transition from pay app)
  let retainageAmount = showInvoiceRetainage
    ? get(lineItem, "retainageAmount") || 0
    : 0;
  let retainageToDateAmount;

  if (autoCalculateRetainage) {
    const {
      autoCalculatedRetainageAmount,
      autoCalculatedRetainageToDateAmount,
    } = autoCalculateLineItemRetainage(
      agreementVendorLineItems,
      grossAmount,
      lineItemObject,
      selectedDraw,
      document.vendor
    );
    retainageAmount = autoCalculatedRetainageAmount;
    retainageToDateAmount = autoCalculatedRetainageToDateAmount;
  } else {
    retainageToDateAmount = calculateLineItemRetainageToDate(
      retainageAmount,
      lineItemObject,
      selectedDraw,
      document.vendor
    );
  }

  const amount = autoCalculateRetainage
    ? subtract(grossAmount, retainageAmount)
    : lineItemAmount;

  return {
    amount,
    grossAmount,
    number: get(match, "number", ""),
    retainageAmount: formatCurrency(retainageAmount),
    retainageToDateAmount: formatCurrency(retainageToDateAmount),
    /* Invoice Line Items can only have 1 job cost code, but they are stored one-to-many
     for invoice line items.  The code will be the first list element, if exists  */
    jobCostCodeId: get(lineItem, "jobCostCodes.0.jobCostCodeId", null),
    lineItemObject,
  };
};

const getEmptyLineItem = (lineItemIndex) => ({
  amount: 0,
  grossAmount: 0,
  retainageAmount: 0,
  retainageToDateAmount: 0,
  jobCostCodeId: null,
  lineItemIndex,
  lineItemObject: {
    id: null,
    name: null,
  },
});

const getInitialValues = ({
  agreementVendorLineItems,
  document,
  hasPermission,
  selectedDraw,
  selectedType,
}) => {
  const isTrackingCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );
  const clearAmounts =
    get(selectedDraw, "isLockedDown") &&
    get(document, "draw.id") !== get(selectedDraw, "id");

  const {
    autoCalculateRetainage,
    comments,
    documentReviewActivity,
    dismissedWarnings,
    number,
    reviews,
    state,
    ...restOfDocument
  } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);
  const trackableAgreements = get(
    restOfDocument,
    "project.trackableAgreements",
    []
  );

  const documentIsInvoiceOrPayApp =
    document.type === DOCUMENT_TYPE_NAME.INVOICE ||
    document.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION;

  return {
    ...documentProperties,
    agreementAmounts: isTrackingCostToAgreements
      ? mapAgreementAmounts(trackableAgreements)
      : {},
    hasTrackedAgreements: trackableAgreements.some(
      ({ spentForDocument }) => unformatNumber(spentForDocument) !== 0
    ),
    draw: selectedDraw
      ? {
          id: selectedDraw.id,
          name: selectedDraw.name,
          lineItems: selectedDraw.lineItems,
          issues: selectedDraw.issues,
        }
      : null,
    type: selectedType,
    isBackup: documentIsInvoiceOrPayApp ? document.isBackup : false,
    number: number || "",
    lineItems:
      document.lineItems && document.lineItems.length > 0 && !clearAmounts
        ? document.lineItems
            .map(
              matchLineItem(
                selectedDraw ? selectedDraw.lineItems : [],
                selectedDraw,
                restOfDocument,
                autoCalculateRetainage,
                agreementVendorLineItems,
                hasPermission(PERMISSION_ACTION.SHOW_INVOICE_RETAINAGE)
              )
            )
            .map((lineItem, index) => ({ ...lineItem, lineItemIndex: index }))
        : [getEmptyLineItem(0)],
    ...(hasPermission(PERMISSION_ACTION.AUTO_CALCULATE_RETAINAGE) && {
      autoCalculateRetainage,
    }),
  };
};

const getDocumentDataFromForm = (values) => {
  return {
    date: dateFormToServer(values.date),
    isBackup: values.isBackup,
    lineItems: formatLineItems(values.lineItems),
    number: isBlank(values.number) ? null : values.number,
  };
};

function isMissingInformation(values) {
  const lineItemsMissingInformation =
    get(values, "lineItems.length") === 0 ||
    every(
      values.lineItems,
      (lineItem) =>
        isBlank(lineItem.amount) || isBlank(get(lineItem, "lineItemObject.id"))
    );

  return !get(values, "vendor.id") || lineItemsMissingInformation;
}

export function getDrawNamesThatHaveDuplicateInvoiceNumbers(
  formDocumentId,
  formInvoiceNumber,
  formVendorId,
  draws,
  invoiceNumbersByVendor
) {
  if (isBlank(formInvoiceNumber) || isBlank(formVendorId)) return [];

  const invoicesWithDuplicateNumbers = invoiceNumbersByVendor.filter(
    ({ id, invoiceNumber, vendorId }) =>
      id !== formDocumentId &&
      invoiceNumber === formInvoiceNumber &&
      vendorId === formVendorId
  );
  return invoicesWithDuplicateNumbers.map(({ drawId }) => {
    const draw = draws.find(({ id }) => id === drawId);
    if (!draw) return "the Project level";
    return draw.name;
  });
}

function getWarnings({
  agreements,
  dismissedWarnings,
  draws,
  form,
  fundedDate,
  invoiceNumbersByVendor,
  hasRulesRedesign,
  projectId,
  projectRules,
  isTrackingCostToAgreements,
  limitTrackedAgreementWarnings,
  vendors,
}) {
  const warnings = {};

  const { values } = form;

  const legacyDuplicateInvoiceNumberRuleEnabled = !!find(
    projectRules,
    (rule) => rule.name === DRAW_RULES.DUPLICATE_INVOICE_NUMBERS && rule.enabled
  );

  const checkForDuplicateInvoiceNumbers =
    legacyDuplicateInvoiceNumberRuleEnabled || hasRulesRedesign;

  const drawsWithDuplicateInvoiceNumbers = checkForDuplicateInvoiceNumbers
    ? getDrawNamesThatHaveDuplicateInvoiceNumbers(
        values.id,
        values.number,
        values.vendor.id,
        draws,
        invoiceNumbersByVendor
      )
    : [];

  if (drawsWithDuplicateInvoiceNumbers.length > 0) {
    warnings.number = {
      type: "duplicateInvoiceNumber",
      name: `${get(values, "vendor.name")} on ${humanReadableList(
        drawsWithDuplicateInvoiceNumbers
      )}`,
    };
  }

  const lineItemsWithInsufficientFunds = hasRulesRedesign
    ? getLineItemsWithInsufficientFunds(
        get(values, "draw.issues", []),
        values.lineItems
      )
    : [];

  if (lineItemsWithInsufficientFunds.length > 0) {
    warnings.insufficientFunds = {
      type: "insufficientFundsInvoice",
      name: humanReadableList(
        lineItemsWithInsufficientFunds.map(
          ({ lineItemObject }) => lineItemObject.name
        )
      ),
    };
  }

  const showTrackCostToAgreementsWarnings = shouldShowTrackCostToAgreementsWarnings(
    {
      isTrackingCostToAgreements,
      limitTrackedAgreementWarnings,
      projectId,
      vendors,
      values,
    }
  );

  if (showTrackCostToAgreementsWarnings) {
    if (
      invoiceAmountFullyNotAllocatedToAgreements(
        values.lineItems,
        Object.values(values.agreementAmounts)
      )
    ) {
      warnings.agreementAmount = {
        type: "invoiceAmountFullyNotAllocatedToAgreements",
        name: "Agreement Amount",
      };
    }

    if (lineItemsNotCoveredByAgreements(values.lineItems, agreements)) {
      warnings.lineItemsCovered = {
        type: "lineItemsNotCoveredByAgreements",
        name: "Line Items Missing From Agreements",
      };
    }
  }

  return removeDismissedWarnings(warnings, dismissedWarnings);
}

function getInformationSummary(form) {
  const summary = [
    get(form.values.vendor, "name"),
    get(form.values, "number") ? `Invoice No. ${form.values.number}` : null,
    formatDate(form.values.date),
  ];
  return summary.filter((value) => !!value).join(", ");
}

/**
 * Retrieve a list of line item suggestions given a set of form values. As
 * values change, the list of suggestions will update, debounced by 500ms.
 *
 * @param {string} projectId - The ID of the project the Draw belongs to
 * @param {Object} formValues - The form values to use for the query, see function body for details
 * @returns {{id: string, reason: string, lineItem}[]} The list of line item suggestions
 */
const useLineItemSuggestions = (projectId, formValues) => {
  const variables = useDebounceDeep(
    {
      projectId,
      data: JSON.stringify({
        date: dateFormToServer(formValues.date),
        isBackup: formValues.isBackup,
        number: isBlank(formValues.number) ? null : formValues.number,
        lineItems: formatLineItems(formValues.lineItems),
        description: formValues.description,
      }),
      drawId: formValues?.draw?.id,
      type: formValues.type,
      vendorId: formValues?.vendor?.id,
    },
    500
  );
  const { data } = useQuery(DOCUMENT_SUGGESTIONS_QUERY, {
    variables,
    fetchPolicy: "cache-first",
  });

  return data?.project?.lineItemSuggestions ?? [];
};

export function InvoiceReview(props) {
  const {
    agreementVendorLineItems,
    disableJobCostCodes,
    disableTrackCostToAgreements,
    disableWarnings,
    document,
    form,
    getAutocalculatedLineItemRetainage,
    invoiceNumbersByVendor,
    projectId,
    projectRules,
    selectedDraw,
  } = props;
  const theme = useContext(ThemeContext);
  const { agreementsQuery, draws } = useContext(DocumentContext);

  const lineItemSuggestions = useLineItemSuggestions(projectId, form.values);

  const { autoCalculateRetainage } = form.values;
  const { isDeveloper, hasPermission, organizationId } = useContext(
    UserContext
  );
  const showParsedFields = hasPermission(PERMISSION_ACTION.SHOW_PARSED_FIELDS);
  const showJobCostCodes =
    !disableJobCostCodes && hasPermission(PERMISSION_ACTION.JOB_COST_CODES);
  const showRetainage = hasPermission(PERMISSION_ACTION.SHOW_INVOICE_RETAINAGE);
  const isTrackingCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );
  const limitTrackedAgreementWarnings = hasPermission(
    PERMISSION_ACTION.LIMIT_TRACKED_AGREEMENT_WARNINGS
  );
  const hasYardiJobCostModule = hasPermission(
    PERMISSION_ACTION.YARDI_JOB_COST_MODULE
  );

  const showTrackCostToAgreements = shouldShowTrackCostToAgreementsSection({
    isTrackingCostToAgreements,
    form,
    disableTrackCostToAgreements,
  });

  // When reviewing a document at the project level or creating a document at any level, this is undefined
  const reviewingOnDraw = get(selectedDraw, "id");

  useEffect(() => {
    form.values.lineItems.forEach((lineItem, lineItemIndex) => {
      const { vendor } = form.values;
      const { grossAmount, lineItemObject } = lineItem;

      let { retainageAmount } = lineItem;
      let retainageToDateAmount;

      if (autoCalculateRetainage) {
        const {
          autoCalculatedRetainageAmount,
          autoCalculatedRetainageToDateAmount,
        } = getAutocalculatedLineItemRetainage(grossAmount, lineItemObject);
        retainageAmount = autoCalculatedRetainageAmount;
        retainageToDateAmount = autoCalculatedRetainageToDateAmount;
      } else {
        retainageToDateAmount = calculateLineItemRetainageToDate(
          retainageAmount,
          lineItemObject,
          selectedDraw,
          vendor
        );
      }

      if (showRetainage) {
        const newAmount = subtract(grossAmount, retainageAmount);

        setLineItemRetainage(
          form.setFieldValue,
          retainageAmount,
          retainageToDateAmount,
          lineItemIndex
        );

        form.setFieldValue(`lineItems.${lineItemIndex}.amount`, newAmount);
      }
    });
    // eslint-disable-next-line
  }, [agreementVendorLineItems, autoCalculateRetainage, form.values.vendor.id]);

  const lockdownDraw = get(selectedDraw, "isLockedDown");
  const documentMarkedPaid = get(document, "isPaid", false);
  const documentImportedFromYardi =
    get(document, "importSource") === DOCUMENT_IMPORT_SOURCE.YARDI;
  const disableForm = lockdownDraw || documentMarkedPaid;
  const [newCostCode, setNewCostCode] = useState({
    input: null,
    index: null,
  });

  const closeJobCostCodeModal = () => {
    form.setFieldValue(`lineItems.${newCostCode.index}.jobCostCodeId`, null);
    setNewCostCode({ input: null, index: null });
  };

  const setCreatedJobCostCode = (data) => {
    const newJobCostCode = data.addJobCostCode;
    form.setFieldValue(
      `lineItems.${newCostCode.index}.jobCostCodeId`,
      newJobCostCode.id
    );
    setNewCostCode({ input: null, index: null });
  };

  const updateLineItemIndices = (lineItems) => {
    form.setFieldValue(
      "lineItems",
      lineItems.map((lineItem, index) => ({
        ...lineItem,
        lineItemIndex: index,
      }))
    );
  };

  if (!form.values.lineItems) return null;

  const warnings =
    disableWarnings || lockdownDraw
      ? {}
      : getWarnings({
          agreements: agreementsQuery?.data?.project?.trackableAgreements || [],
          dismissedWarnings: get(document, "dismissedWarnings", []),
          draws,
          form,
          fundedDate: get(selectedDraw, "fundedDate"),
          invoiceNumbersByVendor,
          hasRulesRedesign: hasPermission(
            PERMISSION_ACTION.RULES_REDESIGN_CLERICAL
          ),
          projectId,
          projectRules,
          isTrackingCostToAgreements,
          limitTrackedAgreementWarnings,
          issues: get(document, "issues", []),
          vendors: props.allAvailableVendors,
        });

  const autofilledFields = getAutofilledFields(
    get(document, "autofilledFields", []),
    form
  );

  const shouldShowAutofilledField = (fieldName) => {
    return showParsedFields && autofilledFields.indexOf(fieldName) !== -1;
  };

  return (
    <Fragment>
      <DocumentReviewLayout
        autofilledFieldCount={autofilledFields.length}
        shouldShowAutofilledField={shouldShowAutofilledField}
        lockdownDraw={lockdownDraw}
        warnings={warnings}
        meta={(content) => (
          <Pane display="flex" width="100%" alignItems="center">
            {content}
            {selectedDraw && <IsBackupCheckbox isDeveloper={isDeveloper} />}
          </Pane>
        )}
        information={(content) => (
          <Pane display="flex" flexDirection="column" width="100%">
            <Pane display="flex" flexDirection="row" flexWrap="wrap">
              {content}
              <Pane flexGrow={1} marginRight={majorScale(2)}>
                <Form.Input
                  disabled={disableForm || documentImportedFromYardi}
                  isHighlighted={shouldShowAutofilledField("number")}
                  label="Invoice Number"
                  name="number"
                />
              </Pane>
              <Pane flexGrow={1}>
                <Form.DateInput
                  disabled={disableForm || documentImportedFromYardi}
                  isHighlighted={shouldShowAutofilledField("date")}
                  label="Invoice Date"
                  name="date"
                  popperPlacement="bottom-end"
                />
              </Pane>
            </Pane>
            <Pane
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <VendorLink
                vendorId={form.values.vendor.id}
                vendors={props.allAvailableVendors}
              />
              {hasPermission(PERMISSION_ACTION.AGREEMENT_MANAGEMENT) && (
                <Pane marginLeft={majorScale(2)}>
                  <AgreementDetailsForVendor
                    agreementVendorLineItems={agreementVendorLineItems}
                    vendorId={form.values.vendor.id}
                  />
                </Pane>
              )}
            </Pane>
          </Pane>
        )}
        informationSummary={getInformationSummary(form)}
        selectedDraw={selectedDraw}
        {...props}
      >
        <DocumentLineItems
          getEmptyLineItem={getEmptyLineItem}
          disableForm={disableForm}
          lockdownDraw={lockdownDraw}
          {...props}
        >
          {({ remove }) => (
            <Fragment>
              {reviewingOnDraw &&
                hasPermission(PERMISSION_ACTION.AUTO_CALCULATE_RETAINAGE) &&
                hasPermission(PERMISSION_ACTION.SHOW_INVOICE_RETAINAGE) && (
                  <Pane
                    display="flex"
                    justifyContent="flex-end"
                    marginRight={majorScale(2)}
                    marginY={majorScale(2)}
                  >
                    <Pane>
                      <Form.Switch
                        label="Auto Calculate Retainage"
                        name="autoCalculateRetainage"
                        textProps={{ size: 300 }}
                      />
                    </Pane>
                  </Pane>
                )}
              <Table paddingBottom={0}>
                <Table.Head>
                  <Table.Row>
                    <Table.TextHeaderCell
                      width={showJobCostCodes ? "25%" : "30%"}
                    >
                      Line Item
                    </Table.TextHeaderCell>
                    {showJobCostCodes &&
                      (hasYardiJobCostModule ? (
                        <Table.TextHeaderCell textAlign="left">
                          Job Cost Code (Yardi Cost Category)
                        </Table.TextHeaderCell>
                      ) : (
                        <Table.TextHeaderCell textAlign="left" width="10%">
                          Job Cost Code
                        </Table.TextHeaderCell>
                      ))}
                    <Table.TextHeaderCell textAlign="left" width="10%">
                      {showRetainage
                        ? "Gross Requested Amount"
                        : "Requested Amount"}
                    </Table.TextHeaderCell>
                    {showRetainage && (
                      <Fragment>
                        {form.values.autoCalculateRetainage ? (
                          <Table.TextHeaderCell
                            textAlign="left"
                            tooltip={
                              hasPermission(
                                PERMISSION_ACTION.AGREEMENT_MANAGEMENT
                              ) &&
                              "This retainage percentage was set either on an agreement or on a line item"
                            }
                          >
                            Expected Retainage
                          </Table.TextHeaderCell>
                        ) : (
                          <Table.TextHeaderCell textAlign="left">
                            Retainage To Date
                          </Table.TextHeaderCell>
                        )}
                        <Table.TextHeaderCell textAlign="left">
                          Retainage Amount
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell
                          fontStyle="italic"
                          textAlign="left"
                        >
                          Requested Amount
                        </Table.TextHeaderCell>
                      </Fragment>
                    )}
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {form.values.lineItems.map((lineItem, index) => {
                    const { expectedRetainage, source } = reviewingOnDraw
                      ? getExpectedRetainage(
                          lineItem.lineItemObject.id,
                          selectedDraw.lineItems,
                          agreementVendorLineItems,
                          form.values.vendor.id
                        )
                      : {};
                    const drawLineItem = selectedDraw.lineItems.find(
                      ({ id }) => get(lineItem, "lineItemObject.id") === id
                    );
                    const setManually = get(drawLineItem, "setManually");
                    return (
                      <Table.Row>
                        <Table.TextCell id="lineItem">
                          {!form.values.__disabled && (
                            <CrossIcon
                              cursor={disableForm ? "not-allowed" : "pointer"}
                              size={14}
                              marginRight={minorScale(3)}
                              onClick={() => {
                                if (!disableForm) {
                                  const currentLineItems =
                                    form.values.lineItems;
                                  remove(index);
                                  updateLineItemIndices(
                                    currentLineItems.filter(
                                      (_, lineItemIndex) =>
                                        index !== lineItemIndex
                                    )
                                  );
                                }
                              }}
                            />
                          )}
                          <Form.Select
                            disabled={disableForm}
                            width={showRetainage ? 120 : 220}
                            hasSuggestions={lineItemSuggestions.length > 0}
                            isWarned={isBlank(
                              get(
                                form,
                                `values.lineItems.${index}.lineItemObject.id`
                              )
                            )}
                            name={`lineItems.${index}.lineItemObject`}
                            noNull
                            onChange={(lineItemObject) => {
                              let { retainageAmount } = lineItem;
                              let retainageToDateAmount;

                              if (autoCalculateRetainage) {
                                const {
                                  autoCalculatedRetainageAmount,
                                  autoCalculatedRetainageToDateAmount,
                                } = getAutocalculatedLineItemRetainage(
                                  lineItem.grossAmount,
                                  lineItemObject
                                );
                                retainageAmount = autoCalculatedRetainageAmount;
                                retainageToDateAmount = autoCalculatedRetainageToDateAmount;
                              } else {
                                retainageToDateAmount = calculateLineItemRetainageToDate(
                                  retainageAmount,
                                  lineItemObject,
                                  selectedDraw,
                                  form.values.vendor
                                );
                              }

                              if (showRetainage) {
                                const newAmount = subtract(
                                  lineItem.grossAmount,
                                  retainageAmount
                                );

                                setLineItemRetainage(
                                  form.setFieldValue,
                                  retainageAmount,
                                  retainageToDateAmount,
                                  index
                                );
                                form.setFieldValue(
                                  `lineItems.${index}.amount`,
                                  newAmount
                                );
                              }
                            }}
                            options={
                              selectedDraw
                                ? getLineItemOptionsWithSuggestions(
                                    selectedDraw.lineItems,
                                    lineItemSuggestions,
                                    get(form.values.vendor, "name"),
                                    theme
                                  )
                                : []
                            }
                            placeholder="Select Line Item..."
                            popoverMinWidth={700}
                          />
                          {setManually && (
                            <Tooltip
                              content={t("documentReview.lineItemSetManually", {
                                lineItems: drawLineItem.name,
                              })}
                            >
                              <InfoSignIcon marginLeft={minorScale(1)} />
                            </Tooltip>
                          )}
                        </Table.TextCell>
                        {showJobCostCodes && (
                          <Table.TextCell id="jobCostCodeId">
                            <Form.Select
                              width={showRetainage ? 130 : 180}
                              addName="New Job Cost Code"
                              disabled={disableForm}
                              name={`lineItems.${index}.jobCostCodeId`}
                              onAddItem={(input) =>
                                setNewCostCode({ input, index })
                              }
                              options={getCostCodeOptions(props.jobCostCodes)}
                              placeholder="Select Job Cost Code..."
                            />
                            <IconButton
                              appearance="minimal"
                              marginTop={minorScale(1)}
                              disabled={disableForm}
                              icon={AddIcon}
                              intent="info"
                              onClick={() => setNewCostCode({ index })}
                              type="button"
                            />
                          </Table.TextCell>
                        )}
                        <Table.Cell id="amount">
                          <Form.Input
                            width={showRetainage ? 90 : 120}
                            disabled={disableForm}
                            isHighlighted={shouldShowAutofilledField(
                              showRetainage
                                ? `lineItems.${index}.grossAmount`
                                : `lineItems.${index}.amount`
                            )}
                            isWarned={isBlank(
                              get(
                                form,
                                showRetainage
                                  ? `values.lineItems.${index}.grossAmount`
                                  : `values.lineItems.${index}.amount`
                              )
                            )}
                            name={
                              showRetainage
                                ? `lineItems.${index}.grossAmount`
                                : `lineItems.${index}.amount`
                            }
                            onChange={(e) => {
                              if (autoCalculateRetainage && showRetainage) {
                                const {
                                  autoCalculatedRetainageAmount,
                                  autoCalculatedRetainageToDateAmount,
                                } = getAutocalculatedLineItemRetainage(
                                  e.target.value,
                                  lineItem.lineItemObject
                                );

                                if (showRetainage) {
                                  const newAmount = subtract(
                                    e.target.value,
                                    autoCalculatedRetainageAmount
                                  );

                                  setLineItemRetainage(
                                    form.setFieldValue,
                                    autoCalculatedRetainageAmount,
                                    autoCalculatedRetainageToDateAmount,
                                    index
                                  );

                                  form.setFieldValue(
                                    `lineItems.${index}.amount`,
                                    newAmount
                                  );
                                }
                              } else if (showRetainage) {
                                form.setFieldValue(
                                  `lineItems.${index}.amount`,
                                  formatCurrency(
                                    subtract(
                                      unformatNumber(e.target.value),
                                      form.values.lineItems[index]
                                        .retainageAmount
                                    )
                                  )
                                );
                              }
                            }}
                            textAlign="left"
                            type="currency"
                          />
                        </Table.Cell>
                        {showRetainage && (
                          <Fragment>
                            {autoCalculateRetainage ? (
                              <Table.TextCell textAlign="right">
                                {formatPercent(expectedRetainage, "-", 2)}
                                {source && (
                                  <ExpectedRetainageTooltip
                                    expectedRetainage={expectedRetainage}
                                    lineItemName={lineItem.lineItemObject.name}
                                    source={source}
                                    vendorName={form.values.vendor.name}
                                  />
                                )}
                              </Table.TextCell>
                            ) : (
                              <Table.Cell id="retainageToDateAmount">
                                <Form.Input
                                  disabled={disableForm}
                                  name={`lineItems.${index}.retainageToDateAmount`}
                                  onChange={(e) => {
                                    const newRetainageAmount = getRetainageThisDraw(
                                      form.values.vendor,
                                      selectedDraw
                                    )({
                                      ...lineItem,
                                      retainageToDateAmount: e.target.value,
                                    });

                                    form.setFieldValue(
                                      `lineItems.${index}.retainageAmount`,
                                      formatCurrency(newRetainageAmount)
                                    );

                                    if (showRetainage) {
                                      form.setFieldValue(
                                        `lineItems.${index}.amount`,
                                        formatCurrency(
                                          subtract(
                                            form.values.lineItems[index]
                                              .grossAmount,
                                            newRetainageAmount
                                          )
                                        )
                                      );
                                    }
                                  }}
                                  textAlign="right"
                                  type="currency"
                                />
                              </Table.Cell>
                            )}
                            {autoCalculateRetainage ? (
                              <Table.TextCell textAlign="right">
                                {formatCurrency(
                                  form.values.lineItems[index].retainageAmount
                                )}
                              </Table.TextCell>
                            ) : (
                              <Table.Cell id="retainageAmount">
                                <Form.Input
                                  disabled={disableForm}
                                  name={`lineItems.${index}.retainageAmount`}
                                  onChange={(e) => {
                                    const previousRetainage = getPreviousRetainage(
                                      form.values.vendor,
                                      selectedDraw
                                    )(lineItem);
                                    const newToDateRetainage = formatCurrency(
                                      add(
                                        unformatNumber(e.target.value),
                                        previousRetainage
                                      )
                                    );

                                    form.setFieldValue(
                                      `lineItems.${index}.retainageToDateAmount`,
                                      newToDateRetainage
                                    );

                                    if (showRetainage) {
                                      form.setFieldValue(
                                        `lineItems.${index}.amount`,
                                        formatCurrency(
                                          subtract(
                                            form.values.lineItems[index]
                                              .grossAmount,
                                            unformatNumber(e.target.value)
                                          )
                                        )
                                      );
                                    }
                                  }}
                                  textAlign="right"
                                  type="currency"
                                />
                              </Table.Cell>
                            )}
                            <Table.TextCell textAlign="right">
                              {formatCurrency(
                                form.values.lineItems[index].amount
                              )}
                              <CalculationTooltip
                                form={form}
                                getPreviousRetainage={getPreviousRetainage}
                                isPayApp={false}
                                lineItem={lineItem}
                                selectedDraw={selectedDraw}
                              />
                            </Table.TextCell>
                          </Fragment>
                        )}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                <Table.Foot>
                  <Table.Row testId="invoiceTotalRow">
                    <Table.TextFooterCell />
                    {showJobCostCodes && <Table.TextFooterCell />}
                    <Table.TextFooterCell textAlign="right">
                      {formatCurrency(
                        sumBy(
                          form.values.lineItems,
                          showRetainage ? "grossAmount" : "amount"
                        )
                      )}
                    </Table.TextFooterCell>
                    {showRetainage && (
                      <Fragment>
                        <Table.TextFooterCell textAlign="right">
                          {formatCurrency(
                            sumBy(
                              form.values.lineItems,
                              "retainageToDateAmount"
                            )
                          )}
                        </Table.TextFooterCell>
                        <Table.TextFooterCell textAlign="right">
                          {formatCurrency(
                            sumBy(form.values.lineItems, "retainageAmount")
                          )}
                        </Table.TextFooterCell>
                        <Table.TextFooterCell textAlign="right">
                          {formatCurrency(
                            sumBy(form.values.lineItems, "amount")
                          )}
                        </Table.TextFooterCell>
                      </Fragment>
                    )}
                  </Table.Row>
                </Table.Foot>
              </Table>
            </Fragment>
          )}
        </DocumentLineItems>
        {showTrackCostToAgreements && <TrackCostToAgreements {...props} />}
      </DocumentReviewLayout>
      {newCostCode.index !== null && (
        <JobCostCodeModal
          costCode={{ code: newCostCode.input }}
          containerProps={{ marginRight: "30%" }}
          onClose={closeJobCostCodeModal}
          onMutationCompleted={setCreatedJobCostCode}
          organizationCostCodes={props.jobCostCodes}
          organizationId={organizationId}
        />
      )}
    </Fragment>
  );
}

InvoiceReview.propTypes = {
  children: PropTypes.node,
  selectedDraw: PropTypes.object,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isLockedDown: PropTypes.boolean,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      isBackup: PropTypes.bool,
      number: PropTypes.string,
      date: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number,
          lineItemObject: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
        })
      ),
    }),
  }),
  jobCostCodes: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string })),
  projectId: PropTypes.string,
  onApprove: PropTypes.func,
  onComment: PropTypes.func,
  onChangeDraw: PropTypes.func,
  onChangeType: PropTypes.func,
};

// TODO: remove dot notation
InvoiceReview.isMissingInformation = isMissingInformation;
InvoiceReview.getEmptyLineItem = getEmptyLineItem;
InvoiceReview.getInitialValues = getInitialValues;
InvoiceReview.getDocumentDataFromForm = getDocumentDataFromForm;
