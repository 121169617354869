import { useState, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { budgetTemplate } from "images";
import { BudgetDropzone } from "components/templates";
import { Button, DownloadLink, Pane, Text } from "components/materials";
import t from "helpers/translate";
import { majorScale, ThemeContext, toaster } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { Header } from "../OnboardingWizard/Header";
import { StepDescription } from "../OnboardingWizard/UploadTemplateStep";
import { parseExcelBudgetFile } from "../../../helpers/budgetUploadHelpers";
import { UPDATE_ONBOARDING_DATA } from "./graphql";

function errorToast() {
  toaster.danger(
    "There was an error uploading your budget. Please try again.",
    {
      duration: 2.5,
    }
  );
}

export function UploadTemplateStep({ project }) {
  const [templateDownloaded, setTemplateDownloaded] = useState(false);
  const [uploadedTemplate, setUploadedTemplate] = useState(null);
  const [uploadedBudget] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);

  const hasMasterFormatDivision = hasPermission(
    PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING
  );

  const history = useHistory();

  const [update] = useMutation(UPDATE_ONBOARDING_DATA, {
    onCompleted: () => {
      history.push(`/projects/${project.id}/confirm_budget`);
    },
  });

  const onParsingComplete = (divisions) => {
    const data = {
      budget: { divisions: Object.values(divisions) },
    };
    update({
      variables: {
        projectId: project.id,
        data: JSON.stringify(data),
      },
    });
  };

  const onError = () => {
    setUploadLoading(false);
    setUploadedTemplate(null);
    errorToast();
  };

  const onAddBehavior = (file) => {
    if (file.length > 1) {
      errorToast();
      return false;
    }

    setUploadedTemplate(file[0]);
    setUploadLoading(true);

    return parseExcelBudgetFile(
      file,
      hasMasterFormatDivision,
      onParsingComplete,
      onError
    );
  };

  return (
    <Fragment>
      <Header
        header={t("onboardingWizard.uploadTemplateStep.header")}
        subheader={t("onboardingWizard.uploadTemplateStep.subheader")}
      />
      <Pane
        border="muted"
        borderRadius={majorScale(1)}
        marginBottom={majorScale(3)}
      >
        <StepDescription
          action={
            <DownloadLink
              url={budgetTemplate}
              filename="Rabbet Budget Template.xlsx"
              purpose="download budget-template"
              forceFilename
              hideSpinner
              label={
                <Button
                  appearance={templateDownloaded ? "default" : "primary"}
                  iconBefore="download"
                  purpose="project-wizard budget-template download"
                  onClick={() => {
                    setTemplateDownloaded(true);
                  }}
                >
                  Download Template
                </Button>
              }
            />
          }
          descriptionBody={t(
            "onboardingWizard.uploadTemplateStep.downloadTemplateDescription"
          )}
          descriptionHeader={t(
            "onboardingWizard.uploadTemplateStep.downloadTemplateHeader"
          )}
          stepNumber="1"
          theme={theme}
        />
        <StepDescription
          descriptionBody={t(
            "onboardingWizard.uploadTemplateStep.fillTemplateDescription"
          )}
          descriptionHeader={t(
            "onboardingWizard.uploadTemplateStep.fillTemplateHeader"
          )}
          stepNumber="2"
          theme={theme}
        />
      </Pane>
      <BudgetDropzone
        uploadedBudget={uploadedBudget}
        buttonAppearance={templateDownloaded ? "primary" : "default"}
        dropzoneStyling={{
          padding: majorScale(3),
          marginBottom: majorScale(3),
        }}
        header={
          <Text fontSize={16} fontWeight={theme.fontWeights.MEDIUM}>
            {t("onboardingWizard.uploadTemplateStep.dropzoneHeader")}
          </Text>
        }
        loading={uploadLoading}
        onAdd={onAddBehavior}
        photoPaneStyling={{ width: "25%" }}
        textPaneStyling={{ width: "75%" }}
        uploadedTemplate={uploadedTemplate}
      />
    </Fragment>
  );
}
