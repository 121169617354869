import { majorScale } from "helpers/utilities";
import { Modal, Tab, Tablist } from "components/materials";

const TAB_NAMES: { [key: string]: string } = {
  UPLOAD: "Upload Documents",
  CREATE: "Create Document",
  CREATE_92403: "Create 92403 - Page 2",
};

export interface NavigationProps {
  selectedTab: string;
  setSelectedTab: Function;
  tabs: string[];
}

export default function Navigation({
  selectedTab,
  setSelectedTab,
  tabs,
}: NavigationProps) {
  if (tabs.length < 2) return null;

  return (
    <Modal.Content marginBottom={majorScale(2)}>
      <Tablist>
        {tabs.map((tab: string) => (
          <Tab
            key={tab}
            id={tab}
            isSelected={selectedTab === tab}
            onSelect={() => setSelectedTab(tab)}
          >
            {TAB_NAMES[tab] || tab}
          </Tab>
        ))}
      </Tablist>
    </Modal.Content>
  );
}
