import { useEffect, Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { Alert, Loadable, Modal, Pane, Paragraph } from "components/materials";
import { toaster, majorScale } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { DRAW_STATE } from "helpers/enums";
import { debounce } from "lodash";
import { BorrowerDrawDetails } from "./BorrowerDrawDetails";
import { BorrowerDrawDocuments } from "./BorrowerDrawDocuments";
import { BorrowerDrawHeader } from "./BorrowerDrawHeader";
import {
  BORROWER_DRAW_QUERY,
  DELETE_BORROWER_DOCUMENT,
  SUBMIT_BORROWER_DRAW,
  UPDATE_BORROWER_DRAW,
} from "./graphql";

function PersistInfo({ borrowerDrawId, formValues, projectId }) {
  const [updateBorrowerDraw] = useMutation(UPDATE_BORROWER_DRAW);
  const debouncedUpdate = debounce(updateBorrowerDraw, 1000);
  useEffect(() => {
    debouncedUpdate({
      variables: {
        ...formValues,
        id: borrowerDrawId,
        projectId,
        requestedAmount: formValues.requestedAmount || null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return null;
}

function getInitialValues(borrowerDraw) {
  const { checklistItems, notes, requestedAmount } = borrowerDraw;
  return {
    requestedAmount: requestedAmount ? formatCurrency(requestedAmount) : "",
    notes,
    checklistItems: checklistItems.map(({ id, label, complete }) => ({
      id,
      label,
      complete,
    })),
  };
}

function handleSubmit(borrowerDraw, submitBorrowerDraw) {
  const { id } = borrowerDraw;
  submitBorrowerDraw({ variables: { borrowerDrawId: id } });
}

function BorrowerDrawSubmissionModal({
  borrowerDraw,
  formValues,
  setModalOpen,
}) {
  const { requestedAmount, checklistItems } = formValues;
  const hasRequestedAmount = !!requestedAmount;
  const hasAllChecklistItems = checklistItems.every(
    (item) => item.complete === true
  );
  const [submitBorrowerDraw, { loading }] = useMutation(SUBMIT_BORROWER_DRAW, {
    onCompleted: () => {
      setModalOpen(false);
      toaster.success(`Success! ${borrowerDraw.name} was submitted.`, {
        duration: 2.5,
      });
    },
    onError: () => {
      setModalOpen(false);
      toaster.danger("That was an error trying to submit the draw.", {
        duration: 2.5,
      });
    },
  });
  return (
    <Modal
      confirmLabel="Submit"
      isConfirmLoading={loading}
      hasFooter
      onClose={() => {
        setModalOpen(false);
      }}
      onConfirm={() => {
        handleSubmit(borrowerDraw, submitBorrowerDraw);
      }}
      open={() => {}}
      size="small"
      title="Submit Draw"
    >
      <Modal.Content>
        <Paragraph>
          This action cannot be undone and you may not edit the draw after
          submission.
        </Paragraph>
        {(!hasRequestedAmount || !hasAllChecklistItems) && (
          <Pane paddingBottom={10} paddingTop={10}>
            {!hasRequestedAmount && (
              <Alert
                intent="warning"
                title="The Requested Amount field is blank"
              />
            )}
            {!hasAllChecklistItems && (
              <Alert
                intent="warning"
                title="The Draw Checklist is incomplete"
              />
            )}
          </Pane>
        )}
        <Paragraph>Are you sure you want to submit the draw?</Paragraph>
      </Modal.Content>
    </Modal>
  );
}

export function BorrowerDrawPage({ match }) {
  const { drawId, projectId } = match.params;
  const [modalOpen, setModalOpen] = useState(false);
  const { data, loading } = useQuery(BORROWER_DRAW_QUERY, {
    variables: { projectId, drawId },
  });
  const [deleteUpload] = useMutation(DELETE_BORROWER_DOCUMENT, {
    refetchQueries: ["BorrowerDrawFormQuery"],
  });

  if (loading) return <Loadable loading />;
  const { project } = data.borrower;
  const { draw } = project;
  const drawHasBeenSubmitted = draw.state !== DRAW_STATE.STARTED;

  return (
    <Pane
      background="#F2F2F2"
      minHeight="100%"
      width="100%"
      padding={majorScale(4)}
      display="flex"
      justifyContent="center"
    >
      <Formik
        enableReinitialize
        initialValues={getInitialValues(draw)}
        validate={{}}
      >
        {(formikProps) => (
          <Fragment>
            <Pane width="100%" maxWidth={1200}>
              <BorrowerDrawHeader
                borrowerDraw={draw}
                drawHasBeenSubmitted={drawHasBeenSubmitted}
                project={project}
                setModalOpen={setModalOpen}
              />
              <Pane display="flex">
                <BorrowerDrawDocuments
                  borrowerDraw={draw}
                  deleteUpload={deleteUpload}
                  drawHasBeenSubmitted={drawHasBeenSubmitted}
                />
                <BorrowerDrawDetails
                  borrowerDraw={draw}
                  drawHasBeenSubmitted={drawHasBeenSubmitted}
                  formikProps={formikProps}
                  organizationName={project.organizationName}
                />
              </Pane>
            </Pane>
            {modalOpen && (
              <BorrowerDrawSubmissionModal
                borrowerDraw={draw}
                formValues={formikProps.values}
                setModalOpen={setModalOpen}
              />
            )}
            <PersistInfo
              borrowerDrawId={draw.id}
              formValues={formikProps.values}
              projectId={draw.projectId}
            />
          </Fragment>
        )}
      </Formik>
    </Pane>
  );
}
