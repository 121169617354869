import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Pane } from "components/materials";
import { get } from "lodash";
import { majorScale } from "helpers/utilities";
import { Comment } from "./Comment";
import { ADD_COMMENT } from "./graphql";
import { PROJECT_DASHBOARD_QUERY } from "../../graphql";
import { NewCommentForm } from "./NewCommentForm";

export function ProjectChat({ project, currentUserId }) {
  const history = useHistory();

  const addCommentMutation = useMutation(ADD_COMMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PROJECT_DASHBOARD_QUERY,
        variables: { projectId: project.id, userId: currentUserId },
      },
    ],
  });

  const commentsPaneRef = useRef(null);
  const comments = get(project, "comments", []).slice().reverse();

  useEffect(() => {
    const commentsPane = commentsPaneRef.current;
    if (commentsPane) {
      commentsPane.scrollTop = commentsPane.scrollHeight;
    }
  }, [comments.length]);

  return (
    <Pane
      height="100%"
      width="100%"
      paddingBottom={majorScale(2)}
      display="flex"
      flexDirection="column"
    >
      <Pane flex="1 1 auto" overflowY="auto" ref={commentsPaneRef}>
        {comments.map((comment) => (
          <Pane
            paddingX={majorScale(3)}
            marginBottom={majorScale(2)}
            key={comment.id}
          >
            <Comment
              history={history}
              key={comment.id}
              comment={comment}
              projectId={project.id}
            />
          </Pane>
        ))}
      </Pane>
      <Pane
        flex="0 0 auto"
        paddingX={majorScale(3)}
        paddingBottom={majorScale(1)}
      >
        <NewCommentForm
          project={project}
          addCommentMutation={addCommentMutation}
        />
      </Pane>
    </Pane>
  );
}
