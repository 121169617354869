import { PureComponent } from "react";
import WizardActions from "./WizardActions";
import WizardContent from "./WizardContent";
import WizardHeading from "./WizardHeading";

class Wizard extends PureComponent {
  static Actions = WizardActions;

  static Heading = WizardHeading;

  static Content = WizardContent;
}

export default Wizard;
