import {
  AirplaneIcon,
  BankAccountIcon,
  BriefcaseIcon,
  BuildIcon,
  CalculatorIcon,
  CellTowerIcon,
  FlagIcon,
  GlassIcon,
  GlobeIcon,
  HomeIcon,
  OfficeIcon,
  OilFieldIcon,
  PeopleIcon,
  ShopIcon,
  TaxiIcon,
  TrainIcon,
  WalkIcon,
} from "evergreen-ui";
import { PROJECT_ICON } from "helpers/enums";
import { majorScale } from "helpers/utilities";

export function getProjectIcon(icon, customProps) {
  const defaultProps = {
    color: "muted",
    marginRight: majorScale(1),
  };

  const props = { ...defaultProps, ...customProps };

  const ICONS = {
    AIRPLANE: <AirplaneIcon {...props} />,
    BANK_ACCOUNT: <BankAccountIcon {...props} />,
    BRIEFCASE: <BriefcaseIcon {...props} />,
    BUILD: <BuildIcon {...props} />,
    CALCULATOR: <CalculatorIcon {...props} />,
    CELL_TOWER: <CellTowerIcon {...props} />,
    FLAG: <FlagIcon {...props} />,
    GLASS: <GlassIcon {...props} />,
    GLOBE: <GlobeIcon {...props} />,
    HOME: <HomeIcon {...props} />,
    OFFICE: <OfficeIcon {...props} />,
    OIL_FIELD: <OilFieldIcon {...props} />,
    PEOPLE: <PeopleIcon {...props} />,
    SHOP: <ShopIcon {...props} />,
    TAXI: <TaxiIcon {...props} />,
    TRAIN: <TrainIcon {...props} />,
    WALK: <WalkIcon {...props} />,
  };

  return ICONS[PROJECT_ICON[icon]];
}

export function getButtonProjectIcon(icon) {
  const ICONS = {
    AIRPLANE: AirplaneIcon,
    BANK_ACCOUNT: BankAccountIcon,
    BRIEFCASE: BriefcaseIcon,
    BUILD: BuildIcon,
    CALCULATOR: CalculatorIcon,
    CELL_TOWER: CellTowerIcon,
    FLAG: FlagIcon,
    GLASS: GlassIcon,
    GLOBE: GlobeIcon,
    HOME: HomeIcon,
    OFFICE: OfficeIcon,
    OIL_FIELD: OilFieldIcon,
    PEOPLE: PeopleIcon,
    SHOP: ShopIcon,
    TAXI: TaxiIcon,
    TRAIN: TrainIcon,
    WALK: WalkIcon,
  };

  return ICONS[PROJECT_ICON[icon]];
}
