import { Table } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { parseCurrencyToFloat } from "helpers/parseCurrencyToFloat";
import isBlank from "helpers/isBlank";
import { add } from "helpers/math";
import { isInteger } from "lodash";

export function DrawSummaryTableTotalRow({
  data,
  lineItems,
  formValues,
  label,
}) {
  const { totalAdjustments, totalRequested, totalRetainage } = lineItems.reduce(
    (totals, { rowIndex, overrides }) => {
      const {
        adjustmentsAmount: parsedAdjustmentsAmount,
        requestedAmount: parsedRequestedAmount,
        retainageAmount: parsedRetainageAmount,
      } = getParsedLineItemValues(data, rowIndex, formValues);

      const adjustmentsAmount = isBlank(overrides.adjustmentsAmount)
        ? parsedAdjustmentsAmount
        : parseCurrencyToFloat(overrides.adjustmentsAmount);
      const requestedAmount = isBlank(overrides.requestedAmount)
        ? parsedRequestedAmount
        : parseCurrencyToFloat(overrides.requestedAmount);
      const retainageAmount = isBlank(overrides.retainageAmount)
        ? parsedRetainageAmount
        : parseCurrencyToFloat(overrides.retainageAmount);

      return {
        totalAdjustments: add(totals.totalAdjustments, adjustmentsAmount),
        totalRequested: add(totals.totalRequested, requestedAmount),
        totalRetainage: add(totals.totalRetainage, retainageAmount),
      };
    },
    { totalAdjustments: 0, totalRequested: 0, totalRetainage: 0 }
  );

  return (
    <Table.Row>
      <Table.FooterCell />
      <Table.TextFooterCell colSpan={1} textAlign="right">
        {label}
      </Table.TextFooterCell>
      <Table.TextFooterCell textAlign="right">
        {formatCurrency(totalAdjustments)}
      </Table.TextFooterCell>
      <Table.TextFooterCell textAlign="right">
        {formatCurrency(totalRequested)}
      </Table.TextFooterCell>
      {formValues.retainageMethod !== "noRetainage" && (
        <Table.TextFooterCell textAlign="right">
          {formatCurrency(totalRetainage)}
        </Table.TextFooterCell>
      )}
    </Table.Row>
  );
}

export function getParsedLineItemValues(sheetData, rowIndex, formValues) {
  const { adjustmentsColumn, retainageColumn, requestedColumn } = formValues;
  const rowData =
    isInteger(rowIndex) && rowIndex < sheetData.length
      ? sheetData[rowIndex]
      : [];
  const adjustmentsAmount = rowData[adjustmentsColumn] ?? null;
  const retainageAmount = rowData[retainageColumn] ?? null;
  const requestedAmount = rowData[requestedColumn] ?? null;

  return { adjustmentsAmount, requestedAmount, retainageAmount };
}
