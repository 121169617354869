import { useEffect, useState, Fragment } from "react";
import { Formik, FieldArray } from "formik";
import {
  Accordion,
  Button,
  Confirm,
  Form,
  Pane,
  Paragraph,
  Table,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { set } from "lodash";
import isBlank from "helpers/isBlank";
import { configurationPanelStyles } from "./helpers";
import { EditConfigurationButtons } from "./EditConfigurationButtons";

export const PANEL_KEY = "productTypes";

export function ProductTypes({
  dirtyPanels,
  setPanelDirty,
  onSubmit,
  loading,
  organization,
  toggle,
  expandedPanelKeys,
}) {
  return (
    <Formik
      initialValues={{ productTypes: organization.productTypes }}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {(form) => (
        <ProductTypesPanel
          dirtyPanels={dirtyPanels}
          setPanelDirty={setPanelDirty}
          form={form}
          loading={loading}
          toggle={toggle}
          expandedPanelKeys={expandedPanelKeys}
        />
      )}
    </Formik>
  );
}

function ProductTypesPanel({
  form,
  loading,
  expandedPanelKeys,
  toggle,
  dirtyPanels,
  setPanelDirty,
}) {
  const dirty = dirtyPanels[PANEL_KEY];
  const open = !!expandedPanelKeys[PANEL_KEY];

  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(PANEL_KEY, isDirty);
    }
    return undefined;
  }, [dirty, form, setPanelDirty]);

  return (
    <Accordion.Panel
      panelKey={PANEL_KEY}
      title="Product Types"
      onClick={() => toggle(PANEL_KEY)}
      open={open}
      actionContent={
        <EditConfigurationButtons dirty={dirty} form={form} loading={loading} />
      }
      {...configurationPanelStyles}
    >
      <FieldArray name="productTypes">
        {({ push, remove }) => (
          <ProductTypesForm form={form} push={push} remove={remove} />
        )}
      </FieldArray>
    </Accordion.Panel>
  );
}

function ProductTypesForm({ form, push, remove }) {
  const [deleteConfirmType, setDeleteConfirmType] = useState(null);

  if (form.values.productTypes.length === 0) {
    return (
      <Pane>
        <Paragraph marginBottom={majorScale(2)}>
          Set the Product Types you would like to make available for use
        </Paragraph>
        <Button
          appearance="primary"
          content="Add Product Type"
          onClick={() => push(getBlankType())}
          purpose="product type blank add"
        />
      </Pane>
    );
  }

  return (
    <Fragment>
      <Form>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell>Product Type</Table.TextHeaderCell>
              <Table.TextHeaderCell width={150} />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Fragment>
              {form.values.productTypes.map((type, index) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <Form.Input name={`productTypes[${index}].type`} />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => {
                          if (type.hasBeenUsed)
                            setDeleteConfirmType({ ...type, index });
                          else remove(index);
                        }}
                      >
                        Delete Product Type
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              <Pane marginTop={majorScale(2)}>
                <Button
                  appearance="primary"
                  onClick={() => push(getBlankType())}
                >
                  Add Product Type
                </Button>
              </Pane>
            </Fragment>
          </Table.Body>
        </Table>
      </Form>
      {deleteConfirmType && (
        <Confirm
          open
          content={`"${deleteConfirmType.type}" has been assigned to at least one project in the organization. Do you wish to delete this product type?`}
          header="Warning"
          onCloseComplete={() => setDeleteConfirmType(null)}
          onConfirm={(close) => {
            remove(deleteConfirmType.index);
            close();
          }}
        />
      )}
    </Fragment>
  );
}

function getBlankType() {
  return {
    id: null,
    type: "",
    hasBeenUsed: false,
  };
}

function validate(values) {
  const errors = {};

  values.productTypes.forEach(({ type }, index) => {
    if (isBlank(type)) {
      set(
        errors,
        `productTypes[${index}].type`,
        "Product Type cannot be blank."
      );
    }

    const isDuplicate =
      values.productTypes.filter(
        (productType) => productType.type.trim() === type.trim()
      ).length > 1;

    if (isDuplicate) {
      set(
        errors,
        `productTypes[${index}].type`,
        "Product Type cannot be used more than once"
      );
    }
  });

  return errors;
}
