import { Pane } from "components/materials";
import { minorScale } from "helpers/utilities";

const Grid = ({ children, ...props }) => {
  return <Pane {...props}>{children}</Pane>;
};

const Column = ({ children, columns = 1, ...props }) => {
  return (
    <Pane width={`${(columns / 16) * 100}%`} padding={minorScale(1)} {...props}>
      {children}
    </Pane>
  );
};

const Row = ({ children, ...props }) => {
  return (
    <Pane
      className="rbt-grid"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      {...props}
    >
      {children}
    </Pane>
  );
};

// TODO: remove dot notation
Grid.Column = Column;
Grid.Row = Row;

export default Grid;
