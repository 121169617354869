import { useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import useCreateInvoiceMutation from "../api/mutations/useCreateInvoiceMutation";
import usePreviewInvoiceMutation from "../api/mutations/usePreviewInvoiceMutation";

const QUERY = gql`
  query GetCreateInvoiceData($projectId: String!) {
    project(id: $projectId) {
      id
      name
      lineItems {
        id
        scopeId
        name
        number
        division {
          id
          scopeId
          name
        }
      }
      organization {
        id
        documentTemplates(type: ${DOCUMENT_TYPE_NAME.INVOICE}) {
          id
          name
        }
      }
    }
  }
`;

type LineItem = {
  id: string;
  scopeId: string;
  name: string;
  number: string;
  division: {
    id: string;
    scopeId: string;
    name: string;
  };
};

type DocumentTemplate = {
  id: string;
  name: string;
};

interface UseCreateInvoiceProps {
  drawId?: string;
  projectId: string;
  onSubmit?: () => void;
}

interface UseCreateInvoiceResult {
  loading: boolean;
  submitLoading: boolean;
  lineItems?: LineItem[];
  templates: DocumentTemplate[];
  createResult: any;
  previewResult: any;
  onCreate: (values: any) => void;
  onPreview: (values: any) => void;
}

export function useCreateInvoice({
  drawId,
  projectId,
  onSubmit,
}: UseCreateInvoiceProps): UseCreateInvoiceResult {
  const { data, loading } = useQuery(QUERY, {
    variables: { projectId },
  });

  const [create, createResult] = useCreateInvoiceMutation({
    variables: { projectId, drawId },
  });

  const [preview, previewResult] = usePreviewInvoiceMutation({
    variables: { projectId, drawId },
  });

  const handleCreate = useCallback(
    async (variables: any) => {
      await create({ variables });
      onSubmit && onSubmit();
    },
    [create, onSubmit]
  );

  const handlePreview = useCallback(
    (variables: any) => {
      preview({ variables });
    },
    [preview]
  );

  const lineItems = data?.project?.lineItems ?? [];
  const templates = data?.project?.organization?.documentTemplates ?? [];
  const submitLoading = createResult.loading || previewResult.loading;

  return {
    loading,
    submitLoading,
    lineItems,
    templates,
    createResult,
    previewResult,
    onCreate: handleCreate,
    onPreview: handlePreview,
  };
}

export default useCreateInvoice;
