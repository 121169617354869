import { useContext } from "react";
import { Form, Pane, Paragraph, Link } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { documentsProcessing } from "images";
import t from "helpers/translate";

function ParseWarning({
  handleCancelParsing,
  isProcessing,
  requireSaveToParse,
}) {
  const theme = useContext(ThemeContext);

  return (
    <Form.Section borderBottom heading="">
      <Pane display="flex" alignItems="center" padding={majorScale(6)}>
        <Pane alt="" height={124} is="img" src={documentsProcessing} />
        <Pane marginLeft={majorScale(4)}>
          <Paragraph fontWeight={theme.fontWeights.DEMI}>
            {t(
              `documentsViewer.${
                isProcessing ? "documentsProcessing1" : "typeChanged"
              }`
            )}
          </Paragraph>
          <Paragraph marginY={majorScale(1)}>
            {requireSaveToParse &&
              "Please save the document to begin parsing. "}
            {t("documentsViewer.documentsProcessing2")}{" "}
          </Paragraph>
          {handleCancelParsing && (
            <Paragraph marginY={majorScale(1)}>
              <Link onClick={handleCancelParsing}>
                Skip parsing and review now.
              </Link>
            </Paragraph>
          )}
        </Pane>
      </Pane>
    </Form.Section>
  );
}

export default ParseWarning;
