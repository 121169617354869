export const STORAGE_KEYS = {
  ACCESS_TOKEN: "access_token",
  CACHE_SCOPE: "cache_scope",
  ID_TOKEN: "id_token",
  LAST_ACTIVITY_AT: "last_activity_at",
  PROGRESS_FILES: "progress_files",
  RECENT_PROJECT_IDS: "recent_project_ids",
  RECENT_UPLOAD_DRAW_ID: "recent_upload_draw_id",
  RECENT_UPLOAD_ERRORS: "recent_upload_errors",
  RECENT_UPLOAD_IDS: "recent_upload_ids",
  RECENT_UPLOAD_PROJECT_ID: "recent_upload_project_id",
  RETURN_TO: "return_to",
  SESSION_EXPIRES_AT: "session_expires_at",
  HIDE_TOAST: "hide_toast",
  HIDE_UPLOAD_WIDGET: "hide_upload_widget",
};
