import { useEffect, useContext } from "react";
import { EditProjectSettingsButtons } from "components/templates";
import { Accordion, Form, Pane, Text, Link } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION, PROJECT_SETUP_STEP } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { map, isEmpty, every, forEach } from "lodash";

export function EditInspectionQuestionsPanel({
  completeSetupStep,
  dirty,
  form,
  loading,
  onToggle,
  open,
  panelKey,
  setPanelDirty,
  ...props
}) {
  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(panelKey, isDirty);
    }
    return undefined;
  }, [dirty, form, panelKey, setPanelDirty]);

  useEffect(() => {
    if (open) {
      completeSetupStep(PROJECT_SETUP_STEP.REVIEW_FORM_CONFIGURATIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { inspectionReportQuestions, drawAssessmentQuestions } = form.values;

  const hasInspectionReportQuestions = !isEmpty(inspectionReportQuestions);
  const allInspectionReportQuestionsSelected = every(
    inspectionReportQuestions,
    "enabled"
  );

  const hasDrawAssessmentQuestions = !isEmpty(drawAssessmentQuestions);
  const allDrawAssessmentQuestionsSelected = every(
    drawAssessmentQuestions,
    "enabled"
  );

  const { hasPermission } = useContext(UserContext);

  const bulkSelectChange = ({ target: { name } }) => {
    switch (name) {
      case "inspectionReportQuestionsBulkSelect":
        forEach(inspectionReportQuestions, (_value, key) => {
          form.setFieldValue(
            `inspectionReportQuestions.${key}.enabled`,
            !allInspectionReportQuestionsSelected
          );
        });
        break;
      case "drawAssessmentQuestionsBulkSelect":
        forEach(drawAssessmentQuestions, (_value, key) => {
          form.setFieldValue(
            `drawAssessmentQuestions.${key}.enabled`,
            !allDrawAssessmentQuestionsSelected
          );
        });
      // no default
    }
  };

  return (
    <Accordion.Panel
      panelKey={panelKey}
      title="Form Configurations"
      onClick={() => onToggle(panelKey)}
      open={open}
      actionContent={
        <EditProjectSettingsButtons
          dirty={dirty}
          form={form}
          loading={loading}
        />
      }
      {...props}
    >
      <Pane display="flex">
        {hasInspectionReportQuestions &&
          hasPermission(PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW) && (
            <Pane marginRight={majorScale(4)} width="50%">
              <Text fontWeight={500}>Inspection Questions</Text>
              <Pane>
                <Link
                  name="inspectionReportQuestionsBulkSelect"
                  onClick={bulkSelectChange}
                  size={300}
                >
                  {allInspectionReportQuestionsSelected
                    ? "Deselect All"
                    : "Select All"}
                </Link>
              </Pane>
              {map(inspectionReportQuestions, (question, key) => (
                <Form.Checkbox
                  key={key}
                  name={`inspectionReportQuestions.${key}.enabled`}
                  label={question.label}
                />
              ))}
            </Pane>
          )}
        {hasDrawAssessmentQuestions &&
          hasPermission(PERMISSION_ACTION.DRAW_ASSESSMENT_QUESTIONS_FORM) && (
            <Pane width="50%">
              <Text fontWeight={500}>Draw Assessment Questions</Text>
              <Pane>
                <Link
                  name="drawAssessmentQuestionsBulkSelect"
                  onClick={bulkSelectChange}
                  size={300}
                >
                  {allDrawAssessmentQuestionsSelected
                    ? "Deselect All"
                    : "Select All"}
                </Link>
              </Pane>
              {map(drawAssessmentQuestions, (question, key) => (
                <Form.Checkbox
                  key={key}
                  name={`drawAssessmentQuestions.${key}.enabled`}
                  label={question.label}
                />
              ))}
            </Pane>
          )}
      </Pane>
    </Accordion.Panel>
  );
}
