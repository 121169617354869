import { AddIcon, InfoSignIcon } from "evergreen-ui";
import {
  Form,
  Link,
  MaskedInput,
  Pane,
  Table,
  Tooltip,
} from "components/materials";
import { FieldArray } from "formik";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";
import {
  getAmountDueThisRequisition,
  getEmptyLineItem,
  getLessPreviousPayments,
  getLessRetainedAmount,
  getMatchedLineItems,
  getTotalAmount,
  getTotalAmountDueToDate,
} from "helpers/hud92464Helpers";
import { LineItemFormBlankSlate } from "components/templates";
import { LineItemRow } from ".";

export function TableSection92464({
  collapsedPanels,
  disableForm,
  form,
  togglePanel,
  draw,
}) {
  const sectionName = "hud92464Values";
  const isCollapsed = collapsedPanels.includes(sectionName);

  const disableAddSplit = disableForm || form.values.__disabled;

  const matchedLineItems = getMatchedLineItems(
    form.values.lineItems,
    form.values.draw.lineItems
  );
  const total = getTotalAmount(form.values.lineItems);
  const lessPreviousPayments = getLessPreviousPayments(matchedLineItems);
  const lessRetained = getLessRetainedAmount(matchedLineItems);

  return (
    <FieldArray name="lineItems">
      {({ remove, push }) => (
        <Form.Section
          heading="Line Items"
          padding={0}
          isCollapsible
          isCollapsed={isCollapsed}
          onCollapse={() => togglePanel(sectionName)}
          action={
            <Link
              color={disableAddSplit ? "disabled" : "blue"}
              cursor={disableAddSplit ? "not-allowed" : "pointer"}
              purpose="document-review line-items add"
              onClick={() => {
                if (!disableAddSplit) {
                  const currentLineItems = form.values.lineItems;
                  const addedItemIndex = currentLineItems.length;
                  push(getEmptyLineItem(addedItemIndex));
                  if (isCollapsed) togglePanel("lineItems");
                }
                return {};
              }}
              size={300}
            >
              <AddIcon size={12} marginRight={majorScale(1)} />
              Add Line Item
            </Link>
          }
        >
          {form.values.lineItems.length < 1 ? (
            <LineItemFormBlankSlate
              addButtonText="Add Line Item"
              onAdd={() => push(getEmptyLineItem(form.values.lineItems.length))}
              text={t("agreementsReview.noLineItems")}
              containerProps={{ backgroundColor: "white", border: "muted" }}
            />
          ) : (
            <Table paddingBottom={0}>
              <Table.Head>
                <Table.Row>
                  <Table.TextHeaderCell width="40%">
                    Item or Construction Change Request Number
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell width="30%">
                    Amounts Completed (C)
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell width="30%">
                    HUD Approved Amount (D)
                  </Table.TextHeaderCell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {form.values.lineItems.map((lineItem, index) => {
                  return (
                    <LineItemRow
                      lineItem={lineItem}
                      key={index}
                      draw={draw}
                      disableForm={disableForm}
                      form={form}
                      sectionName={sectionName}
                      remove={remove}
                      index={index}
                    />
                  );
                })}
                <Table.Row>
                  <Table.TextCell>Total</Table.TextCell>
                  <Table.Cell>
                    <MaskedInput
                      disabled
                      name="total"
                      value={total}
                      type="currency"
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.TextCell>
                    <Pane>Less Retained</Pane>
                    <Tooltip content="Line Item retainage percent is set in Project Settings -> Line Item Settings">
                      <InfoSignIcon
                        color="info"
                        marginLeft={majorScale(1)}
                        size={minorScale(3)}
                      />
                    </Tooltip>
                  </Table.TextCell>
                  <Table.Cell>
                    <MaskedInput
                      disabled
                      name="lessRetained"
                      value={lessRetained}
                      type="currency"
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.TextCell>
                    Balance: Total Amount Due to Date
                  </Table.TextCell>
                  <Table.Cell>
                    <MaskedInput
                      disabled
                      name="totalAmountDueToDate"
                      value={getTotalAmountDueToDate(total, lessRetained)}
                      type="currency"
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.TextCell>Less Previous Payments</Table.TextCell>
                  <Table.Cell>
                    <MaskedInput
                      disabled
                      name="lessPreviousPayments"
                      value={lessPreviousPayments}
                      type="currency"
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.TextCell>
                    Net Amount Due this Requisition
                  </Table.TextCell>
                  <Table.Cell>
                    <MaskedInput
                      disabled
                      name="netAmountDue"
                      value={getAmountDueThisRequisition(
                        total,
                        lessPreviousPayments,
                        lessRetained
                      )}
                      type="currency"
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          )}
        </Form.Section>
      )}
    </FieldArray>
  );
}
