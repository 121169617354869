import { Fragment, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Banner,
  Button,
  Dropzone,
  Form,
  Heading,
  Pane,
  Paragraph,
  ScrollToFormikError,
  Text,
} from "components/materials";
import { VendorFormPartial } from "components/templates";
import { get, includes, reject, set, values } from "lodash";
import { UserContext } from "helpers/behaviors";
import {
  AGREEMENT_TYPE,
  CHANGE_ORDER_REASON,
  PERMISSION_ACTION,
} from "helpers/enums";
import { majorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import isBlank from "helpers/isBlank";
import { add, divide, subtract, sumBy } from "helpers/math";
import { dateServerToForm } from "helpers/dateHelpers";
import unformatNumber from "helpers/unformatNumber";
import { PROJECT_VENDOR_SEARCH_QUERY } from "helpers/queries";
import { CHANGE_ORDER_TYPES, PENDING_CHANGE_ORDER_TYPES } from "./enums";
import { PromptToUpgradeAgreement } from "./PromptToUpgradeAgreement";
import { ECOAdjustmentsFormSection } from "./ECOAdjustmentsFormSection";
import { AgreementLineItemFormSection } from "./AgreementLineItemFormSection";
import { AssociatedAgreementInvoicesTable } from "./AssociatedAgreementInvoicesTable";

function AgreementFormActions({
  formikProps: { dirty, submitForm, resetForm },
  saveLoading,
}) {
  return dirty ? (
    <Fragment>
      <Button
        appearance="default"
        marginRight={majorScale(1)}
        onClick={resetForm}
      >
        Undo
      </Button>
      <Button
        appearance="primary"
        onClick={submitForm}
        isLoading={saveLoading}
        marginRight={majorScale(1)}
      >
        Save
      </Button>
    </Fragment>
  ) : null;
}

export function AgreementForm({
  agreement,
  agreementLineItems,
  agreementVendorLineItems,
  formikProps,
  isAddAgreementModal,
  lineItems,
  projectId,
  saveLoading,
  showAgreementFormActions,
  vendors,
}) {
  const [getProjectVendorSearchQuery, projectVendorSearchQuery] = useLazyQuery(
    PROJECT_VENDOR_SEARCH_QUERY
  );

  const searchedVendors = get(
    projectVendorSearchQuery,
    "data.project.organization.paginatedVendors.results",
    []
  );

  const theme = useContext(ThemeContext);

  const { hasPermission } = useContext(UserContext);
  const canCreateAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );
  const trackingCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );

  const [newlyAddedVendors, setNewlyAddedVendors] = useState([]);

  const {
    agreementNumber,
    userTouchedName,
    vendor: { id: vendorId, name: vendorName },
  } = formikProps.values;

  useEffect(() => {
    const canAutofillNameField = agreementType && !userTouchedName;

    if (canAutofillNameField) {
      const agreementName = reject(
        [vendorName, formattedType, agreementNumber],
        isBlank
      ).join(" ");

      formikProps.setFieldValue("name", agreementName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementNumber, vendorId]);

  const hasAgreementManagement = !formikProps.values
    .agreementManagementDisabled;

  const {
    agreementType,
    budgetAdjustmentTransactions,
    isArtifact,
    promptUserToUpgrade,
  } = formikProps.values;
  const hasAdjustment = budgetAdjustmentTransactions.length > 0;
  const formattedType = t(`agreementType.${agreementType}`);
  const isECO = agreementType === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER;

  const showAssociatedAgreementInvoicesTable =
    !isAddAgreementModal &&
    trackingCostToAgreements &&
    agreement?.trackedAgreementAmounts?.length > 0;

  const {
    vendor: { id: initialVendorId },
  } = formikProps.initialValues;

  const vendorIdHasChanged = vendorId !== initialVendorId;

  if (promptUserToUpgrade)
    return <PromptToUpgradeAgreement form={formikProps} />;

  const showAgreementFields = hasAgreementManagement || isECO;
  const showChangeOrderFields =
    showAgreementFields && includes(CHANGE_ORDER_TYPES, agreementType);

  return (
    <Fragment>
      <ScrollToFormikError formikProps={formikProps} />
      {isAddAgreementModal &&
        includes(PENDING_CHANGE_ORDER_TYPES, agreementType) && (
          <Banner
            backgroundColor={theme.colors.lightPurple}
            icon="infoSignIcon"
            mainText={t("agreementsForm.pendingCostsBanner.main", {
              type: formattedType,
            })}
            marginBottom={majorScale(1)}
            marginTop={-majorScale(2)}
            marginX={-majorScale(2)}
            secondaryText={t("agreementsForm.pendingCostsBanner.secondary", {
              type: formattedType,
            })}
          />
        )}
      <Form>
        {showAgreementFormActions && (
          <Pane display="flex" height={36} justifyContent="flex-end">
            <AgreementFormActions
              formikProps={formikProps}
              saveLoading={saveLoading}
            />
          </Pane>
        )}
        <Pane display="flex" height="100%">
          <Pane
            {...(isAddAgreementModal
              ? { marginRight: majorScale(4) }
              : { width: "100%" })}
          >
            {showAgreementFields && (
              <Fragment>
                <Form.Group>
                  <Pane width={200} marginRight={35}>
                    <VendorFormPartial
                      agreementType={formattedType}
                      disableFields={isArtifact}
                      formikProps={formikProps}
                      getProjectVendorSearchQuery={getProjectVendorSearchQuery}
                      initialVendors={vendors}
                      newlyAddedVendors={newlyAddedVendors}
                      projectId={projectId}
                      searchedVendors={searchedVendors}
                      setNewlyAddedVendors={setNewlyAddedVendors}
                    />
                  </Pane>
                  <Pane marginLeft={majorScale(1)}>
                    <Form.DateInput
                      disabled={isArtifact}
                      label="Date"
                      name="date"
                      popperPlacement={
                        isAddAgreementModal ? "bottom-start" : "bottom-end"
                      }
                    />
                  </Pane>
                  <Pane marginLeft={majorScale(1)}>
                    <Form.Input
                      disabled={isArtifact}
                      name="agreementNumber"
                      label={`${
                        includes(CHANGE_ORDER_TYPES, agreementType)
                          ? "Change Order"
                          : formattedType
                      } Number`}
                    />
                  </Pane>
                </Form.Group>
              </Fragment>
            )}
            <Form.Group>
              <Form.Input
                disabled={isArtifact}
                name="name"
                label={`${
                  includes(CHANGE_ORDER_TYPES, agreementType)
                    ? "Change Order"
                    : formattedType
                } Name`}
                type="text"
                onChange={() => {
                  // onChange is only triggered when field is directly touched/typed into (NOT on autogenerate)
                  // so using this as a "has been touched"
                  formikProps.setFieldValue("userTouchedName", true);
                }}
              />
            </Form.Group>
            {showChangeOrderFields && (
              <Fragment>
                <Form.Group>
                  <Form.Select
                    disabled={isArtifact}
                    name="changeOrderReason"
                    label="Change Reason"
                    noNull
                    options={getChangeOrderReasonOptions()}
                  />
                  <Form.Input
                    disabled={isArtifact}
                    name="daysImpacted"
                    label="Days Impacted"
                    type="integer"
                  />
                </Form.Group>
              </Fragment>
            )}
            <Form.Group>
              <Form.TextArea
                disabled={isArtifact}
                label="Notes"
                name="agreementDescription"
              />
            </Form.Group>
            {hasAgreementManagement ? (
              <AgreementLineItemFormSection
                form={formikProps}
                lineItems={lineItems}
                agreementVendorLineItems={agreementVendorLineItems}
              />
            ) : (
              <Pane>
                {isECO && (
                  <Heading marginBottom={majorScale(1)}>
                    Contract Changes
                  </Heading>
                )}
                <Pane
                  background="#c4c4c4"
                  padding={majorScale(4)}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height={160}
                  borderRadius={majorScale(1)}
                >
                  <Paragraph>
                    Track contracts and create a change order log using
                    Rabbet&apos;s Agreements functionality.
                  </Paragraph>
                  <Paragraph>Contact Customer Success to learn more.</Paragraph>
                </Pane>
              </Pane>
            )}
            {isECO && (canCreateAdjustments || hasAdjustment) && (
              <ECOAdjustmentsFormSection
                disableForm={!canCreateAdjustments}
                formikProps={formikProps}
                lineItems={lineItems}
              />
            )}
            {showAssociatedAgreementInvoicesTable && (
              <AssociatedAgreementInvoicesTable
                agreement={agreement}
                agreementLineItems={agreementLineItems}
                errors={formikProps.errors}
                projectId={projectId}
                vendorIdHasChanged={vendorIdHasChanged}
              />
            )}
          </Pane>
          {isAddAgreementModal && (
            <Pane width="50%">
              <Pane marginY={majorScale(1)}>
                <Text>Upload Agreement (optional)</Text>
              </Pane>
              <Dropzone
                allowMultiple={false}
                files={formikProps.values.file ? [formikProps.values.file] : []}
                onAdd={(files) => {
                  formikProps.setFieldValue("file", files[0]);
                }}
                onRemove={() => {
                  formikProps.setFieldValue("file", null);
                }}
                uploadTypeMain="agreement"
                uploadTypeButton="File"
                containerProps={{ flexDirection: "column", height: "55vh" }}
                textProps={{}}
              />
            </Pane>
          )}
        </Pane>
      </Form>
    </Fragment>
  );
}

function getChangeOrderReasonOptions() {
  return values(CHANGE_ORDER_REASON).map((reason) => ({
    key: reason,
    value: reason,
    text: t(`changeOrderReason.${reason}`),
  }));
}

export function getEmptyAgreementLineItem() {
  return {
    lineItemId: null,
    amount: formatCurrency(0),
    initialAmount: formatCurrency(0),
    retainagePercentage: formatPercent(0),
  };
}

export function formatAgreementLineItems(agreementLineItems) {
  return agreementLineItems.map(
    ({ amount, lineItemId, retainagePercentage }) => {
      return {
        amount,
        lineItemId,
        retainagePercentage: divide(parseFloat(retainagePercentage), 100),
      };
    }
  );
}
export function formatTransactions(budgetAdjustmentTransactions) {
  return budgetAdjustmentTransactions.map(
    ({ amount, id, lineItem: { id: lineItemId } }) => ({
      amount,
      id,
      lineItemId,
    })
  );
}

export function formatNewLineItems(budgetAdjustmentTransactions) {
  return budgetAdjustmentTransactions
    .filter(({ isNewLineItem }) => isNewLineItem)
    .map(
      ({
        lineItem: {
          id,
          name,
          number,
          division: { id: divisionId },
          summaryLineItem,
          masterFormatDivision,
        },
      }) => ({
        id,
        name: name.trim(),
        divisionId,
        number: number.trim() || null,
        superLineItem: summaryLineItem.trim() || null,
        masterFormatDivision,
      })
    );
}

export function getInitialValues({
  existingAgreement,
  isUpgradeFlow,
  lineItems,
  projectId,
  drawId,
  newAgreementType,
}) {
  const totalFromInvoices = sumBy(
    existingAgreement?.trackedAgreementAmounts || [],
    "amount"
  );
  const preparedLineItems = lineItems.map((lineItem) => {
    // this calculation is needed to show up-to-date current budget + adjustment = new budget info while editing
    // i.e. get the current budget without any of the adjustments (ali) that are on this change order
    const adjustmentsThisLineItemOnThisAgreementTotal = get(
      existingAgreement,
      "budgetAdjustment.transactions",
      []
    )
      .filter((transaction) => get(transaction, "lineItem.id") === lineItem.id)
      .reduce((total, { amount }) => add(total, amount), 0);

    return {
      ...lineItem,
      budgetAmount: subtract(
        lineItem.budgetAmount,
        adjustmentsThisLineItemOnThisAgreementTotal
      ),
    };
  });

  const agreementLineItems = existingAgreement
    ? existingAgreement.lineItems.map(
        ({ lineItemId, amount, retainagePercentage }) => ({
          lineItemId,
          amount: formatCurrency(amount),
          retainagePercentage: formatPercent(retainagePercentage),
          initialAmount: formatCurrency(amount),
          initialLineItemId: lineItemId,
        })
      )
    : [];

  const budgetAdjustmentTransactions = get(
    existingAgreement,
    "budgetAdjustment.transactions",
    []
  ).map((transaction) => ({
    ...transaction,
    amount: formatCurrency(transaction.amount),
  }));

  return {
    projectId,
    agreementType: newAgreementType || existingAgreement.type || null,
    budgetAdjustmentTransactions,
    correlationId: get(existingAgreement, "correlationId", null),
    drawId: drawId || get(existingAgreement, "budgetAdjustment.drawId", null),
    vendor: get(existingAgreement, "vendor") || { id: null },
    date: dateServerToForm(get(existingAgreement, "startDate")),
    agreementNumber: get(existingAgreement, "agreementNumber") || "",
    name: get(existingAgreement, "name") || "",
    changeOrderReason: get(
      existingAgreement,
      "changeOrderReason",
      CHANGE_ORDER_REASON.UNSPECIFIED
    ),
    daysImpacted: get(existingAgreement, "daysImpacted", ""),
    userTouchedName: isUpgradeFlow
      ? false
      : get(existingAgreement, "userTouchedName") || false,
    agreementDescription: get(existingAgreement, "agreementDescription") || "",
    file: null,
    preparedLineItems,
    agreementLineItems,
    existingAgreementType: get(existingAgreement, "type"),
    moveDocumentToDraw:
      !!drawId ||
      (existingAgreement &&
        existingAgreement.correlatedDocuments.some(
          ({ type }) => type === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
        )),
    totalFromInvoices,
  };
}

export function validateAgreementForm(values, hasPermission) {
  const errors = {};
  if (values.promptUserToUpgrade) {
    set(errors, "promptUserToUpgrade", "Please make a selection");
  }
  // no form validation for agreements other than ECO IF agreement management is disabled
  if (
    values.agreementManagementDisabled &&
    values.agreementType !== AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
  ) {
    return errors;
  }

  // skip form validation for artifact agreements, which are not editable and can have 0 line items
  if (values.isArtifact) {
    return errors;
  }

  values.agreementLineItems.forEach(({ amount }, index) => {
    if (isBlank(amount)) {
      set(errors, `agreementLineItems.${index}.amount`, "Please add an amount");
    }
  });

  values.budgetAdjustmentTransactions.forEach(({ lineItem, amount }, index) => {
    if (isBlank(amount) || unformatNumber(amount) === 0) {
      set(
        errors,
        `budgetAdjustmentTransactions.${index}.amount`,
        "Please add an amount"
      );
    }
    if (lineItem === null) {
      set(
        errors,
        `budgetAdjustmentTransactions.${index}.lineItem.id`,
        "Please select a line item"
      );
    }
  });

  if (
    !PENDING_CHANGE_ORDER_TYPES.includes(values.agreementType) &&
    isBlank(values.vendor.id)
  ) {
    set(errors, "vendor.id", "Please select a vendor");
  }

  if (isBlank(values.name)) {
    set(errors, "name", "Name is required.");
  }

  return errors;
}
