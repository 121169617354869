import { useState, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Card,
  Confirm,
  Form,
  Link,
  Pane,
  Text,
  Paragraph,
} from "components/materials";
import { Formik } from "formik";
import { EditIcon, ShareIcon } from "evergreen-ui";
import isBlank from "helpers/isBlank";
import analytics from "helpers/analytics";
import { set } from "lodash";
import { majorScale, minorScale } from "helpers/utilities";
import { formatTrimDate } from "helpers/dateHelpers";
import { DirtyEffect } from "helpers/behaviors";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import unformatNumber from "helpers/unformatNumber";
import { hover } from "glamor";
import {
  AdjustmentTransactions,
  getEmptyTransaction,
} from "./AdjustmentTransactions";
import {
  CREATE_ADJUSTMENT,
  UPDATE_ADJUSTMENT,
  DELETE_ADJUSTMENT,
} from "./graphql-queries";

export function getInitialAdjustment(adjustment) {
  if (!adjustment)
    return {
      comment: "",
      transactions: [getEmptyTransaction()],
    };

  const { id: adjustmentId, comment, transactions } = adjustment;

  return {
    adjustmentId,
    comment,
    transactions: transactions.map(({ amount, lineItem }) => ({
      amount: formatCurrency(amount),
      initialAmount: amount,
      lineItemId: lineItem.id,
      isNewLineItem: false,
    })),
  };
}

export function validate(values) {
  const errors = {};

  values.transactions.forEach((transaction, transactionIndex) => {
    if (isBlank(transaction.lineItemId)) {
      set(
        errors,
        `transactions.${transactionIndex}.lineItemId`,
        "Please choose a line item"
      );
    }
    if (
      isBlank(transaction.amount) ||
      unformatNumber(transaction.amount) === 0
    ) {
      set(
        errors,
        `transactions.${transactionIndex}.amount`,
        "Please enter a non-zero amount"
      );
    }
  });

  return errors;
}

function isNewLineItem(transaction) {
  return !!transaction.isNewLineItem;
}

function trackAdjustment(changeOrder, projectId, drawId) {
  if (changeOrder) {
    analytics.track("Executed Change Order Saved", {
      projectId,
      drawId,
      ECOId: changeOrder.id,
    });
  } else {
    analytics.track("Budget Adjustment Saved", {
      projectId,
      drawId,
    });
  }
}

function formatTransactionsandNewLineItems(transactions) {
  return {
    transactions: transactions.map(({ lineItemId, amount }) => ({
      lineItemId,
      amount,
    })),
    newLineItems: transactions
      .filter(({ isNewLineItem }) => isNewLineItem)
      .map(
        ({
          lineItemId,
          lineItemName,
          lineItemNumber,
          divisionId,
          summaryLineItem,
          masterFormatDivision,
        }) => ({
          id: lineItemId,
          divisionId,
          name: lineItemName.trim(),
          number: lineItemNumber.trim() || null,
          superLineItem: summaryLineItem.trim() || null,
          masterFormatDivision,
        })
      ),
  };
}
export function getAdjustmentValues({
  adjustmentId,
  drawId,
  projectId,
  comment,
  transactions,
}) {
  // adjustmentId for update, drawId for create
  const id = {
    ...(adjustmentId ? { adjustmentId } : {}),
    ...(drawId ? { drawId } : {}),
  };

  return {
    ...id,
    projectId,
    comment,
    ...formatTransactionsandNewLineItems(transactions),
  };
}

function EditAdjustment({ beginEdit, disableEdits, showEditIcon }) {
  const editProps = {
    cursor: disableEdits ? "not-allowed" : "pointer",
    onClick: () => (disableEdits ? null : beginEdit()),
    iconColor: disableEdits ? "disabled" : "selected",
    textColor: disableEdits ? "muted" : "selected",
    textDecoration: disableEdits ? "none" : "underline",
  };
  return showEditIcon ? (
    <Pane
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor={editProps.cursor}
      onClick={editProps.onClick}
    >
      <EditIcon
        appearance="minimal"
        color={editProps.iconColor}
        marginRight={minorScale(1)}
      />
      <Text
        size={300}
        color={editProps.textColor}
        className={hover({ textDecoration: editProps.textDecoration })}
      >
        edit
      </Text>
    </Pane>
  ) : null;
}

function ChangeOrderInformation({ changeOrder, getChangeOrderUrl }) {
  const {
    agreementNumber,
    daysImpacted,
    agreementDescription,
    startDate,
    vendor,
    document,
  } = changeOrder;
  const documentName = document?.file?.name;
  const changeOrderName = vendor
    ? `${vendor.name}_Executed_Change_Order`
    : "Executed_Change_Order";

  return (
    <Pane>
      <Paragraph
        color="muted"
        size={300}
        fontWeight={500}
        marginBottom={minorScale(1)}
      >
        Change Order
      </Paragraph>
      <Pane
        padding={majorScale(2)}
        background="#F4F5F9"
        minHeight={150}
        borderRadius={majorScale(1)}
      >
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={majorScale(1)}
        >
          {vendor && (
            <Text size={400} fontWeight={500}>
              {vendor.name}
            </Text>
          )}
          <Text>{formatTrimDate(startDate)}</Text>
        </Pane>
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={majorScale(1)}
        >
          {agreementNumber !== null && (
            <Pane>
              <Paragraph color="muted" size={300} fontWeight={500}>
                Change Order Number
              </Paragraph>
              <Paragraph>{agreementNumber}</Paragraph>
            </Pane>
          )}
          {daysImpacted !== null && (
            <Pane>
              <Paragraph color="muted" size={300} fontWeight={500}>
                Days Impacted
              </Paragraph>
              <Paragraph>{daysImpacted}</Paragraph>
            </Pane>
          )}
        </Pane>
        <Paragraph color="muted" marginBottom={majorScale(1)}>
          {agreementDescription}
        </Paragraph>
        <Link
          href={getChangeOrderUrl(changeOrder)}
          size={300}
          display="inline-flex"
          alignItems="center"
        >
          {documentName || changeOrderName}
          <ShareIcon size="12" marginLeft={minorScale(1)} marginBottom={2} />
        </Link>
      </Pane>
    </Pane>
  );
}

function AdjustmentInformation({ editAdjustment, comment }) {
  if (editAdjustment)
    return (
      <Form.TextArea
        height={175}
        name="comment"
        placeholder="Enter a description (optional)"
        size={300}
      />
    );

  return (
    <Paragraph size={300} fontStyle={comment ? undefined : "italic"}>
      {comment || "No description saved for this adjustment"}
    </Paragraph>
  );
}

function FooterActions({
  adjustment,
  editAdjustment,
  finishEdit,
  formik,
  loading,
  projectId,
  drawId,
  changeOrder,
  onCloseCreate,
  setFormDirty,
  setHasChangesAffectingProjection,
  setShowConfirmDelete,
}) {
  if (!adjustment)
    return (
      <Fragment>
        <Button
          marginRight={minorScale(3)}
          onClick={() => {
            setFormDirty(false);
            setHasChangesAffectingProjection(false);
            onCloseCreate();
          }}
        >
          Cancel
        </Button>
        <Button
          appearance="primary"
          isLoading={loading.createLoading}
          onClick={() => {
            formik.handleSubmit();
            trackAdjustment(changeOrder, projectId, drawId);
          }}
        >
          Create Adjustment
        </Button>
      </Fragment>
    );

  if (editAdjustment)
    return (
      <Fragment>
        <Button
          isLoading={loading.deleteLoading}
          onClick={() => setShowConfirmDelete(true)}
          marginRight={minorScale(3)}
          intent="danger"
        >
          Delete
        </Button>
        <Button
          onClick={() => {
            formik.handleReset();
            setHasChangesAffectingProjection(false);
            finishEdit();
          }}
          marginRight={minorScale(3)}
        >
          Cancel
        </Button>
        <Button
          isLoading={loading.updateLoading}
          onClick={() => {
            formik.handleSubmit();
            trackAdjustment(changeOrder, projectId, drawId);
          }}
          appearance={formik.dirty ? "primary" : "default"}
        >
          Save
        </Button>
      </Fragment>
    );

  return null;
}

export function AdjustmentCard({
  adjustment,
  canMakeAdjustments,
  disableEdits,
  divisions,
  drawId,
  frozenProps,
  getChangeOrderUrl,
  lineItemAdjustmentIds,
  lineItems,
  onCloseCreate,
  projectId,
  refetchOptions,
  setEditInProgress,
  setFormDirty,
}) {
  const [editAdjustment, setEditAdjustment] = useState(!adjustment);
  const [
    hasChangesAffectingProjection,
    setHasChangesAffectingProjection,
  ] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showProjectionWarning, setShowProjectionWarning] = useState(false);
  const [showConfirmNewLineItem, setShowConfirmNewLineItem] = useState(false);
  const hasProjection = lineItems.some(({ projection }) => !!projection);

  const beginEdit = () => {
    setEditAdjustment(true);
    setEditInProgress(true);
  };

  const finishEdit = () => {
    setEditAdjustment(false);
    setEditInProgress(false);
  };

  const { frozenError, frozenSubmit } = frozenProps;

  const [createAdjustment, { loading: createLoading }] = useMutation(
    CREATE_ADJUSTMENT,
    {
      ...refetchOptions,
      onCompleted: onCloseCreate,
      onError: frozenError,
    }
  );

  const [updateAdjustment, { loading: updateLoading }] = useMutation(
    UPDATE_ADJUSTMENT,
    {
      ...refetchOptions,
      onCompleted: finishEdit,
      onError: frozenError,
    }
  );

  const [deleteAdjustment, { loading: deleteLoading }] = useMutation(
    DELETE_ADJUSTMENT,
    {
      ...refetchOptions,
      onCompleted: () => setEditInProgress(false),
      onError: frozenError,
    }
  );

  const handleCreate = (values) =>
    createAdjustment({
      variables: getAdjustmentValues({ ...values, projectId, drawId }),
    });

  const handleUpdate = (values) =>
    updateAdjustment({
      variables: getAdjustmentValues({ ...values, projectId }),
    });

  const handleDelete = (adjustmentId) =>
    deleteAdjustment({
      variables: { adjustmentId },
    });

  const submitFunction = !adjustment ? handleCreate : handleUpdate;
  const frozenSubmitFunction = frozenSubmit(submitFunction);
  const frozenDelete = frozenSubmit(handleDelete);

  const handleSubmit = useCallback(
    (values) => {
      const hasNewLineItems = values.transactions.some(isNewLineItem);

      if (hasNewLineItems) {
        setShowConfirmNewLineItem(true);
        return;
      }

      if (hasProjection && hasChangesAffectingProjection) {
        setShowProjectionWarning(true);
        return;
      }

      frozenSubmitFunction(values);
    },
    [
      setShowConfirmNewLineItem,
      hasProjection,
      hasChangesAffectingProjection,
      setShowProjectionWarning,
      frozenSubmitFunction,
    ]
  );

  const hasChangeOrder = !!adjustment?.agreement;

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialAdjustment(adjustment)}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {(formik) => {
        const newLineItems = formik.values.transactions.filter(isNewLineItem);
        return (
          <Form>
            <Fragment>
              <DirtyEffect dirty={formik.dirty} setFormDirty={setFormDirty} />
              <Card
                marginX={majorScale(4)}
                marginBottom={majorScale(2)}
                padding={majorScale(2)}
                maxWidth={1450}
              >
                <Pane display="flex" flexDirection="column">
                  <Pane display="flex">
                    <Pane flex="0 0 300px" paddingRight={majorScale(2)}>
                      <Fragment>
                        {hasChangeOrder ? (
                          <ChangeOrderInformation
                            changeOrder={adjustment.agreement}
                            getChangeOrderUrl={getChangeOrderUrl}
                          />
                        ) : (
                          <AdjustmentInformation
                            editAdjustment={editAdjustment}
                            comment={formik.values.comment}
                          />
                        )}
                      </Fragment>
                    </Pane>
                    <Pane flex="1 1 auto" marginTop={-majorScale(1)}>
                      <AdjustmentTransactions
                        adjustment={adjustment}
                        divisions={divisions}
                        formik={formik}
                        isEditable={editAdjustment}
                        lineItems={lineItems}
                        lineItemAdjustmentIds={lineItemAdjustmentIds}
                        setHasChangesAffectingProjection={
                          setHasChangesAffectingProjection
                        }
                        transactions={formik.values.transactions}
                      />
                    </Pane>
                    <Pane flex="0 0 75px">
                      <EditAdjustment
                        beginEdit={beginEdit}
                        disableEdits={disableEdits}
                        showEditIcon={canMakeAdjustments && !editAdjustment}
                      />
                    </Pane>
                  </Pane>
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <FooterActions
                      adjustment={adjustment}
                      editAdjustment={editAdjustment}
                      finishEdit={finishEdit}
                      formik={formik}
                      drawId={drawId}
                      projectId={projectId}
                      changeOrder={adjustment?.agreement}
                      loading={{ createLoading, updateLoading, deleteLoading }}
                      onCloseCreate={onCloseCreate}
                      setFormDirty={setFormDirty}
                      setHasChangesAffectingProjection={
                        setHasChangesAffectingProjection
                      }
                      setShowConfirmDelete={setShowConfirmDelete}
                    />
                  </Pane>
                </Pane>
              </Card>
              {showConfirmNewLineItem && (
                <Confirm
                  open
                  header={t("createNewLineItem.header", {
                    count: newLineItems.length,
                  })}
                  content={
                    <Pane>
                      <Paragraph marginBottom={majorScale(2)}>
                        {t("createNewLineItem.info", {
                          count: newLineItems.length,
                        })}
                      </Paragraph>
                      {newLineItems.map(
                        ({ lineItemId, lineItemName, amount }) => (
                          <Paragraph key={lineItemId}>
                            {t("createNewLineItem.lineItemInfo", {
                              lineItemName,
                              amount,
                            })}
                          </Paragraph>
                        )
                      )}
                    </Pane>
                  }
                  onConfirm={(close) => {
                    close();
                    if (hasProjection && hasChangesAffectingProjection) {
                      setShowProjectionWarning(true);
                    } else {
                      frozenSubmitFunction(formik.values);
                    }
                  }}
                  onCloseComplete={() => setShowConfirmNewLineItem(false)}
                />
              )}
              {showProjectionWarning && (
                <Confirm
                  open
                  header="Warning"
                  content={t("projection.budgetChangeWarning")}
                  onConfirm={(close) => {
                    close();
                    frozenSubmitFunction(formik.values);
                  }}
                  onCloseComplete={() => setShowProjectionWarning(false)}
                />
              )}
              {showConfirmDelete && (
                <Confirm
                  open
                  content={`Are you sure you want to delete this budget adjustment? This action is irreversible.${
                    hasProjection ? " This may affect your Projection." : ""
                  }`}
                  header="Delete Budget Adjustment"
                  onConfirm={(close) => {
                    frozenDelete(adjustment.id);
                    close();
                  }}
                  onCloseComplete={() => setShowConfirmDelete(false)}
                  cancelLabel="Cancel"
                  confirmLabel="Delete Adjustment"
                />
              )}
            </Fragment>
          </Form>
        );
      }}
    </Formik>
  );
}

AdjustmentCard.defaultProps = {
  onCloseCreate: () => null,
};

AdjustmentCard.propTypes = {
  onCloseCreate: PropTypes.func,
};
