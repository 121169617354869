import { Fragment } from "react";
import {
  FundingSourceProjectionChart,
  FundingSourceProjectionTable,
  ProjectionTableActions,
} from "components/templates";
import { Loadable, Pane } from "components/materials";
import { NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE } from "hooks/use-projections";
import {
  getHasCompleteProjection,
  getTotalBudgetAmount,
  getTotalFundingSourcesAmount,
  isValidInsufficientFunds,
} from "helpers/projectionHelpers";
import { FundingSourceChartControl } from "./FundingSourceChartControl";
import { FundingSourceProjectionWarning } from "./FundingSourceProjectionWarning";

function shouldShowUsesOfFundsWarning({
  divisions,
  fundingSources,
  insufficientFunds,
  project,
}) {
  const { automaticAllocationEnabled, usesOfFundsEnabled } = project;
  const totalBudget = getTotalBudgetAmount(divisions);
  const totalFunding = getTotalFundingSourcesAmount(fundingSources);
  const budgetFullyFunded = totalFunding >= totalBudget;
  const projectionFullyConfigured = getHasCompleteProjection(divisions);
  const hasUnderDisbursedFundingSource = fundingSources.some(
    ({ unprojectedFunds }) => unprojectedFunds > 0
  );

  return (
    automaticAllocationEnabled &&
    usesOfFundsEnabled &&
    budgetFullyFunded &&
    projectionFullyConfigured &&
    isValidInsufficientFunds(insufficientFunds) &&
    hasUnderDisbursedFundingSource
  );
}

export function FundingSourceProjectionsView({
  fundingSourceChartId,
  handleProjectionsChange,
  isSaving,
  loadingFundingSources,
  projection,
}) {
  const showChartControls =
    projection.state === NEW_ACTUALS_SINCE_LAST_UPDATED_PROJECTION_STATE;
  const showUsesOfFundsWarning = shouldShowUsesOfFundsWarning(projection);
  const { showProjectionCharts } = projection.views;

  return (
    <Fragment>
      <FundingSourceProjectionWarning
        projection={projection}
        showUsesOfFundsWarning={showUsesOfFundsWarning}
      />
      {showProjectionCharts && (
        <Fragment>
          {showChartControls && (
            <FundingSourceChartControl
              handleProjectionsChange={handleProjectionsChange}
            />
          )}
          <Pane position="relative" height="375px" marginX="80px">
            {loadingFundingSources ? (
              <Loadable loading />
            ) : (
              <FundingSourceProjectionChart
                chartId={fundingSourceChartId}
                projection={projection}
              />
            )}
          </Pane>
        </Fragment>
      )}
      <ProjectionTableActions
        chartId={fundingSourceChartId}
        isLoading={isSaving}
        onChange={handleProjectionsChange}
        projection={projection}
      />
      <FundingSourceProjectionTable
        projection={projection}
        showUsesOfFundsWarning={showUsesOfFundsWarning}
      />
    </Fragment>
  );
}
