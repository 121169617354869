import PropTypes from "prop-types";
import { FastField } from "formik";
import { TextInputField } from "components/materials";

export function FastInput({ name, onChange, ...rest }) {
  const handleChange = (field) => (e) => {
    field.onChange(e);
    onChange && onChange(e);
  };

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <TextInputField
          {...field}
          autoComplete="new-password"
          margin={0}
          onChange={handleChange(field)}
          validationMessage={meta && meta.touched && meta.error}
          {...rest}
        />
      )}
    </FastField>
  );
}

FastInput.propTypes = {
  name: PropTypes.string.isRequired,
};
