import { useContext, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ArrowRightIcon } from "evergreen-ui";
import {
  ConfigureButtons,
  Link,
  Pane,
  Text,
  Shortener,
  EmptyCard,
} from "components/materials";
import { flatten, intersectionBy, uniq } from "lodash";
import { add } from "helpers/math";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { trackClickthrough } from "./helpers";
import { BlankSlate } from "./BlankSlate";
import { PORTFOLIO_VENDORS_QUERY } from "./graphql";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";

export const PROJECTS_BY_VENDOR_CONFIGURATION_SETTINGS = {
  i: "projectsByVendor",
  x: 0,
  y: 8,
  w: 1,
  h: 1,
  disabled: false,
};

function VendorCount({
  activeProjectCount,
  href,
  maxNumProjects,
  name,
  organizationId,
  theme,
}) {
  return (
    <Pane width="100%" display="flex" marginBottom={majorScale(2)}>
      <Pane
        display="flex"
        alignItems="center"
        cursor="pointer"
        width="40%"
        marginRight={majorScale(2)}
      >
        <Pane
          is={Link}
          onClick={() =>
            trackClickthrough(
              "Projects by Vendor to Organizations",
              organizationId,
              { vendor: name }
            )
          }
          to={href}
        >
          <Shortener
            color={theme.colors.linkBlue}
            limit={20}
            size={200}
            text={name}
          />
        </Pane>
        <ArrowRightIcon
          color={theme.colors.linkBlue}
          icon={ArrowRightIcon}
          appearance="minimal"
          marginLeft="auto"
        />
      </Pane>
      <Pane width="60%">
        <Pane
          display="flex"
          alignItems="center"
          width={`${(activeProjectCount / maxNumProjects) * 100}%`}
        >
          <Pane
            paddingX={minorScale(1)}
            display="flex"
            alignItems="center"
            width="100%"
            height={30}
            background={theme.colors.barChartBlue}
          />
          <Pane marginLeft={minorScale(1)}>
            <Text color={theme.defaultColors.textMedium}>
              {activeProjectCount}
            </Text>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function ProjectsByVendor({
  cards,
  isConfigurable,
  isDisabled,
  name,
  organization,
  projects,
  setCards,
}) {
  const theme = useContext(ThemeContext);

  const useVendorsQuery = () =>
    useQuery(PORTFOLIO_VENDORS_QUERY, {
      variables: { organizationId: organization.id },
    });

  const useVendors = useCallback(useVendorsQuery, [organization.id]);

  const { data, loading } = useVendors();

  if (loading) {
    return (
      <EmptyCard
        cardName={name}
        cards={cards}
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        name="Projects By Vendor"
        setCards={setCards}
      >
        <Text>Loading...</Text>
      </EmptyCard>
    );
  }

  const organizationId = organization.id;

  const filteredProjects = intersectionBy(
    data.organization.projects,
    projects,
    "id"
  );

  const vendors = flatten(filteredProjects.map((project) => project.vendors));

  const countForVendor = vendors.reduce(
    (acc, vendor) => ({
      ...acc,
      [vendor.id]: acc[vendor.id] ? add(acc[vendor.id], 1) : 1,
    }),
    {}
  );

  const uniqueVendors = uniq(vendors);
  const sortedVendors = [...uniqueVendors].sort(
    (vendorA, vendorB) =>
      countForVendor[vendorB.id] - countForVendor[vendorA.id]
  );

  const maxNumProjects =
    sortedVendors.length === 0 ? 0 : countForVendor[sortedVendors[0].id];

  const showBlankSlate = vendors.length === 0;

  return (
    <Pane
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      padding={majorScale(2)}
    >
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Projects By Vendor" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <CardLinkButton
            href={`/organizations?referrerSelectedOrgId=${organizationId}`}
          />
        )}
      </Pane>
      {showBlankSlate ? (
        <BlankSlate />
      ) : (
        <Pane height="80%" paddingTop={majorScale(2)}>
          <Pane height="100%" overflowY="scroll">
            {sortedVendors.map((vendor) => {
              const { id: vendorId } = vendor;
              const href = `/organizations/${vendorId}?referrerSelectedOrgId=${organizationId}`;

              return (
                <VendorCount
                  activeProjectCount={countForVendor[vendor.id]}
                  href={href}
                  key={vendor.id}
                  maxNumProjects={maxNumProjects}
                  name={vendor.name}
                  organizationId={organizationId}
                  theme={theme}
                />
              );
            })}
          </Pane>
        </Pane>
      )}
    </Pane>
  );
}
