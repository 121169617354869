import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import {
  DocumentIcon,
  ErrorIcon,
  FolderCloseIcon,
  PanelTableIcon,
} from "evergreen-ui";
import { Link, LinkButton, Spinner } from "components/materials";
import { NavigationWarnings } from "helpers/behaviors";
import { majorScale, toaster } from "helpers/utilities";
import { download, getUrl } from "helpers/downloadHelpers";
import { useAuth } from "../../../Auth";

function getIcon(iconName) {
  if (iconName === "panel-table") {
    return <PanelTableIcon marginRight={majorScale(1)} />;
  }
  if (iconName === "document") {
    return <DocumentIcon marginRight={majorScale(1)} />;
  }
  return <FolderCloseIcon marginRight={majorScale(1)} />;
}

const renderIcon = (error, hideSpinner, loading, iconName) => {
  if (!iconName) return null;
  if (loading && !hideSpinner)
    return (
      <Spinner display="inline-block" marginRight={majorScale(1)} size={18} />
    );
  if (error) return <ErrorIcon color="danger" marginRight={majorScale(1)} />;
  return getIcon(iconName);
};

export function showDownloadErrorToast() {
  toaster.danger("We were not able to generate your draw package", {
    description:
      "Please try reducing the size of your draw package or contact support@rabbet.com for more help.",
  });
}

export function showDownloadTimeoutToast() {
  toaster.notify(
    "Your download is taking a while. We'll email you when it's ready.",
    { duration: 60, hasCloseButton: true }
  );
}

function DownloadLink({
  disabled,
  filename,
  hideSpinner,
  iconName,
  isButton,
  isLoading: propsLoading,
  label,
  onError,
  onSuccess,
  onTimeout,
  url,
  ...rest
}) {
  const auth = useAuth();
  const authenticatedUrl = auth.getAuthenticatedUrl(url);

  const [{ error, loading }, setStatus] = useState({
    error: false,
    loading: false,
  });

  const isLoading = propsLoading || loading;

  const urlToUse = url.startsWith("blob") ? url : authenticatedUrl;

  const handleClick = () => {
    setStatus({ error: false, loading: true });

    download({
      filename,
      url: urlToUse,
      onSuccess: ({ blob, filename }) => {
        getUrl(blob, filename);
        setStatus({ error: false, loading: false });
        onSuccess && onSuccess({ blob, filename });
      },
      onTimeout: (response) => {
        setStatus({ error: true, loading: false });
        if (onTimeout) {
          onTimeout(response);
        } else {
          showDownloadTimeoutToast();
        }
      },
      onError: (response) => {
        setStatus({ error: true, loading: false });
        if (onError) {
          onError(response);
        } else {
          showDownloadErrorToast();
        }
      },
    });
  };

  const LinkComponent = isButton ? LinkButton : Link;

  return (
    <Fragment>
      <NavigationWarnings dirty={loading} />
      <LinkComponent
        disabled={disabled || loading}
        display="flex"
        isLoading={isLoading}
        onClick={disabled ? null : handleClick}
        {...rest}
      >
        {renderIcon(error, hideSpinner, loading, iconName)}
        {label}
      </LinkComponent>
    </Fragment>
  );
}

DownloadLink.propTypes = {
  filename: PropTypes.string,
  hideSpinner: PropTypes.bool,
  iconName: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

DownloadLink.defaultProps = {
  hideSpinner: false,
  filename: undefined,
};

export default DownloadLink;
