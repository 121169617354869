import { Pane, Button } from "components/materials";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { Header } from "./Header";

export function ConfirmProjectSetupStep({ goBack, handleSubmit }) {
  return (
    <Pane>
      <Header header={t("onboardingWizard.confirmProjectSetup.header")} />
      <Pane display="flex" marginBottom={majorScale(3)}>
        <Button
          appearance="default"
          marginRight={majorScale(2)}
          onClick={() => {
            goBack();
          }}
          width="20%"
        >
          Back
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            handleSubmit();
          }}
          width="20%"
        >
          Complete Project Creation
        </Button>
      </Pane>
    </Pane>
  );
}
