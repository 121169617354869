import { Form, Table } from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { add } from "helpers/math";
import { parseCurrencyToFloat } from "helpers/parseCurrencyToFloat";
import isBlank from "helpers/isBlank";
import { LineItemRow, TableHeaders, LINE_ITEMS } from ".";
import { getNetAmount } from "./helpers";

export function BreakdownItemsSection({
  collapsedPanels,
  disabled,
  form,
  matchedDrawLineItems,
  togglePanel,
}) {
  const sectionName = "breakdownItems";
  const isCollapsed = collapsedPanels.includes(sectionName);
  return (
    <Form.Section
      heading="Breakdown Items"
      padding={0}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={() => togglePanel(sectionName)}
    >
      <Table paddingBottom={0}>
        <TableHeaders />
        <Table.Body>
          {Object.entries(LINE_ITEMS).map(
            ([lineItemFieldName, { hasColumnA, name, isLineItem }]) => {
              return (
                <LineItemRow
                  key={lineItemFieldName}
                  disabled={disabled}
                  drawLineItem={matchedDrawLineItems[lineItemFieldName]}
                  form={form}
                  hasColumnA={hasColumnA}
                  isLineItem={isLineItem}
                  lineItemFieldName={lineItemFieldName}
                  lineItemName={name}
                  sectionName={sectionName}
                />
              );
            }
          )}
        </Table.Body>
        <TableFooter form={form} matchedDrawLineItems={matchedDrawLineItems} />
      </Table>
    </Form.Section>
  );

  function TableFooter({ form, matchedDrawLineItems }) {
    const {
      columnATotal,
      columnBTotal,
      columnCTotal,
      previousAmountTotal,
      netAmountTotal,
    } = Object.entries(form.values.breakdownItems).reduce(
      (acc, [name, { columnA, columnB, columnC }]) => {
        const sanitizedColumnA = parseCurrencyToFloat(columnA);
        const sanitizedColumnB = parseCurrencyToFloat(columnB);
        const sanitizedColumnC = parseCurrencyToFloat(columnC);
        // a value in column C is an overwrite of whatever value is provided in column B
        // we assign this variable so that we can calculate an effective sum for column C
        const trueColumnCValue = isBlank(sanitizedColumnC)
          ? sanitizedColumnB
          : sanitizedColumnC;

        const requestedPreviouslyAmount =
          matchedDrawLineItems[name]?.requestedPreviouslyAmount;
        const previousAmount =
          name === "lessRetained"
            ? requestedPreviouslyAmount * -1
            : requestedPreviouslyAmount;
        const netAmount = getNetAmount(
          sanitizedColumnB,
          trueColumnCValue,
          previousAmount
        );
        return {
          columnATotal: add(acc.columnATotal, sanitizedColumnA),
          columnBTotal: add(acc.columnBTotal, sanitizedColumnB),
          columnCTotal: add(acc.columnCTotal, trueColumnCValue),
          previousAmountTotal: add(acc.previousAmountTotal, previousAmount),
          netAmountTotal: add(acc.netAmountTotal, netAmount),
        };
      },
      {}
    );

    return (
      <Table.Foot>
        <Table.Row>
          <Table.TextFooterCell textAlign="right">
            Total Cost of Breakdown Items
          </Table.TextFooterCell>
          <Table.TextFooterCell>
            {formatCurrency(columnATotal)}
          </Table.TextFooterCell>
          <Table.TextFooterCell>
            {formatCurrency(columnBTotal)}
          </Table.TextFooterCell>
          <Table.TextFooterCell>
            {formatCurrency(columnCTotal)}
          </Table.TextFooterCell>
          <Table.TextFooterCell>
            {formatCurrency(previousAmountTotal)}
          </Table.TextFooterCell>
          <Table.TextFooterCell>
            {formatCurrency(netAmountTotal)}
          </Table.TextFooterCell>
        </Table.Row>
      </Table.Foot>
    );
  }
}
