import gql from "graphql-tag";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import BUDGET_ADJUSTMENT_FRAGMENT from "./BudgetAdjustmentFragment";
import DOCUMENT_REVIEW_ACTIVITY_FRAGMENT from "./DocumentReviewActivityFragment";

export const HUD_5372_FRAGMENT = gql`
  fragment Hud5372Fragment on Hud5372 {
    id
    actualProgress
    scheduledProgress
  }
`;

const PREVIOUS_HUD_2448_FRAGMENT = gql`
  fragment PreviousHud2448Fragment on Hud2448 {
    id
    lineItems {
      id
      totalAmountDueToDate {
        ...Hud2448LineItemOtherFragment
      }
    }
  }
`;

const HUD_2448_LINE_ITEM = gql`
  fragment Hud2448LineItemFragment on Hud2448LineItem {
    id
    breakdown
    complete
    hudFha
  }
`;

const HUD_2448_LINE_ITEM_WITHOUT_BREAKDOWN = gql`
  fragment Hud2448LineItemWithoutBreakdownFragment on Hud2448LineItemWithoutBreakdown {
    id
    complete
    hudFha
  }
`;

const HUD_2448_LINE_ITEM_OTHER = gql`
  fragment Hud2448LineItemOtherFragment on Hud2448LineItemOther {
    id
    complete
    hudFha
  }
`;

export const HUD_2448_FRAGMENT = gql`
  fragment Hud2448Fragment on Hud2448 {
    id
    requisitionAmount
    requisitionNumber
    lineItems {
      id
      concrete {
        ...Hud2448LineItemFragment
      }
      masonry {
        ...Hud2448LineItemFragment
      }
      metals {
        ...Hud2448LineItemFragment
      }
      roughCarpentry {
        ...Hud2448LineItemFragment
      }
      finishCarpentry {
        ...Hud2448LineItemFragment
      }
      waterproofing {
        ...Hud2448LineItemFragment
      }
      insulation {
        ...Hud2448LineItemFragment
      }
      roofing {
        ...Hud2448LineItemFragment
      }
      sheetMetal {
        ...Hud2448LineItemFragment
      }
      doors {
        ...Hud2448LineItemFragment
      }
      windows {
        ...Hud2448LineItemFragment
      }
      glass {
        ...Hud2448LineItemFragment
      }
      lathAndPlaster {
        ...Hud2448LineItemFragment
      }
      drywall {
        ...Hud2448LineItemFragment
      }
      tileWork {
        ...Hud2448LineItemFragment
      }
      acoustical {
        ...Hud2448LineItemFragment
      }
      woodFlooring {
        ...Hud2448LineItemFragment
      }
      resilientFlooring {
        ...Hud2448LineItemFragment
      }
      paintingAndDecorating {
        ...Hud2448LineItemFragment
      }
      specialties {
        ...Hud2448LineItemFragment
      }
      specialEquipment {
        ...Hud2448LineItemFragment
      }
      cabinets {
        ...Hud2448LineItemFragment
      }
      appliances {
        ...Hud2448LineItemFragment
      }
      blindsAndShadesArtwork {
        ...Hud2448LineItemFragment
      }
      carpets {
        ...Hud2448LineItemFragment
      }
      specialConstruction {
        ...Hud2448LineItemFragment
      }
      elevators {
        ...Hud2448LineItemFragment
      }
      plumbingAndHotWater {
        ...Hud2448LineItemFragment
      }
      heatAndVentilation {
        ...Hud2448LineItemFragment
      }
      airConditioning {
        ...Hud2448LineItemFragment
      }
      electrical {
        ...Hud2448LineItemFragment
      }
      accessoryBuildings {
        ...Hud2448LineItemFragment
      }
      earthWork {
        ...Hud2448LineItemFragment
      }
      siteUtilities {
        ...Hud2448LineItemFragment
      }
      roadsAndWalks {
        ...Hud2448LineItemFragment
      }
      siteImprovement {
        ...Hud2448LineItemFragment
      }
      lawnsAndPlanting {
        ...Hud2448LineItemFragment
      }
      unusualSiteConditions {
        ...Hud2448LineItemFragment
      }
      generalRequirements {
        ...Hud2448LineItemFragment
      }
      subtotalOfBreakdownItems {
        ...Hud2448LineItemFragment
      }
      buildersOverhead {
        ...Hud2448LineItemFragment
      }
      buildersProfit {
        ...Hud2448LineItemFragment
      }
      totalOfCostBreakdownItems {
        ...Hud2448LineItemFragment
      }
      materialsStoredOnsite {
        ...Hud2448LineItemWithoutBreakdownFragment
      }
      materialsStoredOffsite {
        ...Hud2448LineItemWithoutBreakdownFragment
      }
      lessRetained {
        ...Hud2448LineItemWithoutBreakdownFragment
      }
      sumOfCostBreakdownItems {
        ...Hud2448LineItemOtherFragment
      }
      lessNetDecreaseInCost {
        ...Hud2448LineItemOtherFragment
      }
      totalAfterAdjusting {
        ...Hud2448LineItemOtherFragment
      }
      totalAmountDueToDate {
        ...Hud2448LineItemOtherFragment
      }
      lessPreviousPayments {
        ...Hud2448LineItemOtherFragment
      }
      netAmount {
        ...Hud2448LineItemOtherFragment
      }
    }
  }
  ${HUD_2448_LINE_ITEM}
  ${HUD_2448_LINE_ITEM_WITHOUT_BREAKDOWN}
  ${HUD_2448_LINE_ITEM_OTHER}
`;

const HUD_92464_LINE_ITEM = gql`
  fragment Hud92464LineItemFragment on Hud92464LineItem {
    id
    amountsCompleted
    drawLineItemId
    hudApprovedAmount
    requestNumber
  }
`;

export const HUD_92464_FRAGMENT = gql`
  fragment Hud92464Fragment on Hud92464 {
    id
    amountRequested
    advanceNumber
    owner
    lineItems {
      ...Hud92464LineItemFragment
    }
  }
  ${HUD_92464_LINE_ITEM}
`;

export const DOCUMENT_INSPECTION_REPORT_FRAGMENT = gql`
  fragment InspectionReportFragment on InspectionReport {
    id
    expectedCompletionDate
    inspectionDate
    inspectorName
    inspectorNotes
    percentComplete
  }
`;

export const INSURANCE_CERTIFICATE_FRAGMENT = gql`
  fragment InsuranceCertificateFragment on InsuranceCertificate {
    id
    expirationDate
    payApplicationId
  }
`;

export const INVOICE_FRAGMENT = gql`
  fragment InvoiceFragment on Invoice {
    id
    date
    number
    invoiceId
    lineItems {
      id
      lineItemId
      amount
      grossAmount
      jobCostCodes {
        id
        jobCostCodeId
        #    Invoice Line Items can only have one job cost code,
        #    querying for grossAmount + retainageAmount is not needed
      }
      name
      retainageAmount
    }
    project {
      id
      trackableAgreements(documentId: $documentId) {
        id
        amount
        spentForDocument(documentId: $documentId)
        spentToDate
        type
      }
    }
  }
`;

export const LIEN_RELEASE_FRAGMENT = gql`
  fragment LienReleaseFragment on LienRelease {
    id
    assignedInvoiceId
    isFinal
    lienWaiverId
    targetDrawId
    lineItems {
      id
      lineItemId
      name
      amount
    }
  }
`;

export const PAY_APPLICATION_FRAGMENT = gql`
  fragment PayApplicationFragment on PayApplication {
    id
    amount
    currentPaymentDue
    periodToDate
    totalCompletedAndStoredToDate
    applicationNumber
    totalRetainage
    showAllHardCostLineItems
    showFullFields
    invoiceId
    lineItems {
      id
      applicationAmount
      descriptionOfWork
      jobCostCodes {
        id
        grossAmount
        jobCostCodeId
        retainageAmount
      }
      lineItemId
      materialsStoredAmount
      retainageAmount
      retainageToDateAmount
    }
    project {
      id
      trackableAgreements(documentId: $documentId) {
        id
        amount
        spentForDocument(documentId: $documentId)
        spentToDate
        type
      }
    }
  }
`;

export const REVIEW_DOCUMENT_FRAGMENT = gql`
  fragment ReviewDocumentFragment on Document {
    id
    amount
    approvedBy {
      id
    }
    approvedUsers {
      id
    }
    autoCalculateRetainage
    autofilledFields
    description
    documentReviewActivity {
      ...DocumentReviewActivityFragment
    }
    isBackup
    parsedData
    fileData
    autoAssignedVendorOn
    couldNotAutoAssignVendorOn
    processingFinishedAt
    state
    type
    isPaid
    importSource
    amountPaid
    datePaid
    assignedUser {
      id
      fullName
    }
    file {
      url
      name
      type
      annotations
    }
    upload {
      id
      file {
        name
        type
        url
      }
      pages
    }
    dismissedWarnings
    comments {
      id
      body
      insertedAt
      author {
        id
        fullName
      }
      mentions {
        id
        user {
          id
          fullName
        }
      }
    }
    project {
      id
      suggestedDocumentAssignees {
        id
        userId
      }
      hasGeneralContractor
      lineItems {
        id
        scopeId
        budgetAmount
        division {
          id
          scopeId
          name
        }
        name
        number
      }
      name
      stakeholderGroups {
        organizationId
        role
      }
      drawUpdateSource
    }
    draw {
      id
      name
      issues {
        id
        lineItemId
        name
      }
      documents(documentIdsToExclude: $drawDocumentIdsToExclude) {
        id
        type
        invoiceId
        file {
          url
          name
        }
        vendor {
          id
        }
      }
      previousDraw {
        id
        documents(type: ${DOCUMENT_TYPE_NAME.HUD_2448}) {
          id
          ...PreviousHud2448Fragment
        }
      }
      fundedDate
      hasAdjustments
      invoiceSummary {
        id
        type
        file {
          url
          name
          type
          annotations
        }
      }
      isLockedDown
      budgetAdjustments {
        ...BudgetAdjustmentFragment
      }
      lineItems {
        id
        scopeId
        budgetAmount
        requestedPreviouslyAmount
        name
        hardCosts
        grossDocumentedRequestedAmount
        grossRequestedAmount
        number
        requestedAmount
        retainagePercentage
        setManually
        division {
          id
          scopeId
          name
        }
        previousLineItem {
          id
          scopeId
          vendorLineItems(include_gc: true) {
            id
            scopeId
            vendor {
              id
              name
            }
            retainageToDateAmount
          }
        }
      }
      requestedAmount
      vendorLineItems {
        id
        scopeId
        retainageAmount
        drawLineItem {
          id
          scopeId
          name
          division {
            id
            scopeId
            name
          }
        }
        documentation {
          id
        }
        vendor {
          id
        }
      }
    }
    vendor {
      id
      name
      emailAddresses
      phoneNumbers
    }
    suggestedVendors {
      id
      city
      emailAddresses
      name
      phoneNumbers
      state
      agreementProjectIds
    }
  }
  ${BUDGET_ADJUSTMENT_FRAGMENT}
  ${DOCUMENT_REVIEW_ACTIVITY_FRAGMENT}
  ${PREVIOUS_HUD_2448_FRAGMENT}
`;

export const PAY_APPLICATION_ASSIGNMENT_FRAGMENT = gql`
  fragment PayApplicationAssignmentFragment on PayApplicationAssignment {
    id
    payApplicationId
  }
`;

export const RETAINAGE_RELEASE_FRAGMENT = gql`
  fragment RetainageReleaseFragment on RetainageReleaseForm {
    retainageReleasedLineItems
  }
`;

export const AGREEMENT_DOCUMENT_FRAGMENT = gql`
  fragment AgreementDocumentFragment on AgreementDocument {
    id
    adjustedAmount
    agreement {
      id
      budgetAdjustment {
        id
        drawId
        transactions {
          id
          amount
          lineItem {
            id
            scopeId
          }
        }
      }
      agreementNumber
      changeOrderReason
      daysImpacted
      agreementDescription
      name
      userTouchedName
      documentDescription
      correlatedAgreementTypes
      correlatedDocuments {
        id
        type
      }
      correlationId
      inspectionNote
      isArtifact
      lineItems {
        id
        amount
        lineItemId
        retainagePercentage
      }
      startDate
      trackedAgreementAmounts {
        id
        amount
        invoice {
          id
          date
          documentId
          documentName
          drawName
          lineItemNames
        }
      }
      type
      vendor {
        id
        name
      }
    }
  }
`;

export const DRAW_SUMMARY_FRAGMENT = gql`
  fragment DrawSummaryFragment on DrawSummary {
    id
    drawSummaryConfig
    hasAdjustments
  }
`;

export const INSPECTION_IMAGE_FRAGMENT = gql`
  fragment InspectionImageFragment on InspectionImage {
    id
    inspectionNote
  }
`;
