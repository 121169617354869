import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import { DocumentIcon } from "evergreen-ui";
import { Button, Menu, Text } from "components/materials";
import { downloadCsv } from "helpers/downloadHelpers";
import isBlank from "helpers/isBlank";
import {
  getAggregatesForExport,
  getColumnHeaderText,
  getTotalsForExport,
  getValuesForExport,
} from "../FastDataTableUtils";

function getHeaders(columns) {
  return columns.map(getColumnHeaderText);
}

function exportAction(preparedColumns, preparedItems, showFooterTotals, match) {
  const headers = getHeaders(preparedColumns);
  const values = Array.isArray(preparedItems)
    ? getValuesForExport(preparedColumns, preparedItems)
    : Object.entries(preparedItems.groups).reduce(
        (acc, [value, items]) =>
          acc
            .concat([
              [
                isBlank(value) || ["-", "undefined", "null"].includes(value)
                  ? "(None)"
                  : value,
              ],
            ])
            .concat(getValuesForExport(preparedColumns, items))
            .concat([getAggregatesForExport(preparedColumns, items)]),
        []
      );

  const totals = showFooterTotals
    ? [getTotalsForExport(preparedColumns, preparedItems)]
    : [];
  const data = [headers].concat(values).concat(totals);
  const filename = "RabbetExport.csv";
  downloadCsv(data, filename, match);
}

export default function ExportToCSV({
  closeDropdown,
  isButton,
  preparedColumns,
  preparedItems,
  showFooterTotals,
}) {
  const match = useRouteMatch();
  if (isButton) {
    return (
      <Button
        appearance="primary"
        purpose="tables actions export-csv"
        onClick={() => {
          exportAction(preparedColumns, preparedItems, showFooterTotals, match);
        }}
      >
        Export to CSV
      </Button>
    );
  }

  return (
    <Menu.Item
      icon={DocumentIcon}
      is={Text}
      onClick={() => {
        exportAction(preparedColumns, preparedItems, showFooterTotals, match);
        closeDropdown();
      }}
    >
      Export to CSV
    </Menu.Item>
  );
}

ExportToCSV.propTypes = {
  closeDropdown: PropTypes.func,
  isButton: PropTypes.bool,
  preparedColumns: PropTypes.array,
  preparedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  showFooterTotals: PropTypes.bool,
};

ExportToCSV.defaultProps = {};
