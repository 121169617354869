import { Form, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";
import { escapeRegExp, isNil } from "lodash";
import isBlank from "helpers/isBlank";
import { COMPARATORS, filterRequiresInput } from "../FastDataTableUtils";

const options = [
  COMPARATORS.CONTAINS,
  COMPARATORS.NOT_CONTAINS,
  COMPARATORS.EXACT,
  COMPARATORS.IS_EMPTY,
  COMPARATORS.IS_NOT_EMPTY,
];

export const stringColumnDefaults = {
  aggregate: () => null,
  aggregateFormatter: (value) => value,
  filterControl: (index, { values }) => {
    const showFilterInput = filterRequiresInput(values.filters[index]);

    return (
      <Pane display="flex">
        <Form.Select name={`filters.${index}.operator`} options={options} />
        {showFilterInput && (
          <Form.Input
            name={`filters.${index}.input`}
            marginLeft={majorScale(1)}
          />
        )}
      </Pane>
    );
  },
  filterFormatter: (fc, column) =>
    `${column.header}: ${COMPARATORS[fc.operator].text} ${
      filterRequiresInput(fc) && fc.input ? fc.input : ""
    }`,
  filterStrategy: (value, fc) => {
    const lhs = value;
    const rhs = fc.input ? escapeRegExp(fc.input) : null;

    if (filterRequiresInput(fc) && isBlank(rhs)) return true;

    switch (fc.operator) {
      case COMPARATORS.CONTAINS.value:
        return rhs === "-"
          ? [null, undefined, "", "-"].includes(value)
          : new RegExp(rhs, "i").test(lhs);
      case COMPARATORS.NOT_CONTAINS.value:
        return !new RegExp(rhs, "i").test(lhs);
      case COMPARATORS.EXACT.value:
        return new RegExp(`^${rhs}$`, "i").test(lhs);
      case COMPARATORS.IS_EMPTY.value:
        return [null, undefined, "", "-"].includes(value);
      case COMPARATORS.IS_NOT_EMPTY.value:
        return ![null, undefined, "", "-"].includes(value);
      default:
        return true;
    }
  },
  sortStrategy: (stringA, stringB) => {
    if (isNil(stringA)) return 1;
    if (isNil(stringB)) return -1;
    return new Intl.Collator().compare(stringA, stringB);
  },
  type: "string",
  valueFormatter: (value) => value,
  width: 200,
};
