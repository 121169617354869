import { Component } from "react";
import PropTypes from "prop-types";
import { Heading, LinkButton, Pane, Card } from "components/materials";
import { majorScale } from "helpers/utilities";

class ContentLayout extends Component {
  renderPrimaryAction = () => {
    const { primaryAction } = this.props;

    return primaryAction ? (
      <LinkButton
        appearance="primary"
        content={primaryAction.content}
        to={primaryAction.to}
      />
    ) : null;
  };

  renderHeading = () => {
    const { renderHeading, title } = this.props;

    if (!renderHeading && !title) return null;

    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={majorScale(2)}
      >
        {renderHeading ? (
          renderHeading()
        ) : (
          <Heading
            size={600}
            color="default"
            content={title}
            style={{ float: "left" }}
          />
        )}
        {this.renderPrimaryAction()}
      </Pane>
    );
  };

  renderContent = (bleedContent) => {
    const { renderContent, children } = this.props;

    if (!renderContent && !children) return null;

    const content = renderContent ? renderContent() : children;

    return <Pane paddingX={bleedContent ? 0 : majorScale(2)}>{content}</Pane>;
  };

  renderFooter = () => {
    const { renderFooter } = this.props;

    if (!renderFooter) return null;

    return <Pane>{renderFooter()}</Pane>;
  };

  render() {
    const { fixedWidth, bleedContent } = this.props;

    const content = (
      <Pane>
        {this.renderHeading()}
        {this.renderContent(fixedWidth ? false : bleedContent)}
        {this.renderFooter()}
      </Pane>
    );

    const fixedWidthCardProps = Number.isInteger(fixedWidth)
      ? { maxWidth: fixedWidth }
      : { width: 500 };

    return (
      <Pane>
        {fixedWidth ? (
          <Card
            marginX="auto"
            marginY={majorScale(4)}
            padding={majorScale(2)}
            {...fixedWidthCardProps}
          >
            {content}
          </Card>
        ) : (
          content
        )}
      </Pane>
    );
  }
}

ContentLayout.propTypes = {
  bleedContent: PropTypes.bool,
  children: PropTypes.node,
  fixedWidth: PropTypes.bool,
  primaryAction: PropTypes.shape({
    content: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  renderContent: PropTypes.func,
  renderFooter: PropTypes.func,
  renderHeading: PropTypes.func,
  title: PropTypes.string,
};

export default ContentLayout;
