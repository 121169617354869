import gql from "graphql-tag";

export default gql`
  fragment CommentFragment on Comment {
    id
    body
    insertedAt
    author {
      id
      fullName
    }
    mentions {
      id
      user {
        id
        fullName
      }
    }
    target {
      ... on RuleTransition {
        id
        rule {
          id
          scopeId
          projectId
          name
          type
          config
        }
      }
      ... on Rule {
        id
        scopeId
        projectId
        name
        type
        config
      }
      ... on LineItem {
        id
        scopeId
        name
        drawId
        projectId
      }
      ... on BasicDocument {
        id
        file {
          name
          url
        }
        drawId
        projectId
      }
      ... on Project {
        id
        name
      }
      ... on Task {
        id
        eventName
      }
    }
  }
`;
