import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ChevronDownIcon, ChevronRightIcon } from "evergreen-ui";
import { Button, Heading, Pane } from "components/materials";
import { majorScale, minorScale, useTheme } from "helpers/utilities";

function Panel({
  actionContent,
  children,
  contentStyles,
  headerStyles,
  onClick,
  open,
  panelStyles,
  title,
  titleStyles,
}) {
  return (
    <Pane className="rbt-panel" borderBottom {...panelStyles}>
      <Pane cursor="pointer" onClick={onClick}>
        <Pane display="flex" alignItems="center" {...titleStyles}>
          {open ? (
            <ChevronDownIcon marginRight={majorScale(1)} />
          ) : (
            <ChevronRightIcon marginRight={majorScale(1)} />
          )}
          <Heading flexGrow="1" {...headerStyles}>
            {title}
          </Heading>
          {actionContent}
        </Pane>
      </Pane>
      {open && <Pane {...contentStyles}>{children}</Pane>}
    </Pane>
  );
}

function generateStateFromKeys(keys, expanded = true) {
  return keys.reduce(
    (memo, key) => ({
      ...memo,
      [key]: expanded,
    }),
    {}
  );
}

function Accordion({
  allPanelKeys = [],
  contentStyles,
  defaultActiveKeys,
  headerStyles,
  panels,
  panelStyles,
  render,
  shouldExpandAllPanels,
  showExpandCollapseAll,
  theme,
  titleStyles,
  ...props
}) {
  const [expandedPanelKeys, setExpandedPanelKeys] = useState(
    generateStateFromKeys(defaultActiveKeys, true)
  );

  function toggle(key) {
    setExpandedPanelKeys({
      ...expandedPanelKeys,
      [key]: !expandedPanelKeys[key],
    });
  }

  function expandAllPanels() {
    setExpandedPanelKeys(generateStateFromKeys(allPanelKeys, true));
  }

  function collapseAllPanels() {
    setExpandedPanelKeys(generateStateFromKeys(allPanelKeys, false));
  }

  useEffect(() => {
    if (shouldExpandAllPanels) {
      expandAllPanels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldExpandAllPanels]);

  return (
    <Pane {...props} className="rbt-accordion">
      {showExpandCollapseAll && (
        <ExpandCollapseAll
          expandAllPanels={expandAllPanels}
          collapseAllPanels={collapseAllPanels}
        />
      )}
      {render
        ? render({ toggle, expandedPanelKeys })
        : panels.map(({ content, key, title }) => (
            <Panel
              contentStyles={contentStyles}
              headerStyles={headerStyles}
              key={key}
              onClick={() => toggle(key)}
              open={!!expandedPanelKeys[key]}
              panelStyles={panelStyles}
              title={title}
              titleStyles={titleStyles}
            >
              {content}
            </Panel>
          ))}
    </Pane>
  );
}

function ExpandCollapseAll({ collapseAllPanels, expandAllPanels }) {
  const theme = useTheme();

  return (
    <Pane marginBottom={minorScale(3)}>
      <Button
        appearance="minimal"
        color={theme.colors.blue600}
        borderRight={`1px solid ${theme.colors.baseBlue}`}
        borderRadius="none"
        content="EXPAND ALL"
        height={24}
        onClick={expandAllPanels}
      />
      <Button
        appearance="minimal"
        color={theme.colors.blue600}
        content="COLLAPSE ALL"
        height={24}
        onClick={collapseAllPanels}
      />
    </Pane>
  );
}

Accordion.propTypes = {
  allPanelKeys: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  contentStyles: PropTypes.object,
  defaultActiveKeys: PropTypes.arrayOf(PropTypes.string),
  headerStyles: PropTypes.object,
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
  panelStyles: PropTypes.object,
  showExpandCollapseAll: PropTypes.bool,
  titleStyles: PropTypes.object,
};

Accordion.defaultProps = {
  contentStyles: {},
  headerStyles: {},
  defaultActiveKeys: [],
  showExpandCollapseAll: false,
  panelStyles: {},
  titleStyles: {},
};

Panel.propTypes = {
  actionContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  contentStyles: PropTypes.object,
  headerStyles: PropTypes.object,
  panelStyles: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleStyles: PropTypes.object,
};

Panel.defaultProps = {
  actionContent: null,
  contentStyles: {},
  headerStyles: {},
  panelStyles: {},
  titleStyles: {},
};

// TODO: remove dot notation
Accordion.Panel = Panel;

export default Accordion;
