import { useContext } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from "victory";
import { InfoSignIcon } from "evergreen-ui";
import {
  Button,
  Card,
  Pane,
  Paragraph,
  Progress,
  Shortener,
  Text,
  Tooltip,
} from "components/materials";
import {
  majorScale,
  minorScale,
  Position,
  ThemeContext,
} from "helpers/utilities";
import { BRAND_COLORS, SIDEBAR_COLORS } from "helpers/colors";
import {
  addMonths,
  formatDate,
  formatTrimDate,
  getMonthYearFromServerDate,
} from "helpers/dateHelpers";
import t from "helpers/translate";
import { partition, range } from "lodash";
import { DRAW_STATE } from "helpers/enums";
import { numberComparator } from "helpers/comparators";
import { trackPortfolioAnalysis } from "./helpers";
import { HeaderTextCell, TextCell } from ".";

function DrawTimeGraph(data) {
  return (
    <VictoryChart
      padding={{ top: 20, bottom: 35, left: 60, right: 60 }}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiBlacklist={["endPoint"]}
          labels={(d) => `Average: ${d.y} days`}
        />
      }
    >
      <VictoryAxis
        dependentAxis
        domain={[0, 10]}
        tickFormat={(tick) => `${tick}`}
        style={{
          tickLabels: {
            fontSize: 20,
            padding: 5,
            fill: SIDEBAR_COLORS.DARK_GRAY,
          },
          grid: {
            stroke: SIDEBAR_COLORS.GRAY,
            strokeWidth: 0.5,
            strokeDasharray: "4, 2",
          },
        }}
      />
      <VictoryAxis
        crossAxis
        style={{
          tickLabels: {
            fontSize: 20,
            padding: 5,
            fill: SIDEBAR_COLORS.DARK_GRAY,
          },
        }}
      />
      <VictoryLine
        categories={{ x: data.categories }}
        style={{
          data: { stroke: BRAND_COLORS.DARK_BLUE, strokeWidth: 4.5 },
        }}
        data={data.data}
      />
      <VictoryScatter
        name="endPoint"
        style={{
          data: {
            stroke: BRAND_COLORS.DARK_BLUE,
            strokeWidth: 6,
            fill: BRAND_COLORS.WHITE,
          },
        }}
        size={9}
        data={[{ x: data.data[5].x, y: data.data[5].y }]}
        activeData={false}
      />
    </VictoryChart>
  );
}

function getDrawProcessingDataForGraph(draw) {
  const startMonth = draw.processingData?.processingStartDate
    ? getMonthYearFromServerDate(draw.processingData.processingStartDate)
    : null;
  return [startMonth, draw.processingData?.daysToProcess];
}

function getDrawProcessingDataForTable(unfundedDraws) {
  const sortedUnfundedDraws = unfundedDraws.sort((drawA, drawB) => {
    return numberComparator(
      drawA.processingData?.daysToProcess,
      drawB.processingData?.daysToProcess,
      false
    );
  });

  const longestOutstandingDraw =
    sortedUnfundedDraws.length > 0 ? sortedUnfundedDraws[0] : null;

  const maxDaysOutstanding =
    longestOutstandingDraw?.processingData?.daysToProcess || 0;

  return [sortedUnfundedDraws, maxDaysOutstanding];
}

export function DrawProcessingTime({
  history,
  selectedOrganization,
  projects,
  stylingProps,
}) {
  const theme = useContext(ThemeContext);
  const today = new Date();

  const draws = projects.flatMap(
    ({ draws, id: projectId, name: projectName }) =>
      draws.map((draw) => ({ ...draw, projectId, projectName }))
  );

  const [fundedDraws, unfundedDraws] = partition(
    draws,
    ({ state }) => state === DRAW_STATE.FUNDED
  );

  /*  the graph displays data on funded draws from the last 6 months
  year needs to be considered as well for when we have projects from
  (last month) but from last year, as this only considers the last 6 months
  This will build up an object with six keys to aggregate time-to-funded for
  each of the last six months like:
  {
    0-2021: {
      dayCount: 0,
      drawCount: 0,
      xAxisValue: 3
    },
    ....
  }
*/
  const graphDataAccumulator = range(-5, 1).reduce((acc, monthsAgo, index) => {
    const monthToAdd = addMonths(today, monthsAgo);
    const monthToAddKey = getMonthYearFromServerDate(monthToAdd);

    return {
      ...acc,
      [monthToAddKey]: { dayCount: 0, drawCount: 0, xAxisValue: index + 1 },
    };
  }, {});

  const recentFundedDrawGraphData = fundedDraws.reduce((acc, draw) => {
    const [
      submittedMonthYear,
      processingDayCount,
    ] = getDrawProcessingDataForGraph(draw);
    if (submittedMonthYear in acc && processingDayCount !== null) {
      return {
        ...acc,
        [submittedMonthYear]: {
          ...acc[submittedMonthYear],
          dayCount: acc[submittedMonthYear].dayCount + processingDayCount,
          drawCount: acc[submittedMonthYear].drawCount + 1,
        },
      };
    }
    return acc;
  }, graphDataAccumulator);

  const graphCategories = Object.keys(recentFundedDrawGraphData).map((key) => {
    const [month, year] = key.split("-");
    const date = new Date(year, month, 1);
    return formatDate(date, null, "MMM");
  });

  const graphData = Object.values(recentFundedDrawGraphData).map((value) => {
    return {
      drawCount: value.drawCount,
      x: value.xAxisValue,
      y:
        value.drawCount !== 0
          ? parseFloat((value.dayCount / value.drawCount).toFixed(2))
          : 0,
    };
  });

  const currentMonthText =
    graphData[5].drawCount > 0 ? `${graphData[5].y} days` : "N/A";

  const [
    sortedUnfundedDraws,
    maxDaysOutstanding,
  ] = getDrawProcessingDataForTable(unfundedDraws);

  return (
    <Pane>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        Draw Processing Time
      </Paragraph>
      <Card {...stylingProps.card}>
        <Pane
          display="flex"
          padding={majorScale(1)}
          marginBottom={majorScale(4)}
        >
          <Pane width="50%" textAlign="center">
            <Pane display="flex" alignItems="center">
              <Text
                {...stylingProps.titleProps}
                color={SIDEBAR_COLORS.DARK_GRAY}
                fontWeight={theme.fontWeights.MEDIUM}
                display="inline-block"
                marginRight={majorScale(1)}
              >
                {t("portfolioInsightsPage.drawProcessing.graphDescription")}
                {"*"}
              </Text>
              <Tooltip
                content={t(
                  "portfolioInsightsPage.drawProcessing.graphTooltipWithNewCalculationExplanation"
                )}
                position={Position.TOP}
                display="inline-block"
              >
                <InfoSignIcon color="info" size={minorScale(3)} />
              </Tooltip>
            </Pane>
            <DrawTimeGraph data={graphData} categories={graphCategories} />
          </Pane>
          <Pane
            width="50%"
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignContent="center"
            flexDirection="column"
          >
            <Paragraph
              fontWeight={theme.fontWeights.MEDIUM}
              fontSize={majorScale(7)}
              padding={majorScale(3)}
              lineHeight={1}
            >
              {currentMonthText}
            </Paragraph>
            <Paragraph fontWeight={theme.fontWeights.MEDIUM}>
              Current Month&apos;s Average
            </Paragraph>
          </Pane>
        </Pane>
        {sortedUnfundedDraws.length === 0 && (
          <Paragraph
            color="muted"
            fontWeight={theme.fontWeights.MEDIUM}
            marginBottom={majorScale(1)}
          >
            {t(`portfolioInsightsPage.blankSlate.info.${"drawProcessingTime"}`)}
          </Paragraph>
        )}
        {sortedUnfundedDraws.length > 0 && (
          <Pane>
            <Paragraph {...stylingProps.title} margin={minorScale(1)}>
              Longest Outstanding Draws
            </Paragraph>
            <Pane maxHeight={200} overflowY="scroll">
              <table {...stylingProps.table}>
                <thead>
                  <tr>
                    <HeaderTextCell>Project</HeaderTextCell>
                    <HeaderTextCell>Draw</HeaderTextCell>
                    <HeaderTextCell>Processing Started</HeaderTextCell>
                    <HeaderTextCell colSpan={3}>
                      Days In Processing
                    </HeaderTextCell>
                  </tr>
                </thead>
                <tbody>
                  {sortedUnfundedDraws.map((draw) => {
                    const { id: drawId, projectId } = draw;
                    const navigateToDraw = () => {
                      trackPortfolioAnalysis(
                        selectedOrganization.id,
                        "Go to Draw"
                      );
                      return history.push(
                        `/projects/${projectId}/draws/${drawId}`
                      );
                    };
                    return (
                      <DrawProcessingRow
                        key={drawId}
                        draw={draw}
                        maxDaysOutstanding={maxDaysOutstanding}
                        navigateToDraw={navigateToDraw}
                      />
                    );
                  })}
                </tbody>
              </table>
            </Pane>
          </Pane>
        )}
      </Card>
    </Pane>
  );
}

function DrawProcessingRow({ draw, maxDaysOutstanding, navigateToDraw }) {
  const { projectName, name: drawName, processingData } = draw;

  const formattedProcessingStartDate = formatTrimDate(
    processingData?.processingStartDate
  );

  const [date, value] = [
    formattedProcessingStartDate,
    processingData?.daysToProcess,
  ];

  return (
    <tr>
      <TextCell>
        <Shortener limit={17} size={300} text={projectName} />
      </TextCell>
      <TextCell>
        <Shortener limit={17} size={300} text={drawName} />
      </TextCell>
      <TextCell>{date}</TextCell>
      <TextCell>{value}</TextCell>
      <td width="100%" aria-label="progress">
        <Progress
          value={value}
          total={maxDaysOutstanding}
          color={BRAND_COLORS.PRIMARY}
          background="transparent"
        />
      </td>
      <td>
        <Button onClick={navigateToDraw}>Go to Draw</Button>
      </td>
    </tr>
  );
}
