import { useEffect } from "react";
import PropTypes from "prop-types";

export function DisableForm({ form, disabled }) {
  useEffect(
    () => {
      if (disabled) {
        form.setStatus({ disabled: true });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return null;
}

DisableForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
};
