import { EyeOffIcon, DotIcon, LinkIcon } from "evergreen-ui";
import {
  ListItem,
  Pane,
  Spinner,
  Text,
  Tooltip,
  UnorderedList,
} from "components/materials";
import { brokenLink } from "images";
import { majorScale, Position } from "helpers/utilities";
import { get } from "lodash";
import { DOCUMENT_STATE } from "helpers/enums";
import t from "helpers/translate";

export function DocumentStatusIcon({ document, draw, state }) {
  const errors = get(document, "errors", []);
  if (state === DOCUMENT_STATE.IGNORED) {
    return (
      <Tooltip content="Document has been ignored." position={Position.TOP}>
        <EyeOffIcon color="muted" />
      </Tooltip>
    );
  }

  if (state === DOCUMENT_STATE.APPLIED) {
    return (
      <Tooltip
        content={`Document has been applied to the ${
          draw ? "draw" : "project"
        } successfully.`}
        position={Position.TOP}
      >
        <LinkIcon color="success" />
      </Tooltip>
    );
  }

  if (state === DOCUMENT_STATE.FAILED) {
    if (errors && errors.length > 0) {
      return (
        <Tooltip
          content={
            <UnorderedList icon={DotIcon} iconColor="white">
              {errors.map(({ message }) => (
                <ListItem key={message}>
                  <Text color="white">
                    {t(`documentErrors.${message}`, { defaultValue: message })}
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          }
          position={Position.TOP}
        >
          <Pane>
            <img
              src={brokenLink}
              alt="Document not applied"
              width={majorScale(2)}
            />
          </Pane>
        </Tooltip>
      );
    }

    return (
      <img src={brokenLink} alt="Document not applied" width={majorScale(2)} />
    );
  }

  if ([DOCUMENT_STATE.PARSED, DOCUMENT_STATE.CORRECTED].includes(state)) {
    return (
      <Tooltip
        content={
          draw
            ? "Unknown error applying this document"
            : "This document must be assigned to a draw"
        }
        position={Position.TOP}
      >
        <Pane>
          <img
            src={brokenLink}
            alt="Document not applied"
            width={majorScale(2)}
          />
        </Pane>
      </Tooltip>
    );
  }

  // state is "ADDED"
  return (
    <Tooltip
      content="Document Parsing - We will email upon completion."
      position={Position.TOP}
    >
      <Spinner size={majorScale(2)} />
    </Tooltip>
  );
}
