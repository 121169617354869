import { useContext, Fragment } from "react";
import { get } from "lodash";
import { MentionsBody, Pane, Text } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { formatDateTime } from "helpers/dateHelpers";
import t from "helpers/translate";
import { TickCircleIcon, TickIcon } from "evergreen-ui";

const REVIEW_TYPE_ICONS = {
  approved: {
    icon: "tick-circle",
    color: "success",
  },
  reviewed: {
    icon: "tick",
    color: "info",
  },
};

function Activity({ activity }) {
  const theme = useContext(ThemeContext);
  const iconAttributes = REVIEW_TYPE_ICONS[activity.reviewType];

  return (
    <Pane
      paddingY={majorScale(1)}
      marginLeft={iconAttributes ? 0 : majorScale(3)}
    >
      <Fragment>
        <Pane>
          {iconAttributes &&
            (activity.reviewType === "approved" ? (
              <TickCircleIcon color="success" marginRight={majorScale(1)} />
            ) : (
              <TickIcon color="info" marginRight={majorScale(1)} />
            ))}
          <Text marginRight={minorScale(1)} fontWeight={theme.fontWeights.DEMI}>
            {get(activity, "user.fullName")}
          </Text>
          <Text color="muted" marginRight={minorScale(1)}>
            {t(`documentActivity.${activity.reviewType}`)}
          </Text>
          <Text color="muted">{`${formatDateTime(activity.insertedAt)}`}</Text>
        </Pane>
      </Fragment>
      {activity.body && (
        <Text
          display="block"
          paddingTop={majorScale(1)}
          paddingX={majorScale(4)}
        >
          <MentionsBody comment={activity} />
        </Text>
      )}
    </Pane>
  );
}

function ListDocumentActivities({ activities }) {
  return (
    <Fragment>
      {activities.map((activity) => (
        <Activity activity={activity} key={activity.id} />
      ))}
    </Fragment>
  );
}

export default ListDocumentActivities;
