import { get, some } from "lodash";

const withNotFound = (callback) => (query) => {
  const errors = get(query, "error.graphQLErrors", []);
  if (some(errors, ({ message }) => message === "not_found")) {
    window.location.assign("/not_found");
    return null;
  }

  return callback(query);
};

export default withNotFound;
