import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { Button, Modal, Pane, Text } from "components/materials";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { get } from "lodash";
import { INVITATION_QUERY, ACCEPT_INVITATION } from "./AcceptInvitation";

export function AcceptInvitationModal({ onClose, invitation, ...props }) {
  const organizationName = get(invitation, "organization.name");

  const [acceptInvitation, acceptInvitationResult] = useMutation(
    ACCEPT_INVITATION,
    {
      // Use an explicit refetch query since the user record
      // returned by the mutation isn't the same one as the user logged in
      refetchQueries: [{ query: INVITATION_QUERY }],
      onCompleted: () => {
        // Refresh the page as these changes may affect the content
        window.location.reload();
      },
    }
  );

  const handleAccept = () => {
    acceptInvitation({ variables: { invitationToken: invitation.id } });
  };

  return (
    <Modal
      hideViewer
      onClose={onClose}
      open
      size="small"
      title={t("acceptInvitation.modalTitle", { organizationName })}
      {...props}
    >
      <Modal.Content>
        <Text>{t("acceptInvitation.modalHeader", { organizationName })}</Text>
      </Modal.Content>
      <Modal.Actions marginTop={majorScale(1)} paddingBottom={0}>
        <Pane display="flex" justifyContent="flex-end">
          <Button
            content="Cancel"
            onClick={onClose}
            marginRight={majorScale(1)}
            disabled={acceptInvitationResult.loading}
          />
          <Button
            appearance="primary"
            content="Accept Invitation"
            onClick={handleAccept}
            disabled={acceptInvitationResult.loading}
            isLoading={acceptInvitationResult.loading}
          />
        </Pane>
      </Modal.Actions>
    </Modal>
  );
}

AcceptInvitationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired,
};
