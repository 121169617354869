import { useContext, Fragment } from "react";
import { InfoSignIcon } from "evergreen-ui";
import { Calculation, Pane, Tooltip } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import unformatNumber from "helpers/unformatNumber";
import { majorScale, minorScale, Position } from "helpers/utilities";

export function CalculationTooltip({
  form,
  getPreviousRetainage,
  isPayApp,
  lineItem,
  selectedDraw,
}) {
  const { hasPermission } = useContext(UserContext);
  const hasInvoiceRetainage = hasPermission(
    PERMISSION_ACTION.SHOW_INVOICE_RETAINAGE
  );
  return (
    <Fragment>
      {(hasInvoiceRetainage || isPayApp) && (
        <Tooltip
          position={Position.TOP}
          statelessProps={{
            backgroundColor: "transparent",
            maxWidth: "auto",
          }}
          content={
            <Pane elevation={1} backgroundColor="white">
              <Pane borderBottom padding={majorScale(2)} whiteSpace="nowrap">
                <Calculation
                  initialValue={unformatNumber(lineItem.retainageToDateAmount)}
                  initialLabel="Total Retainage"
                  initialColor="#64c192"
                  totalLabel="Current Retainage"
                >
                  <Calculation.Operation
                    value={getPreviousRetainage(
                      form.values.vendor,
                      selectedDraw
                    )(lineItem)}
                    label="Previous Retainage"
                    operator="-"
                  />
                </Calculation>
              </Pane>
              <Pane padding={majorScale(2)} whiteSpace="nowrap">
                <Calculation
                  initialValue={
                    isPayApp
                      ? unformatNumber(lineItem.applicationAmount)
                      : unformatNumber(lineItem.grossAmount)
                  }
                  initialLabel={
                    isPayApp ? "Application Amount" : "Gross Requested Amount"
                  }
                  initialColor="#64c192"
                  totalLabel={
                    isPayApp ? "Current Payment Due" : "Requested Amount"
                  }
                >
                  {isPayApp && (
                    <Calculation.Operation
                      value={unformatNumber(lineItem.materialsStoredAmount)}
                      label="Stored Materials Amount"
                      operator="+"
                    />
                  )}
                  <Calculation.Operation
                    value={unformatNumber(lineItem.retainageAmount)}
                    label="Current Retainage Amount"
                    operator="-"
                  />
                </Calculation>
              </Pane>
            </Pane>
          }
        >
          <InfoSignIcon marginLeft={minorScale(1)} size={12} />
        </Tooltip>
      )}
    </Fragment>
  );
}
