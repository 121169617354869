import gql from "graphql-tag";

export const AGREEMENTS_PAGE_QUERY = gql`
  query AgreementsPageQuery($projectId: String!) {
    project(id: $projectId) {
      id
      name
      amount
      committedAmount
      exposedAmount
      contingencyOriginalAmount
      contingencyAdjustmentsAmount
      documentReviewers {
        id
        position
        user {
          id
          approvalAmountLimit
          approvalAmountMinimum
          fullName
        }
      }
      draws {
        id
        isLockedDown
        name
      }
      lineItems {
        id
        scopeId
        name
        number
        budgetAmount
        division {
          id
          scopeId
          name
        }
      }
      vendors {
        id
        name
      }
      agreements {
        id
        adjustmentAmount
        agreementDescription
        type
        startDate
        agreementNumber
        changeOrderReason
        daysImpacted
        name
        userTouchedName
        lineItemNames
        amount
        budgetAdjustment {
          id
          drawId
          transactions {
            id
            amount
            lineItem {
              id
              scopeId
            }
          }
        }
        agreementNumber
        changeOrderReason
        correlatedAgreementTypes
        correlatedDocuments {
          id
        }
        correlationId
        daysImpacted
        document {
          ... on AgreementDocument {
            id
            adjustedAmount
            type
            recentApprovalReviews {
              id
              userId
              userName
              reviewType
              insertedAt
            }
            draw {
              id
            }
          }
        }
        drawName
        lineItemNames
        lineItems {
          id
          amount
          lineItemId
          retainagePercentage
        }
        spentToDate
        startDate
        type
        vendor {
          id
          name
        }
      }
      agreementVendorLineItems {
        id
        scopeId
        agreementsAmount
        agreementsCount
        lineItem {
          id
          scopeId
          name
          division {
            id
            scopeId
            name
          }
        }
        pendingAgreementsAmount
        pendingAgreementsCount
        spentToDateAmount
        spentToDateCount
        vendor {
          id
          name
        }
      }
    }
  }
`;

export const AGREEMENT_DETAIL_QUERY = gql`
  query AgreementDetailQuery($projectId: String!, $agreementId: String!) {
    project(id: $projectId) {
      id
      agreement(id: $agreementId) {
        id
        budgetAdjustment {
          id
          drawId
          transactions {
            id
            amount
            lineItem {
              id
              scopeId
            }
          }
        }
        agreementNumber
        changeOrderReason
        daysImpacted
        name
        userTouchedName
        agreementDescription
        trackedAgreementAmounts {
          id
          amount
          invoice {
            id
            date
            documentId
            documentName
            drawName
            lineItemNames
          }
        }
        document {
          id
          file {
            name
            type
            url
          }
        }
        correlatedAgreementTypes
        correlatedDocuments {
          id
          file {
            name
            type
            url
          }
          type
        }
        correlationId
        lineItems {
          id
          amount
          lineItemId
          retainagePercentage
        }
        startDate
        type
        vendor {
          id
          name
        }
      }
    }
  }
`;

const AGREEMENT_SIDEBAR_AGREEMENT_FRAGMENT = gql`
  fragment AgreementFragment on Agreement {
    id
    type
    agreementDescription
    name
    startDate
    document {
      id
      file {
        name
        url
      }
    }
    lineItems {
      id
      amount
      lineItemId
      retainagePercentage
    }
  }
`;

export const AGREEMENT_SIDEBAR_QUERY = gql`
  query AgreementsPageQuery(
    $lineItemId: String
    $projectId: String!
    $vendorId: String
  ) {
    project(id: $projectId) {
      id
      agreementVendorLineItem(lineItemId: $lineItemId, vendorId: $vendorId) {
        id
        scopeId
        lineItem {
          id
          scopeId
          name
          division {
            id
            scopeId
            name
          }
        }
        vendor {
          id
          name
        }
        agreements {
          ...AgreementFragment
        }
        pendingAgreements {
          ...AgreementFragment
        }
        documentation {
          id
          amount
          document {
            id
            amount
            draw {
              id
              name
            }
            file {
              name
              url
            }
            originalFile {
              name
              url
            }
            pages
            processingFinishedAt
            project {
              id
            }
            state
            type
            vendor {
              id
              name
            }
            ... on Invoice {
              date
            }
            ... on PayApplication {
              periodToDate
            }
          }
        }
      }
    }
  }
  ${AGREEMENT_SIDEBAR_AGREEMENT_FRAGMENT}
`;

export const DELETE_AGREEMENT_AND_DOCUMENTATION = gql`
  mutation DeleteAgreementAndDocumentation(
    $agreementId: String!
    $deleteBudgetAdjustment: Boolean
  ) {
    deleteAgreementAndDocumentation(
      agreementId: $agreementId
      deleteBudgetAdjustment: $deleteBudgetAdjustment
    ) {
      status
    }
  }
`;

export const UPLOAD_AND_ASSOCIATE_WITH_AGREEMENT = gql`
  mutation UploadAndAssociateWithAgreement(
    $agreementId: String!
    $agreementType: AgreementType!
    $drawId: String
    $file: Upload!
  ) {
    uploadAndAssociateWithAgreement(
      agreementId: $agreementId
      newFileAgreementType: $agreementType
      drawId: $drawId
      file: $file
    ) {
      status
    }
  }
`;

export const ASSOCIATE_PROJECT_AGREEMENTS = gql`
  mutation AssociateProjectAgreements($agreementIds: [String]!) {
    associateProjectAgreements(agreementIds: $agreementIds) {
      status
    }
  }
`;

export const UPDATE_PROJECT_AGREEMENT = gql`
  mutation UpdateProjectAgreementMutation(
    $agreementId: String!
    $agreementLineItems: [AgreementLineItemInput]
    $budgetAdjustmentTransactions: [BudgetAdjustmentTransactionInput]
    $agreementNumber: String
    $changeOrderReason: ChangeOrderReason
    $daysImpacted: Int
    $name: String!
    $agreementDescription: String
    $budgetAdjustmentDrawId: String
    $moveDocumentToDraw: Boolean
    $newLineItems: [NewLineItemInput]
    $startDate: Date
    $userTouchedName: Boolean
    $vendorId: String
  ) {
    updateProjectAgreement(
      agreementId: $agreementId
      agreementLineItems: $agreementLineItems
      budgetAdjustmentTransactions: $budgetAdjustmentTransactions
      agreementNumber: $agreementNumber
      changeOrderReason: $changeOrderReason
      daysImpacted: $daysImpacted
      name: $name
      agreementDescription: $agreementDescription
      budgetAdjustmentDrawId: $budgetAdjustmentDrawId
      moveDocumentToDraw: $moveDocumentToDraw
      newLineItems: $newLineItems
      startDate: $startDate
      userTouchedName: $userTouchedName
      vendorId: $vendorId
    ) {
      id
    }
  }
`;

export const ADD_DRAW = gql`
  mutation AddDrawForAdjustment($name: String!, $projectId: String!) {
    addDraw(
      projectId: $projectId
      name: $name
      submittedDate: null
      expectedSendDate: null
    ) {
      id
    }
  }
`;

export const ADD_PROJECT_AGREEMENT = gql`
  mutation AddProjectAgreementMutation(
    $agreementLineItems: [AgreementLineItemInput]
    $budgetAdjustmentTransactions: [BudgetAdjustmentTransactionInput]
    $agreementNumber: String
    $changeOrderReason: ChangeOrderReason
    $correlationId: String
    $daysImpacted: Int
    $name: String
    $agreementDescription: String
    $budgetAdjustmentDrawId: String
    $file: Upload
    $moveDocumentToDraw: Boolean
    $newLineItems: [NewLineItemInput]
    $projectId: String!
    $startDate: Date
    $type: AgreementType!
    $userTouchedName: Boolean
    $vendorId: String
  ) {
    addProjectAgreement(
      agreementLineItems: $agreementLineItems
      budgetAdjustmentTransactions: $budgetAdjustmentTransactions
      agreementNumber: $agreementNumber
      changeOrderReason: $changeOrderReason
      correlationId: $correlationId
      daysImpacted: $daysImpacted
      name: $name
      agreementDescription: $agreementDescription
      budgetAdjustmentDrawId: $budgetAdjustmentDrawId
      file: $file
      moveDocumentToDraw: $moveDocumentToDraw
      newLineItems: $newLineItems
      projectId: $projectId
      startDate: $startDate
      type: $type
      userTouchedName: $userTouchedName
      vendorId: $vendorId
    ) {
      id
    }
  }
`;

export const UPGRADEABLE_AGREEMENTS_QUERY = gql`
  query UpgradeableProjectAgreements($projectId: String!) {
    project(id: $projectId) {
      id
      agreements {
        id
        lineItems {
          id
          amount
          lineItemId
          retainagePercentage
        }
        amount
        agreementNumber
        changeOrderReason
        correlationId
        daysImpacted
        lineItemNames
        startDate
        type
        vendor {
          id
        }
      }
    }
  }
`;

export const BULK_CREATE_AGREEMENTS = gql`
  mutation BulkCreateAgreements($projectId: ID!, $file: Upload!) {
    bulkCreateAgreements(projectId: $projectId, file: $file) {
      status
    }
  }
`;
