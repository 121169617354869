import { fundingSourceAndBudgetDifference } from "helpers/fundingSourceHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import { Banner, Link } from "components/materials";

export function FundingSourceWarningBanner({
  canEditFundingSources,
  hideAction,
  project,
}) {
  const budgetDifference = fundingSourceAndBudgetDifference(project);
  if (budgetDifference === 0) return null;

  let mainText = t("fundingSources.outOfSyncWithBudget", {
    amount: formatCurrency(Math.abs(budgetDifference)),
    greaterOrLessThan: budgetDifference > 0 ? "greater" : "less",
  });

  if (!hideAction && canEditFundingSources) {
    mainText = `${mainText} ${t("fundingSources.outOfSyncWithBudgetLink1")}`;
  }

  return (
    <Banner border icon="warning-sign" mainText={mainText}>
      {!hideAction && canEditFundingSources && (
        <Link
          to={{
            pathname: `/projects/${project.id}/settings`,
            search: "?settings=fundingSources",
          }}
        >
          {t("fundingSources.outOfSyncWithBudgetLink2")}
        </Link>
      )}
    </Banner>
  );
}
