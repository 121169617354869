import { Fragment } from "react";
import { InfoSignIcon } from "evergreen-ui";
import {
  Button,
  Card,
  Pane,
  Paragraph,
  Progress,
  ProgressGauge,
  Shortener,
  Text,
  Tooltip,
} from "components/materials";
import formatPercent from "helpers/formatPercent";
import { add, divide, multiply, subtract } from "helpers/math";
import { PIE_CHART_COLORS } from "helpers/colors";
import { minorScale, Position } from "helpers/utilities";
import { trackPortfolioAnalysis } from "./helpers";
import { HeaderTextCell, PortfolioInsightBlankSlateCard, TextCell } from ".";

function labelContent() {
  const tooltipContent = `Schedule Health = (Budget Spent% - Schedule Elapsed%) / Budget Spent %`;
  return (
    <Pane>
      <Text>Portfolio Schedule Health</Text>
      <Tooltip content={tooltipContent} position={Position.TOP}>
        <InfoSignIcon
          color="info"
          size={minorScale(3)}
          marginLeft={minorScale(1)}
          position="absolute"
          top="72%"
        />
      </Tooltip>
    </Pane>
  );
}

export function ScheduleRisk({
  history,
  organizationId,
  projects,
  stylingProps,
}) {
  const filteredProjects = projects.filter(
    ({ percentOfProjectTimeElapsed }) => percentOfProjectTimeElapsed !== null
  );

  if (filteredProjects.length === 0)
    return <PortfolioInsightBlankSlateCard cardName="scheduleRisk" />;

  const sortedProjects = filteredProjects
    .map(
      ({
        grossPercentComplete,
        id: projectId,
        name: projectName,
        originalAmount: budgetTotalAmount,
        percentOfProjectTimeElapsed: scheduleElapsed,
      }) => ({
        budgetSpent: formatPercent(grossPercentComplete),
        budgetTotalAmount,
        projectId,
        projectName,
        scheduleHealth: formatPercent(
          divide(
            subtract(grossPercentComplete, scheduleElapsed),
            grossPercentComplete
          )
        ),
        scheduleElapsed: formatPercent(scheduleElapsed),
      })
    )
    .sort(
      (projectA, projectB) =>
        parseFloat(projectA.scheduleHealth) -
        parseFloat(projectB.scheduleHealth)
    );

  const maxAbsHealthValue = Math.max(
    Math.abs(parseFloat(sortedProjects[0]?.scheduleHealth)),
    Math.abs(
      parseFloat(sortedProjects[sortedProjects.length - 1]?.scheduleHealth)
    )
  );

  // aggregate the schedule health values by weighting them by their project's budgetAmount
  const totals = sortedProjects.reduce(
    (acc, { budgetTotalAmount, scheduleHealth }) => ({
      scheduleHealth: add(
        acc.scheduleHealth,
        multiply(parseFloat(scheduleHealth), budgetTotalAmount)
      ),
      budgetTotalAmount: add(acc.budgetTotalAmount, budgetTotalAmount),
    }),
    {
      scheduleHealth: 0,
      budgetTotalAmount: 0,
    }
  );

  const gaugePercent = divide(totals.scheduleHealth, totals.budgetTotalAmount);

  return (
    <Fragment>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        Schedule Risk
      </Paragraph>
      <Card {...stylingProps.card}>
        <ProgressGauge
          height={300}
          labelContent={labelContent()}
          percent={gaugePercent}
          percentToFillGauge={50}
        />

        <Pane maxHeight={200} overflowY="scroll">
          <table {...stylingProps.table}>
            <thead>
              <tr>
                <HeaderTextCell>Project</HeaderTextCell>
                <HeaderTextCell style={{ textAlign: "center" }}>
                  Budget Spent
                </HeaderTextCell>
                <HeaderTextCell style={{ textAlign: "center" }}>
                  Schedule Elapsed
                </HeaderTextCell>
                <HeaderTextCell colSpan={3}>Schedule Health</HeaderTextCell>
              </tr>
            </thead>
            <tbody>
              {sortedProjects.map((project) => {
                const { projectId } = project;
                const navigateToProject = () => {
                  trackPortfolioAnalysis(organizationId, "Go to Project");
                  return history.push(`/projects/${projectId}`);
                };
                return (
                  <ScheduleRiskRow
                    key={`schedule-risk-row-${projectId}`}
                    {...project}
                    navigateToProject={navigateToProject}
                    maxAbsHealthValue={maxAbsHealthValue}
                  />
                );
              })}
            </tbody>
          </table>
        </Pane>
      </Card>
    </Fragment>
  );
}

function ScheduleRiskRow({
  budgetSpent,
  navigateToProject,
  projectName,
  scheduleElapsed,
  scheduleHealth,
  maxAbsHealthValue,
}) {
  return (
    <tr>
      <TextCell>
        <Shortener limit={17} size={300} text={projectName} />
      </TextCell>
      <TextCell style={{ textAlign: "center" }}>{budgetSpent}</TextCell>
      <TextCell style={{ textAlign: "center" }}>{scheduleElapsed}</TextCell>
      <TextCell style={{ textAlign: "center" }}>{scheduleHealth}</TextCell>
      <td width="100%" aria-label="progress">
        <Progress
          value={Math.abs(parseFloat(scheduleHealth))}
          total={maxAbsHealthValue}
          color={
            Math.sign(parseFloat(scheduleHealth)) === -1
              ? PIE_CHART_COLORS.BASE_RED
              : PIE_CHART_COLORS.BASE_GREEN
          }
          background="transparent"
        />
      </td>
      <td>
        <Button onClick={navigateToProject}>Go to Project</Button>
      </td>
    </tr>
  );
}
