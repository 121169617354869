import { Fragment } from "react";
import { uniqBy } from "lodash";
import { Modal, Heading, Paragraph } from "components/materials";
import t from "helpers/translate";
import humanReadableList from "helpers/humanReadableList";
import { GUIDING_EXPLANATION_SCOPES } from "helpers/issues";
import { majorScale } from "helpers/utilities";

function getGuidingExplanationValues(item, issueName, scope) {
  switch (issueName) {
    case "line_item_has_negative_balance_to_fund":
      if (scope === GUIDING_EXPLANATION_SCOPES.DOCUMENT) {
        return {
          documentType: item.type.toLowerCase(),
          lineItems: humanReadableList(
            item.lineItemsWithNegativeBalanceToFundIssue
          ),
        };
      }
      if (
        scope === GUIDING_EXPLANATION_SCOPES.DRAW_LINE_ITEM ||
        scope === GUIDING_EXPLANATION_SCOPES.BUDGET_LINE_ITEM
      ) {
        return {
          lineItemName: item.name,
        };
      }
      return {};
    case "duplicate_invoice_number_for_vendor_on_project":
      return {
        placesOnProject: humanReadableList(
          item.placesOnProjectWithDuplicateInvoiceNumbers
        ),
        vendorName: item.vendor.name,
      };
    default:
      return {};
  }
}

export function IssuesInfoModal({ onClose, issueItem, scope }) {
  const { issues } = issueItem;
  const uniqueIssues = uniqBy(issues, "name");
  const title =
    uniqueIssues.length === 1
      ? t(`issues.badge.${uniqueIssues[0].name}.text`)
      : "Multiple Issues";

  return (
    <Modal
      open
      onClose={onClose}
      size="small"
      title={title}
      hasFooter
      hasCancel={false}
      confirmLabel="Close"
    >
      <Modal.Content>
        {uniqueIssues.map((issue) => {
          return (
            <Fragment>
              {uniqueIssues.length > 1 && (
                <Heading>{t(`issues.badge.${issue.name}.text`)}</Heading>
              )}
              <Paragraph marginBottom={majorScale(2)}>
                {t(`issues.guidingExplanation.${issue.name}${scope}`, {
                  ...getGuidingExplanationValues(issueItem, issue.name, scope),
                })}
              </Paragraph>
            </Fragment>
          );
        })}
      </Modal.Content>
    </Modal>
  );
}
