import { EvergreenHeading, Paragraph } from "components/materials";

// This is a shim since Evergreen's heading doesn't use content= but because of an old UI
// kit, a lot of our code does. You shouldn't use content= going forward.
const Heading = ({ children, content = "", ...otherProps }) => (
  <EvergreenHeading className="rbt-heading" {...otherProps}>
    {content || children}
  </EvergreenHeading>
);

// TODO: remove dot notation
Heading.SubHeading = ({ children, content, ...otherProps }) => (
  <Paragraph fontWeight={300} {...otherProps}>
    {content || children}
  </Paragraph>
);

export default Heading;
