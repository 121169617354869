import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Form, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

function LineItemNumberForm({ form, result }) {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <Fragment>
      <Form.Input
        name="number"
        onBlur={() => setHasFocus(false)}
        onFocus={() => setHasFocus(true)}
      />
      {(hasFocus || form.dirty) && (
        <Pane
          display="flex"
          justifyContent="flex-end"
          marginTop={majorScale(2)}
        >
          <Button
            purpose="line-item-number-form undo"
            marginRight={majorScale(1)}
            onClick={form.handleReset}
          >
            Undo
          </Button>
          <Button
            appearance="primary"
            purpose="line-item-number-form submit"
            isLoading={result.loading}
            onClick={form.handleSubmit}
          >
            Save
          </Button>
        </Pane>
      )}
    </Fragment>
  );
}

LineItemNumberForm.propTypes = {
  form: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
};

export default LineItemNumberForm;
