import { useState, useContext } from "react";
import { set } from "lodash";
import { CreateDocument } from "components/templates";
import {
  Button,
  Dropzone,
  Link,
  Modal,
  Pane,
  Tab,
  Tablist,
  Text,
} from "components/materials";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";

const TABS = {
  UPLOAD: "UPLOAD",
  CREATE: "CREATE",
  CREATE_92403: "CREATE_92403",
};

const UploadFilesModal = ({
  create,
  create92403Page2,
  create92403Page2Result,
  createResult,
  documentTemplates,
  drawId,
  lineItems,
  onCancel,
  onSubmit,
  preview,
  previewDocument,
  previewResult,
  projectId,
  title,
  ...props
}) => {
  const hasDocumentTemplates = documentTemplates.length > 0;

  const [files, setFiles] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TABS.UPLOAD);
  const { hasPermission } = useContext(UserContext);
  const showCreateHudPage2 =
    hasPermission(PERMISSION_ACTION.HUD_FEATURES) && !!drawId;

  const editFile = (index, fieldName, value) => {
    setFiles((files) =>
      files.map((file, fileIndex) => {
        if (fileIndex === index) {
          set(file, fieldName, value);
        }
        return file;
      })
    );
  };

  const concatFiles = (newFiles) => {
    setFiles((oldFiles) => oldFiles.concat(newFiles));
  };

  const removeFile = (index) => {
    setFiles((oldFiles) => oldFiles.filter((_oldFile, i) => i !== index));
  };

  const additionalModalProps = (selectedTab) =>
    selectedTab === TABS.CREATE && {
      contentContainerProps: { padding: 0, paddingTop: majorScale(2) },
    };

  const sizeProps =
    selectedTab === TABS.CREATE && lineItems.length > 0
      ? { size: "large" }
      : { width: 750 };

  const renderTabs = () =>
    (hasDocumentTemplates || showCreateHudPage2) && (
      <Modal.Content marginBottom={majorScale(2)}>
        <Tablist>
          <Tab
            key={TABS.UPLOAD}
            id={TABS.UPLOAD}
            isSelected={selectedTab === TABS.UPLOAD}
            onSelect={() => setSelectedTab(TABS.UPLOAD)}
          >
            Upload Documents
          </Tab>
          {hasDocumentTemplates && (
            <Tab
              key={TABS.CREATE}
              id={TABS.CREATE}
              isSelected={selectedTab === TABS.CREATE}
              onSelect={() => setSelectedTab(TABS.CREATE)}
            >
              Create Document
            </Tab>
          )}
          {showCreateHudPage2 && (
            <Tab
              key={TABS.CREATE_92403}
              id={TABS.CREATE_92403}
              isSelected={selectedTab === TABS.CREATE_92403}
              onSelect={() => setSelectedTab(TABS.CREATE_92403)}
            >
              Create 92403 - Page 2
            </Tab>
          )}
        </Tablist>
      </Modal.Content>
    );

  const renderContent = () => {
    if (selectedTab === TABS.CREATE) {
      return (
        <CreateDocument
          create={create}
          createResult={createResult}
          documentTemplates={documentTemplates}
          drawId={drawId}
          lineItems={lineItems}
          onClose={onCancel}
          preview={preview}
          previewDocument={previewDocument}
          previewResult={previewResult}
          projectId={projectId}
        />
      );
    }
    if (selectedTab === TABS.CREATE_92403) {
      return (
        <Modal.Content>
          <Pane display="flex" justifyContent="center">
            <Text>
              Press &quot;Create&quot; to generate Page 2 of the 92403
            </Text>
          </Pane>
        </Modal.Content>
      );
    }
    return (
      <Modal.Content>
        <Dropzone
          files={files}
          onAdd={concatFiles}
          onEdit={editFile}
          onRemove={removeFile}
        />
      </Modal.Content>
    );
  };

  const renderActions = () => {
    if (selectedTab === TABS.UPLOAD)
      return (
        <Modal.Actions display="flex" alignItems="center">
          <Link purpose="splitter help" href={t("splitterHelp.learnMoreLink")}>
            {t("splitterHelp.learnMoreUploadDescription")}
          </Link>
          <Button
            marginLeft="auto"
            marginRight={minorScale(3)}
            onClick={onCancel}
            purpose="upload-files cancel"
          >
            Cancel
          </Button>
          <Button
            appearance="primary"
            disabled={
              files.length === 0 ||
              files.some(
                ({ numberOfPages, type }) =>
                  type === "application/pdf" && !numberOfPages
              )
            }
            onClick={() => {
              onSubmit({ files });
            }}
            purpose="upload-files submit"
          >
            Upload
          </Button>
        </Modal.Actions>
      );

    if (selectedTab === TABS.CREATE_92403) {
      return (
        <Modal.Actions display="flex" justifyContent="flex-end">
          <Button
            marginLeft="auto"
            marginRight={minorScale(3)}
            onClick={onCancel}
            purpose="upload-files cancel"
          >
            Cancel
          </Button>
          <Button
            appearance="primary"
            isLoading={create92403Page2Result.loading}
            onClick={() => create92403Page2({ variables: { drawId } })}
            purpose="upload-files create-2403"
          >
            Create
          </Button>
        </Modal.Actions>
      );
    }
    return null;
  };

  return (
    <Modal
      open
      title={title}
      onClose={onCancel}
      {...props}
      {...sizeProps}
      {...additionalModalProps(selectedTab)}
    >
      {renderTabs()}
      {renderContent()}
      {renderActions()}
    </Modal>
  );
};

export default UploadFilesModal;
