import { useState } from "react";
import { Pane, RedesignLayout } from "components/materials";
import isEqualWithout from "helpers/isEqualWithout";
import { NavigationWarnings } from "helpers/behaviors";
import FileSplitter from "./Splitter";
import Help from "./Help";
import Header from "./Header";

export default function Layout({
  upload,
  onClose,
  submitting,
  propsFormik,
  helpDismissed,
  onDismissHelp,
}) {
  const [scale, setScale] = useState(50);

  const dirty = !isEqualWithout(
    "start",
    propsFormik.values,
    propsFormik.initialValues
  );

  return (
    <RedesignLayout.Panel paddingTop={56}>
      <NavigationWarnings dirty={dirty} />
      <Header
        loading={submitting}
        onSubmit={propsFormik.handleSubmit}
        onClose={onClose}
        scale={scale}
        setScale={setScale}
        upload={upload}
      />
      <Pane height="100%" overflowY="auto" width="100%">
        <Help dismissed={helpDismissed} onDismiss={onDismissHelp} />
        <FileSplitter propsFormik={propsFormik} scale={scale} upload={upload} />
      </Pane>
    </RedesignLayout.Panel>
  );
}
