import { useContext, Fragment } from "react";
import { Formik, FieldArray } from "formik";
import { CrossIcon } from "evergreen-ui";
import {
  Button,
  IconButton,
  Form,
  Pane,
  Paragraph,
} from "components/materials";
import t from "helpers/translate";
import { get, set } from "lodash";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import isBlank from "helpers/isBlank";
import {
  dateServerToForm,
  dateFormToServer,
  isInvalidDate,
} from "helpers/dateHelpers";
import { css } from "glamor";
import { STEPS } from "./utils";
import { Header } from "./Header";
import { PersistStep } from "./PersistStep";

const emptyDrawInput = (project, name) => {
  const setupDetails = get(project, "onboardingData.details", {});
  const startDate = isBlank(setupDetails.startDate)
    ? undefined
    : setupDetails.startDate;

  return {
    name: name || "",
    targetAmount: 0,
    date: dateServerToForm(startDate || Date.now()),
  };
};

const initialValues = (project) => {
  const draws = get(project, "onboardingData.draws", []);
  return draws.length > 0
    ? { draws }
    : { draws: [emptyDrawInput(project, "Closing Draw")] };
};

const validate = ({ draws }) => {
  const errors = {};
  draws.forEach((draw, index) => {
    if (isBlank(draw.name)) {
      set(errors, `draws.${index}.name`, "Please name the draw");
    }
    if (isBlank(draw.date)) {
      set(errors, `draws.${index}.date`, "Please enter a date");
    }
    if (!isBlank(draw.date) && isInvalidDate(draw.date)) {
      set(errors, `draws.${index}.date`, "Date is invalid");
    }
  });

  return errors;
};

export function PreviousDrawNamesStep({
  goBack,
  project,
  loading,
  updateOnboardingData,
  navigateToStep,
  theme,
}) {
  const { isDeveloper } = useContext(UserContext);
  const values = initialValues(project);

  const getMutationData = ({ draws }) => ({
    draws: draws.map((draw) => ({
      ...draw,
      date: dateFormToServer(draw.date),
    })),
  });

  const navigateToDrawAmounts = (values) => {
    updateOnboardingData(getMutationData(values)).then(() =>
      navigateToStep(STEPS.PREVIOUS_DRAW_AMOUNTS)
    );
  };

  const tableClassName = css({
    borderCollapse: "separate",
    borderSpacing: `0 ${majorScale(1)}px`,
    width: 425,
  });

  return (
    <Formik
      initialValues={values}
      onSubmit={navigateToDrawAmounts}
      validate={validate}
    >
      {(formikProps) => (
        <Fragment>
          <Header
            header={t("onboardingWizard.previousDrawNames.header")}
            subheader={t("onboardingWizard.previousDrawNames.subheader")}
          />
          <FieldArray name="draws">
            {({ push, remove }) => (
              <Pane>
                <Pane textAlign="right" width={425}>
                  <Button
                    background={theme.colors.lightBlue}
                    appearance="minimal"
                    onClick={() => push(emptyDrawInput(project))}
                  >
                    Add draw
                  </Button>
                </Pane>
                <table className={tableClassName}>
                  <thead>
                    <tr>
                      <th aria-label="Remove" />
                      <th>
                        <Paragraph textAlign="left">Draw Name</Paragraph>
                      </th>
                      <th>
                        <Paragraph textAlign="left">
                          {isDeveloper ? "Sent Date" : "Received Date"}
                        </Paragraph>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formikProps.values.draws.map((draw, index) => (
                      <tr key={index}>
                        <td aria-label="progress">
                          <IconButton
                            appearance="minimal"
                            icon={CrossIcon}
                            onClick={() => remove(index)}
                          />
                        </td>
                        <td aria-label="progress">
                          <Pane paddingRight={majorScale(2)}>
                            <Form.Input name={`draws.${index}.name`} />
                          </Pane>
                        </td>
                        <td aria-label="progress">
                          <Form.DateInput name={`draws.${index}.date`} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Pane>
            )}
          </FieldArray>
          <Paragraph marginTop={majorScale(1)}>
            {t("onboardingWizard.previousDrawNames.hint")}
          </Paragraph>
          <Pane marginTop={majorScale(3)}>
            <Button
              onClick={() => {
                goBack();
              }}
            >
              Back
            </Button>
            <Button
              appearance="primary"
              isLoading={loading}
              onClick={formikProps.handleSubmit}
              purpose="onboarding-wizard previous draw names"
              marginLeft={majorScale(2)}
            >
              Next
            </Button>
          </Pane>
          <PersistStep
            mutationData={getMutationData(formikProps.values)}
            projectId={project.id}
          />
        </Fragment>
      )}
    </Formik>
  );
}
