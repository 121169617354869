import { useState } from "react";
import {
  Alert,
  Button,
  Divider,
  FileViewer,
  Form,
  Grid,
  Pane,
  Text,
} from "components/materials";
import { countBy, get, set, xor } from "lodash";
import isBlank from "helpers/isBlank";
import getOptions from "helpers/getOptions";
import {
  dateServerToForm,
  dateFormToServer,
  isInvalidDate,
} from "helpers/dateHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import { add } from "helpers/math";
import { majorScale } from "helpers/utilities";
import unformatNumber from "helpers/unformatNumber";
import { InvoiceReview } from "./DocumentReview/InvoiceReview";

const getLineItem = () => ({
  lineItemObject: { id: null, name: null },
  amount: 0,
  retainageAmount: 0,
  retainageToDateAmount: 0,
});

const CreateDocumentFormLayout = ({ form, templates, lineItems }) => {
  const [collapsedPanels, setCollapsedPanels] = useState([]);

  function togglePanel(panelName) {
    setCollapsedPanels(xor(collapsedPanels, [panelName]));
  }

  return (
    <InvoiceReview
      collapsedPanels={collapsedPanels}
      disableActions
      disableActivity
      disableComments
      disableDescription
      disableHeader
      disableTrackCostToAgreements
      disableWarnings
      disableJobCostCodes
      document={{}}
      form={form}
      information={() => (
        <Pane display="flex">
          <Pane flexGrow={1} marginRight={majorScale(2)}>
            <Form.Input name="number" label="Invoice Number" />
          </Pane>

          <Pane flexGrow={1}>
            <Form.DateInput name="date" label="Invoice Date" />
          </Pane>
        </Pane>
      )}
      meta={() => (
        <Form.Select
          width={250}
          name="templateId"
          options={getOptions(templates)}
        />
      )}
      selectedDraw={{ lineItems }}
      lineItemLimit={10}
      togglePanel={togglePanel}
    />
  );
};

const CreateDocumentForm = ({
  drawId,
  form,
  result,
  templates,
  lineItems,
  onClose,
  preview,
  previewDocument,
  previewResult,
  projectId,
}) => {
  const documentURL = drawId
    ? `/projects/${projectId}/draws/${drawId}/documentation?uploadDocument=true`
    : `/projects/${projectId}/documentation?uploadDocument=true`;

  if (lineItems.length === 0) {
    return (
      <Alert marginX={majorScale(1)} marginY={majorScale(3)}>
        <Text>
          A budget is required to review this document. Please{" "}
          <a href={documentURL}>upload a budget</a>.
        </Text>
      </Alert>
    );
  }

  return (
    <Form.Generic
      textSubmit="Create"
      handleSubmit={form.handleSubmit}
      handleCancel={onClose}
      cancelLabel="Cancel"
      loading={result.loading}
      error={result.error}
      submitButtonProps={{
        marginRight: majorScale(2),
      }}
      buttonGroupProps={{ marginY: majorScale(2) }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column columns={8} padding={0}>
            <CreateDocumentFormLayout
              form={form}
              templates={templates}
              lineItems={lineItems}
            />
          </Grid.Column>
          <Grid.Column columns={8} padding={0}>
            {previewDocument ? (
              <Pane>
                <Button
                  content="Refresh Preview"
                  disabled={!form.isValid}
                  isLoading={previewResult.loading}
                  onClick={() =>
                    CreateDocumentForm.onPreviewSubmit(
                      preview,
                      lineItems
                    )(form.values)
                  }
                  purpose="create-document-form preview refresh"
                />
                <FileViewer file={previewDocument} />
              </Pane>
            ) : (
              <Pane
                background="overlay"
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                height="100%"
              >
                {previewResult.loading ? (
                  <Text>Loading...</Text>
                ) : (
                  <Pane>
                    <Text color="white">
                      Preview your document when details are complete
                    </Text>
                    <Divider opacity={0} />
                    <Button
                      content="Preview"
                      disabled={!form.isValid}
                      isLoading={previewResult.loading}
                      onClick={() =>
                        CreateDocumentForm.onPreviewSubmit(
                          preview,
                          lineItems
                        )(form.values)
                      }
                      purpose="create-document-form preview"
                    />
                  </Pane>
                )}
              </Pane>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Generic>
  );
};

// TODO: remove dot notation
CreateDocumentForm.initialValues = (
  projectId,
  drawId,
  templateId,
  organizationId
) => ({
  projectId,
  drawId,
  templateId,
  number: "",
  date: dateServerToForm(Date.now()),
  lineItems: [getLineItem()],
  type: "INVOICE",
  vendor: { id: organizationId },
});

// TODO: remove dot notation
CreateDocumentForm.validate = (values) => {
  const errors = {};
  if (isBlank(values.templateId)) {
    errors.templateId = "Please select a template";
  }
  if (isBlank(values.number)) errors.number = "Please enter a number";
  if (isBlank(values.date)) errors.date = "Please enter a date";
  else if (isInvalidDate(values.date)) errors.date = "Date is invalid";
  if (values.lineItems.length === 0) {
    errors._lineItems = "Please add a line item";
  }
  const count = countBy(values.lineItems, "lineItemObject.id");
  values.lineItems.forEach((lineItem, index) => {
    if (!get(lineItem, "lineItemObject.id")) {
      set(
        errors,
        `lineItems.${index}.lineItemObject`,
        "Please select a line item"
      );
    } else if (count[lineItem.lineItemObject.id] > 1) {
      set(
        errors,
        `lineItems.${index}.lineItemObject`,
        "Please remove duplicate line item"
      );
    }
    if (isBlank(lineItem.amount)) {
      set(errors, `lineItems.${index}.amount`, "Please enter an amount");
    }
  });
  return errors;
};

// TODO: remove dot notation
CreateDocumentForm.onSubmit = (mutation, projectLineItems) => (values) => {
  const lineItems = values.lineItems.map(
    ({
      amount,
      grossAmount,
      lineItemObject,
      lineItemIndex,
      retainageAmount,
      retainageToDateAmount,
    }) => ({
      amount: formatCurrency(amount),
      grossAmount:
        unformatNumber(retainageAmount) === 0
          ? formatCurrency(amount)
          : formatCurrency(grossAmount),
      lineItemIndex,
      lineItemId: lineItemObject.id,
      name: projectLineItems.find((li) => li.id === lineItemObject.id).name,
      retainageAmount: formatCurrency(retainageAmount),
      retainageToDateAmount: formatCurrency(retainageToDateAmount),
    })
  );

  const { netAmount, grossAmount, retainageAmount } = values.lineItems.reduce(
    (lineItemTotals, lineItem) => ({
      netAmount: add(lineItemTotals.netAmount, lineItem.amount),
      grossAmount: add(lineItemTotals.grossAmount, lineItem.grossAmount),
      retainageAmount: add(
        lineItemTotals.retainageAmount,
        lineItem.retainageAmount
      ),
    }),
    {}
  );

  const { date, drawId, number, projectId, templateId } = values;
  const variables = {
    date: dateFormToServer(date),
    drawId,
    grossAmount: formatCurrency(grossAmount),
    lineItems,
    netAmount: formatCurrency(netAmount),
    number,
    projectId,
    templateId,
    retainageAmount: formatCurrency(retainageAmount),
  };

  mutation({ variables });
};

CreateDocumentForm.onPreviewSubmit = (mutation, lineItems) => (values) => {
  const variables = {
    projectId: values.projectId,
    drawId: values.drawId,
    templateId: values.templateId,
    data: JSON.stringify({
      number: values.number,
      date: dateFormToServer(values.date),
      lineItems: values.lineItems.map(
        ({
          lineItemObject,
          amount,
          grossAmount,
          retainageAmount,
          retainageToDateAmount,
        }) => ({
          name: lineItems.find((li) => li.id === lineItemObject.id).name,
          amount,
          grossAmount:
            unformatNumber(retainageAmount) === 0
              ? amount
              : grossAmount.toString(),
          retainageAmount,
          retainageToDateAmount,
        })
      ),
    }),
  };
  mutation({ variables });
};

export default CreateDocumentForm;
