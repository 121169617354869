import { Text } from "components/materials";

export function TextCell({ children, textProps, ...cellProps }) {
  return (
    <td {...cellProps}>
      <Text size={300} {...textProps}>
        {children}
      </Text>
    </td>
  );
}
