export const MASTER_FORMAT_DIVISION = {
  CONCRETE: "CONCRETE",
  CONVEYING_SYSTEMS: "CONVEYING_SYSTEMS",
  DOORS_AND_WINDOWS: "DOORS_AND_WINDOWS",
  ELECTRICAL: "ELECTRICAL",
  EQUIPMENT: "EQUIPMENT",
  EXISTING_CONDITIONS: "EXISTING_CONDITIONS",
  FINISHES: "FINISHES",
  FURNISHINGS: "FURNISHINGS",
  GENERAL_REQUIREMENT: "GENERAL_REQUIREMENT",
  MASONRY: "MASONRY",
  MECHANICAL_PLUMBING: "MECHANICAL_PLUMBING",
  METALS: "METALS",
  SPECIAL_CONSTRUCTION: "SPECIAL_CONSTRUCTION",
  SPECIALTIES: "SPECIALTIES",
  THERMAL_AND_MOISTURE_PROTECTION: "THERMAL_AND_MOISTURE_PROTECTION",
  WOOD_AND_PLASTICS: "WOOD_AND_PLASTICS",
};
