import PropTypes from "prop-types";
import Box from "ui-box";
import { withTheme } from "evergreen-ui";
import { boxProps, boxPropTypes } from "components/materials/utils";
import { get } from "lodash";
import { getProps, compose, withStrictMemo } from "./utils";

const TableRow = compose(
  (props) => {
    const { children, onClick, state, testId } = props;

    // An undefined data-state means that the row will take the default styling
    return (
      <Box
        is="tr"
        cursor={onClick ? "pointer" : null}
        {...boxProps(props)}
        onClick={onClick}
        position="relative"
        data-border-state={
          typeof state === "string" ? state : get(state, "border")
        }
        data-background-state={
          typeof state === "string" ? state : get(state, "background")
        }
        data-testid={testId}
        zIndex={get(state, "zIndex")}
      >
        {children}
      </Box>
    );
  },
  [withStrictMemo, withTheme]
);

export const propTypes = {
  ...boxPropTypes,
  onClick: PropTypes.func,
  children: PropTypes.node,
  onMouseOver: PropTypes.func,
  index: PropTypes.number,
  renderOnChanged: PropTypes.any,
  state: PropTypes.node,
  testId: PropTypes.string,
};

TableRow.propTypes = propTypes;

// TODO: remove dot notation
TableRow.getProps = (props) => getProps(propTypes, props);

export default TableRow;
