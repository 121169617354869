import { forwardRef, useContext } from "react";
import { EvergreenButton } from "components/materials";
import { ThemeContext } from "helpers/utilities";
import { majorScale } from "evergreen-ui";
import { getButtonBackgroundStylingProps } from "helpers/themeHelpers";

function getIntentColor(theme, intent) {
  if (intent === "success") return theme.colors.green700;
  if (intent === "danger") return theme.colors.red500;
  if (intent === "warning") return theme.colors.warning;
  return null;
}

export default forwardRef(function Button(
  {
    appearance,
    children,
    content,
    intent,
    style,
    purpose,
    themeOverrides = {},
    ...rest
  },
  ref
) {
  const theme = useContext(ThemeContext);
  const { primaryColor, primaryButtonTextColor } = theme.defaultColors;
  const intentColor = getIntentColor(theme, intent);

  const overrideButtonProps = () => {
    if (appearance === "primary") {
      return {
        ...getButtonBackgroundStylingProps(
          themeOverrides.buttonColor || primaryColor
        ),
        appearance: "minimal",
        color: themeOverrides.buttonTextColor || primaryButtonTextColor,
      };
    }

    if (appearance === "minimal") {
      return intentColor
        ? {
            color: intentColor,
          }
        : null;
    }

    return {
      ...getButtonBackgroundStylingProps(theme.colors.gray100),
      appearance: "minimal",
      color: intentColor || theme.colors.gray900,
      border: `1px solid ${theme.colors.gray300}`,
    };
  };

  return (
    <EvergreenButton
      ref={ref}
      appearance={appearance}
      className="rbt-button"
      data-purpose={purpose}
      justifyContent="center"
      style={{ WebkitAppearance: "none", ...style }}
      type="button"
      whiteSpace="nowrap"
      height={majorScale(4)}
      {...overrideButtonProps()}
      {...rest}
    >
      {content || children}
    </EvergreenButton>
  );
});
