import { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Heading,
  ListItem,
  Pane,
  Paragraph,
  UnorderedList,
} from "components/materials";
import { formatDate } from "helpers/dateHelpers";

class ReportSidebar extends PureComponent {
  renderReviewer = ({ id, processedAt, state, reviewer }) => {
    const { translateMessage } = this.props;
    return (
      <ListItem key={id}>
        <Pane>
          <Heading content={`${reviewer.fullName}`} />
          <Paragraph>
            {translateMessage(`reviews.reviewStatusOn.${state}`, {
              date: formatDate(processedAt),
            })}
          </Paragraph>
        </Pane>
      </ListItem>
    );
  };

  render() {
    const { draw } = this.props;

    if (!draw) return null;
    return (
      <UnorderedList listStyleType="none">
        {draw.reviews.map(this.renderReviewer)}
      </UnorderedList>
    );
  }
}

ReportSidebar.propTypes = {
  draw: PropTypes.shape({
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        processedAt: PropTypes.string,
        result: PropTypes.string,
        reviewer: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      })
    ),
  }),
};

export default ReportSidebar;
