import { useContext, Fragment, useMemo, useState } from "react";
import { FieldArray, Formik } from "formik";
import {
  Banner,
  Button,
  Confirm,
  Pane,
  Paragraph,
  Text,
  Spinner,
} from "components/materials";
import {
  FastDataTable,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { DirtyEffect, UserContext } from "helpers/behaviors";
import {
  getDocumentUpdates,
  fillBlankRanges,
  buildDocument,
} from "helpers/uploadHelpers";
import { majorScale, minorScale, withTheme } from "helpers/utilities";
import { first, get, identity, last, sortBy } from "lodash";
import { DOCUMENT_TYPE_NAME, PERMISSION_ACTION } from "helpers/enums";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { documentsProcessing, fileWithMagnifyingGlass } from "images";
import {
  documentIsTimedOut,
  uploadIsClassifying,
  uploadIsProcessing,
} from "helpers/documentHelpers";
import analytics from "helpers/analytics";
import UploadFormControls from "./UploadFormControls";

function UploadForm({
  drawId,
  fieldArray,
  form,
  getProjectVendorSearchQuery,
  hasPermission,
  mutationLoading,
  newlyAddedVendors,
  parseDocumentInformation,
  projectId,
  searchedVendors,
  setConfirmClassifyOpen,
  setConfirmSplitAndClassifyOpen,
  setNewlyAddedVendors,
  setReparseOnSubmit,
  theme,
  upload,
  uploadMutations,
  vendors,
  viewerDirty,
}) {
  const columns = useMemo(() => {
    return [
      {
        ...stringColumnDefaults,
        id: "pages",
        header: "Pages",
        width: "15%",
        value: identity,
        valueFormatter: ({ startPage, endPage }, _document, _props) => {
          if (startPage === endPage) return startPage;
          return `${startPage} - ${endPage}`;
        },
      },
      {
        ...stringColumnDefaults,
        id: "documentType",
        header: "Document Type",
        value: ({ type }) => DOCUMENT_TYPE_NAME[type],
        valueFormatter: (type, document, _props) => {
          if (document.deleted) {
            return (
              <Text
                fontWeight={theme.fontWeights.MEDIUM}
                fontStyle="italic"
                size={300}
              >
                Deleted
              </Text>
            );
          }

          return t(`documentTypeName.${type}`);
        },
        width: "30%",
      },
      {
        ...stringColumnDefaults,
        id: "vendor",
        header: "Vendor",
        width: "50%",
        value: ({ vendor }) => get(vendor, "name"),
        valueFormatter: (vendorName, item, _props) => {
          if (item.deleted) {
            return "";
          }
          if (isBlank(vendorName)) return "-";
          return vendorName;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors.length, newlyAddedVendors, searchedVendors]);

  return (
    <FastDataTable
      columns={columns}
      controls={(controlsProps) => (
        <UploadFormControls
          {...controlsProps}
          drawId={drawId}
          fieldArray={fieldArray}
          form={form}
          getProjectVendorSearchQuery={getProjectVendorSearchQuery}
          mutationLoading={mutationLoading}
          newlyAddedVendors={newlyAddedVendors}
          parseDocumentInformation={
            parseDocumentInformation &&
            hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT)
          }
          projectId={projectId}
          searchedVendors={searchedVendors}
          setConfirmClassifyOpen={setConfirmClassifyOpen}
          setConfirmSplitAndClassifyOpen={setConfirmSplitAndClassifyOpen}
          setNewlyAddedVendors={setNewlyAddedVendors}
          setReparseOnSubmit={setReparseOnSubmit}
          upload={upload}
          uploadMutations={uploadMutations}
          vendors={vendors}
          viewerDirty={viewerDirty}
        />
      )}
      getRowState={({ deleted, startPage, endPage }) => {
        if (deleted) return { background: "deleted" };
        if (!startPage && !endPage) return { background: "success" };
        return undefined;
      }}
      items={form.values.documents}
      pageSize={null}
    />
  );
}

function UploadReview({
  drawId,
  getProjectVendorSearchQuery,
  initialParseDocumentInformation,
  mutationLoading,
  newlyAddedVendors,
  projectId,
  searchedVendors,
  setNewlyAddedVendors,
  setReparseOnSubmit,
  setViewerDirty,
  theme,
  upload,
  uploadMutations,
  userId,
  userParseDocumentInformationLoading,
  vendors,
  viewerDirty,
}) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmClassifyOpen, setConfirmClassifyOpen] = useState(false);
  const [
    confirmSplitAndClassifyOpen,
    setConfirmSplitAndClassifyOpen,
  ] = useState(false);
  const { hasPermission } = useContext(UserContext);

  const handleDeleteUpload = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = () => {
    setConfirmDeleteOpen(false);
    if (confirmDeleteOpen) {
      uploadMutations.deleteUpload({ variables: { uploadId: upload.id } });
    }
  };

  const handleConfirmClassify = (initialValues, formValues) => {
    // set type to null to trigger reclassifying
    analytics.track("Auto Classify Initiated");
    const documentUpdates = updateDocuments(
      initialValues,
      formValues.documents
    ).map((document) => ({
      ...document,
      type: null,
    }));

    uploadMutations.setUploadDocuments({
      variables: { uploadId: upload.id, documentUpdates },
    });
    setConfirmClassifyOpen(false);
  };

  const handleConfirmSplitAndClassify = () => {
    analytics.track("Auto Split and Classify Initiated");
    uploadMutations.splitAndClassifyUploadDocuments({
      variables: { uploadId: upload.id },
    });
    setConfirmSplitAndClassifyOpen(false);
  };

  const handleSubmit = (initialValues) => (values, _formikBag) => {
    const documentUpdates = updateDocuments(initialValues, values.documents);
    const variables = {
      uploadId: upload.id,
      documentUpdates,
    };

    uploadMutations.setUploadDocuments({ variables });
  };

  const updateDocuments = (initialValues, currentDocuments) => {
    const initialDocumentIds = get(initialValues, "documents", []).map(
      ({ id }) => id
    );

    const filteredDocuments = currentDocuments.filter(
      ({ startPage, endPage }) => !isBlank(startPage) && !isBlank(endPage)
    );

    return getDocumentUpdates(filteredDocuments, initialDocumentIds).map(
      (update) => {
        // if the document pages or type change, set reparse to true
        const uploadDocumentChanged =
          initialValues.startPage !== update.startPage ||
          initialValues.endPage !== update.endPage ||
          initialValues.type !== update.type;

        // Whitelist the fields needed for the mutation
        return uploadDocumentChanged
          ? {
              endPage: update.endPage,
              operation: update.operation,
              reparse: true,
              sourceDocumentId: update.sourceDocumentId,
              startPage: update.startPage,
              type: update.type,
              vendorId: get(update, "vendor.id"),
            }
          : {
              endPage: update.endPage,
              operation: update.operation,
              sourceDocumentId: update.sourceDocumentId,
              startPage: update.startPage,
              type: update.type,
              vendorId: get(update, "vendor.id"),
            };
      }
    );
  };

  const uploadDocuments = get(upload, "documents", []);
  const initialValues = UploadReview.initialValues(upload, uploadDocuments);
  const guestVendors = uploadDocuments
    .map(({ vendor }) => vendor)
    .filter((vendor) => {
      // Filter to vendors which are not listed within the given `vendors` for this organization
      return (
        vendors
          .concat(newlyAddedVendors)
          .map(({ id }) => id)
          .indexOf(vendor.id) === -1
      );
    });

  const fileName = get(upload, "file.name");
  const fileType = get(upload, "file.type");
  const target = get(upload, "draw.name", "the Project");

  const isClassifying = uploadIsClassifying(upload);
  const isProcessing = uploadIsProcessing(upload);

  if (isProcessing || isClassifying)
    return (
      <Pane>
        <Pane
          height={48}
          display="flex"
          alignItems="center"
          paddingY={majorScale(1)}
          paddingX={minorScale(3)}
          borderBottom
        >
          <Text
            size={400}
            fontWeight={theme.fontWeights.MEDIUM}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            flexGrow={1}
          >
            {fileName}
          </Text>
          <Spinner size={majorScale(3)} />
        </Pane>
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={majorScale(6)}
        >
          <Pane
            alt=""
            height={124}
            is="img"
            src={isProcessing ? documentsProcessing : fileWithMagnifyingGlass}
          />
          <Pane marginLeft={majorScale(4)}>
            <Paragraph fontWeight={theme.fontWeights.DEMI} maxWidth="70%">
              {isProcessing
                ? t("uploadsViewer.documentsProcessing1")
                : t("uploadsViewer.documentsClassifying1")}
            </Paragraph>
            <Paragraph marginY={majorScale(1)} maxWidth="80%">
              {isProcessing
                ? t("uploadsViewer.documentsProcessing2")
                : t("uploadsViewer.documentsClassifying2")}
            </Paragraph>
          </Pane>
        </Pane>
      </Pane>
    );

  return fileType === "application/pdf" ? (
    <Fragment>
      {upload.hasMixedTargets && (
        <Banner
          borderBottom
          flexDirection="column"
          mainText={t("uploadsViewer.mixedUploadMain")}
          secondaryText={t("uploadsViewer.mixedUploadSecondary", { target })}
          textProps={{ size: 300 }}
        />
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit(initialValues)}
      >
        {(form) => {
          return (
            <Pane display="flex" flexDirection="column" height="100%">
              <Pane flex="1 1 auto" overflowY="auto">
                <FieldArray name="documents">
                  {(fieldArray) => (
                    <UploadForm
                      drawId={drawId}
                      form={form}
                      fieldArray={fieldArray}
                      guestVendors={guestVendors}
                      getProjectVendorSearchQuery={getProjectVendorSearchQuery}
                      hasPermission={hasPermission}
                      mutationLoading={mutationLoading}
                      projectId={projectId}
                      setConfirmClassifyOpen={setConfirmClassifyOpen}
                      setConfirmSplitAndClassifyOpen={
                        setConfirmSplitAndClassifyOpen
                      }
                      setReparseOnSubmit={setReparseOnSubmit}
                      theme={theme}
                      upload={upload}
                      uploadMutations={uploadMutations}
                      vendors={vendors}
                      viewerDirty={viewerDirty}
                      newlyAddedVendors={newlyAddedVendors}
                      searchedVendors={searchedVendors}
                      setNewlyAddedVendors={setNewlyAddedVendors}
                    />
                  )}
                </FieldArray>
              </Pane>
              <DirtyEffect dirty={form.dirty} setFormDirty={setViewerDirty} />
              <Confirm
                content={t("uploadsViewer.autoClassifyConfirmContent", {
                  fileName: get(upload, "file.name"),
                })}
                header="Auto Classify"
                hideViewer
                onCloseComplete={() => setConfirmClassifyOpen(false)}
                onConfirm={() =>
                  handleConfirmClassify(initialValues, form.values)
                }
                open={confirmClassifyOpen}
                cancelLabel="Cancel"
                confirmLabel="Auto Classify"
                warningMessage={t("uploadsViewer.autoClassifyConfirmWarning")}
              />
              <Confirm
                content={t("uploadsViewer.autoSplitAndClassifyConfirmContent", {
                  fileName: get(upload, "file.name"),
                })}
                header="Auto Split & Classify"
                hideViewer
                onCloseComplete={() => setConfirmSplitAndClassifyOpen(false)}
                onConfirm={handleConfirmSplitAndClassify}
                open={confirmSplitAndClassifyOpen}
                cancelLabel="Cancel"
                confirmLabel="Auto Split & Classify"
                warningMessage={t(
                  "uploadsViewer.autoSplitAndClassifyConfirmWarning"
                )}
              />
            </Pane>
          );
        }}
      </Formik>
    </Fragment>
  ) : (
    <Pane>
      <Confirm
        content={t("uploadsViewer.confirmDeleteAlternate")}
        header="Delete Upload"
        hideViewer
        onCloseComplete={() => setConfirmDeleteOpen(false)}
        onConfirm={handleConfirmDelete}
        open={confirmDeleteOpen}
        cancelLabel="Cancel"
        confirmLabel="Delete Upload"
      />
      <Pane
        height={48}
        display="flex"
        alignItems="center"
        paddingY={majorScale(1)}
        paddingX={minorScale(3)}
        borderBottom
      >
        <Text
          size={400}
          fontWeight={theme.fontWeights.MEDIUM}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          flexGrow={1}
        >
          {fileName}
        </Text>
        <Button
          isLoading={mutationLoading}
          onClick={handleDeleteUpload}
          marginLeft={majorScale(1)}
          purpose="upload delete"
        >
          Delete Upload
        </Button>
      </Pane>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={majorScale(6)}
      >
        <Pane alt="" height={124} is="img" src={fileWithMagnifyingGlass} />
        <Pane marginLeft={majorScale(4)}>
          <Paragraph fontWeight={theme.fontWeights.DEMI}>
            {t("uploadsViewer.nonReviewableTitle")}
          </Paragraph>
          <Paragraph marginY={majorScale(1)}>
            {t("uploadsViewer.nonReviewableText1")}
          </Paragraph>
          <Paragraph>{t("uploadsViewer.nonReviewableText2")}</Paragraph>
        </Pane>
      </Pane>
    </Pane>
  );
}

// TODO: remove dot notation
UploadReview.initialValues = (upload, uploadDocuments) => {
  const uploadPages = get(upload, "pages", []);
  const lastUploadPage = uploadPages[uploadPages.length - 1];

  const uploadTimedOut =
    documentIsTimedOut(upload) && uploadDocuments.length === 0;

  let documents = uploadTimedOut
    ? [
        buildDocument({
          type: DOCUMENT_TYPE_NAME.UNKNOWN,
          startPage: 1,
          endPage: lastUploadPage,
        }),
      ]
    : uploadDocuments.map(({ agreement, id, pages, type, vendor }) => ({
        id,
        agreement,
        deleted: false,
        endPage: last(pages),
        sourceDocumentId: id,
        startPage: first(pages),
        type,
        vendor,
      }));

  documents = sortBy(documents, ["startPage", "endPage"]);
  documents = fillBlankRanges(documents, lastUploadPage, true);

  return { documents };
};

export default withTheme(UploadReview);
