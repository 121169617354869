import gql from "graphql-tag";
import {
  BUDGET_FRAGMENT,
  SLIDEOUT_DOCUMENTS_FRAGMNENT,
} from "helpers/fragments";

export const BUDGET_QUERY = gql`
  query ProjectBudgetPage($projectId: String!) {
    project(id: $projectId) {
      ...BudgetFragment
    }
  }
  ${BUDGET_FRAGMENT}
`;

export const ISSUES_SUBSCRIPTION = gql`
  subscription projectIssuesUpdated($projectId: String!) {
    projectIssuesUpdated(projectId: $projectId) {
      id
      lineItems {
        id
        scopeId
        issues {
          id
          name
          severity
        }
      }
    }
  }
`;

export const UPDATE_USER_VIEW_CONFIG = gql`
  mutation UpdateUserViewConfig(
    $showBudgetCharts: Boolean!
    $budgetChartView: BudgetChartView
  ) {
    updateUserViewConfig(
      showBudgetCharts: $showBudgetCharts
      budgetChartView: $budgetChartView
    ) {
      id
      showBudgetCharts
      budgetChartView
    }
  }
`;

const RECENT_LINE_ITEM_FRAGMENT = gql`
  fragment RecentLineItemFragment on LineItem {
    id
    scopeId
    originalBudgetAmount
    adjustmentsAmount
    previousAdjustmentsAmount
    requestedToDateAmount
    retainageToDateAmount
    committedAmount
    exposedAmount
  }
`;

const LINE_ITEM_FRAGMENT = gql`
  fragment LineItemFragment on LineItem {
    id
    scopeId
    name
    originalBudgetAmount
    division {
      id
      scopeId
      name
    }
    expenses {
      id
      amount
      isBackup
      document {
        ...SlideoutDocumentsFragment
      }
    }
    agreements {
      id
      name
      lineItemAmount
      vendor {
        id
        name
      }
      type
    }
    issues {
      id
      name
      severity
    }
  }
  ${SLIDEOUT_DOCUMENTS_FRAGMNENT}
`;

const ADJUSTMENT_LINE_ITEM_FRAGMENT = gql`
  fragment AdjustmentLineItemFragment on LineItem {
    id
    scopeId
    adjustmentsAmount
    budgetAdjustments {
      id
      comment
      transactions {
        id
        amount
        lineItem {
          id
          scopeId
          name
          budgetAmount
          division {
            id
            scopeId
            name
          }
        }
      }
      agreement {
        id
        name
      }
    }
  }
`;

export const LINE_ITEM_QUERY = gql`
  query BudgetLineItemSlideoutQuery($projectId: String!, $lineItemId: String!) {
    project(id: $projectId) {
      id
      recentDraw {
        id
        lineItem(id: $lineItemId) {
          ...RecentLineItemFragment
        }
      }
      lineItem(id: $lineItemId) {
        ...LineItemFragment
      }
      draws {
        id
        name
        lineItem(id: $lineItemId) {
          ...AdjustmentLineItemFragment
        }
      }
    }
  }
  ${RECENT_LINE_ITEM_FRAGMENT}
  ${LINE_ITEM_FRAGMENT}
  ${ADJUSTMENT_LINE_ITEM_FRAGMENT}
`;

export const SUPER_LINE_ITEM_QUERY = gql`
  query BudgetSuperLineItemSlideoutQuery(
    $projectId: String!
    $superLineItemId: String!
  ) {
    project(id: $projectId) {
      id
      recentDraw {
        id
        superLineItem(id: $superLineItemId) {
          id
          lineItems {
            ...RecentLineItemFragment
          }
        }
      }
      superLineItem(id: $superLineItemId) {
        id
        name
        division {
          id
          scopeId
          name
        }
        lineItems {
          ...LineItemFragment
        }
      }
      draws {
        id
        name
        superLineItem(id: $superLineItemId) {
          id
          lineItems {
            ...AdjustmentLineItemFragment
          }
        }
      }
    }
  }
  ${RECENT_LINE_ITEM_FRAGMENT}
  ${LINE_ITEM_FRAGMENT}
  ${ADJUSTMENT_LINE_ITEM_FRAGMENT}
`;
