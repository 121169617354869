import gql from "graphql-tag";

export default gql`
  fragment GuestProjectFragment on Project {
    id
    city
    expectedProjectLength
    name
    owner {
      id
      name
    }
    startDate
    state
    streetAddress
    template {
      id
      icon
    }
    zip
  }
`;
