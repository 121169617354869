import { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { get, flatMap, minBy } from "lodash";
import { DocumentIcon } from "evergreen-ui";
import { Pane, Text, Tooltip } from "components/materials";
import { formatDate } from "helpers/dateHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import { sumBy } from "helpers/math";
import { minorScale, ThemeContext } from "helpers/utilities";

function AgreementDetails({ agreementVendorLineItems }) {
  const agreementsAmount = sumBy(agreementVendorLineItems, "agreementsAmount");
  const spentToDateAmount = sumBy(
    agreementVendorLineItems,
    "spentToDateAmount"
  );
  const agreements = flatMap(
    agreementVendorLineItems,
    (avli) => avli.agreements
  );
  const startDate = get(minBy(agreements, "startDate"), "startDate");
  return (
    <Fragment>
      <Pane>Agreement Amount: {formatCurrency(agreementsAmount)}</Pane>
      <Pane>Spent to Date: {formatCurrency(spentToDateAmount)}</Pane>
      <Pane>Date: {formatDate(startDate, "N/A")}</Pane>
    </Fragment>
  );
}

export function AgreementDetailsForVendor({
  agreementVendorLineItems,
  vendorId,
}) {
  const theme = useContext(ThemeContext);

  if (!vendorId) return null;

  return (
    <Tooltip
      content={
        <Pane padding={minorScale(1)}>
          <Text color="white">
            <AgreementDetails
              agreementVendorLineItems={agreementVendorLineItems.filter(
                (avli) => get(avli, "vendor.id") === vendorId
              )}
            />
          </Text>
        </Pane>
      }
    >
      <Pane display="flex" alignItems="center">
        <DocumentIcon
          color={theme.colors.text.default}
          marginRight={minorScale(1)}
        />
        <Text cursor="default">Vendor Agreement</Text>
      </Pane>
    </Tooltip>
  );
}

AgreementDetailsForVendor.propTypes = {
  agreementVendorLineItems: PropTypes.arrayOf(PropTypes.object),
  vendorId: PropTypes.string.isRequired,
};
