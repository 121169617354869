import { useState } from "react";
import { Form, Pane, MaskedInput } from "components/materials";
import { FastField } from "formik";
import { get } from "lodash";

const FormBlurInput = ({
  label,
  labelProps,
  outerProps,
  onBlur,
  onChange,
  ...props
}) => {
  const [tempValue, setTempValue] = useState(null);

  const handleChange = (event) => {
    setTempValue(event.target.value);
    onChange && onChange(event);
  };

  const handleBlur = (form) => (event) => {
    form.handleChange(event);
    form.handleBlur(event);
    setTempValue(null);
    onBlur && onBlur(event);
  };

  return (
    <FastField {...props}>
      {({ form: { touched, errors, status, ...form }, field }) => {
        return (
          <Form.Field
            label={label && <Pane {...labelProps}>{label}</Pane>}
            validationMessage={
              get(touched, field.name) && get(errors, field.name)
            }
            {...outerProps}
          >
            <MaskedInput
              {...props}
              {...field}
              disabled={
                props.disabled ||
                form.values.__disabled ||
                get(status, "disabled")
              }
              onChange={handleChange}
              onBlur={handleBlur(form)}
              value={tempValue || field.value}
            />
          </Form.Field>
        );
      }}
    </FastField>
  );
};

export default FormBlurInput;
