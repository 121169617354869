import { useContext, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { DeleteIcon, AddIcon } from "evergreen-ui";
import { JobCostCodeModal } from "components/containers";
import { VendorLink } from "components/templates";
import {
  Button,
  Confirm,
  Table,
  Form,
  IconButton,
  Pane,
} from "components/materials";
import { DocumentContext } from "contexts/documentContext";
import { majorScale, minorScale } from "helpers/utilities";
import { DOCUMENT_TYPE_NAME, PERMISSION_ACTION } from "helpers/enums";
import {
  formatLineItems,
  getDescription,
  getLineItemOptions,
  getMatch,
} from "helpers/payApplicationLineItemHelpers";
import {
  autoCalculateLineItemRetainage,
  calculateLineItemRetainageToDate,
  getPreviousRetainage,
  getRetainageThisDraw,
  getTotalRetainage,
} from "helpers/retainageLineItemHelpers";
import { dateFormToServer, formatDate } from "helpers/dateHelpers";
import { UserContext } from "helpers/behaviors";
import humanReadableList from "helpers/humanReadableList";
import isBlank from "helpers/isBlank";
import { add, subtract, sumBy } from "helpers/math";
import unformatNumber from "helpers/unformatNumber";
import { formatCurrency } from "helpers/formatCurrency";
import {
  getLineItemsWithInsufficientFunds,
  removeDismissedWarnings,
} from "helpers/documentHelpers";
import {
  invoiceAmountFullyNotAllocatedToAgreements,
  lineItemsNotCoveredByAgreements,
  mapAgreementAmounts,
} from "helpers/agreementAmountHelpers";
import {
  getCurrentPaymentDue,
  getIsGeneralContractor,
} from "helpers/RequestedAmountHelpers";
import t from "helpers/translate";
import { every, find, get, set, sortBy, omit } from "lodash";
import { v4 as uuid } from "uuid";
import { AgreementDetailsForVendor } from "./AgreementDetailsForVendor";
import DocumentReviewLayout from "./DocumentReviewLayout";
import DocumentLineItems from "./DocumentLineItems";
import GeneralContractorStakeholderPrompt from "./GeneralContractorStakeholderPrompt";
import { PayApplicationSplitTable } from "./PayApplicationSplitTable";
import {
  TrackCostToAgreements,
  shouldShowTrackCostToAgreementsSection,
  shouldShowTrackCostToAgreementsWarnings,
} from "./TrackCostToAgreements";
import IsBackupCheckbox from "./IsBackupCheckbox";
import { getAutofilledFields } from "./AutofilledFieldsBadge";

const getblankCodeOption = () => ({ key: uuid(), value: null, text: "" });

const getCostCodeOptions = (jobCostCodes) => {
  const sortedCodes = sortBy(jobCostCodes, ({ code }) => code.toLowerCase());
  return [getblankCodeOption()].concat(
    sortedCodes.map(({ code, description, id }) => ({
      key: id,
      text: description ? `${code}: ${description}` : code,
      value: id,
    }))
  );
};

const getTotalPaymentDue = (form) => {
  return form.values.lineItems.reduce((total, lineItem) => {
    return add(total, getCurrentPaymentDue(form.values, lineItem));
  }, 0);
};

const matchLineItem = (drawLineItems, selectedDraw, document) => (lineItem) => {
  const match = getMatch(drawLineItems, lineItem);
  const lineItemObject = match
    ? {
        id: match.id,
        descriptionOfWork: match.fullName,
        hardCosts: match.hardCosts,
      }
    : { id: null, descriptionOfWork: null, hardCosts: null };
  return {
    applicationAmount: get(lineItem, "applicationAmount", 0),
    hardCosts: get(match, "hardCosts", false),
    jobCostCodes: get(lineItem, "jobCostCodes", []).map(
      ({ grossAmount, jobCostCodeId, retainageAmount }) => ({
        grossAmount,
        jobCostCodeId,
        retainageAmount,
      })
    ),
    materialsStoredAmount: get(lineItem, "materialsStoredAmount", 0),
    retainageAmount:
      get(lineItem, "retainageAmount") ||
      getRetainageThisDraw(
        document.vendor,
        selectedDraw
      )({ ...lineItem, lineItemObject }),
    retainageToDateAmount: get(lineItem, "retainageToDateAmount", 0),
    lineItemObject,
  };
};

const getEmptyLineItem = (lineItemIndex) => ({
  applicationAmount: 0,
  jobCostCodes: [],
  lineItemIndex,
  lineItemObject: { id: null, descriptionOfWork: null },
  materialsStoredAmount: 0,
  retainageAmount: 0,
  retainageToDateAmount: 0,
});

const formatLineItem = (
  lineItem,
  index,
  autoCalculateRetainage,
  selectedDraw,
  agreementVendorLineItems,
  vendor
) => {
  let retainageAmount = get(lineItem, "retainageAmount") || 0;
  let retainageToDateAmount;

  if (autoCalculateRetainage) {
    const {
      autoCalculatedRetainageAmount,
      autoCalculatedRetainageToDateAmount,
    } = autoCalculateLineItemRetainage(
      agreementVendorLineItems,
      get(lineItem, "applicationAmount", 0),
      lineItem.lineItemObject,
      selectedDraw,
      vendor
    );
    retainageAmount = autoCalculatedRetainageAmount;
    retainageToDateAmount = autoCalculatedRetainageToDateAmount;
  } else {
    retainageToDateAmount = calculateLineItemRetainageToDate(
      retainageAmount,
      lineItem.lineItemObject,
      selectedDraw,
      vendor
    );
  }

  const jobCostCodes = lineItem.jobCostCodes.map(
    ({ grossAmount, jobCostCodeId, retainageAmount }) => ({
      grossAmount: formatCurrency(grossAmount),
      jobCostCodeId,
      retainageAmount: formatCurrency(retainageAmount),
    })
  );

  return {
    ...lineItem,
    lineItemIndex: index,
    applicationAmount: formatCurrency(get(lineItem, "applicationAmount", 0)),
    jobCostCodes,
    materialsStoredAmount: formatCurrency(
      get(lineItem, "materialsStoredAmount", 0)
    ),
    retainageAmount: formatCurrency(retainageAmount),
    retainageToDateAmount: formatCurrency(retainageToDateAmount),
  };
};

function validate(values, hasPermission) {
  const errors = {};

  if (hasPermission(PERMISSION_ACTION.ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES)) {
    values.lineItems.forEach((lineItem, index) => {
      const grossAmount = unformatNumber(lineItem.applicationAmount);
      const retainageAmount = unformatNumber(lineItem.retainageAmount);

      const costCodeGrossAmount = sumBy(
        values.lineItems[index].jobCostCodes,
        "grossAmount"
      );
      const costCodeRetainageAmount = sumBy(
        values.lineItems[index].jobCostCodes,
        "retainageAmount"
      );

      const shouldValidateLineItemCostCodeAmounts =
        grossAmount >= 0 && retainageAmount >= 0;

      if (
        shouldValidateLineItemCostCodeAmounts &&
        (costCodeGrossAmount > grossAmount ||
          costCodeRetainageAmount > retainageAmount)
      ) {
        set(errors, `lineItems.`);
      }
    });
  }

  return errors;
}

const getInitialValues = ({
  agreementVendorLineItems,
  document,
  hasPermission,
  selectedDraw,
  selectedType,
}) => {
  const {
    applicationNumber,
    autoCalculateRetainage,
    comments,
    dismissedWarnings,
    documentReviewActivity,
    reviews,
    showAllHardCostLineItems,
    showFullFields,
    state,
    ...restOfDocument
  } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);
  const isTrackingCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );
  const trackableAgreements = get(
    restOfDocument,
    "project.trackableAgreements",
    []
  );

  const documentIsInvoiceOrPayApp =
    document.type === DOCUMENT_TYPE_NAME.INVOICE ||
    document.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION;

  const clearAmounts =
    get(selectedDraw, "isLockedDown") &&
    get(document, "draw.id") !== get(selectedDraw, "id");

  return {
    ...documentProperties,
    agreementAmounts: isTrackingCostToAgreements
      ? mapAgreementAmounts(trackableAgreements)
      : {},
    hasTrackedAgreements: trackableAgreements.some(
      ({ spentForDocument }) => unformatNumber(spentForDocument) !== 0
    ),
    draw: selectedDraw
      ? {
          id: selectedDraw.id,
          name: selectedDraw.name,
          isLockedDown: selectedDraw.isLockedDown,
          lineItems: selectedDraw.lineItems,
          issues: selectedDraw.issues,
        }
      : null,
    type: selectedType,
    showAllHardCostLineItems,
    showFullFields,
    isBackup: documentIsInvoiceOrPayApp ? document.isBackup : false,
    applicationNumber: applicationNumber || "",
    totalCompletedAndStoredToDate: clearAmounts
      ? formatCurrency(0)
      : formatCurrency(get(document, "totalCompletedAndStoredToDate", 0)),
    totalRetainage: clearAmounts
      ? formatCurrency(0)
      : formatCurrency(get(document, "totalRetainage", 0)),
    currentPaymentDue: clearAmounts
      ? formatCurrency(0)
      : formatCurrency(get(document, "currentPaymentDue", 0)),
    lineItems:
      document.lineItems && document.lineItems.length > 0 && !clearAmounts
        ? document.lineItems
            .map(
              matchLineItem(
                selectedDraw ? selectedDraw.lineItems : [],
                selectedDraw,
                restOfDocument
              )
            )
            .map((lineItem, index) =>
              formatLineItem(
                lineItem,
                index,
                autoCalculateRetainage,
                selectedDraw,
                agreementVendorLineItems,
                document.vendor
              )
            )
        : [getEmptyLineItem(0)],
    ...(hasPermission(PERMISSION_ACTION.AUTO_CALCULATE_RETAINAGE) && {
      autoCalculateRetainage,
    }),
  };
};

const getDocumentDataFromForm = (values, hasPermission) => {
  const isGeneralContractor = getIsGeneralContractor({
    project: values.project,
    vendor: values.vendor,
  });
  const showLineItemsTable =
    isGeneralContractor ||
    !hasPermission(PERMISSION_ACTION.SUBCONTRACTOR_FORM) ||
    values.showFullFields ||
    // Do not show the simplified form if we've parsed out multiple line items
    values.lineItems.length > 1;

  return {
    applicationNumber: values.applicationNumber || null,
    currentPaymentDue: values.currentPaymentDue,
    isBackup: values.isBackup,
    showAllHardCostLineItems: values.showAllHardCostLineItems,
    lineItems: showLineItemsTable
      ? formatLineItems(values.lineItems)
      : formatLineItems(values.lineItems).map((lineItem) => {
          // if the form is a simple subcontractor form
          // we still want to store the amounts associated with gc line items (application amount, stored materials, rtd)
          // then the application amount is calculated from the "current payment due" amount
          // usually we calculate current payment due = application amount + stored materials - rtd
          // instead, when going from simplified pay app to the full line items table,
          // the calculation for This Period is:
          // this period = current payment due + retainage this draw
          const match = getMatch(
            get(values, "draw.lineItems", []),
            lineItem,
            true
          );

          const previousRetainage = getPreviousRetainage(
            values.vendor,
            values.draw
          )(match);

          const retainageAmount = formatCurrency(
            subtract(values.totalRetainage, previousRetainage)
          );

          return {
            ...lineItem,
            descriptionOfWork: get(match, "fullName"),
            applicationAmount: formatCurrency(
              add(retainageAmount, values.currentPaymentDue)
            ).toString(),
            materialsStoredAmount: 0,
            retainageAmount,
            retainageToDateAmount: values.totalRetainage,
          };
        }),
    periodToDate: dateFormToServer(values.periodToDate),
    showFullFields: values.showFullFields,
    totalCompletedAndStoredToDate: values.totalCompletedAndStoredToDate,
    totalRetainage: values.totalRetainage,
  };
};

function getWarnings({
  agreements,
  dismissedWarnings,
  form,
  hasRulesRedesign,
  selectedDraw,
  showLineItemsTable,
  isTrackingCostToAgreements,
  limitTrackedAgreementWarnings,
  vendors,
  projectId,
}) {
  const { values } = form;
  const warnings = {};

  const assignedToDraw = !!values.draw;

  if (assignedToDraw) {
    if (
      showLineItemsTable &&
      unformatNumber(get(values, "totalRetainage")) !==
        getTotalRetainage(values.lineItems)
    )
      warnings.totalRetainage = {
        type: "totalRetainage",
        name: "",
      };

    if (
      showLineItemsTable &&
      unformatNumber(get(values, "currentPaymentDue")) !==
        getTotalPaymentDue(form, selectedDraw)
    )
      warnings.currentPaymentDue = {
        type: "currentPaymentDue",
        name: "",
      };
  }

  const lineItemsWithInsufficientFunds = hasRulesRedesign
    ? getLineItemsWithInsufficientFunds(
        get(values, "draw.issues", []),
        values.lineItems
      )
    : [];

  if (lineItemsWithInsufficientFunds.length > 0) {
    warnings.insufficientFunds = {
      type: "insufficientFundsPayApplication",
      name: humanReadableList(
        lineItemsWithInsufficientFunds.map(
          ({ lineItemObject }) => lineItemObject.descriptionOfWork
        )
      ),
    };
  }

  const showTrackCostToAgreementsWarnings = shouldShowTrackCostToAgreementsWarnings(
    {
      isTrackingCostToAgreements,
      limitTrackedAgreementWarnings,
      projectId,
      vendors,
      values,
    }
  );

  if (showTrackCostToAgreementsWarnings) {
    if (
      invoiceAmountFullyNotAllocatedToAgreements(
        values.lineItems,
        Object.values(values.agreementAmounts),
        values.currentPaymentDue
      )
    ) {
      warnings.agreementAmount = {
        type: "payApplicationAmountFullyNotAllocatedToAgreements",
        name: "Agreement Amount",
      };
    }

    if (lineItemsNotCoveredByAgreements(values.lineItems, agreements)) {
      warnings.lineItemsCovered = {
        type: "lineItemsNotCoveredByAgreements",
        name: "Line Items Missing From Agreements",
      };
    }
  }

  return removeDismissedWarnings(warnings, dismissedWarnings);
}

function getInformationSummary(form) {
  const summary = [
    get(form.values.vendor, "name"),
    get(form.values, "applicationNumber")
      ? `Application No. ${form.values.applicationNumber}`
      : null,
    formatDate(form.values.periodToDate),
  ];
  return summary.filter((value) => !!value).join(", ");
}

function isMissingInformation(values) {
  const lineItemsMissingInformation =
    get(values, "lineItems.length") === 0 ||
    every(
      values.lineItems,
      (lineItem) =>
        (values.showFullFields && isBlank(lineItem.applicationAmount)) ||
        isBlank(get(lineItem, "lineItemObject.id"))
    );
  const missingSummaryAmount =
    !values.showFullFields && isBlank(values.currentPaymentDue);
  return (
    !get(values, "vendor.id") ||
    lineItemsMissingInformation ||
    missingSummaryAmount
  );
}

function PayApplicationReview(props) {
  const {
    addGeneralContractor,
    agreementVendorLineItems,
    disableWarnings,
    document,
    form,
    getAutocalculatedLineItemRetainage,
    selectedDraw,
    projectId,
  } = props;

  const { hasPermission, isDeveloper, organizationId } = useContext(
    UserContext
  );
  const { agreementsQuery } = useContext(DocumentContext);
  const [removeLineItemsProps, setRemoveLineItemsProps] = useState(null);

  const lockdownDraw = get(selectedDraw, "isLockedDown");
  const documentMarkedPaid = get(document, "isPaid", false);
  const disableForm = lockdownDraw || documentMarkedPaid;

  const isGeneralContractor = getIsGeneralContractor({
    project: form.values.project,
    vendor: form.values.vendor,
  });

  const showJobCostCodes = hasPermission(PERMISSION_ACTION.JOB_COST_CODES);
  const canAssignMultipleCostCodes =
    showJobCostCodes &&
    hasPermission(PERMISSION_ACTION.ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES);

  const simplifiedFormEnabled =
    hasPermission(PERMISSION_ACTION.SUBCONTRACTOR_FORM) &&
    !isGeneralContractor &&
    !canAssignMultipleCostCodes;

  const showLineItemsTable =
    selectedDraw &&
    (!simplifiedFormEnabled ||
      form.values.showFullFields ||
      // Do not show the simplified form if we've parsed out multiple line items
      form.values.lineItems.length > 1 ||
      isGeneralContractor);

  const showSimplifiedLineItemFormButton =
    simplifiedFormEnabled && showLineItemsTable;

  const showSimplifiedLineItemForm =
    selectedDraw && simplifiedFormEnabled && !showLineItemsTable;

  const showParsedFields = hasPermission(PERMISSION_ACTION.SHOW_PARSED_FIELDS);
  const isTrackingCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );
  const limitTrackedAgreementWarnings = hasPermission(
    PERMISSION_ACTION.LIMIT_TRACKED_AGREEMENT_WARNINGS
  );
  const hasYardiJobCostModule = hasPermission(
    PERMISSION_ACTION.YARDI_JOB_COST_MODULE
  );

  const [newCostCode, setNewCostCode] = useState({
    input: null,
    index: null,
  });

  const closeJobCostCodeModal = () => {
    /* invoiceLineItem <-> jobCostCodes are one-to-many, but in this flow, there can only
       be one.  Assign it as the first jobCostCode in the jobCostCodes array  */
    form.setFieldValue(
      `lineItems.${newCostCode.index}.jobCostCodes.0.jobCostCodeId`,
      null
    );
    setNewCostCode({ input: null, index: null });
  };

  const setCreatedJobCostCode = (data) => {
    const newJobCostCode = data.addJobCostCode;
    /* invoiceLineItem <-> jobCostCodes are one-to-many, but in this flow, there can only
       be one.  Assign it as the first jobCostCode in the jobCostCodes array  */
    form.setFieldValue(
      `lineItems.${newCostCode.index}.jobCostCodes.0.jobCostCodeId`,
      newJobCostCode.id
    );
    setNewCostCode({ input: null, index: null });
  };

  const updateLineItemIndices = (lineItems) => {
    form.setFieldValue(
      "lineItems",
      lineItems.map((lineItem, index) => ({
        ...lineItem,
        lineItemIndex: index,
      }))
    );
  };

  if (!form.values.lineItems) return null;

  const assignedLineItems = form.values.lineItems.filter(
    ({ lineItemObject }) => !!lineItemObject.id
  );

  const showJobCostCodePanel =
    showJobCostCodes &&
    !canAssignMultipleCostCodes &&
    assignedLineItems.length > 0;

  const showTrackCostToAgreements = shouldShowTrackCostToAgreementsSection({
    isTrackingCostToAgreements,
    form,
  });

  const warnings =
    disableWarnings || lockdownDraw
      ? {}
      : getWarnings({
          agreements: agreementsQuery?.data?.project?.trackableAgreements || [],
          dismissedWarnings: get(document, "dismissedWarnings", []),
          form,
          hasRulesRedesign: hasPermission(
            PERMISSION_ACTION.RULES_REDESIGN_CLERICAL
          ),
          selectedDraw,
          showLineItemsTable,
          isTrackingCostToAgreements,
          limitTrackedAgreementWarnings,
          vendors: props.allAvailableVendors,
          projectId,
        });

  const autofilledFields = getAutofilledFields(
    get(document, "autofilledFields", []),
    form
  );

  const shouldShowAutofilledField = (fieldName) => {
    return showParsedFields && autofilledFields.indexOf(fieldName) !== -1;
  };

  const hardCostLineItems = selectedDraw
    ? selectedDraw.lineItems
        .filter((lineItem) => lineItem.hardCosts)
        .map((lineItem) => {
          return {
            ...lineItem,
            description: getDescription(lineItem),
          };
        })
    : [];

  return (
    <Fragment>
      <DocumentReviewLayout
        lockdownDraw={lockdownDraw}
        {...props}
        autofilledFieldCount={autofilledFields.length}
        shouldShowAutofilledField={shouldShowAutofilledField}
        warnings={warnings}
        meta={(content) => (
          <Pane display="flex" width="100%" alignItems="center">
            {content}
            {selectedDraw && <IsBackupCheckbox isDeveloper={isDeveloper} />}
          </Pane>
        )}
        information={(content) => (
          <Pane display="flex" flexDirection="column" width="100%">
            <Pane display="flex" flexDirection="row" flexWrap="wrap">
              {content}

              <Pane flexGrow={1} marginRight={majorScale(2)}>
                <Form.Input
                  disabled={disableForm}
                  isHighlighted={shouldShowAutofilledField("applicationNumber")}
                  name="applicationNumber"
                  label="Application Number"
                />
              </Pane>

              <Pane flexGrow={1}>
                <Form.DateInput
                  disabled={disableForm}
                  isHighlighted={shouldShowAutofilledField("periodToDate")}
                  name="periodToDate"
                  label="Period To"
                  popperPlacement="bottom-end"
                />
              </Pane>
            </Pane>

            <Pane
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <VendorLink
                vendorId={form.values.vendor.id}
                vendors={props.allAvailableVendors}
              />
              {hasPermission(PERMISSION_ACTION.AGREEMENT_MANAGEMENT) && (
                <Pane marginLeft={majorScale(2)}>
                  <AgreementDetailsForVendor
                    agreementVendorLineItems={agreementVendorLineItems}
                    vendorId={form.values.vendor.id}
                  />
                </Pane>
              )}
            </Pane>
            {!get(form, "values.project.hasGeneralContractor") && (
              <GeneralContractorStakeholderPrompt
                addGeneralContractor={addGeneralContractor}
                form={form}
                projectId={props.projectId}
                projectName={props.projectName}
              />
            )}
          </Pane>
        )}
        informationSummary={getInformationSummary(form)}
        selectedDraw={selectedDraw}
      >
        <Form.Section
          heading="Pay Application"
          display="flex"
          justifyContent="space-between"
          isCollapsible
          isCollapsed={props.collapsedPanels.includes("payApplication")}
          onCollapse={() => props.togglePanel("payApplication")}
          summary={`Current Payment Due: ${form.values.currentPaymentDue}`}
        >
          <Pane flexGrow={1} marginRight={majorScale(2)}>
            <Form.Input
              disabled={disableForm}
              name="totalCompletedAndStoredToDate"
              label="Total Completed and Stored to Date"
              type="currency"
              isHighlighted={shouldShowAutofilledField(
                "totalCompletedAndStoredToDate"
              )}
            />
          </Pane>

          <Pane flexGrow={1} marginRight={majorScale(2)}>
            <Form.Input
              disabled={disableForm}
              isHighlighted={shouldShowAutofilledField("totalRetainage")}
              label="Total Retainage"
              name="totalRetainage"
              type="currency"
            />
          </Pane>

          <Pane flexGrow={1}>
            <Form.Input
              disabled={disableForm}
              isWarned={
                showSimplifiedLineItemForm &&
                isBlank(get(form, "values.currentPaymentDue"))
              }
              isHighlighted={shouldShowAutofilledField("currentPaymentDue")}
              label="Current Payment Due"
              name="currentPaymentDue"
              type="currency"
            />
          </Pane>
        </Form.Section>
        {showSimplifiedLineItemFormButton && (
          <Pane width="100%" display="flex" justifyContent="flex-end">
            <Button
              appearance="minimal"
              iconBefore={DeleteIcon}
              purpose="document-review pay-app show-subcontractor-form"
              onClick={() => {
                const currentLineItems = get(form.values, "lineItems", []);

                const hcLineItemFound = find(
                  currentLineItems,
                  (lineItem) => !!lineItem?.lineItemObject?.hardCosts
                );
                const newLineItem = hcLineItemFound ? [hcLineItemFound] : [];
                const lineItemsWillBeRemoved =
                  currentLineItems.length > (hcLineItemFound ? 1 : 0);

                const removeOtherLineItems = () => {
                  updateLineItemIndices(newLineItem);
                  form.setFieldValue("showFullFields", false);
                };

                if (lineItemsWillBeRemoved) {
                  const lineItemName = get(
                    hcLineItemFound,
                    "lineItemObject.descriptionOfWork",
                    ""
                  );
                  setRemoveLineItemsProps({
                    removeOtherLineItems,
                    lineItemName,
                  });
                } else {
                  removeOtherLineItems();
                }
              }}
            >
              Show Subcontractor Default Form
            </Button>
          </Pane>
        )}
        {showLineItemsTable && (
          <DocumentLineItems
            disabled={disableForm}
            lockdownDraw={lockdownDraw}
            sectionHeading="Continuation Sheet"
            getEmptyLineItem={getEmptyLineItem}
            onAddSplit={updateLineItemIndices}
            {...props}
          >
            {({ remove }) => (
              <PayApplicationSplitTable
                agreementVendorLineItems={agreementVendorLineItems}
                form={form}
                getAutocalculatedLineItemRetainage={
                  getAutocalculatedLineItemRetainage
                }
                getCurrentPaymentDue={getCurrentPaymentDue}
                getEmptyLineItem={getEmptyLineItem}
                getPreviousRetainage={getPreviousRetainage}
                getRetainageThisDraw={getRetainageThisDraw}
                hasAgreementPermission={hasPermission(
                  PERMISSION_ACTION.AGREEMENT_MANAGEMENT
                )}
                hasAutoCalculateRetainage={hasPermission(
                  PERMISSION_ACTION.AUTO_CALCULATE_RETAINAGE
                )}
                disableForm={disableForm}
                remove={remove}
                selectedDraw={selectedDraw}
                shouldShowAutofilledField={shouldShowAutofilledField}
                updateLineItemIndices={updateLineItemIndices}
                warnings={warnings}
              />
            )}
          </DocumentLineItems>
        )}
        {showSimplifiedLineItemForm && (
          <Pane paddingBottom={majorScale(2)} paddingX={majorScale(2)}>
            <Button
              appearance="minimal"
              iconBefore={AddIcon}
              position="absolute"
              right={0}
              purpose="document-review pay-app show-full-form"
              onClick={() => {
                form.setFieldValue("showFullFields", true);
              }}
            >
              Show Multiple Line Items
            </Button>
            <Form.Select
              label="Hard Cost Line Item"
              name="lineItems.0.lineItemObject"
              noNull
              options={getLineItemOptions(
                hardCostLineItems.length > 0
                  ? hardCostLineItems
                  : selectedDraw.lineItems,
                true
              )}
              paddingTop={majorScale(1)}
              isWarned={isBlank(
                get(form, `values.lineItems.0.lineItemObject.id`)
              )}
            />
          </Pane>
        )}
        {showJobCostCodePanel && (
          <Form.Section
            heading="Job Cost Codes"
            isCollapsed={props.collapsedPanels.includes("jobCostCodes")}
            isCollapsible
            onCollapse={() => props.togglePanel("jobCostCodes")}
            padding={0}
          >
            <Table paddingBottom={0}>
              <Table.Head>
                <Table.Row>
                  <Table.TextHeaderCell width="50%">
                    Line Item
                  </Table.TextHeaderCell>
                  {hasYardiJobCostModule ? (
                    <Table.TextHeaderCell textAlign="right">
                      Job Cost Code (Yardi Cost Category)
                    </Table.TextHeaderCell>
                  ) : (
                    <Table.TextHeaderCell textAlign="right">
                      Job Cost Code
                    </Table.TextHeaderCell>
                  )}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {assignedLineItems.map((lineItem, index) => (
                  <Table.Row key={lineItem.lineItemObject.id}>
                    <Table.TextCell id="lineItem">
                      {lineItem.lineItemObject.descriptionOfWork}
                    </Table.TextCell>
                    <Table.TextCell id="jobCostCode">
                      <Form.Select
                        addName="New Job Cost Code"
                        disabled={disableForm}
                        /* if this section is enabled, the FF for multiple JCC per ILI is OFF.
                        // the ILI will only have one JCC, but as they have a one-to-many
                          relationship in the data model, maintain its value in an array */
                        name={`lineItems.${index}.jobCostCodes.0.jobCostCodeId`}
                        onAddItem={(input) => setNewCostCode({ input, index })}
                        options={getCostCodeOptions(props.jobCostCodes)}
                        placeholder="Select Job Cost Code..."
                      />
                      <IconButton
                        appearance="minimal"
                        disabled={disableForm}
                        icon={AddIcon}
                        intent="info"
                        marginLeft={minorScale(1)}
                        onClick={() => setNewCostCode({ index })}
                        type="button"
                      />
                    </Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Form.Section>
        )}
        {showTrackCostToAgreements && <TrackCostToAgreements {...props} />}
      </DocumentReviewLayout>
      {newCostCode.index !== null && (
        <JobCostCodeModal
          costCode={{ code: newCostCode.input }}
          containerProps={{ marginRight: "30%" }}
          onClose={closeJobCostCodeModal}
          onMutationCompleted={setCreatedJobCostCode}
          organizationCostCodes={props.jobCostCodes}
          organizationId={organizationId}
        />
      )}
      {removeLineItemsProps !== null && (
        <Confirm
          open
          header="Remove Line Items"
          content={
            removeLineItemsProps.lineItemName
              ? t(
                  "documentReview.switchingToSubcontractorFormRemovesLineItemsExcept",
                  { lineItemName: removeLineItemsProps.lineItemName }
                )
              : t("documentReview.switchingToSubcontractorFormRemovesLineItems")
          }
          onCloseComplete={() => setRemoveLineItemsProps(null)}
          onConfirm={() => {
            removeLineItemsProps.removeOtherLineItems();
            setRemoveLineItemsProps(null);
          }}
          cancelLabel="Cancel"
          confirmLabel="Remove"
        />
      )}
    </Fragment>
  );
}

PayApplicationReview.propTypes = {
  children: PropTypes.node,
  selectedDraw: PropTypes.object,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          previousLineItem: PropTypes.shape({
            vendorLineItems: PropTypes.arrayOf(
              PropTypes.shape({
                vendorId: PropTypes.string,
                retainageToDateAmount: PropTypes.number,
              })
            ),
          }),
        })
      ),
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      isBackup: PropTypes.bool,
      applicationNumber: PropTypes.string,
      periodToDate: PropTypes.string,
      totalCompletedAndStoredToDate: PropTypes.string,
      totalRetainage: PropTypes.string,
      currentPaymentDue: PropTypes.string,
      lineItems: PropTypes.arrayOf({
        applicationAmount: PropTypes.string,
        materialsStoredAmount: PropTypes.string,
        retainageAmount: PropTypes.string,
        lineItemObject: PropTypes.shape({
          id: PropTypes.string,
          descriptionOfWork: PropTypes.string,
        }),
      }),
    }),
  }),
  jobCostCodes: PropTypes.arrayOf([PropTypes.shape({ code: PropTypes.string })])
    .isRequired,
  onApprove: PropTypes.func,
  onComment: PropTypes.func,
  onChangeDraw: PropTypes.func,
  onChangeType: PropTypes.func,
};

// TODO: remove dot notation
PayApplicationReview.getEmptyLineItem = getEmptyLineItem;
PayApplicationReview.getInitialValues = getInitialValues;
PayApplicationReview.getDocumentDataFromForm = getDocumentDataFromForm;
PayApplicationReview.isMissingInformation = isMissingInformation;
PayApplicationReview.validate = validate;

export default PayApplicationReview;
