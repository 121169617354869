import PropTypes from "prop-types";
import TableCell, { propTypes as cellPropTypes } from "./Cell";
import { getProps, compose, withMemo } from "./utils";

const TableFooterCell = compose(
  ({ children, content, ...props }) => {
    return <TableCell {...props}>{content || children}</TableCell>;
  },
  [withMemo]
);

export const propTypes = {
  ...cellPropTypes,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TableFooterCell.propTypes = propTypes;

// TODO: remove dot notation
TableFooterCell.getProps = (props) => getProps(propTypes, props);

export default TableFooterCell;
