import { Fragment } from "react";
import { BlankSlate } from "components/templates";
import { Paragraph } from "components/materials";
import t from "helpers/translate";
import { lineItemsTablePlaceholder } from "images";

export function MissingBudget({ projectId }) {
  return (
    <Fragment>
      <Paragraph size={500}>{t("fundingSources.noBudgetLine1")}</Paragraph>
      <Paragraph size={500}>{t("fundingSources.noBudgetLine2")}</Paragraph>
      <BlankSlate
        prompt="Add budget"
        src={lineItemsTablePlaceholder}
        to={`/projects/${projectId}/budget`}
      />
    </Fragment>
  );
}
