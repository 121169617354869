import { InfoSignIcon } from "evergreen-ui";
import { Tooltip } from "components/materials";
import formatPercent from "helpers/formatPercent";
import t from "helpers/translate";
import { minorScale } from "helpers/utilities";

export function ExpectedRetainageTooltip({
  expectedRetainage,
  lineItemName,
  source,
  vendorName,
}) {
  return (
    <Tooltip
      content={
        source === "agreement"
          ? t("documentReview.expectedRetainage.agreement", {
              vendorName,
              retainagePercentage: formatPercent(expectedRetainage, "-", 2),
            })
          : t("documentReview.expectedRetainage.draw", {
              lineItemName,
              retainagePercentage: formatPercent(expectedRetainage, "-", 2),
            })
      }
    >
      <InfoSignIcon marginLeft={minorScale(1)} size={12} />
    </Tooltip>
  );
}
