import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const MUTATION = gql`
  mutation Create92403Page2($drawId: String) {
    create92403Page2(drawId: $drawId) {
      id
      documents {
        id
        state
      }
      draw {
        id
      }
      file {
        url
        name
        type
      }
      isHidden
      parsedDatapointCount
      processingFinishedAt
      projectId
      state
      toBeSplit
    }
  }
`;

export function useCreate92403Page2Mutation(options) {
  return useMutation(MUTATION, options);
}

export default useCreate92403Page2Mutation;
