import { useEffect, Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import { reject, partition, get } from "lodash";
import { CrossIcon, InfoSignIcon } from "evergreen-ui";
import {
  Confirm,
  Form,
  Pane,
  Paragraph,
  Table,
  Tooltip,
} from "components/materials";
import { UserContext } from "helpers/behaviors";
import { isInternetExplorer } from "helpers/browserHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import { add } from "helpers/math";
import {
  getLineItemOptions,
  getFullLineItemName,
} from "helpers/payApplicationLineItemHelpers";
import {
  getExpectedRetainage,
  setLineItemRetainage,
  updateLineItemRetainageOnVendorChange,
  updateLineItemRetainage,
} from "helpers/retainageLineItemHelpers";
import unformatNumber from "helpers/unformatNumber";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { majorScale, minorScale } from "helpers/utilities";
import { ExpectedRetainageTooltip } from "./ExpectedRetainageTooltip";
import { CalculationTooltip } from "./CalculationTooltip";
import { AssignMultipleCostCodes } from "./AssignMultipleCostCodes";
import { AssignCostCodesModal } from "./AssignCostCodesModal";

function blankLineItem(lineItem) {
  return (
    unformatNumber(get(lineItem, "amount", 0)) === 0 &&
    unformatNumber(get(lineItem, "applicationAmount", 0)) === 0 &&
    unformatNumber(get(lineItem, "materialsStoredAmount", 0)) === 0 &&
    unformatNumber(get(lineItem, "retainageAmount", 0)) === 0
  );
}

function blankHardCost(lineItem) {
  return lineItem.lineItemObject.hardCosts && blankLineItem(lineItem);
}

function HardCostsSwitch({ form, selectedDraw, getEmptyLineItem }) {
  return (
    <Form.Switch
      label="Show All Hard Cost Line Items"
      name="showAllHardCostLineItems"
      onChange={(switched) => {
        if (switched) {
          const [existingHardCostLineItems, remainingLineItems] = partition(
            form.values.lineItems,
            ["lineItemObject.hardCosts", true]
          );
          const lineItems = selectedDraw.lineItems
            .filter((li) => li.hardCosts)
            .map((lineItem) => ({
              ...(existingHardCostLineItems.find(
                (formValue) => formValue.lineItemObject.id === lineItem.id
              ) || getEmptyLineItem()),
              hardCosts: true,
              lineItemObject: {
                id: lineItem.id,
                descriptionOfWork: getFullLineItemName(lineItem),
                hardCosts: true,
              },
            }));

          form.setFieldValue(
            "lineItems",
            [...lineItems, ...remainingLineItems].map((lineItem, index) => ({
              ...lineItem,
              index,
            }))
          );
        } else {
          form.setFieldValue(
            "lineItems",
            reject(form.values.lineItems, blankHardCost)
          );
        }
      }}
      textProps={{ size: 300 }}
    />
  );
}

export function PayApplicationSplitTable({
  agreementVendorLineItems,
  form,
  getAutocalculatedLineItemRetainage,
  getCurrentPaymentDue,
  getEmptyLineItem,
  getPreviousRetainage,
  getRetainageThisDraw,
  hasAgreementPermission,
  hasAutoCalculateRetainage,
  disableForm,
  remove,
  selectedDraw,
  updateLineItemIndices,
  warnings,
}) {
  const { hasPermission } = useContext(UserContext);
  const [
    assignMultipleCostCodesModalProps,
    setAssignMultipleCostCodesModalProps,
  ] = useState(null);

  const canAssignMultipleCostCodes =
    hasPermission(PERMISSION_ACTION.JOB_COST_CODES) &&
    hasPermission(PERMISSION_ACTION.ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES);

  const { autoCalculateRetainage, lineItems } = form.values;

  /* if there are more than 5 line items, and some are not blank,
  show a warning before letting a user turn on "Auto Calculate Retainage" */
  const eligibleForAutoCalculateWarning =
    form.initialValues.autoCalculateRetainage === false &&
    lineItems.length > 5 &&
    lineItems.some((lineItem) => !blankLineItem(lineItem));

  /* if the pay app doesn't meet the criteria, the user doesn't need to see a warning;
   If the app already has auto-calculate on, this is also not needed  */
  const [
    userDismissedAutoCalculateWarning,
    setUserDismissedAutoCalculateWarning,
  ] = useState(!eligibleForAutoCalculateWarning);
  const [showAutoCalculateWarning, setShowAutoCalculateWarning] = useState(
    false
  );

  useEffect(() => {
    if (!userDismissedAutoCalculateWarning && autoCalculateRetainage) {
      setShowAutoCalculateWarning(true);
    } else {
      form.values.lineItems.forEach((lineItem, lineItemIndex) => {
        const { lineItemObject } = lineItem;
        updateLineItemRetainage(
          form,
          lineItemIndex,
          autoCalculateRetainage,
          lineItem,
          lineItemObject,
          selectedDraw,
          getAutocalculatedLineItemRetainage
        );
      });
    }
    // eslint-disable-next-line
  }, [
    agreementVendorLineItems,
    autoCalculateRetainage,
    form.values.showAllHardCostLineItems,
    userDismissedAutoCalculateWarning,
  ]);

  useEffect(() => {
    form.values.lineItems.forEach((lineItem, lineItemIndex) => {
      const { lineItemObject } = lineItem;
      updateLineItemRetainageOnVendorChange(
        form,
        lineItemIndex,
        autoCalculateRetainage,
        lineItem,
        lineItemObject,
        selectedDraw,
        getAutocalculatedLineItemRetainage
      );
    });

    // eslint-disable-next-line
  }, [form.values.vendor.id]);

  return (
    <Fragment>
      <Pane
        display="flex"
        justifyContent="flex-end"
        marginRight={majorScale(2)}
        marginY={majorScale(2)}
      >
        {selectedDraw.lineItems.find((lineItem) => lineItem.hardCosts) && (
          <Pane>
            <HardCostsSwitch
              form={form}
              getEmptyLineItem={getEmptyLineItem}
              selectedDraw={selectedDraw}
            />
          </Pane>
        )}
        {hasAutoCalculateRetainage && (
          <Pane marginLeft={majorScale(2)}>
            <Form.Switch
              label="Auto Calculate Retainage"
              name="autoCalculateRetainage"
              textProps={{ size: 300 }}
            />
          </Pane>
        )}
      </Pane>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.TextHeaderCell>Line Item</Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="right" tooltip="Gross Amount">
              This Period
            </Table.TextHeaderCell>
            <Table.TextHeaderCell
              textAlign="right"
              tooltip="Materials Presently Stored"
            >
              Stored Materials
            </Table.TextHeaderCell>
            {form.values.autoCalculateRetainage ? (
              <Table.TextHeaderCell
                textAlign="right"
                tooltip={
                  hasAgreementPermission &&
                  "This retainage percentage was set either on an agreement or on a line item"
                }
              >
                Expected Retainage
              </Table.TextHeaderCell>
            ) : (
              <Table.TextHeaderCell
                textAlign="right"
                tooltip="Referencing Previous Pay Apps - Tracked by Line Item and Vendor"
              >
                Retainage To Date
              </Table.TextHeaderCell>
            )}
            <Table.TextHeaderCell
              fontStyle="italic"
              textAlign="right"
              tooltip="Referencing Previous Pay Apps - Tracked by Line Item and Vendor"
            >
              Retainage This Draw
            </Table.TextHeaderCell>
            <Table.TextHeaderCell fontStyle="italic" textAlign="right">
              Current Payment Due
            </Table.TextHeaderCell>
            {canAssignMultipleCostCodes && (
              <Table.TextHeaderCell>Cost Codes</Table.TextHeaderCell>
            )}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {form.values.lineItems.map((lineItem, rowIndex) => {
            const { expectedRetainage, source } = getExpectedRetainage(
              lineItem.lineItemObject.id,
              selectedDraw.lineItems,
              agreementVendorLineItems,
              form.values.vendor.id
            );
            const drawLineItem = selectedDraw.lineItems.find(
              ({ id }) => get(lineItem, "lineItemObject.id") === id
            );
            const setManually = get(drawLineItem, "setManually");
            const lineItemIsAssigned =
              get(lineItem, "lineItemObject.id", null) !== null;

            return (
              <Table.Row>
                <Table.TextCell
                  width="25%"
                  minWidth={isInternetExplorer ? "202px" : undefined}
                  // The line item table in IE11 side scrolls by default and cannot use percentage width
                  // 202px is the exact minimum to see the entire placeholder in IE11
                >
                  {!form.values.__disabled &&
                    !(
                      form.values.showAllHardCostLineItems && lineItem.hardCosts
                    ) && (
                      <CrossIcon
                        cursor={disableForm ? "not-allowed" : "pointer"}
                        size={14}
                        marginRight={minorScale(3)}
                        onClick={() => {
                          if (!disableForm) {
                            const currentLineItems = form.values.lineItems;
                            remove(rowIndex);
                            updateLineItemIndices(
                              currentLineItems.filter(
                                (_, index) => index !== rowIndex
                              )
                            );
                          }
                        }}
                      />
                    )}
                  <Form.Select
                    width="100%"
                    disabled={
                      disableForm ||
                      (form.values.showAllHardCostLineItems &&
                        lineItem.hardCosts)
                    }
                    isWarned={isBlank(
                      get(
                        form,
                        `values.lineItems.${rowIndex}.lineItemObject.id`
                      )
                    )}
                    name={`lineItems.${rowIndex}.lineItemObject`}
                    noNull
                    options={
                      selectedDraw
                        ? getLineItemOptions(selectedDraw.lineItems)
                        : []
                    }
                    onChange={(lineItemObject) => {
                      updateLineItemRetainage(
                        form,
                        rowIndex,
                        autoCalculateRetainage,
                        lineItem,
                        lineItemObject,
                        selectedDraw,
                        getAutocalculatedLineItemRetainage
                      );
                    }}
                    placeholder="Select Line Item..."
                    popoverMinWidth={700}
                  />
                  {setManually && (
                    <Tooltip
                      content={t("documentReview.lineItemSetManually", {
                        lineItems: drawLineItem.name,
                      })}
                    >
                      <InfoSignIcon marginLeft={minorScale(1)} />
                    </Tooltip>
                  )}
                </Table.TextCell>
                <Table.Cell>
                  <Form.Input
                    disabled={disableForm}
                    isWarned={isBlank(
                      get(
                        form,
                        `values.lineItems.${rowIndex}.applicationAmount`
                      )
                    )}
                    name={`lineItems.${rowIndex}.applicationAmount`}
                    textAlign="right"
                    onChange={(e) => {
                      if (autoCalculateRetainage) {
                        const {
                          autoCalculatedRetainageAmount,
                          autoCalculatedRetainageToDateAmount,
                        } = getAutocalculatedLineItemRetainage(
                          e.target.value,
                          lineItem.lineItemObject
                        );

                        setLineItemRetainage(
                          form.setFieldValue,
                          autoCalculatedRetainageAmount,
                          autoCalculatedRetainageToDateAmount,
                          rowIndex
                        );
                      }
                    }}
                    type="currency"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Input
                    disabled={disableForm}
                    name={`lineItems.${rowIndex}.materialsStoredAmount`}
                    textAlign="right"
                    type="currency"
                  />
                </Table.Cell>
                {autoCalculateRetainage ? (
                  <Table.TextCell textAlign="right">
                    {formatPercent(expectedRetainage, "-", 2)}
                    {source && (
                      <ExpectedRetainageTooltip
                        expectedRetainage={expectedRetainage}
                        lineItemName={lineItem.lineItemObject.descriptionOfWork}
                        source={source}
                        vendorName={form.values.vendor.name}
                      />
                    )}
                  </Table.TextCell>
                ) : (
                  <Table.Cell>
                    <Form.Input
                      disabled={disableForm}
                      onChange={(e) => {
                        form.setFieldValue(
                          `lineItems.${rowIndex}.retainageAmount`,
                          formatCurrency(
                            getRetainageThisDraw(
                              form.values.vendor,
                              selectedDraw
                            )({
                              ...lineItem,
                              retainageToDateAmount: e.target.value,
                            })
                          )
                        );
                      }}
                      name={`lineItems.${rowIndex}.retainageToDateAmount`}
                      textAlign="right"
                      type="currency"
                    />
                  </Table.Cell>
                )}
                {autoCalculateRetainage ? (
                  <Table.TextCell textAlign="right">
                    {formatCurrency(
                      form.values.lineItems[rowIndex].retainageAmount
                    )}
                  </Table.TextCell>
                ) : (
                  <Table.Cell>
                    <Form.Input
                      disabled={disableForm}
                      name={`lineItems.${rowIndex}.retainageAmount`}
                      onChange={(e) => {
                        const previousRetainage = getPreviousRetainage(
                          form.values.vendor,
                          selectedDraw
                        )(lineItem);
                        const newToDateRetainage = formatCurrency(
                          add(unformatNumber(e.target.value), previousRetainage)
                        );

                        form.setFieldValue(
                          `lineItems.${rowIndex}.retainageToDateAmount`,
                          newToDateRetainage
                        );
                      }}
                      textAlign="right"
                      type="currency"
                    />
                  </Table.Cell>
                )}
                <Table.TextCell textAlign="right">
                  {formatCurrency(getCurrentPaymentDue(form.values, lineItem))}
                  <CalculationTooltip
                    form={form}
                    getPreviousRetainage={getPreviousRetainage}
                    isPayApp
                    lineItem={lineItem}
                    selectedDraw={selectedDraw}
                  />
                </Table.TextCell>
                {canAssignMultipleCostCodes && lineItemIsAssigned && (
                  <Table.Cell>
                    <AssignMultipleCostCodes
                      form={form}
                      lineItem={lineItem}
                      lineItemIndex={rowIndex}
                      onClick={() =>
                        setAssignMultipleCostCodesModalProps({
                          lineItem,
                          lineItemIndex: rowIndex,
                        })
                      }
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
          <Table.Row testId="payappTotalRow">
            <Table.TextFooterCell textAlign="right">Total</Table.TextFooterCell>
            <Table.TextFooterCell
              highlighted={!!get(warnings, "totalCompletedAndStoredToDate")}
              textAlign="right"
            >
              {formatCurrency(
                form.values.lineItems.reduce((total, lineItem) => {
                  return add(total, lineItem.applicationAmount);
                }, 0)
              )}
            </Table.TextFooterCell>
            <Table.TextFooterCell
              highlighted={!!get(warnings, "totalCompletedAndStoredToDate")}
              textAlign="right"
            >
              {formatCurrency(
                form.values.lineItems.reduce((total, lineItem) => {
                  return add(total, lineItem.materialsStoredAmount);
                }, 0)
              )}
            </Table.TextFooterCell>
            <Table.TextFooterCell
              highlighted={!!get(warnings, "retainageToDate.type")}
              textAlign="right"
            >
              {formatCurrency(
                form.values.lineItems.reduce((total, lineItem) => {
                  return add(total, lineItem.retainageToDateAmount);
                }, 0)
              )}
            </Table.TextFooterCell>
            <Table.TextFooterCell
              highlighted={!!get(warnings, "retainageThisDraw.type")}
              textAlign="right"
            >
              {formatCurrency(
                form.values.lineItems.reduce((total, lineItem) => {
                  return add(total, lineItem.retainageAmount);
                }, 0)
              )}
            </Table.TextFooterCell>
            <Table.TextFooterCell
              highlighted={!!get(warnings, "currentPaymentDue.type")}
              textAlign="right"
            >
              {formatCurrency(
                form.values.lineItems.reduce((total, lineItem) => {
                  return add(
                    total,
                    getCurrentPaymentDue(form.values, lineItem)
                  );
                }, 0)
              )}
            </Table.TextFooterCell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Confirm
        cancelLabel="Cancel"
        confirmLabel="Continue"
        content={
          <Fragment>
            <Paragraph marginBottom={majorScale(2)}>
              {t("documentReview.autoCalculateRetainageWarning1")}
            </Paragraph>
            <Paragraph>
              {" "}
              {t("documentReview.autoCalculateRetainageWarning2")}
            </Paragraph>
          </Fragment>
        }
        hasClose={false}
        header="Auto Calculate Retainage"
        onCancel={() => {
          setShowAutoCalculateWarning(false);
          form.setFieldValue("autoCalculateRetainage", false);
        }}
        onConfirm={() => {
          setUserDismissedAutoCalculateWarning(true);
          setShowAutoCalculateWarning(false);
        }}
        open={showAutoCalculateWarning}
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      />
      {assignMultipleCostCodesModalProps && (
        <AssignCostCodesModal
          onClose={() => setAssignMultipleCostCodesModalProps(null)}
          onConfirm={(newJobCostCodes) =>
            form.setFieldValue(
              `lineItems.${assignMultipleCostCodesModalProps.lineItemIndex}.jobCostCodes`,
              newJobCostCodes
            )
          }
          {...assignMultipleCostCodesModalProps}
        />
      )}
    </Fragment>
  );
}

PayApplicationSplitTable.propTypes = {
  shouldShowAutofilledField: PropTypes.func,
};

PayApplicationSplitTable.defaultProps = {
  shouldShowAutofilledField: () => false,
};
