import {
  currencyColumnDefaults,
  dateColumnDefaults,
  enumColumnDefaults,
  listColumnDefaults,
  numberColumnDefaults,
  stringColumnDefaults,
  toBase64,
} from "components/materials/FastDataTable";
import { formatList } from "helpers/formatList";
import { flatten, get, find } from "lodash";
import analytics from "helpers/analytics";
import t from "helpers/translate";

export function trackPortfolioAnalysis(organizationId, redirectDestination) {
  analytics.track("Portfolio Analysis", {
    organizationId,
    redirectDestination,
  });
}

export const filterFields = [
  {
    ...currencyColumnDefaults,
    id: "projectTotal",
    header: "Project Total",
    value: (project) => project.amount,
  },
  {
    ...stringColumnDefaults,
    id: "projectName",
    header: "Project Name",
    value: (project) => project.name,
  },
  {
    ...enumColumnDefaults,
    id: "team",
    header: "Team",
    value: (project) => get(project, "team.name", "-"),
  },
  {
    ...enumColumnDefaults,
    id: "projectType",
    header: "Project Type",
    value: (project) => get(project, "projectType.name"),
  },
  {
    ...enumColumnDefaults,
    id: "region",
    header: "Region",
    value: (project) => get(project, "projectRegion.region", "-"),
  },
  {
    ...enumColumnDefaults,
    id: "productType",
    header: "Product Type",
    value: (project) => get(project, "productType.type", "-"),
  },
  {
    ...enumColumnDefaults,
    id: "city",
    header: "City",
    value: (project) => project.city,
  },
  {
    ...enumColumnDefaults,
    id: "state",
    header: "State/Province",
    value: (project) => project.state,
  },
  {
    ...enumColumnDefaults,
    id: "zip",
    header: "Postal Code",
    value: (project) => project.zip,
  },
  {
    ...dateColumnDefaults,
    id: "expectedStartDate",
    header: "Expected Project Start Date",
    value: (project) => project.startDate,
  },
  {
    ...numberColumnDefaults,
    id: "expectedProjectLength",
    header: "Expected Project Length",
    value: (project) => project.expectedProjectLength,
  },
  {
    ...numberColumnDefaults,
    id: "projectAreaSquareFeet",
    header: "Project Area Square Feet",
    value: (project) => project.squareFeet,
  },
  {
    ...numberColumnDefaults,
    id: "projectAreaAcres",
    header: "Project Area Acres",
    value: (project) => project.acres,
  },
  {
    ...enumColumnDefaults,
    id: "projectStatus",
    header: "Project Status",
    value: (project) => t(`projectStatus.${project.status}`),
  },
  {
    ...listColumnDefaults,
    id: "fundingSources",
    header: "Funding Sources",
    value: getFundingSourcesByLabel,
  },
];

export function withProjectTypes(projects, projectTypes) {
  return projects.map((project) => {
    const foundProjectType = find(
      projectTypes,
      (projectType) => projectType.id === project.typeId
    );
    return { ...project, projectType: foundProjectType };
  });
}

export function getSerializedURLForProjectReport(
  columnId,
  filter,
  organizationId
) {
  const config = {
    columnConfig: [
      "projectName",
      columnId,
      "projectTotal",
      "commitments",
      "debtCommitted",
      "amountRemaining",
      "percentComplete",
      "projectStatus",
    ],
    filterConfig: [
      {
        key: "projectStatus",
        enum: ["Active"],
      },
      { key: columnId, ...filter },
    ],
    groupConfig: {},
    sortConfig: { columnId: "timeOutstanding", direction: "desc" },
  };
  return `/reports/projects/?referrerSelectedOrgId=${organizationId}&table=${toBase64(
    config
  )}`;
}

// using an arbitrarily early start date that will predate any Tasks in the system
export const TASK_HISTORY_START_DATE = "01/01/2010";

export function getSerializedURLForTasksReport(
  startDate,
  endDate,
  showCompleted,
  organizationId
) {
  const config = {
    columnConfig: ["milestone", "project", "projectedCompletionDate", "status"],
    filterConfig: [
      {
        key: "projectedCompletionDate",
        start: startDate,
        finish: endDate,
      },
      !showCompleted
        ? { key: "status", enum: ["In Progress", "Not Started"] }
        : {},
      { key: "milestone", operator: "CONTAINS", input: "", __isSearch: true },
    ],
    groupConfig: {},
    sortConfig: { columnId: "projectedCompletionDate", direction: "asc" },
  };
  return `/reports/tasks/?referrerSelectedOrgId=${organizationId}&table=${toBase64(
    config
  )}`;
}

export function getFundingSourcesByLabel(project) {
  const fundingSourceList = flatten(project.fundingSourceGroups);
  const sourceNames = fundingSourceList.map((fundingSource) => {
    return (
      get(fundingSource, "label") || get(fundingSource, "organization.name")
    );
  });
  return formatList(sourceNames);
}
