import { useContext } from "react";
import { Pane, Text } from "components/materials";
import { camelCase, get } from "lodash";
import { ThemeContext } from "helpers/utilities";
import t from "helpers/translate";

export function AgreementCard({ agreement }) {
  const theme = useContext(ThemeContext);
  return (
    <Pane width="100%">
      <Pane display="flex" justifyContent="space-between">
        <Text
          textTransform="uppercase"
          color="#7f8f9f"
          fontSize={11}
          fontWeight={theme.fontWeights.DEMI}
        >
          {t(`agreementType.${camelCase(agreement.type)}`)}
        </Text>
      </Pane>
      <Pane>
        <Text fontWeight={theme.fontWeights.MEDIUM}>
          {get(agreement, "vendor.name", "No Vendor Assigned")}
        </Text>
      </Pane>
    </Pane>
  );
}
