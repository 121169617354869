import { compact } from "lodash";
import { Card, GoogleMap, Shortener, Pane, Text } from "components/materials";
import { ProjectBudgetProgress } from "components/templates";
import { majorScale, minorScale } from "helpers/utilities";

export function PortfolioProjectCardContent({ children, project, onClick }) {
  return (
    <Card
      cursor="pointer"
      display="flex"
      elevation={1}
      hoverElevation={2}
      onClick={onClick}
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      top={0}
    >
      <Pane width="40%" display="flex" justifyContent="center">
        {project.picture ? (
          <div
            style={{
              backgroundImage: `url("${project.picture.url}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              borderRadius: "4px 0 0 4px",
            }}
          />
        ) : (
          <GoogleMap height="100%" projects={[project]} />
        )}
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        padding={majorScale(2)}
        width="60%"
        minWidth={225}
        height="100%"
        overflowY="auto"
      >
        <Pane display="flex" flexDirection="column" flex={1}>
          <Shortener
            fontWeight={400}
            limit={35}
            marginBottom={5}
            text={project.name}
            wordBreak="break-word"
            fontSize={24}
            minimumFontSize={16}
            useDynamicFontSize
          />
          {(project.city || project.state) && (
            <Text
              color="muted"
              fontWeight={700}
              marginRight={majorScale(1)}
              marginBottom={minorScale(1)}
              overflow="hidden"
              size={300}
              textOverflow="ellipsis"
              textTransform="uppercase"
              whiteSpace="nowrap"
            >
              {compact([project.city, project.state]).join(", ")}
            </Text>
          )}
          {children}
        </Pane>
        <ProjectBudgetProgress project={project} />
      </Pane>
    </Card>
  );
}
