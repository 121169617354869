import { useCallback, useEffect, useRef, useState } from "react";

export function useResize() {
  const ref = useRef(null);

  const [size, setSize] = useState({ height: 0, width: 0 });

  const handleResize = useCallback(() => {
    if (ref.current) {
      setSize({
        height: ref.current.offsetHeight,
        width: ref.current.offsetWidth,
      });
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return [ref, size, handleResize];
}
