import { Pane, Button } from "components/materials";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { STEPS, PROJECT_STATUS } from "./utils";
import { Header } from "./Header";

export function DeveloperHasDrawsStep({
  goBack,
  loading,
  navigateToStep,
  updateOnboardingData,
}) {
  function updateHasDraws(hasDraws) {
    const hasDrawsVariables = {
      hasDraws,
      draws: hasDraws ? [] : [{ name: "Pre-development" }],
      projectStatus: hasDraws ? PROJECT_STATUS.FINALIZED_BUDGET : undefined,
    };
    updateOnboardingData(hasDrawsVariables).then(() => {
      if (hasDraws) navigateToStep(STEPS.BUDGET_DECISION);
      else navigateToStep(STEPS.PROJECT_STATUS);
    });
  }

  return (
    <Pane>
      <Header header={t("onboardingWizard.developerHasDraws")} />
      <Pane marginBottom={majorScale(3)}>
        <Button
          appearance="primary"
          isLoading={loading}
          onClick={() => {
            updateHasDraws(true);
          }}
          width={70}
        >
          Yes
        </Button>
        <Button
          appearance="primary"
          isLoading={loading}
          onClick={() => {
            updateHasDraws(false);
          }}
          marginLeft={majorScale(2)}
          width={70}
        >
          No
        </Button>
      </Pane>
      <Button
        onClick={() => {
          goBack();
        }}
        width={70}
      >
        Back
      </Button>
    </Pane>
  );
}
