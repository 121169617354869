import { Pane, Paragraph, Progress } from "components/materials";
import { BRAND_COLORS } from "helpers/colors";
import { formatCurrency } from "helpers/formatCurrency";
import { getFundingSourceAggregates } from "helpers/fundingSourceHelpers";
import { majorScale } from "helpers/utilities";

export function ProjectBudgetProgress({ project }) {
  const {
    LOAN: debtAggregates,
    EQUITY: equityAggregates,
    totalDisbursedAmount,
  } = getFundingSourceAggregates(project.fundingSourceGroups);
  const hasEquity = equityAggregates.sources.length > 0;
  const hasDebt = debtAggregates.sources.length > 0;
  const totalDisbursed = totalDisbursedAmount || project.disbursedAmount;

  return (
    <Pane>
      {hasEquity && (
        <Paragraph fontSize={12}>
          {formatCurrency(equityAggregates.disbursed, {
            shortenedFormat: true,
          })}
          {" / "}
          {formatCurrency(equityAggregates.total, {
            shortenedFormat: true,
          })}{" "}
          Equity
        </Paragraph>
      )}
      {hasDebt && (
        <Paragraph fontSize={12}>
          {formatCurrency(debtAggregates.disbursed, { shortenedFormat: true })}
          {" / "}
          {formatCurrency(debtAggregates.total, { shortenedFormat: true })} Debt
        </Paragraph>
      )}
      <Paragraph fontSize={12}>
        {formatCurrency(totalDisbursed, { shortenedFormat: true })}
        {" / "}
        {formatCurrency(project.amount, { shortenedFormat: true })} Total
      </Paragraph>
      <Pane>
        {hasEquity || hasDebt ? (
          <Progress
            background="lightgrey"
            color={BRAND_COLORS.LIGHT_PRIMARY}
            secondColor={BRAND_COLORS.PRIMARY}
            value={
              hasEquity ? equityAggregates.disbursed : debtAggregates.disbursed
            }
            secondValue={debtAggregates.disbursed}
            total={project.amount}
            height={majorScale(1)}
            sideBySide
            hasTwoPercentages={hasEquity && hasDebt}
          />
        ) : (
          <Progress
            background="lightgrey"
            color={BRAND_COLORS.LIGHT_PRIMARY}
            value={totalDisbursed}
            total={project.amount}
            height={majorScale(1)}
          />
        )}
      </Pane>
    </Pane>
  );
}
