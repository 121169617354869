import PropTypes from "prop-types";
import { Pane } from "evergreen-ui";
import { majorScale } from "helpers/utilities";
import Spacer from "../Spacer";

const Divider = ({ color, ...props }) => {
  return (
    <Pane background={color} {...props}>
      <Spacer />
    </Pane>
  );
};

Divider.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  opacity: PropTypes.number,
};

Divider.defaultProps = {
  color: "white",
  height: majorScale(2),
};

export default Divider;
