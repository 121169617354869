import { useState, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { CaretDownIcon, HelpIcon } from "evergreen-ui";
import {
  Button,
  Checkbox,
  Loadable,
  Pane,
  Popover,
  Table,
  Text,
  TextArea,
  Tooltip,
} from "components/materials";
import { formatDateTime } from "helpers/dateHelpers";
import { majorScale, Position, toaster } from "helpers/utilities";
import t from "helpers/translate";
import { get, xor } from "lodash";

export const PROJECT_QUERY = gql`
  query NotifyDocumentsProjectQuery($projectId: String!) {
    project(id: $projectId) {
      id
      assignmentNotifications {
        id
        documentCount
        lastSentAt
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const DRAW_QUERY = gql`
  query NotifyDocumentsDrawQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        id
        assignmentNotifications {
          id
          documentCount
          lastSentAt
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation NotifyUserOfAssignedDocuments(
    $recipient_ids: [String!]
    $projectId: String!
    $drawId: String
    $note: String
  ) {
    notifyUserOfAssignedDocuments(
      recipient_ids: $recipient_ids
      projectId: $projectId
      drawId: $drawId
      note: $note
    ) {
      status
    }
  }
`;

function AssignmentRow({ assignment, selectedUsers, setSelectedUsers }) {
  const user = get(assignment, "user");
  const userId = get(user, "id");
  return (
    <Table.Row>
      <Table.TextCell>
        <Checkbox
          key={assignment.id}
          checked={selectedUsers.includes(userId)}
          marginY={majorScale(1)}
          onChange={() => {
            setSelectedUsers(xor(selectedUsers, [userId]));
          }}
          size={12}
        />
      </Table.TextCell>
      <Table.TextCell>{user.fullName}</Table.TextCell>
      <Table.TextCell>{formatDateTime(assignment.lastSentAt)}</Table.TextCell>
      <Table.TextCell textAlign="right">
        {assignment.documentCount}
      </Table.TextCell>
    </Table.Row>
  );
}

export function NotifyDocumentAssignees({ match }) {
  const { drawId, projectId } = match.params;
  const query = drawId ? DRAW_QUERY : PROJECT_QUERY;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [note, setNote] = useState(undefined);
  const vars = drawId
    ? {
        variables: { projectId, drawId },
      }
    : { variables: { projectId } };
  const [mutate] = useMutation(MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query, ...vars }],
    onCompleted: () => {
      setSelectedUsers([]);
      setNote("");
      toaster.success(
        t("notifyDocumentAssignees.notificationSent", {
          count: selectedUsers.length,
        })
      );
    },
    onError: () => {
      toaster.danger(
        t("notifyDocumentAssignees.notificationError", {
          count: selectedUsers.length,
        })
      );
    },
  });
  const getAssignments = (queryResult) =>
    drawId
      ? get(queryResult, "project.draw.assignmentNotifications")
      : get(queryResult, "project.assignmentNotifications");
  const { data, loading } = useQuery(query, {
    ...vars,
  });

  // Use an effect if the query's data changes,
  // so that this component behaves correctly when the query
  // completes via cache update - eg during bulk actions
  useEffect(() => {
    if (!data || loading) return;
    setSelectedUsers(
      getAssignments(data).map((notification) => get(notification, "user.id"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  if (loading) return <Loadable loading />;

  const assignments = getAssignments(data);

  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      content={
        <Pane margin={majorScale(2)}>
          <Pane marginBottom={majorScale(2)}>
            <Text>Select the assignees you would like to notify below</Text>
          </Pane>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell>
                  <Checkbox
                    checked={selectedUsers.length === assignments.length}
                    onChange={() => {
                      selectedUsers.length === assignments.length
                        ? setSelectedUsers([])
                        : setSelectedUsers(
                            assignments.map(({ user }) => user.id)
                          );
                    }}
                  />
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>Assignee</Table.TextHeaderCell>
                <Table.TextHeaderCell>Last Notified</Table.TextHeaderCell>
                <Table.TextHeaderCell width={100}>
                  Documents Assigned
                </Table.TextHeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {assignments
                .slice()
                .sort(
                  (a, b) => get(a, "user.fullName") > get(b, "user.fullName")
                )
                .map((assignment) => (
                  <AssignmentRow
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    assignment={assignment}
                  />
                ))}
            </Table.Body>
          </Table>
          <TextArea
            minRows={3}
            marginBottom={majorScale(2)}
            placeholder="Enter a note to send to the selected assignees (optional)"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <Pane display="flex" justifyContent="flex-end">
            <Button
              disabled={!selectedUsers.length}
              purpose="notify-document-assignees notify"
              onClick={() => {
                mutate({
                  variables: {
                    recipient_ids: selectedUsers,
                    projectId,
                    drawId,
                    note,
                  },
                });
              }}
            >
              {selectedUsers.length
                ? `Notify Selected (${selectedUsers.length})`
                : "Notify Selected"}
            </Button>
          </Pane>
        </Pane>
      }
    >
      {({ getRef, toggle }) => (
        <Fragment>
          <Button
            disabled={assignments.length === 0}
            marginLeft={majorScale(1)}
            ref={getRef}
            onClick={toggle}
          >
            {t("notifyDocumentAssignees.notifyAssignees", {
              count: assignments.length,
            })}
            <CaretDownIcon />
          </Button>
          {assignments.length === 0 && (
            <Tooltip content={t("notifyDocumentAssignees.blankSlateTooltip")}>
              <HelpIcon color="default" marginLeft={majorScale(1)} size={12} />
            </Tooltip>
          )}
        </Fragment>
      )}
    </Popover>
  );
}
