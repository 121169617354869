import { Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Button, Loadable, Pane } from "components/materials";
import t from "helpers/translate";
import { get } from "lodash";
import { majorScale } from "helpers/utilities";
import {
  importPreview,
  setupBudgetManually,
  uploadFile,
  useDefaultLineItems,
} from "images";
import { OnboardingWizardDecisionCard } from "./OnboardingWizardDecisionCard";
import { CopyLineItemsCard } from "./CopyOrBuildBudgetStep";
import {
  getBudgetHeaders,
  getMatchingProjectTypes,
  PROJECT_STATUS,
  STEPS,
  TEMPLATE_QUERY,
} from "./utils";
import { Header } from "./Header";

export function BudgetDecisionStep({
  goBack,
  navigateToStep,
  project,
  updateOnboardingData,
}) {
  const { data, loading } = useQuery(TEMPLATE_QUERY, {
    variables: { projectId: project.id },
  });

  const matchingProjectTypes = loading
    ? []
    : getMatchingProjectTypes(get(data, "project"));

  const hasDraws = get(project, "onboardingData.hasDraws");
  const projectStatus = get(project, "onboardingData.projectStatus");
  const { header, subheader } = getBudgetHeaders(projectStatus, hasDraws);

  function advanceToEnterBudget(useRabbetBudget) {
    updateOnboardingData({ budget: [], useRabbetBudget }).then(() =>
      navigateToStep(STEPS.ENTER_BUDGET)
    );
  }

  if (loading) return <Loadable loading={loading} />;

  return (
    <Pane>
      <Header header={header} subheader={subheader} />

      <Pane display="flex" marginY={majorScale(4)} justifyContent="center">
        {projectStatus === PROJECT_STATUS.NO_BUDGET ? (
          <Fragment>
            <OnboardingWizardDecisionCard
              backgroundColor
              buttonPrimary
              buttonText={t(
                "onboardingWizard.budgetSetupStep.budgetBuilderButton"
              )}
              header={t("onboardingWizard.budgetSetupStep.budgetBuilderHeader")}
              subHeader={t(
                "onboardingWizard.budgetSetupStep.budgetBuilderSubheader"
              )}
              image={importPreview}
              onClick={() => advanceToEnterBudget(false)}
            />
            <CopyLineItemsCard
              matchingProjectTypes={matchingProjectTypes}
              navigateToStep={navigateToStep}
              updateOnboardingData={updateOnboardingData}
            />
            <OnboardingWizardDecisionCard
              buttonText="Basic Line Items"
              header={t(
                "onboardingWizard.budgetSetupStep.rabbetBudgetTemplateHeader"
              )}
              subHeader={t(
                "onboardingWizard.budgetSetupStep.rabbetBudgetTemplateSubheader"
              )}
              image={useDefaultLineItems}
              onClick={() => advanceToEnterBudget(true)}
            />
          </Fragment>
        ) : (
          <Fragment>
            <OnboardingWizardDecisionCard
              backgroundColor
              buttonPrimary
              buttonText={t("onboardingWizard.budgetSetupStep.setupButton")}
              header={t("onboardingWizard.budgetSetupStep.setupBudgetHeader")}
              image={setupBudgetManually}
              onClick={() =>
                matchingProjectTypes.length > 0
                  ? navigateToStep(STEPS.COPY_OR_BUILD_BUDGET)
                  : navigateToStep(STEPS.ENTER_BUDGET)
              }
              subHeader={t(
                "onboardingWizard.budgetSetupStep.setupBudgetSubheader"
              )}
            />
            <OnboardingWizardDecisionCard
              buttonText={t("onboardingWizard.budgetSetupStep.uploadButton")}
              header={t("onboardingWizard.budgetSetupStep.uploadBudgetHeader")}
              image={uploadFile}
              onClick={() => navigateToStep(STEPS.BUDGET_UPLOAD)}
              subHeader={t(
                "onboardingWizard.budgetSetupStep.uploadBudgetSubheader"
              )}
            />
          </Fragment>
        )}
      </Pane>

      <Button
        onClick={() => {
          goBack();
        }}
        marginTop={majorScale(2)}
        marginBottom={majorScale(4)}
      >
        Back
      </Button>
    </Pane>
  );
}
