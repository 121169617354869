import isBlank from "helpers/isBlank";

export function isValidZipcode(zip) {
  if (isBlank(zip)) return true;

  // 5 and 9 with only digits are valid lengths for US zip codes
  // and 6 with only alphanumeric characters is a valid length for Canadian zip codes
  const digitOnlyMatch = zip.match(/\d/g) || [];
  const alphanumericMatch = zip.match(/[a-zA-Z0-9]/g) || [];

  return (
    digitOnlyMatch.length === 5 ||
    alphanumericMatch.length === 6 ||
    digitOnlyMatch.length === 9
  );
}
