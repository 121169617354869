export const stringComparator = (a, b) => {
  const valueA = (a || "").toLowerCase();
  const valueB = (b || "").toLowerCase();

  if (valueA === valueB) {
    return 0;
  }

  if (valueA > valueB) {
    return 1;
  }

  return -1;
};

export const numberComparator = (a, b, ascending = true) => {
  // nulls last
  if (typeof a !== "number") return 1;
  if (typeof b !== "number") return -1;
  return ascending ? a - b : b - a;
};
