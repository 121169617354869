import PropTypes from "prop-types";
import { VendorFormPartial } from "components/templates";
import { Form, Pane } from "components/materials";
import { useDocumentFormContext } from "contexts/documentFormContext";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { get, includes } from "lodash";

const getHeading = (form) => {
  return [t(`documentTypeName.${form.values.type}`), "Information"].join(" ");
};

const DOCUMENTS_WITH_REQUIRED_VENDOR = [
  DOCUMENT_TYPE_NAME.CONDITIONAL_LIEN_RELEASE,
  DOCUMENT_TYPE_NAME.INVOICE,
  DOCUMENT_TYPE_NAME.PAY_APPLICATION,
  DOCUMENT_TYPE_NAME.UNCONDITIONAL_LIEN_RELEASE,
];

function DocumentInformation({
  children,
  collapsedPanels,
  disableFields,
  document,
  form,
  getProjectVendorSearchQuery,
  informationSummary,
  newlyAddedVendors,
  projectId,
  searchedVendors,
  setNewlyAddedVendors,
  shouldShowAutofilledField,
  suggestedVendors,
  togglePanel,
  vendors,
  warnings,
}) {
  const { usePerformik } = useDocumentFormContext();
  const content = (
    <VendorFormPartial
      autoAssignedOn={get(document, "autoAssignedVendorOn", [])}
      couldNotAutoAssignOn={get(document, "couldNotAutoAssignVendorOn")}
      disableFields={disableFields}
      formikProps={form}
      getProjectVendorSearchQuery={getProjectVendorSearchQuery}
      initialVendors={vendors}
      isHighlighted={shouldShowAutofilledField("vendor")}
      isWarned={
        !get(form, "values.vendor.id") &&
        includes(DOCUMENTS_WITH_REQUIRED_VENDOR, get(form, "values.type"))
      }
      marginRight={majorScale(2)}
      marginBottom={majorScale(2)}
      modalProps={{ marginRight: "45%" }}
      newName={get(document, "vendor.name") || null}
      newlyAddedVendors={newlyAddedVendors}
      parsedInfo={get(document, "parsedData", {})}
      projectId={projectId}
      searchedVendors={searchedVendors}
      setNewlyAddedVendors={setNewlyAddedVendors}
      suggestedVendors={suggestedVendors}
      usePerformik={usePerformik}
      warnings={warnings}
    />
  );

  const isCollapsed = collapsedPanels.includes("information");

  return (
    children !== null && (
      <Form.Section
        isCollapsible
        isCollapsed={isCollapsed}
        onCollapse={() => togglePanel("information")}
        heading={getHeading(form)}
        summary={informationSummary}
      >
        {!isCollapsed && <Pane display="flex">{children(content)}</Pane>}
      </Form.Section>
    )
  );
}

DocumentInformation.propTypes = {
  children: PropTypes.func,
  collapsedPanels: PropTypes.arrayOf(PropTypes.string),
  document: PropTypes.shape({
    vendor: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  form: PropTypes.object,
  informationSummary: PropTypes.string,
  togglePanel: PropTypes.func,
  vendors: PropTypes.array,
};

DocumentInformation.defaultProps = {
  children: (content) => content,
  collapsedPanels: [],
  informationSummary: "",
  vendors: [],
};

export default DocumentInformation;
