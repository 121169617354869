import { MoreIcon } from "evergreen-ui";
import { Menu, Popover, Text } from "components/materials";
import { majorScale, minorScale, Position } from "helpers/utilities";

export function actionsUtilityColumnDefaults(actions) {
  return {
    aggregate: () => null,
    header: () => null,
    id: "actions",
    utility: true,
    value: (item) => {
      return (
        <Popover
          content={
            <Menu>
              {actions.map((action) => (
                <Menu.Item
                  key={action.label}
                  onSelect={() => {
                    action.onClick([item]);
                  }}
                >
                  <Text color="muted" {...action.labelProps}>
                    {action.label}
                  </Text>
                </Menu.Item>
              ))}
            </Menu>
          }
          minWidth={majorScale(20)}
          position={Position.BOTTOM_LEFT}
        >
          <MoreIcon size={minorScale(3)} />
        </Popover>
      );
    },
    width: 25,
  };
}
