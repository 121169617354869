import PropTypes from "prop-types";
import { ChevronDownIcon, ChevronRightIcon } from "evergreen-ui";
import { Pane, Heading, Text } from "components/materials";
import { majorScale } from "helpers/utilities";

function FormSection(props) {
  const {
    action,
    borderBottom,
    borderRight,
    borderLeft,
    children,
    heading,
    isCollapsible,
    isCollapsed,
    onCollapse,
    summary,
    ...otherProps
  } = props;

  const borderStyling = {
    borderLeft,
    borderRight,
    borderBottom:
      borderBottom || !isCollapsed ? "1px solid #d7e0ea" : undefined,
    borderTop: "1px solid #d7e0ea",
  };

  return (
    <Pane>
      {heading && (
        <Pane
          alignItems="center"
          backgroundColor="#fcfcfd"
          boxShadow="0 3px 4px 1px rgba(5,64,127,0.04)"
          display="flex"
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          justifyContent="space-between"
          {...borderStyling}
        >
          <Pane
            alignItems="center"
            cursor={isCollapsible ? "pointer" : undefined}
            display="flex"
            flexGrow={1}
            onClick={onCollapse}
          >
            {isCollapsible &&
              (isCollapsed ? (
                <ChevronRightIcon marginRight={majorScale(1)} />
              ) : (
                <ChevronDownIcon marginRight={majorScale(1)} />
              ))}
            <Heading size={400}>{heading}</Heading>

            {summary !== undefined && isCollapsed && (
              <Text
                size={300}
                color="muted"
                fontStyle="italic"
                marginLeft={majorScale(1)}
              >
                {summary}
              </Text>
            )}
          </Pane>

          {action && <Pane>{action}</Pane>}
        </Pane>
      )}

      {!isCollapsed && (
        <Pane width="100%" padding={majorScale(2)} {...otherProps}>
          {children}
        </Pane>
      )}
    </Pane>
  );
}

FormSection.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.node,
  summary: PropTypes.any,
  action: PropTypes.node,
  borderLeft: PropTypes.string,
  borderRight: PropTypes.string,
  onCollapse: PropTypes.func,
};

FormSection.defaultProps = {
  borderLeft: "3px solid #587593",
  borderRight: undefined,
  onCollapse: () => {},
};

export default FormSection;
