import { Fragment, useContext } from "react";
import { ShareIcon } from "evergreen-ui";
import { Link, Pane, Paragraph, Shortener, Table } from "components/materials";
import { includes } from "lodash";
import { add, divideCurrency, subtract, sumBy } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import { minorScale, majorScale, ThemeContext } from "helpers/utilities";
import { AgreementValue } from "components/containers/Agreements";
import { PENDING_CHANGE_ORDER_TYPES } from "components/containers/Agreements/enums";

function isPendingType(type) {
  return includes(PENDING_CHANGE_ORDER_TYPES, type);
}

function getCurrentBudget(lineItem, recentDrawLineItem) {
  if (!recentDrawLineItem) return lineItem.originalBudgetAmount;
  const {
    adjustmentsAmount,
    previousAdjustmentsAmount,
    originalBudgetAmount,
  } = recentDrawLineItem;

  return add(
    originalBudgetAmount,
    add(adjustmentsAmount, previousAdjustmentsAmount)
  );
}

function getAggregateCalculations(lineItem, recentDraw) {
  const { agreements } = lineItem;
  const currentBudget = getCurrentBudget(lineItem, recentDraw?.lineItem);

  const committed = sumBy(
    agreements.filter(({ type }) => !isPendingType(type)),
    "lineItemAmount"
  );
  const pending = sumBy(
    agreements.filter(({ type }) => isPendingType(type)),
    "lineItemAmount"
  );
  const anticipated = add(committed, pending);

  return {
    percentBoughtOut: divideCurrency(committed, currentBudget),
    committed,
    uncommitted: subtract(currentBudget, committed),
    pending,
    anticipated,
    anticipatedUncommitted: subtract(currentBudget, anticipated),
  };
}

export function LineItemAgreements({ project }) {
  const theme = useContext(ThemeContext);
  const { lineItem, recentDraw } = project;

  const calculatedAmounts = getAggregateCalculations(lineItem, recentDraw);

  if (lineItem.agreements.length === 0) {
    return <Paragraph marginTop={majorScale(1)}>No Agreements</Paragraph>;
  }

  const hasPendingChangeOrderTypes =
    lineItem.agreements.filter((agreement) =>
      includes(PENDING_CHANGE_ORDER_TYPES, agreement.type)
    ).length > 0;

  return (
    <Fragment>
      <Pane display="flex">
        <AgreementValue
          name="Percent Bought Out"
          value={formatPercent(calculatedAmounts.percentBoughtOut)}
          width={170}
        />
        <AgreementValue
          name="Committed"
          value={formatCurrency(calculatedAmounts.committed)}
          width={170}
        />
        <AgreementValue
          name="Uncommitted"
          value={formatCurrency(calculatedAmounts.uncommitted)}
        />
      </Pane>
      {hasPendingChangeOrderTypes && (
        <Pane
          display="flex"
          background={theme.colors.lightPurple}
          borderRadius={minorScale(1)}
        >
          <AgreementValue
            name="Pending"
            value={formatCurrency(calculatedAmounts.pending)}
            width={170}
          />
          <AgreementValue
            name="Anticipated"
            value={formatCurrency(calculatedAmounts.anticipated)}
            width={170}
          />
          <AgreementValue
            name="Aniticpated Uncommitted"
            value={formatCurrency(calculatedAmounts.anticipatedUncommitted)}
          />
        </Pane>
      )}
      <Pane marginTop={majorScale(2)}>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell width={150}>Vendor</Table.TextHeaderCell>
              <Table.TextHeaderCell width={150}>Name</Table.TextHeaderCell>
              <Table.TextHeaderCell width={150}>
                <Shortener
                  size={400}
                  text={`${lineItem.name} Contracted Amount`}
                  limit={40}
                />
              </Table.TextHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {lineItem.agreements.map((agreement) => {
              return (
                <Table.Row
                  key={agreement.id}
                  state={
                    isPendingType(agreement.type)
                      ? { background: "special" }
                      : undefined
                  }
                >
                  <Table.TextCell>
                    <Shortener
                      size={400}
                      text={agreement.vendor?.name}
                      limit={25}
                    />
                  </Table.TextCell>
                  <Table.TextCell>
                    <Link
                      purpose="to agreements page"
                      size={300}
                      target="_blank"
                      to={`/projects/${project.id}/agreements/${agreement.id}`}
                    >
                      <Shortener
                        size={400}
                        text={agreement.name}
                        limit={25}
                        color={theme.colors.baseBlue}
                      />
                      <ShareIcon size="12" marginLeft={minorScale(1)} />
                    </Link>
                  </Table.TextCell>
                  <Table.TextCell>
                    {formatCurrency(agreement.lineItemAmount)}
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Pane>
    </Fragment>
  );
}
