import { Fragment } from "react";
import { LightbulbIcon } from "evergreen-ui";
import { Badge, Pane, Tooltip } from "components/materials";
import { minorScale, Position } from "helpers/utilities";

function SuggestedBadge() {
  return (
    <Badge color="yellow" marginRight={8}>
      <LightbulbIcon size={10} marginRight={minorScale(1)} />
      SUGGESTED
    </Badge>
  );
}

export function SuggestedOptionDescription({ description, tooltipContent }) {
  // Pane is necessary for rendering the tooltip
  return (
    <Fragment>
      {tooltipContent ? (
        <Tooltip content={tooltipContent} position={Position.TOP}>
          <Pane>
            <SuggestedBadge />
          </Pane>
        </Tooltip>
      ) : (
        <SuggestedBadge />
      )}
      {description}
    </Fragment>
  );
}
