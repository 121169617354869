import PropTypes from "prop-types";
import { Text } from "evergreen-ui";
import { zip } from "lodash";
import { INTERPOLATION_PLACEHOLDER } from "helpers/translate";

function InterpolatedTranslation({ string, values, ...props }) {
  const labelChunks = string
    .split(INTERPOLATION_PLACEHOLDER)
    .map((labelChunk) => (
      <Text key={labelChunk} {...props}>
        {labelChunk}
      </Text>
    ));

  return zip(labelChunks, values).map((stringOrInput) => stringOrInput);
}

InterpolatedTranslation.propTypes = {
  string: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.node),
};

InterpolatedTranslation.defaultProps = {
  values: [],
};

export default InterpolatedTranslation;
