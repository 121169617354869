import { DRAW_STATE, REVIEW_STATE } from "helpers/enums";
import { countBy, every, get, some } from "lodash";

export const DRAW_STATE_SORT_ORDER = [
  undefined,
  DRAW_STATE.FUNDED,
  DRAW_STATE.STARTED,
  DRAW_STATE.PENDING_RESUBMISSION,
  DRAW_STATE.RECEIVED_AND_CHANGED,
  DRAW_STATE.SUBMITTED,
  DRAW_STATE.RECEIVED,
];

export const LOCKED_DRAW_STATES = [
  DRAW_STATE.PAUSED,
  DRAW_STATE.WITHDRAWN,
  DRAW_STATE.REJECTED,
  DRAW_STATE.FUNDED,
];

const isDrawApproved = (reviews) =>
  some(reviews, { state: REVIEW_STATE.APPROVED, isFinal: true }) ||
  (reviews.length > 0 && every(reviews, { state: REVIEW_STATE.APPROVED }));

export const secondaryStatus = (draw) => {
  if (draw.state === DRAW_STATE.FUNDED) {
    return draw.fundingConfirmed ? "Incomplete" : "";
  }

  if (isDrawApproved(draw.reviews)) {
    return "Approved";
  }

  if (draw.reviews.length > 0) {
    const count = countBy(draw.reviews, "state");
    const countApproved = get(count, REVIEW_STATE.APPROVED, 0);
    return `${countApproved} / ${draw.reviews.length} Approved`;
  }

  return "";
};
