import { Fragment, useContext } from "react";
import { Card, Paragraph, Spinner } from "components/materials";
import t from "helpers/translate";
import { majorScale, ThemeContext } from "helpers/utilities";

export const PortfolioInsightBlankSlateCard = ({
  cardName,
  loading = false,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Fragment>
      <Paragraph
        color="muted"
        fontWeight={theme.fontWeights.MEDIUM}
        marginBottom={majorScale(1)}
      >
        {t(`portfolioInsightsPage.blankSlate.header.${cardName}`)}
      </Paragraph>
      <Card
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={250}
        marginBottom={majorScale(2)}
      >
        {loading ? (
          <Spinner size={40} />
        ) : (
          <Paragraph padding={majorScale(5)}>
            {t(`portfolioInsightsPage.blankSlate.info.${cardName}`)}
          </Paragraph>
        )}
      </Card>
    </Fragment>
  );
};
