import { get } from "lodash";
import { Pane, ConfigureButtons, Text } from "components/materials";
import { formatDate } from "helpers/dateHelpers";
import { toBase64 } from "components/materials/FastDataTable";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import { LinkCell, Table } from "./Table";
import { CardLinkButton } from "./CardLinkButton";
import { trackClickthrough } from "./helpers";

export const FUNDED_DRAWS_CONFIGURATION_SETTINGS = {
  i: "fundedDraws",
  x: 0,
  y: 4,
  w: 2,
  h: 1,
  disabled: false,
};

export function FundedDraws({
  cards,
  isConfigurable,
  isDisabled,
  name,
  organization,
  projects,
  setCards,
}) {
  const organizationId = organization.id;

  const tableData = projects
    .filter(
      (project) =>
        project.lastDrawFunded && get(project, "lastDrawFunded.daysSinceFunded")
    )
    .map(({ name, lastDrawFunded, id }) => {
      const fundedDate = get(lastDrawFunded, "fundedDate", null);
      const lastDrawId = get(lastDrawFunded, "id", null);

      return {
        projectName: name,
        lastDrawName: get(lastDrawFunded, "name", null),
        daysSinceFunded: get(lastDrawFunded, "daysSinceFunded", null),
        formattedFundedDate: formatDate(fundedDate, "-", "MM/dd/yyyy"),
        projectUrl: `/projects/${id}`,
        drawUrl: `/projects/${id}/draws/${lastDrawId}`,
      };
    });

  const columns = [
    {
      name: "Project",
      renderCell: (row) => (
        <LinkCell
          characterLimit={60}
          name={row.projectName}
          onClick={() =>
            trackClickthrough("Funded Draws to Project", organization.id, {
              projectUrl: row.projectUrl,
            })
          }
          url={row.projectUrl}
        />
      ),
      sortKey: "projectName",
      width: "30%",
    },
    {
      name: "Last Draw",
      renderCell: (row) => {
        return row.lastDrawName ? (
          <LinkCell
            characterLimit={40}
            name={row.lastDrawName}
            onClick={() =>
              trackClickthrough("Funded Draws to Draw", organization.id, {
                drawUrl: row.drawUrl,
              })
            }
            url={row.drawUrl}
          />
        ) : (
          <Text>-</Text>
        );
      },
      sortKey: "lastDrawName",
      width: "25%",
    },
    {
      name: "Date Funded",
      renderCell: (row) => <Text>{row.formattedFundedDate}</Text>,
      sortKey: "formattedFundedDate",
      width: "30%",
    },
    {
      name: "Elapsed",
      renderCell: (row) => (
        <Text>{row.daysSinceFunded ? `${row.daysSinceFunded} days` : "-"}</Text>
      ),
      sortKey: "daysSinceFunded",
      width: "15%",
    },
  ];

  const showBlankSlate = tableData.length === 0;

  const fundedDrawsViewConfig = toBase64({
    columnConfig: [
      "projectName",
      "projectTotal",
      "commitments",
      "currentDraw",
      "percentRemaining",
      "lastDrawFunded",
    ],
    filterConfig: [],
    groupConfig: {},
    sortConfig: {},
  });

  return (
    <Pane
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      padding={majorScale(2)}
    >
      <Pane
        display="flex"
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Funded Draws" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <CardLinkButton
            href={`/reports/projects?table=${fundedDrawsViewConfig}&referrerSelectedOrgId=${organizationId}`}
            onClick={() =>
              trackClickthrough(
                "Project Costs to Project Report",
                organizationId
              )
            }
          />
        )}
      </Pane>
      <Pane display="flex" flexGrow="1" flexDirection="column">
        {showBlankSlate ? (
          <BlankSlate type={BlankSlateType.Draws} />
        ) : (
          <Pane
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Table
              cellBorderY={false}
              columns={columns}
              defaultSortDirection="desc"
              defaultSortKey="daysSinceFunded"
              hasFooter={false}
              maxHeight={280}
              rows={tableData}
              width="100%"
            />
            <Text
              marginTop={majorScale(2)}
              fontStyle="italic"
              textAlign="center"
              maxWidth="80%"
            >
              {t("portfolioInsights.fundedDraws.note")}
            </Text>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}
