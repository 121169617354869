import { useContext, useState } from "react";
import Drawer from "react-modern-drawer";
import {
  Pane,
  Heading,
  IconButton,
  Group,
  Paragraph,
} from "components/materials";
import { UserContext } from "helpers/behaviors";
import {
  majorScale,
  minorScale,
  SocialMediaIcon,
  CrossIcon,
  ThemeContext,
} from "evergreen-ui";
import { Approvals } from "./Approvals/Approvals";
import { Mentions } from "./Mentions/Mentions";
import { ProjectChat } from "./ProjectChat/ProjectChat";

const COLLABORATION_TABS = [
  { label: "To-Dos", value: "todos" },
  { label: "Project Chat", value: "projectChat" },
];

export function CollaborationDrawer({ isOpen, toggleClose, project }) {
  const [selectedValue, setSelectedValue] = useState("todos");
  const theme = useContext(ThemeContext);
  const { userId } = useContext(UserContext);

  return (
    <Drawer
      open={isOpen}
      onClose={toggleClose}
      duration={200}
      size={540}
      direction="right"
      style={{ marginTop: "68px" }}
    >
      <Pane height="calc(100vh - 68px)">
        <Pane
          paddingBottom={majorScale(2)}
          marginBottom={majorScale(2)}
          borderBottom={`1px solid ${theme.colors.gray200}`}
          paddingX={majorScale(3)}
          paddingTop={majorScale(2)}
          height={165}
        >
          <Pane display="flex" alignItems="center" marginBottom={minorScale(1)}>
            <Heading fontSize={majorScale(3)} fontWeight={500}>
              Collaboration
            </Heading>
            <Pane display="flex" alignItems="center" marginTop="2px">
              <SocialMediaIcon
                color="muted"
                marginLeft={majorScale(2)}
                size={minorScale(4)}
              />
            </Pane>
            <IconButton
              icon={() => <CrossIcon color="muted" />}
              appearance="minimal"
              type="button"
              marginLeft="auto"
              onClick={toggleClose}
            />
          </Pane>
          <Pane paddingBottom={majorScale(3)}>
            <Heading fontWeight={300} size={400} color={theme.colors.textGray}>
              Review your to-do list and project conversations
            </Heading>
          </Pane>
          <Pane background="#F9F9FA">
            <Group width="100%" padding={minorScale(2)}>
              {COLLABORATION_TABS.map(({ label, value }, index) => (
                <CollaborationSelector
                  key={`collaboaration-selector-${index}`}
                  label={label}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  value={value}
                />
              ))}
            </Group>
          </Pane>
        </Pane>
        <Pane height="calc(100vh - 233px)">
          {selectedValue === "todos" && (
            <Pane
              paddingBottom={majorScale(2)}
              paddingX={majorScale(3)}
              height="100%"
              overflowY="scroll"
            >
              <Approvals project={project} currentUserId={userId} />
              <Mentions project={project} currentUserId={userId} />
            </Pane>
          )}
          {selectedValue === "projectChat" && (
            <ProjectChat project={project} currentUserId={userId} />
          )}
        </Pane>
      </Pane>
    </Drawer>
  );
}

function CollaborationSelector({
  label,
  selectedValue,
  setSelectedValue,
  value,
}) {
  const theme = useContext(ThemeContext);
  const isSelected = value === selectedValue;
  const selectedStyles = {
    backgroundColor: "white",
    boxShadow: "0px 2px 4px 0px rgba(18, 28, 45, 0.10)",
    color: theme.colors.text,
  };

  const unselectedStyles = {
    backgroundColor: "transparent",
    color: "#AEB2C1",
  };

  const currentStyles = isSelected ? selectedStyles : unselectedStyles;

  return (
    <Pane
      borderRadius={minorScale(1)}
      paddingY={majorScale(1)}
      key={label}
      width="50%"
      onClick={() => setSelectedValue(value)}
      cursor="pointer"
      {...currentStyles}
    >
      <Paragraph textAlign="center" size={400} fontWeight={600}>
        {label}
      </Paragraph>
    </Pane>
  );
}
