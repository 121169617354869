import { Fragment } from "react";
import PropTypes from "prop-types";
import { Form } from "components/materials";
import getOptions from "helpers/getOptions";

const SelectDrawForm = ({ projects, draws, textSubmit, result, form }) => {
  const { error, loading } = result;
  const { values, handleSubmit } = form;
  const selectableDraws = draws.filter(
    (draw) => draw.projectId === values.projectId
  );

  return (
    <Form.Generic
      handleSubmit={handleSubmit}
      loading={loading}
      error={error}
      textSubmit={textSubmit}
    >
      <Fragment>
        <Form.Select
          label="Project name"
          name="projectId"
          options={getOptions(projects)}
        />
        <Form.Select
          label="Draw name"
          name="drawId"
          options={getOptions(selectableDraws)}
        />
      </Fragment>
    </Form.Generic>
  );
};

SelectDrawForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  projectTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onTemplateIdChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    templateId: PropTypes.string,
  }).isRequired,
  isSubmission: PropTypes.bool,
  children: PropTypes.node.isRequired,
  textSubmit: PropTypes.string,
};

export default SelectDrawForm;
