import { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Loadable } from "components/materials";
import { FileView } from "components/templates";
import { findIndex, get, xorBy } from "lodash";
import { AgreementsFileViewer } from "./AgreementsFileViewer";
import { AgreementsViewerHeader } from "./AgreementsViewerHeader";
import { AgreementsViewerLayout } from "./AgreementsViewerLayout";
import { AgreementsViewerSidebar } from "./AgreementsViewerSidebar";
import { AGREEMENT_DETAIL_QUERY } from "../graphql-queries";

export function AgreementsViewer({
  agreementsTableRefetchQueries,
  handleAgreementsViewerClose,
  history,
  match,
  project,
}) {
  const {
    params: { agreementId, projectId },
  } = match;

  const [viewerOpen, setViewerOpen] = useState(true);
  const agreements = get(project, "agreements", []);

  const { data } = useQuery(AGREEMENT_DETAIL_QUERY, {
    variables: {
      projectId,
      agreementId,
    },
  });

  const agreement = get(data, "project.agreement");

  if (!agreement) return <Loadable />;

  const agreementDetailRefetchQueries = [
    { query: AGREEMENT_DETAIL_QUERY, variables: { agreementId, projectId } },
  ];
  const { type: agreementType } = agreement;
  const correlatedDocuments =
    get(data, "project.agreement.correlatedDocuments") || [];
  const document = get(data, "project.agreement.document");
  const selectedAgreementIndex = findIndex(agreements, ["id", agreement.id]);
  const otherProjectAgreements = xorBy(agreements, [agreement], "id");

  const previousDisabled = selectedAgreementIndex < 1;
  const nextDisabled = selectedAgreementIndex === agreements.length - 1;

  const handleNext = () =>
    handleAgreementSelect(agreements[selectedAgreementIndex + 1]);

  const handlePrevious = () =>
    handleAgreementSelect(agreements[selectedAgreementIndex - 1]);

  const navigateOnDelete = () => {
    if (!nextDisabled) {
      handleNext();
    } else if (!previousDisabled) {
      handlePrevious();
    } else {
      handleAgreementsViewerClose();
    }
  };

  const navigateOnCreate = (id) => {
    handleAgreementSelect({ id });
  };

  const navigateOnAssociate = (id) => {
    handleAgreementSelect({ id });
  };

  const handleAgreementSelect = ({ id: clickedAgreementId }) => {
    const {
      location: { pathname, search },
      replace,
    } = history;

    replace({
      pathname: pathname.replace(agreementId, clickedAgreementId),
      search,
    });
  };

  return (
    <FileView
      fileViewer={
        <AgreementsFileViewer
          adjustmentDrawId={get(agreement, "budgetAdjustment.drawId")}
          agreementDocument={document}
          agreementId={agreementId}
          agreementType={agreementType}
          changeOrderDocuments={correlatedDocuments}
          refetchQueries={agreementDetailRefetchQueries}
        />
      }
      header={<AgreementsViewerHeader project={project} />}
      nextDisabled={nextDisabled}
      onClose={handleAgreementsViewerClose}
      onNext={handleNext}
      onPrevious={handlePrevious}
      previousDisabled={previousDisabled}
      setViewerOpen={setViewerOpen}
      sidebar={
        <AgreementsViewerSidebar
          agreements={agreements}
          onSelectAgreement={handleAgreementSelect}
          selectedAgreement={agreement}
        />
      }
      viewerOpen={viewerOpen}
    >
      {(cardsOpen, setCardsOpen) => (
        <AgreementsViewerLayout
          agreement={agreement}
          cardsOpen={cardsOpen}
          navigateOnAssociate={navigateOnAssociate}
          navigateOnDelete={navigateOnDelete}
          navigateOnCreate={navigateOnCreate}
          otherProjectAgreements={otherProjectAgreements}
          project={project}
          agreementDetailRefetchQueries={agreementDetailRefetchQueries}
          agreementsTableRefetchQueries={agreementsTableRefetchQueries}
          setCardsOpen={setCardsOpen}
        />
      )}
    </FileView>
  );
}
