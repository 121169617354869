import { useState, Fragment, useContext } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/react-components";
import { Formik } from "formik";
import { InfoSignIcon } from "evergreen-ui";
import { QUERY as DRAW_SUMMARY_SIDEBAR_QUERY } from "components/containers/DrawLineItemSlideout";
import { DRAW_HEADER_QUERY } from "components/containers/DrawHeader/graphql";
import {
  Banner,
  Button,
  Divider,
  FastMaskInput,
  Form,
  Grid,
  LinkButton,
  Pane,
  Paragraph,
  Text,
} from "components/materials";
import FormTextArea from "components/materials/Form/FormTextArea";
import analytics from "helpers/analytics";
import { Frozen, UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION, DOCUMENT_TYPE_NAME } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import {
  getTransactionsForSource,
  totalForField,
  transactionsForField,
} from "helpers/ledgerHelpers";
import formatPercent from "helpers/formatPercent";
import isBlank from "helpers/isBlank";
import { add, divide, multiply, subtract } from "helpers/math";
import t from "helpers/translate";
import unformatNumber from "helpers/unformatNumber";
import { majorScale, ThemeContext } from "helpers/utilities";
import { get, isEqual } from "lodash";

const MUTATION = gql`
  mutation RequestedAmountFormPartialMutation(
    $drawId: String!
    $lineItemId: String!
    $grossRequestedAmount: Currency!
    $retainageAmount: Currency!
    $retainagePercentage: Float!
    $setManually: Boolean!
    $memo: String
  ) {
    adjustRequestedAmountForLineItem(
      drawId: $drawId
      lineItemId: $lineItemId
      grossRequestedAmount: $grossRequestedAmount
      retainageAmount: $retainageAmount
      retainagePercentage: $retainagePercentage
      setManually: $setManually
      memo: $memo
    ) {
      id
      scopeId
      grossEndorsedPercentComplete
      grossEndorsedRequestedToDateAmount
      grossInspectedRequestedToDateAmount
      grossPercentComplete
      grossPercentRemaining
      grossRequestedAmount
      grossRequestedPreviouslyAmount
      grossRequestedToDateAmount
      grossUndocumentedRequestedAmount
      grossUnendorsedRequestedToDateAmount
      percentComplete
      percentRemaining
      requestedAmount
      requestedPreviouslyAmount
      requestedToDateAmount
      retainageAmount
      retainagePercentage
      retainagePreviouslyAmount
      retainageToDateAmount
      setManually
      undocumentedRequestedAmount
      transactions {
        id
        memo
        insertedAt
        entries {
          id
          account
          amount
        }
        user {
          id
          fullName
        }
      }
    }
  }
`;

const LABEL_COLUMN_WIDTH = 8;
const VALUE_COLUMN_WIDTH = 4;

const RETAINAGE_PERCENTAGE_MUTATION = gql`
  mutation RequestedAmountFormPartialMutation(
    $drawId: String!
    $lineItemId: String!
    $retainagePercentage: Float!
    $setManually: Boolean!
  ) {
    setRetainagePercentageForLineItem(
      drawId: $drawId
      lineItemId: $lineItemId
      retainagePercentage: $retainagePercentage
      setManually: $setManually
    ) {
      id
      scopeId
      grossPercentComplete
      grossPercentRemaining
      grossRequestedAmount
      grossRequestedPreviouslyAmount
      grossRequestedToDateAmount
      grossUndocumentedRequestedAmount
      percentComplete
      percentRemaining
      requestedAmount
      requestedPreviouslyAmount
      requestedToDateAmount
      retainageAmount
      retainagePercentage
      retainagePreviouslyAmount
      retainageToDateAmount
      setManually
      undocumentedRequestedAmount
    }
  }
`;

const initialValues = (drawId, lineItem, documentGrossRequestedAmount) => {
  const useDocumentRequestedAmount =
    documentGrossRequestedAmount &&
    lineItem.grossRequestedAmount === 0 &&
    lineItem.retainageAmount === 0 &&
    lineItem.retainagePercentage !== 0;

  return {
    drawId,
    lineItemId: lineItem.id,
    grossRequestedAmountAdjustment: "$0.00",
    memo: "",
    grossRequestedAmount: formatCurrency(
      useDocumentRequestedAmount
        ? documentGrossRequestedAmount
        : lineItem.grossRequestedAmount
    ),
    retainageAmount: formatCurrency(
      useDocumentRequestedAmount
        ? multiply(documentGrossRequestedAmount, lineItem.retainagePercentage)
        : lineItem.retainageAmount
    ),
    retainagePercentage: formatPercent(lineItem.retainagePercentage, "0%", 10),
    setManually: lineItem.setManually ? "true" : "false",
  };
};

const validate = (lineItem) => (values) => {
  const errors = {};
  if (isBlank(values.grossRequestedAmount)) {
    errors.grossRequestedAmount = "Please enter a gross requested amount";
  }
  if (isBlank(values.retainageAmount)) {
    errors.retainageAmount = "Please enter a retainage amount";
  } else if (
    add(values.retainageAmount, lineItem.retainagePreviouslyAmount) < 0
  ) {
    errors.retainageAmount = "Retainage withheld to date cannot be negative";
  }
  if (isBlank(values.retainagePercentage)) {
    errors.retainagePercentage = "Please enter a retainage percentage";
  }
  return errors;
};

const onSubmit = (mutation, retainagePercentageMutation, initialValues) => (
  values
) => {
  const variables = {
    drawId: values.drawId,
    lineItemId: values.lineItemId,
    grossRequestedAmount: values.grossRequestedAmountAdjustment,
    retainageAmount: formatCurrency(
      subtract(values.retainageAmount, initialValues.retainageAmount)
    ),
    setManually: values.setManually === "true",
    retainagePercentage: divide(parseFloat(values.retainagePercentage), 100),
  };

  const useRetainageMutation =
    isEqual(variables.grossRequestedAmount, 0) &&
    isEqual(variables.retainageAmount, 0);

  if (useRetainageMutation) {
    retainagePercentageMutation({ variables });
  } else {
    mutation({ variables });
    analytics.track("Draw Line Item Requested Amount Manual Edit Created", {
      manual: true,
      drawId: values.drawId,
      lineItemId: values.lineItemId,
      grossRequestedAmountAdjustment: values.grossRequestedAmountAdjustment,
      retainageAmountAdjustment: subtract(
        initialValues.retainageAmount,
        variables.retainageAmount
      ),
      hasMemo: !!variables.memo,
    });
  }
};

const RequestedAmountFormPartial = ({
  drawId,
  projectId,
  lineItem,
  lockdownDraw,
  documentGrossRequestedAmount,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const refetchQueries = [
    {
      query: DRAW_SUMMARY_SIDEBAR_QUERY,
      variables: { drawId, projectId, lineItemId: get(lineItem, "id") },
    },
    {
      query: DRAW_HEADER_QUERY,
      variables: {
        drawId,
        projectId,
        documentType: DOCUMENT_TYPE_NAME.INVOICE,
      },
    },
  ];

  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);
  const disabled = !hasPermission(PERMISSION_ACTION.EDIT_AMOUNT_REQUESTED);

  const manualEdits = transactionsForField(
    getTransactionsForSource(lineItem.transactions, "user"),
    "grossRequestedAmount"
  );
  const previousGrossRequestedAmountAdjustment = totalForField(
    manualEdits,
    "grossRequestedAmount"
  );

  return (
    <Fragment>
      {lineItem ? (
        <Button
          appearance="minimal"
          color={theme.colors.blue600}
          disabled={lockdownDraw}
          onClick={() => setOpen(true)}
          purpose="requested-amount-form submit"
        >
          Manual Edit
        </Button>
      ) : (
        "$0.00 (0%)"
      )}
      {open && (
        <Frozen projectId={projectId}>
          {({ frozenError, frozenSubmit, isFrozenError }) => (
            <Mutation
              awaitRefetchQueries
              mutation={MUTATION}
              onCompleted={handleClose}
              onError={frozenError}
              refetchQueries={refetchQueries}
            >
              {(mutation, result) => (
                <Mutation
                  awaitRefetchQueries
                  mutation={RETAINAGE_PERCENTAGE_MUTATION}
                  onCompleted={handleClose}
                  onError={frozenError}
                  refetchQueries={refetchQueries}
                >
                  {(
                    retainagePercentageMutation,
                    retainagePercentageMutationResult
                  ) => (
                    <Formik
                      initialValues={initialValues(
                        drawId,
                        lineItem,
                        documentGrossRequestedAmount
                      )}
                      validate={validate(lineItem)}
                      onSubmit={
                        disabled
                          ? handleClose
                          : frozenSubmit(
                              onSubmit(
                                mutation,
                                retainagePercentageMutation,
                                initialValues(
                                  drawId,
                                  lineItem,
                                  documentGrossRequestedAmount
                                )
                              )
                            )
                      }
                    >
                      {(form) => {
                        const {
                          grossRequestedAmountAdjustment,
                          grossRequestedAmount,
                          retainageAmount,
                        } = form.values;

                        const grossRequestedAmountBeforeEdits = subtract(
                          grossRequestedAmount,
                          previousGrossRequestedAmountAdjustment
                        );

                        const newGrossRequestedAmount = add(
                          grossRequestedAmount,
                          grossRequestedAmountAdjustment
                        );

                        const retainagePercentage = divide(
                          retainageAmount,
                          newGrossRequestedAmount
                        );

                        const requestedAmount = subtract(
                          newGrossRequestedAmount,
                          retainageAmount
                        );

                        const grossRequestedToDateAmount = add(
                          lineItem.grossRequestedPreviouslyAmount,
                          newGrossRequestedAmount
                        );

                        const retainageToDateAmount = add(
                          lineItem.retainagePreviouslyAmount,
                          retainageAmount
                        );

                        const retainageToDatePercentage = divide(
                          retainageToDateAmount,
                          grossRequestedToDateAmount
                        );

                        const requestedToDateAmount = subtract(
                          grossRequestedToDateAmount,
                          retainageToDateAmount
                        );

                        const retainageWithheldExpected = subtract(
                          multiply(
                            grossRequestedToDateAmount,
                            form.values.retainagePercentage
                          ),
                          lineItem.retainagePreviouslyAmount
                        );

                        const resultError =
                          result.error ||
                          retainagePercentageMutationResult.error;

                        const parsedResult = {
                          loading:
                            result.loading ||
                            retainagePercentageMutationResult.loading,
                          error: isFrozenError(resultError)
                            ? undefined
                            : resultError,
                        };

                        const showUploadDocumentsPrompt =
                          hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT) &&
                          hasPermission(
                            PERMISSION_ACTION.UPDATE_REQUESTED_AMOUNT_WITH_DOCUMENTATION
                          );

                        return (
                          <Fragment>
                            <Form.Modal
                              {...form}
                              {...parsedResult}
                              confirmCancel
                              header={`Edit amount requested - ${lineItem.division.name} - ${lineItem.name}`}
                              onClose={handleClose}
                              onSubmit={form.handleSubmit}
                              containerProps={{
                                "data-testid": "edit-amount-requested-modal",
                              }}
                              contentContainerProps={{
                                padding: 0,
                              }}
                            >
                              {disabled && (
                                <Banner
                                  borderBottom
                                  icon="infoSignIcon"
                                  mainText={t(
                                    "requestedAmountForm.noPermissionMain"
                                  )}
                                  secondaryText={t(
                                    "requestedAmountForm.noPermissionSecondary"
                                  )}
                                  textProps={{ size: 300 }}
                                />
                              )}

                              {!disabled && showUploadDocumentsPrompt && (
                                <Banner borderBottom>
                                  <Pane display="flex" padding={majorScale(1)}>
                                    <Pane marginLeft={majorScale(1)}>
                                      <InfoSignIcon
                                        marginRight={majorScale(1)}
                                        color={theme.colors.baseBlue}
                                      />
                                    </Pane>
                                    <Pane>
                                      <Paragraph>
                                        {t(
                                          "requestedAmountForm.uploadDocumentsPromptTitle"
                                        )}
                                      </Paragraph>
                                      <Paragraph marginTop={majorScale(1)}>
                                        {t(
                                          "requestedAmountForm.uploadDocumentsPromptBody"
                                        )}
                                      </Paragraph>
                                      <Pane marginTop={majorScale(2)}>
                                        <LinkButton
                                          to={`/projects/${projectId}/draws/${drawId}/documentation?uploadDocument=true`}
                                        >
                                          Add Documents
                                        </LinkButton>
                                      </Pane>
                                    </Pane>
                                  </Pane>
                                </Banner>
                              )}
                              <Grid
                                padding={majorScale(2)}
                                paddingBottom={majorScale(4)}
                                paddingLeft={majorScale(5)}
                              >
                                <Grid.Row alignItems="center">
                                  <Grid.Column columns={LABEL_COLUMN_WIDTH} />
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text
                                      fontSize={12}
                                      fontWeight={theme.fontWeights.MEDIUM}
                                    >
                                      This draw
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text
                                      fontSize={12}
                                      fontWeight={theme.fontWeights.MEDIUM}
                                    >
                                      To date
                                    </Text>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row alignItems="center">
                                  <Grid.Column columns={LABEL_COLUMN_WIDTH}>
                                    <Text fontSize={12}>
                                      Current Amount Requested (Gross)
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column
                                    columns={VALUE_COLUMN_WIDTH}
                                    paddingRight={majorScale(2)}
                                  >
                                    <Text
                                      fontSize={12}
                                      data-testid="grossRequestedAmount"
                                    >
                                      {formatCurrency(
                                        grossRequestedAmountBeforeEdits
                                      )}
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH} />
                                </Grid.Row>
                                {previousGrossRequestedAmountAdjustment !==
                                  0 && (
                                  <Grid.Row alignItems="center">
                                    <Grid.Column columns={LABEL_COLUMN_WIDTH}>
                                      <Text fontSize={12}>
                                        Previous Manual Edits
                                      </Text>
                                    </Grid.Column>
                                    <Grid.Column
                                      columns={VALUE_COLUMN_WIDTH}
                                      paddingRight={majorScale(2)}
                                    >
                                      <Text
                                        fontSize={12}
                                        data-testid="manualEditsThisDraw"
                                      >
                                        {formatCurrency(
                                          previousGrossRequestedAmountAdjustment
                                        )}
                                      </Text>
                                    </Grid.Column>
                                    <Grid.Column columns={VALUE_COLUMN_WIDTH} />
                                  </Grid.Row>
                                )}
                                <Grid.Row alignItems="center">
                                  <Grid.Column columns={LABEL_COLUMN_WIDTH}>
                                    <Text fontSize={12}>Manual Edit</Text>
                                  </Grid.Column>
                                  <Grid.Column
                                    columns={VALUE_COLUMN_WIDTH}
                                    paddingRight={majorScale(2)}
                                  >
                                    <FastMaskInput
                                      disabled={disabled}
                                      mask="currency"
                                      name="grossRequestedAmountAdjustment"
                                    />
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH} />
                                </Grid.Row>
                                <Divider
                                  color={theme.layoutColors.border}
                                  height={1}
                                />
                                <Grid.Row alignItems="center">
                                  <Grid.Column columns={LABEL_COLUMN_WIDTH}>
                                    <Text fontSize={12} fontWeight="bold">
                                      Amount Requested (Gross)
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column
                                    columns={VALUE_COLUMN_WIDTH}
                                    paddingRight={majorScale(2)}
                                  >
                                    <Text
                                      fontSize={12}
                                      fontWeight="bold"
                                      data-testid="requestedAmountWithEdits"
                                    >
                                      {formatCurrency(newGrossRequestedAmount)}
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text
                                      fontSize={12}
                                      fontWeight="bold"
                                      data-testid="grossRequestedToDateAmount"
                                    >
                                      {formatCurrency(
                                        grossRequestedToDateAmount
                                      )}
                                    </Text>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column>&nbsp;</Grid.Column>
                                </Grid.Row>
                                <Grid.Row alignItems="center">
                                  <Grid.Column columns={LABEL_COLUMN_WIDTH}>
                                    <Text fontSize={12}>
                                      Retainage Withheld
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column
                                    columns={VALUE_COLUMN_WIDTH}
                                    paddingRight={majorScale(2)}
                                  >
                                    <FastMaskInput
                                      disabled={disabled}
                                      mask="currency"
                                      name="retainageAmount"
                                    />
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text
                                      fontSize={12}
                                      data-testid="retainageToDateAmount"
                                    >
                                      {formatCurrency(retainageToDateAmount)}
                                    </Text>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row alignItems="center">
                                  <Grid.Column columns={LABEL_COLUMN_WIDTH}>
                                    <Text fontSize={12} fontStyle="italic">
                                      Retainage Percentage
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text
                                      fontSize={12}
                                      fontStyle="italic"
                                      data-testid="retainagePercent"
                                    >
                                      {formatPercent(retainagePercentage)}
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text
                                      fontSize={12}
                                      fontStyle="italic"
                                      data-testid="retainagePercentToDate"
                                    >
                                      {formatPercent(retainageToDatePercentage)}
                                    </Text>
                                  </Grid.Column>
                                </Grid.Row>
                                <Divider
                                  color={theme.layoutColors.border}
                                  height={1}
                                />
                                <Grid.Row alignItems="center">
                                  <Grid.Column columns={LABEL_COLUMN_WIDTH}>
                                    <Text fontSize={12} fontWeight="bold">
                                      Amount Requested (Net)
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text fontSize={12} fontWeight="bold">
                                      {formatCurrency(requestedAmount)}
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text fontSize={12} fontWeight="bold">
                                      {formatCurrency(requestedToDateAmount)}
                                    </Text>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column>&nbsp;</Grid.Column>
                                </Grid.Row>
                                {hasPermission(
                                  PERMISSION_ACTION.UPDATE_REQUESTED_AMOUNT_WITH_DOCUMENTATION
                                ) && (
                                  <Grid.Row>
                                    <Grid.Column
                                      columns={18}
                                      paddingTop={majorScale(1)}
                                    >
                                      <Form.RadioGroup
                                        name="setManually"
                                        options={[
                                          {
                                            isDisabled: disabled,
                                            label:
                                              "Auto-calculate these amounts based on documentation updates",
                                            value: "false",
                                          },
                                          {
                                            isDisabled: disabled,
                                            label:
                                              "Do not change these amounts automatically",
                                            value: "true",
                                          },
                                        ]}
                                      />
                                    </Grid.Column>
                                  </Grid.Row>
                                )}
                              </Grid>
                              <Text
                                fontSize={14}
                                fontWeight={theme.fontWeights.MEDIUM}
                                paddingLeft={majorScale(5)}
                              >
                                Expected Retainage
                              </Text>
                              <Grid
                                padding={majorScale(2)}
                                paddingBottom={majorScale(5)}
                                paddingLeft={majorScale(5)}
                              >
                                <Grid.Row alignItems="center">
                                  <Grid.Column columns={LABEL_COLUMN_WIDTH}>
                                    <Text fontSize={12}>
                                      Percentage Expected
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text fontSize={12}>Expected</Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text fontSize={12}>
                                      Withheld Previously
                                    </Text>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row alignItems="center">
                                  <Grid.Column
                                    columns={LABEL_COLUMN_WIDTH}
                                    paddingRight={majorScale(2)}
                                  >
                                    <Form.Input
                                      disabled={disabled}
                                      name="retainagePercentage"
                                      type="percentage"
                                    />
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text fontSize={12}>
                                      {formatCurrency(
                                        retainageWithheldExpected
                                      )}
                                    </Text>
                                  </Grid.Column>
                                  <Grid.Column columns={VALUE_COLUMN_WIDTH}>
                                    <Text fontSize={12}>
                                      {formatCurrency(
                                        lineItem.retainagePreviouslyAmount
                                      )}
                                    </Text>
                                  </Grid.Column>
                                </Grid.Row>
                                {unformatNumber(
                                  grossRequestedAmountAdjustment
                                ) !== 0 && (
                                  <Grid.Row alignItems="center">
                                    <Grid.Column columns={16}>
                                      <Text
                                        fontSize={12}
                                        fontWeight={theme.fontWeights.MEDIUM}
                                      >
                                        Manual Edit Description
                                      </Text>
                                      <FormTextArea name="memo" />
                                    </Grid.Column>
                                  </Grid.Row>
                                )}
                              </Grid>
                            </Form.Modal>
                          </Fragment>
                        );
                      }}
                    </Formik>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Frozen>
      )}
    </Fragment>
  );
};

export default RequestedAmountFormPartial;
