import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { Form, Paragraph } from "components/materials";
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
import "./FormTimeInput.css";

export function FormTimeInput({
  name,
  label,
  labelProps,
  showError,
  ...props
}) {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        const hasError = !!error && (showError || !!touch);

        return (
          <Form.Field
            className="rbt-form-date-input"
            label={label && <Paragraph {...labelProps}>{label}</Paragraph>}
            validationMessage={hasError && error}
            {...props}
          >
            <TimePicker
              value={field.value}
              onChange={(time) => {
                form
                  .setFieldValue(name, time)
                  .then(() => form.setFieldTouched(name, true));
              }}
              clearIcon={null}
              disableClock
            />
          </Form.Field>
        );
      }}
    </Field>
  );
}

FormTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  labelProps: PropTypes.object,
};

FormTimeInput.defaultProps = {
  labelProps: {},
};
