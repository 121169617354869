import PropTypes from "prop-types";
import analytics from "helpers/analytics";
import { useLazyQuery } from "@apollo/react-hooks";
import { Route } from "react-router-dom";
import { BorrowerAppLayout } from "components/templates";
import { getRecentProjectIds } from "helpers/localStorage";
import {
  BORROWER_APP_LAYOUT_QUERY,
  RECENT_BORROWER_PROJECTS_QUERY,
} from "./graphql";
import { useAuth } from "../../../Auth";

function App({ children }) {
  const [getQuery, { called, data, loading }] = useLazyQuery(
    BORROWER_APP_LAYOUT_QUERY,
    {
      onCompleted: () => {
        // Load FullStory
        analytics.initializeFullStory({
          email: data.borrower.email,
          name: data.borrower.name,
          borrowerId: data.borrower.id,
          organization: data.borrower.lender.name,
        });
      },
    }
  );
  const auth = useAuth();
  if (!called && auth.isAuthenticated()) {
    getQuery();
  }

  const [getRecentProjectsQuery, recentProjectsQuery] = useLazyQuery(
    RECENT_BORROWER_PROJECTS_QUERY,
    {
      variables: {
        ids: getRecentProjectIds(),
      },
    }
  );

  if (loading) return null;

  return (
    <BorrowerAppLayout
      recentProjectsQuery={recentProjectsQuery}
      fetchRecentProjects={getRecentProjectsQuery}
      userEmail={data?.borrower?.email}
      organizations={data?.borrower?.lender ? [data.borrower.lender] : []}
    >
      {children}
    </BorrowerAppLayout>
  );
}

export function BorrowerAppLayoutPage({ children }) {
  return <Route render={() => <App>{children}</App>} />;
}

BorrowerAppLayoutPage.propTypes = {
  children: PropTypes.node.isRequired,
};
