import { useState, useContext, Fragment } from "react";
import { v4 as uuid } from "uuid";
import { DownloadIcon } from "evergreen-ui";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { budgetTemplate } from "images";
import { get, has, isNumber, toString } from "lodash";
import { BudgetDropzone } from "components/templates";
import { Button, DownloadLink, Pane, Pill, Text } from "components/materials";
import t from "helpers/translate";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale, ThemeContext, toaster } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import isBlank from "helpers/isBlank";
import { PERMISSION_ACTION } from "helpers/enums";
import formatConstant from "helpers/formatConstant";
import { STEPS } from "./utils";
import { Header } from "./Header";

export const MASTER_FORMAT_DIVISION_VALUES = {
  "general requirement": "GENERAL_REQUIREMENT",
  "existing conditions": "EXISTING_CONDITIONS",
  concrete: "CONCRETE",
  masonry: "MASONRY",
  metals: "METALS",
  "wood and plastics": "WOOD_AND_PLASTICS",
  "thermal and moisture protection": "THERMAL_AND_MOISTURE_PROTECTION",
  "doors and windows": "DOORS_AND_WINDOWS",
  finishes: "FINISHES",
  specialties: "SPECIALTIES",
  equipment: "EQUIPMENT",
  furnishings: "FURNISHINGS",
  "special construction": "SPECIAL_CONSTRUCTION",
  "conveying systems": "CONVEYING_SYSTEMS",
  "mechanical plumbing": "MECHANICAL_PLUMBING",
  electrical: "ELECTRICAL",
};

export function StepDescription({
  action,
  descriptionBody,
  descriptionHeader,
  stepNumber,
  theme,
}) {
  return (
    <Pane
      alignItems="center"
      borderBottom="muted"
      display="flex"
      paddingY={majorScale(3)}
    >
      <Pane display="flex" justifyContent="center" width="10%">
        <Pill>{stepNumber}</Pill>
      </Pane>
      <Pane display="flex" flexDirection="column" width="65%">
        <Text fontWeight={theme.fontWeights.MEDIUM} fontSize={16}>
          {descriptionHeader}
        </Text>
        <Text>{descriptionBody}</Text>
      </Pane>
      <Pane display="flex" justifyContent="center" width="25%">
        {action}
      </Pane>
    </Pane>
  );
}

function errorToast() {
  toaster.danger(
    "There was an error uploading your budget. Please try again.",
    {
      duration: 2.5,
    }
  );
}

function getCellAmount(budgetAmount) {
  const amount = get(budgetAmount, "result") || budgetAmount;
  if (isNumber(amount)) {
    return formatCurrency(amount);
  }
  return formatCurrency(0);
}

function getCellValue(parsedData) {
  if (has(parsedData, "richText")) {
    return parsedData.richText.reduce(
      (output, richTextItem) => output.concat(richTextItem.text),
      ""
    );
  }
  return get(parsedData, "result") || parsedData;
}

function getLineItemNumber({ row, cell }) {
  const parsedNumber = getCellValue(row.values[cell]);

  const trimmedNumber =
    typeof parsedNumber === "string" ? parsedNumber.trim() : parsedNumber;
  if (trimmedNumber === "<Line Item Number>") return null;
  return isBlank(trimmedNumber) ? null : toString(trimmedNumber);
}

function parseCategory(category) {
  const categoryText = getCellValue(category);
  return categoryText ? categoryText.replace(/\//g, " ") : "";
}

function parseMasterFormatDivision(division) {
  if (isBlank(division) || typeof division !== "string") return null;

  // remove forward slashes that came in from the spreadsheet template...
  division = division.replace(/\//g, " ");
  const foundKey = Object.keys(MASTER_FORMAT_DIVISION_VALUES).find((key) =>
    division.toLowerCase().includes(key)
  );
  return foundKey ? MASTER_FORMAT_DIVISION_VALUES[foundKey] : null;
}

function parseRowValues({ row, hasMasterFormatDivision, actualColumnCount }) {
  /**
   * actualColumnCount will vary based on which features are enabled for a given client
   * see Foreman for feature flags assigned to the user
   */

  if (actualColumnCount === 5) {
    const masterFormatDivision = getCellValue(row.values[2]);
    return {
      divisionName: getCellValue(row.values[1]).trim(),
      masterFormatDivision:
        hasMasterFormatDivision && masterFormatDivision
          ? parseMasterFormatDivision(masterFormatDivision)
          : null,
      number: getLineItemNumber({ row, cell: 3 }),
      lineItemName: getCellValue(row.values[4]).trim(),
      amount: getCellAmount(row.values[5]),
    };
  }

  if (actualColumnCount === 4) {
    return {
      divisionName: getCellValue(row.values[1]).trim(),
      masterFormatDivision: null,
      number: getLineItemNumber({ row, cell: 2 }),
      lineItemName: getCellValue(row.values[3]).trim(),
      amount: getCellAmount(row.values[4]),
    };
  }

  return {
    divisionName: getCellValue(row.values[1]).trim(),
    number: getLineItemNumber({ row, cell: 2 }),
    lineItemName: getCellValue(row.values[3]).trim(),
    superLineItem: getCellValue(row.values[4]),
    originalBudgetedAmount: getCellAmount(row.values[5]),
    amount: getCellAmount(row.values[5]),
    lineItemType: getCellValue(row.values[6]),
    category: parseCategory(row.values[7]),
    retainagePercentage: getCellValue(row.values[8]),
    masterFormatDivision: parseMasterFormatDivision(
      getCellValue(row.values[9])
    ),
  };
}

export function UploadTemplateStep({ navigateToStep, updateOnboardingData }) {
  const [templateDownloaded, setTemplateDownloaded] = useState(false);
  const [uploadedTemplate, setUploadedTemplate] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);

  const hasMasterFormatDivision = hasPermission(
    PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING
  );

  const onAdd = (file) => {
    if (file.length > 1) {
      errorToast();
    } else {
      const uploadFile = file[0];
      const wb = new ExcelJS.Workbook();
      const reader = new FileReader();

      setUploadedTemplate(uploadFile);
      setUploadLoading(true);

      reader.readAsArrayBuffer(uploadFile);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx
          .load(buffer)
          .then((workbook) => {
            const { actualColumnCount } = wb.worksheets[0];
            const divisions = {};
            let currentDivision = {};

            wb.worksheets[0].eachRow((row, rowIndex) => {
              if (rowIndex === 1) return;

              const {
                divisionName,
                lineItemName,
                superLineItem,
                category,
                originalBudgetedAmount,
                amount,
                lineItemType,
                retainagePercentage,
                number,
                masterFormatDivision,
              } = parseRowValues({
                row,
                hasMasterFormatDivision,
                actualColumnCount,
              });

              if (!!divisionName && divisionName !== currentDivision.name) {
                currentDivision = divisions[divisionName] || {
                  id: uuid(),
                  name: divisionName,
                  lineItems: [],
                };
              }

              currentDivision.lineItems = [
                ...currentDivision.lineItems,
                {
                  id: uuid(),
                  name: lineItemName,
                  superLineItem,
                  lineItemCategories: [formatConstant(category)],
                  originalBudgetedAmount,
                  amount,
                  lineItemType,
                  retainagePercentage,
                  number,
                  masterFormatDivision,
                },
              ];

              divisions[currentDivision.name] = currentDivision;
            });

            updateOnboardingData({
              budget: { divisions: Object.values(divisions) },
            }).then(() => navigateToStep(STEPS.ENTER_BUDGET));
          })
          .catch(() => {
            setUploadLoading(false);
            setUploadedTemplate(null);
            errorToast();
          });
      };
    }
  };

  return (
    <Fragment>
      <Header
        header={t("onboardingWizard.uploadTemplateStep.header")}
        subheader={t("onboardingWizard.uploadTemplateStep.subheader")}
      />
      <Pane
        border="muted"
        borderRadius={majorScale(1)}
        marginBottom={majorScale(3)}
      >
        <StepDescription
          action={
            <DownloadLink
              url={budgetTemplate}
              filename="Rabbet Budget Template.xlsx"
              purpose="download budget-template"
              forceFilename
              hideSpinner
              label={
                <Button
                  appearance={templateDownloaded ? "default" : "primary"}
                  iconBefore={DownloadIcon}
                  purpose="project-wizard budget-template download"
                  onClick={() => {
                    setTemplateDownloaded(true);
                  }}
                >
                  Download Template
                </Button>
              }
            />
          }
          descriptionBody={t(
            "onboardingWizard.uploadTemplateStep.downloadTemplateDescription"
          )}
          descriptionHeader={t(
            "onboardingWizard.uploadTemplateStep.downloadTemplateHeader"
          )}
          stepNumber="1"
          theme={theme}
        />
        <StepDescription
          descriptionBody={t(
            "onboardingWizard.uploadTemplateStep.fillTemplateDescription"
          )}
          descriptionHeader={t(
            "onboardingWizard.uploadTemplateStep.fillTemplateHeader"
          )}
          stepNumber="2"
          theme={theme}
        />
      </Pane>
      <BudgetDropzone
        buttonAppearance={templateDownloaded ? "primary" : "default"}
        dropzoneStyling={{
          padding: majorScale(3),
          marginBottom: majorScale(3),
        }}
        header={
          <Text fontSize={16} fontWeight={theme.fontWeights.MEDIUM}>
            {t("onboardingWizard.uploadTemplateStep.dropzoneHeader")}
          </Text>
        }
        loading={uploadLoading}
        onAdd={onAdd}
        photoPaneStyling={{ width: "25%" }}
        textPaneStyling={{ width: "75%" }}
        uploadedTemplate={uploadedTemplate}
      />
    </Fragment>
  );
}
