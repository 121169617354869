import { useState, Fragment } from "react";
import { Button, Form, Modal, Pane, Paragraph } from "components/materials";
import { first, get, some, partition } from "lodash";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { loader } from "images";
import analytics from "helpers/analytics";

function getRecentResult(integration) {
  return first(get(integration, "results", []));
}

function hasRecentSuccess(integration) {
  const recentResult = getRecentResult(integration);
  return get(recentResult, "code") === "200";
}

function hasError(results) {
  return some(results, (integration) => !hasRecentSuccess(integration));
}

function getModalTitle({ hasPosted, results }) {
  if (hasPosted && results) {
    if (hasError(results)) return "Error";
    return "Success";
  }
  if (hasPosted) return "Posting";
  return "Post Payment";
}

function SuccessMessage({ successes }) {
  return (
    <Pane marginBottom={majorScale(4)}>
      <Paragraph fontWeight={500}>
        Payments were successfully posted to the following integrations:
      </Paragraph>
      {successes.map((integration) => (
        <Paragraph paddingLeft={majorScale(2)}>
          {integration.webhook_url}
        </Paragraph>
      ))}
    </Pane>
  );
}

function FailureMessage({ failures }) {
  return (
    <Fragment>
      <Paragraph fontWeight={500}>
        The following integrations did not run successfully:
      </Paragraph>
      {failures.map((integration) => {
        const recentResult = getRecentResult(integration);
        const resultCode = get(recentResult, "code");
        return (
          <Pane marginBottom={majorScale(2)}>
            <Paragraph paddingLeft={majorScale(2)}>
              {integration.webhook_url}
            </Paragraph>
            {resultCode && resultCode !== "0" && (
              <Paragraph paddingLeft={majorScale(4)}>
                Code: {resultCode}
              </Paragraph>
            )}
            <Paragraph paddingLeft={majorScale(4)}>
              Error: {recentResult ? recentResult.message : "Unknown error"}
            </Paragraph>
          </Pane>
        );
      })}
    </Fragment>
  );
}

function PaymentSubmitted({ close, results }) {
  const [successes, failures] = partition(results, (integration) => {
    return hasRecentSuccess(integration);
  });

  return (
    <Pane>
      <Pane>
        {successes.length > 0 && <SuccessMessage successes={successes} />}
        {failures.length > 0 && <FailureMessage failures={failures} />}
      </Pane>
      <Pane textAlign="right" marginTop={majorScale(3)}>
        <Button onClick={close}>Close</Button>
      </Pane>
    </Pane>
  );
}

function PaymentLoading() {
  return (
    <Pane display="flex" justifyContent="center">
      <Pane
        backgroundColor="#ffffff"
        borderRadius="50%"
        padding={majorScale(3)}
      >
        <img src={loader} alt="Loading..." width={52} />
      </Pane>
    </Pane>
  );
}

function PaymentSubmissionForm({ confirmationMessage, includeNote }) {
  return (
    <Pane>
      {confirmationMessage && (
        <Pane marginBottom={majorScale(2)}>{confirmationMessage}</Pane>
      )}
      <Paragraph fontWeight={500}>
        {t("paymentIntegration.confirmPost")}
      </Paragraph>
      {includeNote && (
        <Pane marginTop={majorScale(4)}>
          <Form.TextArea
            height={100}
            name="note"
            label="Note"
            placeholder="Enter a note to be sent with the payment (optional)"
            size={300}
          />
        </Pane>
      )}
    </Pane>
  );
}

function PaymentSubmission({
  confirmationMessage,
  close,
  handlePost,
  includeNote,
  loading,
}) {
  // Button is disabled when loading, instead of `isLoading`
  // because it looks dumb to have 2 spinners next to each other
  return (
    <Pane>
      {loading ? (
        <PaymentLoading />
      ) : (
        <PaymentSubmissionForm
          confirmationMessage={confirmationMessage}
          includeNote={includeNote}
        />
      )}
      <Pane
        textAlign="right"
        marginTop={majorScale(3)}
        marginBottom={majorScale(3)}
      >
        <Button onClick={close} marginRight={majorScale(2)}>
          Cancel
        </Button>
        <Button onClick={handlePost} disabled={loading} appearance="primary">
          Post
        </Button>
      </Pane>
    </Pane>
  );
}

export function PostPaymentModal({
  confirmationMessage,
  errorMessage,
  accountsPayableSystem,
  includeNote,
  loading,
  onClose,
  onPost,
  results,
}) {
  const [hasPosted, setHasPosted] = useState(false);
  return (
    <Modal
      open
      title={getModalTitle({ hasPosted, results })}
      hasFooter={false}
      onCloseComplete={() => onClose(hasPosted)}
      topOffset="24vmin"
      width={600}
    >
      {({ close }) => {
        if (errorMessage) {
          return (
            <Pane>
              <Paragraph fontWeight={500}>{errorMessage}</Paragraph>
            </Pane>
          );
        }

        if (hasPosted && results) {
          return <PaymentSubmitted results={results} close={close} />;
        }

        return (
          <PaymentSubmission
            confirmationMessage={confirmationMessage}
            close={close}
            handlePost={() => {
              setHasPosted(true);
              onPost();
              analytics.track("Post to AP", { accountsPayableSystem });
            }}
            includeNote={includeNote}
            loading={loading}
          />
        );
      }}
    </Modal>
  );
}
