import gql from "graphql-tag";

export default gql`
  fragment FastDocumentFragment on FastDocument {
    adjustedAmount
    agreementAmount
    agreementName
    agreementNumber
    amount
    amountNotAllocatedToAgreements
    amountFullyAllocatedToAgreements
    amountPaid
    assignedUser
    date
    datePaid
    description
    divisionNames
    draw
    drawId
    errors
    fileContentType
    grossAmount
    hasAdjustments
    hasComments
    insertedAt
    isBackup
    issues {
      id
      documentId
      lineItemId
      name
      severity
    }
    jobCostCodes
    lineItemNumbers
    lineItems
    isPaid
    importSource
    importSourceMetadata {
      id
      yardiStatus
      lastYardiSync
    }
    number
    originalFileName
    pages
    parentToBeSplit
    postedAt
    processingFinishedAt
    projectCustomId
    projectId
    projectName
    recentApprovalReviews {
      id
      userId
      userName
      reviewType
      insertedAt
    }
    state
    storedMaterialsAmount
    superLineItems
    type
    uploadedBy
    vendorCostCode
    vendorName
    wasEmailImported
  }
`;
