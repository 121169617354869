import { formatCurrency } from "helpers/formatCurrency";
import { Banner } from "components/materials";
import { subtract, sumBy } from "helpers/math";
import { flatten } from "lodash";

export function FundingSourceDisbursedWarningBanner({ project }) {
  const requestedTotal = project?.inProgressAmount || 0;
  const fundedTotal = sumBy(
    flatten(project?.fundingSourceGroups),
    "disbursedAmount"
  );

  const differenceAmount = subtract(fundedTotal, requestedTotal);

  if (differenceAmount === 0) {
    return null;
  }

  const bannerText = `The funding sources disbursed on this project are ${formatCurrency(
    Math.abs(differenceAmount)
  )} ${
    differenceAmount > 0 ? "more" : "less"
  } than the amount requested on the budget.`;

  return <Banner border icon="warning-sign" mainText={bannerText} />;
}
