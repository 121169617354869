import { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { AddIcon, DoughnutChartIcon } from "evergreen-ui";
import { get } from "lodash";
import { Link, Shortener, Paragraph, Text } from "components/materials";
import { PortfolioProjectCardContent } from "components/templates";
import { UserContext } from "helpers/behaviors";
import { formatTrimDate } from "helpers/dateHelpers";
import { DRAW_STATE, PERMISSION_ACTION } from "helpers/enums";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import t from "helpers/translate";
import { minorScale } from "helpers/utilities";

export function PortfolioProjectCard({ project }) {
  const history = useHistory();
  const { hasPermission } = useContext(UserContext);
  return (
    <PortfolioProjectCardContent
      onClick={() => history.push(`/projects/${project.id}`)}
      project={project}
    >
      <DrawCallToAction hasPermission={hasPermission} project={project} />
    </PortfolioProjectCardContent>
  );
}

function getDrawStateDateVariable(drawState) {
  switch (drawState) {
    case DRAW_STATE.STARTED:
      return "createdAt";
    case DRAW_STATE.RECEIVED:
      return "receivedDate";
    case DRAW_STATE.SUBMITTED:
      return "submittedDate";
    case DRAW_STATE.PENDING_RESUBMISSION:
    case DRAW_STATE.RECEIVED_AND_CHANGED:
      return "updatedAt";
    case DRAW_STATE.FUNDED:
      return "fundedDate";
    default:
      return undefined;
  }
}

export function getDrawStateDate(draw) {
  const { state, recentStateUpdate } = draw;
  if (state === recentStateUpdate?.state) {
    return recentStateUpdate.date;
  }

  return get(draw, getDrawStateDateVariable(state));
}

export function getActiveDrawInformation(draw) {
  const date = getDrawStateDate(draw);

  return `${draw.name} - ${t(
    `developerDashboard.unfundedDrawStates.${draw.state}`
  )}${date ? ` on ${formatTrimDate(date)}` : ""}`;
}

export function DrawCallToAction({ hasPermission, project }) {
  const props = {};
  if (!project.setupComplete) {
    props.color = "selected";
    props.icon = "doughnutChart";
    props.link = "Setup in Progress";
    props.to = `/projects/${project.id}`;
  } else if (
    !project.recentUnfundedDraw &&
    !hasPermission(PERMISSION_ACTION.CREATE_DRAW, project.organization)
  ) {
    props.color = "muted";
    props.text = "No Active Draws";
  } else if (!project.recentUnfundedDraw) {
    props.color = "info";
    props.icon = "add";
    props.link = "Create New Draw";
    props.to = `/projects/${project.id}/draws/new`;
  } else {
    props.link = getActiveDrawInformation(project.recentUnfundedDraw);
    props.to = `/projects/${project.id}/draws/${project.recentUnfundedDraw.id}`;
  }

  function getIcon(icon) {
    if (icon === "doughnut-chart") {
      return (
        <DoughnutChartIcon
          color={props.color}
          size={12}
          marginRight={minorScale(1)}
        />
      );
    }
    return (
      <AddIcon color={props.color} size={12} marginRight={minorScale(1)} />
    );
  }

  return (
    <Paragraph
      lineHeight={1.0}
      marginTop={minorScale(1)}
      alignItems="center"
      onClick={preventEventBubbling}
    >
      {props.link && (
        <Link
          purpose="dashboard draw-call-to-action"
          fontSize={12}
          to={props.to}
          marginRight={3}
          display="inline-flex"
          alignItems="center"
        >
          {props.icon && getIcon(props.icon)}
          <Shortener isLink limit={42} text={props.link} />
        </Link>
      )}
      {props.text && (
        <Text lineHeight={1} size={300}>
          {props.text}
        </Text>
      )}
    </Paragraph>
  );
}

PortfolioProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
};
