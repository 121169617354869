import { Confirm, Divider, Modal } from "components/materials";
import { get, find } from "lodash";
import t from "helpers/translate";
import humanReadableList from "helpers/humanReadableList";

export const ERROR_MESSAGE = "Line items have insufficient funds";

export function InsufficientFundsWarningModal({ onProceed, result }) {
  const error = find(get(result, "error.graphQLErrors", []), [
    "message",
    ERROR_MESSAGE,
  ]);

  if (!error) return null;

  const lineItems = get(error, "line_items", []);
  const fundingSources = get(error, "funding_sources", []);
  const lineItemNames = lineItems.map(({ name }) => name);
  const fundingSourceNames = fundingSources.map(({ label }) => label);

  return (
    <Confirm
      content={
        <Modal.Content>
          {t("fundingSources.insufficientFundsWarning1", {
            count: lineItemNames.length,
            lineItemNames: humanReadableList(lineItemNames),
          })}
          <Divider />
          {t("fundingSources.insufficientFundsWarning2", {
            sourceCount: fundingSources.length,
            fundingSourceNames,
          })}
        </Modal.Content>
      }
      header="Insufficient Funds"
      onConfirm={onProceed}
      open
      cancelLabel="Cancel"
      confirmLabel="Ignore and continue"
    />
  );
}
