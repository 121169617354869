import { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  Button,
  Divider,
  ListItem,
  Modal,
  Pane,
  Text,
  UnorderedList,
} from "components/materials";
import { DRAW_STATE, REVIEW_STATE } from "helpers/enums";
import isRecentDraw from "helpers/isRecentDraw";
import { get } from "lodash";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

const QUERY = gql`
  query FreezeConfirmModalQuery(
    $projectId: String!
    $targetId: String!
    $targetIsDraw: Boolean!
  ) {
    project(id: $projectId) {
      id
      recentDraw {
        id
      }
      draw(id: $targetId) @include(if: $targetIsDraw) {
        id
        reviews {
          id
          state
          reviewer {
            id
            fullName
          }
        }
        state
      }
      document(id: $targetId) @skip(if: $targetIsDraw) {
        id
        approvedBy {
          id
          fullName
        }
        reviews {
          id
          state
          reviewer {
            id
            fullName
          }
        }
        showPostedWarning
      }
    }
  }
`;

// todo rusty - revisit this for document approvals v3
const getReviewers = (data) => {
  const drawReviewers = get(data, "project.draw.reviews", [])
    .filter(({ state }) => state === REVIEW_STATE.APPROVED)
    .map(({ reviewer }) => reviewer);
  // If a tiered review exists, use that. Else check for legacy document reviews.
  const documentReviewer = get(data, "project.document.approvedBy");
  const documentReviewers = documentReviewer
    ? [documentReviewer]
    : get(data, "project.document.reviews", [])
        .filter(({ state }) => state === REVIEW_STATE.APPROVED)
        .map(({ reviewer }) => reviewer);

  return drawReviewers.length > 0 ? drawReviewers : documentReviewers;
};

function getConfirmTitleState(drawState, showPostedWarning, reviewers) {
  if (showPostedWarning && reviewers.length > 0) return "postedAndApproved";
  if (showPostedWarning) return "posted";
  if ([DRAW_STATE.SUBMITTED, DRAW_STATE.FUNDED].indexOf(drawState) >= 0)
    return drawState;
  return "approved";
}

export function FreezeConfirmModal({
  loading: mutationLoading,
  onCancel,
  onConfirm,
  open,
  projectId,
  targetId,
  targetType,
}) {
  const { data, loading } = useQuery(QUERY, {
    variables: {
      projectId,
      targetId,
      targetIsDraw: targetType === "draw",
    },
    skip: !open,
  });

  if (loading || !open) return null;

  const drawState = get(data, "project.draw.state", "");
  const showPostedWarning = get(data, "project.document.showPostedWarning");
  const isMarkedFunded = drawState === DRAW_STATE.FUNDED;
  const isImportedDraw = drawState === DRAW_STATE.RECEIVED;
  const reviewers = getReviewers(data);
  const confirmTitleState = getConfirmTitleState(
    drawState,
    showPostedWarning,
    reviewers
  );

  return (
    <Modal
      hideViewer
      open={open}
      onClose={onCancel}
      size="small"
      title={
        isImportedDraw
          ? t("frozenWarning.confirmImportTitle")
          : t(`frozenWarning.confirmTitle.${confirmTitleState}`, {
              targetType,
            })
      }
    >
      <Modal.Content>
        {targetType === "draw" && !isMarkedFunded && (
          <Fragment>
            {isImportedDraw ? (
              <Text>{t("frozenWarning.confirmImportMessage")}</Text>
            ) : (
              <Text>{t("frozenWarning.confirmMessage1")}</Text>
            )}
            <Divider height={majorScale(1)} />
          </Fragment>
        )}
        {isMarkedFunded && (
          <Fragment>
            <Text>
              {t("frozenWarning.confirmMessageLockedDrawIntro", {
                drawState: "Funded",
              })}
            </Text>
            <Divider height={majorScale(1)} />
            <Text>
              {isRecentDraw(data)
                ? t("frozenWarning.confirmMessageLockedDrawPrompt", {
                    drawState: "Funded",
                  })
                : t("frozenWarning.confirmMessageLockedPastFundedDrawPrompt")}
            </Text>
            <Divider height={majorScale(1)} />
          </Fragment>
        )}
        {reviewers.length > 0 && (
          <Fragment>
            <Text>{t("frozenWarning.confirmMessage2", { targetType })}</Text>
            <Divider height={majorScale(1)} />
            <UnorderedList listStyleType="none">
              {reviewers.map(({ id, fullName }) => (
                <ListItem key={id}>{fullName}</ListItem>
              ))}
            </UnorderedList>
            <Divider height={majorScale(1)} />
            <Text>{t("frozenWarning.confirmMessage3")}</Text>
          </Fragment>
        )}
        {showPostedWarning && (
          <Fragment>
            <Divider height={majorScale(1)} />
            <Text>{t("frozenWarning.confirmMessage4")}</Text>
          </Fragment>
        )}
        <Divider height={majorScale(1)} />
        <Text>{t("frozenWarning.confirmMessage5")}</Text>
      </Modal.Content>
      <Modal.Actions>
        <Pane display="flex" justifyContent="flex-end">
          <Button
            content="Cancel"
            disabled={mutationLoading}
            onClick={onCancel}
            marginRight={majorScale(1)}
            purpose="freeze-confirm cancel"
          />
          <Button
            appearance="primary"
            content="OK"
            disabled={mutationLoading}
            isLoading={mutationLoading}
            onClick={onConfirm}
            purpose="freeze-confirm confirm"
          />
        </Pane>
      </Modal.Actions>
    </Modal>
  );
}

FreezeConfirmModal.propTypes = {
  projectId: PropTypes.string.isRequired,
  targetId: PropTypes.string,
  targetType: PropTypes.string,
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
