import { Fragment, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { Pane, Form } from "components/materials";
import { VendorFormPartial } from "components/templates";
import t from "helpers/translate";
import { get, omit } from "lodash";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { PROJECT_VENDOR_SEARCH_QUERY } from "helpers/queries";
import { majorScale } from "helpers/utilities";
import { dateFormToServer } from "helpers/dateHelpers";
import DocumentReviewLayout from "./DocumentReviewLayout";

const getDocumentDataFromForm = ({ expirationDate, target }) => {
  return {
    expirationDate: dateFormToServer(expirationDate),
    payApplicationId: target.id,
  };
};

const getTarget = (document) => {
  const id = get(document, "payApplicationId");

  if (!id) return { id: null, type: null };
  return {
    id,
    type: DOCUMENT_TYPE_NAME.PAY_APPLICATION,
  };
};

const getInitialValues = ({ document, selectedDraw, selectedType }) => {
  const {
    comments,
    dismissedWarnings,
    documentReviewActivity,
    reviews,
    ...restOfDocument
  } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);
  const target = getTarget(document);
  return {
    ...documentProperties,
    draw: selectedDraw,
    type: selectedType,
    target,
  };
};

const getDocumentOptions = (documents) => {
  return documents.map((document) => {
    return {
      key: document.id,
      value: { id: document.id, type: document.type },
      text: document.amount
        ? `${t(`documentTypeName.${document.type}`)} - ${
            document.file.name
          } - ${document.amount}`
        : `${t(`documentTypeName.${document.type}`)} - ${document.file.name}`,
    };
  });
};

function InsuranceCertificateReview(props) {
  const { form: formikProps, projectId, selectedDraw, vendors } = props;

  const [getProjectVendorSearchQuery, projectVendorSearchQuery] = useLazyQuery(
    PROJECT_VENDOR_SEARCH_QUERY
  );
  const [newlyAddedVendors, setNewlyAddedVendors] = useState([]);

  const searchedVendors = get(
    projectVendorSearchQuery,
    "data.project.organization.paginatedVendors.results",
    []
  );

  return (
    <DocumentReviewLayout
      meta={(content) => (
        <Pane display="flex" width="100%" alignItems="center">
          {content}
        </Pane>
      )}
      information={() => (
        <Pane display="flex" width="100%">
          <Form.Group>
            <Pane width={400}>
              <VendorFormPartial
                formikProps={formikProps}
                getProjectVendorSearchQuery={getProjectVendorSearchQuery}
                initialVendors={vendors}
                newlyAddedVendors={newlyAddedVendors}
                projectId={projectId}
                searchedVendors={searchedVendors}
                setNewlyAddedVendors={setNewlyAddedVendors}
              />
            </Pane>
            <Pane marginLeft={majorScale(2)}>
              <Form.DateInput
                label="Expiration Date"
                name="expirationDate"
                popperPlacement="bottom-end"
              />
            </Pane>
          </Form.Group>
        </Pane>
      )}
      {...props}
    >
      <Fragment>
        <Form.Section
          heading="Assign to Pay Application"
          isCollapsible
          isCollapsed={props.collapsedPanels.includes("assignedTo")}
          onCollapse={() => props.togglePanel("assignedTo")}
        >
          <Pane display="flex" width="100%">
            <Form.Select
              label="Assign to"
              name="target"
              noNull
              options={
                selectedDraw
                  ? getDocumentOptions(
                      selectedDraw.documents.filter(
                        (document) =>
                          document.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION
                      )
                    )
                  : []
              }
            />
          </Pane>
        </Form.Section>
      </Fragment>
    </DocumentReviewLayout>
  );
}

InsuranceCertificateReview.propTypes = {
  children: PropTypes.node,
  selectedDraw: PropTypes.object,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
    payApplicationId: PropTypes.string,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      payApplicationId: PropTypes.string,
      target: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
  onReview: PropTypes.func,
};

// TODO: remove dot notation
InsuranceCertificateReview.getInitialValues = getInitialValues;
InsuranceCertificateReview.getDocumentDataFromForm = getDocumentDataFromForm;

export default InsuranceCertificateReview;
