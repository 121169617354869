import { useContext } from "react";
import { Link, Route } from "react-router-dom";
import { ErrorIcon, WarningSignIcon } from "evergreen-ui";
import { Button, Heading, Pane, Portal, Text } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { css } from "glamor";
import { logotype } from "images";

const RedesignLayout = (props) => (
  <Pane
    backgroundColor="#ffffff"
    display="flex"
    flexDirection="column"
    flexWrap="nowrap"
    height="100vh"
    width="100vw"
    minWidth="1000px"
    {...props}
  />
);

const RedesignLayoutNav = ({ children, isFallback, orgName, ...props }) => {
  const theme = useContext(ThemeContext);
  const { primaryLogo: cobrandedLogo } = theme;
  const { headerColor } = theme.defaultColors;

  const logoNavigationProps = isFallback
    ? { is: "a", href: "/" }
    : { is: Link, to: "/" };

  return (
    <Pane
      alignItems="center"
      backgroundColor={headerColor}
      display="flex"
      elevation={2}
      flexGrow="0"
      flexShrink="0"
      flexWrap="nowrap"
      height={68}
      is="nav"
      padding={18}
      role="navigation"
      zIndex="3"
      {...props}
    >
      <Heading
        flexGrow="0"
        flexShrink="0"
        height="1em"
        marginX={majorScale(4)}
        marginY={minorScale(1)}
        size={700}
        {...logoNavigationProps}
      >
        <img
          alt={cobrandedLogo ? orgName : "Rabbet"}
          src={cobrandedLogo || logotype}
          style={{ height: "100%" }}
        />
      </Heading>
      {children}
    </Pane>
  );
};

const RedesignLayoutSubNav = (props) => (
  <Pane
    alignItems="center"
    display="flex"
    elevation={1}
    flexGrow="0"
    flexShrink="0"
    flexWrap="nowrap"
    is="nav"
    paddingLeft={majorScale(5)}
    paddingRight={majorScale(3)}
    role="navigation"
    zIndex="2"
    {...props}
  />
);

const RedesignLayoutContent = (props) => (
  <Pane flexGrow="1" flexShrink="1" overflowY="auto" zIndex="1" {...props} />
);

const RedesignLayoutPanel = (props) => (
  <Portal>
    <Pane
      backgroundColor="#ffffff"
      position="fixed"
      top={68}
      bottom={0}
      left={0}
      right={0}
      zIndex="4"
      {...props}
    />
  </Portal>
);

const RedesignLayoutSpacer = () => <Pane flexGrow="1" flexShrink="1" />;

const RedesignLayoutLink = ({ exact, to, ...rest }) => {
  const theme = useContext(ThemeContext);
  const {
    headerButtonTextColor,
    headerLinkActive,
    headerLinkActiveHover,
    headerLinkInactiveHover,
  } = theme.defaultColors;

  return (
    <Route exact={exact} path={to}>
      {({ match }) => (
        <Button
          appearance="minimal"
          color={headerButtonTextColor}
          fontSize={12}
          fontWeight={match ? 800 : 600}
          id="navLink"
          is={Link}
          isActive={!!match}
          marginX={majorScale(1)}
          style={match && { backgroundColor: headerLinkActive }}
          to={to}
          {...css({
            "&#navLink:hover": {
              backgroundColor: match
                ? headerLinkActiveHover
                : headerLinkInactiveHover,
            },
          })}
          {...rest}
        />
      )}
    </Route>
  );
};

const RedesignLayoutTab = ({
  color: iconColor,
  children,
  exact,
  icon,
  testId,
  to,
  colorOverrides = {},
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const colors = { ...theme.defaultColors, ...colorOverrides };
  const {
    primaryColor,
    navigationSelectedText,
    navigationInactiveText,
    navigationInactiveBorder,
  } = colors;

  return (
    <Route exact={exact} path={to}>
      {({ match }) => (
        <Pane
          alignSelf="stretch"
          borderBottomColor={match ? primaryColor : "transparent"}
          borderBottomStyle="solid"
          borderBottomWidth={minorScale(1)}
          boxSizing="border-box"
          display="flex"
          textDecoration="none"
          is={Link}
          to={to}
          marginTop={minorScale(1)}
          marginX={majorScale(1)}
          {...css({
            ":hover": {
              borderBottomColor: match
                ? primaryColor
                : navigationInactiveBorder,
              color: primaryColor,
            },
          })}
          {...rest}
        >
          <Pane
            display="flex"
            alignItems="center"
            paddingX={majorScale(1)}
            paddingY={majorScale(2)}
          >
            <Text
              color={match ? navigationSelectedText : navigationInactiveText}
              fontSize={14}
              fontWeight={match ? 800 : 600}
              lineHeight={1.2}
              textAlign="center"
              data-testid={testId}
            >
              {children}
            </Text>
            {/* The pane below prevents icon resizing */}
            {icon && (
              <Pane width={majorScale(2)}>
                {icon === "error" ? (
                  <ErrorIcon
                    color="danger"
                    size={12}
                    marginLeft={minorScale(1)}
                  />
                ) : (
                  <WarningSignIcon
                    color="warning"
                    size={12}
                    marginLeft={minorScale(1)}
                  />
                )}
              </Pane>
            )}
          </Pane>
        </Pane>
      )}
    </Route>
  );
};

// TODO: remove dot notation
RedesignLayout.Nav = RedesignLayoutNav;
RedesignLayout.SubNav = RedesignLayoutSubNav;
RedesignLayout.Content = RedesignLayoutContent;
RedesignLayout.Panel = RedesignLayoutPanel;
RedesignLayout.Spacer = RedesignLayoutSpacer;
RedesignLayout.Link = RedesignLayoutLink;
RedesignLayout.Tab = RedesignLayoutTab;

export default RedesignLayout;
