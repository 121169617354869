import ReactMaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { TextInput } from "components/materials";

const MASKS = {
  tel: {
    mask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
  number: {
    mask: createNumberMask({
      prefix: "",
      allowNegative: true,
      allowDecimal: true,
      decimalLimit: 100,
    }),
  },
  zip: {
    mask: (value) => {
      // US zip code; however, alphanumeric entries are allowed here
      // since the Canadian postal code is 6 characters long
      if ((value.match(/[a-zA-Z0-9]/g) || []).length < 6) {
        return [
          /[a-zA-Z0-9]/,
          /[a-zA-Z0-9]/,
          /[a-zA-Z0-9]/,
          /[a-zA-Z0-9]/,
          /[a-zA-Z0-9]/,
        ];
      }

      // Canadian postal code
      if ((value.match(/[a-zA-Z0-9]/g) || []).length === 6) {
        return [
          /[a-zA-Z0-9]/,
          /[a-zA-Z0-9]/,
          /[a-zA-Z0-9]/,
          " ",
          /[a-zA-Z0-9]/,
          /[a-zA-Z0-9]/,
          /[a-zA-Z0-9]/,
        ];
      }

      // US zip code
      return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
    },
  },
  integer: {
    mask: createNumberMask({
      prefix: "",
      allowNegative: false,
      allowDecimal: false,
    }),
  },
  integerNegativeAllowed: {
    mask: createNumberMask({
      prefix: "",
      allowNegative: true,
      allowDecimal: false,
    }),
  },
  percentage: {
    mask: createNumberMask({
      prefix: "",
      suffix: "%",
      allowNegative: true,
      allowDecimal: true,
      decimalLimit: 10,
    }),
  },
  currency: {
    mask: createNumberMask({
      prefix: "$",
      allowNegative: true,
      allowDecimal: true,
      allowLeadingZeroes: true,
    }),
  },
};

const MaskedInput = (props) => {
  const inputProps = {
    autoComplete: "new-password",
    width: props.width || "100%",
    ...props,
  };

  return MASKS[props.type] ? (
    <ReactMaskedInput
      {...inputProps}
      {...MASKS[props.type]}
      render={(ref, maskedInputProps) => {
        return (
          <TextInput
            {...maskedInputProps}
            width={inputProps.width}
            ref={ref}
            type="text"
          />
        );
      }}
    />
  ) : (
    <TextInput width="100%" {...inputProps} />
  );
};

export default MaskedInput;
