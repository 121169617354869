import PropTypes from "prop-types";
import { VictoryTheme, VictoryContainer, VictoryPie } from "victory";
import { Text } from "components/materials";
import { BRAND_COLORS, PIE_CHART_COLORS } from "helpers/colors";
import { css } from "glamor";

const theme = VictoryTheme.grayscale;
const backgroundData = [{ y: 100 }];
const backgroundColorScale = [BRAND_COLORS.OFF_WHITE];

function ProgressGauge({
  dataValue,
  height,
  percent,
  labelContent,
  percentToFillGauge = 100,
}) {
  const scaledPercent = (percent / percentToFillGauge) * 100;
  const data = [
    { y: Math.abs(scaledPercent) },
    { y: 100 - Math.abs(scaledPercent) },
  ];
  const splitBarClass = css({
    position: "absolute",
    left: "calc(50% - 2px)",
    top: 0,
    width: "4px",
    height: "24%",
    backgroundColor: PIE_CHART_COLORS.DARK_NEUTRAL,
    zIndex: "3",
  });
  const labelContentClass = css({
    position: "absolute",
    top: "40%",
    left: "40%",
    right: "40%",
    textAlign: "center",
    zIndex: "3",
  });
  const bolderClass = css({ fontWeight: "bolder" });
  const containerClass = css({ height, width: "100%", position: "relative" });

  const colorScale = [
    Math.sign(percent) === -1
      ? PIE_CHART_COLORS.BASE_RED
      : PIE_CHART_COLORS.BASE_GREEN,
    "transparent",
  ];

  const dataContent = dataValue || `${Math.round(percent)}%`;

  return (
    <div {...containerClass}>
      <div {...splitBarClass} />
      <Text {...labelContentClass}>
        <div {...bolderClass}>{dataContent}</div>
        {labelContent}
      </Text>
      <VictoryContainer height={theme.pie.height} width={theme.pie.width}>
        <VictoryPie
          standalone={false}
          theme={theme}
          data={backgroundData}
          colorScale={backgroundColorScale}
          padding={20}
          innerRadius={130}
          labels={() => null}
          animate={false}
          startAngle={-150}
          endAngle={150}
        />
        <VictoryPie
          standalone={false}
          theme={theme}
          data={data}
          colorScale={colorScale}
          padding={20}
          innerRadius={130}
          labels={() => null}
          animate={false}
          startAngle={0}
          endAngle={Math.sign(percent) * 150}
        />
      </VictoryContainer>
    </div>
  );
}

ProgressGauge.propTypes = {
  dataValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  percentToFillGauge: PropTypes.number,
};

export default ProgressGauge;
