import Table from "./Table";
import TableBody from "./Body";
import TableCell from "./Cell";
import TableFoot from "./Foot";
import TableFooterCell from "./FooterCell";
import TableHead from "./Head";
import TableHeaderCell from "./HeaderCell";
import TableRow from "./Row";
import TableSectionHeader from "./SectionHeader";
import TableSectionHeaderCell from "./SectionHeaderCell";
import TableTextCell from "./TextCell";
import TableTextFooterCell from "./TextFooterCell";
import TableTextHeaderCell from "./TextHeaderCell";
import TableTextSectionHeaderCell from "./TextSectionHeaderCell";

// TODO: remove dot notation
Table.Body = TableBody;
Table.Cell = TableCell;
Table.Foot = TableFoot;
Table.FooterCell = TableFooterCell;
Table.Head = TableHead;
Table.HeaderCell = TableHeaderCell;
Table.Row = TableRow;
Table.SectionHeader = TableSectionHeader;
Table.SectionHeaderCell = TableSectionHeaderCell;
Table.TextCell = TableTextCell;
Table.TextFooterCell = TableTextFooterCell;
Table.TextHeaderCell = TableTextHeaderCell;
Table.TextSectionHeaderCell = TableTextSectionHeaderCell;

export default Table;
