import { useMemo, Fragment } from "react";
import { FieldArray } from "formik";
import { find, includes } from "lodash";
import { CrossIcon, HelpIcon } from "evergreen-ui";
import { LineItemFormBlankSlate } from "components/templates";
import {
  Button,
  FastMaskInput,
  Form,
  Heading,
  IconButton,
  Pane,
  Pill,
  Table,
  Tooltip,
} from "components/materials";
import { formatCurrency } from "helpers/formatCurrency";
import { add, subtract } from "helpers/math";
import { majorScale, minorScale } from "helpers/utilities";
import { AGREEMENT_TYPE } from "helpers/enums";
import t from "helpers/translate";
import { CHANGE_ORDER_TYPES, PENDING_CHANGE_ORDER_TYPES } from "./enums";
import { getEmptyAgreementLineItem } from "./AgreementForm";

function getLineItemName({ division, name, number }) {
  const prefix = number ? `(${number}) ` : "";
  return `${prefix}${name} (${division.name})`;
}

function getCurrentContractsAmount(
  lineItem,
  agreementVendorLineItem,
  existingAgreementType
) {
  if (!agreementVendorLineItem) return 0;

  const isSavedPendingChangeOrder = includes(
    PENDING_CHANGE_ORDER_TYPES,
    existingAgreementType
  );
  const isChangedLineItem = lineItem.initialLineItemId !== lineItem.lineItemId;

  const effectiveLineItemAmount =
    isSavedPendingChangeOrder || isChangedLineItem ? 0 : lineItem.initialAmount;

  return subtract(
    agreementVendorLineItem.agreementsAmount,
    effectiveLineItemAmount
  );
}

function AgreementLineItemRow({
  agreementType,
  agreementVendorLineItems,
  disabled,
  existingAgreementType,
  index,
  isChangeOrder,
  isPending,
  lineItem,
  options,
  remove,
  vendorId,
}) {
  const agreementVendorLineItem = find(
    agreementVendorLineItems,
    (avli) => avli.scopeId === vendorId && avli.id === lineItem.lineItemId
  );

  const currentContracts = getCurrentContractsAmount(
    lineItem,
    agreementVendorLineItem,
    existingAgreementType
  );

  const afterChange = add(currentContracts, lineItem.amount);

  return (
    <Table.Row>
      <Table.Cell minWidth={150}>
        <Pane display="flex">
          <IconButton
            appearance="minimal"
            disabled={disabled}
            icon={CrossIcon}
            marginRight={majorScale(1)}
            onClick={() => remove(index)}
            type="button"
          />
          <Form.Select
            disabled={disabled}
            name={`agreementLineItems.${index}.lineItemId`}
            options={options}
          />
        </Pane>
      </Table.Cell>
      <Table.TextCell
        id={`agreementLineItems.${index}.other`}
        minWidth={102}
        width="15%"
      >
        {formatCurrency(currentContracts)}
      </Table.TextCell>
      <Table.Cell state={isPending && "special"} minWidth={102} width="15%">
        <FastMaskInput
          disabled={disabled}
          mask="currency"
          name={`agreementLineItems.${index}.amount`}
        />
      </Table.Cell>
      <Table.TextCell
        id={`agreementLineItems.${index}.total`}
        state={isPending && "special"}
        minWidth={102}
        textAlign="space-between"
        width="15%"
      >
        {formatCurrency(afterChange)}
        {isPending && (
          <Tooltip
            content={t(`agreementsForm.pendingLineItem.${agreementType}`)}
          >
            <HelpIcon
              color="default"
              marginLeft={minorScale(1)}
              size={minorScale(3)}
            />
          </Tooltip>
        )}
      </Table.TextCell>
      {!isChangeOrder && (
        <Table.Cell minWidth={80} width="10%">
          <Form.Input
            name={`agreementLineItems.${index}.retainagePercentage`}
            type="percentage"
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
}

export function AgreementLineItemFormSection({
  form,
  lineItems,
  agreementVendorLineItems,
}) {
  const {
    agreementType,
    agreementLineItems,
    existingAgreementType,
    isArtifact,
  } = form.values;
  const vendorId = form.values.vendor?.id;
  const isECO = agreementType === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER;
  const isPending = includes(
    PENDING_CHANGE_ORDER_TYPES,
    form.values.agreementType
  );
  const isChangeOrder = includes(CHANGE_ORDER_TYPES, agreementType);

  const options = useMemo(
    () =>
      lineItems
        .map((lineItem) => ({
          key: lineItem.id,
          text: getLineItemName(lineItem),
          value: lineItem.id,
        }))
        .concat({ key: "null", text: "(Unassigned)", value: null }),
    [lineItems]
  );

  const contractChangesTotal = agreementLineItems.reduce(
    (total, { amount }) => add(total, amount),
    0
  );

  const hasAgreementLineItems = agreementLineItems.length !== 0;

  const contractChangeTotalText = `Total ${
    isECO ? "Contract" : ""
  } Change: ${formatCurrency(contractChangesTotal)}`;

  const lineItemHeading = isPending
    ? "Potential Contract Changes"
    : "Contract Changes";
  const addLineItemCopy = isECO ? "Add Change Order Amount" : "Add Line Item";

  return (
    <Pane marginBottom={majorScale(2)}>
      <FieldArray name="agreementLineItems">
        {({ push, remove }) => (
          <Fragment>
            <Heading marginBottom={majorScale(1)}>{lineItemHeading}</Heading>
            {!hasAgreementLineItems && !isArtifact ? (
              <LineItemFormBlankSlate
                addButtonText={addLineItemCopy}
                onAdd={() => push(getEmptyAgreementLineItem())}
                text={t("agreementsReview.noLineItems")}
                containerProps={{ backgroundColor: "white", border: "muted" }}
              />
            ) : (
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.TextHeaderCell>Line Item(s)</Table.TextHeaderCell>
                    <Table.TextHeaderCell>
                      Current Contract Amount
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell>Change(s)</Table.TextHeaderCell>
                    <Table.TextHeaderCell>
                      Contract Amount after Change
                    </Table.TextHeaderCell>
                    {!isChangeOrder && (
                      <Table.TextHeaderCell minWidth={100}>
                        Expected Retainage %
                      </Table.TextHeaderCell>
                    )}
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {agreementLineItems.map((lineItem, index) => (
                    <AgreementLineItemRow
                      agreementType={agreementType}
                      agreementVendorLineItems={agreementVendorLineItems}
                      disabled={isArtifact}
                      existingAgreementType={existingAgreementType}
                      key={lineItem.lineItemId || index}
                      index={index}
                      isChangeOrder={isChangeOrder}
                      isPending={isPending}
                      lineItem={lineItem}
                      options={options.filter(
                        (option) =>
                          option.value === lineItem.lineItemId ||
                          !form.values.agreementLineItems.some(
                            (ali) => ali.lineItemId === option.value
                          )
                      )}
                      remove={remove}
                      vendorId={vendorId}
                    />
                  ))}
                </Table.Body>
              </Table>
            )}
            <Pane
              alignItems="center"
              display="flex"
              justifyContent={isArtifact ? "flex-end" : "space-between"}
              marginTop={majorScale(1)}
            >
              {!isArtifact && hasAgreementLineItems && (
                <Button onClick={() => push(getEmptyAgreementLineItem())}>
                  {addLineItemCopy}
                </Button>
              )}
              {hasAgreementLineItems && (
                <Pill textTransform="none">{contractChangeTotalText}</Pill>
              )}
            </Pane>
          </Fragment>
        )}
      </FieldArray>
    </Pane>
  );
}
