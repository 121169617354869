export const key = (index: number) => localStorage.key(index);

export const keys = (): Array<string> => {
  const keys = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < localStorage.length; i++) {
    // `null` is a valid key for `localStorage`, but you shouldn't do that.
    keys.push(key(i) as string);
  }
  return keys;
};

export const getItem = (key: string) => {
  const item = localStorage.getItem(key);
  if (item === null) return item;
  try {
    return JSON.parse(item);
  } catch (_error) {
    return item;
  }
};

export const setItem = <T>(key: string, value: T): T => {
  localStorage.setItem(key, JSON.stringify(value));
  return value;
};

export const removeItem = (key: string): unknown => {
  const item = getItem(key);
  localStorage.removeItem(key);
  return item;
};

export const getAll = (): Record<string, unknown> =>
  Object.fromEntries(keys().map((key) => [key, getItem(key)]));

export const clear = (): Record<string, unknown> => {
  const items = getAll();
  localStorage.clear();
  return items;
};
