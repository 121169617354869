import PropTypes from "prop-types";
import Box from "ui-box";
import { Loadable } from "components/materials/Loadable";
import { Pane, withTheme } from "evergreen-ui";
import { css } from "glamor";
import { majorScale } from "helpers/utilities";
import { boxProps, getProps, compose } from "./utils";

const Table = compose(
  (props) => {
    const {
      allowHorizontalScroll,
      children,
      hideHeaderBorder,
      loading,
      stickyColumn,
      getTargetScrollRef,
      hover,
      theme,
      paddingBottom,
      onScroll,
      overflowY,
    } = props;

    const activeBorderSelector = '[data-border-state="active"]';
    const defaultBackgroundSelector = '[data-background-state="default"]';
    const deletedBackgroundSelector = '[data-background-state="deleted"]';
    const errorBackgroundSelector = '[data-background-state="error"]';
    const errorBorderSelector = '[data-border-state="error"]';
    const financialBackgroundSelector = '[data-background-state="financial"]';
    const financial2BackgroundSelector = '[data-background-state="financial2"]';
    const ignoredBorderSelector = '[data-border-state="ignored"]';
    const infoBackgroundSelector = '[data-background-state="info"]';
    const infoBorderSelector = '[data-border-state="info"]';
    const internalBackgroundSelector = '[data-background-state="internal"]';
    const pendingBackgroundSelector = '[data-background-state="pending"]';
    const pendingBorderSelector = '[data-border-state="pending"]';
    const specialBackgroundSelector = '[data-background-state="special"]';
    const specialBorderSelector = '[data-border-state="special"]';
    const successBackgroundSelector = '[data-background-state="success"]';
    const successBorderSelector = '[data-border-state="success"]';

    const buildHoverStyles = () => {
      if (!hover) return {};

      return {
        // Hovered header columns
        [`& thead tr:last-child th:hover`]: {
          boxShadow: `inset 0 3px 0 ${theme.tableColors.columnHoverBorder}`,
        },

        // Hovered body / footer colomns and rows
        [`& tbody tr:hover td,
        & tfoot tr:hover td`]: {
          backgroundColor: theme.tableColors.rowHovered,
        },
        [`& tbody tr${errorBackgroundSelector}:hover td`]: {
          backgroundColor: theme.tableColors.errorRowHovered,
        },
        [`& tbody tr:nth-child(even):hover td`]: {
          backgroundColor: theme.tableColors.alternateRowHovered,
        },
        [`& tbody tr${infoBackgroundSelector}:hover td`]: {
          backgroundColor: theme.tableColors.infoRowHovered,
        },
        [`& tbody tr${deletedBackgroundSelector}:hover td`]: {
          backgroundColor: theme.tableColors.deletedRowHovered,
        },
        [`& tbody tr${specialBackgroundSelector}:hover td`]: {
          backgroundColor: theme.tableColors.specialRow,
        },
        [`& tbody tr${successBackgroundSelector}:hover td`]: {
          backgroundColor: theme.tableColors.successRowHovered,
        },
        [`& tbody tr:hover td:first-child,
        & tfoot tr:hover td:first-child`]: {
          boxShadow: `inset 3px 0 0 ${theme.tableColors.rowHoverBorder}`,
        },
        [`& tbody tr${errorBorderSelector}:hover td:first-child`]: {
          boxShadow: `inset 3px 0 0 ${theme.tableColors.errorBorderHovered}`,
        },
        [`& tbody tr${pendingBorderSelector}:hover td:first-child`]: {
          boxShadow: `inset 3px 0 0 ${theme.tableColors.pendingBorderHovered}`,
        },
        [`& tbody tr${specialBorderSelector}:hover td:first-child`]: {
          boxShadow: `inset 3px 0 0 ${theme.tableColors.specialBorder}`,
        },
        [`& tbody tr${successBorderSelector}:hover td:first-child`]: {
          boxShadow: `inset 3px 0 0 ${theme.tableColors.successBorderHovered}`,
        },
        [`& tbody tr${ignoredBorderSelector}:hover td:first-child`]: {
          boxShadow: `inset 3px 0 0 ${theme.tableColors.ignoredBorderHovered}`,
        },
        [`& tbody tr${activeBorderSelector}:hover td:first-child`]: {
          boxShadow: `inset 3px 0 0 ${theme.tableColors.activeBorderHovered}`,
        },
        [`& tbody tr:hover td.section,
        & tbody tr:nth-child(even):hover td.section`]: {
          backgroundColor: theme.tableColors.sectionHeaderHovered,
        },
        [`& tbody tr:hover td.section:first-child`]: {
          boxShadow: `inset 3px 0 0 ${theme.tableColors.rowHoverBorder}`,
        },
        [`& tfoot ${errorBackgroundSelector}:hover td`]: {
          backgroundColor: theme.tableColors.errorRowAlternateHovered,
        },
      };
    };

    const buildStickyColumn = () => {
      return stickyColumn
        ? {
            "& tbody tr td:first-child, & tfoot tr td:first-child": {
              boxShadow: `inset -2px 0 0 ${theme.colors.gray200} !important`,
            },
            "& tbody tr:hover td:first-child, & tfoot tr:hover td:first-child": {
              boxShadow: `inset 3px 0 0 ${theme.tableColors.rowHoverBorder}, inset -2px 0 0 ${theme.colors.gray200} !important`,
            },
            "& tr td:first-child, & tr th:first-child": {
              position: "sticky",
              top: "auto",
              left: 0,
              zIndex: 2,
            },
          }
        : null;
    };

    const className = css({
      ...(hideHeaderBorder
        ? {}
        : {
            "& thead th, & thead td": {
              borderBottom: `1px solid ${theme.layoutColors.border}`,
            },
          }),
      [`& thead tr th${internalBackgroundSelector}`]: {
        backgroundColor: theme.tableColors.internalCell,
      },
      "& tbody td, & tfoot td": {
        backgroundColor: theme.tableColors.row,
      },
      "& tbody tr:nth-child(even) td": {
        backgroundColor: theme.tableColors.alternateRow,
      },
      [`& tbody tr${defaultBackgroundSelector} td, tbody tr${defaultBackgroundSelector}:nth-child(even) td`]: {
        backgroundColor: theme.tableColors.row,
      },
      [`& tbody td.section,
        & tbody tr:nth-child(even) td.section`]: {
        backgroundColor: theme.tableColors.sectionHeader,
      },
      [`& tbody ${errorBackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.errorRow,
      },
      [`& tbody ${errorBorderSelector} td:first-child,
        & tbody ${errorBorderSelector} td.section:first-child`]: {
        boxShadow: `inset 3px 0 0 ${theme.tableColors.errorBorder}`,
      },
      [`& tbody ${activeBorderSelector} td:first-child,
      & tbody ${activeBorderSelector} td.section:first-child`]: {
        boxShadow: `inset 3px 0 0 ${theme.tableColors.activeBorder}`,
      },
      [`& tbody tr${errorBackgroundSelector}:nth-child(even) td`]: {
        backgroundColor: theme.tableColors.errorRowAlternate,
      },
      [`& tbody tr${errorBackgroundSelector}:nth-child(even) td.section`]: {
        backgroundColor: theme.tableColors.sectionHeader,
      },
      [`& tbody tr${pendingBackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.pendingRow,
      },
      [`& tbody tr:nth-child(even) td${pendingBackgroundSelector}`]: {
        backgroundColor: theme.tableColors.pendingRowAlternate,
      },
      [`& tbody tr td${pendingBackgroundSelector}`]: {
        backgroundColor: theme.tableColors.pendingRow,
      },
      [`& tbody tr${pendingBorderSelector} td:first-child,
        & tbody tr${pendingBorderSelector} td.section:first-child`]: {
        boxShadow: `inset 3px 0 0 ${theme.tableColors.pendingBorder}`,
      },
      [`& tbody tr${successBorderSelector} td:first-child,
        & tbody tr${successBorderSelector} td.section:first-child`]: {
        boxShadow: `inset 3px 0 0 ${theme.tableColors.successBorder}`,
      },
      [`& tbody ${ignoredBorderSelector} td:first-child,
        & tbody ${ignoredBorderSelector} td.section:first-child`]: {
        boxShadow: `inset 3px 0 0 ${theme.tableColors.ignoredBorder}`,
      },
      [`& tbody tr${infoBackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.infoRow,
      },
      [`& tbody tr${specialBackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.specialRow,
      },
      [`& tbody tr td${specialBackgroundSelector}`]: {
        backgroundColor: theme.tableColors.specialRow,
        border: `1px solid ${theme.tableColors.specialBorder}`,
      },
      [`& tbody ${infoBorderSelector} td:first-child,
        & tbody ${infoBorderSelector} td.section:first-child`]: {
        boxShadow: `inset 3px 0 0 ${theme.tableColors.infoBorder}`,
      },
      [`& tbody tr${deletedBackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.deletedRow,
      },
      [`& tbody tr${successBackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.successRow,
      },
      [`& tbody tr:nth-child(even) td${successBackgroundSelector}`]: {
        backgroundColor: theme.tableColors.successRowAlternate,
      },
      [`& tbody tr td${successBackgroundSelector}`]: {
        backgroundColor: theme.tableColors.successRow,
      },
      [`& tfoot ${errorBackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.errorRowAlternate,
      },
      [`& tbody tr${financialBackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.financialRow,
      },
      [`& tbody tr:nth-child(even) td${financialBackgroundSelector}`]: {
        backgroundColor: theme.tableColors.financialRowAlternate,
      },
      [`& tbody tr td${financialBackgroundSelector}`]: {
        backgroundColor: theme.tableColors.financialRow,
      },
      [`& tbody tr${financial2BackgroundSelector} td`]: {
        backgroundColor: theme.tableColors.financial2Row,
      },
      [`& tbody tr:nth-child(even) td${financial2BackgroundSelector}`]: {
        backgroundColor: theme.tableColors.financial2RowAlternate,
      },
      [`& tbody tr td${financial2BackgroundSelector}`]: {
        backgroundColor: theme.tableColors.financial2Row,
      },

      ...buildHoverStyles(),
      ...buildStickyColumn(),
    });

    const scrollSettings = allowHorizontalScroll
      ? {
          "&::-webkit-scrollbar": {
            borderRadius: "16px",
            display: "block",
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#dee0e3",
            borderRadius: "8px",
          },
        }
      : { "&::-webkit-scrollbar": { display: "none" } };

    const wrapperClassName = css({
      ...scrollSettings,
      "&": {
        msOverflowStyle: "none",
      },
    });

    const wrapperBoxProps = boxProps({
      overflowY,
      paddingBottom,
      width: "100%",
    });

    const bottomPadding = allowHorizontalScroll ? majorScale(2) : "0px";
    return (
      <Pane position="relative" width="100%" paddingBottom={bottomPadding}>
        <Pane
          {...wrapperBoxProps}
          ref={getTargetScrollRef}
          onScroll={onScroll}
          className={wrapperClassName}
        >
          <Loadable loading={loading} />
          <Box
            is="table"
            minWidth="100%"
            className={className}
            style={{
              borderSpacing: 1,
              borderCollapse: "collapse",
              boxSizing: "border-box",
            }}
          >
            {children}
          </Box>
        </Pane>
      </Pane>
    );
  },
  [withTheme]
);

export const propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  stickyColumn: PropTypes.bool,
  getTargetScrollRef: PropTypes.func,
  hover: PropTypes.bool,
  paddingBottom: PropTypes.number,
  onScroll: PropTypes.func,
  overflowY: PropTypes.string,
  tableLayout: PropTypes.string,
};

Table.propTypes = propTypes;

// TODO: remove dot notation
Table.getProps = (props) => getProps(propTypes, props);

Table.defaultProps = {
  loading: false,
  stickyColumn: false,
  hover: true,
  paddingBottom: 13,
  overflowY: "auto",
};

export default Table;
