import { useState } from "react";
import { Badge, Pane, Paragraph } from "components/materials";
import { IssuesInfoModal } from "components/templates";
import { uniqBy } from "lodash";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { getIssueColor } from "helpers/issues";

function getLineItemIssues(issues) {
  const uniqueIssues = uniqBy(issues, "name");
  return uniqueIssues;
}

export function SlideoutLineItemIssues({ lineItem, scope, showBlankSlate }) {
  const [issuesModalOpen, setIssuesModalOpen] = useState(false);
  const uniqueIssues = getLineItemIssues(lineItem.issues);
  const hasIssues = uniqueIssues.length > 0;

  if (!hasIssues && showBlankSlate) {
    return <Paragraph marginTop={majorScale(1)}>No Issues</Paragraph>;
  }

  if (!hasIssues) return null;

  return uniqueIssues.map((issue) => {
    return (
      <Pane>
        <Badge
          color={getIssueColor(issue.severity)}
          isInteractive
          onClick={() => setIssuesModalOpen(true)}
        >
          {t(`issues.badge.${issue.name}.text`)}
        </Badge>
        {issuesModalOpen && (
          <IssuesInfoModal
            scope={scope}
            issueItem={{ ...lineItem, issues: uniqueIssues }}
            onClose={() => setIssuesModalOpen(false)}
          />
        )}
      </Pane>
    );
  });
}
