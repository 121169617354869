import { useContext } from "react";
import { InfoSignIcon } from "evergreen-ui";
import {
  InterpolatedTranslation,
  Link,
  Pane,
  Text,
} from "components/materials";
import PropTypes from "prop-types";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";

function DrawLockdownBanner({ title }) {
  const theme = useContext(ThemeContext);
  return (
    <Pane
      padding={majorScale(1)}
      paddingX={majorScale(2)}
      display="flex"
      alignItems="flex-start"
      backgroundColor={theme.colors.lightBlue}
    >
      <InfoSignIcon
        marginLeft={majorScale(3)}
        marginRight={majorScale(1)}
        color={theme.colors.icon.default}
      />
      <Text fontWeight={theme.fontWeights.DEMI} marginRight={majorScale(1)}>
        {title || t("lockdownBanner.title")}
      </Text>
      {!title && (
        <InterpolatedTranslation
          string={t("lockdownBanner.description")}
          values={[
            <Link
              purpose="draw-locked-banner help"
              href="mailto:help@rabbet.com"
              marginX={minorScale(1)}
            >
              {t("lockdownBanner.link")}
            </Link>,
          ]}
        />
      )}
    </Pane>
  );
}

DrawLockdownBanner.propTypes = {
  title: PropTypes.string,
};

export default DrawLockdownBanner;
