import { useContext, useMemo, Fragment } from "react";
import { EditTableViews } from "components/containers";
import { Heading, Link, Pane, Shortener } from "components/materials";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  COMPARATORS,
  FastDataTableDownloadDocuments,
  toBase64,
  currencyColumnDefaults,
  listColumnDefaults,
  numberColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { UserContext } from "helpers/behaviors";
import { ThemeContext, majorScale } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { getDefaultAggregate } from "helpers/tableAggregateHelpers";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { getVendorLineItemPosition } from "helpers/lineItemTableHelpers";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { sumBy } from "helpers/math";
import { PERMISSION_ACTION } from "helpers/enums";
import { get, uniqBy } from "lodash";

const defaultViews = [
  {
    config: toBase64({
      columnConfig: [
        "vendorLineItems",
        "invoiceNumber",
        "currentAmountRequested",
        "invoice",
      ],
      filterConfig: [
        {
          input: formatCurrency(0),
          key: "currentAmountRequested",
          operator: COMPARATORS.GREATER_THAN.value,
        },
      ],
      groupConfig: { columnId: "lineItem", expanded: [] },
      sortConfig: { columnId: "position", direction: "asc" },
    }),
    isDefault: true,
    name: "Default",
  },
];

function getColumns({ baseUrl, hasPermission }) {
  const isSubmission = baseUrl.includes("/submissions/");

  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Vendor Line Items",
      id: "vendorLineItems",
      value: (vendorLineItem) => get(vendorLineItem, "vendor.name"),
      valueFormatter: (value) => value || "No documentation found",
      width: 300,
    },
    {
      ...stringColumnDefaults,
      header: "Invoice #",
      id: "invoiceNumber",
      value: (vendorLineItem) =>
        get(vendorLineItem, "invoices", [])
          .map((invoice) => get(invoice, "document.number"))
          .join(", "),
      width: 120,
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Line Item",
      id: "lineItem",
      value: (vendorLineItem) => get(vendorLineItem, "drawLineItem.name"),
      width: 300,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) =>
        sumBy(
          uniqBy(vendorLineItems, "drawLineItem.id"),
          "drawLineItem.requestedAmount"
        ),
      exportValue: "-",
      header: "Current Amount Requested",
      id: "currentAmountRequested",
      value: (vendorLineItem) =>
        get(vendorLineItem, "drawLineItem.requestedAmount"),
      // this is odd, but seems to have been done very intentionally https://github.com/Rabbet/lift/pull/3340
      valueFormatter: () => "-",
    },
    {
      ...currencyColumnDefaults,
      aggregate: (vendorLineItems) => sumBy(vendorLineItems, "requestedAmount"),
      header: "Invoice",
      id: "invoice",
      value: (vendorLineItem) => vendorLineItem.requestedAmount,
      valueFormatter: (value, vendorLineItem) => {
        const invoice = get(vendorLineItem, "invoices.0");
        return invoice ? (
          <Pane onClick={preventEventBubbling}>
            <Link
              disabled={isSubmission}
              purpose="soft-costs document invoice"
              to={`${baseUrl}/line_items/${vendorLineItem.id}/documents/${invoice.document.id}`}
            >
              {formatCurrency(value)}
            </Link>
          </Pane>
        ) : null;
      },
    },
    {
      ...stringColumnDefaults,
      aggregate: (vendorLineItems) =>
        getDefaultAggregate(vendorLineItems, "vendor.vendorCostCode"),
      header: "Vendor ID",
      hidden: !hasPermission(PERMISSION_ACTION.VENDOR_COST_CODES),
      id: "vendorCostCode",
      value: (vendorLineItem) => get(vendorLineItem, "vendor.vendorCostCode"),
    },
    {
      ...listColumnDefaults,
      header: "Job Cost Codes",
      hidden: !hasPermission(PERMISSION_ACTION.JOB_COST_CODES),
      id: "jobCostCodes",
      value: (vendorLineItem) => {
        const codes = get(vendorLineItem, "jobCostCodes", []).map(
          (jobCostCode) => jobCostCode.code
        );
        return codes.join(", ");
      },
      width: 120,
    },
    {
      ...numberColumnDefaults,
      header: "Position",
      hidden: true,
      id: "position",
      value: getVendorLineItemPosition,
    },
  ];
}

export function DrawSoftCostsTable({
  baseUrl,
  history,
  onClickRow,
  totalSoftCostsRequested,
  vendorLineItems,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission, organizationId } = useContext(UserContext);
  const columns = useMemo(() => getColumns({ baseUrl, hasPermission }), [
    baseUrl,
    hasPermission,
  ]);

  return (
    <Fragment>
      <Pane display="flex" margin={majorScale(3)}>
        <Pane marginRight={majorScale(4)}>
          <Heading size={200}>SOFT COSTS REQUESTED</Heading>
          <Shortener
            color={theme.colors.baseBlue}
            limit={25}
            size={400}
            text={formatCurrency(totalSoftCostsRequested)}
          />
        </Pane>
      </Pane>
      <EditTableViews
        canManagePublicViews={hasPermission(PERMISSION_ACTION.SAVE_TABLE_VIEWS)}
        config={getSearchByKey(history, "table")}
        organizationIdToScopeViews={organizationId}
        defaultViews={defaultViews}
        tableName="DrawSoftCostsTable"
      >
        {(propsEditTableViews) => (
          <FastDataTable
            columns={columns}
            controls={(propsControls) => (
              <FastDataTableAdvancedControls
                {...propsControls}
                {...propsEditTableViews}
                disable={[FastDataTableDownloadDocuments]}
                searchPlaceholder="Search Organizations..."
              />
            )}
            footerTotals
            items={vendorLineItems}
            onClickRow={onClickRow}
            onSerialize={(table) => mergeSearch(history, { table })}
            serialized={
              getSearchByKey(history, "table") ||
              get(propsEditTableViews, "views.0.config")
            }
          />
        )}
      </EditTableViews>
    </Fragment>
  );
}
