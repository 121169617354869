import PropTypes from "prop-types";
import { Link, Pane, Text } from "components/materials";
import { minorScale } from "helpers/utilities";

const DescriptionWithHelpLink = ({ helpLink, text, purpose }) => (
  <Pane>
    <Text marginRight={minorScale(1)}>{text}</Text>
    {helpLink && (
      <Link purpose={purpose} href={helpLink} content="Learn more." />
    )}
  </Pane>
);

DescriptionWithHelpLink.propTypes = {
  helpLink: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  purpose: PropTypes.string,
};

export default DescriptionWithHelpLink;
