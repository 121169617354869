import { validate } from "uuid";
import { STORAGE_KEYS } from "./localStorageKeys";

export const getRecentProjectIds = () => {
  const value = localStorage.getItem(STORAGE_KEYS.RECENT_PROJECT_IDS);
  const ids = JSON.parse(value);
  return Array.isArray(ids) ? ids : [];
};

export const setRecentProjectIds = (projectId) => {
  if (!projectId || !validate(projectId)) return;
  const ids = [projectId]
    .concat(getRecentProjectIds().filter((id) => id !== projectId))
    .slice(0, 10);
  const value = JSON.stringify(ids);
  localStorage.setItem(STORAGE_KEYS.RECENT_PROJECT_IDS, value);
};
