import { useEffect } from "react";
import { EditProjectSettingsButtons } from "components/templates";
import { Accordion, Form, Pane } from "components/materials";

export function EditNexusConfigurationPanel({
  dirty,
  form,
  glCodes,
  loading,
  onToggle,
  open,
  panelKey,
  setPanelDirty,
  ...props
}) {
  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(panelKey, isDirty);
    }
    return undefined;
  }, [dirty, form, panelKey, setPanelDirty]);

  return (
    <Accordion.Panel
      panelKey={panelKey}
      title="Nexus Configuration"
      onClick={() => onToggle(panelKey)}
      open={open}
      actionContent={
        <EditProjectSettingsButtons
          dirty={dirty}
          form={form}
          loading={loading}
        />
      }
      {...props}
    >
      <Form.Group aligned renderedChildrenDirectly>
        <Pane width={250}>
          <Form.Select
            label="GL Code"
            name="glCode"
            options={getGlCodeOptions(glCodes)}
            popoverMinWidth={85}
            fullBorder
          />
        </Pane>
      </Form.Group>
    </Accordion.Panel>
  );
}

function getGlCodeOptions(glCodes) {
  return glCodes.map((code) => ({ key: code, value: code, text: code }));
}
