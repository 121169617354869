import { TickIcon, WarningSignIcon } from "evergreen-ui";
import { IconButton } from "components/materials";
import { sumBy } from "helpers/math";
import unformatNumber from "helpers/unformatNumber";

const OVERALLOCATED_PROPS = {
  appearance: "primary",
  intent: "danger",
  icon: WarningSignIcon,
};

const UNDERALLOCATED_PROPS = {
  intent: "warning",
  icon: WarningSignIcon,
};

const FULLY_ALLOCATED_PROPS = {
  intent: "success",
  icon: TickIcon,
};

const getAllocatedProps = (lineItem) => {
  const { applicationAmount, retainageAmount } = lineItem;

  const lineItemGrossAmount = unformatNumber(applicationAmount);
  const lineItemRetainageAmount = unformatNumber(retainageAmount);

  const costCodeGrossAmount = sumBy(lineItem.jobCostCodes, "grossAmount");
  const costCodeRetainageAmount = sumBy(
    lineItem.jobCostCodes,
    "retainageAmount"
  );

  if (
    lineItemGrossAmount === costCodeGrossAmount &&
    lineItemRetainageAmount === costCodeRetainageAmount
  )
    return FULLY_ALLOCATED_PROPS;

  if (
    (lineItemGrossAmount < costCodeGrossAmount && lineItemGrossAmount >= 0) ||
    (lineItemRetainageAmount < costCodeRetainageAmount &&
      lineItemRetainageAmount >= 0)
  )
    return OVERALLOCATED_PROPS;

  return UNDERALLOCATED_PROPS;
};

export function AssignMultipleCostCodes({ lineItem, onClick }) {
  return (
    <IconButton
      onClick={onClick}
      type="button"
      width={50}
      {...getAllocatedProps(lineItem)}
    />
  );
}
