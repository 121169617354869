import { createContext, useState, useContext, useEffect } from "react";
import { isInternetExplorer } from "helpers/browserHelpers";

const valueDefault = { hiddenViewer: false, setHiddenViewer: () => {} };

export const InternetExplorerContext = createContext(valueDefault);

export function InternetExplorerContextProvider({ children }) {
  const [hiddenViewer, setHiddenViewer] = useState(false);

  if (!isInternetExplorer) {
    return children(valueDefault);
  }

  const value = { hiddenViewer, setHiddenViewer };

  return (
    <InternetExplorerContext.Provider value={value}>
      {children(value)}
    </InternetExplorerContext.Provider>
  );
}

export function useHiddenViewerOnMount(hideViewer) {
  const { setHiddenViewer } = useContext(InternetExplorerContext);

  useEffect(() => {
    if (!hideViewer) {
      return () => {};
    }

    setHiddenViewer(true);
    return () => {
      setHiddenViewer(false);
    };
  }, [hideViewer, setHiddenViewer]);

  return null;
}

export function useHiddenViewer() {
  const { hiddenViewer } = useContext(InternetExplorerContext);

  return hiddenViewer;
}
