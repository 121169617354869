import { CommentIcon, PercentageIcon } from "evergreen-ui";
import { Tooltip } from "components/materials";
import { compact, get, uniq, uniqBy } from "lodash";
import { add, divide, multiply, sumBy, subtract } from "helpers/math";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

export function renderCommentIcon(item) {
  if (item.comments.length > 0) {
    return <CommentIcon size={10} marginLeft={majorScale(1)} />;
  }

  return null;
}

export function renderRetainageIcon(item) {
  if (item.retainagePercentage && item.retainagePercentage !== 0) {
    return (
      <Tooltip content="Retainage is being tracked on this line-item">
        <PercentageIcon size={10} marginLeft={majorScale(1)} />
      </Tooltip>
    );
  }

  return null;
}

export const NET_ZERO = "NET_ZERO";

export function amountOrNetZero(lineItems, property) {
  const aggregateAmount = sumBy(lineItems, property);

  if (
    lineItems.some((lineItem) => lineItem[property] !== 0) &&
    aggregateAmount === 0
  ) {
    return NET_ZERO;
  }

  return aggregateAmount;
}

export function percentRemainingNet(lineItem) {
  return lineItem.types?.includes("CONTINGENCY")
    ? divide(
        lineItem.balanceToFundAmount,
        add(lineItem.originalBudgetAmount, lineItem.untrackedContingencyAmount)
      )
    : lineItem.percentRemaining;
}

export function percentCompleteNet(lineItem) {
  return lineItem.types?.includes("CONTINGENCY")
    ? subtract(1, percentRemainingNet(lineItem))
    : lineItem.percentComplete;
}

export function percentRemainingGross(lineItem) {
  return lineItem.types?.includes("CONTINGENCY")
    ? divide(
        subtract(lineItem.balanceToFundAmount, lineItem.retainageToDateAmount),
        add(lineItem.originalBudgetAmount, lineItem.untrackedContingencyAmount)
      )
    : lineItem.grossPercentRemaining;
}

export function percentCompleteGross(lineItem) {
  return lineItem.types?.includes("CONTINGENCY")
    ? subtract(1, percentRemainingGross(lineItem))
    : lineItem.grossPercentComplete;
}

function lineItemPosition(divisionPosition, positionWithinDivision) {
  return add(multiply(divisionPosition, 1000), positionWithinDivision);
}

export function getLineItemPosition(lineItem) {
  const divisionPosition = get(lineItem, "division.position", 0);
  const positionWithinDivision = get(lineItem, "position", 0);

  return lineItemPosition(divisionPosition, positionWithinDivision);
}

export function getVendorLineItemPosition(vendorLineItem) {
  const divisionPosition = get(
    vendorLineItem,
    "drawLineItem.division.position",
    0
  );
  const positionWithinDivision = get(vendorLineItem, "position", 0);

  return lineItemPosition(divisionPosition, positionWithinDivision);
}

export function formatCategories(list) {
  const listItems = compact(uniq(list));
  if (listItems.length === 0) return null;
  const formattedListItems = listItems.map((listItem) => {
    return t(`lineItemTypes.${listItem}`);
  });
  return formattedListItems.join(", ");
}

const lineItemListFields = [
  "agreements",
  "budgetAdjustments",
  "comments",
  "expenses",
  "issues",
  "jobCostCodes",
  "rules",
  "transactions",
];
const lineItemAmountFields = [
  "adjustmentsAmount",
  "balanceToFundAmount",
  "budgetAmount",
  "committedAmount",
  "costEstimate",
  "debtDisbursedToDateAmount",
  "documentedRequestedAmount",
  "equityDisbursedToDateAmount",
  "expendituresOverages",
  "exposedAmount",
  "exposureAmount",
  "exposureForecast",
  "grossDocumentedRequestedAmount",
  "grossRequestedAmount",
  "grossRequestedPreviouslyAmount",
  "grossRequestedToDateAmount",
  "grossUndocumentedRequestedAmount",
  "materialsStoredAmount",
  "originalBudgetAmount",
  "outOfContractAmount",
  "pcoAmount",
  "previousAdjustmentsAmount",
  "requestedAmount",
  "requestedPreviouslyAmount",
  "requestedToDateAmount",
  "requestedToDateAmount",
  "retainageAmount",
  "retainageToDateAmount",
  "totalAgreementsAmount",
  "totalCommitmentsAmount",
  "undocumentedRequestedAmount",
];

export function getSuperLineItemAggregates(lineItems) {
  const startingAccumulator = {
    ...lineItemListFields.reduce((acc, field) => ({ ...acc, [field]: [] }), {}),
    ...lineItemAmountFields.reduce(
      (acc, field) => ({ ...acc, [field]: 0 }),
      {}
    ),
    issues: [],
    lineItems: [],
    lineItemIds: [],
  };

  const aggregatedFields = lineItems.reduce((aggregatedFields, lineItem) => {
    return {
      ...lineItemListFields.reduce(
        (listAcc, field) => ({
          ...listAcc,
          [field]: aggregatedFields[field].concat(get(lineItem, field) || []),
        }),
        {}
      ),
      ...lineItemAmountFields.reduce(
        (amountAcc, field) => ({
          ...amountAcc,
          [field]: add(aggregatedFields[field], get(lineItem, field) || 0),
        }),
        {}
      ),
      issues: aggregatedFields.issues.concat(get(lineItem, "issues")),
      lineItems: aggregatedFields.lineItems.concat({
        name: get(lineItem, "name"),
        issues: get(lineItem, "issues"),
      }),
      lineItemIds: aggregatedFields.lineItemIds.concat(lineItem.id),
    };
  }, startingAccumulator);

  return {
    ...aggregatedFields,
    expenses: prepareExpenses(aggregatedFields.expenses),
    agreements: prepareAgreements(aggregatedFields.agreements),
    budgetAdjustments: prepareAdjustments(aggregatedFields.budgetAdjustments),
  };
}

function prepareExpenses(expenses) {
  const expenseAmounts = expenses.reduce((acc, expense) => {
    const expenseDocumentId = expense.document?.id;
    const accAmount = acc[expenseDocumentId] || 0;
    return {
      ...acc,
      [expenseDocumentId]: add(accAmount, expense.amount),
    };
  }, {});

  return uniqBy(
    expenses.filter(({ document }) => !!document),
    "document.id"
  ).map((expense) => ({
    ...expense,
    amount: expenseAmounts[expense.document.id],
  }));
}

function prepareAgreements(agreements) {
  const agreementAmounts = agreements.reduce((acc, agreement) => {
    const agreementId = agreement.id;
    const accAmount = acc[agreementId] || 0;
    return {
      ...acc,
      [agreementId]: add(accAmount, agreement.lineItemAmount),
    };
  }, {});

  return uniqBy(agreements, "id").map((agreement) => ({
    ...agreement,
    lineItemAmount: agreementAmounts[agreement.id],
  }));
}

function prepareAdjustments(adjustments) {
  return uniqBy(adjustments, "id");
}
