import { getIssuesFilterConfig } from "helpers/issues";
import { toBase64 } from "../../materials/FastDataTable";

export const currentBudgetViewConfig = (hasRulesRedesign, lineItemIssues) =>
  toBase64({
    columnConfig: ["lineItem"]
      .concat(hasRulesRedesign && lineItemIssues.length > 0 ? ["issues"] : [])
      .concat([
        "originalBudget",
        "adjustments",
        "currentBudget",
        "grossRequested",
        "retainage",
        "requested",
        "balanceToFund",
        "percentRemaining",
      ]),
    filterConfig: [],
    groupConfig: { columnId: "division" },
    sortConfig: { columnId: "position", direction: "asc" },
  });

export const issuesViewConfig = (lineItemIssues) =>
  toBase64({
    columnConfig: [
      "lineItem",
      "issues",
      "originalBudget",
      "adjustments",
      "currentBudget",
      "grossRequested",
      "retainage",
      "requested",
      "balanceToFund",
      "percentRemaining",
    ],
    filterConfig: getIssuesFilterConfig(lineItemIssues),
    groupConfig: { columnId: "division" },
    sortConfig: { columnId: "position", direction: "asc" },
  });

export const anticipatedCostViewConfig = () =>
  toBase64({
    columnConfig: [
      "lineItem",
      "currentBudget",
      "committed",
      "outOfContract",
      "exposed",
      "anticipatedExpenditures",
      "expendituresOverages",
    ],
    filterConfig: [],
    groupConfig: { columnId: "division" },
    sortConfig: { columnId: "position", direction: "asc" },
    isDefault: true,
  });

export const originalBudgetVarianceViewConfig = () =>
  toBase64({
    columnConfig: [
      "lineItem",
      "originalBudget",
      "committed",
      "outOfContract",
      "exposed",
      "anticipatedExpenditures",
      "originalBudgetVariance",
    ],
    filterConfig: [],
    groupConfig: { columnId: "division" },
    sortConfig: { columnId: "position", direction: "asc" },
    isDefault: true,
  });

export const adjustmentsOverTimeViewConfig = () =>
  toBase64({
    columnConfig: [
      "lineItem",
      "originalBudget",
      "adjustments",
      "currentBudget",
      "drawAdjustment",
    ],
    filterConfig: [],
    groupConfig: { columnId: "division" },
    sortConfig: { columnId: "position", direction: "asc" },
  });

export const requestedOverTimeViewConfig = () =>
  toBase64({
    columnConfig: [
      "lineItem",
      "currentBudget",
      "grossRequested",
      "retainage",
      "requested",
      "balanceToFund",
      "percentRemaining",
      "drawGrossRequested",
      "drawRetainage",
      "drawRequested",
    ],
    filterConfig: [],
    groupConfig: { columnId: "division" },
    sortConfig: { columnId: "position", direction: "asc" },
  });

export const bySummaryLineItemViewConfig = () =>
  toBase64({
    columnConfig: [
      "lineItem",
      "originalBudget",
      "adjustments",
      "currentBudget",
      "grossRequested",
      "retainage",
      "requested",
      "balanceToFund",
      "percentRemaining",
    ],
    filterConfig: [],
    groupConfig: { columnId: "superLineItem" },
    sortConfig: { columnId: "position", direction: "asc" },
  });
