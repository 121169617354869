import { memo } from "react";
import PropTypes from "prop-types";
import { Table } from "components/materials";
import { preventEventBubbling } from "helpers/preventEventBubbling";

function SlowDataTableUtilityCell({
  preparedItem,
  selectedItemIds,
  setSelectedItemIds,
  utilityColumn,
}) {
  return (
    <Table.TextCell
      cursor="default"
      id={utilityColumn.id}
      onClick={preventEventBubbling}
      width={utilityColumn.width}
    >
      {utilityColumn.value(preparedItem, selectedItemIds, setSelectedItemIds)}
    </Table.TextCell>
  );
}

SlowDataTableUtilityCell.propTypes = {
  preparedItem: PropTypes.object,
  selectedItemIds: PropTypes.array,
  setSelectedItemIds: PropTypes.func,
  utilityColumn: PropTypes.object,
};

SlowDataTableUtilityCell.defaultProps = {};

export const FastDataTableUtilityCell = memo(SlowDataTableUtilityCell);
