import { useState } from "react";
import { Pane, RadioGroup, Text } from "components/materials";
import { majorScale } from "helpers/utilities";
import { PROJECTION_EVENT } from "./enums";

export function FundingSourceChartControl({ handleProjectionsChange }) {
  const [projectionToUse, setProjectionToUse] = useState("updatedProjection");
  return (
    <Pane
      display="flex"
      alignItems="center"
      marginLeft={80}
      marginBottom={majorScale(2)}
    >
      <Text fontWeight={600} size={300}>
        Based on:
      </Text>
      <Pane marginLeft={majorScale(1)} width={300}>
        <RadioGroup
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          value={projectionToUse}
          options={[
            { label: "Last Projection", value: "lastProjection" },
            {
              label: "Actuals / Updated Projection",
              value: "updatedProjection",
            },
          ]}
          onChange={(event) => {
            const { value } = event.target.value;
            setProjectionToUse(value);
            handleProjectionsChange({
              type: PROJECTION_EVENT.FUNDING_SOURCE_CHART_UPDATED,
              data: { fundingSourceChart: value },
            });
          }}
        />
      </Pane>
    </Pane>
  );
}
