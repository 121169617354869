import { useContext } from "react";
import { ThemeContext } from "evergreen-ui";

export {
  majorScale,
  minorScale,
  Position,
  ThemeContext,
  toaster,
  withTheme,
} from "evergreen-ui";

export function useTheme() {
  return useContext(ThemeContext);
}
