import { useContext } from "react";
import { css } from "glamor";
import { Badge, Button, Card, Pane, Text } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";

export function DashboardDrawApprovalCard({
  approvedDraws,
  draw,
  onClick,
  onApprove,
  projectName,
}) {
  const theme = useContext(ThemeContext);
  const isApproved = approvedDraws.includes(draw.id);
  return (
    <Card
      cursor="pointer"
      elevation={1}
      hoverElevation={2}
      marginBottom={majorScale(1)}
      padding={minorScale(3)}
      onClick={onClick}
    >
      <Pane display="flex" justifyContent="space-between">
        <Text
          color={theme.colors.gray500}
          fontWeight={600}
          size={300}
          textTransform="uppercase"
          whiteSpace="nowrap"
        >
          {projectName} Draw Approval
        </Text>
        {isApproved ? (
          <Badge color="green" height={majorScale(2)}>
            Approved
          </Badge>
        ) : (
          <Button
            appearance="minimal"
            backgroundColor={theme.colors.gray90}
            height={majorScale(2)}
            intent="success"
            marginLeft={minorScale(1)}
            onClick={onApprove}
            {...css({
              "&#dashApprove:hover": {
                backgroundColor: theme.colors.gray200,
              },
            })}
          >
            Approve
          </Button>
        )}
      </Pane>
      <Pane>
        <Text
          fontWeight={theme.fontWeights.DEMI}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          marginRight={majorScale(1)}
          size={300}
        >
          {draw.name}
        </Text>
        <Text
          color={theme.colors.gray500}
          fontWeight={600}
          size={300}
          textTransform="uppercase"
          whiteSpace="nowrap"
        >
          {formatCurrency(draw.requestedAmount)}
        </Text>
      </Pane>
    </Card>
  );
}
