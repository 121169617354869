import { Fragment } from "react";
import { startCase } from "lodash";
import { Confirm, Paragraph } from "components/materials";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

export function CannotDeleteWithAdjustmentModal({ context, onClose }) {
  return (
    <Confirm
      hasCancel={false}
      confirmLabel="OK"
      content={
        <Fragment>
          <Paragraph marginBottom={majorScale(2)}>
            {t("agreementsViewer.cannotDeleteWithAdjustment", { context })}
          </Paragraph>
          <Paragraph>
            {t("agreementsViewer.cannotDeleteWithAdjustmentSecondary")}
          </Paragraph>
        </Fragment>
      }
      onCloseComplete={onClose}
      open
      title={startCase(`Delete ${context}`)}
    />
  );
}
