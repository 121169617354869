import PropTypes from "prop-types";
import { Button, Form, Pane } from "components/materials";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { majorScale } from "helpers/utilities";

const EditProjectSettingsButtons = ({
  dirty,
  form,
  loading,
  hasSubmissionPrompt,
  renderSubmissionPrompt,
}) =>
  dirty ? (
    <Pane display="flex">
      <Pane>
        <Button
          onClick={(e) => {
            preventEventBubbling(e);
            form.handleReset();
          }}
          marginRight={majorScale(2)}
          disabled={loading}
          purpose="project-settings undo"
        >
          Undo Changes
        </Button>
      </Pane>
      <Pane>
        <Form.SubmitButton
          onClick={(e) => {
            preventEventBubbling(e);
            if (hasSubmissionPrompt && hasSubmissionPrompt(form)) {
              renderSubmissionPrompt();
            } else {
              form.handleSubmit(form);
            }
          }}
          purpose="project-settings submit"
          isLoading={loading}
          label="Save Changes"
        />
      </Pane>
    </Pane>
  ) : null;

EditProjectSettingsButtons.propTypes = {
  dirty: PropTypes.bool,
  form: PropTypes.shape({
    resetForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  hasSubmissionPrompt: PropTypes.func,
  loading: PropTypes.bool,
  renderSubmissionPrompt: PropTypes.func,
};

export default EditProjectSettingsButtons;
