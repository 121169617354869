import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
  VictoryTooltip,
} from "victory";
import { theme } from "helpers/victoryTheme";

const styleTitle = {
  fontFamily: "AvenirNext, arial, sans-serif",
  fontSize: 18,
  fontWeight: 500,
};

const styleAxis = {
  grid: { stroke: "lightGrey", strokeDasharray: "3" },
};

export function StackedBarChart({
  datasets,
  colors,
  title,
  xFormat,
  xLabel,
  yFormat,
  yLabel,
}) {
  return (
    <VictoryChart
      domain={{ x: [-0.375, 10] }}
      theme={theme}
      padding={{
        top: title ? theme.chart.padding : 25,
        bottom: theme.chart.padding,
        left: theme.chart.padding * 1.75,
        right: theme.chart.padding * 0.5,
      }}
    >
      <VictoryLabel
        style={styleTitle}
        text={title}
        textAnchor="middle"
        x={190}
        y={20}
      />
      <VictoryAxis
        axisLabelComponent={
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            y={theme.chart.height - theme.chart.padding / 4}
          />
        }
        crossAxis={false}
        label={xLabel}
        tickFormat={xFormat}
      />
      <VictoryAxis
        axisLabelComponent={
          <VictoryLabel
            angle={270}
            textAnchor="middle"
            verticalAnchor="middle"
            x={theme.chart.padding / 8}
          />
        }
        dependentAxis
        label={yLabel}
        offsetX={theme.chart.padding * 1.75}
        style={styleAxis}
        tickFormat={yFormat}
      />
      <VictoryStack colorScale={colors}>
        {datasets.map(({ data, tooltipFormat, groupName }) => (
          <VictoryBar
            key={groupName}
            alignment="middle"
            barRatio={0.95}
            data={data}
            labelComponent={<VictoryTooltip theme={theme} />}
            labels={tooltipFormat}
          />
        ))}
      </VictoryStack>
    </VictoryChart>
  );
}
