import PropTypes from "prop-types";
import {
  CaretDownIcon,
  CaretUpIcon,
  majorScale,
  withTheme,
} from "evergreen-ui";
import TableCell, { propTypes as cellPropTypes } from "./Cell";
import { getProps, compose, withMemo } from "./utils";

const TableHeaderCell = compose(
  (props) => {
    const { cellProps, children, content, sortDirection, theme } = props;

    const sortDirectionIcon =
      sortDirection === "asc" ? (
        <CaretUpIcon size={majorScale(1)} />
      ) : (
        <CaretDownIcon size={majorScale(1)} />
      );

    props = {
      backgroundColor: theme.tableColors.tableHeader,
      textAlign: "left",
      ...props,
      ...cellProps,
    };

    return (
      <TableCell is="th" {...props}>
        {content || children}
        {sortDirection ? sortDirectionIcon : null}
      </TableCell>
    );
  },
  [withMemo, withTheme]
);

export const propTypes = {
  ...cellPropTypes,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TableHeaderCell.propTypes = propTypes;

// TODO: remove dot notation
TableHeaderCell.getProps = (props) => getProps(propTypes, props);

export default TableHeaderCell;
