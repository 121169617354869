import { Tooltip, Pane } from "components/materials";
import {
  formatCurrency,
  formatShortenedCurrency,
} from "helpers/formatCurrency";
import { Position } from "helpers/utilities";

const CurrencyTooltip = ({ value }) => {
  const longFormattedValue = formatCurrency(value);
  const shortFormattedValue = formatShortenedCurrency(value);

  if (shortFormattedValue !== longFormattedValue) {
    return (
      <Tooltip content={longFormattedValue} position={Position.TOP}>
        <Pane>{shortFormattedValue}</Pane>
      </Tooltip>
    );
  }
  return longFormattedValue;
};

export default CurrencyTooltip;
