import { Fragment } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "evergreen-ui";
import { IconButton, Pane } from "components/materials";
import { minorScale } from "helpers/utilities";
import { DIRECTIONS } from "./utils";

export default function RowOrderButtons({
  id,
  isFirstItem,
  isLastItem,
  onMoveRow,
  rowType,
}) {
  return (
    <Fragment>
      {isFirstItem ? (
        <Pane width={32} marginX={minorScale(1)} />
      ) : (
        <IconButton
          icon={ArrowUpIcon}
          marginX={minorScale(1)}
          size="small"
          appearance="minimal"
          onClick={() => onMoveRow(id, rowType, DIRECTIONS.UP)}
          type="button"
        />
      )}
      {isLastItem ? (
        <Pane width={32} marginX={minorScale(1)} />
      ) : (
        <IconButton
          icon={ArrowDownIcon}
          size="small"
          appearance="minimal"
          type="button"
          onClick={() => onMoveRow(id, rowType, DIRECTIONS.DOWN)}
          marginX={minorScale(1)}
        />
      )}
    </Fragment>
  );
}
