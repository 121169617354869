import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { TickCircleIcon } from "evergreen-ui";
import { EditTableViews } from "components/containers";
import { Shortener } from "components/materials";
import {
  booleanColumnDefaults,
  currencyColumnDefaults,
  dateColumnDefaults,
  dateTimeColumnDefaults,
  enumColumnDefaults,
  fractionColumnDefaults,
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  listColumnDefaults,
  numberColumnDefaults,
  percentColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
  toBase64,
} from "components/materials/FastDataTable";
import { UserContext } from "helpers/behaviors";
import { formatDateTime } from "helpers/dateHelpers";
import { formatList } from "helpers/formatList";
import {
  DRAW_STATE,
  FUNDING_SOURCE_TYPE,
  ORGANIZATION_TYPE,
  PERMISSION_ACTION,
  RULE_STATE,
  PROJECT_STATUS_TYPE,
} from "helpers/enums";
import { getEnumValuesForOrganization } from "helpers/reportHelpers";
import { fundingSourcesByType } from "helpers/fundingSourceHelpers";
import { add, divide, subtract, sumBy } from "helpers/math";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { formatRuleName, filterEnabledRuleByStatus } from "helpers/ruleHelpers";
import {
  getReviewersForDraw,
  renderReviewsForDraw,
} from "helpers/drawReviewHelpers";
import {
  getDateRangeAggregate,
  getDateTimeRangeAggregate,
  getDefaultAggregate,
} from "helpers/tableAggregateHelpers";
import t from "helpers/translate";
import { camelCase, capitalize, compact, get, uniq, xor } from "lodash";
import PropTypes from "prop-types";
import {
  getApprovalsCount,
  getApprovalStatus,
  RelativeDrawReviewState,
} from "helpers/approvalHelpers";
import { customFieldFastColumns } from "./CustomFieldColumns";

function formatRulesForDraw(draw, state) {
  const filteredRules = get(draw, "rules", [])
    .filter((rule) => filterEnabledRuleByStatus(rule, state))
    .map(formatRuleName);
  return formatList(filteredRules);
}

function getProjectEquityPartners(draw) {
  const list = fundingSourcesByType(
    FUNDING_SOURCE_TYPE.EQUITY,
    draw.project.fundingSourceGroups
  )
    .map((source) => source.organization.name)
    .concat(getStakeholderOrgsByType(draw, ORGANIZATION_TYPE.EQUITY_PARTNER));
  return formatList(list);
}

function getProjectGeneralContractors(draw) {
  const list = getStakeholderOrgsByType(draw, ORGANIZATION_TYPE.CONTRACTOR);
  return formatList(list);
}

function getProjectLenders(draw) {
  const list = fundingSourcesByType(
    FUNDING_SOURCE_TYPE.LOAN,
    draw.project.fundingSourceGroups
  )
    .map((source) => source.organization.name)
    .concat(getStakeholderOrgsByType(draw, ORGANIZATION_TYPE.LENDER));
  return formatList(list);
}

function getProjectLocation(draw) {
  return formatList([draw.project.city, draw.project.state]);
}

function getStakeholderOrgsByType(draw, type, format) {
  const orgs = get(draw, "project.stakeholders", []).reduce(
    (acc, stakeholder) => {
      if (stakeholder.role === type) acc.push(stakeholder.organization.name);
      return acc;
    },
    []
  );
  return format ? formatList(orgs) : compact(uniq(orgs));
}

function getDrawAssessmentHeader(fieldName) {
  const title = (fieldName || "")
    .split("_")
    .map((string) => capitalize(string))
    .join(" ");
  return `Draw Assessment: ${title}`;
}

function getDocumentInspectionReportHeader(
  header,
  hasInspectionReportWorkflow
) {
  return hasInspectionReportWorkflow ? `${header} (Document)` : header;
}

function renderShortener(text) {
  return <Shortener limit={40} size={300} text={text} />;
}

export const defaultViews = [
  {
    config: toBase64({
      columnConfig: [
        "projectName",
        "drawName",
        "drawSentDate",
        "drawStatus",
        "currentAmountRequested",
        "drawApprovalDate",
      ],
      filterConfig: [],
      groupConfig: {},
      sortConfig: {},
    }),
    isDefault: true,
    name: "Outstanding Draws",
  },
  {
    config: toBase64({
      columnConfig: [
        "projectName",
        "drawName",
        "currentAmountRequested",
        "drawSentDate",
        "dateFunded",
        "daysToProcess",
        "drawApprovalDate",
        "daysToApprove",
        "inspectionUploadedDate",
        "titleReportUploadedDate",
        "approvers",
      ],
      filterConfig: [],
      groupConfig: {},
      sortConfig: {},
    }),
    isDefault: true,
    name: "Draw Processing",
  },
];

function getDrawAssessmentColumns(drawAssessmentQuestions) {
  return drawAssessmentQuestions.map(({ id, reportTemplateFieldName }) => {
    return {
      ...stringColumnDefaults,
      header: getDrawAssessmentHeader(reportTemplateFieldName),
      id: `drawAssessment-${id}`,
      category: "Draw Assessment",
      value: (draw) => get(draw, `formattedResponses.${id}`),
    };
  });
}

function getColumns(hasPermission, orgData, userId) {
  const canAccessFundingSources = hasPermission(
    PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
  );

  const canAccessStakeholders = hasPermission(
    PERMISSION_ACTION.ACCESS_STAKEHOLDERS
  );
  const hasTeamManagement = hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT);

  const hasReportTemplating = hasPermission(
    PERMISSION_ACTION.REPORT_GENERATION
  );

  const hasInspectionReportWorkflow = hasPermission(
    PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW
  );

  const hasPaymentTracking = hasPermission(PERMISSION_ACTION.PAYMENT_TRACKING);

  const orgEnumValues = getEnumValuesForOrganization(orgData);

  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      groupable: true,
      header: "Draw",
      id: "drawName",
      // category not needed - primary column
      value: (draw) => draw.name,
      width: 300,
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Project",
      id: "projectName",
      category: "Project Information",
      value: (draw) => draw.project.name,
      width: 300,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDraws) =>
        sumBy(groupDraws, (draw) => draw.requestedAmount),
      header: "Current Amount Requested (Net)",
      id: "currentAmountRequested",
      category: "Draw Details",
      value: (draw) => draw.requestedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDraws) =>
        add(
          sumBy(groupDraws, (draw) => draw.requestedAmount),
          sumBy(groupDraws, (draw) => draw.retainageAmount)
        ),
      header: "Current Amount Requested (Gross)",
      id: "currentAmountRequestedGross",
      category: "Draw Details",
      value: (draw) => add(draw.requestedAmount, draw.retainageAmount),
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDraws) =>
        sumBy(groupDraws, (draw) => draw.materialsStoredAmount),
      header: "Stored Materials",
      id: "materialsStoredAmount",
      category: "Draw Details",
      value: (draw) => draw.materialsStoredAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (groupDraws) =>
        sumBy(groupDraws, (draw) => draw.documentAmountPaidTotal),
      header: "Amount Paid Total from Documents",
      id: "amountPaidTotalFromDocuments",
      hidden: !hasPaymentTracking,
      category: "Draw Details",
      value: ({ documentAmountPaidTotal }) =>
        !documentAmountPaidTotal ? 0 : documentAmountPaidTotal,
    },
    {
      ...enumColumnDefaults,
      enumValues: xor(Object.values(DRAW_STATE), [
        DRAW_STATE.RECEIVED,
        DRAW_STATE.RECEIVED_AND_CHANGED,
      ]).map((state) => t(`drawStates.${state}`)),
      aggregate: (groupDraws) => getDefaultAggregate(groupDraws, "state"),
      aggregateFormatter: (drawState) =>
        drawState && drawState !== "-" ? t(`drawStates.${drawState}`) : "-",
      groupable: true,
      header: "Draw Status",
      id: "drawStatus",
      category: "Draw Details",
      value: (draw) => t(`drawStates.${draw.state}`),
    },
    {
      ...dateTimeColumnDefaults,
      aggregate: (groupDraws) =>
        getDateTimeRangeAggregate(groupDraws, "createdAt"),
      header: "Draw Creation Date",
      id: "drawCreationDate",
      category: "Draw Details",
      value: (draw) => draw.createdAt,
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDraws) =>
        getDateRangeAggregate(groupDraws, "submissionDate"),
      header: "Submission Date",
      id: "drawSentDate",
      category: "Draw Details",
      value: (draw) => draw.submissionDate,
    },
    {
      ...numberColumnDefaults,
      header: "Days to Fund",
      id: "daysToProcess",
      category: "Approvals",
      value: (draw) => {
        return draw.processingData?.daysToProcess;
      },
    },
    {
      ...fractionColumnDefaults,
      aggregate: (groupDraws) =>
        groupDraws.reduce(
          (acc, draw) => {
            const { numerator, denominator } = getApprovalsCount(draw);
            return {
              numerator: add(acc.numerator, numerator),
              denominator: add(acc.denominator, denominator),
            };
          },
          { numerator: 0, denominator: 0 }
        ),
      disableAggregateExport: true,
      header: "Approvals",
      id: "approvals",
      category: "Approvals",
      value: getApprovalsCount,
      valueExporter: (value) => {
        const { numerator, denominator } = value;
        return `${numerator} / ${denominator}`;
      },
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupDraws) => {
        const approvalStatus = getDefaultAggregate(
          groupDraws,
          getApprovalStatus,
          userId
        );
        return approvalStatus === "-"
          ? approvalStatus
          : t(`reviewState.${approvalStatus}`);
      },
      enumValues: Object.values(RelativeDrawReviewState).map((status) =>
        t(`reviewState.${status}`)
      ),
      groupable: true,
      header: "Approval Status",
      id: "approvalStatus",
      category: "Approvals",
      sortOrder: [
        RelativeDrawReviewState.PENDING_YOUR_APPROVAL,
        RelativeDrawReviewState.PENDING_OTHER_APPROVAL,
        RelativeDrawReviewState.APPROVED,
        RelativeDrawReviewState.NO_REVIEWERS,
        undefined,
      ],
      value: (draw) => t(`reviewState.${getApprovalStatus(draw, userId)}`),
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDraws) =>
        getDateRangeAggregate(groupDraws, "approvalData.approvalDate"),
      header: "Draw Approval Date",
      id: "drawApprovalDate",
      category: "Approvals",
      tooltip: `The date the signatory approved the draw, or, if there is no signatory, the date the last reviewer approved the draw.`,
      value: (draw) => draw.approvalData?.approvalDate,
    },
    {
      ...numberColumnDefaults,
      header: "Days to Approve",
      id: "daysToApprove",
      category: "Approvals",
      value: (draw) => {
        return draw.approvalData?.daysToApprove;
      },
    },
    {
      ...dateTimeColumnDefaults,
      aggregate: (groupDraws) =>
        getDateTimeRangeAggregate(groupDraws, "lastDocumentAddedAt"),
      header: "Last Document Added",
      id: "lastDocumentAdded",
      category: "Draw Details",
      value: (draw) => draw.lastDocumentAddedAt,
      valueFormatter: (value) =>
        value ? dateTimeColumnDefaults.valueFormatter(value) : null,
    },
    {
      ...booleanColumnDefaults,
      aggregate: (groupDraws) =>
        groupDraws.every((draw) => draw.state === DRAW_STATE.FUNDED),
      aggregateFormatter: (isFunded) =>
        isFunded && <TickCircleIcon color="success" />,
      header: "Funded Status",
      id: "fundedStatus",
      category: "Draw Details",
      value: (draw) => draw.state === DRAW_STATE.FUNDED,
      valueFormatter: (isFunded, item, { isGroupedValue }) => {
        if (isGroupedValue)
          return item.state === "FUNDED" ? "Funded" : "Not Funded";
        return isFunded && <TickCircleIcon color="success" />;
      },
      width: 60,
    },
    {
      ...listColumnDefaults,
      header: "Rules Passed",
      id: "passedRules",
      category: "Approvals",
      value: (draw) => formatRulesForDraw(draw, RULE_STATE.PASSED),
    },
    {
      ...listColumnDefaults,
      header: "Rules Failed",
      id: "failedRules",
      category: "Approvals",
      value: (draw) => formatRulesForDraw(draw, RULE_STATE.PENDING),
    },
    {
      ...listColumnDefaults,
      header: "Rules Ignored",
      id: "ignoredRules",
      category: "Approvals",
      value: (draw) => formatRulesForDraw(draw, RULE_STATE.IGNORED),
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, getReviewersForDraw),
      header: "Reviewers",
      id: "approvers",
      category: "Approvals",
      value: getReviewersForDraw,
      valueFormatter: (_value, draw) => renderReviewsForDraw(draw),
      width: 300,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, (draw) =>
          getReviewersForDraw(draw, "isPreparer")
        ),
      header: "Preparer",
      id: "preparer",
      category: "Approvals",
      value: (draw) => getReviewersForDraw(draw, "isPreparer"),
      valueFormatter: (_value, draw) =>
        renderReviewsForDraw(draw, "isPreparer"),
      width: 300,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, (draw) =>
          getReviewersForDraw(draw, "isFinal")
        ),
      header: "Signatory",
      id: "signatory",
      category: "Approvals",
      value: (draw) => getReviewersForDraw(draw, "isFinal"),
      valueFormatter: (_value, draw) => renderReviewsForDraw(draw, "isFinal"),
      width: 300,
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDraws) =>
        getDateRangeAggregate(groupDraws, "fundedDate"),
      header: "Date Funded",
      id: "dateFunded",
      category: "Draw Details",
      value: (draw) => draw.fundedDate,
    },
    {
      ...dateColumnDefaults,
      header: "Draw Status Last Changed",
      id: "drawStateLastChanged",
      category: "Draw Details",
      value: ({ recentStateUpdate }) => recentStateUpdate?.date,
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDraws) =>
        getDateRangeAggregate(groupDraws, "inspectionReportUploadedDate"),
      header: "Inspection Uploaded",
      id: "inspectionUploadedDate",
      category: "Draw Details",
      value: (draw) => draw.inspectionReportUploadedDate,
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDraws) =>
        getDateRangeAggregate(groupDraws, "titleReportUploadedDate"),
      header: "Title Report Uploaded",
      id: "titleReportUploadedDate",
      category: "Draw Details",
      value: (draw) => draw.titleReportUploadedDate,
    },
    {
      ...stringColumnDefaults,
      header: "Project Custom ID",
      id: "projectCustomId",
      category: "Project Information",
      value: (draw) => draw.project.customId,
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, getProjectLocation),
      header: "Project Location",
      id: "projectLocation",
      category: "Project Information",
      value: getProjectLocation,
      valueFormatter: renderShortener,
      width: 120,
    },
    {
      ...currencyColumnDefaults,
      header: "Project Total",
      id: "projectTotal",
      category: "Budget Progress",
      value: (draw) => draw.project.amount,
    },
    {
      ...currencyColumnDefaults,
      header: "Project Amount Remaining",
      id: "projectAmountRemaining",
      category: "Budget Progress",
      value: (draw) =>
        subtract(draw.project.amount, draw.project.requestedAmount),
    },
    {
      ...percentColumnDefaults,
      header: "Project % Complete (Net)",
      id: "projectPercentComplete",
      category: "Budget Progress",
      value: (draw) => draw.project.percentComplete,
      width: 110,
    },
    {
      ...percentColumnDefaults,
      header: "Project % Complete (Gross)",
      id: "grossProjectPercentComplete",
      category: "Budget Progress",
      value: (draw) => draw.project.grossPercentComplete,
      width: 125,
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectTemplates,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, "project.template.name"),
      header: "Project Type",
      id: "projectType",
      category: "Project Information",
      value: (draw) => draw.project.template.name,
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(PROJECT_STATUS_TYPE).map((status) =>
        t(`projectStatus.${status}`)
      ),
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, (draw) =>
          t(`projectStatus.${draw.project.status}`)
        ),
      groupable: true,
      header: "Project Status",
      id: "projectStatus",
      category: "Project Information",
      value: (draw) => t(`projectStatus.${draw.project.status}`),
      width: 120,
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.teams,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, (draw) =>
          get(draw, "project.team.name")
        ),
      groupable: true,
      header: "Team",
      hidden: !hasTeamManagement,
      id: "team",
      category: "Project Information",
      value: (draw) => get(draw, "project.team.name"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.projectRegions,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, (draw) =>
          get(draw, "project.projectRegion.region")
        ),
      groupable: true,
      header: "Project Region",
      id: "region",
      category: "Project Information",
      value: (draw) => get(draw, "project.projectRegion.region"),
    },
    {
      ...enumColumnDefaults,
      enumValues: orgEnumValues.productTypes,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, (draw) =>
          get(draw, "project.productType.type")
        ),
      groupable: true,
      header: "Product Type",
      id: "productType",
      category: "Project Information",
      value: (draw) => get(draw, "project.productType.type"),
    },
    {
      ...stringColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, "project.streetAddress"),
      header: "Street Address",
      id: "streetAddress",
      category: "Project Information",
      value: (draw) => draw.project.streetAddress,
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDraws) =>
        getDateRangeAggregate(groupDraws, "project.loanMaturityDate"),
      header: "Project Maturity Date",
      id: "projectMaturityDate",
      category: "Project Information",
      value: (draw) => draw.project.loanMaturityDate,
    },
    {
      ...dateColumnDefaults,
      aggregate: (groupDraws) =>
        getDateRangeAggregate(groupDraws, "project.startDate"),
      header: "Project Expected Start Date",
      id: "projectExpectedStartDate",
      category: "Project Information",
      value: (draw) => draw.project.startDate,
      width: 110,
    },
    {
      ...numberColumnDefaults,
      header: "Expected Project Length (mo)",
      id: "expectedProjectLength",
      category: "Project Information",
      value: (draw) => draw.project.expectedProjectLength,
      width: 110,
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupDraws) => getDefaultAggregate(groupDraws, "project.zip"),
      groupable: true,
      header: "Postal Code",
      id: "zip",
      category: "Project Information",
      value: (draw) => draw.project.zip,
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, "project.state"),
      groupable: true,
      header: "State/Province",
      id: "state",
      category: "Project Information",
      value: (draw) => draw.project.state,
    },
    {
      ...enumColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, "project.city"),
      groupable: true,
      header: "City",
      id: "city",
      category: "Project Information",
      value: (draw) => draw.project.city,
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, getProjectLenders),
      header: "Project Lenders",
      hidden: !canAccessFundingSources || !canAccessStakeholders,
      id: "lendersList",
      category: "Funding Sources",
      value: getProjectLenders,
      valueFormatter: renderShortener,
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, getProjectEquityPartners),
      header: "Project Equity Partners",
      hidden: !canAccessFundingSources || !canAccessStakeholders,
      id: "equityPartners",
      category: "Funding Sources",
      value: getProjectEquityPartners,
      valueFormatter: renderShortener,
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(groupDraws, getProjectGeneralContractors),
      header: "Project General Contractors",
      hidden: !canAccessStakeholders,
      id: "generalContractors",
      category: "Project Stakeholders",
      value: getProjectGeneralContractors,
      valueFormatter: renderShortener,
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(
          groupDraws,
          getStakeholderOrgsByType,
          ORGANIZATION_TYPE.SUBCONTRACTOR,
          true
        ),
      header: "Project Subcontractors",
      hidden: !canAccessStakeholders,
      id: "subcontractors",
      category: "Project Stakeholders",
      value: (draw) =>
        getStakeholderOrgsByType(draw, ORGANIZATION_TYPE.SUBCONTRACTOR, true),
      valueFormatter: renderShortener,
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(
          groupDraws,
          getStakeholderOrgsByType,
          ORGANIZATION_TYPE.BORROWER,
          true
        ),
      header: "Project Borrowers",
      hidden: !canAccessFundingSources || !canAccessStakeholders,
      id: "borrowers",
      category: "Funding Sources",
      value: (draw) =>
        getStakeholderOrgsByType(draw, ORGANIZATION_TYPE.BORROWER, true),
      valueFormatter: renderShortener,
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(
          groupDraws,
          getStakeholderOrgsByType,
          ORGANIZATION_TYPE.TITLE_COMPANY,
          true
        ),
      header: "Project Title Companies",
      hidden: !canAccessStakeholders,
      id: "titleCompanies",
      category: "Project Stakeholders",
      value: (draw) =>
        getStakeholderOrgsByType(draw, ORGANIZATION_TYPE.TITLE_COMPANY, true),
      valueFormatter: renderShortener,
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(
          groupDraws,
          getStakeholderOrgsByType,
          ORGANIZATION_TYPE.VENDOR,
          true
        ),
      header: "Project Vendors",
      hidden: !canAccessStakeholders,
      id: "vendors",
      category: "Project Stakeholders",
      value: (draw) =>
        getStakeholderOrgsByType(draw, ORGANIZATION_TYPE.VENDOR, true),
      valueFormatter: renderShortener,
    },
    {
      ...listColumnDefaults,
      aggregate: (groupDraws) =>
        getDefaultAggregate(
          groupDraws,
          getStakeholderOrgsByType,
          ORGANIZATION_TYPE.INSPECTOR,
          true
        ),
      header: "Project Inspection Companies",
      hidden: !canAccessStakeholders,
      id: "inspectionCompany",
      category: "Project Stakeholders",
      value: (draw) =>
        getStakeholderOrgsByType(draw, ORGANIZATION_TYPE.INSPECTOR, true),
      valueFormatter: renderShortener,
    },
    {
      ...dateColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspection Date",
        hasInspectionReportWorkflow
      ),
      id: "inspectionDate",
      category: "Inspection",
      value: (draw) => draw?.documentInspectionReport?.inspectionDate,
      width: 110,
    },
    {
      ...dateColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspection Expected Completion Date",
        hasInspectionReportWorkflow
      ),
      id: "inspectionExpectedCompletionDate",
      category: "Inspection",
      value: (draw) => draw?.documentInspectionReport?.expectedCompletionDate,
      width: 130,
    },
    {
      ...stringColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspector Name",
        hasInspectionReportWorkflow
      ),
      id: "inspectorName",
      category: "Inspection",
      value: (draw) => draw?.documentInspectionReport?.inspectorName,
    },
    {
      ...stringColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspector Notes",
        hasInspectionReportWorkflow
      ),
      id: "inspectorNotes",
      category: "Inspection",
      value: (draw) => draw?.documentInspectionReport?.inspectorNotes,
      valueFormatter: renderShortener,
    },
    {
      ...percentColumnDefaults,
      header: getDocumentInspectionReportHeader(
        "Inspection Percent Complete",
        hasInspectionReportWorkflow
      ),
      id: "inspectionPercentComplete",
      category: "Inspection",
      value: (draw) => draw?.documentInspectionReport?.percentComplete,
      width: 147,
    },
    {
      ...stringColumnDefaults,
      header: "Inspector Name (Report)",
      hidden: !hasInspectionReportWorkflow,
      id: "inspectorNameReport",
      category: "Inspection",
      value: (draw) => draw?.drawInspectionReport?.inspectorName,
    },
    {
      ...dateTimeColumnDefaults,
      header: "Inspection Requested Date (Report)",
      hidden: !hasInspectionReportWorkflow,
      id: "inspectionRequestedDateReport",
      category: "Inspection",
      value: (draw) => {
        const requested = draw?.drawInspectionReport?.insertedAt;
        return requested ? `${requested}Z` : null;
      },
      width: 140,
    },
    {
      ...dateColumnDefaults,
      header: "Inspection Date (Report)",
      hidden: !hasInspectionReportWorkflow,
      id: "inspectionDateReport",
      category: "Inspection",
      value: (draw) => draw?.drawInspectionReport?.date,
    },
    {
      ...dateTimeColumnDefaults,
      header: "Inspection Submitted Date (Report)",
      hidden: !hasInspectionReportWorkflow,
      id: "inspectionSubmittedDateReport",
      category: "Inspection",
      value: (draw) => {
        const submitted = draw?.drawInspectionReport?.submittedAt;
        return submitted ? `${submitted}Z` : null;
      },
      width: 135,
    },
    {
      ...stringColumnDefaults,
      header: "Created By",
      id: "createdBy",
      category: "Draw Details",
      value: (draw) => get(draw, "createdByUser.fullName"),
    },
    {
      ...stringColumnDefaults,
      header: "Report Last Downloaded By",
      hidden: !hasReportTemplating,
      id: "reportDownloadedBy",
      category: "Draw Details",
      value: (draw) => get(draw, "reportDownloadedBy.fullName"),
    },
    {
      ...dateTimeColumnDefaults,
      header: "Report Last Downloaded At",
      hidden: !hasReportTemplating,
      id: "reportDownloadedAt",
      category: "Draw Details",
      value: (draw) => draw.reportDownloadedAt,
      valueFormatter: (value) => (value ? formatDateTime(value) : null),
      width: 120,
    },
    {
      ...currencyColumnDefaults,
      header: "Project Total Funding",
      hidden: !canAccessFundingSources,
      id: "totalFunding",
      category: "Funding Sources",
      tooltip: "Sum of all funding sources",
      value: (draw) => draw.project.fundingSourceAggregates.totalFundedAmount,
    },
    {
      ...currencyColumnDefaults,
      header: "Project Commitments",
      hidden: !canAccessFundingSources,
      id: "projectCommitments",
      category: "Funding Sources",
      tooltip: "Sum of your organization's funding commitments",
      value: (draw) => draw.project.commitmentAggregates.totalFundedAmount,
    },
    {
      ...currencyColumnDefaults,
      header: "Draw Total Funded",
      hidden: !canAccessFundingSources,
      id: "drawFunded",
      category: "Funding Sources",
      tooltip: "Total amount funded on the draw from all funding sources",
      value: (draw) => draw.fundingSourceAggregates.totalDisbursedAmount,
      width: 145,
    },
    {
      ...currencyColumnDefaults,
      header: "Draw Commitments",
      hidden: !canAccessFundingSources,
      id: "drawCommitments",
      category: "Funding Sources",
      tooltip: "Sum of your organization's funding commitments this draw",
      value: (draw) => draw.commitmentAggregates.totalDisbursedAmount,
    },
  ].concat(
    Object.values(FUNDING_SOURCE_TYPE)
      .map((fundingSourceType) => {
        const titleCaseType = t(`fundingSourceType.${fundingSourceType}`);
        const lowerCaseType = titleCaseType.toLowerCase();
        const camelCaseType = camelCase(titleCaseType);
        const startCaseType = camelCaseType[0]
          .toUpperCase()
          .concat(camelCaseType.slice(1));

        return [
          {
            ...currencyColumnDefaults,
            header: `Project Total ${titleCaseType}`,
            hidden: !canAccessFundingSources,
            id: `project${startCaseType}Committed`,
            category: "Funding Sources",
            tooltip: `Sum of all ${lowerCaseType} funding sources`,
            value: (draw) =>
              draw.project.fundingSourceAggregates[fundingSourceType].total,
            width: 165,
          },
          {
            ...percentColumnDefaults,
            header: `Project Total ${titleCaseType} %`,
            hidden: !canAccessFundingSources,
            id: `projectPercent${startCaseType}Committed`,
            category: "Funding Sources",
            tooltip: `Percent of funding that is ${lowerCaseType}`,
            value: (draw) => {
              const {
                [fundingSourceType]: { total: fundingForType },
                totalFundedAmount,
              } = draw.project.fundingSourceAggregates;

              return totalFundedAmount === 0
                ? 0
                : divide(fundingForType, totalFundedAmount);
            },
            width: 130,
          },
          {
            ...currencyColumnDefaults,
            header: `Project Total ${titleCaseType} Funded To Date`,
            hidden: !canAccessFundingSources,
            id: `project${startCaseType}Funded`,
            category: "Funding Sources",
            tooltip: `Total amount of ${lowerCaseType} that has been funded`,
            value: (draw) =>
              draw.project.fundingSourceAggregates[fundingSourceType].disbursed,
            width: 130,
          },
          {
            ...currencyColumnDefaults,
            header: `Project Total ${titleCaseType} Remaining`,
            hidden: !canAccessFundingSources,
            id: `project${startCaseType}Remaining`,
            category: "Funding Sources",
            tooltip: `Total amount of ${lowerCaseType} that remains unfunded`,
            value: (draw) => {
              const {
                [fundingSourceType]: {
                  total: fundingForType,
                  disbursed: disbursedForType,
                },
              } = draw.project.fundingSourceAggregates;
              return subtract(fundingForType, disbursedForType);
            },
            width: 140,
          },
          {
            ...percentColumnDefaults,
            header: `Project Total ${titleCaseType} % Funded`,
            hidden: !canAccessFundingSources,
            id: `project${startCaseType}PercentFunded`,
            category: "Funding Sources",
            tooltip: `Percent of total ${lowerCaseType} that has been funded`,
            value: (draw) => {
              const {
                [fundingSourceType]: {
                  total: fundingForType,
                  disbursed: disbursedForType,
                },
              } = draw.project.fundingSourceAggregates;

              return fundingForType === 0
                ? 0
                : divide(disbursedForType, fundingForType);
            },
            width: 120,
          },
          {
            ...currencyColumnDefaults,
            header: `Project ${titleCaseType} Commitment`,
            hidden: !canAccessFundingSources,
            id: `project${startCaseType}Commitment`,
            category: "Funding Sources",
            tooltip: `Sum of your organization's ${lowerCaseType} commitments`,
            value: (draw) =>
              draw.project.commitmentAggregates[fundingSourceType].total,
          },
          {
            ...currencyColumnDefaults,
            header: `Project ${titleCaseType} Commitment Funded To Date`,
            hidden: !canAccessFundingSources,
            id: `project${startCaseType}CommitmentFunded`,
            category: "Funding Sources",
            tooltip: `Amount of ${lowerCaseType} that has already been funded by your organization`,
            value: (draw) =>
              draw.project.commitmentAggregates[fundingSourceType].disbursed,
            width: 130,
          },
          {
            ...currencyColumnDefaults,
            header: `Project ${titleCaseType} Commitment Remaining`,
            hidden: !canAccessFundingSources,
            id: `project${startCaseType}CommitmentRemaining`,
            category: "Funding Sources",
            tooltip: `Amount of ${lowerCaseType} your organization has committed to that remains unfunded`,
            value: (draw) => {
              const {
                [fundingSourceType]: {
                  total: commitmentForType,
                  disbursed: disbursedCommitmentForType,
                },
              } = draw.project.commitmentAggregates;
              return subtract(commitmentForType, disbursedCommitmentForType);
            },
          },
          {
            ...percentColumnDefaults,
            header: `Project ${titleCaseType} Commitment % Funded`,
            hidden: !canAccessFundingSources,
            id: `project${startCaseType}CommitmentPercentFunded`,
            category: "Funding Sources",
            tooltip: `Percent of your organization's ${lowerCaseType} commitments that have been funded`,
            value: (draw) => {
              const {
                [fundingSourceType]: {
                  total: commitmentForType,
                  disbursed: disbursedCommitmentForType,
                },
              } = draw.project.commitmentAggregates;

              return commitmentForType === 0
                ? 0
                : divide(disbursedCommitmentForType, commitmentForType);
            },
          },
          {
            ...currencyColumnDefaults,
            header: `Draw Total ${titleCaseType} Funded`,
            hidden: !canAccessFundingSources,
            id: `draw${startCaseType}Funded`,
            category: "Funding Sources",
            tooltip: `Amount of ${lowerCaseType} that has been funded this draw`,
            value: (draw) =>
              draw.fundingSourceAggregates[fundingSourceType].disbursed,
            width: 130,
          },
          {
            ...currencyColumnDefaults,
            header: `Draw ${titleCaseType} Commitment Funded`,
            hidden: !canAccessFundingSources,
            id: `draw${startCaseType}CommitmentFunded`,
            category: "Funding Sources",
            tooltip: `Amount of ${lowerCaseType} that has been funded by your organization this draw`,
            value: (draw) =>
              draw.commitmentAggregates[fundingSourceType].disbursed,
          },
        ];
      })
      .flat()
  );
}

function getControls(propsControls, propsEditTableViews) {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      disable={[FastDataTableDownloadDocuments]}
      isReport
      pinnedFilters={[
        "projectStatus",
        "projectType",
        "productType",
        "region",
        "team",
      ]}
      searchPlaceholder="Search Draws..."
    />
  );
}

export function ReportsDrawsTable({
  drawAssessmentQuestions,
  draws,
  orgData,
  selectedOrganization,
}) {
  const history = useHistory();
  const { hasPermission, userId } = useContext(UserContext);

  const projectCustomFieldColumns = useMemo(() => {
    return customFieldFastColumns(draws, "project.customFields");
  }, [draws]);

  const drawAssessmentColumns = useMemo(() => {
    return getDrawAssessmentColumns(drawAssessmentQuestions);
  }, [drawAssessmentQuestions]);

  const columns = useMemo(() => {
    return getColumns(hasPermission, orgData, userId)
      .concat(drawAssessmentColumns)
      .concat(projectCustomFieldColumns);
  }, [
    drawAssessmentColumns,
    projectCustomFieldColumns,
    hasPermission,
    orgData,
    userId,
  ]);

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(
        PERMISSION_ACTION.SAVE_TABLE_VIEWS,
        selectedOrganization
      )}
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={selectedOrganization.id}
      defaultViews={defaultViews}
      scopeViewsToOrganization
      tableName="ReportsDrawsTable"
    >
      {(propsEditTableViews) => (
        <FastDataTable
          columns={columns}
          controls={(propsControls) =>
            getControls(propsControls, propsEditTableViews)
          }
          items={draws}
          onClickRow={(draw) =>
            history.push(`/projects/${draw.projectId}/draws/${draw.id}`)
          }
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(propsEditTableViews, "views.0.config")
          }
        />
      )}
    </EditTableViews>
  );
}

ReportsDrawsTable.propTypes = {
  draws: PropTypes.array.isRequired,
};
