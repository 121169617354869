import { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFeatureFlags } from "FeatureFlags";
import {
  FileUploadStatus,
  UploadManager,
  UploadSplitter,
} from "components/containers";
import analytics from "helpers/analytics";

export const UploadsViewerContext = createContext({
  openUploads: false,
  setOpenUploads: () => {},
});

// "File" and "Upload" are distinguished as follows:
// - "File" the underlying, raw file which a user selects to upload to the application
// - "Upload" is a persisted record and contains a reference to the file
export const UploadContext = createContext({
  uploadProgress: {},
  setUploadProgress: () => {},
});

export const UploadSplitterContext = createContext({
  openSplitter: false,
  setOpenSplitter: () => {},
});

export const UploadContextProvider = ({ children }) => {
  const featureFlags = useFeatureFlags();
  const useNewUploads = featureFlags.isEnabled("use-upload-refactor");
  const history = useHistory();
  const [viewer, setViewer] = useState(null);
  const [splitter, setSplitter] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const openUploads = !!viewer;
  const openSplitter = !!splitter;
  const setOpenUploads = (uploadId, drawId, projectId) => {
    analytics.track("View Uploads Opened");
    setViewer({ drawId, projectId, uploadId });
    history.push(history.location);
  };
  const handleSetUploadProgress = (id, progress) => {
    setUploadProgress((prevState) => ({ ...prevState, [id]: progress }));
  };
  const setOpenSplitter = ({ onSplit, projectId, uploadId }) => {
    analytics.track("Quick Splitter Opened");
    setSplitter({ onSplit, projectId, uploadId });
    history.push(history.location);
  };

  useEffect(
    () =>
      history.listen((_newLocation, action) => {
        if (splitter || viewer) {
          if (action === "POP") {
            setSplitter(null);
            setViewer(null);
          }
        }
      }),
    // eslint-disable-next-line
    [splitter, viewer]
  );

  return (
    <UploadsViewerContext.Provider value={{ openUploads, setOpenUploads }}>
      <UploadContext.Provider
        value={{
          setUploadProgress: handleSetUploadProgress,
        }}
      >
        <UploadSplitterContext.Provider
          value={{ openSplitter, setOpenSplitter }}
        >
          {children}
          {!useNewUploads && (
            <FileUploadStatus uploadProgress={uploadProgress} />
          )}
          {viewer && (
            <UploadManager
              drawId={viewer.drawId}
              onClose={() => setViewer(null)}
              projectId={viewer.projectId}
              uploadId={viewer.uploadId}
            />
          )}
          {splitter && (
            <UploadSplitter
              onClose={() => setSplitter(null)}
              onSplit={splitter.onSplit}
              projectId={splitter.projectId}
              uploadId={splitter.uploadId}
            />
          )}
        </UploadSplitterContext.Provider>
      </UploadContext.Provider>
    </UploadsViewerContext.Provider>
  );
};
