export const DRAW_SUMMARY_TEMPLATE_TYPE = {
  DIVISION_ONLY: "DIVISION_ONLY",
  EXTRA_DRAW_COLUMNS: "EXTRA_DRAW_COLUMNS",
  GENERIC: "GENERIC",
  JPMORGAN: "JPMORGAN",
  PREVIOUS_DRAW_COLUMNS: "PREVIOUS_DRAW_COLUMNS",
  SINGLE_PAGE: "SINGLE_PAGE",
  SUPER_LINE_ITEMS: "SUPER_LINE_ITEMS",
  WITH_PERCENTAGES_AND_NO_RETAINAGE: "WITH_PERCENTAGES_AND_NO_RETAINAGE",
};
