import { Fragment, useContext } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { VendorForm } from "components/templates";
import { Loadable, Modal } from "components/materials";
import { majorScale, toaster } from "helpers/utilities";
import { get } from "lodash";
import { UserContext } from "helpers/behaviors";
import { ORGANIZATION_TYPE } from "helpers/enums";

const CREATE_VENDOR = gql`
  mutation AddOrganization(
    $email: String
    $streetAddress: String
    $city: String
    $stateValue: AddressState
    $zip: String
    $name: String!
    $phoneNumber: String
    $type: OrganizationType
    $vendorCostCode: String
    $sourceOrganizationId: String!
    $is1099: Boolean
    $isPayable: Boolean
  ) {
    addOrganization(
      email: $email
      name: $name
      phoneNumber: $phoneNumber
      streetAddress: $streetAddress
      city: $city
      state: $stateValue
      zip: $zip
      type: $type
      vendorCostCode: $vendorCostCode
      sourceOrganizationId: $sourceOrganizationId
      is1099: $is1099
      isPayable: $isPayable
    ) {
      id
      emailAddresses
      name
      streetAddress
      city
      state
      zip
      members {
        id
        email
        name
        phone
      }
      phoneNumbers
      type
      vendorCostCode
    }
  }
`;

export function NewVendorModal({
  closeSidebar,
  history,
  refetchQuery,
  selectedCustomerAccount,
}) {
  const { hasPermission } = useContext(UserContext);
  const [createVendor, createVendorResult] = useMutation(CREATE_VENDOR, {
    onCompleted: (result) => {
      const newOrgName = get(result, "addOrganization.name");
      history.push(`/organizations`);
      refetchQuery();
      toaster.success(`Organization ${newOrgName} has been created.`, {
        duration: 2.5,
      });
    },
  });

  if (createVendorResult.loading) return <Loadable loading />;
  const vendor = {
    documents: [],
    members: [],
    projects: [],
    type: ORGANIZATION_TYPE.OTHER,
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={VendorForm.initialValues(vendor)}
        validate={(values) => VendorForm.validate(values, hasPermission)}
        onSubmit={VendorForm.onSubmit(selectedCustomerAccount.id, createVendor)}
      >
        {(form) => (
          <Modal
            open
            title="Add Organization"
            onClose={closeSidebar}
            onConfirm={form.handleSubmit}
            confirmLabel="Add Organization"
            hasFooter
            topOffset={majorScale(10)}
          >
            <VendorForm
              form={form}
              creatingNewVendor
              selectedCustomerAccountId={selectedCustomerAccount.id}
              hasPermission={hasPermission}
            />
          </Modal>
        )}
      </Formik>
    </Fragment>
  );
}
