import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Heading } from "components/materials";

class WizardHeading extends PureComponent {
  render() {
    const { content } = this.props;
    return <Heading content={content} />;
  }
}

WizardHeading.propTypes = {
  content: PropTypes.node.isRequired,
};

export default WizardHeading;
