import { useState, Fragment } from "react";
import { majorScale } from "helpers/utilities";
import { get } from "lodash";
import gql from "graphql-tag";
import { Button, Loadable } from "components/materials";
import { useQuery } from "@apollo/react-hooks";
import { UploadParserStep } from "./UploadParserStep";
import { UploadTemplateStep } from "./UploadTemplateStep";

const QUERY = gql`
  query OnboardingWizardBudgetUploadStepQuery($projectId: String!) {
    project(id: $projectId) {
      id
      budgetParser {
        id
        config
      }
      type
      template {
        id
        budgetParser {
          id
          config
        }
      }
    }
  }
`;

export function BudgetUploadStep({
  goBack,
  navigateToStep,
  project,
  updateOnboardingData,
}) {
  const { data, loading } = useQuery(QUERY, {
    variables: { projectId: project.id },
  });

  const [useTemplate, setUseTemplate] = useState(false);
  if (loading) return <Loadable loading />;
  const customParser =
    (get(data, "project.budgetParser.id") &&
      get(data, "project.budgetParser.config")) ||
    get(data, "project.template.budgetParser.config");

  return (
    <Fragment>
      {customParser && !useTemplate ? (
        <UploadParserStep
          navigateToStep={navigateToStep}
          project={project}
          setUseTemplate={setUseTemplate}
          updateOnboardingData={updateOnboardingData}
        />
      ) : (
        <UploadTemplateStep
          navigateToStep={navigateToStep}
          project={project}
          setUseTemplate={setUseTemplate}
          updateOnboardingData={updateOnboardingData}
        />
      )}

      <Button
        onClick={() => {
          goBack();
        }}
        marginTop={majorScale(2)}
        marginBottom={majorScale(4)}
      >
        Back
      </Button>
    </Fragment>
  );
}
