import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Formik, FieldArray } from "formik";
import { Pane, Form, Popover, Text } from "components/materials";
import { includes } from "lodash";
import { majorScale, Position } from "helpers/utilities";

const initialValues = (projects, selectedProjectIds) => ({
  projects: projects.map(({ id, name }) => ({
    id,
    name,
    selected: selectedProjectIds ? includes(selectedProjectIds, id) : true,
  })),
});

const validate = () => {};

const onSubmit = (onChange) => (values) => {
  const selectedProjectIds = values.projects.reduce((ids, { id, selected }) => {
    if (selected) ids.push(id);
    return ids;
  }, []);
  onChange(selectedProjectIds);
};

class ReportProjectSelector extends PureComponent {
  renderForm = (form) => {
    const { onClose } = this.props;

    return (
      <Pane margin={majorScale(2)}>
        <Form.Generic handleCancel={onClose} handleSubmit={form.handleSubmit}>
          <FieldArray
            name="projects"
            render={() => (
              <Pane height={300} overflowY="scroll">
                {form.values.projects.map(({ id, name }, index) => (
                  <Form.Checkbox
                    key={id}
                    label={name}
                    name={`projects.${index}.selected`}
                  />
                ))}
              </Pane>
            )}
          />
        </Form.Generic>
      </Pane>
    );
  };

  renderTrigger = () => (
    <Text color="selected" textDecoration="underline" cursor="pointer">
      Edit Projects
    </Text>
  );

  render() {
    const {
      onChange,
      onClose,
      onOpen,
      open,
      projects,
      selectedProjectIds,
    } = this.props;

    return (
      <Popover
        content={
          <Formik
            initialValues={initialValues(projects, selectedProjectIds)}
            validate={validate}
            onSubmit={onSubmit(onChange)}
          >
            {this.renderForm}
          </Formik>
        }
        onClose={onClose}
        onOpen={onOpen}
        isShown={open}
        position={Position.BOTTOM_RIGHT}
        margin={majorScale(2)}
      >
        {this.renderTrigger()}
      </Popover>
    );
  }
}

ReportProjectSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  open: PropTypes.bool,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedProjectIds: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default ReportProjectSelector;
