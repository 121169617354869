import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { get, partition } from "lodash";
import { AddIcon } from "evergreen-ui";
import { LineItemFormBlankSlate } from "components/templates";
import { Alert, Text, Pane, Form, Link } from "components/materials";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import unformatNumber from "helpers/unformatNumber";
import { formatCurrency } from "helpers/formatCurrency";
import { add } from "helpers/math";
import DrawLockdownBanner from "../DrawLockdownBanner";

function getSummary(form) {
  const splits = (nameKey) =>
    form.values.lineItems.filter(
      ({ lineItemObject }) => !!get(lineItemObject, nameKey)
    ).length;
  const total = (amountKey) =>
    formatCurrency(
      form.values.lineItems.reduce(
        (sum, lineItem) => add(sum, lineItem[amountKey]),
        0
      )
    );

  const summary =
    form.values.type === DOCUMENT_TYPE_NAME.INVOICE
      ? [
          t("documentReview.splitsSummary", {
            count: splits("name"),
          }),
          `Total Amount: ${total("amount")}`,
        ]
      : [
          t("documentReview.splitsSummary", {
            count: splits("descriptionOfWork"),
          }),
          `Total Amount: ${total("applicationAmount")}`,
        ];

  return summary.join(", ");
}

function DocumentLineItems(props) {
  const {
    children,
    collapsedPanels,
    disableForm,
    lockdownDraw,
    form,
    getEmptyLineItem,
    lineItemLimit,
    name,
    onAddSplit,
    sectionHeading,
    selectedDraw,
    togglePanel,
  } = props;

  const lineItems = get(props, `form.values.${name}`, []);

  const [matchedLineItems, unmatchedLineItems] = partition(
    lineItems,
    (lineItem) => {
      return (
        !!get(lineItem, "lineItemObject.id") &&
        (unformatNumber(get(lineItem, "amount", 0)) ||
          unformatNumber(get(lineItem, "applicationAmount", 0)) ||
          unformatNumber(get(lineItem, "materialsStoredAmount", 0)) ||
          unformatNumber(get(lineItem, "retainageAmount", 0)))
      );
    }
  );

  if (!selectedDraw) return null;

  const disableAddSplit =
    disableForm || form.values.__disabled || lineItems.length >= lineItemLimit;

  const isCollapsed = collapsedPanels.includes("lineItems");

  return (
    <FieldArray name={name}>
      {(fieldArray) => (
        <Form.Section
          heading={sectionHeading}
          isCollapsible
          isCollapsed={isCollapsed}
          onCollapse={() => togglePanel("lineItems")}
          action={
            <Link
              color={disableAddSplit ? "disabled" : "#3366FF"}
              cursor={disableAddSplit ? "not-allowed" : "pointer"}
              purpose="document-review line-items add"
              onClick={() => {
                if (!disableAddSplit) {
                  const currentLineItems = form.values.lineItems;
                  const addedItemIndex = currentLineItems.length;
                  fieldArray.push(getEmptyLineItem(addedItemIndex));
                  onAddSplit &&
                    onAddSplit(
                      currentLineItems.concat(getEmptyLineItem(addedItemIndex))
                    );
                  if (isCollapsed) togglePanel("lineItems");
                }
                return {};
              }}
              size={300}
            >
              <AddIcon size={12} marginRight={majorScale(1)} />
              Add Split
            </Link>
          }
          padding={0}
          summary={getSummary(form)}
        >
          {lockdownDraw && (
            <DrawLockdownBanner
              title={t("lockdownBanner.documentLineItemTitle")}
            />
          )}
          {unmatchedLineItems.length > 0 && lineItems.length > 1 && (
            <Pane>
              <Alert intent="warning">
                <Pane
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text color="muted">
                    {t("documentReview.unmatchedLineItemsWarning", {
                      count: unmatchedLineItems.length,
                    })}
                  </Text>

                  <Link
                    size={300}
                    purpose="document-review line-items remove-unmatched"
                    onClick={() =>
                      form.setFieldValue("lineItems", matchedLineItems)
                    }
                  >
                    Remove
                  </Link>
                </Pane>
              </Alert>
            </Pane>
          )}

          {lineItems.length === 0 ? (
            <LineItemFormBlankSlate
              addButtonText="Add Split"
              onAdd={() => fieldArray.push(getEmptyLineItem(0))}
              text={t("documentReview.noLineItems")}
            />
          ) : (
            children(fieldArray)
          )}
        </Form.Section>
      )}
    </FieldArray>
  );
}

DocumentLineItems.defaultProps = {
  collapsedPanels: [],
  name: "lineItems",
  sectionHeading: "Line Items",
};

DocumentLineItems.propTypes = {
  children: PropTypes.func,
  collapsedPanels: PropTypes.array,
  getEmptyLineItem: PropTypes.func,
  name: PropTypes.string,
  sectionHeading: PropTypes.string,
  selectedDraw: PropTypes.object,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          previousLineItem: PropTypes.shape({
            vendorLineItems: PropTypes.arrayOf(
              PropTypes.shape({
                vendorId: PropTypes.string,
                retainageToDateAmount: PropTypes.number,
              })
            ),
          }),
        })
      ),
    })
  ),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      isBackup: PropTypes.bool,
      applicationNumber: PropTypes.string,
      periodToDate: PropTypes.string,
      isGeneralContractor: PropTypes.bool,
      totalCompletedAndStoredToDate: PropTypes.number,
      totalRetainage: PropTypes.number,
      currentPaymentDue: PropTypes.number,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          descriptionOfWork: PropTypes.string,
          applicationAmount: PropTypes.number,
          materialsStoredAmount: PropTypes.number,
          retainageAmount: PropTypes.number,
        })
      ),
    }),
  }),
  updateLineItemIndices: PropTypes.func,
};

export default DocumentLineItems;
