import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Prompt } from "react-router-dom";
import {
  DocumentReview,
  DocumentsViewerSidebar,
  FileView,
} from "components/templates";
import {
  Button,
  Confirm,
  FileViewer,
  Heading,
  Pane,
} from "components/materials";
import { DocumentContext } from "contexts/documentContext";
import { find, findIndex, flatten, get, map } from "lodash";
import { majorScale } from "helpers/utilities";
import {
  userCanApproveDocument,
  getDocumentApprovalStatus,
} from "helpers/documentHelpers";
import { useBeforeUnload, UserContext } from "helpers/behaviors";
import { DOCUMENT_TYPE_NAME, PERMISSION_ACTION } from "helpers/enums";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { formatCurrency } from "helpers/formatCurrency";
import { DocumentFormContextProvider } from "contexts/documentFormContext";
import analytics, { documentValues } from "helpers/analytics";
import { InvoiceSummaryBlankSlate } from "./InvoiceSummaryBlankSlate";
import { DrawDetail } from "../containers/DrawHeader/DrawDetails";

const INITIAL_ANNOTATIONS_STATE = {
  initialAnnotations: null,
  currentAnnotations: null,
  annotationsLoaded: false,
  hasUnsavedAnnotations: false,
};

function DocumentsViewerHeader({ correctResult, document, draws, match }) {
  const { drawId, lineItemId, vendorId } = match.params;
  const drawName = get(document, "draw.name", "");
  const projectName = get(document, "project.name", "");
  const vendorName = get(document, "vendor.name", "");

  // Using a callback here prevents draw total flashing "$0.00" momentarily
  const getRequestedAmount = useCallback(
    () => get(find(draws, ["id", drawId]), "requestedAmount", 0),
    [drawId, draws]
  );
  const getDrawHasUnsplitUploads = useCallback(
    () => get(find(draws, ["id", drawId]), "uploads", []),
    [drawId, draws]
  );
  const drawHasUnsplitUploads = getDrawHasUnsplitUploads().length > 0;
  const [requestedAmount, setRequestedAmount] = useState(getRequestedAmount());

  useEffect(() => {
    // without this the draw total amount tries to update while the correct mutation isn't done yet and it flashes different intermediary numbers
    // with this in place the requested amount will only update after the mutation is completely done and will show the correct total
    !correctResult.loading && setRequestedAmount(getRequestedAmount());
  }, [correctResult.loading, getRequestedAmount]);

  function getLineItemName() {
    const lineItem = find(
      get(document, "draw.lineItems", []),
      (lineItem) => lineItem.id === lineItemId
    );
    return get(lineItem, "name", "");
  }

  const CardHeading = () => {
    if (match.url.includes("/hard_costs/"))
      return `${projectName} ${drawName} ${vendorName} ${getLineItemName()} Documents`;
    if (lineItemId)
      return `${projectName} ${drawName} ${getLineItemName()} Documents`;
    if (vendorId) return `${vendorName} ${projectName} Documents`;
    if (drawId) return `${projectName} ${drawName} Documents`;
    return `${projectName} Documents`;
  };

  return (
    <Pane width="100%" display="flex" justifyContent="space-between">
      <Heading size={500} fontWeight="bold">
        <CardHeading />
      </Heading>
      {drawId && (
        <Pane textAlign="right" marginRight={majorScale(2)}>
          <DrawDetail
            label="Draw Total"
            value={formatCurrency(requestedAmount)}
            labelExplanation={
              drawHasUnsplitUploads
                ? "You have documents waiting to be split that may be contributing toward the draw total."
                : null
            }
          />
        </Pane>
      )}
    </Pane>
  );
}

function FileViewTabs({
  document,
  selectedFile,
  setSelectedFile,
  showInvoiceSummaryTab,
  summaryFile,
}) {
  const summarySelected = selectedFile === summaryFile;

  if (showInvoiceSummaryTab) {
    return (
      <Pane marginTop={majorScale(1)}>
        <Button
          appearance="minimal"
          marginX={majorScale(2)}
          isActive={!summarySelected}
          onClick={() => setSelectedFile(document)}
        >
          This Invoice
        </Button>
        <Button
          appearance="minimal"
          isActive={summarySelected}
          onClick={() => {
            setSelectedFile(summaryFile);
            analytics.track("Document Preview Changed", {
              ...documentValues(document),
              previewType: "Invoice Summary",
            });
          }}
        >
          Invoice Summary
        </Button>
      </Pane>
    );
  }

  return null;
}

function DocumentsViewer({ onClose }) {
  const {
    correctMutation,
    document,
    documents,
    documentReviewers,
    draws,
    history,
    match,
  } = useContext(DocumentContext);

  const { hasPermission, user } = useContext(UserContext);
  const [viewerDirty, setViewerDirty] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState(documents);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  // if the user triggers the warning via the close button and confirms
  // set the bypass to true so that the router Prompt will not fire
  // this is necessary because the route change is not called until the close animation is complete
  const [bypassRouterWarning, setBypassRouterWarning] = useState(false);
  const [selectedFile, setSelectedFile] = useState(document);
  useEffect(() => setSelectedFile(document), [document]);
  const [annotationsState, annotationsDispatch] = useReducer(
    annotationsReducer,
    INITIAL_ANNOTATIONS_STATE
  );

  function annotationsReducer(currentState, { action, annotations }) {
    switch (action) {
      case "ANNOTATIONS_LOADED":
        return {
          initialAnnotations: annotations,
          currentAnnotations: annotations,
          annotationsLoaded: true,
          hasUnsavedAnnotations: false,
        };
      case "ANNOTATIONS_UPDATED":
        return currentState.annotationsLoaded
          ? {
              ...currentState,
              currentAnnotations: annotations,
              hasUnsavedAnnotations:
                currentState.initialAnnotations !== annotations,
            }
          : currentState;
      case "ANNOTATIONS_RESET":
        return INITIAL_ANNOTATIONS_STATE;
      default:
        return currentState;
    }
  }
  const { hasUnsavedAnnotations } = annotationsState;

  const [, correctResult] = correctMutation;
  useEffect(() => {
    // ensure navigation warning doesn't pop up underneath the frozen error warning
    if (correctResult.error) setBypassRouterWarning(true);
    else setBypassRouterWarning(!!correctResult.loading);
  }, [correctResult.loading, correctResult.error]);

  useBeforeUnload(viewerDirty || hasUnsavedAnnotations);

  const summaryFile = get(document, "draw.invoiceSummary");

  const showInvoiceSummaryTab =
    hasPermission(PERMISSION_ACTION.INVOICE_SUMMARY_TAB) &&
    document.type === DOCUMENT_TYPE_NAME.INVOICE;

  const selectedIndex = findIndex(filteredDocuments, ["id", document.id]);

  const handleSelect = (newDocument) => {
    if (newDocument.id !== document.id) {
      const {
        location: { pathname, search },
        replace,
      } = history;

      replace({
        pathname: pathname
          .replace(document.id, newDocument.id)
          .replace(document.project.id, newDocument.project.id),
        search,
      });
    }
  };

  const previousDisabled = isBlank(filteredDocuments) || selectedIndex === 0;

  const nextDisabled =
    isBlank(filteredDocuments) ||
    selectedIndex === filteredDocuments.length - 1;

  const handlePrevious = () => {
    const previousDocument = filteredDocuments[selectedIndex - 1];
    handleSelect(previousDocument);
  };

  const handleNext = () => {
    const nextDocument = filteredDocuments[selectedIndex + 1];
    handleSelect(nextDocument);
  };

  const closeViewer = () => {
    setViewerOpen(false);
    setTimeout(onClose, 350);
  };

  const handleClose = () => {
    if ((viewerDirty || hasUnsavedAnnotations) && !bypassRouterWarning) {
      setCloseConfirmOpen(true);
    } else {
      closeViewer();
    }
  };

  const navigateOnDelete = () => {
    if (!nextDisabled) {
      handleNext();
    } else if (!previousDisabled) {
      handlePrevious();
    } else {
      closeViewer();
    }
  };

  const { documentHasApprovedStatusForUser } = getDocumentApprovalStatus(
    document,
    user,
    documentReviewers
  );

  const onCardChange = (cardsOrCardGroups) => {
    if (cardsOrCardGroups.groups) {
      const groupedDocuments = map(
        cardsOrCardGroups.groups,
        (groupedItems, _groupNames) => groupedItems
      );
      setFilteredDocuments(flatten(groupedDocuments));
    } else {
      setFilteredDocuments(cardsOrCardGroups);
    }
  };

  return (
    <Fragment>
      <Confirm
        content={t("confirmNavigation.warning")}
        header="Warning"
        hideViewer
        cancelLabel="Cancel"
        confirmLabel="Continue without saving"
        onConfirm={() => {
          setBypassRouterWarning(true);
          setCloseConfirmOpen(false);
          closeViewer();
        }}
        open={closeConfirmOpen}
        onCloseComplete={() => setCloseConfirmOpen(false)}
      />
      <Prompt
        when={
          (!!viewerDirty || !!hasUnsavedAnnotations) && !bypassRouterWarning
        }
        message={`{
        "confirmText": "Continue without saving", "messageText": "${t(
          "confirmNavigation.warning"
        )}"
      }`}
      />
      <FileView
        fileViewer={
          selectedFile && selectedFile.id ? (
            <FileViewer
              file={selectedFile.file}
              annotationProps={{
                documentId: selectedFile.id,
                dispatch: annotationsDispatch,
              }}
            />
          ) : (
            // the only path to a null selectedFile in this component
            // is selecting the "Invoice Summary" tab when one has not been uploaded to the draw
            <InvoiceSummaryBlankSlate />
          )
        }
        header={
          <DocumentsViewerHeader
            correctResult={correctResult}
            document={document}
            draws={draws}
            match={match}
          />
        }
        nextDisabled={nextDisabled}
        nextHighlighted={
          documentHasApprovedStatusForUser ||
          !userCanApproveDocument(
            document,
            user,
            documentReviewers,
            hasPermission
          )
        }
        onClose={handleClose}
        onNext={handleNext}
        onPrevious={handlePrevious}
        previousDisabled={previousDisabled}
        setViewerOpen={setViewerOpen}
        sidebar={
          <DocumentsViewerSidebar
            closeViewer={closeViewer}
            onClickDocumentCard={handleSelect}
            onCardOrderChange={onCardChange}
          />
        }
        tabs={
          <FileViewTabs
            document={document}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            showInvoiceSummaryTab={showInvoiceSummaryTab}
            summaryFile={summaryFile}
          />
        }
        viewerOpen={viewerOpen}
      >
        {(cardsOpen, setCardsOpen) => (
          <DocumentFormContextProvider
            cardsOpen={cardsOpen}
            key={document.id}
            navigateOnDelete={navigateOnDelete}
            setCardsOpen={setCardsOpen}
            setViewerDirty={setViewerDirty}
          >
            <DocumentReview />
          </DocumentFormContextProvider>
        )}
      </FileView>
    </Fragment>
  );
}

export default DocumentsViewer;
