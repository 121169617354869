import { useContext, Fragment } from "react";
import { ErrorIcon } from "evergreen-ui";
import { Badge, Button, Form, Text, Pane, Table } from "components/materials";
import {
  FastDataTable,
  toBase64,
  currencyColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { formatCurrency } from "helpers/formatCurrency";
import { add, subtract, sumBy } from "helpers/math";
import { majorScale, ThemeContext } from "helpers/utilities";
import { forEach } from "lodash";

function formatCurrencyRed(value) {
  if (value > 0)
    return (
      <Text color="red" size={300}>
        {formatCurrency(value)}
      </Text>
    );
  if (value < 0)
    return (
      <Text color="red" size={300}>
        ({formatCurrency(-value)})
      </Text>
    );
  return formatCurrency(value);
}

function getDisbursements(
  disbursements,
  autoAllocationSuggestions,
  hasUsesOfFunds
) {
  const suggestions = {};
  forEach(disbursements, (fsMap, liId) => {
    suggestions[liId] = {};
    forEach(fsMap, (disbursement, fsId) => {
      const suggestion = hasUsesOfFunds
        ? autoAllocationSuggestions.find(
            (s) => s.lineItemId === liId && s.fundingSourceId === fsId
          ) || {}
        : {
            amount: autoAllocationSuggestions.reduce(
              (acc, s) =>
                s.fundingSourceId === fsId ? add(acc, s.amount) : acc,
              0
            ),
          };
      suggestions[liId][fsId] = {
        amount: formatCurrency(suggestion.amount),
        id: disbursement.id,
      };
    });
  });
  return suggestions;
}

export function DrawFundingSourcesForm({
  autoAllocationSuggestions,
  fundingSources,
  hasAutoAllocate,
  hasUsesOfFunds,
  lineItems,
  propsFormik,
}) {
  const theme = useContext(ThemeContext);
  const fundingSourceBorder = `2px solid ${theme.colors.gray500}`;
  let fundedTotal = 0;
  const fsTotals = {};
  const liTotals = {};
  forEach(propsFormik.values.disbursements, (fsMap, liId) => {
    forEach(fsMap, ({ amount }, fsId) => {
      fundedTotal = add(amount, fundedTotal);
      fsTotals[fsId] = add(amount, fsTotals[fsId]);
      liTotals[liId] = add(amount, liTotals[liId]);
    });
  });
  const requestedTotal = sumBy(
    lineItems,
    (lineItem) => lineItem.requestedAmount
  );
  const differenceTotal = subtract(requestedTotal, fundedTotal);

  const columns = [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Line Item",
      id: "lineItem",
      value: (lineItem) => lineItem.name,
    },
    {
      ...stringColumnDefaults,
      header: "Division",
      id: "division",
      value: (lineItem) => lineItem.division.name,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) => lineItem.requestedAmount),
      header: "Requested",
      id: "requested",
      value: (lineItem) => lineItem.requestedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.requestedAmount, liTotals[lineItem.id])
        ),
      aggregateFormatter: formatCurrencyRed,
      header: "Difference",
      id: "difference",
      value: (lineItem) =>
        subtract(lineItem.requestedAmount, liTotals[lineItem.id]),
      valueFormatter: formatCurrencyRed,
    },
    ...fundingSources.map((fundingSource) => ({
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(
          lineItems,
          (lineItem) =>
            propsFormik.values.disbursements[lineItem.id][fundingSource.id]
              .amount
        ),
      cellProps: {
        padding: majorScale(1),
        borderLeft: fundingSourceBorder,
        testId: fundingSource.label,
      },
      header: fundingSource.label,
      id: fundingSource.id,
      value: (lineItem) =>
        propsFormik.values.disbursements[lineItem.id][fundingSource.id].amount,
      valueFormatter: (value, lineItem) => (
        <Form.BlurInput
          disabled={
            !propsFormik.values.disbursements[lineItem.id][fundingSource.id].id
          }
          name={`disbursements.${lineItem.id}.${fundingSource.id}.amount`}
          onBlur={(event) => {
            if (
              propsFormik.values.automaticAllocationEnabled &&
              propsFormik.values.disbursements[lineItem.id][fundingSource.id]
                .amount !== event.target.value
            ) {
              propsFormik.setFieldValue("automaticAllocationEnabled", false);
            }
          }}
          textAlign="right"
          type="currency"
        />
      ),
    })),
  ];

  const serialized = toBase64({
    columnConfig: [
      "lineItem",
      "requested",
      "difference",
      ...fundingSources.map((fundingSource) => fundingSource.id),
    ],
    filterConfig: [],
    groupConfig: { columnId: "division", expanded: [] },
    sortConfig: {},
  });

  const footerContent = (
    <Fragment>
      <Table.Row>
        <Table.TextFooterCell
          textAlign="right"
          width={stringColumnDefaults.width}
        >
          Original Balance
        </Table.TextFooterCell>
        <Table.TextFooterCell
          textAlign="right"
          width={currencyColumnDefaults.width}
        />
        <Table.TextFooterCell
          textAlign="right"
          width={currencyColumnDefaults.width}
        />
        {fundingSources.map((fs) => (
          <Table.TextFooterCell
            key={fs.id}
            textAlign="right"
            width={currencyColumnDefaults.width}
            borderLeft={fundingSourceBorder}
          >
            {formatCurrency(fs.amount)}
          </Table.TextFooterCell>
        ))}
        <Table.TextFooterCell textAlign="right" />
      </Table.Row>
      <Table.Row>
        <Table.TextFooterCell textAlign="right">
          Previously Funded
        </Table.TextFooterCell>
        <Table.TextFooterCell textAlign="right" />
        <Table.TextFooterCell textAlign="right" />
        {fundingSources.map((fs) => (
          <Table.TextFooterCell
            borderLeft={fundingSourceBorder}
            key={fs.id}
            testId={`previouslyFunded-${fs.label}`}
            textAlign="right"
          >
            {formatCurrency(fs.disbursedPreviouslyAmount)}
          </Table.TextFooterCell>
        ))}
        <Table.TextFooterCell textAlign="right" />
      </Table.Row>
      <Table.Row>
        <Table.TextFooterCell textAlign="right">
          Available Balance
        </Table.TextFooterCell>
        <Table.TextFooterCell textAlign="right" />
        <Table.TextFooterCell textAlign="right" />
        {fundingSources.map((fs) => (
          <Table.TextFooterCell
            key={fs.id}
            textAlign="right"
            borderLeft={fundingSourceBorder}
          >
            {formatCurrency(subtract(fs.amount, fs.disbursedPreviouslyAmount))}
          </Table.TextFooterCell>
        ))}
        <Table.TextFooterCell textAlign="right" />
      </Table.Row>
      <Table.Row>
        <Table.TextFooterCell textAlign="right">
          Currently Funded
        </Table.TextFooterCell>
        <Table.TextFooterCell textAlign="right">
          {formatCurrency(requestedTotal)}
        </Table.TextFooterCell>
        <Table.TextFooterCell textAlign="right">
          {formatCurrencyRed(differenceTotal)}
        </Table.TextFooterCell>
        {fundingSources.map((fs) => (
          <Table.TextFooterCell
            borderLeft={fundingSourceBorder}
            key={fs.id}
            testId={`currentlyFunded-${fs.label}`}
            textAlign="right"
          >
            {hasUsesOfFunds ? (
              formatCurrency(fsTotals[fs.id])
            ) : (
              <Form.BlurInput
                name={`disbursements.null.${fs.id}.amount`}
                textAlign="right"
                type="currency"
                onBlur={(event) => {
                  if (
                    propsFormik.values.automaticAllocationEnabled &&
                    propsFormik.values.disbursements.null[fs.id].amount !==
                      event.target.value
                  ) {
                    propsFormik.setFieldValue(
                      "automaticAllocationEnabled",
                      false
                    );
                  }
                }}
              />
            )}
          </Table.TextFooterCell>
        ))}
        <Table.TextFooterCell textAlign="right" />
      </Table.Row>
      <Table.Row>
        <Table.TextFooterCell textAlign="right">
          Remaining Balance
        </Table.TextFooterCell>
        <Table.TextFooterCell textAlign="right" />
        <Table.TextFooterCell textAlign="right" />
        {fundingSources.map((fs) => (
          <Table.TextFooterCell
            borderLeft={fundingSourceBorder}
            key={fs.id}
            testId={`remainingBalance-${fs.label}`}
            textAlign="right"
          >
            {formatCurrency(
              subtract(fs.amount, fs.disbursedPreviouslyAmount, fsTotals[fs.id])
            )}
          </Table.TextFooterCell>
        ))}
        <Table.TextFooterCell textAlign="right" />
      </Table.Row>
    </Fragment>
  );

  return (
    <Form onReset={propsFormik.handleReset} onSubmit={propsFormik.handleSubmit}>
      <Pane display="flex" margin={majorScale(2)}>
        <Pane flex="1 1 auto">
          <Text>Requested: {formatCurrency(requestedTotal)}</Text>
          <Text marginLeft={majorScale(2)}>
            Funded: {formatCurrency(fundedTotal)}
          </Text>
          {differenceTotal < 0 && (
            <Badge color="red" marginLeft={majorScale(2)}>
              <ErrorIcon size={majorScale(1)} marginRight={majorScale(1)} />
              Over: {formatCurrency(Math.abs(differenceTotal))}
            </Badge>
          )}
          {differenceTotal > 0 && (
            <Badge color="red" marginLeft={majorScale(2)}>
              <ErrorIcon size={majorScale(1)} marginRight={majorScale(1)} />
              Under: {formatCurrency(differenceTotal)}
            </Badge>
          )}
          <Pane marginTop={majorScale(1)}>
            {hasAutoAllocate && (
              <Pane display="inline-block">
                <Form.Switch
                  label="Auto Allocate Funds"
                  name="automaticAllocationEnabled"
                  onChange={(checked) => {
                    if (checked) {
                      propsFormik.setFieldValue(
                        "disbursements",
                        getDisbursements(
                          propsFormik.values.disbursements,
                          autoAllocationSuggestions,
                          hasUsesOfFunds
                        )
                      );
                    }
                  }}
                />
              </Pane>
            )}
          </Pane>
        </Pane>
        {propsFormik.dirty && (
          <Pane flex="0 0 auto">
            <Button type="reset">Reset</Button>
            <Button
              type="submit"
              appearance="primary"
              marginLeft={majorScale(1)}
            >
              Submit
            </Button>
          </Pane>
        )}
      </Pane>
      <FastDataTable
        columns={columns}
        empty={null}
        footerContent={footerContent}
        items={hasUsesOfFunds ? lineItems : []}
        serialized={serialized}
      />
    </Form>
  );
}
