import gql from "graphql-tag";

export const ASSIGNED_TO_DOC_TABLE_FRAGMENT = gql`
  fragment AssignedToDocTableFragment on Project {
    id
    users {
      id
      fullName
    }
  }
`;

export const DOC_REVIEWERS_DOC_TABLE_FRAGMENT = gql`
  fragment DocReviewersDocTableFragment on Project {
    id
    documentReviewers {
      id
      position
      user {
        id
        approvalAmountLimit
        approvalAmountMinimum
        fullName
      }
    }
    suggestedDocumentAssignees {
      id
      userId
    }
  }
`;

export const MOVE_TO_DRAW_DOC_TABLE_FRAGMENT = gql`
  fragment MoveToDrawDocTableFragment on Project {
    id
    draws {
      id
      name
      isFrozen
    }
  }
`;

export const DOCUMENT_TABLE_FRAGMENT = gql`
  fragment DocumentTableFragment on Document {
    id
    amount
    description
    grossAmount
    isBackup
    type
    fileContentType
    fileUrl
    pages
    processingFinishedAt
    projectId
    state
    insertedAt
    wasEmailImported
    uploadedBy
    amountFullyAllocatedToAgreements
    lineItemsCoveredByAgreements
    assignedUser {
      id
      fullName
    }
    draw {
      id
      name
    }
    project {
      id
      name
    }
    issues {
      id
      name
      severity
    }
    originalFile {
      name
      url
    }
    vendor {
      id
      name
      vendorCostCode
    }
    errors {
      message
    }
    comments {
      id
    }
    upload {
      id
      toBeSplit
    }
  }
`;

export const PAY_APPLICATION_DOC_TABLE_FRAGMENT = gql`
  fragment PayApplicationDocTableFragment on PayApplication {
    periodToDate
    number: applicationNumber
    jobCostCodes {
      id
      code
    }
    lineItems {
      id
      descriptionOfWork
    }
    lineItemsWithNegativeBalanceToFundIssue
    divisionNames
    trackedAgreementAmounts {
      id
    }
  }
`;

export const INVOICE_DOC_TABLE_FRAGMENT = gql`
  fragment InvoiceDocTableFragment on Invoice {
    date
    placesOnProjectWithDuplicateInvoiceNumbers
    lineItemsWithNegativeBalanceToFundIssue
    number
    jobCostCodes {
      id
      code
    }
    lineItems {
      id
      name
    }
    divisionNames
    trackedAgreementAmounts {
      id
    }
  }
`;

export const LIEN_RELEASE_DOC_TABLE_FRAGMENT = gql`
  fragment LienReleaseDocTableFragment on LienRelease {
    lineItems {
      id
      name
    }
    divisionNames
  }
`;

export const AGREEMENT_DOC_TABLE_FRAGMENT = gql`
  fragment AgreementDocTableFragment on AgreementDocument {
    adjustedAmount
    agreementAmount
    agreement {
      id
      name
      budgetAdjustment {
        id
      }
      startDate
    }
  }
`;

export const DRAW_SUMMARY_DOC_TABLE_FRAGMENT = gql`
  fragment DrawSummaryDocTableFragment on DrawSummary {
    drawSummaryAmount
    hasAdjustments
    lineItems {
      id
      name
    }
  }
`;

export const UPLOAD_DOC_TABLE_FRAGMENT = gql`
  fragment UploadDocTableFragment on OriginalDocument {
    id
    pages
    processingFinishedAt
    toBeSplit
    file {
      name
      url
      type
    }
    draw {
      id
    }
    documents {
      id
    }
  }
`;
