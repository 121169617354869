import { Fragment } from "react";
import { SymbolCircleIcon } from "evergreen-ui";
import {
  Heading,
  InterpolatedTranslation,
  Link,
  Pane,
  Paragraph,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { PROJECT_SETUP_STEP } from "helpers/enums";
import { finalizeProjectSetup } from "images";
import t from "helpers/translate";

function CopyWithLink({ children, text, linkText, to, ...props }) {
  return (
    <Copy {...props}>
      {children}
      <InterpolatedTranslation
        size={300}
        string={text}
        values={[
          <Link size={300} href={to}>
            {linkText}
          </Link>,
        ]}
      />
    </Copy>
  );
}

function Copy({ children, ...props }) {
  return (
    <Paragraph size={300} {...props}>
      {children}
    </Paragraph>
  );
}

function ContentGroup({ children, header, ...props }) {
  return (
    <Pane {...props}>
      <Heading is="h3" size={500} fontWeight={500} marginBottom={minorScale(1)}>
        {header}
      </Heading>
      {children}
    </Pane>
  );
}

function getReviewFormConfigCopy(step, organization) {
  const hasInspectionQuestions =
    organization.inspectionReportQuestions.length > 0;
  const hasDrawAssessmentQuestions =
    organization.drawAssessmentQuestions.length > 0;

  if (hasInspectionQuestions && hasDrawAssessmentQuestions) {
    return t(`onboardingChecklist.${step}.paragraph1WithAll`);
  }
  if (hasDrawAssessmentQuestions) {
    return t(`onboardingChecklist.${step}.paragraph1WithAssessmentOnly`);
  }
  return t(`onboardingChecklist.${step}.paragraph1WithInspectionOnly`);
}

export function StepModalContent({ organization, projectId, step }) {
  const renderContent = () => {
    switch (step) {
      case PROJECT_SETUP_STEP.COMPLETE_HISTORICAL_DRAWS:
        return (
          <Fragment>
            <ContentGroup header={t(`onboardingChecklist.${step}.header1`)}>
              <Copy>{t(`onboardingChecklist.${step}.paragraph1`)}</Copy>
            </ContentGroup>
            <ContentGroup
              header={t(`onboardingChecklist.${step}.header2`)}
              marginTop={majorScale(2)}
            >
              <Copy>{t(`onboardingChecklist.${step}.paragraph2`)}</Copy>
              <Pane marginTop={majorScale(1)} marginLeft={majorScale(2)}>
                <CopyWithLink
                  text={t(`onboardingChecklist.${step}.bullet1`)}
                  linkText={t(`onboardingChecklist.${step}.link1`)}
                  to="https://help.rabbet.com/en/articles/3326405-add-documents-to-a-draw-and-document-automation"
                >
                  <SymbolCircleIcon
                    size={10}
                    color="muted"
                    marginRight={minorScale(1)}
                  />
                </CopyWithLink>
                <CopyWithLink
                  text={t(`onboardingChecklist.${step}.bullet2`)}
                  linkText={t(`onboardingChecklist.${step}.link2`)}
                  to="https://help.rabbet.com/en/articles/3733249-edit-line-item-amount-requested"
                >
                  <SymbolCircleIcon
                    size={10}
                    color="muted"
                    marginRight={minorScale(1)}
                  />
                </CopyWithLink>
              </Pane>
              <Copy marginTop={majorScale(1)}>
                {t(`onboardingChecklist.${step}.paragraph3`)}
              </Copy>
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph4`)}
                linkText={t(`onboardingChecklist.${step}.link3`)}
                to="https://help.rabbet.com/en/articles/4101979-manage-funding-sources-uses-in-a-draw"
                marginTop={majorScale(1)}
              />
            </ContentGroup>
          </Fragment>
        );
      case PROJECT_SETUP_STEP.CREATE_PROJECT:
        return (
          <Fragment>
            <ContentGroup header={t(`onboardingChecklist.${step}.header1`)}>
              <Copy>{t(`onboardingChecklist.${step}.paragraph1`)}</Copy>
            </ContentGroup>
            <Pane marginTop={majorScale(4)} textAlign="center">
              <img
                height={150}
                src={finalizeProjectSetup}
                alt="checklist complete"
              />
            </Pane>
          </Fragment>
        );
      case PROJECT_SETUP_STEP.CREATE_DRAW:
        return (
          <Fragment>
            <ContentGroup header={t(`onboardingChecklist.${step}.header1`)}>
              <Copy>{t(`onboardingChecklist.${step}.paragraph1`)}</Copy>
            </ContentGroup>
            <ContentGroup
              header={t(`onboardingChecklist.${step}.header2`)}
              marginTop={majorScale(2)}
            >
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph2`)}
                linkText={t(`onboardingChecklist.${step}.link1`)}
                to="https://help.rabbet.com/en/articles/3323621-create-a-draw"
              />
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph3`)}
                linkText={t(`onboardingChecklist.${step}.link2`)}
                to="https://help.rabbet.com/en/articles/3326405-add-documents-to-a-draw-and-document-automation"
                marginTop={majorScale(1)}
              />
            </ContentGroup>
          </Fragment>
        );
      case PROJECT_SETUP_STEP.CREATE_FIRST_AGREEMENT:
        return (
          <Fragment>
            <ContentGroup header={t(`onboardingChecklist.${step}.header1`)}>
              <Copy>{t(`onboardingChecklist.${step}.paragraph1`)}</Copy>
            </ContentGroup>
            <ContentGroup
              header={t(`onboardingChecklist.${step}.header2`)}
              marginTop={majorScale(2)}
            >
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph2`)}
                linkText={t(`onboardingChecklist.${step}.link1`)}
                to={`/projects/${projectId}/agreements`}
              />
              <Copy marginTop={majorScale(1)}>
                {t(`onboardingChecklist.${step}.paragraph3`)}
              </Copy>
              <Copy marginTop={majorScale(1)}>
                {t(`onboardingChecklist.${step}.paragraph4`)}
              </Copy>
              <Copy marginTop={majorScale(1)}>
                {t(`onboardingChecklist.${step}.paragraph5`)}
              </Copy>
            </ContentGroup>
          </Fragment>
        );
      case PROJECT_SETUP_STEP.REVIEW_LINE_ITEM_TYPES:
        return (
          <Fragment>
            <ContentGroup header={t(`onboardingChecklist.${step}.header1`)}>
              <Copy>
                <InterpolatedTranslation
                  size={300}
                  string={t(`onboardingChecklist.${step}.paragraph1`)}
                  values={[
                    <Text size={300} fontWeight={500}>
                      {t(`onboardingChecklist.${step}.bold1`)}
                    </Text>,
                    <Text size={300} fontWeight={500}>
                      {t(`onboardingChecklist.${step}.bold2`)}
                    </Text>,
                    <Text size={300} fontWeight={500}>
                      {t(`onboardingChecklist.${step}.bold3`)}
                    </Text>,
                  ]}
                />
              </Copy>
            </ContentGroup>
            <ContentGroup
              header={t(`onboardingChecklist.${step}.header2`)}
              marginTop={majorScale(2)}
            >
              <Copy>
                <InterpolatedTranslation
                  size={300}
                  string={t(`onboardingChecklist.${step}.paragraph2`)}
                  values={[
                    <Link size={300} href={`/projects/${projectId}/settings`}>
                      {t(`onboardingChecklist.${step}.link1`)}
                    </Link>,
                    <Text size={300} fontWeight={500}>
                      {t(`onboardingChecklist.${step}.bold4`)}
                    </Text>,
                  ]}
                />
              </Copy>
              <Copy marginTop={majorScale(1)}>
                {t(`onboardingChecklist.${step}.paragraph3`)}
              </Copy>
            </ContentGroup>
          </Fragment>
        );
      case PROJECT_SETUP_STEP.REVIEW_RULES:
        return (
          <Fragment>
            <ContentGroup header={t(`onboardingChecklist.${step}.header1`)}>
              <Copy>{t(`onboardingChecklist.${step}.paragraph1`)}</Copy>
            </ContentGroup>
            <ContentGroup
              header={t(`onboardingChecklist.${step}.header2`)}
              marginTop={majorScale(2)}
            >
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph2`)}
                linkText={t(`onboardingChecklist.${step}.link1`)}
                to={`/projects/${projectId}/settings`}
              />
              <Copy marginTop={majorScale(1)}>
                {t(`onboardingChecklist.${step}.paragraph3`)}
              </Copy>
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph4`)}
                linkText={t(`onboardingChecklist.${step}.link2`)}
                to="https://help.rabbet.com/en/articles/3329395-reviewing-draw-rules"
                marginTop={majorScale(1)}
              />
            </ContentGroup>
          </Fragment>
        );
      case PROJECT_SETUP_STEP.REVIEW_USER_ACCESS:
        return (
          <Fragment>
            <ContentGroup header={t(`onboardingChecklist.${step}.header1`)}>
              <Copy>{t(`onboardingChecklist.${step}.paragraph1`)}</Copy>
            </ContentGroup>
            <ContentGroup
              header={t(`onboardingChecklist.${step}.header2`)}
              marginTop={majorScale(2)}
            >
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph2`)}
                linkText={t(`onboardingChecklist.${step}.link1`)}
                to={`/projects/${projectId}/settings`}
              />
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph3`)}
                linkText={t(`onboardingChecklist.${step}.link2`)}
                to="https://help.rabbet.com/en/articles/3943734-third-party-access-to-rabbet"
                marginTop={majorScale(1)}
              />
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph4`)}
                linkText={t(`onboardingChecklist.${step}.link3`)}
                to="https://help.rabbet.com/en/articles/4281272-adding-users-managing-permissions"
                marginTop={majorScale(1)}
              />
            </ContentGroup>
          </Fragment>
        );
      case PROJECT_SETUP_STEP.REVIEW_FORM_CONFIGURATIONS:
        return (
          <Fragment>
            <ContentGroup header={t(`onboardingChecklist.${step}.header1`)}>
              <Copy>{getReviewFormConfigCopy(step, organization)}</Copy>
            </ContentGroup>
            <ContentGroup
              header={t(`onboardingChecklist.${step}.header2`)}
              marginTop={majorScale(2)}
            >
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph2`)}
                linkText={t(`onboardingChecklist.${step}.link1`)}
                to={`/projects/${projectId}/settings`}
              />
              <CopyWithLink
                text={t(`onboardingChecklist.${step}.paragraph3`)}
                marginTop={majorScale(1)}
              />
            </ContentGroup>
          </Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <Pane maxHeight={400} overflowY="auto" paddingRight={majorScale(3)}>
      {renderContent()}
    </Pane>
  );
}
