import { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { Button, Pane, Text } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import { UploadTemplateStep } from "./UploadTemplateStep";
import { ONBOARDING_WIZARD_LAYOUT_QUERY } from "../OnboardingWizard/OnboardingWizardLayout";

export function BudgetUploadPage({ history, match }) {
  const { projectId } = match.params;
  const theme = useContext(ThemeContext);

  const { data } = useQuery(ONBOARDING_WIZARD_LAYOUT_QUERY, {
    variables: { projectId },
  });

  const project = get(data, "project");

  const goBack = () => history.go(-1);

  return (
    <Pane>
      <Pane
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        elevation={1}
        paddingX={majorScale(3)}
        paddingY={majorScale(1)}
      >
        <Pane display="flex" flex={1}>
          <Text fontWeight={theme.fontWeights.MEDIUM} fontSize={16}>
            Upload Project Budget
          </Text>
        </Pane>
        <Button appearance="default" content="Cancel" onClick={goBack} />
      </Pane>
      <Pane>
        <Pane display="flex" justifyContent="center" marginTop={majorScale(7)}>
          <Pane width="70%">
            {project && <UploadTemplateStep project={project} />}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}
