import PropTypes from "prop-types";
import "./Figure.css";

function Figure({ children }) {
  return <div className="ui figure">{children}</div>;
}

Figure.propTypes = {
  children: PropTypes.node.isRequired,
};

function FigureGroup({ children }) {
  return <div className="ui figure__group">{children}</div>;
}

FigureGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

function FigureHeading({ children }) {
  return <div className="ui figure__header">{children}</div>;
}

FigureHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

function FigureLabel({ children }) {
  return <div className="ui figure__label">{children}</div>;
}

FigureLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

function FigureValue({ children }) {
  return <div className="ui figure__value">{children}</div>;
}

FigureValue.propTypes = {
  children: PropTypes.node.isRequired,
};

function FigureAmountOfTotal({ amount, suffix, total }) {
  return (
    <div className="ui figure__amount-of-total">
      <span className="amount">{amount}</span>
      <span> of </span>
      <span className="total">{total}</span>
      <span />
      <span>{` ${suffix}`}</span>
    </div>
  );
}

FigureAmountOfTotal.propTypes = {
  amount: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  total: PropTypes.string.isRequired,
};

FigureAmountOfTotal.defaultProps = {
  suffix: "",
};

// TODO: remove dot notation
Figure.Heading = FigureHeading;
Figure.Group = FigureGroup;
Figure.Label = FigureLabel;
Figure.Value = FigureValue;
Figure.AmountOfTotal = FigureAmountOfTotal;

export default Figure;
