import { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { get, findIndex } from "lodash";
import { UploadStatus } from "../../types";

const UPLOAD_FRAGMENT = gql`
  fragment UploadFragment on DocumentUpload {
    id
    drawId
    fileName
    hidden
    projectId
    split
    status
    parsedDatapointCount
    file {
      id
      name
      pageCount
      downloadUrl
    }
    splits {
      id
      indexes
    }
  }
`;

const USER_QUERY = gql`
  query UserUploads(
    $sort: SortInput
    $filters: [FilterInput]
    $pagination: PaginationInput
  ) {
    documentUploads(sort: $sort, filters: $filters, pagination: $pagination) {
      ...UploadFragment
    }
  }
  ${UPLOAD_FRAGMENT}
`;

const PROJECT_QUERY = gql`
  query ProjectUploads(
    $projectId: String
    $sort: SortInput
    $filters: [FilterInput]
    $pagination: PaginationInput
  ) {
    scope: project(id: $projectId) {
      id
      documentUploads(sort: $sort, filters: $filters, pagination: $pagination) {
        ...UploadFragment
      }
    }
  }
  ${UPLOAD_FRAGMENT}
`;

const DRAW_QUERY = gql`
  query DrawUploads(
    $drawId: String
    $sort: SortInput
    $filters: [FilterInput]
    $pagination: PaginationInput
  ) {
    scope: draw(id: $drawId) {
      id
      documentUploads(sort: $sort, filters: $filters, pagination: $pagination) {
        ...UploadFragment
      }
    }
  }
  ${UPLOAD_FRAGMENT}
`;

const USER_SUBSCRIPTION = gql`
  subscription onUploadUpdated {
    documentUploadUpdated: userDocumentUploadUpdated {
      ...UploadFragment
    }
  }
  ${UPLOAD_FRAGMENT}
`;

const PROJECT_SUBSCRIPTION = gql`
  subscription onUploadUpdated($projectId: String) {
    documentUploadUpdated: projectDocumentUploadUpdated(projectId: $projectId) {
      ...UploadFragment
    }
  }
  ${UPLOAD_FRAGMENT}
`;

function defaultFilters(variables) {
  if (variables.filters) return variables.filters;

  if (variables.projectId || variables.drawId) {
    return {
      filters: [
        {
          field: "status",
          comparator: "IN",
          values: [UploadStatus.PROCESSING, UploadStatus.PENDING_CONFIRMATION],
        },
      ],
    };
  }

  // User uploads
  return {
    filters: [
      {
        field: "hidden",
        comparator: "=",
        value: false,
      },
    ],
  };
}

function upsert(uploads, upload) {
  const index = findIndex(uploads, { id: upload.id });

  if (index < 0) {
    return [...uploads, upload];
  }

  const updatedUploads = [...uploads];
  updatedUploads[index] = { ...uploads[index], ...upload };
  return updatedUploads;
}

function updateScopedUpload(prev, upload) {
  const scope = get(prev, "scope", {});

  if (![upload.drawId, upload.projectId].includes(scope.id)) {
    return prev;
  }

  const documentUploads = upsert(
    get(prev, "scope.documentUploads", []),
    upload
  );

  return {
    ...prev,
    scope: { ...prev.scope, documentUploads },
  };
}

function updateUpload(prev, { subscriptionData }) {
  const upload = get(subscriptionData, "data.documentUploadUpdated", {});

  if (!upload.id) return prev;

  if ("scope" in prev) {
    return updateScopedUpload(prev, upload);
  }

  const documentUploads = upsert(get(prev, "documentUploads", []), upload);

  return {
    ...prev,
    documentUploads,
  };
}

export default function useUploadsQuery({
  subscribe = false,
  variables = {},
  ...rest
}) {
  let query = USER_QUERY;

  if (variables.projectId) query = PROJECT_QUERY;
  if (variables.drawId) query = DRAW_QUERY;

  const defaultFiltersValues = defaultFilters(variables);

  const operation = useQuery(query, {
    variables: { ...variables, ...defaultFiltersValues },
    ...rest,
  });
  const { subscribeToMore } = operation;

  useEffect(() => {
    if (subscribe && subscribeToMore) {
      let subscription = USER_SUBSCRIPTION;

      if (variables.drawId || variables.projectId) {
        subscription = PROJECT_SUBSCRIPTION;
      }

      const unsubscribe = subscribeToMore({
        document: subscription,
        variables: {
          drawId: variables.drawId,
          projectId: variables.projectId,
        },
        updateQuery: updateUpload,
      });

      return () => unsubscribe();
    }

    return null;
  }, [variables.drawId, variables.projectId, subscribe, subscribeToMore]);

  return operation;
}
