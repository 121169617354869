import { memo } from "react";
import { CrossIcon } from "evergreen-ui";
import {
  FastInput,
  FastMaskInput,
  Form,
  IconButton,
  Pane,
  Table,
  Text,
  Tooltip,
} from "components/materials";
import { minorScale, Position } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import { EVENTS, ROW_TYPE } from "./utils";
import RowOrderButtons from "./RowOrderButtons";
import { getMasterFormatDivisions } from "../MasterFormatDivisionForm";

function BudgetTableLineItemInner({
  additionalColumns,
  divisionCount,
  divisionIndex,
  lineItem,
  lineItemCount,
  lineItemIndex,
  onLineItemEvent,
  onMoveRow,
  readOnlyAmount,
  removeLineItem,
  setHasChangeAffectingProjection,
  showLineItemNumber,
  showSuperLineItems,
  useEnhancedLineItemReporting,
}) {
  const inputProps = { inputHeight: 26, marginTop: -minorScale(1) };
  const userNotPermittedToRemoveLineItem =
    readOnlyAmount && lineItem.amount > 0;
  const lineItemDisabled =
    lineItem.disabled || userNotPermittedToRemoveLineItem;

  return (
    <Table.Row>
      <Table.TextCell textProps={{ alignItems: "center", display: "flex" }}>
        <RowOrderButtons
          isFirstItem={divisionIndex === 0 && lineItemIndex === 0}
          isLastItem={
            divisionIndex === divisionCount - 1 &&
            lineItemIndex === lineItemCount - 1
          }
          id={lineItem.id}
          rowType={ROW_TYPE.LINE_ITEM}
          onMoveRow={onMoveRow}
        />
        {lineItemDisabled ? (
          <Tooltip
            content={
              <Text color="white">
                {userNotPermittedToRemoveLineItem
                  ? t("budgetPage.userDisabledLineItemTooltip")
                  : t("budgetPage.disabledLineItemTooltip")}
              </Text>
            }
            position={Position.RIGHT}
          >
            <Pane>
              <IconButton
                appearance="minimal"
                disabled
                flex="0 0 auto"
                marginX={minorScale(1)}
                size="small"
                icon={CrossIcon}
              />
            </Pane>
          </Tooltip>
        ) : (
          <IconButton
            appearance="minimal"
            flex="0 0 auto"
            size="small"
            marginX={minorScale(1)}
            icon={CrossIcon}
            onClick={() => {
              setHasChangeAffectingProjection(true);
              removeLineItem(divisionIndex, lineItemIndex);
            }}
            type="button"
          />
        )}
      </Table.TextCell>
      <Table.TextCell>
        <FastInput
          flex="1 1 auto"
          name={`divisions.${divisionIndex}.lineItems.${lineItemIndex}.name`}
          placeholder="Line Item Name..."
          onChange={() => onLineItemEvent(lineItem.id, EVENTS.UPDATE)}
          {...inputProps}
        />
      </Table.TextCell>
      <Table.TextCell width="100%">
        {readOnlyAmount ? (
          formatCurrency(lineItem.amount)
        ) : (
          <FastMaskInput
            mask="currency"
            name={`divisions.${divisionIndex}.lineItems.${lineItemIndex}.amount`}
            placeholder="Line Item Amount..."
            onChange={() => {
              setHasChangeAffectingProjection(true);
              onLineItemEvent(lineItem.id, EVENTS.UPDATE);
            }}
            width="100%"
            {...inputProps}
          />
        )}
      </Table.TextCell>
      {showSuperLineItems && (
        <Table.TextCell>
          <FastInput
            flex="1 1 auto"
            name={`divisions.${divisionIndex}.lineItems.${lineItemIndex}.superLineItem`}
            onChange={() => onLineItemEvent(lineItem.id, EVENTS.UPDATE)}
            {...inputProps}
          />
        </Table.TextCell>
      )}
      {showLineItemNumber && (
        <Table.TextCell>
          <FastInput
            flex="1 1 auto"
            name={`divisions.${divisionIndex}.lineItems.${lineItemIndex}.number`}
            onChange={() => onLineItemEvent(lineItem.id, EVENTS.UPDATE)}
            {...inputProps}
          />
        </Table.TextCell>
      )}
      {useEnhancedLineItemReporting && (
        <Table.TextCell>
          <Form.Select
            name={`divisions.${divisionIndex}.lineItems.${lineItemIndex}.masterFormatDivision`}
            onChange={() => onLineItemEvent(lineItem.id, EVENTS.UPDATE)}
            options={getMasterFormatDivisions()}
            height={26}
          />{" "}
        </Table.TextCell>
      )}
      {additionalColumns.map((column) => (
        <Table.TextCell key={`${column.name}-${lineItem.id}`}>
          {formatCurrency(column.value(lineItem))}
        </Table.TextCell>
      ))}
      <Table.TextCell />
    </Table.Row>
  );
}

export const BudgetTableLineItem = memo(BudgetTableLineItemInner);
