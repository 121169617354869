import { useCallback, createElement, Fragment } from "react";
import PropTypes from "prop-types";
import { ChevronDownIcon, ChevronRightIcon } from "evergreen-ui";
import { Pane, Shortener, Table } from "components/materials";
import { isEmpty, xor } from "lodash";
import { majorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import { FastDataTableRow } from "./FastDataTableRow";

function FastDataTablePlainBody({
  disableRowClick,
  getRowState,
  onClickRow,
  preparedColumns,
  preparedItems,
  selectedItemIds,
  setSelectedItemIds,
  utilityColumns,
}) {
  return (
    <Table.Body>
      {preparedItems.map((preparedItem, rowIndex) => (
        <FastDataTableRow
          disableRowClick={disableRowClick}
          getRowState={getRowState}
          key={`${preparedItem.id}-${rowIndex}`}
          onClickRow={onClickRow}
          preparedColumns={preparedColumns}
          preparedItem={preparedItem}
          preparedItems={preparedItems}
          rowIndex={rowIndex}
          selectedItemIds={selectedItemIds}
          setSelectedItemIds={setSelectedItemIds}
          utilityColumns={utilityColumns}
        />
      ))}
    </Table.Body>
  );
}

FastDataTablePlainBody.propTypes = {
  getRowState: PropTypes.func,
  onClickRow: PropTypes.func,
  preparedColumns: PropTypes.array,
  preparedItems: PropTypes.array,
  selectedItemIds: PropTypes.array,
  setSelectedItemIds: PropTypes.func,
  utilityColumns: PropTypes.array,
};

function FastDataTableGroupedBody({
  config,
  disableCollapse,
  getRowState,
  onClickRow,
  preparedColumns,
  preparedItems,
  selectedItemIds,
  setConfig,
  setSelectedItemIds,
  utilityColumns,
}) {
  const toggleExpanded = useCallback(
    (value) => {
      const expanded = xor(
        config.groupConfig.expanded || Object.keys(preparedItems.groups),
        [value]
      );
      const payload = {
        ...config,
        groupConfig: { ...config.groupConfig, expanded },
      };
      setConfig({ payload });
    },
    [config, preparedItems.groups, setConfig]
  );

  return (
    <Table.Body>
      {Object.entries(preparedItems.groups).map(([value, items]) => {
        const formattedValue = preparedItems.column.valueFormatter(
          value,
          items[0],
          {
            isGroupedValue: true,
          }
        );
        const displayValue =
          isBlank(formattedValue) ||
          ["-", "undefined", "null"].includes(formattedValue)
            ? "(None)"
            : formattedValue;
        const clickProps = disableCollapse
          ? {}
          : { onClick: () => toggleExpanded(value) };

        if (
          !config.groupConfig.expanded ||
          config.groupConfig.expanded.includes(value)
        ) {
          return (
            <Fragment key={value}>
              <Table.Row {...clickProps}>
                {utilityColumns.map((utilityColumn) => (
                  <Table.TextSectionHeaderCell key={utilityColumn.id}>
                    {utilityColumn.aggregate(
                      items,
                      selectedItemIds,
                      setSelectedItemIds
                    )}
                  </Table.TextSectionHeaderCell>
                ))}
                {preparedColumns
                  .filter((col) => col.primary)
                  .map((primaryColumn) => (
                    <Table.TextSectionHeaderCell
                      cellProps={primaryColumn.cellProps}
                      colSpan={preparedColumns.length}
                      id={`${primaryColumn.id}-header`}
                      key={`${primaryColumn.id}-header`}
                      testId={`${
                        primaryColumn.testId || primaryColumn.id
                      }-header`}
                      textAlign={primaryColumn.textAlign}
                      textProps={primaryColumn.textProps}
                      width={primaryColumn.width}
                    >
                      {!disableCollapse && (
                        <ChevronDownIcon
                          size={12}
                          marginRight={majorScale(2)}
                        />
                      )}
                      {displayValue}
                    </Table.TextSectionHeaderCell>
                  ))}
                <Table.SectionHeaderCell />
              </Table.Row>
              {items.map((preparedItem, rowIndex) => (
                <FastDataTableRow
                  getRowState={getRowState}
                  key={`${preparedItem.id}-${rowIndex}`}
                  onClickRow={onClickRow}
                  preparedColumns={preparedColumns}
                  preparedItem={preparedItem}
                  selectedItemIds={selectedItemIds}
                  setSelectedItemIds={setSelectedItemIds}
                  utilityColumns={utilityColumns}
                />
              ))}
              <Table.Row>
                {utilityColumns.map((utilityColumn) => (
                  <Table.TextFooterCell key={utilityColumn.id} />
                ))}
                {preparedColumns.map((preparedColumn) => {
                  const showFooterCell =
                    preparedColumn.primary || preparedColumn.aggregate;

                  if (!showFooterCell) return null;

                  return (
                    <Table.TextFooterCell
                      cellProps={preparedColumn.cellProps}
                      id={preparedColumn.id}
                      key={preparedColumn.id}
                      testId={preparedColumn.testId}
                      textAlign={preparedColumn.textAlign}
                      textProps={preparedColumn.textProps}
                      width={preparedColumn.width}
                    >
                      {/* text " Subtotal" is ~60 pixels wide */}
                      {preparedColumn.primary ? (
                        <Pane textAlign="right">
                          <Shortener
                            fontWeight={600}
                            limit={(preparedColumn.width - 60) / 7}
                            size={300}
                            text={displayValue}
                          />{" "}
                          Subtotal
                        </Pane>
                      ) : (
                        preparedColumn.aggregateFormatter(
                          preparedColumn.aggregate(items),
                          items
                        )
                      )}
                    </Table.TextFooterCell>
                  );
                })}
                <Table.Cell />
              </Table.Row>
            </Fragment>
          );
        }

        return (
          <Fragment key={value}>
            <Table.Row {...clickProps}>
              {utilityColumns.map((utilityColumn) => (
                <Table.TextSectionHeaderCell key={utilityColumn.id}>
                  {utilityColumn.aggregate(
                    items,
                    selectedItemIds,
                    setSelectedItemIds
                  )}
                </Table.TextSectionHeaderCell>
              ))}
              {preparedColumns.map((preparedColumn) => (
                <Table.TextSectionHeaderCell
                  cellProps={preparedColumn.cellProps}
                  id={preparedColumn.id}
                  key={preparedColumn.id}
                  textAlign={preparedColumn.textAlign}
                  textProps={preparedColumn.textProps}
                  width={preparedColumn.width}
                >
                  {preparedColumn.primary ? (
                    <Fragment>
                      {!disableCollapse && (
                        <ChevronRightIcon
                          size={12}
                          marginRight={majorScale(2)}
                        />
                      )}
                      <Shortener
                        fontWeight={600}
                        limit={preparedColumn.width / 7}
                        size={300}
                        text={displayValue}
                      />
                    </Fragment>
                  ) : (
                    preparedColumn.aggregate &&
                    preparedColumn.aggregateFormatter(
                      preparedColumn.aggregate(items),
                      items
                    )
                  )}
                </Table.TextSectionHeaderCell>
              ))}
              <Table.SectionHeaderCell />
            </Table.Row>
          </Fragment>
        );
      })}
    </Table.Body>
  );
}

FastDataTableGroupedBody.propTypes = {
  config: PropTypes.object,
  disableCollapse: PropTypes.bool,
  getRowState: PropTypes.func,
  onClickRow: PropTypes.func,
  preparedColumns: PropTypes.array,
  preparedItems: PropTypes.object,
  selectedItemIds: PropTypes.array,
  setConfig: PropTypes.func,
  setSelectedItemIds: PropTypes.func,
  utilityColumns: PropTypes.array,
};

export function FastDataTableBody(props) {
  const isPlainBody = Array.isArray(props.preparedItems);

  if (isEmpty(isPlainBody ? props.preparedItems : props.preparedItems.groups)) {
    return (
      props.empty && (
        <Table.Body>
          <Table.Row>
            <Table.TextCell colSpan={props.preparedColumns.length}>
              {props.empty}
            </Table.TextCell>
          </Table.Row>
        </Table.Body>
      )
    );
  }

  return isPlainBody
    ? createElement(FastDataTablePlainBody, props, null)
    : createElement(FastDataTableGroupedBody, props, null);
}
