import { VendorLink } from "components/templates";
import { Pane } from "components/materials";
import { omit } from "lodash";
import DocumentReviewLayout from "./DocumentReviewLayout";

function getInitialValues({ document, selectedDraw, selectedType }) {
  const { comments, documentReviewActivity, ...restOfDocument } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);
  return {
    ...documentProperties,
    draw: selectedDraw,
    type: selectedType,
  };
}

function getDocumentDataFromForm(values) {
  return {
    isBackup: values.isBackup,
  };
}

function NoticeToOwnerReview(props) {
  const { form } = props;

  return (
    <DocumentReviewLayout
      {...props}
      information={(content) => (
        <Pane display="flex" flexDirection="column" width="100%">
          <Pane display="flex" flexDirection="row" flexWrap="wrap">
            {content}
          </Pane>

          <Pane display="flex" justifyContent="flex-start" alignItems="center">
            <VendorLink
              vendorId={form.values.vendor.id}
              vendors={props.allAvailableVendors}
            />
          </Pane>
        </Pane>
      )}
    />
  );
}

NoticeToOwnerReview.getInitialValues = getInitialValues;
NoticeToOwnerReview.getDocumentDataFromForm = getDocumentDataFromForm;

export default NoticeToOwnerReview;
