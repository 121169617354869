import { Pane, Heading } from "components/materials";
import { get } from "lodash";

export function AgreementsViewerHeader({ project }) {
  const projectName = get(project, "name");
  return (
    <Pane width="100%" display="flex">
      <Heading size={600} fontWeight="bold">
        {`${projectName} Agreements`}
      </Heading>
    </Pane>
  );
}
