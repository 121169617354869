import { useContext, Fragment } from "react";
import { Pane, ConfigureButtons, Paragraph, Text } from "components/materials";
import { flattenDeep, orderBy } from "lodash";
import { TASK_STATUS } from "helpers/enums";
import { WarningSignIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { useHistory } from "react-router-dom";
import t from "helpers/translate";
import {
  getSerializedURLForTasksReport,
  getSerializedURLForOverdueTasksReport,
  trackClickthrough,
} from "./helpers";
import { Table, LinkCell } from "./Table";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";
import { formatDate } from "../../../helpers/dateHelpers";

export const PROJECT_TASK_SUMMARY_CONFIGURATION_SETTINGS = {
  i: "projectTaskSummary",
  x: 0,
  y: 5,
  w: 2,
  h: 1,
  disabled: false,
};

export function ProjectTaskSummary({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
  projects,
  organization,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const organizationId = organization.id;

  const TASK_COLORS = {
    NOT_STARTED: theme.colors.textGray,
    OVERDUE: theme.tableColors.errorBorder,
    IN_PROGRESS: theme.tableColors.bulkActionsInfo,
    COMPLETE: theme.colors.textGray,
  };

  function navigateToOverdueTasksReport() {
    return history.push(getSerializedURLForOverdueTasksReport(organizationId));
  }
  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const tableData = orderBy(
    flattenDeep(
      projects.map((project) =>
        project.tasks.map((task) => ({
          ...task,
          projectId: project.id,
          status: task.isOverdue ? "OVERDUE" : task.status,
        }))
      )
    ).filter(
      ({ projectedCompletionDate, status }) =>
        status !== TASK_STATUS.COMPLETE && projectedCompletionDate
    ),
    ["projectedCompletionDate"]
  ).map(
    ({
      eventName,
      id,
      projectedCompletionDate,
      projectId,
      projectName,
      status,
    }) => {
      return {
        eventName,
        id,
        projectedCompletionDate: formatDate(
          projectedCompletionDate,
          "-",
          "MM/dd/yyyy"
        ),
        projectId,
        projectName,
        status,
      };
    }
  );

  const overdueTasksCount = projects.reduce((acc, { tasks }) => {
    const overdueTasks = tasks.filter((task) => task.isOverdue);
    return acc + overdueTasks.length;
  }, 0);

  const showBlankSlate = tableData.length === 0;

  const columns = [
    {
      name: "Project",
      width: "30%",
      sortKey: "projectName",
      renderCell: (row) => {
        return row.projectName ? (
          <LinkCell
            characterLimit={42}
            name={row.projectName}
            onClick={trackClickthrough(
              "Project Task Summary to Project",
              organizationId,
              {
                projectId: row.projectId,
                projectName: row.projectName,
              }
            )}
            url={`/projects/${row.projectId}/timeline`}
          />
        ) : (
          <Text>-</Text>
        );
      },
    },
    {
      name: "Task",
      width: "30%",
      sortKey: "eventName",
      renderCell: (row) => {
        return row.eventName ? (
          <LinkCell
            characterLimit={42}
            name={row.eventName}
            onClick={trackClickthrough(
              "Project Task Summary to Project Timeline row",
              organizationId,
              {
                projectId: row.projectId,
                projectName: row.projectName,
              }
            )}
            url={`/projects/${row.projectId}/timeline/${row.id}`}
          />
        ) : (
          <Text>-</Text>
        );
      },
    },
    {
      name: "Est. Completion",
      width: "20%",
      sortKey: "projectedCompletionDate",
      valueKey: "projectedCompletionDate",
    },
    {
      name: "Status",
      renderCell: (row) => (
        <Pane display="flex" alignItems="center">
          <Pane
            width="12px"
            height="12px"
            borderRadius="12px"
            backgroundColor={TASK_COLORS[row.status]}
            marginRight={majorScale(1)}
          />
          <Text color={theme.colors.textGray}>
            {t(`taskStatus.${row.status}`)}
          </Text>
        </Pane>
      ),
      width: "20%",
      sortKey: "status",
    },
  ];

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Project Task Summary" />
        {overdueTasksCount > 0 && (
          <Pane
            marginLeft="auto"
            marginRight={majorScale(2)}
            display="flex"
            alignItems="center"
          >
            <Pane
              width="170px"
              height="30px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`1px solid ${theme.colors.errorRed}`}
              background={theme.colors.errorLightRed}
              paddingY={majorScale(1)}
              paddingX={majorScale(1)}
              borderRadius="4px"
              cursor="pointer"
              onClick={() => navigateToOverdueTasksReport()}
            >
              <WarningSignIcon
                color={theme.colors.errorRed}
                marginRight={majorScale(1)}
              />
              <Paragraph color={theme.colors.errorRed}>
                {overdueTasksCount} overdue{" "}
                {overdueTasksCount > 1 ? "tasks" : "task"}
              </Paragraph>
            </Pane>
          </Pane>
        )}
        <Pane display="flex" alignItems="center">
          {isConfigurable && (
            <ConfigureButtons
              isDisabled={isDisabled}
              cards={cards}
              setCards={setCards}
              name={name}
            />
          )}
          {!isConfigurable && (
            <CardLinkButton
              href={getSerializedURLForTasksReport(true, organization.id)}
            />
          )}
        </Pane>
      </Pane>
      <Pane>
        <Pane>
          <Pane>
            {showBlankSlate ? (
              <BlankSlate type={BlankSlateType.Tasks} />
            ) : (
              <Fragment>
                <Pane
                  maxHeight={280}
                  overflowY="scroll"
                  marginTop={majorScale(2)}
                >
                  <Table
                    cellBorderY={false}
                    columns={columns}
                    defaultSortDirection="desc"
                    defaultSortKey="status"
                    hasFooter={false}
                    maxHeight={280}
                    rows={tableData}
                    width="100%"
                  />
                </Pane>
              </Fragment>
            )}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}
