import { map, reduce } from "lodash";
import { RULE_CONFIG_INPUT_TYPE } from "helpers/enums";
import isBlank from "helpers/isBlank";

export const DRAW_RULES = {
  CONDITIONAL_LIEN_WAIVERS_MUST_MATCH_INVOICES:
    "conditional_lien_waivers_must_match_invoices",
  COVER_SHEET_RECONCILES_WITH_SUMMARY: "cover_sheet_reconciles_with_summary",
  DRAW_DOES_NOT_HAVE_NOTICE_TO_OWNER: "draw_does_not_have_notice_to_owner",
  DRAW_HAS_COVER_SHEET: "draw_has_cover_sheet",
  DRAW_HAS_INSPECTION_REPORT: "draw_has_inspection_report",
  DRAW_HAS_TITLE_ENDORSEMENT: "draw_has_title_endorsement",
  DUPLICATE_INVOICE_NUMBERS: "duplicate_invoice_numbers",
  FIRST_HARD_COST_DRAW_REQUIRES_FOUNDATION_ENDORSEMENT:
    "first_hard_cost_draw_requires_foundation_endorsement",
  GENERAL_CONTRACTOR_PAY_APPLICATIONS_COVERED_BY_UNCONDITIONAL_LIEN_WAIVERS:
    "general_contractor_pay_applications_covered_by_unconditional_lien_waivers",
  INSPECTION_LINE_ITEM_PERCENT_COMPLETE:
    "inspection_line_item_percent_complete",
  INSPECTION_WITHIN_X_PERCENT_OF_HARD_COSTS:
    "inspection_within_x_percent_of_hard_costs",
  INTEREST_RESERVES_REMAINING: "interest_reserves_remaining",
  INVOICES_RECONCILE_WITH_SUMMARY: "invoices_reconcile_with_summary",
  LINE_ITEM_AMOUNT_DOES_NOT_EXCEED_AGREEMENT_AMOUNT:
    "line_item_amount_does_not_exceed_agreement_amount",
  LINE_ITEMS_HAVE_BUDGET_REMAINING_TO_PAY_OUTSTANDING_AGREEMENTS:
    "line_items_have_budget_remaining_to_pay_outstanding_agreements",
  PAY_APPLICATIONS_COVERED_BY_UNCONDITIONAL_LIEN_WAIVERS:
    "pay_applications_covered_by_unconditional_lien_waivers",
  REQUIRE_LIEN_WAIVER_IF_VENDOR_HAS_NOTICE_TO_OWNER:
    "require_lien_waiver_if_vendor_has_notice_to_owner",
  OVERDRAWN_LINE_ITEMS: "overdrawn_line_items",
  OVERDUE_TASKS: "overdue_tasks",
  OVERUSED_CONTINGENCY: "overused_contingency",
  PROJECT_BEHIND_SCHEDULE: "project_behind_schedule",
  PROJECT_HAS_BUILDERS_RISK_CERTIFICATE:
    "project_has_builders_risk_certificate",
  PROJECT_HAS_GENERAL_LIABILITY_INSURANCE_CERTIFICATE:
    "project_has_general_liability_insurance_certificate",
  PROJECT_HAS_PERFORMANCE_BOND: "project_has_performance_bond",
  REQUEST_NOTICE_OF_COMPLETION_AND_CERTIFICATE_OF_OCCUPANCY:
    "request_notice_of_completion_and_certificate_of_occupancy",
  REQUIRE_BILL_OF_SALE_FOR_STORED_MATERIALS:
    "require_bill_of_sale_for_stored_materials",
  REQUIRE_CHANGE_ORDER_FOR_DRAW_ADJUSTMENTS:
    "require_change_order_for_draw_adjustments",
  REQUIRE_CHANGE_ORDER_FOR_LINE_ITEM_ADJUSTMENTS_OVER_X:
    "require_change_order_for_line_item_adjustments_over_x",
  REQUIRE_INSURANCE_FOR_STORED_MATERIALS:
    "require_insurance_for_stored_materials",
  REQUIRE_LIEN_WAIVER_FOR_HARD_COSTS_OVER_X:
    "require_lien_waiver_for_hard_costs_over_x",
  REQUIRE_RETAINAGE_RELEASE_ON_REDUCED_RETAINAGE:
    "require_retainage_release_on_reduced_retainage",
  RETAINAGE_RELEASED: "retainage_released",
  RETAINAGE_REQUESTED_MATCHES_AGREEMENT_RETAINAGE:
    "retainage_requested_matches_agreement_retainage",
  SUBCONTRACTOR_INVOICES_MATCH_GC_PAY_APPLICATION:
    "subcontractor_invoices_match_gc_pay_application",
  VENDOR_LINE_ITEM_AMOUNT_DOES_NOT_EXCEED_AGREEMENT_AMOUNT:
    "vendor_line_item_amount_does_not_exceed_agreement_amount",
};

export function defaultRuleConfiguration(ruleName) {
  const ruleMappings = {
    [DRAW_RULES.INSPECTION_WITHIN_X_PERCENT_OF_HARD_COSTS]: {
      percent: { type: RULE_CONFIG_INPUT_TYPE.FLOAT, value: "5" },
    },
    [DRAW_RULES.PROJECT_BEHIND_SCHEDULE]: {
      days: { type: RULE_CONFIG_INPUT_TYPE.INTEGER, value: "90" },
    },
    [DRAW_RULES.REQUIRE_LIEN_WAIVER_FOR_HARD_COSTS_OVER_X]: {
      amount: { type: RULE_CONFIG_INPUT_TYPE.CURRENCY, value: "25000" },
    },
    [DRAW_RULES.REQUIRE_CHANGE_ORDER_FOR_LINE_ITEM_ADJUSTMENTS_OVER_X]: {
      amount: { type: RULE_CONFIG_INPUT_TYPE.CURRENCY, value: "50000" },
    },
    [DRAW_RULES.INVOICES_RECONCILE_WITH_SUMMARY]: {
      amount: { type: RULE_CONFIG_INPUT_TYPE.CURRENCY, value: "0" },
    },
  };

  return ruleMappings[ruleName];
}

// If a variable setting has been configured, use that value.
// Otherwise fallback to the default provided value.
export const getRuleConfig = (defaultConfig, config) => {
  return reduce(
    defaultConfig,
    (memo, defaultRuleSettings, varName) => {
      const configuredValue = config[varName];

      if (!isBlank(configuredValue)) {
        memo[varName] = {
          ...defaultRuleSettings,
          value: configuredValue.toString(),
        };
      } else {
        memo[varName] = defaultRuleSettings;
      }

      return memo;
    },
    {}
  );
};

// Convert the `config` form value to a value that the server expects
export function getConfigValue(config) {
  return map(config, ({ type, value }, varName) => {
    return {
      type,
      value: value.replace(",", ""),
      key: varName,
    };
  });
}
