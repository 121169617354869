import { HelpIcon } from "evergreen-ui";
import { IconButton } from "components/materials";
import { css } from "glamor";
import PropTypes from "prop-types";

export function HelpButton({ theme }) {
  const {
    headerIconButtonColor,
    headerLinkActiveBoxShadow,
    headerLinkInactiveHover,
    headerTextOnHoverColor,
  } = theme.defaultColors;

  return (
    <IconButton
      appearance="minimal"
      color={headerIconButtonColor}
      data-testid="navbar-help-icon"
      href="https://help.rabbet.com/en/"
      icon={HelpIcon}
      iconSize={14}
      id="navLink"
      intent={null}
      is="a"
      rel="noopener noreferrer"
      target="_blank"
      {...css({
        "&#navLink:hover": {
          backgroundColor: headerLinkInactiveHover,
          color: headerTextOnHoverColor,
        },
        "&#navLink:focus": {
          boxShadow: headerLinkActiveBoxShadow,
        },
      })}
    />
  );
}

HelpButton.propTypes = {
  theme: PropTypes.object.isRequired,
};
