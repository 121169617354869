import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { Form, Paragraph, TextInput } from "components/materials";
import DatePicker from "react-datepicker";
import { formatDate, parseDate } from "helpers/dateHelpers";
import MaskedInput from "react-text-mask";
import { createAutoCorrectedDatePipe } from "text-mask-addons";
import "react-datepicker/dist/react-datepicker.css";
import "./FormDateInput.css";

// `MaskedDateInput` needs to be a class based component,
// see https://github.com/Hacker0x01/react-datepicker/issues/862
class MaskedDateInput extends PureComponent {
  render() {
    return (
      <MaskedInput
        {...this.props}
        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        pipe={createAutoCorrectedDatePipe("mm/dd/yyyy")}
        render={(ref, props) => <TextInput width="100%" ref={ref} {...props} />}
      />
    );
  }
}

export function FormDateInput({
  name,
  label,
  labelProps,
  onChange,
  isWarned,
  popperPlacement,
  showError,
  popperProps,
  ...props
}) {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        const hasError = !!error && (showError || !!touch);
        const disabled = props.disabled || form.values.__disabled;

        return (
          <Form.Field
            className="rbt-form-date-input"
            isWarned={isWarned}
            label={label && <Paragraph {...labelProps}>{label}</Paragraph>}
            validationMessage={hasError && error}
            {...props}
          >
            <DatePicker
              dateFormat="MM/dd/yyyy"
              name={name}
              placeholder=""
              selected={field.value ? parseDate(field.value) : null}
              onChange={(date) => {
                form
                  .setFieldValue(name, formatDate(date, null))
                  .then(() => form.setFieldTouched(name, true));

                onChange(formatDate(date, null));
              }}
              disabled={disabled}
              customInput={<MaskedDateInput />}
              popperPlacement={popperPlacement}
              popperProps={popperProps}
            />
          </Form.Field>
        );
      }}
    </Field>
  );
}

FormDateInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  labelProps: PropTypes.object,
  onChange: PropTypes.func,
  isWarned: PropTypes.bool,
};

FormDateInput.defaultProps = {
  labelProps: {},
  onChange: () => {},
  isWarned: false,
};
