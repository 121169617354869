import { Fragment, useContext, useState } from "react";
import { InfoSignIcon } from "evergreen-ui";
import {
  Button,
  Card,
  Pane,
  Paragraph,
  Progress,
  Shortener,
  Tab,
  Tablist,
  Tooltip,
} from "components/materials";
import { VictoryPie, VictoryTooltip } from "victory";
import { flatten, sortBy } from "lodash";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import {
  BRAND_COLORS,
  COLORS,
  getGraphColor,
  GRAPH_DATASET_COLORS,
} from "helpers/colors";
import t from "helpers/translate";
import { add, divide, multiply, subtract, sumBy } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import { HeaderTextCell, PortfolioInsightBlankSlateCard, TextCell } from ".";

const CHART_VIEWS = {
  SQUARE_FEET: "squareFeet",
  ACRES: "acres",
};

const TABLE_VIEWS = {
  TOTAL: "total",
  HARD_COSTS: "hardCosts",
  SOFT_COSTS: "softCosts",
  INTEREST_RESERVES: "interestReserves",
  CONTINGENCY: "contingency",
};

function addLineItemToCategories(categories, categoryName, lineItem) {
  return {
    ...categories,
    [categoryName]: [...categories[categoryName], lineItem],
  };
}

function prepareChartInfo(projects, areaMeasurement) {
  const filteredProjects = projects.filter(
    (project) => project[areaMeasurement] && project[areaMeasurement] > 0
  );

  const lineItems = flatten(
    filteredProjects.map((project) => project.lineItems)
  );

  const orgProjectAmountSum = sumBy(filteredProjects, "amount");

  const categories = lineItems.reduce(
    (categories, lineItem) => {
      if (lineItem.isInterestReserves) {
        return addLineItemToCategories(
          categories,
          TABLE_VIEWS.INTEREST_RESERVES,
          lineItem
        );
      }
      if (lineItem.isContingency) {
        return addLineItemToCategories(
          categories,
          TABLE_VIEWS.CONTINGENCY,
          lineItem
        );
      }
      if (lineItem.softCosts) {
        return addLineItemToCategories(
          categories,
          TABLE_VIEWS.SOFT_COSTS,
          lineItem
        );
      }
      if (lineItem.hardCosts) {
        return addLineItemToCategories(
          categories,
          TABLE_VIEWS.HARD_COSTS,
          lineItem
        );
      }
      return categories;
    },
    { hardCosts: [], softCosts: [], interestReserves: [], contingency: [] }
  );

  // Total of squareFeet or acres across all the applicable projects
  const areaMeasurementTotalForOrg = sumBy(filteredProjects, areaMeasurement);

  const projectAverageForAreaMeasurement = divide(
    areaMeasurementTotalForOrg,
    filteredProjects.length
  );

  return {
    areaMeasurementTotalForOrg,
    filteredProjects,
    orgProjectAmountSum,
    projectAverageForAreaMeasurement,
    categories,
  };
}

function prepareTableByTotal(areaMeasurement, projects) {
  return projects
    .map((project) => {
      return {
        ...project,
        byCategoryAmount: project.amount,
        categoryAmountPerAreaMeasurement: project[areaMeasurement]
          ? divide(project.amount, project[areaMeasurement])
          : 0,
      };
    })
    .sort((a, b) =>
      subtract(
        b.categoryAmountPerAreaMeasurement,
        a.categoryAmountPerAreaMeasurement
      )
    );
}

function prepareByCategory(areaMeasurement, category, projects) {
  const projectsWithCategory = projects.map((project) => {
    const filteredLineItems = project.lineItems.filter((lineItem) => {
      if (category === TABLE_VIEWS.CONTINGENCY) {
        return lineItem.isContingency;
      }
      if (category === TABLE_VIEWS.INTEREST_RESERVES) {
        return lineItem.isInterestReserves;
      }
      return (
        lineItem[category] &&
        !lineItem.isInterestReserves &&
        !lineItem.isContingency
      );
    });

    const byCategoryAmount = sumBy(filteredLineItems, "budgetAmount");
    return {
      ...project,
      byCategoryAmount,
      categoryAmountPerAreaMeasurement: project[areaMeasurement]
        ? divide(byCategoryAmount, project[areaMeasurement])
        : 0,
    };
  });

  return projectsWithCategory.sort((a, b) =>
    subtract(
      b.categoryAmountPerAreaMeasurement,
      a.categoryAmountPerAreaMeasurement
    )
  );
}

function ChartViewTableRow({
  category,
  categoryAverage,
  correspondingGraphColor,
  pricePerCategory,
}) {
  return (
    <tr>
      <td aria-label="progress">
        <Pane
          height={10}
          width={10}
          borderRadius="50%"
          background={correspondingGraphColor}
        />
      </td>
      <TextCell>
        {t(`portfolioInsightsPage.projectCostsViews.chart.${category}`)}
      </TextCell>
      <TextCell style={{ textAlign: "right" }}>
        {formatCurrency(categoryAverage)}
      </TextCell>
      <TextCell style={{ textAlign: "right" }}>
        {formatCurrency(pricePerCategory)}
      </TextCell>
    </tr>
  );
}

function TableRow({
  chartView,
  history,
  maxPricePerAreaMeasurementAmount,
  project,
}) {
  const pricePerAreaMeasurement =
    project.categoryAmountPerAreaMeasurement !== 0 ? (
      formatCurrency(project.categoryAmountPerAreaMeasurement)
    ) : (
      <Fragment>
        N/A
        <Tooltip
          content={t(`portfolioInsightsPage.projectCostsViews.missing`, {
            category: t(
              `portfolioInsightsPage.projectCostsViews.chart.${chartView}`
            ).toLowerCase(),
          })}
        >
          <InfoSignIcon
            size={10}
            marginLeft={minorScale(1)}
            marginBottom={minorScale(1)}
          />
        </Tooltip>
      </Fragment>
    );
  return (
    <tr>
      <TextCell>
        <Shortener limit={26} size={300} text={project.name} />
      </TextCell>
      <TextCell style={{ textAlign: "right" }}>
        {formatCurrency(project.byCategoryAmount)}
      </TextCell>
      <TextCell style={{ textAlign: "right" }}>
        {pricePerAreaMeasurement}
      </TextCell>
      <td width="100%" aria-label="progress">
        <Progress
          value={project.categoryAmountPerAreaMeasurement}
          total={maxPricePerAreaMeasurementAmount}
          color={BRAND_COLORS.LIGHT_PRIMARY}
          background="transparent"
        />
      </td>
      <td>
        <Button onClick={() => history.push(`/projects/${project.id}/budget`)}>
          Go to Project
        </Button>
      </td>
    </tr>
  );
}

function prepareGraphData(chartInfo) {
  const { categories, orgProjectAmountSum } = chartInfo;
  return Object.keys(categories).map((key) => {
    const categorySum = sumBy(categories[key], "budgetAmount");
    const categoryPercentage = multiply(
      divide(categorySum, orgProjectAmountSum),
      100
    );
    return {
      x: t(`portfolioInsightsPage.projectCostsViews.table.${key}`),
      y: categoryPercentage,
    };
  });
}

function PieChart({ graphData }) {
  const graphColors = GRAPH_DATASET_COLORS.slice(0, 4);
  return (
    <VictoryPie
      data={graphData}
      colorScale={graphColors}
      labelComponent={
        <VictoryTooltip
          cornerRadius={5}
          pointerLength={5}
          flyoutStyle={{
            fill: COLORS.BLACK,
            stroke: "none",
          }}
          style={{
            fill: BRAND_COLORS.WHITE,
            fontSize: 20,
            fontFamily: "AvenirNext, arial, sans-serif",
          }}
        />
      }
      labels={({ x, y }) => `${x}\n ${y.toFixed(2)}%`}
    />
  );
}

export function ProjectCosts({ history, projects, stylingProps }) {
  const theme = useContext(ThemeContext);
  const [chartView, setChartView] = useState(CHART_VIEWS.SQUARE_FEET);
  const [tableView, setTableView] = useState(TABLE_VIEWS.TOTAL);

  if (projects.length === 0)
    return <PortfolioInsightBlankSlateCard cardName="projectCosts" />;

  const chartInfo = prepareChartInfo(projects, chartView);

  const graphData = prepareGraphData(chartInfo);
  const {
    categories,
    areaMeasurementTotalForOrg,
    projectAverageForAreaMeasurement,
  } = chartInfo;

  const tableInfo =
    tableView === TABLE_VIEWS.TOTAL
      ? prepareTableByTotal(chartView, projects)
      : prepareByCategory(chartView, tableView, projects);

  const maxPricePerAreaMeasurementAmount = sortBy(
    tableInfo,
    "categoryAmountPerAreaMeasurement"
  ).slice(-1)[0].categoryAmountPerAreaMeasurement;

  const formattedProjectAverageForAreaMeasurement = formatCurrency(
    projectAverageForAreaMeasurement,
    { withoutCurrencySymbol: true }
  );

  const projectAverageForAreaMeasurementText = `Average ${t(
    `portfolioInsightsPage.projectCostsViews.chart.${chartView}`
  )}: ${formattedProjectAverageForAreaMeasurement}`;

  function getCategorySum(category) {
    return sumBy(categories[category], "budgetAmount");
  }

  function getPricePerCategory(category) {
    return divide(getCategorySum(category), areaMeasurementTotalForOrg);
  }

  function getCategoryAverage(category) {
    return divide(getCategorySum(category), chartInfo.filteredProjects.length);
  }

  const pricePerCategoryTotalForChart = Object.keys(categories).reduce(
    (total, category) => add(total, getPricePerCategory(category)),
    0
  );

  const categoryAverageTotalForChart = Object.keys(categories).reduce(
    (total, category) => add(total, getCategoryAverage(category)),
    0
  );

  return (
    <Fragment>
      <Paragraph {...stylingProps.cardTitle} {...stylingProps.title}>
        Project Costs
      </Paragraph>
      <Card {...stylingProps.card}>
        <Tablist>
          {Object.keys(CHART_VIEWS).map((key) => (
            <Tab
              key={CHART_VIEWS[key]}
              isSelected={chartView === CHART_VIEWS[key]}
              onSelect={() => setChartView(CHART_VIEWS[key])}
            >
              {t(
                `portfolioInsightsPage.projectCostsViews.chart.${CHART_VIEWS[key]}`
              )}
            </Tab>
          ))}
        </Tablist>
        {chartInfo.filteredProjects.length === 0 ? (
          <Paragraph
            alignItems="center"
            textAlign="center"
            padding={majorScale(3)}
          >
            {t(`portfolioInsightsPage.blankSlate.info.projectCostsCategory`, {
              category:
                chartView === CHART_VIEWS.SQUARE_FEET ? "square feet" : "acres",
            })}
          </Paragraph>
        ) : (
          <Pane
            display="flex"
            justifyContent="space-evenly"
            paddingBottom={majorScale(1)}
          >
            <Pane style={{ height: 225, width: "80%" }}>
              <PieChart graphData={graphData} />
            </Pane>
            <Pane>
              <Paragraph
                size={300}
                textAlign="right"
                paddingRight={majorScale(2)}
                paddingBottom={majorScale(1)}
                color={theme.defaultColors.navigationInactiveText}
                fontWeight="bold"
              >
                {projectAverageForAreaMeasurementText.toUpperCase()}
              </Paragraph>
              <table {...stylingProps.table}>
                <thead>
                  <tr>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th colSpan={1} />
                    <HeaderTextCell>Category</HeaderTextCell>
                    <HeaderTextCell style={{ textAlign: "right" }}>
                      Average Price
                    </HeaderTextCell>
                    <HeaderTextCell style={{ textAlign: "right" }}>
                      {chartView === CHART_VIEWS.SQUARE_FEET
                        ? "$/sqft"
                        : "$/acre"}
                    </HeaderTextCell>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(categories).map((key, index) => {
                    const categoryAverage = getCategoryAverage(key);
                    const pricePerCategory = getPricePerCategory(key);
                    const correspondingGraphColor = getGraphColor(index);
                    return (
                      <ChartViewTableRow
                        category={key}
                        categoryAverage={categoryAverage}
                        correspondingGraphColor={correspondingGraphColor}
                        key={key}
                        pricePerCategory={pricePerCategory}
                      />
                    );
                  })}
                  <tr>
                    <TextCell
                      colSpan={2}
                      style={{ textAlign: "right" }}
                      textProps={{
                        color: theme.defaultColors.navigationInactiveText,
                        fontWeight: "bold",
                      }}
                    >
                      TOTAL:
                    </TextCell>
                    <TextCell
                      style={{ textAlign: "right" }}
                      textProps={{
                        color: theme.defaultColors.navigationInactiveText,
                        fontWeight: "bold",
                      }}
                    >
                      {formatCurrency(categoryAverageTotalForChart)}
                    </TextCell>
                    <TextCell
                      style={{ textAlign: "right" }}
                      textProps={{
                        color: theme.defaultColors.navigationInactiveText,
                        fontWeight: "bold",
                      }}
                    >
                      {formatCurrency(pricePerCategoryTotalForChart)}
                    </TextCell>
                  </tr>
                </tbody>
              </table>
              <Paragraph
                textAlign="right"
                fontStyle="italic"
                paddingRight={majorScale(1)}
                size={300}
                color={theme.defaultColors.navigationInactiveText}
              >
                {t(`portfolioInsightsPage.projectCostsViews.info`)}
              </Paragraph>
            </Pane>
          </Pane>
        )}
        <Pane>
          <Tablist>
            {Object.keys(TABLE_VIEWS).map((key) => (
              <Tab
                key={TABLE_VIEWS[key]}
                isSelected={tableView === TABLE_VIEWS[key]}
                onSelect={() => setTableView(TABLE_VIEWS[key])}
              >
                {t(`portfolioInsightsPage.projectCostsViews.table.${key}`)}
              </Tab>
            ))}
          </Tablist>
          <Pane maxHeight={250} overflowY="scroll" marginTop={majorScale(2)}>
            <table {...stylingProps.table}>
              <thead>
                <tr>
                  <HeaderTextCell>Project</HeaderTextCell>
                  <HeaderTextCell style={{ minWidth: 100, textAlign: "right" }}>
                    {t(
                      `portfolioInsightsPage.projectCostsViews.table.${tableView}`
                    )}{" "}
                    Budget
                  </HeaderTextCell>
                  <HeaderTextCell colSpan={3}>
                    {chartView === CHART_VIEWS.SQUARE_FEET
                      ? `${t(
                          `portfolioInsightsPage.projectCostsViews.table.${tableView}`
                        )} Budget / sqft`
                      : `${t(
                          `portfolioInsightsPage.projectCostsViews.table.${tableView}`
                        )} Budget / acre`}
                  </HeaderTextCell>
                </tr>
              </thead>
              <tbody>
                {tableInfo.map((project) => (
                  <TableRow
                    chartView={chartView}
                    history={history}
                    key={project.id}
                    maxPricePerAreaMeasurementAmount={
                      maxPricePerAreaMeasurementAmount
                    }
                    project={project}
                  />
                ))}
              </tbody>
            </table>
          </Pane>
        </Pane>
      </Card>
    </Fragment>
  );
}
