import PropTypes from "prop-types";
import { get, omit } from "lodash";
import { Pane, Form } from "components/materials";
import { majorScale } from "helpers/utilities";
import { dateFormToServer, formatDate } from "helpers/dateHelpers";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import formatPercent from "helpers/formatPercent";
import unformatNumber from "helpers/unformatNumber";
import DocumentReviewLayout from "./DocumentReviewLayout";

const getInitialValues = ({ document, selectedDraw, selectedType }) => {
  const {
    comments,
    dismissedWarnings,
    documentReviewActivity,
    lineItems,
    percentComplete,
    reviews,
    state,
    ...restOfDocument
  } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);
  return {
    ...documentProperties,
    draw: selectedDraw
      ? { id: selectedDraw.id, name: selectedDraw.name }
      : null,
    type: selectedType,
    percentComplete: percentComplete
      ? formatPercent(percentComplete, null, 5)
      : null,
  };
};

const getDocumentDataFromForm = ({
  expectedCompletionDate,
  inspectorName,
  inspectionDate,
  inspectorNotes,
  percentComplete,
}) => {
  return {
    inspectorName,
    inspectionDate: dateFormToServer(inspectionDate),
    percentComplete: unformatNumber(percentComplete),
    expectedCompletionDate: dateFormToServer(expectedCompletionDate),
    inspectorNotes,
  };
};

function getInformationSummary(form) {
  const summary = [
    get(form.values.vendor, "name"),
    formatDate(form.values.inspectionDate),
  ];
  return summary.filter((value) => !!value).join(", ");
}

function InspectionReportReview(props) {
  const { form } = props;

  return (
    <DocumentReviewLayout
      meta={(content) => (
        <Pane display="flex" width="100%" alignItems="center">
          {content}
        </Pane>
      )}
      information={(content) => (
        <Pane display="flex" flexDirection="column" width="100%">
          <Pane display="flex">
            {content}
            <Pane flexGrow={1} marginRight={majorScale(2)}>
              <Form.Input name="inspectorName" label="Inspector Name" />
            </Pane>
          </Pane>
          <Pane display="flex" marginTop={majorScale(2)}>
            <Pane flexGrow={1} marginRight={majorScale(2)}>
              <Form.DateInput name="inspectionDate" label="Inspection Date" />
            </Pane>
            <Pane flexGrow={1} marginRight={majorScale(2)}>
              <Form.Input
                type="percentage"
                name="percentComplete"
                label="Percent Complete"
              />
            </Pane>
            <Pane flexGrow={1} marginRight={majorScale(2)}>
              <Form.DateInput
                name="expectedCompletionDate"
                label="Expected Completion Date"
                popperPlacement="bottom-end"
              />
            </Pane>
          </Pane>
          <Pane marginTop={majorScale(2)}>
            <Form.TextArea name="inspectorNotes" label="Inspector Notes" />
          </Pane>
        </Pane>
      )}
      informationSummary={getInformationSummary(form)}
      {...props}
    />
  );
}

InspectionReportReview.propTypes = {
  children: PropTypes.node,
  selectedDraw: PropTypes.object,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  document: PropTypes.shape({
    reviews: PropTypes.array,
    comments: PropTypes.array,
    expectedCompletionDate: PropTypes.string,
    inspectionDate: PropTypes.string,
    inspectorName: PropTypes.string,
    inspectorNotes: PropTypes.string,
    percentComplete: PropTypes.number,
  }),
  form: PropTypes.shape({
    values: PropTypes.shape({
      draw: PropTypes.shape({
        id: PropTypes.string,
      }),
      vendor: PropTypes.shape({
        id: PropTypes.string,
      }),
      type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE_NAME)),
      expectedCompletionDate: PropTypes.string,
      inspectionDate: PropTypes.string,
      inspectorName: PropTypes.string,
      inspectorNotes: PropTypes.string,
      percentComplete: PropTypes.number,
    }),
  }),
  onReview: PropTypes.func,
};

// TODO: remove dot notation
InspectionReportReview.getInitialValues = getInitialValues;
InspectionReportReview.getDocumentDataFromForm = getDocumentDataFromForm;

export default InspectionReportReview;
