import { Children, cloneElement } from "react";
import { Pane } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { compact } from "lodash";

const FormGroup = ({
  children,
  aligned,
  maximizeChildWidths,
  renderedChildrenDirectly,
  ...otherProps
}) => {
  const outerSizeProps = aligned ? { width: "100%" } : {};
  const innerSizeProps = aligned ? {} : { width: "100%" };
  if (maximizeChildWidths) {
    innerSizeProps.flexGrow = 1;
  }
  const renderedChildren = compact(Children.toArray(children));

  return (
    <Pane
      className="rbt-formgroup"
      display="flex"
      marginBottom={majorScale(2)}
      {...outerSizeProps}
      {...otherProps}
    >
      {Children.map(renderedChildren, (child, i) => {
        const childProps = {
          ...innerSizeProps,
          marginRight:
            i !== Children.count(renderedChildren) - 1 ? minorScale(3) : 0,
        };

        // If children are rendered directly, clone them and add any precalculated props
        // else wrap them in a Pane container element with precalculated props
        return renderedChildrenDirectly ? (
          cloneElement(child, childProps)
        ) : (
          <Pane {...childProps}>{child}</Pane>
        );
      })}
    </Pane>
  );
};

export default FormGroup;
