import { useContext } from "react";
import { SmallCrossIcon } from "evergreen-ui";
import { css } from "glamor";
import {
  Button,
  Card,
  Link,
  Pane,
  Paragraph,
  Popover,
  Progress,
  Text,
} from "components/materials";
import { majorScale, minorScale, Position } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import { getFundingSourceAggregates } from "helpers/fundingSourceHelpers";
import { BRAND_COLORS } from "helpers/colors";
import { PERMISSION_ACTION } from "helpers/enums";
import { UserContext } from "helpers/behaviors";

function FundingSourceProgress({
  backgroundColor,
  fundingSource,
  theme,
  valueColor,
}) {
  const { amount, disbursedAmount, label } = fundingSource;

  return (
    <Pane marginBottom={majorScale(1)}>
      <Pane display="flex" alignItems="center">
        <Pane flex="1 1 auto">
          <Progress
            background={backgroundColor}
            color={valueColor}
            value={disbursedAmount}
            total={amount}
            height={majorScale(1)}
          />
        </Pane>
        <Paragraph
          data-testid="fsPercent"
          size={300}
          fontWeight={theme.fontWeights.DEMI}
          marginLeft={majorScale(2)}
          textAlign="end"
          width={35}
        >
          {formatPercent(disbursedAmount / amount, "0%")}
        </Paragraph>
      </Pane>
      <Pane marginTop={-minorScale(1)}>
        <Text
          fontWeight={theme.fontWeights.DEMI}
          color="muted"
          data-testid="fsProgress"
          size={300}
        >
          {formatCurrency(disbursedAmount, { shortenedFormat: true })} /{" "}
          {formatCurrency(amount, { shortenedFormat: true })}{" "}
        </Text>
        <Text
          color="muted"
          data-testid="fsLabel"
          wordBreak="break-word"
          size={300}
        >
          {label}
        </Text>
      </Pane>
    </Pane>
  );
}

function FundingSourcesPopover({ aggregates, theme }) {
  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      shouldCloseOnExternalClick={false}
      content={({ close }) => (
        <Pane padding={majorScale(2)} width={400}>
          <Pane display="flex">
            <Text flex="1 1 auto" size={300} color="muted">
              Funding Sources
            </Text>
            <SmallCrossIcon cursor="pointer" color="muted" onClick={close} />
          </Pane>
          <Pane
            paddingX={majorScale(3)}
            paddingTop={majorScale(3)}
            paddingBottom={majorScale(1)}
          >
            {aggregates.EQUITY.sources.length > 0 && (
              <Pane marginBottom={majorScale(3)}>
                <Paragraph
                  size={300}
                  fontWeight={theme.fontWeights.DEMI}
                  marginBottom={majorScale(1)}
                >
                  Equity Contributed
                </Paragraph>
                {aggregates.EQUITY.sources.map((fundingSource) => (
                  <FundingSourceProgress
                    key={fundingSource.id}
                    fundingSource={fundingSource}
                    theme={theme}
                    backgroundColor={theme.colors.green300}
                    valueColor={theme.colors.green600}
                  />
                ))}
              </Pane>
            )}
            {aggregates.LOAN.sources.length > 0 && (
              <Pane>
                <Paragraph
                  size={300}
                  fontWeight={theme.fontWeights.DEMI}
                  marginBottom={majorScale(1)}
                >
                  Debt Disbursed
                </Paragraph>
                {aggregates.LOAN.sources.map((fundingSource) => (
                  <FundingSourceProgress
                    key={fundingSource.id}
                    fundingSource={fundingSource}
                    theme={theme}
                    backgroundColor="lightgrey"
                    valueColor={BRAND_COLORS.LIGHT_PRIMARY}
                  />
                ))}
              </Pane>
            )}
          </Pane>
        </Pane>
      )}
    >
      <Button
        appearance="minimal"
        id="fundingSourcesPopover"
        color="blue"
        fontSize={12}
        margin={0}
        padding={0}
        fontWeight={600}
        purpose="project-sources-popover open"
        {...css({
          "&#fundingSourcesPopover:hover": {
            textDecoration: "underline",
            backgroundColor: "transparent",
          },
        })}
      >
        Funding Source Details
      </Button>
    </Popover>
  );
}

function AggregateProgress({ action, colors, label, theme, total, value }) {
  return (
    <Pane>
      <Paragraph
        color="muted"
        marginBottom={-minorScale(1)}
        fontSize={10}
        fontWeight={theme.fontWeights.MEDIUM}
        textTransform="uppercase"
      >
        {label}
      </Paragraph>
      <Pane display="flex">
        <Text flex="1 1 auto" size={300}>{`${formatCurrency(
          value
        )} ${action}`}</Text>
        <Text fontWeight={theme.fontWeights.MEDIUM} size={300}>
          {formatCurrency(total)}
        </Text>
      </Pane>
      <Progress
        background={colors.background}
        color={colors.value}
        value={value}
        total={total}
        height={majorScale(1)}
      />
    </Pane>
  );
}

function FundingSourceAggregates({ aggregates, project, theme }) {
  const hasEquity = aggregates.EQUITY.sources.length > 0;
  const hasDebt = aggregates.LOAN.sources.length > 0;

  return (
    <Pane
      flex="1 1 auto"
      marginTop={minorScale(1)}
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
    >
      {hasEquity && (
        <AggregateProgress
          action="Contributed"
          colors={{
            background: theme.colors.green300,
            value: theme.colors.green600,
          }}
          label="equity"
          theme={theme}
          total={aggregates.EQUITY.total}
          value={aggregates.EQUITY.disbursed}
        />
      )}
      {hasDebt && (
        <AggregateProgress
          action="Disbursed"
          colors={{
            background: "lightgrey",
            value: BRAND_COLORS.LIGHT_PRIMARY,
          }}
          label="debt"
          theme={theme}
          total={aggregates.LOAN.total}
          value={aggregates.LOAN.disbursed}
        />
      )}
      {(hasEquity || hasDebt) && (
        <AggregateProgress
          action="Disbursed"
          colors={{
            background: theme.colors.lightPurple,
            value: theme.colors.purple600,
          }}
          label="total"
          theme={theme}
          total={project.amount}
          value={aggregates.totalDisbursedAmount}
        />
      )}
    </Pane>
  );
}

export function ProjectFundingSourcesOverview({ project, theme }) {
  const { hasPermission } = useContext(UserContext);
  const noSources = project.fundingSourceGroups.length === 0;
  const aggregates = getFundingSourceAggregates(project.fundingSourceGroups);

  const canEditSettings = hasPermission(
    PERMISSION_ACTION.EDIT_PROJECT_SETTINGS
  );
  const canAccessFundingSources = hasPermission(
    PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
  );

  return (
    <Card
      display="flex"
      flexDirection="column"
      height={210}
      marginTop={majorScale(2)}
      padding={majorScale(2)}
    >
      <Pane display="flex">
        <Text flex="1 1 auto" size={300} fontWeight={theme.fontWeights.MEDIUM}>
          Funding Sources Overview
        </Text>
        {!noSources && canAccessFundingSources && (
          <FundingSourcesPopover aggregates={aggregates} theme={theme} />
        )}
      </Pane>
      {noSources ? (
        <Pane
          textAlign="center"
          height="100%"
          marginTop={majorScale(5)}
          width="100%"
        >
          <Paragraph>This project does not have any funding sources.</Paragraph>
          {canEditSettings && canAccessFundingSources && (
            <Link
              purpose="funding-sources blank setup"
              to={{
                pathname: `/projects/${project.id}/settings`,
                search: "?settings=fundingSources",
              }}
            >
              Set up funding sources
            </Link>
          )}
        </Pane>
      ) : (
        <FundingSourceAggregates
          aggregates={aggregates}
          project={project}
          theme={theme}
        />
      )}
    </Card>
  );
}
