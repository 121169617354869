import PropTypes from "prop-types";
import { withTheme } from "evergreen-ui";
import { getProps, compose, withMemo } from "./utils";
import TableCell, { propTypes as cellPropTypes } from "./Cell";

const TableSectionHeaderCell = compose(
  ({ children, content, theme, ...props }) => {
    props = {
      backgroundColor: theme.tableColors.sectionHeader,
      fontWeight: theme.fontWeights.MEDIUM,
      ...props,
    };

    return (
      <TableCell {...props} className="section">
        {content || children}
      </TableCell>
    );
  },
  [withMemo, withTheme]
);

export const propTypes = {
  ...cellPropTypes,
  children: PropTypes.node,
  content: PropTypes.node,
};

TableSectionHeaderCell.propTypes = propTypes;

// TODO: remove dot notation
TableSectionHeaderCell.getProps = (props) => getProps(propTypes, props);

export default TableSectionHeaderCell;
