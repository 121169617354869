import { Fragment } from "react";
import { MapIcon } from "evergreen-ui";
import { Heading, Divider, Link } from "components/materials";
import { majorScale } from "helpers/utilities";

const PageNotFound = () => (
  <Fragment>
    <Divider />
    <Heading icon textAlign="center" size={600}>
      <MapIcon size={majorScale(5)} />
      <Divider />
      You have navigated to a page that either does not exist or for which you
      do not have access. Please contact support if you believe this is in
      error.
      <Divider />
      <Link purpose="not-found home" to="/" size={600}>
        Home
      </Link>
    </Heading>
  </Fragment>
);

export default PageNotFound;
