import { Fragment } from "react";
import { Badge, Shortener, Text } from "components/materials";
import { SuggestedOptionDescription } from "components/templates";
import { find, get, partition, sortBy } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";
import unformatNumber from "helpers/unformatNumber";
import { subtract } from "helpers/math";
import { minorScale } from "helpers/utilities";
import t from "helpers/translate";

export const formatLineItems = (lineItems) => {
  if (!lineItems) return undefined;

  return lineItems.map((lineItem) => {
    const amount = lineItem.amount.toString();

    return {
      name: lineItem.lineItemObject.name,
      amount,
      grossAmount:
        unformatNumber(lineItem.retainageAmount) === 0
          ? amount
          : lineItem.grossAmount.toString(),
      retainageAmount: get(lineItem, "retainageAmount", 0).toString(),
      retainageToDateAmount: get(
        lineItem,
        "retainageToDateAmount",
        0
      ).toString(),
      jobCostCode: lineItem.jobCostCode,
    };
  });
};

// Line items are identified by name and not id
// We use "lineItemName (divisionName)" to prevent name collisions
export function getFullLineItemName({ division, name }) {
  return `${name} (${division.name})`;
}

export function getLineItemDisplayName({ division, name, number }) {
  const prefix = number ? `(${number}) ` : "";
  return `${prefix}${name} (${division.name})`;
}

const getDescription = ({
  grossDocumentedRequestedAmount,
  grossRequestedAmount,
}) => {
  const diff = subtract(grossRequestedAmount, grossDocumentedRequestedAmount);
  if (diff > 0) return `${formatCurrency(diff)} unreconciled`;
  if (diff < 0) return `${formatCurrency(-diff)} over-documented`;
  return null;
};

export const getLineItemOptions = (lineItems) =>
  sortBy(
    lineItems,
    (lineItem) =>
      -Math.abs(
        subtract(
          lineItem.grossDocumentedRequestedAmount,
          lineItem.grossRequestedAmount
        )
      )
  ).map((lineItem) => ({
    description: getDescription(lineItem),
    key: lineItem.id,
    text: getLineItemDisplayName(lineItem),
    value: {
      id: lineItem.id,
      name: getFullLineItemName(lineItem),
    },
  }));

function sortSuggestionsAndLineItems(lineItems, suggestions) {
  const sortedLineItems = sortBy(
    lineItems,
    (lineItem) =>
      -Math.abs(
        subtract(
          lineItem.grossDocumentedRequestedAmount,
          lineItem.grossRequestedAmount
        )
      )
  ).map((li) => {
    const suggestion = find(suggestions, (s) => s.lineItem.id === li.id);

    return {
      ...li,
      reason: get(suggestion, "reason"),
    };
  });

  // returns in the format `[suggestedItems, remainingItems]`
  return partition(sortedLineItems, (li) => !!li.reason);
}

function getBadgedLineItemName(li, description, suggested) {
  const divisionName = get(li, "division.name");
  // Popover minWidth is set at 700, so following calculations approximate based on varying character width
  const descriptionLength =
    typeof description === "string" ? description.length : 0;
  const charactersRemaining =
    85 - divisionName.length - descriptionLength - (suggested ? 10 : 0);

  return divisionName ? (
    <Fragment>
      <Badge color="neutral" fontWeight="normal" marginRight={minorScale(1)}>
        {divisionName}
      </Badge>
      {li.number && (
        <Text fontWeight="bold" marginRight={minorScale(1)}>
          ({li.number})
        </Text>
      )}
      <Shortener fontWeight="bold" limit={charactersRemaining} text={li.name} />
    </Fragment>
  ) : (
    li.name
  );
}

export function getLineItemOptionsWithSuggestions(
  lineItems,
  suggestions,
  vendorName,
  theme
) {
  const [suggestedItems, remainingItems] = sortSuggestionsAndLineItems(
    lineItems,
    suggestions
  );

  const suggestionOptions = suggestedItems.map((lineItem) => {
    const description = getDescription(lineItem);
    return {
      description: (
        <SuggestedOptionDescription
          description={description}
          tooltipContent={t(`lineItemSuggestions.${lineItem.reason}`, {
            lineItemName: lineItem.name,
            vendorName,
          })}
        />
      ),
      key: lineItem.id,
      text: getBadgedLineItemName(lineItem, description, true),
      selectedText: getLineItemDisplayName(lineItem),
      style: { backgroundColor: theme.colors.lightYellow },
      value: {
        id: lineItem.id,
        name: getFullLineItemName(lineItem),
      },
      highlighted: true,
    };
  });

  const remainingOptions = remainingItems.map((lineItem) => {
    const description = getDescription(lineItem);
    return {
      description,
      key: lineItem.id,
      text: getBadgedLineItemName(lineItem, description),
      selectedText: getLineItemDisplayName(lineItem),
      value: {
        id: lineItem.id,
        name: getFullLineItemName(lineItem),
      },
    };
  });

  return suggestionOptions.concat(remainingOptions);
}

export const getMatch = (drawLineItems, lineItem) => {
  const items = drawLineItems.map((drawLineItem) => ({
    ...drawLineItem,
    fullName: getFullLineItemName(drawLineItem),
  }));
  return find(items, (item) => {
    return item.id === lineItem.lineItemId;
  });
};
