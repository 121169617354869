import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get, set } from "lodash";
import isBlank from "helpers/isBlank";
import unformatNumber from "helpers/unformatNumber";
import {
  fundingSourceIsBlank,
  hasInsufficientDrawAmounts,
} from "helpers/fundingSourceHelpers";
import isEqualWithout from "helpers/isEqualWithout";
import formatPercent from "helpers/formatPercent";
import { dateServerToForm } from "helpers/dateHelpers";
import { MissingBudget } from "./ProjectFundingSources/MissingBudget";
import FundingSourcesFormInner from "./ProjectFundingSources/FundingSourcesFormInner";
import FundingSourceBlankSlate from "./ProjectFundingSources/FundingSourceBlankSlate";

function destructureInitialValues(fundingSourceGroups) {
  // Turn queried data into form data
  const fundingSource = {};

  fundingSourceGroups.forEach((fsGroup) => {
    fsGroup.forEach((fs) => {
      fundingSource[fs.id] = { lineItemIds: {} };
      fs.lineItemIds.forEach((lineItemId) => {
        fundingSource[fs.id].lineItemIds[lineItemId] = true;
      });
    });
  });

  return fundingSource;
}

export function initialValues(
  fundingSourceGroups,
  autoAllocateOnProject,
  usesOfFundsOnProject,
  project
) {
  const usesOfFundsLineItems = destructureInitialValues(fundingSourceGroups);
  const projectTotal = project.amount;
  // Only pull off fields needed by FundingSourcesForm
  const groups = [
    ...fundingSourceGroups.map((group) =>
      group.map((fundingSource) => {
        const amount = unformatNumber(fundingSource.amount);
        const percent = projectTotal === 0 ? 0 : amount / projectTotal;

        return {
          amount: fundingSource.amount,
          percent: formatPercent(percent, "-", 3),
          disbursedAmount: fundingSource.disbursedAmount,
          label: fundingSource.label,
          id: fundingSource.id,
          lineItemIds: fundingSource.lineItemIds,
          closeDate: dateServerToForm(fundingSource.closeDate),
          maturityDate: dateServerToForm(fundingSource.maturityDate),
          type: fundingSource.type,
          organization: {
            id:
              get(fundingSource, "organization.id") ||
              get(fundingSource, "organizationId"),
          },
        };
      })
    ),
  ];

  return {
    automaticAllocationEnabled: autoAllocateOnProject,
    errorWarning: "",
    fundingSource: usesOfFundsLineItems,
    fundingSourceGroups: groups,
    projectTotal,
    usesOfFundsEnabled: usesOfFundsOnProject,
    warning: "",
  };
}

export const validate = (
  initialValues,
  { allowBlankAmount, skipBlankFundingSources } = {}
) => (values) => {
  const errors = {};

  // TODO clean up this warning to only display on submit...
  const insufficientDrawFunds = hasInsufficientDrawAmounts(
    values.fundingSourceGroups,
    initialValues.fundingSourceGroups
  );

  if (
    values.fundingSourceGroups.length > 0 &&
    (!isBlank(values.errorWarning) || insufficientDrawFunds)
  ) {
    set(errors, "warning", values.errorWarning);
  }

  values.fundingSourceGroups.forEach((fundingSourceGroup, fsGroupIndex) => {
    fundingSourceGroup.forEach((fundingSource, fsIndex) => {
      const validateFundingSource =
        skipBlankFundingSources && !fundingSourceIsBlank(fundingSource);

      if (!skipBlankFundingSources || validateFundingSource) {
        if (isBlank(get(fundingSource, "label"))) {
          set(
            errors,
            ["fundingSourceGroups", fsGroupIndex, fsIndex, "label"],
            "Please enter a name"
          );
        }
        if (isBlank(get(fundingSource, "organization.id"))) {
          set(
            errors,
            [
              "fundingSourceGroups",
              fsGroupIndex,
              fsIndex,
              "organization",
              "id",
            ],
            "Please select an organization"
          );
        }
        if (!allowBlankAmount && isBlank(get(fundingSource, "amount"))) {
          set(
            errors,
            ["fundingSourceGroups", fsGroupIndex, fsIndex, "amount"],
            "Please enter an amount"
          );
        }
        if (unformatNumber(get(fundingSource, "amount")) < 0) {
          set(
            errors,
            ["fundingSourceGroups", fsGroupIndex, fsIndex, "amount"],
            "Amount cannot be negative"
          );
        }
        if (isBlank(get(fundingSource, "type"))) {
          set(
            errors,
            ["fundingSourceGroups", fsGroupIndex, fsIndex, "type"],
            "Please select a type"
          );
        }
      }
    });
  });

  return errors;
};

export function FundingSourcesForm({
  dirty,
  divisions,
  form,
  getProjectVendorSearchQuery,
  hasAutoAllocateOnOrg,
  hasUsesOfFundsOnOrg,
  newProject,
  organizations,
  panelKey,
  project,
  searchedVendors,
  setPanelDirty,
}) {
  const fundingSourcesDirty = !isEqualWithout(
    ["errorWarning", "warning"],
    form.values,
    form.initialValues
  );

  useEffect(() => {
    if (dirty !== fundingSourcesDirty) {
      setPanelDirty(panelKey, fundingSourcesDirty);
    }
  }, [dirty, fundingSourcesDirty, panelKey, setPanelDirty]);

  const [showForm, setShowForm] = useState(
    form.values.fundingSourceGroups.length > 0
  );

  if (!newProject && !project.amount) {
    return <MissingBudget projectId={project.id} />;
  }

  if (!showForm) {
    return (
      <FundingSourceBlankSlate
        divisions={divisions}
        form={form}
        setShowForm={setShowForm}
      />
    );
  }

  return (
    <FundingSourcesFormInner
      divisions={divisions}
      draws={project.draws}
      form={form}
      getProjectVendorSearchQuery={getProjectVendorSearchQuery}
      hasAutoAllocateOnOrg={hasAutoAllocateOnOrg}
      hasUsesOfFundsOnOrg={hasUsesOfFundsOnOrg}
      organizations={organizations}
      projectTotal={project.amount}
      searchedVendors={searchedVendors}
    />
  );
}

FundingSourcesForm.propTypes = {
  divisions: PropTypes.array.isRequired,
  form: PropTypes.shape({
    dirty: PropTypes.bool.isRequired,
    values: PropTypes.shape({
      fundingSourceGroups: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  hasAutoAllocateOnOrg: PropTypes.bool.isRequired,
  hasUsesOfFundsOnOrg: PropTypes.bool.isRequired,
  newProject: PropTypes.bool,
  organizations: PropTypes.array.isRequired,
  panelKey: PropTypes.string,
  project: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    draws: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  setPanelDirty: PropTypes.func,
};

FundingSourcesForm.defaultProps = {
  newProject: false,
  panelKey: "",
  setPanelDirty: () => {},
};
