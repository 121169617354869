import { Pane, StickyHeaderTable, Table } from "components/materials";
import DivisionRows from "./DivisionRows";
import { ProjectionTableTotal } from "./ProjectionTableTotal";
import { ProjectionTableHeader } from "./ProjectionTableHeader";

function getProjectionHeaderCells(columns) {
  return [
    {
      header: "Line Item",
      props: { width: 300 },
    },
    {
      header: "Curve Type",
      props: { width: 125 },
    },
    {
      header: "Start Month",
      props: { width: 125 },
    },
    {
      header: "Duration (months)",
      props: { width: 125 },
    },
    ...(columns.showPreviousBudget
      ? [
          {
            header: "Previous Budget",
            props: { width: 125, textAlign: "right" },
          },
        ]
      : []),
    {
      header: "Current Budget",
      props: { width: 125, textAlign: "right" },
    },
  ];
}

export function ProjectionTable({ onChange, projection }) {
  const {
    actualsCount,
    columns,
    divisions,
    drawNames,
    project,
    state: projectionState,
    projectionTotals,
  } = projection;

  return (
    <Pane paddingBottom={85}>
      <StickyHeaderTable
        bodyProps={{ allowHorizontalScroll: true, stickyColumn: true }}
        headerProps={{ stickyColumn: true }}
        header={
          <ProjectionTableHeader
            actualsCount={actualsCount}
            columns={columns}
            project={project}
            projectionState={projectionState}
            drawNames={drawNames}
            headerCells={getProjectionHeaderCells(columns)}
            superheaderColSpan={columns.showPreviousBudget ? 5 : 4}
          />
        }
      >
        <Table.Body>
          {divisions.map((division) => {
            return (
              <DivisionRows
                actualsCount={actualsCount}
                columns={columns}
                division={division}
                onChange={onChange}
                project={project}
                projectionState={projectionState}
              />
            );
          })}
          <ProjectionTableTotal
            actualsCount={actualsCount}
            columns={columns}
            divisions={divisions}
            project={project}
            projectionState={projectionState}
            projectionTotals={projectionTotals}
          />
        </Table.Body>
      </StickyHeaderTable>
    </Pane>
  );
}
