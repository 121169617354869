import { useContext, Fragment } from "react";
import { ProjectDraws, VendorLink } from "components/templates";
import {
  Card,
  GoogleMap,
  Link,
  Pane,
  Paragraph,
  Progress,
  Shortener,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { DATE_FORMAT } from "helpers/dateHelpers";
import { ORGANIZATION_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import { compact, find, get } from "lodash";
import { addMonths, format, parse } from "date-fns";
import { formatCurrency } from "helpers/formatCurrency";
import { add } from "helpers/math";
import { DrawCallToAction } from "../../../templates/PortfolioDashboard/PortfolioProjectCard";
import { ProjectBudgetOverview } from "./ProjectBudgetOverview";
import { ProjectFundingSourcesOverview } from "./ProjectSourcesOverview";
import { ProjectTasksOverview } from "./ProjectTasksOverview";

function StakeholderLinks({
  borrower,
  generalContractor,
  hasPermission,
  isDeveloper,
  project,
  theme,
}) {
  const canEditSettings = hasPermission(
    PERMISSION_ACTION.EDIT_PROJECT_SETTINGS
  );
  const canAccessFundingSources = hasPermission(
    PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
  );
  const isImportedGC = !get(project, "organization.vendors", []).some(
    ({ id }) => id === get(generalContractor, "organization.id")
  );

  return (
    <Fragment>
      {!isDeveloper && borrower && canAccessFundingSources && (
        <Pane
          marginY={majorScale(1)}
          maxWidth="100%"
          color={theme.colors.baseBlue}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <Paragraph
            size={300}
            fontWeight={theme.fontWeights.MEDIUM}
            color="muted"
            marginBottom={-6}
          >
            BORROWER
          </Paragraph>
          <VendorLink
            limit={10}
            linkText={borrower.organization.name}
            noIcon
            vendorId={borrower.organization.id}
            vendors={[borrower.organization]}
          />
        </Pane>
      )}
      {generalContractor && (
        <Pane
          marginY={majorScale(1)}
          maxWidth="100%"
          color={theme.colors.baseBlue}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <Paragraph
            size={300}
            fontWeight={theme.fontWeights.MEDIUM}
            color="muted"
            marginBottom={isImportedGC ? 0 : -6}
          >
            GENERAL CONTRACTOR
          </Paragraph>
          {isImportedGC ? (
            <Text size={300}>{generalContractor.organization.name}</Text>
          ) : (
            <VendorLink
              limit={10}
              linkText={generalContractor.organization.name}
              noIcon
              vendorId={generalContractor.organization.id}
              vendors={[generalContractor.organization]}
            />
          )}
        </Pane>
      )}
      {canEditSettings && (
        <Pane position="absolute" bottom={majorScale(1)}>
          <Link
            purpose="project-settings stakeholders manage"
            size={300}
            to={{
              pathname: `/projects/${project.id}/settings`,
              search: "?settings=stakeholders",
            }}
          >
            View all stakeholders
          </Link>
        </Pane>
      )}
    </Fragment>
  );
}

function ProjectProgressCircle({ header, value, total }) {
  const percent = value && total ? (value / total) * 100 : 0;
  const label = `${formatCurrency(value, {
    shortenedFormat: true,
  })} / ${formatCurrency(total, {
    shortenedFormat: true,
  })}`;

  return (
    <Pane
      textAlign="center"
      // needed to keep these components within their container in IE11
      flex="1 1 0%"
    >
      <Progress.Circular height={majorScale(8)} percent={percent} />
      <Paragraph>{header}</Paragraph>
      <Paragraph color="muted" size={300} data-testid="circleProgress">
        {label}
      </Paragraph>
    </Pane>
  );
}

const ProjectProgress = ({ project }) => {
  const hasNoDraws = project.draws.length === 0;
  const startDate = get(project, "startDate");
  const expectedProjectLength = get(project, "expectedProjectLength", 0);
  const endDate =
    startDate && expectedProjectLength
      ? format(
          addMonths(
            parse(startDate, "yyyy-mm-dd", new Date()),
            expectedProjectLength
          ),
          DATE_FORMAT
        )
      : null;

  const contingencySpentAmount = get(project, "contingencySpentAmount", 0);

  const projectTotal = get(project, "amount", 0);

  return (
    <Pane
      // Firefox and IE11 need this width set explicitly
      width="calc(68% - 215px)"
      display="flex"
      justifyContent="center"
    >
      <Pane
        maxWidth={340}
        width="100%"
        minWidth={250}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginLeft={-majorScale(2)}
      >
        <Pane
          display="flex"
          justifyContent="space-evenly"
          marginBottom={majorScale(1)}
        >
          {endDate && (
            <Pane textAlign="center">
              <Paragraph
                fontSize={16}
                fontWeight={500}
                data-testid="projectEndDate"
              >
                {endDate}
              </Paragraph>
              <Paragraph color="muted">Completion Date</Paragraph>
            </Pane>
          )}
          <Pane textAlign="center">
            <Paragraph
              fontSize={16}
              fontWeight={500}
              data-testid="projectTotal"
            >
              {formatCurrency(projectTotal)}
            </Paragraph>
            <Paragraph color="muted">Project Total</Paragraph>
          </Pane>
        </Pane>
        <Pane display="flex" justifyContent="space-around">
          <ProjectProgressCircle
            header="Completed"
            value={get(project, "disbursedAmount", 0)}
            total={projectTotal}
          />
          <ProjectProgressCircle
            header="Requested"
            value={get(project, "inProgressAmount", 0)}
            total={projectTotal}
          />
          {!hasNoDraws && (
            <ProjectProgressCircle
              header="Contingency"
              value={add(
                contingencySpentAmount,
                project.contingencySegments.all.untrackedContingency
              )}
              total={project.contingencySegments.all.totalContingency}
            />
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};

function ProjectOverviewCard({
  borrower,
  generalContractor,
  hasPermission,
  isDeveloper,
  project,
  theme,
}) {
  return (
    <Card display="flex" width="100%" height={215}>
      <Pane minWidth={215} display="flex" justifyContent="center">
        {project.picture ? (
          <div
            style={{
              backgroundImage: `url("${project.picture.url}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              borderRadius: "4px 0 0 4px",
            }}
          />
        ) : (
          <GoogleMap height="100%" projects={[project]} />
        )}
      </Pane>
      <Pane
        width="32%"
        paddingLeft={minorScale(3)}
        paddingTop={majorScale(2)}
        position="relative"
      >
        <Shortener
          fontSize={24}
          limit={30}
          minimumFontSize={16}
          text={project.name}
          useDynamicFontSize
          wordBreak="break-word"
        />
        {(project.city || project.state) && (
          <Paragraph
            color="muted"
            fontWeight={theme.fontWeights.DEMI}
            marginTop={minorScale(1)}
            overflow="hidden"
            size={300}
            textOverflow="ellipsis"
            textTransform="uppercase"
            whiteSpace="nowrap"
          >
            {compact([project.city, project.state]).join(", ")}
          </Paragraph>
        )}
        <DrawCallToAction project={project} hasPermission={hasPermission} />
        {hasPermission(PERMISSION_ACTION.ACCESS_STAKEHOLDERS) && (
          <StakeholderLinks
            borrower={borrower}
            generalContractor={generalContractor}
            hasPermission={hasPermission}
            isDeveloper={isDeveloper}
            theme={theme}
            project={project}
          />
        )}
      </Pane>
      <ProjectProgress project={project} />
    </Card>
  );
}

export function ProjectOverview({ project, history, theme }) {
  const { hasPermission, isDeveloper } = useContext(UserContext);

  const borrower = find(project.stakeholders, {
    role: ORGANIZATION_TYPE.BORROWER,
  });
  const generalContractor = find(project.stakeholders, {
    role: ORGANIZATION_TYPE.CONTRACTOR,
  });
  const showTasks = hasPermission(PERMISSION_ACTION.TASK_MANAGEMENT);

  return (
    <Pane width="100%">
      <Paragraph
        height={40}
        display="flex"
        alignItems="center"
        color="muted"
        fontWeight={theme.fontWeights.MEDIUM}
      >
        PROJECT
      </Paragraph>
      <ProjectOverviewCard
        borrower={borrower}
        generalContractor={generalContractor}
        hasPermission={hasPermission}
        isDeveloper={isDeveloper}
        project={project}
        theme={theme}
      />
      <ProjectDraws
        getDrawPath={(drawId) => `/projects/${project.id}/draws/${drawId}`}
        newDrawPath={`/projects/${project.id}/draws/new`}
        canCreateDraws={hasPermission(PERMISSION_ACTION.CREATE_DRAW)}
        project={project}
      />
      <Pane display="flex">
        <Pane paddingRight={majorScale(1)} width="50%">
          <ProjectBudgetOverview project={project} />
        </Pane>
        <Pane width="50%" paddingLeft={majorScale(1)}>
          <Pane>
            <ProjectFundingSourcesOverview project={project} theme={theme} />
          </Pane>
          {showTasks && (
            <Pane>
              <ProjectTasksOverview
                project={project}
                history={history}
                theme={theme}
              />
            </Pane>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
}
