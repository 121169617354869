import { useContext } from "react";
import { Link, Pane } from "components/materials";
import { find, get, omit } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale, ThemeContext } from "helpers/utilities";
import { DOCUMENT_STATE, DOCUMENT_TYPE_NAME } from "helpers/enums";
import { getMatch } from "./invoiceLineItemHelpers";

export const getVendorIdForDocument = (allDocuments, selectedDocument) => {
  const document = find(allDocuments, ({ id }) => id === selectedDocument.id);
  if (!document) return null;
  return get(document, "vendor.id") || get(document, "vendorId");
};

export const matchLineItem = (drawLineItems) => (lineItem) => {
  const match = getMatch(drawLineItems, lineItem);
  return {
    amount: lineItem.amount,
    lineItemObject: match
      ? { id: match.id, name: match.fullName }
      : { id: null, name: null },
  };
};

export function getEmptyLineItem() {
  return {
    amount: formatCurrency(0),
    lineItemObject: { id: null, name: null },
  };
}

const getTarget = (document, selectedType) => {
  const id = get(document, "assignedInvoiceId");
  const targetDrawId = get(document, "targetDrawId");
  if (!id) return { id: null };
  if (selectedType === DOCUMENT_TYPE_NAME.CONDITIONAL_LIEN_RELEASE)
    return { id };
  // else the selected type is an unconditional lien release -- add the target draw id
  return { id, drawId: targetDrawId };
};

export const getInitialValues = ({ document, selectedDraw, selectedType }) => {
  const {
    comments,
    dismissedWarnings,
    documentReviewActivity,
    reviews,
    state,
    vendor,
    ...restOfDocument
  } = document;
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(restOfDocument, ["file", "upload.file"]);
  const target = getTarget(document, selectedType);
  const isManual =
    !target.id &&
    [DOCUMENT_STATE.APPLIED, DOCUMENT_STATE.CORRECTED].includes(document.state);
  const values = {
    ...documentProperties,
    vendor: vendor || { id: null },
    targetVendorId: isManual ? null : get(document, "vendor.id"),
    draw: selectedDraw
      ? {
          id: selectedDraw.id,
          name: selectedDraw.name,
          documents: selectedDraw.documents,
          lineItems: selectedDraw.lineItems,
        }
      : null,
    type: selectedType,
    isManual,
    target,
    lineItems:
      document.lineItems && document.lineItems.length > 0
        ? document.lineItems.map(
            matchLineItem(selectedDraw ? selectedDraw.lineItems : [])
          )
        : [getEmptyLineItem()],
  };
  if (
    isManual &&
    selectedType === DOCUMENT_TYPE_NAME.UNCONDITIONAL_LIEN_RELEASE
  )
    return {
      ...values,
      manualTargetDraw:
        get(document, "targetDrawId") || get(selectedDraw, "id"),
    };

  return values;
};

export const getVendorIdFromForm = (values) => {
  if (values.isManual) {
    return get(values, "vendor.id", null);
  }

  return get(values, "targetVendorId", null);
};

export function MethodSelectorOption(props) {
  const { selected, children, onClick } = props;
  const theme = useContext(ThemeContext);

  return (
    <Pane
      padding={majorScale(2)}
      boxShadow={selected ? `inset 0 -4px 0 #1d7ce2` : undefined}
    >
      <Link
        color="muted"
        fontWeight={theme.fontWeights.MEDIUM}
        onClick={onClick}
        purpose="document-review lien-waiver select-method"
        size={300}
      >
        {children}
      </Link>
    </Pane>
  );
}

export function MethodSelector({ form }) {
  return (
    <Pane display="flex" justifyContent="space-around" paddingX={majorScale(2)}>
      <MethodSelectorOption
        selected={!form.values.isManual}
        onClick={() => {
          form.setFieldValue("isManual", false);
        }}
      >
        Assign to Pay Application or Invoice
      </MethodSelectorOption>
      <MethodSelectorOption
        selected={form.values.isManual}
        onClick={() => {
          form.setFieldValue("isManual", true);
        }}
      >
        Manually Enter Document Information
      </MethodSelectorOption>
    </Pane>
  );
}
